<div [formGroup]="riskLevelsForm">
  <h4 class="card-title text-muted wrap-this">Risk</h4>
  <div class="row">
    <div class="col-md-12">
      <div
        *ngIf="
          riskLevelsForm.pristine !== true &&
          riskLevelsForm.errors?.requireCheckboxToBeChecked
        "
        class="validation-error"
      >
        At least one risk level must be selected.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ul class="horizontalList">
        <li *ngFor="let riskLevel of riskLevels">
          <input type="checkbox" [formControlName]="riskLevel.description" [id]="selectorPrefix + riskLevel.description" />
          <label [for]="selectorPrefix + riskLevel.description">{{ riskLevel.description }}</label>
        </li>
      </ul>
    </div>
  </div>
</div>
