import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UserRightsInfo, DriverProfile, DriverAutoCoverage } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'safety-auto-coverage',
  templateUrl: './safety-auto-coverage.component.html',
  styleUrls: ['./safety-auto-coverage.component.css']
})
export class SafetyAutoCoverageComponent implements OnInit {

  // private variables that are only shared with subscribers that import the type
  @Input() driverProfile: DriverProfile;
  @Input() driverAutoCoverage: Array<DriverAutoCoverage>;
  @Input() _userRights: Array<UserRightsInfo>;
  showVerified = true;
  gtOptionsAutoCoverage: any = {};
  public driverAutoCoverageTableConfig: any;

  // the overloaded constructor for the controller
  constructor(private readonly datePipe: DatePipe) { }

  // angular on intialization event
  ngOnInit() {
    // set data for tables
    this.setTableData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //update table data when inputs change
    this.setTableData();
  }

  private setTableData() {
    if (this.driverAutoCoverage) {
      this.setAutoCoverageTableConfig(this.driverAutoCoverage);
    }
  }

  private setAutoCoverageTableConfig(arrAutoCoverage: Array<DriverAutoCoverage>) {
    // only showing one row for this table, so it's safe to get the value from the first row to set if the column will show
    if (arrAutoCoverage) { 
      this.showVerified = arrAutoCoverage[0]?.requiresVerification; 
    }
    
    const columns = [
      { name: "Document Type", prop: "documentType", title: "Document Type", visible: true, sort: "", dataType: 'string' },
      { name: "Assigned", prop: "assignedDate", title: "Assigned", visible: true, sort: "", dataType: 'date' },
      { name: "Due Date", prop: "dueDate", title: "Due Date", sort: "", visible: true, dataType: 'date' },
      { name: "Expiration Date", prop: "expirationDate", title: "Expiration Date", visible: true, sort: "", dataType: 'date' },
      { name: "Uploaded", prop: "documentCreateDate", title: "Uploaded", visible: true, sort: "", dataType: 'date' },
      { name: "Verified", prop: "verifiedDate", title: "Verified", sort: "", visible: this.showVerified, dataType: 'date' },
      { name: "Verified By", prop: "verifiedBy", title: "Verified By", sort: "", visible: this.showVerified, dataType: 'string' },
      { name: "Status", prop: "status", title: "Status", sort: "", visible: true, dataType: null },
      { name: "Status Style", prop: "statusStyle", title: "Status Style", sort: "", visible: false, dataType: null },
      { name: "Requires Verification", prop: "requiresVerification", title: "Requires Verification", sort: "", visible: false, dataType: null },
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: s.sort
      };
    });

    const fields = [];
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        hidden: !s.visible,
        sort: s.sort === 'enable',
        render: row => {
          let r = row ? row[s.prop] || null : null;
          if (s.dataType === "date") {
            // format in mm/dd/yyyy format
            try {
              r = this.datePipe.transform(r, 'MM/dd/yyyy')
            } catch (e) { }
          }
          if (s.dataType === "boolean") {
            // convert to string if not null
            try {
              r = r.toString();
            } catch (e) {
              r = "";
            }
          }
          if (s.dataType === "string") {
            // show null or undefined enter none
            if (!r) {
              r = "None"
            }
          }
          if (s.prop === "status" && row?.statusStyle === "FontColorRed") {
            r = `<div class="text-danger"> + ${r} + </div>`
          }

          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.driverAutoCoverageTableConfig = {
      settings: settings,
      fields: fields,
      data: arrAutoCoverage
    }

    this.gtOptionsAutoCoverage = (arrAutoCoverage.length > 1 ? { numberOfRows: arrAutoCoverage.length } : {});
  }

}
