//Angular
import { Component, OnInit, OnDestroy, Inject, ViewChildren, QueryList, Pipe } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
//Third Party
import { Subscription, Observable, forkJoin, combineLatest, zip, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
//App
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import {
    DriverClientSettings,
    DriverProfile,
    UserRightsInfo,
    DriverNotes,
    DriverDocuments,
    DriverFollowUpDate,
    DriverTraining,
    DriverTelematics,
    RiskHistory,
    RiskClaimHistory,
    AuxiliaryPointHistory,
    MVRRecordsCurrent,
    DriverQualifications,
    DriverHPEAssignment,
    MVRHistory,
    DriverActionMenuLabel,
    DriverActionMenuItem,
    DriverExtendedInfo,
    DriverEmploymentHistory,
    DriverProfileTabInfo,
    DriverAutoCoverage,
    DriverPendingTask,
    DriverMMTraining
} from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DriverHistoryProfileService } from './dhp-landing-page-service.component';
import { DataGridModalService } from '../../components/data-grid-filter-modal/data-grid-filter-modal.service'
import { UserRightsService } from '../../components/user-rights-service/user-rights-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { DataGridMenuService } from '../../components/data-grid-menu-component/data-grid-menu.service';
import { DataGrid } from '../../components/data-grid-menu-component/data-grid-menu.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

/**
 * this is the client-side controller for the dashboard custom report admin template.
 */
@Component({
    selector: 'dhp-landing-page',
    templateUrl: './dhp-landing-page.html',
    styleUrls: ['./dhp-landing-page.component.scss']
})

export class DriverProfileComponent implements OnInit, OnDestroy {
    // private variables that are only shared with subscribers that import the type
    driverProfile: DriverProfile;
    arrReqFields: Array<DriverExtendedInfo> = [];
    extendedInfo: Array<any>;
    _userRights: Array<UserRightsInfo>;
    clientSettings: DriverClientSettings;
    obsSubscription: Subscription;
    routeSub: Subscription;
    refreshAllInfoSub: Subscription;
    refreshBaseInfoSub: Subscription;
    refreshNotesInfoSub: Subscription;
    refreshDocumentsInfoSub: Subscription;
    refreshFollowUpDateInfoSub: Subscription;
    refreshCompletedTrainingInfoSub: Subscription;
    refreshIncompleteTrainingInfoSub: Subscription;
    refreshExternalTrainingInfoSub: Subscription;
    refreshHPEAssignmentInfoSub: Subscription;
    refreshMotorMindsAssignmentInfoSub: Subscription;
    refreshTelematicsInfoSub: Subscription;
    refreshRiskHistoryInfoSub: Subscription;
    refreshRiskClaimsHistoryInfoSub: Subscription;
    refreshAuxiliaryPointsInfoSub: Subscription;
    refreshCurrentMVRInfoSub: Subscription;
    refreshMVRHistoryInfoSub: Subscription;
    refreshDriverQualificationsInfoSub: Subscription;
    refreshSecondaryDriverInfoSub: Subscription;
    refreshLeaveOfAbsenceInfoSub: Subscription;
    refreshDriverPendingTask: Subscription;
    resendESignEmailSub: Subscription;
    clientSelectedArray: Array<string>;
    clientIdSelectedArray: Array<number>;
    lineOfBusinessId: number;
    baseUrl: string;
    driverId: string;
    leaveOfAbsenceDataGridId: number;
    canViewDLDOB: boolean = false;

    // for building Action Items and Driver Status actions
    actionMenuObjectArray: any = [];
    tabs: Array<any> = [];
    openToTab: string;

    // the overloaded constructor for the controller
    constructor(private router: Router,
        private driverHistoryProfileService: DriverHistoryProfileService,
        private dataGridModalService: DataGridModalService,
        private clientSelectionService: ClientSelectionService,
        private userRightsService: UserRightsService,
        private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string,
        private loadingSpinnerService: LoadingSpinnerService,
        private lineOfBusinessService: LineOfBusinessService,
        private dataGridMenuService: DataGridMenuService,
        private route: ActivatedRoute,
        private errorService: ErrorModalService,
        private fileDownloadService: FileDownloadService) {
        this.baseUrl = baseUrl;
    }

    // angular on intialization event
    ngOnInit() {

        // set line of business to safety if not already initialized to Safety
        this.lineOfBusinessId = 2;
        let lob = this.lineOfBusinessService.getLineOfBusinessValue();
        if (lob != 2) {
            this.lineOfBusinessService.setLineOfBusiness(2);
        }

        // get the selected client(s)
        this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusinessId);

        this.dataGridMenuService.getByLineOfBusiness(2, this.clientSelectedArray.join(',')).subscribe(data => {
            let dataGrid = data as Array<DataGrid>;

            let report = dataGrid.find(x => x.title.toLowerCase() == "leave of absence report");
            if (report) {
                this.leaveOfAbsenceDataGridId = report.dataGridId;
            }
        });

        // subscribes to user id changes and queryParams
        let obsComb = combineLatest(this.route.params, this.route.queryParams,
            (params, qparams) => ({ params, qparams }));

        this.routeSub = obsComb.subscribe(allParams => {
            this.driverId = allParams.params['id'];
            this.openToTab = allParams.qparams['openToTab'];

            // gets the driver's profile data and use rights
            if (this.driverId && (this.driverId.length > 0)) {
                this.getAllDriverProfileData(this.driverId);
            }
        });

        // subscribe to alerts from modals that ALL data needs to be refreshed
        this.refreshAllInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileAllData$.subscribe({
            next: (data) => {
                // dhpRefreshDriverProfileData will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    // update all profile data
                    this.getAllDriverProfileData(this.driverId);
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileAllData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        }
        );

        // subscribe to alerts from modals that BASE data needs to be refreshed
        this.refreshBaseInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileBaseData$.subscribe({
            next: (data) => {
                // dhpRefreshDriverProfileData will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    let arrObs: Array<Observable<Object>> = [
                        this.driverHistoryProfileService.getDriverHistoryProfileBaseData(this.driverId),
                        this.getActionItemMenuData(this.driverId)
                    ];
                    this.loadingSpinnerService.show();
                    forkJoin(arrObs).subscribe({
                        next: (profile) => {
                            this.setDriverBaseInfo(profile[0] as DriverProfile);
                            this.getActionMenuObjectArray(profile[1]);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileBaseData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshNotesInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileNotesData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    this.loadingSpinnerService.show();
                    this.driverHistoryProfileService.getDriverHistoryProfileNotesData(this.driverId).subscribe({
                        next: (profile) => {
                            this.setDriverNotesInfo(profile, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileNotesData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshDocumentsInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileDocumentsData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    this.loadingSpinnerService.show();
                    this.driverHistoryProfileService.getDriverHistoryProfileDocumentsData(this.driverId).subscribe({
                        next: (profile) => {
                            this.setDriverDocumentsInfo(profile, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileDocumentsData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshFollowUpDateInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileFollowUpDateData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    // refresh FollowUpDate, Actions, and Notes
                    let arrObs: Array<Observable<Object>> = [
                        this.getActionItemMenuData(this.driverId),
                        this.driverHistoryProfileService.getDriverHistoryProfileFollowUpDateData(this.driverId),
                        this.driverHistoryProfileService.getDriverHistoryProfileNotesData(this.driverId)
                    ];
                    this.loadingSpinnerService.show();
                    forkJoin(arrObs).subscribe({
                        next: (profile) => {
                            this.getActionMenuObjectArray(profile[0]);
                            this.setDriverFollowUpDate(profile[1] as DriverFollowUpDate, this.driverProfile);
                            this.setDriverNotesInfo(profile[2] as Array<DriverNotes>, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileFollowUpDateData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshCompletedTrainingInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileCompletedTrainingData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    this.loadingSpinnerService.show();
                    this.driverHistoryProfileService.getDriverHistoryProfileCompletedTrainingData(this.driverId).subscribe({
                        next: (profile) => {
                            this.setDriverCompletedTrainingInfo(profile, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileCompletedTrainingData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshIncompleteTrainingInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileIncompleteTrainingData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    this.loadingSpinnerService.show();
                    this.driverHistoryProfileService.getDriverHistoryProfileIncompleteTrainingData(this.driverId).subscribe({
                        next: (profile) => {
                            this.setDriverIncompleteTrainingInfo(profile, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileIncompleteTrainingData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshExternalTrainingInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileExternalTrainingData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    this.loadingSpinnerService.show();
                    this.driverHistoryProfileService.getDriverHistoryProfileExternalTrainingData(this.driverId).subscribe({
                        next: (profile) => {
                            this.setDriverExternalTrainingInfo(profile, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileExternalTrainingData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshHPEAssignmentInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileHPEAssignmentData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    this.loadingSpinnerService.show();
                    this.driverHistoryProfileService.getDriverHistoryProfileHPETrainingAssignmentsData(this.driverId).subscribe({
                        next: (profile) => {
                            this.setDriverHpeAssignments(profile, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileHPEAssignmentData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshMotorMindsAssignmentInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileMotorMindsAssignmentData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                const id = data;
                if (id === +this.driverId) {
                    this.loadingSpinnerService.show();
                    this.driverHistoryProfileService.getDriverHistoryProfileMotorMindsAssignmentsData(this.driverId).subscribe({
                        next: (mmdata) => {
                            this.setDriverMotorMindsAssignments(mmdata, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileMotorMindsAssignmentData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshTelematicsInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileTelematicsData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    this.loadingSpinnerService.show();
                    this.driverHistoryProfileService.getDriverHistoryProfileTelematicsData(this.driverId).subscribe({
                        next: (profile) => {
                            this.setDriverTelematicsInfo(profile, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileTelematicsData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshRiskHistoryInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileRiskHistoryData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    this.loadingSpinnerService.show();
                    this.driverHistoryProfileService.getDriverHistoryProfileRiskHistoryData(this.driverId).subscribe({
                        next: (profile) => {
                            this.setDriverRiskHistoryInfo(profile, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileRiskHistoryData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshRiskClaimsHistoryInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileRiskClaimHistoryData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    this.loadingSpinnerService.show();
                    this.driverHistoryProfileService.getDriverHistoryProfileRiskHistoryClaimsData(this.driverId).subscribe({
                        next: (profile) => {
                            this.setDriverRiskClaimHistoryInfo(profile, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileRiskClaimHistoryData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshAuxiliaryPointsInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileAuxiliaryPointsData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    // refresh Risk History, Points History, and Notes
                    let arrObs: Array<Observable<Object>> = [
                        this.driverHistoryProfileService.getDriverHistoryProfileRiskHistoryData(this.driverId),
                        this.driverHistoryProfileService.getDriverHistoryProfileAuxiliaryPointData(this.driverId),
                        this.driverHistoryProfileService.getDriverHistoryProfileNotesData(this.driverId)
                    ];
                    this.loadingSpinnerService.show();
                    forkJoin(arrObs).subscribe({
                        next: (profile) => {
                            this.setDriverRiskHistoryInfo(profile[0] as Array<RiskHistory>, this.driverProfile);
                            this.setDriverAuxiliaryPointInfo(profile[1] as Array<AuxiliaryPointHistory>, this.driverProfile);
                            this.setDriverNotesInfo(profile[2] as Array<DriverNotes>, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileAuxiliaryPointsData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshCurrentMVRInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileCurrentMVRData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    // get mvr current and mvr violations
                    this.loadingSpinnerService.show();
                    this.driverHistoryProfileService.getDriverHistoryProfileCurrentMVRData(this.driverId).subscribe({
                        next: (records) => {
                            this.setDriverCurrentMVRInfo(records, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileCurrentMVRData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshMVRHistoryInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileMVRHistoryData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    this.loadingSpinnerService.show();
                    this.driverHistoryProfileService.getDriverHistoryProfileMVRHistoryData(this.driverId).subscribe({
                        next: (history) => {
                            this.setDriverMVRHistoryInfo(history, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileMVRViolationsData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshDriverQualificationsInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileDriverQualificationsData$.subscribe({
            next: (data) => {
                let id = data as number;
                if (id == +this.driverId) {
                    // refresh Driver Qualifications and actions
                    let arrObs: Array<Observable<Object>> = [
                        this.driverHistoryProfileService.getDriverHistoryProfileDriverQualificationsData(this.driverId),
                        this.getActionItemMenuData(this.driverId)
                    ];
                    this.loadingSpinnerService.show();
                    forkJoin(arrObs).subscribe({
                        next: (qualifications) => {
                            this.setDriverDriverQualificationsInfo(qualifications[0] as Array<DriverQualifications>, this.driverProfile);
                            this.getActionMenuObjectArray(qualifications[1]);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileDriverQualificationsData(null);
                }
            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshSecondaryDriverInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileSecondaryDriverData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    this.loadingSpinnerService.show();
                    this.driverHistoryProfileService.getDriverHistoryProfileSecondaryDriverData(this.driverId).subscribe({
                        next: (profile) => {
                            this.setDriverSecondaryDriverInfo(profile, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileSecondaryDriverData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.refreshLeaveOfAbsenceInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileLeaveOfAbsenceData$.subscribe({
            next: (data) => {
                // if true, set resendEsigClicked to true to hide Resend Esig action,
                // and refresh Notes data
                let id = data as number;
                if (id == +this.driverId) {
                    // refresh Base, Action, and Notes data
                    let arrObs: Array<Observable<Object>> = [
                        this.driverHistoryProfileService.getDriverHistoryProfileBaseData(this.driverId),
                        this.getActionItemMenuData(this.driverId),
                        this.driverHistoryProfileService.getDriverHistoryProfileNotesData(this.driverId)
                    ];
                    forkJoin(arrObs).subscribe({
                        next: (profile) => {
                            this.setDriverBaseInfo(profile[0] as DriverProfile);
                            this.getActionMenuObjectArray(profile[1]);
                            this.setDriverNotesInfo(profile[2] as Array<DriverNotes>, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });

                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverProfileLeaveOfAbsenceData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

        this.resendESignEmailSub = this.driverHistoryProfileService.dhpResendESignEmail$.subscribe({
            next: (data) => {
                // if true, set resendEsigClicked to true to hide Resend Esig action,
                // and refresh Notes data
                let id = data as number;
                if (id == +this.driverId) {
                    // refresh Base, Action,  and Notes data
                    let arrObs: Array<Observable<Object>> = [
                        this.driverHistoryProfileService.getDriverHistoryProfileBaseData(this.driverId),
                        this.getActionItemMenuData(this.driverId),
                        this.driverHistoryProfileService.getDriverHistoryProfileNotesData(this.driverId)
                    ];
                    forkJoin(arrObs).subscribe({
                        next: (profile) => {
                            this.setDriverBaseInfo(profile[0] as DriverProfile);
                            this.getActionMenuObjectArray(profile[1]);
                            this.setDriverNotesInfo(profile[2] as Array<DriverNotes>, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });

                    // set action flag back to false
                    this.driverHistoryProfileService.setResendESignEmail(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });


        this.refreshDriverPendingTask = this.driverHistoryProfileService.dhpRefreshDriverPendingTasksData$.subscribe({
            next: (data) => {
                // will be true if refresh needed
                let id = data as number;
                if (id == +this.driverId) {
                    this.loadingSpinnerService.show();
                    this.driverHistoryProfileService.getDriverPendingTasksData(this.driverId).subscribe({
                        next: (pendingTask) => {
                            this.setDriverPendingTasks(pendingTask, this.driverProfile);
                            this.loadingSpinnerService.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.errorService.setErrorObject(err.error);
                            this.loadingSpinnerService.hide();
                        }
                    });
                    // set action flag back to false
                    this.driverHistoryProfileService.setRefreshDriverPendingTasksData(null);
                }

            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });
    }

    ngAfterViewInit(): void {
        if (this.driverId) {
            this.getActionItemMenuData(this.driverId)
        }
    }

    private getAllDriverProfileData(driverId: string) {
        // executes all services to update all profile data
        // updated 2019-03 to load base data first then all other data in callback
        let arrObs: Array<Observable<Object>> = [
            this.getDriverBaseInfoAndUserRightsAndClientSettings(driverId),
            this.driverHistoryProfileService.getProfileTabSettings(driverId, this.lineOfBusinessId),
            this.getActionItemMenuData(driverId)
        ]

        this.loadingSpinnerService.show();
        this.tabs = [];
        this.obsSubscription = forkJoin(arrObs).subscribe({
            next: (data) => {
                // driver Base Info
                let driverBaseInfo = data[0][0] as DriverProfile;



                if (driverBaseInfo.clientCode != this.clientSelectedArray[0]) {
                    // switch left nav client selection to client for driver
                    this.clientSelectionService.setAddClientsByShortName([driverBaseInfo.clientCode], this.lineOfBusinessId);
                    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusinessId);
                }
                // user rights
                let userRights = data[0][1] as Array<UserRightsInfo>;
                // client settings and req fields
                this.clientSettings = data[0][2] as DriverClientSettings;

                if (this.driverHistoryProfileService.inActiveDriverRedirect(driverBaseInfo, driverBaseInfo.clientCode, this.clientSettings.nonEmployeeProfiling)) {
                    return;
                }

                this.arrReqFields = data[0][3] as Array<DriverExtendedInfo>;
                this.setDriverExtendedInfo(this.arrReqFields, driverBaseInfo);
                // tab info
                let arrTabInfo = data[1] as Array<DriverProfileTabInfo>;
                // action label and driver actions are together in one data element
                let actionItemInfo = data[2];

                this.setUserRights(userRights);
                this.setDriverBaseInfo(driverBaseInfo);
                this.setCollectionLoadedFlags(this.driverProfile, false);
                this.getActionMenuObjectArray(actionItemInfo);
                this.getTabs(arrTabInfo);
                this.loadingSpinnerService.hide();
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileNotesData(driverId), this.driverProfile, this.setDriverNotesInfo);
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileDocumentsData(driverId), this.driverProfile, this.setDriverDocumentsInfo);
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileFollowUpDateData(driverId), this.driverProfile, this.setDriverFollowUpDate);
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileCompletedTrainingData(driverId), this.driverProfile, this.setDriverCompletedTrainingInfo);
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileIncompleteTrainingData(driverId), this.driverProfile, this.setDriverIncompleteTrainingInfo);
                if (this.clientSettings.hasExternalTraining) {
                    this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileExternalTrainingData(driverId), this.driverProfile, this.setDriverExternalTrainingInfo);
                }
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileTelematicsData(driverId), this.driverProfile, this.setDriverTelematicsInfo);
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileRiskHistoryData(driverId), this.driverProfile, this.setDriverRiskHistoryInfo);
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileRiskHistoryClaimsData(driverId), this.driverProfile, this.setDriverRiskClaimHistoryInfo);
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileAuxiliaryPointData(driverId), this.driverProfile, this.setDriverAuxiliaryPointInfo);
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileCurrentMVRData(driverId), this.driverProfile, this.setDriverCurrentMVRInfo);
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileMVRHistoryData(driverId), this.driverProfile, this.setDriverMVRHistoryInfo);
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileDriverQualificationsData(driverId), this.driverProfile, this.setDriverDriverQualificationsInfo);
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileSecondaryDriverData(driverId), this.driverProfile, this.setDriverSecondaryDriverInfo);
                this.loadData(this.driverHistoryProfileService.getDriverPendingTasksData(driverId), this.driverProfile, this.setDriverPendingTasks);
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileHPETrainingAssignmentsData(driverId), this.driverProfile, this.setDriverHpeAssignments);
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileMotorMindsAssignmentsData(driverId), this.driverProfile, this.setDriverMotorMindsAssignments);
                this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileEmploymentHistory(driverId), this.driverProfile, this.setDriverEmploymentHistoryInfo);
                this.loadData(this.driverHistoryProfileService.getDriverAutoCoverage(driverId), this.driverProfile, this.setDriverAutoCoverage);
            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });

    }

    private loadData(obs: Observable<Object>, driverProfile: DriverProfile, callback: (data: any, driverProfile: DriverProfile) => void) {
        obs.subscribe({
            next: (data) => {
                if (callback) {
                    callback(data, driverProfile);
                }
            },
            error: (err: HttpErrorResponse) => {
                if (err.status != 404)
                    this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        });
    }

    private setCollectionLoadedFlags(driverProfile: DriverProfile, loadedFlag: boolean) {
        // sets all the collection ..Loaded properties in driverProfile to loadedFlag
        driverProfile.currentMvrLoaded = loadedFlag;
        driverProfile.mvrHistoryLoaded = loadedFlag;
        driverProfile.mvrViolationsLoaded = loadedFlag;
        driverProfile.driverDocumentsLoaded = loadedFlag;
        driverProfile.driverNotesLoaded = loadedFlag;
        driverProfile.completedTrainingLoaded = loadedFlag;
        driverProfile.incompleteTrainingLoaded = loadedFlag;
        driverProfile.driverTelematicsLoaded = loadedFlag;
        driverProfile.riskHistoryLoaded = loadedFlag;
        driverProfile.riskClaimHistoryLoaded = loadedFlag;
        driverProfile.auxiliaryPointHistoryLoaded = loadedFlag;
        driverProfile.driverQualificationsLoaded = loadedFlag;
        driverProfile.secondaryDriversLoaded = loadedFlag;
        driverProfile.driverEmploymentHistoryLoaded = loadedFlag;
        driverProfile.hpeAssignmentsLoaded = loadedFlag;
        driverProfile.hpeCoreCompetenciesLoaded = loadedFlag;
        driverProfile.hpeSubLessonsLoaded = loadedFlag;
        driverProfile.pendingTasksLoaded = loadedFlag;
    }

    private getDriverProfileBaseInfo(driverId: string) {
        return this.driverHistoryProfileService.getDriverHistoryProfileBaseData(driverId);
    }

    private getDriverBaseInfoAndUserRightsAndClientSettings(driverId: string) {
        // gets base driver info first, then user rights
        // based on the client for the driver
        return this.getDriverProfileBaseInfo(driverId).pipe(
            switchMap(res => {
                // get user rights after driver base data, then
                // combine into on observable

                return zip(
                    of(res),
                    res && res.clientCode ? this.userRightsService.getUserRights(this.lineOfBusinessId, [res.clientCode]) : of([]),
                    res && res.clientCode ? this.driverHistoryProfileService.getClientSettings(res.clientCode) : of(null),
                    res && res.clientCode ? this.driverHistoryProfileService.getDriverHistoryProfileExtendedData(res.clientCode) : of([]),
                );
            })
        );

    }

    private getActionItemMenuLabelData() {
        return this.driverHistoryProfileService.getDriverHistoryProfileActionMenuLabels(true);
    }

    private getActionItemMenuData(driverId: string) {
        // gets action item menu info, first labels then items
        // that are applicable to the current driver
        return this.getActionItemMenuLabelData().pipe(
            switchMap(res => {
                // get driver items after label data, then
                // combine into on observable

                return zip(
                    res ? of(res) : of([]),
                    res ? this.driverHistoryProfileService.getDriverHistoryProfileActionMenuItems(driverId, true) : of([])
                );
            })
        );
    }

    private getActionMenuObjectArray(menuData: any) {
        let arrCategory: Array<DriverActionMenuLabel> = menuData[0] as Array<DriverActionMenuLabel>;
        let arrItem: Array<DriverActionMenuItem> = menuData[1] as Array<DriverActionMenuItem>;

        let arrObject = [];

        arrCategory.forEach(c => {

            let label = c.description;
            let catId = c.id;
            let items = arrItem.filter(i => (i.secondaryId == c.id));
            arrObject.push({ catId: catId, label: label, items: items });

        })
        this.actionMenuObjectArray = arrObject.reverse();
    }

    actionMenuItemClick(itemId: number) {
        this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusinessId);
        switch (itemId) {
            case 1:
                this.notifyModal('Assign Points', 'assignPoints', '580px;', 'lg', { driverId: this.driverId, clientCode: this.driverProfile.clientCode });
                break;
            case 2:
                this.openEmployeeDriverForm(null);
                break;
            case 3:
                this.notifyModal('Reset Password', 'resetPassword', '180px;', 'sm', { driverId: this.driverId });
                break;
            case 4:
                this.notifyModal('Resend E-Sig Email', 'resendESignEmail', '200px;', 'sm', { driverId: this.driverId });
                break;
            case 5:
                // assign follow up date
                this.notifyModal('Follow-Up Date', 'assignFollowUpDate', '460px;', 'lg', { driverId: this.driverId, followUpDate: this.driverProfile.followUpDate });
                break;
            case 6:
                this.openEmployeeDriverForm('Training');
                break;
            case 7:
                this.notifyModal('Request MVR', 'requestMVRReport', '180px;', 'sm', { driverId: this.driverId, client: this.clientSelectedArray.join(',') });
                break;
            case 8:
                this.openEmployeeDriverForm('Services');
                break;
            case 9:
                this.notifyModal('Cancel License Verification', 'cancelLicenseVerification', '180px;', 'md', { driverId: this.driverId });
                break;
            case 10:
                this.exportPdf();
                break;
            case 11:
                this.onPrint();
                break;
            case 12:
                // schedule LOA
                this.notifyModal('Leave of Absence', 'dhpLeaveOfAbsence', '420px;', 'md', { driverId: this.driverId });
                break;
            case 13:
                this.notifyModal('Terminate Driver', 'terminateDriver', '180px;', 'sm', { driverId: this.driverId })
                break;
            case 14:
                this.notifyModal('Reset Account', 'resetAccount', '180px;', 'sm', { driverId: this.driverId });
                break;
            case 16:
                this.notifyModal('Cancel Leave of Absence', 'cancelLeaveOfAbsence', '200px;', 'md', { driverId: this.driverId })
                break;
            case 17:
                // edit follow up date
                this.notifyModal('Edit Follow-Up Date', 'assignFollowUpDate', '460px;', 'lg', { driverId: this.driverId, followUpDate: this.driverProfile.followUpDate });
                break;
            case 18:
                // remove follow-up date
                this.notifyModal('Remove Follow-Up Date', 'removeFollowUpDate', '180px;', 'sm', { driverId: this.driverId });
                break;
            case 19:
                this.notifyModal('Resend Login', 'resendLogin', '180px', 'sm', { driverId: this.driverId });
                break;
            case 24:
                // Enroll MVR Monitoring
                this.notifyModal('Enroll In MVR Monitoring', 'mvrMonitoringEnroll', '180px;', 'md', { driverId: this.driverId });
                break;
            case 25:
                // Unenroll MVR Monitoring
                this.notifyModal('Unenroll In MVR Monitoring', 'mvrMonitoringUnenroll', '200px;', 'md', { driverId: this.driverId });
                break;
            case 26:
                // COV signoff
                this.notifyModal('Certificate of Violation Signoff', 'covSignoff', '80vh', 'lg', { driverId: this.driverId, fromDriverProfile: true });
                break;
        }

    }

    private setDriverBaseInfo(data: DriverProfile) {
        // copy all properties that are retrieved from other services
        let driverNotesInfo = this.driverProfile ? this.driverProfile.driverNotes : null;
        let driverDocumentsInfo = this.driverProfile ? this.driverProfile.driverDocuments : null;
        let followUpDateInfo = this.driverProfile ? this.driverProfile.followUpDate : null;
        let completedTrainingInfo = this.driverProfile ? this.driverProfile.completedTraining : null;
        let incompleteTrainingInfo = this.driverProfile ? this.driverProfile.incompleteTraining : null;
        let driverTelematicsInfo = this.driverProfile ? this.driverProfile.driverTelematics : null;
        let riskHistoryInfo = this.driverProfile ? this.driverProfile.riskHistory : null;
        let riskClaimHistoryInfo = this.driverProfile ? this.driverProfile.riskClaimHistory : null;
        let auxiliaryPointHistoryInfo = this.driverProfile ? this.driverProfile.auxiliaryPointHistory : null;
        let currentMvrInfo = this.driverProfile ? this.driverProfile.currentMvr : null;
        let mvrHistoryInfo = this.driverProfile ? this.driverProfile.mvrHistory : null;
        let mvrViolationsInfo = this.driverProfile ? this.driverProfile.mvrViolations : null;
        let driverQualificationsInfo = this.driverProfile ? this.driverProfile.driverQualifications : null;
        let secondaryDriversInfo = this.driverProfile ? this.driverProfile.secondaryDrivers : null;
        let hpeAssignmentsInfo = this.driverProfile ? this.driverProfile.hpeAssignments : null;
        let employmentHistoryInfo = this.driverProfile ? this.driverProfile.driverEmploymentHistory : null;
        let autoCoverage = this.driverProfile ? this.driverProfile.driverAutoCoverage : null;
        let pendingTasks = this.driverProfile ? this.driverProfile.pendingTasks : null;

        // set base data
        this.driverProfile = data as DriverProfile;

        // add back copied properties
        this.setDriverNotesInfo(driverNotesInfo, this.driverProfile);
        this.setDriverDocumentsInfo(driverDocumentsInfo, this.driverProfile);
        this.driverProfile.followUpDate = followUpDateInfo;
        this.setDriverCompletedTrainingInfo(completedTrainingInfo, this.driverProfile);
        this.setDriverIncompleteTrainingInfo(incompleteTrainingInfo, this.driverProfile);
        this.setDriverTelematicsInfo(driverTelematicsInfo, this.driverProfile);
        this.setDriverRiskHistoryInfo(riskHistoryInfo, this.driverProfile);
        this.setDriverRiskClaimHistoryInfo(riskClaimHistoryInfo, this.driverProfile);
        this.setDriverAuxiliaryPointInfo(auxiliaryPointHistoryInfo, this.driverProfile);
        this.setDriverCurrentMVRInfo(currentMvrInfo, this.driverProfile);
        this.setDriverMVRHistoryInfo(mvrHistoryInfo, this.driverProfile);
        this.setDriverDriverQualificationsInfo(driverQualificationsInfo, this.driverProfile);
        this.setDriverSecondaryDriverInfo(secondaryDriversInfo, this.driverProfile);
        this.setDriverPendingTasks(pendingTasks, this.driverProfile);
        this.setDriverHpeAssignments(hpeAssignmentsInfo, this.driverProfile);
        this.setDriverEmploymentHistoryInfo(employmentHistoryInfo, this.driverProfile);
        this.setDriverAutoCoverage(autoCoverage, this.driverProfile);

    }

    private setDriverExtendedInfo(data: Array<DriverExtendedInfo>, driverProfile: DriverProfile) {

        let arrInfo = [];
        this.arrReqFields.forEach(e => {
            let fieldVal = driverProfile[e.reqField.toLowerCase()];
            arrInfo.push({ label: e.reqDescription.trim(), value: (fieldVal || '') });
        });
        this.extendedInfo = arrInfo;
    }

    private setDriverNotesInfo = (function (data: Array<DriverNotes>, driverProfile: DriverProfile) {
        driverProfile.driverNotes = data;
        driverProfile.driverNotesLoaded = true;
    }).bind(this);

    private setDriverDocumentsInfo = (function (data: Array<DriverDocuments>, driverProfile: DriverProfile) {
        driverProfile.driverDocuments = data;
        driverProfile.driverDocumentsLoaded = true;
    }).bind(this);

    private setDriverFollowUpDate = (function (data: DriverFollowUpDate, driverProfile: DriverProfile) {
        driverProfile.followUpDate = data ? data.followUpDate : null;
    }).bind(this);

    private setDriverCompletedTrainingInfo = (function (data: Array<DriverTraining>, driverProfile: DriverProfile) {
        driverProfile.completedTraining = data || [];
        driverProfile.completedTrainingLoaded = true;
    }).bind(this);

    private setDriverIncompleteTrainingInfo = (function (data: Array<DriverTraining>, driverProfile: DriverProfile) {
        driverProfile.incompleteTraining = data || [];
        // add icon to tab heading if incomplete training
        let trainingTab = this.tabs.find(t => t.name == 'Training');
        if (trainingTab) {
            if (driverProfile.incompleteTraining.length > 0) {
                trainingTab.icon = ' <i class="fa fa-exclamation-triangle"></i>'
            } else {
                trainingTab.icon = '';
            }

        }

        driverProfile.incompleteTrainingLoaded = true;
    }).bind(this);

    private setDriverExternalTrainingInfo = (function (data: Array<DriverTraining>, driverProfile: DriverProfile) {
        driverProfile.externalTraining = data || [];
        driverProfile.externalTrainingLoaded = true;
    }).bind(this);

    private setDriverTelematicsInfo = (function (data: Array<DriverTelematics>, driverProfile: DriverProfile) {
        driverProfile.driverTelematics = data || [];
        driverProfile.driverTelematicsLoaded = true;
    }).bind(this);

    private setDriverRiskHistoryInfo = (function (data: Array<RiskHistory>, driverProfile: DriverProfile) {
        driverProfile.riskHistory = data;
        driverProfile.riskHistoryLoaded = true;
    }).bind(this);

    private setDriverRiskClaimHistoryInfo = (function (data: Array<RiskClaimHistory>, driverProfile: DriverProfile) {
        driverProfile.riskClaimHistory = data || [];
        driverProfile.riskClaimHistoryLoaded = true;
    }).bind(this);

    private setDriverAuxiliaryPointInfo = (function (data: Array<AuxiliaryPointHistory>, driverProfile: DriverProfile) {
        driverProfile.auxiliaryPointHistory = data || [];
        driverProfile.auxiliaryPointHistoryLoaded = true;
    }).bind(this);

    private setDriverCurrentMVRInfo = (function (data: MVRRecordsCurrent, driverProfile: DriverProfile) {
        driverProfile.currentMvr = data || {
            mvrId: "",
            driverGuid: "",
            completionDate: "",
            violations: [],
            licenseNumber: "",
            licenseState: "",
            licenseStatus: "",
            licenseClass: "",
            requestedUserName: "",
            currentMvrStatus: "",
            mvrSource: ""
        };

        driverProfile.currentMvr.violations = driverProfile.currentMvr.violations || [];
        driverProfile.currentMvrLoaded = true;
    }).bind(this);

    private setDriverMVRHistoryInfo = (function (data: Array<MVRHistory>, driverProfile: DriverProfile) {
        driverProfile.mvrHistory = data;
        driverProfile.mvrHistoryLoaded = true;
        const alertIcon = ' <i class="fa fa-exclamation-triangle"></i>';
        if (data != null) {
            const mvrRecordsTab = this.tabs.find(t => t.name === 'MVR Records')
            if (this.driverProfile.mvrHistory != null) {
                mvrRecordsTab.icon = (this.driverProfile?.mvrHistory[0]?.licenseStatus.toLowerCase().includes('error') ? alertIcon : '');
                switch (this.driverProfile?.mvrHistory[0]?.licenseStatus.toLowerCase()) {
                    case 'revoked':
                    case 'suspended':
                    case 'expired':
                    case 'cancelled':
                    case 'surrender':
                    case 'review determined invalid':
                        mvrRecordsTab.icon = alertIcon;
                        break;
                }
                switch (this.driverProfile?.mvrTaskStatus?.toLowerCase()) {
                    case 'required form missing':
                    case 'waiting for license verification':
                    case 'waiting for mvr from state':
                    case 'waiting for e-signature verification':
                    case 'pending driver enrollment':
                    case 'waiting for icraa signature':
                    case 'wating for state document':
                    case 'pending state release form':
                    case 'waiting for state results':
                        mvrRecordsTab.icon = alertIcon;
                        break;
                }
            }
        }
    }
    ).bind(this);

    private setDriverDriverQualificationsInfo = (function (data: Array<DriverQualifications>, driverProfile: DriverProfile) {
        driverProfile.driverQualifications = data;
        driverProfile.driverQualificationsLoaded = true;
    }).bind(this);

    private setDriverSecondaryDriverInfo = (function (data: Array<DriverProfile>, driverProfile: DriverProfile) {
        if (data) {
            driverProfile.secondaryDrivers = data.sort((a: DriverProfile, b: DriverProfile) => {
                // sort active then inactive
                return a.isActive ? -1 : 1;
            });
        }

        driverProfile.secondaryDriversLoaded = true;
        // add badge to Non-Employees tab showing number of drivers
        let numDrivers = (driverProfile.secondaryDrivers ? driverProfile.secondaryDrivers.filter(s => s.isActive).length : 0);
        if (numDrivers > 0) {
            let headingIcon = ' <span class="fa-stack fa-sm"><i class="fa fa-circle fa-stack-2x headingCircle"></i><span class="fa-stack-1x headingNumber">' + numDrivers.toString() + '</span></span>';
            this.setTabHeading('Non-Employees', headingIcon);
        }

    }).bind(this);

    //Set Driver Pending Tasks
    private setDriverPendingTasks = (function (data: Array<DriverPendingTask>, driverProfile: DriverProfile) {
        driverProfile.pendingTasks = data;
        driverProfile.pendingTasksLoaded = true;

        let numPendingTasks = (driverProfile.pendingTasks ? driverProfile.pendingTasks.length : 0);

        if (numPendingTasks > 0) {
            let headingIcon = ' <span class="fa-stack fa-sm"><i class="fa fa-circle fa-stack-2x headingCircle"></i><span class="fa-stack-1x headingNumber">' + numPendingTasks.toString() + '</span></span>';
            this.setTabHeading('Pending Tasks', headingIcon);
        }
    }).bind(this);


    private setDriverHpeAssignments = (function (data: Array<DriverHPEAssignment>, driverProfile: DriverProfile) {
        driverProfile.hpeAssignments = data;
        driverProfile.hpeAssignmentsLoaded = true;
        this.getHPECoreCompetencies(this.driverProfile.hpeAssignments, driverProfile);
    }).bind(this);

    private getHPECoreCompetencies(data: Array<DriverHPEAssignment>, driverProfile: DriverProfile) {
        if (data && (data.length > 0)) {
            this.driverHistoryProfileService.getDriverHistoryProfileHPECoreCompetenciesData(driverProfile.driverId.toString(), data[0].assignmentId.toString()).subscribe(data => {
                driverProfile.hpeCoreCompetencies = data;
                driverProfile.hpeCoreCompetenciesLoaded = true;
            });

            this.driverHistoryProfileService.getDriverHistoryProfileHPESubLessonsData(driverProfile.driverId.toString(), data[0].assignmentId.toString()).subscribe(data => {
                driverProfile.hpeSubLessons = data;
                driverProfile.hpeSubLessonsLoaded = true;
            });
        }
    }

    private readonly setDriverMotorMindsAssignments = (function (data: Array<DriverMMTraining>, driverProfile: DriverProfile) {
        driverProfile.MotorMindsAssignments = data;
        driverProfile.motormindsAssignmentsLoaded = true;
    }).bind(this);

    private setDriverEmploymentHistoryInfo = (function (data: Array<DriverEmploymentHistory>, driverProfile: DriverProfile) {
        driverProfile.driverEmploymentHistory = data;
    }).bind(this);

    private setDriverAutoCoverage = (function (data: DriverAutoCoverage, driverProfile: DriverProfile) {
        driverProfile.driverAutoCoverage = [];
        driverProfile.driverAutoCoverage.push(data);
        let autoCoverageTab = this.tabs.find(t => t.name == 'Auto Coverage');
        if (autoCoverageTab) {
            if (data.status === "Pending Driver Action" || data.status === "Awaiting Review") {
                autoCoverageTab.icon = ' <i class="fa fa-exclamation-triangle"></i>'
            } else {
                autoCoverageTab.icon = '';
            }
        }

    }).bind(this);

    private setUserRights(data: Array<UserRightsInfo>) {
        this._userRights = data as Array<UserRightsInfo>;
        this.canViewDLDOB = this.isAccessAllowed(42);
    }

    private getSecondaryDriverData(driverProfile: DriverProfile) {
        // 2019-05 - this function no longer used, secondsary driver detail
        // to be loaded in safety-additional-driver-not-full component
        // if isEmployee and nonEmployeeProfiling is false,
        // need to get the following for each secondary Driver:
        // currentMvr, mvrRecords, notes, documents
        if (this.driverProfile.isEmployee && !this.clientSettings.nonEmployeeProfiling) {
            this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileNotesData(driverProfile.driverId.toString()), driverProfile, this.setDriverNotesInfo);
            this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileDocumentsData(driverProfile.driverId.toString()), driverProfile, this.setDriverDocumentsInfo);
            this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileCurrentMVRData(driverProfile.driverId.toString()), driverProfile, this.setDriverCurrentMVRInfo);
            this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileMVRHistoryData(driverProfile.driverId.toString()), driverProfile, this.setDriverMVRHistoryInfo);
        }

    }

    private getTabs(arrTabInfo: Array<DriverProfileTabInfo>) {
        // build array of tabs
        let arrTab = [];
        let alertIcon = ' <i class="fa fa-exclamation-triangle"></i>';
        arrTabInfo.forEach(tabInfo => {
            // name: text to appear in tab
            // icon: html for alert icon
            // show: render the tab, condition should be calculated BEFORE getTabs is called
            // display: show or hide the tab, for conditions calculated AFTER getTabs is called
            let tab = { name: tabInfo.description, icon: '', show: true, display: true };
            switch (tab.name) {
                case 'MVR Records':
                    tab.icon = (this.driverProfile.isMvrTaskPending ? alertIcon : '');
                    break;
                case 'Driver Info':
                    tab.icon = (this.driverProfile.isLicenseVerificationPending ? alertIcon : '');
                    break;
            }

            arrTab.push(tab);
        });

        this.tabs = arrTab;
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
        this.obsSubscription.unsubscribe();
        this.refreshAllInfoSub.unsubscribe();
        this.refreshBaseInfoSub.unsubscribe();
        this.refreshNotesInfoSub.unsubscribe();
        this.refreshDocumentsInfoSub.unsubscribe();
        this.refreshFollowUpDateInfoSub.unsubscribe();
        this.refreshCompletedTrainingInfoSub.unsubscribe();
        this.refreshIncompleteTrainingInfoSub.unsubscribe();
        this.refreshExternalTrainingInfoSub.unsubscribe();
        this.refreshHPEAssignmentInfoSub.unsubscribe();
        this.refreshTelematicsInfoSub.unsubscribe();
        this.refreshRiskHistoryInfoSub.unsubscribe();
        this.refreshRiskClaimsHistoryInfoSub.unsubscribe();
        this.refreshAuxiliaryPointsInfoSub.unsubscribe();
        this.refreshCurrentMVRInfoSub.unsubscribe();
        this.refreshMVRHistoryInfoSub.unsubscribe();
        this.refreshDriverQualificationsInfoSub.unsubscribe();
        this.refreshSecondaryDriverInfoSub.unsubscribe();
        this.refreshLeaveOfAbsenceInfoSub.unsubscribe();
        this.resendESignEmailSub.unsubscribe();
        this.refreshDriverPendingTask.unsubscribe();
    }

    // notifies the modal of changes
    notifyModal(title: string, message: string, modalHeight: string, modalWidth: string, modalInput?: any) {
        this.driverHistoryProfileService.notifyModal(title, message, modalHeight, modalWidth, modalInput);
    }

    // opens the employee driver form
    openEmployeeDriverForm(openToSection: string) {
        let rt = (this.driverProfile.isEmployee ? 'employeedriverform' : 'nonemployeedriverform');
        let queryParams: any = { returnToProfileId: this.driverProfile.driverId };
        if (openToSection) {
            queryParams.openToSection = openToSection;
        }
        this.router.navigate(['/safety/' + rt + '/' + this.driverProfile.driverId], { queryParams: queryParams });
    }

    // determines if access to the component is allowed
    isAccessAllowed(userRightsId: number) {
        let retVal: boolean = false;

        if (this._userRights) {
            let thisRight = this._userRights.filter(r => r.userRightId == userRightsId);
            if ((thisRight.length > 0) && (thisRight[0].permission == 'allow')) {
                retVal = true;
            }
        }

        return retVal;
    }

    private hideTab(tabName: string) {
        //sets the display attribute in tab to false,
        // this will result in a customClass being added to hide the tab
        this.tabs.filter(t => t.name == tabName).forEach(t => { t.display = false });
    }

    private setTabHeading(tabName: string, txt: string) {
        this.tabs.filter(t => t.name == tabName).forEach(t => { t.icon = txt });
    }

    exportPdf() {
        this.fileDownloadService
            .saveFile(this.baseUrl + 'api/Safety/GetDriverPdf?DriverId=' + this.driverId);
    }

    onPrint() {
        this.fileDownloadService.openTab(this.baseUrl + "api/Safety/PrintDriverProfile?DriverId=" + this.driverId);
    }
}

@Pipe({
    name: 'showdptabspipe',
})
export class ShowDpTabsPipe {

    transform(objects: any[]): any[] {
        if (objects) {
            return objects.filter(object => object.show);
        }
    }

}
