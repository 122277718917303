<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" >
  <div class="col-md-6">
    <h4 class="card-title text-muted" style="font-size:1.5rem;margin-top:12px">Motor Vehicle Record</h4>
  </div>
  <div class="col-md-6">

    <div style="float:right">
      <button type="button"
              (click)="exitMvr()"
              class="btn btn-primary">
        Exit
      </button>
    </div>
    <div style="float:right">
      <button type="button" (click)="exportPdf()"
              class="btn btn-primary">
        Export to PDF
      </button>
    </div>
    <div style="float:right">
      <button type="button" (click)="print()"
              class="btn btn-primary">
        Print
      </button>
    </div>
  </div>
</div>
<div class="row" id="mvrContent" style="width: 100% !important">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <div class="row">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Name:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driver?.driverName}}
        </div>
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Employee ID:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driver?.employeeId}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Company Hierarchy:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driver?.fullHierarchy}}
        </div>
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Date of Birth:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driver?.dateOfBirth |  date:'MM/dd/yyyy'}}
        </div>
      </div>      
      <div class="row">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          MVR Request ID:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driver?.mvrRequestId}}
        </div>
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          MVR Request Date:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.requestedDate |  date:'MM/dd/yyyy'}}
        </div>
      </div>             
      <div class="row">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Company:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.company }}
        </div>
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          MVR Complete Date:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.completeDate |  date:'MM/dd/yyyy'}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Invalid License Notice:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driver?.invalidLicenseNotice }}
        </div>
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Total Org Points:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driver?.totalOrgPoints }}
        </div>
      </div>   
      <div class="row">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          MVR Requested By:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.requestedBy}}          
        </div>
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">

        </div>
      </div>            
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h4 class="card-title text-muted">Driver Information</h4>
      <div class="row">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Name:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driver?.driverName}}
        </div>
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Employee ID:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driver?.employeeId}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Company Hierarchy:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driver?.fullHierarchy}}
        </div>
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          State:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driverLicense?.licenseState}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Eye Color:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driver?.eyeColor}}
        </div>
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Weight:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driver?.weight}}
        </div>
      </div>   
      <div class="row">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Hair Color:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driver?.hairColor}}
        </div>
        <div class="col-sm-2 readOnlyFormLayoutLabel">

        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">

        </div>
      </div>          
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h4 class="card-title text-muted">Driver License Information</h4>
      <div class="row">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          License Number:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driverLicense?.licenseNumber}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Date Issued:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driverLicense?.issueDate |  date:'MM/dd/yyyy'}}
        </div>
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Date Expires:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driverLicense?.expirationDate |  date:'MM/dd/yyyy'}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          License Class:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driverLicense?.licenseClass}}
        </div>
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          License Status:
        </div>
        <div class="col-sm-4 readOnlyFormLayoutBold">
          {{mvrReport?.driverLicense?.licenseStatus}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Restrictions & Endorsements:
        </div>
        <div class="col-sm-10 readOnlyFormLayoutBold">
          {{mvrReport?.driverLicense?.restrictionsAndEndorsements}}
        </div>
      </div>
      <div class="row legendRow">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Legends:
        </div>
        <div class="col-sm-10 readOnlyFormLayoutBold">
          {{mvrReport?.licenseLegend}}
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h4 class="card-title text-muted">Driver Record Information</h4>
      <div *ngIf="driverRecordTableConfig">
        <app-data-table
          [columns]="driverRecordTableConfig.fields" 
          [data]="driverRecordTableConfig.data"
          [expandable]="driverRecordTableConfig?.expandable"
          [expand]="driverRecordTableConfig?.expand"
          [noRecordsLabel]="driverRecordTableConfig?.noRecordsLabel"
          [withFooter]="driverRecordTableConfig?.withFooter"
          [footerObject]="driverRecordTableConfig?.footerObject"
        ></app-data-table>
      </div>
      <div class="row">
        <div class="col-sm-2 readOnlyFormLayoutLabel">
          Legends:
        </div>
        <div class="col-sm-10 readOnlyFormLayoutBold">
          {{mvrReport?.driverRecordLegend}}
        </div>
      </div>
    </div>
  </div>
</div>
<dhp-mvr-report-endorsements></dhp-mvr-report-endorsements>
<dhp-mvr-report-license-information></dhp-mvr-report-license-information>
<dhp-mvr-report-orders></dhp-mvr-report-orders>
<dhp-mvr-address-information></dhp-mvr-address-information>
<dhp-mvr-report-previous-license-info></dhp-mvr-report-previous-license-info>
<dhp-mvr-report-return-info></dhp-mvr-report-return-info>
<dhp-mvr-report-additional-messages></dhp-mvr-report-additional-messages>
<dhp-mvr-report-medical-cert></dhp-mvr-report-medical-cert>
<div class="row">
  <div class="col-sm-12">
    <div class="card card-body mt-3">
      <h4 class="card-title text-muted">Disclaimer</h4>
      <div class="row">
        <div class="col-sm-12">
          {{mvrReport?.disclaimer}}
        </div>
      </div>
    </div>
  </div>
</div>
