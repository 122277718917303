import { Component, Inject, Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable ,  Subject } from "rxjs";


@Injectable()
export class CustomReportGridService {
  baseUrl: string;

  private dataGridRequest = new Subject();
  dataGridRequest$ = this.dataGridRequest.asObservable();


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }


  updateGridRequest(request: CustomReportGridRequest) {
    this.dataGridRequest.next(request);
  }

  getData(request: CustomReportGridRequest): Promise<GridData> {

    this.dataGridRequest.next(request);

    return lastValueFrom(this.http.post(this.baseUrl + 'api/CustomReport/GetReportData',
      JSON.stringify(request),
      {
        headers: { 'Content-Type': 'application/json' }
      }))
      .then(result => {
        return result as GridData;
      }).catch();
  }

 
}


export interface Column {
  name: string;
  prop: string;
}

export interface GridData {
  Columns: Column[];
  Data: any;
}

@Injectable()
export class CustomReportGridRequest {
  Clients: string;
  ReportId: number;
  StartDate: Date;
  EndDate: Date;
  DateField: string;
  DateFieldDisplayValue: string;
  ReportName: string;

  dummy: string;
  SortDisplayField: string;
  SortField: string;
  SortDir: string;
  public reset() {
    this.SortDir = "";
    this.SortField = "";
    this.SortDisplayField = "";
  }
}
