import { Component } from '@angular/core';
import { OrdersAndAssignmentsReportModalService } from "../orders-and-assignments-report-modal/orders-and-assignments-report-modal-service.component";

@Component({
  selector: 'app-orders-and-assignments-report-menu-item',
  templateUrl: './orders-and-assignments-report-menu-item.component.html'
})
export class OrdersAndAssignmentsReportMenuItemComponent {

  constructor(private ordAssignReportService: OrdersAndAssignmentsReportModalService) { }

  showOrdAssignReportModal() {
    this.ordAssignReportService.openOrdAssignReportSearchModal();
  }
}
