import { PreviousLicenseInfo, MVRReport } from './../../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DHPMVRReportService } from '../dhp-mvr-report-service.component';

@Component({
  selector: 'dhp-mvr-report-previous-license-info',
  templateUrl: './dhp-mvr-report-previous-license-info.component.html',
  styleUrls: ['../../dhp-mvr-report/dhp-mvr-report.component.css']
})
export class DhpMvrReportPreviousLicenseInfoComponent implements OnInit {
  mvrReport: MVRReport;
  public previousLicenseInfoTableConfig: any;

  constructor(private readonly datePipe: DatePipe,
    private readonly dhpMVRReportService: DHPMVRReportService) { }
    gtOptions: any = {};

  ngOnInit(): void {
    this.dhpMVRReportService.sharedMvrReport.subscribe(mvrData => { 
      if(mvrData != null ) {
        this.mvrReport = mvrData;
        const previousLicenseArray = (this.mvrReport.previousLicenseInfo != null) ?  this.mvrReport.previousLicenseInfo : [];
        this.setPreviousLicenseInfoTableConfig(previousLicenseArray);
      }
    });
  }

  private setPreviousLicenseInfoTableConfig(previousLicenseRecords: Array<PreviousLicenseInfo>) {
    const columns = [
      { name: "MVR ID", prop: "mvR_ID", title: "MVR ID", visible: true, dataType: null, columnClass: "width15"  },
      { name: "Previous License ID", prop: "previousLicense_ID", title: "Previous License ID", visible: true, dataType: null, columnClass: ""  },
      { name: "Prev Number", prop: "previousNumber", title: "Prev Number", visible: true, dataType: null, columnClass: ""  },
      { name: "Prev State", prop: "previousState", title: "Prev State", visible: true, dataType: null, columnClass: ""  },                  
      { name: "Prev Class", prop: "previousClass", title: "Prev Class", visible: true, dataType: null, columnClass: ""  },      
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    const fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s.dataType,
        hidden: !s.visible,
        columnClass: (row, col) => {
          return s.columnClass;
        },
        render: row => {
          if (s.dataType === "date") {
            return this.datePipe.transform(row[s.prop], 'MM/dd/yyyy');
          } else {
            return row[s.prop];
          }
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.previousLicenseInfoTableConfig = {
      settings: settings,
      fields: fields,
      data: previousLicenseRecords,
      noRecordsLabel: 'No Previous License Records'
    }
    this.gtOptions = (previousLicenseRecords.length > 1 ? { numberOfRows: previousLicenseRecords.length } : {});
  }
}
