import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DocumentLibraryService } from '../../shared/document-library/document-library.service';
import { AzureDocument } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';

@Injectable({
  providedIn: 'root'
})
export class AddDocumentService {
  documentUploadAction$ = new BehaviorSubject(null);

  constructor(private documentLibraryService: DocumentLibraryService,
    private errorService: ErrorModalService){}


  hideDoc(documentId: number): Observable<any> {
    return this.documentLibraryService.hideDocument(documentId);
  }

  deleteDoc(documentId: number): Observable<any> {
    return this.documentLibraryService.deleteDocument(documentId);
  }

showDoc(documentId: number): Observable<any> {
    return this.documentLibraryService.showDocument(documentId)
  }  
}
