<div class="row">
  <div class="col-md-4">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted wrap-this">Select Drivers From:</h4>
          <div class="row">
            <div class="col-md-12">
              <mat-tab-group class="tabs-container">
                <mat-tab label="Corporate Structure"> 
                  <app-corporate-structure-selector-folder
                    [selectorId]="'ch_' +selectorPrefix"
                    [chType]="'MvrExport'"
                    [arrCHInfo]="corporateHierarchy"
                    [clientSelectedArray]="clientSelected"
                    (onSelectionsProcessed)="updateFilterCriteria()">
                  </app-corporate-structure-selector-folder>
                </mat-tab>
                <mat-tab label="State/Province"> 
                  <app-corporate-structure-selector-folder
                    
                  [selectorId]="'sp_' +selectorPrefix"
                  [chType]="'MvrExportByState'"
                  [arrCHInfo]="stateProvHierarchy"
                  [clientSelectedArray]="clientSelected"
                  (onSelectionsProcessed)="updateFilterCriteria()"
                >
                </app-corporate-structure-selector-folder>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-8">
    
    <app-export-driver-select
      [arrDriver]="drivers"
      [showMVRDate]="driverSelectionOptions.showMVRDate"
      [showLastMVRDate]="driverSelectionOptions.showLastMVRDate"
      [showLastDVDate]="driverSelectionOptions.showLastDVDate"
      [showRiskLevel]="driverSelectionOptions.showRiskLevel"
      (onUpdateDriversSelected)="updateDriverIdSelected($event)"
      (onUpdateDriversExcluded)="updateDriverIdExcluded($event)"
    >
    </app-export-driver-select>
    
  </div>
</div>
