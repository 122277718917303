<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Vehicle Information</h4>
          <div class="row">
            <div class="col-md-1">
              <p class="mb-0">Vehicle:</p>
            </div>
            <div class="col-md-7">
              <p class="font-weight-bold mb-0">{{detail?.vehicle}}</p>
            </div>
            <div class="col-md-1">
              <p class="mb-0">ACV:</p>
            </div>
            <div class="col-md-3">
              <p class="font-weight-bold mb-0">{{detail?.actualCashValue | currency:'USD':'$':'1.2-2'}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-1">
              <p class="mb-0">Year:</p>
            </div>
            <div class="col-md-3">
              <p class="font-weight-bold mb-0">{{detail?.vehicleYear}}</p>
            </div>
            <div class="col-md-1">
              <p class="mb-0">Make:</p>
            </div>
            <div class="col-md-3">
              <p class="font-weight-bold mb-0">{{detail?.vehicleMake}}</p>
            </div>
            <div class="col-md-1">
              <p class="mb-0">Model:</p>
            </div>
            <div class="col-md-3">
              <p class="font-weight-bold mb-0">{{detail?.vehicleModel}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-1">
              <p class="mb-0">Mileage:</p>
            </div>
            <div class="col-md-3">
              <p class="font-weight-bold mb-0">{{detail?.vehicleOdometer}}</p>
            </div>
            <div class="col-md-1">
              <p class="mb-0">Lic. Plate:</p>
            </div>
            <div class="col-md-3">
              <p class="font-weight-bold mb-0">{{detail?.vehicleLicensePlate}} <span *ngIf="detail?.vehicleLicensePlateState">({{detail?.vehicleLicensePlateState}})</span></p>
            </div>
            <div class="col-md-1">
              <p class="mb-0">VIN:</p>
            </div>
            <div class="col-md-3">
              <p class="font-weight-bold mb-0">{{detail?.vin}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Vehicle Location</h4>
          <div class="row">
            <div class="col-md-1">
              <p class="mb-0">Name:</p>
            </div>
            <div class="col-md-11">
              <p class="font-weight-bold mb-0">{{detail?.vehicleLocation}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-1">
              <p class="mb-0">Address:</p>
            </div>
            <div class="col-md-11">
              <p class="font-weight-bold mb-0">{{detail?.vehicleLocationAddress}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-1">
              <p class="mb-0">City:</p>
            </div>
            <div class="col-md-3">
              <p class="font-weight-bold mb-0">{{detail?.vehicleLocationCity}}</p>
            </div>
            <div class="col-md-1">
              <p class="mb-0">State:</p>
            </div>
            <div class="col-md-3">
              <p class="font-weight-bold mb-0">{{detail?.vehicleLocationState}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Salvage Information</h4>
          <div class="row">
            <div class="col-md-3">
              <p class="mb-0">Title/Paperwork Sent Date:</p>
            </div>
            <div class="col-md-5">
              <p class="font-weight-bold mb-0">{{detail?.titleSentDate | date:'M/d/yyyy'}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <p class="mb-0">Sale Amount:</p>
            </div>
            <div class="col-md-5">
              <p class="font-weight-bold mb-0">{{detail?.finalSaleAmount | currency:'USD':'$':'1.2-2'}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <p class="mb-0">Net Salvage Return:</p>
            </div>
            <div class="col-md-5">
              <p class="font-weight-bold mb-0">{{detail?.netSalvageReturn | currency:'USD':'$':'1.2-2'}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <p class="mb-0">Salvage Sold Date:</p>
            </div>
            <div class="col-md-5">
              <p class="font-weight-bold mb-0">{{detail?.soldDate | date:'M/d/yyyy'}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <p class="mb-0">Title Brand:</p>
            </div>
            <div class="col-md-5">
              <p class="font-weight-bold mb-0">{{detail?.titleStatus}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <p class="mb-0">Date Title Received/Salvage Closed Date:</p>
            </div>
            <div class="col-md-5">
              <p class="font-weight-bold mb-0">{{detail?.titleReceiveDate | date:'M/d/yyyy'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="bidHistories?.length > 0">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Salvage Bid Activity</h4>
          <div class="row">
            <div class="col-md-12">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr class="table-header">
                    <th scope="col">Bid #</th>
                    <th scope="col">Bid Amount</th>
                    <th scope="col">Date and Time of Bid</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let bid of bidHistories">
                    <td>{{bid.bidOrder}}</td>
                    <td>{{bid.bidAmount | currency:'USD':'$':'1.2-2'}}</td>
                    <td>{{bid.bidDateTime}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
