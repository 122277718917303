import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DashboardTile } from '../classes-and-interfaces/classes-and-interfaces.component';

@Component({
  selector: 'app-card-gridster',
  templateUrl: './card-gridster.component.html',
  styleUrls: ['./card-gridster.component.css']
})

export class CardGridsterComponent {
  @Input() card: DashboardTile;
  @Output() onRemoveClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onShowCardModalClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onShowCardFilterModalClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onShowCardSlicerModalClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onExportClicked: EventEmitter<any> = new EventEmitter<any>();

  isTileFilterApplied(thisCard: DashboardTile) {
    // filter type = range or single
    const filterTypeSelected: boolean = ((thisCard.tileDateFilterTypeId == 1) || (thisCard.tileDateFilterTypeId == 2));
    // if type = range, has start date 
    const hasStart: boolean = ((thisCard.tileDateFilterTypeId == 1) && (thisCard.filterStartDate && (thisCard.filterStartDate.length > 0)));
    // has end date
    const hasEnd: boolean = (thisCard.filterEndDate && (thisCard.filterEndDate.length > 0));

    return filterTypeSelected && (hasStart || hasEnd);
  }

  removeItem(card) {
    this.onRemoveClicked.emit(card);
  }

  showCardModal(card) {
    this.onShowCardModalClicked.emit(card);
  }

  showCardFilterModal(card) {
    this.onShowCardFilterModalClicked.emit(card);
  }

  showCardSlicerModal(card) {
    this.onShowCardSlicerModalClicked.emit(card);
  }
  export(card) {
    this.onExportClicked.emit(card);
  }
}
