<div class="row linkToPrimaryPanel">
  <div class="col-md-9">
    Employee (Primary Driver) {{primaryDriverDetails?.employeeId}} - {{primaryDriverDetails?.fullName}}
  </div>
  <div class="col-md-3">
    <button type="button"
            (click)="openDriverProfile(primaryDriverDetails.driverId)"
            class="btn btn-primary"
            style="float:right">
      View Primary
    </button>
  </div>
</div>
