        <div class="header">
          <h4 class="title">{{reportTitle}}</h4>
          <button type="button" class="close" aria-label="Close" [mat-dialog-close]="true"><span aria-hidden="true">&times;</span></button>
       </div>
      <div class="p-3">
        <span>Today's date: {{ today }}</span>
          <div>
          <mat-form-field appearance="fill">
            <mat-label class="lbl-start-date" *ngIf="!startDate">From Date</mat-label>
            <input class="input-date" matInput disabled [matDatepicker]="startDatePicker" [(ngModel)]="startDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker disabled="false">
              <mat-datepicker-actions>
                <button mat-button (click)="startDatePicker.select(actualDate); startDatePicker.close()">
                  <mat-icon>history</mat-icon>
                  Today
                </button>
                <button mat-button (click)="startDatePicker.select(null); startDatePicker.close()">
                  <mat-icon>highlight_off</mat-icon>
                  Clear
                </button>
              </mat-datepicker-actions> 
            </mat-datepicker>
          </mat-form-field>
          <mat-divider></mat-divider>
          <mat-form-field appearance="fill">
            <mat-label *ngIf="!endDate">Through Date</mat-label>
            <input class="input-date" [(ngModel)]="endDate" matInput disabled [matDatepicker]="endDatePicker" [(ngModel)]="endDate">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker disabled="false">
              <mat-datepicker-actions>
                <button mat-button (click)="endDatePicker.select(actualDate); endDatePicker.close()">
                  <mat-icon>history</mat-icon>
                  Today
                </button>
                <button mat-button (click)="endDatePicker.select(null); endDatePicker.close()">
                  <mat-icon>highlight_off</mat-icon>
                  Clear
                </button>
              </mat-datepicker-actions> 
            </mat-datepicker>
          </mat-form-field>
          <mat-divider></mat-divider>
          </div>
          <div class="mt-3">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Specify the filter type</mat-label>
              <mat-select [(value)]="selectedDateField">
                <mat-option *ngFor="let option of DateFieldOptions" [value]="option.value">
                  {{option.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
      </div>
      <div class="footer">
        <div class="label-validation-error">
          {{errorMsg}}
        </div>
        <a class="btn btn-default" (click)="onGenerateReport()">
          Generate Report
        </a>
      </div>
