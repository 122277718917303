import { OnInit, OnDestroy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { AccidentClaimRepairDetail, AccidentClaimRepairEstimateDetail, AccidentClaimRepairGlassDetail } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-accident-claim-repair-first-party',
  templateUrl: './accident-claim-repair-first-party.component.html'
})
export class AccidentClaimRepairFirstPartyComponent implements OnInit, OnDestroy {
  _claimId: number;
  detail: AccidentClaimRepairDetail;
  estimateDetails: Array<AccidentClaimRepairEstimateDetail>;
  glassDetail: AccidentClaimRepairGlassDetail;
  _routeSub: Subscription;

  constructor(private readonly _route: ActivatedRoute,
    private readonly accidentClaimService: AccidentClaimService) { }


  printPage(): void {
    window.print();
  }

  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];
      this.accidentClaimService.getRepairDetail(this._claimId).subscribe({
        next: (data) => {
          this.detail = data as AccidentClaimRepairDetail;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });

      this.accidentClaimService.getRepairEstimateDetails(this._claimId).subscribe({
        next: (data) => {
          this.estimateDetails = data as Array<AccidentClaimRepairEstimateDetail>;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });

      this.accidentClaimService.getRepairGlassDetail(this._claimId).subscribe({
        next: (data) => {
          this.glassDetail = data as AccidentClaimRepairGlassDetail;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });
    });
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
  }

  getEstimatedTotal(): number {
    if (this.estimateDetails) {
      return this.estimateDetails.reduce((x, y) => x + (y.approveDate === '' ? 0 : y.estimateAmount), 0);
    } else {
      return null;
    }
  }
}
