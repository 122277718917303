<div>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeReactivateInfoModal()">
        <span aria-hidden="true">×</span>
      </button>
      <h4 class="modal-title w-100" id="labelReactivateInfoModal">Opt Out Drivers</h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12" style="text-align:center">
          Today's Date: {{dtToday|date:'MM/dd/yyyy'}}
        </div>
      </div>
      <app-data-table
        [columns]="tableConfig.fields"
        [data]="tableConfig.data"
      ></app-data-table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" aria-label="Terminate" (click)="permanentlyTerminate()">Opt Out</button>
      <button type="button" class="btn btn-default" style="margin-bottom:6px" aria-label="Close" (click)="closeReactivateInfoModal()">Close</button>
    </div>
  </div>
</div>
