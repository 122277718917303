//Angular
import { Component, ViewChild, QueryList, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
//Third Party
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//APP
import { DriverInactive } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ReactivateDriverInfoModalService } from "./reactivate-driver-info-modal-service.component";
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reactivate-driver-info-modal',
  templateUrl: './reactivate-driver-info-modal.component.html',
  styleUrls: ['./reactivate-driver-info-modal.component.scss']
})
export class ReactivateDriverInfoModalComponent {
  driverInfo: DriverInactive;
  dtToday: Date = new Date();
  reactivateSubscription: Subscription;
  searchId: string;
  searchName: string;

  constructor(private router: Router,
    private reactivateService: ReactivateDriverInfoModalService,
    private http: HttpClient,
    private onPremService: OnPremDriverService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private dialogRef: MatDialogRef<ReactivateDriverInfoModalComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { driverInfo: DriverInactive }) {
    this.driverInfo = data.driverInfo;
  }

  reActivate(): void {

    this.loadingSpinnerService.show();
    this.onPremService.put('status/reactivate/' + this.driverInfo.driverId.toString(),
      null
    ).subscribe({
      next: (data) => {
        this.snackBar.open('Driver Reactivated', 'Ok', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5000,
          panelClass: 'success-snackbar'
        })
        this.closeReactivateInfoModal();
        // refresh inactive driver grid results
        this.reactivateService.setInactiveDriverGridRefresh(true);
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  closeReactivateInfoModal(): void {
    this.driverInfo = null;
    this.dialogRef.close();
  }
}
