import { Component, OnInit, Input } from '@angular/core';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DHPAddNotesService } from '../dhp-add-notes/dhp-add-notes-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';

@Component({
  selector: 'dhp-verify-mvr',
  templateUrl: './dhp-verify-mvr.component.html',
  styleUrls: ['./dhp-verify-mvr.component.css']
})
export class DHPVerifyMvrComponent implements OnInit {
  @Input() modalHeight: string;
  @Input() modalInput: any;
  note: DriverNote;
  driverName: string;
  driverId: number;

  constructor(
    private dhpService: DriverHistoryProfileService,
    private loadingSpinnerService: LoadingSpinnerService,
    private onPremService: OnPremDriverService,
    private alertService: AlertService,
    private http: HttpClient,
    private errorService: ErrorModalService
  ) { }

  ngOnInit() {
    this.driverName = this.modalInput["driverName"];
    this.note = new DriverNote(this.modalInput["driverId"], " MVR Verified");
  }

  submit() {
    this.loadingSpinnerService.show();
    if (this.updateVerifyDate()) this.addNote();
    this.loadingSpinnerService.hide();
  }

  cancel() {
    this.dhpService.notifyModalClose();
  }

  private updateVerifyDate(): boolean {
    let postData = '';
    this.onPremService.post('mvr/' + this.modalInput["driverId"] + '/' + this.modalInput["mvrId"] + '/verify',
      JSON.stringify(postData)
    ).subscribe({
      next: (data) => {
        // signal that driver mvr data needs to be refreshed
        this.dhpService.setRefreshDriverProfileMVRHistoryData(this.modalInput["driverId"]);
        this.dhpService.notifyModalClose();

        this.alertService.showSuccessAlert('MVR Verified.', 'end', 'top', 5000);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        return false;
      }
    });
    return true;
  }

  private addNote(): boolean {
    let postData = this.note;
    this.onPremService.post('note',
      JSON.stringify(postData)
    ).subscribe({
      next: (data) => {
        // signal that driver note data needs to be refreshed
        this.dhpService.setRefreshDriverProfileNotesData(this.modalInput["driverId"]);
        this.dhpService.notifyModalClose();
        this.alertService.showSuccessAlert('Add Note Completed.', 'end', 'top', 5000);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        return false;
      }
    });
    return true;
  }
}

export class DriverNote {
  driverId: number;
  isNotification: boolean;
  note: string;

  constructor(driverId: number, note: string) {
    return {
      driverId: driverId,
      isNotification: false,
      note: note
    }
  }
}
