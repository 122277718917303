//Angular
import { Component, Inject } from '@angular/core';
//Third Party
import { Subscription } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//APP
import { AlertObject } from "../classes-and-interfaces/classes-and-interfaces.component";

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent {
  alertSubscription: Subscription;
  title: string;
  message: string;
  htmlMessage: string;

  constructor(
    private dialogRef: MatDialogRef<AlertModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {message: AlertObject}) {
    this.title = data.message.title;
    this.message = data.message.message;
    this.htmlMessage = data.message.htmlMessage;
  }

  alertModalClose() {
    this.title = null;
    this.message = null;
    this.dialogRef.close();
  }
}
