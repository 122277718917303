<div class="row">

  <div class="col-md-12">
    <div class="card card-body mt-3">
      <div class="form-inline">
        <h4 class="card-title text-muted">Notes</h4>
        <button *ngIf="canAddNote" class="btn btn-xs btn-default p-1" style="margin:-10px 0 0 10px; width:100px;" (click)="notifyModal('Add Note', 'addNotes', 'auto;', 'lg',{driverId:driverId})">
          Add Note
        </button>
      </div>
      <app-loading-panel *ngIf="!driverNotesTableConfig"></app-loading-panel>
      <div *ngIf="driverNotesTableConfig">
        <app-data-table 
          [columns]="driverNotesTableConfig.fields"
          [data]="driverNotesTableConfig.data"
          noRecordsLabel="No Notes"
          (triggerOpenModal)="openModal($event)"
        ></app-data-table>
      </div>

    </div>
  </div>
</div>
<div *ngIf="showUploadPanel" class="row">
  <div class="col-md-12">
    <document-upload [uploadType]="'DriverProfile'"
                     [driverProfile]="driverProfile"
                     [driverId]="driverId"
                     (onDocUploadExit)="toggleUploadPanel(false)"
                     (onDocUploadRefresh)="refreshDocInfo()">
    </document-upload>
  </div>
</div>
<div *ngIf="showDocuments" class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <div class="form-inline">
        <h4 class="card-title text-muted">Documents</h4>
        <button *ngIf="canUpload" class="btn btn-xs btn-default p-1" style="margin:-10px 0 0 10px; width:100px;" (click)="toggleUploadPanel(!showUploadPanel)">
          Upload
        </button>
        <button class="btn btn-xs btn-default p-1" style="margin: -10px 0 0 10px; width:100px;" (click)="downloadZipFile()">
          Download
        </button>
      </div>
      <app-loading-panel *ngIf="!driverDocsTableConfig"></app-loading-panel>
      <div *ngIf="driverDocsTableConfig">
        <app-data-table 
          [columns]="driverDocsTableConfig.fields"
          [data]="driverDocsTableConfig.data"
          noRecordsLabel="No Documents"
          (triggerOpenModal)="openModal($event)"
        ></app-data-table>
      </div>

    </div>
  </div>
</div>


