//Angular
import { Injectable } from '@angular/core';
//Third Party
import { MatDialog } from '@angular/material/dialog';
import { Subject ,  Observable } from 'rxjs';
//App
import { OrdersAndAssignmentsReportModalComponent } from './orders-and-assignments-report-modal.component';

@Injectable()
export class OrdersAndAssignmentsReportModalService {
  private ordAssignReportSearchObs = new Subject();
  ordAssignReportSearchObs$ = this.ordAssignReportSearchObs.asObservable();

  constructor(private dialog: MatDialog) { }

  openOrdAssignReportSearchModal() {
    this.ordAssignReportSearchObs.next(true);
    this.openModal();
  }

  closeOrdAssignReportDriverSearchModal() {
    this.ordAssignReportSearchObs.next(false);
  }

  private openModal() {
    const dialogRef = this.dialog.open(OrdersAndAssignmentsReportModalComponent, {
      minWidth: '30em',
      width: '35em',
      minHeight: '5em',
      panelClass: 'add-document-modal',
      hasBackdrop: false,
      position: {top: '2em'}
    })
  }

}

export class OrdersAndAssignmentsBatchDateTime {
  batchId: number;
  batchDateTime: string;
}
