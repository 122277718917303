import { Component, OnInit, Input, Injectable, Inject } from '@angular/core';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { UserRightsService } from '../../components/user-rights-service/user-rights-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { SafetyModalWindowService } from '../safety-modal-window/safety-modal-window-service.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { UserRightsInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { Observable, Subject ,  forkJoin } from 'rxjs';

@Injectable()
export class DriverMaintenanceReportService {
  //private driverMaintenanceModalRow = new Subject();
  //driverMaintenanceModalRow$ = this.driverMaintenanceModalRow.asObservable();
  public row: any;

  baseUrl: string;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private dhpService: DriverHistoryProfileService,
    private onPremService: OnPremDriverService,
    private errorService: ErrorModalService) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  getDriverMaintenanceReport(clientCode): Observable<object> {
    return this.onPremService.get("/drivermaintenance/" + clientCode + "/report")
  }

  submitKeepDrivers(clientCode, keepDrivers: any[]) {
    let driverIds = keepDrivers.map(x => { return x.driverId });
    return this.onPremService.post('drivermaintenance/' + clientCode + '/keep', '"' + driverIds.join(",") + '"');
  }
  submitRemoveDrivers(clientCode, removeDrivers: any[]) {
    let driverIds = removeDrivers.map(x => { return x.driverId });
    return this.onPremService.post('drivermaintenance/' + clientCode + '/remove', '"' + driverIds.join(",") + '"');
  }
  //submitSelectedDrivers(clientCode, keepDrivers: any[], removeDrivers: any[]) {

  //  let requests: any[] = new Array<any>();



  //  if (keepDrivers.length > 0 && removeDrivers.length >0)
  //  return this.onPremService.post('/drivermaintenance/' + clientCode + '/keep', keepDrivers.join(","))
  //    .subscribe(data => {
  //      return this.onPremService.post('/drivermaintenance/' + clientCode + '/remove', removeDrivers.join(","))
  //        .subscribe(data => {
  //          return new Observable<object>();
  //        },
  //          err => {
  //            this.errorService.setErrorObject(err);
  //          });
  //    }, err => {
  //      this.errorService.setErrorObject(err);
  //    });


  //  //requests.push(this.onPremService.post('/drivermaintenance/' + clientCode + '/remove', removeDrivers.join(",")).catch(error => {
  //  //    try {
  //  //      this.toastService.show(error.message);
  //  //    }
  //  //    catch{ }
  //  //    return Observable.of(null)
  //  //  }));

  //  //requests.push(this.onPremService.post('/drivermaintenance/' + clientCode + '/keep', keepDrivers.join(",")).catch(error => {
  //  //    try {
  //  //      this.toastService.show(error.message);
  //  //    }
  //  //    catch{ }
  //  //    return Observable.of(null)
  //  //  }));

  //  //removeDrivers.forEach(x => {
  //  //  requests.push(this.onPremService.post('/drivermaintenance/' + x.driverId + '/remove', '').catch(error => {
  //  //    try {
  //  //      this.toastService.show(error.message);
  //  //    }
  //  //    catch{ }
  //  //    return Observable.of(null)
  //  //  }));

  //  //});
  //  //keepDrivers.forEach(x => {
  //  //  requests.push(this.onPremService.post('/drivermaintenance/' + x.driverId + '/keep', '').catch(error => {
  //  //    try {
  //  //      this.toastService.show(error.message);
  //  //    }
  //  //    catch{ }
  //  //    return Observable.of(null)
  //  //  }));

  //  //});
  //  //console.log(requests);
  //  //return forkJoin(requests);


  //}
  public moveDriverToGroup(clientCode, driverId) {
    //return this.onPremService.post('/drivermaintenance/' + driverId + '/move', '')
    return this.onPremService.post('drivermaintenance/' + clientCode + '/move', '"' + driverId + '"');
  }



  openDriverModal(row: any) {
    // this.driverMaintenanceModalRow.next(row);
    this.row = row;
  }
}
