import { Injectable, Inject, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,  BehaviorSubject, Subscription, PartialObserver } from 'rxjs';
import { LineOfBusinessInfo } from '../classes-and-interfaces/classes-and-interfaces.component';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
}
)
export class LineOfBusinessService implements OnDestroy {
  baseUrl: string;

  // NOTE: default line of business to 1 for initial claims release
  // should default to 0 once we start adding other lines
  private lineOfBusinessSelected = new BehaviorSubject<number>(0);
  private lineOfBusinessSelectedId = 0;

  public lineOfBusinessSelected$ = this.lineOfBusinessSelected.asObservable();
  private _lineOfBusinessChoices = new Array<LineOfBusinessInfo>();
  private _lineOfBusinessChoices$ = new BehaviorSubject<Array<LineOfBusinessInfo>>([]);
  private _initialized = false;
  private _subscriptions: Subscription[] = new Array<Subscription>();
  private _lobChoicesSubscription: Subscription;


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private authService: AuthService) {
    this.http = http;
    this.baseUrl = baseUrl;

    if (this._initialized !== true) {
      if (!authService.isLoggedIn()) {
        authService.isLoggedInAndGetUser();
        authService.onAuthCompleted.subscribe((isAuthCompleted: boolean) => {
          if (this._initialized !== true && isAuthCompleted === true) {
            this.getLineOfBusinessChoices();
          }
        });

      } else if (authService.isLoggedIn()) {
        this.getLineOfBusinessChoices();
        this._initialized = true;
      }
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  public getLineOfBusinessChoices(): Observable<LineOfBusinessInfo[]> {
    if (this._initialized !== true) {
      const lobChoicesSub = this.http.get(this.baseUrl + 'api/UserFilter/UserLineOfBusinesses')
        .subscribe((data: Array<LineOfBusinessInfo>) => this.setLineOfBusinessChoices(data));

      this._subscriptions.push(lobChoicesSub);

      this._initialized = true;
    }
    return this._lineOfBusinessChoices$.asObservable();
  }

  // If a component attempts to set the line of business before we know what lines of business the user has access to
  // We need to defer that change until after we know we can change the user to that lob.
  setLineOfBusiness(lineOfBusiness: number) {
    if (this._lineOfBusinessChoices.length === 0 && this._lobChoicesSubscription == null) {
      this._lobChoicesSubscription =
      this.getLineOfBusinessChoices().subscribe(
        (choices: Array<LineOfBusinessInfo>) => this.setLineOfBusinessAfterChoices(lineOfBusiness));
      this._subscriptions.push(this._lobChoicesSubscription);
    } else if (this._lineOfBusinessChoices.length > 0) {
        this.setLineOfBusinessAfterChoices(lineOfBusiness);
    } else {
      console.debug('Multiple attempts were made to set the line of business before choices returned');
    }

  }

  private setLineOfBusinessAfterChoices(lineOfBusiness: number) {
    if (this._lineOfBusinessChoices.length > 0) {
      if (lineOfBusiness > 0 &&
        // If the provided LineOfBusinessId exists in the choices available to the user
        this._lineOfBusinessChoices.findIndex((lob) => lob.lineOfBusinessId === lineOfBusiness) >= 0) {
        this.lineOfBusinessSelected.next(lineOfBusiness);
        this.lineOfBusinessSelectedId = lineOfBusiness;
      } else {
        this.lineOfBusinessSelectedId = this._lineOfBusinessChoices[0].lineOfBusinessId;
        this.lineOfBusinessSelected.next(this.lineOfBusinessSelectedId);
      }
    }
  }

  getLineOfBusinessValue() {
    return this.lineOfBusinessSelected.getValue();
  }


  private setLineOfBusinessChoices(choices: Array<LineOfBusinessInfo>) {

    this._lineOfBusinessChoices = choices;
    this._lineOfBusinessChoices$.next(this._lineOfBusinessChoices);
    if (this.lineOfBusinessSelectedId === 0 && this._lineOfBusinessChoices.length > 0) {
      this.setLineOfBusiness(this._lineOfBusinessChoices[0].lineOfBusinessId);
    }

  }



  getLineOfBusinessLabel(lineOfBusinessId: number) {
    return this._lineOfBusinessChoices.find(lob => lob.lineOfBusinessId === lineOfBusinessId)?.lineOfBusinessName ?? '';

  }
}
