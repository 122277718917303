<div [formGroup]="driverTypesForm">
  <h4 class="card-title text-muted wrap-this">Drivers</h4>
  <div class="row">
    <div class="col-md-12">
      <div
        *ngIf="
          driverTypesForm.pristine !== true &&
          driverTypesForm.errors?.requireCheckboxToBeChecked
        "
        class="validation-error"
      >
        At least one driver type must be selected.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ul class="horizontalList">
        <li>
          <input type="checkbox" formControlName="Employee" [id]="selectorPrefix + 'dtEmployee'" />
          <label [for]="selectorPrefix + 'dtEmployee'">Employee</label>
        </li>
        <li>
          <input
            type="checkbox"
            formControlName="Non-employee"
            [id]="selectorPrefix + 'dtNonEmployee'"
          />
          <label [for]="selectorPrefix + 'dtNonEmployee'">Non-employee</label>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12"><hr /></div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ul>
        <li *ngIf="showLicenseVerificationOption">
          <input
            type="checkbox"
            formControlName="NoLicVer_Mvr"
            [id]="selectorPrefix + 'dtNoLicVerMvr'"
          />
          <label [for]="selectorPrefix + 'dtNoLicVerMvr'"
            >Drivers that never had Lic. Verification/MVR</label
          >
        </li>
        <li>
          <input
            type="checkbox"
            formControlName="DOT"
            [id]="selectorPrefix + 'dtDOT'"
          />
          <label [for]="selectorPrefix + 'dtDOT'"
            >DOT Drivers</label
          >
        </li>      
        <li>
          <input
            type="checkbox"
            formControlName="AutoCovReq"
            [id]="selectorPrefix + 'dtAutoCovReq'"
          />
          <label [for]="selectorPrefix + 'dtAutoCovReq'"
            >Auto Coverage Required Drivers</label
          >
        </li>           
      </ul>
    </div>
  </div>
</div>
