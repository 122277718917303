<div class="row">
    <div class="col-12" style="padding: 20px;">
        <h4 class="card-title">Arbitration Details</h4>
        <div class="row col-9">
            <div class="accidentClaimTabLabel">
                Filing Date:
            </div>
            <div class="accidentClaimTabBold" style="padding-right:20px;">
                {{artbitrationDetails?.filingDate}}
            </div>
            <div class="accidentClaimTabLabel">
                Response Date:
            </div>
            <div class="accidentClaimTabBold" style="padding-right:20px;">
                {{artbitrationDetails?.updateDate}}
            </div>
            <div class="accidentClaimTabLabel">
                Total requested:
            </div>
            <div class="accidentClaimTabBold" style="padding-right: 20px;">
                ${{artbitrationDetails?.amountDemanded}}
            </div>
        </div>
    </div>
    <div class="col-12" style="padding: 20px;">
        <mat-accordion class="example-headers-align" multi="true">
            <mat-expansion-panel *ngFor="let docket of docketsDetailsWithRespondent; let i = index">
                <mat-expansion-panel-header class="pHeader">
                    <mat-panel-title class="pTitle">
                        Docket {{docket.docketNumber}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mt-3">
                    <div class="row col-9">
                        <div class="accidentClaimTabLabel">
                            Filing Type:
                        </div>
                        <mat-label>Filing Type:</mat-label>
                        <div class="accidentClaimTabBold" style="padding-right:20px;">
                            {{docket.filingType}}
                        </div>
                        <div class="accidentClaimTabLabel">
                            Forum:
                        </div>
                        <div class="accidentClaimTabBold" style="padding-right:20px;">
                            {{docket.forumType}}
                        </div>
                        <div class="accidentClaimTabLabel">
                            Fleet Response Role:
                        </div>
                        <div class="accidentClaimTabBold" style="padding-right:20px;">
                            {{docket.role}}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <mat-accordion multi="true">
                        <mat-expansion-panel *ngFor="let r of docket.respondent; let i = index">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Respondent {{i+1}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="mt-3">
                                <div class="row col-12">
                                    <div class="accidentClaimTabLabel">
                                        Amount Requested:
                                    </div>
                                    <div class="accidentClaimTabBold" style="padding-right:20px;">
                                        ${{r.amountRequested}}
                                    </div>
                                    <div class="accidentClaimTabLabel">
                                        Company Name:
                                    </div>
                                    <div class="accidentClaimTabBold" style="padding-right:20px;">
                                        {{r.companyName}}
                                    </div>
                                    <div class="accidentClaimTabLabel">
                                        Payment Address:
                                    </div>
                                    <div class="accidentClaimTabBold" style="padding-right:20px;">
                                        {{r.addres}}
                                    </div>
                                </div>
                                <div class="row col-12">
                                    <div class="accidentClaimTabLabel">
                                        Previous Payment Received:
                                    </div>
                                    <div class="accidentClaimTabBold" style="padding-right:20px;">
                                        ${{r.previousPaymentReceived}}
                                    </div>
                                    <div class="accidentClaimTabLabel">
                                        Adjuster Name:
                                    </div>
                                    <div class="accidentClaimTabBold" style="padding-right:20px;">
                                        {{r.name}}
                                    </div>
                                    <div class="accidentClaimTabLabel">
                                        Adjuster Email:
                                    </div>
                                    <div class="accidentClaimTabBold" style="padding-right:20px;">
                                        {{r?.adjusterEmail}}
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="col-12" style="padding: 20px;">
        <h4 class="card-title">Final Decision</h4>
        <div class="row col-9">
            <div class="accidentClaimTabLabel">
                Decision Date:
            </div>
            <div class="accidentClaimTabBold" style="padding-right:20px;">
                {{artbitrationDetails?.decisionDate}}
            </div>
            <div class="accidentClaimTabLabel">
                Close Date:
            </div>
            <div class="accidentClaimTabBold" style="padding-right:20px;">
                {{artbitrationDetails?.closeDate}}
            </div>
            <div class="accidentClaimTabLabel">
                Close Reason:
            </div>
            <div class="accidentClaimTabBold" style="padding-right: 20px;">
                {{artbitrationDetails?.closeReasonString}}
            </div>
            <div class="accidentClaimTabLabel">
                Post Decision Inquiry Date:
            </div>
            <div class="accidentClaimTabBold" style="padding-right: 20px;">
                {{artbitrationDetails?.postDecisionInquiryDate}}
            </div>
        </div>
        <table aria-describedby="Docket Information" class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Docket(s)</th>
                    <th scope="col">Overall Outcome</th>
                    <th scope="col">Percentage of Liability</th>
                    <th scope="col">Status</th>
                    <th scope="col">Total Amount Awarded</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let docket of docketDetails">
                    <td>
                        {{docket?.docketNumber}}
                    </td>
                    <td>
                        {{docket?.decision}}
                    </td>
                    <td>
                        {{docket?.percentageOfLiability}}
                    </td>
                    <td>
                        {{docket?.status}}
                    </td>
                    <td>
                        {{docket?.amountAwarded}}
                    </td>
                </tr>
                <tr>
                    <td>

                    </td>
                    <td>

                    </td>
                    <td>

                    </td>
                    <td>

                    </td>
                    <td>
                        {{docketTotal}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>