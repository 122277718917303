<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
  <div class="row">
    <div class="col-md-12">
      <div class="card card-body" align="center">
        <label class="">Order MVR?</label>
        <div class="row" style="padding-top:15px; align-content:center; align-items:center; align-self:center;">
          <button class="btn btn-xs btn-default"
                  (click)="submit()">
            Order
          </button>
          <button class="btn btn-xs btn-default"
                  (click)="cancel()">
            Exit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
