    <div>
      <div class="header-container">
        <h4 class="modal-title w-100 text-center p-2" id="myBasicModalLabel">Report Filters</h4>
        <button type="button" class="close pull-right" (click)="closeFilterPanel()">
          <span>×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="row" style="padding-bottom:10px">
            <!--<div class="col-md-8">
          <h3>Report Filters</h3>
        </div>-->
            <div class="col-md-12">
              <span style="float:right">
                <button type="button"
                        *ngIf="!gridFilterPanelEdit"
                        (click)="editGridFilterInfo()"
                        class="btn btn-default">
                  Edit Filters
                </button>
                <button type="button"
                        (click)="closeFilterPanel()"
                        class="btn btn-default">
                  Exit
                </button>
              </span>
            </div>
          </div>

          <ng-container *ngIf="gridFilterPanelEdit">
            <div class="row" style="padding:10px">
              <div class="col-md-12">
                <mat-form-field appearance="fill" class="select-container w-100">
                  <mat-label>Select Client</mat-label>
                  <mat-select #selectClients="matSelect" [(value)]="gridFilterClientChosen" (selectionChange)="selectClientGridFilter(gridFilterClientChosen)">
                    <input matInput class="select-search-input" placeholder="Search Clients..." (keyup)="filterOptions($event.target.value)">
                    <mat-option *ngFor="let option of selectFilteredClientChoices" [value]="option.value">
                      {{option.label}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>
            </div>
            <div class="row" style="padding:10px" *ngIf="gridFilterClientChosen?.toString().length>0">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field appearance="fill" class="select-container w-100">
                      <mat-label>Filter</mat-label>
                      <mat-select  [(value)]="gridFilterReqNew">
                        <mat-option *ngFor="let option of gridFilterReqChoices" [value]="option.value">
                          {{option.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field appearance="fill" class="select-container w-100">
                      <mat-label>Criteria</mat-label>
                      <mat-select  [(value)]="gridFilterCriteriaNew">
                        <mat-option *ngFor="let option of gridFilterCriteriaChoices" [value]="option.value">
                          {{option.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="md-form">
                      <mat-form-field class="w-100" appearance="fill">
                        <mat-label>Value</mat-label>
                        <input matInput [(ngModel)]="gridFilterValNew">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <button type="button"
                            [disabled]="(!gridFilterReqNew)||(!gridFilterCriteriaNew)||(!gridFilterValNew)||(gridFilterReqNew.length<=0)||(gridFilterCriteriaNew.length<=0)||(gridFilterValNew.length<=0)"
                            (click)="gridFilterAddNew()"
                            class="btn btn-primary">
                      Add
                    </button>
                  </div>
                </div>
              </div>   
            </div>
            <div class="row" *ngIf="gridFilterClientChosen?.toString().length>0 || true">
              <div class="col-md-12">
                <table class="table table-striped table-bordered">
                  <thead style="background-color:#d23138;">
                    <tr>
                      <th class="p-2" scope="col">Client</th>
                      <th class="p-2" scope="col" style="color:white;">Filter</th>
                      <th class="p-2" scope="col" style="color:white;">Criteria</th>
                      <th class="p-2" scope="col" style="color:white;">Value</th>
                      <th class="p-2" scope="col" style="color:white;">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let record of dashboardFilterInfo; let recIndex = index">
                      <td>{{record.clientName}}</td>
                      <td>{{record.element}}</td>
                      <td>{{record.filterOperator}}</td>
                      <td style="word-wrap:break-word;word-break:break-all;">{{record.filterValue}}</td>
                      <td>
                        <button type="button" class="close grid-filter-button" aria-label="Remove" title="Remove" (click)="gridFilterRemove(record.userClientGroupFilterId)" *ngIf="record.isUserEditable != false" >
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <button type="button" class="close locked grid-filter-button" aria-label="Locked" title="Locked" *ngIf="record.isUserEditable != true" >
                          <span class='fa fa-lock'></span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>
          <div class="row" *ngIf="!gridFilterPanelEdit">
            <div class="col-md-12">
              <table class="table table-striped table-bordered gridFilterPanelEditTable">
                <thead>
                  <tr>
                    <th scope="col">Client</th>
                    <th scope="col">Filter</th>
                    <th scope="col">Criteria</th>
                    <th scope="col">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let record of dashboardFilterInfo">
                    <td>{{record.clientName}}</td>
                    <td>{{record.element}}</td>
                    <td>{{record.filterOperator}}</td>
                    <td>{{record.filterValue}}</td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
