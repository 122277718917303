//Angular
import { Component, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PlatformLocation } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
//Third Party
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as exceljs from 'exceljs';
import * as FileSaver from 'file-saver';
//APP
import { ReportRequest, DataGridService } from './data-grid.service';
import { ClientSelectionService } from '../client-selection-service/client-selection-service.component';
import { DataGridEmailService } from "../data-grid-export-email/data-grid-export-email.service";
import { LineOfBusinessService } from '../line-of-business-service/line-of-business-service.component';
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { ErrorModalService } from '../error-modal/error-modal-service.component';
import { ErrorObject, DriverClientSettings } from '../classes-and-interfaces/classes-and-interfaces.component';
import { ScrollToTopService } from '../../shared/scroll-to-top-service/scroll-to-top.service';
import { SafetyModalWindowService } from '../../safety/safety-modal-window/safety-modal-window-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { DataTableComponent } from '../data-table/data-table.component';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent implements OnInit, OnDestroy {
  invalidGridData = false;
  baseUrl: string;

  clientSelectionSubscription: Subscription;
  clientSelectedArray: Array<string>;
  clientIdSelectedArray: Array<number>;
  clientSettings: DriverClientSettings;

  lineOfBusinessSubscription: Subscription;
  lineOfBusinessSelected: number;

  dataSub: Subscription;
  _routeSub: Subscription;
  routeId: string;

  refreshGridSubscription: Subscription;

  public page = 1;
  public itemsPerPage = 50;
  public maxSize = 5;
  public numPages = 1;
  public length = 0;
  public today = new Date(Date.now());
  public sortPageTime = 0;
  public dateFilterRangeType = 1;
  columns = [];

  rowsRaw: Array<any>;

  public configObject: any;

  gridRequestSubscription: Subscription;
  @ViewChild('myTable') myTable: DataTableComponent;
  clientSettingsSubscription: Subscription;

  constructor(
    private readonly router: Router,
    @Inject('BASE_URL') baseUrl: string,
    private readonly route: ActivatedRoute,
    private readonly dataGridService: DataGridService,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    public gridRequest: ReportRequest,
    private readonly clientSelectionService: ClientSelectionService,
    private readonly lineOfBusinessService: LineOfBusinessService,
    private readonly emailService: DataGridEmailService,
    private readonly fileDownloadService: FileDownloadService,
    private readonly errorModalService: ErrorModalService,
    private readonly scrollToTopService: ScrollToTopService,
    private readonly safetyModalWindowService: SafetyModalWindowService,
    private readonly location: PlatformLocation
  ) {
    this.baseUrl = baseUrl;
    this.gridRequest = gridRequest;

    this.lineOfBusinessSelected = this.lineOfBusinessService.getLineOfBusinessValue();

    this.lineOfBusinessSubscription = lineOfBusinessService.lineOfBusinessSelected$.subscribe(
      lineOfBusinessSelected => {
        this.lineOfBusinessSelected = lineOfBusinessSelected;
      }
    )

    // client selection
    this.clientSelectedArray = this.clientSelectionService.getClientSelectedArrayValue(this.lineOfBusinessSelected);
    this.clientIdSelectedArray = this.clientSelectionService.getClientIdSelectedArrayValue(this.lineOfBusinessSelected);
    this.clientSelectionSubscription = clientSelectionService.clientSelectedInfo$.subscribe(
      clientSelectedInfo => {
        const temp = clientSelectedInfo;
        if (temp[this.lineOfBusinessSelected]) {
          this.clientSelectedArray = temp[this.lineOfBusinessSelected].clientSelectedArray;
          this.clientIdSelectedArray = temp[this.lineOfBusinessSelected].clientIdSelectedArray;
        }
      }
    );

    this._routeSub = this.route.params.subscribe(params => {
      // save id param in case we need to reload route
      this.routeId = params['id'];
    });

    this.refreshGridSubscription = this.dataGridService.refreshGrid$.subscribe(
      doRefresh => {
        // re-run resolver to refresh data
        this.loadingSpinnerService.show();
        this.router.navigate(['/datagrid/' + this.routeId]);
      }
    )
  }

  grid: any;
  
  ngOnInit() {
    this.scrollToTopService.scrollTop();

    this.dataSub = this.route.data
      .subscribe((data) => {
        this.loadingSpinnerService.hide();
        if (data?.data) {
          if (data.data instanceof HttpErrorResponse) {
            this.errorModalService.setErrorObject(data.data.error as ErrorObject);
            return;
          }
          this.lineOfBusinessService.setLineOfBusiness(data.data.grid.lineOfBusinessId);

          this.columns = data.data.columns;
          this.rowsRaw = (data.data.rows as any[]).slice(0, 50);

          this.grid = data.data.grid;

          // Function to determine if the grid title contains a specific keyword
          function gridTitleContains(keyword) {
            return (this.grid.title as string).toLowerCase().includes(keyword.toLowerCase());
          }

          // Function to create a clickable field
          function createClickableField(column, clickHandler) {
            return {
              name: column.title,
              objectKey: column.prop,
              columnClass: 'clickable',
              click: clickHandler
            };
          }

          // Function to create a render-only field
          function createRenderField(column) {
            return {
              name: column.title,
              objectKey: column.prop,
              hidden: ['driverid', 'actionurl'].includes(column.prop.toLowerCase()),
              render: row => formatField(column, row)
            };
          }

          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          });

          // Function to format the field values
          function formatField(column, row) {
            const value = row[column.prop];
            if (column.dataType === "currency") {
              const formattedValue = formatter.format(Math.abs(value));
              return value >= 0 ? formattedValue : `($${formattedValue})`;
            }
            if (column.dataType === "date" && value) {
              try {
                return moment(new Date(value)).format('MM/DD/YYYY').toString();
              } catch {
                return value;
              }
            }
            return value;
          }

          // for COV Status - Pending Manager, employee id link
          // will open COV Signoff modal
          const isCovStatus = gridTitleContains.call(this, "cov status");
          let isCovSignoff = false;
          try {
            isCovSignoff = isCovStatus && data.data.request.parameterValues.some(p => p.parameterName === "PointName" && p.value === "Pending-Manager");
          } catch { }

          // Create fields based on column configuration
          const fields = this.columns.map(column => {
            const propLower = column.prop.toLowerCase();

            if ((this.lineOfBusinessSelected === 1 || this.lineOfBusinessSelected === 2) && column.name === "Claim") {
              return createClickableField(column, row => {
                const route = gridTitleContains.call(this, "Client Approval Pending") ||
                  gridTitleContains.call(this, "Pending Client Approvals - User Notification")
                  ? `/accident/claim/${row[column.prop.trim()]}/15`
                  : `/accident/claim/${row[column.prop].trim()}`;
                this.router.navigate([route]);
              });
            }

            if (gridTitleContains.call(this, "leave of absence") && propLower === "employeeid") {
              return createClickableField(column, row => this.openLeaveOfAbsenceDialog(row));
            }

            if (gridTitleContains.call(this, "ca dl-414 validation") && propLower === "employeeid") {
              return createClickableField(column, row => this.openCDLVerificationModal(row));
            }

            if (isCovSignoff && propLower === "employeeid") {
              return createClickableField(column, row => this.openCovSignoffModal(row));
            }

            if ((this.lineOfBusinessSelected === 2 || this.lineOfBusinessSelected === 7) && propLower === "employeeid") {
              const idIndex = this.columns.findIndex(x => x.prop.toLowerCase() === "driverid");
              if (idIndex > -1) {
                return createClickableField(column, row => {
                  const driverId = row[this.columns[idIndex].prop] || "";
                  if (driverId.length > 0) {
                    this.navigateToDriverProfile(driverId);
                  }
                });
              }
              return createRenderField(column);
            }

            if (propLower === "action") {
              const urlIndex = this.columns.findIndex(x => x.prop.toLowerCase() === "actionurl");
              if (urlIndex > -1) {
                return createClickableField(column, row => {
                  const actionUrl = row[this.columns[urlIndex].prop] || "";
                  if (actionUrl.length > 0) {
                    this.router.navigateByUrl(actionUrl, { state: { returnUrl: this.router.url } });
                    return true;
                  }
                  return false;
                });
              }
              return createRenderField(column);
            }
            return createRenderField(column);
          });


          const settings = this.columns.map(function (s) {
            return {
              objectKey: s.prop,
              sort: 'enable'
            };
          }).filter(x => x.objectKey.toLowerCase() !== "driverid" && x.objectKey.toLowerCase() !== "actionurl");

          const map: { [key: string]: string | number | Function } = {};
          if (data.data.totals) {
            data.data.totals.map(function (t) {
              map[t.name] = t.value;
            });
          }
          this.configObject = {
            settings: settings,
            fields: fields,
            data: data.data.rows,
            totals: [
              {
                name: 'Total *',
                position: 'footer',
                update: false,
                fields: map
              }]
          };
          if (!data.data.totals || data.data.totals.length === 0) {
            this.configObject.totals = [];
          }
          (data.data.filters as [any]).forEach(x => {
            if (x.filterType === "Date") {
              this.dateFilterRangeType = x.dataGridFilterRangeTypeId
            }
          });
        } else {
          this.invalidGridData = true;
        }
        this.loadingSpinnerService.hide();
      });
  }

  openModal(data: any): void {
    switch (data?.type) {
      case 'leaveOfAbsence':
        this.openLeaveOfAbsenceDialog(data?.row);
        break;
      case 'cdlVerification':
        this.openCDLVerificationModal(data?.row);
        break;
      case 'covSignOff':
        this.openCovSignoffModal(data?.row);
        break;
      default:
        break;
    }
  }

  openLeaveOfAbsenceDialog(row: any) {
    const driverBaseInfo: any = {};
    driverBaseInfo.driverId = row['driverId'];
    driverBaseInfo.firstName = row['first'];
    driverBaseInfo.middleName = `${row['middle']}${row['middle']?.length > 0 ? " " : ""}`;
    driverBaseInfo.lastName = row['last'];
    const driverNameLabel = `${driverBaseInfo.firstName} ${driverBaseInfo.middleName}${driverBaseInfo.lastName}`;
    driverBaseInfo.fullName = driverNameLabel;
    driverBaseInfo.employeeId = row['employeeID'];
    driverBaseInfo.fullHierarchy = row['group'];
    driverBaseInfo.driverLicenseStatus = row['licStatus'];
    driverBaseInfo.lastMVR = row['lastMvr'];
    driverBaseInfo.driverLicenseState = row['state'];
    driverBaseInfo.leaveOfAbsenceStartDate = row['startDate'];
    driverBaseInfo.leaveOfAbsenceEndDate = row['returnDate'];
    driverBaseInfo.enteredBy = row['enteredBy'];

    const config = {
      title: 'Leave of Absence',
      message: 'leaveOfAbsence',
      height: 'auto',
      width: 'lg',
      data: { driverBaseInfo: driverBaseInfo }
    };

    this.openNotifyModal(config);
  }

  openCovSignoffModal(row: any) {
    const config = {
      title: 'Certificate of Violation Signoff',
      message: 'covSignoff',
      height: '80vh',
      width: 'lg',
      data: { driverId: row['DriverID'] }
    };

    this.openNotifyModal(config);
  }

  openCDLVerificationModal(row: any) {
    const driverBaseInfo: any = {};

    driverBaseInfo.driverId = row['driverId'];
    driverBaseInfo.firstName = row['firstName'];
    driverBaseInfo.lastName = row['lastName'];
    driverBaseInfo.employeeId = row['employeeId'];
    driverBaseInfo.reviewRequired = row['reviewRequired']

    driverBaseInfo.documentId = row['documentId'];
    driverBaseInfo.documentDescription = row['documentDescription'];
    driverBaseInfo.dateUploaded = row['dateUploaded'];

    const config = {
      title: 'CA DL-414 Validation',
      message: 'cdlVerification',
      height: 'auto',
      width: 'lg',
      data: { driverBaseInfo: driverBaseInfo }
    };

    this.openNotifyModal(config);
  }

  openNotifyModal(config: any) {
    this.safetyModalWindowService.notifyModal(
      config.title,
      config.message,
      config.height,
      config.width,
      config.data
    );
  }

  ngOnDestroy() {
    this.clientSelectionSubscription.unsubscribe();
    this.lineOfBusinessSubscription.unsubscribe();
    this.dataSub.unsubscribe();
    this._routeSub.unsubscribe();
    this.refreshGridSubscription.unsubscribe();
    this.rowsRaw = null;
    this.configObject = null;
  }

  onExcel() {
    const dbColumns = [];
    const workbook = new exceljs.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    const searchString = " ";
    const replaceWith = "_";
    const dateFormat = 'mm/dd/yyyy';
    const columnsForExport = this.columns.filter(x => (x['prop'] as string).toLowerCase() !== 'driverid');

    columnsForExport.forEach((element) => {
      element['name'] = element['name'] === "employee?" ? "employee" : element['name'];

      if (element['dataType'] === "date" || element['dataType'] === "DateTime") {
        dbColumns.push({ header: element['name'], key: element['name'].replace(new RegExp(searchString, 'g'), ''), style: { numFmt: dateFormat } });
      } else {
        dbColumns.push({ header: element['name'], key: element['name'].replace(new RegExp(searchString, 'g'), '') });
      }
    });

    worksheet.columns = dbColumns;
    this.configObject.data.forEach((element) => {
      const rowValue = {};
      dbColumns.forEach((columns) => {
        if (columns.style?.numFmt) {
          const dateString = element[columns['key']]?.substr(0, 10);
          rowValue[columns['key']] = dateString ? new Date(dateString) : '';
        } else {
          rowValue[columns['key']] = element[columns['key']];
        }
      })
      worksheet.addRow(rowValue);
    });

    this.addColumnHeaderStyles(worksheet);
    this.addRowStyles(worksheet);

    const fileName = `Visibility-${this.grid.title}-${moment().format("MM-DD-YYYY")}.xlsx`.replace(new RegExp(searchString, 'g'), replaceWith);
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      FileSaver.saveAs(blob, fileName)
    });
  }

  onPrint() {
    const request: ReportRequest = JSON.parse(JSON.stringify(this.gridRequest));

    if (!request.clickTrough) {
      request.ParameterValues.forEach(x => {
        if (x.filterType !== 'Date') {
          x.DisplayValue = "";
          x.ParameterName = "";
        }
      });
    }

    const reportParam = btoa(JSON.stringify(request));
    this.fileDownloadService.openTab(`${this.baseUrl}api/DataGrid/Print?report=${encodeURIComponent(reportParam)}`);
  }

  onEmail() {
    this.emailService.OpenEmailPopup(this.grid);
  }

  trigger($event) {
    if ($event.name === 'gt-sorting-applied') {
      if ($event.value && $event.value[0] !== '$$gtInitialRowIndex') {

        if (($event.value[0] as string).startsWith('-')) {
          this.gridRequest.SortField = ($event.value[0] as string).slice(1);
          this.gridRequest.SortDir = "DESC"
        }
        else {
          this.gridRequest.SortField = $event.value[0]
          this.gridRequest.SortDir = "ASC"
        }
        const fld = this.configObject.fields.find(x => x.objectKey === this.gridRequest.SortField)
        if (fld) {
          this.gridRequest.SortDisplayField = fld.name;
        }
      }
      else {
        this.gridRequest.SortDir = "";
        this.gridRequest.SortDisplayField = "";
        this.gridRequest.SortField = "";
      }
    }
  }

  resetSort() {
    this.gridRequest.SortDir = "";
    this.gridRequest.SortDisplayField = "";
    this.gridRequest.SortField = "";
  }

  sortChange($event): void {
    if (!$event?.active) {
      this.resetSort();
    } else {
      this.gridRequest.SortField = $event?.active || null;
      this.gridRequest.SortDir = $event?.direction?.toUpperCase() || null;
      const fld = this.configObject.fields.find(x => x.objectKey === this.gridRequest.SortField);
      if (fld) {
        this.gridRequest.SortDisplayField = fld.name;
      }
    }
  }

  navigateToDriverProfile(driverId) {
    const _historyProfileUrl = "/driver/history-profile/";
    try {
      if (this.lineOfBusinessSelected === 2) //Safety
      {
        this.dataGridService.getPrimaryDriverId(driverId).subscribe({
          next: (data) => {
            const primaryDriverId = data as number;
            if (primaryDriverId > 0 && !this.getClientSettings().nonEmployeeProfiling) {
              this.router.navigate([_historyProfileUrl + primaryDriverId], { queryParams: { openToTab: 'Non-Employees' } });
            }
            else {
              this.router.navigate([_historyProfileUrl + driverId]);
            }
          },
          error: (err) => {
            this.router.navigate([_historyProfileUrl + driverId]);
          }
        });
      }
      else if (this.lineOfBusinessSelected === 7) //Delivery Contractors
      {
        this.router.navigate(["/dc/driverprofile/" + driverId])
      }
    }
    catch (err) {
      this.router.navigate([_historyProfileUrl + driverId]);
    }
  }

  private getClientSettings(): DriverClientSettings {
    const cSettings = this.clientSelectionService.getClientSettingsObjectValue();
    if (cSettings && cSettings[this.clientSelectedArray[0]]) {
      this.clientSettings = cSettings[this.clientSelectedArray[0]];
    } else {
      this.clientSettings = null;
    }
    return this.clientSettings;
  }

  private addRowStyles(worksheet: exceljs.Worksheet) {
    const defaultRowHeight = 25;
    const dataRows = worksheet.getRows(2, this.configObject.data.length);
    dataRows.forEach((row, index) => {
      row.border = {
        bottom: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
        top: { style: 'thin', color: { argb: '000000' } }
      };
      row.font = {
        color: { argb: '000000' },
        name: 'Arial',
        size: 9
      };
      row.fill = {
        fgColor: index % 2 === 0 ? { argb: 'A6A6A6' } : { argb: 'FFFFFF' },
        pattern: 'solid',
        type: 'pattern'
      };
      row.alignment = {
        horizontal: 'left',
        vertical: 'middle'
      };
      row.height = defaultRowHeight;
    });
  }

  private addColumnHeaderStyles(worksheet: exceljs.Worksheet) {
    const defaultColWidth = 20;
    const defaultRowHeight = 25;
    worksheet.columns.forEach((col) => {
      col.width = defaultColWidth;
    });

    const headerRow = worksheet.getRow(1);
    headerRow.border = {
      bottom: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } },
      top: { style: 'thin', color: { argb: '000000' } }
    };
    headerRow.fill = {
      fgColor: { argb: '800000' },
      pattern: 'solid',
      type: 'pattern'
    };
    headerRow.font = {
      color: { argb: 'FFFFFF' },
      name: 'Arial',
      size: 9
    };
    headerRow.alignment = {
      horizontal: 'left',
      vertical: 'middle'
    };
    headerRow.height = defaultRowHeight;
  }

}


export class DataGridFilter {
  dateField: string;
  filterField1: string;
  filterField2: string;
}
export class DataGridFilterValue {
  fromDate: Date;
  toDate: Date;
  filterDef: DataGridFilter
  filterVal1: any;
  filterVal2: any;
}
