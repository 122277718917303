<safety-additional-drivers-full *ngIf="nonEmployeeProfiling"
                      [driverProfile]="driverProfile"
                      [secondaryDrivers]="driverProfile.secondaryDrivers||[]"
                      [allowMultipleNonEmployees]="allowMultipleNonEmployees"
                      [_userRights]="_userRights">
</safety-additional-drivers-full>
<safety-additional-drivers-not-full *ngIf="!nonEmployeeProfiling"
                                [primaryDriverProfile]="driverProfile"
                                [secondaryDrivers]="driverProfile.secondaryDrivers||[]"
                                [arrReqFields]="arrReqFields||[]"
                                [allowMultipleNonEmployees]="allowMultipleNonEmployees"
                                [_userRights]="_userRights"
                                [clientSettings]="clientSettings">
</safety-additional-drivers-not-full>
