<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
  <div class="card card-body">
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label class="lbl-start-date">Begin</mat-label>
          <input class="input-date" matInput disabled 
            [matDatepicker]="beginDatePicker" 
            [formControl]="beginDateControl" 
            [min]="actualDate"
            [(ngModel)]="beginDate"
            (click)="beginDatePicker.open()">
          <mat-datepicker-toggle matSuffix [for]="beginDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #beginDatePicker [disabled]="startDateReadOnly">
            <mat-datepicker-actions>
              <button mat-button (click)="beginDatePicker.select(actualDate); beginDatePicker.close()">
                <mat-icon>history</mat-icon>
                Today
              </button>
              <button mat-button (click)="beginDatePicker.select(null); beginDatePicker.close()">
                <mat-icon>highlight_off</mat-icon>
                Clear
              </button>
            </mat-datepicker-actions> 
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label class="lbl-start-date">Return</mat-label>
            <input class="input-date" matInput disabled 
              [matDatepicker]="returnDatePicker" 
              [formControl]="returnDateControl" 
              [min]="actualDate"
              [(ngModel)]="returnDate"
              (click)="returnDatePicker.open()">
            <mat-datepicker-toggle matSuffix [for]="returnDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #returnDatePicker disabled="false">
              <mat-datepicker-actions>
                <button mat-button (click)="returnDatePicker.select(actualDate); returnDatePicker.close()">
                  <mat-icon>history</mat-icon>
                  Today
                </button>
                <button mat-button (click)="returnDatePicker.select(null); returnDatePicker.close()">
                  <mat-icon>highlight_off</mat-icon>
                  Clear
                </button>
              </mat-datepicker-actions> 
            </mat-datepicker>
          </mat-form-field>
      </div>
    </div>
    <div class="row" style="padding-top:15px;">

    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-default"
          (click)="submit()">
    Schedule
  </button>
  <button class="btn btn-default" style="margin-bottom:6px" 
          (click)="cancel()">
    Exit
  </button>
</div>
