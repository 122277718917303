<ul class="customSelectorContainer">
  <li class="waves-effect customSelectorRow" *ngFor="let item of customModuleChoices">
    <div>
      <!--{{item.clientName}}-->
      <input type="radio"
             class="input-dark"
             name="custom-module-choice"
             id="custom-module-choice-{{item.clientId}}"
             value="{{item.appUrl}}"
             checked="{{item.checked}}"
             (change)="processCustomModuleSelection(item.clientId)" />
      <label class="label-base" for="custom-module-choice-{{item.clientId}}">{{item.clientName}}</label>
    </div>
  </li>
</ul>
