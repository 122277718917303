<div class="row" >
    <div class="col-12">
        <h4 class="card-title text-muted wrap-this" style="padding-bottom:20px;">Confirm</h4>

        <div class="row" *ngIf="selectedDriver && selectedDhp">
            <div class="col-md-6 col-12">
                <div class="card panel-separator">
                    <div class="card-header card-panel-header">
                         Final Output
                    </div>
                    <div class="card-body min-height-300">
                        <div class="row mb-2">
                            <div class="col-12 col-md-4">
                                Driver ID
                            </div>
                            <div class="col-12 col-md-8 located-driver-text">
                                {{selectedDriver.driverId}}
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-4">
                                Employee ID
                            </div>
                            <div class="col-12 col-md-8 located-driver-text">
                                {{selectedDriver.employeeId}}
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-4">
                                Name
                            </div>
                            <div class="col-12 col-md-8 located-driver-text">
                                {{selectedDriver.fullName}} 
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-4">
                                Client Code
                            </div>
                            <div class="col-12 col-md-8 located-driver-text">
                                {{selectedDhp.dhpClient}}
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-4">
                                DHP Group
                            </div>
                            <div class="col-12 col-md-8 located-driver-text">
                                {{selectedDhp.element}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
