//Angular
import { Component, Input, Output, ViewChild, EventEmitter, Inject } from '@angular/core';
//Third Party
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//APP
import { SafetyModalWindowService } from './safety-modal-window-service.component';
import { DriverHistoryProfileModal } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

@Component({
  selector: "safety-modal-window",
  templateUrl: "./safety-modal-window.component.html",
  styleUrls: ["./safety-modal-window.component.scss"],
})
export class SafetyModalWindowComponent {
  safetyModalSubscription: Subscription;
  safetyModalSubscriptionClose: Subscription;
  title: string;
  htmlBody: string;
  modalWidth: string;
  modalHeight: string;
  modalInput: any;

  // constructor
  constructor(
    private safetyModalWindowServiceService: SafetyModalWindowService,
    @Inject(MAT_DIALOG_DATA)
    public data: { modalInfo: DriverHistoryProfileModal },
    private dialogRef: MatDialogRef<SafetyModalWindowComponent>
  ) {
    this.title = data.modalInfo.title;
    this.htmlBody = data.modalInfo.message;
    this.modalWidth = data.modalInfo.modalWidth;
    this.modalHeight = data.modalInfo.modalHeight;
    this.modalInput = data.modalInfo.modalInput;

    // closes the modal using a signal passed to it from the safety-modal-window-service layer
    this.safetyModalSubscriptionClose =
      safetyModalWindowServiceService.alertSafetyModalClose$.subscribe((a) => {
        this.closeModal();
      });
  }

  // hides the modal
  closeModal() {
    this.dialogRef.close();
  }
}
