import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { AzureDocument } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DocumentLibraryService } from '../../shared/document-library/document-library.service';
import { ConfirmModalComponent } from "../../components/confirm-modal/confirm-modal.component";
import { AddDocumentComponent } from '../../modals/add-document/add-document.component';
import { DocumentUploadAction } from '../../enum/document-upload-azure.enum';
import { AddDocumentService } from '../../services/add-document-service/add-document.service';

@Component({
  templateUrl: './document-library-admin.component.html',
  styleUrls: ['./document-library-admin.component.scss']
})
export class DocumentLibraryAdminComponent implements OnInit {
  @ViewChild(ConfirmModalComponent) confirmModalContainer: ConfirmModalComponent;
  documents: AzureDocument[] = [];
  companySelectedObject: any = [];
  docToDelete: AzureDocument = null;
  showDocumentPanel: boolean = false;
  lineofBusinesssId: string = "2"; // when more business lines, make it selectable from screen
  baseUrl: string;
  docUploadAzureRef: any;
  documentUploadActions = DocumentUploadAction;

  constructor(private errorService: ErrorModalService,
    @Inject('BASE_URL') baseUrl: string,
    private http: HttpClient,
    private DocumentLibraryService: DocumentLibraryService,
    public addDocumentService: AddDocumentService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  ngOnInit(): void {
    this.companySelectedObject["clientShortName"] = "";
  }

  initPage(companyObject: any) {
    this.companySelectedObject = companyObject;
    this.documents = [];
    this.loadDocuments();
  }

  private loadDocuments(): void {
    this.DocumentLibraryService.getDocs(this.companySelectedObject["clientShortName"], this.lineofBusinesssId)
      .subscribe({
        next: (data) => {
          this.documents = data;
          this.toggleDocumentPanel(true);
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
        }
      });
  }

  public toggleUploadPanel(action: DocumentUploadAction): void {
    const dialogRef = this.dialog.open(AddDocumentComponent, {
      data: { documents: this.documents, clientShortName: this.companySelectedObject["clientShortName"] },
      width: '54em',
      height: '34em',
      panelClass: 'add-document-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.refreshDocInfo();
    })
  }

  public confirmRemoveDocument(doc: AzureDocument) {
    this.docToDelete = doc;
    this.showConfirmModal();
  }

  showConfirmModal(): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: { confirmTitle: "Delete Document", confirmPrompt: `Are you sure you want to delete this document entitled '${this.docToDelete?.title}'?` },
      width: '34em',
      minHeight: '13em',
      panelClass: 'alert-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.deleteDoc();
    })
  }

  showDoc(document: AzureDocument) {
    this.addDocumentService.showDoc(document.clientDocumentId).subscribe({
      next: () => {
        this.snackBar.open('Document shown Sucessfully', 'Ok', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5000,
          panelClass: 'success-snackbar'
        })
        this.refreshDocInfo()
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject({ message: "There was an error changing the status to show." });
      }
    });
  }

  hideDoc(document: AzureDocument) {
    this.addDocumentService.hideDoc(document.clientDocumentId).subscribe({
      next: () => {
        this.snackBar.open('Document Hidden Successfully', 'Ok', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 55000,
          panelClass: 'success-snackbar'
        })
        this.refreshDocInfo()
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject({ message: "There was an error changing the status to hide." });
      }
    });
  }

  deleteDoc() {
    this.addDocumentService.deleteDoc(this.docToDelete.clientDocumentId).subscribe({
      next: () => {
        this.snackBar.open('Document Library Deleted Successfully', 'Ok', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 55000,
          panelClass: 'success-snackbar'
        })
        this.refreshDocInfo();
        this.docToDelete = null;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject({ message: "There was an error deleting the file." });
      }
    });
  }

  public toggleDocumentPanel(showDocumentPanel: boolean) {
    this.showDocumentPanel = showDocumentPanel;
  }

  public refreshDocInfo() {
    this.loadDocuments();
  }

}
