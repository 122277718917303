//Angular
import { Component, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router'
//Third Party
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDatepicker } from '@angular/material/datepicker';
//App
import { DataGridFilterValue } from '../../components/data-grid/data-grid.component'
import { CustomReportsModalService } from './custom-reports-filter-modal.service'
import { ClientSelectionService} from '../../components/client-selection-service/client-selection-service.component'
import { DateFilterService } from '../../components/date-filter-service/date-filter-service.component';
import { DashboardDateFilterRange } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { CustomReportGridRequest } from '../custom-report-grid/custom-report-grid.service';
import { ScrollToTopService } from '../../shared/scroll-to-top-service/scroll-to-top.service';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'custom-reports-grid-filter-modal',
  templateUrl: './custom-reports-filter-modal.component.html',
  styleUrls: ['./custom-reports-filter-modal.component.scss']
})
export class CustomReportsFilterModalComponent implements OnInit, OnDestroy {
  @ViewChild('startDatePicker') startDatePicker: MatDatepicker<Date>;
  @ViewChild('endDatePicker') endDatePicker: MatDatepicker<Date>;
  baseUrl: string;
  today: string;
  dataGridId: number;
  errorMsg: string = "";
  actualDate = new Date();

  clientSelectionSubscription: Subscription;
  //clientIdSelectionSubscription: Subscription;
  clientSelectedArray: Array<string>;
  clientIdSelectedArray: Array<number>;
  globalDateFilter: DashboardDateFilterRange;
  globalDateFilterSubscription: Subscription;
  lineOfBusinessSubscription: Subscription;
  startDate: string;
  endDate: string;
  reportId: number;
  filter: DataGridFilterValue;
  lineOfBusiness: number;
  public reportTitle: string;
  selectedDateField: string = "DateReported";
  DateFieldOptions = [{
    label: "Date Reported", value: "DateReported"
  },
  {
    label: "Date of Loss", value: "DateOfLoss"
  }];
  constructor(private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string, 
    private route: ActivatedRoute,
    private clientSelectionService: ClientSelectionService,
    private reportRequest: CustomReportGridRequest,
    private modalService: CustomReportsModalService,
    private dateFilterService: DateFilterService,
    private lineOfBusinessService: LineOfBusinessService,
    private datePipe: DatePipe,
    private loadingSpinnerService: LoadingSpinnerService,
    private scrollToTopService: ScrollToTopService,
    @Inject(MAT_DIALOG_DATA) public data: {reportId: number, reportName: string, title: string},
    private readonly dialogRef: MatDialogRef<CustomReportsFilterModalComponent>) {
    this.http = http;
    this.baseUrl = baseUrl;

    // Sets the line of business
    this.lineOfBusiness = this.lineOfBusinessService.getLineOfBusinessValue();

    // Gets today's date
    this.today = new Date().toDateString();
    
    this.PopulateClientsSelected();
    this.clientSelectionSubscription = clientSelectionService.clientSelectedInfo$.subscribe(
      clientSelectedInfo => {
        const temp = clientSelectedInfo;
        if (temp[this.lineOfBusiness]) {
          this.clientSelectedArray = temp[this.lineOfBusiness].clientSelectedArray;
          this.clientIdSelectedArray = temp[this.lineOfBusiness].clientIdSelectedArray;
        }
      });
    /*
    this.clientIdSelectedArray = this.clientSelectionService.getClientIdSelectedArrayValue();
    this.clientIdSelectionSubscription = clientSelectionService.clientIdSelectedArray$.subscribe(
      clientIdSelectedArray => {
        this.clientIdSelectedArray = clientIdSelectedArray as Array<number>;
      });
    */
  }

  // the initialization routine
  ngOnInit(): void {
    this.startDate = null;
    this.endDate = null;
    this.reportId = this.data.reportId;
    this.reportTitle = this.data.title;
    this.initModalInfo();
  }

  ngAfterViewInit(){
    this.startDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.startDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.startDatePicker.select(event.value);
            this.startDatePicker.close();
        })
    },0)
    })

    this.endDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.endDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.endDatePicker.select(event.value);
            this.endDatePicker.close();
        })
    },0)
    })
  }

  ngOnDestroy() {
    this.clientSelectionSubscription.unsubscribe();
    this.lineOfBusinessSubscription.unsubscribe();
  }

  // shows the modal dialog box
  initModalInfo() {
    this.startDate = "";
    this.endDate = "";
    this.lineOfBusiness = this.lineOfBusinessService.getLineOfBusinessValue();

    this.dateFilterService.getGlobalDateFilter(this.lineOfBusiness).subscribe(data => {
      this.globalDateFilter = data as DashboardDateFilterRange;
      try {
        this.startDate = this.globalDateFilter.startDate;
        this.endDate = this.globalDateFilter.endDate
      }
      catch (ex) {
        console.log(ex);
      }
    });
    
    this.lineOfBusinessSubscription = this.lineOfBusinessService.lineOfBusinessSelected$.subscribe(data => {
      this.lineOfBusiness = data;
      this.PopulateClientsSelected();
    });
  }

  // on close event
  onCloseHandled() {
    this.dialogRef.close();
  }

  // validates the form data
  private validateForm(): boolean {
    return this.validateDates(this.startDate, this.endDate);
  }

  private PopulateClientsSelected(): void {
    this.clientSelectedArray = this.clientSelectionService.getClientSelectedArrayValue(this.lineOfBusiness);
    this.clientIdSelectedArray = this.clientSelectionService.getClientIdSelectedArrayValue(this.lineOfBusiness);
  }

  // validates the start and end date
  private validateDates(startDate: string, endDate: string): boolean {
    const dt1 = new Date(Date.parse(startDate));
    const dt2 = new Date(Date.parse(endDate));
    const differenceDays = Math.floor((
        Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) 
      - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())
      ) / (1000 * 60 * 60 * 24));
    if (differenceDays >= 0) {
      return true;
    }
    else {
      return false;
    }
  }

  GetSelectedDateFieldDisplayValue(selectedValue: string): string {
    let result = "";
    this.DateFieldOptions.forEach(o => {
      if (o.value == selectedValue) {
        result = o.label;
      }
    });
    return result;
  }

  // generates the report
  onGenerateReport() {
    this.loadingSpinnerService.show();
    this.clientSelectedArray = this.clientSelectionService.getClientSelectedArrayValue(this.lineOfBusiness);
    if (this.validateForm()) {
      this.reportRequest.reset();
      this.reportRequest.ReportId = this.reportId;
      this.reportRequest.Clients = this.clientSelectedArray.join(",");
      this.reportRequest.StartDate = new Date(Date.parse(this.startDate));
      this.reportRequest.EndDate = new Date(Date.parse(this.endDate));
      this.reportRequest.DateField = this.selectedDateField;
      this.reportRequest.DateFieldDisplayValue = this.GetSelectedDateFieldDisplayValue(this.selectedDateField);
      console.log(this.reportRequest);

      try {
        this.scrollToTopService.scrollTop();

        let reportRequestParam = btoa(JSON.stringify(this.reportRequest));
        this.router.navigate(['/customReports/' + encodeURIComponent(reportRequestParam)]);
      } catch (e) {
        this.onCloseHandled();
        this.loadingSpinnerService.hide();
      }
    }
    else {
      this.errorMsg = "Please validate all fields.";
      this.loadingSpinnerService.hide();
      console.log("Form validation fail");
      return;
    }
    this.onCloseHandled();
  }
}

