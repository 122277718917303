import { Component, OnInit, Input } from '@angular/core';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { UserRightsService } from '../../components/user-rights-service/user-rights-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { SafetyModalWindowService } from '../safety-modal-window/safety-modal-window-service.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { UserRightsInfo, ErrorObject } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';
import { DriverMaintenanceReportService } from './driver-maintenance-report.service';
import { formatDate } from 'ngx-bootstrap/chronos';
import { Router } from '@angular/router';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'driver-maintenance-report',
  templateUrl: './driver-maintenance-report.component.html',
  styleUrls: ['./driver-maintenance-report.component.css']
})
export class DriverMaintenanceReportComponent implements OnInit {
  @Input() modalHeight: string;
  @Input() modalInput: any;
  clientSelectedArray: Array<string>;
  clientIdSelectedArray: Array<number>;
  lineOfBusinessId: number;
  baseUrl: string;
  private driverId: string;
  driverBaseInfo: any;
  _userRights: Array<UserRightsInfo>;
  loaNotFound: boolean = false;
  canTerminateDriver: boolean = false;
  isActiveLeave: boolean = false;
  // date vars for yesterday and today, with time stripped out
  // correct values are set in constructor
  private dtYesterday: Date = new Date((new Date()).toDateString());
  private dtTomorrow: Date = new Date((new Date()).toDateString());

  public page: number = 1;
  public itemsPerPage: number = 50;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;
  public today: Date = new Date(Date.now());
  public sortPageTime: number = 0;
  public dateFilterRangeType: number = 1;
  columns = [];
  keepDrivers = [];
  removeDrivers = [];

  rowsRaw: Array<any>;
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  gtOptions: any = { numberOfRows: 500 };
  public configObject: any;

  constructor(private loadingSPinnerService: LoadingSpinnerService,
    private clientSelectionService: ClientSelectionService,
    private lineOfBusinessService: LineOfBusinessService,
    private userRightsService: UserRightsService,
    private errorService: ErrorModalService,
    private driverHistoryProfileService: DriverHistoryProfileService,
    private safetyModalWindowService: SafetyModalWindowService,
    private driverMaintenanceService: DriverMaintenanceReportService,
    private router: Router,
    private datepipe: DatePipe) {
    this.dtYesterday.setDate(this.dtYesterday.getDate() - 1);
    this.dtTomorrow.setDate(this.dtTomorrow.getDate() + 1);
  }

  ngOnInit(): void {
    // set line of business to safety if not already initialized to Safety
    this.lineOfBusinessId = 2;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    // get the selected client(s)
    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusinessId);

    this.initAll();


  }

  private initAll() {

    this.loadingSPinnerService.show();
    this.driverMaintenanceService.getDriverMaintenanceReport("BI1").subscribe({
      next: (data: any) => {
        this.loadingSPinnerService.hide();
        //console.log(data);
        if (data && data.datasets) {
          if (data.data instanceof HttpErrorResponse) {
            this.errorService.setErrorObject(data.data.error as ErrorObject);
            return;
          }


          this.columns = data.datasets[0].columns.map(x => {
            return {
              prop: x.propertyName.replace(/^\w/, c => c.toLowerCase()),
              name: x.propertyName,
              title: x.displayName
            };
          });

          let extraColumns = [{ name: "Keep", prop: "keep", title: 'Keep', sort: null, visible: true, dataType: null },
          { name: "Remove", prop: "remove", title: 'Remove', sort: null, visible: true, dataType: null },]
          this.columns = this.columns.concat(extraColumns);
          this.rowsRaw = (data.datasets[0].rows as any[]).slice(0, 50);


          var fields = new Array<any>();
          this.columns.forEach(s => {
            if (s.prop.toLowerCase() == "driverid") {
              //do nothing
            }
            else if (s.prop.toLowerCase() == "employeeid") {
              fields.push({
                name: s.title,
                objectKey: s.prop,
                columnClass: 'clickable',
                hidden: !s.visible,
                click: row => {
                  this.openPopup(row);
                }
              });
            }
            else
              fields.push({
                name: s.title,
                objectKey: s.prop,
                hidden: !s.visible,
                type: s.prop === 'keep' || s.prop === 'remove' ? 'component' : 'text',
                component: this.setComponentValue(s.prop),
                componentObject: s.prop !== "keep" ? null : {},
                columnComponent: (s.prop === "keep" ?
                  { type: DriverMaintenanceReportKeepSwitchComponent } :
                  (s.prop === "remove" ? { type: DriverMaintenanceReportRemoveSwitchComponent } : null)),
                render: row => {

                  if (s.dataType === "currency") {
                    var formattedCurrencyValue = this.formatter.format(Math.abs(row[s.prop]))
                    if (row[s.prop] >= 0)
                      return formattedCurrencyValue;
                    else {
                      return "($" + formattedCurrencyValue + ")";
                    }
                  }
                  else if (s.dataType === "date" && row[s.prop]) {
                    try {
                      return formatDate(new Date(row[s.prop]), "MM/DD/YYYY");
                    }
                    catch (err) {
                      return row[s.prop];
                    }
                  }
                  else if (row[s.prop] != null && Boolean(row[s.prop])) {
                    return (row[s.prop] as boolean) ? "Yes" : "No";
                  }
                  else
                    return row[s.prop]
                },
                value: row => {
                  let val = row[s.prop];

                  if (s.prop === "keep") {
                    val = false;
                  }
                  else if (s.prop === "remove") {
                    val = false;
                  }
                  return val;
                },

              });
          });

          var settings = this.columns.map(function (s) {
            return {
              objectKey: s.prop,
              sort: 'enable'
            };
          }).filter(x => x.objectKey.toLowerCase() != "driverid");

          this.configObject = {
            settings: settings,
            fields: fields,
            data: data.datasets[0].rows,

          };
        } else {
          //this.invalidGridData = true;
        }
        this.loadingSPinnerService.hide();
      }, error: (err) => {
        this.errorService.setErrorObject(err);
        this.loadingSPinnerService.hide();
      }
    });

  }

  private setComponentValue(prop: string): string {
    let componentName = '';

    if (prop === 'keep') {
      componentName = 'driverMaintenanceReportKeepSwitch';
    }
    else if (prop === 'remove') {
      componentName = 'driverMaintenanceReportRemoveSwitchComponent';
    }
    else {
      componentName = null;
    }

    return componentName;
  }

  openPopup(row: any) {
    this.driverMaintenanceService.openDriverModal({
      driverId: row.driverId,
      employeeID: row.employeeID,
      employeeName: row.employeeName,
      active: row.active,
      corporateStructure: row.division + "|" + row.region + "|" + row.district + "|" + row.costCenter
    })
    this.router.navigateByUrl("/safety/drivermaintenance-update/" + row.driverId);
  }


  openSubmitSelectedDrivers() {
    //this.driverMaintenanceService.submitSelectedDrivers(this.keepDrivers, this.removeDrivers).subscribe(result => {
    //  console.log("success.");
    //  this.initAll();
    //});

    this.loadingSPinnerService.show();
    //let driverIds = this.configObject.data.filter(d => d.keep).map(d => d.driverId).join(",");

    this.keepDrivers = this.configObject.data.filter(d => d.keep);
    this.removeDrivers = this.configObject.data.filter(d => d.remove);
    if (this.keepDrivers.length > 0 || this.removeDrivers.length > 0) {

      //console.log(this.keepDrivers);
      //this.driverMaintenanceService.submitSelectedDrivers(this.clientSelectedArray[0], this.keepDrivers, this.removeDrivers).subscribe(result => {
      //  //console.log("success.");
      //  this.initAll();
      //});
      if (this.keepDrivers.length > 0 && this.removeDrivers.length > 0) {
        this.driverMaintenanceService.submitKeepDrivers(this.clientSelectedArray[0], this.keepDrivers).subscribe({
          next: () => {
            this.driverMaintenanceService.submitRemoveDrivers(this.clientSelectedArray[0], this.removeDrivers).subscribe({
              next: () => {
                this.initAll();
                this.loadingSPinnerService.hide();
              },
              error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err);
                this.loadingSPinnerService.hide();
              }
            });
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err);
            this.loadingSPinnerService.hide();
          }
        });
      }
      else if (this.keepDrivers.length > 0) {
        this.driverMaintenanceService.submitKeepDrivers(this.clientSelectedArray[0], this.keepDrivers).subscribe({
          next: () => {
            this.initAll();
            this.loadingSPinnerService.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err);
            this.loadingSPinnerService.hide();
          }
        });
      }
      else if (this.removeDrivers.length > 0) {
        this.driverMaintenanceService.submitRemoveDrivers(this.clientSelectedArray[0], this.removeDrivers).subscribe({
          next: () => {
            this.initAll();
            this.loadingSPinnerService.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err);
            this.loadingSPinnerService.hide();
          }
        });
      }
    } else {
      this.errorService.setErrorObject({ message: "Please set one or more Drivers" });
      this.loadingSPinnerService.hide();
    }
  }
}

// custom component for active/inactive switch
@Component({
  selector: `app-driver-maintenance-report-keep-switch`,
  template: `
    <div class="chbx">
      <input [checked]="row.keep" (change)="toggleIsActive(row.driverId, !row.keep)" type="checkbox" class="cbxOptOut" style="opacity:1;pointer-events:all;">
    </div>
  `
})
export class DriverMaintenanceReportKeepSwitchComponent {
  //@Input() clientSelectedArray: Array<string>;
  //@Input() gridRequestId: string;
  @Input() row: any;

  toggleIsActive(driverId: number, isActive: boolean) {
    this.row.keep = isActive;

    if (isActive)
      this.row.remove = false;
    /* 
    this.removedDriversService.reactivateDrivers(this.clientSelectedArray[0], this.row.driverId).subscribe(data => {
      this.router.navigate(["safety/removeddrivers/" + this.gridRequestId]);
    },
      (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
      });
      */
  }
}
// custom component for active/inactive switch
@Component({
  selector: `app-driver-maintenance-report-remove-switch`,
  template: `
    <div class="chbx">
      <input [checked]="row.remove" (change)="toggleIsActive(row.driverId, !row.remove)" type="checkbox" class="cbxOptOut" style="opacity:1;pointer-events:all;">
    </div>
  `
})
export class DriverMaintenanceReportRemoveSwitchComponent {
  //@Input() clientSelectedArray: Array<string>;
  //@Input() gridRequestId: string;
  @Input() row: any;

  toggleIsActive(driverId: number, isActive: boolean) {
    this.row.remove = isActive;
    if (isActive)
      this.row.keep = false;
    /* 
    this.removedDriversService.reactivateDrivers(this.clientSelectedArray[0], this.row.driverId).subscribe(data => {
      this.router.navigate(["safety/removeddrivers/" + this.gridRequestId]);
    },
      (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
      });
      */
  }
}
