import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { ClientSelectionObject, DriverClientSettings } from '../classes-and-interfaces/classes-and-interfaces.component';
import { OnPremDriverService } from '../on-prem-service/on-prem-driver-service.component';
import { ErrorModalService } from '../error-modal/error-modal-service.component';
import { shareReplay } from 'rxjs-compat/operator/shareReplay';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Injectable({
  providedIn: 'root'
})
export class ClientSelectionService {
  baseUrl: string;

  private clientSelectedInfo = new BehaviorSubject<ClientSelectionsObject>({});
  clientSelectedInfo$ = this.clientSelectedInfo.asObservable();
  private clientChoicesInfo = new BehaviorSubject<ClientChoicesObject>({});
  clientChoicesInfo$ = this.clientChoicesInfo.asObservable();
  private clientSettingsInfo = new BehaviorSubject<ClientSettingsObject>({});
  clientSettingsInfo$ = this.clientSettingsInfo.asObservable();
  private addClientsByShortName = new BehaviorSubject<ClientSelectionsObject>({});
  addClientsByShortName$ = this.addClientsByShortName.asObservable();
  private clientLogoSrc = new Subject();
  clientLogoSrc$ = this.clientLogoSrc.asObservable();

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private onPremService: OnPremDriverService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
    // for delivery contractor use FEDEX1 as the client selection
    this.applyClientSelection(['FEDEX1'], [], 7);
  }

  subscribeToClientSelectedInfo$() {
    return this.clientSelectedInfo.asObservable();
  }

  getClientChoices(LineOfBusinessId: number) {
    let params = new HttpParams();
    params = params.append('LineOfBusinessId', LineOfBusinessId.toString());

    return this.http.get(this.baseUrl + 'api/Client/Get', {
      params: params
    });
  }

  getClientReqTitles(client: string) {
    let params = new HttpParams();
    params = params.append('client', client);

    return this.http.get(this.baseUrl + 'api/Client/GetClientReqTitles', {
      params: params
    });
  }
  getClientSettings(clientSelected: string) {
    // 2019-11 - get stored client settings from ClientSelectionService observable
    const cSettings = this.getClientSettingsObjectValue();
    if (cSettings && cSettings[clientSelected]) {
      const clientSettings = cSettings[clientSelected];
      return of(clientSettings);
    } else {
      // do lookup if client settings not defined for this client
      return this.onPremService.get('lookup/clientSettings/' + clientSelected);
    }

  }

  getClientSelectionsObjectValue(): ClientSelectionsObject {
    return this.clientSelectedInfo.getValue();
  }

  getClientSettingsObjectValue(): ClientSettingsObject {
    return this.clientSettingsInfo.getValue();
  }

  getClientSelectedArrayValue(lineOfBusinessId: number) {
    if (lineOfBusinessId && (lineOfBusinessId > 0)) {
      const cInfo = this.clientSelectedInfo.getValue();
      if (cInfo[lineOfBusinessId]) {
        return cInfo[lineOfBusinessId].clientSelectedArray;
      } else if (lineOfBusinessId === 7) {
        return ['FEDEX1'];
      } else {
        return [];
      }
    } else {
      return [];
    }
    // return this.clientSelectedArray.getValue();
  }

  getClientIdSelectedArrayValue(lineOfBusinessId: number) {
    if (lineOfBusinessId && (lineOfBusinessId > 0)) {
      const cInfo = this.clientSelectedInfo.getValue();
      if (cInfo[lineOfBusinessId]) {
        return cInfo[lineOfBusinessId].clientIdSelectedArray;
      } else {
        return [];
      }
    } else {
      return [];
    }
    // return this.clientIdSelectedArray.getValue();
  }

  selectClients(clientShortNamesChosen: Array<string>, clientIdsChosen: Array<number>, lineOfBusinessId: number) {
    if (lineOfBusinessId && (lineOfBusinessId > 0)) {

      switch (lineOfBusinessId) {
        case 2:
          // for Safety, get client settings, then process client selection
          this.loadingSpinnerService.show();
          this.getClientSettings(clientShortNamesChosen.join(',')).subscribe({
            next: (data) => {
              const cSettingsInfo = this.clientSettingsInfo.getValue();
              cSettingsInfo[clientShortNamesChosen.join(',')] = data as DriverClientSettings;
              this.clientSettingsInfo.next(cSettingsInfo);
              this.loadingSpinnerService.hide();
              this.applyClientSelection(clientShortNamesChosen, clientIdsChosen, lineOfBusinessId);

            },
            error: (err: HttpErrorResponse) => {
              this.errorService.setErrorObject(err.error);
              this.loadingSpinnerService.hide();
            }
          });
          break;
        default:
          this.clientSettingsInfo.next({});
          this.applyClientSelection(clientShortNamesChosen, clientIdsChosen, lineOfBusinessId);
      }
    }

  }

  private applyClientSelection(clientShortNamesChosen: Array<string>, clientIdsChosen: Array<number>, lineOfBusinessId: number) {
    const cInfo = this.clientSelectedInfo.getValue();
    const cInfoLOB: ClientSelectedInfo = {
      lineOfBusiness: lineOfBusinessId,
      clientSelectedArray: clientShortNamesChosen,
      clientIdSelectedArray: clientIdsChosen
    };
    cInfo[lineOfBusinessId] = cInfoLOB;
    this.clientSelectedInfo.next(cInfo);
  }

  getSavedClientShortNames(lineOfBusinessId: number): Array<string> {
    const s: string = localStorage.getItem('FleetSuite_SavedClientShortNames_' + lineOfBusinessId.toString());
    if (s && (s.length > 0)) {
      return JSON.parse(s);
    } else {
      return [];
    }
  }

  setSavedClientShortNames(arr: Array<string>, lineOfBusinessId: number) {
    localStorage.setItem('FleetSuite_SavedClientShortNames_' + lineOfBusinessId.toString(), JSON.stringify(arr));
  }

  getSavedClientIds(lineOfBusinessId: number): Array<number> {
    const s: string = localStorage.getItem('FleetSuite_SavedClientIds_' + lineOfBusinessId.toString());
    if (s && (s.length > 0)) {
      return JSON.parse(s);
    } else {
      return [];
    }
  }

  setSavedClientIds(arr: Array<number>, lineOfBusinessId: number) {
    localStorage.setItem('FleetSuite_SavedClientIds_' + lineOfBusinessId.toString(), JSON.stringify(arr));
  }

  setAddClientsByShortName(arrClientShortName: Array<string>, lineOfBusinessId: number) {
    const addClientInfo = { arrClientShortName: arrClientShortName, lineOfBusinessId: lineOfBusinessId };
    this.addClientsByShortName.next(addClientInfo);
  }

  getAddClientsByShortName(lineOfBusinessId: number): any {
    const addInfo = this.addClientsByShortName.getValue();
    if (addInfo && (addInfo['lineOfBusinessId'] === lineOfBusinessId)) {
      return addInfo;
    } else {
      return null;
    }
  }

  setClientLogoSrc(logoSrc: string) {
    this.clientLogoSrc.next(logoSrc);
  }

  setClientChoicesInfo(lineOfBusiness: number, choices: Array<ClientSelectionObject>) {
    const cInfo = this.clientChoicesInfo.getValue();
    cInfo[lineOfBusiness] = choices;
    this.clientChoicesInfo.next(cInfo);
  }


}

export class ClientSelectedInfo {
  lineOfBusiness: number;
  clientSelectedArray: Array<string>;
  clientIdSelectedArray: Array<number>;
}

export class ClientSelectionsObject {
  [key: number]: ClientSelectedInfo;
}

export class ClientChoicesObject {
  [key: number]: Array<ClientSelectionObject>;
}

export class ClientSettingsObject {
  [key: string]: DriverClientSettings;
}
