//Angular
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
//Third Party
import { Subscription } from 'rxjs';
//App
import { ClientSelectionService, ClientSelectionsObject } from '../../components/client-selection-service/client-selection-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';

@Component({
  selector: 'app-safety-mvr-release-req',
  templateUrl: './safety-mvr-release-req.component.html',
  styleUrls: ['./safety-mvr-release-req.component.scss']
})
export class SafetyMvrReleaseRequirementsComponent implements OnInit, OnDestroy {
  baseUrl: string;
  mvrReleaseDocuments: Array<MvrReleaseDocument>;
  usaReleaseDocuments: Array<MvrReleaseDocument>;
  canadaReleaseDocuments: Array<MvrReleaseDocument>;

  clientSelectionSubscription: Subscription;
  clientSelectedArray: Array<string>;

  constructor(@Inject('BASE_URL') baseUrl: string,
    private clientService: ClientSelectionService,
    private onPremService: OnPremDriverService,
    private errorService: ErrorModalService) {
    this.baseUrl = baseUrl;
  }


  ngOnInit(): void {
    this.clientSelectionSubscription = this.clientService.clientSelectedInfo$.subscribe(data => {
      let temp = data as ClientSelectionsObject;
      if (temp[2]) {
        this.clientSelectedArray = temp[2].clientSelectedArray;

        this.getMvrReleaseDocs(this.clientSelectedArray[0]).subscribe({
          next: (mvrData) => {
            this.mvrReleaseDocuments = mvrData as Array<MvrReleaseDocument>;
            this.usaReleaseDocuments = this.mvrReleaseDocuments.filter(x => x.country == 'USA');
            this.canadaReleaseDocuments = this.mvrReleaseDocuments.filter(x => x.country == 'Canada');
          },
          error: (err: HttpErrorResponse) => {
            //this.toastrService.error(err.error.toString());
            this.errorService.setErrorObject(err.error);
          }
        });
      }
    })
  }

  ngOnDestroy(): void {
    this.clientSelectionSubscription.unsubscribe();
  }


  private getMvrReleaseDocs(client: string) {
    return this.onPremService.get('releaseforms/' + client);
  }
}

export interface MvrReleaseDocument {
  documentId: number;
  country: string;
  stateCode: string;
  stateName: string;
  verbiage: string;
  azureBlobUrl: string;
}
