//Angular
import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
//Third Party
import { Subscription } from "rxjs";
import { formatDate } from "ngx-bootstrap/chronos";
import * as moment from 'moment';
import * as exceljs from 'exceljs';
import * as FileSaver from 'file-saver';
//App
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { Router, ActivatedRoute } from "@angular/router";
import { AdvancedSearchRequest } from "./advanced-search-claims.service";
import { DataGridEmailService, } from "../../components/data-grid-export-email/data-grid-export-email.service";
import { FileDownloadService } from "../../shared/file-download/file-download.service";
import { LoadingSpinnerService } from "../../services/loading-spinner-service/loading-spinner.service";

@Component({
  selector: 'advanced-search-claims',
  templateUrl: './advanced-search-claims.component.html',
  styleUrls: ['./advanced-search-claims.component.scss']
})
export class AdvancedSearchClaimsComponent implements OnInit, OnDestroy {
  baseUrl: string;
  lineOfBusinessId: number;
  public page = 1;
  public itemsPerPage = 50;
  public maxSize = 5;
  public numPages = 1;
  public length = 0;
  public searchText: string;
  lineOfBusinessSubscription: Subscription;
  public configObject: any;

  public sortPageTime = 0;
  columns = [];
  rowsRaw: Array<any>;
  rows: Array<any>;
  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: {}
  };
  today: any;

  constructor(private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private route: ActivatedRoute,
    private searchRequest: AdvancedSearchRequest,
    private emailService: DataGridEmailService,
    private fileDownloadService: FileDownloadService,
    private lineOfBusinessService: LineOfBusinessService,
    private readonly loadingSpinnerService: LoadingSpinnerService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;

    this.today = Date.now();
    this.lineOfBusinessSubscription = lineOfBusinessService.lineOfBusinessSelected$.subscribe(data => {
      this.lineOfBusinessId = data as number;
    });
  }

  ngOnInit(): void {
    this.lineOfBusinessId = this.lineOfBusinessService.getLineOfBusinessValue();
    this.route.data
      .subscribe((data) => {
        this.columns = data.data.columns;
        this.rowsRaw = data.data.rows;
        var fields = new Array<any>();

        this.columns.forEach(s => {
          if (this.lineOfBusinessId === 1 && s.name === "Claim") {
            fields.push({
              name: s.title,
              objectKey: s.name,
              columnClass: 'clickable',
              click: row => {
                this.router.navigate(["/accident/claim/" + row[s.name]]);
              }
            });
          }
          else if (this.lineOfBusinessId === 2 && s.name === "DriverId") {
            fields.push({
              name: s.title,
              objectKey: s.name,
              columnClass: 'clickable',
              click: row => {
                this.router.navigate(["/driver/history-profile/" + row[s.name]]);
              }
            });
          }
          else
            fields.push({
              name: s.title,
              objectKey: s.prop,
              dataType: s.dataType,
              render: row => {
                if (s.dataType === "currency") {
                  if (row[s.prop] >= 0)
                    return "$" + row[s.prop];
                  else
                    return "($" + (row[s.prop] * -1) + ")";
                }
                else if (s.dataType === "date" && row[s.prop]) {
                  try {
                    return formatDate(new Date(row[s.prop]), "MM/DD/YYYY");
                  }
                  catch (err) {
                    return row[s.prop];
                  }
                }
                else
                  return row[s.prop]
              }
            });
        });

        var settings = this.columns.map(function (s) {
          return {
            objectKey: s.name,
            sort: 'enable'
          };
        });
        this.configObject = {
          settings: settings,
          fields: fields,
          data: data.data.rows
        };
        console.log(this.configObject);
        if (!data.data.totals || data.data.totals.length == 0) {
          this.configObject.totals = [];
        }
      });
    this.onChangeTable(this.config);
    this.loadingSpinnerService.hide();
  }

  ngOnDestroy() {
    this.lineOfBusinessSubscription.unsubscribe();
  }

  public changePage(page: any, data: Array<any> = this.rowsRaw): Array<any> {
    let start = (page.page - 1) * this.itemsPerPage;
    let end = this.itemsPerPage > -1 ? (start + this.itemsPerPage) : data.length;
    console.log(this.itemsPerPage);
    console.log(start + "-" + end);
    return data.slice(start, end);
  }

  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    let columns = this.config.sorting.columns || [];
    let columnName = '';
    let sort = '';

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '') {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }



    if (!columnName) {
      return data;
    }


    // simple sorting
    return data.sort((previous: any, current: any) => {
      if (previous[columnName] > current[columnName]) {
        return sort === 'desc' ? -1 : 1;
      } else if (previous[columnName] < current[columnName]) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }
  public onChangeTable(config: any, page: any = { page: 1 }): any {
    var t0 = performance.now();


    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }
    if (config.sorting) {
      Object.assign(this.config.sorting, config.sorting);
    }
    this.itemsPerPage = Number(this.itemsPerPage);
    //let filteredData = this.changeFilter(this.rows, this.config);
    let sortedData = this.changeSort(this.rowsRaw, this.config);
    this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData;
    this.length = this.rowsRaw.length;

    var t1 = performance.now();
    this.sortPageTime = t1 - t0;
    console.log("Call to onChangeTable took " + (t1 - t0) + " milliseconds.")

  }

  public onCellClick(event: any): void {
    if (event.column == "Claim") {
      this.router.navigate(["/accident/claim/" + event.row.Claim]);
    }
  }

  onExcel() {
    const workbook = new exceljs.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    let dbColumns;
    const searchString = " ";
    const replaceWith = "_";
    
    this.columns.forEach((element) => {
      if (dbColumns === undefined) {
        dbColumns = [{header: element['name'], key: element['name'].replace(new RegExp(searchString, 'g'), '')}]
      }
      else {
        dbColumns.push({header: element['name'], key: element['name'].replace(new RegExp(searchString, 'g'), '')}) 
      }
    })
    worksheet.columns = dbColumns;
    this.configObject.data.forEach((element) => {
      const rowValue = {};
      dbColumns.forEach((columns) => { 
        rowValue[columns['key']] = element[columns['header']]
      })
      worksheet.addRow(rowValue);
    })
    const fileName = `Visibility-AdvancedSearch-${moment().format("MM-DD-YYYY")}.xlsx`.replace(new RegExp(searchString, 'g'), replaceWith);
    workbook.xlsx.writeBuffer().then(function (data) {
      var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      FileSaver.saveAs(blob, fileName)
    })

  }

  onPrint() {
    let requestParam = btoa(JSON.stringify(this.searchRequest));
    this.fileDownloadService.openTab(this.baseUrl + "api/ClaimsSearch/Print?request=" + encodeURIComponent(requestParam));
  }

  onEmail() {
    this.emailService.OpenSearchEmailPopup(this.searchRequest);
  }

  trigger($event) {
    switch ($event.name) {
      case 'gt-sorting-applied':
        console.log($event.value);
        if ($event.value && $event.value[0] !== '$$gtInitialRowIndex') {

          if (($event.value[0] as string).startsWith('-')) {
            this.searchRequest.SortField = ($event.value[0] as string).slice(1);
            this.searchRequest.SortDir = "DESC"
          }
          else {
            this.searchRequest.SortField = $event.value[0]
            this.searchRequest.SortDir = "ASC"
          }
          let fld = this.configObject.fields.find(x => x.objectKey == this.searchRequest.SortField)
          //this.gridRequest.SortDir = ($event.value[0] as string).startsWith('-') ? "DESC": "ASC";
          if (fld)
            this.searchRequest.SortDisplayField = fld.name;
          //this.gridRequest.SortField = $event.value[0];
          //console.log(this.gridRequest)
        }
        else {
          this.searchRequest.SortDir = "";
          this.searchRequest.SortDisplayField = "";
          this.searchRequest.SortField = "";
        }
        break;
    }
  }

  sortChange($event): void {
    if (!$event?.active) {
      this.searchRequest.SortDir = "";
      this.searchRequest.SortDisplayField = "";
      this.searchRequest.SortField = "";
    } else {
      this.searchRequest.SortField = $event?.active || null;
      this.searchRequest.SortDir = $event?.direction?.toUpperCase() || null;
      const fld = this.configObject.fields.find(x => x.objectKey === this.searchRequest.SortField);
      if (fld) {
        this.searchRequest.SortDisplayField = fld.name;
      }
    }
  }
}
