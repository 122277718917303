<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row widgetListWrapper" >
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-9">
        <h3>StatCenter Report List</h3>
      </div>
      <div class="col-sm-3">
        <button type="button"
                (click)="addNewDataGrid()"
                class="btn btn-primary">
          Add New
        </button>
      </div>
    </div>
    <div class="row" style="padding:20px">
      <div class="col-sm-12">
         <mat-form-field appearance="fill" class="select-container w-100">
          <mat-label>Choose Line Of Business</mat-label>
          <mat-select [(value)]="lineOfBusinessFilter" (selectionChange)="applyLineOfBusinessFilter(lineOfBusinessFilter)">
            <mat-option *ngFor="let option of lineOfBusinessChoices" [value]="option.value">
              {{option.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <label>Filter By Line Of Business</label>        
      </div>
    </div>
    <ng-container *ngFor="let w of dataGridListFiltered">
      <div class="row widgetInfoRow">
        <div class="col-sm-2">
          <div class="switch">
            <label>
              <input [checked]="w.isActive" (change)="w.isActive=!w.isActive;enableDataGrid(w.dataGridId, w.isActive);" type="checkbox">
              <span class="lever enableWidgetSwitch"></span>
              <span class="enableWidgetLabel">{{w.isActive?"Enabled":"Disabled"}}</span>
            </label>
          </div>
        </div>
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-4"><h5>{{w.title}}</h5></div>
            <div class="col-sm-8">
              <a [routerLink]="['/admin/datagridadminedit',w.dataGridId]">
                Edit Report
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">{{w.description}}</div>
          </div>
        </div>
        <div class="col-sm-1">
          <button type="button" class="close dataGridAdminListRemoveButton " aria-label="Remove" (click)="confirmRemoveDataGrid(w)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
