<div class="quickSearchContainerDataGrid">
  <quick-search-menu></quick-search-menu>
</div>
<div class="dataGridContainer" *ngIf="!invalidGridData">
  <navbar class="navbar navbar-expand-lg navbar-dark dashboardFilterPanelActionBar" [containerInside]="false">
    <ul class="nav navbar-nav nav-flex-icons ml-auto">
      <li class="nav-item">
        <a (click)="onPrint()">
          <span class='fa fa-print'></span> Print
        </a>
      </li>
      <li class="nav-item">
        <a (click)="onExcel()">
          <span class='fa fa-file-excel-o'></span> Export to Excel
        </a>
      </li>
      <li class="nav-item">
        <a (click)="onEmail()">
          <span class='fa fa-envelope'></span>
          Export to Email
        </a>
      </li>
    </ul>
  </navbar>

  <app-data-grid-export-email [lineOfBusiness]="lineOfBusinessSelected"></app-data-grid-export-email>

  <H3>{{grid?.title}}</H3>
  <div>
    <div *ngIf="gridRequest?.StartDate && gridRequest?.EndDate">
      <span *ngIf="dateFilterRangeType==4 || dateFilterRangeType== 2">{{gridRequest?.StartDate | date :  "MM/dd/y" }} </span>
      <span *ngIf="dateFilterRangeType==4 || dateFilterRangeType== 3">
        <span *ngIf="dateFilterRangeType==4 && gridRequest !== undefined &&  gridRequest.EndDate !== undefined && gridRequest.EndDate.toString() != ''"> - </span>
        {{gridRequest?.EndDate | date :  "MM/dd/y"}}

      </span>
    </div>
    <!--{{filter?.fromDate}} - {{filter?.toDate}} <br />



    Date Range Based On: Date of Loss-->

    <div *ngIf="gridRequest?.ParameterValues?.length > 0 && gridRequest?.clickTrough == false">

      <!--Filters:-->
      <div *ngFor="let filter of gridRequest?.ParameterValues">
        {{(filter?.DisplayName) ? filter.DisplayName : filter.ParmameterName}}: {{filter.DisplayValue}}
      </div>
    </div>
    <!--<div>
      Report generated: {{today | date :'short' }}
    </div>-->
  </div>
  <div *ngIf="rowsRaw?.length > 0">
    <app-data-table 
      #myTable
      [columns]="configObject.fields"
      [data]="configObject.data"
      [showPagination]="true"
      [showPageSize]="true"
      (triggerSortChange)="sortChange($event)"
    >
    </app-data-table>

    <div id="reportFooter">
      <div class="left"> Fleet Response Confidential</div>
      <div class="right"> Report run {{today | date :'short' }}</div>
    </div>
  </div>
  <div *ngIf="!rowsRaw || rowsRaw?.length == 0">
    No results
  </div>
</div>

