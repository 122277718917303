<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <div class="form-inline">
            <h4 class="card-title text-muted">Additional Drivers</h4>
            <button *ngIf="canAddNewDriver" class="btn btn-xs btn-default p-1" title="Add Driver" style="margin:-10px 0 0 10px; width:200px;" (click)="addNonEmployeeDriver(driverProfile)">
              Add Non-Employee Driver
            </button>
            <button *ngIf="!canAddNewDriver" class="btn btn-xs btn-default p-1" title="Add Driver" style="margin:-10px 0 0 10px; width:200px;" (click)="addNonEmployeeDriver(driverProfile)" disabled>
              Add Non-Employee Driver
            </button>
          </div>
          <app-loading-panel *ngIf="!driverProfile.secondaryDriversLoaded"></app-loading-panel>
          <div *ngIf="driverProfile.secondaryDriversLoaded">
            <app-data-table
              [columns]="driverSecondaryTableConfig.fields"
              [data]="driverSecondaryTableConfig.data"
              (triggerNavigate)="onNavigate($event)"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
