<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h4 class="card-title text-muted">Medical Certification</h4>
      <div *ngIf="medicalCertificateTableConfig">
        <app-data-table
          [columns]="medicalCertificateTableConfig.fields"
          [data]="medicalCertificateTableConfig.data"
          [noRecordsLabel]="medicalCertificateTableConfig?.noRecordsLabel"
        ></app-data-table>
      </div>
    </div>
  </div>
</div>