import { Component } from '@angular/core';
import { ReactivateDriverSearchModalService } from "../reactivate-driver-search-modal/reactivate-driver-search-modal-service.component";

@Component({
  selector: 'app-reactivate-driver-menu-item',
  templateUrl: './reactivate-driver-menu-item.component.html'
})
export class ReactivateDriverMenuItemComponent {

  constructor(private reactivateService: ReactivateDriverSearchModalService) { }

  showReactivateModal() {
    this.reactivateService.openReactivateDriverSearchModal();
  }
}
