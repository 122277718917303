import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Inject, Input, ElementRef, ViewChild } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DriverProfile, UserRightsInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import * as Highcharts from 'highcharts';
import Bullet from 'highcharts/modules/bullet';
import More from 'highcharts/highcharts-more';
import Tree from 'highcharts/modules/treemap';
import Heatmap from 'highcharts/modules/heatmap';
import Tilemap from 'highcharts/modules/tilemap';
import Exporting from 'highcharts/modules/exporting'
import bulletChart from 'highcharts/modules/bullet.src'
import { SafetyDriverRiskService } from './safety-driver-risk-service.component';
More(Highcharts);
Bullet(Highcharts);
bulletChart(Highcharts);
More(Highcharts);
Tree(Highcharts);
Heatmap(Highcharts);
Tilemap(Highcharts);
Exporting(Highcharts);

@Component({
  selector: 'safety-driver-risk-chart',
  templateUrl: './safety-driver-risk.component.html',
  styleUrls: ['./safety-driver-risk.component.css']
})

export class SafetyDriverRiskComponent implements OnInit {
  @Input() driverProfile: DriverProfile;
  @Input() _userRights: Array<UserRightsInfo>;
  @ViewChild('container') container: ElementRef;
  Highcharts = Highcharts;
  chart: Highcharts.Chart;
  hasPoints: boolean = false;

  @Input() chartOptions: Highcharts.Options = ({
    colors: [
      "rgb(159, 190, 197)", //Additional
      "rgb(54, 74, 110)", //Telematics
      "rgb(210, 49, 56)", //Claims
      "rgb(128, 128, 128)" //MVR
    ],
    chart: {
      type: 'bar',
      style: {
        fontFamily: "Arial, Helvetica, Gadget, sans-serif",
        textOutline: ''
      }
    },
    title: {
      text: 'Driver History Profile',
      style: {
        fontSize: "24px",
        color: "#868e96",
        align: "left"
      }
    },
    subtitle:
    {
      text: ""
    },
    xAxis: {
      categories: ['Points'],
      style: {
        textOutline: ''
      }
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    legend: {
      reversed: true,
      itemStyle: {
        fontWeight: "normal",
        fontSize: "13px",
        color: "#212529"
      }
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          format: '{y}',
          style: {
            textOutline: ''
          }
        }
      }
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    series: [
      {
        name: 'Additional',
        data: [5]
      }, {
        name: 'Telematics',
        data: [4]
      }, {
        name: 'Claims',
        data: [3]
      }, {
        name: 'MVR',
        data: [2]
      }
    ]

  } as any) as Highcharts.Options;

  constructor(
    private safetyDriverRiskService: SafetyDriverRiskService,
    private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
  ) { }

  ngOnInit() {
    this.safetyDriverRiskService.getRiskByDriverId(this.driverProfile.driverId).subscribe(x => {

      this.hasPoints = (x.additional + x.mvr + x.claims + x.telematics) > 0;

      if (!this.hasPoints) {
        this.chartOptions.subtitle.text = "This driver has no points.";
      } else {
        this.chartOptions.subtitle.text = "";
      }

      (this.chartOptions.series[0] as any).data[0] = x.additional;
      (this.chartOptions.series[1] as any).data[0] = x.telematics;
      (this.chartOptions.series[2] as any).data[0] = x.claims;
      (this.chartOptions.series[3] as any).data[0] = x.mvr;

      this.chart = Highcharts.chart(this.container.nativeElement.id, this.chartOptions);
      this.chart.reflow();

      //console.log(this.chartOptions);
    })
  }
}
