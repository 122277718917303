import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { SafetyModalWindowService } from '../safety-modal-window/safety-modal-window-service.component';
import { DataGridModalService } from '../../components/data-grid-filter-modal/data-grid-filter-modal.service'
import { DHPConfirmActionService } from './dhp-confirm-action-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';

@Component({
  selector: 'dhp-confirm-action',
  templateUrl: './dhp-confirm-action.component.html'
})
export class DHPConfirmActionComponent implements OnInit, OnDestroy {
  @Input() modalHeight: string;
  @Input() modalType: string;
  @Input() modalInput: any;
  baseUrl: string;
  driverId: string;

  // the overloaded constructor for the controller
  constructor(private router: Router,
    private dhpService: DriverHistoryProfileService,
    private safetyModalWindowService: SafetyModalWindowService,
    private dhpConfirmActionService: DHPConfirmActionService,
    private dataGridModalService: DataGridModalService,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private route: ActivatedRoute,
    private errorService: ErrorModalService,
    private loadingSpinnerService: LoadingSpinnerService,
    private alertService: AlertService) { }

  // angular on intialization event
  ngOnInit() {
    this.driverId = this.route.snapshot.paramMap.get('id');
  }

  // provides a central point for handling errors
  handleError(message: string) {

  }

  // retrieves and performs the selected action
  submit() {

    let action: string = '';

    try {
      if (this.modalType) {
        switch (this.modalType) {
          case 'resetPassword':
            {
              let action = 'Reset Password';
              let driverId = this.modalInput.driverId;
              this.loadingSpinnerService.show();
              this.dhpConfirmActionService.resetPassword(driverId).subscribe({
                next: (data) => {
                  this.loadingSpinnerService.hide();
                  // notify the modal window to close
                  this.dhpService.notifyModalClose();
                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }
          case 'resendESignEmail':
            {
              let action = 'Resend E-Sig Email';
              let driverId = this.modalInput.driverId;
              this.loadingSpinnerService.show();
              this.dhpConfirmActionService.resendESignEmail(driverId).subscribe({
                next: (data) => {
                  this.dhpService.setResendESignEmail(driverId);
                  // notify the modal window to close
                  this.dhpService.notifyModalClose();
                  this.loadingSpinnerService.hide();
                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }
          case 'resetAccount':
            {
              let action = 'Reset Account';
              let driverId = this.modalInput.driverId;
              this.loadingSpinnerService.show();
              this.dhpConfirmActionService.resetAccount().subscribe({
                next: (data) => {
                  // signal that driver profile data needs to be refreshed
                  this.dhpService.setRefreshDriverProfileAllData(driverId);
                  // notify the modal window to close
                  this.dhpService.notifyModalClose();
                  this.loadingSpinnerService.hide();
                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }
          case 'terminateDriver':
            {
              let action = 'Terminate Driver';
              let driverId = this.modalInput.driverId;
              let primaryDriverId = this.modalInput.primaryDriverId;
              let isSecondaryDriver = this.modalInput.isSecondaryDriver;
              this.loadingSpinnerService.show();
              this.dhpConfirmActionService.terminateDriver(driverId).subscribe({
                next: (data) => {
                  // notify the modal window to close
                  this.dhpService.notifyModalClose();
                  this.loadingSpinnerService.hide();
                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                  if (this.modalInput.fromLOAReport) {
                    // notify the modal windows to close
                    this.dhpService.notifyModalClose();
                    this.safetyModalWindowService.notifyModalClose();
                    // if calling from LOA report, refresh report on submit
                    //this.dataGridModalService.openModal(41);
                    this.dataGridModalService.openLeaveOfAbsenceReport(this.modalInput.client)
                  } else {
                    if (!this.modalInput.fromAdvancedSearch) {
                      if (isSecondaryDriver) {
                        // refresh all secondary driver data for primary driver
                        this.dhpService.setRefreshDriverProfileSecondaryDriverData(primaryDriverId);
                      } else {
                        // go to safety stat center
                        this.router.navigate(['statcenter', '2']);
                      }
                    }
                  }

                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }
          case 'cancelLeaveOfAbsence':
            // cancel future LOA
            {
              let action = 'Cancel Leave Of Absence';
              let driverId = this.modalInput.driverId;
              this.loadingSpinnerService.show();
              this.dhpConfirmActionService.cancelLeaveOfAbsence(driverId).subscribe({
                next: (data) => {
                  if (this.modalInput.fromLOAReport) {
                    // if calling from LOA report, refresh report on submit
                    this.dataGridModalService.openLeaveOfAbsenceReport(this.modalInput.client)
                  } else {
                    // signal that driver profile data needs to be refreshed
                    if (!this.modalInput.fromAdvancedSearch) {
                      this.dhpService.setRefreshDriverProfileBaseData(driverId);
                    }
                  }

                  // notify the modal windows to close
                  this.dhpService.notifyModalClose();
                  this.loadingSpinnerService.hide();
                  this.safetyModalWindowService.notifyModalClose();

                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }
          case 'endLeaveOfAbsence':
            // current LOA
            {
              let action = 'End Leave Of Absence';
              let driverId = this.modalInput.driverId;
              this.loadingSpinnerService.show();
              this.dhpConfirmActionService.endLeaveOfAbsence(driverId).subscribe({
                next: (data) => {
                  if (this.modalInput.fromLOAReport) {
                    // if calling from LOA report, refresh report on submit
                    this.dataGridModalService.openLeaveOfAbsenceReport(this.modalInput.client)
                  } else {
                    // no action - End LOA not available in driver profile form
                  }

                  // notify the modal windows to close
                  this.dhpService.notifyModalClose();
                  this.safetyModalWindowService.notifyModalClose();
                  this.loadingSpinnerService.hide();
                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }
          case 'removeFollowUpDate':
            {
              let action = 'Remove Follow-Up Date';
              let driverId = this.modalInput.driverId;
              this.loadingSpinnerService.show();
              this.dhpConfirmActionService.removeFollowUpDate(driverId).subscribe({
                next: (data) => {
                  // signal that driver profile data needs to be refreshed
                  this.dhpService.setRefreshDriverProfileFollowUpDateData(driverId);
                  // notify the modal window to close
                  this.dhpService.notifyModalClose();
                  this.loadingSpinnerService.hide();
                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }
          case 'cancelLicenseVerification':
            {
              let action = 'Cancel License Verification';
              let driverId = this.modalInput.driverId;
              this.loadingSpinnerService.show();
              this.dhpConfirmActionService.cancelLicenseVerification(driverId).subscribe({
                next: (data) => {
                  // signal that driver profile data needs to be refreshed
                  this.dhpService.setRefreshDriverProfileBaseData(driverId);
                  // notify the modal window to close
                  this.dhpService.notifyModalClose();
                  this.loadingSpinnerService.hide();
                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }
          case 'removeDocument':
            {
              let action = 'Remove Document';
              let docId = this.modalInput.docId;
              let driverId = this.modalInput.driverId;
              this.loadingSpinnerService.show();
              this.dhpConfirmActionService.removeDocument(docId).subscribe({
                next: (data) => {
                  // signal that driver profile data needs to be refreshed
                  this.dhpService.setRefreshDriverProfileDocumentsData(driverId);
                  // notify the modal window to close
                  this.dhpService.notifyModalClose();
                  this.loadingSpinnerService.hide();
                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }
          case 'verifyDocument':
            {
              let action = 'Verify Document';
              let docId = this.modalInput.docId;
              let driverId = this.modalInput.driverId;
              this.loadingSpinnerService.show();
              this.dhpConfirmActionService.verifyDocument(docId).subscribe({
                next: (data) => {
                  // signal that driver profile data needs to be refreshed
                  this.dhpService.setRefreshDriverProfileDocumentsData(driverId);
                  this.dhpService.setRefreshDriverProfileNotesData(driverId);
                  // notify the modal window to close
                  this.dhpService.notifyModalClose();
                  this.loadingSpinnerService.hide();
                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }
          case 'mvrMonitoringEnroll':
            {
              let action = 'Enroll in MVR Monitoring';
              let driverId = this.modalInput.driverId;
              this.loadingSpinnerService.show();
              this.dhpConfirmActionService.mvrMonitoringEnroll(driverId).subscribe({
                next: (data) => {
                  this.loadingSpinnerService.hide();
                  // signal that driver profile data needs to be refreshed
                  this.dhpService.setRefreshDriverProfileBaseData(driverId);
                  // notify the modal window to close
                  this.dhpService.notifyModalClose();
                  this.loadingSpinnerService.hide();
                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }
          case 'mvrMonitoringUnenroll':
            {
              let action = 'Unenroll in MVR Monitoring';
              let driverId = this.modalInput.driverId;
              this.loadingSpinnerService.show();
              this.dhpConfirmActionService.mvrMonitoringUnenroll(driverId).subscribe({
                next: (data) => {
                  this.loadingSpinnerService.hide();
                  // signal that driver profile data needs to be refreshed
                  this.dhpService.setRefreshDriverProfileBaseData(driverId);
                  // notify the modal window to close
                  this.dhpService.notifyModalClose();

                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }
          case 'resendLogin':
            {
              let action = 'Resend Login';
              let driverId = this.modalInput.driverId;
              this.loadingSpinnerService.show();
              this.dhpConfirmActionService.resendLogin(driverId).subscribe({
                next: (data) => {
                  // signal that driver profile data needs to be refreshed
                  this.dhpService.setRefreshDriverProfileBaseData(driverId);
                  // notify the modal window to close
                  this.dhpService.notifyModalClose();
                  this.loadingSpinnerService.hide();
                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }
          default: {
            throw new Error('An error occurred. A corresponding action was not found.');
          }
        }
      }
    } catch (e) {
      // Present the error details on the modal
      console.log(e.messge);
    }
  }

  cancel() {
    // do your business here.
    console.log('user submitted note.');
    // notify the modal window to close
    this.dhpService.notifyModalClose();
  }


  // sleeps for a certain number of milliseconds
  sleep(milliseconds: number) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }

  // clears the source and target field arrays
  initializeValues() {
  }

  // the default destructor
  ngOnDestroy() {
  }
}
