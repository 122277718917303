import {NgModule} from '@angular/core';
import {GoTopButtonComponent} from './go-top-button.component';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [GoTopButtonComponent],
  imports: [CommonModule],
  exports: [GoTopButtonComponent]
})
export class GoTopButtonModule {
}

