//Angular
import { Component, OnInit, OnDestroy, EventEmitter, ChangeDetectorRef, ViewChildren, QueryList, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { UntypedFormControl, Validators } from '@angular/forms';
//Third Party
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepicker } from '@angular/material/datepicker';
//APP
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { DriverProfile, DQFileManagementBaseInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DQRejectModalComponent, DqRejectModalReason } from '../dq-reject-modal/dq-reject-modal.component';
import { DQApproveModalComponent } from '../dq-approve-modal/dq-approve-modal.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';


/**
 * this is the client-side controller for the safety - driver info tab panel
 */
@Component({
  selector: 'app-dq-medical-card',
  templateUrl: './dq-medical-card.component.html',
  styleUrls: ['./dq-medical-card.component.scss']
})

export class DQMedicalCardComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChildren('endDatePicker') endDatePickerList: QueryList<MatDatepicker<Date>>;
  endDatePicker: MatDatepicker<Date>;
  formData: FormData;
  files: File[];
  private routeSub: Subscription;
  private driverId: string;
  private driverProcessAssignedId: string;
  private returnUrl: string;
  medCard: DQMedicalCardInfo;
  medCardSubmitInfo: MedicalCardSubmitInfo;
  driverInfo: DriverProfile;
  rejectOptions: Array<any>;
  arrFileName: Array<string> = [];
  disableUpload: boolean = false;
  humanizeBytes: Function;
  actualDate = new Date();

  formControlExpirationDate: UntypedFormControl = new UntypedFormControl("", [Validators.required]);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private onPremService: OnPremDriverService,
    private lineOfBusinessService: LineOfBusinessService,
    private driverHistoryProfileService: DriverHistoryProfileService,
    private datePipe: DatePipe,
    private cdRef: ChangeDetectorRef,
    private loadingSpinnerService: LoadingSpinnerService,
    private alertService: AlertService,
    private errorService: ErrorModalService,
    private dialog: MatDialog
  ) {
    //get the returnUrl if set
    var nav = this.router.getCurrentNavigation();
    this.returnUrl = nav?.extras?.state?.returnUrl || "";

    // for mdb upload control
    this.files = [];
  }

  ngOnInit() {
    //make sure LOB=2
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    this.routeSub = this.route.params.subscribe(params => {
      this.driverId = params['driverId'];
      this.driverProcessAssignedId = params['driverProcessAssignedId'];
      this.initAll(true);
    });
  }

  ngAfterViewChecked(): void {
    if (!this.endDatePicker && this.endDatePickerList?.first) {
      this.endDatePicker = this.endDatePickerList.first;
      this.endDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.endDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.endDatePicker.select(event.value);
            this.endDatePicker.close();
          })
        }, 0)
      })
    }
  }

  private initAll(updateSubmitInfo: boolean) {
    let endpoint = "";
    if (this.driverProcessAssignedId) {
      endpoint = "driverQualification/" + this.driverId + "/medicalcard/" + this.driverProcessAssignedId;
    } else {
      endpoint = "driverQualification/" + this.driverId + "/medicalcard/latest";
    }
    if (endpoint.length > 0) {
      this.loadingSpinnerService.show();
      this.onPremService.get(endpoint).subscribe({
        next: (data) => {
          this.medCard = data as DQMedicalCardInfo;
          if (!this.medCard.documentId) {
            this.returnToListing();
          } else {
            if (updateSubmitInfo) {
              this.arrFileName = [this.medCard.fileName];
              if (this.medCard.nationalRegistryFileName && (this.medCard.nationalRegistryFileName.length > 0)) {
                this.arrFileName.push(this.medCard.nationalRegistryFileName);
              }
              this.medCardSubmitInfo = new MedicalCardSubmitInfo(this.medCard);
              ;
              this.getDriverInfo(this.driverId);
              this.getRejectOptions(this.medCard.processId);
              // need detectChanges call to remove error related to field validation
              this.cdRef.detectChanges();
            } else {
              // just update National Registery info for submit object
              this.medCardSubmitInfo.updateNatRegInfo(this.medCard);
            }
          }
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    } else {
      this.errorService.setErrorObject({ message: 'Invalid Parameters' });
    }
  }

  private getDriverInfo(driverId: string) {
    this.driverHistoryProfileService.getDriverHistoryProfileBaseData(driverId).subscribe({
      next: (data) => {
        this.driverInfo = data as DriverProfile;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private getRejectOptions(processId: number) {
    this.onPremService.get("lookup/rejectionreasons/" + processId.toString()).subscribe({
      next: (data) => {
        const tempin: Array<MedicalCardRejectReason> = data as Array<MedicalCardRejectReason>;
        const tempout: Array<DqRejectModalReason> = [];
        tempin.forEach((item: MedicalCardRejectReason, index: number) => {
          const tempobj = {
            value: item.id.toString(),
            label: item.description,
            isReasonRequired: (item.id == 1)
          };
          tempout.push(tempobj);
        })
        this.rejectOptions = tempout;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    })
  }

  public enableDisableUpload(): void {
    this.disableUpload = !this.disableUpload;
    if (this.disableUpload) {
      this.medCard.nationalRegistryDocumentId = this.medCard.documentId;
    } else {
      this.medCard.nationalRegistryDocumentId = null;
    }
  }

  openDriverProfile() {
    this.router.navigate(["/driver/history-profile/" + this.driverId]);
  }

  openApproveModal() {
    if (this.validateForm()) {
      const dialogRef = this.dialog.open(DQApproveModalComponent, {
        data: { dqType: 'Medical Certificate', attNames: this.arrFileName },
        minWidth: '31em',
        minHeight: '10em',
        panelClass: 'add-document-modal',
        hasBackdrop: false,
        position: { top: '2em' }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) this.approve();
      })
    }

  }

  openRejectModal() {
    const dialogRef = this.dialog.open(DQRejectModalComponent, {
      data: { dqType: 'Medical Certificate', rejectOptions: this.rejectOptions },
      minWidth: '31em',
      minHeight: '10em',
      panelClass: 'add-document-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.reject(result);
    })
  }

  showFiles() {
    let files = "";
    for (let i = 0; i < this.files.length; i++) {
      files += this.files[i].name
      if (!(this.files.length - 1 == i)) {
        files += ", "
      }
    }
    return files;
  }

  onUploadOutput(event: any): void {
    const fileSelected: File = event.target.files[0];
    this.files[0] = fileSelected;
    this.uploadDocs();

  }

  uploadDocs() {
    let arrFileName = this.files[0].name.split('.');

    this.getBase64(this.files[0]).then(data => {
      let filenamefull = this.files[0].name;
      let filename = filenamefull.substr(0, filenamefull.lastIndexOf('.'));
      let extension = "";
      if (filename.length > 0) {
        extension = filenamefull.substr(filenamefull.lastIndexOf('.'));
      } else {
        filename = filenamefull;
      }
      let param = {
        DriverProcessId: this.medCard.driverProcessAssignedId.toString(),
        DriverId: this.medCard.driverId.toString(),
        DocumentTypeId: '24',
        FileName: filename,
        Extension: extension,
        Description: '',
        FileData: (data as string).replace("data:", "").replace(/^.*;base64,/, "")
      };
      this.loadingSpinnerService.show();
      this.onPremService.post("driverQualification/" + this.medCard.driverId.toString() + "/medicalcard/ " + this.medCard.driverProcessAssignedId.toString() + "/nationalregistry/upload",
        param
      ).subscribe({
        next: () => {
          // reload base info to get updated file info
          this.initAll(false);
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    });
  }

  private getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }


  private validateForm(): boolean {
    let isOk: boolean = true;

    if (this.formControlExpirationDate.invalid) {
      this.errorService.setErrorObject({ message: 'Expiration Date is Required.' });
      isOk = false;
    }

    return isOk;
  }

  approve() {
    if (this.validateForm()) {
      let endpoint = "driverQualification/" + this.driverId + "/medicalcard/" + this.medCardSubmitInfo.driverProcessAssignedId.toString() + "/approve";
      let postData = JSON.parse(JSON.stringify(this.medCardSubmitInfo));
      let needUpdate = false;
      needUpdate = needUpdate || (this.medCardSubmitInfo.expirationDate != (this.medCard.expirationDate ? this.datePipe.transform(this.medCard.expirationDate, "MM/dd/yyyy") : null));
      needUpdate = needUpdate || (this.medCardSubmitInfo.nationalRegistryDocumentId != this.medCard.nationalRegistryDocumentId);
      if (!needUpdate) {
        delete postData.expirationDate;
        delete postData.nationalRegistryDocumentId;
      }
      delete postData.rejectionReasonId;
      delete postData.rejectionReasonIdString;

      let postput = (needUpdate ? this.onPremService.put(endpoint, JSON.stringify(postData)) : this.onPremService.post(endpoint, JSON.stringify(postData)));

      this.loadingSpinnerService.show();
      postput.subscribe({
        next: (data) => {
          this.alertService.showSuccessAlert('Medical Certificate Upload Approved', 'end', 'top', 5000);
          this.loadingSpinnerService.hide();
          this.returnToListing();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error.message);
          this.loadingSpinnerService.hide();
        }
      })
    }
  }

  reject(rejectInfo: any) {
    let endpoint = "driverQualification/" + this.driverId + "/medicalcard/" + this.medCardSubmitInfo.driverProcessAssignedId.toString() + "/reject";
    let postData = JSON.parse(JSON.stringify(this.medCardSubmitInfo));
    let needUpdate = false;
    needUpdate = needUpdate || (this.medCardSubmitInfo.expirationDate != (this.medCard.expirationDate ? this.datePipe.transform(this.medCard.expirationDate, "MM/dd/yyyy") : null));
    needUpdate = needUpdate || (this.medCardSubmitInfo.nationalRegistryDocumentId != this.medCard.nationalRegistryDocumentId);
    if (!needUpdate) {
      delete postData.expirationDate;
      delete postData.nationalRegistryDocumentId;
    }
    postData.rejectionReasonId = +rejectInfo.rejectionReasonIdString;
    postData.otherRejectionReason = rejectInfo.otherRejectionReason;

    let postput = (needUpdate ? this.onPremService.put(endpoint, JSON.stringify(postData)) : this.onPremService.post(endpoint, JSON.stringify(postData)));

    this.loadingSpinnerService.show();
    postput.subscribe({
      next: (data) => {
        this.alertService.showSuccessAlert('Medical Certificate Upload Rejected', 'end', 'top', 5000);
        this.loadingSpinnerService.hide();
        this.returnToListing();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error.message);
        this.loadingSpinnerService.hide();
      }
    })
  }

  cancel() {
    this.returnToListing();
  }

  returnToListing() {
    if (this.returnUrl.length > 0) {
      this.router.navigate([this.returnUrl]);
      return;
    }

    //fallback to the default location
    this.router.navigate(['/dq/filemanagement']);
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

}

export interface DQMedicalCardInfo extends DQFileManagementBaseInfo {
  nationalRegistryDocumentId: number;
  nationalRegistryFileName: string;
  nationalRegistryUploadDate: string;
  nationalRegistryUploadUserId: number;
  nationalRegistryVerifiedDate: string;

  expirationDate: string;
}

export interface MedicalCardRejectReason {
  id: number;
  secondaryId: number;
  description: string;
  secondaryCode: string;
}

export class MedicalCardSubmitInfo {
  driverProcessAssignedId: number;
  driverId: number;
  expirationDate: string | Date;
  nationalRegistryDocumentId: number;
  rejectionReasonId: number;
  otherRejectionReason: string;

  constructor(info: DQMedicalCardInfo) {
    let datePipe = new DatePipe("en-US");
    this.driverProcessAssignedId = info.driverProcessAssignedId;
    this.driverId = info.driverId;
    this.expirationDate = (info.expirationDate ? new Date(info.expirationDate) : null);
    this.nationalRegistryDocumentId = info.nationalRegistryDocumentId;
    this.rejectionReasonId = 1;
  }

  updateNatRegInfo(info: DQMedicalCardInfo) {
    this.nationalRegistryDocumentId = info.nationalRegistryDocumentId;
  }
}
