import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { AccidentClaimSubroStatus, AccidentClaimSubroNetRecoveryInfo, AccidentClaimSupplementalData } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

@Injectable()
export class AccidentClaimService {
  baseUrl: string;
  private driverName: string;
  private updatingFollowUpDate = new Subject();
  updatingFollowUpDate$ = this.updatingFollowUpDate.asObservable();
  private goToClaim = new Subject();
  goToClaim$ = this.goToClaim.asObservable();
  private subroStatuses = new Subject();
  subroStatuses$ = this.subroStatuses.asObservable();
  private subroNetRecovery = new Subject();
  subroNetRecovery$ = this.subroNetRecovery.asObservable();


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  getLossNotice(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('ClaimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetLossNotice', {
      params: params
    });
  }
  getLossNoticeTransactions(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('ClaimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetLossNoticeTransactions', {
      params: params
    });
  }

  getClaimInfo(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetClaimInfo', {
      params: params
    });
  }

  getArbitrationDetails(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetArbitrationInfo', {
      params: params
    });
  }

  getDocketDetails(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetDocketInfo', {
      params: params
    });
  }

  setDriverName(driverName: string) {
    this.driverName = driverName;
  }

  getDriverName(): string {
    return this.driverName;
  }

  getClaimActionHistory(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetClaimActionHistory', {
      params: params
    });
  }

  getClaimNotes(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetClaimNotes', {
      params: params
    });
  }

  getMerchantsLeaseTypes() {
    return this.http.get(this.baseUrl + 'api/Claim/GetMerchantsLeaseTypes');
  }

  getMerchantsLiabilityTypes() {
    return this.http.get(this.baseUrl + 'api/Claim/GetMerchantsLiabilityTypes');
  }

  getSupplementalData(claimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', claimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetSupplementalData', {
      params: params
    });
  }

  getSubroApprovalDetail(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetSubroApprovalDetail', {
      params: params
    });
  }

  getSubroApprovalHistory(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetSubroApprovalHistory', {
      params: params
    });
  }

  getSubroApprovalResponses(clientActionId: number) {
    let params = new HttpParams();
    params = params.append('clientActionId', clientActionId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetSubroApprovalResponses', {
      params: params
    });
  }

  getSubroInboundInfo(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetSubroInboundInfo', {
      params: params
    });
  }

  getSubroOutboundInfo(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetSubroOutboundInfo', {
      params: params
    });
  }

  getSubroNetRecoveryInfo(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetSubroNetRecovery', {
      params: params
    });
  }

  getSubroNotes(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetSubroNotes', {
      params: params
    });
  }

  getSubroStatuses(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetSubroStatuses', {
      params: params
    });
  }

  getInvoices(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetInvoices', {
      params: params
    });
  }

  getRentalDetails(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetRentalDetail', {
      params: params
    });
  }

  getRepairApprovalDetail(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetRepairApprovalDetail', {
      params: params
    });
  }

  getRepairApprovalHistory(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetRepairApprovalHistory', {
      params: params
    });
  }

  getRepairApprovalResponses(clientActionId: number) {
    let params = new HttpParams();
    params = params.append('clientActionId', clientActionId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetRepairApprovalResponses', {
      params: params
    });
  }

  getRepairDetail(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetRepairDetail', {
      params: params
    });
  }

  getRepairEstimateDetails(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetRepairEstimateDetails', {
      params: params
    });
  }

  getRepairGlassDetail(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetRepairGlassDetail', {
      params: params
    });
  }

  getRepairPayoutDetails(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetRepairPayoutDetails', {
      params: params
    });
  }

  getSalvageTitleHolders(client: string) {
    let params = new HttpParams();
    params = params.append('client', client);

    return this.http.get(this.baseUrl + 'api/Claim/GetSalvageTitleHolders', {
      params: params
    });
  }

  getTotalLossBidHistories(salvageId: number) {
    let params = new HttpParams();
    params = params.append('salvageId', salvageId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetTotalLossBidHistories', {
      params: params
    });
  }

  getTotalLossDetail(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetTotalLossDetail', {
      params: params
    });
  }

  getUnits(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetUnits', {
      params: params
    });
  }

  getVendorDetail(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetVendorDetail', {
      params: params
    });
  }

  getAtFaultPartyInfo(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetAtFaultPartyInfo', {
      params: params
    });
  }

  getDocumentInfo(ClaimId: number, docType:number, lineOfBusinessId:number) {
    let params = new HttpParams();
    params = params.append('ClaimId', ClaimId.toString());
    params = params.append('DocumentType', docType.toString());
    params = params.append('LineOfBusinessId', lineOfBusinessId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetDocumentsByClaimIdAndType', {
      params: params
    });
  }

  getSubroMissingDocs(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('ClaimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetMissingDocuments', {
      params: params
    });
  }


  setUpdatingFollowUpDate() {
    this.updatingFollowUpDate.next(true);
  }

  setGoToClaim(claimId: number) {
    this.goToClaim.next(claimId);
  }

  setSubroNetRecovery(recovery: Array<AccidentClaimSubroNetRecoveryInfo>) {
    this.subroNetRecovery.next(recovery);
  }

  setSubroStatuses(statuses: Array<AccidentClaimSubroStatus>) {
    this.subroStatuses.next(statuses);
  }


  deleteFollowUpDate(claimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', claimId.toString());

    return this.http.delete(this.baseUrl + 'api/Claim/DeleteFollowUpDate', {
      params: params
    });
  }

  upsertFollowUpDate(claimId: number, followUpDate: string, note: string, emailRequested: boolean) {
    let params = new HttpParams();
    params = params.append('claimId', claimId.toString());
    params = params.append('emailRequested', emailRequested.toString());
    params = params.append('followUpDate', followUpDate);

    return this.http.post(this.baseUrl + 'api/Claim/UpsertFollowUpDate',
      JSON.stringify(note),
      {
        headers: { 'Content-Type': 'application/json' },
        params: params
      }
    );
  }

  updateRepairClientApproval(clientApprovalId: number, claimId: number, clientResponseLookupId: number, vin: string, titleHolderId: number, buyItNowAmount: number, comments: string) {
    let params = new HttpParams();
    params = params.append('clientApprovalId', clientApprovalId.toString());
    params = params.append('claimId', claimId.toString());
    params = params.append('clientResponseLookupId', clientResponseLookupId.toString());
    params = params.append('vin', vin);
    params = params.append('titleHolderId', titleHolderId ? titleHolderId.toString() : null);
    params = params.append('buyItNowAmount', buyItNowAmount ? buyItNowAmount.toString() : null);

    return this.http.put(this.baseUrl + 'api/Claim/UpdateRepairClientApproval',
      JSON.stringify(comments),
      {
        headers: { 'Content-Type': 'application/json' },
        params: params
      }
    );
  }

  updateSubroClientApproval(clientApprovalId: number, claimId: number, clientResponseLookupId: number, subroRepUserId: number, comments: string) {
    let params = new HttpParams();
    params = params.append('clientApprovalId', clientApprovalId.toString());
    params = params.append('claimId', claimId.toString());
    params = params.append('clientResponseLookupId', clientResponseLookupId.toString());
    params = params.append('subroRepUserId', subroRepUserId ? subroRepUserId.toString() : '');

    return this.http.put(this.baseUrl + 'api/Claim/UpdateSubroClientApproval',
      JSON.stringify(comments),
      {
        headers: { 'Content-Type': 'application/json' },
        params: params
      }
    );
  }

  updateSupplementalData(supplementalData: AccidentClaimSupplementalData) {
    return this.http.put(this.baseUrl + 'api/Claim/UpsertSupplementalData',
      JSON.stringify(supplementalData),
      {
        headers: { 'Content-Type': 'application/json' }
      }
    );
  }

  insertNote(claimId: number, claimNote: string, noteType: number) {
    let params = new HttpParams();
    params = params.append('claimId', claimId.toString());
    params = params.append('noteType', noteType.toString());

    return this.http.post(this.baseUrl + 'api/Claim/AddNote',
      JSON.stringify(claimNote),
      {
        headers: { 'Content-Type': 'application/json' },
        params: params
      }
    );
  }

  uploadDocs(ClaimId: number, desc: string, fileData:FormData) {
    let params = new HttpParams();
    params = params.append('ClaimId', ClaimId.toString());
    params = params.append('Description', desc);

    return this.http.post(this.baseUrl + 'api/Document/Upload',
      fileData,
      {params: params}
    );
  }

  insertDocumentData(ClaimId: number, desc: string, fileByteLength: string, fileName: string, largeFileByteLength: string) {
    let params = new HttpParams();
    params = params.append('ClaimId', ClaimId.toString());
    params = params.append('Description', desc);
    params = params.append('FileByteLength', fileByteLength);
    params = params.append('FileName', fileName);
    params = params.append('LargeFileByteLength', largeFileByteLength);    

    return this.http.post(this.baseUrl + 'api/Document/InsertDocumentData',
      null,
      {params: params}
    );
  }  

  documentUploadedUpdate(documentGuid: string) {
    let params = new HttpParams();
    params = params.append('DocumentGuid', documentGuid);

    return this.http.post(this.baseUrl + 'api/Document/DocumentUploadedUpdate',
      null,  
      {params: params}
    );   
  }
}
