import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.css']
})
export class ImagePreviewComponent {
  @Input() src: any;
  @Input() type: string;
  totalPages: number;
  pageArray: Array<number>;
  page = 1;
  isLoaded = false;

  constructor() { }

  nextPage() {
    if (this.page < this.totalPages) {
      this.page += 1;
    }
  }

  previousPage() {
    if (this.page > 1) {
      this.page -= 1;
    }
  }

  goToPage(pg:number) {
    this.page = pg;
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.pageArray = Array.from(Array(this.totalPages).keys());
    this.isLoaded = true;
  }
}
