//Angular
import { Component, Input, Inject, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
//Third Party
import { Subscription } from 'rxjs';
//App
import { ClientSelectionService, ClientSelectionsObject } from '../../components/client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { CorporateStructure } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { AlertModalService } from "../../components/alert-modal/alert-modal-service.component";
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-safety-new-employee-driver-selector',
  templateUrl: './safety-new-employee-driver-selector.component.html'
})
export class SafetyNewEmployeeDriverSelectorComponent implements OnInit, OnDestroy {
  clientSelectedArray: Array<string> = [];
  arrCH: Array<CorporateStructure>;
  clientSelectionSubscription: Subscription;
  baseUrl: string;

  constructor(private router: Router,
    private clientSelectionService: ClientSelectionService,
    private lineOfBusinessService: LineOfBusinessService,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private onPremService: OnPremDriverService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private alertService: AlertModalService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;

    this.clientSelectionSubscription = clientSelectionService.clientSelectedInfo$.subscribe(
      clientSelectedArray => {
        let temp = clientSelectedArray as ClientSelectionsObject;
        if (temp[2]) {
          let cArr = temp[2].clientSelectedArray;
          if (cArr && (cArr.length > 0)) {
            if (!this.arrayCompare(cArr, this.clientSelectedArray)) {
              this.clientSelectedArray = cArr;
              this.getCHInfo(null, null);
            }
          } else {
            this.arrCH = null;
          }
        } else {
          this.arrCH = null;
        }
      }
    );
  }

  ngOnInit() {
    // set line of business to safety and client choice to
    // safety choice, if not already initialized to Safety
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
      let cArr = this.clientSelectionService.getClientSelectedArrayValue(2);
      if (cArr && (cArr.length > 0)) {
        this.clientSelectedArray = cArr;
        this.getCHInfo(null, null);
      } else {
        this.arrCH = null;
      }
    }
  }

  private getCHInfo(parentElementId: string, callingElement: CorporateStructure) {

    this.loadingSpinnerService.show();

    let parentElementParam = ''
    if (parentElementId) {
      parentElementParam = '/' + parentElementId.toString();
    }

    this.onPremService.get('hierarchy/structure/' + this.clientSelectedArray.join(',') + '/true' + parentElementParam)
      .subscribe({
        next: (data) => {
          if (!parentElementId) {
            // init arrCH if parentElement=null
            this.arrCH = data as Array<CorporateStructure>;
          } else {
            // assign to childElements of callingElement
            callingElement.childElements = data as Array<CorporateStructure>;
            // pass reference to parent to each child
            callingElement.childElements.forEach(c => c.parentElement = callingElement);
          }
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });

  }

  private arrayCompare(a1, a2) {
    return (a1.length == a2.length && a1.every((v, i) => v === a2[i]));
  }

  ngOnDestroy() {
    this.clientSelectionSubscription.unsubscribe();
  }
}
