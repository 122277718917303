import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { WidgetListComponent } from '../components/widget-list/widget-list.component';
import { WidgetEditComponent } from '../components/widget-edit/widget-edit.component';
import { WidgetPerClientComponent } from '../components/widget-per-client/widget-per-client.component';
import { AuthGuard } from '../components/auth/auth.guard';
import { BusinessGroupNewsListComponent } from '../components/business-group-news-admin-list/business-group-news-admin-list.component';
import { BusinessGroupNewsAdminEditComponent } from '../components/business-group-news-admin-edit/business-group-news-admin-edit.component';
import { DataGridAdminListComponent } from '../components/data-grid-admin-list/data-grid-admin-list.component';
import { DataGridAdminEditComponent, SortDataGridFiltersPipe } from '../components/data-grid-admin-edit/data-grid-admin-edit.component';
import { DataGridAdminFilterModalComponent } from '../components/data-grid-admin-filter-modal/data-grid-admin-filter-modal.component';
import { DataGridPerClientComponent } from '../components/data-grid-per-client/data-grid-per-client.component';
import { DocumentLibraryAdminComponent } from './document-library-admin/document-library-admin.component';
import { UserAdminComponent } from './user-admin/user-admin.component';

@NgModule({
    declarations: [
        BusinessGroupNewsListComponent,
        BusinessGroupNewsAdminEditComponent,
        DataGridAdminFilterModalComponent,
        DataGridAdminEditComponent,
        DataGridAdminListComponent,
        DataGridPerClientComponent,
        SortDataGridFiltersPipe,
        WidgetListComponent,
        WidgetEditComponent,
        WidgetPerClientComponent,
        DocumentLibraryAdminComponent,
        UserAdminComponent
    ],
    imports: [
        SharedModule,
        RouterModule.forChild([

            { path: 'admin/widgetlist', component: WidgetListComponent, canActivate: [AuthGuard] },
            { path: 'admin/widgetedit/:WidgetId', component: WidgetEditComponent, canActivate: [AuthGuard] },
            { path: 'admin/widgetperclientedit/:WidgetId', component: WidgetPerClientComponent, canActivate: [AuthGuard] },

            { path: 'admin/datagridadminlist', component: DataGridAdminListComponent, canActivate: [AuthGuard] },
             { path: 'admin/datagridadminedit/:dataGridId', component: DataGridAdminEditComponent, canActivate: [AuthGuard] },
             { path: 'admin/datagridadminedit/:dataGridId/:returnToWidgetId', component: DataGridAdminEditComponent, canActivate: [AuthGuard] },
            { path: 'admin/reportperclientedit/:dataGridId', component: DataGridPerClientComponent, canActivate: [AuthGuard] },
            { path: 'admin/businessgroupnewsadminlist', component: BusinessGroupNewsListComponent, canActivate: [AuthGuard] },
             { path: 'admin/businessgroupnewsadminedit/:newsItemId', component: BusinessGroupNewsAdminEditComponent, canActivate: [AuthGuard] },
             { path: 'admin/documentlibraryadmin', component: DocumentLibraryAdminComponent, canActivate: [AuthGuard] },
             { path: 'admin/useradmin', component: UserAdminComponent, canActivate: [AuthGuard] }             

        ])
    ],
    providers: [
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class AdminModule {
  constructor() {
        console.log('ADMIN MODULE STARTING');
    }
}
