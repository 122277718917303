import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DhpDocumentService } from '../../services/dhp-document-service/dhp-document.service';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DhpDocumentValidationDocumentType } from '../models/dhp-document-validation-document-type.model';
import { ConfirmModalGenericComponent } from '../../components/confirm-modal-generic/confirm-modal-generic.component';
import { AuthService } from '../../components/auth/auth.service';
import {
  DhpDocumentValidation,
  LookupListItem,
  DriverNote,
} from '../../safety/models';

@Component({
  selector: 'app-dhp-document-validation-edit-modal',
  templateUrl: './dhp-document-validation-edit-modal.component.html',
  styleUrls: ['./dhp-document-validation-edit-modal.component.scss']
})
export class DhpDocumentValidationEditModalComponent {

  documentData: DhpDocumentValidation;
  documentTypes: Array<LookupListItem>;
  selectedDocumentTypeField = 'DocumentType';
  isValid: boolean;

  form = new FormGroup({
    documentTypeId: new FormControl(null, Validators.required)
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: {
      documentData: DhpDocumentValidation,
      documentTypes: Array<LookupListItem>
    },
    private readonly dialogRef: MatDialogRef<DhpDocumentValidationEditModalComponent>,
    private readonly dhpDocumentService: DhpDocumentService,
    private readonly errorService: ErrorModalService,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly onPremService: OnPremDriverService,
    private readonly authService: AuthService,
  ) {
    this.documentData = this.dialogData.documentData;
    this.documentTypes = this.dialogData.documentTypes;
  }

  cancel(): void {
    this.closeModal();
  }

  handleDocumentTypeChange(event: MatSelectChange): void {
    const formControl = this.form.get('documentTypeId');
    if (event.value === 0) {
      formControl.setValidators([Validators.required]);
      formControl.updateValueAndValidity();
    } else {
      formControl.setValue(event.value);
      formControl.clearValidators();
      formControl.updateValueAndValidity();
    }
  }

  openConfirmationModal(): void {
    const documentType = this.documentTypes.find(t => t.id === this.form.get('documentTypeId').value);
    const dialogRef = this.dialog.open(ConfirmModalGenericComponent,{
      data: {
        confirmGenericTitle: 'Confirm Change',
        message: `Are you sure you want to change the document type from ${this.documentData.documentType} to ${documentType.description}`,
        yesLabel: 'Confirm Changes',
        noLabel: 'Cancel'
      },
      width: '34em',
      minHeight: '10em',
      panelClass: 'alert-modal',
      disableClose: true,
      restoreFocus: false,
      hasBackdrop: true,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) =>{
      if(result)
        {
          this.submitEdit();
        }
    });
  }

  private addNoteForDocumentTypeChange(): () => Observable<any> {
    const documentDescription = this.documentData.documentDescription;
    const orgDocumentType = this.documentData.documentType;
    const newDocumentType = this.documentTypes.find(t => t.id === this.form.get('documentTypeId').value).description;
    const userName = this.authService.user.profile.name;

    const note = new DriverNote(this.documentData.driverId);
    note.isNotification = false;
    note.note = `'${documentDescription}' document type was changed from '${orgDocumentType}' to '${newDocumentType}' by ${userName}`;

    return () => this.onPremService.post('note', JSON.stringify(note));
  }

  private closeModal(result = false): void {
    this.dialogRef.close(result);
  }

  private saveDocumentType(): Observable<any> {
    try {
      return this.dhpDocumentService.ChangeDocumentType(this.documentData.documentId, {
        documentTypeId: this.form.get('documentTypeId').value
      } as DhpDocumentValidationDocumentType);
    }
    catch (err) {
      console.log(err);
      return new Observable<null>();
    }
  }

  private submitEdit(): void {
    this.saveDocumentType()
      .pipe(mergeMap(this.addNoteForDocumentTypeChange()))
      .subscribe({
        next: (data) => {
          this.snackBar.open('DHP Document Type Changed', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: 'success-snackbar',
          });
          this.loadingSpinnerService.hide();
          this.router.navigate([`safety/dhpdocumentvalidationreview/${this.documentData.documentId}`]);
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    this.closeModal();
  }
}
