//Angular
import { Injectable } from '@angular/core';
//third Party
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
//APP

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private snackBar: MatSnackBar) { }

  showSuccessAlert(message: string, horizontalPosition: MatSnackBarHorizontalPosition, verticalPosition: MatSnackBarVerticalPosition, duration: number): void {
    this.snackBar.open(message, 'Ok', {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: duration,
      panelClass: 'success-snackbar'
    });
  }

  showErrorAlert(message: string, horizontalPosition: MatSnackBarHorizontalPosition, verticalPosition: MatSnackBarVerticalPosition, duration: number) :void{
    this.snackBar.open(message, 'Ok', {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: duration,
      panelClass: 'error-snackbar'
    });
  }


}
