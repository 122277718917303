import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import {  DhpHierarchy, ClientSelectionObject } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-safety-move-drive-group',
  templateUrl: './safety-move-drive-group.component.html',
  styleUrls: ['./safety-move-drive-group.component.css']
})
export class SafetyMoveDriveGroupComponent implements OnInit {
  newDhpGroupForm : UntypedFormGroup

  @Input() searchedDhpHierarchy: DhpHierarchy[]
  @Input() clientSelections: ClientSelectionObject[]
  @Input() isLoading : boolean = false;

  @Output()
  searchDhpHierarchy = new EventEmitter<string>();
  @Output()
  selectedDhpChange = new EventEmitter<DhpHierarchy>();


  isSubmitted: boolean = false;
  isDhpGroupMissing: boolean = false;
  selectedDhp: DhpHierarchy

  constructor(private readonly fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.newDhpGroupForm = this.fb.group({
        clientCode: ['', Validators.required]
    });
    
  }
  ngOnChanges(changes:SimpleChanges): void{
    const loadingChange = changes['isLoading']
    if(loadingChange?.previousValue && !loadingChange.currentValue ){
      this.isDhpGroupMissing = this.searchedDhpHierarchy?.length > 0 ? false : true;
      this.newDhpGroupForm.reset(this.newDhpGroupForm.value);
      this.isSubmitted = false;
    }
  }

  get form() { return this.newDhpGroupForm.controls; }

  
  searchDhpGroup(){
    this.isSubmitted = true;
    if(this.newDhpGroupForm.valid) {
      this.searchDhpHierarchy.emit(this.newDhpGroupForm.get('clientCode').value);
    }
  }

  onSelectedDhpChange(){
    if(typeof this.selectedDhp === 'string'){
      this.selectedDhpChange.emit(null);
      
    }else{
      this.selectedDhpChange.emit(this.selectedDhp);
    }
  }
}
