<div class="card mb-3">
    <div class="card-header card-default" >
        <h5 class="panel-title" (click)="card.collapsed = !card.collapsed">
            <i class='glyphicon'
               [class.glyphicon-chevron-up] = "!card.collapsed"
               [class.glyphicon-chevron-down] = "card.collapsed" ></i>  {{card.title}}
        </h5>
    </div>
    <div class="card-block" [class.collapse]="card?.collapsed">
        <ng-content></ng-content>
    </div>
</div>
