//Angular
import { Component, Input, Output, Inject, ViewChildren, QueryList, Renderer2, EventEmitter, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
//Third Party
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
//App
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { CorporateStructure } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: "app-corporate-structure-selector-folder",
  templateUrl: "./corporate-structure-selector-folder.component.html",
  styleUrls: ["./corporate-structure-selector-folder.component.scss"],
})
export class CorporateStructureSelectorFolderComponent
  implements AfterContentInit {
  @Input() chType: string;
  get arrCHInfo(): Array<CorporateStructure> {
    return this._arrCHInfo;
  }
  @Input() set arrCHInfo(value: Array<CorporateStructure>) {
    if (value) {
      Object.keys(value).forEach((key) => {
        this.setParent(value[key], null);
      });
      this._arrCHInfo = value;
      this.dataSource.data = value;
      console.log(this.arrCHInfo);
    }
  }
  @Input() clientSelectedArray: Array<string>;
  @Input() selectorId: string;
  // emit when single item is checked, to update info on parent and/or children
  @Output() onCheckItem: EventEmitter<any> = new EventEmitter<any>();
  // emit when all processing related to an item selection is completed
  @Output() onSelectionsProcessed: EventEmitter<any> = new EventEmitter<any>();
  baseUrl: string;
  collapsed = true;
  private _arrCHInfo: Array<CorporateStructure>;

  treeControl = new NestedTreeControl<CorporateStructure>(
    (node) => node.childElements
  );
  dataSource = new MatTreeNestedDataSource<CorporateStructure>();

  constructor(
    private router: Router,
    private clientSelectionService: ClientSelectionService,
    private renderer: Renderer2,
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private onPremService: OnPremDriverService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngAfterContentInit() {
    // console.log(this.arrCHInfo)
    if (this.arrCHInfo) {
      // console.log("Processed")
      this.processIsSelected(this.arrCHInfo);

      this.arrCHInfo.forEach((x) => {
        // this.markCheckboxIndeterminate(x, x.indeterminate);
        this.checkAllParents(x);
      });
    }
  }

  hasChild(index: number, node: CorporateStructure): boolean {
    return node.childElements?.length > 0 || node.hasChildren;
  }

  elementClick(callingElement: CorporateStructure): void {
    if (callingElement.hasChildren) {
      // expand next level
      this.getCHSubInfo(callingElement);
    }
  }

  private getCHSubInfo(callingElement: CorporateStructure) {
    const parentElementId: string = callingElement.elementGuid;
    const hasChildElements =
      callingElement.childElements && callingElement.childElements.length > 0;

    if (!hasChildElements) {
      let svcUrl: string =
        "hierarchy/structureForFilter/" +
        this.clientSelectedArray.join(",") +
        "/" +
        parentElementId.toString();
      switch (this.chType) {
        case "SafetyFilter":
        case "DcFilter":
        case "MvrExport":
          if (callingElement.isLowestTier) {
            svcUrl = "";
          } else {
            svcUrl =
              "hierarchy/structureForFilter/" +
              this.clientSelectedArray.join(",") +
              "/" +
              parentElementId.toString();
          }

          break;
        case "MvrExportByState":
          if (callingElement.isLowestTier) {
            svcUrl = "";
          } else {
            svcUrl =
              "hierarchy/stateStructure/" +
              this.clientSelectedArray.join(",") +
              "/" +
              parentElementId.toString();
          }

          break;
        case "NoExpand":
          svcUrl = "";
          break;
      }

      if (svcUrl.length > 0) {
        this.loadingSpinnerService.show();
        this.onPremService.get(svcUrl).subscribe({
          next: (data) => {
            const arrCH = data as Array<CorporateStructure>;
            this.processIsSelected(arrCH);
            // assign to childElements of callingElement
            callingElement.childElements = arrCH;
            // pass checkbox selection to children if
            // callingElement was updated by user
            if (callingElement.updatedByUser) {
              this.itemToggle(callingElement, callingElement.boxChecked);
            }
            callingElement.childElements.forEach((childNode) => {
              this.setParent(childNode, callingElement);
            });
            this.reloadTree();
            this.loadingSpinnerService.hide();
          },
          error: (err: HttpErrorResponse) => {
            // this.toastrService.error(err.error.toString());
            this.errorService.setErrorObject(err.error);
            this.loadingSpinnerService.hide();
          }
        }
        );
      }
    }
  }

  private processIsSelected(arrCH: Array<CorporateStructure>) {
    // updates boxChecked and indeterminate properties based on isSelected value
    arrCH.forEach((c) => {
      switch (c.isSelected) {
        case 0:
          // not checked
          c.boxChecked = false;
          c.indeterminate = false;
          break;
        case 1:
          // checked
          c.boxChecked = true;
          c.indeterminate = false;
          break;
        case 2:
          // indeterminate
          c.boxChecked = false;
          c.indeterminate = true;
          break;
      }
    });
  }

  private reloadTree(): void {
    this.dataSource.data = null;
    this.dataSource.data = this.arrCHInfo;
  }

  private itemToggle(node: CorporateStructure, checked: boolean): void {
    node.boxChecked = checked;
    node.updatedByUser = true;
    node.isSelected = checked ? 1 : 0;
    if (node.childElements) {
      node.childElements.forEach((childNode) => {
        this.itemToggle(childNode, checked);
      });
    }
    this.checkAllParents(node);
  }

  private checkAllParents(node: CorporateStructure): void {
    if (node.parentElement) {
      const descendants = this.treeControl.getDescendants(node.parentElement);
      node.parentElement.boxChecked = descendants.every((child) => child.boxChecked);
      node.parentElement.indeterminate = !node.parentElement.boxChecked && descendants.some((child) => child.boxChecked);
      if (node.parentElement.boxChecked) {
        node.parentElement.isSelected = 1;
      } else if (node.parentElement.indeterminate) {
        node.parentElement.isSelected = 2;
      } else {
        node.parentElement.isSelected = 0;
      }
      node.parentElement.updatedByUser = true;
      this.checkAllParents(node.parentElement);
    } else {
      // no more processing to parents, emit onSelectionsProcessed
      this.onSelectionsProcessed.emit();
    }
  }

  private setParent(node: CorporateStructure, parent: CorporateStructure): void {
    node.parentElement = parent;
    if (node.childElements) {
      node.childElements.forEach((childNode) => {
        this.setParent(childNode, node);
      });
    }
  }

  /* no longer used, indeterminate status now managed thru indeterminate
   * property passed to template
  private markCheckboxIndeterminate(element: CorporateStructure, indeterminate:boolean) {
    let cbEl = document
      .getElementById('cbFilter_' + + (this.selectorId ? this.selectorId + '_' : '')+ element.elementGuid) as HTMLInputElement;
    if (cbEl) {
      cbEl.indeterminate = indeterminate;
    }
  }
  */
}
