<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" style="padding-bottom:20px">
  <div class="col-md-9">
    <h3>{{driverInfo.label}}</h3>
    <h4 *ngIf="isNewDriver" style="margin-left:2rem">{{driverGroup}}</h4>
    <h4 *ngIf="blockProfileEdit" style="color:red;margin-left:2rem">{{blockProfileEditMsg}}</h4>
  </div>
  <div class="col-md-3">
    <button type="button" (click)="submitDriverForm(true)" class="btn btn-primary">
      Submit
    </button>
    <button type="button" (click)="exitDriverForm(false)" class="btn btn-primary">
      Exit
    </button>
  </div>
</div>
<ng-container *ngIf="driverInfo&&driverInfo.driverBaseInfo&&formSettingsDictionaryLoaded">
  <div class="row" *ngIf="driverInfo&&driverInfo.driverBaseInfo&&driverInfo.driverBaseInfo.primaryDriverId">
    <div class="col-md-12">
      <app-safety-primary-driver-link [primaryDriverId]="driverInfo.driverBaseInfo.primaryDriverId">
      </app-safety-primary-driver-link>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <mat-accordion multi="true">
        <mat-expansion-panel [expanded]="!expandGeneralInfo">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="title">General Information</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row mt-2" style="margin-left:1rem;margin-right:1rem;">
            <div class="col-md-12">
              <div *ngIf="!isNewDriver" class="row extra-margin">
                <div class="col-md-12">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Group</mat-label>
                    <mat-select #groupSelect [(value)]="driverInfo.driverBaseInfo.groupGuid">
                      <input matInput class="select-search-input" placeholder="Search Groups..." (keyup)="filterGroupOptions($event.target.value)">
                      <mat-option *ngFor="let option of filteredGroupOptions" [value]="option.value">
                        {{option.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row" *ngIf="canMarkDriverAsTOP">
                <div class="col-md-8"></div>
                <div class="col-md-4">
                  <div class="switch" style="padding-top:1rem">
                    <label>
                      <input type="checkbox" [checked]="driverInfo.driverBaseInfo.trainingOnlyPortalAccess"
                        [(ngModel)]="driverInfo.driverBaseInfo.trainingOnlyPortalAccess"
                        (change)="toggleMarkAsTOP()" />
                      <span class="lever">&nbsp;</span>
                    </label>
                    <span
                      [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.driverBaseInfo.trainingOnlyPortalAccess}">Has
                      no email</span>
                  </div>
                </div>
              </div>
              <div *ngIf="isEmployeeDriver && formSettingsDictionary['employeeId'].isVisible"
                [ngClass]="{'row':true, 'extra-margin':isNewDriver, 'rem1-margin':!isNewDriver}">
                <div class="col-md-4">
                  <div class="md-form">
                    <input id="driverId" type="text" [(ngModel)]="driverInfo.driverBaseInfo.employeeId"
                      class="form-control" [formControl]="formSettingsDictionary['employeeId'].formControl"
                      placeholder="Employee ID" />
                    <label for="driverId"
                      [ngClass]="{ 'label-validation-error': formSettingsDictionary['employeeId'].formControl.invalid, 'active':true }">Employee
                      ID<span class="font-weight-light" *ngIf="!isNewDriver"> (if changed, claims will be disconnected)</span></label>
                  </div>
                </div>
                <div class="col-md-8">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Choose Country</mat-label>
                    <mat-select #selectCountry [(value)]="driverCountry">
                      <mat-option *ngFor="let option of countryOptions" [value]="option.value">
                        {{option.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>                   
              </div>
              <div [ngClass]="{'row':true, 'rem1-margin':!isEmployeeDriver}">  
                <div class="col-md-4" *ngIf="formSettingsDictionary['firstName'].isVisible">
                  <div class="md-form">
                    <input id="driverFirstName" type="text" [(ngModel)]="driverInfo.driverBaseInfo.firstName"
                      [formControl]="formSettingsDictionary['firstName'].formControl" class="form-control"
                      placeholder="First Name" />
                    <label for="driverFirstName"
                      [ngClass]="{ 'label-validation-error': formSettingsDictionary['firstName'].formControl.invalid, 'active':true }">First
                      Name</label>
                  </div>
                </div>
                <div class="col-md-4" *ngIf="formSettingsDictionary['middleName'].isVisible">
                  <div class="md-form">
                    <input id="driverMiddleName" type="text" [(ngModel)]="driverInfo.driverBaseInfo.middleName"
                      [disabled]="blockProfileEdit" class="form-control" placeholder="Middle Name" />
                    <label for="driverMiddleName" class="active">Middle Name</label>
                  </div>
                </div>
                <div class="col-md-4" *ngIf="formSettingsDictionary['lastName'].isVisible">
                  <div class="md-form">
                    <input id="driverLastName" type="text" [(ngModel)]="driverInfo.driverBaseInfo.lastName"
                      [formControl]="formSettingsDictionary['lastName'].formControl" class="form-control"
                      placeholder="Last Name" />
                    <label for="driverLastName"
                      [ngClass]="{ 'label-validation-error': formSettingsDictionary['lastName'].formControl.invalid, 'active':true }">Last
                      Name</label>
                  </div>
                </div>
              </div>
              <div *ngIf="showEmailFields && formSettingsDictionary['emailAddress'].isVisible" class="row">
                <div class="col-md-6">
                  <div class="md-form">
                    <input id="driverEmail" type="text" [(ngModel)]="driverInfo.driverBaseInfo.emailAddress"
                      [formControl]="formSettingsDictionary['emailAddress'].formControl" class="form-control"
                      placeholder="Email Address" />
                    <label for="driverEmail"
                      [ngClass]="{ 'label-validation-error': formSettingsDictionary['emailAddress'].formControl.invalid, 'active':true }">Email
                      Address</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="md-form">
                    <input id="driverEmailConfirm" type="text"
                      [(ngModel)]="driverInfo.driverBaseInfo.emailAddressConfirm"
                      [formControl]="formSettingsDictionary['emailAddressConfirm'].formControl" class="form-control"
                      placeholder="Re-Type Email to Confirm" />
                    <label for="driverEmailConfirm"
                      [ngClass]="{ 'label-validation-error': formSettingsDictionary['emailAddressConfirm'].formControl.invalid, 'active':true }">Re-Type
                      Email to Confirm</label>
                  </div>
                </div>
              </div>
              <div *ngIf="showDOTDriver || showAutoCoverageRequired || showLanguage" class="row">
                <div *ngIf="showDOTDriver || showAutoCoverageRequired" class="col-md-6">
                  <div class="switch" *ngIf="showDOTDriver">
                    <label>
                      <input type="checkbox" [checked]="driverInfo.driverBaseInfo.dotDriver"
                        [(ngModel)]="driverInfo.driverBaseInfo.dotDriver" (change)="updateDriverCOV()" />
                      <span class="lever">&nbsp;</span>
                    </label>
                    <span
                      [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.driverBaseInfo.dotDriver, 'active':true}">DOT
                      Driver</span>
                  </div>
                  <div class="switch" *ngIf="showAutoCoverageRequired">
                    <label>
                      <input type="checkbox" [checked]="driverInfo.driverBaseInfo.requiresAutoCoverage"
                        [(ngModel)]="driverInfo.driverBaseInfo.requiresAutoCoverage" (change)="updateDriverCOV()" />
                      <span class="lever">&nbsp;</span>
                    </label>
                    <span
                      [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.driverBaseInfo.requiresAutoCoverage, 'active':true}">Auto Coverage Required</span>
                  </div>                    
                </div>
                <div class="col-md-6" *ngIf="showLanguage">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Choose Country</mat-label>
                    <mat-select #selectCountry [(value)]="driverInfo.driverBaseInfo.languageId">
                      <mat-option *ngFor="let option of languageOptions" [value]="option.value">
                        {{option.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <div class="switch" style="padding-top:1rem">
                    <label>
                      <input type="checkbox" [checked]="driverInfo.driverBaseInfo.isCommercialDriversLicense"
                        [(ngModel)]="driverInfo.driverBaseInfo.isCommercialDriversLicense" />
                      <span class="lever">&nbsp;</span>
                    </label>
                    <span
                      [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.driverBaseInfo.isCommercialDriversLicense}">Commercial Driver's License (CDL)</span>
                  </div>
                </div>
              </div>
              <div *ngIf="this.formSettingsDictionary['hireDate'].isVisible" class="row">
                <div class="col-md-12">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label class="lbl-start-date">Date of Hire</mat-label>
                    <input class="input-date" matInput disabled 
                      [matDatepicker]="hireDatePicker" 
                      [(ngModel)]="driverInfo.driverBaseInfo.hireDate"
                      [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="hireDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #hireDatePicker disabled="false">
                      <mat-datepicker-actions>
                        <button mat-button (click)="hireDatePicker.select(actualDate); hireDatePicker.close()">
                          <mat-icon>history</mat-icon>
                          Today
                        </button>
                        <button mat-button (click)="hireDatePicker.select(null); hireDatePicker.close()">
                          <mat-icon>highlight_off</mat-icon>
                          Clear
                        </button>
                      </mat-datepicker-actions> 
                    </mat-datepicker>
                  </mat-form-field>
                </div>
                
              </div>
              <div
                *ngIf="formSettingsDictionary['isTemporaryDriver']&&formSettingsDictionary['isTemporaryDriver'].isVisible"
                class="row rem1-margin">
                <div class="col-md-4">
                  Is Non-Employee Driver Permanent or Temporary?
                </div>
                <div class="col-md-8">
                  <form class="form-inline">
                    <div class="form-check mr-3">
                      <input class="form-check-input" name="driverPermTemp" type="radio" id="radioDriverPermanent"
                        [(ngModel)]="driverInfo.driverBaseInfo.driverDuration"
                        [formControl]="formSettingsDictionary['isTemporaryDriver'].formControl" value="1"
                        (change)="updateDriverPermTemp(true)">
                      <label
                        [ngClass]="{ 'label-validation-error': formSettingsDictionary['isTemporaryDriver'].formControl.invalid, 'form-check-label':true }"
                        for="radioDriverPermanent">Permanent</label>
                    </div>
                    <div class="form-check mr-3">
                      <input class="form-check-input" name="driverPermTemp" type="radio" id="radioDriverTemporary"
                        [(ngModel)]="driverInfo.driverBaseInfo.driverDuration"
                        [formControl]="formSettingsDictionary['isTemporaryDriver'].formControl" value="2"
                        (change)="updateDriverPermTemp(true)">
                      <label
                        [ngClass]="{ 'label-validation-error': formSettingsDictionary['isTemporaryDriver'].formControl.invalid, 'form-check-label':true }"
                        for="radioDriverTemporary">Temporary</label>
                    </div>
                  </form>
                </div>
              </div>
              <div
                *ngIf="showTemporaryDriverExpirationDate&&formSettingsDictionary['temporaryDriverExpirationDate']&&formSettingsDictionary['temporaryDriverExpirationDate'].isVisible"
                class="row rem1-margin">
                <div class="col-md-12">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label class="lbl-start-date" [ngClass]="{ 'label-validation-error': formSettingsDictionary['temporaryDriverExpirationDate'].formControl.invalid, 'date-picker-label':true }">Expiration Date</mat-label>
                    <input class="input-date" matInput disabled [matDatepicker]="expirationDatePicker"
                      [(ngModel)]="driverInfo.driverBaseInfo.temporaryDriverExpirationDate" 
                      [formControl]="formSettingsDictionary['temporaryDriverExpirationDate'].formControl"
                      [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #expirationDatePicker [disabled]="blockProfileEdit||formSettingsDictionary['temporaryDriverExpirationDate'].isReadOnly">
                      <mat-datepicker-actions>
                        <button mat-button (click)="expirationDatePicker.select(actualDate); expirationDatePicker.close()">
                          <mat-icon>history</mat-icon>
                          Today
                        </button>
                        <button mat-button (click)="expirationDatePicker.select(null); expirationDatePicker.close()">
                          <mat-icon>highlight_off</mat-icon>
                          Clear
                        </button>
                      </mat-datepicker-actions> 
                    </mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div
                *ngIf="formSettingsDictionary['livesWithPrimaryDriver']&&formSettingsDictionary['livesWithPrimaryDriver'].isVisible"
                class="row rem1-margin">
                <div class="col-md-4">
                  <div class="switch">
                    <label>
                      <input type="checkbox" [checked]="driverInfo.driverBaseInfo.livesWithPrimaryDriver"
                        [(ngModel)]="driverInfo.driverBaseInfo.livesWithPrimaryDriver"
                        [formControl]="formSettingsDictionary['livesWithPrimaryDriver'].formControl"
                        (change)="updateDriverPermTemp(true)" />
                      <span class="lever">&nbsp;</span>
                    </label>
                    <span
                      [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.driverBaseInfo.livesWithPrimaryDriver, 'active':true, 'label-validation-error': formSettingsDictionary['livesWithPrimaryDriver'].formControl.invalid}">Lives
                      in Household?</span>
                  </div>
                </div>
                <div *ngIf="driverPermTempMsg.length > 0" class="col-md-8" style="color:red">
                  {{driverPermTempMsg}}
                </div>
              </div>
              <div
                *ngIf="formSettingsDictionary['primaryDriverRelationshipId']&&formSettingsDictionary['primaryDriverRelationshipId'].isVisible"
                class="row rem2-margin">
                <div class="col-md-12">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label [ngClass]="{ 'label-validation-error': formSettingsDictionary['primaryDriverRelationshipId'].formControl.invalid, 'active':true  }">Choose Relationship to Primary Driver</mat-label>
                    <mat-select #selectCountry 
                      [(value)]="driverInfo.driverBaseInfo.primaryDriverRelationshipId"
                      [formControl]="formSettingsDictionary['primaryDriverRelationshipId'].formControl">
                      <mat-option *ngFor="let option of relationshipTypeOptions" [value]="option.value">
                        {{option.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="!expandExtendedInfo" *ngIf="extendedInfo&&(extendedInfo.length>0)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="title">Extended Information</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngFor="let e of extendedInfo; let i = index" class="row mt-3" style="margin-left:1rem;margin-right:1rem;">
            <div class="col-md-12">
              <div class="row extra-margin">
                <div class="col-md-12">
                  <div class="md-form">
                    <input [id]="'driverExtInfo_'+(i+1)" type="text"
                      [(ngModel)]="driverInfo.driverBaseInfo[e.reqField.toLowerCase()]" class="form-control"
                      [formControl]="e.formControl" [placeholder]="e.reqDescription" />
                    <label [for]="'driverExtInfo_'+(i+1)"
                      [ngClass]="{ 'label-validation-error': e&&e.formControl&&e.formControl.invalid, 'active':true }">{{e.reqDescription}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="showLicenseNumberAndState" [expanded]="!expandLicenseInfo">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="title">Driver's License Information (required to order MVRs)</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row mt-3" style="margin-left:1rem;margin-right:1rem;">
            <div class="col-md-12">
              <div class="row extra-margin">
                <div class="col-md-8" *ngIf="formSettingsDictionary['driverLicenseNumber'].isVisible">
                  <div class="md-form">
                    <input id="driverLicenseNo" type="text"
                      [(ngModel)]="driverInfo.driverBaseInfo.driverLicenseNumber"
                      [formControl]="formSettingsDictionary['driverLicenseNumber'].formControl" class="form-control"
                      placeholder="License # (Note: always check the expiration date)" />
                    <label for="driverLicenseNo"
                      [ngClass]="{ 'label-validation-error': formSettingsDictionary['driverLicenseNumber'].formControl.invalid, 'active':true }">License
                      # (Note: always check the expiration date)</label>
                  </div>
                </div>
                <div class="col-md-4" *ngIf="formSettingsDictionary['driverLicenseState'].isVisible">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label [ngClass]="{ 'label-validation-error': formSettingsDictionary['driverLicenseState'].formControl.invalid, 'active':true  }">State/Province</mat-label>
                      <mat-select #licenseStateSelect 
                        [(value)]="driverInfo.driverBaseInfo.driverLicenseState" 
                        [formControl]="formSettingsDictionary['driverLicenseState'].formControl">
                        <input matInput class="select-search-input" placeholder="Search Groups..." (keyup)="filterStateOptions($event.target.value)">
                        <mat-option *ngFor="let option of filteredStateOptions" [value]="option.value">
                          {{option.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>
              </div>
              <div class="row" *ngIf="formSettingsDictionary['dateOfBirth'].isVisible">
                <div class="col-md-12">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label class="lbl-start-date" [ngClass]="{ 'label-validation-error': formSettingsDictionary['dateOfBirth'].formControl.invalid, 'date-picker-label':true }">Date of Birth</mat-label>
                    <input class="input-date" matInput disabled [matDatepicker]="licenseDateOfBirthPicker"
                    [(ngModel)]="driverInfo.driverBaseInfo.dateOfBirth"
                    [formControl]="formSettingsDictionary['dateOfBirth'].formControl"
                    [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="licenseDateOfBirthPicker"></mat-datepicker-toggle>
                    <mat-datepicker #licenseDateOfBirthPicker [disabled]="blockProfileEdit||formSettingsDictionary['dateOfBirth'].isReadOnly">
                      <mat-datepicker-actions>
                        <button mat-button (click)="licenseDateOfBirthPicker.select(actualDate); licenseDateOfBirthPicker.close()">
                          <mat-icon>history</mat-icon>
                          Today
                        </button>
                        <button mat-button (click)="licenseDateOfBirthPicker.select(null); licenseDateOfBirthPicker.close()">
                          <mat-icon>highlight_off</mat-icon>
                          Clear
                        </button>
                      </mat-datepicker-actions> 
                    </mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="showLocationSection" [expanded]="!expandLocation">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="title">Location</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" style="margin-left:1rem;margin-right:1rem;">
            <div class="col-md-12">
              <div class="row extra-margin">
                <div class="col-md-12">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Choose Driver's Location</mat-label>
                    <mat-select #driverLocationsSelect 
                      [(value)]="driverInfo.driverBaseInfo.licenseState" 
                      [disabled]="blockProfileEdit">
                      <input matInput class="select-search-input" placeholder="Search Locations..." (keyup)="filterStateOptions($event.target.value)">
                      <mat-option *ngFor="let option of filteredStateOptions" [value]="option.value">
                        {{option.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="showTrainingSection" [expanded]="expandTraining">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="title">Training</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngIf="(!trainingOptions) || (trainingOptions.length <= 0)" class="row">
            <div class="col-md-12">
              <h4 style="text-align:center">*** All Training Lessons Have Been Assigned ***</h4>
            </div>
          </div>
          <div *ngIf="trainingOptions && (trainingOptions.length > 0)" class="row"
            style="margin-left:1rem;margin-right:1rem;">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="switch" style="padding-top:1rem">
                    <label>
                      <input type="checkbox" [checked]="driverInfo.assignOnlineTraining"
                        [(ngModel)]="driverInfo.assignOnlineTraining" />
                      <span class="lever">&nbsp;</span>
                    </label>
                    <span
                      [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.assignOnlineTraining}">Assign
                      online Training</span>
                  </div>
                </div>
                <div class="col-md-8">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label class="lbl-start-date">Date Due</mat-label>
                    <input class="input-date" matInput disabled [matDatepicker]="trainingDueDatePicker" 
                      [(ngModel)]="driverInfo.assignOnlineTrainingDateDue"
                      [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="trainingDueDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #trainingDueDatePicker [disabled]="!driverInfo.assignOnlineTraining">
                      <mat-datepicker-actions>
                        <button mat-button (click)="trainingDueDatePicker.select(actualDate); trainingDueDatePicker.close()">
                          <mat-icon>history</mat-icon>
                          Today
                        </button>
                        <button mat-button (click)="trainingDueDatePicker.select(null); trainingDueDatePicker.close()">
                          <mat-icon>highlight_off</mat-icon>
                          Clear
                        </button>
                      </mat-datepicker-actions> 
                    </mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="row extra-margin">
                <div class="col-md-6">
                  <div class="md-form">
                    <input type="text" id="searchText" name="searchText" class="form-control"
                      placeholder="Enter 3 or more characters to Search by Description" [(ngModel)]="searchText"
                      (ngModelChange)="onTextSearch()" />
                    <label for="searchText" class="active">Search</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Search By Category</mat-label>
                    <mat-select #trainingCategorySelect [(value)]="categorySearchValue" (selectionChange)="onCategorySearch($event)">
                      <input matInput class="select-search-input" placeholder="Search Categories..." (keyup)="filterCategoryOptions($event.target.value)">
                      <mat-option *ngFor="let option of filteredTrainingCategoryOptions" [value]="option.value">
                        {{option.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row rem1-margin">
                <div class="col-md-12">
                  <div class="trainingListContainer">
                    <ul>
                      <li *ngFor="let t of trainingOptionsFiltered">
                        <input type="checkbox" class="input-dark" name="checkb" id="checkb-{{t.value}}"
                          value="{{t.value}}" [(ngModel)]="t.selected"
                          [disabled]="!driverInfo.assignOnlineTraining" />
                        <label class="label-base" for="checkb-{{t.value}}">{{t.label}}</label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="showServicesSection" [expanded]="expandServices">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="title">Services</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" style="margin-left:1rem;margin-right:1rem;">
            <div class="col-md-12">

              <div class="row" *ngIf="showVerifyLicenseInfo">
                <div class="col-md-4">
                  <div class="switch" style="padding-top:1rem">
                    <label>
                      <input type="checkbox" [checked]="driverInfo.confirmLicenseInfo"
                        [(ngModel)]="driverInfo.confirmLicenseInfo" (change)="confirmLicenseInfoOnChange()"
                        [disabled]="blockProfileEdit" />
                      <span class="lever">&nbsp;</span>
                    </label>
                    <span [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.confirmLicenseInfo}">Have
                      the driver confirm license information</span>
                  </div>
                </div>
                <div class="col-md-8">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label class="lbl-start-date">Date Due</mat-label>
                    <input class="input-date" matInput disabled [matDatepicker]="confirmlicenseDateDuePicker" 
                      [(ngModel)]="driverInfo.confirmLicenseInfoDateDue"
                      [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="confirmlicenseDateDuePicker"></mat-datepicker-toggle>
                    <mat-datepicker #confirmlicenseDateDuePicker [disabled]="!driverInfo.confirmLicenseInfo">
                      <mat-datepicker-actions>
                        <button mat-button (click)="confirmlicenseDateDuePicker.select(actualDate); confirmlicenseDateDuePicker.close()">
                          <mat-icon>history</mat-icon>
                          Today
                        </button>
                        <button mat-button (click)="confirmlicenseDateDuePicker.select(null); confirmlicenseDateDuePicker.close()">
                          <mat-icon>highlight_off</mat-icon>
                          Clear
                        </button>
                      </mat-datepicker-actions> 
                    </mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="row" *ngIf="showMonitoring">
                <div class="col-md-4">
                  <div class="switch" style="padding-top:1rem">
                    <label>
                      <input type="checkbox" [checked]="driverInfo.enrollInMonitoring"
                        [(ngModel)]="driverInfo.enrollInMonitoring" />
                      <span class="lever">&nbsp;</span>
                    </label>
                    <span
                      [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.enrollInMonitoring}">Enroll
                      driver in MVR Monitoring</span>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="showRequestMVR">
                <div class="col-md-4">
                  <div class="switch" style="padding-top:1rem">
                    <label>
                      <input type="checkbox" [checked]="driverInfo.requestMVR" [(ngModel)]="driverInfo.requestMVR"
                        [disabled]="blockProfileEdit||driverInfo.dqRequestCOV" (change)="requestMVROnChange()" />
                      <span class="lever">&nbsp;</span>
                    </label>
                    <span [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.requestMVR}">Request a MVR
                      for the driver</span>
                  </div>
                </div>
                <div class="col-md-2" *ngIf="!isNewDriver">
                  <label for="lastMvrOrdered" style="padding-top:12px">Last MVR Ordered:</label>
                </div>
                <div class="col-md-6" *ngIf="!isNewDriver">
                  <label for="mvrOrderedDate" style="padding-top:12px">{{driverInfo.mvrOrderedDate |  date:'MM/dd/yyyy'}}</label>
                </div>
              </div>

              <div class="row" *ngIf="canOrderNonEmpMVR && isEmployeeDriver">
                <div class="col-md-4">
                  <div class="switch" style="padding-top:1rem">
                    <label>
                      <input type="checkbox" [checked]="driverInfo.requestNonEmployeeMVR"
                        [(ngModel)]="driverInfo.requestNonEmployeeMVR" />
                      <span class="lever">&nbsp;</span>
                    </label>
                    <span
                      [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.requestNonEmployeeMVR}">Request
                      a MVR for the non-employee driver</span>
                  </div>
                </div>
              </div>

              <div class="row">
                  <div class="col-md-4">
                    <div class="switch" style="padding-top:1rem">
                      <label>
                        <input *ngIf="driverInfo.driverBaseInfo.requiresAutoCoverage" type="checkbox"
                          [checked]="driverInfo.requestCOI" [(ngModel)]="driverInfo.requestCOI"
                          [disabled]="!driverInfo.driverBaseInfo.requiresAutoCoverage" />
                        <span class="lever">&nbsp;</span>
                      </label>
                      <span [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.requestCOI}">Have Driver Upload Auto Coverage</span>
                    </div>
                  </div>                 
              </div>

              <div class="row" *ngIf="showDriverLicenseUpload && !driverInfo.dqRequired">
                <div class="col-md-4">
                  <div class="switch" style="padding-top:1rem">
                    <label>
                      <input type="checkbox" [checked]="driverInfo.driverLicenseUpload"
                        [(ngModel)]="driverInfo.driverLicenseUpload" />
                      <span class="lever">&nbsp;</span>
                    </label>
                    <span
                      [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.driverLicenseUpload}">Driver
                      License Upload</span>
                  </div>
                </div>

                <div class="col-md-8">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label class="lbl-start-date">License Upload Date Due</mat-label>
                    <input class="input-date" matInput disabled 
                      [matDatepicker]="licenseUploadDateDuePicker" 
                      [(ngModel)]="driverInfo.driverLicenseUploadDateDue"
                      [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="licenseUploadDateDuePicker"></mat-datepicker-toggle>
                    <mat-datepicker #licenseUploadDateDuePicker 
                      [disabled]="!driverInfo.driverLicenseUpload">
                      <mat-datepicker-actions>
                        <button mat-button (click)="licenseUploadDateDuePicker.select(actualDate); licenseUploadDateDuePicker.close()">
                          <mat-icon>history</mat-icon>
                          Today
                        </button>
                        <button mat-button (click)="licenseUploadDateDuePicker.select(null); licenseUploadDateDuePicker.close()">
                          <mat-icon>highlight_off</mat-icon>
                          Clear
                        </button>
                      </mat-datepicker-actions> 
                    </mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="row" *ngIf="showCOV">
                <div class="col-md-4">
                  <div class="switch" style="padding-top:1rem">
                    <label>
                      <input type="checkbox" [checked]="driverInfo.requestCOV" [disabled]="disableDriverCOV"
                        [(ngModel)]="driverInfo.requestCOV" />
                      <span class="lever">&nbsp;</span>
                    </label>
                    <span [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.requestCOV}">Driver's
                      Certificate of Violation</span>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="showPolicyTask">
                <div class="col-md-4">
                  <div class="switch" style="padding-top:1rem">
                    <label>
                      <input type="checkbox" [checked]="driverInfo.orderPolicyTask"
                        [(ngModel)]="driverInfo.orderPolicyTask" [disabled]="blockProfileEdit" />
                      <span class="lever">&nbsp;</span>
                    </label>
                    <span [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.orderPolicyTask}">Policy
                      Sign Off</span>
                  </div>
                </div>
                <div class="col-md-8">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label class="lbl-start-date">Policy Sign Off Date Due</mat-label>
                    <input class="input-date" matInput disabled 
                      [matDatepicker]="policySignOffDatePicker" 
                      [(ngModel)]="driverInfo.policyTaskDueDate"
                      [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="policySignOffDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #policySignOffDatePicker 
                      disabled="false">
                      <mat-datepicker-actions>
                        <button mat-button (click)="policySignOffDatePicker.select(actualDate); policySignOffDatePicker.close()">
                          <mat-icon>history</mat-icon>
                          Today
                        </button>
                        <button mat-button (click)="policySignOffDatePicker.select(null); policySignOffDatePicker.close()">
                          <mat-icon>highlight_off</mat-icon>
                          Clear
                        </button>
                      </mat-datepicker-actions> 
                    </mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div *ngIf="isEmployeeDriver && clientSettings && clientSettings.isDriverQualificationOn">
                <div class="row">
                  &nbsp;
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="switch" style="padding-top:1rem">
                      <label>
                        <input type="checkbox" 
                          [checked]="driverInfo.dqRequired" 
                          [(ngModel)]="driverInfo.dqRequired"
                          (change)="updateDqSelections(driverInfo.dqRequired)" />
                        <span class="lever">&nbsp;</span>
                      </label>
                      <span [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.dqRequired}">DQ Required
                        for Driver</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="switch" style="padding-top:1rem">
                      <label>
                        <input type="checkbox" 
                          [checked]="driverInfo.dqRequestLicenseUpload"
                          [(ngModel)]="driverInfo.dqRequestLicenseUpload" 
                          [disabled]="!driverInfo.dqRequired" />
                        <span class="lever">&nbsp;</span>
                      </label>
                      <span
                        [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.dqRequestLicenseUpload}">DQ
                        Request License Upload</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="switch" style="padding-top:1rem">
                      <label>
                        <input *ngIf="clientSettings.isDQDriverApplicationOn" type="checkbox"
                          [checked]="driverInfo.dqRequestApplication" [(ngModel)]="driverInfo.dqRequestApplication"
                          [disabled]="!driverInfo.dqRequired" />
                        <span class="lever">&nbsp;</span>
                      </label>
                      <span
                        [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.dqRequestApplication}">DQ
                        Request Application</span>
                    </div>
                  </div>                    
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="switch" style="padding-top:1rem">
                      <label>
                        <input *ngIf="clientSettings.isDQMedCardUploadOn" type="checkbox"
                          [checked]="driverInfo.dqRequestMedCard" [(ngModel)]="driverInfo.dqRequestMedCard"
                          [disabled]="!driverInfo.dqRequired" />
                        <span class="lever">&nbsp;</span>
                      </label>
                      <span [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.dqRequestMedCard}">DQ
                        Request Medical Certificate</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="switch" style="padding-top:1rem">
                      <label>
                        <input *ngIf="clientSettings.isDQCertificateOfRoadTestOn" type="checkbox"
                          [checked]="driverInfo.dqRequestCertOfRoadTest"
                          [(ngModel)]="driverInfo.dqRequestCertOfRoadTest" [disabled]="!driverInfo.dqRequired" />
                        <span class="lever">&nbsp;</span>
                      </label>
                      <span
                        [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.dqRequestCertOfRoadTest}">DQ
                        Request Certificate of Road Test</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="switch" style="padding-top:1rem">
                      <label>
                        <input *ngIf="clientSettings.isCertificateOfViolationOn" type="checkbox"
                          [checked]="driverInfo.dqRequestCOV" [(ngModel)]="driverInfo.dqRequestCOV"
                          (change)="dqRequestCOVOnChange(driverInfo.dqRequestCOV)"
                          [disabled]="!driverInfo.dqRequired" />
                        <span class="lever">&nbsp;</span>
                      </label>
                      <span [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.dqRequestCOV}">DQ
                        Request COV</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="switch" style="padding-top:1rem">
                      <label>
                        <input *ngIf="clientSettings.isDQCertificateOfRoadTestOn" type="checkbox"
                          [checked]="driverInfo.dqRequestRecordOfRoadTest"
                          [(ngModel)]="driverInfo.dqRequestRecordOfRoadTest" [disabled]="!driverInfo.dqRequired" />
                        <span class="lever">&nbsp;</span>
                      </label>
                      <span
                        [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.dqRequestRecordOfRoadTest}">DQ
                        Request Record of Road Test</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div *ngIf="clientSettings.isDQDriverLogUploadOn" class="switch" style="padding-top:1rem">
                      <label>
                        <input type="checkbox"
                          [checked]="driverInfo.dqRequestDriverLog" 
                          [(ngModel)]="driverInfo.dqRequestDriverLog" [disabled]="!driverInfo.dqRequired" />
                        <span class="lever">&nbsp;</span>
                      </label>
                      <span [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.dqRequestDriverLog}">DQ
                        Request Driver Log</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div *ngIf="clientSettings.isDQClearingHouseLimitedSignoffOn" class="switch" style="padding-top:1rem">
                      <label>
                        <input type="checkbox"
                          [checked]="driverInfo.dqRequestClearingHouseSignoff"
                          [(ngModel)]="driverInfo.dqRequestClearingHouseSignoff" [disabled]="!driverInfo.dqRequired" />
                        <span class="lever">&nbsp;</span>
                      </label>
                      <span
                        [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.dqRequestClearingHouseSignoff}">Clearing House Limited Signoff</span>
                    </div>
                  </div>
                </div>                  
              </div>

              <div class="row" *ngIf="showResetPassword || showResendLogin">
                &nbsp;
              </div>

              <div class="row" *ngIf="showResetPassword">
                <div class="col-md-4">
                  <div class="switch" style="padding-top:1rem">
                    <label>
                      <input type="checkbox" [checked]="driverInfo.resetPassword"
                        [(ngModel)]="driverInfo.resetPassword" />
                      <span class="lever">&nbsp;</span>
                    </label>
                    <span [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.resetPassword}">Reset
                      Password for the driver</span>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="showResendLogin">
                <div class="col-md-4">
                  <div class="switch" style="padding-top:1rem">
                    <label>
                      <input type="checkbox" [checked]="driverInfo.resendLogon"
                        [(ngModel)]="driverInfo.resendLogon" />
                      <span class="lever">&nbsp;</span>
                    </label>
                    <span [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverInfo.resendLogon}">Resend Login
                      for the driver</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="false" *ngIf="canUpload">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="title">Upload</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" *ngIf="isNewDriver&&(!showUploadPanel)">
            <div class="col-md-12">
              <label for="uploadingDocuments">The new driver must be saved prior to uploading documents. Please click "SAVE AND UPLOAD" to
                continue.</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button *ngIf="isNewDriver&&(!showUploadPanel)" class="btn btn-primary" style="margin:-10px 0 0 10px;"
                (click)="saveThenUpload()">
                SAVE AND UPLOAD
              </button>
              <button *ngIf="!isNewDriver&&(!showUploadPanel)" class="btn btn-primary" style="margin:-10px 0 0 10px;"
                (click)="toggleUploadPanel(!showUploadPanel)">
                Upload
              </button>
            </div>
          </div>
          <div *ngIf="showUploadPanel" class="row">
            <div class="col-md-12">
              <document-upload [uploadType]="'EmployeeDriverForm'" [driverProfile]="driverProfile"
                [driverId]="driverId" (onDocUploadExit)="toggleUploadPanel(false)"
                (onDocUploadRefresh)="uploadFinished()">
              </document-upload>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</ng-container>
<div class="row">
  <div class="col-md-9">

  </div>
  <div class="col-md-3">
    <button type="button" (click)="submitDriverForm(true)" class="btn btn-primary">
      Submit
    </button>
    <button type="button" (click)="exitDriverForm(false)" class="btn btn-primary">
      Exit
    </button>
  </div>
</div>