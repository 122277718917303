<div class="row widgetListWrapper">
  <div class="col-md-12">
    <div class="row">
      <div class="col-sm-8">
        <h3>Add Chart</h3>
      </div>
      <div class="col-sm-4">
        <span style="float:right">
          <button type="button"
                  (click)="closeAddWidgetPanel()"
                  class="btn btn-primary">
            Exit
          </button>
        </span>
      </div>
    </div>
    <div class="row widgetInfoRow" *ngIf="widgetList.length <= 0">
      <div class="col-sm-12">
        No additional charts available
      </div>
    </div>
    <div class="row widgetInfoRow" *ngIf="widgetList.length > 0">
      <div class="col-md-6">
        <div class="md-form">
          <input type="text"
                 id="searchText"
                 name="searchText"
                 class="form-control"
                 placeholder="Enter 3 or more characters to Search by Title or Description"
                 [(ngModel)]="searchText" (ngModelChange)="onTextSearch()" />
          <label for="searchText" class="active">Search</label>
        </div>
      </div>
      <div class="col-md-6">
        <ul class="searchByCat" *ngIf="arrCategoryChoices">
          <li *ngFor="let cat of arrCategoryChoices">
            <input type="checkbox"
                   class="input-dark"
                   name="checkb"
                   id="checkb-{{cat.categoryId}}"
                   value="{{cat.categoryId}}"
                   [(ngModel)]="cat.selected"
                   (change)="onCategorySearch()" />
            <label class="label-base" for="checkb-{{cat.categoryId}}">{{cat.categoryName}}</label>
          </li>
        </ul>

      </div>
    </div>
    <app-widget-user-add-list *ngIf="arrCategoryChoicesFiltered.length <=0"
                              [widgetListFiltered]="widgetListFiltered"
                              [arrCategoryChoices]="arrCategoryChoices"
                              (onSelectCategory)="selectCategory($event)"
                              (onAddWidget)="addWidget($event)">
    </app-widget-user-add-list>
    <app-widget-user-add-list *ngFor="let catSel of arrCategoryChoicesFiltered"
                              [widgetListCategory]="catSel.categoryName"
                              [widgetListFiltered]="getWidgetListByCategory(catSel.categoryId, widgetListFiltered)"
                              [arrCategoryChoices]="arrCategoryChoices"
                              (onSelectCategory)="selectCategory($event)"
                              (onAddWidget)="addWidget($event)">
    </app-widget-user-add-list>
  </div>
</div>
