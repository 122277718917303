import { Component, Input, Output, AfterViewInit, ViewChildren, QueryList, Renderer2, ChangeDetectorRef, EventEmitter } from '@angular/core';
//Third Party
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
//App
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DcProductDocumentCategory, DcProductDocument } from '../dc-classes-and-interfaces/dc-classes-and-interfaces.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-dc-document-library-folder',
  templateUrl: './dc-document-library-folder.component.html',
  styleUrls: ['./dc-document-library-folder.component.scss']
})
export class DcDocumentLibraryFolderComponent implements AfterViewInit {
  @Input() parentDocCategory: DcProductDocumentCategory;
  @Input() childDocCategory: Array<DcProductDocumentCategory>;
  @Input() parentIndex: number;
  baseUrl: string;
  collapsed: boolean = true;

  treeControl = new NestedTreeControl<DcProductDocumentCategory>(node => node.documents ?? node.childCategories);
  dataSource = new MatTreeNestedDataSource<DcProductDocumentCategory>();

  constructor(private onPremService: OnPremDcService,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService) { }

  hasChild(index: number, node: DcProductDocumentCategory): boolean {
    const child = node?.documents ?? node?.childCategories;
    return child?.length > 0;
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
    this.dataSource.data = this.childDocCategory;
  }

  openDocWindow(uniqueId: string) {
    this.loadingSpinnerService.show();
    this.getDocument(uniqueId).subscribe({
      next: (response) => {
        const blob = new Blob([response.body], { type: response.headers.get("content-type") });
        let objectURL = URL.createObjectURL(blob);
        window.open(objectURL, "_blank");
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private getDocument(docId: string) {
    //return this.onPremService.getFile('companies/' + companyId.toString() + '/documents/' + docId);
    return this.onPremService.getFile('documentlibrary/documents/' + docId);
  }
}