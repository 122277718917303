<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" >
  <div class="col-md-12">
    <H4>My Account</H4>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <dc-company-selector [lineOfBusiness]="7" (onCompanySelected)="initPage($event)"></dc-company-selector>
  </div>
</div>

<div class="row" style="padding-top:2rem" *ngIf="arrProgram&&(arrProgram.length<=0)">
  <div class="col-md-12">
    <h4 style="text-align:center">*** No Program Information Available ***</h4>
  </div>
</div>
<ng-container *ngIf="arrProgram&&(arrProgram.length>0)&&configObject">
  <div class="row" style="padding-top:2rem">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <H4>{{companyName}}</H4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 ml-2 font-weight-bold">
          <H6><i>{{fullHierarchy}}</i></H6>
        </div>
      </div>
      <div class="row">
        <div class="form-sm col-md-8 ml-2 mb-2">
          <input class="form-control" type="text" id="billingEmailAddress" [(ngModel)]="billingEmailAddress" disabled />
          <label for="billingEmailAddress" class="text-secondary">Billing Email</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <H5>Services</H5>
        </div>
      </div>
    </div>
    <div class="col-md-6" style="text-align:right">
      <span>
        To purchase additional programs, training lessons, or driver slots, please
        <a href="http://safety.fleetresponse.com" target="_blank" rel="noopener noreferrer">click here</a>
      </span>

    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-loading-panel *ngIf="!configObject"></app-loading-panel>
      <div *ngIf="configObject">
        <app-data-table
          [columns]="configObject.fields"
          [data]="configObject.data"
          [showPagination]="true"
          noRecordsLabel="No Programs/Services information"
        ></app-data-table>
      </div>

    </div>
  </div>
</ng-container>