import { Component, Input } from '@angular/core';
import { SettingsPageAssignmentInfo } from '../settings-page/settings-page.component'

@Component({
  selector: 'app-settings-assignment-panel',
  templateUrl: './settings-assignment-panel.component.html'
})
export class SettingsAssignmentPanelComponent {
    @Input() card: SettingsPageAssignmentInfo;
}
