//Angular
import { Component, Input, OnDestroy, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
//Third Party
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
//APP
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { CorporateStructureAddGroupModalService } from './corporate-structure-add-group-modal.service';
import { CorporateStructure } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-corporate-structure-add-group-modal',
  templateUrl: './corporate-structure-add-group-modal.component.html',
  styleUrls: ['./corporate-structure-add-group-modal.component.scss']
})
export class CorporateStructureAddGroupModalComponent implements OnDestroy {

  @Input() chType: string;
  groupAddedSubscription: Subscription;
  groupName: string;
  destroy$ = new Subject();

  constructor(
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private corporateStructureAddGroupService: CorporateStructureAddGroupModalService,
    private dialogRef: MatDialogRef<CorporateStructureAddGroupModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { item: CorporateStructure, clientSelectedArray: Array<string> },
    private snackBar: MatSnackBar
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onCloseHandled(): void {
    this.groupName = "";
    this.dialogRef.close();
  }

  onOkHandled(): void {
    if (!this.groupName || this.groupName.trim().length == 0) {
      this.errorService.setErrorObject({ message: "Group name is required." });
      return;
    }
    this.loadingSpinnerService.show();
    this.corporateStructureAddGroupService
      .addGroup(
        this.data.clientSelectedArray.join(","),
        this.data.item.elementGuid,
        this.groupName
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          //show success toast and close
          this.loadingSpinnerService.hide();
          this.snackBar.open('Group added.', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: 'success-snackbar'
          })
          this.onCloseHandled();
          this.corporateStructureAddGroupService.folderAdded();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      }
      );
  }
}
