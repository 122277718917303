//Angular
import { Component, Input, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
//Third Party
import { Subscription, Observable, forkJoin } from 'rxjs';
//APP
import { DocumentLibraryService } from './../../shared/document-library/document-library.service';
import { DashboardService } from '../dashboard-service/dashboard-service.component';
import { LineOfBusinessService } from '../line-of-business-service/line-of-business-service.component';
import { ClientSelectionService, ClientSelectionsObject } from '../client-selection-service/client-selection-service.component';
import { UserRightsService } from '../user-rights-service/user-rights-service.component';
import {
  UserRightsInfo,
  GetLOB,
  LineOfBusinessInfo,
  DriverClientSettings,
  ClientSelectionObject,
  AdminNavItem
} from "../classes-and-interfaces/classes-and-interfaces.component";
import { AuthService } from '../auth/auth.service';
import { ErrorModalService } from "../error-modal/error-modal-service.component";
import { ReportRequest } from '../data-grid/data-grid.service';
import { BusinessGroupNewsService } from '../business-group-news-popup/business-group-news-service.component';
import { OnPremDriverService } from '../on-prem-service/on-prem-driver-service.component';
import { NavmenuService } from './navmenu.service';
import { ClientHasDocumentLibrary } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { DhpDocumentService } from '../../services/dhp-document-service/dhp-document.service';

@Component({
  selector: 'nav-menu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.scss']
})
export class NavMenuComponent implements OnInit, OnDestroy {
  @Input() isIE: boolean;
  index: number = 0;
  baseUrl: string;
  pageName: string = "StatCenter";
  userFunctionLinkSelected: string = "";
  pageSelected: number;
  pageSelectedName: string = "";
  pageChoices: Array<PageChoice>;
  pageChoicesCanView: Array<PageChoice>;
  settingsPageInfo: any = { label: 'Settings', routerLink: ['/settings'], iconClass: 'fa fa-cog', canView: false };
  _userRights: Array<UserRightsInfo>;
  adminMenuItems: Array<AdminNavItem> = [];
  lineOfBusiness: number;
  showForAccidentClaims: boolean = false;
  showForSafety: boolean = false;
  showForHeavyEquipment: boolean = false;
  showForDeliveryContractor: boolean = false;
  showForCustom: boolean = false;
  clientSelectedArray: Array<string>;
  clientSettings: DriverClientSettings;
  goToLineOfBusinessSubscription: Subscription;
  clientSelectionSubscription: Subscription;
  clientLogoSubscription: Subscription;
  lineOfBusinessSubscription: Subscription;
  businessGroupNewsSubscription: Subscription;
  employeeCount: number;
  nonEmployeeCount: number;
  customReportsArray: Array<GetLOB>;
  customReportSubscription: Subscription;
  pendingApprovals: number = 0;
  pendingApprovalsReportId: number = 0;
  showClaimsNotifications: boolean = false;
  showSafetyNotifications: boolean = false;
  pendingCOIApprovals: number = 0;
  pendingCOIApprovalsReportId: number = 0;
  pendingDocumentValidationCount = 0;
  newsButtonLabel: string = "";
  newsButtonShow: boolean = false;
  logoSrcDefault: string = "../images/visibility_app_main.png";
  logoSrc: string;
  hasReactivateEmployeePermission: boolean = false;
  hasOptOutDriverPermission: boolean = false;
  hasBatchToolPermission: boolean = false;
  hasAddDriverPermission: boolean = false;
  isCertificateOfViolationOn: boolean = false;
  isTrainingAccount: boolean = false;
  isMvrAccount: boolean = false;
  isDriverQualificationOn: boolean = false;
  isCOIValidationOn: boolean = false;
  hasClientDocumentLibrary: boolean = false;
  hasMoveDriverPermission: boolean = false;
  isSideMenuOpen = false;
  constructor(private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private dashboardService: DashboardService,
    private lineOfBusinessService: LineOfBusinessService,
    private clientSelectionService: ClientSelectionService,
    private userRightsService: UserRightsService,
    private authService: AuthService,
    private errorService: ErrorModalService,
    private cdRef: ChangeDetectorRef,
    private gridRequest: ReportRequest,
    private businessGroupNewsService: BusinessGroupNewsService,
    private onPremService: OnPremDriverService,
    private navmenuService: NavmenuService,
    private documentLibraryService: DocumentLibraryService,
    private loadingSpinnerService: LoadingSpinnerService,
    private readonly dhpDocumentService: DhpDocumentService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit() {
    this.lineOfBusiness = this.lineOfBusinessService.getLineOfBusinessValue();
    if (this.lineOfBusiness > 0) {
      if (this.lineOfBusiness === 7) {
        // no client selector for DC, use first client from client choices
        this.updateDCClientSelected(this.lineOfBusiness);
      } else {
        this.clientSelectedArray = this.clientSelectionService.getClientSelectedArrayValue(this.lineOfBusiness);
      }

      if (this.clientSelectedArray.length > 0) {
        this.updateClientInfo(this.clientSelectedArray);
      }
    }
    this.lineOfBusinessService.getLineOfBusinessChoices()
      .subscribe(data => this.consumeLineOfBusinessChoices(data));

    // subscribe to changes in Line of Business
    this.lineOfBusinessSubscription = this.lineOfBusinessService.lineOfBusinessSelected$.subscribe(
      lineOfBusinessSelected => {
        let lob = lineOfBusinessSelected as number;
        if ((lob > 0) && (this.lineOfBusiness != lob)) {
          this.lineOfBusiness = lineOfBusinessSelected as number;
          this.showClaimsNotifications = (lineOfBusinessSelected == 1);
          this.showSafetyNotifications = (lineOfBusinessSelected == 2);
          if (this.lineOfBusiness == 7) {
            // no client selector for DC, use first client from client choices
            this.updateDCClientSelected(this.lineOfBusiness);
          }
          this.initShowForLOB();

        }

      }
    );

    // subscribe to client short name selected
    this.clientSelectionSubscription = this.clientSelectionService.clientSelectedInfo$.subscribe(
      clientSelectedInfo => {
        let temp = clientSelectedInfo as ClientSelectionsObject;
        if (temp[this.lineOfBusiness]) {
          let clientSelectedArrayNew = temp[this.lineOfBusiness].clientSelectedArray;

          this.updateClientInfo(clientSelectedArrayNew);

        }
      }
    );

    // subscribe to client logo
    this.clientLogoSubscription = this.clientSelectionService.clientLogoSrc$.subscribe(
      temp => {
        let logo = temp as string;
        this.logoSrc = (logo && (logo.length > 0) ? 'images/' + logo : this.logoSrcDefault)
      }

    );

    // subscribe to goToLineOfBusiness - navigate to new line of business
    this.goToLineOfBusinessSubscription = this.dashboardService.goToLineOfBusiness$.subscribe(
      lob => {
        let lineOfBusinessId = lob as number;

        if (lineOfBusinessId && (lineOfBusinessId > 0)) {
          if (lineOfBusinessId == 6) {
            this.router.navigate(['/custom', lineOfBusinessId.toString()]);
          }
          else {
            this.router.navigate(['/statcenter', lineOfBusinessId.toString()]);
          }
        }
      }
    );

    this.businessGroupNewsSubscription = this.businessGroupNewsService.businessGroupNewsItem$
      .subscribe(item => {
        if (item) {
          this.newsButtonLabel = item.dashboardButtonLabel;
          this.newsButtonShow = true;
        }
        else {
          this.newsButtonShow = false;
        }
      });
    this.getUserNotifications();
    this.getAdminNavItems();
  }

  toggleSideMenu(): void {
    this.isSideMenuOpen = !this.isSideMenuOpen;
    const linksFull = document.querySelectorAll<HTMLElement>('.navbar-links-full')[0];
    const mobile = document.querySelectorAll<HTMLElement>('.navbar-links-mobile')[0];
    if (this.isSideMenuOpen) {
      if (window.matchMedia('(min-width: 1360px)').matches) {
        linksFull.style.display = 'none';
        mobile.style.display = 'flex';
      }
    }
    else {
      linksFull.style.display = 'flex';
      mobile.style.display = 'none';
    }
  }

  private initShowForLOB() {
    if (this.lineOfBusiness > 0) {
      this.setPageSelectedInfo();
      switch (this.lineOfBusiness) {
        case 1:
          this.showForAccidentClaims = true;
          this.showForSafety = false;
          this.showForHeavyEquipment = false;
          this.showForDeliveryContractor = false;
          this.showForCustom = false;
          break;
        case 2:
          this.showForAccidentClaims = false;
          this.showForSafety = true;
          this.showForHeavyEquipment = false;
          this.showForDeliveryContractor = false;
          this.showForCustom = false;
          this.clientSelectedArray = this.clientSelectionService.getClientSelectedArrayValue(this.lineOfBusiness);
          if (this.clientSelectedArray.length > 0) {
            this.getUserRightsAndClientSettings();
          }
          break;
        case 4:
          this.showForAccidentClaims = false;
          this.showForSafety = false;
          this.showForHeavyEquipment = true;
          this.showForDeliveryContractor = false;
          this.showForCustom = false;
          break;
        case 6:
          this.showForAccidentClaims = false;
          this.showForSafety = false;
          this.showForHeavyEquipment = false;
          this.showForDeliveryContractor = false;
          this.showForCustom = true;
          break;
        case 7:
          this.showForAccidentClaims = false;
          this.showForSafety = false;
          this.showForHeavyEquipment = false;
          this.showForDeliveryContractor = true;
          this.showForCustom = false;
          break;
        default:
          this.showForAccidentClaims = false;
          this.showForSafety = false;
          this.showForHeavyEquipment = false;
          this.showForCustom = false;
          break;
      }

      this.cdRef.detectChanges();
    }
    //
  }

  private updateDCClientSelected(lineOfBusiness: number) {
    // no client selector for DC, use first client from client choices
    this.clientSelectionService.getClientChoices(lineOfBusiness).subscribe({
      next: (data) => {
        let choices = data as Array<ClientSelectionObject>;
        if (choices && (choices.length > 0)) {
          this.clientSelectedArray = [choices[0].clientShortName];
          this.clientSelectionService.setSavedClientShortNames(this.clientSelectedArray, lineOfBusiness);
        } else {
          this.errorService.setErrorObject({ message: 'No Client Data found for Delivery Contractor' });
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
      }
    });
  }

  private updateClientInfo(clientSelectedArrayNew: string[]) {
    if (!this.arrayCompare(clientSelectedArrayNew, this.clientSelectedArray)) {
      this.clientSelectedArray = clientSelectedArrayNew;
      this.getUserRightsAndClientSettings();
    }
  }

  ngOnDestroy() {
    this.lineOfBusinessSubscription.unsubscribe();
    this.clientLogoSubscription.unsubscribe();
    this.goToLineOfBusinessSubscription.unsubscribe();
    this.clientSelectionSubscription.unsubscribe();
    this.businessGroupNewsSubscription.unsubscribe();
  }

  private consumeLineOfBusinessChoices(data: LineOfBusinessInfo[]) {
    const tempin: Array<LineOfBusinessInfo> = data as Array<LineOfBusinessInfo>;
    const tempout: Array<PageChoice> = [];
    tempin.forEach((element) => {
      let url = '/statcenter';
      if (element.lineOfBusinessId === 6) {
        url = '/custom';
      }
      const tempobj: PageChoice = {
        id: element.lineOfBusinessId,
        label: element.lineOfBusinessName,
        routerLink: [url, element.lineOfBusinessId.toString()],
        canView: true,
        selected: false
      };
      tempout.push(tempobj);
    });
    tempout.push(this.settingsPageInfo);
    this.pageChoices = tempout;
    this.adjustLineOfBusinessChoices();
    this.pageChoicesCanView = this.getTopMenuPageChoices();
    this.initShowForLOB();
  }
  /*
  private getLineOfBusinessChoices() {
    this.http.get(this.baseUrl + 'api/UserFilter/UserLineOfBusinesses')
      .subscribe(data => {
        const tempin: Array<LineOfBusinessInfo> = data as Array<LineOfBusinessInfo>;
        const tempout: Array<any> = [];
        tempin.forEach((element) => {
          let url = '/statcenter';
          if (element.lineOfBusinessId == 6) {
            url = '/custom';
          }
          const tempobj = {
            id: element.lineOfBusinessId,
            label: element.lineOfBusinessName,
            routerLink: [url, element.lineOfBusinessId.toString()],
            canView: true,
            selected: false
          };
          tempout.push(tempobj);
        });
        this.lineOfBusinessService.setLineOfBusinessChoices(tempout);
        tempout.push(this.settingsPageInfo);
        this.pageChoices = tempout;
        this.adjustLineOfBusinessChoices();
        this.pageChoicesCanView = this.getTopMenuPageChoices();
        this.initShowForLOB();

      },
        (err: HttpErrorResponse) => {
          if (err.status != 403)
            this.errorService.setErrorObject(err.error);
          else {
            this.showForAccidentClaims = false;
          }
        })
  }
  */

  private setPageSelectedInfo() {
    if (this.pageChoices) {
      this.pageChoices.forEach(p => p.selected = (this.lineOfBusiness == p.id));
      let pageSelected = this.pageChoices.filter(p => p.id == this.lineOfBusiness);
      if (pageSelected && (pageSelected.length > 0)) {
        this.pageSelected = pageSelected[0].id;
        this.pageSelectedName = pageSelected[0].label;
      }
    }
  }

  private adjustLineOfBusinessChoices() {
    if (this.pageChoices) {
      let settingsObj = this.pageChoices.filter(p => p.label == 'Settings');
      if (settingsObj && (settingsObj.length > 0)) {
        settingsObj[0].canView = this.canViewSettingsPage();
      }
    }
  }

  private getTopMenuPageChoices() {
    if (this.pageChoices) {
      return this.pageChoices.filter(p => p.canView);
    } else {
      return [];
    }
  }

  private getUserRightsAndClientSettings() {
    // 2019-12 combines lookups for top nav choices and safety links into one observable
    this.loadingSpinnerService.show();
    if (this.clientSelectedArray && (this.clientSelectedArray.length > 0)) {
      let arrObs: Array<Observable<Object>> = [
        this.userRightsService.getUserRights(5, this.clientSelectedArray)
      ];
      if (this.lineOfBusiness == 2) {
        arrObs.push(this.userRightsService.getUserRights(2, this.clientSelectedArray));
      }

      forkJoin(arrObs).subscribe({
        next: (data) => {
          this._userRights = data[0] as Array<UserRightsInfo>;
          this.adjustLineOfBusinessChoices();
          if (this.lineOfBusiness == 2) {
            let userRightsForLOB = data[1] as Array<UserRightsInfo>;
            this.applyUserRightsForSafetyLinks(userRightsForLOB);
            let cSettings = this.clientSelectionService.getClientSettingsObjectValue();
            if (cSettings && cSettings[this.clientSelectedArray[0]]) {
              this.clientSettings = cSettings[this.clientSelectedArray[0]];
              this.applyClientSettings(this.clientSettings, userRightsForLOB);
            } else {
              this.clientSettings = null;
            }

          } else {
            this.clientSettings = null;
          }
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      }
      );
    }

  }

  private applyUserRightsForSafetyLinks(userRights: Array<UserRightsInfo>) {
    let reactivatePermission = userRights.find(x => x.userRightId == 36);
    let batchToolPermission = userRights.find(x => x.userRightId == 51)
    let addDriverPermission = userRights.find(x => x.userRightId == 53)
    let moveDriverPermission = userRights.find(x => x.userRightId == 2066);
    let optOutDriverPermission = userRights.find(x => x.userRightId == 3064);
    this.hasReactivateEmployeePermission = (reactivatePermission && reactivatePermission.permission.toLowerCase() === "allow");
    this.hasOptOutDriverPermission = (optOutDriverPermission && optOutDriverPermission.permission.toLowerCase() === "allow");

    this.hasBatchToolPermission = (batchToolPermission && batchToolPermission.permission.toLowerCase() === "allow");
    this.hasAddDriverPermission = (addDriverPermission && addDriverPermission.permission.toLowerCase() === "allow");
    this.hasMoveDriverPermission = (moveDriverPermission && moveDriverPermission.permission.toLowerCase() === "allow");
    this.cdRef.detectChanges();
  }

  private applyClientSettings(data: DriverClientSettings, userRights: Array<UserRightsInfo>) {
    this.isCertificateOfViolationOn = data.isCertificateOfViolationOn as boolean;
    this.isMvrAccount = data.mvrAccount as boolean;
    this.isTrainingAccount = data.trainingAccount as boolean;
    this.isDriverQualificationOn = data.isDriverQualificationOn as boolean;
    this.getHasDocumentLibrary();
    let coiPermission = (userRights.find(x => x.userRightId == 2063));
    this.isCOIValidationOn = (data.isAutoCoverageOn && coiPermission && (coiPermission.permission.toLowerCase() === "allow"));
  }

  getHasDocumentLibrary(): void {
    this.documentLibraryService.clientHasDocumentsCheck(this.clientSelectedArray[0], this.lineOfBusiness.toString()).subscribe(data => {
      data = data as ClientHasDocumentLibrary;
      this.hasClientDocumentLibrary = data.clientHasDocument === 1 ? true : false;
    })
  }

  getUserNotifications() {
    this.userRightsService.getUserNotifications().subscribe(data => {

      this.showClaimsNotifications = (this.lineOfBusiness == 1);
      this.showSafetyNotifications = (this.lineOfBusiness == 2);

      if (Array.isArray(data)) {
        (data as Array<any>).forEach(item => {
          if (item.notificationType == "Pending Approvals") {
            this.pendingApprovals = item.units;
            this.pendingApprovalsReportId = item.dataGridId;
          }

          if (item.notificationType == "Pending Certificate of Insurance Approvals") {
            this.pendingCOIApprovals = item.units;
            this.pendingCOIApprovalsReportId = item.dataGridId;
          }
        });
      }
      else {
        this.showClaimsNotifications = false;
        this.showSafetyNotifications = false;
      }
    });
  }

  getAdminNavItems(): void {
    this.navmenuService.getAdminNavItems().subscribe(data => {
      this.adminMenuItems = (data != null) ? data as Array<AdminNavItem> : [];
      this.adminMenuItems.forEach(item => {
        var matches = item.label.match(/\{\{([^}]+)\}\}/ig);

        if (matches && matches.length == 1) {
          item.labeltemplate = item.label;
          item.labeltoken = matches[0];
          item.label = item.labeltemplate.replace(item.labeltoken, "0");
        }
      });

      this.updateAdminMenu();
    });
  }

  selectPage(id: number, n: string, routerLink: Array<string>) {
    this.pageSelected = id;
    this.pageSelectedName = n;
    this.userFunctionLinkSelected = "";
    if (id) {
      // navigate to dashboard page
      this.dashboardService.setGoToLineOfBusiness(id);
      this.employeeCount = null;
      this.nonEmployeeCount = null;
    } else {
      //settings page
      this.router.navigate(routerLink);
    }
  }

  generateSpreadsheet(isEmployee: string) {
    let url: string = this.baseUrl + "/api/Safety/DriverExportCSV?Client=" + this.clientSelectedArray.join(",") + "&IsEmployee=" + isEmployee;
    var strWindowFeatures = "location=no,height=0,width=0,scrollbars=no,status=no";
    window.open(url, "_self", strWindowFeatures);
  }

  getReportBuilderReports() {

  }

  selectUserFunctionLink(n: string) {
    // when functionalities are developed for admin links,
    // then we will need to clear this.gridSelected

    alert(n);
  }

  canViewAdminOptions() {
    let canView: boolean = false;

    if (this._userRights) {
      let thisRight = this._userRights.filter(r => r.userRightId == 63);
      if ((thisRight.length > 0) && (thisRight[0].permission == 'allow')) {
        canView = true;
      }
    }

    return canView;
  }

  logout(): void {
    this.authService.logOut();
  }

  openPendingRequests(reportId): void {
    this.clientSelectedArray = this.clientSelectionService.getClientSelectedArrayValue(this.lineOfBusiness);
    this.gridRequest.reset();
    this.gridRequest.DataGridId = reportId;
    this.gridRequest.SelectedClientIds = this.clientSelectedArray;
    this.gridRequest.ParameterValues = [];
    this.gridRequest.StartDate = null;
    this.gridRequest.EndDate = null;
    this.gridRequest.clickTrough = false;

    let randomVal = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
    sessionStorage.setItem('grid_' + randomVal, JSON.stringify(this.gridRequest));
    this.loadingSpinnerService.show();
    this.router.navigate(['/datagrid/' + randomVal]).catch(e => this.loadingSpinnerService.hide());
  }

  openPendingCOIRequests(reportId): void {
    this.clientSelectedArray = this.clientSelectionService.getClientSelectedArrayValue(this.lineOfBusiness);
    this.gridRequest.reset();
    this.gridRequest.DataGridId = reportId;
    this.gridRequest.SelectedClientIds = this.clientSelectedArray;
    this.gridRequest.ParameterValues = [];
    this.gridRequest.StartDate = null;
    this.gridRequest.EndDate = null;
    this.gridRequest.clickTrough = false;

    let randomVal = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
    sessionStorage.setItem('grid_' + randomVal, JSON.stringify(this.gridRequest));
    this.loadingSpinnerService.show();
    this.router.navigate(['/safety/coivalidationgrid/' + randomVal]).catch(e => this.loadingSpinnerService.hide());
  }

  showBusinessGroupNews() {
    this.businessGroupNewsService.getNewsItem(this.lineOfBusiness, true);
  }

  private canViewSettingsPage() {
    let canView: boolean = false;

    if (this._userRights) {
      let thisRight = this._userRights.filter(r => r.userRightId == 19);
      if ((thisRight.length > 0) && (thisRight[0].permission == 'allow')) {
        canView = true;
      }
    }

    return canView;
  }

  private arrayCompare(a1, a2) {
    return (a1 && a2 && (a1.length == a2.length) && a1.every((v, i) => v === a2[i]));
  }

  getClientSettings(clientSelected: string) {
    return this.onPremService.get('lookup/clientSettings/' + clientSelected);
  }

  itemWasToggled(event: any) {
    //console.log('collapsed:', event);

  }

  updateAdminMenu() {
    //console.log('updateAdminMenu:', this.adminMenuItems);
    var toupdate = this.adminMenuItems.filter(item => item.labeltoken != null);
    if (toupdate && toupdate.length > 0) {
      toupdate.forEach(item => {
        //console.log("toupdate", item);
        switch (item.labeltoken) {
          case "{{abstractreviewcount}}":
            this.onPremService.get("abstractreview").subscribe({
              next: (data: Array<any>) => {
                item.label = item.labeltemplate.replace(item.labeltoken, data.length.toString());
              },
              error: (err: HttpErrorResponse) => {
                item.label = item.labeltemplate.replace(item.labeltoken, "0");
              }
            }
            );

            break;
          case "{{dhpdocumentvalidationcount}}":
            this.dhpDocumentService.GetPendingDocuments().subscribe({
              next: (data: Array<any>) => {
                this.pendingDocumentValidationCount = data.length;
                item.label = item.labeltemplate.replace(item.labeltoken, this.pendingDocumentValidationCount.toString());
              },
              error: (err: HttpErrorResponse) => {
                item.label = item.labeltemplate.replace(item.labeltoken, "0");
              }
            }
            );

            break;
        }
      });
    }
  }
}

interface PageChoice {
  id: number;
  label: string;
  routerLink: [string, string];
  canView: boolean;
  selected: boolean;
}
