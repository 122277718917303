
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class SafetyDriverRiskService {
  baseUrl: string;
  errorMessage: string;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  getRiskByDriverId(DriverId: number): Observable<DriverRisk> {
    let params = new HttpParams();
    params = params.append('DriverId', DriverId.toString());
    return this.http.get<DriverRisk>(this.baseUrl + 'api/Safety/GetDriverRisk', {
      params: params
    }).pipe(
      catchError(this.handleError)
    );
  }

  /* HANDLES ENCOUNTERED ERRORS (I'LL TAKE THIS OUT AFTER TESTING) */
  private handleError(err: HttpErrorResponse) {
    return observableThrowError(err.error.message);
  }
}

export class DriverRisk {
  overAll: number;
  claims: number;
  mvr: number;
  additional: number;
  telematics: number;
}
