<div class="row">
  <div class="col-md-12">
    <h3 class="text-muted">Advanced Search</h3>

    <div class="card card-body mb-3">
      <h4 class="card-title text-muted">Select Criteria</h4>

      <div class="row mb-3">
        <div class="col-md-12">
          <div class="form-check">
            <input type="radio" class="form-check-input" name="dateField" id="dateOfLoss" [value]="'Loss'" [(ngModel)]="searchRequest.DateField" />
            <label class="form-check-label font-weight-normal" for="dateOfLoss">Date of Loss</label>
          </div>
          <div class="form-check">
            <input type="radio" class="form-check-input" name="dateField" id="dateReported" [value]="'Reported'" [(ngModel)]="searchRequest.DateField" />
            <label class="form-check-label font-weight-normal" for="dateReported">Date Reported</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12 d-flex flex-column">
          <span class="font-weight-bold grey-label">Start Date</span>
          <mat-form-field appearance="fill">
            <mat-label class="lbl-start-date">Start Date</mat-label>
            <input class="input-date" matInput disabled [matDatepicker]="startDatePicker" [(ngModel)]="searchRequest.StartDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker disabled="false">
              <mat-datepicker-actions>
                <button mat-button (click)="startDatePicker.select(actualDate); startDatePicker.close()">
                  <mat-icon>history</mat-icon>
                  Today
                </button>
                <button mat-button (click)="startDatePicker.select(null); startDatePicker.close()">
                  <mat-icon>highlight_off</mat-icon>
                  Clear
                </button>
              </mat-datepicker-actions> 
            </mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-12 d-flex flex-column">
          <span class="font-weight-bold grey-label">End Date</span>
          <mat-form-field appearance="fill">
            <mat-label>End Date</mat-label>
            <input class="input-date" matInput disabled [matDatepicker]="endDatePicker" [(ngModel)]="searchRequest.EndDate">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker disabled="false">
              <mat-datepicker-actions>
                <button mat-button (click)="endDatePicker.select(actualDate); endDatePicker.close()">
                  <mat-icon>history</mat-icon>
                  Today
                </button>
                <button mat-button (click)="endDatePicker.select(null); endDatePicker.close()">
                  <mat-icon>highlight_off</mat-icon>
                  Clear
                </button>
              </mat-datepicker-actions> 
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="card card-body mb-3">
      <h4 class="card-title text-muted">Additional Filters</h4>

      <div class="row">
        <div class="col-md-6">
          <div class="md-form">
            <input id="driverFirstName" type="text" class="form-control" placeholder="Driver First Name" [(ngModel)]="searchRequest.DriverFirstName" />
            <label for="driverFirstName" class="active" >Driver First Name</label>
          </div>
        </div>

        <div class="col-md-6">
          <div class="md-form">
            <input id="driverLastName" type="text" class="form-control" placeholder="Driver Last Name" [(ngModel)]="searchRequest.DriverLastName" />
            <label for="driverLastName" class="active" >Driver Last Name</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="md-form">
            <input id="vehicleNumber" type="text" class="form-control" placeholder="Vehicle No." [(ngModel)]="searchRequest.VehicleUnitNumber" />
            <label for="vehicleNumber" class="active" >Vehicle No.</label>
          </div>
        </div>

        <div class="col-md-6">
          <div class="md-form">
            <input id="vehicleVin" type="text" class="form-control" placeholder="VIN" [(ngModel)]="searchRequest.Vin" />
            <label for="vehicleVin" class="active" >VIN</label>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-body mb-3">
      <h4 class="card-title text-muted">Client Custom Field</h4>

      <div class="row">
        <div class="col-md-12">
          <table class="table table-striped table-bordered">
            <thead style="display:none;">
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let clientReqTitle of clientReqTitles">
                <td>{{clientReqTitle.clientName}}</td>
                <td>{{clientReqTitle.req1}}</td>
                <td>{{clientReqTitle.req2}}</td>
                <td>{{clientReqTitle.req3}}</td>
                <td>{{clientReqTitle.req4}}</td>
                <td>{{clientReqTitle.req5}}</td>
                <td>{{clientReqTitle.req6}}</td>
                <td>{{clientReqTitle.req7}}</td>
                <td>{{clientReqTitle.req8}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="align-middle">Search Term:</td>
                <td><input type="text" class="form-control my-0 pt-0 pb-1" name="req1" [(ngModel)]="searchRequest.Req1" /></td>
                <td><input type="text" class="form-control my-0 pt-0 pb-1" name="req2" [(ngModel)]="searchRequest.Req2" /></td>
                <td><input type="text" class="form-control my-0 pt-0 pb-1" name="req3" [(ngModel)]="searchRequest.Req3" /></td>
                <td><input type="text" class="form-control my-0 pt-0 pb-1" name="req4" [(ngModel)]="searchRequest.Req4" /></td>
                <td><input type="text" class="form-control my-0 pt-0 pb-1" name="req5" [(ngModel)]="searchRequest.Req5" /></td>
                <td><input type="text" class="form-control my-0 pt-0 pb-1" name="req6" [(ngModel)]="searchRequest.Req6" /></td>
                <td><input type="text" class="form-control my-0 pt-0 pb-1" name="req7" [(ngModel)]="searchRequest.Req7" /></td>
                <td><input type="text" class="form-control my-0 pt-0 pb-1" name="req8" [(ngModel)]="searchRequest.Req8" /></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

    </div>

    <div class="card card-body mb-3">
      <h4 class="card-title text-muted">Other Driver Information</h4>
      <div class="row">
        <div class="col-md-6">
          <div class="md-form">
            <input id="otherDriverFirstName" type="text" class="form-control" placeholder="Driver First Name" [(ngModel)]="searchRequest.OvOwnerFirstName" />
            <label for="otherDriverFirstName" class="active" >Driver First Name</label>
          </div>
        </div>

        <div class="col-md-6">
          <div class="md-form">
            <input id="otherDriverLastName" type="text" class="form-control" placeholder="Driver Last Name" [(ngModel)]="searchRequest.OvOwnerLastName" />
            <label for="otherDriverLastName" class="active" >Driver Last Name</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="md-form">
            <input id="city" type="text" class="form-control" placeholder="City" [(ngModel)]="searchRequest.OvOwnerCity" />
            <label for="city" class="active" >City</label>
          </div>
        </div>

        <div class="col-md-6">
          <mat-form-field appearance="fill" class="select-container">
            <mat-label>Select a State or Province</mat-label>
            <mat-select [(value)]="searchRequest.OvOwnerStateCode">
              <input matInput class="select-search-input" placeholder="Search States/Provinces..." (keyup)="filterOptions($event.target.value)">
              <mat-option *ngFor="let option of filteredStateProvinceOptions" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          </div>

      <div class="row">
        <div class="col-md-6">
          <div class="md-form">
            <input id="insuranceCompany" type="text" class="form-control" placeholder="Insurance Company" [(ngModel)]="searchRequest.OvOwnerInsuranceCompany" />
            <label for="insuranceCompany" class="active" >Insurance Company</label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <button class="btn btn-default" (click)="search()">Search</button>
      </div>
    </div>
  </div>
</div>
