//Angular
import { Component, OnInit, OnDestroy, Inject, ViewChildren, ElementRef, QueryList, ViewChild, AfterViewInit, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
//Third Party
import { DragulaService } from "ng2-dragula";
import { Subscription, Observable, forkJoin } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
//App
import { DashboardService } from '../../components/dashboard-service/dashboard-service.component';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { CustomReportService } from '../dashboard-custom-report-admin/dashboard-custom-report-service.component';
import { CustomReportField, CustomReport, ClientReqFields, GetLOB, CustomReportHeader } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

/**
 * this is the client-side controller for the dashboard custom report admin template.
 */
@Component({
  selector: "app-dashboard-custom-report-admin",
  templateUrl: "./dashboard-custom-report-admin.component.html",
  styleUrls: ["./dashboard-custom-report-admin.component.scss"],
  providers: [ClientSelectionService],
})
export class DashboardCustomReportAdminComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  // private variables that are only shared with subscribers that import the type
  @ViewChildren("selectReports") selectReports: QueryList<MatSelect>;
  selectReportRef: MatSelect = null;
  customReports: Array<CustomReport> = [];
  customReportsFiltered: Array<CustomReport> = [];
  lob: Array<GetLOB> = [];
  clientReqFields: Array<ClientReqFields> = [];
  showClientReqFields: ShowClientReqFields = new ShowClientReqFields();
  sourceFields: Array<CustomReportFieldSelect> = [];
  targetFields: Array<CustomReportFieldSelect> = [];
  customReportHeader: CustomReportHeader;
  //clientSelectionSubscription: Subscription;
  // lineOfBusinessSubscription: Subscription;
  clientSelectedArray: Array<string>;
  clientIdSelectedArray: Array<number>;
  lineOfBusinessId: number;
  selectedReportName: string;
  selectedReportId: number;
  selectedReportIdString: string = "";
  selectedColumnIds: string;
  baseUrl: string;
  reportSelection: string = null;
  reportName: ElementRef;
  @ViewChild("reportName") set content(content: ElementRef) {
    this.reportName = content;
    if (this.reportName) {
      this.reportName.nativeElement.focus();
    }
  }
  // for dragula multi select code
  challdata: boolean = false;
  challtarget: boolean = false;
  hasMultiple: boolean = false;
  selectedItems: JQuery<HTMLElement>;
  mirrorContainer: JQuery<HTMLElement>;
  dragulaSubs = new Subscription();

  // the overloaded constructor for the controller
  constructor(
    private router: Router,
    private clientSelectionService: ClientSelectionService,
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private snackBar: MatSnackBar,
    private loadingSpinnerService: LoadingSpinnerService,
    private customReportService: CustomReportService,
    private errorService: ErrorModalService,
    private dragula: DragulaService,
    private lineOfBusinessService: LineOfBusinessService
  ) {
    this.dragula.createGroup("bag-items", {
      moves: function (el: any, container: any, handle: any): any {
        if (el.classList.contains("customReportField")) {
          return true;
        } else {
          return false;
        }
      },
      invalid: function (el: any, handle: any): any {
        // for multiple selections, allow drag only from a checked item
        let selectedItems = $(".ex-over");
        let hasMultiple = selectedItems && selectedItems.length >= 1;
        if (hasMultiple) {
          let isValid = false;
          if (handle.control) {
            // look at checkbox linked to label
            isValid = handle.control.checked;
          } else {
            //look for checkbox within container DIV
            isValid = handle.firstElementChild.checked;
          }
          return !isValid;
        } else {
          return false;
        }
      },
    });

    this.dragulaSubs.add(
      this.dragula
        .cloned("bag-items")
        .subscribe(({ clone, original, cloneType }) => {
          this.mirrorContainer = $(".gu-mirror").first();
          this.mirrorContainer.removeClass("ex-over");
          this.selectedItems = $(".ex-over");
          this.hasMultiple =
            this.selectedItems.length > 1 ||
            (this.selectedItems.length == 1 &&
              !$(original).hasClass("ex-over"));
          if (this.hasMultiple) {
            $(".gu-transit").addClass("ex-over");
            this.selectedItems = $(".ex-over");
            this.mirrorContainer.empty();
            var height = 0,
              width = 0;
            let temp = this.mirrorContainer;
            this.selectedItems.each(function (index) {
              var item = $(this);
              var mirror = item.clone(true);
              mirror.removeClass("ex-over gu-transit");
              temp.append(mirror);
              temp.css("background-color", "transparent");
              item.addClass("gu-transit");
              var rect = item[0].getBoundingClientRect();
              height += rect.height;
              width = rect.width;
            });
            this.mirrorContainer = temp;
            this.mirrorContainer.css("height", height + "px");
          }
        })
    );

    this.dragulaSubs.add(
      this.dragula
        .drop("bag-items")
        .subscribe(({ name, el, target, source, sibling }) => {
          //console.log(source.attributes[2].value);
          //console.log(target.attributes[3].value);
          //console.log(target.attributes[2].value);
          if (
            source.classList.contains("sourceContainer") &&
            target.classList.contains("targetContainer")
          ) {
            if (!this.hasMultiple) {
              let target = document.getElementsByClassName("targetField");
              for (let i = target.length - 1; i >= 0; i--) {
                if (target[i].className.includes("ex-over") && i >= 0) {
                  this.removeClass(target[i], "ex-over");
                }
              }
            } else {
              el.remove();
              this.moveto();
            }
            for (let i = 0; i < this.targetFields.length; i++) {
              this.targetFields[i].state = false;
            }
            this.challtarget = false;
          } else if (
            source.classList.contains("targetContainer") &&
            target.classList.contains("sourceContainer")
          ) {
            if (!this.hasMultiple) {
              let target = document.getElementsByClassName("sourceField");
              for (let i = target.length - 1; i >= 0; i--) {
                if (target[i].className.includes("ex-over") && i >= 0) {
                  this.removeClass(target[i], "ex-over");
                }
              }
            } else {
              el.remove();
              this.moveback();
            }
            for (let i = 0; i < this.sourceFields.length; i++) {
              this.sourceFields[i].state = false;
            }
            this.challdata = false;
          } else {
            // if dropping back on same container, execute cancel code
            // remove transit class if cancel drop
            let sourceChildren = source.children;
            for (let i = sourceChildren.length - 1; i >= 0; i--) {
              if (
                sourceChildren[i].className.includes("gu-transit") &&
                i >= 0
              ) {
                this.removeClass(sourceChildren[i], "gu-transit");
              }
            }
          }
        })
    );

    /*
    this.dragula.over("bag-items").subscribe(({ el, source }) => {
      // remove transit class if hovering over originating container
      // assumes that unique container class name will be second in class list
      let target = document.getElementsByClassName(source.classList.item(1));
      for (let i = target.length - 1; i >= 0; i--) {
        if (target[i].className.includes('gu-transit') && i >= 0) {
          this.removeClass(target[i], 'gu-transit');
        }
      }
    });
    */

    this.dragulaSubs.add(
      this.dragula
        .cancel("bag-items")
        .subscribe(({ el, container, source }) => {
          // remove transit class if cancel drop
          let sourceChildren = source.children;
          for (let i = sourceChildren.length - 1; i >= 0; i--) {
            if (sourceChildren[i].className.includes("gu-transit") && i >= 0) {
              this.removeClass(sourceChildren[i], "gu-transit");
            }
          }
        })
    );
  }

  // angular on intialization event
  ngOnInit() {
    this.lineOfBusinessId = 1;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 1) {
      this.lineOfBusinessService.setLineOfBusiness(1);
    }

    // get the default values
    this.clientSelectedArray =
      this.clientSelectionService.getSavedClientShortNames(
        this.lineOfBusinessId
      );
    this.customReports = [];
    let arrObs: Array<Observable<Object>> = [
      this.customReportService.getByUserLOB(this.lineOfBusinessId),
      this.customReportService.getClientReqByUserLob(
        this.lineOfBusinessId,
        this.clientSelectedArray.join(",")
      ),
    ];
    this.loadingSpinnerService.show();
    forkJoin(arrObs).subscribe({
      next: (data) => {
        let reports = data[0] as Array<GetLOB>;
        let clientReqFields = data[1] as Array<ClientReqFields>;
        this.loadingSpinnerService.hide();
        this.processDefaultReports(reports);
        this.processClientReqByUserLob(clientReqFields);
        // Get the default 'Create New Report' fields if there are not reports left
        if (this.customReports.length <= 0) {
          this.createNewReport();
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

    // add dragula drag & drop support
    /*
    this.dragula
      .drop
      .subscribe(value => {
        console.log(value);
        this.droppedOnList(value[1]);
        setTimeout(() => {
        }, 1000);
      });
     */

    // detects the window resize event and manipulates label repositioning
    window.addEventListener(
      "resize",
      function () {
        var elAvailableLabel: any = document.getElementById(
          "lblAvailableFields"
        ) as HTMLLabelElement;
        var elTargetLabel: any = document.getElementById(
          "lblTargetFields"
        ) as HTMLLabelElement;
        if (elAvailableLabel != null && elTargetLabel != null) {
          if (window.innerWidth < 992) {
            elAvailableLabel.style.marginTop = "-40px";
            elTargetLabel.style.marginTop = "-40px";
          } else {
            elAvailableLabel.style.marginTop = "0px";
            elTargetLabel.style.marginTop = "0px";
          }
        }
      },
      true
    );
  }

  ngAfterViewChecked(): void {
    if (!this.selectReportRef && this.selectReports?.first) {
      this.selectReportRef = this.selectReports.first;
      this.selectReportRef._elementRef.nativeElement.addEventListener(
        "keydown",
        (event) => {
          if (event.code === "Space") {
            event.preventDefault();
          }
        }
      );
    }
  }

  // retrieves the initial reports for the dropdown using the selected LOB
  private getDefaultReports() {
    // Add the default report (Makes sure it starts at zero)
    this.customReports = [];
    //this.customReports.splice(0, 0, { value: 0, label: 'Create a New Report' });

    // get the saved reports
    this.loadingSpinnerService.show();
    this.customReportService.getByUserLOB(this.lineOfBusinessId).subscribe({
      next: (data) => {
        this.loadingSpinnerService.hide();
        this.processDefaultReports(data as Array<GetLOB>);
        // getDefaultReports will be called after report save or report delete.
        // navigate either to choose report or create new report
        if (this.customReports.length <= 0) {
          this.createNewReport();
        } else {
          this.clearReportDetails();
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private processDefaultReports(data: Array<GetLOB>) {
    this.lob = data;

    this.lob.forEach((a) => {
      if (a.reportId > 0) {
        this.customReports.push({
          value: a.reportId.toString(),
          label: a.reportName,
        });
      }
    });
    this.customReportsFiltered = this.customReports;
    this.customReports = this.customReports.slice();
    this.customReportService.customReportsList.next(this.lob);
  }

  // retrieves the chosen client(s) REQs using the selected LOB and the ClientId(s)
  private clientReqByUserLob() {
    let commaDelimitedShortCorpNames = this.clientSelectedArray.join(",");
    this.loadingSpinnerService.show();
    this.customReportService
      .getClientReqByUserLob(
        this.lineOfBusinessId,
        commaDelimitedShortCorpNames
      )
      .subscribe({
        next: (data) => {
          this.processClientReqByUserLob(data as Array<ClientReqFields>);
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
  }

  private processClientReqByUserLob(data: Array<ClientReqFields>) {
    this.clientReqFields = data;
    this.calcShowClientReqFields();
  }

  private calcShowClientReqFields() {
    // sets showClientReqFields, which hides any req fields that are blank
    let hideAll = true;

    for (let i = 1; i <= 8; i++) {
      let whichReq = "req" + i.toString();
      let arrReq = this.clientReqFields.map((r) => r[whichReq]);
      // show only if req for all clients is not blank or empty
      let showCol = !arrReq.every((x) => x.length <= 0);

      this.showClientReqFields[whichReq] = showCol;
      if (showCol) {
        hideAll = false;
      }
    }

    this.showClientReqFields.hideAll = hideAll;
  }

  // retrieves the default fields for the 'Create New Report' selection (0 for reportId returns all fields)
  private getFields(reportId: number) {
    this.loadingSpinnerService.show();
    this.customReportService
      .getColumnsByUserLOB(this.lineOfBusinessId, reportId)
      .subscribe({
        next: (data) => {
          var tempArray = data as Array<CustomReportFieldSelect>;

          // Set the source and target field arrays
          this.sourceFields = tempArray.filter((a) => a.displayOrder == -1);
          this.targetFields = tempArray.filter((a) => a.displayOrder > -1);
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          //this.handleError(err.error.toString());
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
  }

  // clear report details
  clearReportDetails() {
    this.initializeValues();
  }

  // create a brand new report
  createNewReport() {
    this.initializeValues();
    this.selectedReportId = 0;
    this.selectedReportIdString = "";
    this.getFields(0);
  }

  // handles the event where an item was dropped on a list
  /* 2019-09 - no longer needed, using dragulaModel to track source & target selections
  private droppedOnList(e) {
    //console.log(e);
    var el: HTMLTableElement = (document.getElementById('targetTable')) as HTMLTableElement;

    if (el.rows.length == 0) {
      // User has no fields in the target
      this.dirty = false;
      //add background to target table
      el.classList.add("selectedFieldsTableEmpty");
    }
    else {
      //remove background from target table
      el.classList.remove("selectedFieldsTableEmpty");
      if (this.selectedReportId == 0) {
        // User selected 'Create New Report'
        this.dirty = true;
      }
      else if (this.selectedReportId > 0) {
        //this.saveReportDetails(true);
        this.dirty = true;
      }
    }
  }
  */

  // saves a custom report for specified client ids
  private saveReport(event: KeyboardEvent) {
    if (!event || event.key === "Enter") {
      if (this.reportSelection == null || this.reportSelection == "") {
        this.snackBar.open("Please give your report a name.", "Ok", {
          horizontalPosition: "end",
          verticalPosition: "top",
          duration: 5000,
          panelClass: "error-snackbar",
        });
      }
      if (this.targetFields.length <= 0) {
        this.snackBar.open("Please select at least one field.", "Ok", {
          horizontalPosition: "end",
          verticalPosition: "top",
          duration: 5000,
          panelClass: "error-snackbar",
        });
      } else {
        // save the report header and details
        this.saveReportHeader();
      }
    }
  }

  // deletes a custom report for specified client ids
  private deleteReport() {
    let thisReportName = this.reportSelection;
    this.loadingSpinnerService.show();
    this.customReportService
      .upsertReportHeader({
        reportId: +this.selectedReportId,
        reportName: this.reportSelection,
        lineOfBusinessId: this.lineOfBusinessId,
        isActive: false,
      })
      .subscribe({
        next: (data) => {
          this.selectedReportId = data as number;
          this.selectedReportIdString = data.toString();
          this.loadingSpinnerService.hide();
          // refresh the reports and save the current report details
          this.getDefaultReports();
          this.snackBar.open(`${thisReportName} successfully removed!`, "Ok", {
            horizontalPosition: "end",
            verticalPosition: "top",
            duration: 5000,
            panelClass: "success-snackbar",
          });
        },
        error: (err: HttpErrorResponse) => {
          //this.handleError(err.toString());
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
  }

  // saves the report header
  private saveReportHeader() {
    this.loadingSpinnerService.show();
    this.customReportService
      .upsertReportHeader({
        reportId: this.selectedReportId,
        reportName: this.reportSelection,
        lineOfBusinessId: this.lineOfBusinessId,
        isActive: true,
      })
      .subscribe({
        next: (data) => {
          this.selectedReportId = data as number;
          this.selectedReportIdString = data.toString();

          // refresh the reports and save the current report details
          //this.getDefaultReports();
          this.saveReportDetails(false);
        },
        error: (err: HttpErrorResponse) => {
          //this.handleError(err.toString());
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
  }

  // saves the report details
  private saveReportDetails(targetDetailsChanged: boolean) {
    // make sure there's at least one target field specified
    if (this.targetFields.length > 0) {
      let thisReportName = this.reportSelection;
      this.customReportService
        .upsertReportColumns({
          reportId: this.selectedReportId,
          sourceColumnIds: this.targetFields
            .map((f) => f.sourceColumnId.toString())
            .join(","),
        })
        .subscribe({
          next: (data) => {
            var retVal = data as any;
            if (!targetDetailsChanged) {
              this.snackBar.open(
                `${thisReportName} successfully saved!`,
                "Ok",
                {
                  horizontalPosition: "end",
                  verticalPosition: "top",
                  duration: 5000,
                  panelClass: "success-snackbar",
                }
              );
              this.loadingSpinnerService.hide();

              this.getDefaultReports();
            }
          },
          error: (err: HttpErrorResponse) => {
            //this.handleError(err.toString());
            this.errorService.setErrorObject(err.error);
            this.loadingSpinnerService.hide();
          }
        });
    } else {
      this.snackBar.open("successfully saved!", "Ok", {
        horizontalPosition: "end",
        verticalPosition: "top",
        duration: 5000,
        panelClass: "success-snackbar",
      });
    }
  }

  // detects a change in the report id dropdown
  onChange(event: any) {
    this.initializeValues();
    this.sleep(100);

    // set the base properties for the selected report and get the applicable data
    if (event != undefined) {
      this.selectedReportId = +event.value;
      this.selectedReportIdString = event.value;

      if (this.selectedReportId == 0) {
        this.reportSelection = null;
      } else {
        this.reportSelection = event.label;
      }

      this.getFields(event.value);
    }
  }

  removeAll() {
    this.sourceFields = [];
    this.targetFields = [];
    this.getFields(0);
  }

  onCancel() {
    if (this.customReports.length <= 0) {
      this.createNewReport();
    } else {
      this.clearReportDetails();
    }
  }

  // provides a central point for handling errors
  handleError(message: string) {
    this.snackBar.open(message, "Ok", {
      horizontalPosition: "end",
      verticalPosition: "top",
      duration: 5000,
      panelClass: "error-snackbar",
    });
  }

  // sleeps for a certain number of milliseconds
  sleep(milliseconds: number) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }

  // clears the source and target field arrays
  initializeValues() {
    this.sourceFields = [];
    this.targetFields = [];
    this.selectedReportId = null;
    this.selectedReportIdString = "";
    this.selectedReportName = "";
    this.reportSelection = null;
  }

  // the default destructor
  ngOnDestroy() {
    this.dragula.destroy("bag-items");
    this.dragulaSubs.unsubscribe();
  }

  moveback() {
    let target = document.getElementsByClassName("targetField");
    for (let i = target.length - 1; i >= 0; i--) {
      if (target[i].className.includes("ex-over") && i >= 0) {
        let div = target[i];
        let input = div.getElementsByTagName("input")[0];
        let fldId = input.id.split("-")[1];
        let fld = this.targetFields.find((f) => f.sourceColumnId == +fldId);
        this.removeClass(div, "ex-over");
        if (fld) {
          fld.state = false;
          this.sourceFields.unshift(fld);
        }

        this.targetFields.splice(i, 1);
      }
    }
    this.challdata = false;
    this.challtarget = false;
    /*
    if (this.targetFields.length < 1) {
      this.placeholdertarget = true;
    }
    if (this.sourceFields.length > 0) {
      this.placeholderdata = false;
    }
    */
  }

  moveto() {
    let target = document.getElementsByClassName("sourceField");
    for (let i = target.length - 1; i >= 0; i--) {
      if (target[i].className.includes("ex-over") && i >= 0) {
        let div = target[i];
        let input = div.getElementsByTagName("input")[0];
        let fldId = input.id.split("-")[1];
        let fld = this.sourceFields.find((f) => f.sourceColumnId == +fldId);
        this.removeClass(div, "ex-over");
        if (fld) {
          fld.state = false;
          this.targetFields.unshift(fld);
        }

        this.sourceFields.splice(i, 1);
      }
    }
    this.challdata = false;
    this.challtarget = false;
    /*
    if (this.targetFields.length > 0) {
      this.placeholdertarget = false;
    }
    if (this.sourceFields.length < 1) {
      this.placeholderdata = true;
    }
    */
  }

  checkAlldata(ev) {
    this.sourceFields.forEach((x) => {
      x.state = ev.target.checked;
    });
    let data = document.getElementsByClassName("sourceField");
    for (let i = 0; i < data.length; i++) {
      if (
        data[i].className.includes("ex-over") &&
        this.sourceFields[i].state === true
      ) {
      } else {
        this.addClass(data[i], "ex-over");
      }
    }
  }

  checkAlltarget(ev) {
    this.targetFields.forEach((x) => {
      x.state = ev.target.checked;
    });
    let target = document.getElementsByClassName("targetField");
    for (let i = 0; i < target.length; i++) {
      if (
        target[i].className.includes("ex-over") &&
        this.targetFields[i].state === true
      ) {
      } else {
        this.addClass(target[i], "ex-over");
      }
    }
  }

  check(val, i) {
    this.addClass(val.parentElement, "ex-over");
  }

  clickdata(val, i) {
    this.addClass(val, "ex-over");
    this.sourceFields[i].state = !this.sourceFields[i].state;
  }

  clicktarget(val, i) {
    this.addClass(val, "ex-over");
    this.targetFields[i].state = !this.targetFields[i].state;
  }

  private hasClass(el: any, name: string): any {
    return new RegExp("(?:^|\\s+)" + name + "(?:\\s+|$)").test(el.className);
  }

  private addClass(el: any, name: string): void {
    if (!this.hasClass(el, name) && !el.state === true) {
      el.className = el.className ? [el.className, name].join(" ") : name;
    } else {
      this.removeClass(el, name);
    }
  }

  private removeClass(el: any, name: string): void {
    if (this.hasClass(el, name)) {
      el.className = el.className.replace(
        new RegExp("(?:^|\\s+)" + name + "(?:\\s+|$)", "g"),
        " "
      );
    }
  }

  private filterOptions(value: string): void {
    const filterValue = value.toLowerCase();
    this.customReportsFiltered = this.customReports.filter((option) =>
      option.label.toLowerCase().startsWith(value)
    );
  }
}

export interface CustomReportFieldSelect extends CustomReportField {
  state: boolean;
}

export class ShowClientReqFields {
  req1: boolean;
  req2: boolean;
  req3: boolean;
  req4: boolean;
  req5: boolean;
  req6: boolean;
  req7: boolean;
  req8: boolean;
  hideAll: boolean;

  constructor() {
    this.req1 = false;
    this.req2 = false;
    this.req3 = false;
    this.req4 = false;
    this.req5 = false;
    this.req6 = false;
    this.req7 = false;
    this.req8 = false;
    this.hideAll = true;
  }
}
