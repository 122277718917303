<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" >
  <div class="col-sm-12 text-xs-center">
    <!--<a [routerLink]="['/widgetlist']">
    Widget List
  </a>
  <a [routerLink]="['/datagridadminlist']">
    Report List
  </a>
      -->
    <app-settings-subscription-panel [card]="settingsData.subscriptionInfo"></app-settings-subscription-panel>
    <app-settings-assignment-panel [card]="settingsData.assignmentInfo"></app-settings-assignment-panel>
    <app-settings-role-panel [card]="settingsData.roleInfo"></app-settings-role-panel>
  </div>
</div>
<div class="row">
    <div class="col-sm-4">
        <div style="padding:10px">
            <button type="button" (click)="clearUserSettings()">Clear Form</button>
            <button type="button" (click)="saveUserSettings()" *ngIf="settingsData.settingsLookupSuccess">Save</button>
        </div>
    </div>
    <div class="col-sm-2"></div>
    <div class="col-sm-6">
        <div style="padding:10px">
            <label for="SettingsEmailPassword">User ID:</label>
            <input type="text" id="SettingsEmailPassword" [(ngModel)]="settingsData.userInfo.userId" />
            <button type="button" (click)="emailPassword()" [disabled]="settingsData.userInfo.userId.length <= 0">Email Password</button>
        </div>
    </div>
</div>
