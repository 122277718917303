import { Injectable } from '@angular/core';
import { combineLatest, ReplaySubject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DriverClientSettings, UserRightsInfo, SafetyUserRightsList, DriverTrainingCourse, DriverTrainingCategory } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { UserRightsService } from '../../components/user-rights-service/user-rights-service.component';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
// TODO: TrainingCourses observable should not be a public property, but the return value of a method.
// so that it can be used for getting courses available to a single driver, instead of all courses for a client.
export class TrainingLessonService {
  public safetyClientSelected: string;
  private _safetyClientSettings: DriverClientSettings;
  private _safetyUserRights: Array<UserRightsInfo>;

  private _TrainingCourses: Array<DriverTrainingCourse>;
  private _TrainingCourses$ = new ReplaySubject<Array<DriverTrainingCourse>>(1);
  public TrainingCourses$ = this._TrainingCourses$.asObservable();

  private _TrainingCategories: Array<DriverTrainingCategory>;
  private _TrainingCategories$ = new ReplaySubject<Array<DriverTrainingCategory>>(1);
  public TrainingCategories$ = this._TrainingCategories$.asObservable();


  constructor(
    private _userRightsService: UserRightsService,
    private _clientSelectionService: ClientSelectionService,
    private _onPremService: OnPremDriverService
  ) {
    combineLatest([
      this._clientSelectionService.clientSelectedInfo$,
      this._clientSelectionService.clientSettingsInfo$,
      this._userRightsService.userRightsValue$
    ]).subscribe(([clientSelected, clientSettings, userRights]) => {
      // clientSelected[] is indexed based on line of business
      // safety only permits one client to be selected, so get the value from the first item
      this.safetyClientSelected = clientSelected[2]?.clientSelectedArray[0];
      this._safetyClientSettings = clientSettings[this.safetyClientSelected];
      this._safetyUserRights = userRights?.filter((right) => right.lineOfBusinessId = 2);
      this.retrieveTrainingLessons();
    });
  }

  private retrieveTrainingLessons() {
    const assignTrainingRight = this._safetyUserRights?.find(ur => ur.userRightId === SafetyUserRightsList.AssignTraining);
    // combineLatest will fire once each of the subscriptions returns at least one value
    // Some of the subscriptions will update multiple times as the app initializes
    // Test to make sure we have all the data we need before publishing information.
    if (
      this._safetyClientSettings &&
      this._safetyClientSettings?.clientType &&
      assignTrainingRight?.permission === 'allow'
    ) {
      this._onPremService.get('training/lessons/' + this.safetyClientSelected)
        .subscribe({
          next: (lessonData: Array<DriverTrainingCourse>) => {
            this._TrainingCourses = lessonData;
            this._TrainingCourses$.next(this._TrainingCourses);
          },
          error: (error: HttpErrorResponse) => this._TrainingCourses$.error(error)
        });

      this._onPremService.get('training/lessons/categories/' + this.safetyClientSelected)
        .subscribe({
          next: (categoryData: Array<DriverTrainingCategory>) => {
            this._TrainingCategories = categoryData;
            this._TrainingCategories$.next(this._TrainingCategories);
          }, error: (error: HttpErrorResponse) =>
            this._TrainingCategories$.error(error)
        });
    }
  }
}
