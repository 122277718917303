<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
  <div class="card card-body mt-3">
    <div class="row extra-margin">
      <div class="col-12">
        <mat-form-field appearance="fill" class="show-toggle-in-modal">
          <mat-label>Program</mat-label>
          <mat-select 
            placeholder="Select a Program">
            <mat-option (onSelectionChange)="assignProgram" *ngFor="let option of programOptions" [value]="option.value">
              {{option.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-default"
          [disabled]="((!programChosen)||(programChosen.length <= 0))"
          (click)="confirmAssignment()">
    Assign Program
  </button>
  <button class="btn btn-default" style="margin-bottom:6px"
          (click)="cancel()">
    Exit
  </button>
</div>
