//Angular
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
//Third Party
import * as _ from 'lodash';
//App
import { CorporateStructure, DriverType } from '../../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ErrorModalService } from '../../../components/error-modal/error-modal-service.component';
import { CorporateStructures, CorporateStructureService } from '../../../safety/corporate-structure/corporate-structure.service';
import { MvrExportDriverInfo } from '../../../safety/mvr-export/mvr-export.component';
import { DriverRiskLevel } from '../driver-risk/driver-risk-level';
import { DriverRiskLevelsService } from '../services/driver-risk-levels.service';
import { FilteredDriversService } from '../services/filtered-drivers.service';
import { DriverFilterCriteria } from '../driver-filter-criteria';
import { LoadingSpinnerService } from '../../../services/loading-spinner-service/loading-spinner.service';

// DHP Next Gen
import { BatchUtilities } from '../../../services/fleet-command-services';

@Component({
  selector: 'app-select-drivers-tab',
  templateUrl: './select-drivers-tab.component.html',
  styleUrls: ['./select-drivers-tab.component.scss'],
  providers: [FilteredDriversService]
})
export class SelectDriversTabComponent implements OnInit, AfterViewInit {

  @Input() parentForm: UntypedFormGroup;
  @Input() criteriaFormGroupName: string;
  @Input() selectorPrefix: string;

  // DHP Next Gen
  @Output() selectedDriverGroups = new EventEmitter<object>();

  public corporateHierarchy = new Array<CorporateStructure>();
  public stateProvHierarchy = new Array<CorporateStructure>();
  public clientSelected = new Array<string>();
  public drivers = new Array<MvrExportDriverInfo>();
  public driverSelectionOptions: DriverSelectionOptions;

  private _selectedCorporateHierarchyElements = new Array<string>();
  private _selectedStateProvinceElements = new Array<string>();
  private _riskLevels: Array<DriverRiskLevel>;

  private _previousFilterCriteria = '';
  private _driverSelectionForm = new UntypedFormGroup({});
  private _includedDrivers = new UntypedFormControl({}, Validators.required);
  private _excludedDrivers = new UntypedFormControl({});

  constructor(
    private _corporateStructureService: CorporateStructureService,
    private _filteredDriverService: FilteredDriversService,
    private _errorModalService: ErrorModalService,
    private loadingSpinnerService: LoadingSpinnerService,
    private readonly _riskLevelsService: DriverRiskLevelsService,
    private readonly batchUtilities: BatchUtilities
  ) {
    this.clientSelected[0] = 'Unknown';
  }

  ngOnInit(): void {

    this._riskLevelsService.riskLevels$.subscribe((riskLevels: Array<DriverRiskLevel>) => {
      this._riskLevels = riskLevels;
    });
    // TODO: SelectorPrefix should be defined as an Enum and the Enum used when passing the values in.
    switch (this.selectorPrefix) {
      case 'MvrDrivers':
        this.driverSelectionOptions = {
          showMVRDate: false,
          showLastMVRDate: true,
          showLastDVDate: true,
          showRiskLevel: true,
        };
        break;
      case 'TrainingDrivers':
        this.driverSelectionOptions = {
          showMVRDate: false,
          showLastMVRDate: false,
          showLastDVDate: false,
          showRiskLevel: true,
        };
        break;
    }
    this._filteredDriverService.DriverInfo$
      .subscribe({
        next: (driverList: Array<MvrExportDriverInfo>) => this.processFilteredDrivers(driverList),
        error: (error: HttpErrorResponse) => {
          this.loadingSpinnerService.hide();
          this._errorModalService.setErrorObject(error.error);
        }
      });
    this._corporateStructureService.corporateStructures$.subscribe((data: CorporateStructures) => {

      this.stateProvHierarchy[0] = _.cloneDeep(data.StateProvinceStructure);
      this.corporateHierarchy[0] = _.cloneDeep(data.CorporateHierarchyStructure);

      // Default all options to be selected. Default clients to not selected, states to selected
      this.selectHierarchyItem(this.corporateHierarchy[0], false);
      this.selectHierarchyItem(this.stateProvHierarchy[0], true);

      // At the current time, the client is displayed as the root level node in the Corporate hierarchy.
      // If that changes, we will need to subscribe to the client selection service to gain access to the
      // selected safety client.
      this.clientSelected = [this.corporateHierarchy[0].element];
      this.updateFilterCriteria();
    });

    this.parentForm.valueChanges.subscribe((data) => {
        this.updateFilterCriteria();
    });

    this._driverSelectionForm.addControl('includedDrivers', this._includedDrivers);
    this._driverSelectionForm.addControl('excludedDrivers', this._excludedDrivers);
    this.parentForm.addControl('selectedDrivers', this._driverSelectionForm);

  }
  ngAfterViewInit(): void {

    // this.corpHierarchyComponent.onSelectionsProcessed.emit();
    // this.corpHierarchyComponent.checkItem(this.corporateHierarchy[0], true);
  }

  // DHP Next Gen
  emitSelectedMvrDriverInfo(event: Array<MvrExportDriverInfo>): void {
    this.selectedDriverGroups.emit(this.batchUtilities.groupSelectedDriversByEmployeeStatus(event));
  }

  private selectHierarchyItem(item: CorporateStructure, checked: boolean) {
    item.boxChecked = checked;
    item.indeterminate = false;
    item.isSelected = 1;
    item.updatedByUser = true;
    item.childElements?.forEach(element => {
      this.selectHierarchyItem(element, checked);
    });
  }

  public updateFilterCriteria() {


        // Don't request a list of drivers if the form is pristine. Wait until the user makes at least one change.
        // During initialization, the form will be valid because it has no controls
        // However, it will ALSO be pristine.
      if (this.parentForm.pristine === false) {


      const criteriaFg = this.parentForm.controls[this.criteriaFormGroupName] as UntypedFormGroup;
      this._selectedCorporateHierarchyElements = this.getCheckedGroupGuidArray(this.corporateHierarchy);
      this._selectedStateProvinceElements = this.getCheckedGroupGuidArray(this.stateProvHierarchy);

      if ((this._selectedCorporateHierarchyElements.length > 0) || (this._selectedStateProvinceElements.length > 0)) {

        const selectedDriverTypes = new Array<number>();
        const driverTypeForm: UntypedFormGroup = criteriaFg.controls['driverTypes'] as UntypedFormGroup;
        if (driverTypeForm?.controls['Employee'].value === true) { selectedDriverTypes.push(DriverType.Employee); }
        if (driverTypeForm?.controls['Non-employee'].value === true) { selectedDriverTypes.push(DriverType.NonEmployee); }

        const filterCriteria: DriverFilterCriteria = {
          clientCode: this.corporateHierarchy[0].element,
          groupGuidList: this._selectedCorporateHierarchyElements.join(','),
          stateGuidList: this._selectedStateProvinceElements.join(','),
          driverTypeIds: selectedDriverTypes.join(','),
          hasNoMvr: false,
          isDotDriver: false,
          autoCoverageRequired: false
        };

        if (driverTypeForm?.controls['NoLicVer_Mvr']?.valid != null) {
          filterCriteria.hasNoMvr = driverTypeForm.controls['NoLicVer_Mvr'].value;
        }
        if (driverTypeForm?.controls['DOT']?.valid != null) {
          filterCriteria.isDotDriver = driverTypeForm.controls['DOT'].value;
        }
        if (driverTypeForm?.controls['AutoCovReq']?.valid != null) {
          filterCriteria.autoCoverageRequired = driverTypeForm.controls['AutoCovReq'].value;
        }
        const riskLevelForm: UntypedFormGroup = criteriaFg.controls['riskLevels'] as UntypedFormGroup;
        const selectedRiskLevels = new Array<number>();
        this._riskLevels.forEach(rl => {
          if (riskLevelForm?.controls[rl.description].value === true) {
            selectedRiskLevels.push(rl.id);
          }
        });
        if (selectedRiskLevels.length > 0) {
          filterCriteria.riskLevelIds = selectedRiskLevels.join(',');
        }

        if (
          criteriaFg.controls['mvrRequestFilter'].pristine === false &&
          criteriaFg.controls['mvrRequestFilter'].valid === true
          ) {
          // this.parentForm.controls['mvrRequestFilter'].pristine === false ) {
          filterCriteria.asOfDate = new Date(criteriaFg.controls['mvrRequestFilter'].value).toLocaleDateString('en-US');
        }

        // Check to see if the data we're sending to the API has actually changed from what is currently loaded.
        // If it hasn't, no need to send another request.
        if (JSON.stringify(filterCriteria) !== this._previousFilterCriteria) {
          this._previousFilterCriteria = JSON.stringify(filterCriteria);
          // this._loadingSpinnerService.start();
          this._filteredDriverService.changeSelectionCriteria(filterCriteria);
          this.drivers = [];
          // console.debug(filterCriteria);
        }
      } else {
        this.drivers = [];
        // this._loadingSpinnerService.stop();
      }
    }
  }

  private processFilteredDrivers(data: Array<MvrExportDriverInfo>) {
    this.drivers = data;
    // this._loadingSpinnerService.stop();
  }

  updateDriverIdSelected(event: Array<number>) {
    this._includedDrivers.setValue(event);
  }

  updateDriverIdExcluded(event: Array<number>) {
    this._excludedDrivers.setValue(event);
  }

  private getCheckedGroupGuidArray(arrCH: Array<CorporateStructure>): Array<string> {
    // get guids of topmost checked entries
    let arrGuid: Array<string> = arrCH.filter(c => c.boxChecked).map(c => c.elementGuid);
    // get partially checked entries
    const arrEntryIndeterminate = arrCH.filter(c => c.indeterminate);
    // for each partially checked entry, add guids of checked sub entries
    arrEntryIndeterminate.forEach(cParent => {
      if (cParent && cParent.childElements) {
        arrGuid = arrGuid.concat(this.getCheckedGroupGuidArray(cParent.childElements));
      }

    });

    return arrGuid;
  }

}

interface DriverSelectionOptions {
  showMVRDate: boolean;
  showLastMVRDate: boolean;
  showLastDVDate: boolean;
  showRiskLevel: boolean;
}
