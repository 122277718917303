//Angular
import { Component, OnInit, Input, ViewChildren, QueryList, AfterViewChecked } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { KeyValue } from '@angular/common';
//Third Party
import { MatDatepicker } from '@angular/material/datepicker';
//App
import { SafetyProcess, SafetyProcessList, TargetDriverTypes } from '../services/safety-processes.service';
import { minimumCheckboxesSelected } from '../../../components/classes-and-interfaces/classes-and-interfaces.component';
import { SafetyProcessUiProperties } from '../safety-process-ui-properties';

@Component({
  selector: 'app-order-assign-choices',
  templateUrl: './order-assign-choices.component.html',
  styleUrls: ['./order-assign-choices.component.scss']
})
export class OrderAssignChoicesComponent implements OnInit, AfterViewChecked {
@ViewChildren('dueDatePicker') dueDatePickerList: QueryList<MatDatepicker<Date>>;
@Input() availableChoices: Map<SafetyProcessList, SafetyProcess>;
@Input() uiMap: Map<SafetyProcessList, SafetyProcessUiProperties>;
@Input() parentForm: UntypedFormGroup;
@Input() selectorPrefix: string;
dueDatePicker: MatDatepicker<Date>;
public choiceList = new Map<SafetyProcessList, ChoiceItem>();
public servicesSelections: UntypedFormGroup;
public targetDriverTypes = TargetDriverTypes;
actualDate = new Date();
minDate = new Date('01/01/2011');
  constructor(private readonly fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    const inputs: any = {};

    this.uiMap.forEach((value, key) => {
      value.getsSelectedWith?.forEach(toggledByKey => {
        const toggledByMap = this.uiMap.get(toggledByKey);
        if (typeof(toggledByMap.selects) !== 'object' || Array.isArray(toggledByMap.selects) !== true) {
          toggledByMap.selects = new Array<SafetyProcessList>();
        }
        toggledByMap.selects.push(key);
      }, this);
      if (this.availableChoices.has(key)) {
        // ... is the spread operator
        // The spread operator can combine two objects into one.
        this.choiceList.set(key, { ...this.availableChoices.get(key), ...this.uiMap.get(key)} );
      }});



    this.choiceList.forEach((currentMap, key) => {
      inputs[key] = this.fb.control(false);
      currentMap.control = inputs[key];
      currentMap.control.valueChanges.subscribe(newValue => this.handleControlChange(newValue, currentMap, currentMap.control));
    });

    this.choiceList.forEach((currentMap, key) => {
      if (currentMap?.requiresDueDate) {
        const DueDateKey = (key as String) + 'dd';
        const ControlDefault = new Date();
        ControlDefault.setDate(ControlDefault.getDate() + currentMap.defaultDueDays);
        inputs[DueDateKey] = this.fb.control({disabled: true, value: ControlDefault }, Validators.required);
        // (inputs[DueDateKey] as FormControl).setValidators()
        currentMap.dueDateControl = inputs[DueDateKey];
        currentMap.dueDateControlKey = DueDateKey;
        currentMap.control.valueChanges.subscribe (newValue => this.handleDatePickerVisibility(newValue, currentMap.dueDateControl));
      }
    }, this);
    this.servicesSelections = new UntypedFormGroup(inputs, [minimumCheckboxesSelected(1)]);

    this.servicesSelections.valueChanges
      .subscribe((newValue) => this.formGroupChangeHandler(newValue));
      this.parentForm.addControl('servicesSelections', this.servicesSelections);
  }

  ngAfterViewChecked(): void {
    if (this.dueDatePickerList?.first) {
      this.dueDatePicker = this.dueDatePickerList.first;
      this.dueDatePickerList.forEach((datePicker,_i) => {
        datePicker.openedStream.subscribe(() =>{
          setTimeout(() => {
            datePicker['_componentRef'].instance._calendar._userSelection.subscribe((event: { value: Date; }) => {
            datePicker.select(event.value);
            datePicker.close();
            })
          },0)
        })
      })
    }
  }

  private handleDatePickerVisibility(newValue, datePicker: UntypedFormControl) {
    if (newValue === true) {
      datePicker.enable();
    } else {
      datePicker.disable();
    }

  }

  private handleControlChange(newValue, sender: ChoiceItem, control: UntypedFormControl) {
    if (typeof(newValue) === 'boolean') {
      // If the input is readonly, rejecct the change.
      if (sender.isReadOnly) {
        sender.control.setValue(!newValue, {emitEvent: false});
        return;
      }
      let otherOptionsChanged = false;
      // If the new value is true/checked, deselect controls this control cannot be selected with
      // select controls this option must be selected with
      if (newValue === true) {

        sender.selects?.forEach(selectedProcessKey => {
          const selectedProcess = this.choiceList.get(selectedProcessKey);
          if (selectedProcess.control.value !== true) {
            selectedProcess.control.setValue(true, {emitEvent: false});
          }
        }, this);

        sender.requires.forEach(requiredProcessKey => {
          const requiredProcess = this.choiceList.get(requiredProcessKey);
          if (requiredProcess.control.value !== true) {
            requiredProcess.control.setValue(true, { emitEvent: false});
            otherOptionsChanged = true;
          }}, this);

        sender.conflicts.forEach(conflictingProcessKey => {
          const conflictingProcess = this.choiceList.get(conflictingProcessKey);
          if (conflictingProcess.control.value !== false) {
            conflictingProcess.control.setValue(false, {emitEvent: false});
            otherOptionsChanged = true;
          }}, this);

      // If the new value is false/unchecked,
      } else {
        sender.requiredBy.forEach(dependentProcessKey => {
          const dependentProcess = this.choiceList.get(dependentProcessKey);
          if (dependentProcess.control.value !== false) {
            dependentProcess.control.setValue(false, { emitEvent: false});
            otherOptionsChanged = true;
          }
        }, this);
      }
      if (otherOptionsChanged) {
        this.servicesSelections.updateValueAndValidity();
      }
    }
  }

  // TODO: Also test for current state of control.
  private toggleControlReadOnly(control: ChoiceItem) {

    let isReadOnly = false;
    control.requires.forEach(requiredProcessKey => {
      // If an option this option requires is false, we cannot mark this as editable.
      if (this.choiceList.get(requiredProcessKey).control.value === false) {
        isReadOnly = true;
      }
    }, this);

    // If a process that cannot be assigned with this process is currently selected, we cannot mark this as editable
    control.conflicts.forEach(conflictingProcessKey => {
      if (this.choiceList.get(conflictingProcessKey).control.value === true) {
        isReadOnly = true;
      }
    }, this);

    control.requiredBy?.forEach(requiredByProcessKey => {
      if (this.choiceList.get(requiredByProcessKey).control.value === true) {
        isReadOnly = true;
      }
    }, this);

    control.isReadOnly = isReadOnly;

  }


  private formGroupChangeHandler(newValue) {
      this.choiceList.forEach((choiceItem, safetyProcessKey) => this.toggleControlReadOnly(choiceItem));

  }
  public choiceItemSort(v1: KeyValue<number, ChoiceItem>, v2: KeyValue<number, ChoiceItem>): number {
    // Sort by displayOrder propery ascending.
    return v1.value.displayOrder > v2.value.displayOrder ? 1
      : (v2.value.displayOrder > v1.value.displayOrder) ? -1 : 0;

  }

}

function noConflictingOptions(choices: Map<SafetyProcessList, SafetyProcess>): ValidatorFn {
  return function validate(fg: UntypedFormGroup) {
    Object.keys(fg.controls).forEach( key => {
      const control = fg.controls[key];
      if (control.value === true) {

      }
    });
    return null;
  };
}



interface ChoiceItem extends SafetyProcess, SafetyProcessUiProperties {}
