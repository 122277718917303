<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
    <div class="card card-body mt-3">
        <div class="row">
            <div class="col-sm-3 readOnlyFormLayoutLabel">
                Last, First Name:
            </div>
            <div class="col-sm-9 readOnlyFormLayoutBold">
                {{driverBaseInfo?.lastName}}, {{driverBaseInfo?.firstName}}
            </div>
        </div>

        <div class="row">
            <div class="col-sm-3 readOnlyFormLayoutLabel">
                Hire Date:
            </div>
            <div class="col-sm-9 readOnlyFormLayoutBold">
                10/26/2021
            </div>
        </div>

        <div class="row">
            <div class="col-sm-3 readOnlyFormLayoutLabel">
                Employee ID:
            </div>
            <div class="col-sm-9 readOnlyFormLayoutBold">
                {{driverBaseInfo?.employeeId}}
            </div>
        </div>
    </div>

    <!-- Table -->
  <app-loading-panel *ngIf="!tableConfig"></app-loading-panel>
  <div *ngIf="tableConfig" style="padding-top:1rem;">
    <app-data-table 
        [columns]="tableConfig.fields"
        [data]="tableConfig.data"
        noRecordsLabel="No Document information"
        (triggerOpenModal)="openModal($event)"
    ></app-data-table>
  </div>

    <!-- Notice -->
    <div class="card card-body mt-3">
        <div class="row">
            <div class="col-sm-12" style="font-weight:bold">
                <p>{{driverBaseInfo?.firstName}} {{driverBaseInfo?.lastName}} has received a California form DL-414 from a recently returned California monitored MVR.</p>
                <p>Pursuant to California regulations concerning monitored drivers, please click verify on the recently returned DL-414 form to confirm receipt of this document.</p>
            </div>
        </div>
    </div>
   
</div>
<div class="modal-footer">  
    <button class="btn btn-exit" style="margin-bottom:6px" (click)="cancel()">
        Exit
   </button>
    <button *ngIf="driverBaseInfo?.reviewRequired === 'True'" class="btn btn-default btn-border" style="margin-bottom:6px" (click)="reviewDoc(driverBaseInfo?.documentId)">
       Verify
    </button>
</div>
  