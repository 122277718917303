import { Injectable } from '@angular/core';
import { combineLatest, ReplaySubject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DriverClientSettings, UserRightsInfo, SafetyUserRightsList } from '../../../components/classes-and-interfaces/classes-and-interfaces.component';
import { UserRightsService } from '../../../components/user-rights-service/user-rights-service.component';
import { ClientSelectionService } from '../../../components/client-selection-service/client-selection-service.component';




export enum TargetDriverTypes {
  NONE = 0,
  EMPLOYEE = 1,
  NONEMPLOYEE = 2,
  ANY = 3
}



export enum SafetyProcessList {
  AutoCoverage = 'autoCoverage',
  Mvr = 'mvr',
  Monitoring = 'monitoring',
  LicenseVerifcation = 'licenseVerification',
  CertificateOfViolation = 'certificateOfViolation',
  PolicySignOff = 'policySignOff',
  LicenseUpload = 'licenseUpload',
  Training = 'training',
  Telematics = 'telematics',
  DriverQualification = 'driverQualification',
  DqMedCard = 'dqMedCard',
  DqLicenseUpload = 'dqLicenseUpload',
  DqDriverApplication = 'dqDriverApplication',
  DqCertificateOfViolation = 'dqCertificateOfViolation',
  DqCertificateOfRoadTest = 'dqCertificateOfRoadTest',
  DqRecordOfRoadTest = 'dqRecordOfRoadTest',
  DqDriverLog = 'dqDriverLog',
  DqClearingHouse = 'dqClearingHouse'
}

export interface SafetyProcess {
  clientCanAssignTo: TargetDriverTypes;
  userCanAssignTo: TargetDriverTypes;
  defaultDueDays: number;
  userRightId: number;

  requires: Array<SafetyProcessList>;
  conflicts: Array<SafetyProcessList>;
  requiredBy: Array<SafetyProcessList>;

}
interface ProcessRelationship {
  requires?: SafetyProcessList[];
  conflicts?: SafetyProcessList[];
}

@Injectable({
  providedIn: 'root'
})
export class SafetyProcessesService {
  public safetyClientSelected: string;
  private _safetyClientSettings: DriverClientSettings;
  private _safetyUserRights: Array<UserRightsInfo>;
  private _safetyProcesses = new Map<SafetyProcessList, SafetyProcess>();
  private _safetyProcesses$ = new ReplaySubject<Map<SafetyProcessList, SafetyProcess>>(1);

  private _processDependencyMaps = new Map<SafetyProcessList, ProcessRelationship>();

  public safetyProcesses$ = this._safetyProcesses$.asObservable();

  constructor(
    private _userRightsService: UserRightsService,
    private _clientSelectionService: ClientSelectionService
    ) {

      // Define relationships between processes where the process either requires another, or conflicts with another.
      this._processDependencyMaps.set(SafetyProcessList.CertificateOfViolation, { requires: [SafetyProcessList.Mvr] });
      this._processDependencyMaps.set(SafetyProcessList.DriverQualification, { conflicts: [SafetyProcessList.LicenseUpload] });
      this._processDependencyMaps.set(SafetyProcessList.DqMedCard, { requires: [SafetyProcessList.DriverQualification] });
      this._processDependencyMaps.set(SafetyProcessList.DqLicenseUpload, { requires: [SafetyProcessList.DriverQualification] });
      this._processDependencyMaps.set(SafetyProcessList.DqDriverApplication, { requires: [SafetyProcessList.DriverQualification] });
      this._processDependencyMaps.set(SafetyProcessList.DqCertificateOfViolation, {
        requires: [SafetyProcessList.DriverQualification, SafetyProcessList.Mvr] });
      this._processDependencyMaps.set(SafetyProcessList.DqCertificateOfRoadTest, { requires: [SafetyProcessList.DriverQualification] });
      this._processDependencyMaps.set(SafetyProcessList.DqRecordOfRoadTest, { requires: [SafetyProcessList.DriverQualification] });
      this._processDependencyMaps.set(SafetyProcessList.DqDriverLog, { requires: [SafetyProcessList.DriverQualification] });
      this._processDependencyMaps.set(SafetyProcessList.DqClearingHouse, { requires: [SafetyProcessList.DriverQualification] });

      // Get all values of the enum and add it to the array to initialize each process in it.
      for (const processValue of Object.values(SafetyProcessList)) {
        this._safetyProcesses.set(processValue, {
          clientCanAssignTo: TargetDriverTypes.NONE,
          userCanAssignTo: TargetDriverTypes.NONE,
          defaultDueDays: 0,
          userRightId: 0,
          requires: this._processDependencyMaps.get(processValue)?.requires ?? new Array<SafetyProcessList>(),
          conflicts: this._processDependencyMaps.get(processValue)?.conflicts ?? new Array<SafetyProcessList>(),
          requiredBy:
            // Convert map to array to filter it
            Array.from(this._processDependencyMaps.entries())
              // identify dependency maps that require the current process
              .filter(dependencyMap => dependencyMap[1].requires?.includes(processValue))
              // return an array of keys that require the current process
              .map(filteredProcessValue => filteredProcessValue[0])
              // If nothing requires this process, set it to an empty, initalized array
              ?? new Array<SafetyProcessList>()
        } as SafetyProcess);
      }
      combineLatest([
        this._clientSelectionService.clientSelectedInfo$/*.pipe(catchError(error => of(error)))*/,
        this._clientSelectionService.clientSettingsInfo$/*.pipe(catchError(error => of(error)))*/,
        this._userRightsService.userRightsValue$/*.pipe(catchError(error => of(error)))*/
      ]).subscribe(([clientSelected, clientSettings, userRights]) => {
        // clientSelected[] is indexed based on line of business
        // safety only permits one client to be selected, so get the value from the first item
        this.safetyClientSelected = clientSelected[2]?.clientSelectedArray[0];
        this._safetyClientSettings = clientSettings[this.safetyClientSelected];
        this._safetyUserRights = userRights?.filter((right) => right.lineOfBusinessId = 2);
        this.publishSafetyServices();
      });

    }
    private publishSafetyServices() {
      /*
        Specified user rights:
        Order MVR (49)
        Batch Tool (51)
        Assign Training (18)
      */
      const orderMvrRight = this._safetyUserRights?.find(ur => ur.userRightId === SafetyUserRightsList.OrderMVRs);
      const batchToolRight = this._safetyUserRights?.find(ur => ur.userRightId === SafetyUserRightsList.BatchTool);
      const assignTrainingRight = this._safetyUserRights?.find(ur => ur.userRightId === SafetyUserRightsList.AssignTraining);
      /*
      Specified employee/nonemployee settings:
        MonitoringEnrollment
        Mvr Ordering
        Training
      */


      if (
          this._safetyClientSettings &&
          this._safetyClientSettings?.clientType &&
          orderMvrRight &&
          batchToolRight &&
          assignTrainingRight
          ) {

        const DefaultDriverType: number = this._safetyClientSettings.hasNonEmployees ? TargetDriverTypes.ANY : TargetDriverTypes.EMPLOYEE;
        //#region rights per process
        this._safetyProcesses.get(SafetyProcessList.AutoCoverage).clientCanAssignTo =
          this._safetyClientSettings.isAutoCoverageOn ? DefaultDriverType : TargetDriverTypes.NONE;

        this._safetyProcesses.get(SafetyProcessList.CertificateOfViolation).clientCanAssignTo =
          this._safetyClientSettings.isCertificateOfViolationOn ? DefaultDriverType : TargetDriverTypes.NONE;

        this._safetyProcesses.get(SafetyProcessList.LicenseVerifcation).clientCanAssignTo =
          this._safetyClientSettings.dataVerification ? DefaultDriverType : TargetDriverTypes.NONE;

        this._safetyProcesses.get(SafetyProcessList.LicenseUpload).clientCanAssignTo =
          this._safetyClientSettings.isLicenseUploadOn ? DefaultDriverType : TargetDriverTypes.NONE;

        // Separate setting exists for non-Employee Monitoring enrollment
        let nonEmployeeMonitoring = false;

        if (this._safetyClientSettings.hasNonEmployees) {
          nonEmployeeMonitoring = this._safetyClientSettings.mvrMonitorNonEmployee;
        }

        if (this._safetyClientSettings.mvrMonitorServices) {
            this._safetyProcesses.get(SafetyProcessList.Monitoring).clientCanAssignTo =
              (nonEmployeeMonitoring === true) ? TargetDriverTypes.ANY : TargetDriverTypes.EMPLOYEE;
        } else {
          this._safetyProcesses.get(SafetyProcessList.Monitoring).clientCanAssignTo = TargetDriverTypes.NONE;
        }

        this._safetyProcesses.get(SafetyProcessList.Mvr).clientCanAssignTo =
          this._safetyClientSettings.mvrAccount ? DefaultDriverType : TargetDriverTypes.NONE;

        this._safetyProcesses.get(SafetyProcessList.PolicySignOff).clientCanAssignTo =
          this._safetyClientSettings.isPolicyTask ? DefaultDriverType : TargetDriverTypes.NONE;

        this._safetyProcesses.get(SafetyProcessList.Training).clientCanAssignTo =
          this._safetyClientSettings.trainingAccount ?
            (this._safetyClientSettings.nonEmployeeTraining ? TargetDriverTypes.ANY : TargetDriverTypes.EMPLOYEE) :
            TargetDriverTypes.NONE;

        this._safetyProcesses.get(SafetyProcessList.Telematics).clientCanAssignTo =
            this._safetyClientSettings.hasTelematics ? DefaultDriverType : TargetDriverTypes.NONE;

        this._safetyProcesses.get(SafetyProcessList.DriverQualification).clientCanAssignTo =
          this._safetyClientSettings.isDriverQualificationOn ? DefaultDriverType : TargetDriverTypes.NONE;

        this._safetyProcesses.get(SafetyProcessList.DqCertificateOfRoadTest).clientCanAssignTo =
          this._safetyClientSettings.isDQCertificateOfRoadTestOn ? DefaultDriverType : TargetDriverTypes.NONE;

        this._safetyProcesses.get(SafetyProcessList.DqRecordOfRoadTest).clientCanAssignTo =
        // Note: Client Settings stored procedure does not return a value for Record of Road Test
        // Assign Multiple services page on driver profile bases Record of Road test visibility on CertificateOfRoadTest value.
          this._safetyClientSettings.isDQCertificateOfRoadTestOn ? DefaultDriverType : TargetDriverTypes.NONE;

        this._safetyProcesses.get(SafetyProcessList.DqCertificateOfViolation).clientCanAssignTo =
          this._safetyClientSettings.isDQCertificateOfViolationOn ? DefaultDriverType : TargetDriverTypes.NONE;

        this._safetyProcesses.get(SafetyProcessList.DqClearingHouse).clientCanAssignTo =
          this._safetyClientSettings.isDQClearingHouseLimitedSignoffOn ? DefaultDriverType : TargetDriverTypes.NONE;

        this._safetyProcesses.get(SafetyProcessList.DqDriverApplication).clientCanAssignTo =
          this._safetyClientSettings.isDQDriverApplicationOn ? DefaultDriverType : TargetDriverTypes.NONE;

        this._safetyProcesses.get(SafetyProcessList.DqDriverLog).clientCanAssignTo =
          this._safetyClientSettings.isDQDriverLogUploadOn ? DefaultDriverType : TargetDriverTypes.NONE;

        this._safetyProcesses.get(SafetyProcessList.DqLicenseUpload).clientCanAssignTo =
          this._safetyClientSettings.isDQLicenseUploadOn ? DefaultDriverType : TargetDriverTypes.NONE;

        this._safetyProcesses.get(SafetyProcessList.DqMedCard).clientCanAssignTo =
          this._safetyClientSettings.isDQMedCardUploadOn ? DefaultDriverType : TargetDriverTypes.NONE;

      // Default user permission to match client setting, and default "default due days" to match training/license verification
      // Most processes don't have rights at the user level.
      for (const processValue of Object.values(SafetyProcessList)) {
        const currentProcess: SafetyProcess = this._safetyProcesses.get(processValue);
        currentProcess.userCanAssignTo = this._safetyProcesses.get(processValue).clientCanAssignTo;
        currentProcess.defaultDueDays =
        // If TrainingDueDays is falsy (0, null, undefined), use license verification
        // If both are falsy, default to 14
          this._safetyClientSettings.trainingDueDays ||
          this._safetyClientSettings.licenseVerificationDueDays ||
          14;
      }

      this._safetyProcesses.get(SafetyProcessList.PolicySignOff).defaultDueDays =
        this._safetyClientSettings.policyTaskDueDays;

      this._safetyProcesses.get(SafetyProcessList.Training).defaultDueDays =
        this._safetyClientSettings.trainingDueDays;

      this._safetyProcesses.get(SafetyProcessList.LicenseUpload).defaultDueDays =
        this._safetyClientSettings.licenseUploadDueDays;

      this._safetyProcesses.get(SafetyProcessList.LicenseVerifcation).defaultDueDays =
        this._safetyClientSettings.licenseVerificationDueDays;

      // If the user has permission to order MVRs, set their target driver types to match Monitoring enrollment and MVR Ordering
      this._safetyProcesses.get(SafetyProcessList.Mvr).userCanAssignTo = (
        orderMvrRight.permission === 'allow'
      ) ? this._safetyProcesses.get(SafetyProcessList.Mvr).clientCanAssignTo : TargetDriverTypes.NONE;

      this._safetyProcesses.get(SafetyProcessList.Monitoring).userCanAssignTo = (
        orderMvrRight.permission === 'allow'
      ) ? this._safetyProcesses.get(SafetyProcessList.Monitoring).clientCanAssignTo : TargetDriverTypes.NONE;

      this._safetyProcesses$.next(this._safetyProcesses);
    }
  }
}
