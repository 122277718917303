import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';
import { GridsterModule } from 'angular-gridster2';

import { UserRightsService } from './components/user-rights-service/user-rights-service.component';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/navmenu/navmenu.component';
import { ClientSelectorComponent } from './components/client-selector/client-selector.component';
import { CompanySelectorComponent } from './components/company-selector/company-selector.component';
import { CardCollapsibleComponent } from './components/card-collapsible/card-collapsible.component';
import { CardStaticComponent } from './components/card-static/card-static.component';
import { CardGridsterComponent } from './components/card-gridster/card-gridster.component';
import { DashboardGridComponent } from './components/dashboard-grid/dashboard-grid.component';
import { DashboardStatCenterComponent } from './components/dashboard-stat-center/dashboard-stat-center.component';
import { DashboardActionBarComponent } from './components/dashboard-action-bar/dashboard-action-bar.component';
import { DashboardFilterStandardComponent } from './components/dashboard-filter-standard/dashboard-filter-standard.component';
import { DashboardDateSelectComponent } from './components/dashboard-date-select/dashboard-date-select.component';
import { DashboardFilterSummaryComponent } from './components/dashboard-filter-summary/dashboard-filter-summary.component';
import { CardStatCenterComponent } from './components/card-stat-center/card-stat-center.component';
import { DashboardTileDateFilterComponent } from './components/dashboard-tile-date-filter/dashboard-tile-date-filter.component';
import { DashboardTileSlicerFilterComponent } from './components/dashboard-tile-slicer-filter/dashboard-tile-slicer-filter.component';
import { DashboardTileFilterSummaryComponent } from './components/dashboard-tile-filter-summary/dashboard-tile-filter-summary.component';

import { WidgetUserAddComponent } from './components/widget-user-add/widget-user-add.component';
import { WidgetUserAddListComponent } from './components/widget-user-add-list/widget-user-add-list.component';
import { SettingsPageComponent } from './components/settings-page/settings-page.component';
import { SettingsSubscriptionPanelComponent } from './components/settings-subscription-panel/settings-subscription-panel.component';
import { SettingsAssignmentPanelComponent } from './components/settings-assignment-panel/settings-assignment-panel.component';
import { SettingsRolePanelComponent } from './components/settings-role-panel/settings-role-panel.component';
import { SharedModule } from './shared/shared.module';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { AlertModalService } from './components/alert-modal/alert-modal-service.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { ErrorModalService } from './components/error-modal/error-modal-service.component';
import { IEWarningPanelComponent } from './components/ie-warning-panel/ie-warning-panel.component';
import { IELearnMoreModalComponent } from './components/ie-learnmore-modal/ie-learnmore-modal.component';
import { IELearnMoreModalService } from './components/ie-learnmore-modal/ie-learnmore-modal-service.component';

import { DataGridComponent } from './components/data-grid/data-grid.component';
import { DataGridService, ReportRequest } from './components/data-grid/data-grid.service';
import { DataGridResolve } from './components/data-grid/data-grid-resolve.service';
import { PaginationConfig } from 'ngx-bootstrap/pagination';
import { DataGridMenuComponent } from './components/data-grid-menu-component/data-grid-menu.component';
import { DataGridMenuService } from './components/data-grid-menu-component/data-grid-menu.service';
import { DataGridFilterModalComponent } from './components/data-grid-filter-modal/data-grid-filter-modal.component';
import { DataGridEmailService } from './components/data-grid-export-email/data-grid-export-email.service';
import { DataGridModalService } from './components/data-grid-filter-modal/data-grid-filter-modal.service';
import { QuickSearchComponent } from './components/quick-search/quick-search.component';
import { QuickSearchRequest, QuickSearchService } from './components/quick-search/quick-search.service';
import { QuickSearchResolve } from './components/quick-search/quick-search-resolve.service';
import { OrdersAndAssignmentsReportMenuItemComponent } from './components/orders-and-assignments-report-menu-item/orders-and-assignments-report-menu-item.component';
import { OrdersAndAssignmentsReportModalComponent } from './components/orders-and-assignments-report-modal/orders-and-assignments-report-modal.component';
import { OrdersAndAssignmentsReportModalService } from './components/orders-and-assignments-report-modal/orders-and-assignments-report-modal-service.component';

import { ClaimTabService } from './components/claim-tab-service/claim-tab-service.component';

import { AuthService } from './components/auth/auth.service';
import { AuthGuard } from './components/auth/auth.guard';
import { AuthInterceptor } from './components/auth/auth.interceptor';
import { AuthCallbackComponent } from './components/auth/auth-callback.component';
import { OnPremClaimService } from './components/on-prem-service/on-prem-claim-service.component';
import { OnPremDriverService } from './components/on-prem-service/on-prem-driver-service.component';
import { OnPremDcService } from './components/on-prem-service/on-prem-dc-service.component';
import { OnPremService } from './components/on-prem-service/on-prem-service.component';

import { BrowserInfoService } from './components/browser-info-service/browser-info-service.component';
import { GoTopButtonModule } from './shared/ng2-go-top-button/go-top-button.module';

import { AccessDeniedComponent } from './components/access-denied-component/access-denied.component';

import { BusinessGroupNewsPopupComponent } from './components/business-group-news-popup/business-group-news-popup.component';
import { BusinessGroupNewsService } from './components/business-group-news-popup/business-group-news-service.component';

import { ChartV2Component } from './components/chart-v2/chart-v2.component';
import { ChartV2Service } from './components/chart-v2/chart-v2-service.component';

// highcharts
import { HighchartsChartModule } from 'highcharts-angular';

// CUSTOM MODULES
import { CustomModuleComponent } from './components/custom-module/custom-module.component';
import { CustomModuleSelectorComponent } from './components/custom-module-selector/custom-module-selector.component';
import { CustomModuleSelectorService } from './components/custom-module-selector/custom-module-selector-service.component';

import { AdminModule } from './admin/admin.module';
import { environment } from '../environments/environment';
import { ClaimsModule } from './claims/claims.module';
import { SafetyModule } from './safety/safety.module';
import { DcModule } from './dc/dc.module';
import { ReactivateDriverMenuItemComponent } from './components/reactivate-driver-menu-item/reactivate-driver-menu-item.component';
import { ReactivateDriverSearchModalComponent } from './components/reactivate-driver-search-modal/reactivate-driver-search-modal.component';
import { HondaOptOutMenuItemComponent } from './components/honda-opt-out-menu-item/honda-opt-out-menu-item.component';
import { HondaOptOutSearchModalComponent } from './components/honda-opt-out-search-modal/honda-opt-out-search-modal.component';
import { HondaOptOutSearchModalService } from './components/honda-opt-out-search-modal/honda-opt-out-search-modal-service.component';
import { ReactivateDriverSearchModalService } from './components/reactivate-driver-search-modal/reactivate-driver-search-modal-service.component';
import { OnPremTimeclockService } from './components/on-prem-service/on-prem-timeclock-service.component';
import { AddDocumentComponent } from './modals/add-document/add-document.component';
import { AddDocumentService } from './services/add-document-service/add-document.service';
import { DashboardService } from './components/dashboard-service/dashboard-service.component';
import { DashboardTileZoomComponent } from './modals/dashboard-tile-zoom/dashboard-tile-zoom.component';
import { DashboardTileFilterComponent } from './modals/dashboard-tile-filter/dashboard-tile-filter.component';
import { AppInisghtsErrorHandlerService } from './services/app-insights-services/AppInsightsErrorHandler.service';
import { AppInsightsService } from './services/app-insights-services/AppInsights.service';
import { DhpDocumentService } from './services/dhp-document-service/dhp-document.service';

export function loadAuthConfig(authService: AuthService) {
    console.log('APP_INITIALIZER STARTING');
    return () => authService.configureAuthentication(environment.visibilityApiUrl + `api/Configuration`);
}

type PathMatch = "full" | "prefix" | undefined;
// DHP NextGen
import {
    FleetResponseService,
    DriverTasksCommandService,
    DriverTasksQueryService
} from './services/fleet-response-services';

@NgModule({
    declarations: [
        AppComponent,
        AccessDeniedComponent,
        AlertModalComponent,
        AuthCallbackComponent,
        BusinessGroupNewsPopupComponent,
        CardCollapsibleComponent,
        CardGridsterComponent,
        CardStatCenterComponent,
        CardStaticComponent,
        ChartV2Component,
        ClientSelectorComponent,
        CompanySelectorComponent,
        CustomModuleComponent,
        CustomModuleSelectorComponent,
        DashboardActionBarComponent,
        DashboardDateSelectComponent,
        DashboardFilterStandardComponent,
        DashboardFilterSummaryComponent,
        DashboardGridComponent,
        DashboardStatCenterComponent,
        DashboardTileDateFilterComponent,
        DashboardTileSlicerFilterComponent,
        DashboardTileFilterSummaryComponent,
        DataGridComponent,
        DataGridFilterModalComponent,
        DataGridMenuComponent,
        OrdersAndAssignmentsReportMenuItemComponent,
        OrdersAndAssignmentsReportModalComponent,
        ErrorModalComponent,
        IEWarningPanelComponent,
        IELearnMoreModalComponent,
        HondaOptOutMenuItemComponent,
        HondaOptOutSearchModalComponent,
        NavMenuComponent,
        QuickSearchComponent,
        ReactivateDriverMenuItemComponent,
        ReactivateDriverSearchModalComponent,
        SettingsAssignmentPanelComponent,
        SettingsPageComponent,
        SettingsRolePanelComponent,
        SettingsSubscriptionPanelComponent,
        WidgetUserAddComponent,
        WidgetUserAddListComponent,
        AddDocumentComponent,
        DashboardTileZoomComponent,
        DashboardTileFilterComponent
    ],
    imports: [
        HttpClientJsonpModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        ClaimsModule,
        SafetyModule,
        DcModule,
        GridsterModule,
        HighchartsChartModule,
        GoTopButtonModule,
        SharedModule,
        AdminModule,
        RouterModule.forRoot([
            { path: '', redirectTo: 'statcenter/', pathMatch: 'full' as PathMatch },
            { path: 'callback', component: AuthCallbackComponent },
            { path: 'statcenter/:LineOfBusiness', component: DashboardStatCenterComponent, canActivate: [AuthGuard] },
          {
            path: 'datagrid/:id', component: DataGridComponent,
            runGuardsAndResolvers: 'always',
            resolve: { data: DataGridResolve },
            canActivate: [AuthGuard],
            data: { shouldReuse: false }
          },
            {
                path: 'quickSearch/:id', component: QuickSearchComponent,
                runGuardsAndResolvers: 'always',
                resolve: { data: QuickSearchResolve },
                canActivate: [AuthGuard]
            },
            { path: 'accessdenied', component: AccessDeniedComponent, canActivate: [AuthGuard] },
            { path: 'custom/:LineOfBusiness', component: CustomModuleComponent, canActivate: [AuthGuard] },
            { path: '**', redirectTo: 'statcenter/' }
        ], { onSameUrlNavigation: 'reload' }),
    ],
    providers: [
        DatePipe,
        DecimalPipe,
        UserRightsService,
        BrowserInfoService,
        AlertModalService,
        AuthGuard,
        AuthService,
        DashboardService,
        {
            provide: APP_INITIALIZER,
            useFactory: loadAuthConfig,
            deps: [AuthService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },

        BusinessGroupNewsService,
        ChartV2Service,
        ClaimTabService,
        CustomModuleSelectorService,
        DataGridService,
        DataGridResolve,
        DataGridEmailService,
        DataGridMenuService,
        DataGridModalService,
        ErrorModalService,
        HondaOptOutSearchModalService,
        ReactivateDriverSearchModalService,
        IELearnMoreModalService,
        OnPremClaimService,
        OnPremDriverService,
        OnPremDcService,
        OnPremTimeclockService,
        OnPremService,
        PaginationConfig,
        QuickSearchService,
        QuickSearchResolve,
        QuickSearchRequest,
        ReportRequest,
        OrdersAndAssignmentsReportModalService,
        AddDocumentService,
        { provide: ErrorHandler, useClass: AppInisghtsErrorHandlerService},
        AppInsightsService,
        AppInisghtsErrorHandlerService,
        FleetResponseService,
        DriverTasksCommandService,
        DriverTasksQueryService,
        DhpDocumentService
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
  constructor(private readonly authService: AuthService) {
        console.log('APP STARTING');
    }
}
