<div>
  <div>
    <div>
      <div class="modal-header">
        <h4 class="modal-title w-100" id="labelDashboardTileFilterModal">Filter: {{dashboardTileToFilter?.title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" [mat-dialog-close]="true">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div>
        <app-dashboard-tile-date-filter [card]="dashboardTileToFilter"
                                        [initStartDate]="dashboardTileToFilterStartDate"
                                        [initEndDate]="dashboardTileToFilterEndDate"
                                        *ngIf="dashboardTileToFilter&&(dashboardTileFilterType=='date')"
                                        (onFilterUpdate)="closeTileFilterModal()">
        </app-dashboard-tile-date-filter>
        <app-dashboard-tile-slicer-filter [card]="dashboardTileToFilter"
                                          *ngIf="dashboardTileToFilter&&(dashboardTileFilterType=='slicer')"
                                          (onFilterUpdate)="closeTileFilterModal()">
        </app-dashboard-tile-slicer-filter>
      </div>
      <div class="modal-footer">
        <button type="button" *ngIf="dashboardTileFilterType!='slicer'" class="btn btn-default" aria-label="Save Filter" (click)="updateTileFilterInfo()">{{dashboardTileFilterType=='date'?'Save Date':'Save Filter'}}</button>
        <button type="button" class="btn btn-default" style="margin-bottom:6px" aria-label="Close" [mat-dialog-close]="true">Close</button>
      </div>
    </div>
  </div>
</div>
