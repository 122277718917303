//Angular
import { Component, Input, Inject, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
//Third Party
//APP
import { WidgetPerClientSettingsInfo, WidgetInfo } from "../classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-widget-per-client',
  templateUrl: './widget-per-client.component.html',
  styleUrls: ['./widget-per-client.component.scss']
})
export class WidgetPerClientComponent implements OnInit {
  private routewidget: any;
  widgetId: number;
  widgetTitle: string;
  perClientSettings: Array<WidgetPerClientSettingsInfo>;
  perClientProductGroups: Array<WidgetPerClientProductGroup>;
  baseUrl: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit() {
    this.routewidget = this.route.params.subscribe(params => {
      this.widgetId = +params['WidgetId'];
      this.getPerClientInfo();
    });
  }

  private getPerClientInfo() {
    let params = new HttpParams();
    params = params.append('TileId', this.widgetId.toString());

    this.http.get(this.baseUrl + 'api/AdminTile/GetById', { params: params })
      .subscribe({
        next: (data) => {
          let widgetObject = data as WidgetInfo;
          this.widgetTitle = widgetObject.title;
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
        }
      });

    this.getClientsForTile();
  }

  private getClientsForTile() {
    let params = new HttpParams();
    params = params.append('TileId', this.widgetId.toString());

    this.loadingSpinnerService.show();

    this.http.get(this.baseUrl + 'api/AdminTile/GetClientsByTileId', { params: params })
      .subscribe({
        next: (data) => {
          this.perClientSettings = data as Array<WidgetPerClientSettingsInfo>;
          if (this.perClientProductGroups && (this.perClientProductGroups.length > 0)) {
            this.updateProductGroups()
          } else {
            this.createProductGroups();
          }
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
  }

  private createProductGroups() {
    let arrProdGroup: Array<WidgetPerClientProductGroup> = [];
    for (let pcs of this.perClientSettings) {
      let prodGroup = arrProdGroup.find(function (obj) { return obj.productId === pcs.productId; });
      if (!prodGroup) {
        let prodGroup = { productId: pcs.productId, productName: pcs.productName, clients: [], productExpanded: false };
        prodGroup.clients.push(pcs);
        arrProdGroup.push(prodGroup);
      } else {
        prodGroup.clients.push(pcs);
      }
    }
    this.perClientProductGroups = arrProdGroup.sort(function (a, b) { return a.productId - b.productId });
  }

  private updateProductGroups() {
    this.perClientProductGroups.forEach(g => g.clients = []);
    for (let pcs of this.perClientSettings) {
      let prodGroup = this.perClientProductGroups.find(function (obj) { return obj.productId === pcs.productId; });
      if (!prodGroup) {
        let prodGroup = { productId: pcs.productId, productName: pcs.productName, clients: [], productExpanded: false };
        prodGroup.clients.push(pcs);
        this.perClientProductGroups.push(prodGroup);
      } else {
        prodGroup.clients.push(pcs);
      }
    }
  }

  enableAll(enable: boolean) {
    let params = new HttpParams();
    params = params.append('TileId', this.widgetId.toString());
    params = params.append('IsActive', enable.toString());
    this.loadingSpinnerService.show();
    this.http.post(this.baseUrl + 'api/AdminTile/ActivateAllClientsForTile', null, {
      params: params
    }).subscribe({
      next: (data) => {
        this.loadingSpinnerService.hide();
        this.getClientsForTile();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    }
    )
  }

  enableProduct(productId: number, enable: boolean) {
    let params = new HttpParams();
    params = params.append('TileId', this.widgetId.toString());
    params = params.append('ProductId', productId.toString());
    params = params.append('IsActive', enable.toString());
    this.loadingSpinnerService.show();
    this.http.post(this.baseUrl + 'api/AdminTile/ActivateProductClientTile', null, {
      params: params
    }).subscribe({
      next: (data) => {
        this.loadingSpinnerService.hide();
        this.getClientsForTile();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    }
    )
  }

  enableClient(clientShortName: string, enable: boolean) {
    let params = new HttpParams();
    params = params.append('TileId', this.widgetId.toString());
    params = params.append('Clients', clientShortName);
    this.loadingSpinnerService.show();
    if (enable) {
      this.http.put(this.baseUrl + 'api/AdminTile/AddTileClientMap', null, {
        params: params
      }).subscribe({
        next: (data) => {
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      }
      )
    } else {
      //disable
      this.http.delete(this.baseUrl + 'api/AdminTile/DeleteTileClientMap', {
        params: params
      }).subscribe({
        next: (data) => {
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      }
      )
    }
  }

  exitPerClientInfo() {
    this.router.navigate(['widgetedit', this.widgetId.toString()]);
  }

  ngOnDestroy() {
    this.routewidget.unsubscribe();
  }
}

export interface WidgetPerClientProductGroup {
  productId: number;
  productName: string;
  clients: Array<WidgetPerClientSettingsInfo>;
  productExpanded: boolean;
}
