<div class="row">
  <div class="col-md-12 left-side-align">
    <h5><strong>All MVR release forms must be kept on file by your organization to satisfy the conditions set forth by the FCRA.</strong></h5>
  </div>
</div>

<div class="row">
  <div class="col-md-12 left-side-align">
    <div class="col-md-2">
      <a href="https://get.adobe.com/reader/" target="_blank" rel="noopener noreferrer">
        <img alt="Adobe Reader" src="images/get-adobe-reader.png" />
      </a>
    </div>
    <div class="col-md-10">
      <p>Adobe Acrobat Reader must be installed to be able to view and print the PDF version of these files.</p>
      <p>Please click on the Adobe icon on the left to download Acrobat Reader.</p>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <mat-accordion multi="true">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="title">Required Forms to Authorize Release - USA</div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <table small="true">
          <thead style="display:none;">
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="col" style="display:none;"></th>
              <th scope="col" style="display:none;"></th>
            </tr>
            <tr *ngFor="let doc of usaReleaseDocuments">
              <td class="release-link"><a href="{{doc.azureBlobUrl}}" target="_blank" rel="noopener noreferrer">{{doc.stateName}}</a></td>
              <td [innerHTML]="doc.verbiage"></td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="title">Required Forms to Authorize Release - Canada</div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <table small="true">
          <thead style="display:none;">
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let doc of canadaReleaseDocuments">
              <td class="release-link"><a href="{{doc.azureBlobUrl}}" target="_blank" rel="noopener noreferrer">{{doc.stateName}}</a></td>
              <td [innerHTML]="doc.verbiage"></td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
