<div class="row" *ngIf="fullProfile">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Driver Information</h4>
          <div class="row">
            <div class="col-sm-2 accidentClaimTabLabel">
              Employee ID:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.employeeId != '' ? driverProfile?.employeeId : 'Not Available'}}
            </div>
            <div class="col-sm-2 accidentClaimTabLabel">
              Email:
            </div>
            <div class="col-sm-5 accidentClaimTabBold">
              <a *ngIf="driverProfile?.emailAddress != ''" href="{{'mailTo:'+driverProfile?.emailAddress}}">
                {{driverProfile?.emailAddress}}
              </a>
              {{driverProfile?.emailAddress == '' ? 'Not Available':''}}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 accidentClaimTabLabel">
              Employee Name:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.fullName != '' ? driverProfile?.fullName : 'Not Available'}}
            </div>
            <div class="col-sm-2 accidentClaimTabLabel">
              Language:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.language != '' ? driverProfile?.language : 'Not Available'}}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 accidentClaimTabLabel">
              License #:
            </div>
            <div *ngIf="canViewDLDOB" class="col-sm-3 accidentClaimTabBold">
              {{((driverProfile?.driverLicenseNumber&&(driverProfile?.driverLicenseNumber.length > 0)) ? driverProfile?.driverLicenseNumber +(driverProfile?.driverLicenseState != '' ? ' (' + driverProfile?.driverLicenseState + ')': '') : 'Not Available')}}
            </div>
            <div *ngIf="!canViewDLDOB" class="col-sm-3 accidentClaimTabBold">
              Not Available
            </div>
            <div class="col-sm-2 accidentClaimTabLabel">
              Country:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.country != '' ? driverProfile?.country : 'Not Available'}}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 accidentClaimTabLabel">
              Date of Birth:
            </div>
            <div *ngIf="canViewDLDOB&&(driverProfile?.dateOfBirth != '')" class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.dateOfBirth |  date:'MM/dd/yyyy'}}
            </div>
            <div *ngIf="(canViewDLDOB&&(driverProfile?.dateOfBirth == '')) || !canViewDLDOB" class="col-sm-3 accidentClaimTabBold">
              Not Available
            </div>
            <div class="col-sm-2 accidentClaimTabLabel">
              Home State:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.homeState != '' ? driverProfile?.homeState : 'Not Available'}}
            </div>
          </div>
          <div class="row" *ngIf="driverProfile&&driverProfile.trainingOnlyPortalAccess">
            <div class="col-sm-2 accidentClaimTabLabel">
              User Name:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.trainingOnlyPortalUserName && (driverProfile?.trainingOnlyPortalUserName != '') ? driverProfile?.trainingOnlyPortalUserName : 'Not Available'}}
            </div>
            <div class="col-sm-2 accidentClaimTabLabel">
              Password:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.trainingOnlyPortalPassword && (driverProfile?.trainingOnlyPortalPassword != '') ? driverProfile?.trainingOnlyPortalPassword : 'Not Available'}}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 accidentClaimTabLabel">
              Group:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.fullHierarchy != '' ? driverProfile?.fullHierarchy : 'Not Available'}}
            </div>
            <div *ngIf="driverProfile&&driverProfile.driverReactivateDate" class="col-sm-2 accidentClaimTabLabel">
              Re-Activated:
            </div>
            <div *ngIf="driverProfile&&driverProfile.driverReactivateDate" class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.driverReactivateDate |  date:'M/d/yyyy h:mm:ss a'}}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 accidentClaimTabLabel">  
              Driver Provided License Expiration Date:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.driverLicenseExpirationDate |  date:'M/d/yyyy'}}
            </div>
            <div class="col-sm-2 accidentClaimTabLabel">
              Driver Indicated Commercial License (CDL):
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.isCommercialDriversLicense ? 'Yes' : 'No'}}
            </div>
          </div>
          <div class="row" *ngIf="canViewDOT || canViewAutoCoverage">
            <div class="col-sm-2 accidentClaimTabLabel" *ngIf="canViewDOT">
              DOT Driver:
            </div>
            <div class="col-sm-3 accidentClaimTabBold" *ngIf="canViewDOT">
              {{driverProfile?.dotDriver ? 'Yes' : 'No'}}
            </div>
            <div class="col-sm-2 accidentClaimTabLabel" *ngIf="canViewAutoCoverage">
              Auto Coverage Required:
            </div>
            <div class="col-sm-3 accidentClaimTabBold" *ngIf="canViewAutoCoverage">
              {{driverProfile?.requiresAutoCoverage ? 'Yes' : 'No'}}
            </div>
          </div>          
          <div class="row" *ngFor="let e of extendedInfo">
            <div class="col-sm-2 accidentClaimTabLabel">
              {{e.label}}:
            </div>
            <div class="col-sm-10 accidentClaimTabBold">
              {{e.value}}
            </div>
          </div>
          <div class="row" *ngIf="driverProfile&&(driverProfile.leaveOfAbsenceStatus==1)">
            <div class="col-sm-2 accidentClaimTabLabel">
              Driver Status:
            </div>
            <div class="col-sm-10 accidentClaimTabBold" style="color:red;">
              On Leave: {{driverProfile?.leaveOfAbsenceStartDate | date:'MM/dd/yyyy'}} - {{driverProfile?.leaveOfAbsenceEndDate | date:'MM/dd/yyyy'}}
            </div>
          </div>
          <div class="row" >
            <ng-container *ngIf="(driverProfile && !hireDateInReq)">
            <div class="col-sm-2 accidentClaimTabLabel">
              Hire Date:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.hireDate | date:'MM/dd/yyyy'}}
            </div>
          </ng-container>
            <div class="col-sm-2 accidentClaimTabLabel">
              Added:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.driverCreateDate |  date:'MM/dd/yyyy'}} by {{driverProfile?.driverCreateUser}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Data Verification</h4>
          <div class="row">
            <div class="col-sm-2 accidentClaimTabLabel">
              Assigned Date:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.dataVerificationAssignDate&&(driverProfile?.dataVerificationAssignDate != '') ? driverProfile?.dataVerificationAssignDate : 'Not Available'}}
            </div>
            <div class="col-sm-2 accidentClaimTabLabel">
              Due Date:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.dataVerificationDueDate&&(driverProfile?.dataVerificationDueDate != '') ? driverProfile?.dataVerificationDueDate : 'Not Available'}}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 accidentClaimTabLabel">
              License Updated:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.dataVerificationCompleteDate&&(driverProfile?.dataVerificationCompleteDate != '') ? driverProfile?.dataVerificationCompleteDate : 'Not Available'}}
            </div>
          </div>
          <div class="row" *ngIf="driverProfile?.licenseVerificationStatus">
            <div class="col-sm-12" [ngStyle]="{'color': driverProfile?.licenseVerificationStatusColor}">
              {{driverProfile?.licenseVerificationStatus}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
