import { Component, OnInit, Input } from '@angular/core';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { Subscription } from 'rxjs';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { HttpErrorResponse } from '@angular/common/http';

// DHP Next Gen
import { FeatureFlagService } from '../../services/launchdarkly-service/feature-flag.service';
import { BatchCommandService } from '../../services/fleet-command-services/batch-command.service';
import { BatchUtilities } from '../../services/fleet-command-services/batch-utilities.service';
import {
  Batch,
  ServiceMvrOrder
} from '../models';

@Component({
  selector: 'dhp-order-mvr',
  templateUrl: './dhp-order-mvr.component.html'
})
export class DHPOrderMVRComponent implements OnInit {
  /**
   * DESCRIPTION:   Request for MVR order only, and supports both employee and non-employee drivers.
   *                Can be called by DriverProfileComponent or SafetyAdditionalDriversNotFullComponent.
   *
   * NOTES:         Currently under DHP Next Gen transition using Launch Darkly.
   *
   * */

  @Input() modalHeight: string;
  @Input() modalInput: any;
  // private variables that are only shared with subscribers that import the type
  clientSelectionSubscription: Subscription;
  lineOfBusinessSubscription: Subscription;
  clientSelectedArray: Array<string>;
  clientIdSelectedArray: Array<number>;
  lineOfBusinessId: number;
  baseUrl: string;
  reportSelection: null;
  dirty: boolean = false;

  // DHP Next Gen
  batchRequest: Array<Batch> = [];
  canUseNewBatchProcess: boolean;

  // the overloaded constructor for the controller
  constructor(
    private readonly clientSelectionService: ClientSelectionService,
    private readonly dhpService: DriverHistoryProfileService,
    private readonly alertService: AlertService,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly onPremService: OnPremDriverService,
    private readonly lineOfBusinessService: LineOfBusinessService,
    private readonly errorService: ErrorModalService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly batchCommandService: BatchCommandService,
    private readonly batchUtilities: BatchUtilities
    ) {

    this.loadingSpinnerService.show();

    // the LOB subscription service
    this.lineOfBusinessSubscription = this.lineOfBusinessService.lineOfBusinessSelected$.subscribe({
      next: (data) => {
        this.lineOfBusinessId = data as number;
      },
        error: (err: HttpErrorResponse) => {
        this.handleError(err.error.toString());
      }
    });

    // get the selected client(s)
    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusinessId);
    loadingSpinnerService.hide();
  }

  ngOnInit(): void {
    this.subscribeToLaunchDarkly();
  }

  submit(): void {
    if (this.canUseNewBatchProcess) {
      this.processBatchRequest();
    } else {
      this.processLegacyRequest();
    }
  }

  cancel(): void {
    this.dhpService.notifyModalClose();
  }

  private processBatchRequest(): void {
    this.batchRequest = [];

    const batch = {
      applicationSource: this.batchUtilities.batchSource,
      clientCode: this.modalInput.client,
      driverIds: [this.modalInput.driverId],
      services: []
    } as Batch;

    batch.services.push(new ServiceMvrOrder());
    this.batchRequest.push(batch);

    this.batchCommandService.post('batch', this.batchRequest).subscribe({
      next: (data) => {
        this.dhpService.notifyModalClose();
        this.alertService.showSuccessAlert(
          'Action Successfully Completed.',
          'end',
          'top',
          5000
        );
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.dhpService.notifyModalClose();
      }
    });
  }

  private processLegacyRequest(): void {
    const params = {
      ClientCode: this.modalInput.client,
      CertificateOfInsuranceDriverIdList: null,
      CertificateOfInsuranceDriverIdExcludeList: null,
      MvrDriverIdList: this.modalInput.driverId.toString(),
      MvrDriverIdExcludeLis: null,
      MvrDataValidationDue: null,
      MvrDataValidationOnly: false,
      MvrMonitorDriverIdList: null,
      MvrMonitorDriverIdExcludeList: null,
      LicenseUploadDriverIdList: null,
      LicenseUploadDriverIdExcludeList: null,
      LicenseUploadDueDate: null,
      TrainingDriverIdList: null,
      TrainingDriverIdExcludeList: null,
      TrainingDueDate: null,
      TrainingAssignmentType: null,
      TrainingCourseGroupList: null,
      TrainingPassRateList: null,
      ProcessId: null
    };

    this.onPremService.post('tasks/batch', params).subscribe({
      next: (data) => {
        this.dhpService.notifyModalClose();
        this.alertService.showSuccessAlert(
          'Action Successfully Completed.',
          'end',
          'top',
          5000
        );
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.dhpService.notifyModalClose();
      },
    });
  }

  private handleError(message: string): void {
    // This needs to actually handle the error.
  }

  private subscribeToLaunchDarkly(): void {
    const featureFlag = 'order-mvr-client-list';
    this.featureFlagService
      .getFlag(featureFlag, false)
      .subscribe((variation) => {
      this.canUseNewBatchProcess = this.featureFlagService.evaluateClientVariation(variation, this.modalInput.client, featureFlag);
    });
  }
}
