<div class="card card-body mt-3" *ngIf="uploadType=='DriverProfile'">
  <h4 class="card-title text-muted">Driver Info</h4>
  <div class="row">
    <div class="col-sm-2 readOnlyFormLayoutLabel">
      Employee Name:
    </div>
    <div class="col-sm-3 readOnlyFormLayoutBold">
      {{driverProfile?.fullName != '' ? driverProfile?.fullName : 'Not Available'}}
    </div>
    <div class="col-sm-2 readOnlyFormLayoutLabel">
      License #:
    </div>
    <div class="col-sm-3 readOnlyFormLayoutBold">
      {{(driverProfile?.driverLicenseNumber != '' ? driverProfile?.driverLicenseNumber : 'Not
      Available')+(driverProfile?.driverLicenseState != '' ? ' (' + driverProfile?.driverLicenseState + ')': '')}}
    </div>
  </div>
</div>
<div class="card card-body mt-3">
  <h4 class="card-title text-muted">Document Upload</h4>
  <div class="row">
    <div class="col-md-12">
      <div class="md-form">
        <input id="docUploadDescription" type="text" [(ngModel)]="fileDescription" class="form-control"
          placeholder="Description" [formControl]="descriptionControl" />
        <label for="docUploadDescription"
          [ngClass]="{ 'label-validation-error': descriptionControl.invalid, 'active':true }">Description</label>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="(uploadType=='DriverProfile')||(uploadType=='EmployeeDriverForm')">
    <div class="col-md-12">
      <label [ngClass]="{ 'label-validation-error': documentTypeControl.invalid }">Mark Document As</label>
      <mat-form-field appearance="fill" class="select-container ml-2 w-50">
        <mat-label>Choose the Document Type</mat-label>
        <mat-select [(value)]="documentTypeId">
          <input matInput class="select-search-input" placeholder="Search Document Types..."
            (keyup)="filterOptions($event.target.value)">
          <mat-option *ngFor="let option of documentTypeOptions" [value]="option.value">
            {{option.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="documentTypeId == 5">
    <div class="col-md-12">
      <label [ngClass]="{ 'label-validation-error': dateObtainedTypeControl.invalid, 'active':true }">Date Obtained from
        State/Province</label>
      <mat-form-field appearance="fill" class="ml-2">
        <mat-label class="lbl-start-date">From Date</mat-label>
        <input class="input-date" matInput disabled [matDatepicker]="myDateStatePicker"
          [(ngModel)]="documentDateObtainedState" [formControl]="dateObtainedTypeControl">
        <mat-datepicker-toggle matSuffix [for]="myDateStatePicker"></mat-datepicker-toggle>
        <mat-datepicker #myDateStatePicker disabled="false">
          <mat-datepicker-actions>
            <button mat-button (click)="myDateStatePicker.select(actualDate); myDateStatePicker.close()">
              <mat-icon>history</mat-icon>
              Today
            </button>
            <button mat-button (click)="myDateStatePicker.select(null); myDateStatePicker.close()">
              <mat-icon>highlight_off</mat-icon>
              Clear
            </button>
          </mat-datepicker-actions>
        </mat-datepicker>
      </mat-form-field>

    </div>
  </div>
  <div class="row">
    <div>&nbsp;</div>
  </div>
  <div class="row" *ngIf="((uploadType=='DriverProfile')||(uploadType=='EmployeeDriverForm')) && documentTypeId==15 ">
    <div class="col-md-12">
      <div class="md-form">
        <label [ngClass]="{ 'label-validation-error': expirationDateControl.invalid, 'active':true }">Expiration
          Date</label>
        <div class="">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label class="lbl-start-date">Expiration Date</mat-label>
            <input class="input-date" matInput disabled [matDatepicker]="autoCoverageExpirationDatePicker" [(ngModel)]="expirationDate"
              [formControl]="expirationDateControl">
            <mat-datepicker-toggle matSuffix [for]="autoCoverageExpirationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #autoCoverageExpirationDatePicker disabled="false">
              <mat-datepicker-actions>
                <button mat-button (click)="autoCoverageExpirationDatePicker.select(actualDate); autoCoverageExpirationDatePicker.close()">
                  <mat-icon>history</mat-icon>
                  Today
                </button>
                <button mat-button (click)="autoCoverageExpirationDatePicker.select(null); autoCoverageExpirationDatePicker.close()">
                  <mat-icon>highlight_off</mat-icon>
                  Clear
                </button>
              </mat-datepicker-actions>
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="((uploadType=='DriverProfile')||(uploadType=='EmployeeDriverForm')) && documentTypeId==31 ">
    <div class="col-md-12">
      <div class="md-form">
        <!--<div [ngClass]="{ 'label-validation-error': expirationDateControl.invalid, 'active':true }">
          Expiration Date:
        </div>-->
        <label [ngClass]="{ 'label-validation-error': dqDriverLogStartDateControl.invalid, 'active':true }">Start
          Date</label>
        <div class="">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label class="lbl-start-date">StartDate Date</mat-label>
            <input class="input-date" matInput [max]="maxDateStartDate" disabled [matDatepicker]="startDatePicker" [(ngModel)]="filterStartDate" [formControl]="dqDriverLogStartDateControl">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker [disabled]="false">
              <mat-datepicker-actions>
                <button mat-button (click)="startDatePicker.select(actualDate); startDatePicker.close()">
                  <mat-icon>history</mat-icon>
                  Today
                </button>
                <button mat-button (click)="startDatePicker.select(null); startDatePicker.close()">
                  <mat-icon>highlight_off</mat-icon>
                  Clear
                </button>
              </mat-datepicker-actions>
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="((uploadType=='DriverProfile')||(uploadType=='EmployeeDriverForm')) && documentTypeId==31 ">
    <div class="col-md-12">
      <div class="md-form">
        <!--<div [ngClass]="{ 'label-validation-error': expirationDateControl.invalid, 'active':true }">
          Expiration Date:
        </div>-->
        <label [ngClass]="{ 'label-validation-error': dqDriverLogEndDateControl.invalid, 'active':true }">End
          Date</label>
        <div class="">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>End Date</mat-label>
            <input class="input-date" [max]="maxDateEndDate" [(ngModel)]="dqDriverLogEndDate" [formControl]="dqDriverLogEndDateControl"
              matInput disabled [matDatepicker]="endDatePicker">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker disabled="false">
              <mat-datepicker-actions>
                <button mat-button (click)="endDatePicker.select(actualDate); endDatePicker.close()">
                  <mat-icon>history</mat-icon>
                  Today
                </button>
                <button mat-button (click)="endDatePicker.select(null); endDatePicker.close()">
                  <mat-icon>highlight_off</mat-icon>
                  Clear
                </button>
              </mat-datepicker-actions>
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="row extra-extra-margin">
    <div class="col-md-9">
      <div class="file-field">
        <div class="btn btn-default btn-sm waves-light">
          <span>Choose file</span>
          <input type="file" (change)="onUploadOutput($event)" #docUploadFileInput (click)="$event.target.value=null">
        </div>
        <div class="file-path-wrapper">
          <input class="file-path" type="text" placeholder="Upload your file" [value]="showFiles()">
        </div>
      </div>
    </div>
    <div class="col-md-3">
      File cannot be larger than 10MB.
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" style="text-align:center">
      <span>
        <button type="button" (click)="uploadDocs()" class="btn btn-default">
          Finish Uploading Documentation
        </button>
        <button type="button" (click)="closeUploadPanel()" class="btn btn-default">
          Exit
        </button>
      </span>
    </div>
  </div>
</div>