<div class="row">
  <div class="col-md-12">
    <h3 class="text-muted">Report Builder</h3>
  </div>
  <div class="card card-body mb-3">
    <!-- REPORTS -->
    <div class="row" *ngIf="selectedReportId==null">
      <div class="col-md-2">
        <button type="button"
                (click)="createNewReport()"
                class="btn btn-primary">
          Create A New Report
        </button>
      </div>
      <ng-container *ngIf="customReports.length >= 1">
        <div class="col-md-1" style="padding-top:1rem">
          <h5> OR </h5>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="fill" class="select-container w-100">
            <mat-label>Select a Report to Edit</mat-label>
            <mat-select #selectReports="matSelect" [(value)]="selectedReportIdString" (selectionChange)="onChange($event)">
              <input matInput class="select-search-input" placeholder="Search Reports..." (keyup)="filterOptions($event.target.value)">
              <mat-option *ngFor="let option of customReportsFiltered" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="selectedReportId!=null">
      <!-- REPORT NAME / RENAME / SAVE BUTTONS -->
      <div class="row">
        <div class="col-md-12" style="margin-top:1rem;padding-top:1rem;">
          <div class="md-form">
            <input #reportName
                   type="text"
                   style="font-size:smaller; font-weight:700; color:#757575"
                   id="reportName"
                   class="form-control"
                   [(ngModel)]="reportSelection"
                   (keypress)="saveReport($event)"
                   [placeholder]="(selectedReportId=='0'?'New ':'')+'Report Name'" />
            <label for="reportName" class="active">{{selectedReportId=='0'?'New ':''}}Report Name</label>
          </div>
        </div>
      </div>
      <!-- START OF FIELDS -->
      <hr />

      <div class="row" style="margin-top:-15px;">
        <div>
          <div class="col-md-9">
            <!-- AVAILABLE FIELDS-->
            <div class="md-form">
              <label id="lblAvailableFields" class="accidentClaimSupportsTableHeader font-weight-bold position-relative" for="sourceTable">Available Fields</label>
            </div>
            <div class="customReportFieldContainer sourceContainer"
                 [dragula]='"bag-items"' [(dragulaModel)]="sourceFields">
              <div *ngIf="(!sourceFields||(sourceFields.length <= 0))" class="selectedFieldsTableEmpty"></div>
              <div *ngFor="let sourceField of sourceFields" class="customReportField sourceField">
                <input id="sourceCB-{{sourceField.sourceColumnId}}"
                       type="checkbox"
                       (change)="check($event.target, i)"
                       value="{{sourceField.sourceColumnId}}" />
                <label class="label-base customReportFieldLabel"
                       for="sourceCB-{{sourceField.sourceColumnId}}">
                  {{ sourceField.sourceColumn }}
                </label>
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-2">
          <div class="customReportFieldsNote">
            Drag and drop to add, remove, or re-order fields.  To move multiple items, check one or more boxes, then drag from a checked item.
          </div>
        </div>
        <div>
          <div class="col-md-9">
            <!-- SELECTED FIELDS-->
            <div class="md-form">
              <label id="lblTargetFields" class="font-weight-bold position-relative" for="targetTable">Selected Fields</label>
            </div>
            <div class="customReportFieldContainer targetContainer"
                 [dragula]='"bag-items"' [(dragulaModel)]="targetFields">
              <div *ngIf="(!targetFields||(targetFields.length <= 0))" class="selectedFieldsTableEmpty"></div>
              <div *ngFor="let targetField of targetFields" class="customReportField targetField">
                <input id="targetCB-{{targetField.sourceColumnId}}"
                       type="checkbox"
                       (change)="check($event.target, i)"
                       value="{{targetField.sourceColumnId}}" />
                <label class="label-base customReportFieldLabel"
                       for="targetCB-{{targetField.sourceColumnId}}">
                  {{ targetField.sourceColumn }}
                </label>
              </div>
            </div>

            <button type="button"
                    (click)="removeAll()"
                    class="btn btn-default"
                    [disabled]="(!targetFields)||(targetFields.length<=0)">
              Remove All
            </button>

          </div>
        </div>
      </div>
      <!--END OF FIELDS-->
      <div class="row">
        <div class="col-md-12">
          <hr />
        </div>
      </div>
      <!-- CLIENTS -->
      <div class="row" *ngIf="!showClientReqFields.hideAll">
        <div class="col-md-12">
          <div class="md-form">
            <label for="sourceTable">User Defined Fields</label>
          </div>
          <table id="clientTable" class="table-responsive table-bordered table-condensed table-striped table-hover" style="margin-top:40px;">
            <tbody>
              <tr class="table-header">
                <th scope="col" style="font-size:smaller; font-weight:700; color:#757575">Client Name</th>
                <th scope="col" style="font-size:smaller; font-weight:700; color:#757575" *ngIf="showClientReqFields.req1">Req 1</th>
                <th scope="col" style="font-size:smaller; font-weight:700; color:#757575" *ngIf="showClientReqFields.req2">Req 2</th>
                <th scope="col" style="font-size:smaller; font-weight:700; color:#757575" *ngIf="showClientReqFields.req3">Req 3</th>
                <th scope="col" style="font-size:smaller; font-weight:700; color:#757575" *ngIf="showClientReqFields.req4">Req 4</th>
                <th scope="col" style="font-size:smaller; font-weight:700; color:#757575" *ngIf="showClientReqFields.req5">Req 5</th>
                <th scope="col" style="font-size:smaller; font-weight:700; color:#757575" *ngIf="showClientReqFields.req6">Req 6</th>
                <th scope="col" style="font-size:smaller; font-weight:700; color:#757575" *ngIf="showClientReqFields.req7">Req 7</th>
                <th scope="col" style="font-size:smaller; font-weight:700; color:#757575" *ngIf="showClientReqFields.req8">Req 8</th>
              </tr>
              <tr *ngFor="let client of clientReqFields" style="height:10px;">
                <td hidden="hidden">{{client.client}}</td>
                <td style="width:20%; font-size:smaller;">{{client.clientName}}</td>
                <td style="width:10%; font-size:smaller;" *ngIf="showClientReqFields.req1">{{client.req1}}</td>
                <td style="width:10%; font-size:smaller;" *ngIf="showClientReqFields.req2">{{client.req2}}</td>
                <td style="width:10%; font-size:smaller;" *ngIf="showClientReqFields.req3">{{client.req3}}</td>
                <td style="width:10%; font-size:smaller;" *ngIf="showClientReqFields.req4">{{client.req4}}</td>
                <td style="width:10%; font-size:smaller;" *ngIf="showClientReqFields.req5">{{client.req5}}</td>
                <td style="width:10%; font-size:smaller;" *ngIf="showClientReqFields.req6">{{client.req6}}</td>
                <td style="width:10%; font-size:smaller;" *ngIf="showClientReqFields.req7">{{client.req7}}</td>
                <td style="width:10%; font-size:smaller;" *ngIf="showClientReqFields.req8">{{client.req8}}</td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>
      <!-- REPORT NAME / RENAME / SAVE BUTTONS -->
      <div class="row">
        <div class="col-md-12" style="margin-top:5px;">
          <hr />
          <div class="md-form">
            <span style="float:left">
              <button type="button"
                      (click)="saveReport()"
                      class="btn btn-default"
                      [disabled]="(!reportSelection)||(reportSelection.length<=0)||(!targetFields)||(targetFields.length<=0)">
                Save
              </button>
              <button *ngIf="selectedReportId>0"
                      type="button"
                      (click)="deleteReport()"
                      class="btn btn-default">
                Remove
              </button>
              <button type="button"
                      (click)="onCancel()"
                      class="btn btn-default">
                Exit
              </button>
            </span>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
</div>
