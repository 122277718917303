//Angular
import { Component, ViewChild, QueryList } from '@angular/core';
import { Router } from '@angular/router';
//Third Party
import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
//APP
import { DriverInactive } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ReactivateDriverSearchModalService } from "./reactivate-driver-search-modal-service.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";

@Component({
  selector: 'app-reactivate-driver-search-modal',
  templateUrl: './reactivate-driver-search-modal.component.html',
  styleUrls: ['./reactivate-driver-search-modal.component.scss']
})
export class ReactivateDriverSearchModalComponent {
  reactivateSubscription: Subscription;
  searchId: string;
  searchName: string;

  constructor(private router: Router,
    private reactivateService: ReactivateDriverSearchModalService,
    private errorService: ErrorModalService,
    private dialogRef: MatDialogRef<ReactivateDriverSearchModalComponent>)
  {
  }

  search() {
    let driverId = this.searchId||'';
    let driverLastName = this.searchName || '';
    if ((driverId.length > 0) || (driverLastName.length > 0)) {
      this.searchName = null;
      this.searchId = null;
      this.closeReactivateSearchModal();
      this.router.navigate(['reactivatedrivergrid', driverId, driverLastName]);
    } else {
      this.errorService.setErrorObject({ message: 'ID and Last Name cannot both be blank.' });
    }

  }

  closeReactivateSearchModal(): void {
    this.dialogRef.close();
  }
}
