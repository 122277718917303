import { Component, Inject, Input, OnInit } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router'
//import { DataGridService, GridData } from './data-grid-menu.service';
//import { NG_TABLE_DIRECTIVES, NgTableSortingDirective } from 'ng2-table';
import { DataGridFilter, DataGridFilterValue } from '../data-grid/data-grid.component'
import { ReportRequest } from '../data-grid/data-grid.service';
import {
  DataGridEmailService,
  ExportToEmailRequest,
  DQGridRequest,
  COIValidationGridRequest,
  DcDriversOnLeaveGridRequest,
  DcDriverTimeClockGridRequest,
  DcDriverTimeClockDrilldownGridRequest,
  AbstractReviewGridRequest
} from "./data-grid-export-email.service";
import { Subscription } from 'rxjs';
import { UserSettings } from "../classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../error-modal/error-modal-service.component";
import { QuickSearchRequest } from "../quick-search/quick-search.service";
import { AdvancedSearchRequest } from '../../claims/advanced-search-claims/advanced-search-claims.service';
import { CustomReportGridRequest } from '../../claims/custom-report-grid/custom-report-grid.service';
import { AlertService } from '../../services/alert-service/alert.service';

@Component({
  selector: 'app-data-grid-export-email',
  templateUrl: './data-grid-export-email.component.html',
  styleUrls: ['./data-grid-export-email.component.css']
})
export class DataGridExportEmailComponent implements OnInit {
  @Input() lineOfBusiness: number;
  popupSubscription: Subscription;

  baseUrl: string;
  display: string = "none";
  cc: boolean;
  gridRequest: any;

  name: string;
  public _userDetails: UserSettings;
  grid: any;
  searchRequest: any;//QuickSearchRequest|AdvancedSearchRequest;
  isQuickSearch: boolean = false;
  gridType: number = 1;  //report=1, quicksearch=2, advancedsearch=3, customreport=4


  constructor(
    private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private route: ActivatedRoute,
    private emailService: DataGridEmailService,
    gridRequest: ReportRequest,
    private errorService: ErrorModalService,
    private alertService: AlertService,
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
    this.emailService = emailService;
    this.gridRequest = gridRequest;

    this.popupSubscription = emailService.modalPopup$.subscribe(
      request => {
        //console.log(request);
        if (request instanceof QuickSearchRequest) {
          //this.isQuickSearch = true;
          this.searchRequest = request;
          this.gridType = 2
          this.emailSubject = "Quick Search";
        }
        else if (request instanceof AdvancedSearchRequest) {
          this.searchRequest = request;
          this.gridType = 3
          this.emailSubject = "Advanced Search";
        }
        else if (request instanceof CustomReportGridRequest) {
          this.grid = request;
          //this.searchRequest = request;
          this.gridType = 4
          this.emailSubject = (request as CustomReportGridRequest).ReportName;

        }
        else if (request instanceof DQGridRequest) {
          this.grid = request;
          this.gridType = 5;
          this.emailSubject = "DQ File Review";
        }
        else if (request instanceof COIValidationGridRequest) {
          this.grid = request;
          this.gridType = 6;
          this.emailSubject = "COI Validation Review";
        }
        else if (request instanceof DcDriversOnLeaveGridRequest) {
          this.grid = request;
          this.gridType = 7;
          this.emailSubject = "Drivers On Leave";
        }
        else if (request instanceof DcDriverTimeClockGridRequest) {
          this.grid = request;
          this.gridType = 8;
          this.emailSubject = "Driver Time Clock Report";
        }
        else if (request instanceof DcDriverTimeClockDrilldownGridRequest) {
          this.grid = request;
          this.gridType = 9;
          this.emailSubject = "Driver Time Clock Drilldown Report";
        }
        else if (request instanceof AbstractReviewGridRequest) {
          this.grid = request;
          this.gridType = 10;
          this.emailSubject = "WA and AB Abstract Review";
        }
        else {
          this.gridType = 1
          this.grid = request;
          this.emailSubject = this.grid.title;
          if (this.gridRequest.ParameterValues && this.gridRequest.ParameterValues.length > 0 && !this.gridRequest.clickTrough) {
            this.gridRequest.ParameterValues.forEach(x => {
              this.emailSubject += " | " + x.ParameterName + ": " + x.DisplayValue
            })
          }
        }
        this.showModal();
      }
    );

  }

  ngOnInit() {
    this.getUserDetails();


    //this.gridRequestSubscription = dataservice.dataGridRequest$.subscribe(
    //  gridRequest => {
    //    this.gridRequest = gridRequest as ReportRequest;
    //    console.log("gridRequest Subscription: ");
    //    console.log(this.gridRequest);
    //  }
    //);
  }

  private getUserDetails() {

    this.emailService.GetUserDetails()
      .subscribe({
        next: (data) => {
          this._userDetails = data as UserSettings;
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
        }
      })

  }
  showModal() {
    this.display = "block";
  }
  onCloseHandled() {
    this.display = "none";

    //localStorage["test1"] = "test123";
  }

  toEmail: string;
  emailSubject: string;// = "FleetSuite by Fleet Response | Accident Reason: All " ;
  onSend() {

    let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

    emailPattern = '^([\w\d\-\.]+)@{1}(([\w\d\-]{1,67})|([\w\d\-]+\.[\w\d\-]{1,67}))\.(([a-zA-Z\d]{2,4})(\.[a-zA-Z\d]{2})?)$';


    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    //serchfind = regexp.test(search);

    if (!this.toEmail || !regexp.test(this.toEmail)) {
      this.alertService.showErrorAlert('Invalid recipient email', 'end', 'top', 5000);
      return;
    }


    let emailRequest = new ExportToEmailRequest();
    emailRequest.cc = this.cc;



    emailRequest.emailSubject = this.emailSubject
    //emailRequest.grid.DataGridId = 2;
    emailRequest.Recipient = this.toEmail;
    emailRequest.GridType = this.gridType;

    if (this.gridType == 1) {
      emailRequest.DataGridRequest = this.gridRequest;
      this.emailService.SendEmail(emailRequest, this.lineOfBusiness);
    }
    else if (this.gridType == 2) {
      emailRequest.SearchRequest = this.searchRequest;
      this.emailService.SendSearchEmail(emailRequest, this.lineOfBusiness);

    }
    else if (this.gridType == 3) {
      emailRequest.AdvancedSearchRequest = this.searchRequest;
      this.emailService.SendSearchEmail(emailRequest, this.lineOfBusiness);

    }
    else if (this.gridType == 4) {
      emailRequest.CustomReportRequest = this.grid;
      this.emailService.SendEmail(emailRequest, this.lineOfBusiness);
    }
    else if (this.gridType == 5) {
      emailRequest.DqGridRequest = this.grid;
      this.emailService.SendEmail(emailRequest, this.lineOfBusiness);
    }
    else if (this.gridType == 6) {
      emailRequest.DqGridRequest = this.grid;
      this.emailService.SendEmail(emailRequest, this.lineOfBusiness);
    }
    else if (this.gridType == 7) {
      emailRequest.DqGridRequest = this.grid;
      this.emailService.SendEmail(emailRequest, this.lineOfBusiness);
    }
    else if (this.gridType == 8) {
      emailRequest.DataGridRequest = (this.grid as DcDriverTimeClockGridRequest).DataGridRequest;
      this.emailService.SendEmail(emailRequest, this.lineOfBusiness);
    }
    else if (this.gridType == 9) {
      emailRequest.DataGridRequest = (this.grid as DcDriverTimeClockGridRequest).DataGridRequest;
      this.emailService.SendEmail(emailRequest, this.lineOfBusiness);
    }
    this.onCloseHandled();
    this.alertService.showSuccessAlert('Email sent!', 'end', 'top', 5000);
  }

}
