import { Component, OnInit, Input } from '@angular/core';
import { UserRightsInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DcDriver, DcLiveRouteTraining } from '../dc-classes-and-interfaces/dc-classes-and-interfaces.component';
import { DatePipe } from '@angular/common';

/**
 * this is the client-side controller for the safety - driver info tab panel
 */
@Component({
  selector: 'dc-driver-info',
  templateUrl: './dc-driver-info.component.html',
  styleUrls: ['./dc-driver-info.component.css']
})

export class DcDriverInfoComponent implements OnInit {
  @Input() driverProfile: DcDriver;
  @Input() _userRights: Array<UserRightsInfo>;
 
  // the overloaded constructor for the controller
  constructor(private datePipe: DatePipe) {
  }

  // angular on intialization event
  ngOnInit() {
    //this.canViewDLDOB = this.isAccessAllowed(42);
  }

  // determines if access to the component is allowed
  isAccessAllowed(userRightsId: number) {
    let retVal: boolean = false;

    if (this._userRights) {
      let thisRight = this._userRights.filter(r => r.userRightId == userRightsId);
      if ((thisRight.length > 0) && (thisRight[0].permission == 'allow')) {
        retVal = true;
      }
    }

    return retVal;
  }

  getLiveRouteTrainingString(arr: DcLiveRouteTraining[]): string {

    if (arr.filter(x => x.trainingDate != null)[0]) {
      let ss = arr.filter(x => x.trainingDate != null).map(x => this.datePipe.transform(x.trainingDate, "M/d/yyyy") + " - " + x.qualifiedObserverEmployeeId);
      return ss.join(", ");
    }

    if (arr.filter(x => x.trainingHours != null && x.trainingHours != 0)[0]) {
      let ss = arr.filter(x => x.trainingHours != null).map(x => x.trainingHours + " - " + x.qualifiedObserverEmployeeId);
      return ss.join(", ");
    }
  }
}
