<nav class="navbar navbar-expand-lg navbar-dark quickSearchActionBar">
  <div class="">
    <ul class="nav navbar-nav nav-flex-icons ml-auto">
      <li class="nav-item quick-search-container mr-4">
        <input type="text" name="searchText" class="form-control quickSearchInput ml-0"
               placeholder="Quick search..." [(ngModel)]="searchText" (keyup.enter)="search()">
      </li>
      <li class="nav-item refine-search-container mr-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Refine search by...</mat-label>
          <mat-select [(value)]="selectedColumnFilter" (selectionChange)="onChange($event)">
            <mat-option *ngFor="let option of quickSearchChoices" [value]="option.value">
              {{option.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
      <li class="nav-item search-button-container">
        <button type="submit" class="btn btn-default btn-sm search-buttonGo ml-0" (click)="search()">Go</button>
        <div class="switch " *ngIf="hasMultipleClients" style="display:inline-block">

          <label>
            <input type="checkbox"
                   [checked]="searchAcrossClients"
                   (change)="searchAcrossClients=!searchAcrossClients">
            <span class="lever enableWidgetSwitch"></span>
            <span class="enableWidgetLabel">&nbsp;</span>
          </label>
          <span style="color: #212529">Search all clients</span>
        </div>

      </li>
      <div *ngIf="lineOfBusinessId == 1" style="padding-left:1rem" class="advanced-search-container">
        <li class="nav-item">
          <button type="button" class="btn btn-default btn-sm search-button" [routerLink]="['/accident/advancedSearch']">Advanced Search</button>
        </li>
      </div>
      <div *ngIf="lineOfBusinessId == 2" style="padding-left:1rem" class="advanced-search-container">
        <li class="nav-item">
          <button type="button" class="btn btn-default btn-sm search-button" [routerLink]="['/safety/advancedSearch']">Advanced Search</button>
        </li>
      </div>      
    </ul>

  </div>
  
</nav>

