<div>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">×</span>
      </button>
      <h4 class="modal-title w-100" id="labelDqApproveModal">Approve {{dqType}}</h4>
    </div>
    <div id="modalBody" class="modal-body">
      <div class="row" *ngIf="attNames&&(attNames.length>0)">
        <div class="col-md-12">
          <div class="card card-body">
            <div class="row" *ngIf="showExpirationDate">
              <div class="col-md-12">
                <label [ngClass]="{ 'label-validation-error': expirationDateControl.invalid, 'active':true }">*Expiration Date</label>
                <div class="">
                  <mat-form-field appearance="fill">
                    <mat-label class="lbl-start-date">Expiration Date</mat-label>
                    <input 
                      class="input-date" 
                      matInput disabled 
                      [(ngModel)]="expirationDate"
                      [matDatepicker]="startDatePicker" 
                      [min]="today"
                      [max]="maxDate"
                      [formControl]="expirationDateControl" 
                      (dateChange)="onExpirationDateChange()">
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker disabled="false">
                    </mat-datepicker>
                  </mat-form-field>
                </div>
                <p class="text-danger" *ngIf="showCallOutMessage">
                   <strong>Attention: Expiration is more than 1 year out</strong>
                </p>
              </div>
            </div>
            <div align="center">
              <div class="row">
                <div class="col-md-12">
                  <h5>Attachments Reviewed:</h5>
                </div>
              </div>
              <div class="row" *ngFor="let att of attNames">
                <div class="col-md-12">
                  {{att}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary"
              (click)="submit()"
              [disabled]="showExpirationDate && expirationDateControl.invalid? true: false">
        Approve
      </button>
      <button class="btn btn-primary"
              (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
