//Angular
import { Component, OnInit, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
//Third Party
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
//App
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { DcModalWindowService } from '../dc-modal-window/dc-modal-window-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { UserRightsInfo, ErrorObject } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { DataGridEmailService, DcDriversOnLeaveGridRequest } from '../../components/data-grid-export-email/data-grid-export-email.service';
import { ReportRequest } from '../../components/data-grid/data-grid.service';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'dc-drivers-on-leave-report',
  templateUrl: './dc-drivers-on-leave-report.component.html',
  styleUrls: ['./dc-drivers-on-leave-report.component.scss']
})
export class DcDriversOnLeaveReportComponent implements OnInit {
 
  clientSelectedArray: Array<string>;
  clientIdSelectedArray: Array<number>;
  lineOfBusinessId: number;
  baseUrl: string;
  driverBaseInfo: any;
  _userRights: Array<UserRightsInfo>;
  loaNotFound: boolean = false;
  canTerminateDriver: boolean = false;
  isActiveLeave: boolean = false;
  gridRequestId: string;
  gridRequest: ReportRequest;
  routeParamsSub: Subscription;
  companySelectedGuid: string;

  public page = 1;
  public maxSize = 5;
  public itemsPerPage = 50;
  public numPages = 1;
  public length = 0;
  public today = new Date(Date.now());
  public sortPageTime = 0;
  public dateFilterRangeType = 1;
  columns = [];
  keepDrivers = [];
  removeDrivers = [];

  rowsRaw: Array<any>;
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  gtOptions: any = { numberOfRows: 50 };
  public configObject: any;

  constructor(@Inject('BASE_URL') baseUrl: string,
    private clientSelectionService: ClientSelectionService,
    private lineOfBusinessService: LineOfBusinessService,
    private dcModalWindowService: DcModalWindowService,
    private errorService: ErrorModalService,
    private onPremDcService: OnPremDcService,
    private fileDownloadService: FileDownloadService,
    private emailService: DataGridEmailService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private loadingSpinnerService: LoadingSpinnerService) {
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void {
    // set line of business to safety if not already initialized to Safety
    this.lineOfBusinessId = 7;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob !== 7) {
      this.lineOfBusinessService.setLineOfBusiness(7);
    }

    // get the selected client(s)
    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusinessId);

    this.routeParamsSub = this.route.params.subscribe(params => {
      this.gridRequestId = params['id'];
      if (this.gridRequestId && (this.gridRequestId.length > 0)) {
        this.gridRequest = JSON.parse(sessionStorage.getItem('grid_' + this.gridRequestId));
        this.initAll();
      }
    });




  }
  onPrint() {
    let sortParams = "";

    let companySelection = "&company=" + this.companySelectedGuid;

    this.fileDownloadService.openTab(this.baseUrl + "api/DeliveryContractors/PrintDriversOnLeaveGrid?client=" + this.clientSelectedArray[0] + sortParams + companySelection);
  }
  onExcel() {

    let sortParams = "";

    let companySelection = "&company=" + this.companySelectedGuid;


    this.fileDownloadService.saveFile(this.baseUrl + "api/DeliveryContractors/DriversOnLeaveGridExcel?client=" + this.clientSelectedArray[0] + sortParams + companySelection);
  }

  onEmail() {

    const request = new DcDriversOnLeaveGridRequest();

    request.SelectedClient = this.companySelectedGuid;
    request.SelectedCompany = this.companySelectedGuid;
    this.emailService.OpenEmailPopup(request);
  }

  private initAll() {
    const companyParameter = this.gridRequest.ParameterValues.find(x => x.ParameterName === "Company")
    if (companyParameter)
      this.companySelectedGuid = companyParameter.Value

    this.onPremDcService.get('leaveofabsence/FEDEX1/report?groupGuid=' + this.companySelectedGuid).subscribe((data: any) => {
      if (data?.datasets) {
        if (data.data instanceof HttpErrorResponse) {
          this.errorService.setErrorObject(data.data.error as ErrorObject);
          return;
        }


        this.columns = data.datasets[0].columns.map(x => {

          return {
            prop: x.propertyName,
            name: x.propertyName,
            title: x.displayName,
            dataType: x.dbTypeName
          }
        });

        this.rowsRaw = Array.from(data.datasets[0].rows).slice(0, 50);


        const fields: Array<any> = [];
        this.columns.forEach(s => {
          if (s.prop.toLowerCase() === "driverid") {
           //do nothing
          }
          else if (s.prop.toLowerCase() === "employeeid") {
            fields.push({
              name: s.title,
              objectKey: s.prop,
              columnClass: 'clickable',
              dataType: s.dataType,
              click: row => {
                this.openLeaveOfAbsenceDialog(row);
              }
            });
          }
          else
            fields.push({
              name: s.title,
              objectKey: s.prop,
              hidden: ['driverid'].includes(s.prop?.toLowerCase()),
              render: row => {
                // return empty string when value is null
                if (row[s.prop] === null) {
                  return "";
                }
                else if (s.dataType === "DateTime" && row[s.prop]) {
                  try {
                   
                    try {
                      return this.datePipe.transform(row[s.prop], 'MM/dd/yyyy');
                    } catch (e) { }
                  }
                  catch (err) {
                    return row[s.prop];
                  }
                }
                else
                  return row[s.prop]
              },
              value: row => {
                let val = row[s.prop];
                if (s.dataType === "DateTime") {
                  // use ISO string as value for date sorting
                  try {
                    val = new Date(val).toISOString();
                  } catch (e) { }
                }
                return val;
              },              
            });
        });

        const settings = this.columns.map(function (s) {
          return {
            objectKey: s.prop,
            sort: 'enable'
          };
        }).filter(x => x.objectKey.toLowerCase() !== "driverid");

        this.configObject = {
          settings: settings,
          fields: fields,
          data: data.datasets[0].rows,
        };
      }
      this.loadingSpinnerService.hide();
    });

  }

  openLeaveOfAbsenceDialog(row: any) {
    let driverBaseInfo: any = {};
    driverBaseInfo.driverId = row['DriverId'];
    driverBaseInfo.firstName = row['First'];
    driverBaseInfo.middleName = row['Middle'];
    driverBaseInfo.lastName = row['Last'];
    let driverNameLabel = driverBaseInfo.firstName + " " + (driverBaseInfo.middleName && (driverBaseInfo.middleName.length > 0) ? driverBaseInfo.middleName + " " : "") + driverBaseInfo.lastName;
    driverBaseInfo.fullName = driverNameLabel;
    driverBaseInfo.employeeId = row['EmployeeID'];
    driverBaseInfo.fullHierarchy = row['Group'];
    driverBaseInfo.driverLicenseStatus = row['LicStatus'];
    driverBaseInfo.lastMVR = row['LastMvr'];
    driverBaseInfo.driverLicenseState = row['State'];
    driverBaseInfo.leaveOfAbsenceStartDate = row['StartDate'];
    driverBaseInfo.leaveOfAbsenceEndDate = row['ReturnDate'];
    driverBaseInfo.enteredBy = row['EnteredBy'];
    this.dcModalWindowService.notifyModal('Leave of Absence', 'dcDriversOnLeaveDialog', 'auto', 'lg', { driverBaseInfo: driverBaseInfo });
  }

  openModal(data: any): void {
    this.openLeaveOfAbsenceDialog(data?.row);
  }
}




