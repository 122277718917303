<div class="row">
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <h4 class="text-muted" style="font-weight:bold">Driving History Review</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a class="driverProfileLink" (click)="openDriverProfile()">
          {{driverInfo?.fullName}} ({{driverInfo?.employeeId.trim()}})
        </a>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div style="float:right">
      <button type="button"
              (click)="cancel()"
              class="btn btn-primary">
        Exit
      </button>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h5 class="text-muted" style="font-weight:bold">Attachments</h5>
      <app-dq-file-panel *ngIf="drivingHistory&&drivingHistory.fileName&&drivingHistory.documentId"
                         [documentId]="drivingHistory.documentId"
                         [fileName]="drivingHistory.fileName"
                         [uploadDate]="drivingHistory.uploadDate">
      </app-dq-file-panel>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <app-loading-panel *ngIf="!tableConfig"></app-loading-panel>
      <div *ngIf="tableConfig">
        <app-data-table 
          [columns]="tableConfig.fields"
          [data]="tableConfig.data"
          (triggerSubmitAttempt)="submitAttempt($event)"
        ></app-data-table>
      </div>
    </div>
  </div>
</div>

