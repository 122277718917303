<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Incomplete Training</h4>
          <app-loading-panel *ngIf="!driverIncompleteTrainingTableConfig"></app-loading-panel>
          <div *ngIf="driverIncompleteTrainingTableConfig">
            <app-data-table 
              [columns]="driverIncompleteTrainingTableConfig.fields"
              [data]="driverIncompleteTrainingTableConfig.data"
              [rowClassObject]="driverIncompleteTrainingTableConfig.rowClassObject"
              noRecordsLabel="No Incomplete Training"
              (triggerOpenModal)="openModal($event)"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Completed Training</h4>
          <app-loading-panel *ngIf="!driverCompletedTrainingTableConfig"></app-loading-panel>
          <div *ngIf="driverCompletedTrainingTableConfig">
            <app-data-table 
              [columns]="driverCompletedTrainingTableConfig.fields"
              [data]="driverCompletedTrainingTableConfig.data"
              noRecordsLabel="No Completed Training"
              (triggerDownload)="onDownload($event)"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="hasExternalTraining">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Learning Management System Training</h4>
          <app-loading-panel *ngIf="!driverExternalTrainingTableConfig"></app-loading-panel>
          <div *ngIf="driverExternalTrainingTableConfig">
            <app-data-table 
              [columns]="driverExternalTrainingTableConfig.fields"
              [data]="driverExternalTrainingTableConfig.data"
              [rowClassObject]="driverExternalTrainingTableConfig.rowClassObject"
              noRecordsLabel="No External Training"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">HPE Assignment</h4>
          <app-loading-panel *ngIf="!driverHpeTrainingTableConfig"></app-loading-panel>
          <div *ngIf="driverHpeTrainingTableConfig">
            <app-data-table 
              [columns]="driverHpeTrainingTableConfig.fields"
              [data]="driverHpeTrainingTableConfig.data"
              [rowClassObject]="driverHpeTrainingTableConfig.rowClassObject"
              noRecordsLabel="No HPE Training"
              (triggerOpenModal)="openModal($event)"
            ></app-data-table>
          </div>
          <app-loading-panel *ngIf="!driverHpeCoreCompetenciesTableConfig"></app-loading-panel>
          <div *ngIf="driverHpeCoreCompetenciesTableConfig">
            <app-data-table 
              [columns]="driverHpeCoreCompetenciesTableConfig.fields"
              [data]="driverHpeCoreCompetenciesTableConfig.data"
              noRecordsLabel="No HPE Core Competencies"
            ></app-data-table>
          </div>
          <app-loading-panel *ngIf="!driverHpeSubLessonsTableConfig"></app-loading-panel>
          <div *ngIf="driverHpeSubLessonsTableConfig">
            <app-data-table 
              [columns]="driverHpeSubLessonsTableConfig.fields"
              [data]="driverHpeSubLessonsTableConfig.data"
              noRecordsLabel="No HPE Sub Lessons"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">MotorMinds Assignment</h4>
          <app-loading-panel *ngIf="!driverMMTrainingTableConfig"></app-loading-panel>
          <div *ngIf="driverMMTrainingTableConfig">
            <app-data-table 
              [columns]="driverMMTrainingTableConfig.fields"
              [data]="driverMMTrainingTableConfig.data"
              noRecordsLabel="No MotorMinds Training"
              (triggerOpenModal)="openModal($event)"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<!--<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">HPE Core Competency</h4>
          <div>
           
          </div>

        </div>
      </div>
    </div>
  </div>
</div>-->
