<div class="quickSearchContainer">
  <quick-search-menu [searchText]="searchText" [selectedColumnFilter]="selectedColumnFilter" [searchAcrossClients]="searchAcrossClients"></quick-search-menu>
</div>
<div>
  <nav class="navbar navbar-expand-lg navbar-dark dashboardFilterPanelActionBar" *ngIf="!invalidGridData">
    <ul class="nav navbar-nav nav-flex-icons ml-auto">
      <li class="nav-item">
        <a (click)="onPrint()">
          <span class='fa fa-print'></span> Print
        </a>
      </li>
      <li class="nav-item">
        <a (click)="onExcel()">
          <span class='fa fa-file-excel-o'></span> Export to Excel
        </a>
      </li>
      <li class="nav-item">
        <a (click)="onEmail()">
          <span class='fa fa-envelope'></span>
          Export to Email
        </a>
      </li>
    </ul>
  </nav>
  <app-data-grid-export-email [lineOfBusiness]="lineOfBusinessId"></app-data-grid-export-email>

  <H3>Search Criteria: [ {{ searchText }} ]</H3>
  <h4>Search Date: {{ today | date :'short' }}</h4>

  <div *ngIf="rowsRaw?.length > 0">
    <app-data-table 
      [columns]="configObject.fields"
      [data]="configObject.data"
      [showPagination]="true"
      [showPageSize]="true"
      (triggerNavigateDriverProfile)="navigateToDriverProfile($event)"
      (triggerSortChange)="sortChange($event)"
    ></app-data-table>
    <div id="reportFooter">
      <div class="left"> Fleet Response Confidential</div>
      <div class="right"> Report run {{today | date :'short' }}</div>
    </div>
  </div>
  <div *ngIf="!invalidGridData && (!rowsRaw || rowsRaw?.length == 0)">
    <p>No results</p>
  </div>
  <div *ngIf="invalidGridData">
    <p>An unexpected error occurred. Please try your search again.</p>
  </div>
</div>
