<div class="row">
  <div class="col-md-12">
        <mat-accordion multi="true">
          <cdk-accordion-item
            *ngFor="let afInfo of arrAfInfo; let i = index"
            #accordionItem="cdkAccordionItem"
            class="example-accordion-item"
            role="button"
            tabindex="0"
            [attr.id]="'accordion-header-' + index"
            [attr.aria-expanded]="accordionItem.expanded"
            [attr.aria-controls]="'accordion-body-' + index">
            <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
              <button mat-icon-button>
                <mat-icon class="mat-icon-rtl-mirror link-icon">
                  {{accordionItem.expanded ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <span class="link">AT-FAULT PARTY {{i+1}}</span>
            </div>
            <div
              class="example-accordion-item-body"
              role="region"
              [style.display]="accordionItem.expanded ? '' : 'none'">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-12">
                        <mat-card appearance="outlined" class="mt-3">
                          <mat-card-title>Insurance Company</mat-card-title>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Name:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.insuranceCompany}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Carrier Claim Number:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.insuranceCompanyClaimId}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Recovery Source:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.subroRecoverySource}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              At Fault Driver:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.atFault}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Address:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.addressLine1}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              City:
                            </div>
                            <div class="col-sm-3 accidentClaimTabBold">
                              {{afInfo?.city}}
                            </div>
                            <div class="col-sm-1 accidentClaimTabLabel">
                              State:
                            </div>
                            <div class="col-sm-1 accidentClaimTabBold">
                              {{afInfo?.state}}
                            </div>
                            <div class="col-sm-1 accidentClaimTabLabel">
                              Zip:
                            </div>
                            <div class="col-sm-2 accidentClaimTabBold">
                              {{afInfo?.zipCode}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Adjuster:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.adjuster}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Phone:
                            </div>
                            <div class="col-sm-3 accidentClaimTabBold">
                              {{afInfo?.phoneNumber | phoneUs}}
                            </div>
                            <div class="col-sm-2 accidentClaimTabLabel">
                              Ext:
                            </div>
                            <div class="col-sm-3 accidentClaimTabBold">
                              {{afInfo?.phoneNumberExtension}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Fax:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.faxNumber | phoneUs}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Email:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.emailAddress}}
                            </div>
                          </div>
                        </mat-card>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <mat-card appearance="outlined" class=" mt-3">
                          <mat-card-title>Liability Status</mat-card-title>
                          <div class="row ">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Liability Status:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.subroLiabilityStatus}}
                            </div>
                          </div>
                          <div class="row" *ngIf="afInfo?.subroLiabilityStatus">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Percent Accepted:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.liabilityStatusPercent | percent:'1.2-2'}}
                            </div>
                          </div>
                          <div class="row" *ngIf="afInfo?.subroLiabilityStatus">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Reason:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.liabilityStatusReason}}
                            </div>
                          </div>
                        </mat-card>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <mat-card appearance="outlined" class="mt-3">
                          <mat-card-title>Arbitration</mat-card-title>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Filing Date:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.arbFilingDate |  date:'MM/dd/yyyy'}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Hearing Date:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.arbHearingDate |  date:'MM/dd/yyyy'}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Close Date:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.arbCloseDate |  date:'MM/dd/yyyy'}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Docket Number:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.arbDocket}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Amount Requested:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.arbRequestedAmount | currency:'USD':'$':'1.2-2' | negativeToParensPipe}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Amount Collected:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.arbCollectedAmount | currency:'USD':'$':'1.2-2' | negativeToParensPipe}}
                            </div>
                          </div>
                        </mat-card>
                      </div>
                    </div>
                    <div class="row" *ngIf="afInfo?.outsideSubro">
                      <div class="col-md-12">
                        <mat-card appearance="outlined" class="mt-3">
                          <mat-card-title>Outside Agency</mat-card-title>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Name:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.outsideSubro?.agencyName}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Start Date:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.outsideSubro?.startDate |  date:'MM/dd/yyyy'}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Agency Claim Number:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.outsideSubro?.oaClaim}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Close Date:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.outsideSubro?.closeDate |  date:'MM/dd/yyyy'}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              In Suit:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.outsideSubro?.inSuit}}
                            </div>
                          </div>
                        </mat-card>
                      </div>
                    </div>
                    <div class="row" *ngIf="afInfo?.outsideSubro?.inSuit == 'Yes'">
                      <div class="col-md-12">
                        <mat-card appearance="outlined" class="mt-3">
                          <mat-card-title>In Suit</mat-card-title>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Suit Filed:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.outsideSubro?.suitFiledDate |  date:'MM/dd/yyyy'}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4 accidentClaimTabLabel">
                              Date of Judgment:
                            </div>
                            <div class="col-sm-8 accidentClaimTabBold">
                              {{afInfo?.outsideSubro?.judgmentDate |  date:'MM/dd/yyyy'}}
                            </div>
                          </div>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </cdk-accordion-item>
        </mat-accordion>
  </div>
</div>
