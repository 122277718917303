//angular imports
import { Component, OnInit, OnDestroy, EventEmitter, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UntypedFormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
//thirdparty libraries
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
//angular imports
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { DriverProfile } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-abstract-review',
  templateUrl: './abstract-review.component.html',
  styleUrls: ['./abstract-review.component.scss']
})

export class AbstractReviewComponent implements OnInit, OnDestroy, AfterViewInit {
  formData: FormData;
  files: File[];
  private routeSub: Subscription;
  private driverId: string;
  private gridId: string;
  private abstractReviewId: string;
  abstractReviewInstructions: string = '';
  abstractReview: AbstractReviewDetails;
  driverInfo: DriverProfile;
  dateObtainedFromState: string;
  licenseStatuses: Array<any>;
  licenseStatusIsValid: boolean = false;
  licenseStatusString: string;
  notifyDriver: boolean = false;
  requiresReview: boolean = false;
  note: string;
  arrFileName: Array<string> = [];
  actualDate = new Date();
  disableSince: Date;
  @ViewChild('dateObtainedFromStatePicker') dateObtainedFromStatePicker: MatDatepicker<Date>;

  formControlExpirationDate: UntypedFormControl = new UntypedFormControl("", [Validators.required]);

  dateObtainedFromStateControl: UntypedFormControl = new UntypedFormControl("", [Validators.required]);

  today = new Date();

  private dtToday: Date = new Date((new Date()).toDateString());

  constructor(private router: Router,
    private route: ActivatedRoute,
    private onPremService: OnPremDriverService,
    private lineOfBusinessService: LineOfBusinessService,
    private driverHistoryProfileService: DriverHistoryProfileService,
    private datePipe: DatePipe,
    private cdRef: ChangeDetectorRef,
    private loadingSpinnerService: LoadingSpinnerService,
    private snackbar: MatSnackBar,
    private errorService: ErrorModalService) {
    this.files = [];
  }

  ngOnInit() {
    //make sure LOB=2
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    this.routeSub = this.route.params.subscribe(params => {
      this.driverId = params['driverId'];
      this.gridId = params['gridId'];
      this.abstractReviewId = params['abstractReviewId'];
      this.initAll(true);
    });
    this.disableSince = new Date(this.dtToday.getFullYear(), this.dtToday.getMonth(), this.dtToday.getDate());
  }

  ngAfterViewInit(): void {
    this.dateObtainedFromStatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.dateObtainedFromStatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
          this.dateObtainedFromStatePicker.select(event.value);
          this.dateObtainedFromStatePicker.close();
        })
      }, 0)
    })
  }

  private initAll(updateSubmitInfo: boolean) {
    let endpoint = "abstractreview/" + this.driverId + "/" + this.abstractReviewId;

    if (endpoint.length > 0) {
      this.loadingSpinnerService.show();
      this.onPremService.get(endpoint).subscribe({
        next: (data) => {
          this.abstractReview = data as AbstractReviewDetails;

          if (!this.abstractReview.documentId) {
            this.returnToListing();
          } else {
            this.arrFileName = [this.abstractReview.fileName];

            this.getDriverInfo(this.driverId);
            this.getInstructions();
            this.getLicenseStatuses();
          }

          if (this.abstractReview.dateObtained) {
            this.dateObtainedFromState = new DatePipe("en-US").transform(this.abstractReview.dateObtained, 'MM/dd/yyyy');
          }

          this.loadingSpinnerService.hide();

        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    } else {
      this.errorService.setErrorObject({ message: 'Invalid Parameters' });
    }
  }

  private getDriverInfo(driverId: string) {
    this.driverHistoryProfileService.getDriverHistoryProfileBaseData(driverId).subscribe({
      next: (data) => {
        this.driverInfo = data as DriverProfile;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private getInstructions() {
    this.onPremService.get(`abstractreview/${this.driverId}/instructions`).subscribe({
      next: (data) => {
        this.abstractReviewInstructions = data as string;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    })
  }

  private getLicenseStatuses() {
    this.onPremService.get("lookup/mvrlicensestatuses").subscribe({
      next: (data) => {
        const tempin = data as Array<LicenseStatus>;
        const tempout: Array<LicenseStatusSelectItem> = [];
        tempin.forEach((item: LicenseStatus, index: number) => {
          const tempobj = {
            value: item.description,
            label: item.description,
            isValid: item.isValid
          };
          tempout.push(tempobj);
        })

        this.licenseStatuses = tempout;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    })
  }

  openDriverProfile() {
    this.router.navigate(["/driver/history-profile/" + this.driverId]);
  }

  onLicenseStatusSelect() {
    //console.log(this.licenseStatusString);
  }

  submit() {
    this.errorService.clearErrorObject();

    if (!this.licenseStatusString || this.licenseStatusString == "") {
      this.errorService.setErrorObject({ message: "License Status is required" });
      return;
    }
    if (this.requiresReview && (!this.note || this.note == "")) {
      this.errorService.setErrorObject({ message: "Review notes are required if the MVR needs further review by client" });
      return;
    }

    let abstractReviewSubmission = {
      licenseStatus: this.licenseStatusString,
      dateObtained: this.dateObtainedFromState,
      notifyDriver: this.notifyDriver,
      requiresClientReview: this.requiresReview,
      reviewNotes: this.note
    };

    console.log("submit", abstractReviewSubmission);

    let endpoint = "abstractreview/" + this.driverId.toString() + "/" + this.abstractReviewId.toString() + "/submit"
    let postrequest = this.onPremService.post(endpoint, JSON.stringify(abstractReviewSubmission));

    this.loadingSpinnerService.show();
    postrequest.subscribe({
      next: (data) => {
        this.snackbar.open('Review Complete', 'Ok', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 55000,
          panelClass: 'success-snackbar'
        })

        this.loadingSpinnerService.hide();

        this.returnToListing();
      },
      error: (err: HttpErrorResponse) => {
        console.log("error", err);

        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });


  }

  cancel() {
    this.returnToListing();
  }

  returnToListing() {
    if (this.gridId) {
      this.router.navigate(['/safety/abstractreviewgrid/' + this.gridId]);
    }
    else {
      this.router.navigate(['/safety/abstractreviewgrid']);
    }
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

}

export interface AbstractReviewDetails {
  abstractReviewId: number;
  documentId: number;
  fileName: string;
  dateObtained: string;
  uploadDate: string;
  uploadUserId: number;
}

export interface LicenseStatus {
  id: number;
  secondaryId: number;
  description: string;
  secondaryCode: string;
  isValid: boolean;
}

export interface LicenseStatusSelectItem {
  value: string;
  label: string;
  isValid: boolean;
}