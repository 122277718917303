import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot, Params, OutletContext } from "@angular/router";
import { ComponentRef, Injectable } from "@angular/core";

interface RouteStorageObject {
  path: string;
  params: Params;
  snapshot: ActivatedRouteSnapshot;
  handle: DetachedRouteHandle;
}

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
  storedRoutes: Array<RouteStorageObject> = [];
  // for temporarily preventing a route from being stored
  private doNotStore: boolean = false;

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    let doDetach = false;
    if (route.data.shouldReuse) {
      // skip detach if doNotStore was set
      if (this.doNotStore) {
        doDetach = false;
      } else {
        doDetach = true;
      }
    }
    // clear doNotStore
    this.doNotStore = false;

    return doDetach;
  }

  /**
   * Constructs object of type `RouteStorageObject` to store, and then stores it for later attachment
   * @param route This is stored for later comparison to requested routes, see `this.shouldAttach`
   * @param handle Later to be retrieved by this.retrieve, and offered up to whatever controller is using this class
   */
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (route.data["clearStoredRoutes"] || null) {
      // if null param, remove all stored routes
      this.storedRoutes.forEach(r => {
        let handle = r.handle || null;
        if (handle) {
          let comp = handle["componentRef"] as ComponentRef<any> || null;
          if (comp) {
            comp.destroy();
          }
        }

      });
      this.storedRoutes = [];

      if (route.data["doNotStoreThisRoute"] || null) {
        // set doNotStore to true to prevent next route from being stored
        this.doNotStore = true;
      }

    } else {
      if (route.data.shouldReuse) {
        let storedRoute: RouteStorageObject = {
          path: route.routeConfig.path,
          snapshot: route,
          handle: handle,
          params: route.params
        };

        // remove if already in array
        //let index = this.storedRoutes.findIndex(r => this.checkRouteMatches(route, r));
        // allow only one stored route per path - forces reload if user goes to a new line of business
        let index = this.storedRoutes.findIndex(r => r.path == route.routeConfig.path);
        if (index >= 0) {
          let removedRoute = this.storedRoutes.splice(index, 1)[0];
          //this.deactivateOutlet(removedRoute.handle);
        }

        this.storedRoutes.push(storedRoute);
        //console.log("store:", storedRoute, "into: ", this.storedRoutes);
      }
    }

  }

  private deactivateOutlet(handle: DetachedRouteHandle): void {
    let contexts: Map<string, OutletContext> = handle['contexts'];
    contexts.forEach((context: OutletContext, key: string) => {
      if (context.outlet) {
        // Destroy the component
        context.outlet.deactivate();
        // Destroy the contexts for all the outlets that were in the component
        context.children.onOutletDeactivated();
      }
    });
  }

  /**
   * Determines whether or not there is a stored route and, if there is, whether or not it should be rendered in place of requested route
   * @param route The route the user requested
   * @returns boolean indicating whether or not to render the stored route
   */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (route.data.shouldReuse) {
      // this will be true if the route has been stored before
      // and is not a force reload
      let canAttach: boolean = (!!route.routeConfig) && (!this.isForceReload(route)) && (!!this.getStoredRoute(route));

      return canAttach;
    }
  }

  private isForceReload(route: ActivatedRouteSnapshot): boolean {
    if (route.queryParams && route.queryParams['forceReload']) {
      // return true if forceReload param is present
      return true;
    } else {
      return false;
    }
  }

  private getStoredRoute(route: ActivatedRouteSnapshot): RouteStorageObject {
    let r1: Array<RouteStorageObject> = this.storedRoutes.filter(r => this.checkRouteMatches(route, r));

    if (r1.length > 0) {
      return r1[0];
    } else {
      return null;
    }
  }

  private checkRouteMatches(route: ActivatedRouteSnapshot, savedRoute: RouteStorageObject): boolean {
    let routeMatches: boolean = false;

    // first check that paths match
    if (route.routeConfig.path == savedRoute.path) {
      // return true if no params in stored Route or matched route
      if (((!savedRoute.params) || (Object.keys(savedRoute.params).length <= 0)) && ((!route.params) || (Object.keys(route.params).length <= 0))) {
        return true;
      }

      // return false if any mismatch in number of params
      if (savedRoute.params && !route.params) {
        return false;
      }
      if (route.params && !savedRoute.params) {
        return false;
      }
      if (Object.keys(savedRoute.params).length != Object.keys(route.params).length) {
        return false;
      }

      // for each param, compare values in stored route and matched route
      let arrParamsMatch = [];
      Object.keys(savedRoute.params).forEach(p => {
        arrParamsMatch.push(((route.params[p]) && (route.params[p] == savedRoute.params[p])))
      })

      if (arrParamsMatch.length <= 0) {
        return false;
      }

      // return true if all params match
      return arrParamsMatch.reduce((allMatch, m) => allMatch && m);
    }

    return routeMatches
  }

  /** 
   * Finds the locally stored instance of the requested route, if it exists, and returns it
   * @param route New route the user has requested
   * @returns DetachedRouteHandle object which can be used to render the component
   */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {

    // return null if the path does not have a routerConfig OR if there is no stored route for that routerConfig
    if (!route.routeConfig) return null;
    let sRoute = this.getStoredRoute(route);
    if (!sRoute) return null;

    /** returns handle when the route.routeConfig.path is already stored */
    return sRoute.handle;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.data.shouldReuse || false;
  }

}
