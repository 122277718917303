//Angular
import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
//Third Party
//APP
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { UserRightsService } from '../../components/user-rights-service/user-rights-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { DcDriverProfileService } from '../dc-driver-profile/dc-driver-profile-service.component';
import { DcModalWindowService } from '../dc-modal-window/dc-modal-window-service.component';
import { UserRightsInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'dc-drivers-on-leave-dialog',
  templateUrl: './dc-drivers-on-leave-dialog.component.html',
  styleUrls: ['./dc-drivers-on-leave-dialog.component.scss']
})
export class DcDriversOnLeaveDialogComponent implements OnInit {
  @Input() modalHeight: string;
  @Input() modalInput: any;
  clientSelectedArray: Array<string>;
  clientIdSelectedArray: Array<number>;
  lineOfBusinessId: number;
  baseUrl: string;
  private driverId: string;
  driverBaseInfo: any;
  _userRights: Array<UserRightsInfo>;
  loaNotFound: boolean = false;
  canTerminateDriver: boolean = false;
  isActiveLeave: boolean = false;
  // date vars for yesterday and today, with time stripped out
  // correct values are set in constructor
  private dtYesterday: Date = new Date((new Date()).toDateString());
  private dtTomorrow: Date = new Date((new Date()).toDateString());

  constructor(
    private loadingSpinnerService: LoadingSpinnerService,
    private clientSelectionService: ClientSelectionService,
    private lineOfBusinessService: LineOfBusinessService,
    private userRightsService: UserRightsService,
    private errorService: ErrorModalService,
    private dcProfileService: DcDriverProfileService,
    private dcModalWindowService: DcModalWindowService,
    private datepipe: DatePipe) {
    this.dtYesterday.setDate(this.dtYesterday.getDate() - 1);
    this.dtTomorrow.setDate(this.dtTomorrow.getDate() + 1);
  }

  ngOnInit(): void {
    // set line of business to safety if not already initialized to Safety
    this.lineOfBusinessId = 7;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 7) {
      this.lineOfBusinessService.setLineOfBusiness(7);
    }

    // get the selected client(s)
    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusinessId);

    this.initAll();

  }

  private initAll() {
    this.loadingSpinnerService.show();
    this.userRightsService.getUserRights(this.lineOfBusinessId, this.clientSelectedArray).subscribe({
      next: (data) => {
        this._userRights = data as Array<UserRightsInfo>;
        this.driverBaseInfo = this.modalInput.driverBaseInfo;

        let loaStartDate = this.driverBaseInfo.leaveOfAbsenceStartDate;
        if (loaStartDate && (loaStartDate.length > 0)) {
          let startDate = new Date(loaStartDate);
          this.isActiveLeave = (startDate) < this.dtTomorrow;
          // format start date since it is read only
          this.driverBaseInfo.leaveOfAbsenceStartDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
        }

        this.setViewRights();
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

  }

  // notifies the modal of changes
  notifyModal(title: string, message: string, modalHeight: string, modalWidth: string) {
    this.dcProfileService.notifyModal(title, message, modalHeight, modalWidth);
  }

  editLeave() {
    let modalInput = {
      fromLOAReport: true,
      startDateReadOnly: true,
      driverId: this.driverBaseInfo.driverId,
      leaveOfAbsenceBeginDate: this.driverBaseInfo.leaveOfAbsenceStartDate,
      leaveOfAbsenceReturnDate: this.driverBaseInfo.leaveOfAbsenceEndDate,
      client: this.driverBaseInfo.clientCode
    };
    this.dcProfileService.notifyModal('Leave of Absence', 'dcLeaveOfAbsence', '420px;', 'md', modalInput);
  }

  cancelLeave(dialogLabel: string) {
    // cancel future leave
    let modalInput = {
      fromLOAReport: true,
      driverId: this.driverBaseInfo.driverId,
      client: this.driverBaseInfo.clientCode
    };
    this.dcProfileService.notifyModal(dialogLabel, 'dcCancelLeaveOfAbsence', '180px;', 'sm', modalInput);
  }

  endLeave(dialogLabel: string) {
    // end active leave
    let modalInput = {
      fromLOAReport: true,
      driverId: this.driverBaseInfo.driverId,
      client: this.driverBaseInfo.clientCode
    };
    this.dcProfileService.notifyModal(dialogLabel, 'dcEndLeaveOfAbsence', '180px;', 'sm', modalInput);
  }

  terminateDriver() {
    // end active leave
    let modalInput = {
      fromLOAReport: true,
      driverId: this.driverBaseInfo.driverId,
      client: this.driverBaseInfo.clientCode
    };
    this.dcProfileService.notifyModal('Terminate Driver', 'dcTerminateDriver', '180px;', 'sm', modalInput);
  }

  private setViewRights() {
    // set the view rights booleans according to whats in user rights
    this.canTerminateDriver = this.isAccessAllowed(54);
  }

  private isAccessAllowed(userRightsId: number) {
    let retVal: boolean = false;

    if (this._userRights) {
      let thisRight = this._userRights.filter(r => r.userRightId == userRightsId);
      if ((thisRight.length > 0) && (thisRight[0].permission == 'allow')) {
        retVal = true;
      }
    }

    return retVal;
  }

  cancel() {
    // notify the modal window to close
    this.dcModalWindowService.notifyModalClose();
  }

}
