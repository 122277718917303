import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ClientSelectionService } from '../client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from '../line-of-business-service/line-of-business-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
//import { ToastService } from '../../typescripts/pro/alerts';
import { DashboardService } from '../dashboard-service/dashboard-service.component';
import { ActivatedRoute, Router, NavigationEnd, RouteReuseStrategy } from '@angular/router';
import { ClientSelectionObject } from "../classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../error-modal/error-modal-service.component";

@Component({
  selector: 'client-selector',
  templateUrl: './client-selector.component.html',
  styleUrls: [
    "./checkboxlist.base.css"
    /*
    "./checkboxlist.dark.css",
    "./checkboxlist.light.css"
    */
  ]
})
export class ClientSelectorComponent implements OnInit, OnDestroy {
  // set up arrays of arrays of client choice/chosen info for each LOB
  // index 0 will always be enpty, 1 thru 5 correspond to lines of business
  clientChoices: Array<Array<ClientSelectionObject>> = new Array<Array<ClientSelectionObject>>(6).fill([]);
  // initial selections which persist when navigating lines of business
  clientShortNamesSaved: Array<Array<string>> = new Array<Array<string>>(6).fill([]);
  clientIdsSaved: Array<Array<number>> = new Array<Array<number>>(6);
  // selections for currectly selected line of business
  clientShortNamesChosen: Array<Array<string>> = new Array<Array<string>>(6).fill([]);
  clientIdsChosen: Array<Array<number>> = new Array<Array<number>>(6);

  // client choices array for currently selected LOB
  clientChoicesForLOB: Array<ClientSelectionObject>;
  // client choices array for currently selected LOB
  clientShortNamesChosenForLOB: Array<string>;

  @Input() lineOfBusinessSelected: number;
  //lineOfBusinessSubscription: Subscription;
  //dashboardTilesLoadingSubscription: Subscription;
  addClientByShortNameSubscription: Subscription;
  //dashboardTilesLoading: boolean = false;
  //maskClientChoices: boolean = false;
  currentUrl: string;
  showSingleSelect: boolean = false;
  firstClientSelected: Array<boolean> = new Array<boolean>(6).fill(false);
  firstClientSelectedForLOB: boolean;

  constructor(
    private clientSelectionService: ClientSelectionService,
    private lineOfBusinessService: LineOfBusinessService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private router: Router,
    //private toastrService: ToastService,
    private errorService: ErrorModalService,
    private readonly routeReuse: RouteReuseStrategy
  ) {

    // subscribe to changes in Line of Business
    /*
    this.lineOfBusinessSubscription = this.lineOfBusinessService.lineOfBusinessSelected$.subscribe(
      lineOfBusinessSelected => {
        this.lineOfBusinessSelected = lineOfBusinessSelected as number;
        if (this.lineOfBusinessSelected && (this.lineOfBusinessSelected > 0)) {
          let newClientInfo = this.clientSelectionService.getAddClientsByShortName(this.lineOfBusinessSelected);
          this.getClientChoices(this.lineOfBusinessSelected, newClientInfo);
        }

      }
    );
    */

    // subscribe to attempts by other modules to add client selection
    this.addClientByShortNameSubscription = this.clientSelectionService.addClientsByShortName$.subscribe(
      info => {
        let newClientInfo = info as any;

        this.processAddClient(newClientInfo);

      }
    );

    router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.urlAfterRedirects;
        //this.dashboardTilesLoading = false;
      }
    });
  }

  ngOnInit(): void {
    //this.lineOfBusinessSelected = this.lineOfBusinessService.getLineOfBusinessValue();
    if (this.lineOfBusinessSelected && (this.lineOfBusinessSelected > 0)) {
      switch (this.lineOfBusinessSelected) {
        case 2:
          this.showSingleSelect = true;
          break;
        default:
          this.showSingleSelect = false;
      }
      let newClientInfo = this.clientSelectionService.getAddClientsByShortName(this.lineOfBusinessSelected);
      this.getClientChoices(this.lineOfBusinessSelected, newClientInfo);
    }
  }

  ngOnDestroy() {
    //this.lineOfBusinessSubscription.unsubscribe();
    //this.dashboardTilesLoadingSubscription.unsubscribe();
    this.addClientByShortNameSubscription.unsubscribe();
  }

  private processAddClient(newClientInfo: any) {
    let isNewClientInfoValid: boolean = (newClientInfo && newClientInfo.lineOfBusinessId && newClientInfo.arrClientShortName && (newClientInfo.arrClientShortName.length > 0));
    if (isNewClientInfoValid && (this.lineOfBusinessSelected == newClientInfo.lineOfBusinessId)) {
      if (this.clientChoices[newClientInfo.lineOfBusinessId].length > 0) {
        // choices exist for this line of business, add new selection
        this.processAddClientsByShortName(newClientInfo);
      } else {
        // initialize all choices, this.newClientInfo will be added as part of getClientChoices
        this.getClientChoices(newClientInfo.lineOfBusinessId, newClientInfo);
      }
      // reset add clients to empty
      this.clientSelectionService.setAddClientsByShortName([], this.lineOfBusinessSelected);
    }
  }

  private isClientChosen(thisName: string, LineOfBusinessId: number) {
    // return true if member of saved short names
    return this.clientShortNamesSaved[LineOfBusinessId].indexOf(thisName) >= 0;
  }

  private isSingleChoice() {
    return this.clientChoices.length <= 1;
  }

  private getClientChoices(LineOfBusinessId: number, newClientInfo: any) {
    this.clientSelectionService.getClientChoices(LineOfBusinessId).subscribe({
      next: (data) => {
        this.clientChoices[LineOfBusinessId] = data as Array<ClientSelectionObject>;
        this.clientSelectionService.setClientChoicesInfo(LineOfBusinessId, this.clientChoices[LineOfBusinessId]);

        // get saved client info
        this.clientShortNamesSaved[LineOfBusinessId] = this.clientSelectionService.getSavedClientShortNames(LineOfBusinessId);
        this.clientIdsSaved[LineOfBusinessId] = this.clientSelectionService.getSavedClientIds(LineOfBusinessId);

        if (this.clientShortNamesSaved[LineOfBusinessId].length > 0) {
          // check choices that match saved clients
          this.clientChoices[LineOfBusinessId].forEach((element) => {
            element.checked = this.isClientChosen(element.clientShortName, LineOfBusinessId);
          });

          if (this.clientChoices[LineOfBusinessId].find(element => element.checked)) {
            if (newClientInfo && newClientInfo.arrClientShortName && newClientInfo.arrClientShortName.length > 0) {
              // attempt to add client has not yet processed -
              // add selection then process
              this.processAddClientsByShortName(newClientInfo);
            } else {
              this.showSingleSelect ? this.processClientSelectionSingleSelect(this.clientIdsSaved[LineOfBusinessId][0], LineOfBusinessId, false, true) : this.processClientSelection(LineOfBusinessId, false, true);
            }
          } else {
            // if no choices are checked, select first client
            this.selectFirstClient(LineOfBusinessId, newClientInfo);
          }

        } else {
          // if no saved client info, select first client in array
          this.selectFirstClient(LineOfBusinessId, newClientInfo);
        }

      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
      }
    })
  }

  private selectFirstClient(LineOfBusinessId: number, newClientInfo: any) {
    if (this.clientChoices[LineOfBusinessId].length > 0) {
      this.clientChoices[LineOfBusinessId][0].checked = true;
      this.clientShortNamesChosen[LineOfBusinessId] = [this.clientChoices[LineOfBusinessId][0].clientShortName];
      this.clientIdsChosen[LineOfBusinessId] = [this.clientChoices[LineOfBusinessId][0].clientId];
      if (newClientInfo && newClientInfo.arrClientShortName && newClientInfo.arrClientShortName.length > 0) {
        // attempt to add client has not yet processed -
        // add selection then process
        this.processAddClientsByShortName(newClientInfo);
      } else {
        this.showSingleSelect ? this.processClientSelectionSingleSelect(this.clientIdsChosen[LineOfBusinessId][0], LineOfBusinessId, true, true) : this.processClientSelection(LineOfBusinessId, true, true);
      }
    }
  }

  private initClientSelectionByLOBInfo() {
    if (this.lineOfBusinessSelected && (this.lineOfBusinessSelected > 0)) {
      switch (this.lineOfBusinessSelected) {
        case 2:
          this.showSingleSelect = true;
          break;
        default:
          this.showSingleSelect = false;
      }
      this.clientChoicesForLOB = this.clientChoices[this.lineOfBusinessSelected];
      this.clientShortNamesChosenForLOB = this.clientShortNamesChosen[this.lineOfBusinessSelected];
      this.firstClientSelectedForLOB = this.firstClientSelected[this.lineOfBusinessSelected];
    }
  }

  processClientSelection(lineOfBusinessSelected: number, updateSavedChoices: boolean, navigateOnClientChange: boolean) {
    this.clientShortNamesChosen[lineOfBusinessSelected] = this.clientChoices[lineOfBusinessSelected]
      .filter(opt => opt.checked)
      .map(opt => opt.clientShortName);
    this.clientIdsChosen[lineOfBusinessSelected] = this.clientChoices[lineOfBusinessSelected]
      .filter(opt => opt.checked)
      .map(opt => opt.clientId);
    if ((this.clientShortNamesChosen[lineOfBusinessSelected].length <= 0) || (this.clientIdsChosen[lineOfBusinessSelected].length <= 0)) {
      // choose first choice in client list if choice doesnt match anything in the client choice list
      this.clientShortNamesChosen[lineOfBusinessSelected] = [this.clientChoices[lineOfBusinessSelected][0].clientShortName];
      this.clientIdsChosen[lineOfBusinessSelected] = [this.clientChoices[lineOfBusinessSelected][0].clientId];
      this.clientChoices[lineOfBusinessSelected][0].checked = true;
    }
    // if first client choice is the only one selected, set 
    // firstClientSelected so user can't unselect that client
    this.firstClientSelected[lineOfBusinessSelected] = ((this.clientShortNamesChosen[lineOfBusinessSelected].length == 1) && (this.clientChoices[lineOfBusinessSelected][0].checked == true) ? true : false);

    this.initClientSelectionByLOBInfo();

    this.setLogo(this.clientChoices[lineOfBusinessSelected].filter(c => c.checked));

    if (updateSavedChoices) {
      // updateSavedChoices will be true if user is selecting clients from navigator
      // if true, record selections in localStorage 
      this.clientShortNamesSaved[lineOfBusinessSelected] = Object.assign([], this.clientShortNamesChosen[lineOfBusinessSelected]);
      this.clientIdsSaved[lineOfBusinessSelected] = Object.assign([], this.clientIdsChosen[lineOfBusinessSelected]);
      this.clientSelectionService.setSavedClientShortNames(this.clientShortNamesSaved[lineOfBusinessSelected], lineOfBusinessSelected);
      this.clientSelectionService.setSavedClientIds(this.clientIdsSaved[lineOfBusinessSelected], lineOfBusinessSelected);

      if (navigateOnClientChange) {
        // clear stored routes if changing clients
        let ss = this.route.snapshot;
        ss.data["clearStoredRoutes"] = true;
        ss.data["doNotStoreThisRoute"] = true;
        this.routeReuse.store(ss, null);
        //this.router.routeReuseStrategy.store(null, null);
        if ((window.location.href.indexOf('statcenter') < 0) && (window.location.href.indexOf('home') < 0)
          && this.lineOfBusinessSelected != 6) {
          // navigate back to statcenter if changing clients while not in a dashboard
          this.router.navigate(['/statcenter', this.lineOfBusinessSelected.toString()]);
        }
      }
    }
    this.clientSelectionService.selectClients(this.clientShortNamesChosen[lineOfBusinessSelected], this.clientIdsChosen[lineOfBusinessSelected], lineOfBusinessSelected);
  }

  processClientSelectionSingleSelect(clientId: number, lineOfBusinessSelected: number, updateSavedChoices: boolean, navigateOnClientChange: boolean) {
    let clientsChosen = this.clientChoices[lineOfBusinessSelected].filter(opt => opt.clientId === clientId);

    if (clientsChosen.length <= 0) {
      // choose first choice in client list if choice doesnt match anything in the client choice list
      clientsChosen = [this.clientChoices[lineOfBusinessSelected][0]];
    }

    this.clientShortNamesChosen[lineOfBusinessSelected] = clientsChosen.map(opt => opt.clientShortName);
    this.clientIdsChosen[lineOfBusinessSelected] = clientsChosen.map(opt => opt.clientId);

    this.initClientSelectionByLOBInfo();

    this.setLogo(clientsChosen);

    if (updateSavedChoices) {
      // updateSavedChoices will be true if user is selecting clients from navigator
      // if true, record selections in localStorage 
      this.clientShortNamesSaved[lineOfBusinessSelected] = Object.assign([], this.clientShortNamesChosen[lineOfBusinessSelected]);
      this.clientIdsSaved[lineOfBusinessSelected] = Object.assign([], this.clientIdsChosen[lineOfBusinessSelected]);
      this.clientSelectionService.setSavedClientShortNames(this.clientShortNamesSaved[lineOfBusinessSelected], lineOfBusinessSelected);
      this.clientSelectionService.setSavedClientIds(this.clientIdsSaved[lineOfBusinessSelected], lineOfBusinessSelected);

      if (navigateOnClientChange) {
        // clear stored routes if changing clients
        let ss = this.route.snapshot;
        ss.data["clearStoredRoutes"] = true;
        ss.data["doNotStoreThisRoute"] = true;
        this.routeReuse.store(ss, null);
        //this.router.routeReuseStrategy.store(null, null);
        if ((window.location.href.indexOf('statcenter') < 0) && (window.location.href.indexOf('home') < 0) && this.lineOfBusinessSelected != 6) {
          // navigate back to statcenter if changing clients while not in a dashboard
          this.router.navigate(['/statcenter', this.lineOfBusinessSelected.toString()]);
        }
      }
    }
    this.clientSelectionService.selectClients(this.clientShortNamesChosen[lineOfBusinessSelected], this.clientIdsChosen[lineOfBusinessSelected], lineOfBusinessSelected);
  }

  private processAddClientsByShortName(newClientInfo: any) {
    let lobNew = newClientInfo.lineOfBusinessId as number;
    let arrClientNew = newClientInfo.arrClientShortName as Array<string>;
    let updateSelections: boolean = false;
    // for single select
    let clientIdNew: number = null;
    if (newClientInfo && this.clientChoices[lobNew] && this.clientShortNamesChosen[lobNew] && (lobNew == this.lineOfBusinessSelected)) {
      if (this.showSingleSelect) {
        // if new selection doesnt match current, check that its in
        // the list of choices, then select it
        if (arrClientNew[0] != this.clientShortNamesChosen[lobNew][0]) {
          let arrMatching = this.clientChoices[lobNew].filter(c => (c.clientShortName == arrClientNew[0]));
          if (arrMatching.length > 0) {
            updateSelections = true;
            clientIdNew = arrMatching[0].clientId;
          }
        }
      } else {
        arrClientNew.forEach(newClient => {
          // check that client is in list of choices and not selected
          let arrMatching = this.clientChoices[lobNew].filter(c => ((c.clientShortName == newClient) && (!c.checked)));
          if (arrMatching.length > 0) {
            updateSelections = true;
            arrMatching.forEach(m => {
              m.checked = true;
            });
          }
        })
      }
      if (updateSelections) {
        if (this.showSingleSelect) {
          this.processClientSelectionSingleSelect(clientIdNew, lobNew, true, false);
        } else {
          this.processClientSelection(lobNew, true, false);
        }
      }
    }
  }

  private setLogo(clientsSelected: Array<ClientSelectionObject>) {
    // if all clients selected have a logoFileName, send that
    // to client selection service to show that logo at top
    // of left nav. otherwise send blank string to indicate default logo
    let arrLogo = clientsSelected.map(c => c.logoFileName);
    let arrLogoUnique = Array.from(new Set(arrLogo));
    let logoSrc = ((arrLogoUnique.length == 1) && arrLogoUnique[0] ? arrLogoUnique[0] : '');
    this.clientSelectionService.setClientLogoSrc(logoSrc);
  }
}
