import { Component, OnInit, Input, Inject } from '@angular/core';
import { SafetyModalWindowService } from '../safety-modal-window/safety-modal-window-service.component';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { DataGridService } from '../../components/data-grid/data-grid.service';
import { DHPConfirmActionService } from '../dhp-confirm-action/dhp-confirm-action-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'cdl-verification-modal',
  templateUrl: './cdl-verification-modal.component.html',
  styleUrls: ['./cdl-verification-modal.component.css']
})
export class CDLVerificationModalComponent implements OnInit {
  @Input() modalHeight: string;
  @Input() modalInput: any;
  baseUrl: string;
  driverBaseInfo: any;

  gtOptions: any = {};
  public tableConfig: any;

  constructor(private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly errorService: ErrorModalService,
    private readonly dhpConfirmActionService: DHPConfirmActionService,
    private readonly safetyModalWindowService: SafetyModalWindowService,
    protected fileDownloadService: FileDownloadService,
    private readonly dataGridService: DataGridService,
    private snackBar: MatSnackBar,
    private readonly datePipe: DatePipe,
    @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  private initAll() {
    this.loadingSpinnerService.show();

    this.driverBaseInfo = this.modalInput.driverBaseInfo;
    this.loadingSpinnerService.hide();
    this.setTableConfig();
  }

  private setTableConfig() {
    const columns = [
      { name: "Date", prop: "dateUploaded", title: "Date", dataType: 'date' },
      { name: "Document", prop: "documentDescription", title: "Document", dataType: null },
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: ''
      };
    });

    const fields = [];

    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s?.dataType,
        render: row => {
          let r = "";
          if (s.dataType === "date" && row[s.prop]) {
            try {
              return this.datePipe.transform(new Date(row[s.prop]), "MM/dd/yyyy");
            }
            catch (err) {
              return row[s.prop];
            }
          }

          if (s.prop === "documentDescription" && row[s.prop].length > 0) {
            r = `<a style="color:#d23138;""><u>${row[s.prop]}</u></a>`;
          } else {
            r = row[s.prop];
          }

          return r;
        },
        value: row => {
          return row[s.prop];
        },
        click: row => {
          if (s.prop === "documentDescription") {
            return this.downloadDoc(row["documentId"]);
          }
          return null;
        }
      });
    });

    this.tableConfig = {
      settings: settings,
      fields: fields,
      data: [{
        documentId: this.driverBaseInfo.documentId,
        documentDescription: this.driverBaseInfo.documentDescription,
        dateUploaded: this.driverBaseInfo.dateUploaded
      }]
    }
  }

  ngOnInit(): void {
    this.initAll();
  }

  cancel() {
    // notify the modal window to close
    this.safetyModalWindowService.notifyModalClose();
  }

  downloadDoc(docId: number) {
    this.fileDownloadService
      .saveFile(`${this.baseUrl}api/Safety/GetSafetyFile?DocumentId=${docId.toString()}`);
  }


  reviewDoc(docId: string) {
    this.loadingSpinnerService.show();

    this.dhpConfirmActionService.verifyDocument(docId).subscribe({
      next: (data) => {
        this.dataGridService.setRefreshGrid(true);
        this.safetyModalWindowService.notifyModalClose();
        this.snackBar.open('CA DL-414 Validation Completed', 'DL-414 Status', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5000,
          panelClass: 'success-snackbar'
        })
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  openModal(data: any): void {
    const row = data?.row || null;
    switch (data?.type) {
      case 'downloadDocument':
        this.downloadDoc(row?.driverDocumentId);
        break;
      case 'reviewDocument':
        this.reviewDoc(data.row["driverDocumentId"]);
        break;
      default:
        break;
    }
  }
}
