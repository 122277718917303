import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OnPremService } from './on-prem-service.component';

@Injectable()
export class OnPremDcService {
  private service: string = 'DeliveryContractor/';


  constructor(private onPremService: OnPremService) {

  }


  delete(endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object> {
    return this.onPremService.delete(this.service, endpoint, options);
  }

  get(endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object>;
  get<T>(endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<T>;

  get<T>(endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<T> {
    return this.onPremService.get<T>(this.service, endpoint, options);
  }

  getFile(endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<HttpResponse<Blob>> {
    return this.onPremService.getFile(this.service, endpoint, options);
  }

  post(endpoint: string, body: any | null, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object> {
    return this.onPremService.post(this.service, endpoint, body, options);
  }

  put(endpoint: string, body: any | null, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object> {
    return this.onPremService.put(this.service, endpoint, body, options);
  }
}
