<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row">
  <div class="col-md-6">
    <h3>Request COVs to PDF</h3>
  </div>
  <div class="col-md-6" *ngIf="(!isSubmitted)&&(myStepper?.selectedIndex == 0)">
    <div style="float:right;margin-right:5px;">
      <button type="button" (click)="onGoToDriverSelect()" [disabled]="!criteriaValid()"
        class="btn btn-primary btn-next-step">
        Continue
      </button>
    </div>
  </div>
  <div class="col-md-6" *ngIf="(!isSubmitted)&&(myStepper?.selectedIndex == 1)">
    <div style="float:right;margin-right:5px;">
      <button type="button" (click)="onGoToCriteriaSelect()" class="btn btn-primary btn-prev-step">
        Back
      </button>
      <button type="button" (click)="submitCOVExport()" class="btn btn-primary btn-next-step">
        Request COVs to PDF
      </button>
    </div>
  </div>
  <div class="col-md-6" *ngIf="isSubmitted">
    <h3 class="label-validation-error">SUBMITTED</h3>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <mat-stepper [linear]="true" #stepper>
      <mat-step [stepControl]="criteriaFor1TabFormGroup">
        <form [formGroup]="criteriaFor1TabFormGroup">
          <ng-template matStepLabel>
            <div class="stepper-header-overlay" (click)="onGoToCriteriaSelect()"></div>
            Select Criteria for COVs</ng-template>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <div class="card card-body mt-3">
                    <h4 class="card-title text-muted wrap-this">Date Range</h4>
                    <div class="row">
                      <div class="col-sm-2" style="padding-top:1rem">
                        <label [ngClass]="{ 'label-validation-error': exportStartDateControl.invalid }">From:</label>
                      </div>
                      <div class="col-sm-4">
                        <mat-form-field appearance="fill" class="w-100">
                          <mat-label class="lbl-start-date">From Date</mat-label>
                          <input class="input-date" matInput disabled [matDatepicker]="startDatePicker"
                            [(ngModel)]="selectionCriteria.startDate" formControlName="exportStartDateControl">
                          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                          <mat-datepicker #startDatePicker disabled="false">
                            <mat-datepicker-actions>
                              <button mat-button (click)="startDatePicker.select(actualDate); startDatePicker.close()">
                                <mat-icon>history</mat-icon>
                                Today
                              </button>
                              <button mat-button (click)="startDatePicker.select(null); startDatePicker.close()">
                                <mat-icon>highlight_off</mat-icon>
                                Clear
                              </button>
                            </mat-datepicker-actions>
                          </mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="col-sm-2" style="padding-top:1rem">
                        <label [ngClass]="{ 'label-validation-error': exportEndDateControl.invalid }">Through:</label>
                      </div>
                      <div class="col-sm-4">
                        <mat-form-field appearance="fill" class="w-100">
                          <mat-label>Through Date</mat-label>
                          <input class="input-date" matInput disabled [matDatepicker]="endDatePicker"
                            [(ngModel)]="selectionCriteria.endDate" formControlName="exportEndDateControl">
                          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                          <mat-datepicker #endDatePicker disabled="false">
                            <mat-datepicker-actions>
                              <button mat-button (click)="endDatePicker.select(actualDate); endDatePicker.close()">
                                <mat-icon>history</mat-icon>
                                Today
                              </button>
                              <button mat-button (click)="endDatePicker.select(null); endDatePicker.close()">
                                <mat-icon>highlight_off</mat-icon>
                                Clear
                              </button>
                            </mat-datepicker-actions>
                          </mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <div class="card card-body mt-3">
                    <h4 class="card-title text-muted wrap-this">Retrieve</h4>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-check mr-3 inlineRB">
                          <input class="form-check-input" name="exportRetrieveTypeControl" type="radio" id="radioRetrieveAll"
                            [(ngModel)]="selectionCriteria.mostRecent" formControlName="exportRetrieveTypeControl"
                            value="false">
                          <label
                            [ngClass]="{ 'label-validation-error': exportRetrieveTypeControl.invalid, 'form-check-label':true }"
                            for="radioRetrieveAll">All COVs</label>
                        </div>
                        <div class="form-check mr-3 inlineRB">
                          <input class="form-check-input" name="exportRetrieveTypeControl" type="radio" id="radioRetrieveRecent"
                            [(ngModel)]="selectionCriteria.mostRecent" formControlName="exportRetrieveTypeControl"
                            value="true">
                          <label
                            [ngClass]="{ 'label-validation-error': exportRetrieveTypeControl.invalid, 'form-check-label':true }"
                            for="radioRetrieveRecent">Most Recent</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>
          <div class="stepper-header-overlay" (click)="onGoToDriverSelect()"></div>
          Select Drivers for COVs</ng-template>
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-body mt-3">
                  <h4 class="card-title text-muted wrap-this">Select Drivers From:</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <mat-tab-group id="matTabs" disablePagination="true">
                        <mat-tab label="Corporate Structure">
                          <app-corporate-structure-selector-folder [chType]="'MvrExport'" [arrCHInfo]="arrCH"
                            [clientSelectedArray]="clientSelectedArray" (onSelectionsProcessed)="getDrivers()">
                          </app-corporate-structure-selector-folder>
                        </mat-tab>
                        <mat-tab label="State/Province">
                          <app-corporate-structure-selector-folder [chType]="'MvrExportByState'"
                            [arrCHInfo]="arrCHByState" [clientSelectedArray]="clientSelectedArray"
                            (onSelectionsProcessed)="getDrivers()">
                          </app-corporate-structure-selector-folder>
                        </mat-tab>
                      </mat-tab-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <app-export-driver-select [arrDriver]="arrDriver" [showMVRDate]="true" [showLastMVRDate]="false"
              [showLastDVDate]="false" [showRiskLevel]="true" (onUpdateDriversSelected)="updateDriverIdSelected($event)"
              (onUpdateDriversExcluded)="updateDriverIdExcluded($event)">
            </app-export-driver-select>
          </div>
        </div>
      </mat-step>
    </mat-stepper>

  </div>
</div>