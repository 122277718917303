import { Component, Input } from '@angular/core';
import { SettingsPageSubscriptionInfo } from '../settings-page/settings-page.component'

@Component({
  selector: 'app-settings-subscription-panel',
  templateUrl: './settings-subscription-panel.component.html'
})
export class SettingsSubscriptionPanelComponent {
    @Input() card: SettingsPageSubscriptionInfo;
}
