//Angular
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
//Third Party
//APP
import { DashboardTile } from "../classes-and-interfaces/classes-and-interfaces.component";
import { DashboardService } from '../dashboard-service/dashboard-service.component';

@Component({
  selector: 'app-dashboard-tile-slicer-filter',
  templateUrl: './dashboard-tile-slicer-filter.component.html',
  styleUrls: ['./dashboard-tile-slicer-filter.component.css']
})

export class DashboardTileSlicerFilterComponent implements OnInit{
  @Input() card: DashboardTile;
  @Output() onFilterUpdate: EventEmitter<any> = new EventEmitter<any>();
  slicerOptions: Array<any>;

  constructor(
    private dashboardService: DashboardService,
  ) { }
  
  ngOnInit() {
    this.slicerOptions = this.card.slicerOptions?.split(',').map(o => { return { label: o, value: o } });
  }

  updateTileFilterInfo() {

  /* may need service to update only slicerOptionChosen,
    if it is decided to persist slicerOptionChosen among sessions
    this.dashboardService.updateTileFilter(this.card, s, e).subscribe(data => {
      this.card.filterStartDate = s;
      this.card.filterEndDate = e;
      this.onFilterUpdate.emit();
    },
      (err: HttpErrorResponse) => {
        this.toastrService.error(err.error.toString());

      })
    */

    this.onFilterUpdate.emit();
  }
   
}
