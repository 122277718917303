import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class DHPEmployeeMVRReportsService {
  baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.http = http;
  }
}
