<div class="quickSearchContainer">
    <quick-search-menu></quick-search-menu>
</div>
<div class="row" >
    <div class="col-md-12">
      <H4>Document Library Admin</H4>
    </div>
  </div>
<div class="row">
    <div class="col-md-12">
        <dc-company-selector [lineOfBusiness]="lineofBusinesssId" (onCompanySelected)="initPage($event)"></dc-company-selector>
    </div>
</div>
<div *ngIf="showDocumentPanel">
    <div class="row">
        <div class="col-md-12">
            <button type="button" class="btn btn-primary float-right mt-4" (click)="toggleUploadPanel(); addDocumentService.documentUploadAction$.next({action:documentUploadActions.addNewdocument, document: null})">Add New Document</button>
        </div>
    </div>
    <div class="row mt-5">
        <div class="table-container">
            <span class="documentRowHeader">
            Position
            </span>
            <span class="documentRowHeader">
            Show
            </span>            
            <span class="documentRowHeader">
            Document Title
            </span>      
            <span class="documentRowHeader">
            Document Description
            </span>
            <span class="documentRowHeader">
            Edit
            </span>
            <span class="documentRowHeader">
            Hide
            </span>
            <span class="documentRowHeader">
            Delete
            </span>                  
        </div>
    </div>

    <div *ngIf="documents.length > 0; else elseBlock">
        <div class="row" *ngFor="let doc of documents">
            <div class="table-container document-container">
            <span>
                {{doc.uiSortOrder}}
            </span>
            <span>
                {{ (doc.isVisible) ? 'Yes' : 'No' }}
            </span>            
            <span>
                <a href="{{doc.azureBlobFullUrl}}" target="_blank">{{doc.title}}</a>
            </span>
            <span>
                {{doc.description}}
            </span>
            <span>
                <button class="btn btn-primary btn-sm" (click)="toggleUploadPanel(true); addDocumentService.documentUploadAction$.next({action:documentUploadActions.edit, document: doc});">Edit</button>
            </span>
            <span *ngIf="doc.isVisible">
                <button class="btn btn-primary btn-sm"(click)="hideDoc(doc)" >Hide</button>
            </span>
            <span *ngIf="!doc.isVisible">
                <button class="btn btn-primary btn-sm"(click)="showDoc(doc)" >Show</button>
            </span>            
            <span>
                <button class="btn btn-primary btn-sm" (click)="confirmRemoveDocument(doc)">Delete</button>
            </span>                            
            </div>
        </div>
    </div>
</div>  
<ng-template #elseBlock>
<div class="row mt-5">
    There are currenty no documents
</div>
</ng-template>
