import { ErrorHandler, Injectable } from "@angular/core";
import { AppInsightsService } from "./AppInsights.service";

@Injectable()
export class AppInisghtsErrorHandlerService extends ErrorHandler {

    constructor(private readonly appInsightsService: AppInsightsService) {
        super();
    }

    handleError(error: Error) {
        this.appInsightsService.logException(error); // Manually log exception
        super.handleError(error);
    }
}
