<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
  <div class="row">
    <div class="col-md-12">
      <div class="card card-body" align="center">
        <label class="">Are You Sure?</label>
        <div class="row" style="padding-top:15px; align-content:center; align-items:center; align-self:center;">
          <div style="align-content:center; align-items:center;">
            <button class="btn btn-default"
                    (click)="submit()">
              Yes
            </button>
            <button class="btn btn-default"
                    (click)="cancel()">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
