<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" >
  <div class="col-md-12">
    <H4>Driver Entry</H4>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <dc-company-selector lineOfBusiness="7" (onCompanySelected)="initPage($event)"></dc-company-selector>
  </div>
</div>

<div class="row" style="padding-top:2rem" *ngIf="arrProgram&&(arrProgram.length<=0)">
  <div class="col-md-12">
    <h4 style="text-align:center">***  No Program Information Available  ***</h4>
  </div>
</div>
<ng-container *ngIf="arrProgram&&(arrProgram.length>0)">
  <div class="row" style="padding-top:2rem">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <H4>{{companyName}}</H4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <H5>Driver Entry</H5>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12" style="text-align:right">
          <span>
            To purchase additional programs, training lessons, or driver slots, please
            <a href="http://safety.fleetresponse.com" target="_blank" rel="noopener noreferrer">click here</a>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" style="text-align:right">
          <button type="button"
                  (click)="addNewDriver()"
                  class="btn btn-primary">
            Add New Driver
          </button>
          <button type="button"
                  (click)="addExistingDriver()"
                  class="btn btn-primary">
            Add Existing Driver
          </button>
        </div>
      </div>


    </div>
  </div>
  <div class="row" *ngFor="let program of arrProgram" style="padding-top:1rem">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <label>{{program.productName}}</label>
        </div>
        <div class="col-md-6" style="text-align:right">
          <label style="color:blue">
            You have used {{program.slotsUsed}} of {{program.slotsPurchased}} slots
          </label>

        </div>
      </div>
      <dc-programs-driver-slots-detail [arrSlotDetail]="program.slotDetail"></dc-programs-driver-slots-detail>
    </div>
  </div>

</ng-container>
