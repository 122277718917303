<header>
  <nav-menu *ngIf="loadNavMenu" [isIE]="ieFlag"></nav-menu>
</header>
<app-loading-spinner></app-loading-spinner>

<main>
  <div class="main-wrapper">
    <div class="container-fluid">
      <div class="row ml-0" *ngIf="displayCookiesWarningPanel">
        



        <div class="col-md-9">
          <div class="row">
            <div class="col-md-12">
              <h4>Cookies are currently disabled in your browser.  VISIBILITY requires cookies to be enabled.  Please update your browser settings.  Please contact your IT or <a href="mailto:support@fleetresponse.com">Fleet Response Support</a> if you need any assistance.​</h4>
            </div>
          </div>
        </div>

      </div>
      <div class="row ml-0">
        <!--Main column-->
        <div class="col-md-12">
          <ie-warning-panel *ngIf="loadNavMenu && ieFlag && displayIEWarningPanel"></ie-warning-panel>

          <app-business-group-news-popup *ngIf="loadNavMenu"></app-business-group-news-popup>
          <router-outlet></router-outlet>
          <app-dashboard-grid></app-dashboard-grid>
          <go-top-button [animate]="true"
                         [speed]="50"
                         [acceleration]="2"
                         [scrollDistance]="300"
                         [classNames]="'scroll-to-top-custom'" *ngIf="loadNavMenu">
            <i class='fa fa-arrow-up'></i> Back to Top
          </go-top-button>
        </div>
      </div>
    </div>

    <app-data-grid-filter-modal></app-data-grid-filter-modal>
  </div>
</main>
<footer></footer>
