import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from "rxjs";

@Injectable()
export class BrowserInfoService {
  private isIE = new BehaviorSubject<boolean>(false);
  isIE$ = this.isIE.asObservable();

  constructor(
  ) { }

  setIEFlag(isIE: boolean) {
    this.isIE.next(isIE);
  }

  areCookiesEnabled() {
    try {
      document.cookie = 'cookietest=1';
      var cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1;
      document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
      return cookiesEnabled;
    } catch (e) {
      return false;
    }
  }
}
