<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <app-accident-claim-supports-upload *ngIf="uploadPanelShow"
                                            [claimId]="_claimId"
                                            (onDocUploadExit)="toggleUploadPanel(false)"
                                            (onDocUploadRefresh)="refreshDocInfo()">
        </app-accident-claim-supports-upload>
        <app-accident-claim-supports-slideshow *ngIf="slideshowPanelShow"
                                               [arrPhotoInfo]="arrPhotoInfo"
                                               [ClaimId]="_claimId"
                                               (onSlideshowExit)="toggleSlideshowPanel(false)">
        </app-accident-claim-supports-slideshow>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Body Shop Photos</h4>
          <div class="row accidentClaimTabPaddingBottom" *ngIf="arrPhotoInfo.length <= 0">
            <div class="col-md-12 accidentClaimSupportsAlignCenter">
              (No Photos)
            </div>
          </div>
          <div class="row accidentClaimTabPaddingBottom" *ngIf="arrPhotoInfo.length > 0">
            <div class="col-md-3 accidentClaimSupportsAlignCenter">
              ({{arrPhotoInfo.length}} Images)
            </div>
            <div class="col-md-4 accidentClaimSupportsAlignCenter">
              <button type="button" class="btn btn-default btn-sm accidentClaimSupportsBtn" (click)="toggleSlideshowPanel(!slideshowPanelShow)">View Photos</button>
            </div>
            <div class="col-md-4 accidentClaimSupportsAlignCenter">
              <button type="button" class="btn btn-default btn-sm accidentClaimSupportsBtn" (click)="downloadZipFile(1, true)">Download Claim Photos (Original Size)</button><br/>
              <button type="button" class="btn btn-default btn-sm accidentClaimSupportsBtn" (click)="downloadZipFile(1, false)">Download Claim Photos (REDUCED SIZE)</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Documentation</h4>
          <div class="row">
            <div class="col-md-1"></div>
            <div *ngIf="canUploadDocs()"
                 [ngClass]="{'col-md-5': arrDocInfo.length > 0, 'col-md-10': arrDocInfo.length <= 0, 'accidentClaimSupportsAlignCenter': true}">
              <button type="button" class="btn btn-default btn-sm accidentClaimSupportsBtn" (click)="toggleUploadPanel(!uploadPanelShow)">Upload</button>
            </div>
            <div *ngIf="arrDocInfo.length > 0"
                 [ngClass]="{'col-md-5': canUploadDocs(), 'col-md-10': !canUploadDocs(), 'accidentClaimSupportsAlignCenter': true}">
              <button type="button" class="btn btn-default btn-sm accidentClaimSupportsBtn" (click)="downloadZipFile(10)">Download Documents</button>
            </div>
          </div>
          <div class="row" *ngIf="arrDocInfo.length <= 0">
            <div class="col-md-12 accidentClaimSupportsAlignCenter">
              (No Documents)
            </div>
          </div>
          <div class="row" *ngIf="arrDocInfo.length > 0">
            <div class="col-md-12">

              <div class="row accidentClaimTabPaddingTop">
                <div class="col-md-12">
                  <table class="table table-striped table-bordered accidentClaimSupportsTable">
                    <thead>
                      <tr>
                        <th class="accidentClaimSupportsTableHeader" scope="col" style="width:15%;">Date Form Uploaded</th>
                        <th class="accidentClaimSupportsTableHeader" scope="col" style="width:20%;">Uploaded By</th>
                        <th class="accidentClaimSupportsTableHeader" scope="col" style="width:30%;">Document Description</th>
                        <th class="accidentClaimSupportsTableHeader" scope="col" style="width:25%;">File Name</th>
                        <th class="accidentClaimSupportsTableHeader" scope="col" style="text-align:right;width:10%;">Size</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let info of arrDocInfo">
                        <td class="tdOther" style="width:15%;">{{info.uploadDate}}</td>
                        <td class="tdOther" style="width:20%;">{{info.uploadUser}}</td>
                        <td class="tdDesc" style="width:30%;">
                          <a (click)="downloadFile(info)" [target]="_blank">{{info.documentDesc}}</a>
                        </td>
                        <td class="tdOther" style="width:25%;">{{info.userFileName}}</td>
                        <td class="tdOther" style="text-align:right;width:10%;">{{info.fileSize}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
