<img *ngIf="type=='image'"
     [src]="src"
     style="width:100%">
<div *ngIf="type=='pdf'">
  <nav *ngIf="isLoaded&&(totalPages>1)" aria-label="Available PDFs">
    <ul class="pagination pg-blue justify-content-center">
      <li class="page-item">
        <a class="page-link" aria-label="Previous" matRipple (click)="previousPage()">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li [ngClass]="{'page-item':true, 'active':pg==(page-1)}" *ngFor="let pg of pageArray">
        <a class="page-link" matRipple (click)="goToPage(pg+1)">{{pg+1}}</a>
      </li>
      <li class="page-item">
        <a class="page-link" aria-label="Next" matRipple (click)="nextPage()">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
  </nav>

  <pdf-viewer 
    [src]="src"
    [page]="page"
    [render-text]="true"
    [show-all]="false"
    (after-load-complete)="afterLoadComplete($event)"
    class="pdf-viewer-container"
  ></pdf-viewer>
  <nav *ngIf="isLoaded&&(totalPages>1)" aria-label="Available PDFs">
    <ul class="pagination pg-blue justify-content-center">
      <li class="page-item">
        <a class="page-link" aria-label="Previous" matRipple (click)="previousPage()">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li [ngClass]="{'page-item':true, 'active':pg==(page-1)}" *ngFor="let pg of pageArray">
        <a class="page-link" matRipple (click)="goToPage(pg+1)">{{pg+1}}</a>
      </li>
      <li class="page-item">
        <a class="page-link" aria-label="Next" matRipple (click)="nextPage()">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
