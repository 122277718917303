  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-body mt-3">
            <h4 class="card-title text-muted">Auto Coverage</h4>
            <app-loading-panel *ngIf="!driverAutoCoverageTableConfig"></app-loading-panel>
            <div *ngIf="driverAutoCoverageTableConfig">
              <app-data-table 
                [columns]="driverAutoCoverageTableConfig.fields"
                [data]="driverAutoCoverageTableConfig.data"
                noRecordsLabel="No Auto Coverage information"
              ></app-data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  