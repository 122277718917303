//Angular
import { Component, Input, Inject, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
//Third Party
//App
//import { ToastService } from '../../typescripts/pro/alerts';
import { WidgetPerClientSettingsInfo, DataGridInfo } from "../classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-data-grid-per-client',
  templateUrl: './data-grid-per-client.component.html',
  styleUrls: ['./data-grid-per-client.component.scss']
})
export class DataGridPerClientComponent implements OnInit {
  private routewidget: any;
  dataGridId: number;
  dataGridTitle: string;
  perClientSettings: Array<WidgetPerClientSettingsInfo>;
  perClientProductGroups: Array<DataGridPerClientProductGroup>;
  baseUrl: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    //private toastrService: ToastService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit() {
    this.routewidget = this.route.params.subscribe(params => {
      this.dataGridId = +params['dataGridId'];
      this.getPerClientInfo();
    });
  }

  private getPerClientInfo() {
    let params = new HttpParams();
    params = params.append('DataGridId', this.dataGridId.toString());

    this.http.get(this.baseUrl + 'api/AdminDataGrid/GetById', { params: params })
      .subscribe({
        next: (data) => {
          let dataGridObject = data as DataGridInfo;
          this.dataGridTitle = dataGridObject.title;
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
        }
      });

    this.getClientsForDataGrid();
  }

  private getClientsForDataGrid() {
    let params = new HttpParams();
    params = params.append('DataGridId', this.dataGridId.toString());

    this.loadingSpinnerService.show();

    this.http.get(this.baseUrl + 'api/AdminDataGrid/GetClientsByDataGridId', { params: params })
      .subscribe({
        next: (data) => {
          this.perClientSettings = data as Array<WidgetPerClientSettingsInfo>;
          if (this.perClientProductGroups && (this.perClientProductGroups.length > 0)) {
            this.updateProductGroups()
          } else {
            this.createProductGroups();
          }
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
  }

  private createProductGroups() {
    let arrProdGroup: Array<DataGridPerClientProductGroup> = [];
    for (let pcs of this.perClientSettings) {
      let prodGroup = arrProdGroup.find(function (obj) { return obj.productId === pcs.productId; });
      if (!prodGroup) {
        let prodGroup = { productId: pcs.productId, productName: pcs.productName, clients: [], productExpanded: false };
        prodGroup.clients.push(pcs);
        arrProdGroup.push(prodGroup);
      } else {
        prodGroup.clients.push(pcs);
      }
    }
    this.perClientProductGroups = arrProdGroup.sort(function (a, b) { return a.productId - b.productId });
  }

  private updateProductGroups() {
    this.perClientProductGroups.forEach(g => g.clients = []);
    for (let pcs of this.perClientSettings) {
      let prodGroup = this.perClientProductGroups.find(function (obj) { return obj.productId === pcs.productId; });
      if (!prodGroup) {
        let prodGroup = { productId: pcs.productId, productName: pcs.productName, clients: [], productExpanded: false };
        prodGroup.clients.push(pcs);
        this.perClientProductGroups.push(prodGroup);
      } else {
        prodGroup.clients.push(pcs);
      }
    }
  }

  enableAll(enable: boolean) {
    let params = new HttpParams();
    params = params.append('DataGridId', this.dataGridId.toString());
    params = params.append('IsActive', enable.toString());
    this.loadingSpinnerService.show();
    this.http.post(this.baseUrl + 'api/AdminDataGrid/ActivateAllClientsForDataGrid', null, {
      params: params
    }).subscribe({
      next: (data) => {
        this.loadingSpinnerService.hide();
        this.getClientsForDataGrid();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    }
    )
  }

  enableProduct(productId: number, enable: boolean) {
    let params = new HttpParams();
    params = params.append('DataGridId', this.dataGridId.toString());
    params = params.append('ProductId', productId.toString());
    params = params.append('IsActive', enable.toString());
    this.loadingSpinnerService.show();
    this.http.post(this.baseUrl + 'api/AdminDataGrid/ActivateProductClientDataGrid', null, {
      params: params
    }).subscribe({
      next: (data) => {
        this.loadingSpinnerService.hide();
        this.getClientsForDataGrid();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    }
    )
  }

  enableClient(clientShortName: string, enable: boolean) {
    let params = new HttpParams();
    params = params.append('DataGridId', this.dataGridId.toString());
    params = params.append('Clients', clientShortName);
    this.loadingSpinnerService.show();
    if (enable) {
      this.http.put(this.baseUrl + 'api/AdminDataGrid/AddDataGridClientMap', null, {
        params: params
      }).subscribe({
        next: (data) => {
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      }
      )
    } else {
      //disable
      this.http.delete(this.baseUrl + 'api/AdminDataGrid/DeleteDataGridClientMap', {
        params: params
      }).subscribe({
        next: (data) => {
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      }
      )
    }
  }

  exitPerClientInfo() {
    this.router.navigate(['/admin/datagridadminedit', this.dataGridId.toString()]);
  }

  ngOnDestroy() {
    this.routewidget.unsubscribe();
  }
}

export interface DataGridPerClientProductGroup {
  productId: number;
  productName: string;
  clients: Array<WidgetPerClientSettingsInfo>;
  productExpanded: boolean;
}
