import { Injectable } from '@angular/core';
import { Batch } from './batch';
import { OnPremDriverService } from '../../../components/on-prem-service/on-prem-driver-service.component';

@Injectable({
  providedIn: 'root'
})
export class SubmitBatchService {

  constructor(private legacySvc: OnPremDriverService) { }
  submitBatch(data: Batch) {
    const postReturn = this.legacySvc.post('tasks/batch', JSON.stringify(data));
    return postReturn;
  }
}
