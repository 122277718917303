import { Component, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CustomModuleSelectorService } from './custom-module-selector-service.component';
import { LineOfBusinessService } from '../line-of-business-service/line-of-business-service.component';
import { ErrorModalService } from '../error-modal/error-modal-service.component';
import { HttpErrorResponse } from '@angular/common/http';
//#AngularUpgrade
//import { setTimeout } from 'timers';

@Component({
  selector: 'custom-module-selector',
  templateUrl: './custom-module-selector.component.html',
  styleUrls: [
    './custom-module-selector.component.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class CustomModuleSelectorComponent implements OnInit {
  lineOfBusinessSelected: number;
  customModuleChoices: Array<CustomAppSelector> = new Array<CustomAppSelector>();
  customModuleSavedUrl: string;

  @Output() onAppsListLoaded: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private customModuleSelectorService: CustomModuleSelectorService,
    private lineOfBusinessService: LineOfBusinessService,
    private router: Router,
    private errorService: ErrorModalService
  ) {

  }


  ngOnInit(): void {
    this.lineOfBusinessSelected = this.lineOfBusinessService.getLineOfBusinessValue();
    if (this.lineOfBusinessSelected && (this.lineOfBusinessSelected > 0)) {
      this.customModuleSelectorService.getCustomApps(this.lineOfBusinessSelected).subscribe({
        next: (data) => {
          this.customModuleChoices = data as Array<CustomAppSelector>;

          //console.log(this.customModuleChoices);
          if (this.customModuleChoices && this.customModuleChoices.length > 0) {
            this.customModuleSavedUrl = this.customModuleSelectorService.getSavedCustomApp();

            if (this.customModuleSavedUrl && this.customModuleSavedUrl.length > 0) {
              this.customModuleChoices.forEach((item) => {
                if (item.appUrl === this.customModuleSavedUrl) {
                  item.checked = true;
                }
              });
            }
            else {
              this.customModuleChoices[0].checked = true;
              this.customModuleSelectorService.setSaveCustomApp(this.customModuleChoices[0].appUrl);
            }
          }
          setTimeout(() => { this.onAppsListLoaded.emit() }, 200);
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
        }
      });
    }
  }


  processCustomModuleSelection(customModuleId: number) {
    let customModuleSelected = this.customModuleChoices.filter(opt => opt.clientId === customModuleId);
    this.customModuleSelectorService.setSaveCustomApp(customModuleSelected[0].appUrl);
  }
}


export class CustomAppSelector {
  clientId: number;
  client: string;
  clientName: string;
  productId: number;
  product: string;
  logoFileName: string;
  appUrl: string;
  checked: boolean;
}
