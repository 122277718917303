import { Component, OnInit, OnDestroy, Input, Output, OnChanges, SimpleChanges,Inject, EventEmitter } from '@angular/core';
import { DriverProfile, UserRightsInfo, DriverPendingTask, DriverDocuments } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';

/**
 * this is the client-side controller for the safety - pending tasks tab panel
 */
@Component({
    selector: 'safety-driver-pending-tasks',
    templateUrl: './safety-driver-pending-tasks.component.html',
    styleUrls: ['./safety-driver-pending-tasks.component.css']
})

export class SafetyDriverPendingTasksComponent implements OnInit, OnDestroy, OnChanges {
    @Input() driverProfile : DriverProfile;
    driverId: number;
    @Input() _userRights: Array<UserRightsInfo>;
    @Input() pendingTasks: Array<DriverPendingTask>;


    baseUrl: string;
    
    gtOptionsPendingTasksTable: any = {  };
    public driverPendingTasksTableConfig: any;

    constructor(
        @Inject('BASE_URL') baseUrl: string,
        protected datePipe: DatePipe
    ){
        this.baseUrl = baseUrl;
    }

    ngOnInit() {
        this.setTableData(this.pendingTasks);
    }

    ngOnChanges(changes: SimpleChanges): void {
        //update table data when inputs change
        this.driverId = this.driverProfile.driverId;
        this.setTableData(this.pendingTasks);
    }

    private setTableData(arrDriverPendingTasks: Array<DriverPendingTask>) {
      let columns = [
          { name: "Pending Task", prop: "task", title: "Pending Task", visible: true, dataType: null, columnClass: null },
          { name: "Task Status", prop: "processStatus", title: "Task Status", visible: true, dataType: null, columnClass: null },
          { name: "Assigned By", prop: "assignedBy", title: "Assigned By", visible: true, dataType: null, columnClass: null},
          { name: "Due Date", prop: "dueDate", title: "Due Date", visible: true, dataType: 'date', columnClass: null}
      ];

      let settings = columns.map(function (s) {
          return {
            objectKey: s.prop,
            visible:s.visible,
            sort: (s.visible?'enable':'')
          };
      });

      let fields = new Array<any>();
      columns.forEach(s => {
        fields.push({
          name: s.title,
          objectKey: s.prop,
          type: 'text',
          dataType: s.dataType,
          columnClass: s.columnClass,
          render: row => {
            let r = "";
            switch (s.prop) {
              case "dueDate":
                if (row[s.prop] && (row[s.prop].length > 0)) {
                  // formatting for createDate
                  if (s.dataType == "date") {
                    r = this.datePipe.transform(row[s.prop], 'MM/dd/yyyy');
                  } else {
                    r = row[s.prop];
                  }
                } else {
                  r = '';
                }
                break;
              
              default:
                r= row[s.prop];
            }
            return r;
          },
          value: row => {
            let val = row[s.prop];
            if (s.dataType === "date") {
              // use ISO string as value for date sorting
              try {
                val = new Date(val).toISOString();
              } catch (e) { }
            }
            if (s.dataType === "number") {
              // convert to number
              try {
                val = +val;
              } catch (e) { }
            }
            return val;
          },
          click: row => {}
        });
      });
        
      this.driverPendingTasksTableConfig = {
        settings: settings,
        fields: fields,
        data: arrDriverPendingTasks
      }

      this.gtOptionsPendingTasksTable = (arrDriverPendingTasks.length > 1 ? { numberOfRows: arrDriverPendingTasks.length } : {});
    }


    ngOnDestroy() {

    }
}