import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { AccidentClaimNote, UserRightsInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { FileDownloadService } from '../../shared/file-download/file-download.service';

@Component({
  selector: 'app-accident-claim-note',
  templateUrl: './accident-claim-notes.component.html',
})
export class AccidentClaimNotes implements OnInit, OnDestroy {
  _claimId: number;
  @Input() noteType: string;
  @Input() noteTypeId: number;
  @Input() userRights: Array<UserRightsInfo>;
  _routeSub: Subscription;
  followUpDateSubscription: Subscription;
  notes: Array<AccidentClaimNote>;
  rows: Array<AccidentClaimNote>;
  showAddNotePanel: boolean = false;
  baseUrl: string;

  public columns: Array<any> = [
    { title: 'Date & Time', name: 'formattedCreateDate' },
    { title: 'Entered By', name: 'createUserName', sort: '' },
    { title: 'Notes', name: 'noteText', sort: '' }
  ];
  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering: {}
  };

  constructor(@Inject('BASE_URL') baseUrl: string,
    private readonly _route: ActivatedRoute,
    private readonly fileDownloadService: FileDownloadService,
    private readonly accidentClaimService: AccidentClaimService
  ) {
    this.baseUrl = baseUrl;

    this.followUpDateSubscription = this.accidentClaimService.updatingFollowUpDate$.subscribe(
      followUpDateUpdated => {
        this.getNotes();
      }
    );
  }


  printPage(): void {
    window.print();
  }


  ngOnInit(): void {
    this.getNotes();
  }

  canAddNote(): boolean {
    let canAdd: boolean = false;

    if (this.userRights) {
      let thisRight = this.userRights.filter(r => r.userRightId === 59);
      if ((thisRight.length > 0) && (thisRight[0].permission === 'allow')) {
        canAdd = true;
      }
    }

    return canAdd;
  }

  refreshNotes(): void {
    this.getNotes();
    this.toggleAddNotePanel();
  }

  toggleAddNotePanel(): void {
    this.showAddNotePanel = !this.showAddNotePanel;
  }


  private getNotes(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];
      switch (this.noteType) {
        case 'Claim':
          this.accidentClaimService.getClaimNotes(this._claimId).subscribe({
            next: (data) => {
              this.notes = data as Array<AccidentClaimNote>;
              this.rows = this.notes.slice();
            },
            error: (err: HttpErrorResponse) => {
              console.log(err.error.toString());
            }
          });
          break;
        case 'Subrogation':
          this.accidentClaimService.getSubroNotes(this._claimId).subscribe({
            next: (data) => {
              this.notes = data as Array<AccidentClaimNote>;
            },
            error: (err: HttpErrorResponse) => {
              console.log(err.error.toString());
            }
          });
          break;
      }
    });
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
    this.followUpDateSubscription.unsubscribe();
  }

  public onChangeTable(config: any, page: any = { page: 1 }): any {

    //clear the sort
    this.columns.forEach((col: any) => {
      if (col.name !== config.name) {
        col.sort = '';
      }
    });

    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }
    if (config.sorting) {
      Object.assign(this.config.sorting, config.sorting);
    }
    this.rows = this.changeSort(this.notes, this.config);
  }


  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    let columns = this.config.sorting.columns || [];
    let columnName: string = '';
    let sort: string = '';

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '') {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }
    // simple sorting
    return data.sort((previous: any, current: any) => {
      if (previous[columnName] > current[columnName]) {
        return sort === 'desc' ? -1 : 1;
      } else if (previous[columnName] < current[columnName]) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  public exportToPdf() {
    this.fileDownloadService
      .saveFile(this.baseUrl + "api/Claim/GetNotesPdf?ClaimId=" + this._claimId + "&noteType=" + this.noteType);
  }
}
