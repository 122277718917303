//Angular
import { Injectable } from '@angular/core';
//Third Party
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
//APP
import { DriverInactive } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ReactivateDriverSearchModalComponent } from './reactivate-driver-search-modal.component';

@Injectable()
export class ReactivateDriverSearchModalService {
  private reactivateDriverSearchObs = new Subject();
  reactivateDriverSearchObs$ = this.reactivateDriverSearchObs.asObservable();
  private reactivateDriverSearchResultsObs = new Subject();
  reactivateDriverSearchResultsObs$ = this.reactivateDriverSearchResultsObs.asObservable();

  constructor(private dialog: MatDialog) { }

  openReactivateDriverSearchModal(): void {
    this.reactivateDriverSearchObs.next(true);
    this.showReactivateDriverModal();
  }

  closeReactivateDriverSearchModal(): void {
    this.reactivateDriverSearchObs.next(false);
  }

  setReactivateDriverSearchResults(res: Array<DriverInactive>) {
    this.reactivateDriverSearchResultsObs.next(res);
  }

  showReactivateDriverModal(): void {
    const dialogRef = this.dialog.open(ReactivateDriverSearchModalComponent,{
      data: {},
      minWidth: '30em',
      minHeight: '21em',
      panelClass: 'add-document-modal',
      hasBackdrop: false,
      position: {top: '2em'}
    });
  }
}
