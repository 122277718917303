import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertObject } from "../classes-and-interfaces/classes-and-interfaces.component";
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChartRequest } from './chart-v2.component';

@Injectable()
export class ChartV2Service {
  private chartData = new Subject();
  chartData$ = this.chartData.asObservable();
  baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public getChartData(tileRequest: ChartRequest) {
    this.http.post(this.baseUrl + 'api/Dashboard/GetTileData',
      JSON.stringify(tileRequest),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {
        this.chartData.next(data);
      });
  }

}
