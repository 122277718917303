import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { AccidentClaimVehicle } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-accident-claim-unit',
  templateUrl: './accident-claim-unit.component.html',
  styleUrls: ['./accident-claim-unit.component.css']
})
export class AccidentClaimUnitComponent implements OnInit, OnDestroy {
  _claimId: number;
  units: Array<AccidentClaimVehicle>;
  _routeSub: Subscription;

  constructor(private _route: ActivatedRoute,
    private accidentClaimService: AccidentClaimService) { }


  printPage(): void {
    window.print();
  }

  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];
      this.accidentClaimService.getUnits(this._claimId).subscribe({
        next: (data) => {
          this.units = data as Array<AccidentClaimVehicle>;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });
    });
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
  }
}
