import { Component, Inject, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DataGridMenuService } from './data-grid-menu.service';
import { LineOfBusinessService } from '../line-of-business-service/line-of-business-service.component';
import { DataGridModalService } from '../data-grid-filter-modal/data-grid-filter-modal.service'
import { ClientSelectionService, ClientSelectionsObject } from '../client-selection-service/client-selection-service.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-data-grid-menu',
  templateUrl: './data-grid-menu.component.html',
  styleUrls: ['./data-grid-menu.component.scss']
})
export class DataGridMenuComponent implements OnInit, OnDestroy {
  baseUrl: string;
  display: string = "none";
  //lineOfBusinessId: number;
  menuItems: Array<DataGrid>;
  clientSelectionSubscription: Subscription;
  clientSelectedArray: Array<string>;
  @Input() lineOfBusinessId: number;
  @Input() showTrainingSummaryToPDF: boolean = false;
  @Output() onReportsRefreshed: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private dataGridMenuService: DataGridMenuService,
    private lineOfBusinessService: LineOfBusinessService,
    private dataGridModalService: DataGridModalService,
    private clientSelectionService: ClientSelectionService) {
    this.http = http;
    this.baseUrl = baseUrl;
    /*
    this.lineOfBusinessService.lineOfBusinessSelected$.subscribe(data => {
      this.lineOfBusinessId = data as number;
      this.clientSelectedArray = this.clientSelectionService.getClientSelectedArrayValue(this.lineOfBusinessId);
      //this.getMenuItems();

    });
    */
  }

  ngOnInit(): void {
    //this.lineOfBusinessId = this.lineOfBusinessService.getLineOfBusinessValue();
    this.clientSelectedArray = this.clientSelectionService.getClientSelectedArrayValue(this.lineOfBusinessId);
    this.getMenuItems();

    this.clientSelectionSubscription = this.clientSelectionService.clientSelectedInfo$.subscribe(
      clientSelectedInfo => {
        let temp = clientSelectedInfo as ClientSelectionsObject;
        let arrClientOld = this.clientSelectedArray;
        let arrClientNew = (temp[this.lineOfBusinessId] ? temp[this.lineOfBusinessId].clientSelectedArray : []);

        if (!this.arrayCompare(arrClientNew, arrClientOld)) {
          this.clientSelectedArray = arrClientNew;
          this.getMenuItems();
        }

      }
    );
  }

  getMenuItems(): void {
    if (this.clientSelectedArray && this.clientSelectedArray.length > 0 && this.lineOfBusinessId) {
      var client = this.clientSelectionService.getClientSelectedArrayValue(this.lineOfBusinessId).join(",")
      this.dataGridMenuService.getByLineOfBusiness(this.lineOfBusinessId, client).subscribe({
        next: (data: Array<DataGrid>) => {
          this.menuItems = data;

          if (this.lineOfBusinessId == 2) {
            let report = this.menuItems.find(x => x.title.toLowerCase() == "leave of absence report");
            if (report) {
              this.dataGridMenuService.setLeaveOfAbsenceReportId(report.dataGridId);
            }
          }
          this.onReportsRefreshed.emit();
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });
    }
    else if (this.lineOfBusinessId == 7) { //Delivery Contractors
      this.dataGridMenuService.getByLineOfBusiness(this.lineOfBusinessId, "FEDEX1").subscribe({
        next: (data) => {
          this.menuItems = data as Array<DataGrid>;
          this.onReportsRefreshed.emit();
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });
    }
  }

  showModal(DataGridId: number) {
    //this.display = "block";
    this.dataGridModalService.openModal(DataGridId);
  }
  onCloseHandled() {
    //this.display = "none";
  }

  private arrayCompare(a1, a2) {
    return (a1.length == a2.length && a1.every((v, i) => v === a2[i]));
  }

  ngOnDestroy() {
    this.clientSelectionSubscription.unsubscribe();
  }
}

export class DataGrid {
  dataGridId: number;
  title: string;
  description: string
  lineOfBusinessId: number;
  showFilterSw: boolean;
  isOnPrem: boolean;
  onPremReportId: number;
  customReportUrl: string;
  isInNavMenu: boolean;
}
