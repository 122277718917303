<h4 class="card-title text-muted">{{ addEditDocTitle }}</h4>
<div class="row mt-3" *ngIf="allowUpload == true">
  <div class="col-md-9">
    <div class="file-field">
      <div class="btn btn-default btn-sm waves-light">
        <span>Browse</span>
        <input type="file" (change)="onUploadOutput($event)" #fileInput (click)="$event.target.value=null">
      </div>
      <div class="file-path-wrapper">
        <input id="docUploadFileName" class="file-path" type="text" placeholder="Document Upload"
          [value]="showFiles()" />
      </div>
    </div>
  </div>
  <div class="col-md-3">
    File cannot be larger than 10MB.
  </div>
</div>

<div class="row mt-3">
  <div class="col-md-12">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label for="docTitle" [ngClass]="{ 'label-validation-error': titleControl.invalid, 'active':true }">Document
        Title</mat-label>
      <input matInput id="docTitle" type="text" [(ngModel)]="fileTitle" class="form-control"
        placeholder="Document Title" [formControl]="titleControl" />
    </mat-form-field>
  </div>
</div>


<div class="row mt-4">
  <div class="col-md-12">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label for="docUploadDescription"
        [ngClass]="{ 'label-validation-error': descriptionControl.invalid, 'active':true }">Description
        Title</mat-label>
      <input matInput id="docUploadDescription" type="text" [(ngModel)]="fileDescription" class="form-control"
        placeholder="Description" [formControl]="descriptionControl" />
    </mat-form-field>
  </div>
</div>


<div class="row mt-3" *ngIf="sortOrderOptions">
  <div class="col-md-12">
    <mat-form-field appearance="fill" class="select-container w-100">
      <mat-label>Document Position</mat-label>
      <mat-select #selectTitleHolder="matSelect" [(value)]="sortOrder"
        (populateSortOrderPullDown)="populateSortOrderPullDown()" (selected)="onSortOrderSelect($event)">
        <mat-option *ngFor="let option of sortOrderOptions" [value]="option.value">
          {{option.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-md-12" style="text-align:center">
    <span>
      <button *ngIf="addDoc" type="button" (click)="uploadDocs()" class="btn btn-default">
        Finish Uploading Document

      </button>
      <button *ngIf="!addDoc" type="button" (click)="editDoc()" class="btn btn-default">
        Finish Edit

      </button>
      <button type="button" (click)="closeUploadPanel()" class="btn btn-default">
        Exit
      </button>
    </span>
  </div>
</div>