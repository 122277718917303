<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
  <div class="row">
    <div class="col-sm-12">
      <app-loading-panel *ngIf="!driverTelTableConfig"></app-loading-panel>
      <div *ngIf="driverTelTableConfig">
        <app-data-table
          [columns]="driverTelTableConfig.fields" 
          [data]="driverTelTableConfig.data"
          (triggerOpenModal)="openModal($event)"
          [showPagination]="true"
        ></app-data-table>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-default" style="margin-bottom:6px"
          (click)="cancel()">
    Close
  </button>
</div>
