import { Component, OnInit, OnChanges, Inject, Input, SimpleChanges } from '@angular/core';
import { DriverProfile, UserRightsInfo, RiskHistory, RiskClaimHistory, AuxiliaryPointHistory, MVRViolationsCurrent, MVRRecordsCurrent, DriverClientSettings } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { UserRightsService } from '../../components/user-rights-service/user-rights-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";

/**
 * this is the client-side controller for the safety - risk profile tab panel
 */
@Component({
  selector: 'safety-risk-profile',
  templateUrl: './safety-risk-profile.component.html',
  styleUrls: ['./safety-risk-profile.component.css']
})

export class SafetyRiskProfileComponent implements OnInit, OnChanges {
  @Input() driverProfile: DriverProfile;
  @Input() _userRights: Array<UserRightsInfo>;
  @Input() riskHistory: Array<RiskHistory>;
  @Input() riskClaimHistory: Array<RiskClaimHistory>;
  @Input() currentMvr: MVRRecordsCurrent;
  @Input() auxiliaryPointHistory: Array<AuxiliaryPointHistory>;
  @Input() clientSettings: DriverClientSettings;
  gtOptionsRiskHistoryTable: any = {  };
  gtOptionsClaimTable: any = {  };
  gtOptionsMVRViolationsTable: any = { };
  gtOptionsPointsTable: any = {  };
  public driverRiskHistoryTableConfig: any;
  public driverClaimTableConfig: any;
  public driverMVRViolationsTableConfig: any;
  public driverPointsTableConfig: any;
  arrRiskHistoryLegend: Array<SafetyRiskHistoryLegend>;
  lastPreventableIncidentText: string;

  // private variables that are only shared with subscribers that import the type
  baseUrl: string;
  dirty: boolean = false;
  showRiskHistory: boolean = false;
  canViewMVR: boolean = false;

  // the overloaded constructor for the controller
  constructor(private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private errorService: ErrorModalService,
    private onPremService: OnPremDriverService,
    private datePipe: DatePipe)
  {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  // angular on intialization event
  ngOnInit() {
    this.showRiskHistory = this.isAccessAllowed(15);
    this.canViewMVR = this.isAccessAllowed(48);
    // set data for tables
    this.setTableData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //update table data when inputs change
    this.setTableDataOnChanges(changes);
  }

  private setTableData() {
    if (this.showRiskHistory) {
      if (this.riskHistory) {
        this.setRiskHistoryTableConfig(this.riskHistory);
      }
      this.getRiskHistoryLegend();
      this.getLastPreventableIncidentText();
    }
    if (this.riskClaimHistory) {
      this.setClaimHistoryTableConfig(this.riskClaimHistory);
    }
    if (this.currentMvr && this.currentMvr.violations) {
      this.setMVRViolationsTableConfig(this.currentMvr.violations);
    }
    if (this.auxiliaryPointHistory) {
      this.setPointsTableConfig(this.auxiliaryPointHistory);
    }
    
  }

  private setTableDataOnChanges(changes: SimpleChanges) {
    if (this.showRiskHistory) {
      if (changes["riskHistory"] && this.riskHistory) {
        this.setRiskHistoryTableConfig(this.riskHistory);
        this.getRiskHistoryLegend();
        this.getLastPreventableIncidentText();
      }

    }
    if (changes["riskClaimHistory"] && this.riskClaimHistory) {
      this.setClaimHistoryTableConfig(this.riskClaimHistory);
    }
    if (changes["currentMvr"] && this.currentMvr && this.currentMvr.violations) {
      this.setMVRViolationsTableConfig(this.currentMvr.violations);
    }
    if (changes["auxiliaryPointHistory"] && this.auxiliaryPointHistory) {
      this.setPointsTableConfig(this.auxiliaryPointHistory);
    }
  }

  private setRiskHistoryTableConfig(riskHistory: Array<RiskHistory>) {
    let columns = [
      { name: "Change Reason", prop: "changeReason", title: "Change Reason", visible: true, dataType: null },
      { name: "Date of Change", prop: "dateOfChange", title: "Date of Change", visible: true, dataType: "date" },
      { name: "mvrId", prop: "mvrId", title: "mvrId", visible: false, dataType: null },
      { name: "Points Assigned", prop: "pointsAssigned", title: "Points Assigned", visible: true, dataType: "number" },
      { name: "Rating", prop: "rating", title: "Rating", visible: true, dataType: null },
      { name: "riskId", prop: "riskId", title: "riskId", visible: false, dataType: "number" },
      { name: "isHyperlink", prop: "isHyperlink", title: "isHyperlink", visible: false, dataType: "boolean" },
      { name: "Total Points", prop: "totalPoints", title: "Total Points", visible: true, dataType: "number" },
      { name: "rowColor", prop: "rowColor", title: "rowColor", visible: false, dataType: null }
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        hidden: !s.visible,
        render: row => {
          let r = "";
          switch (s.prop) {
            case "changeReason":
              // create link if mvrId exists
              if (this.canViewMVR && (row["isHyperlink"]) && (row["mvrId"].trim().length > 0)) {
                r = '<a style="color:#d23138;font-weight:bold;"><u>'+ row[s.prop]+'</u></a>';
              } else {
                r = row[s.prop];
              }
              break;
            case "rating":
            case "totalPoints":
              /* use color coding only for Rating and Total Points*/
              if (row['rowColor']) {
                r = '<span style="color:' + row['rowColor'] + ';font-weight:bold;">' + row[s.prop] + '</span>';
              } else {
                r = row[s.prop];
              }
              break;
            default:
              r = row[s.prop];
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        },
        click: row => {
          if ((s.prop === "changeReason") && (row["isHyperlink"]) && (row["mvrId"].trim().length > 0)) {
            // if mvrId exists, open
            return this.openMVR(row["mvrId"].trim());
          }
        }
      });
    });

    this.driverRiskHistoryTableConfig = {
      settings: settings,
      fields: fields,
      data: riskHistory
    }

    this.gtOptionsRiskHistoryTable = (riskHistory.length > 1 ? { numberOfRows: riskHistory.length } : {});
  }

  private setClaimHistoryTableConfig(claimHistory: Array<RiskClaimHistory>) {
    let columns = [
      { name: "Claim", prop: "claimId", title: "Claim", visible: true, dataType: null },
      { name: "Date of Loss", prop: "dateOfLoss", title: "Date of Loss", visible: true, dataType: "date" },
       { name: "Accident Reason", prop: "accidentReason", title: "Accident Reason", visible: true, dataType: null },
      { name: "Claim Cost", prop: "claimCost", title: "Claim Cost", visible: true, dataType: "number" },
      { name: "Preventable", prop: "preventability", title: "Preventable", visible: true, dataType: null },
      { name: "Points", prop: "points", title: "Points", visible: true, dataType: "number" },
      { name: "Type", prop: "claimIncidentType", title: "Type", visible: this.clientSettings.hasMultiAccidentTypes, dataType: null },
      { name: "riskClaimId", prop: "riskClaimId", title: "riskClaimId", visible: false, dataType: null },
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        hidden: !s?.visible,
        render: row => {
          let r = "";
          switch (s.prop) {
            case "claimId":
              // create link to claim
              r = '<a style="color:#d23138;"><u>' + row[s.prop] + '</u></a>';
              break;
            default:
              r = row[s.prop];
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        },
        click: row => {
          if (s.prop === "claimId") {
            // open claim
            return this.goToClaim(row[s.prop]);
          }
        }
      });
    });

    this.driverClaimTableConfig = {
      settings: settings,
      fields: fields,
      data: claimHistory
    }

    this.gtOptionsClaimTable = (claimHistory.length > 1 ? { numberOfRows: claimHistory.length } : {});
  }

  private setMVRViolationsTableConfig(mvrViolations: Array<MVRViolationsCurrent>) {
    let columns = [
      { name: "Incidents", prop: "incidents", title: "Incidents", visible: true, dataType: "number" },
      { name: "Violation Date", prop: "violationDate", title: "Violation Date", visible: true, dataType: "date" },
      { name: "Convicted Date", prop: "convictionDate", title: "Convicted Date", visible: true, dataType: "date" },
      { name: "Description", prop: "masterDescription", title: "Description", visible: true, dataType: null },
      { name: "Points", prop: "totalPoints", title: "Points", visible: true, dataType: "number" }
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        type: 'text',
        dataType: s?.dataType,
        hidden: !s?.visible,
        render: row => {
          let r = row[s.prop];
          if (s.dataType === "date") {
            // format in mm/dd/yyyy format
            try {
              r = this.datePipe.transform(r, 'MM/dd/yyyy')
            } catch (e) { }
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.driverMVRViolationsTableConfig = {
      settings: settings,
      fields: fields,
      data: mvrViolations
    }

    this.gtOptionsMVRViolationsTable = (mvrViolations.length > 1 ? { numberOfRows: mvrViolations.length } : {});
  }

  private setPointsTableConfig(additionalPoints: Array<AuxiliaryPointHistory>) {
    let columns = [
      { name: "Description", prop: "infraction", title: "Description", visible: true, dataType: null },
      { name: "Points", prop: "points", title: "Points", visible: true, dataType: "number" },
      { name: "Date of Incident", prop: "dateOfIncident", title: "Date of Incident", visible: true, dataType: "date" },
      { name: "Expires", prop: "expires", title: "Expires", visible: true, dataType: "date" },
      { name: "Date Entered", prop: "dateEntered", title: "Date Entered", visible: true, dataType: "date" },
      { name: "Assigned By", prop: "assignedBy", title: "Assigned By", visible: true, dataType: null },
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        type: 'text',
        dataType: s?.dataType,
        hidden: !s?.visible,
        render: row => {
          let r = row[s.prop];
          if (s.dataType === "date") {
            // format in mm/dd/yyyy format
            try {
              r = this.datePipe.transform(r, 'MM/dd/yyyy')
            } catch (e) { }
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.driverPointsTableConfig = {
      settings: settings,
      fields: fields,
      data: additionalPoints
    }

    this.gtOptionsPointsTable = (additionalPoints.length > 1 ? { numberOfRows: additionalPoints.length } : {});
  }

  private getRiskHistoryLegend() {
    this.onPremService.get('riskHistory/legend/' + this.driverProfile.driverId)
      .subscribe({
        next: (data) => {
          this.arrRiskHistoryLegend = data as Array<SafetyRiskHistoryLegend>;
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
        }
      });
  }

  private getLastPreventableIncidentText() {
    this.onPremService.get('riskHistory/lastpreventableincidenttext/' + this.driverProfile.driverId)
      .subscribe({
        next: (data) => {
          this.lastPreventableIncidentText = data as string;
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
        }
      });
  
  }

  openMVR(mvrId: number) {
    this.router.navigate(['/driver/mvrreport/' + mvrId + '/' + this.driverProfile.driverId], { queryParams: { returnTo: 'driverProfileRisk' } });
  }

  goToClaim(claimId: string) {
    this.router.navigate(['/accident/claim/' + claimId.trim()]);
  }

  // determines if access to the component is allowed
  isAccessAllowed(userRightsId: number) {
    let retVal: boolean = false;

    if (this._userRights) {
      let thisRight = this._userRights.filter(r => r.userRightId == userRightsId);
      if ((thisRight.length > 0) && (thisRight[0].permission == 'allow')) {
        retVal = true;
      }
    }

    return retVal;
  }

  onNavigate(data: any): void {
    const type = data?.type;
    const row = data?.row;
    if (type === 'openMVR') {
      const mvrId = row?.mvrId?.trim();
      this.openMVR(mvrId);
    } else if (type === 'goToClaim') {
      const claimId = row?.claimId;
      this.goToClaim(claimId);
    }
  }
 
  // sleeps for a certain number of milliseconds
  sleep(milliseconds: number) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }

}

export interface SafetyRiskHistoryLegend {
  safetyRiskDefinitionId: number;
  driverRiskLevel: string;
  pointRange: string;
  visibilityRowColor: string;
}
