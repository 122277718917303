import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable, forkJoin, of } from 'rxjs';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { OrdersAndAssignmentsReportModalService, OrdersAndAssignmentsBatchDateTime } from "../../components/orders-and-assignments-report-modal/orders-and-assignments-report-modal-service.component";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-orders-and-assignments-report',
  templateUrl: './orders-and-assignments-report.component.html',
  styleUrls: ['./orders-and-assignments-report.component.scss']
})

export class OrdersAndAssignmentsReportComponent implements OnInit, OnDestroy {
  public itemsPerPage: number = 50;
  batchId: string;
  batchDateTime: string;
  summaryData: Array<OrdersAndAssignmentsReportSummary>;
  detailData: Array<OrdersAndAssignmentsReportDetail>;
  lineOfBusinessId: number;
  clientSelectedArray: Array<string>;
  routeSub: Subscription;
  gtOptions: any = { numberOfRows: 50 };
  public summaryTableConfig: any;
  public detailTableConfig: any;
  dtToday: Date = new Date();

  // the overloaded constructor for the controller
  constructor(private route: ActivatedRoute,
    private lineOfBusinessService: LineOfBusinessService,
    private clientService: ClientSelectionService,
    private ordAssignReportService: OrdersAndAssignmentsReportModalService,
    private http: HttpClient,
    private onPremService: OnPremDriverService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService) { }

  ngOnInit() {
    // set line of business to safety if not already initialized to Safety
    this.lineOfBusinessId = 2;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    // get the selected client(s)
    this.clientSelectedArray = this.clientService.getSavedClientShortNames(this.lineOfBusinessId);

    // get data for table
    this.routeSub = this.route.params.subscribe(params => {
      this.batchId = params['batchId'] || '';
      let clientCode = this.clientSelectedArray[0];

      this.initAll(clientCode, this.batchId);
    });

  }

  private initAll(clientCode: string, batchId: string) {
    let arrObs: Array<Observable<Object>> = [
      this.getBatchDateTimeData(),
      this.getDetailTableData(clientCode, batchId),
      this.getSummaryTableData(clientCode, batchId)
    ]
    if (clientCode && batchId) {
      this.loadingSpinnerService.show();
      forkJoin(arrObs).subscribe({
        next: (data) => {
          let batchOptions = data[0] as Array<OrdersAndAssignmentsBatchDateTime>;
          this.batchDateTime = batchOptions.find(b => b.batchId == +batchId).batchDateTime;
          this.detailData = data[1] as Array<OrdersAndAssignmentsReportDetail>;
          this.summaryData = data[2] as Array<OrdersAndAssignmentsReportSummary>;

          this.setSummaryTableConfig(this.summaryData);
          this.setDetailTableConfig(this.detailData);

          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    }
  }

  private getSummaryTableData(clientCode: string, batchId: string) {
    let data1: OrdersAndAssignmentsReportSummary = {
      mvrDataValidationOnly: true,
      mvrDataValidationDueDate: "1/ 1/ 2020",
      lmsDueDate: "2/2/2022",
      courses: "Test",
      mvrDriverCount: 99,
      lmsDriverCount: 100
    };
    return of([data1]) as Observable<Object>;
  }

  private getDetailTableData(clientCode: string, batchId: string) {
    let data1: OrdersAndAssignmentsReportDetail = {
      employeeId: "SN00001",
      active: false,
      client: "DEMO1",
      driver: "Kevin Spelling",
      group: "Unassigned",
      dueDate: "12/31/2019",
      action: "Training XXX"
    };
    let data2: OrdersAndAssignmentsReportDetail = {
      employeeId: "SN00002",
      active: true,
      client: "DEMO1",
      driver: "Kelly Kapoor",
      group: "Reassigned",
      dueDate: "2/2/2020",
      action: "Training YYY"
    };
    return of([data1, data2]) as Observable<Object>;
  }

  private getBatchDateTimeData() {
    //#AngularUpgrade
    //return this.ordAssignReportService.getBatchDateTimeOptions() as Observable<Object>;
    return new Observable<object>();
  }

  private setSummaryTableConfig(arrData: Array<OrdersAndAssignmentsReportSummary>) {
    let datePipe = new DatePipe("en-US");
    let titleCasePipe = new TitleCasePipe();

    let columns = [
      { name: "MVR Data Validation Only", prop: "mvrDataValidationOnly", title: "MVR Data Validation Only", sort: "enable", visible: true, dataType: 'boolean' },
      { name: "MVR Data Validation Due", prop: "mvrDataValidationDueDate", title: "MVR Data Validation Due", sort: "enable", visible: true, dataType: 'date' },
      { name: "LMSDueDate", prop: "lmsDueDate", title: "LMSDueDate", sort: "enable", visible: true, dataType: 'date' },
      { name: "Courses", prop: "courses", title: "Courses", sort: "enable", visible: true, dataType: null },
      { name: "MVR Driver Count", prop: "mvrDriverCount", title: "MVR Driver Count", sort: "enable", visible: true, dataType: 'number' },
      { name: "LMS Driver Count", prop: "lmsDriverCount", title: "LMS Driver Count", sort: "enable", visible: true, dataType: 'number' }
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: s.sort,
        visible: s.visible
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        render: row => {
          let r = row[s.prop];

          if (s.dataType === "date") {
            try {
              r = datePipe.transform(new Date(r), "MM/dd/yyyy");
            } catch (e) { }
          }
          if (s.dataType === "boolean") {
            // convert to string if not null
            try {
              r = titleCasePipe.transform(r.toString());
            } catch (e) {
              r = "";
            }
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.summaryTableConfig = {
      settings: settings,
      fields: fields,
      data: arrData
    }
  }

  private setDetailTableConfig(arrData: Array<OrdersAndAssignmentsReportDetail>) {
    let datePipe = new DatePipe("en-US");
    let titleCasePipe = new TitleCasePipe();

    let columns = [
      { name: "Employee ID", prop: "employeeId", title: "Employee ID", sort: "enable", visible: true, dataType: null },
      { name: "Active", prop: "active", title: "Active", sort: "enable", visible: true, dataType: 'boolean' },
      { name: "Client", prop: "client", title: "Client", sort: "enable", visible: true, dataType: null },
      { name: "Driver", prop: "driver", title: "Driver", sort: "enable", visible: true, dataType: null },
      { name: "Group", prop: "group", title: "Group", sort: "enable", visible: true, dataType: null },
      { name: "Due", prop: "dueDate", title: "Due", sort: "enable", visible: true, dataType: 'date' },
      { name: "Action", prop: "action", title: "Action", sort: "enable", visible: true, dataType: null }
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: s.sort,
        visible: s.visible
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        render: row => {
          let r = row[s.prop];

          if (s.dataType === "date") {
            try {
              r = datePipe.transform(new Date(r), "MM/dd/yyyy");
            } catch (e) { }
          }
          if (s.dataType === "boolean") {
            // convert to string if not null
            try {
              r = r = titleCasePipe.transform(r.toString());
            } catch (e) {
              r = "";
            }
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.detailTableConfig = {
      settings: settings,
      fields: fields,
      data: arrData
    }
  }

  onPrint() {

  }

  onExcel() {

  }

  onEmail() {

  }


  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}

export class OrdersAndAssignmentsReportSummary {
  mvrDataValidationOnly: boolean;
  mvrDataValidationDueDate: string;
  lmsDueDate: string;
  courses: string;
  mvrDriverCount: number;
  lmsDriverCount: number;
}

export class OrdersAndAssignmentsReportDetail {
  employeeId: string;
  active: boolean;
  client: string;
  driver: string;
  group: string;
  dueDate: string;
  action: string;
}
