import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DriverProfile, UserRightsInfo, MVRRecordsCurrent, MVRViolationsCurrent, MVRHistory, DriverClientSettings } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';

/**
 * this is the client-side controller for the safety - mvr tab panel tab panel
 */
@Component({
  selector: 'safety-mvr',
  templateUrl: './safety-mvr.component.html',
  styleUrls: ['./safety-mvr.component.css']
})

export class SafetyMVRComponent implements OnInit, OnDestroy, OnChanges {
  @Input() driverProfile: DriverProfile;
  @Input() currentMvr: MVRRecordsCurrent;
  @Input() mvrRecords: Array<MVRHistory>;
  @Input() fullProfile: boolean;
  @Input() clientSettings: DriverClientSettings;
  @Input() _userRights: Array<UserRightsInfo>;
  gtOptionsMVRViolationsTable: any = {  };
  gtOptionsMVRRecordsTable: any = {  };
  public driverMVRViolationsTableConfig: any;
  public driverMVRRecordsTableConfig: any;
  canViewDLDOB: boolean = false;
  canViewMVR: boolean = false;
  
  // the overloaded constructor for the controller
  constructor(private router: Router,
    private datePipe: DatePipe,
    private driverHistoryProfileService: DriverHistoryProfileService) { }

  // angular on intialization event
  ngOnInit() {
    this.canViewDLDOB = this.isAccessAllowed(42);
    this.canViewMVR = this.isAccessAllowed(48);
    // set data for tables
    this.setTableData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //update table data when inputs change
    this.setTableData();
  }

  private setTableData() {
    if (this.currentMvr && this.currentMvr.violations) {
      this.setMVRViolationsTableConfig(this.currentMvr.violations);
    }
    if (this.mvrRecords) {
      this.setMVRRecordsTableConfig(this.mvrRecords);
    }
  }

  private setMVRViolationsTableConfig(mvrViolations: Array<MVRViolationsCurrent>) {
    let columns = [
      { name: "Incidents", prop: "incidents", title: "Incidents", visible: true, dataType: "number" },
      { name: "Violation Date", prop: "violationDate", title: "Violation Date", visible: true, dataType: "date" },
      { name: "Convicted Date", prop: "convictionDate", title: "Convicted Date", visible: true, dataType: "date" },
      { name: "Description", prop: "masterDescription", title: "Description", visible: true, dataType: null },
      { name: "Points", prop: "totalPoints", title: "Points", visible: true, dataType: "number" }
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        type: 'text',
        dataType: s.dataType,
        hidden: !s.visible,
        render: row => {
          let r = row[s.prop];
          if (s.dataType === "date") {
            // format in mm/dd/yyyy format
            try {
              r = this.datePipe.transform(r, 'MM/dd/yyyy')
            } catch (e) { }
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.driverMVRViolationsTableConfig = {
      settings: settings,
      fields: fields,
      data: mvrViolations,
      noRecordsLabel: 'No MVR Violations'
    }

    this.gtOptionsMVRViolationsTable = (mvrViolations.length > 1 ? { numberOfRows: mvrViolations.length } : {});
  }

  private setMVRRecordsTableConfig(mvrRecords: Array<MVRHistory>) {
    let hasContestedMVR = (this.clientSettings.hasContestedMvr != null) ? this.clientSettings.hasContestedMvr : false;
    let columns = [
      { name: "Received Date", prop: "receivedDate", title: "Received Date", visible: true, dataType: "date" },
      { name: "License #", prop: "licenseNumber", title: "License #", visible: true, dataType: null },
      { name: "State", prop: "licenseState", title: "State", visible: true, dataType: null },
      { name: "Status", prop: "licenseStatus", title: "Status", visible: true, dataType: null },
      { name: "Requested By", prop: "requestedUserName", title: "Requested By", visible: true, dataType: null },
      { name: "mvrId", prop: "mvrId", title: "mvrId", visible: false, dataType: null },
      { name: "mvrSource", prop: "mvrSource", title: "mvrSource", visible: false, dataType: null },
      { name: "Contested Date", prop: "contestedDate", title: "Contested Date", visible: hasContestedMVR, dataType: "date" },
      { name: "verifiedDate", prop: "verifiedDate", title: "verifiedDate", visible: false, dataType: "date" },
      { name: "contestedEnabled", prop: "contestedEnabled", title: "Contest", visible: false, dataType: null },
      { name: "Contest", prop: "isContestable", title: "Contest", visible: hasContestedMVR, dataType: null },
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {      
      fields.push({
        name: s.title,
        objectKey: s.prop,
        clickable: ['receivedDate', 'isContestable'].includes(s.prop),
        hidden: !s.visible,
        render: row => {
          let r = "";
          switch (s.prop) { 
            case "receivedDate":
              // convert date format
              r = this.datePipe.transform(row[s.prop], 'MM/dd/yyyy');
              // create link if mvrId exists
              if (row["mvrSource"] && (row["mvrSource"].toLowerCase() == "samba") && (row["mvrId"].length > 0)) {
                r = '<a style="color:#d23138;"><u>' + r + '</u></a>';
              } 
              break;
            case "contestedDate":
              if (row[s.prop]) {
                r = this.datePipe.transform(row[s.prop], 'MM/dd/yyyy');
              }
              break;
            case "isContestable":
              // if isContestable and no contest and verify dates, it is contestable
              if (row[s.prop] && !row['verifiedDate'] && !row['contestedDate']) { 
                let strDisabled = (row['contestedEnabled'] ? '' : ' disabled=true'); 
                let buttonTitle = 'Contest'; 
                r = '<button class="btn btn-xs mvrContestButton rounded my-0 p-1"' + strDisabled + '>' + buttonTitle + '</button>';
              }
              // if no verified date and contested date exist, it has been contested and is verifyable indefinately (reguardless of isContestable flag)
              if (!row['verifiedDate'] && row['contestedDate']) { 
                let strDisabled = (row['contestedEnabled'] ? '' : ' disabled=true'); 
                let buttonTitle = 'Verify';
                r = '<button class="btn btn-xs mvrContestButton rounded my-0 p-1"' + strDisabled + '>' + buttonTitle + '</button>';
              }
              break;
            default:
              r = row[s.prop];
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        },
        click: row => {
          if ((s.prop === "receivedDate") && row["mvrSource"] && (row["mvrSource"].toLowerCase() == "samba") && (row["mvrId"].length > 0)) {
            // if mvrId exists, open
            return this.openMVR(row["mvrId"]);
          }
          if (s.prop === "isContestable" && !row['contestedDate']) {
            return this.openContestModal(row["mvrId"]);
          }
          if (s.prop === "isContestable" && !row['verifiedDate']) {
            return this.openVerifyModal(row["mvrId"]);
          }
        }
      });
    });

    this.driverMVRRecordsTableConfig = {
      settings: settings,
      fields: fields,
      data: mvrRecords,
      noRecordsLabel: 'No MVR Records'
    }

    this.gtOptionsMVRRecordsTable = (mvrRecords.length > 1 ? { numberOfRows: mvrRecords.length } : {});
  }

  openMVR(mvrId: number) {
    let queryParams:any = {};
    if (this.fullProfile) {
      // just send returnTo
      queryParams = { returnTo: 'driverProfileMVR' };
    } else {
      // return user to primary driver profile, Non-Employees tab on exit
      queryParams = { returnTo: 'driverProfileNonEmployees', returnId : this.driverProfile.primaryDriverId.toString() };
    }
    this.router.navigate(['/driver/mvrreport/' + mvrId + '/' + this.driverProfile.driverId], { queryParams: queryParams });
  }

  openContestModal(mvrId: number) {
    this.notifyModal('Contest MVR Results', 'contestMVR', '420px;', 'md', { driverName: this.driverProfile.fullName, driverId: this.driverProfile.driverId, mvrId: mvrId });
  }

  openVerifyModal(mvrId: number) {
    this.notifyModal('Verify MVR Results', 'verifyMVR', '420px;', 'md', { driverName: this.driverProfile.fullName, driverId: this.driverProfile.driverId, mvrId: mvrId });
  }

  notifyModal(title: string, message: string, modalHeight: string, modalWidth: string, modalInput?: any) {
    this.driverHistoryProfileService.notifyModal(title, message, modalHeight, modalWidth, modalInput);
  }


  // determines if access to the component is allowed
  isAccessAllowed(userRightsId: number) {
    let retVal: boolean = false;

    if (this._userRights) {
      let thisRight = this._userRights.filter(r => r.userRightId == userRightsId);
      if ((thisRight.length > 0) && (thisRight[0].permission == 'allow')) {
        retVal = true;
      }
    }

    return retVal;
  }

  // sleeps for a certain number of milliseconds
  sleep(milliseconds: number) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }

  openModal(data: any): void {
    const type = data?.type;
    const mvrId = data?.row?.mvrId;
    if (type === 'contest') {
      this.notifyModal('Contest MVR Results', 'contestMVR', '420px;', 'md', { driverName: this.driverProfile.fullName, driverId: this.driverProfile.driverId, mvrId: mvrId });
    } else if (type === 'verify') {
      this.notifyModal('Verify MVR Results', 'verifyMVR', '420px;', 'md', { driverName: this.driverProfile.fullName, driverId: this.driverProfile.driverId, mvrId: mvrId });
    }
  }

  onNavigate(data: any): void {
    const type = data?.type;
    const mvrId = data?.row?.mvrId;

    if (type === 'openMVR') {
      this.openMVR(mvrId);
    }
  }

  // the default destructor
  ngOnDestroy() {

  }
}
