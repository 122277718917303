import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DriverProfile, UserRightsInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

/**
 * this is the client-side controller for the safety - additional drivers not full panel
 */
@Component({
  selector: 'safety-additional-drivers-full',
  templateUrl: './safety-additional-drivers-full.component.html',
  styleUrls: ['./safety-additional-drivers-full.component.css']
})

export class SafetyAdditionalDriversFullComponent implements OnInit, OnChanges {
  @Input() driverProfile: DriverProfile;
  @Input() _userRights: Array<UserRightsInfo>;
  @Input() secondaryDrivers: Array<DriverProfile>;
  @Input() allowMultipleNonEmployees: boolean;
  driverSecondaryTableConfig: any;
  gtOptions: any = {};
  canAddNewDriver = false;

  // the overloaded constructor for the controller
  constructor(private readonly router: Router) {
  }

  // angular on intialization event
  ngOnInit() {
    this.onInitAndChanges();
  }

  ngOnChanges(): void {
    this.onInitAndChanges();
  }

  private onInitAndChanges(): void {
    this.calcCanAddNewDriver();
    // set data for tables
    this.setTableData();
  }

  private setTableData() {
    if (this.secondaryDrivers) {
      this.setSecondaryDriverTableConfig(this.secondaryDrivers);
    }
  }

  private setSecondaryDriverTableConfig(arrSecondaryDriver: Array<DriverProfile>) {
    const columns = [
      { name: "Non-Employee Driver", prop: "fullName", title: "Non-Employee Driver", visible: true, dataType: null, sort: "enable" },
      { name: "Status", prop: "isActive", title: "Status", visible: false, dataType: null, sort: null },
      { name: "Status", prop: "activeInactiveSwitchEnabled", title: "Status", visible: true, dataType: null, sort: "enable" },
      { name: "driverId", prop: "driverId", title: "driverId", visible: false, dataType: null, sort: null },
      { name: "primaryDriverId", prop: "primaryDriverId", title: "primaryDriverId", visible: false, dataType: null, sort: null }
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: s.sort
      };
    });

    const fields = [];
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        columnComponent: (s.prop === "activeInactiveSwitchEnabled" ? { type: SecondaryDriverActiveSwitchComponent } : null),
        component: s.prop === "activeInactiveSwitchEnabled" ? 'safetySecondaryDriverActiveSwitch' : null,
        componentObject: s.prop !== "activeInactiveSwitchEnabled" ? null : {},
        sort: s?.sort === 'enable',
        hidden: !s?.visible,
        type: s.prop === "activeInactiveSwitchEnabled" ? 'component' : 'text',
        render: row => {
          return s.prop === 'fullName' && row["isActive"] 
            ? `<a class="textalign center" style="color:#d23138;"><u>${row[s.prop]}</u></a>` 
            : row[s.prop];
        },
        value: row => {
          let val = row ? row[s.prop] || null : null;
          if (s.prop === "activeInactiveSwitchEnabled") {
            // calc whether active/inactive switch is enabled based
            // on client settings
            val = this.allowMultipleNonEmployees;
            if (!this.allowMultipleNonEmployees) {
              val = (row && row["isActive"]) || this.canAddNewDriver;
            }
          }
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          } else if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }

          return val;
        },
        click: row => {
          return s.prop === "fullName" && row["isActive"] ? this.openNonEmployeeDriverForm(row["driverId"]) : null;
        }
      });
    });

    this.driverSecondaryTableConfig = {
      settings: settings,
      fields: fields,
      data: arrSecondaryDriver
    }

    this.gtOptions = (arrSecondaryDriver.length > 1 ? { numberOfRows: arrSecondaryDriver.length } : {});
  }

  // determines if access to the component is allowed
  isAccessAllowed(userRightsId: number) {
    let retVal = false;

    if (this._userRights) {
      const thisRight = this._userRights.filter(r => r.userRightId === userRightsId);
      if ((thisRight.length > 0) && (thisRight[0].permission === 'allow')) {
        retVal = true;
      }
    }

    return retVal;
  }

  onNavigate(data: any): void {
    const type = data?.type;
    const row = data?.row;
    if (type === 'openNonEmployeeDriverForm') {
      const driverId = row?.driverId;
      this.openNonEmployeeDriverForm(driverId);
    }
  }

  openNonEmployeeDriverForm(driverId: number) {
    if (driverId) {
      this.router.navigate(['driver/history-profile', driverId.toString()]);
    } else {
      alert('driver id not specified');
    }
  }

  addNonEmployeeDriver(driverProfile: DriverProfile) {
    const queryParams: any = { returnToProfileId: driverProfile.driverId, returnToTab: 'Non-Employees' };
    this.router.navigate(['safety/newnonemployeedriverform', driverProfile.groupGuid, driverProfile.driverId.toString()], { queryParams: queryParams });
  }

  private calcCanAddNewDriver() {
    // calc conditions under which the Add New Driver button can be enabled

    // user right to add driver
    this.canAddNewDriver = this.isAccessAllowed(53);

    // based on multiple Non Employees property
    if (this.canAddNewDriver) {
      if (this.allowMultipleNonEmployees) {
        this.canAddNewDriver = true;
      } else {
        this.canAddNewDriver = (this.secondaryDrivers.filter(s => s.isActive).length < 1);
      }
    }
  }
}

// custom component for active/inactive switch
@Component({
  selector: 'app-safety-secondary-driver-active-switch',
  template: `
      <div class="switch">
      <label>
        <input [checked]="row.isActive" [disabled]="isDisabled" (change)="toggleIsActive(row.driverId, row.primaryDriverId, !row.isActive)" type="checkbox">
        <span class="lever enableWidgetSwitch"></span>
        <span class="enableWidgetLabel">{{row?.isActive?"Active":"Inactive"}}</span>
      </label>
    </div>
  `
})
export class SecondaryDriverActiveSwitchComponent {
  @Input() row: any;
  @Input() isDisabled: boolean;

  constructor(private readonly dhpService: DriverHistoryProfileService,
    private readonly onPremService: OnPremDriverService,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly errorService: ErrorModalService) {
  }

  toggleIsActive(driverId: number, primaryDriverId: number, isActive: boolean) {
    
    this.row.isActive = isActive;
    const ep = (isActive ? 'status/reactivate/' : 'status/terminate/');

    this.loadingSpinnerService.show();
    this.onPremService.put(ep + driverId.toString(), null).subscribe({
      next: (data) => {
        // signal that driver profile data needs to be refreshed
        this.dhpService.setRefreshDriverProfileSecondaryDriverData(primaryDriverId);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }
}
