import { Observable, Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { AzureDocument } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { DocumentLibraryService } from '../../shared/document-library/document-library.service';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'safety-document-library',
  templateUrl: './safety-document-library.component.html',
  styleUrls: ['./safety-document-library.component.css']
})

export class SafetyDocumentLibraryComponent implements OnInit {
  documents: AzureDocument[] = [];
  private clientCode: string;
  clientSelectionSubscription: Subscription;
  clientSelectedArray: Array<string>;
  companyId: number;
  lineOfBusiness: number = 2;

  constructor(private errorService: ErrorModalService,
    private onPremService: OnPremDriverService,
    private loadingSpinnerService: LoadingSpinnerService,
    private clientSelectionService: ClientSelectionService,
    private lineOfBusinessService: LineOfBusinessService,
    private documentLibraryService: DocumentLibraryService) { }

  ngOnInit(): void {
    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusiness);
    this.setLineOfBusiness();
    this.loadDocuments();
  }

  private loadDocuments(): void {
    this.loadingSpinnerService.show();
    this.documentLibraryService.getDocs(this.clientSelectedArray[0], this.lineOfBusiness.toString())
      .subscribe({
        next: (data) => {
          this.documents = data;
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
  }

  private setLineOfBusiness(): void {
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != this.lineOfBusiness) {
      this.lineOfBusinessService.setLineOfBusiness(this.lineOfBusiness);
    }
  }

}
