<div class="quickSearchContainerDataGrid">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" >
  <div class="col-md-12">
    <H3>
      Driver Time Clock Report
    </H3>
  </div>
</div>
<div >
  <mat-sidenav-container class="navbar-dark dashboardFilterPanelActionBar div_top_hypers" [containerInside]="false">
    <mat-sidenav mode="side">Sidenav content</mat-sidenav>
    <ul class="nav navbar-nav nav-flex-icons ml-auto inlineStyle">
      <li class="nav-item inlineStyle">
        <a (click)="onPrint()">
          <span class='fa fa-print'></span> Print
        </a>
      </li>
      <li class="nav-item inlineStyle">
        <a (click)="onExcel()">
          <span class='fa fa-file-excel-o'></span> Export to Excel
        </a>
      </li>
      <li class="nav-item inlineStyle">
        <a (click)="onEmail()">
          <span class='fa fa-envelope'></span>
          Export to Email
        </a>
      </li>
    </ul>
  </mat-sidenav-container>
  <app-data-grid-export-email [lineOfBusiness]="lineOfBusinessId"></app-data-grid-export-email>
  <div class="row">
    <div class="col-md-12" style="text-align:left">
      <span class="font-weight-bold">Report Start Date: </span>{{ datePipe.transform(gridRequest.StartDate,
      'MM/dd/yyyy') }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-data-table
        [columns]="configObject.fields"
        [data]="configObject.data"
        noRecordsLabel="No results"
      ></app-data-table>
      <div class="text-center">
      </div>
      <div id="reportFooter">
        <div class="left"> Fleet Response Confidential</div>
        <div class="right"> Report run {{today | date :'short' }}</div>
      </div>
    </div>
  </div>
</div>