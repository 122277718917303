import { Component, Inject, Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { lastValueFrom, Observable ,  Subject } from "rxjs";
import { ClientSelectionService } from '../client-selection-service/client-selection-service.component';
import { OnPremDriverService } from '../on-prem-service/on-prem-driver-service.component';


@Injectable()
export class QuickSearchService {
  baseUrl: string;
  public showMvrSafetyColumns: boolean = false;
  private dataGridRequest = new Subject();
  dataGridRequest$ = this.dataGridRequest.asObservable();


  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private clientSelectionService: ClientSelectionService,
    private onPremService: OnPremDriverService
  ) {
    this.baseUrl = baseUrl;
  }


  updateGridRequest(request: QuickSearchRequest) {
    this.dataGridRequest.next(request);
  }

  getData(request: QuickSearchRequest): Promise<any> {

    this.dataGridRequest.next(request);
    if (request.LineOfBusinessId == 2) {
      //2019-11 - quick search code will lookup client settings from
      // clientSettingsInfo$ observable in ClientSelectionService
      let cSettingsObj = this.clientSelectionService.getClientSettingsObjectValue();
      let client = request.SelectedClients.join(",");
      if (cSettingsObj && cSettingsObj[client]) {
        this.showMvrSafetyColumns = (cSettingsObj[client].mvrAccount as boolean) && (cSettingsObj[client].profilingAccount as boolean);
      }

      let params = new HttpParams();
      params = params.append('ClientCode', request.SelectedClients.join(","));
      params = params.append('SearchText', request.SearchText);
      params = params.append('LineOfBusinessId', request.LineOfBusinessId.toString());
      params = params.append('IsGlobalSearch', request.SearchAcrossClients ? "true" : "false");

      //this.getClientSettings(request.SelectedClients.join(",")).subscribe(data => {
      //  this.showMvrSafetyColumns = (data as any).mvrAccount as boolean && (data as any).profilingAccount  as boolean;
      //},
      //   (err: HttpErrorResponse) => {
      //     //this.toastrService.error(err.error.toString());
      //     //this.errorService.setErrorObject(err.error);
      //   });

      if (request.SearchColumnFilterId > 0)
        params = params.append('SearchColumnFilterId', request.SearchColumnFilterId.toString());
      return lastValueFrom(this.onPremService.get('entity/search', { params: params }))
        .then(result => {

          let cols: any[];

          cols = [
            { name: 'driverId', prop: 'driverId', title: 'DriverId', visible: true },
            { name: 'employeeId', prop: 'employeeId', title: 'Employee ID', visible: true },
            { name: 'employee', prop: 'employee', title: 'Employee', visible: true },
            { name: 'firstName', prop: 'firstName', title: 'First Name', visible: true },
            { name: 'lastName', prop: 'lastName', title: 'Last Name', visible: true },
            { name: 'driverLicenseState', prop: 'driverLicenseState', title: 'Driver License State', visible: true },
            { name: 'clientName', prop: 'clientName', title: 'Client Name', visible: true },
            { name: 'field', prop: 'field', title: 'Field', visible: true },
            { name: 'matchingValue', prop: 'matchingValue', title: 'Matching Value', visible: true },
            { name: 'corporateHierarchy', prop: 'corporateHierarchy', title: 'Corporate Hierarchy', visible: true },
            { name: 'lastMvrRequestDate', prop: 'lastMvrRequestDate', title: 'Last MVR', dataType: "date", visible: this.showMvrSafetyColumns },
            { name: 'totalPoints', prop: 'totalPoints', title: 'Total Points', visible: this.showMvrSafetyColumns }
          ];


          return { rows: result, columns: cols };
        }).catch();
      //.subscribe(data => {
      //  this.arrDriverInactive = data as Array<DriverInactive>;
      //  this.setTelTableConfig(this.arrDriverInactive);
      //},
      //  (err: HttpErrorResponse) => {
      //    this.errorService.setErrorObject(err.error);
      //  });
    }
    else {
      return lastValueFrom(this.http.post(this.baseUrl + 'api/QuickSearch/Search',
        JSON.stringify(request),
        {
          headers: { 'Content-Type': 'application/json' }
        }))
        .then(result => {
          return result;
        }).catch();
    }
  }

  getFilterColumns(Client: string, LineOfBusiness: number): Promise<any> {
    let params = new HttpParams();
    if (LineOfBusiness == 7)
      //Use Safety LOB for getting the column filters for Delivery Contractors
      params = params.append('LineOfBusinessId', "2");
    else
      params = params.append('LineOfBusinessId', LineOfBusiness.toString());
    params = params.append('Client', Client);

    return lastValueFrom(this.http.get(this.baseUrl + 'api/QuickSearch/GetColumnFilters', {
      params: params
    }))
      .then(result => {
        return result;
      })
      .catch();
  }
  getClientSettings(clientSelected: string) {
    if (!clientSelected || clientSelected == '')
      return;
    this.onPremService.get('lookup/clientSettings/' + clientSelected).subscribe(data => {
      this.showMvrSafetyColumns = (data as any).mvrAccount as boolean && (data as any).profilingAccount as boolean;
    });
  }
}


export interface Column {
  name: string;
  prop: string;
}

export interface GridData {
  Columns: Column[];
  Data: any;
}

@Injectable()
export class QuickSearchRequest {
  SelectedClients: Array<any>;
  LineOfBusinessId: number
  SearchText: string
  SearchColumnFilterId: number
  SearchAcrossClients: boolean;
  dummy: string;
  SortDisplayField: string;
  SortField: string;
  SortDir: string;
  GroupGuid: string;
  public reset() {
    this.SortDir = "";
    this.SortField = "";
    this.SortDisplayField = "";
  }
}
