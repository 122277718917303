<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div  *ngIf="rowsRaw?.length > 0">
  <nav class="navbar navbar-expand-lg navbar-dark dashboardFilterPanelActionBar">
    <ul class="nav navbar-nav nav-flex-icons ml-auto">
      <li class="nav-item">
        <a (click)="onPrint()">
          <span class='fa fa-print'></span> Print
        </a>
      </li>
      <li class="nav-item">
        <a (click)="onExcel()">
          <span class='far fa-file-excel'></span> Export to Excel
        </a>
      </li>
      <li class="nav-item">
        <a (click)="onEmail()">
          <span class='fa fa-envelope'></span>
          Export to Email
        </a>
      </li>
    </ul>
  </nav>
  <app-data-grid-export-email [lineOfBusiness]="lineOfBusinessId"></app-data-grid-export-email>

  <!--<H3>Search Criteria: [  ]</H3>-->
  <h4>Search Date: {{ today | date :'short' }}</h4>
  <app-data-table 
    [columns]="configObject.fields"
    [data]="configObject.data"
    [showPageSize]="true"
    [showPagination]="true"
    (triggerSortChange)="sortChange($event)"
  ></app-data-table>
  <div id="reportFooter">
    <div class="left"> Fleet Response Confidential</div>
    <div class="right"> Report run {{today | date :'short' }}</div>
  </div>
</div>

<div  *ngIf="!rowsRaw || rowsRaw?.length == 0">
  No results
</div>
