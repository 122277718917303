//Angular
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup, UntypedFormControl, AbstractControl, ValidationErrors } from '@angular/forms';
//Third Party
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';
//APP
import { StateProvince } from './../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { ClientReqTitles } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { AdvancedSearchRequest } from '../safety-advanced-search-results/search-advanced-search-service.service';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { SafetyAdvancedSearchService } from './safety-advanced-search.service';
import { MatSelectOption } from '../../shared/models/mat-select-option.model';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';



@Component({
  selector: 'safety-advanced-search',
  templateUrl: './safety-advanced-search.component.html',
  styleUrls: ['./safety-advanced-search.component.scss']
})
export class SafetyAdvancedSearchComponent implements OnInit, AfterViewInit {
  @ViewChild('selectStateProvidence', { static: true }) selectStateProvidence: MatSelect;
  clientSelectionSubscription: Subscription;
  clientSelectedArray: Array<string>;
  clientReqTitles: Array<ClientReqTitles> = [];
  stateProvinceOptions: Array<any>;
  filteredStateProvinceOptions: Array<MatSelectOption>
  lineOfBusinessId: number;

  constructor(private readonly _route: ActivatedRoute,
    private router: Router,
    private loadingSpinnerService: LoadingSpinnerService,
    private readonly clientService: ClientSelectionService,
    public readonly searchRequest: AdvancedSearchRequest,
    private readonly safetySearchService: SafetyAdvancedSearchService,
    private lineOfBusinessService: LineOfBusinessService) {

    this.lineOfBusinessId = 2;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }
    this.clientSelectedArray = this.clientService.getSavedClientShortNames(this.lineOfBusinessId);
  }

  advancedSearchForm = new UntypedFormGroup({
    driverStatusActive: new UntypedFormControl('', [this.validateField]),
    driverStatusInactive: new UntypedFormControl('', [this.validateField]),
    driverStatusLeaveOfAbsence: new UntypedFormControl('', [this.validateField]),
    driverTypeEmployees: new UntypedFormControl('', [this.validateField]),
    driverTypeNonEmployees: new UntypedFormControl('', [this.validateField]),
    clientSelection: new UntypedFormControl(''),
    driverFirstName: new UntypedFormControl('', [this.validateField]),
    driverLastName: new UntypedFormControl('', [this.validateField]),
    driverEmail: new UntypedFormControl('', [this.validateField]),
    driverLicenseNumber: new UntypedFormControl('', [this.validateField]),
    driverId: new UntypedFormControl('', [this.validateField]),
    driverLicenseState: new UntypedFormControl('', [this.validateField])
  });

  get formControls() {
    return this.advancedSearchForm.controls;
  }

  ngOnInit(): void {



    // set default selections
    if (!this.searchRequest.IncludeEmployees && !this.searchRequest.IncludeNonEmployees) this.searchRequest.IncludeEmployees = true;

    this.searchRequest.IsGlobalSearch = false;
    if (!this.searchRequest.IncludeActiveDrivers && !this.searchRequest.IncludeInactiveDrivers && !this.searchRequest.IncludeDriversOnLeaveOfAbsence) this.searchRequest.IncludeActiveDrivers = true;
    if (!this.searchRequest.ClientSelection) this.searchRequest.ClientSelection = 'searchCurrent';

    this.safetySearchService.getStateProvinces().subscribe({
      next: (data) => {
        const tempin: Array<StateProvince> = data as Array<any>;
        this.stateProvinceOptions = [];

        tempin.forEach((item: StateProvince, index: number) => {
          const tempobj = {
            value: item.stateCode,
            label: item.stateName
          };
          this.stateProvinceOptions.push(tempobj);
        });
        this.filteredStateProvinceOptions = this.stateProvinceOptions;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err.error.toString());
      }
    });

  }

  ngAfterViewInit(): void {
    this.selectStateProvidence?.panel?.nativeElement?.addEventListener('keydown', (event) => {
      if (event.code === 'Space') {
        event.preventDefault();
      }
    });
  }


  public search(): void {
    if (this.validateForm()) {
      this.searchRequest.ClientCode = this.clientSelectedArray[0];
      this.searchRequest.LineOfBusinessId = 2;
      if (this.searchRequest.ClientSelection == 'searchAll') this.searchRequest.IsGlobalSearch = true;

      let randomVal = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);

      this.loadingSpinnerService.show();
      this.router.navigate(['/safety/advancedSearch/' + randomVal]);
    }
  }

  filterOptions(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredStateProvinceOptions = this.stateProvinceOptions.filter(option => option.label.toLowerCase().startsWith(filterValue));
  }

  private validateField(control: AbstractControl): ValidationErrors {
    if (!control.value as boolean) {
      return { validateField: true };
    }
    return { validateField: false };
  }

  private validateForm(): boolean {
    if (this.formControls.driverStatusActive.errors.validateField && this.formControls.driverStatusInactive.errors.validateField && this.formControls.driverStatusLeaveOfAbsence.errors.validateField) {
      return false;
    }

    if (this.formControls.driverTypeEmployees.errors.validateField && this.formControls.driverTypeNonEmployees.errors.validateField) {
      return false;
    }

    if (this.formControls.driverFirstName.errors.validateField && this.formControls.driverLastName.errors.validateField && this.formControls.driverEmail.errors.validateField && this.formControls.driverLicenseNumber.errors.validateField && this.formControls.driverId.errors.validateField && this.formControls.driverLicenseState.errors.validateField) {
      return false;
    }


    return true;
  }

}
