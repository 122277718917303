import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { ClientSelectionService, ClientSelectionsObject } from '../../components/client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CorporateStructure } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { AlertModalService } from "../../components/alert-modal/alert-modal-service.component";
import { FormControl, Validators, ValidationErrors } from '@angular/forms';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-mvr-retrieve',
  templateUrl: './mvr-retrieve.component.html',
  styleUrls: ['./mvr-retrieve.component.css']
})
export class MvrRetrieveComponent implements OnInit {
  clientSelectedArray: Array<string> = [];
  mvrTableConfig: any;
  gtOptions: any = {};
  baseUrl: string;

  @Input() pdfType: PdfType;
  @Input() Lob: number;

  constructor(@Inject('BASE_URL') baseUrl: string,
    private router: Router,
    private clientSelectionService: ClientSelectionService,
    private lineOfBusinessService: LineOfBusinessService,
    private onPremService: OnPremDriverService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private alertService: AlertModalService,
    private route: ActivatedRoute,
    private fileDownloadService: FileDownloadService
  ) {
    this.baseUrl = baseUrl;
  }

  ngOnInit() {

    this.route.data.subscribe(data => {
      this.pdfType = data.pdfType;
      this.Lob = data.Lob
      let lob = this.lineOfBusinessService.getLineOfBusinessValue();
      if (lob != this.Lob) {
        this.lineOfBusinessService.setLineOfBusiness(this.Lob);

      }
      console.log(lob + " - " + this.Lob);
      this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.Lob);
    });
    // set line of business to safety and client choice to
    // safety choice, if not already initialized to Safety

    // get the selected client(s) and initial hierarchy

    this.getBatches();
  }

  private setTable(arrMvrBatches: Array<MvrBatch>) {
    let columns = [
      { name: "File", prop: "fileName", title: "File", visible: true, dataType: null, sort: "enable" },
      { name: "From Date", prop: "fromDate", title: "From Date", visible: true, dataType: "date", sort: "enable" },
      { name: "Through Date", prop: "thruDate", title: "Through Date", visible: true, dataType: "date", sort: "enable" },
      { name: "Driver(s) Submitted", prop: "includeDrivers", title: "Driver(s) Submitted", visible: true, dataType: "number", sort: "enable" },
      { name: "Driver(s) Excluded", prop: "excludeDrivers", title: "Driver(s) Excluded", visible: true, dataType: "number", sort: "enable" },
      { name: "Requested", prop: "requestDate", title: "Requested", visible: true, dataType: "date", sort: "enable" },
      { name: "Available Until", prop: "availableDate", title: "Available Until", visible: true, dataType: "date", sort: "enable" }
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: s.sort
      };
    });

    let fields = new Array<any>();

    columns.forEach(s => {
      //console.log("field:" + s.prop);
      fields.push({
        name: s.title,
        objectKey: s.prop,
        columnClass: (s.prop == "fileName") ? 'clickable' : '',
        render: row => {
          //console.log(s.prop)
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              //console.log("date:" + s.prop);
              val = new Date(val).toLocaleDateString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          if (s.prop == "fileName" && !row["isProcessCompleted"]) {
            val = "File not yet available";
          }
          return val;
        },
        click: row => {

          if (s.prop === "fileName" && row["isProcessCompleted"] === true) {
            this.getBatchDocument(row["batchID"].toString(), row["batchDocumentId"].toString(), row["fileName"])
          }
        }
      });
    });

    this.mvrTableConfig = {
      settings: settings,
      fields: fields,
      data: arrMvrBatches
    }

    this.gtOptions = (arrMvrBatches.length > 1 ? { numberOfRows: arrMvrBatches.length } : {});
  }

  private getBatches() {

    this.loadingSpinnerService.show();


    var url: string = "/toPdf/batch/"

    switch (this.pdfType) {
      case PdfType.MVR:
        url = "mvr" + url;
        break;
      case PdfType.COV:
        url = "cov" + url;
        break;
      case PdfType.Training:
        url = "training" + url;
        break;
    }

    this.onPremService.get(url + this.clientSelectedArray.join(','))
      .subscribe({
        next: (data) => {
          //console.log("success");
          //console.log(data);

          this.setTable(data as Array<MvrBatch>);

          this.loadingSpinnerService.hide();


        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });

  }
  getType() {
    return PdfType[this.pdfType];
  }

  onDownload(data: any): void {
    if (data?.columName === 'batchDocument') {
      this.getBatchDocument(data?.batchID?.toString(), data?.batchDocumentId?.toString(), data?.fileName);
    }
  }


  getBatchDocument(batchId, batchDocumentId, filename) {
    this.fileDownloadService
      .saveFile(this.baseUrl + 'api/Safety/GetBatchFile?BatchId=' + batchId + "&BatchDocumentId=" + batchDocumentId, filename);
  }
}

export class MvrBatch {
  batchID: number;
  batchDocumentId: number
  fileName: string;
  fromDate: Date;
  thruDate: Date;
  includeDrivers: number;
  excludeDrivers: number;
  requestDate: Date;
  availableDate: Date;
  isProcessCompleted: boolean
  constructor() { }
}


export enum PdfType {
  MVR,
  COV,
  Training
}
