import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dhp-mvr-custom-row',
  templateUrl: './dhp-mvr-custom-row.component.html',
  styleUrls: ['../../dhp-mvr-report/dhp-mvr-report.component.css']
})
export class DhpMvrCustomRowComponent implements OnInit {
  public extendedInfo: any[];
  @Input() data: any;

  ngOnInit(): void {
    switch (true) {
      case this.data.hasOwnProperty("ordersExtended"):
        this.extendedInfo = this.data.ordersExtended;
        break;
      case this.data.hasOwnProperty("additionalLicensesExtended"):
        this.extendedInfo = this.data.additionalLicensesExtended;
        break;   
        case this.data.hasOwnProperty("violationsExtended"):
          this.extendedInfo = this.data.violationsExtended;
          break;                
      default:
        this.extendedInfo = this.data;
        break;
    }
  }

}
