//Angular
import { Injectable } from '@angular/core';
//Third party
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
//APP
import { ErrorObject } from "../classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalComponent } from './error-modal.component';

@Injectable()
export class ErrorModalService {
  private errorArray: Array<ErrorObject> = [];

  constructor(private dialog: MatDialog) {}

  setErrorObject(e: any) {
    this.errorArray.push(e as ErrorObject);
    this.showModal();
  }

  replaceErrorObject(e: any): void {
    this.errorArray = [];
    this.errorArray.push(e as ErrorObject);
    this.showModal();
  }

  clearErrorObject(): void {
    this.errorArray = [];
  }

  private showModal(): void {
    if(this.errorArray.length){
      const dialogRef = this.dialog.open(ErrorModalComponent,{
        data: {error: this.errorArray},
        width: '30em',
        minHeight: '11em',
        panelClass: 'add-document-modal',
        hasBackdrop: false,
        position: {top: '2em'}
      });
    }
  }
}
