import { AlertModalService } from "../../components/alert-modal/alert-modal-service.component";
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { CompanySelectionObject, DriverTrainingCategory, DriverTrainingCourse } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit, AfterViewChecked, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  DcDriverEntry, DcLookupValue, DcDriverMedicalCard, DcDriverLicense, DcLiveRouteTraining, DcLiveRouteTrainingType,
  DcDriverPreferredLanguage, DcSubscription, DcDriverAssignedOnlineTraining, DcAssignDriverTraining
} from '../dc-classes-and-interfaces/dc-classes-and-interfaces.component';
import { distinctUntilChanged } from 'rxjs/operators';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { UntypedFormControl, Validators, ValidationErrors } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';
import { MatSelectOption } from '../../shared/models/mat-select-option.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Observable, forkJoin, combineLatest, of } from 'rxjs';

@Component({
  selector: 'app-dc-driver-edit',
  templateUrl: './dc-driver-edit.component.html',
  styleUrls: ['./dc-driver-edit.component.scss']
})
export class DcDriverEditComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChildren('medCardExpirationDatePicker') medCardExpirationDatePickerList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('assignOnlineTrainingDatePicker') assignOnlineTrainingDatePickerList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('driversLicenseExpirationDatePicker') driversLicenseExpirationDatePickerList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('roadTestDatePicker') roadTestDatePickerList: QueryList<MatDatepicker<Date>>;
  @ViewChild("trainingCategorySelect", { static: false }) trainingCategorySelect: MatSelect;
  medCardExpirationDatePicker: MatDatepicker<Date>;
  driversLicenseExpirationDatePicker: MatDatepicker<Date>;
  assignOnlineTrainingDatePicker: MatDatepicker<Date>;
  roadTestDatePicker: MatDatepicker<Date>;
  routeParamsSub: Subscription;
  isNewDriver = false;
  driverId: string;
  driverInfo: DcDriverEntry;
  arrLiveRouteTrainingType: Array<DcLiveRouteTrainingType> = [];
  clientSelectedArray: Array<string>;
  lineOfBusinessId: number;
  companyOptions: Array<any> = [];
  stateProvinceOptions: Array<any> = [];
  languageLookup: Array<DcDriverPreferredLanguage> = [];
  languageOptions: Array<any> = [];
  trainingCategoryOptions: Array<any> = [];
  filteredTrainingCategoryOptions: Array<MatSelectOption> = [];
  trainingOptions: Array<any> = [];
  trainingOptionsFiltered: Array<any> = [];
  trainingSlotsUsed: number;
  trainingSlotsPurchased: number;
  trainingSlotsAvailable: number;
  searchText: string;
  categorySearchValue: number;

  expandGeneralInfo = false;
  expandExtendedInfo = false;
  expandLocation = false;
  expandDcInfo = false;
  expandServices = false;
  expandTraining = false;
  allSectionsLoaded = false;
  dateFormat = "MM/dd/yyyy";

  openToSection: string;
  returnToTab: string;
  returnToPage: string;
  returnToProfileId: string;
  returnToOriginPage = false;
  dtToday: Date = new Date();

  employeeIdControl = new UntypedFormControl('', [Validators.required]);
  companyIdControl = new UntypedFormControl('', [Validators.required]);
  firstNameControl = new UntypedFormControl('', [Validators.required]);
  lastNameControl = new UntypedFormControl('', [Validators.required]);
  preferredLanguageControl = new UntypedFormControl('', [Validators.required]);
  driverEmailControl = new UntypedFormControl('', [Validators.email]);
  driverEmailConfirmControl = new UntypedFormControl('', [Validators.email]);
  actualDate = new Date();

  allControls: Array<UntypedFormControl> = [
    this.employeeIdControl,
    this.firstNameControl,
    this.lastNameControl,
    this.driverEmailControl,
    this.driverEmailConfirmControl
  ];

  liveRouteDateControlDictionary: { [id: number]: UntypedFormControl; } = {};
  liveRouteIdControlDictionary: { [id: number]: UntypedFormControl; } = {};
  practiceHoursControlDictionary: { [id: number]: UntypedFormControl; } = {};
  liveRouteTrainingDataLoaded = false;

  // array of form controls for req fields
  allReqControls: Array<UntypedFormControl> = [];


  constructor(private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly clientSelectionService: ClientSelectionService,
    private readonly lineOfBusinessService: LineOfBusinessService,
    private readonly onPremService: OnPremDcService,
    private readonly onPremDriverService: OnPremDriverService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly datePipe: DatePipe,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly errorService: ErrorModalService,
    private readonly alertService: AlertModalService,
    private readonly snackBar: MatSnackBar
  ){  }


  ngOnInit() {
    // set line of business to dc if not already initialized to DC
    this.lineOfBusinessId = 7;
    const lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob !== 7) {
      this.lineOfBusinessService.setLineOfBusiness(7);
    }

    // get client
    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(7);

    const obsComb = combineLatest(this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams }));

    this.routeParamsSub = obsComb.subscribe(ap => {
      this.openToSection = ap.qparams['openToSection'];
      this.returnToTab = ap.qparams['returnToTab'];
      this.returnToPage = ap.qparams['returnToPage'];
      this.returnToProfileId = ap.qparams['returnToProfileId'];
      this.driverId = ap.params['id'];
      if (this.driverId && (this.driverId.length > 0)) {
        this.allControls.push(this.companyIdControl);
        this.initAll(this.driverId);
      } else {
        this.isNewDriver = true;
        this.allControls.push(this.preferredLanguageControl);
        this.initAll(null);
      }
    });

  }

  ngAfterViewInit(): void {
    this.trainingCategorySelect?._elementRef.nativeElement.addEventListener(
      "keydown",
      (event) => {
        if (event.code === "Space") {
          event.preventDefault();
        }
      }
    );
  }


  ngAfterViewChecked() {
    if (!this.medCardExpirationDatePicker && this.medCardExpirationDatePickerList?.first) {

      this.medCardExpirationDatePicker = this.medCardExpirationDatePickerList.first;

      this.medCardExpirationDatePicker.openedStream.subscribe(() => {

        setTimeout(() => {

          this.medCardExpirationDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {

            this.medCardExpirationDatePicker.select(event.value);

            this.medCardExpirationDatePicker.close();

          })

        }, 0)

      })

    }

    if (!this.assignOnlineTrainingDatePicker && this.assignOnlineTrainingDatePickerList?.first) {

      this.assignOnlineTrainingDatePicker = this.assignOnlineTrainingDatePickerList.first;

      this.assignOnlineTrainingDatePicker.openedStream.subscribe(() => {

        setTimeout(() => {

          this.assignOnlineTrainingDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {

            this.assignOnlineTrainingDatePicker.select(event.value);

            this.assignOnlineTrainingDatePicker.close();

          })

        }, 0)

      })

    }


    if (!this.driversLicenseExpirationDatePicker && this.driversLicenseExpirationDatePickerList?.first) {

      this.driversLicenseExpirationDatePicker = this.driversLicenseExpirationDatePickerList.first;

      this.driversLicenseExpirationDatePicker.openedStream.subscribe(() => {

        setTimeout(() => {

          this.driversLicenseExpirationDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {

            this.driversLicenseExpirationDatePicker.select(event.value);

            this.driversLicenseExpirationDatePicker.close();

          })

        }, 0)

      })

    }

    if (!this.roadTestDatePicker && this.roadTestDatePickerList?.first) {
      this.roadTestDatePicker = this.roadTestDatePickerList.first;
      this.roadTestDatePickerList.forEach((datePicker, _i) => {
        datePicker.openedStream.subscribe(() => {
          setTimeout(() => {
            datePicker['_componentRef'].instance._calendar._userSelection.subscribe((event: { value: Date; }) => {
              datePicker.select(event.value);
              datePicker.close();
            })
          }, 0)
        })
      })
    }
  }

  filterCategoryOptions(value: string): void {
    const filterValue = value.toLowerCase();
    this.filteredTrainingCategoryOptions = this.trainingCategoryOptions.filter(option => option.label.toLowerCase().startsWith(filterValue));
  }

  private initAll(driverId: string) {

    if (!this.isNewDriver) {
      // get driver profile first, to get correct company for driver,
      // then get form lookups
      const arrObs: Array<Observable<Object>> = [
        this.onPremService.get<DcDriverEntry>(`drivers/${driverId}`),
        this.onPremService.get('companies')
      ]
      this.loadingSpinnerService.show();
      forkJoin(arrObs).subscribe({
        next: (data) => {
          const dr = data[0] as DcDriverEntry;
          this.clientSelectedArray = [dr.clientCode];
          this.getCompanyOptions(data[1] as Array<CompanySelectionObject>);
          this.getOtherFormInfo(this.clientSelectedArray[0], dr.driverId, dr);
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      })
    } else {
      // 2020-03 - NOTE: we will not be using this form to create new drivers,
      // so options for isNewDriver = true may not work correctly
      //const dr = new DcDriverEntry(this.clientSelectedArray[0], this.companyGuidSelectedArray[0]);
      //this.getOtherFormInfo(dr.clientCode, null, dr);
    }
  }

  private getOtherFormInfo(clientSelected: string, driverId: number, driverToInit: DcDriverEntry) {
    // don't do languages lookup unless a stateProvince is defined
    let licensesObs: Observable<Object> = of([]);
    if (driverToInit.driverLicense?.stateProvince?.length > 0) {
      licensesObs = this.onPremService.get(`lookup/${clientSelected}/languages/${driverToInit.driverLicense.stateProvince}`);
    }
    const companyId = this.companyOptions.find(c => c.value === driverToInit.groupGuid).companyId;
    const arrObs: Array<Observable<Object>> = [
      this.onPremService.get(`lookup/${clientSelected}/states`),
      licensesObs,
      this.onPremService.get(`companies/${companyId}/training/summary`),
      this.onPremDriverService.get(`training/lessons/categories/${clientSelected}${(driverToInit.driverId ? '/' + driverToInit.driverId : '')}`),
      this.onPremDriverService.get(`training/lessons/${clientSelected}${(driverToInit.driverId ? '/' + driverToInit.driverId : '')}`)
    ]

    if (!this.isNewDriver) {
      arrObs.push(this.onPremService.get(`training/liveroute/${driverId.toString()}`));
    }

    this.loadingSpinnerService.show();
    forkJoin(arrObs).subscribe({
      next: (data) => {
        this.getStateProvinceOptions(data[0] as Array<DcLookupValue>);
        this.languageLookup = (data[1] as Array<DcDriverPreferredLanguage>);
        this.getLanguageOptions(this.languageLookup);
        const trainingSummary = data[2] as DcSubscription;
        this.trainingSlotsUsed = trainingSummary.slotsUsed;
        this.trainingSlotsPurchased = trainingSummary.slotsPurchased;
        this.trainingSlotsAvailable = trainingSummary.slotsAvailable;
        this.getTrainingCategoryOptions(data[3] as Array<DriverTrainingCategory>);
        this.getTrainingOptions(data[4] as Array<DriverTrainingCourse>);

        if (!this.isNewDriver) {
          this.arrLiveRouteTrainingType = data[5] as Array<DcLiveRouteTrainingType>;

          this.arrLiveRouteTrainingType.forEach(tt => {

            tt.liveRouteTraining.forEach(tr => {
              // convert dates to MM/dd/yyyy so they will work with date picker
              tr.trainingDate = (tr.trainingDate ? new Date(this.datePipe.transform(tr.trainingDate, 'MM/dd/yyyy')) : null);
              // create a form control for each date and id field
              this.liveRouteDateControlDictionary[tr.liveRouteTrainingId] = new UntypedFormControl('', []);
              this.allControls.push(this.liveRouteDateControlDictionary[tr.liveRouteTrainingId]);
              this.liveRouteDateControlDictionary[tr.liveRouteTrainingId].valueChanges.pipe(distinctUntilChanged()).subscribe(v => { this.validateLiveRouteLine(tr.liveRouteTrainingId, tt.isPracticeHours, tt.isRoadTest, tt.isLiveRoute) });


              this.liveRouteIdControlDictionary[tr.liveRouteTrainingId] = new UntypedFormControl('', []);
              this.allControls.push(this.liveRouteIdControlDictionary[tr.liveRouteTrainingId]);
              this.liveRouteIdControlDictionary[tr.liveRouteTrainingId].valueChanges.pipe(distinctUntilChanged()).subscribe(v => { this.validateLiveRouteLine(tr.liveRouteTrainingId, tt.isPracticeHours, tt.isRoadTest, tt.isLiveRoute) });


              this.practiceHoursControlDictionary[tr.liveRouteTrainingId] = new UntypedFormControl('', []);
              this.allControls.push(this.practiceHoursControlDictionary[tr.liveRouteTrainingId]);
              this.practiceHoursControlDictionary[tr.liveRouteTrainingId].valueChanges.pipe(distinctUntilChanged()).subscribe(v => { this.validateLiveRouteLine(tr.liveRouteTrainingId, tt.isPracticeHours, tt.isRoadTest, tt.isLiveRoute) });

            });

            this.liveRouteTrainingDataLoaded = true;
          });
        }

        if (driverToInit) {
          // init assign online training due date to 3 weeks from today
          const dtAssignTrainingDueDate: Date = new Date((new Date()).toDateString());
          dtAssignTrainingDueDate.setDate(dtAssignTrainingDueDate.getDate() + 21);
          driverToInit.assignOnlineTrainingDateDue = new Date(this.datePipe.transform(dtAssignTrainingDueDate, this.dateFormat));
          this.initDriverInfo(driverToInit);
        }

        // need detectChanges call to remove error related to field validation
        this.cdRef.detectChanges();

        this.initSections();

        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

  }

  private initDriverInfo(driverProfile: DcDriverEntry) {
    driverProfile.emailAddressConfirm = driverProfile.emailAddress;
    if (!this.isNewDriver) {

			const strSpace =' ';																											 

      driverProfile.label = `${driverProfile.firstName}${driverProfile.middleName && driverProfile.firstName.length > 0 ? 
                            strSpace + driverProfile.middleName : ''}${strSpace}${driverProfile.lastName}`;


      if (driverProfile.preferredLanguage && driverProfile.preferredLanguage.id) {
        driverProfile.languageId = driverProfile.preferredLanguage.id.toString();
      }
      if (driverProfile.medicalCard && driverProfile.medicalCard.expirationDate) {
        driverProfile.medicalCardExpirationDate = new Date(this.datePipe.transform(driverProfile.medicalCard.expirationDate, this.dateFormat));
      }

      if (driverProfile.driverAssignedOnlineTraining && driverProfile.driverAssignedOnlineTraining.dueDate) {
        driverProfile.assignOnlineTrainingDateDue = new Date(this.datePipe.transform(driverProfile.driverAssignedOnlineTraining.dueDate, this.dateFormat));
      }

      if (driverProfile.driverLicense) {
        if (driverProfile.driverLicense.expirationDate) {
          driverProfile.driverLicenseExpirationDate = new Date(this.datePipe.transform(driverProfile.driverLicense.expirationDate, this.dateFormat));
        }
        if (driverProfile.driverLicense.stateProvince) {
          driverProfile.locationId = driverProfile.driverLicense.stateProvince;
        }
      }
    }

    // create array of manager emails
    const arrMgr = Array.from({ length: 7 }).map(x => "");
    if (driverProfile.managerTrainerEmailAddresses) {
      for (let i = 0; i < 7; i++) {
        const addr = driverProfile.managerTrainerEmailAddresses[i];
        if (addr) {
          arrMgr[i] = addr;
        }
      }
    }
    driverProfile.managerTrainerEmailAddresses = arrMgr;

    this.driverInfo = driverProfile;
  }

  private getTrimmedValue(val: any) {
    return (val ? val.toString().trim() : null);
  }

  private initSections() {
    if (this.isNewDriver) {
      //expand all sections if new
      this.expandGeneralInfo = true;
      this.expandExtendedInfo = true;
      this.expandLocation = true;
      this.expandDcInfo = true;
      this.expandServices = true;
    } else {
      if (!this.openToSection) {
        // expand all except services
        this.expandGeneralInfo = true;
        this.expandExtendedInfo = true;
        this.expandDcInfo = true;
        this.expandLocation = true;
      } else {
        switch (this.openToSection) {
          case "Training":
            this.expandTraining = true;
            break;
          case "Services":
            this.expandServices = true;
            break;
        }
      }
    }
    this.allSectionsLoaded = true;
  }

  private getCompanyOptions(tempin: Array<CompanySelectionObject>) {
    // get choices for Group dropdown
    this.companyOptions = [];

    tempin.forEach((item: CompanySelectionObject, index: number) => {
      const tempobj = {
        value: item.groupGuid.toString(),
        label: item.description,
        companyId: item.id
      };
      this.companyOptions.push(tempobj);
    });

  }

  private getStateProvinceOptions(tempin: Array<DcLookupValue>) {
    // get choices for state dropdown
    this.stateProvinceOptions = [];

    tempin.forEach((item: DcLookupValue, index: number) => {
      const tempobj = {
        value: item.code,
        label: item.description
      };
      this.stateProvinceOptions.push(tempobj);
    });
  }

  private getLanguageOptions(tempin: Array<DcDriverPreferredLanguage>) {
    // get choices for language dropdown
    this.languageOptions = [];

    tempin.forEach((item: DcDriverPreferredLanguage, index: number) => {
      const tempobj = {
        value: item.id.toString(),
        //value: item.code,
        label: item.description
      };
      this.languageOptions.push(tempobj);
    });

  }

  private getTrainingCategoryOptions(tempin: Array<DriverTrainingCategory>) {
    this.trainingCategoryOptions = [];
    this.trainingCategoryOptions.push({ label: '-- No Category --', value: 0 });

    tempin.forEach((item: DriverTrainingCategory, index: number) => {
      const tempobj = {
        value: item.id,
        label: item.description
      };
      this.trainingCategoryOptions.push(tempobj);
    });
    this.filteredTrainingCategoryOptions = this.trainingCategoryOptions;
  }

  private getTrainingOptions(tempin: Array<DriverTrainingCourse>) {
    this.trainingOptionsFiltered = [];
    this.trainingOptions = [];

    tempin.forEach((item: DriverTrainingCourse, index: number) => {
      const tempobj = {
        value: `${item.courseGroupId}~${item.passRate.toString()}`,
        label: `${item.title} PASS RATE: ${item.passRate.toString()}%`,
        courseGroupId: item.courseGroupId,
        courseCategoryId: item.courseCategoryId,
        passRate: item.passRate,
        selected: false,
        disableMoreTraining: (this.trainingSlotsAvailable <= 0)
      };
      this.trainingOptions.push(tempobj);
      this.trainingOptionsFiltered.push(tempobj);
    });

  }

  onStateChange(e: any) {
    // update language selections when state changes
    if (e) {
      const stateCurr = this.driverInfo.locationId;
      const languageCurr = this.driverInfo.languageId;
      if (e !== stateCurr) {
        this.loadingSpinnerService.show();
        this.onPremService.get(`lookup/${this.clientSelectedArray[0]}/languages/${stateCurr}`).subscribe({
          next: (data) => {
            this.languageLookup = (data as Array<DcDriverPreferredLanguage>);
            this.getLanguageOptions(this.languageLookup);
            if (this.languageOptions.findIndex(o => o.value === languageCurr)) {
              // set languageId to current id if its in the updated language list
              this.driverInfo.languageId = languageCurr;
            } else {
              this.driverInfo.languageId = null;
            }
            this.loadingSpinnerService.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err.error);
            this.loadingSpinnerService.hide();
          }
        }
        )
      }
    }
  }

  validateLiveRouteLine(trId: number, isPracticeHours: boolean, isRoadTest: boolean, isLiveRoute: boolean) {
    // checks that for a given training id, both date and observer id are either
    // both populated, or both blank. Otherwise, mark for validation

    let lineValid: boolean;
    const dateVal = this.liveRouteDateControlDictionary[trId].value;
    const hoursVal =  this.practiceHoursControlDictionary[trId].value;
    const idVal = this.liveRouteIdControlDictionary[trId].value;


    if (!isPracticeHours) {
      if (((!dateVal) || (dateVal.length <= 0)) && ((!idVal) || (idVal.length <= 0))) {
        // both date and id empty
        lineValid = false;
      }

      if (dateVal && idVal && (dateVal.length > 0) && (idVal.length > 0)) {
        // both date and id populated
        lineValid = false;
      }
    }

    if (isPracticeHours && !isLiveRoute) {
      if (hoursVal && idVal && (hoursVal.toString().length > 0) && (idVal.length > 0)) {
        // both hours and id populated
        lineValid = false;
      }

      if (((!hoursVal) || (hoursVal.toString().length <= 0)) && ((!idVal) || (idVal.length <= 0))) {
        // both hours and id empty
        lineValid = false;
      }
    }

    if (isPracticeHours && isLiveRoute) {
      if (hoursVal && idVal && dateVal && (hoursVal.toString().length > 0) && (idVal.length > 0) && (dateVal.length > 0)) {
        // hours, date and id populated
        lineValid = false;
      }

      if (((!hoursVal) || (hoursVal.toString().length <= 0)) && ((!idVal) || (idVal.length <= 0)) && ((!dateVal) || (dateVal.length <= 0))) {
        // hours, date or id empty
        lineValid = false;
      }
    }

    if (!lineValid) {
      this.liveRouteDateControlDictionary[trId].setValidators(null);
      this.liveRouteDateControlDictionary[trId].updateValueAndValidity();
      this.liveRouteIdControlDictionary[trId].setValidators(null);
      this.liveRouteIdControlDictionary[trId].updateValueAndValidity();
      this.practiceHoursControlDictionary[trId].setValidators(null);
      this.practiceHoursControlDictionary[trId].updateValueAndValidity();
    }else {
      this.liveRouteDateControlDictionary[trId].setValidators([Validators.required]);
      this.liveRouteDateControlDictionary[trId].updateValueAndValidity();
      this.liveRouteIdControlDictionary[trId].setValidators([Validators.required]);
      this.liveRouteIdControlDictionary[trId].updateValueAndValidity();
      this.practiceHoursControlDictionary[trId].setValidators([Validators.required]);
      this.practiceHoursControlDictionary[trId].updateValueAndValidity();
    }
    
  }

  onTextSearch() {
    // filter training options by both search criteria
    this.trainingOptionsFiltered = this.trainingOptions.filter(t => this.combinedSearchFunction(t));
    // unselect any options that are not in the filtered options
    this.trainingOptions.filter(t => !this.combinedSearchFunction(t)).forEach(o => o.selected = false);
  }

  onCategorySearch(event: any) {
    // filter training options by both search criteria
    this.trainingOptionsFiltered = this.trainingOptions.filter(t => this.combinedSearchFunction(t));
    // unselect any options that are not in the filtered options
    this.trainingOptions.filter(t => !this.combinedSearchFunction(t)).forEach(o => o.selected = false);
  }

  private combinedSearchFunction(trainingOption: any) {
    // function that checks whether a training option meets both saerch criteria
    let matchesText = true;
    let matchesCat = true;

    if (this.searchText && (this.searchText.length >= 3)) {
      matchesText = (trainingOption.label.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0);
    }

    if (this.categorySearchValue > 0) {
      matchesCat = (trainingOption.courseCategoryId === this.categorySearchValue);
    }

    return matchesText && matchesCat;
  }

  checkTraining(i: number) {
    // prevents check if training selections > slots available
    const trainingCount = this.trainingOptionsFiltered.filter(t => t.selected).length;
    // disable further training selections if selected exceeds slots available
    const disableTraining = (trainingCount >= this.trainingSlotsAvailable)
    this.trainingOptionsFiltered.filter(t => !t.selected).forEach(t => {
      t.disableMoreTraining = disableTraining;
    })
  }

  private validateDriverForm(): boolean {
    let isOK = true;

    //must trigger email match check to reset the invalid flag
    if (this.validateEmail() === false) {
      isOK = false;
    }
    else if (this.allControls.filter(c => (c.invalid)).length > 0) {
      this.errorService.setErrorObject({ message: 'Missing or Invalid Fields.' });
      isOK = false;
    }

    return isOK;
  }


  private validateEmail(): boolean {
    let isOK = true;

    // check email fields
    if (this.driverEmailControl.value !== this.driverEmailConfirmControl.value) {
      isOK = false;
      // set errors on email fields
      this.errorService.setErrorObject({ message: 'Email Fields must match.' });
      this.addValidationError(this.driverEmailControl, 'emailmatch');
      this.addValidationError(this.driverEmailConfirmControl, 'emailmatch');
    } else {
      // clear errors on email fields
      this.removeValidationError(this.driverEmailControl, 'emailmatch');
      this.removeValidationError(this.driverEmailConfirmControl, 'emailmatch');
    }

    return isOK;
  }

  private addValidationError(ctrl: UntypedFormControl, key: string) {
    if (ctrl) {
      let errs: ValidationErrors = ctrl.errors;

      if (errs) {
        errs[key] = true;
      } else {
        errs = {};
        errs[key] = true;
      }
      ctrl.setErrors(errs);
    }

  }

  private removeValidationError(ctrl: UntypedFormControl, key: string) {
    if (ctrl) {
      let errs: ValidationErrors = ctrl.errors;

      if (errs) {
        delete errs[key];
      }

      if (errs && (Object.keys(errs).length <= 0)) {
        errs = null;
      }

      ctrl.setErrors(errs);
    }

  }

  validateBeforeSubmit() {
    if (this.validateDriverForm()) {
      let isLinked = false;
      const postData = { emailAddress: this.driverInfo.emailAddress };
      this.onPremService.post(`drivers/${this.driverInfo.driverId.toString()}/islinkedaccount`, JSON.stringify(postData))
        .subscribe({
          next: (data) => {
            isLinked = data as boolean;
            if (!isLinked) {
              //no action
              this.submitDriverForm("ignore");
            } else {
              //unlink the driver
              this.submitDriverForm("unlink");
            }
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err.error);
            this.loadingSpinnerService.hide();
          }
        });
    }
  }

  private submitDriverForm(authAction: string) {
    if (this.validateDriverForm()) {
      // get copy of driverInfo object
      this.driverInfo.medicalCard.expirationDate = (this.driverInfo.medicalCardExpirationDate ? this.driverInfo.medicalCardExpirationDate.toISOString() : null);
      this.driverInfo.driverLicense.expirationDate = (this.driverInfo.driverLicenseExpirationDate ? this.driverInfo.driverLicenseExpirationDate.toISOString() : null);
      const postData = JSON.parse(JSON.stringify(this.driverInfo));

      postData.AuthAction = authAction;

      const doResetPassword = this.driverInfo.resetPassword;

      // create objects from input
      // medical card
      let medicalCard = null;
      if (!this.driverInfo.medicalCard) {
        medicalCard = new DcDriverMedicalCard();
      } else {
        medicalCard = JSON.parse(JSON.stringify(this.driverInfo.medicalCard));
      }
      if (this.driverInfo.medicalCardExpirationDate && (this.driverInfo.medicalCardExpirationDate.toString.length > 0)) {
        medicalCard.expirationDate = new Date(this.driverInfo.medicalCardExpirationDate).toISOString().slice(0, 10);
      }
      postData.medicalCard = medicalCard;

      // assigned Online Training
      let assignedOnlineTraining = null;
      if (!this.driverInfo.driverAssignedOnlineTraining) {
        assignedOnlineTraining = new DcDriverAssignedOnlineTraining();
      } else {
        assignedOnlineTraining = JSON.parse(JSON.stringify(this.driverInfo.driverAssignedOnlineTraining));
      }
      if (this.driverInfo.assignOnlineTrainingDateDue && (this.driverInfo.assignOnlineTrainingDateDue.toString.length > 0)) {
        assignedOnlineTraining.assignedOnlineTrainingDueDate = new Date(this.driverInfo.assignOnlineTrainingDateDue).toISOString().slice(0, 10);
      }
      postData.assignedOnlineTraining = assignedOnlineTraining;

      // license
      let driverLicense = null;
      if (!this.driverInfo.driverLicense) {
        driverLicense = new DcDriverLicense();
      } else {
        driverLicense = JSON.parse(JSON.stringify(this.driverInfo.driverLicense));
      }

      if (this.driverInfo.driverLicenseExpirationDate && (this.driverInfo.driverLicenseExpirationDate.toString.length > 0)) {
        driverLicense.expirationDate = new Date(this.driverInfo.driverLicenseExpirationDate).toISOString().slice(0, 10);
      }
      if (this.driverInfo.locationId && (this.driverInfo.locationId.length > 0)) {
        driverLicense.stateProvince = this.driverInfo.locationId;
      }
      postData.driverLicense = driverLicense;

      // preferred language
      let preferredLanguage = null;
      if (!this.driverInfo.preferredLanguage) {
        preferredLanguage = new DcDriverPreferredLanguage();
      } else {
        preferredLanguage = JSON.parse(JSON.stringify(this.driverInfo.preferredLanguage));
      }
      if (this.driverInfo.languageId && (this.driverInfo.languageId.length > 0)) {
        preferredLanguage = this.languageLookup.find(lang => (+this.driverInfo.languageId) === lang.id);
      }
      postData.preferredLanguage = preferredLanguage;

      // delete unneeded properties
      delete postData.label;
      delete postData.companyIdString;
      delete postData.driverCompanyLabel;
      delete postData.languageId;
      delete postData.locationId;
      delete postData.emailAddressConfirm;
      delete postData.medicalCardExpirationDate;
      delete postData.driverLicenseExpirationDate;
      delete postData.resetPassword;

      console.log(postData);
      const updateObs: Observable<Object> = (this.isNewDriver ? this.onPremService.post('drivers', JSON.stringify(postData)) : this.onPremService.put('drivers/' + this.driverId, JSON.stringify(postData)));

      this.loadingSpinnerService.show();
      updateObs.subscribe({
        next: (data) => {
          const arrObs: Array<Observable<Object>> = [];
          // PUT reset password if selected
          if (doResetPassword) {
            arrObs.push(this.onPremService.put(`drivers/${this.driverInfo.driverId.toString()}/resetpassword`, null));
          }

          const liveRouteTrainingObservable = this.getLiveTrainingObservable();
          if (liveRouteTrainingObservable) {
            arrObs.push(liveRouteTrainingObservable);
          }

          if (this.driverInfo.assignOnlineTraining) {

          const strTask = 'tasks/';
          const strTraining = '/training';
          const companyId = this.companyOptions.find(c => c.value === this.driverInfo.groupGuid).companyId;

            const arrTraining = this.trainingOptionsFiltered.filter(t => t.selected);
            const obj: DcAssignDriverTraining = {
              TrainingAssignmentType: 0,
              TrainingDriverIdList: this.driverInfo.driverId.toString(),
              TrainingDueDate: this.datePipe.transform(this.driverInfo.assignOnlineTrainingDateDue, this.dateFormat),
              TrainingCourseGroupList: arrTraining.map(t => t.courseGroupId).join(","),
              TrainingPassRateList: arrTraining.map(t => t.passRate).join(",")
            };


          arrObs.push(this.onPremService.post(strTask + companyId + strTraining, JSON.stringify(obj)));

          }


          const strNewDriverCreated = 'New Driver Created.';
          const strDriverUpdated = 'Driver Updated.';
          const strSuccessSnackbar = 'success-snackbar';

          if (arrObs.length > 0) {
            forkJoin(arrObs).subscribe({
              next: () => {
                if (this.isNewDriver) {
                  // return to originating page
                  this.snackBar.open(strNewDriverCreated, strNewDriverCreated, {
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                    duration: 5000,
                    panelClass: strSuccessSnackbar
                  });
                } else {
                  // go to driver profile
                  this.snackBar.open(strDriverUpdated, strDriverUpdated, {
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                    duration: 5000,
                    panelClass: strSuccessSnackbar
                  });
                  this.loadingSpinnerService.hide();
                  this.exitDriverForm(true);
                }
              },
              error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
              }
            });
          } else {
            // no additional post/put
            if (this.isNewDriver) {
              // return to originating page
              this.snackBar.open(strNewDriverCreated, strNewDriverCreated, {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                duration: 5000,
                panelClass: strSuccessSnackbar
              });
            } else {
              // go to driver profile
              this.snackBar.open(strDriverUpdated, strDriverUpdated, {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                duration: 5000,
                panelClass: strSuccessSnackbar
              });
              this.loadingSpinnerService.hide();
              this.exitDriverForm(true);
            }
          }
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    }

  }

  private getLiveTrainingObservable() {
    let changesMade = false;
    Object.keys(this.liveRouteDateControlDictionary).forEach(key => {
      if (this.liveRouteDateControlDictionary[key].dirty) {
        changesMade = true;
      }
    });
    Object.keys(this.liveRouteIdControlDictionary).forEach(key => {
      if (this.liveRouteIdControlDictionary[key].dirty) {
        changesMade = true;
      }
    });

    Object.keys(this.practiceHoursControlDictionary).forEach(key => {
      if (this.practiceHoursControlDictionary[key].dirty) {
        changesMade = true;
      }
    });
    if (changesMade) {
      if (this.arrLiveRouteTrainingType.length > 0) {
        let arrLiveRouteTraining: Array<DcLiveRouteTraining> = [];
        this.arrLiveRouteTrainingType.forEach(tt => {
          tt.liveRouteTraining.forEach(tr => {
            if (tr.trainingDate || null) {
              try {
                tr.trainingDate = new Date(tr.trainingDate).toISOString().slice(0, 10);
              } catch (e) {
                tr.trainingDate = null;
              }
            } else {
              tr.trainingDate = null;
            }

          });
          arrLiveRouteTraining = arrLiveRouteTraining.concat(tt.liveRouteTraining);
        });
        return this.onPremService.put(`training/liveroute/${this.driverId.toString()}`, JSON.stringify(arrLiveRouteTraining));
      } else {
        return null;
      }
    } else {
      return null;
    }

  }

  exitDriverForm(forceReload: boolean) {
    let returnToProfileId = null;
    if (this.returnToProfileId) {
      returnToProfileId = this.returnToProfileId;
    } else {
      returnToProfileId = this.driverId;
    }

    if (returnToProfileId && (returnToProfileId.length > 0)) {
      if (this.returnToTab) {
        this.router.navigate(['dc/driverprofile', returnToProfileId], { queryParams: { openToTab: this.returnToTab } });
      } else {
        this.router.navigate(['dc/driverprofile', returnToProfileId]);
      }

    } else {
      if (this.returnToPage) {
        this.router.navigate([`dc/${this.returnToPage}`], (forceReload ? { queryParams: { forceReload: 'true' } } : {}));
      } else {
        this.router.navigate(['stacenter/7']);
      }
    }
  }

  private arrayCompare(a1, a2) {
    return (a1.length === a2.length && a1.every((v, i) => v === a2[i]));
  }

  private indexTracker(index: number, value: any) {
    return index;
  }

  ngOnDestroy() {
    this.routeParamsSub.unsubscribe();
  }
}
