import { Component, Inject, Injectable } from '@angular/core';
import { Observable ,  Subject } from "rxjs";
import { DataGrid } from '../../components/data-grid-menu-component/data-grid-menu.component'
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class CustomReportsModalService {
    _http: HttpClient
    _baseUrl: string

    private reportId = new Subject();
    reportId$ = this.reportId.asObservable();

    private reportName = new Subject();
    reportName$ = this.reportName.asObservable();

    private gridFilters = new Subject();
    gridFilters$ = this.gridFilters.asObservable();

    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this._http = http;
        this._baseUrl = baseUrl;
    }

    openModal(reportId: number, reportName: string, title: string) {
        this.reportId.next(reportId);
        this.reportName.next(reportName);
    }
}

export class DataGridFilter {
    dataGridFilterId: number;
    dataGridId: number;
    dataGridFilterTypeId: number;
    filterType: string;
    displayName: string;
    dbName: string;
    textField: string;
    valueField: string;
    isMultiSelect: boolean;
    dataGridFilterRangeTypeId: number;
    rangeType: string;
    filterOptions: any[];
    value: any;
}
