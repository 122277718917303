//angular libraries
import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
//thirdparty libraries
import { Subscription, Observable, forkJoin } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
//project dependencies
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { DriverProfile, DQFileManagementBaseInfo, DQFileManagementEmployerInfo, PhoneUsPipe, ErrorObject } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
//#AngularUpgrade
import { DQRejectModalComponent, DqRejectModalReason } from '../dq-reject-modal/dq-reject-modal.component';
import { DQApproveModalComponent } from '../dq-approve-modal/dq-approve-modal.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';


@Component({
  selector: 'app-dq-application-review',
  templateUrl: './dq-application-review.component.html',
  styleUrls: ['./dq-application-review.component.scss'],
  providers: [PhoneUsPipe]
})

export class DQApplicationReviewComponent implements OnInit, OnDestroy {
  private routeSub: Subscription;
  private driverId: string;
  private driverProcessAssignedId: string;
  private returnUrl: string;
  driverAppReview: DQApplicationReviewInfo;
  driverAppReviewSubmitInfo: DriverApplicationSubmitInfo;
  employerSubmitInfo: DriverApplicationEmployerSubmitInfo;
  driverInfo: DriverProfile;
  rejectOptions: Array<any>;
  arrFileName: Array<string> = [];
  tableConfig: any;
  gtOptions: any = {};
  dtToday: Date = new Date();
  @ViewChildren('employerStartDate') employerStartDateList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('employerEndDate') employerEndDateList: QueryList<MatDatepicker<Date>>;
  employerStartDate: MatDatepicker<Date>;
  employerEndDate: MatDatepicker<Date>;
  actualDate = new Date();

  employerStartDateControl = new UntypedFormControl('', [Validators.required]);
  employerEndDateControl = new UntypedFormControl('', [Validators.required]);

  allControls: Array<UntypedFormControl> = [
    this.employerStartDateControl,
    this.employerEndDateControl
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private onPremService: OnPremDriverService,
    private lineOfBusinessService: LineOfBusinessService,
    private driverHistoryProfileService: DriverHistoryProfileService,
    private phoneNumberPipe: PhoneUsPipe,
    private datePipe: DatePipe,
    private loadingSpinnerService: LoadingSpinnerService,
    private snackBar: MatSnackBar,
    private errorService: ErrorModalService,
    private dialog: MatDialog
  ) {
    //get the returnUrl if set
    var nav = this.router.getCurrentNavigation();
    this.returnUrl = nav?.extras?.state?.returnUrl || "";
  }

  ngOnInit() {
    //make sure LOB=2
    //this.lineOfBusinessService.setLineOfBusiness(2);
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    this.routeSub = this.route.params.subscribe(params => {
      this.driverId = params['driverId'];
      this.driverProcessAssignedId = params['driverProcessAssignedId'];
      this.initAll(true, true, true);
    });
  }

  ngAfterViewChecked(): void {
    if (!this.employerStartDate && this.employerStartDateList?.first) {
      this.employerStartDate = this.employerStartDateList.first;
      this.employerStartDate.openedStream.subscribe(() => {
        setTimeout(() => {
          this.employerStartDate['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.employerStartDate.select(event.value);
            this.employerStartDate.close();
          })
        }, 0)
      })
    }

    if (!this.employerEndDate && this.employerEndDateList?.first) {
      this.employerEndDate = this.employerEndDateList.first;
      this.employerEndDate.openedStream.subscribe(() => {
        setTimeout(() => {
          this.employerEndDate['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.employerEndDate.select(event.value);
            this.employerEndDate.close();
          })
        }, 0)
      })
    }
  }

  private initAll(clearUserEdits: boolean, clearEmployerInput: boolean, updateBaseInfo: boolean) {
    let endpoint = "";
    if (this.driverProcessAssignedId) {
      endpoint = "driverQualification/" + this.driverId + "/application/" + this.driverProcessAssignedId;
    } else {
      endpoint = "driverQualification/" + this.driverId + "/application/latest";
    }
    if (endpoint.length > 0) {
      this.loadingSpinnerService.show();
      this.onPremService.get(endpoint).subscribe({
        next: (data) => {
          this.driverAppReview = data as DQApplicationReviewInfo;
          if (!this.driverAppReview.documentId) {
            //alert('no document, go to DQ File Management view');
            this.returnToListing();
          } else {
            if (clearUserEdits) {
              this.driverAppReviewSubmitInfo = new DriverApplicationSubmitInfo(this.driverAppReview);
              this.arrFileName = [this.driverAppReview.fileName];
            }

            if (clearEmployerInput) {
              this.employerSubmitInfo = new DriverApplicationEmployerSubmitInfo(this.driverAppReview);
              if (this.employerStartDate && this.employerEndDate) {
                this.employerStartDate = null;
                this.employerEndDate = null;
              }
            }

            if (updateBaseInfo) {
              let arrObs: Array<Observable<Object>> = [
                this.driverHistoryProfileService.getDriverHistoryProfileBaseData(this.driverId),
                this.onPremService.get("lookup/rejectionreasons/" + this.driverAppReview.processId.toString())
              ];

              forkJoin(arrObs).subscribe({
                next: (profile) => {
                  this.driverInfo = profile[0] as DriverProfile;
                  let rejectOptions: Array<DriverApplicationRejectReason> = profile[1] as Array<DriverApplicationRejectReason>;
                  this.processRejectOptions(rejectOptions);
                  this.loadingSpinnerService.hide();
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
            }

            //this.getDriverInfo(this.driverId);
            //this.getRejectOptions(this.driverAppReview.processId);
            this.setEmployerHistoryTableConfig(this.driverAppReview.employmentHistory);
          }
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    } else {
      this.errorService.setErrorObject({ message: 'Invalid Parameters' });
    }
  }

  private getDriverInfo(driverId: string) {
    this.driverHistoryProfileService.getDriverHistoryProfileBaseData(driverId).subscribe({
      next: (data) => {
        this.driverInfo = data as DriverProfile;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private setEmployerHistoryTableConfig(employerHistory: Array<DQFileManagementEmployerInfo>) {
    let columns = [
      { name: "Employer Name", prop: "employerName", title: "Employer Name", visible: true, dataType: null },
      { name: "Phone", prop: "employerPhone", title: "Phone", visible: true, dataType: "phone" },
      { name: "From", prop: "startDate", title: "From", visible: true, dataType: "date" },
      { name: "To", prop: "endDate", title: "To", visible: true, dataType: "date" },
      { name: "Position Name", prop: "positionHeld", title: "Position Name", visible: true, dataType: null }
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        render: row => {
          let r = row[s.prop];

          switch (s.dataType) {
            case "date":
              try {
                r = this.datePipe.transform(r, 'MM/dd/yyyy');
              } catch (e) { }
              break;
            case "phone":
              try {
                r = this.phoneNumberPipe.transform(r);
              } catch (e) { }
              break;
            default:
              r = row[s.prop];
          }

          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }

          return val;
        }
      });
    });

    this.tableConfig = {
      settings: settings,
      fields: fields,
      data: employerHistory
    }

    this.gtOptions = (employerHistory.length > 1 ? { numberOfRows: employerHistory.length } : {});
  }

  private processRejectOptions(tempin: Array<DriverApplicationRejectReason>) {
    const tempout: Array<DqRejectModalReason> = [];
    tempin.forEach((item: DriverApplicationRejectReason, index: number) => {
      const tempobj = {
        value: item.id.toString(),
        label: item.description,
        isReasonRequired: (item.id == 1)
      };
      tempout.push(tempobj);
    })
    this.rejectOptions = tempout;
  }

  openDriverProfile() {
    this.router.navigate(["/driver/history-profile/" + this.driverId]);
  }

  private employerCriteriaValid(): boolean {
    let isOK: boolean = true;

    if (this.allControls.filter(c => (c.invalid)).length > 0) {
      this.errorService.setErrorObject({ message: 'Missing or Invalid Fields.' });
      isOK = false;
    }

    return isOK;
  }

  addEmployer() {
    if (this.employerCriteriaValid()) {
      let endpoint = "driverQualification/" + this.driverId + "/employers/" + this.driverAppReviewSubmitInfo.driverProcessAssignedId.toString();
      let postData = JSON.parse(JSON.stringify(this.employerSubmitInfo));

      delete postData.rejectionReasonId;
      delete postData.rejectionReasonIdString;

      this.loadingSpinnerService.show();
      this.onPremService.post(endpoint, JSON.stringify(postData)).subscribe({
        next: (data) => {
          this.snackBar.open('Previous Employer Added', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: 'success-snackbar'
          })
          //this.loadingSpinnerService.show();
          this.initAll(false, true, false);
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    }
  }

  openApproveModal() {
    if (this.validateApproval()) {
      const dialogRef = this.dialog.open(DQApproveModalComponent, {
        data: { dqType: 'Driver\'s License', attNames: this.arrFileName },
        minWidth: '31em',
        minHeight: '10em',
        panelClass: 'add-document-modal',
        hasBackdrop: false,
        position: { top: '2em' }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) this.approve();
      })

    }
    else {
      //show error message
      let err = new ErrorObject();
      err.message = "All items must be completed prior to approval";
      this.errorService.setErrorObject(err);
    }
  }

  openRejectModal() {
    const dialogRef = this.dialog.open(DQRejectModalComponent, {
      data: { dqType: 'Driver\'s License', rejectOptions: this.rejectOptions },
      minWidth: '31em',
      minHeight: '10em',
      panelClass: 'add-document-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.reject(result);
    })
  }

  save() {
    let endpoint = "driverQualification/" + this.driverId + "/application/" + this.driverAppReviewSubmitInfo.driverProcessAssignedId.toString();
    let postData = JSON.parse(JSON.stringify(this.driverAppReviewSubmitInfo));

    delete postData.rejectionReasonId;
    delete postData.rejectionReasonIdString;

    this.loadingSpinnerService.show();
    this.onPremService.put(endpoint, JSON.stringify(postData)).subscribe({
      next: (data) => {
        this.snackBar.open('Driver Application Review Information Saved', 'Ok', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5000,
          panelClass: 'success-snackbar'
        });
        //this.loadingSpinnerService.show();
        this.initAll(true, false, false);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  approve() {
    let endpoint = "driverQualification/" + this.driverId + "/application/" + this.driverAppReviewSubmitInfo.driverProcessAssignedId.toString() + "/approve";
    let postData = JSON.parse(JSON.stringify(this.driverAppReviewSubmitInfo));
    let needUpdate = this.hasDataUpdate(this.driverAppReviewSubmitInfo, this.driverAppReview);
    if (!needUpdate) {
      delete postData.hasAddressesFor3Years;
      delete postData.hasOneLicenseOnly;
      delete postData.hasAccidentRecordsForPastYears;
      delete postData.hasCommercialDriversLicense;
      delete postData.hasPreviousCommercialEmployment;
      delete postData.hasNoEmploymentGaps;
      delete postData.hasEmploymentHistory;
    }
    delete postData.rejectionReasonId;
    delete postData.rejectionReasonIdString;

    let postput = ((needUpdate) ? this.onPremService.put(endpoint, JSON.stringify(postData)) : this.onPremService.post(endpoint, JSON.stringify(postData)));

    this.loadingSpinnerService.show();
    postput.subscribe({
      next: (data) => {
        this.snackBar.open('Driver Application Approved', 'Ok', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5000,
          panelClass: 'success-snackbar'
        });
        //alert('go to DQ File Management view');
        this.loadingSpinnerService.hide();
        this.returnToListing();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error.message);
        this.loadingSpinnerService.hide();
      }
    });
  }
  validateApproval() {
    if (this.driverAppReviewSubmitInfo.hasAccidentRecordsForPastYears
      && this.driverAppReviewSubmitInfo.hasAddressesFor3Years
      && this.driverAppReviewSubmitInfo.hasOneLicenseOnly
      && this.driverAppReviewSubmitInfo.hasEmploymentHistory
      && this.driverAppReviewSubmitInfo.hasNoEmploymentGaps
    ) {
      return true;
    }
    else
      return false;
  }
  reject(rejectInfo: any) {
    let endpoint = "driverQualification/" + this.driverId + "/application/" + this.driverAppReviewSubmitInfo.driverProcessAssignedId.toString() + "/reject";
    let postData = JSON.parse(JSON.stringify(this.driverAppReviewSubmitInfo));
    let needUpdate = this.hasDataUpdate(this.driverAppReviewSubmitInfo, this.driverAppReview);
    if (!needUpdate) {
      delete postData.hasAddressesFor3Years;
      delete postData.hasOneLicenseOnly;
      delete postData.hasAccidentRecordsForPastYears;
      delete postData.hasCommercialDriversLicense;
      delete postData.hasPreviousCommercialEmployment;
      delete postData.hasNoEmploymentGaps;
      delete postData.hasEmploymentHistory;
    }
    postData.rejectionMessage = this.getRejectionMessage(rejectInfo);
    delete postData.otherRejectionReason;
    delete postData.rejectionReasonId;
    delete postData.rejectionReasonIdString;

    let postput = ((needUpdate) ? this.onPremService.put(endpoint, JSON.stringify(postData)) : this.onPremService.post(endpoint, JSON.stringify(postData)));

    this.loadingSpinnerService.show();
    postput.subscribe({
      next: (data) => {
        this.snackBar.open('Driver Application Rejected', 'Ok', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5000,
          panelClass: 'success-snackbar'
        });
        //alert('go to DQ File Management view');
        this.loadingSpinnerService.hide();
        this.returnToListing();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error.message);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private hasDataUpdate(submitInfo: DriverApplicationSubmitInfo, info: DQApplicationReviewInfo): boolean {
    let hasUpdate: boolean = false;

    hasUpdate = ((submitInfo.hasAddressesFor3Years != info.hasAddressesFor3Years) ||
      (submitInfo.hasOneLicenseOnly != info.hasOneLicenseOnly) ||
      (submitInfo.hasAccidentRecordsForPastYears != info.hasAccidentRecordsForPastYears) ||
      (submitInfo.hasCommercialDriversLicense != info.hasCommercialDriversLicense) ||
      (submitInfo.hasPreviousCommercialEmployment != info.hasPreviousCommercialEmployment) ||
      (submitInfo.hasNoEmploymentGaps != info.hasNoEmploymentGaps) ||
      (submitInfo.hasEmploymentHistory != info.hasEmploymentHistory));

    return hasUpdate;
  }

  private getRejectionMessage(rejectInfo: any): string {
    let rejectionMessage: string = "";
    let rejectId = rejectInfo.rejectionReasonIdString;

    rejectionMessage = this.rejectOptions.find(r => r.value == rejectId).label;

    if (rejectInfo.otherRejectionReason && (rejectInfo.otherRejectionReason.length > 0)) {
      rejectionMessage = rejectionMessage + " - " + rejectInfo.otherRejectionReason;
    }

    return rejectionMessage;
  }

  cancel() {
    //alert('go to DQ File Management view');
    this.returnToListing();
  }

  returnToListing() {
    if (this.returnUrl.length > 0) {
      this.router.navigate([this.returnUrl]);
      return;
    }

    //fallback to the default location
    this.router.navigate(['/dq/filemanagement']);
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

}

export interface DQApplicationReviewInfo extends DQFileManagementBaseInfo {
  hasAddressesFor3Years: boolean;
  hasOneLicenseOnly: boolean;
  hasAccidentRecordsForPastYears: boolean;
  hasCommercialDriversLicense: boolean;
  hasPreviousCommercialEmployment: boolean;
  hasNoEmploymentGaps: boolean;
  hasEmploymentHistory: boolean;

  employmentHistory: Array<DQFileManagementEmployerInfo>;
}

export interface DriverApplicationRejectReason {
  id: number;
  secondaryId: number;
  description: string;
  secondaryCode: string;
}

export class DriverApplicationSubmitInfo {
  driverProcessAssignedId: number;
  driverId: number;
  hasAddressesFor3Years: boolean;
  hasOneLicenseOnly: boolean;
  hasAccidentRecordsForPastYears: boolean;
  hasCommercialDriversLicense: boolean;
  hasPreviousCommercialEmployment: boolean;
  hasNoEmploymentGaps: boolean;
  hasEmploymentHistory: boolean;
  //rejectionReasonIdString: string;
  rejectionReasonId: number;
  otherRejectionReason: string;
  rejectionMessage: string;

  constructor(info: DQApplicationReviewInfo) {
    this.driverProcessAssignedId = info.driverProcessAssignedId;
    this.driverId = info.driverId;
    this.hasAddressesFor3Years = info.hasAddressesFor3Years;
    this.hasOneLicenseOnly = info.hasOneLicenseOnly;
    this.hasAccidentRecordsForPastYears = info.hasAccidentRecordsForPastYears;
    this.hasCommercialDriversLicense = true;
    this.hasPreviousCommercialEmployment = true;
    this.hasNoEmploymentGaps = info.hasNoEmploymentGaps;
    this.hasEmploymentHistory = info.hasEmploymentHistory;
    this.rejectionReasonId = 1;
    //this.rejectionReasonIdString = "1";
  }
}

export class DriverApplicationEmployerSubmitInfo {
  driverProcessAssignedId: number;
  driverId: number;

  employerName: string
  employerPhone: string
  positionHeld: string
  startDate: string
  endDate: string
  isDrivingPosition: boolean;

  constructor(info: DQApplicationReviewInfo) {
    this.driverProcessAssignedId = info.driverProcessAssignedId;
    this.driverId = info.driverId;
    this.isDrivingPosition = true;
  }
}
