import { Component, Inject, OnInit, OnDestroy, Input } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CustomReportsMenuService } from './custom-reports-menu.service';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { CustomReportsModalService } from '../custom-reports-filter-modal/custom-reports-filter-modal.service'
import { CustomReportService } from '../dashboard-custom-report-admin/dashboard-custom-report-service.component';
import { GetLOB } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CustomReportsFilterModalComponent } from '../custom-reports-filter-modal/custom-reports-filter-modal.component';

/**
 * This is the client-side controller that supports custom report display options for the left-nav
 */
@Component({
  selector: 'app-custom-reports-menu',
  templateUrl: './custom-reports-menu.component.html',
  styleUrls: ['./custom-reports-menu.component.css']
})

export class CustomReportsMenuComponent implements OnInit, OnDestroy {

  // private variables that are only shared with subscribers that import the type
  baseUrl: string;
  display: string = "none";
  lineOfBusinessId: number;
  menuItems: Array<DataGrid> = [];
  lob: Array<GetLOB> = [];
  customReportsListSub: Subscription;
  lobSub: Subscription;

  // the overloaded constructor for the controller
  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private customReportsMenuService: CustomReportsMenuService,
    private lineOfBusinessService: LineOfBusinessService,
    private customReportsModalService: CustomReportsModalService,
    private customReportService: CustomReportService,
    public dialog: MatDialog) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  // angular on intialization event
  ngOnInit(): void {
    this.lineOfBusinessId = this.lineOfBusinessService.getLineOfBusinessValue();
    this.getMenuItems(this.lineOfBusinessId);

    this.lobSub = this.lineOfBusinessService.lineOfBusinessSelected$.subscribe(data => {
      let lobOld = this.lineOfBusinessId;
      let lobNew = data as number;

      if (lobNew != lobOld) {
        this.lineOfBusinessId = lobNew;
        this.getMenuItems(lobNew);
      }

    });

    // Subscribes to the BehavioralSubject and monitors report adds and deletes
    this.customReportsListSub = this.customReportService.customReportsList.subscribe(data => {
      this.lob = [];
      this.menuItems = [];

      this.lob = data as Array<GetLOB>;
      this.lob.forEach(a => {
        this.menuItems.push({
          dataGridId: a.reportId,
          description: a.reportName,
          lineOfBusinessId: this.lineOfBusinessId,
          showFilterSw: true,
          title: a.reportName
        });
      })
    });
  }

  private getMenuItems(lineOfBusiness: number) {
    // gets the initial custom reports to display in the left-nav
    if (lineOfBusiness > 0) {
      this.customReportService.getByUserLOB(lineOfBusiness).subscribe({
        next: (data) => {
          this.lob = [];
          this.menuItems = [];

          this.lob = data as Array<GetLOB>;
          this.lob.forEach(a => {
            this.menuItems.push({
              dataGridId: a.reportId,
              description: a.reportName,
              lineOfBusinessId: lineOfBusiness,
              showFilterSw: true,
              title: a.reportName
            });
          })
          this.menuItems = this.menuItems.slice();
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      })
    }

  }

  // opens and displays the modal template
  showModal(reportId: number, reportName: string, title: string) {
    const dialogRef = this.dialog.open(CustomReportsFilterModalComponent, {
      data: { reportId, reportName, title },
      width: '30em',
      height: '31em',
      panelClass: 'custom-reports-filter-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });
  }

  // handles the modal closed event
  onCloseHandled() {
  }

  ngOnDestroy() {
    this.lobSub.unsubscribe();
    this.customReportsListSub.unsubscribe();
  }
}

// specifies the domain model for the datagrid
export class DataGrid {
  dataGridId: number;
  title: string;
  description: string
  lineOfBusinessId: number;
  showFilterSw: boolean;
}
