<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
  <div class="card card-body">
    <div class="row">
      <div *ngIf="!editMode">
        <div class="col-sm-12"><b>Week Period:</b> {{startDateString | date:'EEEE, M/d/yy'}} - {{endDate | date:'EEEE,
          M/d/yy'}}</div>
        <div class="col-sm-12"><b>Total Hours:</b> {{totalHours | number:'1.2-2'}}</div>
        <div class="col-sm-12">
          <div class="row" style="padding-top:25px;">
            <div class="col-sm-4">
              <div style="margin-top: 14px">Select Different Start Date:</div>
            </div>
            <div class="col-sm-4">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label class="lbl-start-date">Start Date</mat-label>
                  <input class="input-date" matInput disabled [matDatepicker]="startDatePicker" [(ngModel)]="startDate" [formControl]="beginDateControl">
                  <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #startDatePicker disabled="false">
                    <mat-datepicker-actions>
                      <button mat-button (click)="startDatePicker.select(actualDate); startDatePicker.close()">
                        <mat-icon>history</mat-icon>
                        Today
                      </button>
                      <button mat-button (click)="startDatePicker.select(null); startDatePicker.close()">
                        <mat-icon>highlight_off</mat-icon>
                        Clear
                      </button>
                    </mat-datepicker-actions> 
                  </mat-datepicker>
                </mat-form-field>
                
            </div>          
            <div class="col-sm-3"><button class="btn btn-default" (click)="goToDate()">Go</button></div>
          </div>




          <!--<input id="beginDateReadOnly"
        *ngIf="startDateReadOnly"
        type="text"
        [(ngModel)]="beginDate"
        class="form-control"
        disabled="true"
        [formControl]="beginDateControl"/>-->
        </div>
      </div>


    </div>

    <div class="row" style="padding-top:15px;">
      <div class="col-sm-6">
        <button *ngIf="!editMode" class="btn btn-default" style="margin-bottom:6px" (click)="edit()">
          Edit Hours
        </button>
        <button *ngIf="!editMode" class="btn btn-default" style="margin-bottom:6px" (click)="cancel()">
          Exit
        </button>
      </div>
      <div class="col-sm-6">

      </div>
    </div>

    <div *ngIf="editMode">
      <dc-driver-time-clock-detail [arrEntryDetail]="arrEntryDetail" [totalHours]="totalHours"
        (EditingStopped)="onEditStopped($event)" (EntryUpdated)="onEntryUpdated($event)"
        [driverId]="this.modalInput.driverId"></dc-driver-time-clock-detail>
    </div>
  </div>

</div>
<div class="modal-footer">
  <!--<button class="btn btn-default"
          (click)="submit()">
    Schedule
  </button>-->

</div>