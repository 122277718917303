<div [formGroup]="monitoringStatusForm">
    <h4 class="card-title text-muted wrap-this">Monitoring</h4>
    <div class="row">
      <div class="col-md-12">
        <div
          *ngIf="
          monitoringStatusForm.pristine !== true &&
          monitoringStatusForm.errors?.requireCheckboxToBeChecked
          "
          class="validation-error"
        >
          At least one MVR type must be selected.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ul class="horizontalList">
          <li>
            <input type="checkbox" formControlName="Monitored" id="dtMonitored" />
            <label for="dtMonitored">Monitored</label>
          </li>
          <li>
            <input
              type="checkbox"
              formControlName="NonMonitored"
              id="dtNonMonitored"
            />
            <label for="dtNonMonitored">Non-Monitored</label>
          </li>
        </ul>
      </div>
    </div>
  </div>
  