//Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//Third App
import { Observable ,  Subject } from "rxjs";
//App
import { CorporateStructure } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';

@Injectable()
export class CorporateStructureAddGroupModalService {
  _http: HttpClient
  _baseUrl: string
  //private reportId = new Subject();
  //reportId$ = this.reportId.asObservable();

  //private reportName = new Subject();
  //reportName$ = this.reportName.asObservable();

  //private gridFilters = new Subject();
  //gridFilters$ = this.gridFilters.asObservable();
  private _corporateStructure = new Subject();
  _corporateStructure$ = this._corporateStructure.asObservable();

  private _corporateStructureFolderAdded = new Subject();
  _corporateStructureFolderAdded$ = this._corporateStructureFolderAdded.asObservable();

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private onPremService: OnPremDriverService,
    private errorService: ErrorModalService
  ) {
    this._http = http;
    this._baseUrl = baseUrl;
  }

  openModal(ch: CorporateStructure) {
    this._corporateStructure.next(ch);
  }

  addGroup(client: string, parentId: string, groupName: string): Observable<any> {
    let param = {
      client: client,
      elementName: groupName,
      parentElement: parentId
    }
    return this.onPremService.post('hierarchy', JSON.stringify(param))
  }

  folderAdded(): void{
    this._corporateStructureFolderAdded.next(true);
  }
}


