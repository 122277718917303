<div class="row">
    <div class="col-md-12">
        <div class="card card-body mt-3">
            <div class="form-inline">
                <h4 class="card-title text-muted">Driver Pending Tasks</h4>
            </div>
             <app-loading-panel *ngIf="!driverPendingTasksTableConfig"></app-loading-panel>
            <div *ngIf="driverPendingTasksTableConfig">
            <app-data-table 
                [columns]="driverPendingTasksTableConfig.fields"
                [data]="driverPendingTasksTableConfig.data"
            ></app-data-table>
      </div>
        </div>
    </div>
</div>