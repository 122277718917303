import { DriverDocuments, DriverNotes } from "../../components/classes-and-interfaces/classes-and-interfaces.component";

export interface DcLookupValue {
  id: number;
  code: string;
  description: string;
}

export interface DcProduct {
  id: number;
  sku: string;
  description: string;
}

export interface DcSubscription {
  product: DcProduct;
  expirationDate: string;
  slotsPurchased: number;
  slotsUsed: number;
  slotsAvailable: number;
  // properties below are added in the UI code
  slotDetail?: Array<DcSubscriptionSlot>;
  productName?: string;
}

export interface DcSubscriptionSlot {
  slotId: number;
  slotIdentifier: string;
  product: DcProduct;
  assignedDriver: DcDriver;
  assignedDate: string;
  expirationDate: string;
  isExpiringSoon: boolean;
}

export class DcSubscriptionIdentifier {
  productId: number;
  expirationDate: string;

  constructor(productId: number, expirationDate: string) {
    this.productId = productId;
    this.expirationDate = expirationDate;
  }
}

export interface DcProductDocument {
  uniqueIdentifier: string;
  name: string;
  azureBlobContainerName: string;
  azureFileName: string;
  fileName: string;
}

export interface DcProductDocumentIdentifier {
  uniqueIdentifier: string;
  name: string;
}

export interface DcProductDocumentCategory {
  uniqueIdentifier: string;
  name: string;
  childCategories: Array<DcProductDocumentCategory>;
  documents: Array<DcProductDocumentCategory>;
}

export class DcDriver {
  driverId: number;
  public clientCode: string;
  public groupGuid: string;
  createdBy: string;
  createDate: string;
  employeeId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  dateOfBirth: Date;
  emailAddress: string;
  ownerEmailAddress: string;
  managerTrainerEmailAddresses:	Array<string>;
  medicalCard: DcDriverMedicalCard;
  leaveOfAbsence: DcDriverLeaveOfAbsence;
  driverLicense: DcDriverLicense;
  driverAssignedOnlineTraining: DcDriverAssignedOnlineTraining;
  assignedProducts: Array<DcDriverProduct>;
  preferredLanguage: DcDriverPreferredLanguage;
  isActive: boolean;

  // properties set in driver profile code
  driverDocuments?: Array<DriverDocuments>;
  driverDocumentsLoaded?: boolean;
  driverNotes?: Array<DriverNotes>;
  driverNotesLoaded?: boolean;
  driverLicenseNumber?: string;
  driverLicenseState?: string;

  completedTraining: Array<any>;
  completedTrainingLoaded: boolean;
  incompleteTraining: Array<any>;
  incompleteTrainingLoaded: boolean;
  liveRouteTraining: DcLiveRouteTrainingType[];
  liveRouteTrainingLoaded: boolean;

  constructor(clientCode: string, groupGuid: string) {
    this.clientCode = clientCode;
    this.groupGuid = groupGuid;
    this.isActive = true;
  }
}

export class DcDriverEntry extends DcDriver {
  label: string;
  driverCompanyLabel: string;
  languageId: string;
  locationId: string;
  emailAddressConfirm: string;
  medicalCardExpirationDate: Date;
  driverLicenseExpirationDate: Date;
  resetPassword: boolean;
  assignOnlineTraining: boolean;
  assignOnlineTrainingDateDue: Date;
  AuthAction?: string;
  // assignedProduct property needs to be sent when creating a new driver
  assignedProduct: DcSubscriptionIdentifier;

  constructor(
    public clientCode: string,
    public groupGuid: string
  ) {
    super(clientCode, groupGuid);
    this.clientCode = clientCode;
    this.resetPassword = false;
    this.emailAddressConfirm = this.emailAddress;
  }
}

export interface DcDriverValidateResponse {
  // object returned when attempting to validate new driver
  driverId: number;
  validationStateId: number;
  validationMessage: string; 
}

export interface DcDriverCreateResponse {
  // object returned when attempting to create new driver
  isNewDriver: boolean;
  driverId: number;
}

export interface DcDriverProduct {
  driverId: boolean;
  product: DcProduct;
  productExpirationDate: string;
  assignedDate: string;
  assignedBy: string;
  // assigned in dc-driver-assigned-programs
  productName?: string;
}

export class DcDriverMedicalCard {
  expirationDate: string;

  constructor() {

  }
}

export class DcDriverAssignedOnlineTraining {
  dueDate: string;

  constructor() {

  }
}

export interface DcDriverLeaveOfAbsence {
  isLeaveOfAbsencePending: boolean;
  isOnLeaveOfAbsence: boolean;
  startDate: string;
  endDate: string;
  status: DcDriverLeaveOfAbsenceStatus;
}

export interface DcDriverLeaveOfAbsenceStatus {
  id: number;
  code: number;
  description: string;
}

export class DcDriverPreferredLanguage {
  id: number;
  code: number;
  description: string;

  constructor() {

  }
}

export class DcDriverLicense {
  driverLicenseNumber: string;
  expirationDate: string;
  stateProvince: string;
  country: string;

  constructor() {

  }
}

export interface DcProduct {
  id: number;
  sku: string;
  description: string;
}

export interface DcRequestResponse {
  isSuccess: boolean;
  errorMessage: string;
}

export interface DcLiveRouteTrainingType {
  description: string;
  isLiveRoute: boolean;
  isRoadTest: boolean;
  isPracticeHours: boolean;
  liveRouteTraining: Array<DcLiveRouteTraining>;
}

export interface DcLiveRouteTraining {
  liveRouteTrainingId: number;
  qualifiedObserverEmployeeId: string;
  trainingDate: string | Date;
  trainingHours: number;
}

export interface DcAssignDriverTraining {
  TrainingDriverIdList: string;
  TrainingDueDate: string;
  TrainingAssignmentType: number;
  TrainingCourseGroupList: string;
  TrainingPassRateList: string;

}

export class DcTimeEntry {
  TimeEntryId: number;
  VehicleNumber: string;
  ClockInTime: Date;
  ClockOutTime: Date;
  TotalHours: number;
}
