import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { AccidentClaimInvoiceChargeCode, AccidentClaimInvoice } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-accident-claim-invoices',
  templateUrl: './accident-claim-invoices.component.html',
  styleUrls: ['./accident-claim-invoices.component.css']
})
export class AccidentClaimInvoicesComponent implements OnInit, OnDestroy {
  _claimId: number;
  invoices: Array<AccidentClaimInvoice>;
  _routeSub: Subscription;

  constructor(private readonly _route: ActivatedRoute,
    private readonly accidentClaimService: AccidentClaimService
  ) { }

  printPage(): void {
    window.print();
  }

  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];
      this.accidentClaimService.getInvoices(this._claimId).subscribe({
        next: (data) => {
          this.invoices = data as Array<AccidentClaimInvoice>;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });
    });
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
  }

  getSumByInvoice(iArr: Array<AccidentClaimInvoiceChargeCode>): number {
    return iArr.reduce((x, y) => (x + y.amount), 0);
  }

  getInvoicesTotal(): number {
    if (this.invoices) {
      return this.invoices.reduce((x, y) => x + this.getSumByInvoice(y.chargeCodes), 0);
    } else {
      return null;
    }
  }
}
