import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DriverInactive } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

@Injectable()
export class ReactivateDriverInfoModalService {
  private reactivateDriverInfoObs = new Subject();
  reactivateDriverInfoObs$ = this.reactivateDriverInfoObs.asObservable();
  private inactiveDriverGridRefreshObs = new Subject();
  inactiveDriverGridRefreshObs$ = this.inactiveDriverGridRefreshObs.asObservable();

  constructor() { }

  openReactivateDriverInfoModal(driverInfo: DriverInactive) {
    this.reactivateDriverInfoObs.next(driverInfo);
  }

  closeReactivateDriverInfoModal() {
    this.reactivateDriverInfoObs.next(false);
  }

  setInactiveDriverGridRefresh(r: boolean) {
    this.inactiveDriverGridRefreshObs.next(r);
  }

}
