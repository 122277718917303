<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <div class="form-inline">
            <h4 class="card-title text-muted">Additional Drivers</h4>
            <button *ngIf="canAddNewDriver" class="btn btn-xs btn-default p-1" title="Add Driver" style="margin:-10px 0 0 10px; width:200px;" (click)="addNonEmployeeDriver(primaryDriverProfile)">
              Add Non-Employee Driver
            </button>
            <button *ngIf="!canAddNewDriver" class="btn btn-xs btn-default p-1" title="Add Driver" style="margin:-10px 0 0 10px; width:200px;" (click)="addNonEmployeeDriver(primaryDriverProfile)" disabled>
              Add Non-Employee Driver
            </button>
          </div>
          <app-loading-panel *ngIf="!primaryDriverProfile.secondaryDriversLoaded"></app-loading-panel>
          <div class="row" *ngIf="primaryDriverProfile.secondaryDriversLoaded">
            <div class="col-md-12">
              <mat-accordion togglePosition="before" multi="true">
                <mat-expansion-panel 
                  *ngFor="let driverProfile of secondaryDrivers; let i = index" 
                  [disabled]="!driverProfile.isActive"
                  (opened)="toggleDriver(driverProfile, i, $event)">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="title">{{driverProfile.fullName}}</div>
                    </mat-panel-title>
                    <mat-panel-description>
                      <div class="switch">
                        <label>
                          <input [checked]="driverProfile.isActive"
                                 (change)="toggleIsActive(driverProfile, driverProfile.driverId, !driverProfile.isActive)"
                                 [disabled]="(driverProfile.isActive?false:!canAddNewDriver)"
                                 type="checkbox">
                          <span class="lever enableWidgetSwitch"></span>
                          <span class="enableWidgetLabel">{{driverProfile?.isActive?"Active":"Inactive"}}</span>
                        </label>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card card-body mt-3">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-12">
                                <h4 class="card-title">{{driverProfile.fullName}}</h4>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <li *ngFor="let actionCat of driverProfile.actionMenuObjectArray"
                                [class]="'nav-item dropdown btn-group actionDropdownButton'+actionCat.catId"
                                dropdown>
                              <button [matMenuTriggerFor]="menu" class="btn btn-secondary dropdown-toggle" type="button" aria-haspopup="true" aria-expanded="false">
                                {{actionCat.label}}
                              </button>
                              <mat-menu #menu="matMenu">
                                <button *ngFor="let actionItem of actionCat.items"
                                        class="dropdown-item"
                                        type="button"
                                        (click)="actionMenuItemClick(driverProfile, actionItem.id)">
                                  {{actionItem.description}}
                                </button>
                              </mat-menu>
                            </li>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-4">
                            <safety-non-employee-info [driverProfile]="driverProfile"
                                                      [_userRights]="_userRights"
                                                      [arrReqFields]="arrReqFields||[]">
                            </safety-non-employee-info>
                          </div>
                          <div class="col-lg-8">
                            <safety-driver-info [driverProfile]="driverProfile"
                                                [_userRights]="_userRights"
                                                [fullProfile]="false">
                            </safety-driver-info>
                            <safety-mvr [driverProfile]="driverProfile"
                                        [currentMvr]="driverProfile.currentMvr"
                                        [mvrRecords]="driverProfile.mvrHistory"
                                        [clientSettings]="clientSettings"
                                        [_userRights]="_userRights"
                                        [fullProfile]="false">
                            </safety-mvr>
                            <safety-notes-docs [driverProfile]="driverProfile"
                                               [driverNotes]="driverProfile.driverNotes||[]"
                                               [driverDocuments]="driverProfile.driverDocuments||[]"
                                               [_userRights]="_userRights">
                            </safety-notes-docs>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

