//Angular
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
//Third Party
import { Subject ,  Observable ,  BehaviorSubject, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
//APP
import { DriverProfile, 
  DriverHistoryProfileModal, 
  DriverNotes, 
  DriverDocuments, 
  DriverTraining, 
  DriverTelematics, 
  RiskHistory, 
  RiskClaimHistory, 
  AuxiliaryPointHistory, 
  MVRRecordsCurrent, 
  DriverQualifications, 
  DriverHPEAssignment, 
  DriverHPECoreCompetency, 
  DriverHPESubLesson, 
  MVRHistory, 
  DriverActionMenuLabel, 
  DriverActionMenuItem, 
  DriverExtendedInfo, 
  DriverEmploymentHistory, 
  DriverProfileTabInfo, 
  DriverAutoCoverage, 
  DriverPendingTask, 
  DriverMMTraining } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DataGridModalService } from '../../components/data-grid-filter-modal/data-grid-filter-modal.service';
import { DHPModalWindowComponent } from '../dhp-modal-window/dhp-modal-window.component';

@Injectable()
export class DriverHistoryProfileService {
  baseUrl: string;
  public defaultTabIndex: number;

  // alert for the modal open with data supplied from the ui layer
  public dhp = new Subject<DriverHistoryProfileModal>();
  public dhpDefaultTabIndex = new BehaviorSubject<number>(0);
  alertDHPModalInfo$ = this.dhp.asObservable();

  // alert for the modal close
  public dhpClose = new Subject<boolean>();
  alertDHPModalClose$ = this.dhpClose.asObservable();

  // alert for any driver history profile deep copy data changes
  public dhpDriverProfileData = new Subject<DriverProfile>();
  alertDHPDriverProfileData$ = this.dhpDriverProfileData.asObservable();

  // alert to landing page that ALL driver profile info should be refreshed
  public dhpRefreshDriverProfileAllData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileAllData$ = this.dhpRefreshDriverProfileAllData.asObservable();

  // alert to landing page that BASE driver profile info should be refreshed
  public dhpRefreshDriverProfileBaseData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileBaseData$ = this.dhpRefreshDriverProfileBaseData.asObservable();

  // alert to landing page that driver profile NOTES info should be refreshed
  public dhpRefreshDriverProfileNotesData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileNotesData$ = this.dhpRefreshDriverProfileNotesData.asObservable();

  // alert to landing page that driver profile DOCUMENTS info should be refreshed
  public dhpRefreshDriverProfileDocumentsData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileDocumentsData$ = this.dhpRefreshDriverProfileDocumentsData.asObservable();

  // alert to landing page that driver profile FOLLOW UP DATE info should be refreshed
  public dhpRefreshDriverProfileFollowUpDateData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileFollowUpDateData$ = this.dhpRefreshDriverProfileFollowUpDateData.asObservable();

  // alert to landing page that driver profile COMPLETED TRAINING info should be refreshed
  public dhpRefreshDriverProfileCompletedTrainingData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileCompletedTrainingData$ = this.dhpRefreshDriverProfileCompletedTrainingData.asObservable();

  // alert to landing page that driver profile INCOMPLETE TRAINING info should be refreshed
  public dhpRefreshDriverProfileIncompleteTrainingData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileIncompleteTrainingData$ = this.dhpRefreshDriverProfileIncompleteTrainingData.asObservable();

  // alert to landing page that driver profile HPE ASSIGNMENT info should be refreshed
  public dhpRefreshDriverProfileHPEAssignmentData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileHPEAssignmentData$ = this.dhpRefreshDriverProfileHPEAssignmentData.asObservable();

  // alert to landing page that driver profile EXTERNAL TRAINING info should be refreshed
  public dhpRefreshDriverProfileExternalTrainingData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileExternalTrainingData$ = this.dhpRefreshDriverProfileExternalTrainingData.asObservable();

    // alert to landing page that driver profile MOTORMINDS info should be refreshed
    public dhpRefreshDriverProfileMotorMindsAssignmentData = new BehaviorSubject<number>(null);
    dhpRefreshDriverProfileMotorMindsAssignmentData$ = this.dhpRefreshDriverProfileMotorMindsAssignmentData.asObservable();

  // alert to landing page that driver profile TELEMATICS info should be refreshed
  public dhpRefreshDriverProfileTelematicsData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileTelematicsData$ = this.dhpRefreshDriverProfileTelematicsData.asObservable();

  // alert to landing page that driver profile RISK HISTORY info should be refreshed
  public dhpRefreshDriverProfileRiskHistoryData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileRiskHistoryData$ = this.dhpRefreshDriverProfileRiskHistoryData.asObservable();

  // alert to landing page that driver profile RISK CLAIMS HISTORY info should be refreshed
  public dhpRefreshDriverProfileRiskClaimHistoryData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileRiskClaimHistoryData$ = this.dhpRefreshDriverProfileRiskClaimHistoryData.asObservable();

  // alert to landing page that driver profile AUXILIARY POINTS info should be refreshed
  public dhpRefreshDriverProfileAuxiliaryPointsData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileAuxiliaryPointsData$ = this.dhpRefreshDriverProfileAuxiliaryPointsData.asObservable();

  // alert to landing page that driver profile MVR CURRENT info should be refreshed
  public dhpRefreshDriverProfileCurrentMVRData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileCurrentMVRData$ = this.dhpRefreshDriverProfileCurrentMVRData.asObservable();

  // alert to landing page that driver profile MVR HISTORY info should be refreshed
  public dhpRefreshDriverProfileMVRHistoryData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileMVRHistoryData$ = this.dhpRefreshDriverProfileMVRHistoryData.asObservable();

  // alert to landing page that driver profile MVR VIOLATIONS info should be refreshed
  public dhpRefreshDriverProfileMVRViolationsData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileMVRViolationsData$ = this.dhpRefreshDriverProfileMVRViolationsData.asObservable();

  // alert to landing page that driver profile DRIVER QUALIFICATIONS info should be refreshed
  public dhpRefreshDriverProfileDriverQualificationsData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileDriverQualificationsData$ = this.dhpRefreshDriverProfileDriverQualificationsData.asObservable();

  // alert to landing page that driver profile SECONDARY DRIVERS info should be refreshed
  public dhpRefreshDriverProfileSecondaryDriverData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileSecondaryDriverData$ = this.dhpRefreshDriverProfileSecondaryDriverData.asObservable();

  // alert to landing page that RESEND E-SIG EMAIL was clicked
  public dhpResendESignEmail = new BehaviorSubject<number>(null);
  dhpResendESignEmail$ = this.dhpResendESignEmail.asObservable();

  // alert to landing page that LEAVE OF ABSENCE was set
  public dhpRefreshDriverProfileLeaveOfAbsenceData = new BehaviorSubject<number>(null);
  dhpRefreshDriverProfileLeaveOfAbsenceData$ = this.dhpRefreshDriverProfileLeaveOfAbsenceData.asObservable();

  // alert to landing page that driver profile PENDING TASKS info should be refreshed
  public dhpRefreshDriverPendingTasksData = new BehaviorSubject<number>(null);
  dhpRefreshDriverPendingTasksData$ = this.dhpRefreshDriverPendingTasksData.asObservable();

  constructor(private router: Router,
    @Inject('BASE_URL') baseUrl: string,
    private http: HttpClient,
    private clientSelectionService: ClientSelectionService,
    private onPremService: OnPremDriverService,
    private dataGridModalService: DataGridModalService,
    private dialog: MatDialog) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  getClientSettingsOLD(clientSelected:string) {
    return this.onPremService.get('lookup/clientSettings/' + clientSelected);
  }

  getClientSettings(clientSelected: string) {
    // 2019-11 - get stored client settings from ClientSelectionService observable
    let cSettings = this.clientSelectionService.getClientSettingsObjectValue();
    if (cSettings && cSettings[clientSelected]) {
      let clientSettings = cSettings[clientSelected];
      return of(clientSettings);
    } else {
      // do lookup if client settings not defined for this client
      return this.onPremService.get('lookup/clientSettings/' + clientSelected);
    }

  }

  getProfileTabSettings(driverId: string, lineOfBusinessId: number) {
    return this.onPremService.get<Array<DriverProfileTabInfo>>('lookup/visibility/tabs/' + driverId + "/" + lineOfBusinessId.toString());
  }

  /* GETS LIST OF LABELS/IDS FOR ACTION  ITEMS*/
  getDriverHistoryProfileActionMenuLabels(isNonEmployeeProfiled:boolean) {
    return this.onPremService.get<Array<DriverActionMenuLabel>>('lookup/visibility/driverActionMenu' + (isNonEmployeeProfiled ? '' :'/false'));
  }

  /* GETS LIST OF ACTION ITEMS FOR DRIVER*/
  getDriverHistoryProfileActionMenuItems(driverId: string, isNonEmployeeProfiled: boolean) {
    return this.onPremService.get<Array<DriverActionMenuItem>>('lookup/visibility/driverAction/' + driverId + (isNonEmployeeProfiled ? '' : '/false'));
  }

  /* RETRIEVES A DEEP COPY OF THE DRIVER HISTORY PROFILE DATA by driver id*/
  getDriverHistoryProfileBaseData(driverId: string) {
    //return this.onPremService.get<DriverProfile>('entity/driverProfile/base/' + driverId);
    return this.onPremService.get<DriverProfile>('entity/' + driverId);
  }

  /* GETS INFO ON EXTENDED INFO FIELDS (REQ FIELDS) FOR DRIVER PROFILE*/
  getDriverHistoryProfileExtendedData(clientSelected: string) {
    return this.onPremService.get<Array<DriverExtendedInfo>>('lookup/clientReqFields/' + clientSelected);
  }

  /* GETS NOTES DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileNotesData(driverId: string) {
    return this.onPremService.get<Array<DriverNotes>>('note/list/' + driverId);
  }

  /* GETS DOCUMENTS DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileDocumentsData(driverId: string) {
    return this.onPremService.get<Array<DriverDocuments>>('file/list/' + driverId);
  }

  /* GETS FOLLOW UP DATE FOR DRIVER PROFILE*/
  getDriverHistoryProfileFollowUpDateData(driverId: string) {
    //return this.onPremService.get<DriverFollowUpDate>('entity/followUp/' + driverId);
    return this.onPremService.get('entity/' + driverId + '/followUp');
  }

  /* GETS COMPLETED TRAINING DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileCompletedTrainingData(driverId: string) {
    return this.onPremService.get<Array<DriverTraining>>('training/list/completed/' + driverId);
  }

  /* GETS INCOMPLETE TRAINING DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileIncompleteTrainingData(driverId: string) {
    return this.onPremService.get<Array<DriverTraining>>('training/list/incomplete/' + driverId);
  }

  /* GETS HPE TRAINING DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileHPETrainingAssignmentsData(driverId: string) {
    return this.onPremService.get<Array<DriverHPEAssignment>>('training/hpe/assignments/' + driverId);
  }
  getDriverHistoryProfileHPECoreCompetenciesData(driverId: string, assignmentId: string) {
    return this.onPremService.get<Array<DriverHPECoreCompetency>>('training/hpe/coreCompetencies/' + driverId + '/' + assignmentId);
  }
  getDriverHistoryProfileHPESubLessonsData(driverId: string, assignmentId: string) {
    return this.onPremService.get<Array<DriverHPESubLesson>>('training/hpe/subLessons/' + driverId + '/' + assignmentId);
  }

  /* GETS EXTERNAL TRAINING DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileExternalTrainingData(driverId: string) {
    return this.onPremService.get<Array<DriverTraining>>('training/list/external/' + driverId);
  }

  /* GETS MOTORMINDS TRAINING DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileMotorMindsAssignmentsData(driverId: string) {
    return this.onPremService.get<Array<DriverMMTraining>>('training/motorminds/assignments/' + driverId);
  }

  /* GETS TELEMATICS DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileTelematicsData(driverId: string) {
    return this.onPremService.get<Array<DriverTelematics>>('training/telematics/' + driverId);
  }

  /* GETS RISK HISTORY DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileRiskHistoryData(driverId: string) {
    return this.onPremService.get<Array<RiskHistory>>('riskHistory/' + driverId);
  }

  /* GETS RISK HISTORY CLAIMS DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileRiskHistoryClaimsData(driverId: string) {
    return this.onPremService.get<Array<RiskClaimHistory>>('riskHistory/claim/' + driverId);
  }

  /* GETS AUXILIARY POINT DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileAuxiliaryPointData(driverId: string) {
    return this.onPremService.get<Array<AuxiliaryPointHistory>>('riskHistory/auxiliaryPoints/' + driverId);
  }

  /* GETS CURRENT MVR DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileCurrentMVRData(driverId: string) {
    return this.onPremService.get<MVRRecordsCurrent>('mvr/current/' + driverId);
  }

  /* GETS MVR HISTORY DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileMVRHistoryData(driverId: string) {
    return this.onPremService.get<Array<MVRHistory>>('mvr/history/' + driverId);
  }

  /* GETS DRIVER QUALIFICATIONS DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileDriverQualificationsData(driverId: string) {
    return this.onPremService.get<Array<DriverQualifications>>('driverQualification/' + driverId);
  }

  /* GETS SECONDARY DRIVER DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileSecondaryDriverData(driverId: string) {
    //return this.onPremService.get<Array<SecondaryDriver>>('entity/secondaryInfo/' + driverId);
    return this.onPremService.get<Array<DriverProfile>>('entity/' + driverId +'/secondary');
  }

  /* GETS DRIVER HISTORY REVIEW DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileEmploymentHistory(driverId: string) {
    return this.onPremService.get<Array<DriverEmploymentHistory>>('driverQualification/' + driverId + '/employmenthistory');
  }

  getDriverAutoCoverage(driverId: string) {
    return this.onPremService.get<DriverAutoCoverage>('coi/' + driverId);
  }  

  getDriverPendingTasksData(driverId: string) {
    return this.onPremService.get<Array<DriverPendingTask>>('tasks/' + driverId + '/pending?excludeTraining=false');
  }

  /* NOTIFIES THE OBSERVABLE OF THE DROPDOWN SELECTION, WHICH IN TURN
     NOTIFIES THE MODAL DIALOG TO RESPOND */
  notifyModal(title: string, message: string, modalHeight: string, modalWidth: string, modalInput?:any) {
    let _modalInfo = new DriverHistoryProfileModal();
    _modalInfo.message = message;
    _modalInfo.title = title;
    _modalInfo.modalHeight = modalHeight;
    _modalInfo.modalWidth = modalWidth;
    _modalInfo.modalInput = modalInput;
    this.showDhpModal(_modalInfo);
  }

  showDhpModal(modalInfo: DriverHistoryProfileModal): void {
    const dialogRef = this.dialog.open(DHPModalWindowComponent,{
      data: { modalInfo: modalInfo },
      width: modalSize[modalInfo.modalWidth],
      minHeight: '5em',
      maxHeight: '93vh',
      panelClass: 'add-document-modal',
      hasBackdrop: false,
      position: {top: '2em'}
    });
  }

  /* UPDATES THE INDEX FOR THE LAST DRIVER HISTORY PROFILE TAB THAT WAS USED
     THIS WAY IT WE CAN RETAIN IT IF WE WANT TO NAVIGATE BACK TO IT AFTER LEAVING */
  updateLastDHPTab(lastTabIndex: number) {
    this.defaultTabIndex = lastTabIndex;
  }

  getLastDHPTab() {
    return this.defaultTabIndex;
  }

  /* set flag to indicate refresh of ALL Driver Profile data */
  setRefreshDriverProfileAllData(driverId: number) {
    this.dhpRefreshDriverProfileAllData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile BASE data */
  setRefreshDriverProfileBaseData(driverId: number) {
    this.dhpRefreshDriverProfileBaseData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile NOTES data */
  setRefreshDriverProfileNotesData(driverId: number) {
    this.dhpRefreshDriverProfileNotesData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile DOCUMENTS data */
  setRefreshDriverProfileDocumentsData(driverId: number) {
    this.dhpRefreshDriverProfileDocumentsData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile FOLLOW UP DATE data */
  setRefreshDriverProfileFollowUpDateData(driverId: number) {
    this.dhpRefreshDriverProfileFollowUpDateData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile COMPLETED TRAINING data */
  setRefreshDriverProfileCompletedTrainingData(driverId: number) {
    this.dhpRefreshDriverProfileCompletedTrainingData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile INCOMPLETE TRAINING data */
  setRefreshDriverProfileIncompleteTrainingData(driverId: number) {
    this.dhpRefreshDriverProfileIncompleteTrainingData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile HPE ASSIGNMENT data */
  setRefreshDriverProfileHPEAssignmentData(driverId: number) {
    this.dhpRefreshDriverProfileHPEAssignmentData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile HPE ASSIGNMENT data */
  setRefreshDriverProfileMotorMindsAssignmentData(driverId: number) {
    this.dhpRefreshDriverProfileMotorMindsAssignmentData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile EXTERNAL TRAINING data */
  setRefreshDriverProfileExternalTrainingData(driverId: number) {
    this.dhpRefreshDriverProfileExternalTrainingData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile TELEMATICS data */
  setRefreshDriverProfileTelematicsData(driverId: number) {
    this.dhpRefreshDriverProfileTelematicsData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile RISK HISTORY data */
  setRefreshDriverProfileRiskHistoryData(driverId: number) {
    this.dhpRefreshDriverProfileRiskHistoryData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile RISK CLAIMS HISTORY data */
  setRefreshDriverProfileRiskClaimHistoryData(driverId: number) {
    this.dhpRefreshDriverProfileRiskClaimHistoryData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile AUXILIARY POINTS data */
  setRefreshDriverProfileAuxiliaryPointsData(driverId: number) {
    this.dhpRefreshDriverProfileAuxiliaryPointsData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile CURRENT MVR data */
  setRefreshDriverProfileCurrentMVRData(driverId: number) {
    this.dhpRefreshDriverProfileCurrentMVRData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile MVR HISTORY data */
  setRefreshDriverProfileMVRHistoryData(driverId: number) {
    this.dhpRefreshDriverProfileMVRHistoryData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile MVR VIOLATIONS data */
  setRefreshDriverProfileMVRViolationsData(driverId: number) {
    this.dhpRefreshDriverProfileMVRViolationsData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile DRIVER QUALIFICATIONS data */
  setRefreshDriverProfileDriverQualificationsData(driverId: number) {
    this.dhpRefreshDriverProfileDriverQualificationsData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile SECONDARY DRIVER data */
  setRefreshDriverProfileSecondaryDriverData(driverId: number) {
    this.dhpRefreshDriverProfileSecondaryDriverData.next(driverId);
  }

  /* set flag to indicate RESEND E-SIG EMAIL was clicked */
  setResendESignEmail(driverId: number) {
    this.dhpResendESignEmail.next(driverId);
  }

  /* set flag to indicate LEAVE OF ABSENCE was set */
  setRefreshDriverProfileLeaveOfAbsenceData(driverId: number) {
    this.dhpRefreshDriverProfileLeaveOfAbsenceData.next(driverId);
  }

   /* set flag to indicate PENDING TASKS was set */
  setRefreshDriverPendingTasksData(driverId: number) {
    this.dhpRefreshDriverPendingTasksData.next(driverId);
  }

  /* NOTIFIES THE MODAL TO CLOSE */
  notifyModalClose() {
    this.dhpClose.next(true);
  }

  /* NOTIFIES THE DRIVER HISTORY PROFILE COMPONENTS OF A DATA UPDATE */
  notifyDriverHistoryProfileDataUpdate(data: DriverProfile) {
    this.dhpDriverProfileData.next(data);
  }

  inActiveDriverRedirect(dp: DriverProfile, client: string, showFullNonEmployeeProfile: boolean): boolean {
    // handles redirect for terminated or LOA drivers
    // returns true if redirect happens
    let ret: boolean = false;

    let isTerminated = (!dp.isActive) && (dp.leaveOfAbsenceStatus == 0);
    let isCurrentLOA = (!dp.isActive) && (dp.leaveOfAbsenceStatus == 2);
    if (isTerminated) {
      if (dp.employeeId) {
        // redirect to Re-Activate Employee report if terminated employee driver
        this.router.navigate(['reactivatedrivergrid', dp.employeeId.trim(), '']);
      } else {
        // redirect to Primary Driver Profile if terminated non-employee driver
        this.router.navigate(['/driver/history-profile/' + dp.primaryDriverId.toString()], { queryParams: { openToTab: 'Driver Info' } });
      }

      ret = true;
    }
    if (!dp.isEmployee && !showFullNonEmployeeProfile) {
      this.router.navigate(['/driver/history-profile/' + dp.primaryDriverId.toString()], { queryParams: { openToTab: 'Non-Employees' } });
    }

    if (isCurrentLOA) {
      // redirect to LOA report
      //this.dataGridModalService.openModal(this.dataGridMenuService.getLeaveOfAbsenceReportId());
      this.dataGridModalService.openLeaveOfAbsenceReport(client);
      ret = true;
    }

    return ret;
  }
}

enum modalSize {
  'lg' = '800px',
  'md' = '500px',
  'sm' = '300px'
}