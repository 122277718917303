<navbar style="z-index: 99;" class="navbar navbar-expand-lg navbar-dark dashboardFilterPanelActionBar" [containerInside]="false">
  <ul class="nav navbar-nav nav-flex-icons ml-auto">
    <li class="nav-item">
      <a (click)="actionSelect('showAddWidgetPanel')">
        <span class='fa fa-plus'></span> Add Chart
      </a>
    </li>
    <li class="nav-item" *ngIf="!dashboardNoResults">
      <a (click)="actionSelect('resetTileFilters')" title="Reset Chart Date Ranges to Global Setting">
        <span class='far fa-calendar-times'></span> Clear Dates
      </a>
    </li>
    <li class="nav-item" *ngIf="!dashboardNoResults">
      <a (click)="actionSelect('showDateSelectPanel')" title="Set Global Date Range for StatCenter">
        <span class='fa fa-calendar-alt'></span> Global Date
      </a>
    </li>
    <li class="nav-item" *ngIf="!dashboardNoResults">
      <a (click)="actionSelect('showFilterPanel')">
        <span class='fa fa-filter'></span> Filters
      </a>
    </li>
  </ul>
</navbar>
