import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';

@Injectable()
export class DataGridMenuService {
  baseUrl: string;


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  getByLineOfBusiness(LineOfBusinessId: number, client: string) {
    let params = new HttpParams();
    params = params.append('LineOfBusiness', LineOfBusinessId.toString());
    params = params.append('Clients', client);
    return this.http.get(this.baseUrl + 'api/Datagrid/GetByLineOfBusiness', {
      params: params
    });
  }
  private leaveOfAbsenceReportId: number;
  getLeaveOfAbsenceReportId(): number {
    return this.leaveOfAbsenceReportId;
  }
  setLeaveOfAbsenceReportId(id: number) {
    this.leaveOfAbsenceReportId = id;
  }
}
