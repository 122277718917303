<h5 class="dashboardFilterSummary">
  <div class="row">
    <div class="col-md-6">
      {{fullSummaryText}}
    </div>
    <div class="col-md-6">
      <div style="float:right">
        <span style="padding-right:10px">Last Updated: {{dateLastUpdatedText}}</span>
        <a (click)="reloadDashboard()">
          <span class='fa fa-refresh'></span> Reload Dashboard
        </a>
      </div>
    </div>
  </div>
</h5>
