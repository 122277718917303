//Angular
import { Component, OnInit, OnDestroy, Inject, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
//Third Party
import { Subscription, Observable } from 'rxjs';
//App
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { OnPremClaimService } from '../../components/on-prem-service/on-prem-claim-service.component';
import { MatSelectOption } from '../../shared/models/mat-select-option.model';
import { MatSelect } from '@angular/material/select';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';

@Component({
  selector: 'app-accident-claim-edit-claim',
  templateUrl: './accident-claim-edit-claim.component.html',
  styleUrls: ['./accident-claim-edit-claim.component.scss']
})
export class AccidentClaimEditClaimComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('selectUnderwriting', { static: true }) selectUnderwriting: MatSelect;
  @ViewChild('selectLinesOfCoverage', { static: true }) selectLinesOfCoverage: MatSelect;
  _claimId: number;
  _routeSub: Subscription;
  baseUrl: string;
  dataGridFilterTypeChoices: any[];
  claim: EditClaim;
  linesOfCoverage: Array<MatSelectOption>;
  filteredLinesOfCoverage: Array<MatSelectOption>;
  underwritingCompanies: Array<MatSelectOption>;
  filteredunderwritingCompanies: Array<MatSelectOption>;
  objValidationFail: object = {};
  showValidationErrorMessage: boolean = false;
  @Output() onClaimUpdated: EventEmitter<any> = new EventEmitter<any>();

  selectedLineOfCoverage: string = "1";

  constructor(private readonly _route: ActivatedRoute,
    private readonly accidentClaimService: AccidentClaimService,
    private readonly http: HttpClient,
    private readonly errorService: ErrorModalService,
    private readonly onPremClaimService: OnPremClaimService,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly alertService: AlertService,
    @Inject('BASE_URL') baseUrl: string,
  ) {

    this.baseUrl = baseUrl;
  }


  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];
      this.initAll();
    });
  }

  ngAfterViewInit(): void {
    this.selectUnderwriting.panel.nativeElement.addEventListener('keydown', (event) => {
      if (event.code === 'Space') {
        event.preventDefault();
      }
    });

    this.selectLinesOfCoverage.panel.nativeElement.addEventListener('keydown', (event) => {
      if (event.code === 'Space') {
        event.preventDefault();
      }
    });
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
  }

  private initAll() {
    this.showValidationErrorMessage = false;
    this.getLineOfCoverages();
    this.getUnderwritingCompanies();
    this.getClaimInfo(this._claimId);
  }

  private getClaimInfo(claimId: number) {
    let params = new HttpParams();
    params = params.append('ClaimId', claimId.toString());

    this.http.get(this.baseUrl + 'api/Claim/GetEditClaimDetail', {
      params: params
    }).subscribe({
      next: (data) => {
        this.claim = data as EditClaim;
        if (this.claim.lineOfCoverageId) {
          this.selectedLineOfCoverage = this.claim.lineOfCoverageId.toString();
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
      }
    })
  }

  getLineOfCoverages() {
    const params = new HttpParams();

    this.http.get(this.baseUrl + 'api/Claim/GetLineOfCoverages', {
      params: params
    }).subscribe({
      next: (data) => {
        const tmp = data as Array<DropdownOption>;
        this.linesOfCoverage = tmp.map(x => { return { value: x.id.toString(), label: x.description } });
        this.filteredLinesOfCoverage = this.linesOfCoverage;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
      }
    })
  }

  getUnderwritingCompanies() {
    this.http.get(this.baseUrl + 'api/Claim/GetUnderwritingCompanies').subscribe({
      next: (data) => {
        const tmp = data as Array<DropdownOption>;

        this.underwritingCompanies = tmp.map(x => { return { value: x.id, label: x.description } })
        this.filteredunderwritingCompanies = this.underwritingCompanies;
      },
      error: (err) => this.errorService.setErrorObject(err.error)
    });
  }

  private validateForm(): boolean {
    let valid = true;
    const arrToValidate = [
      "insured",
      "subAccountName",
      "policyNumber",
      "clientClaimId",
      "rentalAllowance",
      "deductible",
      "underwritingCompanyId"
    ];

    this.objValidationFail = {};

    arrToValidate.forEach((fld) => {
      if ((!this.claim[fld]) || (this.claim[fld].length <= 0)) {
        valid = false;
        this.objValidationFail[fld] = true;
      }
    });
    return valid;
  }
  saveClaim() {
    this.claim.lineOfCoverageId = +this.selectedLineOfCoverage;
    console.log(this.claim);
    if (!this.validateForm()) {
      this.showValidationErrorMessage = true;
      return;
    }
    this.showValidationErrorMessage = false;
    this.loadingSpinnerService.show();
    this.onPremClaimService.put("entity/visibility/editClaimTab/" + this._claimId, JSON.stringify(this.claim))
      .subscribe({
        next: (x) => {
          this.alertService.showSuccessAlert('Claim Successfully Updated!', 'end', 'top', 5000);
          this.loadingSpinnerService.hide();
          this.onClaimUpdated.emit();
        }, error: (err) => {
          this.loadingSpinnerService.hide();
          this.errorService.setErrorObject(err.error);
        }
      });
  }

  filterLinesOfCoverageOptions(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredLinesOfCoverage = this.linesOfCoverage.filter(option => option.label.toLowerCase().startsWith(value));
  }

  filterUnderwritingCompanyOptions(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredunderwritingCompanies = this.underwritingCompanies.filter(option => option.label.toLowerCase().startsWith(value));
  }

  keyPresses(event) {
    console.log(" event", event)
  }

}
export interface EditClaim {
  claimId: number;
  insured: string;
  subAccountName: string;
  policyNumber: string;
  clientClaimId: string;
  deductible: string;
  rentalAllowance: string;
  clientAdjusterEmailAddress: string;
  clientAdjusterName: string;
  lineOfCoverageId: number;
  underwritingCompanyId: number;
}
export interface DropdownOption {
  id: number;
  description: string;
}
