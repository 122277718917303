import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DriverClientSettings } from '../../../components/classes-and-interfaces/classes-and-interfaces.component';
import { SafetyProcessUiProperties } from '../safety-process-ui-properties';
import { TargetDriverTypes, SafetyProcess, SafetyProcessList } from '../services/safety-processes.service';


@Component({
  selector: 'app-assign-training-tab',
  templateUrl: './assign-training-tab.component.html',
  styleUrls: ['./assign-training-tab.component.css']
})
export class AssignTrainingTabComponent implements OnInit {
  @Input() safetyProcesses: Map<SafetyProcessList, SafetyProcess>;
  @Input() parentForm: UntypedFormGroup;
  @Input() formGroupNameInput: string;

  public trainingAssignmentsForm: UntypedFormGroup = new UntypedFormGroup({});
  public mvrRequestFilter = new UntypedFormControl({ value: new Date() });
  public hideMonitoringControl = true;
  public uiMap = new Map<SafetyProcessList, SafetyProcessUiProperties>();

  constructor() {
    this.uiMap.set(SafetyProcessList.Training,
      { displayOrder: 1, controlLabel: 'Assign Training', requiresDueDate: true });
  }
  ngOnInit(): void {
    const test = 1;

    this.parentForm.addControl(this.formGroupNameInput, this.trainingAssignmentsForm);
    this.trainingAssignmentsForm.addControl('mvrRequestFilter', this.mvrRequestFilter);

    if (
        this.safetyProcesses.has(SafetyProcessList.Monitoring) &&
        this.safetyProcesses.get(SafetyProcessList.Monitoring).userCanAssignTo !== TargetDriverTypes.NONE
    ) {
      // this.multipleServicesForm.addControl('monitoringStatusFilter',)
      this.hideMonitoringControl = false;
    }
  }
}
