<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" style="padding-bottom:20px">
  <div class="col-sm-9">
    <h3>{{isNewDataGrid?"Add":"Edit"}} Report</h3>
  </div>
  <div class="col-sm-3">
    <button type="button"
            (click)="saveDataGrid()"
            class="btn btn-primary">
      Save
    </button>
    <button type="button"
            (click)="exitDataGridEdit()"
            class="btn btn-primary">
      Exit
    </button>
  </div>
</div>
<div *ngIf="dataGridNotFound" class="row">
  <div class="col-sm-12">Report with id {{dataGridId}} not found</div>
</div>
<div *ngIf="dataGridLoading" class="row">
  <div class="col-sm-12">Loading...</div>
</div>
<ng-container *ngIf="!(dataGridNotFound||dataGridLoading)">
  <div class="row">
    <div class="col-sm-12">
      <div class="md-form">
        <input id="dataGridTitle" type="text" [(ngModel)]="dataGridObject.title" class="form-control" placeholder="Display Name"/>
        <label for="dataGridTitle" [ngClass]="{ 'label-validation-error': objValidationFail['title'], 'active':true }">Display Name</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="md-form">
        <input id="dataGridDesc" type="text" [(ngModel)]="dataGridObject.description" class="form-control" placeholder="Description (Displayed to user)"/>
        <label for="dataGridDesc" [ngClass]="{ 'label-validation-error': objValidationFail['description'], 'active':true }">Description (Displayed to user)</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <span class="widgetLabelBold">Globally Enable/Disable</span>
    </div>
    <div class="col-md-8">
      <span class="widgetLabelSmaller">Make this report available to be linked to charts.</span>
    </div>
  </div>
  <div class="row select-cell-margin">
    <div class="col-sm-2">
      <div class="switch">
        <label>
          <input [checked]="dataGridObject.isActive" (change)="dataGridObject.isActive=!dataGridObject.isActive" type="checkbox">
          <span class="lever enableWidgetSwitch"></span>
          <span class="enableWidgetLabel">{{dataGridObject.isActive?"Enabled":"Disabled"}}</span>
        </label>
      </div>
    </div>
    <div class="col-md-10">
      <a href="javascript:void(0);" (click)="openDataGridPerClientSettings()">Manage per-client settings</a>
    </div>
  </div>
  <div class="row select-cell-margin">
    <div class="col-sm-12">
      <div class="md-form">
        <input id="dataGridStoredProcedure" type="text" [(ngModel)]="dataGridObject.dataSourceSql" class="form-control" placeholder="Stored Procedure for this report" />
        <label for="dataGridStoredProcedure" [ngClass]="{ 'label-validation-error': objValidationFail['dataSourceSql'], 'active':true }">Stored Procedure for this report</label>
      </div>
    </div>
  </div>
  <div class="row select-cell-margin">
    <div class="col-md-12">
      <label for="lineOfBusinessIdString" [ngClass]="{ 'label-validation-error': objValidationFail['lineOfBusinessIdString'], 'active':true }">Line Of Business</label>
    
      <mat-form-field appearance="fill" class="select-container w-100">
        <mat-select #selectTitleHolder="matSelect" [(value)]="dataGridObject.lineOfBusinessIdString">
          <mat-option *ngFor="let option of dataGridLineOfBusinessChoices" [value]="option.value">
            {{option.label}}
          </mat-option>
        </mat-select>        
      </mat-form-field>
    </div>
  </div>

  <div class="row select-cell-margin">
    <div class="col-sm-2">
      <div class="switch">
        <label>
          <input [checked]="dataGridObject.isDefault" (change)="dataGridObject.isDefault=!dataGridObject.isDefault" type="checkbox">
          <span class="lever enableWidgetSwitch"></span>
          <span class="enableWidgetLabel">{{dataGridObject.isDefault?"Show":"Hide"}}</span>
        </label>
      </div>
    </div>
    <div class="col-sm-10">
      <div class="row">
        <div class="col-sm-12">
          <span class="widgetLabelBold">Show for new client</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <span class="widgetLabelSmaller">Show for new client.</span>
        </div>
      </div>
    </div>
  </div>


  <div class="row select-cell-margin">
    <div class="col-sm-2">
      <div class="switch">
        <label>
          <input [checked]="dataGridObject.isInNavMenu" (change)="dataGridObject.isInNavMenu=!dataGridObject.isInNavMenu" type="checkbox">
          <span class="lever enableWidgetSwitch"></span>
          <span class="enableWidgetLabel">{{dataGridObject.isInNavMenu?"Show":"Hide"}}</span>
        </label>
      </div>
    </div>
    <div class="col-sm-10">
      <div class="row">
        <div class="col-sm-12">
          <span class="widgetLabelBold">Show in left navigation</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <span class="widgetLabelSmaller">Show in left navigation.</span>
        </div>
      </div>
    </div>
  </div>


  <div class="row select-cell-margin">
    <div class="col-sm-2">
      <div class="switch">
        <label>
          <input [checked]="dataGridObject.showFilterSw" (change)="dataGridObject.showFilterSw=!dataGridObject.showFilterSw" type="checkbox">
          <span class="lever enableWidgetSwitch"></span>
          <span class="enableWidgetLabel">{{dataGridObject.showFilterSw?"Show":"Hide"}}</span>
        </label>
      </div>
    </div>
    <div class="col-sm-10">
      <div class="row">
        <div class="col-sm-12">
          <span class="widgetLabelBold">Show Pop-up when user clicks left navigation</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <span class="widgetLabelSmaller">Show a Pop-up dialog with filters when user clicks this report from the left navigation.</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row select-cell-margin">
    <div class="col-sm-2">
      <div class="switch">
        <label>
          <input [checked]="dataGridObject.isOnPrem" (change)="dataGridObject.isOnPrem=!dataGridObject.isOnPrem" type="checkbox">
          <span class="lever enableWidgetSwitch"></span>
          <span class="enableWidgetLabel">{{dataGridObject.isOnPrem?"Show":"Hide"}}</span>
        </label>
      </div>
    </div>
    <div class="col-sm-10">
      <div class="row">
        <div class="col-sm-12">
          <span class="widgetLabelBold">On Premise API</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <span class="widgetLabelSmaller">On Premise API</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row select-cell-margin">
    <div class="col-sm-2">
      <div class="md-form">
        <input id="customReportUrl" type="text" [(ngModel)]="dataGridObject.customReportUrl" class="form-control" />
      </div>
    </div>
    <div class="col-sm-10">
      <div class="row">
        <div class="col-sm-12">
          <span class="widgetLabelBold">Custom Report Url</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <span class="widgetLabelSmaller">Custom Report Url</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row select-cell-margin">
    <div class="col-sm-2">
      <div class="md-form">
        <input id="onPremReportId" type="text" [(ngModel)]="dataGridObject.onPremReportId" class="form-control" />
      </div>
    </div>
    <div class="col-sm-10">
      <div class="row">
        <div class="col-sm-12">
          <span class="widgetLabelBold">On Premise Report Id</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <span class="widgetLabelSmaller">On Premise Report Id</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row select-cell-margin">
    <div class="col-sm-9">
      <div class="row">
        <div class="col-sm-12">
          <span class="widgetLabelBold">Report Filters</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <span class="widgetLabelSmaller">Choose report filters to display in the Pop-up dialog when the user navigates to the report.</span>
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <button type="button"
              (click)="openDataGridFilterModal(null)"
              class="btn btn-primary">
        Add New
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">Filter</th>
            <th scope="col">Type</th>
            <th scope="col">Order</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let filter of dataGridFilterArr | sortDataGridFilters">
            <td>
              <a href="javascript:void(0);" (click)="openDataGridFilterModal(filter)" class="dataGridAdminEditLink">
                {{filter.displayName}}
              </a>
            </td>
            <td>{{filter.filterType}}</td>
            <td>{{filter.filterOrder}}</td>
            <td>
              <button type="button" class="close dataGridAdminEditRemoveButton" aria-label="Remove" (click)="removeDataGridFilter(filter.dataGridFilterId)">
                <span aria-hidden="true">&times;</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
