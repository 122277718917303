<div id="riskHistory" class="row" *ngIf="showRiskHistory">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <div class="row">
            <div class="col-md-6">
              <safety-driver-risk-chart [driverProfile]="driverProfile"></safety-driver-risk-chart>
              <safety-driver-ranking [driverProfile]="driverProfile"></safety-driver-ranking>
              <div *ngIf="lastPreventableIncidentText" class="row" style="margin-top:1rem">
                <div class="col-md-12" style="text-align:center">
                  {{lastPreventableIncidentText}}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <h4 class="card-title text-muted" style="text-align:left;">Risk History</h4>
                  <app-loading-panel *ngIf="!driverRiskHistoryTableConfig"></app-loading-panel>
                  <div *ngIf="driverRiskHistoryTableConfig">
                    <app-data-table 
                      [columns]="driverRiskHistoryTableConfig.fields"
                      [data]="driverRiskHistoryTableConfig.data"
                      noRecordsLabel="No Risk History"
                      (triggerOpenModal)="openModal($event)"
                      (triggerNavigate)="onNavigate($event)"
                    ></app-data-table>
                  </div>

                </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="text-align:center">
                  <span *ngFor="let el of arrRiskHistoryLegend; let last=last" [ngStyle]="{'font-weight':'bold','color': el.visibilityRowColor}">
                    {{el.driverRiskLevel + ': ' + el.pointRange + ' points' + (last?'':'; ')}}
                  </span>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="claimHistory" class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Claim History</h4>
          <app-loading-panel *ngIf="!driverClaimTableConfig"></app-loading-panel>
          <div *ngIf="driverClaimTableConfig">
            <app-data-table 
              [columns]="driverClaimTableConfig.fields"
              [data]="driverClaimTableConfig.data"
              noRecordsLabel="No Claim History"
              (triggerOpenModal)="openModal($event)"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="mvrViolations" class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">MVR Violations</h4>
          <app-loading-panel *ngIf="!driverMVRViolationsTableConfig"></app-loading-panel>
          <div *ngIf="driverMVRViolationsTableConfig">
            <app-data-table 
              [columns]="driverMVRViolationsTableConfig.fields"
              [data]="driverMVRViolationsTableConfig.data"
              noRecordsLabel="No MVR Violations"
              (triggerOpenModal)="openModal($event)"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="additionalPoints" class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Additional Points</h4>
          <app-loading-panel *ngIf="!driverPointsTableConfig"></app-loading-panel>
          <div *ngIf="driverPointsTableConfig">
            <app-data-table 
              [columns]="driverPointsTableConfig.fields"
              [data]="driverPointsTableConfig.data"
              noRecordsLabel="No Additional Points"
              (triggerOpenModal)="openModal($event)"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
