import { Component, Inject, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { DcDriverProfileService } from '../dc-driver-profile/dc-driver-profile-service.component';
import { SafetyModalWindowService } from '../../safety/safety-modal-window/safety-modal-window-service.component';
import { FormControl, Validators, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'dc-training-summary-export',
  templateUrl: './dc-training-summary-export.component.html',
  styleUrls: ['./dc-training-summary-export.component.css']
})
export class DcTrainingSummaryExportComponent {
  

  constructor()
  {
    
  }

 
}
