<div class="row">
  <div class="col-md-12">
    <app-loading-panel *ngIf="!configObject"></app-loading-panel>
    <div *ngIf="configObject && (!showEntryForm && isNew)">
      <app-data-table
        [columns]="configObject.fields"
        [data]="configObject.data"
        (triggerAction)="onTriggerAction($event)"
        noRecordsLabel="No entries"
      ></app-data-table>
    </div>
    <div class="row">
      <div class="col-md-6" *ngIf="!showEntryForm">
        <button class="btn btn-default" (click)="showNewEntryForm()">Add New Time Entry</button>

        <button class="btn btn-default" style="margin-bottom:6px" (click)="exit()">
          Exit
        </button>
      </div>
    </div>
    <div class="row">

      <div class="col-sm-12" *ngIf="showEntryForm">
        <div class="row">
          <div class="col-sm-12">
            <h3 *ngIf="isNew">Add New Time Entry</h3>
            <h3 *ngIf="!isNew">Edit Time Entry</h3>
          </div>
        </div>
        <div class="row">

          <div class="col-sm-12">
            <div class="md-form">

              <div class="row">
                <div class="col-sm-4">Vehicle Number</div>
                <div class="col-sm-4">Clock In Date</div>
                <div class="col-sm-4">Clock In Time</div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="md-form">
                    <input id="vehicleNumber" type="text" [(ngModel)]="newEntry.VehicleNumber" class="form-control"
                      required active />
                    <!--<label for="vehicleNumber">Vehicle Number</label>-->
                  </div>
                </div>

                <div class="col-sm-4">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label class="lbl-start-date">Clock In Date</mat-label>
                    <input class="input-date" disabled matInput [matDatepicker]="startDatePicker" [formControl]="beginDateControl"
                      [(ngModel)]="clockInDate"  (dateChanged)="onDateChange($event)" >
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker disabled="false">
                      <mat-datepicker-actions>
                        <button mat-button (click)="startDatePicker.select(actualDate); startDatePicker.close()">
                          <mat-icon>history</mat-icon>
                          Today
                        </button>
                        <button mat-button (click)="startDatePicker.select(null); startDatePicker.close()">
                          <mat-icon>highlight_off</mat-icon>
                          Clear
                        </button>
                      </mat-datepicker-actions>
                    </mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-sm-4">

                  <div class="md-form">

                    <input type="time" id="clockInTimePicker" class="form-control" #clockInTimePicker
                      name="clockInTimePicker" [(ngModel)]="clockInTime" />
                    <!--<label for="clockInTimePicker">Clock In Time</label>-->
                  </div>

                </div>

              </div>

              <div class="row">
                <div class="col-sm-4"></div>
                <div class="col-sm-4">Clock Out Date</div>
                <div class="col-sm-4">Clock Out Time</div>
              </div>
              <div class="row">
                <div class="col-sm-4"></div>
                <div class="col-sm-4">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Clock Out Date</mat-label>
                      <input class="input-date" matInput disabled [matDatepicker]="endDatePicker" 
                      (dateChanged)="onDateChange($event)" [(ngModel)]="endDate" [formControl]="endDateControl">
                      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                      <mat-datepicker #endDatePicker disabled="false">
                        <mat-datepicker-actions>
                          <button mat-button (click)="endDatePicker.select(actualDate); endDatePicker.close()">
                            <mat-icon>history</mat-icon>
                            Today
                          </button>
                          <button mat-button (click)="endDatePicker.select(null); endDatePicker.close()">
                            <mat-icon>highlight_off</mat-icon>
                            Clear
                          </button>
                        </mat-datepicker-actions>
                      </mat-datepicker>
                    </mat-form-field>
                  </div>
                <div class="col-sm-4">
                  <div class="md-form">

                    <input type="time" id="clockOutTimePicker" class="form-control" #clockOutTimePicker
                      name="clockOutTimePicker" [(ngModel)]="clockOutTime" />
                    <!--<label for="clockInTimePicker">Clock In Time</label>-->
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div *ngIf="!isFormValid" class="label-validation-error">All fields are required!</div>
          </div>
        </div>
        <div class="row">
          <button *ngIf="isNew" class="btn btn-default" style="margin-bottom:6px" (click)="addEntry()">Add</button>
          <button *ngIf="!isNew" class="btn btn-default" style="margin-bottom:6px" (click)="addEntry()">Save</button>
          <button class="btn btn-default" style="margin-bottom:6px" (click)="cancel()">
            Cancel
          </button>

        </div>

      </div>
      <!--<div class="col-sm-12" *ngIf="!showEntryForm">
        
      </div>-->
    </div>
  </div>
</div>