import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject } from "rxjs";
import { DashboardTile, DashboardFilterRecord, DashboardGridsterItem } from "../classes-and-interfaces/classes-and-interfaces.component";
import { ReportRequest } from "../data-grid/data-grid.service";
import { ErrorModalService } from "../error-modal/error-modal-service.component";

@Injectable()
export class DashboardService {
    baseUrl: string;

    private readonly goToLineOfBusiness = new Subject();
    goToLineOfBusiness$ = this.goToLineOfBusiness.asObservable();
    private readonly tileArray = new Subject();
    tileArray$ = this.tileArray.asObservable();
    private readonly availableTileArray = new Subject();
    availableTileArray$ = this.availableTileArray.asObservable();
    private readonly filterInfoArray = new Subject();
    filterInfoArray$ = this.filterInfoArray.asObservable();
    private readonly tileIdAdded = new Subject();
    tileIdAdded$ = this.tileIdAdded.asObservable();
    private readonly tileIdRemoved = new Subject();
    tileIdRemoved$ = this.tileIdRemoved.asObservable();
    private readonly tileZoom = new Subject();
    tileZoom$ = this.tileZoom.asObservable();
    private readonly tileToFilter = new Subject();
    tileToFilter$ = this.tileToFilter.asObservable();

    private readonly showClaimsFilterPanel = new Subject();
    showClaimsFilterPanel$ = this.showClaimsFilterPanel.asObservable();

    // 2019-02 remove observables related to powerbi
    //private tilesLoading = new BehaviorSubject<boolean>(false);
    //tilesLoading$ = this.tilesLoading.asObservable();
    //private reportLoaded = new Subject();
    //reportLoaded$ = this.reportLoaded.asObservable();
    private readonly gridsterDashboard = new Subject();
    gridsterDashboard$ = this.gridsterDashboard.asObservable();
    private readonly gridsterAutoArrange = new Subject();
    gridsterAutoArrange$ = this.gridsterAutoArrange.asObservable();
    private readonly dashboardLastUpdated = new BehaviorSubject<DashboardLastUpdatedObject>({});
    dashboardLastUpdated$ = this.dashboardLastUpdated.asObservable();

    constructor(
        private readonly http: HttpClient,
        @Inject('BASE_URL') baseUrl: string,
        private readonly errorService: ErrorModalService
    ) {
        this.http = http;
        this.baseUrl = baseUrl;
    }

    getTileArray(LineOfBusinessId: number, clientShortNames: Array<string>) {
        let params = new HttpParams();
        params = params.append('LineOfBusiness', LineOfBusinessId.toString());
        params = params.append('Clients', clientShortNames.join(','));

        return this.http.get(this.baseUrl + 'api/Dashboard/GetDashboardTiles', {
            params: params
        });
    }

    setTileArray(tileArray: Array<DashboardTile>) {
        this.tileArray.next(tileArray);
    }

    showClaimsFilters() {
        this.showClaimsFilterPanel.next(true);
    }

    getTileById(tileId: number, LineOfBusinessId: number, clientShortNames: Array<string>) {
        let params = new HttpParams();
        params = params.append('TileId', tileId.toString());
        params = params.append('LineOfBusinessId', LineOfBusinessId.toString());
        params = params.append('Clients', clientShortNames.join(','));

        return this.http.get(this.baseUrl + 'api/Dashboard/GetTile', {
            params: params
        });
    }

    getAvailableTileArray(LineOfBusinessId: number, clientShortNames: Array<string>) {
        let params = new HttpParams();
        params = params.append('LineOfBusiness', LineOfBusinessId.toString());
        params = params.append('Clients', clientShortNames.join(','));

        return this.http.get(this.baseUrl + 'api/Dashboard/GetAvailableTiles', {
            params: params
        });
    }

    setAvailableTileArray(tileArray: Array<DashboardTile>) {
        this.availableTileArray.next(tileArray);
    }

    updateTilePosition(tileId: number, x: number, y: number) {
        let params = new HttpParams();
        params = params.append('TileId', tileId.toString());
        params = params.append('PositionX', x.toString());
        params = params.append('PositionY', y.toString());

        return this.http.post(this.baseUrl + 'api/Dashboard/AddOrUpdate', null, { params: params })
    }

    updateTileDateFilter(tile: DashboardTile, filterStartDate: string, filterEndDate: string) {
        let params = new HttpParams();
        params = params.append('TileId', tile.tileId.toString());
        params = params.append('PositionX', tile.positionX.toString());
        params = params.append('PositionY', tile.positionY.toString());
        params = params.append('FilterStartDate', filterStartDate);
        params = params.append('FilterEndDate', filterEndDate);

        return this.http.post(this.baseUrl + 'api/Dashboard/AddOrUpdate', null, { params: params })
    }

    removeTile(tileId: number) {
        let params = new HttpParams();
        params = params.append('tileId', tileId.toString());

        return this.http.delete(this.baseUrl + 'api/Dashboard/RemoveUserTile', { params: params })
    }

    getFilterDataMultiClient(LineOfBusinessId: number, clientArr: Array<number>) {
        const param = { LineOfBusiness: 1, clientIds: clientArr }

        return this.http.post(this.baseUrl + 'api/UserFilter/GetMultiClient', JSON.stringify(param), { headers: { 'Content-Type': 'application/json' } });

    }

    getFilterDataSingleClient(LineOfBusinessId: number, clientId: number) {
        let params = new HttpParams();
        params = params.append('clientId', clientId.toString());
        params = params.append('LineOfBusiness', LineOfBusinessId.toString());

        return this.http.get(this.baseUrl + 'api/UserFilter/Get', {
            params: params
        })
    }

    resetTileFilters(LineOfBusinessId: number) {
        let params = new HttpParams();
        params = params.append('LineOfBusinessId', LineOfBusinessId.toString());

        return this.http.post(this.baseUrl + 'api/Dashboard/ResetTileDateRangeFilters', null, {
            params: params
        })
    }



    buildGridRequestHighCharts(gridRequest: ReportRequest, data: [any], thisCard: DashboardTile): boolean {
        if (data.length > 0) {
            gridRequest.reset();
            gridRequest.DataGridId = thisCard.dataGridId;
            gridRequest.SelectedClientIds = thisCard.clientSelectedArray;
            gridRequest.clickTrough = true;


            const arrParam = [];
            data.forEach(i => arrParam.push(
                {
                    ParameterName: i.name,
                    DisplayValue: i.name,
                    Value: i.value
                }
            ));
            if (thisCard.hasSlicer) {
                arrParam.push(
                    {
                        ParameterName: thisCard.slicerPbixFieldName,
                        DisplayValue: thisCard.slicerDisplayName,
                        Value: thisCard.slicerOptionChosen
                    }
                );

            }
            gridRequest.ParameterValues = arrParam;

            // date filter info
            // get start & end date info from tile and global filters
            let startDate: string = null;
            let endDate: string = null;

            // global date filter values are either in Date object format or string
            if (thisCard.globalDateFilter) {
                startDate = thisCard.globalDateFilter.startDate || null;
                endDate = thisCard.globalDateFilter.endDate || null;
            }

            // override global filters with any tile filters
            if (thisCard.tileDateFilterTypeId === 1) {
                // start date for filter type 1
                if (thisCard.filterStartDate && (thisCard.filterStartDate.length > 0)) {
                    startDate = thisCard.filterStartDate;
                }
            }
            if ((thisCard.tileDateFilterTypeId === 1) || (thisCard.tileDateFilterTypeId === 2)) {
                // end date for filter type 1 or 2
                if (thisCard.filterEndDate && (thisCard.filterEndDate.length > 0)) {
                    endDate = thisCard.filterEndDate;
                }
            }
            gridRequest.DateFilterTypeId = thisCard.tileDateFilterTypeId;
            gridRequest.StartDate = new Date(startDate);
            gridRequest.EndDate = new Date(endDate);

            return true;
        } else {
            return false;
        }
    }

    setFilterData(filterInfoArray: Array<DashboardFilterRecord>) {
        this.filterInfoArray.next(filterInfoArray);
    }

    setTileIdAdded(tileId: number) {
        this.tileIdAdded.next(tileId);
    }

    setTileIdRemoved(tileId: number) {
        this.tileIdRemoved.next(tileId);
    }

    setTileZoom(tile: DashboardTile) {

        this.tileZoom.next(tile);


        //let params = new HttpParams();
        //params = params.append('ReportGuid', tile.reportGuid);
        //this.http.get(this.baseUrl + 'api/AdminTile/GetEmbedToken', { params: params })
        //  .subscribe(data => {
        //    tile.token = (data as PowerBIToken).token;
        //    this.tileZoom.next(tile);
        //  },
        //    (err: HttpErrorResponse) => {
        //      //this.toastrService.error(err.error.toString());
        //      this.errorService.setErrorObject(err.error);
        //    });


    }

    setTileToFilter(filterInfo: any) {
        this.tileToFilter.next(filterInfo);
    }

    /* 2019-02 remove observables related to powerbi
    setTilesLoading(tilesLoading: boolean) {
      this.tilesLoading.next(tilesLoading);
    }
  
    getTilesLoadingValue() {
      return this.tilesLoading.getValue();
    }
  
    setReportLoaded(tileId: number) {
      this.reportLoaded.next(tileId);
    }
    */

    setGoToLineOfBusiness(lineOfBusinessId: number) {
        this.goToLineOfBusiness.next(lineOfBusinessId);
    }

    setGridsterDashboard(gridsterDashboard: Array<DashboardGridsterItem>) {
        this.gridsterDashboard.next(gridsterDashboard);
    }

    setGridsterAutoArrange(flag: boolean) {
        this.gridsterAutoArrange.next(flag);
    }

    setDashboardLastUpdated(lineOfBusiness: number, dt: Date) {
        const d = this.dashboardLastUpdated.getValue();
        d[lineOfBusiness] = dt;
        this.dashboardLastUpdated.next(d);
    }

    getDashboardLastUpdatedValue(lineOfBusiness: number) {
        return this.dashboardLastUpdated.getValue()[lineOfBusiness];
    }

    getTileCategoriesByLOB(lineOfBusinessId: number) {
        let params = new HttpParams();
        params = params.append('LineOfBusinessId', lineOfBusinessId.toString());

        return this.http.get(this.baseUrl + 'api/Dashboard/GetTileCategories', {
            params: params
        });

    }

    getTileCategories() {
        return this.http.get(this.baseUrl + 'api/Dashboard/GetTileCategories');

    }
}

export class DashboardLastUpdatedObject {
    [key: number]: Date;
}
