import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { Subscription } from 'rxjs';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { DriverProfile, DQFileManagementBaseInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DQRejectModalComponent, DqRejectModalReason } from '../dq-reject-modal/dq-reject-modal.component';
import { DQApproveModalComponent } from '../dq-approve-modal/dq-approve-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';

const safetyRoute = "/safety/coivalidationgrid";
@Component({
  selector: 'app-coi-validation-review',
  templateUrl: './coi-validation-review.component.html',
  styleUrls: ['./coi-validation-review.component.css']
})

export class COIValidationReviewComponent implements OnInit, OnDestroy {
  //@ViewChild('dqCOIReviewFileInput') inputEl: ElementRef;
  formData: FormData;
  files: File[];
  private routeSub: Subscription;
  private driverId: string;
  private gridId: string;
  private driverProcessAssignedId: string;
  coiInstructions = '';
  coi: COIInfo;
  coiSubmitInfo: COISubmitInfo;
  driverInfo: DriverProfile;
  rejectOptions: Array<any>;
  arrFileName: Array<string> = [];
  

  formControlExpirationDate: UntypedFormControl = new UntypedFormControl("", [Validators.required]);

  constructor(readonly router: Router,
    readonly route: ActivatedRoute,
    readonly onPremService: OnPremDriverService,
    readonly lineOfBusinessService: LineOfBusinessService,
    readonly driverHistoryProfileService: DriverHistoryProfileService,
    readonly datePipe: DatePipe,
    readonly cdRef: ChangeDetectorRef,
    readonly loadingSpinnerService: LoadingSpinnerService,
    readonly alertService: AlertService,
    readonly errorService: ErrorModalService,
    readonly dialog: MatDialog) {
    // for mdb upload control
    this.files = [];
  }

  ngOnInit() {
    //make sure LOB=2
    //this.lineOfBusinessService.setLineOfBusiness(2);
    const lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob !== 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    this.routeSub = this.route.params.subscribe(params => {
      this.driverId = params['driverId'];
      this.gridId = params['gridId'];
      this.driverProcessAssignedId = params['driverProcessAssignedId'];
      this.initAll(true);
    });
  }

  private initAll(updateSubmitInfo: boolean) {
    const endpoint = `coi/${this.driverId}/${this.driverProcessAssignedId}`;
    if (endpoint.length > 0) {
      this.loadingSpinnerService.show();
      this.onPremService.get(endpoint).subscribe({
        next: (data) => {
          this.coi = data as COIInfo;
          if (!this.coi.documentId) {
            if (this.gridId) {
              this.router.navigate([`${safetyRoute}/${this.gridId}`]);
            }
            else {
              this.router.navigate([safetyRoute]);
            }
          } else {
            this.arrFileName = [this.coi.fileName];

            this.coiSubmitInfo = new COISubmitInfo(this.coi);
            this.getDriverInfo(this.driverId);
            this.getRejectOptions();
            this.getCOIInstructions();
          }
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    } else {
      this.errorService.setErrorObject({ message: 'Invalid Parameters' });
    }
  }

  private getCOIInstructions() {
    this.onPremService.get(`coi/${this.driverId}/coiinstructions`).subscribe({
      next: (data) => {
        this.coiInstructions = data as string;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private getDriverInfo(driverId: string) {
    this.driverHistoryProfileService.getDriverHistoryProfileBaseData(driverId).subscribe({
      next: (data) => {
        this.driverInfo = data;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private getRejectOptions() {
    this.onPremService.get("lookup/coi/rejectionreasons").subscribe({
      next: (data) => {
        const tempin: Array<COIRejectReason> = data as Array<COIRejectReason>;
        const tempout: Array<DqRejectModalReason> = [];
        tempin.forEach((item: COIRejectReason, index: number) => {
          const tempobj = {
            value: item.insuranceVerificationRejectReasonId.toString(),
            label: item.insuranceVerificationRejectReason,
            isReasonRequired: item.isReasonRequired
          };
          tempout.push(tempobj);
        })
        this.rejectOptions = tempout;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  openDriverProfile() {
    this.router.navigate(["/driver/history-profile/" + this.driverId]);
  }

  openApproveModal() {
    const dialogRef = this.dialog.open(DQApproveModalComponent, {
      data: { dqType: 'Certificate of Insurance', attNames: this.arrFileName, expirationDate: this.datePipe.transform(this.coi?.insuranceExpirationDate, 'MM/dd/yyyy') },
      minWidth: '31em',
      minHeight: '10em',
      panelClass: 'add-document-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.approve(result);
    })
  }

  openRejectModal() {
    const dialogRef = this.dialog.open(DQRejectModalComponent, {
      data: { dqType: 'Certificate of Insurance', rejectOptions: this.rejectOptions },
      minWidth: '31em',
      minHeight: '10em',
      panelClass: 'add-document-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reject(result);
      }
    })
  }

  approve(approveInfo: any) {
    const expirationDate = approveInfo.expirationDate.toISOString();
    const endpoint = `coi/${this.coiSubmitInfo.driverProcessAssignedId}/${this.coiSubmitInfo.insuranceVerificationId}/approve?insuranceExpirationDate=${expirationDate}`;
    const postput = this.onPremService.post(endpoint, null);

    this.loadingSpinnerService.show();
    postput.subscribe({
      next: (data) => {
        this.alertService.showSuccessAlert('Certificate of Insurance Approved', 'end', 'top', 5000);
        this.loadingSpinnerService.hide();

        if (this.gridId) {
          this.router.navigate([`${safetyRoute}/${this.gridId}`]);
        }
        else { this.router.navigate([safetyRoute]); }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error.message);
        this.loadingSpinnerService.hide();
      }
    });
  }

  reject(rejectInfo: any) {
    const endpoint = `coi/${this.coiSubmitInfo.driverProcessAssignedId.toString()}/${this.coiSubmitInfo.insuranceVerificationId.toString()}/reject`;
    const postData = JSON.parse(JSON.stringify(this.coiSubmitInfo));

    postData.rejectionReasonId = +rejectInfo.rejectionReasonIdString;
    postData.otherRejectionReason = rejectInfo.otherRejectionReason;
    delete postData.insuranceVerificationId;
    delete postData.driverProcessAssignedId;

    const postput = this.onPremService.post(endpoint, JSON.stringify(postData));

    this.loadingSpinnerService.show();
    postput.subscribe({
      next: (data) => {
        this.alertService.showSuccessAlert('Certificate of Insurance Rejected', 'end', 'top', 5000);
        this.loadingSpinnerService.hide();
        if (this.gridId) {
          this.router.navigate([`${safetyRoute}/${this.gridId}`]);
        }
        else
        {
          this.router.navigate([`${safetyRoute}`]);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error.message);
        this.loadingSpinnerService.hide();
      }
    });
  }


  cancel() {
    if (this.gridId) {
      this.router.navigate([`${safetyRoute}/${this.gridId}`]);
    }
    else {
      this.router.navigate([`${safetyRoute}`]);
    }
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

}

export interface COIInfo {
  insuranceVerificationId: number;
  driverProcessAssignedId: number;
  documentId: number;
  fileName: string;
  uploadDate: string;
  uploadUserId: number;
  insuranceExpirationDate: string;
  rejectReasonId: number;
  rejectionReasonIfOther: string;
  ReviewProcessCompleteDate: string;
  ReviewProcessCancelDate: string;
}

export interface COIRejectReason {
  insuranceVerificationRejectReasonId: number;
  insuranceVerificationRejectReason: string;
  isReasonRequired: boolean;
}

export class COISubmitInfo {
  insuranceVerificationId: number;
  driverProcessAssignedId: number;
  rejectionReasonId: number;
  otherRejectionReason: string;

  constructor(info: COIInfo) {
    this.driverProcessAssignedId = info.driverProcessAssignedId;
    this.insuranceVerificationId = info.insuranceVerificationId;
    this.rejectionReasonId = 1;
  }

}
