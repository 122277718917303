import { Subject ,  Observable ,  BehaviorSubject } from 'rxjs';
import { DriverHistoryProfileModal } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { Injectable } from '@angular/core';
import { SafetyModalWindowComponent } from './safety-modal-window.component';
import { MatDialog } from '@angular/material/dialog';
import { modalSize } from '../../enum/modal-size.enum';

@Injectable()
export class SafetyModalWindowService {
  // alert for the modal open with data supplied from the ui layer
  public dhp = new Subject<DriverHistoryProfileModal>();
  public dhpDefaultTabIndex = new BehaviorSubject<number>(0);
  alertSafetyModalInfo$ = this.dhp.asObservable();

  // alert for the modal close
  public dhpClose = new Subject<boolean>();
  alertSafetyModalClose$ = this.dhpClose.asObservable();

  constructor(private dialog: MatDialog) {
  }

  /* NOTIFIES THE OBSERVABLE OF THE DROPDOWN SELECTION, WHICH IN TURN
     NOTIFIES THE MODAL DIALOG TO RESPOND */
  notifyModal(title: string, message: string, modalHeight: string, modalWidth: string, modalInput?:any) {
    let _modalInfo = new DriverHistoryProfileModal();
    _modalInfo.message = message;
    _modalInfo.title = title;
    _modalInfo.modalHeight = modalHeight;
    _modalInfo.modalWidth = modalWidth;
    _modalInfo.modalInput = modalInput;
    this.openModal(_modalInfo)
  }

  openModal(modalInfo: DriverHistoryProfileModal) : void {
    const dialog = this.dialog.open(SafetyModalWindowComponent,
      {
        data: { modalInfo: modalInfo },
        minHeight: "20em",
        minWidth: modalSize[modalInfo.modalWidth],
        panelClass: "add-group-modal",
        hasBackdrop: false,
        position: {top: "2em", right: "34em"}
      })
  }

  /* NOTIFIES THE MODAL TO CLOSE */
  notifyModalClose() {
    this.dhpClose.next(true);
  }
  
}
