import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../components/auth/auth.service';

@Injectable()
export class FleetResponseService {

  constructor(private readonly http: HttpClient,
    private readonly authService: AuthService) {
  }


  delete(serviceSubDirectory: string, endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object> {
    const fleetResponseServicesUrl = this.authService.getFleetResponseServicesBaseUrl();
    return this.http.delete(fleetResponseServicesUrl + serviceSubDirectory + endpoint,
      {
        params: options ? options.params : null
      });
  }

  get<T>(serviceSubDirectory: string, endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<T> {
    const fleetResponseServicesUrl = this.authService.getFleetResponseServicesBaseUrl();
    return this.http.get<T>(fleetResponseServicesUrl + serviceSubDirectory + endpoint,
      {
        params: options ? options.params : null
      });
  }

  getFile(serviceSubDirectory: string, endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<HttpResponse<Blob>> {
    const fleetResponseServicesUrl = this.authService.getFleetResponseServicesBaseUrl();
    return this.http.get(fleetResponseServicesUrl + serviceSubDirectory + endpoint,
      {
        params: options ? options.params : null,
        responseType: 'blob',
        observe: 'response'
      });
  }

  post(serviceSubDirectory: string, endpoint: string, body: any | null, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object> {
    const fleetResponseServicesUrl = this.authService.getFleetResponseServicesBaseUrl();
    return this.http.post(fleetResponseServicesUrl + serviceSubDirectory + endpoint, body,
      {
        params: options ? options.params : null,
        headers: {
          'Content-Type': 'application/json'
        }
      });
  }

  put(serviceSubDirectory: string, endpoint: string, body: any | null, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object> {
    const fleetResponseServicesUrl = this.authService.getFleetResponseServicesBaseUrl();
    return this.http.put(fleetResponseServicesUrl + serviceSubDirectory + endpoint, body,
      {
        params: options ? options.params : null,
        headers: {
          'Content-Type': 'application/json'
        }
      });
  }
}
