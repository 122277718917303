import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { SupportsDocInfo, UserRightsInfo } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { environment } from './../../../environments/environment.int';
import { saveAs as importedSaveAs } from "file-saver";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-accident-claim-supports',
  templateUrl: './accident-claim-supports.component.html',
  styleUrls: ['./accident-claim-supports.component.css']
})
export class AccidentClaimSupportsComponent implements OnInit, OnDestroy {
  @Input() userRights: Array<UserRightsInfo>;
  _claimId: number;
  arrPhotoInfo: Array<SupportsDocInfo> = [];
  arrDocInfo: Array<SupportsDocInfo> = [];
  uploadPanelShow: boolean = false;
  slideshowPanelShow: boolean = false;
  baseUrl: string;
  _routeSub: Subscription;

  constructor(private _route: ActivatedRoute,
    private accidentClaimService: AccidentClaimService,
    @Inject('BASE_URL') baseUrl: string,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private fileDownloadService: FileDownloadService
  ) {
    this.baseUrl = baseUrl;
  }

  printPage(): void {
    window.print();
  }

  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];
      // assume line of business = 1 since we are in accident/claim
      const lineOfBusinessId: number = 1;
      this.getDocInfo(this._claimId, lineOfBusinessId, false);
    });
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
  }

  private getDocInfo(claimId: number, lineOfBusinessId: number, showLoadMask: boolean) {
    let arrObs: Array<Observable<Object>> = [
      // photos = 1, documents = 2, video = 3 (2 gets documents and videos)
      this.accidentClaimService.getDocumentInfo(claimId, 1, lineOfBusinessId),
      this.accidentClaimService.getDocumentInfo(claimId, 2, lineOfBusinessId)
    ]

    if (showLoadMask) {
      this.loadingSpinnerService.show();
    }
    forkJoin(arrObs).subscribe({
      next: (data) => {
        this.arrPhotoInfo = data[0] as Array<SupportsDocInfo>;
        this.arrDocInfo = data[1] as Array<SupportsDocInfo>;
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

  }

  downloadFile(f: SupportsDocInfo) {
    this.fileDownloadService
      .saveAzureFile(this.baseUrl + 'api/Document/GetDocumentAzureInfoById?DocumentGuid=' + f.documentGuid, f.userFileName);
  }

  private closeAllPanels() {
    this.slideshowPanelShow = false;
    this.uploadPanelShow = false;
  }

  toggleSlideshowPanel(showPanel: boolean) {
    this.closeAllPanels();
    this.slideshowPanelShow = showPanel;
  }

  toggleUploadPanel(showPanel: boolean) {
    this.closeAllPanels();
    this.uploadPanelShow = showPanel;
  }

  refreshDocInfo() {
    // assume line of business = 1 since we are in accident/claim
    const lineOfBusinessId: number = 1;
    this.getDocInfo(this._claimId, lineOfBusinessId, true);
    this.uploadPanelShow = false;
  }

  canUploadDocs(): boolean {
    let canUpload: boolean = false;

    if (this.userRights) {
      let thisRight = this.userRights.filter(r => r.userRightId == 58);
      if ((thisRight.length > 0) && (thisRight[0].permission == 'allow')) {
        canUpload = true;
      }
    }

    return canUpload;
  }

  downloadZipFile(documentType: number, originalSize: boolean = false) {
    let zipUrl = this.baseUrl + 'api/Document/DownloadDocuments?ClaimId=' + this._claimId.toString() + '&DocumentType=' + documentType.toString() + '&LineOfBusinessId=1&OriginalSize=' + (originalSize ? "true" : "false");
    console.log(zipUrl);
    this.fileDownloadService
      .saveFile(zipUrl);
  }
}
