import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { Subscription } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { DHPOrderMVRService } from './dhp-order-mvr-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';

@Component({
  selector: 'dhp-order-mvr',
  templateUrl: './dhp-order-mvr.component.html'
})
export class DHPOrderMVRComponent implements OnInit, OnDestroy {
  @Input() modalHeight: string;
  @Input() modalInput: any;
  // private variables that are only shared with subscribers that import the type
  clientSelectionSubscription: Subscription;
  lineOfBusinessSubscription: Subscription;
  clientSelectedArray: Array<string>;
  clientIdSelectedArray: Array<number>;
  lineOfBusinessId: number;
  baseUrl: string;
  reportSelection: null;
  dirty: boolean = false;

  // the overloaded constructor for the controller
  constructor(private router: Router,
    private clientSelectionService: ClientSelectionService,
    private dhpService: DriverHistoryProfileService,
    private dhpOrderMVRService: DHPOrderMVRService,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private alertService: AlertService,
    private loadingSpinnerService: LoadingSpinnerService,
    private onPremService: OnPremDriverService,
    private lineOfBusinessService: LineOfBusinessService,
    private _errorService: ErrorModalService
    ) {

    loadingSpinnerService.show();

    // the LOB subscription service
    this.lineOfBusinessSubscription = this.lineOfBusinessService.lineOfBusinessSelected$.subscribe({
      next: (data) => {
      this.lineOfBusinessId = data as number;
    },
      error: (err: HttpErrorResponse) => {
        this.handleError(err.error.toString());
      }
      });

    // get the selected client(s)
    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusinessId);
    loadingSpinnerService.hide();
  }
  ngOnDestroy(): void {
    //do nothing
  }

  ngOnInit(): void {
    //do nothing
  }

  // provides a central point for handling errors
  handleError(message: string) {
    //do nothing
  }

  submit() {
    // do your business here.
    var params = {
      ClientCode: this.modalInput.client,
      CertificateOfInsuranceDriverIdList: null,
      CertificateOfInsuranceDriverIdExcludeList: null,
      MvrDriverIdList: this.modalInput.driverId.toString(),
      MvrDriverIdExcludeLis: null,
      MvrDataValidationDue: null,
      MvrDataValidationOnly: false,
      MvrMonitorDriverIdList: null,
      MvrMonitorDriverIdExcludeList: null,
      LicenseUploadDriverIdList: null,
      LicenseUploadDriverIdExcludeList: null,
      LicenseUploadDueDate: null,
      TrainingDriverIdList: null,
      TrainingDriverIdExcludeList: null,
      TrainingDueDate: null,
      TrainingAssignmentType: null,
      TrainingCourseGroupList: null,
      TrainingPassRateList: null,
      ProcessId: null
    };
    this.onPremService.post("tasks/batch", params).subscribe({
      next: (data) => {
        this.dhpService.notifyModalClose();
        this.alertService.showSuccessAlert('Action Successfully Completed.', 'end', 'top', 5000);
      },
      error: (err: HttpErrorResponse) => {
        this._errorService.setErrorObject(err.error);
        this.dhpService.notifyModalClose();
      }
      }
    );
  }

  cancel() {
    // do your business here.
    console.log('user submitted note.');

    // notify the modal window to close
    this.dhpService.notifyModalClose();
  }

  // sleeps for a certain number of milliseconds
  sleep(milliseconds: number) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }
}
