import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-accident-claim-note-add',
  templateUrl: './accident-claim-note-add.component.html',
})
export class AccidentClaimAddNote implements OnInit {
  @Input() claimId: number;
  @Input() noteTypeId: number;
  @Output() onInsertNoteRefresh: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancelInsertNote: EventEmitter<any> = new EventEmitter<any>();
  note: string;


  constructor(private readonly _route: ActivatedRoute,
    private readonly accidentClaimService: AccidentClaimService,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly errorService: ErrorModalService
  ) { }


  printPage(): void {
    window.print();
  }


  ngOnInit(): void {

  }

  insertNote() {
    if (this.note && this.note.length > 0) {
      this.loadingSpinnerService.show();

      this.accidentClaimService.insertNote(this.claimId, this.note, this.noteTypeId).subscribe({
        next: (data) => {
          this.loadingSpinnerService.hide();
          this.onInsertNoteRefresh.emit();
        },
        error: (err: HttpErrorResponse) => {
          if (err.error) {
            this.errorService.setErrorObject(err.error);
          }

          this.loadingSpinnerService.hide();
        }
      })
    } else {
      this.errorService.setErrorObject({ message: "Please enter a note" });
    }
  }

  cancelInsertNote() {
    this.onCancelInsertNote.emit();
  }
}
