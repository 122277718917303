<div class="row header">
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <h4 class="text-muted">DHP Document Validation</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 driver">{{ documentData.driverFirstName }} {{ documentData.driverLastName }}</div>
    </div>
  </div>
  <div class="col-md-6">
    <div style="float:right">
      <button type="button" (click)="openDocumentApprove()" class="btn btn-primary">
        Approve
      </button>
      <button type="button" (click)="openDocumentReject()" class="btn btn-primary">
        Reject
      </button>
      <button type="button" (click)="exit()" class="btn btn-primary">
        Exit
      </button>
    </div>

  </div>
</div>

<div class="row driver-details">
  <div class="col-md-12">
    <div class="card card-body mt-3">

      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">MVR Request Date:</div>
        <div class="col-md-10 readOnlyFormLayoutBold">{{ documentData.mvrRequestDate | date:'M/d/yyyy' }}</div>
      </div>

      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">Client:</div>
        <div class="col-md-10 readOnlyFormLayoutBold">{{ documentData.clientCode }}</div>
      </div>

      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">Driver Name:</div>
        <div class="col-md-10 readOnlyFormLayoutBold red-highlight">
          <a [routerLink]="['/driver/history-profile/', documentData.driverId]" target="_blank">
            {{ documentData.driverFirstName }} {{ documentData.driverLastName }}
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">Driver DOB:</div>
        <div class="col-md-10 readOnlyFormLayoutBold" [ngClass]="alertIsDriverUnderAge()"><span>{{ documentData.driverDOB | date:'M/d/yyyy' }}</span></div>
      </div>

      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">Driver License:</div>
        <div class="col-md-10 readOnlyFormLayoutBold">{{ documentData.driverLicenseNumber }}</div>
      </div>

      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">State:</div>
        <div class="col-md-10 readOnlyFormLayoutBold">{{ documentData.driverLicenseState }}</div>
      </div>

      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">Driver Type:</div>
        <div class="col-md-10 readOnlyFormLayoutBold">{{ documentData.driverType }}</div>
      </div>

      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">MVR Request Id:</div>
        <div class="col-md-10 readOnlyFormLayoutBold">{{ documentData.mvrRequestId }}</div>
      </div>


    </div>

  </div>
</div>

<div class="row document-details">
  <div class="col-md-12">
    <div class="card card-body mt-3">

      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">Description:</div>
        <div class="col-md-10 readOnlyFormLayoutBold">{{ documentData.documentDescription }}</div>
      </div>

      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">File Name:</div>
        <div class="col-md-10 readOnlyFormLayoutBold">{{ documentData.documentFileName }}</div>
      </div>

      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">Document Type:</div>
        <div class="col-md-10 readOnlyFormLayoutBold">{{ documentData.documentType }}
          <button type="button" (click)="openDocumentEdit()" class="btn btn-sm edit">
            Edit
          </button>
          <button type="button" (click)="openDocumentRemove()" class="btn btn-sm remove">
            Remove
          </button>
      </div>
      </div>

      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">Uploaded:</div>
        <div class="col-md-10 readOnlyFormLayoutBold" [ngClass]="alertIsDocumentPastDue()"><span>{{ documentData.documentUploadedDate | date:'M/d/yyyy' }}</span></div>
      </div>

      <div class="row document-attachment">
        <app-dq-file-panel *ngIf="!!documentData.documentId"
          [documentId]="documentData.documentId"
          [fileName]="documentData.documentFileName"
          [uploadDate]="documentData.documentUploadedDate">
        </app-dq-file-panel>
      </div>

    </div>
  </div>
</div>
