import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormControl, Validators } from '@angular/forms';
//thirdparty libraries
import { MatSnackBar } from '@angular/material/snack-bar';
//project imports
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { DHPMyFollowupService } from './dhp-my-followup-service.component';
import { DriverFollowUpDate } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { MatDatepicker } from '@angular/material/datepicker';



@Component({
  selector: 'dhp-my-followup',
  templateUrl: './dhp-my-followup.component.html',
  styleUrls: ['./dhp-my-followup.component.scss'],
})
export class DHPMyFollowUpComponent implements OnInit, OnDestroy {
  @ViewChild('datePicker') datePicker: MatDatepicker<Date>;
  @Input() modalHeight: string;
  @Input() modalInput: any;
  followUpDateObject: DriverFollowUpDate;
  followUpDateControl = new UntypedFormControl('', [Validators.required]);
  private dtToday: Date = new Date((new Date()).toDateString());
  followUpDateInvalid: boolean = true;
  minDate: Date = new Date;

  allControls: Array<UntypedFormControl> = [
    this.followUpDateControl
  ];

  // the overloaded constructor for the controller
  constructor(
    private dhpService: DriverHistoryProfileService,
    private dhpFollowupService: DHPMyFollowupService,
    private datePipe: DatePipe,
    private cdRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService) {
    // do not allow date picker start before tomorrows date
    this.minDate = new Date(this.dtToday.getFullYear(), this.dtToday.getMonth(), this.dtToday.getDate() + 1);
  }

  // angular on intialization event
  ngOnInit() {
    this.followUpDateObject = new DriverFollowUpDate(this.modalInput.driverId, (this.modalInput.followUpDate ? this.datePipe.transform(this.modalInput.followUpDate, 'MM/dd/yyyy') : null));

    // listen for changes in date controls
    this.followUpDateControl.valueChanges.subscribe(val => {
      this.followUpDateInvalid = this.followUpDateControl.invalid;
      this.cdRef.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.datePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.datePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
          this.datePicker.select(event.value);
          this.datePicker.close();
        })
      }, 0)
    })
  }
  // provides a central point for handling errors
  handleError(message: string) {

  }

  // prints the page
  printPage(): void {
    window.print();
  }

  private validateForm(): boolean {
    let isOK: boolean = true;

    if (this.allControls.filter(c => (c.invalid)).length > 0) {
      this.errorService.setErrorObject({ message: 'Missing or Invalid Fields.' });
      isOK = false;
    }

    return isOK;
  }

  // sets the follow-up date
  submitDate() {
    if (this.validateForm()) {
      this.loadingSpinnerService.show();
      this.dhpFollowupService.setFollowUpDate(this.modalInput.driverId, this.followUpDateObject).subscribe({
        next: (data) => {
          // signal that driver profile data needs to be refreshed
          this.dhpService.setRefreshDriverProfileFollowUpDateData(this.modalInput.driverId);
          // notify the modal window to close
          this.dhpService.notifyModalClose();

          this.snackBar.open("Follow Up Date Set.", "Driver History Profile", {
            horizontalPosition: "end",
            verticalPosition: "top",
            duration: 5000,
            panelClass: "success-snackbar",
          });
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });

    }
  }

  cancel() {
    // notify the modal window to close
    this.dhpService.notifyModalClose();
  }

  // sleeps for a certain number of milliseconds
  sleep(milliseconds: number) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }

  // the default destructor
  ngOnDestroy() {
  }
}
