



<div class="quickSearchContainerDataGrid">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" >
  <div class="col-md-12">
    <H3>
      Driver Time Clock Report
    </H3>
  </div>
</div>
<div>
  <div *ngIf="gridRequest?.EndDate">
    
    <span>
      Start Date: {{gridRequest?.EndDate | date :  "MM/dd/y"}} <!-- for this report we use end date instead of start date-->
    </span>
  </div>

  <div *ngIf="gridRequest?.ParameterValues?.length > 0 ">

    <!--Filters:-->
    <ng-container *ngFor="let filter of gridRequest?.ParameterValues">
      <div *ngIf="filter.ParameterName=='PointName' && filter.Value">Level: {{filter.Value}}</div>
    </ng-container>
  </div>
</div>
<div >
  <mat-sidenav-container class="navbar-dark dashboardFilterPanelActionBar div_top_hypers" [containerInside]="false">
    <mat-sidenav mode="side">Sidenav content</mat-sidenav>
    <ul class="nav navbar-nav nav-flex-icons ml-auto inlineStyle">
      <li class="nav-item inlineStyle">
        <a (click)="onPrint()">
          <span class='fa fa-print'></span> Print
        </a>
      </li>
      <li class="nav-item inlineStyle">
        <a (click)="onExcel()">
          <span class='fa fa-file-excel-o'></span> Export to Excel
        </a>
      </li>
      <li class="nav-item inlineStyle">
        <a (click)="onEmail()">
          <span class='fa fa-envelope'></span>
          Export to Email
        </a>
      </li>
    </ul>
  </mat-sidenav-container>
  <app-data-grid-export-email [lineOfBusiness]="lineOfBusinessId"></app-data-grid-export-email>

  <div class="row">
    <div class="col-md-12" style="text-align:right">
      <!--<button #btnOptOutMultiple id="btnOptOutMultiple" class="btn btn-primary"  style="white-space: nowrap!important" (click)="openSubmitSelectedDrivers()">
        Submit All Selected Drivers
      </button>-->
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-data-table 
        [columns]="configObject.fields"
        [data]="configObject.data"
        [showPagination]="true"
        noRecordsLabel="No results"
      ></app-data-table>
      <div id="reportFooter">
        <div class="left"> Fleet Response Confidential</div>
        <div class="right"> Report run {{today | date :'short' }}</div>
      </div>
    </div>
    <!--<div *ngIf="!rowsRaw || rowsRaw?.length == 0">
      No results
    </div>-->
  </div>
</div>
