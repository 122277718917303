import { ReturnInfo, MVRReport } from './../../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DHPMVRReportService } from '../dhp-mvr-report-service.component';

@Component({
  selector: 'dhp-mvr-report-return-info',
  templateUrl: './dhp-mvr-report-return-info.component.html',
  styleUrls: ['../../dhp-mvr-report/dhp-mvr-report.component.css']
})
export class DhpMvrReportReturnInfoComponent implements OnInit {
  mvrReport: MVRReport;
  public returnInfoTableConfig: any;

  constructor(private readonly datePipe: DatePipe,
    private readonly dhpMVRReportService: DHPMVRReportService) { }
    gtOptions: any = {};

  ngOnInit(): void {
    this.dhpMVRReportService.sharedMvrReport.subscribe(mvrData => { 
      if(mvrData != null ) {
        this.mvrReport = mvrData;
        const returnsArray = (this.mvrReport.returnInfo != null) ?  this.mvrReport.returnInfo : [];
        this.setReturnInfoTableConfig(returnsArray);
      }
    });
  }

  private setReturnInfoTableConfig(returnInfoRecords: Array<ReturnInfo>) {
    const columns = [
      { name: "Valid", prop: "valid", title: "Valid", visible: true, dataType: null, columnClass: "width15"  },
      { name: "Date", prop: "date", title: "Date", visible: true, dataType: "date", columnClass: ""  },      
      { name: "Time", prop: "time", title: "Time", visible: true, dataType: null, columnClass: ""  },
      { name: "Clear", prop: "clear", title: "Clear", visible: true, dataType: null, columnClass: ""  },      
      { name: "Original Type", prop: "originalType", title: "Original Type", visible: true, dataType: null, columnClass: ""  },
      { name: "Multi Hit", prop: "multiHit", title: "Multi Hit", visible: true, dataType: null, columnClass: ""  },
      { name: "No Hit", prop: "noHit", title: "No Hit", visible: true, dataType: null, columnClass: ""  },
      { name: "Response", prop: "response", title: "Response", visible: true, dataType: null, columnClass: ""  },            
      { name: "Company Score", prop: "company_Score", title: "Company Score", visible: true, dataType: null, columnClass: ""  },
      { name: "Company Class", prop: "company_Class", title: "Company Class", visible: true, dataType: null, columnClass: ""  }
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    const fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s.dataType,
        hidden: !s.visible,
        columnClass: (row, col) => {
          return s.columnClass;
        },
        render: row => {
          if (s.dataType === "date") {
            return this.datePipe.transform(row[s.prop], 'MM/dd/yyyy');
          } else {
            return row[s.prop];
          }
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.returnInfoTableConfig = {
      settings: settings,
      fields: fields,
      data: returnInfoRecords,
      noRecordsLabel: 'No Return Info Records'
    }
    this.gtOptions = (returnInfoRecords.length > 1 ? { numberOfRows: returnInfoRecords.length } : {});
  }
}
