import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { minimumCheckboxesSelected } from '../../../components/classes-and-interfaces/classes-and-interfaces.component';

@Component({
  selector: 'app-monitoring-status',
  templateUrl: './monitoring-status.component.html',
  styleUrls: ['./monitoring-status.component.css']
})
export class MonitoringStatusComponent implements OnInit {

  @Input() parentForm: UntypedFormGroup;
  public monitoringStatusForm = new UntypedFormGroup({});
  constructor() { }

  ngOnInit(): void {

    this.monitoringStatusForm.addControl('Monitored', new UntypedFormControl(true));
    this.monitoringStatusForm.addControl('NonMonitored', new UntypedFormControl(true));
    this.monitoringStatusForm.setValidators([minimumCheckboxesSelected(1)]);

    this.parentForm.addControl('monitoringStatus', this.monitoringStatusForm);
  }

}
