<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <div class="row">
        <div class="col-md-4 readOnlyFormLayoutLabel">
          License #:
        </div>
        <div *ngIf="canViewDLDOB" class="col-md-8 readOnlyFormLayoutBold">
          {{((driverProfile?.driverLicenseNumber&&(driverProfile?.driverLicenseNumber.length > 0)) ? driverProfile?.driverLicenseNumber +(driverProfile?.driverLicenseState != '' ? ' (' + driverProfile?.driverLicenseState + ')': '') : 'Not Available')}}
        </div>
        <div *ngIf="!canViewDLDOB" class="col-sm-3 col-md-8 readOnlyFormLayoutBold">
          Not Available
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 readOnlyFormLayoutLabel">
          Date of Birth:
        </div>
        <div *ngIf="canViewDLDOB&&(driverProfile?.dateOfBirth != '')" class="col-md-8 readOnlyFormLayoutBold">
          {{driverProfile?.dateOfBirth |  date:'MM/dd/yyyy'}}
        </div>
        <div *ngIf="canViewDLDOB&&(driverProfile?.dateOfBirth == '')" class="col-md-8 readOnlyFormLayoutBold">
          Not Available
        </div>
        <div *ngIf="!canViewDLDOB" class="col-md-8 readOnlyFormLayoutBold">
          Not Available
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 readOnlyFormLayoutLabel">
          Email:
        </div>
        <div class="col-md-8 readOnlyFormLayoutBold">
          <a *ngIf="driverProfile?.emailAddress != ''" href="{{'mailTo:'+driverProfile?.emailAddress}}">
            {{driverProfile?.emailAddress}}
          </a>
          {{driverProfile?.emailAddress == '' ? 'Not Available':''}}
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 readOnlyFormLayoutLabel">
          Added:
        </div>
        <div class="col-md-8 readOnlyFormLayoutBold">
          {{driverProfile?.driverCreateDate |  date:'MM/dd/yyyy'}} by {{driverProfile?.driverCreateUser}}
        </div>
      </div>
      <div class="row" *ngIf="driverProfile&&driverProfile.driverReactivateDate">
        <div class="col-md-4 readOnlyFormLayoutLabel">
          Re-Activated:
        </div>
        <div class="col-md-8 readOnlyFormLayoutBold">
          {{driverProfile?.driverReactivateDate |  date:'M/d/yyyy h:mm:ss a'}}
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 readOnlyFormLayoutLabel">
          Language:
        </div>
        <div class="col-md-8 readOnlyFormLayoutBold">
          {{driverProfile?.language != '' ? driverProfile?.language : 'Not Available'}}
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 readOnlyFormLayoutLabel">
          Country:
        </div>
        <div class="col-md-8 readOnlyFormLayoutBold">
          {{driverProfile?.country&&(driverProfile?.country != '') ? driverProfile?.country : 'Not Available'}}
        </div>
      </div>
      <div *ngFor="let e of extendedInfo"class="row">
        <div class="col-md-4 readOnlyFormLayoutLabel">
          {{e.label}}:
        </div>
        <div class="col-md-8 readOnlyFormLayoutBold">
          {{e.value}}
        </div>
      </div>
    </div>
  </div>
</div>
