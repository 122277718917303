//angular libraries
import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
//thirdparty libraries
import { Observable } from 'rxjs';
import { formatDate } from 'ngx-bootstrap/chronos';

//project imports
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { DataGridEmailService, COIValidationGridRequest } from '../../components/data-grid-export-email/data-grid-export-email.service';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { ReportRequest } from '../../components/data-grid/data-grid.service';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-coi-validation-grid',
  templateUrl: './coi-validation-grid.component.html',
  styleUrls: ['./coi-validation-grid.component.scss']
})

export class COIValidationGridComponent implements OnInit {
  public configObject: any;
  clientSelected: string;
  gtOptions: any = {};
  columns = [];
  rowsRaw: Array<any>;
  rows: Array<any>;
  today: any;
  baseUrl: string;
  lineOfBusinessId: number;
  SortDisplayField: string;
  SortField: string;
  SortDir: string;

  gridId: string;

  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: {}
  };

  constructor(@Inject('BASE_URL') baseUrl: string,
    readonly router: Router,
    private readonly onPremService: OnPremDriverService,
    private readonly clientSelectionService: ClientSelectionService,
    private readonly datePipe: DatePipe,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private lineOfBusinessService: LineOfBusinessService,
    private readonly errorService: ErrorModalService,
    private readonly fileDownloadService: FileDownloadService,
    private readonly route: ActivatedRoute,
    private readonly _http: HttpClient,
    private readonly emailService: DataGridEmailService) {
    this.baseUrl = baseUrl;
    this.today = Date.now();
  }

  ngOnInit() {
    // set line of business to safety if not already initialized to Safety
    this.lineOfBusinessId = 2;
    const lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob !== 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    this.route.paramMap.subscribe(params => {
      this.gridId = params.get("id");

      this.clientSelected = this.clientSelectionService.getSavedClientShortNames(2)[0];
      if (this.clientSelected && (this.clientSelected.length > 0)) {
        this.loadingSpinnerService.show();

        if (this.gridId && this.gridId !== '') {
          this.getGridData(this.gridId).subscribe({
            next: (data) => {

              const rows = (data as any).rows.map(x => {
                return {
                  action: (x.Action || "Review"),
                  documentType: (x.DocumentType || "Auto Coverage"),
                  driverProcessAssignedId: x.DriverProcessAssignedID,
                  emailAddress: x.EmailAddress,
                  assignedDate: x.AssignedDate,
                  dueDate: x.DueDate,
                  driverCompletedDate: x.DriverCompletedDate,

                  driverId: x.DriverId,
                  employeeId: x.EmployeeId,
                  firstName: x.FirstName,
                  lastName: x.LastName
                }
              })
              this.setupGrid(rows);
              this.loadingSpinnerService.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.errorService.setErrorObject(err.error);
              this.loadingSpinnerService.hide();
            }
          }
          );
        }
        else {
          this.getData(this.clientSelected).subscribe({
            next: (data) => {
              this.setupGrid(data);
              this.loadingSpinnerService.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.errorService.setErrorObject(err.error);
              this.loadingSpinnerService.hide();
            }
          }
          );
        }
      } else {
        this.errorService.setErrorObject({ message: 'Client Not Specified' });
      }
    });
    this.loadingSpinnerService.hide();
  }

  setupGrid(data) {

    this.rows = data;
    this.rowsRaw = data;

    this.columns = [
      { name: "Action", prop: "action", title: "Action", visible: true, sort: '' },
      { name: "Employee Id", prop: "employeeId", title: "Employee Id", visible: true, sort: 'enable' },
      { name: "First Name", prop: "firstName", title: "First Name", visible: true, sort: 'enable' },
      { name: "Last Name", prop: "lastName", title: "Last Name", visible: true, sort: 'enable' },
      { name: "Email", prop: "emailAddress", title: "Email", visible: true, sort: 'enable' },
      { name: "Review Type", prop: "documentType", title: "Review Type", visible: true, sort: 'enable' },
      { name: "Date Requested", prop: "assignedDate", title: "Date Requested", dataType: "date", visible: true, sort: 'enable' },
      { name: "Date Uploaded", prop: "driverCompletedDate", title: "Date Uploaded", dataType: "date", visible: true, sort: 'enable' }
    ];


    const fields = [];

    this.columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s?.dataType,
        sort: s?.sort === 'enable',
        hidden: !s.visible,
        columnClass: s.prop.toLowerCase() === "employeeid" || s.prop.toLowerCase() === "action" ? 'clickable' : '',
        click: row => {
          if (s.prop.toLowerCase() === "action") {
            if (this.gridId) {
              this.router.navigate([`safety/coivalidationreview/${row["driverId"]}/${row["driverProcessAssignedId"]}/${this.gridId}`]);
            } else {
              this.router.navigate([`safety/coivalidationreview/${row["driverId"]}/${row["driverProcessAssignedId"]}`]);
            }
          } else {
            this.router.navigate(["/driver/history-profile/" + row["driverId"]]);
          }
        },
        render: row => {
          if (s.dataType === "currency") {
            return row[s.prop] >= 0 ? `$${row[s.prop]}` : `($${(row[s.prop] * -1)})`;   
          }
          else if (s.dataType === "date" && row[s.prop]) {
            try {
              return formatDate(new Date(row[s.prop]), "MM/DD/YYYY");
            }
            catch (err) {
              return row[s.prop];
            }
          }
          else {
            return row[s.prop];
          }
        }
      });
    });

    const settings = this.columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: s.sort
      };
    }).filter(x => x.objectKey.toLowerCase() !== "driverid");
    this.configObject = {
      settings: settings,
      fields: fields,
      data: data
    };
  }

  getData(client: string) {
    this.resetSort();

    if (client && client !== "") {
      return this.onPremService.get(`coi/${client}/review`);
    }
  }


  getGridData(id) {

    try {
      const request = JSON.parse(sessionStorage.getItem('grid_' + id)) as ReportRequest;

      return this._http.post(`${this.baseUrl}api/Datagrid/GridData`,
        JSON.stringify(request),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )

    }
    catch (err) {
      console.log(err);
      return new Observable<null>();
    }
  }

  onPrint() {
    let sortParams = "";

    if (this.SortDisplayField && this.SortDisplayField.length > 0) {
      sortParams = `&sort=${this.SortField}&sortDir=${this.SortDir}`;
    }

    this.fileDownloadService.openTab(`${this.baseUrl}api/Safety/PrintCOIGrid?client=${this.clientSelected}${sortParams}`);
  }


  onExcel() {
    let sortParams = "";

    if (this.SortDisplayField && this.SortDisplayField.length > 0) {
      sortParams = `&sort=${this.SortDisplayField}&sortDir=${this.SortDir}`;
    }

    this.fileDownloadService
      .saveFile(`${this.baseUrl}api/Safety/COIExcel?client=${this.clientSelected}${sortParams}`);
  }
  
  onEmail() {
    const request = new COIValidationGridRequest();
    request.SortDisplayField = this.SortDisplayField;
    request.SortDir = this.SortDir;
    request.SortField = this.SortField;

    request.SelectedClient = this.clientSelected;
    this.emailService.OpenEmailPopup(request);
  }

  resetSort() {
    this.SortDir = "";
    this.SortField = "";
    this.SortDisplayField = "";
  }

  trigger($event: any) {
    if ($event.name === 'gt-sorting-applied') {
      if ($event.value && $event.value[0] !== '$$gtInitialRowIndex') {
        if (($event.value[0] as string).startsWith('-')) {
          this.SortField = ($event.value[0] as string).slice(1);
          this.SortDir = "DESC"
        }
        else {
          this.SortField = $event.value[0]
          this.SortDir = "ASC"
        }
        const fld = this.configObject.fields.find(x => x.objectKey === this.SortField)
        if (fld) {
          this.SortDisplayField = fld.name;
        }
      }
      else {
        this.resetSort();
      }
    } 
  }

  sortChange($event: any): void {
    if (!$event?.active) {
      this.resetSort();
    } else {
      this.SortField = $event?.active || null;
      this.SortDir = $event?.direction?.toUpperCase() || null;
      const fld = this.configObject.fields.find(x => x.objectKey === this.SortField);
      if (fld) {
        this.SortDisplayField = fld.name;
      }
    }
  }
}

