import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { AccidentClaimTotalLossBidHistory, AccidentClaimTotalLossDetail } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-accident-claim-total-loss',
  templateUrl: './accident-claim-total-loss.component.html'
})
export class AccidentClaimTotalLossComponent implements OnInit, OnDestroy {
  _claimId: number;
  bidHistories: Array<AccidentClaimTotalLossBidHistory>;
  detail: AccidentClaimTotalLossDetail;
  _routeSub: Subscription;

  constructor(private _route: ActivatedRoute,
    private accidentClaimService: AccidentClaimService
  ) { }


  printPage(): void {
    window.print();
  }


  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];
      this.accidentClaimService.getTotalLossDetail(this._claimId).subscribe({
        next: (data) => {
          this.detail = data as AccidentClaimTotalLossDetail;

          this.accidentClaimService.getTotalLossBidHistories(this.detail.salvageId).subscribe({
            next: data2 => {
              this.bidHistories = data2 as Array<AccidentClaimTotalLossBidHistory>;
            },
            error: (err: HttpErrorResponse) => {
              console.log(err.error.toString());
            }
          });
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });
    });
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
  }
}
