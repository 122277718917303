//Angular
import { Component, Inject, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
//Third Party
//APP
import { SupportsDocInfo } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: "app-accident-claim-supports-slideshow",
  templateUrl: "./accident-claim-supports-slideshow.component.html",
  styleUrls: ["./accident-claim-supports-slideshow.component.scss"],
  animations: [
    trigger("fade", [
      state("visible", style({ opacity: 1 })),
      state("hidden", style({ opacity: 0 })),
      transition("* <=> *", animate("0.5s ease-in-out")),
    ]),
  ],
})
export class AccidentClaimSupportsSlideshowComponent implements OnInit {
  @Input() arrPhotoInfo: Array<SupportsDocInfo>;
  @Input() ClaimId: number = 0;
  @Output() onSlideshowExit: EventEmitter<any> = new EventEmitter<any>();
  baseUrl: string;
  isPlaying: boolean = false;
  playInterval: number = 2000;
  activeSlideObject: any = 0;
  activeSlideIndex: number = 0;
  currentRotationDegree: number = 0;
  imageClass: string = "";
  timeoutId?: number;
  intervalId?: NodeJS.Timeout;

  constructor(
    @Inject("BASE_URL") baseUrl: string,
    private loadingSpinnerService: LoadingSpinnerService,
    private readonly fileDownloadService: FileDownloadService
  ) {
    this.baseUrl = baseUrl;
  }

  ngOnInit() {
    // show loadmask until first image loads
    this.loadingSpinnerService.show();
  }

  playSlideshow(): void {
    this.isPlaying = true;
    this.intervalId = setInterval(() => {
      this.goToNext();
    }, this.playInterval);
  }

  pauseSlideShow(): void {
    this.isPlaying = false;
    window.clearInterval(this.intervalId);
  }

  downloadPhoto() {
    let photoUrl =
      this.baseUrl +
      "api/Document/GetDocumentById?DocumentGuid=" +
      this.arrPhotoInfo[this.activeSlideIndex].documentGuid +
      "&ClaimId=" +
      this.ClaimId;
    this.fileDownloadService.saveFile(photoUrl);
  }

  downloadPhotoReducedSize() {
    let photoUrl =
      this.baseUrl +
      "api/Document/GetThumbnailImageById?DocumentGuid=" +
      this.arrPhotoInfo[this.activeSlideIndex].documentGuid +
      "&ClaimId=" +
      this.ClaimId;
    this.fileDownloadService.saveFile(
      photoUrl,
      "Claim_" + this.ClaimId + "_Photo1_thumbnail"
    );
  }

  rotateLeft() {
    if (this.currentRotationDegree === 0) this.currentRotationDegree = 360;
    let rotateDegree = (this.currentRotationDegree - 45) * 1;
    this.imageClass = "rotate-" + rotateDegree.toString();
    this.currentRotationDegree = rotateDegree;
  }

  rotateRight() {
    if (this.currentRotationDegree === 360) this.currentRotationDegree = 0;
    let rotateDegree = (this.currentRotationDegree + 45) * 1;
    this.imageClass = "rotate-" + rotateDegree.toString();
    this.currentRotationDegree = rotateDegree;
  }

  onImageLoad() {
    // turn off load mask when first photo image loads
    if (this.activeSlideIndex === 0) {
      this.loadingSpinnerService.hide();
    }
  }

  closeSlideshowPanel() {
    this.onSlideshowExit.emit();
  }
  //carousel
  resetTimer(): void {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
    this.timeoutId = window.setTimeout(() => this.goToNext(), 3000);
  }

  stopTimer(): void {
    window.clearTimeout(this.timeoutId);
  }

  goToPrevious(): void {
    if (this.isPlaying) this.resetTimer();
    const isFirstSlide = this.activeSlideIndex === 0;
    const newIndex = isFirstSlide
      ? this.arrPhotoInfo.length - 1
      : this.activeSlideIndex - 1;
    this.activeSlideIndex = newIndex;
  }

  goToNext(): void {
    if (this.isPlaying) this.resetTimer();
    const isLastSlide = this.activeSlideIndex === this.arrPhotoInfo.length - 1;
    const newIndex = isLastSlide ? 0 : this.activeSlideIndex + 1;
    this.activeSlideIndex = newIndex;
  }

  goToSlide(slideIndex: number): void {
    if (this.isPlaying) this.resetTimer();
    this.activeSlideIndex = slideIndex;
  }

  isCurrentImage(index: number): boolean {
    return this.activeSlideIndex === index;
  }
}

export interface IActiveSlideObject {
  direction: string;
  relatedTarget: number;
}
