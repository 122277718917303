import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dashboard-action-bar',
  templateUrl: './dashboard-action-bar.component.html',
  styleUrls: ['./dashboard-action-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DashboardActionBarComponent {
  @Input() dashboardNoResults: boolean;
  @Output() onDashboardActionSelect: EventEmitter<any> = new EventEmitter<any>();

  actionSelect(action: string) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.onDashboardActionSelect.emit(action);
  }


}
