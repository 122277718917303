<div class="quickSearchContainerDataGrid">
  <quick-search-menu></quick-search-menu>
</div>
<nav class="navbar navbar-expand-lg navbar-dark dashboardFilterPanelActionBar" [containerInside]="false">
  <ul class="nav navbar-nav nav-flex-icons ml-auto">
    <li class="nav-item">
      <a (click)="onPrint()">
        <span class='fa fa-print'></span> Print
      </a>
    </li>
    <li class="nav-item">
      <a (click)="onExcel()">
        <span class='fa fa-file-excel-o'></span> Export to Excel
      </a>
    </li>
    <li class="nav-item">
      <a (click)="onEmail()">
        <span class='fa fa-envelope'></span>
        Export to Email
      </a>
    </li>
  </ul>
</nav>
<div class="row" >
  <div class="col-md-12">
    <h4>Orders & Assignments Report</h4>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    Batch Date/Time: {{batchDateTime}}
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div *ngIf="summaryTableConfig">
      <app-data-table 
        [columns]="summaryTableConfig.fields"
        [data]="summaryTableConfig.data"
      ></app-data-table>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div *ngIf="detailTableConfig">
      <app-data-table 
        [columns]="detailTableConfig.fields"
        [data]="detailTableConfig.data"
        [showPagination]="true"
        [showPageSize]="true"
      ></app-data-table>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div id="reportFooter">
      <div class="left"> Fleet Response Confidential</div>
      <div class="right"> Report run {{dtToday | date :'short' }}</div>
    </div>
  </div>
</div>
