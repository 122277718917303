import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { AccidentClaimSubroInboundInfo, UserRightsInfo } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-accident-claim-subro-inbound',
  templateUrl: './accident-claim-subro-inbound.component.html'
  //styleUrls: ['./accident-claim-subro-outbound.component.css']
})
export class AccidentClaimSubroInboundComponent implements OnInit, OnDestroy {
  @Input() userRights: Array<UserRightsInfo>;
  _claimId: number;
  public subroInfo: AccidentClaimSubroInboundInfo;
  _routeSub: Subscription;

  constructor(private _route: ActivatedRoute,
    private accidentClaimService: AccidentClaimService
  ) { }

  printPage(): void {
    window.print();
  }

  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];
      this.accidentClaimService.getSubroInboundInfo(this._claimId).subscribe({
        next: (data) => {
          this.subroInfo = data as AccidentClaimSubroInboundInfo;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });
    });
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
  }
}
