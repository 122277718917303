import { formatDate } from "ngx-bootstrap/chronos";
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { AdvancedSearchRequest } from "./search-advanced-search-service.service";
import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { DriverInactive, DriverProfile, UserRightsInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { Subscription } from "rxjs";
import { ReactivateDriverInfoModalService } from '../reactivate-driver-info-modal/reactivate-driver-info-modal-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { SafetyModalWindowService } from '../safety-modal-window/safety-modal-window-service.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { UserRightsService } from "../../components/user-rights-service/user-rights-service.component";
import { ClientSelectionService, ClientSelectionsObject } from "../../components/client-selection-service/client-selection-service.component";
import { ReactivateDriverInfoModalComponent } from "../reactivate-driver-info-modal/reactivate-driver-info-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { LoadingSpinnerService } from "../../services/loading-spinner-service/loading-spinner.service";

@Component({
  templateUrl: './safety-advanced-search-results.component.html',
  styleUrls: ['./safety-advanced-search-results.component.css']
})
export class SafetyAdvancedSearchResultsComponent implements OnInit, OnDestroy {
  baseUrl: string;
  lineOfBusinessId: number = 2;
  public page: number = 1;
  public itemsPerPage: number = 50;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;
  public searchText: string;
  lineOfBusinessSubscription: Subscription;
  public configObject: any;
  public driverInfo: DriverInactive = new DriverInactive();
  public sortPageTime: number = 0;
  userRights: Array<UserRightsInfo>;
  reactivatePermission: UserRightsInfo;
  arrDriverInactive: Array<DriverInactive> = [];
  columns = [];
  rowsRaw: Array<any>;
  rows: Array<any>;
  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: {}
  };
  today: any;

  constructor(
    private loadingSpinnerService: LoadingSpinnerService,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private lineOfBusinessService: LineOfBusinessService,
    private route: ActivatedRoute,
    private searchRequest: AdvancedSearchRequest,
    private reactivateService: ReactivateDriverInfoModalService,
    private readonly clientService: ClientSelectionService,
    private onPremService: OnPremDriverService,
    private router: Router,
    private driverHistoryProfileService: DriverHistoryProfileService,
    private errorService: ErrorModalService,
    private safetyModalWindowService: SafetyModalWindowService,
    private userRightsService: UserRightsService,
    private dialog: MatDialog) {
    this.http = http;
    this.baseUrl = baseUrl;

    this.today = Date.now();
    this.lineOfBusinessSubscription = lineOfBusinessService.lineOfBusinessSelected$.subscribe(data => {
      this.lineOfBusinessId = data as number;
    });
  }

  ngOnDestroy() {
    this.lineOfBusinessSubscription.unsubscribe();
  }


  ngOnInit(): void {
    this.loadingSpinnerService.hide();
    // set line of business to safety if not already initialized to Safety
    this.lineOfBusinessId = 2;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    this.userRights = this.userRightsService.getUserRightsValue();
    this.reactivatePermission = this.userRights.find(x => x.userRightId == 36);

    this.route.data
      .subscribe((data) => {
        this.columns = data.data.columns;
        this.rowsRaw = data.data.rows;
        var fields = new Array<any>();

        this.columns.forEach(s => {
          if (s.propertyName == "employeeID") {
            fields.push({
              name: s.displayName,
              objectKey: s.propertyName,
              columnClass: 'clickable',
              click: row => {
                this.router.navigate(["/driver/history-profile/" + row["driverId"]]);
              }
            });
          }
          else {
            if (s.propertyName == "isActive") s.displayName = "Active";
            if (s.propertyName == "isDriverOnLeaveOfAbsence") s.displayName = "Leave Of Absence";
            fields.push({
              name: s.displayName,
              objectKey: s.propertyName,
              render: row => {
                if (s.propertyName === "isActive" && row[s.propertyName] == false) {
                  // create Re-Activate button
                  if (this.reactivatePermission && this.reactivatePermission.permission.toLowerCase() === "allow") {
                    return '<button class="btn btn-xs btn-default my-0 p-1">Re-Activate</button>';
                  }
                  else {
                    return 'No';
                  }
                }

                if (s.propertyName === "isActive" && row[s.propertyName] == true) {
                  row[s.propertyName] = "Yes";
                }

                if (s.propertyName === "isDriverOnLeaveOfAbsence" && row[s.propertyName] == true) {
                  // create Update LOA button
                  return '<button class="btn btn-xs btn-default my-0 p-1">Update</button>';
                }

                if (s.propertyName === "isDriverOnLeaveOfAbsence" && row[s.propertyName] == false) {
                  row[s.propertyName] = "No";
                }

                if (s.dataType === "currency") {
                  if (row[s.propertyName] >= 0)
                    return "$" + row[s.propertyName];
                  else
                    return "($" + (row[s.propertyName] * -1) + ")";
                }
                else if (s.dbType === "date" && row[s.propertyName]) {
                  try {
                    return formatDate(new Date(row[s.propertyName]), "MM/DD/YYYY");
                  }
                  catch (err) {
                    return row[s.propertyName];
                  }
                }
                else
                  return row[s.propertyName]
              },
              click: row => {
                if (s.propertyName === "isActive" && row[s.propertyName] == false && this.reactivatePermission && this.reactivatePermission.permission.toLowerCase() === "allow") {
                  // open reactivate modal
                  this.openReactivateModal(row['employeeID'], row['last'], row['clientName']);
                }
                if (s.propertyName == "isDriverOnLeaveOfAbsence" && row[s.propertyName] == true) {
                  this.openLeaveOfAbsenceDialog(row);
                }
              }
            });
          }
        });

        var settings = this.columns.map(function (s) {
          return {
            objectKey: s.propertyName,
            sort: 'enable',
            visible: (s.propertyName != "dotDriver" && s.propertyName != "driverId"),
          };
        });
        this.configObject = {
          settings: settings,
          fields: fields,
          data: this.rowsRaw
        };

        console.log(this.configObject);
      });


    this.onChangeTable(this.config);

  }


  public onChangeTable(config: any, page: any = { page: 1 }): any {
    var t0 = performance.now();


    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }
    if (config.sorting) {
      Object.assign(this.config.sorting, config.sorting);
    }
    this.itemsPerPage = Number(this.itemsPerPage);
    let sortedData = this.changeSort(this.rowsRaw, this.config);
    this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData;
    this.length = this.rowsRaw.length;

    var t1 = performance.now();
    this.sortPageTime = t1 - t0;
    console.log("Call to onChangeTable took " + (t1 - t0) + " milliseconds.")

  }

  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    let columns = this.config.sorting.columns || [];
    let columnName: string = '';
    let sort: string = '';

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '') {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      if (previous[columnName] > current[columnName]) {
        return sort === 'desc' ? -1 : 1;
      } else if (previous[columnName] < current[columnName]) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  public changePage(page: any, data: Array<any> = this.rowsRaw): Array<any> {
    console.log("Page: " + page.page);
    let start = (page.page - 1) * this.itemsPerPage;
    let end = this.itemsPerPage > -1 ? (start + this.itemsPerPage) : data.length;
    console.log(this.itemsPerPage);
    console.log(start + "-" + end);
    return data.slice(start, end);
  }

  trigger($event) {
    switch ($event.name) {
      case 'gt-sorting-applied':
        console.log($event.value);
        if ($event.value && $event.value[0] != '$$gtInitialRowIndex') {

          if (($event.value[0] as string).startsWith('-')) {
            this.searchRequest.SortField = ($event.value[0] as string).slice(1);
            this.searchRequest.SortDir = "DESC"
          }
          else {
            this.searchRequest.SortField = $event.value[0]
            this.searchRequest.SortDir = "ASC"
          }
          let fld = this.configObject.fields.find(x => x.objectKey == this.searchRequest.SortField)
          if (fld)
            this.searchRequest.SortDisplayField = fld.name;

        }
        else {
          this.searchRequest.SortDir = "";
          this.searchRequest.SortDisplayField = "";
          this.searchRequest.SortField = "";
        }
        break;
    }
  }

  sortChange($event): void {
    if (!$event?.active) {
      this.searchRequest.SortDir = "";
      this.searchRequest.SortDisplayField = "";
      this.searchRequest.SortField = "";
    } else {
      this.searchRequest.SortField = $event?.active || null;
      this.searchRequest.SortDir = $event?.direction?.toUpperCase() || null;
      const fld = this.configObject.fields.find(x => x.objectKey === this.searchRequest.SortField);
      console.log(fld);
      if (fld) {
        this.searchRequest.SortDisplayField = fld.name;
      }
    }
  }

  openReactivateModal(employeeId: string, driverLastName: string, clientCode: string) {
    this.getDriverInfoAndOpen(employeeId, driverLastName, clientCode);
  }

  private getDriverInfoAndOpen(employeeId: string, driverLastName: string, clientCode: string) {
    let params = new HttpParams();
    params = params.append('clientCode', clientCode);
    if (employeeId && (employeeId.length > 0)) {
      params = params.append('employeeId', employeeId);
    }
    if (driverLastName && (driverLastName.length > 0)) {
      params = params.append('lastName', driverLastName);
    }
    this.loadingSpinnerService.show();
    this.onPremService.get('entity/inactive', { params: params })
      .subscribe({
        next: (data) => {
          this.arrDriverInactive = data as Array<DriverInactive>;
          let driverInfo = this.arrDriverInactive.find(d => employeeId == d.employeeId.toString());
          if (driverInfo) {
            const dialog = this.dialog.open(ReactivateDriverInfoModalComponent, {
              data: { driverInfo: driverInfo },
              minHeight: "12em",
              width: "30em",
              panelClass: "add-group-modal",
              hasBackdrop: false,
              position: { top: "2em", right: "42em" },
            });
          }
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
  }

  private openLeaveOfAbsenceDialog(row: any) {
    this.loadingSpinnerService.show();
    this.getDriverProfileBaseInfo(row['driverId']).subscribe({
      next: (data) => {
        let dataDriverProfile = data as DriverProfile;
        let driverBaseInfo: any = {};
        driverBaseInfo.driverId = row['driverId'];
        driverBaseInfo.firstName = row['first'];
        driverBaseInfo.middleName = row['middle'];
        driverBaseInfo.lastName = row['last'];
        let driverNameLabel = row['first'] + " " + (row['middle'] && (row['middle'].length > 0) ? row['middle'] + " " : "") + row['last'];
        driverBaseInfo.fullName = driverNameLabel;
        driverBaseInfo.employeeId = row['employeeID'];
        driverBaseInfo.fullHierarchy = row['group'];
        driverBaseInfo.lastMVR = dataDriverProfile.mvrRequestDate;
        driverBaseInfo.driverLicenseState = row['state'];
        driverBaseInfo.leaveOfAbsenceStartDate = dataDriverProfile.leaveOfAbsenceStartDate;
        driverBaseInfo.leaveOfAbsenceEndDate = dataDriverProfile.leaveOfAbsenceEndDate;
        driverBaseInfo.enteredBy = row['enteredBy'];
        driverBaseInfo.fromLOAReport = false;
        driverBaseInfo.fromAdvancedSearch = true;
        this.loadingSpinnerService.hide();
        this.safetyModalWindowService.notifyModal('Leave of Absence', 'leaveOfAbsence', 'auto', 'lg', { driverBaseInfo: driverBaseInfo });
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private getDriverProfileBaseInfo(driverId: string) {
    return this.driverHistoryProfileService.getDriverHistoryProfileBaseData(driverId);
  }

}
