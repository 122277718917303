<div class="row">
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <h4 class="text-muted" style="font-weight:bold">Medical Certificate Verification</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a class="driverProfileLink" (click)="openDriverProfile()">
          {{driverInfo?.fullName}} ({{driverInfo?.employeeId.trim()}})
        </a>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div style="float:right">
      <button type="button"
              (click)="openApproveModal()"
              [disabled]="(medCard && ((!medCard.documentId) || !medCard.nationalRegistryDocumentId))"
              class="btn btn-primary">
        Approve
      </button>
      <button type="button"
              (click)="openRejectModal()"
              class="btn btn-primary">
        Reject
      </button>
      <button type="button"
              (click)="cancel()"
              class="btn btn-primary">
        Exit
      </button>
    </div>

  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h5 class="text-muted" style="font-weight:bold">Additional Information</h5>
      <div class="row" *ngIf="medCardSubmitInfo">
        <div class="col-md-12">
          <mat-form-field appearance="fill" class="w-25">
            <mat-label [ngClass]="{ 'label-validation-error': formControlExpirationDate.invalid, 'date-picker-label':true }">Medical Certificate Expiration Date</mat-label>
            <input class="input-date w-100" matInput disabled [matDatepicker]="endDatePicker"  [(ngModel)]="medCardSubmitInfo.expirationDate" [formControl]="formControlExpirationDate">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker disabled="false">
              <mat-datepicker-actions>
                <button mat-button (click)="endDatePicker.select(actualDate); endDatePicker.close()">
                  <mat-icon>history</mat-icon>
                  Today
                </button>
                <button mat-button (click)="endDatePicker.select(null); endDatePicker.close()">
                  <mat-icon>highlight_off</mat-icon>
                  Clear
                </button>
              </mat-datepicker-actions> 
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h5 class="text-muted" style="font-weight:bold">Attachments</h5>

      <div class="row">
        <div class="col-md-12 label-validation-error" *ngIf="medCard && !medCard.nationalRegistryDocumentId">
          National Medical Registery Card Needed, select file to upload:
        </div>
        <div class="col-md-12">
          <input type="checkbox"
                 class="input-dark"
                 name="checkb"
                 id="checkb"
                 [disabled]="false"
                 (change)="enableDisableUpload()" />
          <label class="label-base" for="checkb">National Medical Registry details are on the Medical Certificate - upload not needed</label>
        </div>
        <div class="col-md-12">
          
        </div>        
      </div>
      <app-dq-file-panel *ngIf="medCard&&medCard.nationalRegistryDocumentId"
                         [documentId]="medCard.nationalRegistryDocumentId"
                         [fileName]="medCard.nationalRegistryFileName"
                         [uploadDate]="medCard.nationalRegistryUploadDate">
      </app-dq-file-panel>      
      <div class="row" *ngIf="medCard && !medCard.nationalRegistryDocumentId && !disableUpload" style="padding-top: 1rem;padding-bottom: 1rem;">
        <div class="col-md-12">
          <div class="file-field">
            <div class="btn btn-primary">
              <span>Choose file</span>
              <input type="file" (change)="onUploadOutput($event)" #dqMedicalCardFileInput>
            </div>
            <div class="file-path-wrapper" *ngIf="false">
              <input class="file-path" type="text" placeholder="Upload your file" [value]="showFiles()">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr />
        </div>
      </div>
      <app-dq-file-panel *ngIf="medCard&&medCard.fileName&&medCard.documentId"
                         [documentId]="medCard.documentId"
                         [fileName]="medCard.fileName"
                         [uploadDate]="medCard.uploadDate">
      </app-dq-file-panel>

    </div>
  </div>
</div>
