import { Component, Inject, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataGridInfo, BusinessGroupNewsItem } from "../classes-and-interfaces/classes-and-interfaces.component";
import { ConfirmModalComponent } from "../confirm-modal/confirm-modal.component";
import { ErrorModalService } from "../error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-business-news-admin-list',
  templateUrl: './business-group-news-admin-list.component.html',
  styleUrls: ['./business-group-news-admin-list.component.css']
})
export class BusinessGroupNewsListComponent implements OnInit {
  @ViewChild(ConfirmModalComponent) removeConfirmModalContainer: ConfirmModalComponent;
  dataGridToRemove: DataGridInfo = null;
  dataGridList: Array<BusinessGroupNewsItem>;
  baseUrl: string;

  constructor(private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit() {
    this.getDataGridList();
  }

  ngAfterViewInit() {
    //this.removeConfirmModal = this.removeConfirmModalContainer.confirmModal;
  }

  private getDataGridList() {
    this.loadingSpinnerService.show();
    this.http.get(this.baseUrl + 'api/BusinessNews/GetAll').subscribe({
      next: (data) => {
        this.dataGridList = data as Array<BusinessGroupNewsItem>;
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    })
  }

  enableDataGrid(dataGridId: number, enabled: boolean) {
    let params = new HttpParams();
    params = params.append('VisibilityNewsId', dataGridId.toString());
    params = params.append('Show', enabled.toString());

    this.http.post(this.baseUrl + 'api/BusinessNews/SetViewStatus', null, {
      params: params
    }).subscribe({
      next: (data) => {
        this.getDataGridList();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
      }
    });
  }

  addNewDataGrid() {
    this.router.navigateByUrl('admin/businessgroupnewsadminedit/');
  }

  removeDataGrid(dataGridId: number) {
    let params = new HttpParams();
    params = params.append('DataGridId', dataGridId.toString());

    this.loadingSpinnerService.show();
    this.http.delete(this.baseUrl + 'api/AdminDataGrid/DeleteGrid', {
      params: params
    }).subscribe({
      next: (data) => {
        this.loadingSpinnerService.hide();
        this.getDataGridList();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    })
  }
}
