import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FleetResponseService } from './fleet-response.service';

@Injectable()
export class DriverTasksQueryService {
  private readonly service: string = 'drivertasksquery/';

  constructor(private readonly fleetResponseService: FleetResponseService) {
  }

  delete(endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object> {
    return this.fleetResponseService.delete(this.service, endpoint, options);
  }

  get(endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object>;

  get<T>(endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<T> {
    return this.fleetResponseService.get<T>(this.service, endpoint, options);
  }

  post(endpoint: string, body: any | null, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object> {
    return this.fleetResponseService.post(this.service, endpoint, body, options);
  }

  put(endpoint: string, body: any | null, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object> {
    return this.fleetResponseService.put(this.service, endpoint, body, options);
  }
}
