import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { AccidentClaimRentalDetail } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-accident-claim-rental',
  templateUrl: './accident-claim-rental.component.html',
  styleUrls: ['./accident-claim-rental.component.css']
})
export class AccidentClaimRentalComponent implements OnInit, OnDestroy {
  _claimId: number;
  rentalDetails: Array<AccidentClaimRentalDetail>;
  _routeSub: Subscription;


  constructor(private readonly _route: ActivatedRoute,
    private readonly accidentClaimService: AccidentClaimService) { }


  printPage(): void {
    window.print();
  }

  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];
      this.accidentClaimService.getRentalDetails(this._claimId).subscribe({
        next: (data) => {
          this.rentalDetails = data as Array<AccidentClaimRentalDetail>;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });
    });
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
  }

  getRentalDaysTotal(): number {
    if (this.rentalDetails) {
      return this.rentalDetails.reduce((x, y) => x + y.invoiceDays, 0);
    } else {
      return null;
    }
  }

  getInvoicedTotal(): number {
    if (this.rentalDetails) {
      return this.rentalDetails.reduce((x, y) => x + y.rentalAmount, 0);
    } else {
      return null;
    }
  }
}
