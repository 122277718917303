<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row widgetListWrapper" >
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-9">
        <h3>Business Group News List</h3>
      </div>
      <div class="col-sm-3">
        <button type="button"
                (click)="addNewDataGrid()"
                class="btn btn-primary">
          Add New
        </button>
      </div>
    </div>
    <ng-container *ngFor="let w of dataGridList">
      <div class="row widgetInfoRow">
        <!--<div class="col-sm-2">
          <div class="switch">
            <label>
              <input [checked]="w.isShowPopup" (change)="w.isShowPopup=!w.isShowPopup;enableDataGrid(w.visibilityNewsId, w.isShowPopup);" type="checkbox">
              <span class="lever enableWidgetSwitch"></span>
              <span class="enableWidgetLabel">{{w.isShowPopup?"Enabled":"Disabled"}}</span>
            </label>
          </div>
        </div>-->
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-4"><h5>{{w.dialogTitle}}</h5></div>
            <div class="col-sm-8">
              <a [routerLink]="['/admin/businessgroupnewsadminedit',w.lineOfBusinessId]">
                Edit News Item
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">Line of Business:{{w.lineOfBusinessId}}</div>
          </div>
        </div>
        <!--<div class="col-sm-1">
          <button type="button" class="close dataGridAdminListRemoveButton " aria-label="Remove" (click)="confirmRemoveDataGrid(w)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>-->
      </div>
    </ng-container>
  </div>
</div>
<!--<app-confirm-modal title="Delete Report?"
                   prompt="Delete {{dataGridToRemove?.title}}?"
                   (onConfirmModalYes)="removeDataGrid(dataGridToRemove.dataGridId)"
                   (onConfirmModalNo)="closeRemoveConfirmModal()">
</app-confirm-modal>-->
