<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div >
  <navbar Class="navbar navbar-expand-lg navbar-dark dashboardFilterPanelActionBar p-3">
    <ul class="nav navbar-nav nav-flex-icons ml-auto">
      <li class="nav-item">
        <a (click)="onPrint()">
          <span class='fa fa-print'></span> Print
        </a>
      </li>
      <li class="nav-item">
        <a (click)="onExcel()">
          <span class='fa fa-file-excel-o'></span> Export to Excel
        </a>
      </li>
      <li class="nav-item">
        <a (click)="onEmail()">
          <span class='fa fa-envelope'></span>
          Export to Email
        </a>
      </li>
    </ul>
  </navbar>
  <app-data-grid-export-email [lineOfBusiness]="lineOfBusinessId"></app-data-grid-export-email>
  <H3> {{ reportTitle }} </H3>
  <!--<h4>Report Date: {{ today | date :'short' }}</h4>-->

  <div *ngIf="reportRequestService?.StartDate && reportRequestService?.EndDate">
    {{reportRequestService?.StartDate | date :  "MM/dd/y" }} <span *ngIf="reportRequestService?.EndDate"> - </span> {{reportRequestService?.EndDate | date :  "MM/dd/y"}}
  </div>
  <!--{{filter?.fromDate}} - {{filter?.toDate}} <br />



  Date Range Based On: Date of Loss-->

  <div>
    <!--Filters:-->
    <div>
      Date Range Based On: {{reportRequestService?.DateFieldDisplayValue}}
    </div>
  </div>
  <div *ngIf="rowsRaw?.length > 0">
    <app-data-table 
      [columns]="configObject.fields"
      [data]="configObject.data"
      [showPagination]="true"
      [showPageSize]="true"
      (triggerSortChange)="sortChange($event)"
    ></app-data-table>
    <div id="reportFooter">
      <div class="left"> Fleet Response Confidential</div>
      <div class="right"> Report run {{today | date :'short' }}</div>
    </div>
  </div>
  <div *ngIf="!rowsRaw || rowsRaw?.length == 0">
    No results
  </div>
</div>
