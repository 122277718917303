import { Component } from '@angular/core';
import { AuthService } from './components/auth/auth.service';
import { DateFilterService } from './components/date-filter-service/date-filter-service.component';
import { LineOfBusinessService } from './components/line-of-business-service/line-of-business-service.component';
import { DashboardService } from './components/dashboard-service/dashboard-service.component';
import { BrowserInfoService } from './components/browser-info-service/browser-info-service.component';
import { LoadingSpinnerService } from './services/loading-spinner-service/loading-spinner.service';
import { AppInsightsService } from './services/app-insights-services/AppInsights.service';
import { AppInisghtsErrorHandlerService } from './services/app-insights-services/AppInsightsErrorHandler.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
      DateFilterService,
      LineOfBusinessService,
      DashboardService
    ]
})
export class AppComponent {
  title = 'app';
  loadNavMenu: boolean = false;
  ieFlag: boolean = false;
  displayIEWarningPanel: boolean = true;
  displayCookiesWarningPanel: boolean = false;

  constructor(
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly authService: AuthService,
    private readonly browserInfoService: BrowserInfoService,
    private readonly appInsightsService: AppInsightsService,
    private readonly appInsightsErrorHandlerService: AppInisghtsErrorHandlerService) { }

  ngOnInit() {
    this.loadingSpinnerService.hide();
    if (!this.browserInfoService.areCookiesEnabled()) {
      this.displayCookiesWarningPanel = true;
    }

    this.authService.onAuthCompleted.subscribe(data => {
      this.loadNavMenu = data as boolean;
    })

    this.calcIEFlag();
    this.calcDisplayIEWarning();

    //uncomment this block to override console.error and direct available console messages to app insights 
    /*window.console.error = function (err?,optional?) {
      let aiService =new AppInsightsService();
      if (optional) {
        if (optional.message) {
          aiService.logException(new Error(optional.message));
        }
        if (optional.stack) {
          aiService.logTrace(optional.stack);
        }
      }
      else {
        aiService.logException(err);
      } 
    }*/
  }

  calcIEFlag() {
    // check whether browser is IE - sets flag that can
    // be passed to other components
    const ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    const is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    this.ieFlag = is_ie;
    this.browserInfoService.setIEFlag(is_ie);
  }

  calcDisplayIEWarning() {
    // determine whether user has dismissed IE warning already
    if (this.ieFlag) {
      const s: string = sessionStorage.getItem('FleetVisibility_IEWarningDismissed');
      if (s && (s.length > 0)) {
        this.displayIEWarningPanel = false;
      }
    } else {
      // dont show warning if not using IE
      this.displayIEWarningPanel = false;
    }
  }
}
export interface GridFilterClient {
  clientId: number;
  clientName: string;
}
