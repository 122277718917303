<div class="modal-content" [formGroup]="form">
  <div class="modal-header">

    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="cancel()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="labelDqRejectModal">Reject Document</h4>
  </div>

  <div id="modalBody" class="modal-body">

    <div class="row">
      <p class="description">
        Reviewing document: <span>{{ documentData.documentFileName }}</span>
      </p>
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Reject Reason</mat-label>
          <mat-select
            formControlName="docRejectionReasonId"
            (selectionChange)="handleRejectReasonChange($event)">
            <mat-option
              *ngFor="let option of rejectReasons"
              [value]="option.id">
              {{ option.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row select"  *ngIf="this.form.get('docRejectionReasonId').value === 0">
      <div class="col-md-12">
        <div class="md-form">
          <mat-label class="active">Reason if Other</mat-label>
          <input
            formControlName="docRejectionReasonOtherText"
            minlength="3"
            type="text"
            class="form-control"
            [placeholder]="'Other Reason'"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" [disabled]="form.invalid" (click)="openConfirmationModal()">
      Submit Rejection
    </button>
    <button class="btn btn-primary" (click)="cancel()">Cancel</button>
  </div>
</div>
