<div class="row">
    <div class="col-md-12">
        <div class="card card-body mt-3">
            <h4 class="card-title text-muted">Endorsements</h4>
            <div *ngIf="endorsementsTableConfig">
                <app-data-table 
                    [columns]="endorsementsTableConfig.fields"
                    [data]="endorsementsTableConfig.data"
                    [noRecordsLabel]="endorsementsTableConfig?.noRecordsLabel"
                ></app-data-table>
            </div>
        </div>
    </div>
</div>