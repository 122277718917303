//Angular
import { Component, Input, Output, EventEmitter, OnInit, ViewChildren, QueryList, AfterViewChecked } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from "@angular/common/http";
//Third Party
import { MatDatepicker } from '@angular/material/datepicker';
//App
import { DashboardTile } from "../classes-and-interfaces/classes-and-interfaces.component";
import { DashboardService } from '../dashboard-service/dashboard-service.component';
import { ErrorModalService } from "../error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-dashboard-tile-date-filter',
  templateUrl: './dashboard-tile-date-filter.component.html',
  styleUrls: ['./dashboard-tile-date-filter.component.scss']
})

export class DashboardTileDateFilterComponent implements OnInit, AfterViewChecked {
  @ViewChildren('startDatePicker') startDatePickerList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('endDatePicker') endDatePickerList: QueryList<MatDatepicker<Date>>;
  @Input() card: DashboardTile;
  @Input() initStartDate: Date;
  @Input() initEndDate: Date;
  @Output() onFilterUpdate: EventEmitter<any> = new EventEmitter<any>();
  filterStartDate: Date;
  filterEndDate: Date;
  startDatePicker: MatDatepicker<Date>;
  endDatePicker: MatDatepicker<Date>;
  actualDate = new Date();

  constructor(
    private dashboardService: DashboardService,
    private datePipe: DatePipe,
    //private toastrService: ToastService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService
  ) { }

  ngOnInit() {
    this.filterStartDate = this.initStartDate;
    this.filterEndDate = this.initEndDate;
  }

  ngAfterViewChecked(): void {
    if (!this.startDatePicker && this.startDatePickerList?.first) {
      this.startDatePicker = this.startDatePickerList.first;
      this.startDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.startDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.startDatePicker.select(event.value);
            this.startDatePicker.close();
          })
        }, 0)
      })
    }

    if (!this.endDatePicker && this.endDatePickerList?.first) {
      this.endDatePicker = this.endDatePickerList.first;
      this.endDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.endDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.endDatePicker.select(event.value);
            this.endDatePicker.close();
          })
        }, 0)
      })
    }
  }

  updateTileFilterInfo() {
    var s: string = null;
    var e: string = null;

    if ((this.card.tileDateFilterTypeId == 1) && this.filterStartDate && (this.filterStartDate.toString().length > 0)) {
      s = new Date(this.filterStartDate).toLocaleDateString();
    }
    if (this.filterEndDate && (this.filterEndDate.toString().length > 0)) {
      e = new Date(this.filterEndDate).toLocaleDateString();
    }

    this.dashboardService.updateTileDateFilter(this.card, s, e).subscribe({
      next: (data) => {
        //this.card.filterStartDate = s;
        //this.card.filterEndDate = e;
        this.card.filterStartDate = (s ? new Date(this.filterStartDate).toISOString() : null);
        this.card.filterEndDate = (e ? new Date(this.filterEndDate).toISOString() : null);
        this.onFilterUpdate.emit();
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    })
  }
}
