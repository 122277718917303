import { Injectable } from '@angular/core';
import { OnPremDriverService } from '../../../components/on-prem-service/on-prem-driver-service.component';
import { DriverRiskLevel } from '../driver-risk/driver-risk-level';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DriverRiskLevelsService {
  private _riskLevels$ = new ReplaySubject<Array<DriverRiskLevel>>(1);
  public riskLevels$ = this._riskLevels$.asObservable();

  constructor(private onPremService: OnPremDriverService) {
    this.onPremService.get('lookup/risklevels')
      .subscribe(data => this.getRiskLevelOptions(data));
  }
  private getRiskLevelOptions(data: any) {
    this._riskLevels$.next(data);
  }
}
