<div class="row">
    <div class="col-md-12">
        <div class="card card-body mt-3">
        <h4 class="card-title text-muted">Orders</h4>
        <div *ngIf="ordersTableConfig">
            <app-data-table 
                [columns]="ordersTableConfig.fields"
                [data]="ordersTableConfig.data"
                [expandable]="ordersTableConfig?.expandable"
                [expand]="ordersTableConfig?.expand"
                [noRecordsLabel]="ordersTableConfig?.noRecordsLabel"
            ></app-data-table>
        </div>
        </div>
    </div>
</div>
