<div class="card card-body card-tile-filter">
  <div class="row">
    <div class="col-sm-12">
      {{card.slicerDisplayName}}
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Choose the value for this filter</mat-label>
        <mat-select [(value)]="card.slicerOptionChosen">
          <mat-option *ngFor="let option of slicerOptions" [value]="option.value">
            {{option.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
