//Angular
import { Injectable } from '@angular/core';
//Third Party
import { Subject ,  Observable ,  BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
//App
import { DriverHistoryProfileModal } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { DcModalWindowComponent } from './dc-modal-window.component';

@Injectable()
export class DcModalWindowService {
  // alert for the modal open with data supplied from the ui layer
  public dc = new Subject<DriverHistoryProfileModal>();
  public dcDefaultTabIndex = new BehaviorSubject<number>(0);
  alertDcModalInfo$ = this.dc.asObservable();//hola

  // alert for the modal close
  public dcClose = new Subject<boolean>();
  alertDcModalClose$ = this.dcClose.asObservable();

  constructor(private dialog: MatDialog) {
  }

  /* NOTIFIES THE OBSERVABLE OF THE DROPDOWN SELECTION, WHICH IN TURN
     NOTIFIES THE MODAL DIALOG TO RESPOND */
  notifyModal(title: string, message: string, modalHeight: string, modalWidth: string, modalInput?:any) {
    let _modalInfo = new DriverHistoryProfileModal();
    _modalInfo.message = message;
    _modalInfo.title = title;
    _modalInfo.modalHeight = modalHeight;
    _modalInfo.modalWidth = modalWidth;
    _modalInfo.modalInput = modalInput;
    this.showDcModalWindow(_modalInfo)
  }

  showDcModalWindow(modalInfo: DriverHistoryProfileModal): void {
    const dialogRef = this.dialog.open(DcModalWindowComponent,{
      data: {modalInfo: modalInfo},
      width: '54em',
      minHeight: '18em',
      panelClass: 'add-document-modal',
      hasBackdrop: false,
      position: {top: '2em'}
    });
  }

  /* NOTIFIES THE MODAL TO CLOSE */
  notifyModalClose() {
    this.dcClose.next(true);
  }
  
}
