//Angular
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
//Third Party
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//App

@Component({
  selector: 'review-submit-modal',
  templateUrl: './review-submit-modal.component.html',
  styleUrls: ['./review-submit-modal.component.scss']
})
export class ReviewSubmitModalComponent implements OnInit {
  prompt: string;
  @Output() onConfirmModalYes: EventEmitter<any> = new EventEmitter<any>();
  @Output() onConfirmModalNo: EventEmitter<any> = new EventEmitter<any>();
  constructor(public dialogRef: MatDialogRef<ReviewSubmitModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {prompt: string}) {
    this.prompt = data.prompt;
   }

  ngOnInit(): void {
  }

  public closeModal(): void {
    this.dialogRef.close(false);
  }

  confirmModalYes() {
    this.dialogRef.close(true);
  }

  confirmModalNo() {
    this.dialogRef.close(false);
  }

}
