//angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//thirdparty
import { Subscription } from 'rxjs';
//project libraries
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { DriverInactive } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { HondaOptOutSearchModalService } from './honda-opt-out-search-modal-service.component';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-honda-opt-out-search-modal',
  templateUrl: './honda-opt-out-search-modal.component.html',
  styleUrls: ['./honda-opt-out-search-modal.component.scss']
})
export class HondaOptOutSearchModalComponent implements OnInit {
  reactivateSubscription: Subscription;
  searchId: string;
  searchName: string;
  selectedGroup: string = "";
  groupsChoices: Array<any>;
  showAll: boolean = false;


  constructor(private router: Router,
    private reactivateService: HondaOptOutSearchModalService,
    private clientService: ClientSelectionService,
    private errorService: ErrorModalService,
    private dialogRef: MatDialogRef<HondaOptOutSearchModalComponent>) {
  }

  ngOnInit(): void {
    this.searchId = "";
    this.searchName = "";
    this.selectedGroup = "";
    this.initReactivateSearchModal();
  }

  search() {
    let driverId = this.searchId || '';
    let driverLastName = this.searchName || '';
    let selectedGroup = this.selectedGroup || '';
    if ((driverId.length > 0) || (driverLastName.length > 0) || selectedGroup.length > 0) {
      this.searchName = null;
      this.searchId = null;
      this.closeReactivateSearchModal();
      this.router.navigate(['optoutreport', this.selectedGroup || '', driverId, driverLastName]);
    } else {
      this.errorService.setErrorObject({ message: 'Enter "Employee Id", "Last Name", or select "Group".' });
    }

  }

  initReactivateSearchModal() {
    this.reactivateService.getGroups(this.clientService.getClientSelectedArrayValue(2).join(",")).subscribe(data => {
      var options = data as HierarchyGroup[];
      this.groupsChoices = options.map(x => { return { value: x.elementGuid, label: x.element } });

      this.groupsChoices.unshift({ value: 'all', label: 'Show All', selected: true });
      //console.log(this.groupsChoices);
    });
  }

  closeReactivateSearchModal():void {
    this.dialogRef.close();
  }

  onGroupChange(event) {
    console.log(this.selectedGroup)
  }
}

class HierarchyGroup {
  public elementGuid: string;
  public element: string;
}
