<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
  <div class="row">
    <div class="col-md-12">
      <label>
        Are you sure you have verified these MVR results for {{ driverName }}?
      </label>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-default"
          (click)="submit()">
    Confirm
  </button>
  <button class="btn btn-default" style="margin-bottom:6px"
          (click)="cancel()">
    Exit
  </button>
</div>
