<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
  <div class="card card-body">
    <div class="row">
      <div class="col-sm-12">
          <mat-form-field appearance="fill" class="w-100">
           <mat-label class="lbl-start-date">Begin</mat-label>
             <input class="input-date" matInput disabled [matDatepicker]="startDatePicker" [min]="actualDate" [(ngModel)]="beginDate"  [formControl]="beginDateControl">
             <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
             <mat-datepicker #startDatePicker [disabled]="startDateReadOnly">
              <mat-datepicker-actions>
                <button mat-button (click)="startDatePicker.select(actualDate); startDatePicker.close()">
                  <mat-icon>history</mat-icon>
                  Today
                </button>
                <button mat-button (click)="startDatePicker.select(null); startDatePicker.close()">
                  <mat-icon>highlight_off</mat-icon>
                  Clear
                </button>
              </mat-datepicker-actions> 
            </mat-datepicker>
          </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field appearance="fill" class="w-100" *ngIf="!startDateReadOnly">
          <mat-label>Return</mat-label>
          <input class="input-date" matInput disabled [matDatepicker]="endDatePicker" [min]="actualDate" [(ngModel)]="returnDate" [formControl]="returnDateControl">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker disabled="false">
            <mat-datepicker-actions>
              <button mat-button (click)="endDatePicker.select(actualDate); endDatePicker.close()">
                <mat-icon>history</mat-icon>
                Today
              </button>
              <button mat-button (click)="endDatePicker.select(null); endDatePicker.close()">
                <mat-icon>highlight_off</mat-icon>
                Clear
              </button>
            </mat-datepicker-actions> 
          </mat-datepicker>
        </mat-form-field>                   
      </div>
    </div>
    <div class="row" style="padding-top:15px;">

    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-default"
          (click)="submit()">
    Schedule
  </button>
  <button class="btn btn-default" style="margin-bottom:6px" 
          (click)="cancel()">
    Exit
  </button>
</div>
