<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" >
  <div class="col-md-12">
    <!--<h4 class="card-title text-muted" *ngIf="pdfType == 0">Available MVR PDF's for Download</h4>
    <h4 class="card-title text-muted" *ngIf="pdfType == 1">Available COVs PDF's for Download</h4>
    <h4 class="card-title text-muted" *ngIf="pdfType == 2">Available Training PDF's for Download</h4>-->

    <h4 class="card-title text-muted" >Available {{getType()}} PDF's for Download</h4>
    <app-loading-panel *ngIf="!mvrTableConfig"></app-loading-panel>
    <div *ngIf="mvrTableConfig">
      <app-data-table
        [columns]="mvrTableConfig.fields"
        [data]="mvrTableConfig.data"
        (triggerDownload)="onDownload($event)"
      ></app-data-table>
    </div>
  </div>
</div>
