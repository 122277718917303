<div>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeOrdAssignReportSearchModal()">
        <span aria-hidden="true">×</span>
      </button>
      <h4 class="modal-title w-100" id="labelReactivateModal">Orders & Assignments Report</h4>
    </div>
    <div class="modal-body">
      <div class="row" style="margin-top:1rem">
        <div class="col-sm-3">
          Todays Date:
        </div>
        <div class="col-sm-9">
          {{dtToday |  date:'M/d/yyyy'}}
        </div>
      </div>
      <div class="row" style="margin-top:2rem">
        <div class="col-sm-12">
          <label class="active">Batch Date/Time</label>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Select Batch Date/Time</mat-label>
            <mat-select>
              <mat-option *ngFor="let opt of batchDateTimeOptions" [value]="opt.value">
                {{opt.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" aria-label="Search" [disabled]="!batchId" (click)="search()">Search</button>
      <button type="button" class="btn btn-default" style="margin-bottom:6px" aria-label="Close" (click)="closeOrdAssignReportSearchModal()">Close</button>
    </div>
  </div>
</div>
