import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ErrorModalService } from '../error-modal/error-modal-service.component';
import { LineOfBusinessService } from '../line-of-business-service/line-of-business-service.component';
import { Subscription } from 'rxjs';
import { CustomModuleSelectorService } from '../custom-module-selector/custom-module-selector-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-custom-module',
  templateUrl: './custom-module.component.html',
  styleUrls: ['./custom-module.component.css']
})
export class CustomModuleComponent implements OnInit, OnDestroy, AfterViewInit {
  lineOfBusiness: number;
  private routesub: any;
  customModuleSubscription: Subscription;

  customUrl: SafeResourceUrl;
  //@ViewChild('customIFrame') customIFrame: ElementRef;

  private customIFrame: ElementRef;

  @ViewChild('customIFrame') set content(content: ElementRef) {
    this.customIFrame = content;
    //console.log("OK1");
    //console.log(this.customIFrame);
    if (this.customIFrame) {
      (this.customIFrame.nativeElement as HTMLIFrameElement).onload = () => {
        this.loadingSpinnerService.hide();
      };
    }
  }


  constructor(private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private lineOfBusinessService: LineOfBusinessService,
    private customModuleSelectorService: CustomModuleSelectorService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService) {


    this.customUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.customModuleSelectorService.getSavedCustomApp());
    if (this.customUrl) {
      this.loadingSpinnerService.show();
    }
    this.customModuleSubscription = customModuleSelectorService.customModuleSelectedUrl$.subscribe(customModule => {
      this.loadingSpinnerService.show();
      this.customUrl = this.sanitizer.bypassSecurityTrustResourceUrl(customModule as string);
      //console.log(this.customIFrame);
      //if (this.customIFrame) {
      //  console.log("OK0");
      //  (this.customIFrame.nativeElement as HTMLIFrameElement).onload = () => {
      //  };

      //}
    });

  }

  ngAfterViewInit() {

    //if (this.customIFrame) {
    //  (this.customIFrame.nativeElement as HTMLIFrameElement).onload = () => {
    //  };

    //}
  }
  ngOnInit(): void {

    this.routesub = this.route.params.subscribe(params => {
      if (params['LineOfBusiness']) {
        this.lineOfBusiness = +params['LineOfBusiness']; // (+) converts string 'id' to a number
      }

      // set line of business for other components that subscribe
      this.lineOfBusinessService.setLineOfBusiness(this.lineOfBusiness);

      //this.customUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://fleetsuite-dev.azurewebsites.net/VendorLookup/');
    });

    this.loadingSpinnerService.hide();
    

  }

  ngOnDestroy() {
    this.routesub.unsubscribe();
    this.customModuleSubscription.unsubscribe();
  }
}
