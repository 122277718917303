<div class="card card-body card-tile-filter">
  <ng-container *ngIf="card?.tileDateFilterTypeId==1">
    <div class="row">
      <div class="col-sm-12" style="padding-top:1rem">
        Date Range:
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>From Date</mat-label>
          <input class="input-date" matInput disabled [matDatepicker]="startDatePicker" [(ngModel)]="filterStartDate">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker disabled="false">
            <mat-datepicker-actions>
              <button mat-button (click)="startDatePicker.select(actualDate); startDatePicker.close()">
                <mat-icon>history</mat-icon>
                Today
              </button>
              <button mat-button (click)="startDatePicker.select(null); startDatePicker.close()">
                <mat-icon>highlight_off</mat-icon>
                Clear
              </button>
            </mat-datepicker-actions> 
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Through Date</mat-label>
          <input class="input-date" matInput disabled [matDatepicker]="endDatePicker" [(ngModel)]="filterEndDate">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker disabled="false">
            <mat-datepicker-actions>
              <button mat-button (click)="endDatePicker.select(actualDate); endDatePicker.close()">
                <mat-icon>history</mat-icon>
                Today
              </button>
              <button mat-button (click)="endDatePicker.select(null); endDatePicker.close()">
                <mat-icon>highlight_off</mat-icon>
                Clear
              </button>
            </mat-datepicker-actions> 
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="card?.tileDateFilterTypeId==2">
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Date</mat-label>
          <input class="input-date" matInput disabled [matDatepicker]="endDatePicker" [(ngModel)]="filterEndDate">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker disabled="false">
            <mat-datepicker-actions>
              <button mat-button (click)="endDatePicker.select(actualDate); endDatePicker.close()">
                <mat-icon>history</mat-icon>
                Today
              </button>
              <button mat-button (click)="endDatePicker.select(null); endDatePicker.close()">
                <mat-icon>highlight_off</mat-icon>
                Clear
              </button>
            </mat-datepicker-actions> 
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
</div>