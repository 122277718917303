import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, NavigationEnd, ResolveEnd } from '@angular/router';
import { AdvancedSearchService, GridData, AdvancedSearchRequest } from './advanced-search-claims.service';

@Injectable()
export class ClaimsAdvancedSearchResolve  {
  constructor(private advancedSearchService: AdvancedSearchService, private router: Router, private searchRequest: AdvancedSearchRequest) {
    this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        //this.reloadGridData();
      }
      if (event instanceof ResolveEnd) {

      }
    });
  }

  resolve(route: ActivatedRouteSnapshot): Promise<any> | boolean {
    let id = route.params["id"] as string;

    return this.advancedSearchService.getData(this.searchRequest).then(x => {
      if (x) {
        if (x.rows != null && x.rows.length == 1) {
          console.log("advanced search redirect to claim:")
          console.log(x);
          this.router.navigateByUrl("/accident/claim/" + x.rows[0].Claim);
          return false;
        }
        return x;
      } else { // id not found
        this.router.navigate(['/']);
        return false;
      }
    });
  }
}
