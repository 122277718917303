<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Incomplete Training</h4>
          <app-loading-panel *ngIf="!driverIncompleteTrainingTableConfig"></app-loading-panel>
          <div *ngIf="driverIncompleteTrainingTableConfig">
            <app-data-table 
              [columns]="driverIncompleteTrainingTableConfig.fields"
              [data]="driverIncompleteTrainingTableConfig.data"
              noRecordsLabel="No Incomplete Training"
              (triggerOpenModal)="openModal($event)"
              (triggerDownload)="onDownload($event)"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Completed Training</h4>
          <app-loading-panel *ngIf="!driverCompletedTrainingTableConfig"></app-loading-panel>
          <div *ngIf="driverCompletedTrainingTableConfig">
            <app-data-table 
              [columns]="driverCompletedTrainingTableConfig.fields"
              [data]="driverCompletedTrainingTableConfig.data"
              noRecordsLabel="No Completed Training"
              (triggerOpenModal)="openModal($event)"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
