<div class="row" >
    <div class="col-12">
        <h4 class="card-title text-muted wrap-this" style="padding-bottom:20px;">Select Group</h4>
        <form [formGroup]="newDhpGroupForm" autocomplete="off" (ngSubmit)="searchDhpGroup()">
        <div class="row">
            <div class="col-12 col-md-8">
                <div class="md-form">

                    <label for="type" class="select-label">Driver Groups</label>
                    <select class="browser-default custom-select select-margin w-100" formControlName="clientCode"  >
                        <option value="">Choose Client Code</option>
                        <option *ngFor="let client of (clientSelections ? clientSelections : [])" [ngValue]="client.clientShortName">{{client.clientShortName}} - {{client.clientName}}</option> 
                    </select>
              
                    <div *ngIf="isSubmitted && form.clientCode.errors" class="invalid-feedback">
                        <div *ngIf="form.clientCode.errors.required">Client Code is required</div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                    <button type="submit" *ngIf="!isLoading"
                    class="btn btn-primary w-100">
                    Search DHP Group
                    </button>

                    <div class="col-12 text-align-center" *ngIf="isLoading">
                        <app-loading-panel ></app-loading-panel>
                    </div>
            </div>
        </div>
        
        </form>
        <div class="row" *ngIf = "searchedDhpHierarchy?.length > 0">
            <div class="col-12 col-md-12">
                <div class="md-form">
                    <label for="type" class="select-label">Driver Groups</label>
                    <select class="browser-default custom-select select-margin w-100" [(ngModel)] = "selectedDhp"  (change)="onSelectedDhpChange()"  > 
                        <option selected  value="null" >Please Select...</option>
                        <option *ngFor="let dhp of (searchedDhpHierarchy ? searchedDhpHierarchy : [])" [ngValue]="dhp" >{{dhp.element}}</option>
                    </select>
                </div>

            </div>
        </div>
        <div class="row ">
            <div class="col-12 summary" *ngIf = "isDhpGroupMissing && !newDhpGroupForm.dirty">
                <div class="alert alert-danger" role="alert">Can't find DHP Group: {{form.clientCode.value}}  </div>
            </div>
        </div>

    </div>
</div>
