import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { IELearnMoreModalComponent } from './ie-learnmore-modal.component';

@Injectable()
export class IELearnMoreModalService {
  private openIEModalObs = new Subject();
  openIEModalObs$ = this.openIEModalObs.asObservable();

  constructor(private dialog: MatDialog) { }

  openModal() {
    this.openIEModalObs.next(true);
    const dialogRef = this.dialog.open(IELearnMoreModalComponent, {
      hasBackdrop: false,
      minWidth: '29em',
    })
  }

  closeModal() {
    this.openIEModalObs.next(false);
  }
}
