import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from '../../components/auth/auth.service'

@Pipe({name: 'image'})
export class ImagePipe implements PipeTransform {
  constructor(private http: HttpClient, private authService: AuthService) {}

  transform(url: string) {
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.user.access_token,
      //'Content-Type': 'image/*',
    });

    /* tell that XHR is going to receive an image as response, so it can be then converted to blob, and also provide your token in a way that your server expects */
      return this.http.get(url, {
          headers: headers,
          responseType: 'blob'
      }) // specify that response should be treated as blob data
          .pipe(
              switchMap(blob => {
                  // return new observable which emits a base64 string when blob is converted to base64
                  return Observable.create(observer => {
                      const reader = new FileReader();
                      reader.readAsDataURL(blob); // convert blob to base64
                      reader.onloadend = function() {
                          observer.next(reader.result); // emit the base64 string result
                      }
                  });
              })
          )
        /*
  .map(response => response.blob()) // take the blob
  .switchMap(blob => {
  // return new observable which emits a base64 string when blob is converted to base64
      return Observable.create(observer => { 
        const  reader = new FileReader(); 
        reader.readAsDataURL(blob); // convert blob to base64
        reader.onloadend = function() {             
              observer.next(reader.result); // emit the base64 string result
        }
     });
   });
   */
  }
}
