<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" style="padding-bottom:20px">
  <div class="col-md-9">
    <h3>Filter Selection</h3>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <mat-tab-group class="tabs-container">
      <mat-tab label="Corporate Structure"> 
        <div class="row">
          <div class="col-md-9">
            <app-corporate-structure-selector-folder  [chType]="'DcFilter'"
                                                      [arrCHInfo]="arrCH"
                                                      [clientSelectedArray]="clientSelectedArray">
            </app-corporate-structure-selector-folder>
          </div>
          <div class="col-md-3">
            <button type="button"
                    (click)="saveFilter()"
                    class="btn btn-primary">
              Apply the Selected Filter
            </button>
          </div>
         </div>
      </mat-tab>
    </mat-tab-group> 
  </div>
</div>
