import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimaryDriverDetails } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-safety-primary-driver-link',
  templateUrl: './safety-primary-driver-link.component.html',
  styleUrls: ['./safety-primary-driver-link.component.css']
})
export class SafetyPrimaryDriverLinkComponent implements OnInit {
  @Input() primaryDriverId: number;
  @Input() primaryDriverDetails: PrimaryDriverDetails;

  constructor(private router: Router,
    private onPremService: OnPremDriverService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService) { }

  ngOnInit() {
    // look up primaryDriverDetails if not provided
    if (!this.primaryDriverDetails) {
      this.loadingSpinnerService.show();
      this.onPremService.get('entity/' + this.primaryDriverId.toString() + '/details')
        .subscribe({
          next: (data) => {
            this.primaryDriverDetails = data as PrimaryDriverDetails;
            this.loadingSpinnerService.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.loadingSpinnerService.hide();
            this.errorService.setErrorObject(err.error);
          }
        });
    }
  }

  openDriverProfile(driverId: number) {
    if (driverId) {
      this.router.navigate(['driver/history-profile', driverId.toString()]);
    } else {
      alert('driver id not specified');
    }
  }
}
