  <div>
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="confirmModalGenericNo()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="labelConfirmModalGeneric">{{ confirmGenericTitle }}</h4>
      </div>
      <div class="modal-body">
        {{ message }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" aria-label="yesLabel" (click)="confirmModalGenericYes()">{{ yesLabel }}</button>
        <button type="button" class="btn btn-default" style="margin-bottom:6px" aria-label="noLabel" (click)="confirmModalGenericNo()">{{ noLabel }}</button>
      </div>
    </div>
  </div>
