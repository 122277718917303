//angular libraries
import { Component, ViewChild, Inject, OnInit, OnDestroy, Pipe, PipeTransform, NgModule, AfterContentInit, AfterViewInit } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

//thirdparty libraries
import { Observable } from 'rxjs';
import { LineOfBusinessInfo, DataGridInfo, DataGridFilterInfo, DataGridFilterType, BusinessGroupNewsItem } from "../classes-and-interfaces/classes-and-interfaces.component";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';

//project imports
import { ErrorModalService } from "../error-modal/error-modal-service.component";
import { AlertModalService } from "../alert-modal/alert-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';


//#AngularUpgrade
//@NgModule({
//  imports: [NgxEditorModule]
//})



@Component({
  selector: 'app-business-group-news-admin-edit',
  templateUrl: './business-group-news-admin-edit.component.html',
  styleUrls: ['./business-group-news-admin-edit.component.scss']
})
export class BusinessGroupNewsAdminEditComponent implements OnInit, OnDestroy, AfterViewInit {
  private routeDataGridId: any;
  newsItemId: string = "";
  newsItemObject: BusinessGroupNewsItem = new BusinessGroupNewsItem();
  dataGridFilterArr: Array<DataGridFilterInfo>;
  dataGridFilterObjectSelected: DataGridFilterInfo;
  baseUrl: string;
  dataGridLoading: boolean = true;
  isNewDataGrid: boolean = false;
  dataGridNotFound: boolean = false;
  dataGridLineOfBusinessChoices: Array<any>;
  dataGridFilterOrderChoices: Array<any>;
  dataGridFilterTypeChoices: Array<any>;
  objValidationFail: object = {};
  objFilterValidationFail: object = {};
  returnToWidgetId: string = "";
  lineOfBusinessIdString: string;
  htmlContent: string = "";


  lineOfBusinessName: string;
  startDateString: string = "";
  endDateString: string = "";

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: []
  };

  actualDate = new Date();
  @ViewChild('startDatePicker') startDatePicker: MatDatepicker<Date>;
  @ViewChild('endDatePicker') endDatePicker: MatDatepicker<Date>;
  showModal: boolean;
  display: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private snackBar: MatSnackBar,
    private LoadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private alertService: AlertModalService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit() {
    this.routeDataGridId = this.route.params.subscribe(params => {
      this.newsItemId = params['newsItemId'];
      //this.returnToWidgetId = params['returnToWidgetId'];
      if (this.newsItemId.length > 0) {
        this.initAll(+this.newsItemId);
      } else {
        //this.widgetObject = new WidgetInfo();
        this.isNewDataGrid = true;
        this.initAll(null);
      }
    });
  }

  private getDataGridInfo(LineOfBusiness: number) {
    //let params = new HttpParams();
    //params = params.append('newsItemId', dataGridId.toString());



    this.http.get(this.baseUrl + "api/BusinessNews/GetByLobForEdit?LineOfBusinessId=" + LineOfBusiness).subscribe({
      next: (data) => {

        let datePipe = new DatePipe("en-US");


        this.newsItemObject = data as BusinessGroupNewsItem;
        let startDate = new Date(this.newsItemObject.startDate);
        this.startDateString = datePipe.transform(startDate, "MM/dd/yyyy");
        let endDate = new Date(this.newsItemObject.endDate);
        this.endDateString = datePipe.transform(endDate, "MM/dd/yyyy");

        if (this.newsItemObject) {
          this.dataGridLineOfBusinessChoices.forEach(x => {
            if (x.value == this.newsItemObject.lineOfBusinessId.toString())
              this.lineOfBusinessName = x.label;
          });
        }
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
      }
    });
  }

  ngAfterViewInit() {
    this.startDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.startDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
          this.startDatePicker.select(event.value);
          this.startDatePicker.close();
        })
      }, 0)
    })

    this.endDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.endDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
          this.endDatePicker.select(event.value);
          this.endDatePicker.close();
        })
      }, 0)
    })
  }


  private initAll(dataGridId: number) {

    let obs: Observable<Object> = this.http.get(this.baseUrl + 'api/UserFilter/LineOfBusinesses');

    obs.subscribe({
      next: (data) => {
        this.dataGridLineOfBusinessChoices = this.transformLineOfBusinessChoices(<Array<any>>data);
        if (!this.isNewDataGrid) {
          this.getDataGridInfo(dataGridId);
        } else {
          this.dataGridLoading = false;
        }
      },
      //err => this.toastrService.error(err.error.toString())
      error: (err) => this.errorService.setErrorObject(err.error)
    });
  }

  private transformLineOfBusinessChoices(data: Array<any>): Array<any> {
    const tempin: Array<LineOfBusinessInfo> = data as Array<LineOfBusinessInfo>;
    const tempout: Array<any> = [];
    tempin.forEach((item: LineOfBusinessInfo, index: number) => {
      const tempobj = {
        value: item.lineOfBusinessId.toString(),
        label: item.lineOfBusinessName,
        selected: (item.lineOfBusinessId == 1)
      };
      tempout.push(tempobj);
      if (this.newsItemObject && item.lineOfBusinessId == this.newsItemObject.lineOfBusinessId)
        this.lineOfBusinessName = item.lineOfBusinessName
    })
    return tempout;
  }


  private validateDataGrid(): boolean {
    let valid = true;
    const arrToValidate = [
      "lineOfBusinessId",
      "dialogTitle",
      "dashboardButtonLabel",
      "title",
      "startDate",
      "endDate",
      "newsText"
    ];

    this.objValidationFail = {};

    arrToValidate.forEach((fld) => {
      if ((!this.newsItemObject[fld]) || (this.newsItemObject[fld].length <= 0)) {
        valid = false;
        this.objValidationFail[fld] = true;
      }
    });

    if (!valid) {
      this.errorService.setErrorObject({ message: 'Missing or Invalid Fields.' });
    }
    return valid;
  }

  saveDataGrid() {
    //if (!this.validateDataGrid()) {
    //  let strErr = "Please fill in all required values";
    //  //this.toastrService.error(strErr);
    //  this.errorService.setErrorObject({ message: strErr });
    //} else{
    //  this.dataGridObject.lineOfBusinessId = +this.dataGridObject.lineOfBusinessIdString;
    //  if (this.isNewDataGrid) {
    //    //this.addDataGrid(this);
    //  } else {
    //    this.newsItemObject.lin
    //    //this.updateDataGrid(this.dataGridObject);
    //}



    this.newsItemObject.startDate = this.startDateString;
    this.newsItemObject.endDate = this.endDateString;

    if (this.validateDataGrid())
      this.addDataGrid();
    this.showModal = true;
    this.display = "block";
  }



  private addDataGrid() {
    this.http.post(this.baseUrl + 'api/BusinessNews/Update', JSON.stringify(this.newsItemObject), { headers: { 'Content-Type': 'application/json' } }).subscribe({
      next: (data) => {

        if (this.isNewDataGrid) {
          this.snackBar.open('News Item Added', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: 'success-snackbar'
          });
        }
        else {
          this.snackBar.open('News Item Updated', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: 'success-snackbar'
          });
        }

        this.exitDataGridEdit();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
      }
    })
  }

  //private updateDataGrid(data: DataGridInfo) {
  //      //this.http.post(this.baseUrl + 'api/AdminDataGrid/UpdateGrid', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } }).subscribe(data => {
  //      this.toastrService.success("News Item Updated");
  //      this.exitDataGridEdit();
  //      //},
  //      //  (err: HttpErrorResponse) => {
  //      //    //this.toastrService.error(err.error.toString());
  //      //    this.errorService.setErrorObject(err.error);
  //      //  }
  //      //)
  //    }

  exitDataGridEdit() {
    if (this.returnToWidgetId && (this.returnToWidgetId.length > 0)) {
      this.router.navigate(['/admin/widgetedit', this.returnToWidgetId]);
    } else {
      this.router.navigateByUrl('/admin/businessgroupnewsadminlist');
    }
    this.returnToWidgetId = "";
  }



  ngOnDestroy() {
    this.routeDataGridId.unsubscribe();
  }

  preview() {
    this.newsItemObject.startDate = this.startDateString;
    this.newsItemObject.endDate = this.endDateString;

    if (this.validateDataGrid()) {
      this.showModal = true;
      this.display = "block";
    }
  }
  closeModal() {
    this.showModal = false;
    this.display = "none";
  }
}


