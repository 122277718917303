import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { DriverTelematics, UserRightsInfo, DriverProfile } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

/**
 * this is the client-side controller for the safety - telematics tab panel
 */
@Component({
  selector: 'safety-telematics',
  templateUrl: './safety-telematics.component.html',
  styleUrls: ['./safety-telematics.component.css']
})

export class SafetyTelematicsComponent implements OnInit, OnChanges {
  @Input() driverProfile: DriverProfile;
  @Input() driverTelematics: Array<DriverTelematics>;
  @Input() _userRights: Array<UserRightsInfo>;
  gtOptions: any = { };
  public driverTelTableConfig: any;

  // the overloaded constructor for the controller
  constructor(private driverHistoryProfileService: DriverHistoryProfileService) { }

  ngOnInit() {
    // set data for tables
    this.setTableData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //update table data when inputs change
    this.setTableData();
  }

  private setTableData() {
    if (this.driverTelematics) {
      this.setTelTableConfig(this.driverTelematics);
    }
  }

  private setTelTableConfig(arrDriverTel: Array<DriverTelematics>) {
    var columns = [
      { name: "Category", prop: "alertCategory", title: "Category", visible: true, sort: "", dataType: "link" },
      { name: "Score", prop: "alertScore", title: "Score", sort: "", visible: true, dataType: "number" },
      { name: "driverTelematicsId", prop: "driverTelematicsId", title: "driverTelematicsId", visible: false, dataType: null },
      { name: "hasDetail", prop: "hasDetail", title: "hasDetail", visible: false, dataType: null }
    ];

    var settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: 'enable'
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        hidden: !s?.visible,
        render: row => {
          let r = "";
          switch (s.prop) {
            case "alertCategory":
              // link if hasDetail = true
              if (row["hasDetail"]) {
                r = r = '<a class="textalign center" style="color:#d23138;"><u>' + row[s.prop] + '</u></a>';
              } else {
                r = row[s.prop];
              }
              break;
            default:
              r = row[s.prop];
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        },
        click: row => {
          if (s.prop === "alertCategory") {
            // open detail modal if hasDetail
            if (row["hasDetail"]) {
              return this.openTelematicsDetailModal(row["alertCategory"], this.driverProfile.driverId, row["driverTelematicsId"]);
            }
          }
        }
      });
    });

    this.driverTelTableConfig = {
      settings: settings,
      fields: fields,
      data: arrDriverTel
    }

    this.gtOptions = (arrDriverTel.length > 1 ? { numberOfRows: arrDriverTel.length } : {});

  }

  private openTelematicsDetailModal(alertCategory:string, driverId:number, driverTelematicsId: number) {
    this.driverHistoryProfileService.notifyModal(alertCategory, 'telematicsDetail', '580px;', 'lg', { driverId: driverId, driverTelematicsId: driverTelematicsId });
  }

  openModal(data: any): void {
    this.openTelematicsDetailModal(data.row["alertCategory"], this.driverProfile.driverId, data.row["driverTelematicsId"])
  }
}
