import { Orders, MVRReport } from './../../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DHPMVRReportService } from '../dhp-mvr-report-service.component';
import { DhpMvrCustomRowComponent } from '../dhp-mvr-custom-row/dhp-mvr-custom-row.component';

@Component({
  selector: 'dhp-mvr-report-orders',
  templateUrl: './dhp-mvr-report-orders.component.html',
  styleUrls: ['../../dhp-mvr-report/dhp-mvr-report.component.css']
})
export class DhpMvrReportOrdersComponent implements OnInit {
  mvrReport: MVRReport;
  public ordersTableConfig: any;
  
  constructor(private readonly datePipe: DatePipe,
    private readonly dhpMVRReportService: DHPMVRReportService) { }
    gtOptions: any = {};

  ngOnInit(): void {
    this.dhpMVRReportService.sharedMvrReport.subscribe(mvrData => { 
      if(mvrData != null ) {
        this.mvrReport = mvrData;
        const ordersArray = (this.mvrReport.orders != null) ?  this.mvrReport.orders : [];
        this.setOrderTableConfig(ordersArray);
      }
    });
  }

  private setOrderTableConfig(orderRecords: Array<Orders>) {
    const columns = [
      { name: "Details", prop: "details", title: "Details", visible: true, dataType: null, columnClass:"width7"  },
      { name: "Control Nbr", prop: "control", title: "Control Nbr", visible: true, dataType: null, columnClass: "width15"  },
      { name: "Date", prop: "orderDate", title: "Date", visible: true, dataType: "date", columnClass: ""  },      
      { name: "Time", prop: "orderTime", title: "Time", visible: true, dataType: null, columnClass: ""  },
      { name: "DOB", prop: "orderDOB", title: "DOB", visible: true, dataType: "date", columnClass: ""  },      
      { name: "Handling", prop: "handling", title: "Handling", visible: true, dataType: null, columnClass: ""  },
      { name: "Misc", prop: "misc", title: "Misc", visible: true, dataType: null, columnClass: ""  },
      { name: "State", prop: "state", title: "State", visible: true, dataType: null, columnClass: ""  },
      { name: "License", prop: "license", title: "License", visible: true, dataType: null, columnClass: ""  }
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    const fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s.dataType,
        hidden: !s.visible,
        expand: {
          component: DhpMvrCustomRowComponent
        },
        columnClass: (row, col) => {
          return s.columnClass;
        },
        render: row => {
          if (s.dataType === "date") {
            return this.datePipe.transform(row[s.prop], 'MM/dd/yyyy');
          } else if(s.prop === "details"){
            return '<span class="row-toggler"><i class="fa fa-bars"></i></span>'
          }else {
            return row[s.prop];
          }
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.ordersTableConfig = {
      settings: settings,
      fields: fields,
      data: orderRecords,
      expandable: true,
      expand: {
        type: 'dhpMvrCustomRowComponent'
      },
      noRecordsLabel: 'No Orders Records'
    }
    this.gtOptions = (orderRecords.length > 1 ? 
      { 
        numberOfRows: orderRecords.length, rowExpandInitialState: false, rowExpandInitialComponent: 
        { 
          component: DhpMvrCustomRowComponent, data: (row) => row.ordersExtended 
        }
      } 
        : 
      { 
        rowExpandInitialState: false, rowExpandInitialComponent: 
        { 
          component: DhpMvrCustomRowComponent, data: (row) => row.ordersExtended 
        } 
      });
  }

}
