//Angular
import { Component, Inject, OnInit, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
//Third Party
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
//App
import { LineOfBusinessInfo, DataGridInfo, DataGridFilterInfo, DataGridFilterType } from "../classes-and-interfaces/classes-and-interfaces.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { ErrorModalService } from "../error-modal/error-modal-service.component";
import { AlertModalService } from "../alert-modal/alert-modal-service.component";
import { DataGridAdminFilterModalComponent } from '../data-grid-admin-filter-modal/data-grid-admin-filter-modal.component';

@Component({
  selector: 'app-data-grid-admin-edit',
  templateUrl: './data-grid-admin-edit.component.html',
  styleUrls: ['./data-grid-admin-edit.component.scss']
})
export class DataGridAdminEditComponent implements OnInit, OnDestroy {
  private routeDataGridId: any;
  dataGridId: string = "";
  dataGridObject: DataGridInfo = new DataGridInfo();
  dataGridFilterArr: Array<DataGridFilterInfo>;
  dataGridFilterObjectSelected: DataGridFilterInfo;
  baseUrl: string;
  dataGridLoading: boolean = true;
  isNewDataGrid: boolean = false;
  dataGridNotFound: boolean = false;
  dataGridLineOfBusinessChoices: Array<any>;
  dataGridFilterOrderChoices: Array<any>;
  dataGridFilterTypeChoices: Array<any>;
  dataGridFilterDateRangeChoices: Array<any>;
  dataGridFilterArrChoices: Array<any>;
  objValidationFail: object = {};
  objFilterValidationFail: object = {};
  returnToWidgetId: string = "";

  constructor(private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private snackBar: MatSnackBar,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private alertService: AlertModalService,
    public dialog: MatDialog
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void {
    this.routeDataGridId = this.route.params.subscribe(params => {
      this.dataGridId = params['dataGridId'];
      this.returnToWidgetId = params['returnToWidgetId'];
      if (this.dataGridId.length > 0) {
        this.initAll(+this.dataGridId);
      } else {
        //this.widgetObject = new WidgetInfo();
        this.isNewDataGrid = true;
        this.initAll(null);
      }
    });
  }

  private getDataGridInfo(dataGridId: number) {
    let params = new HttpParams();
    params = params.append('DataGridId', dataGridId.toString());

    this.http.get(this.baseUrl + 'api/AdminDataGrid/GetById', {
      params: params
    }).subscribe({
      next: (data) => {
        this.dataGridObject = data as DataGridInfo;
        this.dataGridObject.lineOfBusinessIdString = this.dataGridObject.lineOfBusinessId.toString();
        if (!this.dataGridObject) {
          this.dataGridLoading = false;
          this.dataGridNotFound = true;
        } else {
          this.dataGridLoading = false;
        }
      },
      error: (err: HttpErrorResponse) => {
        if (err.status != 403)
          this.errorService.setErrorObject(err.error);
      }
    })
  }

  getDataGridFilterInfo(dataGridId: number) {
    let params = new HttpParams();
    params = params.append('DataGridId', dataGridId.toString());

    this.http.get(this.baseUrl + 'api/AdminDataGrid/GetDataGridFiltersByDataGridId', {
      params: params
    }).subscribe({
      next: (data) => {
        this.dataGridFilterArr = data as Array<DataGridFilterInfo>;
        this.dataGridFilterArrChoices = this.transformFilterArrayChoices(this.dataGridFilterArr);
        this.dataGridFilterArr.forEach((item: DataGridFilterInfo, index: number) => {
          item.dataGridFilterTypeIdString = item.dataGridFilterTypeId.toString();
          item.dataGridFilterRangeTypeIdString = item.dataGridFilterRangeTypeId.toString();
          item.filterOrderString = item.filterOrder.toString();
          item.dataGridFilterIdDependentOnIdString = item.dataGridFilterIdDependentOn ? item.dataGridFilterIdDependentOn.toString() : "0";
          item.dataGridFilterIdDependentOn = item.dataGridFilterIdDependentOn ? item.dataGridFilterIdDependentOn : 0;
          item.filterType = this.dataGridFilterTypeChoices?.filter(f => (f.value == item.dataGridFilterTypeId))[0].label;
        })
      },
      error: (err: HttpErrorResponse) => {
        if (err.status != 403)
          this.errorService.setErrorObject(err.error);
      }
    })
  }

  getDataGridFilterTypeChoices() {
    let obs: Observable<Object> = this.http.get(this.baseUrl + 'api/AdminDataGrid/GetFilterTypes');

    obs.subscribe({
      next: (data) => {
        this.dataGridFilterTypeChoices = this.transformFilterTypeChoices(<Array<any>>data);
      },
      error: (err) => this.errorService.setErrorObject(err.error)
    }
    );
  }

  getDataGridDateFilterRaneChoices() {
    let obs: Observable<Object> = this.http.get(this.baseUrl + 'api/AdminDataGrid/GetFilterRangeTypes');

    obs.subscribe({
      next: (data) => {
        this.dataGridFilterDateRangeChoices = this.transformFilterDateRangeChoices(<Array<any>>data);
      },
      error: (err) => this.errorService.setErrorObject(err.error)
    }
    );
  }

  private transformFilterArrayChoices(tempin: Array<DataGridFilterInfo>): Array<any> {
    const tempout: Array<any> = [];
    tempin.forEach((item: DataGridFilterInfo, index: number) => {
      const tempobj = {
        value: item.dataGridFilterId.toString(),
        label: item.displayName
      };
      tempout.push(tempobj);
    })
    return tempout;
  }

  private transformFilterTypeChoices(data: Array<any>): Array<any> {
    const tempin: Array<DataGridFilterType> = data as Array<DataGridFilterType>;
    const tempout: Array<any> = [];
    tempin.forEach((item: DataGridFilterType, index: number) => {
      const tempobj = {
        value: item.dataGridFilterTypeId.toString(),
        label: item.filterTypeName
      };
      tempout.push(tempobj);
    })
    return tempout;
  }

  private transformFilterDateRangeChoices(data: Array<any>): Array<any> {
    const tempin: Array<DataGridFilterType> = data as Array<DataGridFilterType>;
    const tempout: Array<any> = [];
    tempin.forEach((item: any, index: number) => {
      const tempobj = {
        value: item.dataGridFilterRangeTypeId.toString(),
        label: item.rangeType
      };
      tempout.push(tempobj);
    })
    return tempout;
  }

  private initAll(dataGridId: number) {
    this.getDataGridFilterTypeChoices();
    this.getDataGridDateFilterRaneChoices();

    let obs: Observable<Object> = this.http.get(this.baseUrl + 'api/UserFilter/LineOfBusinesses');

    obs.subscribe({
      next: (data) => {
        this.dataGridLineOfBusinessChoices = this.transformLineOfBusinessChoices(<Array<any>>data);
        if (!this.isNewDataGrid) {
          this.getDataGridInfo(dataGridId);
          this.getDataGridFilterInfo(dataGridId);
        } else {
          this.dataGridLoading = false;
        }
      },
      error: (err) => this.errorService.setErrorObject(err.error)
    }
    );
  }

  private transformLineOfBusinessChoices(data: Array<any>): Array<any> {
    const tempin: Array<LineOfBusinessInfo> = data as Array<LineOfBusinessInfo>;
    const tempout: Array<any> = [];
    tempin.forEach((item: LineOfBusinessInfo, index: number) => {
      const tempobj = {
        value: item.lineOfBusinessId.toString(),
        label: item.lineOfBusinessName
      };
      tempout.push(tempobj);
    })
    return tempout;
  }

  private transformStoredProcedureChoices(data: Array<any>): Array<any> {
    const tempout: Array<any> = [];

    return tempout;
  }

  private validateDataGrid(): boolean {
    let valid = true;
    const arrToValidate = [
      "title",
      "description",
      "dataSourceSql",
      "lineOfBusinessIdString"
    ];

    this.objValidationFail = {};

    arrToValidate.forEach((fld) => {
      if ((!this.dataGridObject[fld]) || (this.dataGridObject[fld].length <= 0)) {
        valid = false;
        this.objValidationFail[fld] = true;
      }
    });
    return valid;
  }

  private validateDataGridFilter(dataGridFilterObjectSelected: DataGridFilterInfo): boolean {
    let valid = true;
    let arrToValidate = [
      "displayName",
      "dataGridSpInputParameter",
      "dataGridFilterTypeIdString",
      "filterOrderString"
    ];
    if ((dataGridFilterObjectSelected.dataGridFilterTypeIdString == '2') || (dataGridFilterObjectSelected.dataGridFilterTypeIdString == '5')) {
      arrToValidate = arrToValidate.concat(["dataSourceSql", "textField", "valueField"]);
    }

    if (dataGridFilterObjectSelected.dataGridFilterTypeIdString == '1') {
      arrToValidate = arrToValidate.concat(["dataGridFilterRangeTypeIdString"]);
    }

    if (dataGridFilterObjectSelected.dataGridFilterTypeIdString == '8') {
      arrToValidate = arrToValidate.concat(["dataSourceSql", "textField", "valueField", "dataGridFilterIdDependentOnIdString"]);
    }

    this.objFilterValidationFail = {};

    arrToValidate.forEach((fld) => {
      if ((!this.dataGridFilterObjectSelected[fld]) || (this.dataGridFilterObjectSelected[fld].length <= 0)) {
        valid = false;
        this.objFilterValidationFail[fld] = true;
      }
    });
    return valid;
  }

  saveDataGrid() {
    if (!this.validateDataGrid()) {
      let strErr = "Please fill in all required values";
      this.errorService.setErrorObject({ message: strErr });
    } else {
      this.dataGridObject.lineOfBusinessId = +this.dataGridObject.lineOfBusinessIdString;
      if (this.isNewDataGrid) {
        this.addDataGrid(this.dataGridObject);
      } else {
        this.updateDataGrid(this.dataGridObject);
      }
    }
  }

  saveDataGridFilter(dataGridFilterObjectSelected: DataGridFilterInfo) {
    if (!this.validateDataGridFilter(dataGridFilterObjectSelected)) {
      let strErr = "Please fill in all required values";
      this.errorService.setErrorObject({ message: strErr });
    } else {
      dataGridFilterObjectSelected.dataGridFilterTypeId = +dataGridFilterObjectSelected.dataGridFilterTypeIdString;
      dataGridFilterObjectSelected.dataGridFilterRangeTypeId = +dataGridFilterObjectSelected.dataGridFilterRangeTypeIdString;
      dataGridFilterObjectSelected.filterOrder = +dataGridFilterObjectSelected.filterOrderString;
      dataGridFilterObjectSelected.dataGridFilterIdDependentOn = +dataGridFilterObjectSelected.dataGridFilterIdDependentOnIdString;
      if (!dataGridFilterObjectSelected.dataGridFilterId) {
        this.addDataGridFilter(dataGridFilterObjectSelected);
      } else {
        this.updateDataGridFilter(dataGridFilterObjectSelected);
      }
    }
  }

  private addDataGrid(data: DataGridInfo) {
    this.http.put(this.baseUrl + 'api/AdminDataGrid/AddGrid', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } })
      .subscribe({
        next: () => {
          this.snackBar.open('Report Added', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: 'success-snackbar'
          });
          this.exitDataGridEdit();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
        }
      }
      )
  }

  private updateDataGrid(data: DataGridInfo) {
    this.http.post(this.baseUrl + 'api/AdminDataGrid/UpdateGrid', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } })
      .subscribe({
        next: () => {
          this.snackBar.open('Report Updated', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: 'success-snackbar'
          });
          this.exitDataGridEdit();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
        }
      }
      )
  }

  private updateDataGridFilter(data: DataGridFilterInfo) {
    this.loadingSpinnerService.show();
    this.http.post(this.baseUrl + 'api/AdminDataGrid/UpdateGridFilter', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } })
      .subscribe({
        next: () => {
          this.snackBar.open('Filter Updated', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: 'success-snackbar'
          });
          this.getDataGridFilterInfo(+this.dataGridId);
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      }
      )
  }

  private addDataGridFilter(data: DataGridFilterInfo) {
    this.loadingSpinnerService.show();
    if (!data.dataGridFilterRangeTypeId)
      data.dataGridFilterRangeTypeId = 0;

    this.http.put(this.baseUrl + 'api/AdminDataGrid/AddGridFilter', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } })
      .subscribe({
        next: () => {
          this.snackBar.open('Filter Added', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: 'success-snackbar'
          });
          this.getDataGridFilterInfo(+this.dataGridId);
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      }
      )
  }

  exitDataGridEdit() {
    if (this.returnToWidgetId && (this.returnToWidgetId.length > 0)) {
      this.router.navigate(['/admin/widgetedit', this.returnToWidgetId]);
    } else {
      //this.router.navigateByUrl('datagridadminlist');
      this.router.navigate(['/admin/datagridadminlist']);
    }
    this.returnToWidgetId = "";
  }

  openDataGridFilterModal(dataGridFilterObject: DataGridFilterInfo) {
    if (this.isNewDataGrid) {
      // cant create new filters for an unsaved new data grid -
      // dataGridId not yet defined
      this.snackBar.open('Please save this new Report before attempting to create filters.', 'Ok', {
        horizontalPosition: 'end',
        verticalPosition: 'top',
        duration: 5000,
        panelClass: 'warning-snackbar'
      });
    } else {
      // create list of filterOrder choices
      let lastIndex = this.dataGridFilterArr.map(item => {
        return item.filterOrder;
      }).reduce(function (a, b) {
        return Math.max(a, b);
      }, 0);
      this.dataGridFilterOrderChoices = Array.from(Array(lastIndex + 2).keys()).map(c => {
        var obj = {};
        obj["value"] = c.toString();
        obj["label"] = c.toString();
        return obj;
      });

      if (dataGridFilterObject) {
        //make copy of filter object for editing
        this.dataGridFilterObjectSelected = Object.assign({}, dataGridFilterObject);
      } else {
        // create new filter object and set dataGridId and filterOrder;
        this.dataGridFilterObjectSelected = new DataGridFilterInfo();
        this.dataGridFilterObjectSelected.dataGridId = +this.dataGridId;
        // set filterOrder to last entry
        this.dataGridFilterObjectSelected.filterOrder = lastIndex + 1;
        this.dataGridFilterObjectSelected.filterOrderString = this.dataGridFilterObjectSelected.filterOrder.toString();
      }
      const dialogRef = this.dialog.open(DataGridAdminFilterModalComponent, {
        data: {
          dataGridFilter: this.dataGridFilterObjectSelected,
          filterTypeChoices: this.dataGridFilterTypeChoices,
          filterOrderChoices: this.dataGridFilterOrderChoices,
          reportFilters: this.dataGridFilterArrChoices,
          objFilterValidationFail: this.objFilterValidationFail,
          filterDateRangeChoices: this.dataGridFilterDateRangeChoices
        },
        width: '30em',
        height: '31em',
      });

      dialogRef.afterClosed().subscribe((data: any) => {
        if (data) {
          this.saveDataGridFilter(data.dataGridFilter)
        }
      })
    }
  }

  closeDataGridFilterModal() {
    this.dataGridFilterObjectSelected = null;
  }

  removeDataGridFilter(dataGridFilterId: number) {
    let params = new HttpParams();
    params = params.append('DataGridFilterId', dataGridFilterId.toString());

    this.loadingSpinnerService.show();
    this.http.delete(this.baseUrl + 'api/AdminDataGrid/DeleteGridFilter', {
      params: params
    }).subscribe({
      next: () => {
        // update filter table
        this.getDataGridFilterInfo(+this.dataGridId);
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    }
    )
  }

  openDataGridPerClientSettings() {
    if (this.dataGridId && (this.dataGridId.length > 0)) {
      this.router.navigate(['/admin/reportperclientedit', this.dataGridId.toString()]);
    } else {
      this.alertService.setAlertObject({ title: 'Please Save New Report', message: 'You must save this new Report before creating per-client settings.' });
    }
  }

  ngOnDestroy() {
    this.routeDataGridId.unsubscribe();
  }
}
@Pipe({
  name: 'sortDataGridFilters'
})
export class SortDataGridFiltersPipe implements PipeTransform {

  transform(filterItems: Array<DataGridFilterInfo>): Array<DataGridFilterInfo> {

    // Check if is not null
    if (!filterItems) return filterItems;

    return filterItems.sort((a: DataGridFilterInfo, b: DataGridFilterInfo) => {
      // sort ascending 
      return a.filterOrder > b.filterOrder ? 1 : -1;
    })
  }

}

