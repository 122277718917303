<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div >
  <mat-sidenav-container class="example-container navbar-dark dashboardFilterPanelActionBar div_top_hypers">
    <mat-sidenav mode="side">Sidenav content</mat-sidenav>
    <ul class="nav navbar-nav nav-flex-icons ml-auto inlineStyle">
      <li class="nav-item inlineStyle">
        <a (click)="onPrint()">
          <span class='fa fa-print'></span> Print
        </a>
      </li>
      <li class="nav-item inlineStyle">
        <a (click)="onExcel()">
          <span class='fa fa-file-excel-o'></span> Export to Excel
        </a>
      </li>
      <li class="nav-item inlineStyle">
        <a (click)="onEmail()">
          <span class='fa fa-envelope'></span>
          Export to Email
        </a>
      </li>
    </ul>
  </mat-sidenav-container>
  <app-data-grid-export-email [lineOfBusiness]="lineOfBusinessId"></app-data-grid-export-email>

  <H3>DQ File Management</H3>
  <div *ngIf="rowsRaw?.length > 0">
    <app-data-table
      #myTable
      [columns]="configObject.fields"
      [data]="configObject.data"
      [showPagination]="true"
      (triggerSortChange)="sortChange($event)"
    ></app-data-table>
    <div id="reportFooter">
      <div class="left"> Fleet Response Confidential</div>
      <div class="right"> Report run {{today | date :'short' }}</div>
    </div>
  </div>
  <div *ngIf="!rowsRaw || rowsRaw?.length == 0">
    No results
  </div>
</div>