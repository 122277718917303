import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { GridsterConfig, GridsterItemComponent, GridsterComponent } from 'angular-gridster2';
import { DashboardGridsterItem } from '../classes-and-interfaces/classes-and-interfaces.component';
import { Subscription } from 'rxjs';
import { DashboardService } from '../dashboard-service/dashboard-service.component';
import { LineOfBusinessService } from '../line-of-business-service/line-of-business-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { compactTypes } from 'angular-gridster2/lib/gridsterConfig.interface';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-dashboard-grid',
  templateUrl: './dashboard-grid.component.html',
  styleUrls: ['./dashboard-grid.component.css']
})

export class DashboardGridComponent implements OnInit, OnDestroy, AfterViewInit {
  private routewidget: any;
  @ViewChildren('divDashboardGridsterContainer') dashboardGridsterContainer: QueryList<ElementRef>;
  @ViewChildren(GridsterItemComponent) gridsterItems: QueryList<GridsterItemComponent>;
  @ViewChild(GridsterComponent) gridsterComponent: GridsterComponent;
  private gridItemWidth = 333;
  private gridItemWidthWithMargin = 350;
  private gridItemHeight = 500;
  private gridItemHeightWithMargin = 525;
  // default height of gridster container to 3 times tile height
  gridsterContainerHeight: number = this.gridItemHeightWithMargin * 3;

  // for gridster
  gridsterOptions: GridsterConfig;
  gridsterDashboard: Array<DashboardGridsterItem>;
  gridsterDashboardSubscription: Subscription;
  dashboardAutoArrangeSubscription: Subscription;
  lineOfBusinessSelected: number;
  lineOfBusinessSubscription: Subscription;
  hideThisPanel = true;
  hideThisPanelTemp = false;
  compactTypeDefault: compactTypes = 'none';
  // compactTypeCompact: compactTypes = 'compactUp&Left';
  autoArrange = false;

  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private lineOfBusinessService: LineOfBusinessService,
    private loadingSpinnerService: LoadingSpinnerService,
  ) {

  }

  ngOnInit() {

    this.routewidget = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        // alert(event.urlAfterRedirects);
        this.hideThisPanel = !((event.urlAfterRedirects.indexOf('statcenter') >= 0) || (event.urlAfterRedirects.indexOf('home') >= 0));
        this.recalcGridDimensions();
      }
    });

    // subscribe to changes in Line of Business
    this.lineOfBusinessSubscription = this.lineOfBusinessService.lineOfBusinessSelected$.subscribe(
      lineOfBusinessSelected => {
        const lobNew = lineOfBusinessSelected as number;
        if (
            lobNew !== this.lineOfBusinessSelected &&
            lobNew !== 0 && this.lineOfBusinessSelected &&
            this.router.url.indexOf('statcenter') > -1) {
          // changing line of business, hide & show spinner until gridsterDashboard updates
          this.hideThisPanelTemp = true;
          this.recalcGridDimensions();
          this.loadingSpinnerService.show();
        }
        this.lineOfBusinessSelected = lobNew;
      }
    );

    // subscribe to dashboard updates
    this.gridsterDashboardSubscription = this.dashboardService.gridsterDashboard$.subscribe(
      gridsterDashboard => {
        this.gridsterDashboard = gridsterDashboard as Array<DashboardGridsterItem>;
        this.updateGridsterContainerHeight();
        this.hideThisPanelTemp = false;
        this.recalcGridDimensions();
        this.loadingSpinnerService.hide();
      }
    );

    this.dashboardAutoArrangeSubscription = this.dashboardService.gridsterAutoArrange$.subscribe(
      flag => {
        this.autoArrange = true;
      }
    );
    // gridster
    this.gridsterOptions = {
      gridType: 'fixed',
      compactType: this.compactTypeDefault,
      fixedColWidth: this.gridItemWidth,
      fixedRowHeight: this.gridItemHeight,
      keepFixedWidthInMobile: true,
      keepFixedHeightInMobile: true,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      swap: false,
      draggable: {
        enabled: true,
        ignoreContent: true,
        dragHandleClass: 'drag-handler',
        start: this.dragStart,
        stop: this.dragStop
      },
      minCols: 3,
      // maxCols: 3,
      minRows: 2,
      maxItemCols: 2,
      maxItemRows: 2,
      emptyCellDragMaxCols: 1, // limit empty cell drag max cols
      emptyCellDragMaxRows: 1, // limit empty cell drag max rows
      itemRemovedCallback: this.itemRemovedCallback,
      itemChangeCallback: this.itemChange,
      pushItems: true,
      disablePushOnDrag: false,
      // displayGrid: 'always',
      resizable: {
        // delayStart: 0, // milliseconds to delay the start of resize, useful for touch interaction
        enabled: false // enable/disable resizable items
        /*
        handles: {
          s: false,
          e: false,
          n: false,
          w: false,
          se: true,
          ne: false,
          sw: false,
          nw: false
        }
        */
      }
    };
  }
  ngAfterViewInit() {
    // make sure correct max number of columns is calculated
    // whenever dashboardGridsterContainer is initialized
    this.dashboardGridsterContainer.changes.subscribe((gc: QueryList<ElementRef>) => {
      if (gc.length > 0) {
        setTimeout(() => {
          this.onDashboardWindowResize(gc.first.nativeElement.getBoundingClientRect());
        }, 0);

      }
    });
  }

  private itemRemovedCallback = (function (item, itemComponent) {
    //console.info('itemRemoved', item, itemComponent);
    if (this.autoArrange) {
      // auto arrange remaining tiles when one is removed
      this.gridsterItems.filter(i => i.item.dashboardTile.tileId != item.dashboardTile.tileId).forEach(i => i.gridster.autoPositionItem(i));
      this.autoArrange = false;
    }
  }).bind(this);

  private itemChange = (function (item, itemComponent) {
    //console.info('itemChanged', item, itemComponent);
    const dbitem = item as DashboardGridsterItem;

    this.dashboardService.updateTilePosition(dbitem.dashboardTile.tileId, dbitem.x, dbitem.y).subscribe(data => {
      // sync x and y coordinates from gridster item to tile
      dbitem.dashboardTile.positionX = dbitem.x;
      dbitem.dashboardTile.positionY = dbitem.y;
      this.updateGridsterContainerHeight();
    },
      (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
      });
  }).bind(this);

  private updateGridsterContainerHeight() {
    // update height of gridster container to max row plus 2
    if (this.gridsterDashboard) {
      const maxRow = Math.max.apply(Math, this.gridsterDashboard.map(function (o) { return o.y; })) + 1;
      this.gridsterContainerHeight = this.gridItemHeightWithMargin * (maxRow + 2);
    }
  }

  private dragStart = (function (item, itemComponent) {
    // on drag start, set isDropTarget on all tiles to true.
    // this will result in the powerBI container div getting overlayed with
    // a transparent element that will prevent iFrame interference with the drop
    this.gridsterDashboard.forEach((item: DashboardGridsterItem, index: number) => {
      item.dashboardTile.isDropTarget = true;
    });
  }).bind(this);

  private dragStop = (function (item, itemComponent) {
    //console.info('dragStop', item, itemComponent);
    this.gridsterDashboard.forEach((item: DashboardGridsterItem, index: number) => {
      item.dashboardTile.isDropTarget = false;
    });
  }).bind(this);

  onDashboardWindowResize(event) {
    //recalculate number of gridster columns based on container size
    const maxColsOld = this.gridsterOptions.maxCols;
    const maxCols = Math.floor(event.width / this.gridItemWidthWithMargin);
    if ((maxCols > 1) && (maxCols != maxColsOld)) {
      this.gridsterOptions.maxCols = maxCols;
      this.gridsterOptions.minCols = maxCols;
      this.gridsterOptions.api.optionsChanged();

      if (maxColsOld) {
        // if changing number of columns,do an auto rearrange of tiles
        this.gridsterItems.forEach(i => i.gridster.autoPositionItem(i));
      }
    }
  }

  private recalcGridDimensions() {
    // purpose of this is to prevent automatic application of
    // mobile style when the grid container is hidden
    if (this.gridsterComponent && this.gridsterComponent.options && this.gridsterComponent.el.classList.contains('mobile')) {
      if ((this.hideThisPanel || this.hideThisPanelTemp)) {
        this.gridsterComponent.options.mobileBreakpoint = 0;
        this.gridsterComponent.calculateLayout$.next();
      } else {
        this.gridsterComponent.options.mobileBreakpoint = 640;
        this.gridsterComponent.calculateLayout$.next();
      }
    }
  }

  ngOnDestroy() {
    this.gridsterDashboardSubscription.unsubscribe();
    this.dashboardAutoArrangeSubscription.unsubscribe();
    this.routewidget.unsubscribe();
  }
}
