//Angular
import { Component, OnInit, Inject, Input, ViewChild, AfterViewInit, ViewChildren, AfterViewChecked, QueryList } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UntypedFormControl, Validators } from '@angular/forms';
//Third Party
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDatepicker } from '@angular/material/datepicker';
//APP
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'dhp-assign-points',
  templateUrl: './dhp-assign-points.component.html',
  styleUrls: ['./dhp-assign-points.component.scss'],
})
export class DHPAssignPointsComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('dateOfIncidentPicker') dateOfIncidentPicker: MatDatepicker<Date>;
  @ViewChildren('expirationDatePicker') expirationDatePickerList: QueryList<MatDatepicker<Date>>;
  @Input() modalHeight: string;
  @Input() modalInput: any;
  // private variables that are only shared with subscribers that import the type
  baseUrl: string;
  infractionOptions: Array<any>;
  expiresTypeOptions: Array<any>;
  pointsAssignment: DriverPointsAssignment;
  reportSelection: null;
  dirty = false;
  expirationDate: string;
  dateOfIncident: string;
  formValidated = false;
  pointsPattern = "^[-]?[0-9]+[0-9]*$";
  expireLabelInvalid = true;
  // date vars for tomorrow and today, with time stripped out
  // correct value for tomorrow is set in constructor
  actualDate = new Date();
  minDate =  new Date();
  expirationDatePicker: MatDatepicker<Date>;
  infractionControl = new UntypedFormControl('', [Validators.required]);
  pointsControl = new UntypedFormControl('', [Validators.required,Validators.pattern(this.pointsPattern)]);
  dateOfIncidentControl = new UntypedFormControl('', [Validators.required]);
  expiresTypeControl = new UntypedFormControl('', [Validators.required]);
  expiresControl = new UntypedFormControl('', []);

  allControls: Array<UntypedFormControl> = [
    this.infractionControl,
    this.pointsControl,
    this.dateOfIncidentControl,
    this.expiresTypeControl,
    this.expiresControl
  ];
 
  // the overloaded constructor for the controller
  constructor(private readonly dhpService: DriverHistoryProfileService,
    private readonly http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private readonly onPremService: OnPremDriverService,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly snackBar: MatSnackBar,
    private readonly errorService: ErrorModalService)
  {
    this.baseUrl = baseUrl;
    this.minDate.setDate(this.actualDate.getDate() +1);
  }

  // angular on intialization event
  ngOnInit() {
    this.pointsAssignment = new DriverPointsAssignment(this.modalInput.driverId);
    this.getOptions();

    // listen for changes in date controls
    this.expiresControl.valueChanges.subscribe(val => {
      this.expireLabelInvalid = this.expiresTypeControl.invalid || this.expiresControl.invalid;
    });
  }

  ngAfterViewInit(): void {
    this.dateOfIncidentPicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.dateOfIncidentPicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.dateOfIncidentPicker.select(event.value);
            this.dateOfIncidentPicker.close();
        })
    },0)
    })
  }

  ngAfterViewChecked(): void {
    if (!this.expirationDatePicker && this.expirationDatePickerList?.first) {
      this.expirationDatePicker = this.expirationDatePickerList.first;
      this.expirationDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.expirationDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.expirationDatePicker.select(event.value);
            this.expirationDatePicker.close();
          })
        }, 0)
      })
    }
  }

  private getOptions() {
    this.expiresTypeOptions = [
      { value: true, label: 'Expire With MVR' },
      { value: false, label: 'Set Date of Expiration' }
    ];
    this.loadingSpinnerService.show();
    this.onPremService.get(`lookup/additionalPointInfractionTypes?clientCode=${this.modalInput.clientCode}`)
      .subscribe({
        next: (data) => {
          const infractionOptions = data as Array<AuxiliaryPointInfractionType>;
          this.infractionOptions = infractionOptions.map(i => { return { value: i.id, label: i.description } });
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
  }

  onChangeExpireType(event: any) { 
    if (!event.value) {
      this.expiresControl.setValidators([Validators.required]);
      this.expiresControl.updateValueAndValidity();
    } else {
      this.expiresControl.setValidators([]);
      this.expiresControl.setValue(null);
      this.pointsAssignment.expirationDate = null;
    }
    this.expireLabelInvalid = this.expiresTypeControl.invalid || this.expiresControl.invalid;
  }

  private validateForm(): boolean {
    let isOK = true;

    if (this.allControls.filter(c => (c.invalid)).length > 0) {
      this.errorService.setErrorObject({ message: 'Missing or Invalid Fields.' });
      isOK = false;
    }

    return isOK;
  }

  submit() {

    if (this.validateForm()) {
      const postData = this.pointsAssignment;

      this.loadingSpinnerService.show();
      this.onPremService.post('riskHistory/additionalPoints',
        JSON.stringify(postData)
      ).subscribe({
        next: (data) => {
          // signal that driver profile data needs to be refreshed
          this.dhpService.setRefreshDriverProfileAuxiliaryPointsData(this.modalInput.driverId);
          // notify the modal window to close
          this.dhpService.notifyModalClose();

          this.snackBar.open('Assign Points Completed.', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: 'success-snackbar'
          });

          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    }
  }

  cancel() {
    // notify the modal window to close
    this.dhpService.notifyModalClose();

  }

  // sleeps for a certain number of milliseconds
  sleep(milliseconds: number) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }


}

export class AuxiliaryPointInfractionType {
  id: number;
  description: string;
}

export class DriverPointsAssignment {
  driverId: number;
  auxiliaryPointInfractionTypeId: number;
  points: number;
  infractionDate: string;
  expireWithMvr: boolean;
  expirationDate: string;
  noteText: string;

  constructor(driverId: number) {
    return {
      driverId: driverId,
      auxiliaryPointInfractionTypeId: null,
      points: null,
      infractionDate: null,
      expireWithMvr: null,
      expirationDate: null,
      noteText: null
    }
  }
}
