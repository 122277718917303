//Angular
import { Component, Inject, OnInit, OnDestroy, ViewChild, AfterViewInit, ViewChildren, QueryList, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UntypedFormControl, Validators, ValidationErrors } from '@angular/forms';
import { DatePipe } from '@angular/common';
//Third Party
import { Subscription, Observable, forkJoin, combineLatest, of } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { MatDatepicker } from '@angular/material/datepicker';
//App
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { UserRightsService } from '../../components/user-rights-service/user-rights-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { 
  UserRightsInfo, 
  DriverProfile, 
  DriverExtendedInfo, 
  DriverClientSettings, 
  DriverTrainingCategory, 
  DriverTrainingCourse 
} from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { SubmitBatchService } from '../shared/batch-assignment/submit-batch.service';
import { Batch } from '../shared/batch-assignment/batch';
import { 
  DriverFormInfo, 
  DriverFormFieldSettings, 
  DriverBatchItem, DriverState, 
  DriverCounty, DriverHierarchy, 
  DriverLanguage, DriverRelationshipType, 
  EmployeeDriverInfo, 
  EntityDriverResponseObject 
} from './safety-employee-driver-form';
import { MatSelectOption } from '../../shared/models/mat-select-option.model';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';

@Component({
  selector: 'app-safety-employee-driver-form',
  templateUrl: './safety-employee-driver-form.component.html',
  styleUrls: ['./safety-employee-driver-form.component.scss']
})
export class SafetyEmployeeDriverFormComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild("groupSelect", { static: false }) groupSelect: MatSelect;
  @ViewChild("licenseStateSelect", { static: false }) licenseStateSelect: MatSelect;
  @ViewChild("driverLocationsSelect", { static: false }) driverLocationsSelect: MatSelect;
  @ViewChild("trainingCategorySelect", { static: false }) trainingCategorySelect: MatSelect;
  @ViewChildren('hireDatePicker') hireDatePickerList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('expirationDatePicker') expirationDatePickerList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('licenseDateOfBirthPicker') licenseDateOfBirthPickerList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('trainingDueDatePicker') trainingDueDatePickerList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('confirmlicenseDateDuePicker') confirmlicenseDateDuePickerList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('licenseUploadDateDuePicker') licenseUploadDateDuePickerList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('policySignOffDatePicker') policySignOffDatePickerList: QueryList<MatDatepicker<Date>>;
  hireDatePicker: MatDatepicker<Date>;
  expirationDatePicker: MatDatepicker<Date>;
  licenseDateOfBirthPicker: MatDatepicker<Date>;
  trainingDueDatePicker: MatDatepicker<Date>;
  confirmlicenseDateDuePicker: MatDatepicker<Date>;
  policySignOffDatePicker: MatDatepicker<Date>;
  licenseUploadDateDuePicker: MatDatepicker<Date>;
  routeParamsSub: Subscription;
  obsSubscription: Subscription;
  baseUrl: string;
  isNewDriver = false;
  isEmployeeDriver = false;
  isFilterValid = false;
  driverProfile: DriverProfile = null;
  driverCountry = '';
  driverId: string;
  groupguid: string;
  primaryDriverId: string;
  driverInfo: EmployeeDriverInfo = new EmployeeDriverInfo();
  driverGroup: string;
  _userRights: Array<UserRightsInfo>;
  clientSelectedArray: Array<string>;
  lineOfBusinessId: number;
  formSettings: DriverFormInfo;
  formSettingsDictionary: { [id: string]: DriverFormFieldSettings; } = {};
  formSettingsDictionaryOriginal: { [id: string]: DriverFormFieldSettings; } = {};
  formSettingsDictionaryLoaded = false;
  groupOptions: Array<any> = [];
  filteredGroupOptions: Array<MatSelectOption> = [];
  stateProvinceOptions: Array<any> = [];
  filteredStateOptions: Array<MatSelectOption> = [];
  languageOptions: Array<any> = [];
  countryOptions: Array<any> = [];
  trainingCategoryOptions: Array<any> = [];
  filteredTrainingCategoryOptions: Array<MatSelectOption> = [];
  trainingOptions: Array<any> = [];
  trainingOptionsFiltered: Array<any> = [];
  relationshipTypeOptions: Array<any> = [];
  extendedInfo: Array<DriverExtendedInfo>;
  clientSettings: DriverClientSettings;
  expandGeneralInfo = false;
  expandExtendedInfo = false;
  expandLicenseInfo = false;
  expandLocation = false;
  expandTraining = false;
  expandServices = false;
  openToSection: string;
  returnToTab: string;
  returnToPage: string;
  returnToProfileId: string;
  returnToOriginPage = false;
  canUpload = false;
  showUploadPanel = false;

  // user rights
  canViewDLAndDOB = false;
  canOrderMVR = false;
  canOrderNonEmpMVR = false;
  canAssignTraining = false;
  canMarkDriverAsTOP = false;
  searchText: string;
  categorySearchValue: number;

  // driven by client settings, mostly for showing/hiding fields
  showVerifyLicenseInfo = true;
  showLocationSection = false;
  showLicenseNumberAndState = true;
  showDOTDriver = true;
  showAutoCoverageRequired = true;
  showCOV = true;
  showPolicyTask = false;
  dueDatesLimit = false;
  blockProfileEdit = false;
  showMarkAsTop = false;
  showEmailFields = true;
  showResendLogin = true;
  showResetPassword = true;
  showTrainingSection = true;
  showServicesSection = true;
  showRequestMVR = true;
  showLanguage = true;
  showMonitoring = true;
  showDriverLicenseUpload = false;
  showTemporaryDriverExpirationDate = false;
  disableAssignTraing = false;
  disableDriverCOV = false;
  blockProfileEditMsg = 'Note: This record is pending an MVR result and some fields cannot be changed at this time.';
  dtToday: Date = new Date();
  driverPermTempMsg = '';
  actualDate = new Date();
  maxDate = new Date().setFullYear(this.actualDate.getFullYear() - 7)
  // array of form controls for req fields
  allReqControls: Array<UntypedFormControl> = [];

  readonly driverUpdatedMsg = 'Driver Updated.';
  readonly driverCreatedMsg = 'New Driver Created.';
  readonly dateFormat = 'MM/dd/yyyy';

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly clientSelectionService: ClientSelectionService,
    private readonly lineOfBusinessService: LineOfBusinessService,
    private readonly userRightsService: UserRightsService,
    private readonly onPremDriverService: OnPremDriverService,
    private readonly driverHistoryProfileService: DriverHistoryProfileService,
    private readonly alertService: AlertService,
    private readonly datePipe: DatePipe,
    private readonly http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly errorService: ErrorModalService,
    private readonly submitBatchService: SubmitBatchService,
    private readonly cdref: ChangeDetectorRef
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit() {
    // set line of business to safety if not already initialized to Safety
    this.lineOfBusinessId = 2;
    const lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob !== 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    // get the selected client(s)
    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusinessId);

    const obsComb = combineLatest([this.route.params, this.route.queryParams],
      (params, qparams) => ({ params, qparams }));

    this.routeParamsSub = obsComb.subscribe(ap => {
      this.openToSection = ap.qparams['openToSection'];
      this.returnToTab = ap.qparams['returnToTab'];
      this.returnToPage = ap.qparams['returnToPage'];
      this.returnToProfileId = ap.qparams['returnToProfileId'];
      this.driverId = ap.params['id'];
      this.groupguid = ap.params['groupguid'];
      this.primaryDriverId = ap.params['primarydriverid'];
      if (this.driverId && (this.driverId.length > 0)) {
        this.initAll(this.driverId, true);
      } else {
        this.isNewDriver = true;
        this.initAll(null, true);
      }
    });

  }

  ngAfterViewInit(): void {
    this.groupSelect?._elementRef.nativeElement.addEventListener("keydown", (event) => {
      this.handleSpaceKeyDownEvent(event);
    });

    this.licenseStateSelect?._elementRef.nativeElement.addEventListener("keydown", (event) => {
      this.handleSpaceKeyDownEvent(event);
    });

    this.driverLocationsSelect?._elementRef.nativeElement.addEventListener("keydown", (event) => {
      this.handleSpaceKeyDownEvent(event);
    });

    this.trainingCategorySelect?._elementRef.nativeElement.addEventListener("keydown", (event) => {
      this.handleSpaceKeyDownEvent(event);
    });
  }

  private handleSpaceKeyDownEvent(event: any): void {
    if (event.code === "Space") {
      event.preventDefault();
    }
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  ngAfterViewChecked(): void {
    if (!this.hireDatePicker && this.hireDatePickerList?.first) {
      this.hireDatePicker = this.hireDatePickerList.first;
      this.hireDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.hireDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.hireDatePicker.select(event.value);
            this.hireDatePicker.close();
          })
        }, 0)
      })
    }

    if (!this.expirationDatePicker && this.expirationDatePickerList?.first) {
      this.expirationDatePicker = this.expirationDatePickerList.first;
      this.expirationDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.expirationDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.expirationDatePicker.select(event.value);
            this.expirationDatePicker.close();
          })
        }, 0)
      })
    }

    if (!this.licenseDateOfBirthPicker && this.licenseDateOfBirthPickerList?.first) {
      this.licenseDateOfBirthPicker = this.licenseDateOfBirthPickerList.first;
      this.licenseDateOfBirthPicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.licenseDateOfBirthPicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.licenseDateOfBirthPicker.select(event.value);
            this.licenseDateOfBirthPicker.close();
          })
        }, 0)
      })
    }

    if (!this.trainingDueDatePicker && this.trainingDueDatePickerList?.first) {
      this.trainingDueDatePicker = this.trainingDueDatePickerList.first;
      this.trainingDueDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.trainingDueDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.trainingDueDatePicker.select(event.value);
            this.trainingDueDatePicker.close();
          })
        }, 0)
      })
    }

    if (!this.confirmlicenseDateDuePicker && this.confirmlicenseDateDuePickerList?.first) {
      this.confirmlicenseDateDuePicker = this.confirmlicenseDateDuePickerList.first;
      this.confirmlicenseDateDuePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.confirmlicenseDateDuePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.confirmlicenseDateDuePicker.select(event.value);
            this.confirmlicenseDateDuePicker.close();
          })
        }, 0)
      })
    }

    if (!this.policySignOffDatePicker && this.policySignOffDatePickerList?.first) {
      this.policySignOffDatePicker = this.policySignOffDatePickerList.first;
      this.policySignOffDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.policySignOffDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.policySignOffDatePicker.select(event.value);
            this.policySignOffDatePicker.close();
          })
        }, 0)
      })
    }

    if (!this.licenseUploadDateDuePicker && this.licenseUploadDateDuePickerList?.first) {
      this.licenseUploadDateDuePicker = this.licenseUploadDateDuePickerList.first;
      this.licenseUploadDateDuePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.licenseUploadDateDuePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.licenseUploadDateDuePicker.select(event.value);
            this.licenseUploadDateDuePicker.close();
          })
        }, 0)
      })
    }
  }

  filterGroupOptions(value: string): void {
    const filterValue = value.toLowerCase();
    this.filteredGroupOptions = this.groupOptions.filter(option => option.label.toLowerCase().startsWith(filterValue));
  }

  filterStateOptions(value: string): void {
    const filterValue = value.toLowerCase();
    this.filteredStateOptions = this.stateProvinceOptions.filter(option => option.label.toLowerCase().startsWith(filterValue));
  }

  filterCategoryOptions(value: string): void {
    const filterValue = value.toLowerCase();
    this.filteredTrainingCategoryOptions = this.trainingCategoryOptions.filter(option => option.label.toLowerCase().startsWith(filterValue));
  }

  private initAll(driverId: string, loadAllFormInfo: boolean) {
    let driverProfile: DriverProfile = null;
    if (!this.isNewDriver) {
      // get driver profile first, to get correct client for driver,
      // then get user rights and client
      this.loadingSpinnerService.show();
      this.onPremDriverService.get<DriverProfile>('entity/driverProfile/base/' + driverId).subscribe({
        next: (data) => {
          driverProfile = data;
          this.driverProfile = driverProfile;
          this.driverInfo.enrollInMonitoring = this.driverProfile.isEnrolledInMonitoring

          if (this.driverHistoryProfileService.inActiveDriverRedirect(driverProfile, driverProfile.clientCode, true)) {
            return;
          }

          if (driverProfile.country) {
            this.driverCountry = driverProfile.country;
          }

          if (driverProfile.clientCode !== this.clientSelectedArray[0]) {
            // switch left nav client selection to client for driver
            this.clientSelectionService.setAddClientsByShortName([driverProfile.clientCode], this.lineOfBusinessId);
          }
          if (driverProfile.isEmployee) {
            this.isEmployeeDriver = true;
          }

          this.blockProfileEdit = (this.isNewDriver || (!driverProfile) ? false : driverProfile.blockDriverEdit);

          if (loadAllFormInfo) {
            // get all form info
            this.getAllFormInfo(driverProfile, driverProfile.clientCode);
          } else {
            // get only form info that needs to be updated
            this.updateFormInfoNewDriver(driverProfile, driverProfile.clientCode);
          }
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    } else {
      // set default values
      this.blockProfileEdit = false;
      this.driverInfo.label = (this.primaryDriverId ? 'Add Non-Employee Driver' : 'New Employee Driver');
      this.driverInfo.driverBaseInfo.client = this.clientSelectedArray[0];
      this.driverInfo.driverBaseInfo.groupGuid = this.groupguid;
      this.driverInfo.driverBaseInfo.primaryDriverId = (this.primaryDriverId ? +this.primaryDriverId : null);
      if (!this.primaryDriverId) {
        this.isEmployeeDriver = true;
      }
      this.getAllFormInfo(driverProfile, this.clientSelectedArray[0]);
    }
  }

  private getClientSettings(clientSelected: string) {
    // 2019-11 - get stored client settings from ClientSelectionService observable
    const cSettings = this.clientSelectionService.getClientSettingsObjectValue();
    if (cSettings && cSettings[clientSelected]) {
      const clientSettings = cSettings[clientSelected];
      return of(clientSettings);
    } else {
      // do lookup if client settings not defined for this client
      return this.onPremDriverService.get('lookup/clientSettings/' + clientSelected);
    }
  }

  private getAllFormInfo(driverProfile: DriverProfile, clientSelected: string) {
    const formSettingsEndpoint = (
      this.isEmployeeDriver ? `entity/${clientSelected}/form` : `entity/${clientSelected}/secondaryform`);
    let lookUpLanguageUrl = `lookup/language/${clientSelected}`;
    lookUpLanguageUrl += driverProfile && driverProfile.driverLicenseState ? `/${driverProfile.driverLicenseState}` : '';
    let trainingLessonsCategoriesUrl = `training/lessons/categories/${clientSelected}`;
    trainingLessonsCategoriesUrl += this.driverId ? `/${this.driverId}` : ''
    let trainingLessonsUrl = `training/lessons/${clientSelected}`;
    trainingLessonsUrl += this.driverId ? `/${this.driverId}` : '';

    const arrObs: Array<Observable<Object>> = [
      // get user rights and form options
      this.userRightsService.getUserRights(this.lineOfBusinessId, [clientSelected]),
      this.onPremDriverService.get(formSettingsEndpoint),
      this.onPremDriverService.get(`lookup/states/${clientSelected}`),
      this.onPremDriverService.get(`lookup/hierarchy/${clientSelected}`),
      this.onPremDriverService.get(lookUpLanguageUrl),
      this.onPremDriverService.get(trainingLessonsCategoriesUrl),
      this.onPremDriverService.get(trainingLessonsUrl),
      this.onPremDriverService.get(`lookup/relationshiptypes/${clientSelected}`),
      this.onPremDriverService.get(`lookup/clientReqFields/${clientSelected}`),
      this.getClientSettings(clientSelected),
      this.onPremDriverService.get('lookup/country')
    ];

    // track observable index for parent profile and secondary drivers
    let nextObsIndex = arrObs.length;
    let parentProfileIndex = null;
    let secondaryDriverIndex = null;

    if ((!this.isEmployeeDriver) && this.isNewDriver) {
      // get parent profile if new non-employee driver
      arrObs.push(this.onPremDriverService.get<DriverProfile>(
        `entity/driverProfile/base/${this.driverInfo.driverBaseInfo.primaryDriverId.toString()}`
      ));
      parentProfileIndex = nextObsIndex;
      nextObsIndex++;
    }

    if (this.driverId) {
      // get secondary drivers
      arrObs.push(this.onPremDriverService.get<Array<DriverProfile>>(`entity/${this.driverId}/secondary`));
      secondaryDriverIndex = nextObsIndex;
      nextObsIndex++;
    }

    this.obsSubscription = forkJoin(arrObs).subscribe({
      next: (data) => {
        this.getUserRights(data[0] as Array<UserRightsInfo>);
        this.formSettings = data[1] as DriverFormInfo;
        this.setFormControls(driverProfile);
        this.getStateProvinceOptions(data[2] as Array<DriverState>);
        this.getGroupOptions(data[3] as Array<DriverHierarchy>);
        this.getLanguageOptions(data[4] as Array<DriverLanguage>);
        this.getTrainingCategoryOptions(data[5] as Array<DriverTrainingCategory>);
        this.getTrainingOptions(data[6] as Array<DriverTrainingCourse>);
        this.getRelationshipTypeOptions(data[7] as Array<DriverRelationshipType>);
        this.extendedInfo = data[8] as Array<DriverExtendedInfo>;
        this.clientSettings = data[9] as DriverClientSettings;
        this.getCountryOptions(data[10] as Array<DriverCounty>);

        let parentProfile = null;
        if ((!this.isEmployeeDriver) && parentProfileIndex) {
          // get parent profile if new non-employee driver
          parentProfile = data[parentProfileIndex] as DriverProfile;
        }

        this.processExtendedInfo(parentProfile, this.extendedInfo);
        if (this.driverId && secondaryDriverIndex) {
          this.driverInfo.secondaryDrivers = data[secondaryDriverIndex] as Array<DriverProfile>;
        }

        this.initClientSettings(driverProfile);
        this.initSections();

        if (driverProfile) {
          setTimeout(() => this.initDriverInfo(driverProfile), 0);
        } else {
          // get text for groupguid
          const groupObj = this.groupOptions.filter(g => g.elementGuid === this.groupguid);
          if (groupObj.length > 0) {
            this.driverGroup = groupObj[0].label;
          }
        }

        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private updateFormInfoNewDriver(driverProfile: DriverProfile, clientSelected: string) {
    let lookUpLanguageUrl = `lookup/language/${clientSelected}`;
    lookUpLanguageUrl += driverProfile && driverProfile.driverLicenseState ? `/${driverProfile.driverLicenseState}` : '';
    // updates form settings related to saving and refreshing new driver
    const arrObs: Array<Observable<Object>> = [
      // get user rights and form options
      this.onPremDriverService.get(lookUpLanguageUrl),
      this.onPremDriverService.get<Array<DriverProfile>>(`'entity/${this.driverId}/secondary`)
    ];

    this.obsSubscription = forkJoin(arrObs).subscribe({
      next: (data) => {
        this.getLanguageOptions(data[0] as Array<DriverLanguage>);
        this.driverInfo.secondaryDrivers = data[1] as Array<DriverProfile>;

        if (driverProfile) {
          setTimeout(() => this.initDriverInfo(driverProfile), 0);
        } else {
          // get text for groupguid
          const groupObj = this.groupOptions.filter(g => g.elementGuid === this.groupguid);
          if (groupObj.length > 0) {
            this.driverGroup = groupObj[0].label;
          }
        }

        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private setFormControls(driverProfile: DriverProfile) {
    this.formSettings?.fields.forEach(f => {
      const fkey = f.propertyName.charAt(0).toLowerCase() + f.propertyName.slice(1);
      this.formSettingsDictionary[fkey] = f;
      // store in dictonary of original values in case we need to revert
      this.formSettingsDictionaryOriginal[fkey] = JSON.parse(JSON.stringify(f));
      const isDisabled = (this.blockProfileEdit || f.isReadOnly);
      if (f.isVisible) {
        // add form control info
        if (f.isRequired && (!f.isReadOnly)) {
          this.formSettingsDictionary[fkey].formControl = new UntypedFormControl({ value: '', disabled: isDisabled }, [Validators.required]);
        } else {
          this.formSettingsDictionary[fkey].formControl = new UntypedFormControl({ value: '', disabled: isDisabled }, []);
        }
      }
    });

    if (this.formSettingsDictionary['emailAddress'] && this.formSettingsDictionary['emailAddress'].isVisible) {
      // adjustments for email fields
      let emailValidatorsIsRequired =
        (this.formSettingsDictionary['emailAddress'].isRequired && !this.formSettingsDictionary['emailAddress'].isReadOnly);

      emailValidatorsIsRequired = this.determineIfEmailIsRequired(driverProfile, emailValidatorsIsRequired);

      const emailValidators = (emailValidatorsIsRequired ? [Validators.email] : [Validators.email, Validators.required]);
      this.formSettingsDictionary['emailAddress'].formControl.setValidators(emailValidators);
      this.formSettingsDictionary['emailAddressConfirm'] = {
        propertyName: 'emailAddressConfirm',
        isVisible: this.formSettingsDictionary['emailAddress'].isVisible,
        isReadOnly: this.formSettingsDictionary['emailAddress'].isReadOnly,
        isRequired: this.formSettingsDictionary['emailAddress'].isRequired,
        formControl: new UntypedFormControl(
          { value: '', disabled: this.blockProfileEdit || this.formSettingsDictionary['emailAddress'].isReadOnly },
          emailValidators
        )
      };
    }

    // Honda adjustments
    if (this.livesWithPrimaryRequired) {
      // if lives in household is required, make sure default value is not null
      driverProfile = this.setLivesWithPrimaryDriver(driverProfile);
    }

    this.formSettingsDictionaryLoaded = true;
  }

  private determineIfEmailIsRequired(driverProfile: DriverProfile, emailValidatorsIsRequired: boolean): boolean {
    if ((!driverProfile) || (!driverProfile.emailAddress) || (driverProfile.emailAddress.length <= 0)) {
      // make email fields required and editable if email address is blank
      emailValidatorsIsRequired = true;
      this.formSettingsDictionary['emailAddress'].isRequired = true;
      this.formSettingsDictionary['emailAddress'].isReadOnly = false;
      this.formSettingsDictionary['emailAddress'].formControl = new UntypedFormControl(
        { value: '', disabled: this.blockProfileEdit || this.formSettingsDictionary['emailAddress'].isReadOnly },
        [Validators.email, Validators.required]
      );
    }
    return emailValidatorsIsRequired;
  }

  private livesWithPrimaryRequired(): boolean {
    return this.formSettingsDictionary['livesWithPrimaryDriver'] &&
    this.formSettingsDictionary['livesWithPrimaryDriver'].isVisible &&
    (!this.formSettingsDictionary['livesWithPrimaryDriver'].isReadOnly) &&
    this.formSettingsDictionary['livesWithPrimaryDriver'].isRequired
  }

  private setLivesWithPrimaryDriver(driverProfile: DriverProfile): DriverProfile {
    if ((!driverProfile) || driverProfile.livesWithPrimaryDriver == null) {
      if (!driverProfile) {
        this.driverInfo.driverBaseInfo.livesWithPrimaryDriver = false;
      } else {
        driverProfile.livesWithPrimaryDriver = false;
      }
    }
    return driverProfile;
  }

  private initDriverInfo(driverProfile: DriverProfile) {
    // translate DriverProfile data into driverInfo object
    // calculated properties:
    const middleNameLabel = driverProfile.middleName && driverProfile.firstName.length > 0 ? `${driverProfile.middleName} ` : '';
    const driverNameLabel = `${driverProfile.firstName}${middleNameLabel} ${driverProfile.lastName}`;
    this.driverInfo.label = driverNameLabel;
    this.driverInfo.driverBaseInfo.isActive = driverProfile.isActive;
    this.driverInfo.driverBaseInfo.client = this.getTrimmedValue(driverProfile.clientCode);
    this.driverInfo.driverBaseInfo.groupGuid = this.getTrimmedValue(driverProfile.groupGuid);
    this.driverInfo.driverBaseInfo.dateOfBirth = driverProfile.dateOfBirth === null ? null : new Date(this.datePipe.transform(driverProfile.dateOfBirth, this.dateFormat));
    this.driverInfo.driverBaseInfo.hireDate = driverProfile.hireDate === null ? null : new Date(this.datePipe.transform(driverProfile.hireDate, this.dateFormat));
    this.driverInfo.driverBaseInfo.dotDriver = driverProfile.dotDriver;
    this.driverInfo.driverBaseInfo.requiresAutoCoverage = driverProfile.requiresAutoCoverage;
    this.driverInfo.driverBaseInfo.emailAddress = this.getTrimmedValue(driverProfile.emailAddress);
    this.driverInfo.driverBaseInfo.emailAddressConfirm = this.getTrimmedValue(driverProfile.emailAddress);
    this.driverInfo.driverBaseInfo.firstName = this.getTrimmedValue(driverProfile.firstName);
    this.driverInfo.driverBaseInfo.middleName = this.getTrimmedValue(driverProfile.middleName);
    this.driverInfo.driverBaseInfo.lastName = this.getTrimmedValue(driverProfile.lastName);
    this.driverInfo.driverBaseInfo.driverId = driverProfile.driverId;
    this.driverInfo.driverBaseInfo.employeeId = this.getTrimmedValue(driverProfile.employeeId);
    this.driverInfo.driverBaseInfo.driverLicenseNumber = this.getTrimmedValue(driverProfile.driverLicenseNumber);
    this.driverInfo.driverBaseInfo.driverLicenseState = this.getTrimmedValue(driverProfile.driverLicenseState);
    this.driverInfo.driverBaseInfo.languageId = this.getTrimmedValue(driverProfile.languageId);
    this.driverInfo.driverBaseInfo.isCommercialDriversLicense = driverProfile.isCommercialDriversLicense;
    this.driverInfo.driverBaseInfo.primaryDriverId = driverProfile.primaryDriverId;
    this.driverInfo.driverBaseInfo.trainingOnlyPortalAccess = driverProfile.trainingOnlyPortalAccess;

    // secondary driver fields
    this.driverInfo.driverBaseInfo.isTemporaryDriver = driverProfile.isTemporaryDriver;
    if (driverProfile.driverDuration) {
      this.driverInfo.driverBaseInfo.driverDuration = driverProfile.driverDuration.toString();
    }

    this.driverInfo.driverBaseInfo.livesWithPrimaryDriver = driverProfile.livesWithPrimaryDriver;

    if (driverProfile.primaryDriverRelationshipId) {
      this.driverInfo.driverBaseInfo.primaryDriverRelationshipId = driverProfile.primaryDriverRelationshipId.toString();
    }

    this.driverInfo.driverBaseInfo.temporaryDriverExpirationDate =
      this.datePipe.transform(driverProfile.temporaryDriverExpirationDate, this.dateFormat);

    // req fields for extended info
    for (let i = 1; i <= 10; i++) {
      const req = driverProfile['req' + i.toString()];
      if (req) {
        this.driverInfo.driverBaseInfo['req' + i.toString()] = this.getTrimmedValue(req);
      }
    }

    this.driverInfo.mvrOrderedDate = driverProfile.mvrRequestDate;

    this.updateDriverPermTemp(
      this.formSettingsDictionary['temporaryDriverExpirationDate'] &&
      this.formSettingsDictionary['temporaryDriverExpirationDate'].isVisible
    );
  }

  private getTrimmedValue(val: any) {
    return (val ? val.toString().trim() : null);
  }

  private getUserRights(data: Array<UserRightsInfo>) {
    this._userRights = data;
    this.canViewDLAndDOB = this.isAccessAllowed(42);
    this.canAssignTraining = this.isAccessAllowed(18);
    this.canOrderMVR = this.isAccessAllowed(49);
    this.canOrderNonEmpMVR = this.isAccessAllowed(26);
    this.canUpload = this.isAccessAllowed(55);
  }

  private initClientSettings(driverProfile: DriverProfile) {
    // verify license info, request MVR, language, license upload
    // defaults
    this.showVerifyLicenseInfo = this.clientSettings.dataVerification;
    this.showRequestMVR = this.canOrderMVR;
    this.showLanguage = !this.isNewDriver;

    // training section
    this.showTrainingSection = this.canAssignTraining;
    if (!this.clientSettings.trainingAccount) {
      this.showTrainingSection = this.clientSettings.trainingAccount;
    }
    if (!this.isEmployeeDriver) {
      this.showTrainingSection = this.clientSettings.nonEmployeeTraining;
    }
    this.showDriverLicenseUpload = this.clientSettings.isLicenseUploadOn;

    // policy task
    this.showPolicyTask = this.clientSettings.isPolicyTask;

    // adjustments to defaults based on client settings
    if (this.isEmployeeDriver) {
      // conditions for employee driver only

      // 2019-07 - condition for showing mark as top
      if (this.clientSettings.mixedTrainingOnlyPortal && this.isAccessAllowed(28)) {
        this.canMarkDriverAsTOP = true;
        if (this.driverInfo.driverBaseInfo.trainingOnlyPortalAccess == null) {
          this.driverInfo.driverBaseInfo.trainingOnlyPortalAccess = true;
        }
      }

      if (this.canMarkDriverAsTOP && this.clientSettings.mixedTrainingOnlyPortal && this.clientSettings.driversUseTrainingOnlyPortal) {
        this.showVerifyLicenseInfo = false;
      }

      if (
        (!this.clientSettings.trainingAccount) &&
        this.clientSettings.mvrAccount &&
        (!this.clientSettings.profilingAccount) &&
        (!this.clientSettings.dataVerification)
      ) {
        this.showVerifyLicenseInfo = false;
        this.showLanguage = false;
      }

      // DOT Driver
      this.showDOTDriver = this.clientSettings.dotServices;
      this.showAutoCoverageRequired = this.clientSettings.isAutoCoverageOn;


      // monitoring
      this.showMonitoring = (this.clientSettings.mvrMonitorServices && (!this.clientSettings.employeeAutoEnrollInMonitoring));

      // reset password, resend login, email
      // default
      this.showResetPassword = (!this.isNewDriver);
      this.showResendLogin = false;
      this.showEmailFields = true;

      // adjustments based on client settings
      if (this.clientSettings.trainingAccount && this.clientSettings.driversUseTrainingOnlyPortal) {
        this.showResetPassword = false;
        this.showResendLogin = true;
        this.showEmailFields = this.isNewDriver ? false : true;
      }

    } else {
      // conditions for non-employee driver only

      // 2019-07 never show confirm license or mark as TOP for a non-employee
      this.showVerifyLicenseInfo = false;
      this.canMarkDriverAsTOP = false;

      if (!this.clientSettings.mvrAccount) {
        this.showVerifyLicenseInfo = false;
        this.showRequestMVR = false;
        this.showLicenseNumberAndState = false;
      }

      if (this.clientSettings.mvrAccount && this.clientSettings.nonEmployeeMvrUpload) {
        this.showLicenseNumberAndState = false;
        if (this.clientSettings.nonEmployeeTraining) {
          this.showVerifyLicenseInfo = false;
          this.showRequestMVR = false;
        } else {
          this.showServicesSection = false;
        }
      }

      // DOT Driver
      this.showDOTDriver = false;
      this.showAutoCoverageRequired = false;

      // monitoring
      this.showMonitoring = (
        this.clientSettings.mvrMonitorServices &&
        this.clientSettings.mvrMonitorNonEmployee &&
        (!this.clientSettings.nonEmployeeAutoEnrollInMonitoring)
      );

      // reset password, resend login, email
      // default
      this.showResetPassword = false;
      this.showResendLogin = false;
      this.showEmailFields = true;
      // adjustments based on client settings
      if (this.clientSettings.nonEmployeesUseTrainingOnlyPortal) {
        // show email email and make required
        this.showEmailFields = true;
      } else {
        // show email but not required
        this.showEmailFields = true;
        this.makeEmailFieldsNotRequired();
      }

      if (this.clientSettings.trainingAccount && this.clientSettings.driversUseTrainingOnlyPortal) {
        this.showEmailFields = this.isNewDriver ? false : true;
      }

      if (this.clientSettings.mixedTrainingOnlyPortal) { // and toggle is off
        this.showEmailFields = true;
        this.makeEmailFieldsNotRequired(); // make required only if toggle is off
      }

    }

    if (this.clientSettings.trainingAccount && (!this.clientSettings.mvrAccount) && (!this.clientSettings.dataVerification)) {
      this.showVerifyLicenseInfo = false;
      this.showRequestMVR = false;
    }

    // location section, license number and state
    this.showLocationSection = (
      (!this.clientSettings.dataVerification) &&
      (!this.clientSettings.mvrAccount) &&
      this.clientSettings.trainingAccount
    );
    this.showLicenseNumberAndState = this.canViewDLAndDOB && (!this.showLocationSection);

    // COV
    this.showCOV = this.clientSettings.isCertificateOfViolationOn;

    // disable request COV unless DOT Driver and Request MVR checked;
    this.updateDriverCOV();

    // default confirm license due date
    const dtLicVerDueDate: Date = new Date((new Date()).toDateString());
    if (this.clientSettings.licenseVerificationDueDays) {
      dtLicVerDueDate.setDate(dtLicVerDueDate.getDate() + this.clientSettings.licenseVerificationDueDays);
    }
    this.driverInfo.confirmLicenseInfoDateDue = this.datePipe.transform(dtLicVerDueDate, this.dateFormat);

    // default training due date
    const dtAssignTrainingDueDate: Date = new Date((new Date()).toDateString());
    if (this.clientSettings.trainingDueDays) {
      dtAssignTrainingDueDate.setDate(dtAssignTrainingDueDate.getDate() + this.clientSettings.trainingDueDays);
    }
    this.driverInfo.assignOnlineTrainingDateDue = this.datePipe.transform(dtAssignTrainingDueDate, this.dateFormat);

    // default license upload due date
    const dtLicenseUploadDueDate: Date = new Date((new Date()).toDateString());
    if (this.clientSettings.licenseUploadDueDays) {
      dtLicenseUploadDueDate.setDate(dtLicenseUploadDueDate.getDate() + this.clientSettings.licenseUploadDueDays);
    }
    this.driverInfo.driverLicenseUploadDateDue = this.datePipe.transform(dtLicenseUploadDueDate, this.dateFormat);

    // default policy signoff due date
    const dtPolicyTaskDueDate: Date = new Date((new Date()).toDateString());
    if (this.clientSettings.policyTaskDueDays) {
      dtPolicyTaskDueDate.setDate(dtPolicyTaskDueDate.getDate() + this.clientSettings.policyTaskDueDays);
    }
    this.driverInfo.policyTaskDueDate = this.datePipe.transform(dtPolicyTaskDueDate, this.dateFormat);

  }

  private initSections() {
    if (this.isNewDriver) {
      // expand all sections if new
      this.expandGeneralInfo = true;
      this.expandExtendedInfo = true;
      this.expandLicenseInfo = true;
      this.expandLocation = true;
      this.expandTraining = true;
      this.expandServices = true;
    } else {
      if (!this.openToSection) {
        // expand all except training and services
        this.expandGeneralInfo = true;
        this.expandExtendedInfo = true;
        this.expandLicenseInfo = true;
        this.expandLocation = true;
      } else {
        switch (this.openToSection) {
          case 'Training':
            this.expandTraining = true;
            break;
          case 'Services':
            this.expandServices = true;
            break;
        }
      }
    }
  }

  private getGroupOptions(tempin: Array<DriverHierarchy>) {
    // get choices for Group dropdown
    this.groupOptions = [];

    tempin.forEach((item: DriverHierarchy, index: number) => {
      const tempobj = {
        value: item.elementGuid,
        label: item.description,
        elementGuid: item.elementGuid
      };
      this.groupOptions.push(tempobj);
    });
    this.filteredGroupOptions = this.groupOptions;
  }

  private getStateProvinceOptions(tempin: Array<DriverState>) {
    // get choices for state dropdown
    this.stateProvinceOptions = [];

    tempin.forEach((item: DriverState, index: number) => {
      const tempobj = {
        value: item.secondaryCode,
        label: item.description
      };
      this.stateProvinceOptions.push(tempobj);
    });
    this.filteredStateOptions = this.stateProvinceOptions;
  }

  private getLanguageOptions(tempin: Array<DriverLanguage>) {
    // get choices for language dropdown
    this.languageOptions = [];

    tempin.forEach((item: DriverLanguage, index: number) => {
      const tempobj = {
        value: item.id.toString(),
        label: item.language
      };
      this.languageOptions.push(tempobj);
    });
  }

  private getCountryOptions(tempin: Array<DriverCounty>) {
    // get choices for language dropdown
    this.countryOptions = [];
    tempin.forEach((item: DriverCounty, index: number) => {
      const tempobj = {
        value: item.description,
        label: item.description
      };
      this.countryOptions.push(tempobj);
    });
  }

  private getTrainingCategoryOptions(tempin: Array<DriverTrainingCategory>) {
    this.trainingCategoryOptions = [];
    this.trainingCategoryOptions.push({ label: '-- No Category --', value: 0 });

    tempin.forEach((item: DriverTrainingCategory, index: number) => {
      const tempobj = {
        value: item.id,
        label: item.description
      };
      this.trainingCategoryOptions.push(tempobj);
    });
    this.filteredTrainingCategoryOptions = this.trainingCategoryOptions;
  }

  private getTrainingOptions(tempin: Array<DriverTrainingCourse>) {
    this.trainingOptionsFiltered = [];
    this.trainingOptions = [];

    tempin.forEach((item: DriverTrainingCourse, index: number) => {
      const tempobj = {
        value: `${item.courseGroupId} ~ ${item.passRate.toString()}`,
        label: `${item.title} PASS RATE: ${item.passRate.toString()}%'`,
        courseGroupId: item.courseGroupId,
        courseCategoryId: item.courseCategoryId,
        passRate: item.passRate,
        selected: false
      };
      this.trainingOptions.push(tempobj);
      this.trainingOptionsFiltered.push(tempobj);
    });

  }

  private getRelationshipTypeOptions(tempin: Array<DriverRelationshipType>) {
    this.relationshipTypeOptions = [];

    tempin.forEach((item: DriverRelationshipType, index: number) => {
      const tempobj = {
        value: item.id.toString(),
        label: item.description
      };
      this.relationshipTypeOptions.push(tempobj);
    });
  }

  private processExtendedInfo(parentProfile: DriverProfile, info: Array<DriverExtendedInfo>) {
    // set up form control for each extended info field
    if (this.isEmployeeDriver) {
      // all fields editable
      info.forEach(i => {
        i.formControl = new UntypedFormControl('', (i.reqIsRequired ? [Validators.required] : []));
        this.allReqControls.push(i.formControl);
      });
    } else {
      // fields disabled
      info.forEach(i => {
        if (parentProfile) {
          // get defaults from parent profile if new driver
          this.driverInfo.driverBaseInfo[i.reqField.toLowerCase()] = parentProfile[i.reqField.toLowerCase()];
        }
        i.formControl = new UntypedFormControl({ value: '', disabled: true }, []);
        this.allReqControls.push(i.formControl);
      });
    }

  }

  onStateChange(e: any) {
    // update language selections when state changes
    if (e) {
      const stateCurr = this.driverInfo.driverBaseInfo.driverLicenseState;
      const languageCurr = this.driverInfo.driverBaseInfo.languageId;
      if (e !== stateCurr) {
        this.loadingSpinnerService.show();
        this.onPremDriverService.get(`lookup/language/${this.clientSelectedArray[0]}${(e ? '/' + e : '')}`).subscribe({
          next: (data) => {
            this.getLanguageOptions(data as Array<DriverLanguage>);
            if (this.languageOptions.findIndex(o => o.value === languageCurr)) {
              // set languageId to current id if its in the updated language list
              this.driverInfo.driverBaseInfo.languageId = languageCurr;
            } else {
              this.driverInfo.driverBaseInfo.languageId = null;
            }
            this.loadingSpinnerService.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err.error);
            this.loadingSpinnerService.hide();
          }
        });
      }
    }
  }

  requestMVROnChange() {
    // license fields are required ONLY if MVR Requested, but NOT required
    // if confirm license info selected
    this.confirmLicenseInfoOnChange();

    // update request COV
    this.updateDriverCOV();
  }

  updateDriverCOV() {
    // disable request COV unless DOT Driver and Request MVR checked;
    this.driverInfo.requestCOV = false;
    this.disableDriverCOV = !(this.driverInfo.driverBaseInfo.dotDriver && this.driverInfo.requestMVR);
  }

  updateDriverPermTemp(doUpdate: boolean) {
    if (doUpdate) {
      // update UI when perm/temp and/or lives in household changes
      this.driverInfo.driverBaseInfo.isTemporaryDriver = (this.driverInfo.driverBaseInfo.driverDuration === '2' ? true : false);

      if (
        this.formSettingsDictionary['temporaryDriverExpirationDate'] &&
        this.formSettingsDictionary['temporaryDriverExpirationDate'].isVisible
      ) {
        // show temporary driver expiration date only for temp driver
        if (this.driverInfo.driverBaseInfo.isTemporaryDriver) {
          this.showTemporaryDriverExpirationDate = true;
          this.formSettingsDictionary['temporaryDriverExpirationDate'].isRequired = true;
        } else {
          this.showTemporaryDriverExpirationDate = false;
          this.formSettingsDictionary['temporaryDriverExpirationDate'].isRequired = false;
          this.driverInfo.driverBaseInfo.temporaryDriverExpirationDate = null;
          this.formSettingsDictionary['temporaryDriverExpirationDate'].formControl.setValue(null);
        }
      }

      if ((!this.driverInfo.driverBaseInfo.isTemporaryDriver) && (!this.driverInfo.driverBaseInfo.livesWithPrimaryDriver)) {
        this.driverPermTempMsg = 'If driver does not live in household, they must be made temporary.';
      } else {
        this.driverPermTempMsg = '';
      }
    }
  }

  updateDqSelections(dqRequired: boolean) {
    this.driverInfo.requestMVR = dqRequired;
    this.driverInfo.dqRequestLicenseUpload = dqRequired;
    this.driverInfo.dqRequestMedCard = dqRequired;
    this.driverInfo.dqRequestCOV = dqRequired;
    this.driverInfo.dqRequestApplication = dqRequired;
    this.driverInfo.dqRequestRecordOfRoadTest = dqRequired;
    this.driverInfo.dqRequestCertOfRoadTest = dqRequired;
    this.driverInfo.dqRequestDriverLog = dqRequired;

    // dq turned off. Clearing House input is disabled, so switch it to false.
    if (!dqRequired) {
      this.driverInfo.dqRequestClearingHouseSignoff = dqRequired;
    }
    // dq turned on; turn off lic upload
    if (dqRequired) {
      this.driverInfo.driverLicenseUpload = false;
    }
  }

  dqRequestCOVOnChange(dqRequestCOV: boolean) {
    // if dqRequestCOV selected, then requestMVR gets selected and disabled
    // if dqRequestCOV de-selected, requestMVR is eanbled but does not change
    if (dqRequestCOV) {
      this.driverInfo.requestMVR = true;
    }
  }


  confirmLicenseInfoOnChange() {
    // 2019-07 license fields are required ONLY if MVR Requested, but NOT required
    // if confirm license info selected
    if (this.driverInfo.requestMVR && !this.driverInfo.confirmLicenseInfo) {
      this.formSettingsDictionary['driverLicenseNumber'].isRequired = true;
      this.formSettingsDictionary['driverLicenseState'].isRequired = true;
      this.formSettingsDictionary['dateOfBirth'].isRequired = true;
      // mark as dirty to make sure it gets included in validation
      this.formSettingsDictionary['driverLicenseNumber'].formControl.markAsDirty();
      this.formSettingsDictionary['driverLicenseState'].formControl.markAsDirty();
      this.formSettingsDictionary['dateOfBirth'].formControl.markAsDirty();
    } else {
      this.formSettingsDictionary['driverLicenseNumber'].isRequired = false;
      this.formSettingsDictionary['driverLicenseState'].isRequired = false;
      this.formSettingsDictionary['dateOfBirth'].isRequired = false;
    }
    this.updateFormControlRequiredValidation('driverLicenseNumber');
    this.updateFormControlRequiredValidation('driverLicenseState');
    this.updateFormControlRequiredValidation('dateOfBirth');
  }

  private updateFormControlRequiredValidation(key: string) {
    const formEntry = this.formSettingsDictionary[key];
    if (formEntry) {
      if (formEntry.isRequired) {
        formEntry.formControl.setValidators([Validators.required]);
        formEntry.formControl.updateValueAndValidity();
      } else {
        formEntry.formControl.setValidators(null);
        formEntry.formControl.setErrors(null);
      }
    }
  }

  private validateDriverForm(): boolean {
    let isOK = true;
    // get settings dictionary of all fields that are visible, required, and editable
    const formSettingsDictionary = this.formSettingsDictionary;
    const isNewDriver = this.isNewDriver;
    const formSettingsToValidate = Object.keys(formSettingsDictionary).reduce(function (r, e) {
      const formSetting = formSettingsDictionary[e];
      // 2019-07 - validate all required fields for new drivers - otherwise validate only edited fields
      const toValidate = (isNewDriver || (formSetting.formControl && formSetting.formControl.dirty));
      if (toValidate && formSetting.isVisible && formSetting.isRequired && !formSetting.isReadOnly) {
        r[e] = formSetting;
      }
      return r;
    }, {});

    const validateEmail = this.showEmailFields && formSettingsToValidate['emailAddress'];
    if (validateEmail) {
      if (!this.driverInfo.driverBaseInfo.trainingOnlyPortalAccess) {
        // validate email fields unless marked as no email
        isOK = this.validateEmail(
          formSettingsToValidate['emailAddress'].formControl,
          formSettingsToValidate['emailAddressConfirm'].formControl
        );
      } else {
        // do not validate email fields
        delete formSettingsToValidate['emailAddress'];
        delete formSettingsToValidate['emailAddressConfirm'];
      }
    } else {
      // do not validate email fields
      delete formSettingsToValidate['emailAddress'];
      delete formSettingsToValidate['emailAddressConfirm'];
    }

    if (!this.showLicenseNumberAndState) {
      // remove license fields from validation
      delete formSettingsToValidate['driverLicenseNumber'];
      delete formSettingsToValidate['driverLicenseState'];
      delete formSettingsToValidate['dateOfBirth'];
    }

    // not on form, ignore for now
    delete formSettingsToValidate['isCommercialDriversLicense'];
    delete formSettingsToValidate['driverLicenseExpirationDate'];
    delete formSettingsToValidate['isActive'];

    // get all controls to validate
    let allControls = Object.keys(formSettingsToValidate).map(function (key) {
      if (formSettingsToValidate[key].formControl.invalid) {
        console.log(formSettingsToValidate[key].propertyName);
      }

      return formSettingsToValidate[key].formControl;
    });
    // add req controls
    allControls = allControls.concat(this.allReqControls);
    // filter out errors related to other validations
    // and add message for missing/invalid
    if (allControls.map(c1 => {
      let errs = c1.errors;
      if (errs) {
        delete errs['emailmatch'];
        if (Object.keys(errs).length <= 0) {
          errs = null;
        }
      }
      return errs;
    }).filter(c2 => c2 != null).length > 0) {
      this.errorService.setErrorObject({ message: 'Missing or Invalid Fields.' });
      isOK = false;
    }

    return isOK;
  }

  toggleMarkAsTOP() {
    const formSettingEmail = this.formSettingsDictionary['emailAddress'];
    const formSettingEmailConfirm = this.formSettingsDictionary['emailAddressConfirm'];
    const updateEmailValidation = (
      this.showEmailFields &&
      formSettingEmail &&
      formSettingEmail.isVisible &&
      formSettingEmail.isRequired &&
      !formSettingEmail.isReadOnly
    );
    if (updateEmailValidation) {
      if (this.driverInfo.driverBaseInfo.trainingOnlyPortalAccess) {
        // make email fields not required
        formSettingEmail.formControl.setValidators(null);
        formSettingEmail.formControl.setErrors(null);
        formSettingEmailConfirm.formControl.setValidators(null);
        formSettingEmailConfirm.formControl.setErrors(null);
      } else {
        formSettingEmail.formControl.setValidators([Validators.required, Validators.email]);
        formSettingEmail.formControl.updateValueAndValidity();
        formSettingEmailConfirm.formControl.setValidators([Validators.required, Validators.email]);
        formSettingEmailConfirm.formControl.updateValueAndValidity();
      }
    }

  }

  private makeEmailFieldsNotRequired() {
    const formSettingEmail = this.formSettingsDictionary['emailAddress'];
    const formSettingEmailConfirm = this.formSettingsDictionary['emailAddressConfirm'];
    const updateEmailValidation = (
      this.showEmailFields &&
      formSettingEmail &&
      formSettingEmail.isVisible &&
      formSettingEmail.isRequired &&
      !formSettingEmail.isReadOnly
    );
    if (updateEmailValidation) {

      // make email fields not required
      formSettingEmail.formControl.setValidators(null);
      formSettingEmail.formControl.setErrors(null);
      formSettingEmailConfirm.formControl.setValidators(null);
      formSettingEmailConfirm.formControl.setErrors(null);

    }
  }

  onTextSearch() {
    this.handleSearch();
  }

  onCategorySearch() {
    this.handleSearch();
  }

  private handleSearch(): void {
    // filter training options by both search criteria
    this.trainingOptionsFiltered = this.trainingOptions.filter(t => this.combinedSearchFunction(t));
    // unselect any options that are not in the filtered options
    this.trainingOptions.filter(t => !this.combinedSearchFunction(t)).forEach(o => o.selected = false);
  }

  private combinedSearchFunction(trainingOption: any) {
    // function that checks whether a training option meets both saerch criteria
    let matchesText = true;
    let matchesCat = true;

    if (this.searchText && (this.searchText.length >= 3)) {
      matchesText = (trainingOption.label.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0);
    }

    if (this.categorySearchValue > 0) {
      matchesCat = (trainingOption.courseCategoryId === this.categorySearchValue);
    }

    return matchesText && matchesCat;
  }

  toggleUploadPanel(showPanel: boolean) {
    this.showUploadPanel = showPanel;
  }

  uploadFinished() {
    this.showUploadPanel = false;
    this.loadingSpinnerService.hide();
  }

  saveThenUpload() {
    this.submitDriverForm(false);
  }

  private validateEmail(driverEmailControl: UntypedFormControl, driverEmailConfirmControl: UntypedFormControl): boolean {
    let isOK = true;
    // check email fields
    if (driverEmailControl.value !== driverEmailConfirmControl.value) {
      isOK = false;
      // set errors on email fields
      this.errorService.setErrorObject({ message: 'Email Fields must match.' });
      this.addValidationError(driverEmailControl, 'emailmatch');
      this.addValidationError(driverEmailConfirmControl, 'emailmatch');
    } else {
      // clear errors on email fields
      this.removeValidationError(driverEmailControl, 'emailmatch');
      this.removeValidationError(driverEmailConfirmControl, 'emailmatch');
    }

    return isOK;
  }

  private addValidationError(ctrl: UntypedFormControl, key: string) {
    if (ctrl) {
      let errs: ValidationErrors = ctrl.errors;

      if (errs) {
        errs[key] = true;
      } else {
        errs = {};
        errs[key] = true;
      }
      ctrl.setErrors(errs);
    }
  }

  private removeValidationError(ctrl: UntypedFormControl, key: string) {
    if (ctrl) {
      let errs: ValidationErrors = ctrl.errors;
      if (errs) {
        delete errs[key];
      }
      if (errs && (Object.keys(errs).length <= 0)) {
        errs = null;
      }
      ctrl.setErrors(errs);
    }
  }

  private createServicesBatchObject(driverId: number): DriverBatchItem {
    const obj: Batch = {};
    let DriverBatch = new DriverBatchItem();

    // CSV list for request MVR switches
    const arrMvrId: Array<string> = [];
    arrMvrId.push(driverId.toString());

    this.setBatchMvrDriverIdList(arrMvrId, obj);

    // driver license upload
    if (this.driverInfo.driverLicenseUpload) {
      obj.LicenseUploadDriverIdList = driverId.toString();
      obj.LicenseUploadDueDate = this.datePipe.transform(this.driverInfo.driverLicenseUploadDateDue, this.dateFormat);
    }

    // COV
    if (this.driverInfo.requestCOV) {
      obj.ProcessId = 2;
      obj.IsCOVRequested = true;
    }

    // MVR Monitoring
    if (this.driverInfo.enrollInMonitoring) {
      obj.MvrMonitoringDriverIdList = driverId.toString();
    }

    // Policy Signoff
    if (this.driverInfo.orderPolicyTask) {
      obj.PolicySignoffDriverIdList = driverId.toString();
      obj.PolicySignoffDueDate = this.datePipe.transform(this.driverInfo.policyTaskDueDate, this.dateFormat);
    }

    // Training
    if (this.driverInfo.assignOnlineTraining) {
      obj.TrainingAssignmentType = 0;
      obj.TrainingDriverIdList = driverId.toString();
      obj.TrainingDueDate = this.datePipe.transform(this.driverInfo.assignOnlineTrainingDateDue, this.dateFormat);
      const arrTraining = this.trainingOptionsFiltered.filter(t => t.selected);
      obj.TrainingCourseGroupList = arrTraining.map(t => t.courseGroupId).join(',');
      obj.TrainingPassRateList = arrTraining.map(t => t.passRate).join(',');
    }

    // DQ fields
    if (this.driverInfo.dqRequired) {
      obj.DQDriverIdList = driverId.toString();
      obj.EnrollInDQService = true;
      obj.IsDQMedicalCardRequested = this.driverInfo.dqRequestMedCard;
      obj.IsDQDriversLicenseUploadRequested = this.driverInfo.dqRequestLicenseUpload;
      obj.IsDQDriverApplicationRequested = this.driverInfo.dqRequestApplication;
      obj.IsDQCOVRequested = this.driverInfo.dqRequestCOV;
      obj.IsDQRecordOfRoadTestRequested = this.driverInfo.dqRequestRecordOfRoadTest;
      obj.IsDQCertificateOfRoadTestRequested = this.driverInfo.dqRequestCertOfRoadTest;
      obj.IsDQDriversLogRequested = this.driverInfo.dqRequestDriverLog;
      obj.IsDQClearingHouseLimitedSignoffRequested = this.driverInfo.dqRequestClearingHouseSignoff;
    }

    // COI
    if (this.driverInfo.requestCOI) {
      obj.CertificateOfInsuranceDriverIdList = driverId.toString();
    }

    // add client code if obj is not empty
    if (Object.keys(obj).length > 0) {
      obj.ClientCode = this.driverInfo.driverBaseInfo.client;
    }

    DriverBatch = this.setDriverBatchOptions(obj, DriverBatch)
    return DriverBatch;
  }

  private setBatchMvrDriverIdList(arrMvrId: Array<string>, obj: Batch): Batch {
    let arrayMvrId: Array<string> = arrMvrId;
    if (this.driverInfo.requestNonEmployeeMVR && this.driverInfo.secondaryDrivers) {
      // add ids for secondary employees
      arrayMvrId = arrayMvrId.concat(this.driverInfo.secondaryDrivers.map(s => s.driverId.toString()));
    }

    if (this.driverInfo.requestMVR) {
      if (arrayMvrId.length > 0) {
        obj.MvrDriverIdList = arrayMvrId.join(',');
      }
    }

    if (this.driverInfo.confirmLicenseInfo) {
      if (arrayMvrId.length > 0) {
        obj.MvrDriverIdList = arrayMvrId.join(',');
      }
      // send due date if license verification selected
      obj.MvrDataValidationDue = this.datePipe.transform(this.driverInfo.confirmLicenseInfoDateDue, this.dateFormat);
      // set MvrDataValidationOnly to true if not requesting MVR
      obj.MvrDataValidationOnly = !this.driverInfo.requestMVR;
    }

    return obj;
  }

  private setDriverBatchOptions(obj: Batch, driverBatch: DriverBatchItem): DriverBatchItem {
    // add properties for calling resendLogon and resetPassword
    if (this.driverInfo.resendLogon) {
      driverBatch.resendLogin = true;
    }

    if (this.driverInfo.resetPassword) {
      driverBatch.resetPassword = true;
    }

    // set flag indicating if a batch request should be made
    if (Object.keys(obj).length > 0 &&
      (obj?.MvrDriverIdList?.length > 0 ||
        obj?.LicenseUploadDriverIdList?.length > 0 ||
        obj?.MvrMonitoringDriverIdList?.length > 0 ||
        obj?.PolicySignoffDriverIdList?.length > 0 ||
        obj?.TrainingDriverIdList?.length > 0 ||
        obj?.DQDriverIdList?.length > 0 ||
        obj?.CertificateOfInsuranceDriverIdList?.length > 0
      )) {
     driverBatch.hasBatchRequest = true;
    }
    driverBatch.batchRequest = obj;
    return driverBatch;
  }

  private postServicesBatchObject(driverId: string, servicesObject: DriverBatchItem) {
    const arrObs: Array<Observable<Object>> = [];
    if (servicesObject.resendLogin) {
      arrObs.push(this.onPremDriverService.post(`entity/${driverId}/resendlogin`, null));
    }
    if (servicesObject.resetPassword) {
      arrObs.push(this.onPremDriverService.put(`entity/${driverId}/resetpassword`, null));
    }

    if (servicesObject.hasBatchRequest === true) {
      arrObs.push(this.submitBatchService.submitBatch(servicesObject.batchRequest));
    }
    forkJoin(arrObs).subscribe({
      next: (data) => {
        // toastr label will vary if the driver is employee vs non-employee
        if (this.isNewDriver) {
          this.alertService.showSuccessAlert(this.driverCreatedMsg, 'end', 'top', 5000);
        } else {
          // go to driver profile
          this.alertService.showSuccessAlert(this.driverUpdatedMsg, 'end', 'top', 5000);
        }
        this.loadingSpinnerService.hide();
        this.exitDriverForm(true);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  submitDriverForm(exitOnSave: boolean) {
    if (this.validateDriverForm()) {
      this.loadingSpinnerService.show();
      // update driver info
      this.driverInfo.driverBaseInfo.driverCountry = this.driverCountry;
      const postData = this.driverInfo.driverBaseInfo;

      // use POST for new driver, PUT for existing driver
      const updateObs: Observable<Object> = (
        this.isNewDriver ?
          this.onPremDriverService.post('entity', JSON.stringify(postData)) :
          this.onPremDriverService.put('entity/' + this.driverId, JSON.stringify(postData))
      );
      updateObs.subscribe({
        next: (data) => {
          const responseObject = data as Array<EntityDriverResponseObject>;
          if (exitOnSave) {
            // submit then exit
            // post selections in Training and Services sections, if any
            const servicesObject = this.createServicesBatchObject(responseObject[0].entityId);
            if (
              servicesObject.hasBatchRequest === true ||
              servicesObject.resendLogin === true ||
              servicesObject.resetPassword === true
            ) {
              // call tasks/batch to send service selection info
              this.postServicesBatchObject(responseObject[0].entityId.toString(), servicesObject);
            } else {
              // no service updates
              // toastr label will vary if the driver is employee vs non - employee
              if (this.isNewDriver) {
                // return to originating page
                this.alertService.showSuccessAlert(this.driverCreatedMsg, 'end', 'top', 5000);
              } else {
                // go to driver profile
                this.alertService.showSuccessAlert(this.driverUpdatedMsg, 'end', 'top', 5000);
              }
              this.loadingSpinnerService.hide();
              this.exitDriverForm(true);

            }
          } else {
            if (this.isNewDriver) {
              // return to originating page
              this.alertService.showSuccessAlert(this.driverCreatedMsg, 'end', 'top', 5000);
            } else {
              // go to driver profile
              this.alertService.showSuccessAlert(this.driverUpdatedMsg, 'end', 'top', 5000);
            }
            this.loadingSpinnerService.hide();

            // refresh driver info and show upload panel
            this.isNewDriver = false;
            this.returnToOriginPage = true;
            this.driverId = responseObject[0].entityId.toString();
            this.initAll(this.driverId, false);
            this.showUploadPanel = true;

          }
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    }

  }

  exitDriverForm(forceReload: boolean) {
    let returnToProfileId = null;
    if (this.returnToProfileId) {
      returnToProfileId = this.returnToProfileId;
    } else {
      if ((!this.returnToOriginPage) && this.driverId && (this.driverId.length > 0)) {
        returnToProfileId = this.driverId;
      } else {
        if (this.primaryDriverId && (this.primaryDriverId.length > 0)) {
          returnToProfileId = this.primaryDriverId;
        }
      }
    }

    if (returnToProfileId && (returnToProfileId.length > 0)) {
      if (this.returnToTab) {
        this.router.navigate(['driver/history-profile', returnToProfileId], { queryParams: { openToTab: this.returnToTab } });
      } else {
        this.router.navigate(['driver/history-profile', returnToProfileId]);
      }

    } else {
      if (this.returnToPage) {
        this.router.navigate(['safety/' + this.returnToPage], (forceReload ? { queryParams: { forceReload: 'true' } } : {}));
      } else {
        this.router.navigate(['safety/newemployeedriverselector']);
      }
    }
  }

  // determines if access to the component is allowed
  isAccessAllowed(userRightsId: number) {
    let retVal = false;

    if (this._userRights) {
      const thisRight = this._userRights.filter(r => r.userRightId === userRightsId);
      if ((thisRight.length > 0) && (thisRight[0].permission === 'allow')) {
        retVal = true;
      }
    }

    return retVal;
  }

  private arrayCompare(a1, a2) {
    return (a1.length === a2.length && a1.every((v, i) => v === a2[i]));
  }

  ngOnDestroy() {
    this.routeParamsSub.unsubscribe();
  }
}
