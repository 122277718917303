//angular modules
import { Component, OnInit, ViewChild, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
//thirdparty libraries
import { UntypedFormControl, Validators, ValidationErrors, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatStepper } from '@angular/material/stepper';
//project dependencies
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { CorporateStructure } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { AlertModalService } from "../../components/alert-modal/alert-modal-service.component";
import { ClientSelectionService, ClientSelectionsObject } from '../../components/client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';

@Component({
  selector: 'app-cov-export',
  templateUrl: './cov-export.component.html',
  styleUrls: ['./cov-export.component.scss']
})
export class CovExportComponent implements OnInit {
  clientSelectedArray: Array<string> = [];
  arrCH: Array<CorporateStructure>;
  arrCHByState: Array<CorporateStructure>;
  @ViewChild('stepper') myStepper: MatStepper;
  @ViewChildren('startDatePicker') startDatePickerList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('endDatePicker') endDatePickerList: QueryList<MatDatepicker<Date>>;

  selectionCriteria: MvrExportDriverSelectionCriteria = new MvrExportDriverSelectionCriteria();
  arrDriver: Array<MvrExportDriverInfo> = [];
  arrGroupGuid: Array<string> = [];
  arrStateGuid: Array<string> = [];
  arrDriverIdSelected: Array<number> = [];
  arrDriverIdExcluded: Array<number> = [];
  isSubmitted: boolean = false;
  public driverTableConfig: any;
  dtToday: Date = new Date();
  startDatePicker: MatDatepicker<Date>;
  endDatePicker: MatDatepicker<Date>;
  actualDate = new Date();
  criteriaFor1TabFormGroup: UntypedFormGroup;

  exportStartDateControl = new UntypedFormControl('', [Validators.required]);
  exportEndDateControl = new UntypedFormControl('', [Validators.required]);
  exportRetrieveTypeControl = new UntypedFormControl('', [Validators.required]);
  exportDriverTypeControl = new UntypedFormControl('', [Validators.required]);

  constructor(private router: Router,
    private clientSelectionService: ClientSelectionService,
    private lineOfBusinessService: LineOfBusinessService,
    private onPremService: OnPremDriverService,
    private cdRef: ChangeDetectorRef,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private alertService: AlertModalService,
    private readonly formBuilder: UntypedFormBuilder
  ) {
    this.criteriaFor1TabFormGroup = this.formBuilder.group({
      exportStartDateControl: ['', Validators.required],
      exportEndDateControl: ['', Validators.required],
      exportRetrieveTypeControl: ['', Validators.required]
    })
  }

  ngOnInit() {
    // set line of business to safety and client choice to
    // safety choice, if not already initialized to Safety
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }
    // get the selected client(s) and initial hierarchy
    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(2);
    if (this.clientSelectedArray && (this.clientSelectedArray.length > 0)) {
      let arrObs: Array<Observable<Object>> = [
        this.onPremService.get('hierarchy/structureForFilter/' + this.clientSelectedArray.join(',')),
        this.onPremService.get('hierarchy/stateStructure/' + this.clientSelectedArray.join(','))
      ];
      this.loadingSpinnerService.show();
      forkJoin(arrObs).subscribe({
        next: (data) => {
          this.initCHInfo(data[0] as Array<CorporateStructure>, true);
          this.initCHByStateInfo(data[1] as Array<CorporateStructure>, true);
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
      //this.getCHInfo(null, null, true);
      //this.getCHByStateInfo(0, null, true);
    } else {
      this.arrCH = null;
      this.arrCHByState = null;
    }

    // listen for changes in date controls
    this.exportStartDateControl.valueChanges.subscribe(val => this.validateDates(val, this.exportEndDateControl.value));
    this.exportEndDateControl.valueChanges.subscribe(val => this.validateDates(this.exportStartDateControl.value, val));
  }

  ngAfterViewChecked(): void {
    if (!this.startDatePicker && this.startDatePickerList?.first) {
      this.startDatePicker = this.startDatePickerList.first;
      this.startDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.startDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.startDatePicker.select(event.value);
            this.startDatePicker.close();
          })
        }, 0)
      })
    }

    if (!this.endDatePicker && this.endDatePickerList?.first) {
      this.endDatePicker = this.endDatePickerList.first;
      this.endDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.endDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.endDatePicker.select(event.value);
            this.endDatePicker.close();
          })
        }, 0)
      })
    }
  }

  private initCHInfo(arrCH: Array<CorporateStructure>, selectAll: boolean) {
    this.arrCH = arrCH;
    if (selectAll) {
      this.arrCH.forEach(c => {
        c.boxChecked = true;
        c.indeterminate = false;
        c.isSelected = 1;
        // set updatedByUser so selections will pass to children
        c.updatedByUser = true;
        if (c.childElements) {
          c.childElements.forEach(c => {
            c.boxChecked = true;
            c.indeterminate = false;
            c.isSelected = 1;
            // set updatedByUser so selections will pass to children
            c.updatedByUser = true;
          });
        }
      });

    }
  }

  private initCHByStateInfo(arrCH: Array<CorporateStructure>, selectAll: boolean) {
    this.arrCHByState = arrCH;
    if (selectAll) {
      this.arrCHByState.forEach(c => {
        c.boxChecked = true;
        c.indeterminate = false;
        c.isSelected = 1;
        // set updatedByUser so selections will pass to children
        c.updatedByUser = true;
        if (c.childElements) {
          c.childElements.forEach(c => {
            c.boxChecked = true;
            c.indeterminate = false;
            c.isSelected = 1;
            // set updatedByUser so selections will pass to children
            c.updatedByUser = true;
          });
        }
      });
    }
  }

  getDrivers() {
    this.arrGroupGuid = this.getCheckedGroupGuidArray(this.arrCH);
    this.arrStateGuid = this.getCheckedGroupGuidArray(this.arrCHByState);
    if ((this.arrGroupGuid.length > 0) || (this.arrStateGuid.length > 0)) {
      this.selectionCriteria.clientCode = this.clientSelectedArray[0];
      this.selectionCriteria.groupGuidList = this.arrGroupGuid.join(",");
      this.selectionCriteria.stateGuidList = this.arrStateGuid.join(",");

      this.loadingSpinnerService.show();
      this.onPremService.post('mvr/toPdf/drivers', JSON.stringify(this.selectionCriteria)).subscribe({
        next: (data) => {
          this.arrDriver = data as Array<MvrExportDriverInfo>;
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    } else {
      this.arrDriver = [];
    }

  }

  private getCheckedGroupGuidArray(arrCH: Array<CorporateStructure>): Array<string> {
    // get guids of topmost checked entries
    let arrGuid: Array<string> = arrCH.filter(c => c.boxChecked).map(c => c.elementGuid);
    // get partially checked entries
    let arrEntryIndeterminate = arrCH.filter(c => c.indeterminate);
    // for each partially checked entry, add guids of checked sub entries
    arrEntryIndeterminate.forEach(cParent => {
      if (cParent && cParent.childElements) {
        arrGuid = arrGuid.concat(this.getCheckedGroupGuidArray(cParent.childElements));
      }

    });

    //console.log(arrGuid);
    return arrGuid;
  }

  updateDriverIdSelected(arrId: Array<number>) {
    this.arrDriverIdSelected = arrId;
  }

  updateDriverIdExcluded(arrId: Array<number>) {
    this.arrDriverIdExcluded = arrId;
  }

  onGoToCriteriaSelect() {
    this.myStepper.previous();
    this.cdRef.detectChanges();
  }

  onGoToDriverSelect() {
    this.myStepper.next();
    this.getDrivers();
  }

  criteriaValid() {
    return this.criteriaFor1TabFormGroup.valid;
  }
  private validateDates(valBeginDate: any, valEndDate: any): boolean {
    let isOK = true;

    // return date should be after begin date
    let beginDate = new Date(valBeginDate);
    let returnDate = new Date(valEndDate);
    if (returnDate < beginDate) {
      isOK = false;
      // set errors on date fields
      this.errorService.replaceErrorObject({ message: 'Through Date must be on or after From Date' });
      if (!this.exportEndDateControl.hasError('datevalidation')) {
        this.addValidationError(this.exportEndDateControl, 'datevalidation');
      }
    } else {
      // clear errors on date fields
      //this.errorService.clearErrorObject();
      this.removeValidationError(this.exportEndDateControl, 'datevalidation');
    }


    return isOK;
  }

  private addValidationError(ctrl: UntypedFormControl, key: string) {
    let errs: ValidationErrors = ctrl.errors;

    if (errs) {
      errs[key] = true;
    } else {
      errs = {};
      errs[key] = true;
    }
    ctrl.setErrors(errs);
  }

  private removeValidationError(ctrl: UntypedFormControl, key: string) {
    let errs: ValidationErrors = ctrl.errors;

    if (errs) {
      delete errs[key];
    }

    if (errs && (Object.keys(errs).length <= 0)) {
      errs = null;
    }

    ctrl.setErrors(errs);
  }

  submitCOVExport() {
    if (this.arrDriverIdSelected.length <= 0) {
      this.alertService.setAlertObject({
        title: 'COV(s) to PDF', message: 'Please select one or more drivers to include.'
      });
    } else {
      let postData = new MvrExportMVRSelectionCriteria();
      postData.ClientCode = this.clientSelectedArray[0];
      postData.BatchType = (this.selectionCriteria.mostRecent == "true" ? 1 : 0);
      postData.StartDate = this.selectionCriteria.startDate;
      postData.EndDate = this.selectionCriteria.endDate;
      postData.DriverIdList = this.arrDriverIdSelected.map(d => d.toString()).join(",");
      postData.DriverIdExcludeList = this.arrDriverIdExcluded.map(d => d.toString()).join(",");

      //console.log(postData);

      this.loadingSpinnerService.show();
      this.onPremService.post('cov/toPdf/batch',
        JSON.stringify(postData)
      ).subscribe({
        next: (data) => {
          this.loadingSpinnerService.hide();
          this.isSubmitted = true;
          this.alertService.setAlertObject({ title: 'COV(s) to PDF', message: 'Your COVs to PDF file is being processed. You will receive an email when it is available. \n\nNote: This process may take up to an hour depending on the size of the file.' })
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    }

  }

  calcTabHeading(num: number, txt: string) {
    return "<div class='mvrExportTabHeadingNumber'><div class='mvrExportTabHeadingNumberAlign'>" + num.toString() + "</div></div><div class='mvrExportTabHeading'>" + txt + "</div>";
  }

  private arrayCompare(a1, a2) {
    return (a1.length == a2.length && a1.every((v, i) => v === a2[i]));
  }

  //ngOnDestroy() {
  //this.clientSelectionSubscription.unsubscribe();
  //}
}

export class MvrExportDriverSelectionCriteria {
  clientCode: string;
  groupGuidList: string;
  stateGuidList: string;
  employeeType: string;
  startDate: string;
  endDate: string;
  mostRecent: string;

  constructor() { }
}

export class MvrExportDriverInfo {
  driverId: number;
  driverName: string;
  fullHierarchy: string;
  driverLicenseState: string;
  employee: string;
  mvrDate: string;
  riskLevel: string;

  constructor() { }
}

export class MvrExportMVRSelectionCriteria {
  ClientCode: string;
  DriverIdList: string;
  DriverIdExcludeList: string;
  StartDate: string;
  EndDate: string;
  BatchType: number;

  constructor() { }
}
