import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserRightsService } from '../user-rights-service/user-rights-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from "rxjs";
import { UserRightsInfo } from "../classes-and-interfaces/classes-and-interfaces.component";
import { LocationStrategy } from '@angular/common';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AccessDeniedComponent implements OnInit {



  constructor(private router: Router,
    private _route: ActivatedRoute,
    private loadingSpinnerService: LoadingSpinnerService,
  ) {
    _route.url.subscribe(x => {
      loadingSpinnerService.hide();
    });

  }

  ngOnInit(): void {

  }


}
