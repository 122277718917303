import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { DataGridService, GridData, ReportRequest } from './data-grid.service';

@Injectable()
export class DataGridResolve  {
  constructor(private dataGridService: DataGridService, private router: Router, private gridRequest: ReportRequest) {
    this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        //this.reloadGridData();
      }
    });
  }

    resolve(route: ActivatedRouteSnapshot): Promise<any> | boolean {
      let id = route.params["id"] as string;
     // let req1: ReportRequest = new ReportRequest();
      //req1.DataGridId = this.gridRequest.DataGridId;
      //console.log(id);

      if (!this.gridRequest.DataGridId) {
        try {
          let req1 = JSON.parse(sessionStorage.getItem('grid_' + id)) as ReportRequest;
          this.gridRequest.DataGridId = req1.DataGridId;
          this.gridRequest.ParameterValues = req1.ParameterValues;
          this.gridRequest.DateFilterTypeId = req1.DateFilterTypeId;

          this.gridRequest.SelectedClientIds = req1.SelectedClientIds;
          this.gridRequest.ParameterValues = req1.ParameterValues;
          this.gridRequest.StartDate = req1.StartDate;
          this.gridRequest.EndDate = req1.EndDate;
          this.gridRequest.clickTrough = req1.clickTrough;
        }
        catch { }

      }
      return this.dataGridService.getData(this.gridRequest).then(x => {
        if (x) {
          return x;
        } else { // id not found
          this.router.navigate(['/']);
          return false;
        }
      }).catch(e => {
        console.log(e)

        return e;
      });
    }
}
