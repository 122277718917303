<div class="row mt-2">
  <div class="col-md-12">
    <div class="row" style="padding-bottom:10px">
      <div class="col-md-2">
        <h4 class="text-muted">View Photos</h4>
      </div>
      <div class="col-md-10">
        <span style="float:right">
          <button type="button"
                  *ngIf="!isPlaying"
                  (click)="rotateLeft()"
                  class="btn btn-default btn-download-image">
            Rotate Left
          </button>
          <button type="button"
                  *ngIf="!isPlaying"
                  (click)="rotateRight()"
                  class="btn btn-default btn-download-image">
            Rotate Right
          </button>                        
          <button type="button"
                  *ngIf="!isPlaying"
                  (click)="downloadPhoto()"
                  class="btn btn-default btn-download-image">
            Download Claim Photo (Original Size)
          </button>
          <button type="button"
                  *ngIf="!isPlaying"
                  (click)="downloadPhotoReducedSize()"
                  class="btn btn-default btn-download-image">
            Download Claim Photo (REDUCED SIZE)
          </button>
          <button type="button"
                  *ngIf="!isPlaying"
                  (click)="playSlideshow()"
                  class="btn btn-default btn-download-image">
            Play Slideshow
          </button>
          <button type="button"
                  *ngIf="isPlaying"
                  (click)="pauseSlideShow()"
                  class="btn btn-default btn-download-image">
            Pause Slideshow
          </button>
          <button type="button"
                  (click)="closeSlideshowPanel()"
                  class="btn btn-default btn-download-image">
            Exit
          </button>
        </span>
      </div>
    </div>
  </div>
  <div class="slider col-md-12">
    <div>
      <div (click)="goToPrevious()" class="leftArrow">❰</div>
      <div (click)="goToNext()" class="rightArrow">❱</div>
    </div>
    <div class="slide" *ngFor="let image of arrPhotoInfo; let i = index">
      <img
        class="slider-image"
        [ngStyle]="{'transform': 'rotate('+currentRotationDegree+'deg)'}"
        [ngClass]="{'hidden': !isCurrentImage(i)}"
        [@fade]="isCurrentImage(i) ? 'visible' : 'hidden'"
        [src]="(baseUrl + 'api/Document/GetThumbnailImageById?DocumentGuid=' + image.documentGuid ) | image | async"
        [alt]="image.documentDesc"
        (load)="onImageLoad()" (error)="onImageLoad()">
    </div>
    <div class="carousel-dots mt-2">
      <img 
        *ngFor="let dotImage of arrPhotoInfo; let i = index" 
        [src]="(baseUrl + 'api/Document/GetThumbnailImageById?DocumentGuid=' + dotImage.documentGuid ) | image | async" 
        [class.active]="isCurrentImage(i)"
        (click)="goToSlide(i)">
    </div>
  </div>
</div>
