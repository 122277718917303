
import { throwError as observableThrowError, Subject, Observable, BehaviorSubject } from 'rxjs';
import { catchError } from "rxjs/operators";
import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DriverProfile } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

@Injectable()
export class SafetyDriverRankingService {
  baseUrl: string;
  errorMessage: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  getRankingByDriverId(DriverId: number): Observable<DriverRanking> {
    let params = new HttpParams();
    params = params.append('DriverId', DriverId.toString());
    return this.http.get<DriverRanking>(this.baseUrl + 'api/Safety/GetDriverRanking', {
      params: params
    }).pipe(
        catchError(this.handleError)
    );
  }
  
  /* HANDLES ENCOUNTERED ERRORS (I'LL TAKE THIS OUT AFTER TESTING) */
  private handleError(err: HttpErrorResponse) {
    return observableThrowError(err.error.message);
  }
}
export class DriverRanking{
  driverRankingValue: number;
  maxValue: number;
}
