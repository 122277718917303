//angular libraries
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
//thirdparty libraries
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
//project imports
import { environment } from './../../../environments/environment';
import { AzureDocument, AzureDocumentDataUpload } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { BlobStorageService } from '../blob-storage/blob-storage.service';
import { DocumentLibraryService } from '../document-library/document-library.service';
import { AddDocumentService } from '../../services/add-document-service/add-document.service';
import { DocumentUploadAction } from '../../enum/document-upload-azure.enum';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';


@Component({
  selector: 'document-upload-azure',
  templateUrl: './document-upload-azure.component.html',
  styleUrls: ['./document-upload-azure.component.scss']
})
export class DocumentUploadAzureComponent implements OnInit {
  @Input() uploadType: string;
  @Input() documents: Array<AzureDocument>;
  @Input() clientShortName: string;
  @Input() lineofBusinesssId: string;
  @Output() onDocUploadExit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDocUploadRefresh: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSortOrderSelected: EventEmitter<any> = new EventEmitter<any>();
  document: AzureDocument;
  sortOrderOptions: Array<any> = [];
  sortOrder: string;
  files: File[];
  fileDescription: string;
  fileTitle: string;
  addDoc: boolean = true;
  addEditDocTitle: string;
  lineOfBusiness: string;
  currentDoc: AzureDocument;
  allowUpload: boolean = true;
  clickFunction: Object;
  returnAzureDocInfo: AzureDocument;
  descriptionControl = new UntypedFormControl('', [Validators.required]);
  titleControl = new UntypedFormControl('', [Validators.required]);
  allControls: Array<UntypedFormControl> = [
    this.descriptionControl,
    this.titleControl,
  ];
  destroy$ = new Subject<void>();

  constructor(private errorService: ErrorModalService,
    private blobStorageService: BlobStorageService,
    private snackBar: MatSnackBar,
    private documentLibraryService: DocumentLibraryService,
    private loadingSpinnerService: LoadingSpinnerService,
    private addDocumentService: AddDocumentService) {
    this.files = [];
  }

  ngOnInit(): void {
    this.addDocumentService.documentUploadAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: { action: DocumentUploadAction, document?: AzureDocument }) => {
        console.log("subs", action)
        this.document = action?.document ?? null;
        this.triggerAction(action.action);
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  triggerAction(action: DocumentUploadAction): void {
    switch (action) {
      case DocumentUploadAction.addNewdocument: {
        this.populateSortOrderPullDown();
        break;
      }
      case DocumentUploadAction.edit: {
        this.populateSortOrderPullDown();
        this.initializeEditDocument(this.document);
        break;
      }
    }
  }

  public onUploadOutput(outputGeneral: any): void {
    // allow only one file at a time
    let output: any = outputGeneral.target.files[0];
    if (this.files.length <= 0) {
      this.files.push(output);
    } else {
      this.files[0] = output;
    }
    this.showFiles();
  }

  public showFiles(): string {
    let files = "";
    for (let i = 0; i < this.files.length; i++) {
      files += this.files[i].name
      if (!(this.files.length - 1 == i)) {
        files += ", "
      }
    }
    return files;
  }

  private validateForm(): boolean {
    let isOK: boolean = true;

    let controls = this.allControls.slice();
    if (controls.filter(c => (c.invalid)).length > 0) {
      this.errorService.setErrorObject({ message: 'Missing or Invalid Fields.' });
      isOK = false;
    }

    return isOK;
  }

  public uploadDocs(): void {
    if (this.validateForm()) {
      if (this.fileDescription && (this.fileDescription.length > 0)) {
        let fileCount: number = this.files.length;
        if (fileCount > 0 && fileCount < 2) {
          this.loadingSpinnerService.show();
          this.documentLibraryService.postDocData(this.convertFileToAzureDoc(this.files[0]), this.clientShortName, this.lineofBusinesssId)
            .subscribe({
              next: (data: AzureDocument) => {
                this.returnAzureDocInfo = data;
              },
              error: (err: HttpErrorResponse) => {
                this.loadingSpinnerService.hide();
                this.errorService.setErrorObject({ message: "There was an error posting the file." });
              },
              complete: () => {
                this.postDocToAzure(this.returnAzureDocInfo);
              }
            });
        } else {
          this.errorService.setErrorObject({ message: "Please choose only one file to upload" });
        }
      }
    }
  }

  public editDoc(): void {
    if (this.validateForm() && this.fileDescription) {
      this.currentDoc.description = this.fileDescription;
      this.currentDoc.uiSortOrder = +this.sortOrder;
      this.currentDoc.title = this.fileTitle;
      this.loadingSpinnerService.show();
      this.documentLibraryService.updateDocInformation(this.currentDoc)
        .subscribe({
          next: () => {
            this.loadingSpinnerService.hide();
            this.snackBar.open(`Document Updated Sucessfully. \nDocument Library Update`, '', {
              horizontalPosition: 'end',
              verticalPosition: 'top',
              duration: 5000,
              panelClass: 'success-snackbar'
            })
            this.closeUploadPanelAndRefresh();
          },
          error: (err: HttpErrorResponse) => {
            this.loadingSpinnerService.hide();
            this.errorService.setErrorObject({ message: "There was an error updating the file." });
          }
        });
    }
  }

  private convertFileToAzureDoc(file: any): AzureDocumentDataUpload {
    let azureDocument: AzureDocumentDataUpload = {
      fileName: file.name,
      title: this.fileTitle,
      description: this.fileDescription,
      uiSortOrder: parseInt(this.sortOrder),
      isVisible: true
    }
    return azureDocument;
  }

  private postDocToAzure(blobData: AzureDocument) {
    this.blobStorageService.uploadBlob(blobData, this.files[0], environment.storageUriDocumentLibrary)
      .then(blobResponse => {
        this.documentLibraryService.showDocument(blobData.clientDocumentId).subscribe({
          next: () => {
            this.loadingSpinnerService.hide();
            this.snackBar.open(`Document Uploaded Sucessfully. \nDocument Library Upload`, '', {
              horizontalPosition: 'end',
              verticalPosition: 'top',
              duration: 5000,
              panelClass: 'success-snackbar'
            })
            this.closeUploadPanelAndRefresh();
          },
          error: (err: HttpErrorResponse) => {
            this.loadingSpinnerService.hide();
            this.errorService.setErrorObject({ message: "There was an error uploading the document. Please try again later." });
          }
        })
      })
      .catch(error => {
        // call to data service to revert data
        this.loadingSpinnerService.hide();
        this.documentLibraryService.rollbackDocument(blobData.clientDocumentId).subscribe();
        this.errorService.setErrorObject({ message: "There was an error uploading the document. Please try again later." });
        console.error(error);
      });
  }

  public populateSortOrderPullDown(): void {
    this.initalizeFormFields();
    if (this.documents.length > 0) {
      this.sortOrderOptions = [];
      this.documents.forEach((item: AzureDocument, index: number) => {
        const tempobj = {
          value: item.uiSortOrder.toString(),
          label: item.uiSortOrder.toString()
        }
        this.sortOrderOptions.push(tempobj);
      });
      let highestSortOrder = 0;
      this.sortOrderOptions.forEach(sortOrderOptions => {
        if (Number(sortOrderOptions["value"]) > highestSortOrder) {
          highestSortOrder = parseInt(sortOrderOptions["value"]);
        }
      });
      // Set the selected to be the next 
      highestSortOrder++;
      this.sortOrderOptions.push({ value: highestSortOrder.toString(), label: highestSortOrder.toString(), selected: true });
      this.sortOrder = highestSortOrder.toString()
    } else {
      this.sortOrderOptions = [];
      const tempobj = { value: "1", label: "1", selected: true };
      this.sortOrder = "1";
      this.sortOrderOptions.push(tempobj);
    }
  }

  public closeUploadPanel(): void {
    this.onDocUploadExit.emit();
  }

  public closeUploadPanelAndRefresh(): void {
    this.onDocUploadRefresh.emit();
    this.onDocUploadExit.emit();
  }

  public initalizeFormFields() {
    this.allowUpload = true;
    this.addEditDocTitle = "Add New Document";
    this.clickFunction = this.uploadDocs;
    this.files = [];
    this.fileTitle = "";
    this.fileDescription = "";
    this.addDoc = true;
  }

  public initializeEditDocument(azureDocument: AzureDocument) {
    this.allowUpload = false;
    this.addDoc = false;
    this.addEditDocTitle = "Edit Document";
    this.clickFunction = this.editDoc;
    this.fileDescription = azureDocument.description;
    this.fileTitle = azureDocument.title;
    this.sortOrder = azureDocument.uiSortOrder.toString();
    this.currentDoc = azureDocument;
  }

  public onSortOrderSelect(): void {
    let emitObj = {};
    emitObj["sortOrderSelected"] = +this.sortOrder;
    emitObj["sortOrder"] = this.sortOrderOptions.find(c => c.value == this.sortOrder).label;
    this.onSortOrderSelected.emit(emitObj);
  }

}
