import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {

  private isLoadingSubject = new Subject<boolean>();
  isLoading$ = this.isLoadingSubject.asObservable();
  private isUploadingDocumentSubject = new Subject<boolean>();
  isUploadingDocument$ = this.isUploadingDocumentSubject.asObservable();

  show(): void {
    this.isLoadingSubject.next(true);
  }

  hide(): void {
    this.isLoadingSubject.next(false);
  }

  showUploadMessage(): void {
    this.isUploadingDocumentSubject.next(true);
  }

  hideUploadMessage(): void {
    this.isUploadingDocumentSubject.next(false);
  }
}
