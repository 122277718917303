import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable ,  Subject } from "rxjs";
import { DashboardDateFilterRange } from "../classes-and-interfaces/classes-and-interfaces.component";

@Injectable()
export class DateFilterService {
  baseUrl: string;

  private globalDateFilter = new Subject();
  globalDateFilter$ = this.globalDateFilter.asObservable();

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private datePipe: DatePipe,
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  getGlobalDateFilter(LineOfBusinessId: number) {
    let params = new HttpParams();
    params = params.append('LineOfBusinessId', LineOfBusinessId.toString());

    return this.http.get(this.baseUrl + 'api/UserFilter/GetDateRange', {
      params: params
    });
  }

  getGlobalDateRangeOptions() {
    return this.http.get(this.baseUrl + 'api/UserFilter/GetDateRangeOptions');
  }  

  updateGlobalDateFilter(dateRange: DashboardDateFilterRange, LineOfBusinessId: number, rollingOptionID: string = null) {
    let params = new HttpParams();
    params = params.append('StartDate', this.datePipe.transform(dateRange.startDate, 'yyyy-MM-dd'));
    params = params.append('EndDate', this.datePipe.transform(dateRange.endDate, 'yyyy-MM-dd'));
    params = params.append('LineOfBusinessId', LineOfBusinessId.toString());
    params = params.append('RollingTypeId', rollingOptionID);

    return this.http.post(this.baseUrl + 'api/UserFilter/SetDateRange', null, {
      params: params
    })
  }

  setGlobalDateFilter(dateRange: DashboardDateFilterRange) {
    this.globalDateFilter.next(dateRange);
  }
  savePerReportDateFilter(reportId: number, startDate: string, endDate: string) {

    let dateRange: DashboardDateFilterRange = { startDate: startDate, endDate: endDate };

    localStorage.setItem(this.getLocalStorateItemKeyForReport(reportId), JSON.stringify(dateRange));
  }
  private getLocalStorateItemKeyForReport(reportId: number) {
    return ("ReportDateRange_" + reportId);
  }

  getReportDateRangeFilter(reportId: number, lineOfBusinessId: number): Observable<any> {
    if (localStorage[this.getLocalStorateItemKeyForReport(reportId)]) {
      let result: DashboardDateFilterRange = JSON.parse(localStorage[this.getLocalStorateItemKeyForReport(reportId)]);
      return new Observable(x => {
        x.next(result);
      });
    }
    return this.getGlobalDateFilter(lineOfBusinessId);
    
  }
}
