//Angular
import { Component, Inject, Input, Output, EventEmitter, OnInit, NgModule, ViewChild, AfterViewInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
//Third Party
import { MatDatepicker } from '@angular/material/datepicker';
//APP
import { DateFilterService } from '../date-filter-service/date-filter-service.component';
import { DashboardDateFilterRange } from "../classes-and-interfaces/classes-and-interfaces.component";
import { DashboardService } from "../dashboard-service/dashboard-service.component";
import { ErrorModalService } from "../error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-dashboard-date-select',
  templateUrl: './dashboard-date-select.component.html',
  styleUrls: ['./dashboard-date-select.component.scss']
})

export class DashboardDateSelectComponent implements OnInit, AfterViewInit {
  dateRange: DashboardDateFilterRange = new DashboardDateFilterRange();
  @Output() onDateSelectExit: EventEmitter<any> = new EventEmitter<any>();
  @Input() lineOfBusinessId: number;
  @ViewChild('startDatePicker') startDatePicker: MatDatepicker<Date>;
  @ViewChild('endDatePicker') endDatePicker: MatDatepicker<Date>;
  rollingOptions: any = [];
  selectedRollingOption: string = "";
  manuallyEnteredDisabled: boolean = false;
  useRollingDatesDisabled: boolean = true;
  dateRangeOptions: Array<DateRangeOption> = [];
  rollingOptionInvalid: boolean = false;
  manualRadioChecked: any;
  rollingRadioChecked: any;
  actualDate = new Date();


  constructor(private dateFilterService: DateFilterService,
    private dashboardService: DashboardService,
    @Inject('BASE_URL') baseUrl: string,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService
  ) { }

  ngOnInit(): void {
    this.getDateRangeOptions();
  }

  ngAfterViewInit(): void {
    this.startDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.startDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
          this.startDatePicker.select(event.value);
          this.startDatePicker.close();
        })
      }, 0)
    })

    this.endDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.endDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
          this.endDatePicker.select(event.value);
          this.endDatePicker.close();
        })
      }, 0)
    })
  }

  private loadOptionRollingOptionDrillDown(data: DateRangeOption[]): Array<any> {
    const rollingOptionsArray: Array<any> = [];
    data.forEach(function (dateRangeOption) {
      rollingOptionsArray.push({
        value: dateRangeOption.rollingTypeId.toString(),
        label: dateRangeOption.description,
      })
    });
    return rollingOptionsArray;
  }


  private getDateRangeOptions(): void {
    this.loadingSpinnerService.show();
    this.dateFilterService.getGlobalDateRangeOptions().subscribe({
      next: (data) => {
        this.dateRangeOptions = data as DateRangeOption[];
        this.loadingSpinnerService.hide();
      },

      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      },
      complete: () => { // complete
        this.rollingOptions = this.loadOptionRollingOptionDrillDown(this.dateRangeOptions as DateRangeOption[]);
        this.getDates(false);
      }
    }
    );
  }

  private getDates(setGlobalDates: boolean): void {
    this.loadingSpinnerService.show();

    this.dateFilterService.getGlobalDateFilter(this.lineOfBusinessId).subscribe({
      next: (data) => {
        this.dateRange = data as DashboardDateFilterRange;
        this.dateRange.startDate = this.dateRange.startDate;
        this.dateRange.endDate = this.dateRange.endDate;
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      },
      complete: () => { // complete, prepopulate fields based on users saved selections
        this.prepopulate(setGlobalDates);
      }
    }
    )
  }

  private prepopulate(setGlobalDates: boolean): void {
    if (this.dateRange.rollingTypeId != null) {
      if (setGlobalDates) {
        this.dateFilterService.setGlobalDateFilter(this.dateRange);
      }
      this.manuallyEnteredDisabled = true;
      this.useRollingDatesDisabled = false;
      this.manualRadioChecked = false;
      this.rollingRadioChecked = true;
      this.selectedRollingOption = this.dateRange.rollingTypeId.toString();

    } else {
      this.manuallyEnteredDisabled = false;
      this.useRollingDatesDisabled = true;
      this.manualRadioChecked = true;
      this.rollingRadioChecked = false;
    }
  }

  public saveDates(): void {
    if (!this.manuallyEnteredDisabled) {
      this.saveManualDates();
    } else {
      this.saveRollingDates();
    }
  }

  private saveManualDates(): void {
    if ((this.dateRange.startDate.toString().length) && (this.dateRange.endDate.toString().length)) {
      this.loadingSpinnerService.show();
      this.dateFilterService.updateGlobalDateFilter(
        this.dateRange,
        this.lineOfBusinessId
      ).subscribe({
        next: (data) => {
          this.dateFilterService.setGlobalDateFilter(this.dateRange);
          this.onDateSelectExit.emit('closePanels');
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      }
      )
    } else {
      this.errorService.setErrorObject({ message: 'Dates cannot be empty' });
    }

  }

  private saveRollingDates(): void {
    // validate rolling options
    this.rollingOptionInvalid = false;
    if (this.selectedRollingOption == "") {
      this.rollingOptionInvalid = true;
      this.errorService.setErrorObject({ message: 'You must select a Rolling Option' });
    }

    if (!this.rollingOptionInvalid) {
      // update options
      this.loadingSpinnerService.show();
      this.dateFilterService.updateGlobalDateFilter(
        this.dateRange,
        this.lineOfBusinessId,
        this.selectedRollingOption

      ).subscribe({
        next: (data) => { },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        },
        complete: () => { // complete, getdates since it's calculated on server 
          this.getDates(true);
          this.onDateSelectExit.emit('closePanels');
          this.loadingSpinnerService.hide();
        }
      }
      )
    }
  }


  public disableDates(disableDates: boolean): void {
    this.manualRadioChecked = !disableDates;
    this.rollingRadioChecked = disableDates;
    this.manuallyEnteredDisabled = disableDates;
    this.selectedRollingOption = "";
    this.useRollingDatesDisabled = !disableDates;
  }


  public closeDateSelectPanel(): void {
    this.onDateSelectExit.emit('closePanels');
  }
}

export interface DateRangeOption {
  rollingTypeId: number;
  description: string;
}

