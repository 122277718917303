<div class="card card-body mt-3" *ngIf="companyOptions">
  <div class="row extra-margin">
    <div class="col-12">
      <mat-label>Company</mat-label>
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Choose Company</mat-label>
        <mat-select>
        <mat-option (onSelectionChange)="onCompanySelect($event)" *ngFor="let option of companyOptions" [value]="option.value">
            {{option.label}}
          </mat-option>
        </mat-select>
        <mat-divider></mat-divider>
      </mat-form-field>
    </div>
  </div>
</div>

