import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { DcDriverProfileService } from '../dc-driver-profile/dc-driver-profile-service.component';
import { AlertModalService } from "../../components/alert-modal/alert-modal-service.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { DcSubscription, DcRequestResponse } from '../dc-classes-and-interfaces/dc-classes-and-interfaces.component';
import { DatePipe } from '@angular/common';
import { AssignProductToDriverConfirmObject } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'dc-driver-assign-program',
  templateUrl: './dc-driver-assign-program.component.html',
  styleUrls: ['./dc-driver-assign-program.component.css'],
})
export class DcDriverAssignProgramComponent implements OnInit {
  @ViewChild(ConfirmModalComponent) confirmModalContainer: ConfirmModalComponent;
  @Input() modalHeight: string;
  @Input() modalInput: any;
  companyId: number;
  driverId: number;
  programOptions: Array<any>;
  programChosen: string = "";
  assignConfirmed: boolean = false;
  confirmMessage: string = "";

  // the overloaded constructor for the controller
  constructor(private dcService: DcDriverProfileService,
    private onPremService: OnPremDcService,
    private loadingSpinnerService: LoadingSpinnerService,
    private datePipe: DatePipe,
    private alertService: AlertModalService,
    private errorService: ErrorModalService,
    private dialog: MatDialog) { }

  // angular on intialization event
  ngOnInit() {
    this.companyId = this.modalInput.companyId;
    this.driverId = this.modalInput.driverId;
    this.loadingSpinnerService.show();
    this.onPremService.get("drivers/" + this.driverId.toString() + "/products/available").subscribe({
      next: (data) => {
        let arrProgram = data as Array<DcSubscription>;
        let programChoices = arrProgram.filter(p => p.slotsAvailable > 0);
        this.loadingSpinnerService.hide();
        if (programChoices.length > 0) {
          this.programOptions = programChoices.map(i => {
            // value should include expiration date if available
            let dt = (i.expirationDate ? "~" + i.expirationDate : '');
            return {
              value: i.product.id.toString() + dt,
              label: i.product.description + (i.expirationDate ? " - Exp: " + this.datePipe.transform(i.expirationDate, "MM/dd/yyyy") : "")
            }
          });
        } else {
          //no programs with open slots, open alert modal
          this.dcService.notifyModalClose();
          let htmlMessage = 'There are no available slots for any programs.';
          htmlMessage += '<br/><br/>To purchase additional programs, training lessons, or driver slots, please <a href="http://safety.fleetresponse.com" target="_blank" rel="noopener noreferrer">click here</a>';
          this.alertService.setAlertObject({ title: 'No Slots Available', htmlMessage: htmlMessage })
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    })
  }

  public assignProgram(): void {
    let parts = this.programChosen.split("~");
    let endpoint = "companies/" + this.companyId.toString() + "/products/" + parts[0] + "/drivers/" + this.driverId.toString();
    // add expiration date param if date is part of value
    if (parts.length > 1) {
      endpoint += "?expirationDate=" + parts[1];
    }

    this.onPremService.put(endpoint, null).subscribe({
      next: (data) => {
        let resp = data as DcRequestResponse;
        if (!resp.isSuccess) {
          this.errorService.replaceErrorObject({ message: resp.errorMessage });
        } else {
          this.dcService.setRefreshDriverProfileBaseData(this.driverId);
          this.dcService.notifyModalClose();
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
      }
    })
  }

  public confirmAssignment(): void { // only need to show a confirm if they are in TEMP and adding CSP, API will return a flag to say if the confirm should show
    let parts = this.programChosen.split("~");
    let endpoint = "companies/" + this.companyId.toString() + "/products/" + parts[0] + "/drivers/" + this.driverId.toString() + "/confirm/";
    let expDate = "";
    if (parts.length > 1) {
      expDate = parts[1];
    }
    this.onPremService.post(endpoint, `{ expirationDate: ${expDate} }`).subscribe({
      next: (data) => { // next
        let resp = data as AssignProductToDriverConfirmObject;
        if (resp.userFacingMessageDefinitionId > 0) {
          this.confirmMessage = resp.messageBody;
          this.assignConfirmed = false;
        } else {
          this.assignConfirmed = true;
        }
      },
      error: (err: HttpErrorResponse) => { // error
        this.errorService.setErrorObject(err.error);
      },
      complete: () => { // complete
        if (this.assignConfirmed) {
          this.assignProgram();
        } else {
          this.showConfirmModal();
        }
      }
    })
  }

  private showConfirmModal(): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: { confirmTitle: "Confirm Assignment", confirmPrompt: this.confirmMessage },
      width: '34em',
      minHeight: '13em',
      panelClass: 'alert-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.assignProgram();
    })
  }

  public cancel(): void {
    // notify the modal window to close
    this.dcService.notifyModalClose();
  }
}

