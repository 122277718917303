//Angular
import { Component, Inject, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
//Third Party
import { Observable, forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
//APP
import { WidgetInfo, LineOfBusinessInfo } from "../classes-and-interfaces/classes-and-interfaces.component";
import { ConfirmModalComponent } from "../confirm-modal/confirm-modal.component";
import { ErrorModalService } from "../error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-widget-list',
  templateUrl: './widget-list.component.html',
  styleUrls: ['./widget-list.component.scss']
})
export class WidgetListComponent implements OnInit {
  @ViewChild(ConfirmModalComponent) removeConfirmModalContainer: ConfirmModalComponent;
  widgetList: Array<WidgetInfo>;
  widgetListFiltered: Array<WidgetInfo>;
  widgetToRemove: WidgetInfo = null;
  baseUrl: string;
  lineOfBusinessChoices: Array<any>;
  lineOfBusinessFilter: string;

  constructor(private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private dialog: MatDialog
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit() {
    this.initAll();
  }

  private showConfirmModal(): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: { confirmTitle: "Delete Widget?", confirmPrompt: `Delete ${this.widgetToRemove?.title}?` },
      width: '34em',
      minHeight: '13em',
      panelClass: 'alert-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.removeWidget(this.widgetToRemove.tileId);
      }
    })
  }

  private initAll() {
    let arrObs: Array<Observable<Object>> = [
      this.http.get(this.baseUrl + 'api/AdminTile/Get'),
      this.http.get(this.baseUrl + 'api/UserFilter/LineOfBusinesses')
    ];
    this.loadingSpinnerService.show();
    let obsSub = forkJoin(arrObs).subscribe({
      next: (data) => {
        this.widgetList = data[0] as Array<WidgetInfo>;
        this.widgetListFiltered = this.widgetList;
        let lobInfo = data[1] as Array<LineOfBusinessInfo>;
        let lobChoices: Array<any> = [{ value: "0", label: "-- No Filter --" }];
        this.lineOfBusinessChoices = lobChoices.concat(lobInfo.map(c => { return { value: c.lineOfBusinessId.toString(), label: c.lineOfBusinessName } }));
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        if (err.status != 403)
          this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private getWidgetList() {
    this.loadingSpinnerService.show();
    this.http.get(this.baseUrl + 'api/AdminTile/Get').subscribe({
      next: (data) => {
        this.widgetList = data as Array<WidgetInfo>;
        this.applyLineOfBusinessFilter(this.lineOfBusinessFilter);
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        if (err.status != 403)
          this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    })
  }

  applyLineOfBusinessFilter(strLOB: string) {
    try {
      let lob = parseInt(strLOB, 10);
      if (lob > 0) {
        this.widgetListFiltered = this.widgetList.filter(w => w.lineOfBusinessId == lob);
      } else {
        this.widgetListFiltered = this.widgetList;
      }

    } catch (e) {
      this.widgetListFiltered = this.widgetList;
    }
  }

  enableWidget(tileId: number, enabled: boolean) {
    let params = new HttpParams();
    params = params.append('TileId', tileId.toString());
    params = params.append('active', enabled.toString());

    this.http.post(this.baseUrl + 'api/AdminTile/Activate', null, {
      params: params
    }).subscribe({
      next: (data) => {
        this.getWidgetList();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        if (err.status != 403)
          this.errorService.setErrorObject(err.error);
      }
    }
    )
  }

  addNewWidget() {
    this.router.navigateByUrl('/admin/widgetedit/');
  }

  confirmRemoveWidget(widget: WidgetInfo) {
    this.widgetToRemove = widget;
    this.showConfirmModal();
  }

  removeWidget(widgetId: number) {
    let params = new HttpParams();
    params = params.append('TileId', widgetId.toString());

    this.loadingSpinnerService.show();
    this.http.delete(this.baseUrl + 'api/AdminTile/DeleteTile', {
      params: params
    }).subscribe({
      next: (data) => {
        this.loadingSpinnerService.hide();
        this.widgetToRemove = null;
        this.getWidgetList();
        this.closeRemoveConfirmModal();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        if (err.status != 403)
          this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    }
    )
  }

  closeRemoveConfirmModal() {
    this.widgetToRemove = null;
  }

}
