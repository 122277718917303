<div class="modal-content">
  <div class="modal-header">

    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="cancel()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="labelDqRejectModal">Confirm Removal</h4>
  </div>

  <div id="modalBody" class="modal-body">
    <div class="row">
      <div class="description">
        <div>Are you sure you want to remove this document?</div>
        <span>{{ documentData.documentType }}</span>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="openConfirmationModal()">Confirm Removal</button>
    <button class="btn btn-primary" (click)="cancel()">Cancel</button>
  </div>
</div>
