import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-card-collapsible',
  templateUrl: './card-collapsible.component.html',
  //styleUrls: ['./card-collapsible.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class CardCollapsibleComponent {
    @Input() card: any;
}
