<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight, 'overflow-y':'auto'}">
  <div class="card card-body mt-3">
    <div class="row">
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        Last, First Name:
      </div>
      <div class="col-sm-9 readOnlyFormLayoutBold">
        {{managerReviewInfo?.lastName}}, {{managerReviewInfo?.firstName}}
      </div>

    </div>
    <div class="row">
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        Hire Date:
      </div>
      <div class="col-sm-9 readOnlyFormLayoutBold">
        {{managerReviewInfo?.hireDate | date:'MM/dd/yyyy'}}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        Employee ID:
      </div>
      <div class="col-sm-9 readOnlyFormLayoutBold">
        {{managerReviewInfo?.employeeId}}
      </div>
    </div>

  </div>

  <app-loading-panel *ngIf="!tableConfig"></app-loading-panel>
  <div *ngIf="tableConfig" style="padding-top:1rem;">
    <app-data-table [columns]="tableConfig.fields" [data]="tableConfig.data"
      noRecordsLabel="No Review Detail information"></app-data-table>
  </div>

  <div class="card card-body mt-3">
    <div class="row">
      <div class="col-sm-12" style="font-weight:bold">
        This day I reviewed the driver record of the above named driver in accordance with 391.25 of the Federal Motor
        Carrier Safety Regulations and the Hazardous Materials Regulations.
        I considered the driver's accident record and any evidence that he/she has violated laws governing the operation
        of motor vehicles,
        and gave great weight to violations such as speeding, reckless driving and operating under the influence of
        alcohol or drugs,
        that indicated that the driver has exhibited a disregard for safety to the public.
      </div>
    </div>
    <div class="row rem1-margin">
      <div class="col-sm-12">
        Having done the above, I find that:
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="switch">
          <label>
            <input type="checkbox" [checked]="driverQualified" [(ngModel)]="driverQualified"
              (change)="toggleDriverQualified()" />
            <span class="lever"></span>
            <span [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverQualified}">The driver meets the minimum
              requirements for safe driving</span>
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        OR
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="switch">
          <label>
            <input type="checkbox" [checked]="driverDisqualified" [(ngModel)]="driverDisqualified"
              (change)="toggleDriverDisqualified()" />
            <span class="lever"></span>
            <span [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverDisqualified}">The driver is disqualified
              to drive a motor vehicle pursuant to 391.25</span>
          </label>
        </div>
      </div>
    </div>
    <div class="row rem1-margin">
      <div class="col-sm-6">
        <div class="md-form">
          <input id="supervisorFirstName" type="text" [(ngModel)]="supervisorFirstName" class="form-control"
            [formControl]="supervisorFirstNameControl" placeholder="Supervisor's First Name" />
          <label for="supervisorFirstName"
            [ngClass]="{ 'label-validation-error': supervisorFirstNameControl.invalid, 'active':true }">Supervisor's
            First Name</label>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="md-form">
          <input id="supervisorLastName" type="text" [(ngModel)]="supervisorLastName" class="form-control"
            [formControl]="supervisorLastNameControl" placeholder="Supervisor's Last Name" />
          <label for="supervisorLastName"
            [ngClass]="{ 'label-validation-error': supervisorLastNameControl.invalid, 'active':true }">Supervisor's Last
            Name</label>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button *ngIf="false" class="btn btn-default" style="margin-bottom:6px" (click)="testRefresh()">
    test Refresh
  </button>
  <button class="btn btn-default" style="margin-bottom:6px"
    [disabled]="supervisorFirstNameControl.invalid||supervisorLastNameControl.invalid||!(driverQualified||driverDisqualified)"
    (click)="submit()">
    Sign Off
  </button>
  <button class="btn btn-default" style="margin-bottom:6px" (click)="cancel()">
    Exit
  </button>
</div>