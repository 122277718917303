//angular libraries
import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UntypedFormControl, Validators, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
//thirdparty libraries
import { MatDatepicker } from '@angular/material/datepicker';
import { Subscription } from 'rxjs';
//project dependencies
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { DcDriverProfileService } from '../dc-driver-profile/dc-driver-profile-service.component';
import { SafetyModalWindowService } from '../../safety/safety-modal-window/safety-modal-window-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { OnPremTimeclockService } from '../../components/on-prem-service/on-prem-timeclock-service.component';
import { DcTimeEntry } from '../dc-classes-and-interfaces/dc-classes-and-interfaces.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'dc-driver-time-clock-dialog',
  templateUrl: './dc-driver-time-clock-dialog.component.html',
  styleUrls: ['./dc-driver-time-clock-dialog.component.scss']
})
export class DcDriverTimeClockDialogComponent implements OnInit, AfterViewInit {
  @Input() modalHeight: string;
  @Input() modalInput: any;

  baseUrl: string;
  formValidated: boolean = false;
  startDateString: string;
  startDate: string;
  endDate: string;
  beginDateControl = new UntypedFormControl('', [Validators.required]);
  returnDateControl = new UntypedFormControl('', [Validators.required]);
  editMode: boolean = false;
  totalHours: number = 0;
  // date vars for yesterday and today, with time stripped out
  // correct values are set in constructor
  private dtYesterday: Date = new Date((new Date()).toDateString());
  private dtTomorrow: Date = new Date((new Date()).toDateString());
  @ViewChild('startDatePicker') startDatePicker: MatDatepicker<Date>;
  actualDate = new Date();

  allControls: Array<UntypedFormControl> = [
    this.beginDateControl,
    this.returnDateControl
  ];

  arrEntryDetail: Array<DcTimeEntry>

  // the overloaded constructor for the controller
  constructor(private router: Router,
    private dcService: DcDriverProfileService,
    private safetyModalWindowService: SafetyModalWindowService,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private onPremService: OnPremTimeclockService,
    private loadingSpinnerService:LoadingSpinnerService,
    private errorService: ErrorModalService,
    private datepipe: DatePipe) {

    
    //let date = new Date();
    //date.setDate(date.getDate() - 7);
    //this.startDate = date.toDateString();
    //this.goToDate();

    //let date = new Date();
    //date.setDate(date.getDate() - 7);
    //this.startDateString = date.toDateString();
    //this.dtYesterday.setDate(this.dtYesterday.getDate() - 1);
    //this.dtTomorrow.setDate(this.dtTomorrow.getDate() + 1);
    //// do not allow date picker start before todays date
    //this.myDatePickerOptions.disableUntil = { year: this.dtYesterday.getFullYear(), month: this.dtYesterday.getMonth() + 1, day: this.dtYesterday.getDate() };


  }

  ngAfterViewInit(){
    this.startDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.startDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.startDatePicker.select(event.value);
            this.startDatePicker.close();
        })
    },0)
    });
  }
  ngOnInit() {
    if (this.modalInput && this.modalInput.driverId) {
      let date = new Date();
      date.setDate(date.getDate() - 7);
      this.startDate = date.toDateString();
      this.goToDate();
    }
  }

  goToDate() {
    //this.startDate.to
    if (!this.startDate)
      return;

    this.startDateString = this.startDate;
    let date = new Date(this.startDateString);
    date.setDate(date.getDate() + 7);
    this.endDate = date.toDateString();
    this.startDate = "";



    //get entries
    this.getEntries();
  }

  getEntries() {
    this.loadingSpinnerService.show();
    if (this.startDateString && this.endDate) {
      this.onPremService.get("entries/" + this.modalInput.driverId + "?startDate=" + this.startDateString + "&endDate=" + this.endDate).subscribe({
        next: (data) => {
          this.arrEntryDetail = data as Array<DcTimeEntry>;
          if (this.arrEntryDetail) {
            this.totalHours = this.arrEntryDetail
              .map(item =>item.TotalHours)
              .reduce((prev, curr) => prev + curr, 0) as number;
          }
          else {
            this.totalHours = 0;
          }
          this.loadingSpinnerService.hide();
        },
        error: (err) => {
          this.loadingSpinnerService.hide();
          this.errorService.setErrorObject(err);
        }
      });
    }
  }
  private validateDates(): boolean {
    let isOK = true;

    // return date should be after begin date
    let beginDate = new Date(this.beginDateControl.value);
    let returnDate = new Date(this.returnDateControl.value);
    if (returnDate <= beginDate) {
      isOK = false;
      // set errors on date fields
      this.errorService.setErrorObject({ message: 'Return Date must be after Begin Date' });
      this.addValidationError(this.returnDateControl, 'datevalidation');
    } else {
      // clear errors on date fields
      this.removeValidationError(this.returnDateControl, 'datevalidation');
    }


    return isOK;
  }

  private validateForm(): boolean {
    let isOK: boolean = true;

    // validate dates
    isOK = this.validateDates();

    // filter out errors related to other validations
    // and add message for missing/invalid
    if (this.allControls.map(c1 => {
      let errs = c1.errors;
      if (errs) {
        delete errs['datevalidation'];
        if (Object.keys(errs).length <= 0) {
          errs = null;
        }
      }
      return errs;
    }).filter(c2 => c2 != null).length > 0) {
      this.errorService.setErrorObject({ message: 'Missing or Invalid Fields.' });
      isOK = false;
    }

    return isOK;
  }

  private addValidationError(ctrl: UntypedFormControl, key: string) {
    let errs: ValidationErrors = ctrl.errors;

    if (errs) {
      errs[key] = true;
    } else {
      errs = {};
      errs[key] = true;
    }
    ctrl.setErrors(errs);
  }

  private removeValidationError(ctrl: UntypedFormControl, key: string) {
    let errs: ValidationErrors = ctrl.errors;

    if (errs) {
      delete errs[key];
    }

    if (errs && (Object.keys(errs).length <= 0)) {
      errs = null;
    }

    ctrl.setErrors(errs);
  }

  submit() {
    if (this.validateForm()) {
     

    }
  }


  onEditStopped(obj) {
    this.editMode = false;
    this.getEntries();
  }
  onEntryUpdated(obj) {
    this.getEntries();
  }
  cancel() {

    // notify the modal window to close
    this.dcService.notifyModalClose();
  }


  // provides a central point for handling errors
  handleError(message: string) {

  }
  edit() {
    //this.ngOnInit();
    this.editMode = !this.editMode;
  }
  // sleeps for a certain number of milliseconds
  sleep(milliseconds: number) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }
}
