import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class DHPUploadDocumentsService {
  baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  // download a document
  downloadDocument() {
    console.log('document downloaded');
  }

  // upload a document
  uploadDocument(desc: string, fileData: FormData) {
    let params = new HttpParams();
    params = params.append('Description', desc);

    return this.http.post(this.baseUrl + 'api/Document/Upload',
      fileData,
      { params: params }
    );
  }
}
