export class DriverNote {
  driverId: number;
  isNotification: boolean;
  note: string;

  constructor(driverId: number) {
    return {
      driverId: driverId,
      isNotification: false,
      note: null
    }
  }
}
