//angular libraries
import { Component, Inject, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router'
import { DatePipe } from '@angular/common';
//thirdparty libraries
import { Subscription } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
//project dependencies
import { ClientSelectionService, ClientSelectionsObject } from '../client-selection-service/client-selection-service.component'
import { DataGridFilterValue } from '../data-grid/data-grid.component'
import { DataGridService, ReportRequest } from '../data-grid/data-grid.service';
import { DataGrid } from '../data-grid-menu-component/data-grid-menu.component'
import { DataGridModalService, DataGridFilter } from './data-grid-filter-modal.service'
import { DateFilterService } from '../date-filter-service/date-filter-service.component';
import { DashboardDateFilterRange } from '../classes-and-interfaces/classes-and-interfaces.component';
import { LineOfBusinessService } from '../line-of-business-service/line-of-business-service.component';
import { ScrollToTopService } from '../../shared/scroll-to-top-service/scroll-to-top.service';
import { AlertModalService } from '../alert-modal/alert-modal-service.component';
import { ErrorModalService } from '../error-modal/error-modal-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-data-grid-filter-modal',
  templateUrl: './data-grid-filter-modal.component.html',
  styleUrls: ['./data-grid-filter-modal.component.scss']
})
export class DataGridFilterModalComponent implements OnInit, OnDestroy {
  @ViewChildren('startDatePicker') startDatePickerList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('endDatePicker') endDatePickerList: QueryList<MatDatepicker<Date>>;
  baseUrl: string;
  display: string = "none";
  today: string;
  dataGridId: number;

  errorMsg: string;

  clientSelectionSubscription: Subscription;
  clientSelectedArray: Array<string>;
  clientIdSelectedArray: Array<number>;
  globalDateFilter: DashboardDateFilterRange;
  globalDateFilterSubscription: Subscription;

  startDate: string;
  endDate: string;
  actualDate = new Date();
  startDatePicker: MatDatepicker<Date>;
  endDatePicker: MatDatepicker<Date>;

  filter: DataGridFilterValue;
  private gridSubscription: Subscription;
  public grid: DataGrid;
  private gridFilterSubscription: Subscription;
  lineOfBusiness: number;
  public gridFilters: DataGridFilter[];

  constructor(private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string, private route: ActivatedRoute,
    private clientSelectionService: ClientSelectionService,
    private gridRequest: ReportRequest,
    private modalService: DataGridModalService,
    private dateFilterService: DateFilterService,
    private lineOfBusinessService: LineOfBusinessService,
    private datePipe: DatePipe,
    private dataGridService: DataGridService,
    private scrollToTopService: ScrollToTopService,
    protected errorService: ErrorModalService,
    protected alertService: AlertModalService,
    private readonly loadingSpinnerService: LoadingSpinnerService) {
    this.http = http;
    this.baseUrl = baseUrl;

    this.lineOfBusiness = this.lineOfBusinessService.getLineOfBusinessValue();
    this.today = new Date().toDateString();
    this.gridFilters = null;

    this.gridSubscription = modalService.grid$.subscribe(
      grid => {
        this.errorMsg = "";
        this.grid = grid as DataGrid;
        this.dataGridId = this.grid.dataGridId;
        if (this.grid.showFilterSw) {
          this.showModal(this.dataGridId);
        }
        else {
          this.startDate = "";
          this.endDate = "";
          this.globalDateFilter = null;
          this.onGenerateReport();
        }
      }
    );

    this.lineOfBusinessService.lineOfBusinessSelected$.subscribe(x => { this.lineOfBusiness = x });

    this.gridFilterSubscription = modalService.gridFilters$.subscribe(
      gridFilters => {
        this.lineOfBusiness = this.lineOfBusinessService.getLineOfBusinessValue();
        this.startDate = "";
        this.endDate = "";
        this.gridFilters = gridFilters as DataGridFilter[];

        if (this.gridFilters) {
          this.dateFilterService.getReportDateRangeFilter(this.dataGridId, this.lineOfBusiness).subscribe(data => {
            this.globalDateFilter = data as DashboardDateFilterRange;
            this.gridFilters.forEach(x => {

              if (x.filterType == "Date") {
                try {
                  this.startDate = this.globalDateFilter.startDate;
                }
                catch (ex) { }
                try {
                  this.endDate = this.globalDateFilter.endDate;
                }
                catch (ex) { }
              }
            },
              (err: HttpErrorResponse) => {

              })
          });
        }

        this.gridFilters.forEach(x => {
          if (x.filterType == "Single-Select List" && x.filterOptions && x.filterOptions[0]) {
            x.value = x.filterOptions[0].value;
          }
          if (x.filterType == "Dependent Select List" && x.filterOptions.length > 0) x.disabled = false;
        });
      }
    );

    this.clientSelectedArray = this.clientSelectionService.getClientSelectedArrayValue(this.lineOfBusiness);
    this.clientIdSelectedArray = this.clientSelectionService.getClientIdSelectedArrayValue(this.lineOfBusiness);
    this.clientSelectionSubscription = clientSelectionService.clientSelectedInfo$.subscribe(
      clientSelectedInfo => {
        let temp = clientSelectedInfo as ClientSelectionsObject;
        if (temp[this.lineOfBusiness]) {
          this.clientSelectedArray = temp[this.lineOfBusiness].clientSelectedArray;
          this.clientIdSelectedArray = temp[this.lineOfBusiness].clientIdSelectedArray;
        }
      }
    );
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    if (this.startDatePickerList?.first) {
      this.startDatePicker = this.startDatePickerList.first;
      this.startDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.startDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.startDatePicker.select(event.value);
            this.startDatePicker.close();
          })
        }, 0)
      })
    }
    if (this.endDatePickerList?.first) {
      this.endDatePicker = this.endDatePickerList.first;
      this.endDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.endDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.endDatePicker.select(event.value);
            this.endDatePicker.close();
          })
        }, 0)
      })
    }

  }
  ngOnDestroy() {
    this.clientSelectionSubscription.unsubscribe();
  }

  public showModal(DataGridId: number): void {
    this.display = "block";
    this.dataGridId = DataGridId;
  }

  public onCloseHandled(): void {
    this.display = "none";
    this.gridFilters = null;
    this.globalDateFilter = null;
  }

  private GetFilterDisplayValue(dataGridFilterId: number, selectedValue: string): string {
    var result: string = "";
    if (this.gridFilters) {
      this.gridFilters.forEach(x => {
        if (x.dataGridFilterId == dataGridFilterId && x.filterOptions) {
          x.filterOptions.forEach(o => {
            if (o.value == selectedValue) {
              result = o.label;
              return;
            }
          });
        }
      });
    }
    return result;
  }

  public OnChangeSelected(event: any, filter: DataGridFilter) {
    if (filter.hasDependentFilter) {
      let dependentFilter = this.gridFilters.filter(f => (f.dataGridFilterIDDependentOn == filter.dataGridFilterId));
      dependentFilter[0].filterOptionsFilter = event.value;
      this.refreshOptions(dependentFilter[0]);
    }
  }

  private GetMultiSelectFilterDisplayValue(dataGridFilterId: number, selection: Array<string>): string {
    var result: Array<string> = [];
    selection.forEach(x => {
      result.push(this.GetFilterDisplayValue(dataGridFilterId, x))
    });
    return result.join(",");
  }

  private validateForm(): boolean {
    let valid = true;

    this.gridFilters.forEach((x) => {
      if (!x.disabled) {
        if (x.filterType == "Date") {
          if (x.rangeType == "Range" || x.rangeType == "From") {
            if (!this.startDate && this.startDate.length == 0) {
              valid = false;
            }
          }
          if (x.rangeType == "Range" || x.rangeType == "To") {
            if (!this.endDate && this.endDate.length == 0) {
              valid = false;
            }
          }
        }
        else {
          if (!x.value)
            valid = false;
          else {
            if (Array.isArray(x.value) && (x.value as Array<string>).length <= 0) {
              valid = false;
            }
            else if ((x.value as string).length <= 0) {
              valid = false;
            }
          }
        }
      }


    });
    return valid;
  }


  public onGenerateReport(): void {
    if (this.grid.showFilterSw && !this.validateForm()) {
      this.errorMsg = "Please select values for all fields.";
      console.log("Form Validation Fail")
      return;
    }

    // if the report is in nav menu, clear the paramaters or we will be adding them each time, creating duplicates
    if (this.grid.isInNavMenu) {
      this.gridRequest.reset();
      this.gridRequest.ParameterValues = [];
      this.gridRequest.clickTrough = false;
      this.gridRequest.StartDate = new Date(this.startDate);
      this.gridRequest.EndDate = new Date(this.endDate);
    }

    this.clientSelectedArray = this.clientSelectionService.getClientSelectedArrayValue(this.lineOfBusiness);
    this.gridRequest.SelectedClientIds = this.clientSelectedArray;
    this.gridRequest.DataGridId = this.dataGridId;

    if (!this.gridRequest.ParameterValues) {
      this.gridRequest.ParameterValues = []
    }

    let randomVal = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);

    //save date filter for report
    this.dateFilterService.savePerReportDateFilter(this.dataGridId, this.startDate, this.endDate);

    // get the choosen parameters from the filters if there were any
    if (this.gridFilters && this.grid.showFilterSw) {
      this.gridFilters.forEach(obj => {
        if (!obj.disabled && obj.filterType != 'Date') {
          this.gridRequest.ParameterValues.push(
            {
              ParameterName: (this.grid && this.grid.isOnPrem && obj.dataGridSpInputParameter) ? obj.dataGridSpInputParameter : obj.displayName,
              DisplayName: obj.displayName,
              ParameterId: obj.dataGridFilterId,
              Value: (Array.isArray(obj.value)) ? obj.value.join(",") : obj.value,
              DisplayValue: (Array.isArray(obj.value)) ? this.GetMultiSelectFilterDisplayValue(obj.dataGridFilterId, obj.value) : this.GetFilterDisplayValue(obj.dataGridFilterId, obj.value)
            });
        }
      });
    }

    if (this.grid && this.grid.isOnPrem) {
      this.handleOnPremReports(randomVal);
    } else {
      this.handleAzureReports(randomVal);
    }
  }

  private handleAzureReports(randomVal: string) {
    this.loadingSpinnerService.show();

    sessionStorage.setItem('grid_' + randomVal, JSON.stringify(this.gridRequest));

    try {
      this.scrollToTopService.scrollTop();
      this.router.navigate(['/datagrid/' + randomVal]);
    } catch (e) {
      this.loadingSpinnerService.hide();
    }
    this.onCloseHandled();
  }

  private handleOnPremReports(randomVal: string): void {
    switch (this.grid.onPremReportId) {
      case -1:
        this.onPremVehicleHistoryReport();
        break;
      default:
        this.redirectToOnPremReport(this.grid.customReportUrl, randomVal);
        break;
    }
  }

  private onPremVehicleHistoryReport(): void {
    this.loadingSpinnerService.show();
    this.dataGridService.runVehicleInventoryReport(this.clientSelectedArray.join(",")).subscribe({
      next: (data) => {
        this.loadingSpinnerService.hide();
        this.alertService.setAlertObject({ title: 'Success', message: 'Your report was submitted successfully.  Please allow a few minutes before it will show up in your email.' })
        //show confirmation popup
      },
      error: (err: HttpErrorResponse) => {
        console.log("on prem report error:");
        console.log(err);
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

    this.onCloseHandled();
  }

  private redirectToOnPremReport(reportBaseUrl: string, randomVal: string): void {
    this.loadingSpinnerService.show();
    //let randomVal = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);

    sessionStorage.setItem('grid_' + randomVal, JSON.stringify(this.gridRequest));

    try {
      this.scrollToTopService.scrollTop();
      if (reportBaseUrl.substring(0, 7) === "reports") {
        this.router.navigate(['/datagrid/' + randomVal]);
      } else {
        this.router.navigate(['/' + reportBaseUrl + '/' + randomVal]);
      }
    } catch (e) {
      this.loadingSpinnerService.hide();
    }

    this.onCloseHandled();
  }


  private refreshOptions(request: DataGridFilter): void {
    this.http.post(this.baseUrl + 'api/Datagrid/RefreshFilterOptions', JSON.stringify(request), { headers: { 'Content-Type': 'application/json' } })
      .subscribe({
        next: (data) => {
          this.refreshFilter(data as any[], request.dataGridFilterId);
          this.filter
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
        }
      }
      )
  }

  private refreshFilter(dataGridFilter: any[], filterID: number) {
    this.gridFilters.forEach(x => {
      if (x.dataGridFilterId == filterID) {
        x.filterOptions = [];
        x.filterOptions = dataGridFilter;
        if (x.filterOptions.length == 0) {
          x.disabled = true;
        } else {
          x.disabled = false;
        }
      }
    });
  }
}

