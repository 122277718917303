//Angular
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
//Third Party
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//App

@Component({
  selector: 'dq-approve-modal',
  templateUrl: './dq-approve-modal.component.html',
  styleUrls: ['./dq-approve-modal.component.scss']
})
export class DQApproveModalComponent {
  expirationDateControl = new UntypedFormControl("", [Validators.required]);

  showCallOutMessage =  false;
  showExpirationDate: boolean = false;

  today = new Date();
  maxDate = new Date().setFullYear(this.today.getFullYear() + 100)
  dqType: string;
  attNames: Array<string>;
  expirationDate : string | Date;


  constructor(readonly dialogRef: MatDialogRef<DQApproveModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { dqType: string, attNames: Array<string>, expirationDate: string }) {
    this.dqType = data.dqType;
    this.attNames = data.attNames;
    this.expirationDate =  (data.expirationDate ? new Date(data.expirationDate) : null);
    this.setupView();
  }

  setupView(): void {
    this.showExpirationDate = this.dqType === "Certificate of Insurance";

    //Calculate year difference from today's date.
    if (this.expirationDate !== "" || this.expirationDate !== null) {
      this.onExpirationDateChange();
    }
  }

  ngOnChanges(): void {
    this.setupView();
  }

  onExpirationDateChange(): void {
    //Calculate year difference from today's date.
    var yearsFromToday = new Date(this.expirationDate).getFullYear() - this.today.getFullYear();

    this.showCallOutMessage = yearsFromToday > 1 ? true : false;
  }

  submit(): void {
    if (this.showExpirationDate) {
      //validate form before submitting if COI
      if (!this.expirationDateControl.invalid) {
        this.dialogRef.close({
          expirationDate: this.expirationDate
        });
      }
    } else {
      this.dialogRef.close(true);
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
