<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
  <div class="row">
    <div class="col-sm-2">Sent At:</div>
    <div class="col-sm-10" style="font-weight:bold">{{emailInfo?.sendTime |  date:'M/d/yy h:mm:ss a'}}</div>
  </div>
  <div class="row">
    <div class="col-sm-2">Sent To:</div>
    <div class="col-sm-10" style="font-weight:bold">{{emailInfo?.toRecipients}}</div>
  </div>
  <div class="row" *ngIf="emailInfo&&emailInfo.ccRecipients">
    <div class="col-sm-2">CC:</div>
    <div class="col-sm-10" style="font-weight:bold">{{emailInfo?.ccRecipients}}</div>
  </div>
  <div class="row" *ngIf="emailInfo&&emailInfo.bcRecipients">
    <div class="col-sm-2">BCC:</div>
    <div class="col-sm-10" style="font-weight:bold">{{emailInfo?.bcRecipients}}</div>
  </div>
  <div class="row" style="padding-top:1rem">
    <div class="col-sm-12">
      <div [innerHTML]="emailInfo.emailBody | safe" id="emailBodyDiv" style="white-space:normal" *ngIf="emailInfo&&emailInfo.emailBody"></div>
    </div>

  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-default" style="margin-bottom:6px"
          (click)="cancel()">
    Close
  </button>
</div>
