import { OnPremDriverService } from './../../components/on-prem-service/on-prem-driver-service.component';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchAdvancedSearchServiceService {

  private dataGridRequest = new Subject();
  dataGridRequest$ = this.dataGridRequest.asObservable();

  constructor(private onPremDriverService: OnPremDriverService) {
  }

  updateGridRequest(request: AdvancedSearchRequest) {
    this.dataGridRequest.next(request);
  }


  getData(request: AdvancedSearchRequest): Promise<any> {
    this.dataGridRequest.next(request);

    return lastValueFrom(this.onPremDriverService.post('entity/advancedsearch', request))
      .then(result => {
        return result;
      }).catch();
  }

}

export interface Column {
  name: string;
  prop: string;
}

export interface GridData {
  Columns: Column[];
  Data: any;
}

@Injectable()
export class AdvancedSearchRequest {
  ClientCode: string;
  LineOfBusinessId: number;
  IsGlobalSearch: boolean;
  IncludeActiveDrivers: boolean;
  IncludeInactiveDrivers: boolean;    
  IncludeDriversOnLeaveOfAbsence: boolean;
  IncludeEmployees: boolean;
  IncludeNonEmployees: boolean;
  DriverFirstName: string;
  DriverLastName: string;
  EmployeeId: string;
  DriverLicenseNumber: string;
  DriverLicenseState: string;
  EmailAddress: string;
  ClientSelection: string;  

  SortDisplayField: string;
  SortField: string;
  SortDir: string;
}
