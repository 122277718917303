import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserRightsService } from '../user-rights-service/user-rights-service.component';

@Injectable()
export class AdminAuthGuard  {

  constructor(private readonly userRightsService: UserRightsService, 
              private readonly router: Router) {

  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
        const userRights = this.userRightsService.getUserRightsValue()
        const thisRight = userRights.filter(r => r.userRightId === 63);
        if ((thisRight.length > 0) && (thisRight[0].permission === 'allow')) {
            resolve(true);
            return;
        }

        this.router.navigate(["/accessdenied"])
        
        resolve(false);
      });
  }
}
