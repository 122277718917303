//Angular
import { Injectable } from '@angular/core';
//Third Party
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
//APP
import { AlertObject } from "../classes-and-interfaces/classes-and-interfaces.component";
import { AlertModalComponent } from './alert-modal.component';

@Injectable()
export class AlertModalService {
  private alertObj = new Subject();
  alertObj$ = this.alertObj.asObservable();

  constructor(private dialog: MatDialog) {}

  setAlertObject(e: any) {
     this.alertObj.next(e as AlertObject);
     this.showAlertModal(e);
  }

  showAlertModal(message: AlertObject): void {
    const dialogRef = this.dialog.open(AlertModalComponent,{
      data: {message},
      width: '34em',
      minHeight: '13em',
      panelClass: 'alert-modal',
      hasBackdrop: false,
      position: {top: '2em'}
    });
  }
  
}
