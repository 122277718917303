<div>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeReactivateSearchModal()">
        <span aria-hidden="true">×</span>
      </button>
      <h4 class="modal-title w-100" id="labelReactivateModal">Opt Out Drivers</h4>
    </div>
    <div class="modal-body">
      <div class="row" style="margin-top:1rem">
        <div class="col-sm-12">
          <div class="md-form">
            <input id="searchOptOutId" type="text" [(ngModel)]="searchId" class="form-control"
              placeholder="Employee ID" />
            <label for="searchOptOutId" class="active">Employee ID</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="md-form">
            <input id="searchOptOutName" type="text" [(ngModel)]="searchName" class="form-control"
              placeholder="Last Name" />
            <label for="searchOptOutName" class="active">Last Name</label>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top:1rem">
        <!--<div class="col-sm-12">
              <div class="md-form">
                <input id="showAll" type="checkbox" [(ngModel)]="showAll" class="form-control" placeholder="Show All" />
                <label for="showAll" class="active">Show All</label>
              </div>
            </div>
          </div>
          <div class="row">-->
        <div class="col-sm-12">
          <div class="md-form">
            <!--<input id="searchName" type="text" [(ngModel)]="searchName" class="form-control" placeholder="Last Name" />-->

            <!-- <label for="searchGroup" class="active">Group</label> -->

            <mat-form-field appearance="fill" class="select-container w-100">
              <mat-label>Select Group...</mat-label>
              <mat-select #selectTitleHolder="matSelect" [(value)]="selectedGroup">
                <input matInput class="select-search-input" placeholder="Search Groups..."
                  (keyup)="onGroupChange($event)">
                <mat-option *ngFor="let option of groupsChoices" [value]="option.value">
                  {{option.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>


          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" aria-label="Search" (click)="search()">Search</button>
      <button type="button" class="btn btn-default" style="margin-bottom:6px" aria-label="Close"
        (click)="closeReactivateSearchModal()">Close</button>
    </div>
  </div>
</div>