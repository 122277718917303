//Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router'
//Third Party
import { Subscription } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
//APP
import { AccidentAdvanceSearchService } from './accident-advanced-search-service.component';
import { ClientSelectionService, ClientSelectionsObject } from '../../components/client-selection-service/client-selection-service.component';
import { StateProvince, ClientReqTitles } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { AdvancedSearchRequest } from '../advanced-search-claims/advanced-search-claims.service'
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-accident-advanced-search',
  templateUrl: './accident-advanced-search.component.html',
  styleUrls: ['./accident-advanced-search.component.scss']
})
export class AccidentAdvancedSearchComponent implements OnInit {
  @ViewChild('startDatePicker') startDatePicker: MatDatepicker<Date>;
  @ViewChild('endDatePicker') endDatePicker: MatDatepicker<Date>;
  clientSelectionSubscription: Subscription;
  clientSelectedArray: Array<string>;
  clientReqTitles: Array<ClientReqTitles> = [];
  stateProvinceOptions: Array<any>;
  filteredStateProvinceOptions: Array<any>;
  actualDate = new Date();


  constructor(private readonly _route: ActivatedRoute,
    private readonly advanceSearchService: AccidentAdvanceSearchService, private router: Router,
    private readonly clientService: ClientSelectionService,
    public readonly searchRequest: AdvancedSearchRequest,
    private readonly loadingSpinnerService: LoadingSpinnerService
  ) {

    this.searchRequest.DateField = 'Loss';
    this.clientSelectionSubscription = clientService.clientSelectedInfo$.subscribe(data => {
      let temp = data as ClientSelectionsObject;
      if (temp[1]) {
        this.clientSelectedArray = temp[1].clientSelectedArray;
        this.getClientReqTitles();
      }
    });
  }

  ngOnInit(): void {
    this.advanceSearchService.getStateProvinces().subscribe({
      next: (data: Array<StateProvince>) => {
        const tempin: Array<StateProvince> = data;
        this.stateProvinceOptions = [];

        tempin.forEach((item: StateProvince, index: number) => {
          const tempobj = {
            value: item.stateCode,
            label: item.stateName
          };
          this.stateProvinceOptions.push(tempobj);
        });
        this.filteredStateProvinceOptions = this.stateProvinceOptions;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err.error.toString());
      }
    });
  }

  ngAfterViewInit() {
    this.startDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.startDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
          this.startDatePicker.select(event.value);
          this.startDatePicker.close();
        })
      }, 0)
    })

    this.endDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.endDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
          this.endDatePicker.select(event.value);
          this.endDatePicker.close();
        })
      }, 0)
    })
  }

  public search(): void {
    this.loadingSpinnerService.show();
    this.searchRequest.reset(); //resets sort parameters
    this.searchRequest.SelectedClients = this.clientSelectedArray;

    let randomVal = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);

    this.router.navigate(['/accident/advancedSearch/' + randomVal]);
  }

  filterOptions(value: string): void {
    const filterValue = value.toLowerCase();
    this.filteredStateProvinceOptions = this.stateProvinceOptions.filter(option => option.label.toLowerCase().startsWith(filterValue));
  }

  private getClientReqTitles() {
    this.clientReqTitles = [];

    this.clientSelectedArray.forEach((item: string, index: number) => {
      this.clientService.getClientReqTitles(item).subscribe(data => {
        this.clientReqTitles.push(data as ClientReqTitles);
      });
    });
  }
}
