<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
  <div class="card card-body mt-3">
    <div class="row extra-margin">
      <div class="col-sm-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Select a Product</mat-label>
          <mat-select [(value)]="programChosen" (selectionChange)="onChangeProgram($event)">
            <mat-option *ngFor="let option of programOptions" [value]="option.value">
              {{option.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row margin-2rem" *ngIf="showSelectDriver&&programChosen&&(programChosen.length > 0)">
      <div class="col-sm-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Select a Driver</mat-label>
          <mat-select #selectDrivers="matSelect" [(value)]="driverChosen">
            <input matInput class="select-search-input" placeholder="Search Drivers..." (keyup)="filterOptions($event.target.value)">
            <mat-option *ngFor="let option of filteredDriverOptions" [value]="option.value">
              {{option.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div class="card card-body mt-3" *ngIf="showDriverEntry" style="height:50vh;overflow-y:auto">
    <div class="row">
      <div class="col-md-6">
        <div class="md-form">
          <input id="driverFirstName"
                 type="text"
                 [(ngModel)]="newDriverEntry.firstName"
                 class="form-control"
                 [formControl]="firstNameControl"
                 placeholder="First Name" />
          <label for="driverFirstName" [ngClass]="{ 'label-validation-error': firstNameControl.invalid, 'active':true }">First Name</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="md-form">
          <input id="driverLastName"
                 type="text"
                 [(ngModel)]="newDriverEntry.lastName"
                 class="form-control"
                 [formControl]="lastNameControl"
                 placeholder="Last Name" />
          <label for="driverLastName" [ngClass]="{ 'label-validation-error': lastNameControl.invalid, 'active':true }">Last Name</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="md-form">
          <input id="employeeId"
                 type="text"
                 [(ngModel)]="newDriverEntry.employeeId"
                 class="form-control"
                 [formControl]="employeeIdControl"
                 placeholder="Employee ID" />
          <label for="employeeId" [ngClass]="{ 'label-validation-error': employeeIdControl.invalid, 'active':true }">Employee ID</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="md-form">
          <input id="driverEmail"
                 type="text"
                 [(ngModel)]="newDriverEntry.emailAddress"
                 class="form-control"
                 [formControl]="driverEmailControl"
                 placeholder="Email Address" />
          <label for="driverEmail" [ngClass]="{ 'label-validation-error': driverEmailControl.invalid, 'active':true }">Email Address</label>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="md-form">
          <input id="ownerEmail"
                 type="text"
                 [(ngModel)]="newDriverEntry.ownerEmailAddress"
                 class="form-control"
                 placeholder="Owner Email" />
          <label for="ownerEmail" class="active">Owner Email</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="md-form">
          <input id="mgrEmail1"
                 type="text"
                 [(ngModel)]="newDriverEntry.managerTrainerEmailAddresses[0]"
                 class="form-control"
                 placeholder="Manager/Trainer Email" />
          <label for="driverEmail" class="active">Manager/Trainer Email</label>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="md-form">
          <input id="mgrEmail2"
                 type="text"
                 [(ngModel)]="newDriverEntry.managerTrainerEmailAddresses[1]"
                 class="form-control"
                 placeholder="2nd Manager/Trainer Email" />
          <label for="ownerEmail" class="active">2nd Manager/Trainer Email</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="md-form">
          <input id="mgrEmail3"
                 type="text"
                 [(ngModel)]="newDriverEntry.managerTrainerEmailAddresses[2]"
                 class="form-control"
                 placeholder="3rd Manager/Trainer Email" />
          <label for="ownerEmail" class="active">3rd Manager/Trainer Email</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label class="lbl-start-date">Medical Certificate Expiration Date</mat-label>
          <input class="input-date" matInput disabled [matDatepicker]="medicalCertificateDatePicker" [(ngModel)]="newDriverEntry.medicalCardExpirationDate">
          <mat-datepicker-toggle matSuffix [for]="medicalCertificateDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #medicalCertificateDatePicker disabled="false">
            <mat-datepicker-actions>
              <button mat-button (click)="medicalCertificateDatePicker.select(actualDate); medicalCertificateDatePicker.close()">
                <mat-icon>history</mat-icon>
                Today
              </button>
              <button mat-button (click)="medicalCertificateDatePicker.select(null); medicalCertificateDatePicker.close()">
                <mat-icon>highlight_off</mat-icon>
                Clear
              </button>
            </mat-datepicker-actions> 
          </mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label class="lbl-start-date">Drivers License Expiration Date</mat-label>
          <input class="input-date" matInput disabled [matDatepicker]="driversLicenceExpirationDatePicker" [(ngModel)]="newDriverEntry.driverLicenseExpirationDate">
          <mat-datepicker-toggle matSuffix [for]="driversLicenceExpirationDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #driversLicenceExpirationDatePicker disabled="false">
            <mat-datepicker-actions>
              <button mat-button (click)="driversLicenceExpirationDatePicker.select(actualDate); driversLicenceExpirationDatePicker.close()">
                <mat-icon>history</mat-icon>
                Today
              </button>
              <button mat-button (click)="driversLicenceExpirationDatePicker.select(null); driversLicenceExpirationDatePicker.close()">
                <mat-icon>highlight_off</mat-icon>
                Clear
              </button>
            </mat-datepicker-actions> 
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>

  </div>
</div>
<div class="modal-footer">
  <button *ngIf="(!showSelectDriver)&&(!showDriverEntry)"
          class="btn btn-default"
          [disabled]="(showSelectDriver&&(driverChosen.length <= 0))||((!showSelectDriver)&&((!programChosen)||(programChosen.length <= 0)))||(programChosenSlots<=0)"
          (click)="addNew()">
    Continue
  </button>
  <button *ngIf="showDriverEntry"
          class="btn btn-default"
          [disabled]="(showSelectDriver&&(driverChosen.length <= 0))||((!showSelectDriver)&&((!programChosen)||(programChosen.length <= 0)))"
          (click)="validateNewDriver()">
    Add This Driver
  </button>
  <button *ngIf="showSelectDriver"
          class="btn btn-default"
          [disabled]="(showSelectDriver&&((!driverChosen)||(driverChosen.length <= 0)))||((!showSelectDriver)&&((!programChosen)||(programChosen.length <= 0)))"
          (click)="confirmAssignment()">
    Add
  </button>
  <button class="btn btn-default" style="margin-bottom:6px"
          (click)="cancel()">
    Exit
  </button>
</div>
