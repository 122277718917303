import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable()
export class CustomModuleSelectorService {
  baseUrl: string;

  private customModuleSelectedUrl = new Subject();
  customModuleSelectedUrl$ = this.customModuleSelectedUrl.asObservable();


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.http = http;
    this.baseUrl = baseUrl;
  }


  getCustomApps(lineOfBusinessId: number) {
    let params = new HttpParams();
    params = params.append('lineOfBusinessId', lineOfBusinessId.toString());

    return this.http.get(this.baseUrl + 'api/Custom/GetCustomApps', {
      params: params
    });
  }

  getSavedCustomApp(): string {
    let url: string = localStorage.getItem('FleetSuite_SavedCustomAppUrl');
    //console.log("getSavedCustomApp: " + (url && url.length > 0 ? url : ''));
    return url && url.length > 0 ? url : '';
  }

  setSaveCustomApp(customAppUrl: string) {
    if (customAppUrl && customAppUrl.length > 0) {
      localStorage.setItem('FleetSuite_SavedCustomAppUrl', customAppUrl);
      //console.log(customAppUrl);
      this.customModuleSelectedUrl.next(customAppUrl);
    }
  }
}
