import { Component, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { LocateDriver, SearchLineOfBusiness } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { SafetyMoveDriverService } from '../safety-move-driver/safety-move-driver.service';
@Component({
  selector: 'app-safety-move-driver-locate',
  templateUrl: './safety-move-driver-locate.component.html',
  styleUrls: ['./safety-move-driver-locate.component.css']
})
export class SafetyMoveDriverLocateComponent implements OnInit {
  driverForm: UntypedFormGroup;
  locatedDrivers: LocateDriver[];
  isLoading: boolean;
  isSubmitted: boolean = false;
  isDriverMissing: boolean = false;
  constructor(private readonly fb: UntypedFormBuilder, private safetyService: SafetyMoveDriverService) { }

  ngOnInit(): void {
    this.driverForm = this.fb.group({
      clientCode: ['', Validators.required],
      searchText: ['', Validators.required],
      type: ['5', Validators.required]
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    const loadingChange = changes['isLoading']
    if (loadingChange?.previousValue && !loadingChange.currentValue) {
      this.isDriverMissing = this.locatedDrivers?.length > 0 ? false : true;
      this.driverForm.reset(this.driverForm.value);
      this.isSubmitted = false;
    }
  }


  get form() { return this.driverForm.controls; }


  driverLookup() {
    this.isSubmitted = true;
    if (this.driverForm.valid) {
      this.isLoading = true;
      const search: SearchLineOfBusiness = {
        lineOfBusinessId: 2,
        searchColumnId: this.driverForm.get('type').value,
        clientCode: this.driverForm.get('clientCode').value,
        searchText: this.driverForm.get('searchText').value
      }
      this.safetyService.quickSearchDriver(search).subscribe({
        next: (res) => this.setLocatedDrivers(res, search.clientCode),
        error: (err) => this.locatedDrivers = null,
        complete: () => this.isLoading = false
      });
    }
  }

  setLocatedDrivers(quickSearchResponse: any, clientCode: string) {
    if (quickSearchResponse && quickSearchResponse.rows.length > 0) {
      this.locatedDrivers = quickSearchResponse.rows as LocateDriver[];
    }
    else {
      this.locatedDrivers = null;
    }
  }

}
