import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserManager, User, Log, UserManagerSettings } from 'oidc-client';
import { environment } from "../../../environments/environment"
import { BrowserInfoService } from '../browser-info-service/browser-info-service.component';


@Injectable()
export class AuthService {
  @Output() onConfigurationLoaded = new EventEmitter<boolean>();
  @Output() onAuthCompleted = new EventEmitter<boolean>();

  manager: UserManager;
  settings: FleetUserManagerSettings
  user: User = null;

  constructor(private http: HttpClient, private browserInfoService: BrowserInfoService) {
    Log.logger = console;
    Log.level = Log.WARN;
  }

  areCookiesEnabled() {
    try {
      document.cookie = 'cookietest=1';
      var cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1;
      document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
      return cookiesEnabled;
    } catch (e) {
      return false;
    }
  }
  configureAuthentication(configUrl: string): void {
    //check if cookies are enabled
    if (!this.browserInfoService.areCookiesEnabled()) {
      console.log("auth: cookies disabled");
      return;
    }


    console.log('get auth config data');

    // console.log(environment.AuthConfiguration);
    this.manager = new UserManager(environment.AuthConfiguration as UserManagerSettings);

    this.manager.events.addAccessTokenExpired(ev => {
      //console.log("token expiring... logging out..");
      this.logOut();
      //console.log(ev)
    });
    this.manager.events.addAccessTokenExpiring(e => {
      //console.log("token expiring... ");
      this.logOut();

    });

    this.manager.events.addSilentRenewError(function () {
      return this.manager.signinRedirectCallback().then(user => {
        this.user = user;
        this.onAuthCompleted.emit(true);
        //console.log(this.user);
      });
    });

    //Depricated
    this.onConfigurationLoaded.emit(true);
  }

  startAuthentication(): Promise<User> {
    console.log('start login');
    return this.manager.signinRedirect();
  }

  refreshToken(): Promise<User> {
    console.log('refresh token');
    this.manager.startSilentRenew();
    return this.manager.signinSilent();
  }

  completeAuthentication(): Promise<void> {

    return this.manager.signinRedirectCallback().then(user => {
      try {
        this.user = user;
        this.onAuthCompleted.emit(true);
      }
      catch {
        this.startAuthentication();
      }
    });
  }

  getAuthorizationHeaderValue(): string {
    return `${this.user.token_type} ${this.user.access_token}`;
  }

  isLoggedInAndGetUser(): Promise<boolean> {
    if ((!this.manager || this.manager == null) && this.settings == null) {
      return Promise.resolve(false);
    }

    if (!this.manager || this.manager == null) {
      this.manager = new UserManager(this.settings);
    }

    if (!this.user || this.user == null) {
      return new Promise((resolve, reject) => {
        this.manager.getUser().then(user => {
          this.user = user;
          this.onAuthCompleted.emit(this.user != null && !this.user.expired);
          resolve(this.user != null && !this.user.expired)
          return;
        });
      });
    }
    else {
      // User object is already set
      //console.log('user already is loaded');
      return Promise.resolve(this.user != null && !this.user.expired && this.manager != null);
    }
  }

  isLoggedIn(): boolean {
    return this.user != null && !this.user.expired && this.manager != null;
  }

  logOut(): Promise<void> {
    if (this.isLoggedIn()) {
      return this.manager.signoutRedirect();
    }
  }

  getOnPremApiBaseUrl(): string {
    return environment.AuthConfiguration.onPremApi; //this.settings.onPremApi;
  }

  // DHP NextGen
  getFleetResponseServicesBaseUrl() : string {
    return environment.AuthConfiguration.fleetResponseServicesBaseUrl;
  }
}

export interface FleetUserManagerSettings extends UserManagerSettings {
  onPremApi: string;
}
