import { Component, Input, ViewChild } from '@angular/core';
import { DashboardTile } from "../classes-and-interfaces/classes-and-interfaces.component";
import { DashboardService } from '../dashboard-service/dashboard-service.component';
import { ReportRequest } from '../data-grid/data-grid.service';
import { Router } from '@angular/router'
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorModalService } from "../error-modal/error-modal-service.component";
import { ScrollToTopService } from '../../shared/scroll-to-top-service/scroll-to-top.service';
import { ChartV2Component } from '../chart-v2/chart-v2.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-card-stat-center',
  templateUrl: './card-stat-center.component.html',
  styleUrls: ['./card-stat-center.component.css']
})

export class CardStatCenterComponent {
  @ViewChild(ChartV2Component) chart: ChartV2Component;

  constructor(
    private router: Router,
    private gridRequest: ReportRequest,
    private loadingSpinnerService: LoadingSpinnerService,
    private dashboardService: DashboardService,
    //private toastrService: ToastService,
    private errorService: ErrorModalService,
    private scrollToTopService: ScrollToTopService
  ) { };

  @Input() card: DashboardTile;
  @Input() frameId: string;

  isTileFilterApplied(thisCard: DashboardTile) {
    // filter type = range or single
    const filterTypeSelected: boolean = ((thisCard.tileDateFilterTypeId == 1) || (thisCard.tileDateFilterTypeId == 2));
    // if type = range, has start date 
    const hasStart: boolean = ((thisCard.tileDateFilterTypeId == 1) && (thisCard.filterStartDate && (thisCard.filterStartDate.length > 0)));
    // has end date
    const hasEnd: boolean = (thisCard.filterEndDate && (thisCard.filterEndDate.length > 0));

    return filterTypeSelected && (hasStart || hasEnd);
  }

  showFilterSummaryPanel(thisCard: DashboardTile) {
    return thisCard.filtersSummary.length > 0;
  }

  showDataGrid(data: any, thisCard: DashboardTile) {
    //scroll to top
    //this.scrollTop();
    this.scrollToTopService.scrollTop();

    this.loadingSpinnerService.show();
    //console.log(data);
    if (this.dashboardService.buildGridRequestHighCharts(this.gridRequest, data, thisCard)) {
      let randomVal = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
      try {
        sessionStorage.setItem('grid_' + randomVal, JSON.stringify(this.gridRequest));
        this.router.navigate(['/datagrid/' + randomVal]);
      } catch (e) {
        this.loadingSpinnerService.hide();
      }
    }

  }

  removeItem(card: DashboardTile) {
    this.dashboardService.removeTile(card.tileId).subscribe({
      next: (data) => {
        this.dashboardService.setTileIdRemoved(card.tileId);

        /* - do not refresh tile info from service
        this.dashboardService.getTileArray(card.lineOfBusinessId).subscribe(data => {
          let arrD = data as Array<DashboardTile>;
      // remove positionX and positionY attributes from an array of
      // DashboardTile, to allow auto-arrange by Gridster
          this.removeGridsterPositionInfo(arrD);
  
          this.dashboardService.setTileArray(arrD);
        },
          (err: HttpErrorResponse) => {
            this.toastrService.error(err.error.toString());
          });
        */
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }
  exportChart(card: DashboardTile) {
    this.chart.exportChart();
  }
  showCardModal(card: DashboardTile) {
    this.dashboardService.setTileZoom(card);
  }

  showCardFilterModal(card: DashboardTile, filterType: string) {
    let filterInfo = {
      card: card,
      filterType: filterType
    }
    this.dashboardService.setTileToFilter(filterInfo);
  }

  /* - moved to dashboard-stat-center
  private removeGridsterPositionInfo(arrD: Array<DashboardTile>){
    // removes positionX and positionY attributes from an array of
    // DashboardTile, to allow auto-arrange by Gridster
    arrD.forEach(d => {
      delete d.positionX;
      delete d.positionY;
    });
  }
  */



}
