import { Component, Injectable, Inject } from '@angular/core';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DatePipe } from '@angular/common';

@Injectable()
export class RemovedDriversReportService {
  baseUrl: string;

  constructor(
    private onPremService: OnPremDriverService,
    private datePipe: DatePipe
  ) { }

  getRemovedDriversReport(clientCode: string, startDate: string, endDate: string) {
    let endpoint = "drivermaintenance/" + clientCode + "/removed/report?startDate=" + this.datePipe.transform(startDate, "MM-dd-yyyy");
    if (endDate && (endDate.length > 0)) {
      endpoint = endpoint + "&endDate=" + this.datePipe.transform(endDate, "MM-dd-yyyy");
    }
    return this.onPremService.get(endpoint);
  }

  reactivateDrivers(clientCode: string, driverIdList: string) {
    return this.onPremService.post("drivermaintenance/" + clientCode + "/reactivate", driverIdList);
  }

}
