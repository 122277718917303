//Angular
import { Component, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';
//Third Party
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//APP
import { AzureDocument } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss']
})
export class AddDocumentComponent {
  @Input() lineofBusinesssId = "2";
  @Input() companySelectedObject: any = []; 
  @Output() refreshDocEmitter = new EventEmitter<boolean>();

  constructor(public dialogRef: MatDialogRef<AddDocumentComponent>, 
    @Inject(MAT_DIALOG_DATA) public data) {}

  closeModal(refresh: boolean): void {
    this.dialogRef.close(refresh);
  }
}
