//angular libraries
import { Component, Inject, OnInit, OnDestroy, ViewChildren, ChangeDetectorRef, AfterViewChecked, QueryList } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
//third party
import { Observable } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isArray } from 'highcharts/highcharts.src';
//project libraries
import { WidgetInfo, DashboardTileCategory, CustomReport } from "../classes-and-interfaces/classes-and-interfaces.component";
import { LineOfBusinessInfo, PowerBIToken, PowerBITile, DataGridInfo, TileDateFilterTypeChoice } from "../classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../error-modal/error-modal-service.component";
import { AlertModalService } from "../alert-modal/alert-modal-service.component";
import { DashboardService } from '../dashboard-service/dashboard-service.component';



@Component({
  selector: 'app-widget-edit',
  templateUrl: './widget-edit.component.html',
  styleUrls: ['./widget-edit.component.scss']
})
export class WidgetEditComponent implements OnInit, OnDestroy, AfterViewChecked {
  filterStartDate: string;
  filterEndDate: string;
  filterStartDatePicker: MatDatepicker<Date>;
  filterEndDatePicker: MatDatepicker<Date>;
  @ViewChildren('startDatePicker') startDatePickerList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('endDatePicker') endDatePickerList: QueryList<MatDatepicker<Date>>;

  actualDate = new Date();
  private routewidget: any;
  widgetId: string = "";
  widgetObject: WidgetInfo = new WidgetInfo();
  widgetReportPreviewObject: PowerBITile;
  widgetVisPreviewObject: PowerBITile;
  baseUrl: string;
  widgetLoading: boolean = true;
  isNewWidget: boolean = false;
  widgetNotFound: boolean = false;
  private arrPowerBIToken: Array<PowerBIToken>;
  widgetReportChoices: Array<any>;
  widgetDataGridChoices: Array<any>;
  widgetDataGridChoicesFiltered: Array<CustomReport>;
  widgetVisualizationChoices: Array<any>;
  widgetLineOfBusinessChoices: Array<any>;
  widgetTileDateFilterTypeChoices: Array<any>;
  widgetTileHeightChoices: Array<any> = [
    { value: "1", label: "1" },
    { value: "2", label: "2" }
  ];
  widgetTileWidthChoices: Array<any> = [
    { value: "1", label: "1" },
    { value: "2", label: "2" }
  ];
  widgetSlicerOperatorChoices: Array<any> = [
    { value: "=", label: "= (Equal To)" },
    { value: ">", label: "> (Greater Than)" },
    { value: ">=", label: ">= (Greater Than Or Equal To)" },
    { value: "<", label: "< (Less Than)" },
    { value: "<=", label: "<= (Less Than Or Equal To)" }
  ];
  widgetChartTypeChoices: Array<any> = [
    { value: "1", label: "HighChart" },
    { value: "2", label: "Text" }
  ];
  /*
  widgetTileTypeChoices: Array<any> = [
    { value: "Full Report", label: "Full Report" },
    { value: "Visualization", label: "Visualization" }
  ]*/
  widgetVisChoicesLoading: boolean = true;
  widgetReportChoicesLoading: boolean = true;
  widgetLineOfBusinessChoicesLoading: boolean = true;
  widgetDataGridChoicesLoading: boolean = true;
  widgetDateFilterTypeChoicesLoading: boolean = true;
  canSaveWidget: boolean = false;

  titleControl = new UntypedFormControl('', [Validators.required]);
  descriptionControl = new UntypedFormControl('', [Validators.required]);
  lineOfBusinessIdControl = new UntypedFormControl('', [Validators.required]);
  dataGridIdControl = new UntypedFormControl('', [Validators.required]);
  storedProcControl = new UntypedFormControl('', [Validators.required]);
  chartTypeControl = new UntypedFormControl('', [Validators.required]);
  chartConfigControl = new UntypedFormControl('', [Validators.required]);
  dateFilterTypeControl = new UntypedFormControl('', [Validators.required]);
  tileHeightControl = new UntypedFormControl('', [Validators.required]);
  tileWidthControl = new UntypedFormControl('', [Validators.required]);

  onPremApiUrlControl = new UntypedFormControl('', []);

  slicerDisplayNameControl = new UntypedFormControl('', []);
  slicerPbixFieldNameControl = new UntypedFormControl('', []);
  slicerPbixTableNameControl = new UntypedFormControl('', []);
  slicerOptionsControl = new UntypedFormControl('', []);
  slicerDefaultValueControl = new UntypedFormControl('', []);
  slicerOperatorControl = new UntypedFormControl('', []);
  arrCategoryChoices: Array<DashboardTileCategory> = [];
  arrCategoryChoicesFiltered: Array<DashboardTileCategory> = [];

  allControls: Array<UntypedFormControl> = [
    this.titleControl,
    this.descriptionControl,
    this.lineOfBusinessIdControl,
    this.dataGridIdControl,
    this.storedProcControl,
    this.chartTypeControl,
    this.chartConfigControl,
    this.dateFilterTypeControl,
    this.tileHeightControl,
    this.tileHeightControl,
    this.slicerDisplayNameControl,
    this.slicerPbixFieldNameControl,
    this.slicerPbixTableNameControl,
    this.slicerOptionsControl,
    this.slicerDefaultValueControl,
    this.slicerOperatorControl
  ];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private dashboardService: DashboardService,
    @Inject('BASE_URL') baseUrl: string,
    private cdRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private errorService: ErrorModalService,
    private alertService: AlertModalService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void {
    this.filterStartDate = "";
    this.filterEndDate = "";
    //this.getCategoryChoices();
    this.routewidget = this.route.params.subscribe(params => {
      this.widgetId = params['WidgetId'];
      if (this.widgetId.length > 0) {
        this.initAll(+this.widgetId);
      } else {
        //this.widgetObject = new WidgetInfo();
        this.isNewWidget = true;
        this.initAll(null);
      }
    });
  }


  ngAfterViewChecked(): void {
    if (!this.filterStartDatePicker && this.startDatePickerList?.first) {
      this.filterStartDatePicker = this.startDatePickerList.first;
      this.filterStartDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.filterStartDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.filterStartDatePicker.select(event.value);
            this.filterStartDatePicker.close();
          })
        }, 0)
      })
    }

    if (!this.filterEndDatePicker && this.endDatePickerList?.first) {
      this.filterEndDatePicker = this.endDatePickerList.first;
      this.filterEndDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.filterEndDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.filterEndDatePicker.select(event.value);
            this.filterEndDatePicker.close();
          })
        }, 0)
      })
    }
  }

  private filterOptions(value: string): void {
    const filterValue = value.toLowerCase();
    this.widgetDataGridChoices = this.widgetDataGridChoicesFiltered.filter((option) =>
      option.label.toLowerCase().startsWith(value)
    );
  }

  private getCategoryChoices(lineOfBusinessIdString: string) {
    if (lineOfBusinessIdString && (lineOfBusinessIdString.length > 0)) {
      this.dashboardService.getTileCategoriesByLOB(+lineOfBusinessIdString).subscribe({
        next: (data) => {
          this.arrCategoryChoices = data as Array<DashboardTileCategory>;

          if (this.widgetObject.categoryIdList) {
            var selectedIds = this.widgetObject.categoryIdList.split(',')
            selectedIds.forEach(x => {
              let cat = this.arrCategoryChoices.find(c => c.categoryId.toString() == x);
              if (cat) {
                cat.selected = true;
              }
            });
          }
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
        }
      })
    }

  }

  public getSelectedCategories() {

  }

  public onCategorySelection(catId: string) {


  }
  private updateLabelCSS() {
    this.cdRef.detectChanges();
  }

  private getWidgetInfo(tileId: number) {
    this.widgetVisChoicesLoading = true;
    let params = new HttpParams();
    params = params.append('TileId', tileId.toString());

    this.http.get(this.baseUrl + 'api/AdminTile/GetById', {
      params: params
    }).subscribe({
      next: (data) => {
        this.widgetObject = data as WidgetInfo;
        // 2019-02 - remove powerbi references
        //this.getReportChoices();

        if (!this.widgetObject) {
          this.widgetLoading = false;
          this.widgetNotFound = true;
        } else {
          this.widgetObject.tileHeightString = this.widgetObject.tileHeight.toString();
          this.widgetObject.tileWidthString = this.widgetObject.tileWidth.toString();
          this.widgetObject.lineOfBusinessIdString = this.widgetObject.lineOfBusinessId.toString();
          this.widgetObject.dataGridIdString = this.widgetObject.dataGridId.toString();
          this.widgetObject.tileDateFilterTypeIdString = this.widgetObject.tileDateFilterTypeId.toString();
          this.widgetObject.jsChartTypeIdString = (this.widgetObject.jsChartTypeId ? this.widgetObject.jsChartTypeId.toString() : null);

          this.initSlicerFields(this.widgetObject.hasSlicer);

          this.getCategoryChoices(this.widgetObject.lineOfBusinessIdString);
          /*
          if (this.arrCategoryChoices && this.arrCategoryChoices.length > 0 && this.widgetObject.categoryIdList) {
  
            var selectedIds = this.widgetObject.categoryIdList.split(',')
            selectedIds.forEach(x => {
              this.arrCategoryChoices.find(c => c.categoryId.toString() == x).selected = true;
            });
         
            //this.arrCategoryChoices.forEach(x => { if (x.categoryId.toString() == )})
  
          }
             */

          this.widgetLoading = false;
          this.updateLabelCSS();
          this.checkCanSaveWidget();
        }
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        if (err.status != 403)
          this.errorService.setErrorObject(err.error);
      }
    })
  }

  private initAll(tileId: number) {
    //this.getReportChoices();
    this.getLineOfBusinessChoices();
    this.getDataGridChoices();
    this.getDateFilterTypeChoices();

    if (!this.isNewWidget) {
      this.getWidgetInfo(tileId);
    } else {
      //this.getReportChoices();
      this.widgetReportPreviewObject = null;
      this.widgetLoading = false;
      this.updateLabelCSS();
      this.checkCanSaveWidget();
    }
  }

  private transformLineOfBusinessChoices(data: Array<any>): Array<any> {
    const tempin: Array<LineOfBusinessInfo> = data as Array<LineOfBusinessInfo>;
    const tempout: Array<any> = [];
    tempin.forEach((item: LineOfBusinessInfo, index: number) => {
      const tempobj = {
        value: item.lineOfBusinessId.toString(),
        label: item.lineOfBusinessName
      };
      tempout.push(tempobj);
    })
    return tempout;
  }

  private getLineOfBusinessChoices() {
    this.http.get(this.baseUrl + 'api/UserFilter/LineOfBusinesses')
      .subscribe({
        next: (data) => {
          const tempin: Array<LineOfBusinessInfo> = data as Array<LineOfBusinessInfo>;
          const tempout: Array<any> = this.transformLineOfBusinessChoices(tempin);
          this.widgetLineOfBusinessChoices = tempout;
          this.widgetLineOfBusinessChoicesLoading = false;
          this.checkCanSaveWidget();
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
        }
      });
  }

  private transformDataGridChoices(data: Array<any>): Array<any> {
    const tempin: Array<DataGridInfo> = data as Array<DataGridInfo>;
    const tempout: Array<any> = [];
    tempin.forEach((item: DataGridInfo, index: number) => {
      const tempobj = {
        value: item.dataGridId.toString(),
        label: item.title
      };
      tempout.push(tempobj);
    })
    return tempout;
  }

  private getDataGridChoices() {
    this.http.get(this.baseUrl + 'api/AdminDataGrid/Get')
      .subscribe({
        next: (data) => {
          const tempin: Array<DataGridInfo> = data as Array<DataGridInfo>;
          const tempout: Array<any> = this.transformDataGridChoices(tempin);
          this.widgetDataGridChoices = tempout;
          this.widgetDataGridChoicesFiltered = tempout;
          this.widgetDataGridChoicesLoading = false;
          this.checkCanSaveWidget();
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
        }
      });
  }

  private transformDateFilterTypeChoices(data: Array<any>): Array<any> {
    const tempin: Array<TileDateFilterTypeChoice> = data as Array<TileDateFilterTypeChoice>;
    const tempout: Array<any> = [];
    tempin.forEach((item: TileDateFilterTypeChoice, index: number) => {
      const tempobj = {
        value: item.tileDateFilterTypeId.toString(),
        label: item.tileDateFilterTypeName
      };
      tempout.push(tempobj);
    })
    return tempout;
  }

  private getDateFilterTypeChoices() {
    this.http.get(this.baseUrl + 'api/AdminTile/GetTileDateFilterTypes')
      .subscribe({
        next: (data) => {
          const tempin: Array<DataGridInfo> = data as Array<DataGridInfo>;
          const tempout: Array<any> = this.transformDateFilterTypeChoices(tempin);
          this.widgetTileDateFilterTypeChoices = tempout;
          this.widgetDateFilterTypeChoicesLoading = false;
          this.checkCanSaveWidget();
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
        }
      });
  }

  private getVisPreviewInfo(w: WidgetInfo, token: string, embedUrl: string): PowerBITile {
    // allows preview to show visualization if visualizationId and pageName are provided
    if (w.visualizationId && (w.visualizationId.length > 0) && w.pageName && (w.pageName.length > 0)) {
      let obj: any = {
        reportGuid: w.reportGuid,
        token: token,
        embedUrl: embedUrl,
        tokenType: "Embed",
        type: "visual",
        visualizationId: w.visualizationId,
        pageName: w.pageName
      }
      return obj as PowerBITile;
    } else {
      return null;
    }
  }

  private getReportPreviewInfo(w: WidgetInfo, token: string, embedUrl: string): PowerBITile {
    // updates preview as report
    let obj: any = {
      reportGuid: w.reportGuid,
      token: token,
      embedUrl: embedUrl,
      tokenType: "Embed",
      type: "report"
    }

    return obj as PowerBITile;
  }

  updateReportPreviewInfo(PBItoken: PowerBIToken) {
    // update report preview with new embed token and URL
    if (PBItoken) {
      this.widgetReportPreviewObject = this.getReportPreviewInfo(this.widgetObject, PBItoken.token, PBItoken.embedUrl);
    } else {
      this.widgetReportPreviewObject = null;
    }
  }

  updateVisPreviewInfo(PBItoken: PowerBIToken) {
    // update visualization preview with new embed token and URL
    if (PBItoken) {
      this.widgetVisPreviewObject = this.getVisPreviewInfo(this.widgetObject, PBItoken.token, PBItoken.embedUrl);
    } else {
      this.widgetVisPreviewObject = null;
    }
  }

  private getVisualizationChoices(visChoices: any) {
    if (visChoices) {
      let newVisChoices = [{ value: "", label: "-- No Visualization --" }];
      this.widgetVisualizationChoices = newVisChoices.concat(visChoices);
      // check if existing visualizationId and pageName are in visChoices -
      // if not then clear from widget object
      let arrChoices1 = visChoices.filter(v => v.value == this.widgetObject.visualizationId);
      let arrChoices2 = arrChoices1.filter(v => v.pageName == this.widgetObject.pageName);
      if (arrChoices2.length <= 0) {
        delete this.widgetObject.visualizationId;
        delete this.widgetObject.pageName;
        this.widgetVisPreviewObject = null;
      }
      this.widgetVisChoicesLoading = false;
    } else {
      this.widgetVisChoicesLoading = true;
    }
    this.checkCanSaveWidget();
  }

  hasSlicerChange(hasSlicer: boolean) {
    // change handler for hasSlicer switch
    // show slicer options
    this.widgetObject.hasSlicer = hasSlicer;
    // set up validation on slicer fields
    this.initSlicerFields(hasSlicer);
  }
  isOnPremChange(isOnPrem: boolean) {
    // change handler for hasSlicer switch
    // show slicer options
    this.widgetObject.isOnPrem = isOnPrem;
    // set up validation on slicer fields
    //this.initSlicerFields(hasSlicer);
  }

  onChangeLOB(lineOfBusinessIdString: string) {
    this.getCategoryChoices(lineOfBusinessIdString);
  }

  private initSlicerFields(hasSlicer: boolean) {
    // set up slicer field validations based on hasSlicer choice
    if (hasSlicer) {
      this.slicerDisplayNameControl.setValidators([Validators.required]);
      this.slicerPbixFieldNameControl.setValidators([Validators.required]);
      this.slicerPbixTableNameControl.setValidators([Validators.required]);
      this.slicerOptionsControl.setValidators([Validators.required]);
      this.slicerDefaultValueControl.setValidators([Validators.required]);
      this.slicerOperatorControl.setValidators([Validators.required]);
    } else {
      this.slicerDisplayNameControl.setValidators(null);
      this.slicerPbixFieldNameControl.setValidators(null);
      this.slicerPbixTableNameControl.setValidators(null);
      this.slicerOptionsControl.setValidators(null);
      this.slicerDefaultValueControl.setValidators(null);
      this.slicerOperatorControl.setValidators(null);
      this.slicerDisplayNameControl.setErrors(null);
      this.slicerPbixFieldNameControl.setErrors(null);
      this.slicerPbixTableNameControl.setErrors(null);
      this.slicerOptionsControl.setErrors(null);
      this.slicerDefaultValueControl.setErrors(null);
      this.slicerOperatorControl.setErrors(null);
    }
  }

  private validateWidget(): boolean {
    let isOK: boolean = true;

    if (this.allControls.filter(c => (c.invalid)).length > 0) {
      this.errorService.setErrorObject({ message: 'Missing or Invalid Fields.' });
      isOK = false;
    }

    return isOK;
  }

  previewReport(reportGuid: string, updateWidgetObject: boolean) {
    // if this is a new report selection, clear visualization info
    if (updateWidgetObject) {
      this.widgetVisChoicesLoading = true;
    }

    let params = new HttpParams();
    params = params.append('ReportGuid', reportGuid);
    this.http.get(this.baseUrl + 'api/AdminTile/GetEmbedToken', { params: params })
      .subscribe({
        next: (data) => {
          this.updateReportPreviewInfo(data as PowerBIToken);
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
          this.updateReportPreviewInfo(null);
        }
      });
  }

  editReport(reportGuid: string) {

  }

  previewDataGrid(dataGridIdString) {

  }

  showEditDataGrid(dataGridIdString) {
    return dataGridIdString && (dataGridIdString.length > 0);
  }

  editDataGrid(dataGridIdString) {
    if (dataGridIdString && (dataGridIdString.length > 0)) {
      this.router.navigate(['/admin/datagridadminedit', dataGridIdString, this.widgetId.toString()]);
    }
  }

  previewVisualization(reportGuid, visualizationId, updateWidgetObject: boolean) {
    if ((!visualizationId) || (visualizationId.length <= 0)) {
      // chose no visualization option
      delete this.widgetObject.visualizationId;
      delete this.widgetObject.pageName;
      this.widgetVisPreviewObject = null;
    } else {
      // get pagename for visualizationId and add to widgetObject
      let pageName = this.widgetVisualizationChoices.filter(v => (v.value == visualizationId))[0].pageName;
      this.widgetObject.pageName = pageName;

      let params = new HttpParams();
      params = params.append('ReportGuid', reportGuid);
      this.http.get(this.baseUrl + 'api/AdminTile/GetEmbedToken', { params: params })
        .subscribe({
          next: (data) => {
            this.updateVisPreviewInfo(data as PowerBIToken);
          },
          error: (err: HttpErrorResponse) => {
            //this.toastrService.error(err.error.toString());
            this.errorService.setErrorObject(err.error);
            this.updateVisPreviewInfo(null);
          }
        });
    }
  }

  editVisualization(visualizationId) {

  }

  private checkCanSaveWidget() {
    // enable save button only if all data loaded
    let canSaveWidget = (!this.widgetLoading
      && !this.widgetLineOfBusinessChoicesLoading
      && !this.widgetDataGridChoicesLoading
      && !this.widgetDateFilterTypeChoicesLoading);

    this.canSaveWidget = canSaveWidget;
  }

  saveWidget() {



    if (this.validateWidget()) {

      let params = new HttpParams();
      if (typeof this.widgetObject.title !== "undefined") params = params.append('Title', this.widgetObject.title);
      if (typeof this.widgetObject.description !== "undefined") params = params.append('Description', this.widgetObject.description);

      this.widgetObject.tileType = "Full Report";

      if (this.widgetObject.jsChartTypeIdString)
        this.widgetObject.jsChartTypeId = +this.widgetObject.jsChartTypeIdString;
      if (this.widgetObject.lineOfBusinessIdString)
        this.widgetObject.lineOfBusinessId = +this.widgetObject.lineOfBusinessIdString;
      if (this.widgetObject.dataGridIdString)
        this.widgetObject.dataGridId = +this.widgetObject.dataGridIdString;

      if (this.widgetObject.tileHeightString)
        this.widgetObject.tileHeight = +this.widgetObject.tileHeightString;

      if (this.widgetObject.tileWidthString)
        this.widgetObject.tileWidth = +this.widgetObject.tileWidthString;

      if (this.widgetObject.tileDateFilterTypeIdString)
        this.widgetObject.tileDateFilterTypeId = +this.widgetObject.tileDateFilterTypeIdString;

      let selectedCategoryIds = this.arrCategoryChoices.filter(x => x.selected == true).map(x => { return x.categoryId });
      this.widgetObject.categoryIdList = selectedCategoryIds.join(',');
      //this.widgetObject.hasSlicer = hasSlicer;

      if (this.isNewWidget) {
        this.addWidget(this.widgetObject);
      } else {
        params = params.append('TileId', this.widgetObject.tileId.toString());
        this.updateWidget(this.widgetObject);
      }
    }
  }

  private addWidget(data: WidgetInfo) {
    //this.http.put(this.baseUrl + 'api/AdminTile/AddTile', null, {
    //  params: params
    //})

    this.http.put(this.baseUrl + 'api/AdminTile/AddTile', JSON.stringify(data),
      { headers: { 'Content-Type': 'application/json' } }).subscribe({
        next: () => {
          this.snackBar.open('Tile Updatedy', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: 'success-snackbar'
          })
          //this.router.navigateByUrl('widgetlist');
          this.router.navigate(['/admin/widgetlist']);
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
        }
      })
  }

  private updateWidget(data: WidgetInfo) {
    //this.http.post(this.baseUrl + 'api/AdminTile/UpdateTile', null, {
    //  params: params
    //}).subscribe(data => {
    this.http.post(this.baseUrl + 'api/AdminTile/UpdateTile',
      JSON.stringify(data),
      { headers: { 'Content-Type': 'application/json' } }
    ).subscribe({
      next: () => {
        this.snackBar.open('Tile Updatedy', 'Ok', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5000,
          panelClass: 'success-snackbar'
        })
        //this.router.navigateByUrl('widgetlist');
        this.router.navigate(['/admin/widgetlist']);
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
      }
    })
  }

  openWidgetPerClientSettings() {
    if (this.widgetId && (this.widgetId.length > 0)) {
      this.router.navigate(['/admin/widgetperclientedit', this.widgetId.toString()]);
    } else {
      this.alertService.setAlertObject({ title: 'Please Save New Chart', message: 'You must save this new Chart before creating per-client settings.' });
    }
  }

  exitWidgetEdit() {
    //this.router.navigateByUrl('widgetlist');
    this.router.navigate(['/admin/widgetlist']);
  }

  ngOnDestroy() {
    this.routewidget.unsubscribe();
  }
}
