import { Component, OnInit, Input, Inject } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DriverViewEmail } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'dhp-view-email',
  templateUrl: './dhp-view-email.component.html',
  styleUrls: ['./dhp-view-email.component.css']
})
export class DHPViewEmailComponent implements OnInit {
  @Input() modalHeight: string;
  @Input() modalInput: any;
  // private variables that are only shared with subscribers that import the type
  baseUrl: string;
  emailInfo: any;


  constructor(private dhpService: DriverHistoryProfileService,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private onPremService: OnPremDriverService) {
    this.baseUrl = baseUrl;
  }


  ngOnInit(): void {
    this.loadingSpinnerService.show();

    this.onPremService.get('email/outbox/' + this.modalInput.driverEmailOutboxId.toString()).subscribe({
      next: (data) => {
        this.emailInfo = data as DriverViewEmail;
        // for testing
        //this.emailInfo.ccRecipients = 'testcc';
        //this.emailInfo.bcRecipients = 'testbc';
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.loadingSpinnerService.hide();
        this.errorService.setErrorObject(err.error);
      }
    })
  }


  cancel() {
    this.loadingSpinnerService.hide();
    // notify the modal window to close
    this.dhpService.notifyModalClose();
  }
}
