//Angular
import { Component, OnInit, OnDestroy, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
//Third Party
import { Subscription, forkJoin } from 'rxjs';
//App
import { ClientSelectionService, ClientSelectionsObject } from '../../components/client-selection-service/client-selection-service.component';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { MatDatepicker } from '@angular/material/datepicker';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';

@Component({
  selector: 'app-safety-mvr-state-codes',
  templateUrl: './safety-mvr-state-codes.component.html',
  styleUrls: ['./safety-mvr-state-codes.component.scss']
})
export class SafetyMvrStateCodesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('caExpDatePicker') caExpDatePicker: MatDatepicker<Date>;
  stateCodes: Array<MvrStateCode>;
  stateCodeForm: MvrStateCodeForm = new MvrStateCodeForm();
  actualDate = new Date();
  clientSelectionSubscription: Subscription;
  clientSelectedArray: Array<string>;


  constructor(private http: HttpClient,
    private clientService: ClientSelectionService,
    private onPremService: OnPremDriverService,
    private errorService: ErrorModalService,
    private loadingSpinnerService: LoadingSpinnerService,
    private lineOfBusinessService: LineOfBusinessService,
    private alertService: AlertService,
    private datePipe: DatePipe) {
  }


  ngOnInit(): void {
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
      let cArr = this.clientService.getClientSelectedArrayValue(2);
      if (cArr && (cArr.length > 0)) {
        this.clientSelectedArray = cArr;
      }
    }

    this.clientSelectionSubscription = this.clientService.clientSelectedInfo$.subscribe(data => {
      let temp = data as ClientSelectionsObject;
      if (temp[2]) {
        this.clientSelectedArray = temp[2].clientSelectedArray;

        this.getStateMvrCode(this.clientSelectedArray[0]).subscribe({
          next: (mvrData) => {
            this.loadingSpinnerService.show();
            this.stateCodes = mvrData as Array<MvrStateCode>;

            let caCode: MvrStateCode = this.stateCodes.find(x => x.stateCode == 'CA');
            if (caCode) {
              this.stateCodeForm.CaCode = caCode.clientAccessCode;
              this.stateCodeForm.CaExpirationDate = this.datePipe.transform(caCode.expirationDate, 'MM/dd/yyyy');
            }

            let utCode: MvrStateCode = this.stateCodes.find(x => x.stateCode == 'UT');
            if (utCode) {
              this.stateCodeForm.UtCode = utCode.clientAccessCode;
            }

            let paCode: MvrStateCode = this.stateCodes.find(x => x.stateCode == 'PA');
            if (paCode) {
              this.stateCodeForm.PaCode = paCode.clientAccessCode;
            }
            this.loadingSpinnerService.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.loadingSpinnerService.hide();
            this.errorService.setErrorObject(err.error);
          }
        });
      }
    })
  }

  ngAfterViewInit(): void {
    this.caExpDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.caExpDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
          this.caExpDatePicker.select(event.value);
          this.caExpDatePicker.close();
        })
      }, 0)
    })
  }

  ngOnDestroy(): void {
    if (this.clientSelectionSubscription)
      this.clientSelectionSubscription.unsubscribe();
  }

  public save(): void {
    //CA Expiration date is required
    let hasValidationErrors = false;

    if ((this.stateCodeForm.CaCode && this.stateCodeForm.CaCode != '') &&
      ((!this.stateCodeForm.CaExpirationDate) || this.stateCodeForm.CaExpirationDate == '')) {

      //CA Expiration date is invalid
      hasValidationErrors = true;
    }


    if (hasValidationErrors) {
      this.errorService.setErrorObject({ message: 'Missing or Invalid Fields.' });
      return;
    }

    this.saveStateMvrCode(this.clientSelectedArray[0]).subscribe(data => {
      this.alertService.showSuccessAlert('Successfully saved!', 'end', 'top', 5000);
    });

  }

  private saveStateMvrCode(client: string) {
    let params = new HttpParams();
    params = params.append('client', client);

    let caCode: MvrStateCode = new MvrStateCode();
    caCode.stateCode = 'CA';
    caCode.clientAccessCode = this.stateCodeForm.CaCode;
    caCode.expirationDate = this.stateCodeForm.CaExpirationDate;
    caCode.clientCode = client;

    let utCode: MvrStateCode = new MvrStateCode();
    utCode.stateCode = 'UT';
    utCode.clientAccessCode = this.stateCodeForm.UtCode;
    utCode.clientCode = client;

    let paCode: MvrStateCode = new MvrStateCode();
    paCode.stateCode = 'PA';
    paCode.clientAccessCode = this.stateCodeForm.PaCode;
    paCode.clientCode = client;

    let utRequest = this.onPremService.put('mvr/' + client + '/accesscodes/UT', JSON.stringify({ ClientAccessCode: utCode.clientAccessCode }));
    let caRequest = this.onPremService.put('mvr/' + client + '/accesscodes/CA', JSON.stringify({ ClientAccessCode: caCode.clientAccessCode, ExpirationDate: caCode.expirationDate }));
    let paRequest = this.onPremService.put('mvr/' + client + '/accesscodes/PA', JSON.stringify({ ClientAccessCode: paCode.clientAccessCode }));



    return forkJoin([utRequest, caRequest, paRequest]);
  }

  private getStateMvrCode(client: string) {
    return this.onPremService.get('mvr/' + client + '/accesscodes/');
  }
}

export class MvrStateCode {
  clientMvrAccessCodeId: number;
  clientCode: string;
  stateCode: string;
  clientAccessCode: string;
  expirationDate: string;
}

export class MvrStateCodeForm {
  CaCode: string;
  CaExpirationDate: string;
  UtCode: string;
  PaCode: string;
}
