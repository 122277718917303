<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" >
  <div class="col-md-6">
    <h3>Request MVRs to PDF</h3>
  </div>
  <div class="col-md-6" *ngIf="(!isSubmitted)&&(myStepper?.selectedIndex == 0)">
    <div style="float:right;margin-right:5px;">
      <button type="button"
              (click)="onGoToDriverSelect()"
              [disabled]="!criteriaValid()"
              class="btn btn-primary btn-next-step">
        Continue
      </button>
    </div>

  </div>
  <div class="col-md-6" *ngIf="(!isSubmitted)&&(myStepper?.selectedIndex == 1)">
    <div style="float:right;margin-right:5px;">
      <button type="button"
              (click)="onGoToCriteriaSelect()"
              class="btn btn-primary btn-prev-step">
        Back
      </button>
      <button type="button"
              (click)="submitMVRExport()"
              class="btn btn-primary btn-next-step">
        Request MVRs to PDF
      </button>
    </div>
  </div>
  <div class="col-md-6" *ngIf="isSubmitted">
    <h3 class="label-validation-error">SUBMITTED</h3>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <mat-stepper #stepper [linear]="true">
      <mat-step [stepControl]="criteriaForMVRFormGroup">
        <ng-template matStepLabel>Select Criteria for MVRs</ng-template>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-body mt-3">
                  <h4 class="card-title text-muted wrap-this">Date Range</h4>
                  <div class="row">
                    <div class="col-sm-4">
                      <mat-form-field appearance="fill">
                        <mat-label class="lbl-start-date" [ngClass]="{ 'label-validation-error': exportStartDateControl.invalid }">From</mat-label>
                        <input class="input-date" matInput disabled [matDatepicker]="startDatePicker" [min]="minDate" [max]="actualDate" [(ngModel)]="selectionCriteria.startDate" [formControl]="exportStartDateControl">
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker disabled="false">
                          <mat-datepicker-actions>
                            <button mat-button (click)="startDatePicker.select(actualDate); startDatePicker.close()">
                              <mat-icon>history</mat-icon>
                              Today
                            </button>
                            <button mat-button (click)="startDatePicker.select(null); startDatePicker.close()">
                              <mat-icon>highlight_off</mat-icon>
                              Clear
                            </button>
                          </mat-datepicker-actions> 
                        </mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                      <mat-form-field appearance="fill">
                        <mat-label [ngClass]="{ 'label-validation-error': exportEndDateControl.invalid }">Through</mat-label>
                        <input class="input-date" matInput disabled [matDatepicker]="endDatePicker"  [min]="minDate" [max]="actualDate" [(ngModel)]="selectionCriteria.endDate" [formControl]="exportEndDateControl">
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker disabled="false">
                          <mat-datepicker-actions>
                            <button mat-button (click)="endDatePicker.select(actualDate); endDatePicker.close()">
                              <mat-icon>history</mat-icon>
                              Today
                            </button>
                            <button mat-button (click)="endDatePicker.select(null); endDatePicker.close()">
                              <mat-icon>highlight_off</mat-icon>
                              Clear
                            </button>
                          </mat-datepicker-actions> 
                        </mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-body mt-3">
                  <h4 class="card-title text-muted wrap-this">Retrieve</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <form class="form-inline">
                        <div class="form-check mr-3">
                          <input class="form-check-input"
                                 name="retrieveType"
                                 type="radio"
                                 id="radioRetrieveAll"
                                 [(ngModel)]="selectionCriteria.mostRecent"
                                 [formControl]="exportRetrieveTypeControl"
                                 value="false">
                          <label [ngClass]="{ 'label-validation-error': exportRetrieveTypeControl.invalid, 'form-check-label':true }" for="radioRetrieveAll">All MVRs</label>
                        </div>

                        <div class="form-check mr-3">
                          <input class="form-check-input"
                                 name="retrieveType"
                                 type="radio"
                                 id="radioRetrieveRecent"
                                 [(ngModel)]="selectionCriteria.mostRecent"
                                 [formControl]="exportRetrieveTypeControl"
                                 value="true">
                          <label [ngClass]="{ 'label-validation-error': exportRetrieveTypeControl.invalid, 'form-check-label':true }" for="radioRetrieveRecent">Most Recent</label>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card card-body mt-3">
                  <h4 class="card-title text-muted wrap-this">Drivers</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <form class="form-inline">
                        <div class="form-check mr-3">
                          <input class="form-check-input"
                                 name="driverType"
                                 type="radio"
                                 id="radioDriverAll"
                                 [(ngModel)]="selectionCriteria.employeeType"
                                 [formControl]="exportDriverTypeControl"
                                 value="0">
                          <label [ngClass]="{ 'label-validation-error': exportDriverTypeControl.invalid, 'form-check-label':true }" for="radioDriverAll">All</label>
                        </div>

                        <div class="form-check mr-3">
                          <input class="form-check-input"
                                 name="driverType"
                                 type="radio"
                                 id="radioDriverEmployee"
                                 [(ngModel)]="selectionCriteria.employeeType"
                                 [formControl]="exportDriverTypeControl"
                                 value="1">
                          <label [ngClass]="{ 'label-validation-error': exportDriverTypeControl.invalid, 'form-check-label':true }" for="radioDriverEmployee">Employees Only</label>
                        </div>

                        <div class="form-check mr-3">
                          <input class="form-check-input"
                                 name="driverType"
                                 type="radio"
                                 id="radioDriverNonEmployee"
                                 [(ngModel)]="selectionCriteria.employeeType"
                                 [formControl]="exportDriverTypeControl"
                                 value="2">
                          <label [ngClass]="{ 'label-validation-error': exportDriverTypeControl.invalid, 'form-check-label':true }" for="radioDriverNonEmployee">Non-Employees Only</label>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Select Drivers for MVRs</ng-template>
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-body mt-3">
                  <h4 class="card-title text-muted wrap-this">Select Drivers From:</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <mat-tab-group>
                        <mat-tab label="Corporate Structure">
                          <app-corporate-structure-selector-folder 
                            [chType]="'MvrExport'"
                            [arrCHInfo]="arrCH"
                            [clientSelectedArray]="clientSelectedArray"
                            (onSelectionsProcessed)="getDrivers()"
                          ></app-corporate-structure-selector-folder>
                        </mat-tab>
                        <mat-tab label="State/Province">
                          <app-corporate-structure-selector-folder [chType]="'MvrExportByState'"
                            [arrCHInfo]="arrCHByState"
                            [clientSelectedArray]="clientSelectedArray"
                            (onSelectionsProcessed)="getDrivers()">
                          </app-corporate-structure-selector-folder>
                        </mat-tab>
                      </mat-tab-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-md-8">
            <app-export-driver-select 
              [arrDriver]="arrDriver"
              [showMVRDate]="true"
              [showLastMVRDate]="false"
              [showLastDVDate]="false"
              [showRiskLevel]="true"
              (onUpdateDriversSelected)="updateDriverIdSelected($event)"
              (onUpdateDriversExcluded)="updateDriverIdExcluded($event)"
            ></app-export-driver-select>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>
