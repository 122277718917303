<div class="row">
  <div class="col-sm-12 buttonsContainer"><button (click)="exportToPdf()" class="btn btn-default">Export To PDF</button></div>
</div>
<div class="row mt-3">
  <div class="col-md-12">
    <div class="card card-body">
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Client</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.client}}</p>
        </div>
        <div class="col-md-3">
          <p class="mb-0">Date of Loss</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.dateOfLoss | date:'M/d/yyyy'}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Driver Name</p>
        </div>
        <div class="col-md-9">
          <p class="font-weight-bold mb-0">{{approvalDetail?.driverName}}</p>
        </div>
      </div>

      <div class="row mt-3 d-flex flex-wrap">
        <div class="col-md-3">
          <p class="mb-0 wrap-text">{{clientReqTitles?.req1}}</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0 wrap-text">{{approvalDetail?.req1}}</p>
        </div>
        <div class="col-md-3">
          <p class="mb-0 wrap-text">{{clientReqTitles?.req2}}</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0 wrap-text">{{approvalDetail?.req2}}</p>
        </div>
      </div>
      <div class="row d-flex flex-wrap">
        <div class="col-md-3">
          <p class="mb-0 wrap-text">{{clientReqTitles?.req3}}</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0 wrap-text">{{approvalDetail?.req3}}</p>
        </div>
        <div class="col-md-3">
          <p class="mb-0 wrap-text">{{clientReqTitles?.req4}}</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0 wrap-text">{{approvalDetail?.req4}}</p>
        </div>
      </div>
      <div class="row d-flex flex-wrap">
        <div class="col-md-3">
          <p class="mb-0 wrap-text">{{clientReqTitles?.req5}}</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0 wrap-text">{{approvalDetail?.req5}}</p>
        </div>
        <div class="col-md-3">
          <p class="mb-0 wrap-text">{{clientReqTitles?.req6}}</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0 wrap-text">{{approvalDetail?.req6}}</p>
        </div>
      </div>
      <div class="row d-flex flex-wrap">
        <div class="col-md-3">
          <p class="mb-0 wrap-text">{{clientReqTitles?.req7}}</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0 wrap-text">{{approvalDetail?.req7}}</p>
        </div>
        <div class="col-md-3">
          <p class="mb-0 wrap-text">{{clientReqTitles?.req8}}</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0 wrap-text">{{approvalDetail?.req8}}</p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-3">
          <p class="mb-0">Vehicle</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.vehicle}}</p>
        </div>
        <div class="col-md-3">
          <p class="mb-0">VIN</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.vin}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Year</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.vehicleYear}}</p>
        </div>
        <div class="col-md-3">
          <p class="mb-0">Lic. Plate</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.licensePlate}} <span *ngIf="approvalDetail?.licensePlateState && approvalDetail?.licensePlate!='UNKNOWN'">({{approvalDetail?.licensePlateState}})</span></p>
        </div>

      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Make</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.vehicleMake}}</p>
        </div>
        <div class="col-md-3">
          <p class="mb-0">Model</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.vehicleModel}}</p>
        </div>


      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Mileage</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.vehicleOdometer}}</p>
        </div>
        <div class="col-md-3">
          <p class="mb-0">Drivable</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.isVehicleDrivable}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Preventability</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.preventability}}</p>
        </div>
        <div class="col-md-3">
          <p class="mb-0">Subro</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.isSubro}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-12">
    <div class="card card-body">
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Action Pending</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.requestDate | date:'M/d/yyyy'}}</p>
        </div>
        <div class="col-md-6">
          <p class="font-weight-bold red-text mb-0">{{approvalDetail?.approvalDescription}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Note to Client</p>
        </div>
        <div class="col-md-9 d-flex flex-wrap">
          <p class="font-weight-bold mb-0 wrap-text">{{approvalDetail?.noteToClient}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Recommendation</p>
        </div>
        <div class="col-md-9 d-flex flex-wrap">
          <p class="font-weight-bold mb-0 wrap-text">{{approvalDetail?.recommendation}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Estimate Type</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.estimateDate | date:'M/d/yyyy'}}</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold red-text mb-0">{{approvalDetail?.estimateType}}</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.estimateAmount | currency:'USD':'$':'1.2-2'}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Related Document</p>
        </div>
        <div class="col-md-3">
          <a (click)="getDownloadUrl(approvalDetail)" class="font-weight-bold red-text mb-0">{{approvalDetail?.documentDescription}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-12">
    <div class="card card-body">
      <h4 class="card-title text-muted">Client Response: Required Action</h4>
      <div class="row" *ngIf="disableApproval"> 
        <div class="col-md-6">
          <p class="font-weight-bold red-text mb-0">You do not have permission to submit changes.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-check" *ngFor="let response of approvalResponses">
            <input class="form-check-input" type="radio" [id]="'response-' + response.responseLookupId" [name]="'clientResponseLookupId'" [value]="response.responseLookupId" [(ngModel)]="clientResponseId" (change)="onResponseChange()" [disabled]="disableApproval" />
            <label [for]="'response-' + response.responseLookupId" class="form-check-label font-weight-normal">{{response.responseDescription}}</label>
          </div>
        </div>
        <div class="col-md-6 md-form">
          <span>Comments</span>
          <textarea class="form-control" rows="5" cols="5" [(ngModel)]="comments" [name]="comments" [disabled]="disableApproval"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" *ngIf="showTitleHolders">
          <div class="row">
            <div class="col-md-12">
              <mat-form-field appearance="fill" class="select-container">
                <mat-label>Select Leasing Company</mat-label>
                <mat-select #selectTitleHolder="matSelect" [(value)]="titleHolderId">
                  <input matInput class="select-search-input" placeholder="Search Leasing Companies..." (keyup)="filterOptions($event.target.value)">
                  <mat-option *ngFor="let option of filteredTitleHolderOptions" [value]="option.value">
                    {{option.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-3">
              <span>Please confirm the above is who Fleet Response works with to obtain the title:</span>
              <div class="form-check mr-3">
                <input class="form-check-input" type="radio" [id]="'verifyTitleHolder-Yes'" [name]="'verifyTitleHolder'" [value]="true" [(ngModel)]="verifyTitleHolder"  />
                <label [for]="'verifyTitleHolder-Yes'" class="form-check-label font-weight-normal">Yes</label>
              </div>
              <div class="form-check mr-3">
                <input class="form-check-input" type="radio" [id]="'verifyTitleHolder-No'" [name]="'verifyTitleHolder'" [value]="false" [(ngModel)]="verifyTitleHolder"  />
                <label [for]="'verifyTitleHolder-No'" class="form-check-label font-weight-normal">No</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="showBuyItNowSection">
          <div class="row">
            <div class="col-md-12" *ngIf="showBuyItNowOptions">
              <span>Would you like to add a "Buy It Now" button to the salvage auction?</span>
              <div class="form-check">
                <input class="form-check-input" type="radio" [id]="'buyItNowOption-No'" [name]="'buyItNowOption'" [value]="1" [(ngModel)]="buyItNowOption"  />
                <label [for]="'buyItNowOption-No'" class="form-check-label font-weight-normal">No</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" [id]="'buyItNowOption-Yes'" [name]="'buyItNowOption'" [value]="2" [(ngModel)]="buyItNowOption"  />
                <label [for]="'buyItNowOption-Yes'" class="form-check-label font-weight-normal">Yes, let salvage coordinator set the price</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" [id]="'buyItNowOption-YesAmount'" [name]="'buyItNowOption'" [value]="3" [(ngModel)]="buyItNowOption"  />
                <label [for]="'buyItNowOption-YesAmount'" class="form-check-label font-weight-normal">Yes, 'Buy It Now' Amount:</label>
              </div>
            </div>
            <div class="col-md-12">
              <mat-form-field class="example-full-width w-100" appearance="fill">
                <mat-label>Buy It Now Amount</mat-label>
                <input matInput id="buyItNowAmount" [name]="buyItNowAmount" [(ngModel)]="buyItNowAmount">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-md-offset-6 col-sm-12">
          <button type="button" class="btn btn-default" aria-label="Submit" (click)="submitClientApproval()" [disabled]="disableApproval">Submit</button>
          <button type="button" class="btn btn-default" aria-label="Cancel" (click)="resetClientApproval()" [disabled]="disableApproval">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-12">
    <div class="card card-body">
      <h4 class="card-title text-muted">Previous Review History</h4>

      <div class="row">
        <div class="col-md-12">
          <table class="table table-striped table-bordered" *ngIf="approvalHistory?.length > 0">
            <thead class="red darken-2 text-white">
              <tr>
                <th scope="col">Requested</th>
                <th scope="col">Requested Action</th>
                <th scope="col">Note to Client</th>
                <th scope="col">Reviewed Amount</th>
                <th scope="col">Reviewed By</th>
                <th scope="col">Replied</th>
                <th scope="col">Action Taken</th>
                <th scope="col">Client Comments</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let history of approvalHistory">
                <td>{{history.requestDate | date:'M/d/yyyy'}}</td>
                <td>{{history.approvalDescription}}</td>
                <td>{{history.noteToClient}}</td>
                <td>{{history.estimateAmount | currency:'USD':'$':'1.2-2'}}</td>
                <td>{{history.contactName}}</td>
                <td>{{history.responseDate | date:'M/d/yyyy'}}</td>
                <td>{{history.responseDescription}}</td>
                <td>{{history.clientComments}}</td>
              </tr>
            </tbody>
          </table>
          <p *ngIf="approvalHistory?.length == 0">(No Approval History Found)</p>
        </div>
      </div>
    </div>
  </div>
</div>
