import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { minimumCheckboxesSelected } from '../../../components/classes-and-interfaces/classes-and-interfaces.component';


@Component({
  selector: 'app-driver-type',
  templateUrl: './driver-type.component.html',
  styleUrls: ['./driver-type.component.css']
})
export class DriverTypeComponent implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() showLicenseVerificationOption: boolean;
  @Input() selectorPrefix: string;
  public driverTypesForm = new UntypedFormGroup({});

  constructor() { }

  ngOnInit(): void {
    this.driverTypesForm.addControl('Employee', new UntypedFormControl(true));
    this.driverTypesForm.addControl('Non-employee', new UntypedFormControl(false));
    this.driverTypesForm.addControl('NoLicVer_Mvr', new UntypedFormControl(false));
    this.driverTypesForm.addControl('DOT', new UntypedFormControl(false));    
    this.driverTypesForm.addControl('AutoCovReq', new UntypedFormControl(false));     
    this.driverTypesForm.setValidators([minimumCheckboxesSelected(1)]);
    this.parentForm.addControl('driverTypes', this.driverTypesForm);
  }




}
