<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <div class="row" *ngIf="claimInfo?.clientClaimPartyType != 'Third Party'">
        <div class="col-sm-3 accidentClaimTabLabel">
          Client:
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          {{claimInfo?.client}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 accidentClaimTabLabel">
          {{claimInfo?.clientClaimPartyType == 'First Party'? 'Driver Name:':'Claimant:'}}
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          {{claimInfo?.driverName}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 accidentClaimTabLabel">
          Driver Email:
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          {{claimInfo?.driverEmail}}
        </div>
      </div>
      <div class="row accidentClaimTabPaddingTop">
        <div class="col-sm-3 accidentClaimTabLabel">
          Date of Loss:
        </div>
        <div class="col-sm-3 accidentClaimTabBold">
          {{claimInfo?.dateOfLoss |  date:'M/d/yyyy'}}
        </div>
        <div class="col-sm-3 accidentClaimTabLabel">
          Date Reported:
        </div>
        <div class="col-sm-3 accidentClaimTabBold">
          {{claimInfo?.dateReported |  date:'M/d/yyyy'}}
        </div>
      </div>
      <div class="row accidentClaimTabPaddingBottom">
        <div class="col-sm-3 accidentClaimTabLabel">
          Date Closed:
        </div>
        <div class="col-sm-3 accidentClaimTabBold">
          {{claimInfo?.closedDate |  date:'M/d/yyyy'}}
        </div>
        <div class="col-sm-3 accidentClaimTabLabel">
          Date Invoiced:
        </div>
        <div class="col-sm-3 accidentClaimTabBold">
          {{claimInfo?.invoiceDate |  date:'M/d/yyyy'}}
        </div>
      </div>
      <div class="row" *ngFor="let reqObj of arrReq">
        <div class="col-sm-3 accidentClaimTabLabel">
          {{reqObj.label}}:
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          {{reqObj.req}}
        </div>
      </div>
      <div class="row accidentClaimTabPaddingTop" *ngFor="let info of firstThirdPartyClaimInfo">
        <div class="col-sm-3 accidentClaimTabLabel">
          {{info.label}}
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          <a *ngIf="info.showLink" href="javascript:void(0);" (click)="goToClaim(info.claimId)">
            {{info.claimId}}
          </a>
          <span *ngIf="!info.showLink">{{info.claimId}}</span>
          {{info.systemSourceLabel}}
        </div>
      </div>
      <div class="row accidentClaimTabPaddingTop" *ngIf="claimInfo?.clientClaimPartyType != 'Third Party'">
        <div class="col-sm-3 accidentClaimTabLabel">
          Assigned Driver ID:
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          <a [routerLink]="['/driver/history-profile', claimInfo?.driverId]" *ngIf="claimInfo?.driverId && claimInfo?.driverId > 0">
            {{claimInfo?.assignedDriverId}}
          </a>
          <span *ngIf="claimInfo?.driverId === 0 && claimInfo?.assignedDriverId">{{claimInfo?.assignedDriverId}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 accidentClaimTabLabel">
          Assigned Claim Rep:
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          <a href={{getMailToLink(claimInfo)}}>{{claimInfo?.assignedClaimRep}}</a>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 accidentClaimTabLabel">
          Assigned Claim Rep Phone Number:
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          {{claimInfo?.claimRepPhoneNumber}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 accidentClaimTabLabel">
          My Follow-up Date:
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          <p class="mb-0" *ngIf="claimInfo?.followUpDate">
            {{claimInfo?.followUpDate | date:'M/d/yyyy'}}
            <span>
              <button class="btn btn-xs btn-default my-0 p-1"
                      [disabled]="showUpsertFollowUpDate"
                      (click)="toggleUpsertFollowUpDate()">
                Update
              </button>
              <button class="btn btn-xs btn-default my-0 p-1"
                      [disabled]="showUpsertFollowUpDate"
                      (click)="deleteFollowUpDate()">
                Delete
              </button>
            </span>
          </p>
          <p class="mb-0" *ngIf="!claimInfo?.followUpDate">
            Not Assigned
            <span>
              <button class="btn btn-xs btn-default my-0 p-1"
                      [disabled]="showUpsertFollowUpDate"
                      (click)="toggleUpsertFollowUpDate()">
                Assign
              </button>
            </span>
          </p>
        </div>
      </div>
      <app-accident-claim-follow-up-date *ngIf="showUpsertFollowUpDate"
                                         [claimId]="_claimId"
                                         (onUpsertFollowUpDate)="refreshClaimInfo()"
                                         (onCancelUpsertFollowUpDate)="toggleUpsertFollowUpDate()"></app-accident-claim-follow-up-date>
      <div class="row accidentClaimTabPaddingTop">
        <div class="col-sm-3 accidentClaimTabLabel">
          Accident Reason:
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          {{claimInfo?.accidentReason}}
        </div>
      </div>
      <div class="row" *ngIf="claimInfo?.clientAccidentType">
        <div class="col-sm-3">
          Accident Type:
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          {{claimInfo?.clientAccidentType}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          Preventability:
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          {{claimInfo?.preventability}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          Subro:
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          {{claimInfo?.subro}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          Initial Vehicle Location:
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          {{claimInfo?.locationDescription}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          Rental:
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          {{claimInfo?.rental}}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="actionHistory?.length > 0">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h4 class="card-title text-muted">Claim Action History</h4>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-striped table-bordered">
            <thead>
              <tr class="table-header">
                <th scope="col">Date &amp; Time</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let history of actionHistory">
                <td>{{history.formattedCreateDate}}</td>
                <td>{{history.claimAction}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<app-accident-claim-note [noteType]="'Claim'" [noteTypeId]="4" [userRights]="userRights"></app-accident-claim-note>
