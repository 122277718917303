import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AdditionalLicenses, MVRReport } from './../../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DHPMVRReportService } from '../dhp-mvr-report-service.component';
import { DhpMvrCustomRowComponent } from '../dhp-mvr-custom-row/dhp-mvr-custom-row.component';

@Component({
  selector: 'dhp-mvr-report-license-information',
  templateUrl: './dhp-mvr-report-license-information.component.html',
  styleUrls: ['../../dhp-mvr-report/dhp-mvr-report.component.css']
})
export class DhpMvrReportLicenseInformationComponent implements OnInit {
  mvrReport: MVRReport;
  public licenseInformationTableConfig: any;

  constructor(private readonly datePipe: DatePipe,
    private readonly dhpMVRReportService: DHPMVRReportService) { }
    gtOptions: any = {};

  ngOnInit(): void {
    this.dhpMVRReportService.sharedMvrReport.subscribe(mvrData => { 
      if(mvrData != null ) {
        this.mvrReport = mvrData;
        const licenseArray = (this.mvrReport.additionalLicenses != null) ?  this.mvrReport.additionalLicenses : [];
        this.setLicenseInformationTableConfig(licenseArray);
      }
    });
  }

  private setLicenseInformationTableConfig(licenseArray: Array<AdditionalLicenses>) {
    const columns = [
      { name: "Details", prop: "details", title: "Details", visible: true, dataType: null, columnClass:"width7"  },
      { name: "License Id", prop: "licenseId", title: "License Id", visible: true, dataType: null, columnClass: "width15"  },
      { name: "Type", prop: "type", title: "Type", visible: true, dataType: null, columnClass: ""  },
      { name: "Primary License", prop: "primaryLicense", title: "Primary License", visible: true, dataType: null, columnClass: ""  },      
      { name: "Issue Date", prop: "issueDate", title: "Issue Date", visible: true, dataType: "date", columnClass: ""  },
      { name: "Expire Date", prop: "expireDate", title: "Expire Date", visible: true, dataType: "date", columnClass: ""  },   
      { name: "Renewal Date", prop: "renewalDate", title: "Renewal Date", visible: true, dataType: "date", columnClass: ""  },   
      { name: "Original Issue Expiration", prop: "origIssueE", title: "Original Issue Expiration", visible: true, dataType: "date", columnClass: ""  },              
      { name: "Class Code", prop: "classCode", title: "Class Code", visible: true, dataType: null, columnClass: ""  },
      { name: "Class", prop: "class", title: "Class", visible: true, dataType: null, columnClass: ""  },
      { name: "Total State Points", prop: "totalStatePoints", title: "Total State Points", visible: true, dataType: null, columnClass: ""  }
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    const fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s.dataType,
        hidden: !s.visible,
        expand: {
          component: DhpMvrCustomRowComponent
        },        
        columnClass: (row, col) => {
          return s.columnClass;
        },
        render: row => {
          if (s.dataType === "date") {
            return this.datePipe.transform(row[s.prop], 'MM/dd/yyyy');
          } else if(s.prop === "details"){
            return '<span class="row-toggler"><i class="fa fa-bars"></i></span>'
          } else {
            return row[s.prop];
          }
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.licenseInformationTableConfig = {
      settings: settings,
      fields: fields,
      data: licenseArray,
      expandable: true,
      expand: {
        type: 'dhpMvrCustomRowComponent'
      },
      noRecordsLabel: 'No License Records'
    }
    this.gtOptions = (licenseArray.length > 1 ? 
      { 
        numberOfRows: licenseArray.length, rowExpandInitialState: false, rowExpandInitialComponent: 
        { 
          component: DhpMvrCustomRowComponent, data: (row) => row.additionalLicensesExtended 
        } 
      } 
        : 
      { 
        rowExpandInitialState: false, rowExpandInitialComponent: 
          { 
            component: DhpMvrCustomRowComponent, data: (row) => row.additionalLicensesExtended 
          }
      });
  }
}
