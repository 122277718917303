//angular
import { Component } from '@angular/core';
//app
import { ErrorObject } from "../classes-and-interfaces/classes-and-interfaces.component";
import { IELearnMoreModalService } from '../ie-learnmore-modal/ie-learnmore-modal-service.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ie-learnmore-modal',
  templateUrl: './ie-learnmore-modal.component.html',
  styleUrls: ['./ie-learnmore-modal.component.scss']
})
export class IELearnMoreModalComponent {

  constructor(private ieModalService: IELearnMoreModalService,
    private dialogRef: MatDialogRef<IELearnMoreModalComponent>) {
  }

  openInEdge() {
    window.location.href = "microsoft-edge:" + window.location.href;
  }

  closeIEModal() {
    this.ieModalService.closeModal();
    this.dialogRef.close();
  }
}
