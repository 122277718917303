
import {throwError as observableThrowError,  Subject ,  Observable ,  BehaviorSubject } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DriverProfile } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

@Injectable()
export class SafetyTrainingService {
  baseUrl: string;
  errorMessage: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  ///* GET BY LOB ID */
  //getByUserLOB(LineOfBusinessId: number): Observable<GetLOB[]> {
  //  let params = new HttpParams();
  //  params = params.append('LineOfBusinessId', LineOfBusinessId.toString());
  //  return this.http.get<GetLOB[]>(this.baseUrl + 'api/CustomReport/GetByUserLob', {
  //    params: params
  //  }).catch(this.handleError);
  //}
  
  /* HANDLES ENCOUNTERED ERRORS (I'LL TAKE THIS OUT AFTER TESTING) */
  private handleError(err: HttpErrorResponse) {
    return observableThrowError(err.error.message);
  }
}
