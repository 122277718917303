<div class="quickSearchContainer">
    <quick-search-menu></quick-search-menu>
  </div>
  <div class="row" >
    <div class="col-md-12">
      <H3>Document Library</H3>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 left-side-align">
      <div class="col-md-2">
        <a href="https://get.adobe.com/reader/" target="_blank" rel="noopener noreferrer">
          <img alt="Adobe Reader" src="images/get-adobe-reader.png" />
        </a>
      </div>
      <div class="col-md-10">
        <p>Adobe Acrobat Reader must be installed to be able to view and print the PDF version of these files.</p>
        <p>Please click on the Adobe icon on the left to download Acrobat Reader.</p>
      </div>
    </div>
  </div>

  <div class="row mt-5 documentRowHeader">
    <div class="col-md-12 left-side-align">
      <span class="col-md-4 documentRowHeader">
        Document
      </span>
      <span class="col-md-6 documentRowHeader">
        Description
      </span>
    </div>
  </div>
  
  <div *ngIf="documents.length > 0; else elseBlock">
    <div class="row" *ngFor="let doc of documents">
      <div *ngIf="doc.isVisible" class="col-md-12 left-side-align">
        <span class="col-md-4">
          <a href="{{doc.azureBlobFullUrl}}" target="_blank">{{doc.title}}</a>
        </span>
        <span class="col-md-6">
          {{doc.description}}
        </span>
      </div>
    </div>
  </div>
  <ng-template #elseBlock>
    <div class="row mt-5">
      There are currenty no documents
    </div>
  </ng-template>

