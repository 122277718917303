import { Component, OnInit } from '@angular/core';
import { SafetyMoveDriverService } from './safety-move-driver.service';
import { DhpHierarchy, MoveDriver, DriverProfile, ClientSelectionObject } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-safety-move-driver',
  templateUrl: './safety-move-driver.component.html',
  styleUrls: ['./safety-move-driver.component.css']
})
export class SafetyMoveDriverComponent implements OnInit {
  selectedDriver: DriverProfile;
  searchedDhpHierarchy: DhpHierarchy[];
  selectedDhp: DhpHierarchy;
  isSearchingDriverGroup: boolean = false;
  isMovingDriver: boolean = false;
  showMoveDriverErrorMessage: boolean = false;
  showMoveDriverSuccessfulMessage: boolean = false;
  isLocatingDriver: boolean = true;
  clientSelections: ClientSelectionObject[];



  constructor(private safetyService: SafetyMoveDriverService, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.getDriverProfile();
  }

  getDriverProfile() {
    this.safetyService.getDriverProfile(this.route.snapshot.paramMap.get('id')).subscribe({
      next: (res) => {
        this.selectedDriver = res,
          this.getClientSelections(res.clientCode);
      },
      error: (err) => this.selectedDriver = null,
      complete: () => this.isLocatingDriver = false
    }
    );
  }

  getClientSelections(clientCode: string) {
    this.safetyService.getRelatedClients(clientCode).subscribe({
      next: (res) => this.clientSelections = res,
      error: (err) => this.clientSelections = null
    }
    )
  }

  selectedDhpChange(dhp: DhpHierarchy) {
    this.selectedDhp = dhp;
  }

  searchDhpGroup(clientCode: string) {
    this.isSearchingDriverGroup = true;
    this.safetyService.getDhpHierarchy(clientCode).subscribe({
      next: (res) => this.setSearchDhpHierarchy(res, clientCode),
      error: (err) => this.searchedDhpHierarchy = null,
      complete: () => this.isSearchingDriverGroup = false
    }
    );
  }

  moveDriver() {
    this.isMovingDriver = true;
    if (this.selectedDriver && this.searchedDhpHierarchy) {
      const moveDriver: MoveDriver = {
        driverId: this.selectedDriver.driverId,
        elementGuid: this.selectedDhp.elementGuid
      };
      this.safetyService.moveDriver(moveDriver).subscribe({
        next: (res) => this.setMoveDriverStatus(res),
        error: (err) => this.showMoveDriverErrorMessage = true,
        complete: () => this.isMovingDriver = false
      }
      )
    }
  }

  setMoveDriverStatus(moveDriver: MoveDriver) {
    this.showMoveDriverSuccessfulMessage = moveDriver?.driverId > 0;
    this.showMoveDriverErrorMessage = moveDriver?.driverId > 0 ? false : true;
  }


  setSearchDhpHierarchy(dhps: DhpHierarchy[], clientCode: string) {
    if (dhps && dhps.length > 0) {
      this.searchedDhpHierarchy = dhps;
      for (let i = 0; i < this.searchedDhpHierarchy.length; i++) {
        this.searchedDhpHierarchy[i].dhpClient = clientCode;
      }
    }
    else {
      this.searchedDhpHierarchy = null;
    }
  }

  refresh() {
    window.location.reload();
  }




}
