//Angular
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewChildren, QueryList, Renderer2 } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
//Third Party
import { Subscription, Observable } from 'rxjs';
//App
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { DcProductDocumentCategory } from '../dc-classes-and-interfaces/dc-classes-and-interfaces.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-dc-document-library',
  templateUrl: './dc-document-library.component.html',
  styleUrls: ['./dc-document-library.component.scss']
})
export class DcDocumentLibraryComponent implements OnInit {
  documents: Array<DcProductDocumentCategory>;
  //companyIdSelectedArray: Array<number>;
  //companyId: number;
  panelOpenState = false;

  constructor(private lineOfBusinessService: LineOfBusinessService,
    //private companySelectionService: CompanySelectionService,
    private onPremService: OnPremDcService,
    private loadingSpinnerService: LoadingSpinnerService,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private errorService: ErrorModalService) { }

  ngOnInit(): void {
    // set line of business to dc 
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 7) {
      this.lineOfBusinessService.setLineOfBusiness(7);
    }

    this.getDocs().subscribe({
      next: (data) => {
        this.documents = data as Array<DcProductDocumentCategory>;
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

    // get the selected company
    /*
    this.companyIdSelectedArray = this.companySelectionService.getSavedCompanyIds(7);
    if (this.companyIdSelectedArray && (this.companyIdSelectedArray.length > 0)) {
      this.companyId = this.companyIdSelectedArray[0];
      this.getDocs(this.companyId).subscribe(data => {
        this.documents = data as Array<DcProductDocumentCategory>;        
      },
        (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);         
        });
    } 
    */
  }

  private getDocs() {
    this.loadingSpinnerService.show();
    return this.onPremService.get('documentlibrary');
  }

}

