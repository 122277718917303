<div class="row">
  <div class="col-md-12">
      <h3 class="text-muted">Advanced Search</h3>
      <form [formGroup]="advancedSearchForm"  (ngSubmit)="search()" >
        <div class="card card-body mb-3">
          <h4 class="card-title text-muted">Select Criteria</h4>
    
          <div class="row mb-3">
            <div class="col-md-4">
              <h5 class="card-title text-muted">Driver Status</h5>
              <div class="form-check">
                <input type="checkbox" class="form-check-input form-control" name="driverStatusActive" formControlName="driverStatusActive" id="driverStatusActive" value="true" [(ngModel)]="searchRequest.IncludeActiveDrivers" />
                <label class="form-check-label font-weight-normal" for="driverStatusActive">Active</label>
              </div>
              <div class="form-check">
                <input type="checkbox" class="form-check-input form-control" name="driverStatusInactive" formControlName="driverStatusInactive" id="driverStatusInactive" value="false" [(ngModel)]="searchRequest.IncludeInactiveDrivers" />
                <label class="form-check-label font-weight-normal" for="driverStatusInactive">Inactive</label>
              </div>
              <div class="form-check">
                <input type="checkbox" class="form-check-input form-control" name="driverStatusLeaveOfAbsence" formControlName="driverStatusLeaveOfAbsence" id="driverStatusLeaveOfAbsence" value="false" [(ngModel)]="searchRequest.IncludeDriversOnLeaveOfAbsence" />
                <label class="form-check-label font-weight-normal" for="driverStatusLeaveOfAbsence">Leave of Absence</label>
              </div>
              <div class="label-validation-error" *ngIf="formControls.driverStatusActive.errors.validateField && formControls.driverStatusInactive.errors.validateField && formControls.driverStatusLeaveOfAbsence.errors.validateField">Driver Status is Required</div>            
            </div>
            <div class="col-md-4">
              <h5 class="card-title text-muted">Client Selection</h5>            
              <div class="form-check">
                <input type="radio" class="form-check-input" name="clientSelection" formControlName="clientSelection" id="searchCurrentClient" value="searchCurrent" [(ngModel)]="searchRequest.ClientSelection" />
                <label class="form-check-label font-weight-normal" for="searchCurrentClient">Search Current Client</label>
              </div>
              <div class="form-check">
                <input type="radio" class="form-check-input" name="clientSelection" formControlName="clientSelection" id="searchAllClients" value="searchAll" [(ngModel)]="searchRequest.ClientSelection" />
                <label class="form-check-label font-weight-normal" for="searchAllClients">Search All Clients</label>
              </div>
            </div>
            <div class="col-md-4">
              <h5 class="card-title text-muted">Driver Type</h5>              
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="driverTypeEmployees" formControlName="driverTypeEmployees" id="driverTypeEmployees" value="true" [(ngModel)]="searchRequest.IncludeEmployees" />
                <label class="form-check-label font-weight-normal" for="driverTypeEmployees">Employees</label>
              </div>
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="driverTypeNonEmployees" formControlName="driverTypeNonEmployees" id="driverTypeNonEmployees" value="false" [(ngModel)]="searchRequest.IncludeNonEmployees" />
                <label class="form-check-label font-weight-normal" for="driverTypeNonEmployees">Non-Employees</label>
              </div>
              <div class="label-validation-error" *ngIf="formControls.driverTypeEmployees.errors.validateField && formControls.driverTypeNonEmployees.errors.validateField">Driver Type is Required</div>
            </div>                    
          </div>
      

          <div class="card card-body mb-3">
            <h4 class="card-title text-muted">Driver Search</h4>
        
            <div class="row">
              <div class="col-md-4">
                <div class="md-form">
                  <input id="driverFirstName" formControlName="driverFirstName" type="text" class="form-control" placeholder="Driver First Name" [(ngModel)]="searchRequest.DriverFirstName" />
                  <label for="driverFirstName" class="active" >Driver First Name</label>
                </div>
              </div>
          
              <div class="col-md-4">
                <div class="md-form">
                  <input id="driverEmail" formControlName="driverEmail" type="text" class="form-control" placeholder="Driver Email" [(ngModel)]="searchRequest.EmailAddress" />
                  <label for="driverEmail" class="active" >Driver Email</label>
                </div>
              </div>

              <div class="col-md-4">
                <div class="md-form">
                  <input id="driverLicenseNumber" formControlName="driverLicenseNumber" type="text" class="form-control" placeholder="Driver License Number" [(ngModel)]="searchRequest.DriverLicenseNumber" />
                  <label for="driverLicenseNumber" class="active" >Driver License Number</label>
                </div>
              </div>          
            </div>
        
            <div class="row">
              <div class="col-md-4">
                <div class="md-form">
                  <input id="driverLastName" formControlName="driverLastName" type="text" class="form-control" placeholder="Driver Last Name" [(ngModel)]="searchRequest.DriverLastName" />
                  <label for="driverLastName" class="active" >Driver Last Name</label>
                </div>
              </div> 
          
              <div class="col-md-4">
                <div class="md-form">
                  <input id="driverId" formControlName="driverId" type="text" class="form-control" placeholder="Driver ID" [(ngModel)]="searchRequest.EmployeeId" />
                  <label for="driverId" class="active" >Driver ID</label>
                </div>
              </div>

              <div class="col-md-4">
                <div class="md-form">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Select a State or Province</mat-label>
                    <mat-select #selectStateProvidence="matSelect" [(value)]="searchRequest.DriverLicenseState" formControlName="driverLicenseState">
                      <input matInput class="select-search-input" placeholder="Search States/Provinces..." (keyup)="filterOptions($event.target.value)">
                      <mat-option *ngFor="let option of filteredStateProvinceOptions" [value]="option.value">
                        {{option.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>    
              <div class="label-validation-error" *ngIf="formControls.driverFirstName.errors.validateField && formControls.driverLastName.errors.validateField && formControls.driverEmail.errors.validateField && formControls.driverLicenseNumber.errors.validateField && formControls.driverId.errors.validateField && formControls.driverLicenseState.errors.validateField">You must fill in at least one Driver Search field</div>      
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-default" type="submit">Search</button>
              <div class="ml-2 text-danger" *ngIf="formControls.driverStatusActive.errors.validateField && formControls.driverStatusInactive.errors.validateField && formControls.driverStatusLeaveOfAbsence.errors.validateField">You must select a Driver Status to proceed</div>
              <div class="ml-2 text-danger" *ngIf="formControls.driverTypeEmployees.errors.validateField && formControls.driverTypeNonEmployees.errors.validateField">You must select a Driver Type to proceed</div>
              <div class="ml-2 text-danger">Note: this search will only return the first 100 drivers</div>
            </div>
          </div>
        </div>
    </form>
  </div>
</div>

