import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { Subscription, Observable, forkJoin } from 'rxjs';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { DriverProfile, DQFileManagementBaseInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DQRejectModalComponent, DqRejectModalReason } from '../dq-reject-modal/dq-reject-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DQApproveModalComponent } from '../dq-approve-modal/dq-approve-modal.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';

@Component({
  selector: 'app-safety-mvr-state-codes',
  templateUrl: './dq-record-of-road-test.component.html',
  styleUrls: ['./dq-record-of-road-test.component.css']
})
export class DqRecordOfRoadTestComponent implements OnInit, OnDestroy {

  private routeSub: Subscription;
  private driverId: string;
  private driverProcessAssignedId: string;
  private returnUrl: string;
  recordOfRoadtest: DQFileManagementBaseInfo;
  recordOfRoadtestSubmitInfo: DriverLicenseSubmitInfo;
  driverInfo: DriverProfile;
  rejectOptions: Array<any>;
  arrFileName: Array<string> = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private onPremService: OnPremDriverService,
    private lineOfBusinessService: LineOfBusinessService,
    private driverHistoryProfileService: DriverHistoryProfileService,
    private loadingSpinnerService: LoadingSpinnerService,
    private alertService: AlertService,
    private errorService: ErrorModalService,
    private dialog: MatDialog
  ) {
    //get the returnUrl if set
    var nav = this.router.getCurrentNavigation();
    this.returnUrl = nav?.extras?.state?.returnUrl || "";
  }

  ngOnInit(): void {
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    this.routeSub = this.route.params.subscribe(params => {
      this.driverId = params['driverId'];
      this.driverProcessAssignedId = params['driverProcessAssignedId'];
      let endpoint = "";
      if (this.driverProcessAssignedId) {
        endpoint = "driverQualification/" + this.driverId + "/recordofroadtest/" + this.driverProcessAssignedId;
      } else {
        endpoint = "driverQualification/" + this.driverId + "/recordofroadtest/latest";
      }
      if (endpoint.length > 0) {
        this.loadingSpinnerService.show();
        this.onPremService.get(endpoint).subscribe({
          next: (data) => {
            this.recordOfRoadtest = data as DQFileManagementBaseInfo;
            if (!this.recordOfRoadtest.documentId) {
              this.returnToListing();
            } else {
              this.arrFileName = [this.recordOfRoadtest.fileName];
              this.recordOfRoadtestSubmitInfo = new DriverLicenseSubmitInfo(this.recordOfRoadtest);
              let arrObs: Array<Observable<Object>> = [
                this.driverHistoryProfileService.getDriverHistoryProfileBaseData(this.driverId),
                this.onPremService.get("lookup/rejectionreasons/" + this.recordOfRoadtest.processId.toString())
              ];

              forkJoin(arrObs).subscribe({
                next: (profile) => {
                  this.driverInfo = profile[0] as DriverProfile;
                  const rejectOptions: Array<DriverLicenseRejectReason> = profile[1] as Array<DriverLicenseRejectReason>;
                  this.processRejectOptions(rejectOptions);
                  this.loadingSpinnerService.hide();
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
            }
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err.error);
            this.loadingSpinnerService.hide();
          }
        });
      } else {
        this.errorService.setErrorObject({ message: 'Invalid Parameters' });
      }
    });
  }
  private processRejectOptions(tempin: Array<DriverLicenseRejectReason>) {
    const tempout: Array<DqRejectModalReason> = [];
    tempin.forEach((item: DriverLicenseRejectReason, index: number) => {
      if (item.id == null) item.id = 0;
      const tempobj = {
        value: item.id.toString(),
        label: item.description,
        isReasonRequired: (item.id == 1)
      };
      tempout.push(tempobj);
    })
    this.rejectOptions = tempout;
  }

  openDriverProfile() {
    this.router.navigate(["/driver/history-profile/" + this.driverId]);
  }

  openApproveModal() {
    const dialogRef = this.dialog.open(DQApproveModalComponent, {
      data: { dqType: 'Driver\'s License', attNames: this.arrFileName },
      minWidth: '31em',
      minHeight: '10em',
      panelClass: 'add-document-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.approve();
    })
  }

  openRejectModal() {
    const dialogRef = this.dialog.open(DQRejectModalComponent, {
      data: { dqType: 'Road Test', rejectOptions: this.rejectOptions },
      minWidth: '31em',
      minHeight: '10em',
      panelClass: 'add-document-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.reject(result);
    })
  }

  approve() {
    let endpoint = "driverQualification/" + this.driverId + "/recordofroadtest/" + this.recordOfRoadtestSubmitInfo.driverProcessAssignedId.toString() + "/approve";
    let postData = JSON.parse(JSON.stringify(this.recordOfRoadtestSubmitInfo));
    delete postData.rejectionReasonId;
    delete postData.rejectionReasonIdString;

    let postput = this.onPremService.post(endpoint, JSON.stringify(postData));

    this.loadingSpinnerService.show();
    postput.subscribe({
      next: (data) => {
        this.alertService.showSuccessAlert('Road Test Upload Approved', 'end', 'top', 5000);
        this.loadingSpinnerService.hide();
        this.returnToListing();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error.message);
        this.loadingSpinnerService.hide();
      }
    })
  }

  reject(rejectInfo: any) {
    let endpoint = "driverQualification/" + this.driverId + "/recordofroadtest/" + this.recordOfRoadtestSubmitInfo.driverProcessAssignedId.toString() + "/reject";
    let postData = JSON.parse(JSON.stringify(this.recordOfRoadtestSubmitInfo));
    postData.rejectionReasonId = +rejectInfo.rejectionReasonIdString;
    postData.otherRejectionReason = rejectInfo.otherRejectionReason;

    let postput = this.onPremService.post(endpoint, JSON.stringify(postData));

    this.loadingSpinnerService.show();
    postput.subscribe({
      next: (data) => {
        this.alertService.showSuccessAlert('Road Test Upload Rejected', 'end', 'top', 5000);
        this.loadingSpinnerService.hide();
        this.returnToListing();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error.message);
        this.loadingSpinnerService.hide();
      }
    })
  }

  cancel() {
    this.returnToListing();
  }

  returnToListing() {
    if (this.returnUrl.length > 0) {
      this.router.navigate([this.returnUrl]);
      return;
    }

    //fallback to the default location
    this.router.navigate(['/dq/filemanagement']);
  }

  ngOnDestroy() {
    if (this.routeSub)
      this.routeSub.unsubscribe();
  }

}

export interface DriverLicenseRejectReason {
  id: number;
  secondaryId: number;
  description: string;
  secondaryCode: string;
}

export class DriverLicenseSubmitInfo {
  driverProcessAssignedId: number;
  driverId: number;
  isCommercialDriversLicense: boolean;
  rejectionReasonId: number;
  otherRejectionReason: string;

  constructor(info: DQFileManagementBaseInfo) {
    this.driverProcessAssignedId = info.driverProcessAssignedId;
    this.driverId = info.driverId;
    this.rejectionReasonId = 1;
  }
}
