import { Component, OnInit, OnDestroy, Inject, ViewChildren, QueryList, AfterViewInit, Pipe } from '@angular/core';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { CompanySelectionService } from '../../components/company-selector/company-selection-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { Subscription, Observable, forkJoin, combineLatest, of, zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { UserRightsInfo, DriverNotes, DriverDocuments, DriverFollowUpDate, DriverTraining, DriverHPEAssignment, CompanySelectionObject } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DcDriverProfileService } from './dc-driver-profile-service.component';
import { DriverHistoryProfileService } from '../../safety/dhp-landing-page/dhp-landing-page-service.component';
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { DataGridModalService } from '../../components/data-grid-filter-modal/data-grid-filter-modal.service'
import { UserRightsService } from '../../components/user-rights-service/user-rights-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { DataGrid } from '../../components/data-grid-menu-component/data-grid-menu.component';
import { DcDriver, DcLookupValue, DcLiveRouteTraining, DcLiveRouteTrainingType } from '../dc-classes-and-interfaces/dc-classes-and-interfaces.component';
import { MatTabGroup } from '@angular/material/tabs';

/**
 * this is the client-side controller for the dashboard custom report admin template.
 */
@Component({
  selector: 'dc-driver-profile',
  templateUrl: './dc-driver-profile.component.html',
  styleUrls: ['./dc-driver-profile.component.css']
})

export class DcDriverProfileComponent implements OnInit, OnDestroy {
  // private variables that are only shared with subscribers that import the type
  driverProfile: DcDriver;
  extendedInfo: Array<any>;
  _userRights: Array<UserRightsInfo> = null;
  obsSubscription: Subscription;
  routeSub: Subscription;
  refreshAllInfoSub: Subscription;
  refreshBaseInfoSub: Subscription;
  refreshNotesInfoSub: Subscription;
  refreshDocumentsInfoSub: Subscription;
  refreshCompletedTrainingInfoSub: Subscription;
  refreshIncompleteTrainingInfoSub: Subscription;
  refreshLeaveOfAbsenceInfoSub: Subscription;
  clientSelectedArray: Array<string>;
  clientIdSelectedArray: Array<number>;
  //companyIdSelectedArray: Array<number>;
  //companyNameSelectedArray: Array<string>;
  lineOfBusinessId: number;
  baseUrl: string;
  driverId: string;
  leaveOfAbsenceDataGridId: number;
  canViewDLDOB: boolean = false;
  companyName: string;
  companyId: number;
  companyFullHierarchy: string;
  arrCompany: Array<CompanySelectionObject>;
  arrLiveRouteTrainingType: Array<DcLiveRouteTrainingType> = [];

  // for building Action Items and Driver Status actions
  actionMenuObjectArray: Array<DcLookupValue> = [];

  @ViewChildren('dhpTabs') dhpTabsetList: QueryList<MatTabGroup>;
  dhpTabset: MatTabGroup;
  tabs: Array<any> = [];
  openToTab: string;

  constructor(private router: Router,
    private onPremService: OnPremDcService,
    private dcProfileService: DcDriverProfileService,
    private driverHistoryProfileService: DriverHistoryProfileService,
    private dataGridModalService: DataGridModalService,
    private clientSelectionService: ClientSelectionService,
    //private companySelectionService: CompanySelectionService,
    private userRightsService: UserRightsService,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private loadingSpinnerService: LoadingSpinnerService,
    private lineOfBusinessService: LineOfBusinessService,
    private route: ActivatedRoute,
    private errorService: ErrorModalService,
    private fileDownloadService: FileDownloadService) {
    this.baseUrl = baseUrl;
  }

  // angular on intialization event
  ngOnInit() {

    // set line of business to dc if not already initialized to Safety
    this.lineOfBusinessId = 7;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 7) {
      this.lineOfBusinessService.setLineOfBusiness(7);
    }

    // get the selected client(s)
    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusinessId);

    // get the selected company
    //this.companyIdSelectedArray = this.companySelectionService.getSavedCompanyIds(7);
    //this.companyNameSelectedArray = this.companySelectionService.getSavedCompanyNames(7);

    // subscribes to user id changes and queryParams
    let obsComb = combineLatest(this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams }));

    this.routeSub = obsComb.subscribe(allParams => {
      this.driverId = allParams.params['id'];
      this.openToTab = allParams.qparams['openToTab'];

      // gets the driver's profile data 
      if (this.driverId && (this.driverId.length > 0)) {
        this.getAllDriverProfileData(this.driverId);
      }
    });

    // subscribe to alerts from modals that ALL data needs to be refreshed
    this.refreshAllInfoSub = this.dcProfileService.dcRefreshDriverProfileAllData$.subscribe({
      next: (data) => {
        // dcRefreshDriverProfileData will be true if refresh needed
        let id = data as number;
        if (id == +this.driverId) {
          // update all profile data
          this.getAllDriverProfileData(this.driverId);
          // set action flag back to false
          this.dcProfileService.setRefreshDriverProfileAllData(null);
        }

      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    }
    );

    // subscribe to alerts from modals that BASE data needs to be refreshed
    this.refreshBaseInfoSub = this.dcProfileService.dcRefreshDriverProfileBaseData$.subscribe({
      next: (data) => {
        // dcRefreshDriverProfileData will be true if refresh needed
        let id = data as number;
        if (id == +this.driverId) {
          let arrObs: Array<Observable<Object>> = [
            this.dcProfileService.getDriverHistoryProfileBaseData(this.driverId),
            this.getActionItemMenuData(this.driverId)
          ];
          this.loadingSpinnerService.show();
          forkJoin(arrObs).subscribe({
            next: (driver) => {
              this.setDriverBaseInfo(driver[0] as DcDriver);
              this.actionMenuObjectArray = driver[1] as Array<DcLookupValue>;
              this.loadingSpinnerService.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.errorService.setErrorObject(err.error);
              this.loadingSpinnerService.hide();
            }
          });
          // set action flag back to false
          this.dcProfileService.setRefreshDriverProfileBaseData(null);
        }

      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

    // subscribe to safety observable for Notes and Files
    this.refreshNotesInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileNotesData$.subscribe({
      next: (data) => {
        // will be true if refresh needed
        let id = data as number;
        if (id == +this.driverId) {
          this.loadingSpinnerService.show();
          this.dcProfileService.getDriverHistoryProfileNotesData(this.driverId).subscribe({
            next: (profile) => {
              this.setDriverNotesInfo(profile, this.driverProfile);
              this.loadingSpinnerService.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.errorService.setErrorObject(err.error);
              this.loadingSpinnerService.hide();
            }
          });
          // set action flag back to false
          this.driverHistoryProfileService.setRefreshDriverProfileNotesData(null);
        }

      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

    // subscribe to safety observable for Notes and Files and Training
    this.refreshDocumentsInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileDocumentsData$.subscribe({
      next: (data) => {
        // will be true if refresh needed
        let id = data as number;
        if (id == +this.driverId) {
          this.loadingSpinnerService.show();
          this.dcProfileService.getDriverHistoryProfileDocumentsData(this.driverId).subscribe({
            next: (profile) => {
              this.setDriverDocumentsInfo(profile, this.driverProfile);
              this.loadingSpinnerService.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.errorService.setErrorObject(err.error);
              this.loadingSpinnerService.hide();
            }
          });
          // set action flag back to false
          this.driverHistoryProfileService.setRefreshDriverProfileDocumentsData(null);
        }

      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

    this.refreshCompletedTrainingInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileCompletedTrainingData$.subscribe({
      next: (data) => {
        // will be true if refresh needed
        let id = data as number;
        if (id == +this.driverId) {
          this.loadingSpinnerService.show();
          this.dcProfileService.getDriverHistoryProfileCompletedTrainingData(this.driverId).subscribe({
            next: (profile) => {
              this.setDriverCompletedTrainingInfo(profile, this.driverProfile);
              this.loadingSpinnerService.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.errorService.setErrorObject(err.error);
              this.loadingSpinnerService.hide();
            }
          });
          // set action flag back to false
          this.driverHistoryProfileService.setRefreshDriverProfileCompletedTrainingData(null);
        }

      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

    this.refreshIncompleteTrainingInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileIncompleteTrainingData$.subscribe({
      next: (data) => {
        // will be true if refresh needed
        let id = data as number;
        if (id == +this.driverId) {
          this.loadingSpinnerService.show();
          this.dcProfileService.getDriverHistoryProfileIncompleteTrainingData(this.driverId).subscribe({
            next: (training) => {
              this.setDriverIncompleteTrainingInfo(training, this.driverProfile);
              this.loadingSpinnerService.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.errorService.setErrorObject(err.error);
              this.loadingSpinnerService.hide();
            }
          });
          // set action flag back to false
          this.driverHistoryProfileService.setRefreshDriverProfileIncompleteTrainingData(null);
        }

      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

    // subscribe to safety observable for LOA
    this.refreshLeaveOfAbsenceInfoSub = this.dcProfileService.dcRefreshDriverProfileLeaveOfAbsenceData$.subscribe({
      next: (data) => {
        // if true, set resendEsigClicked to true to hide Resend Esig action,
        // and refresh Notes data
        let id = data as number;
        if (id == +this.driverId) {
          // refresh Base, Action, and Notes data
          let arrObs: Array<Observable<Object>> = [
            this.dcProfileService.getDriverHistoryProfileBaseData(this.driverId),
            this.getActionItemMenuData(this.driverId),
            this.dcProfileService.getDriverHistoryProfileNotesData(this.driverId)
          ];
          forkJoin(arrObs).subscribe({
            next: (dcDriver) => {
              this.setDriverBaseInfo(dcDriver[0] as DcDriver);
              this.actionMenuObjectArray = dcDriver[1] as Array<DcLookupValue>;
              this.setDriverNotesInfo(dcDriver[2] as Array<DriverNotes>, this.driverProfile);
              this.loadingSpinnerService.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.errorService.setErrorObject(err.error);
              this.loadingSpinnerService.hide();
            }
          });

          // set action flag back to false
          this.dcProfileService.setRefreshDriverProfileLeaveOfAbsenceData(null);
        }

      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  public ngAfterViewInit(): void {

    this.dhpTabsetList.changes.subscribe((comps: QueryList<MatTabGroup>) => {
      this.dhpTabset = comps.first;
      if (this.openToTab) {

        setTimeout(() => {
          this.selectTab(this.openToTab);
        }, 250);
      }
    });
  }

  private getAllDriverProfileData(driverId: string) {
    // executes all services to update all profile data
    // updated 2019-03 to load base data first then all other data in callback
    let arrObs: Array<Observable<Object>> = [
      this.getDriverBaseInfoAndUserRights(driverId),
      this.onPremService.get('companies'),
      this.getActionItemMenuData(driverId)
    ]

    this.loadingSpinnerService.show();
    this.tabs = [];
    this.obsSubscription = forkJoin(arrObs).subscribe({
      next: (data) => {
        // driver Base Info
        let driverBaseInfo = data[0][0] as DcDriver;
        this._userRights = data[0][1] as Array<UserRightsInfo>;
        this.arrCompany = data[1] as Array<CompanySelectionObject>;
        this.actionMenuObjectArray = data[2] as Array<DcLookupValue>;

        if (this.dcProfileService.inActiveDriverRedirect(driverBaseInfo, driverBaseInfo.clientCode)) {
          return;
        }

        // get company name from guid
        let companyObj = this.arrCompany.find(c => c.groupGuid == driverBaseInfo.groupGuid);
        this.companyFullHierarchy = companyObj.fullHierarchy;
        this.companyId = companyObj.id;
        this.companyName = companyObj.description;

        this.setDriverBaseInfo(driverBaseInfo);
        this.setCollectionLoadedFlags(this.driverProfile, false);
        //this.getActionMenuObjectArray(actionItemInfo);
        this.getTabs();
        this.loadingSpinnerService.hide();
        //this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileExtendedData(driverBaseInfo.clientCode), this.driverProfile, this.setDriverExtendedInfo);
        this.loadData(this.dcProfileService.getDriverHistoryProfileNotesData(driverId), this.driverProfile, this.setDriverNotesInfo);
        this.loadData(this.dcProfileService.getDriverHistoryProfileDocumentsData(driverId), this.driverProfile, this.setDriverDocumentsInfo);
        //this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileFollowUpDateData(driverId), this.driverProfile, this.setDriverFollowUpDate);
        this.loadData(this.dcProfileService.getDriverHistoryProfileCompletedTrainingData(driverId), this.driverProfile, this.setDriverCompletedTrainingInfo);
        this.loadData(this.dcProfileService.getDriverHistoryProfileIncompleteTrainingData(driverId), this.driverProfile, this.setDriverIncompleteTrainingInfo);
        this.loadData(this.dcProfileService.getDriverLiveRouteTraining(driverId), this.driverProfile, this.setDriverLiveRouteTrainingInfo);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

  }

  private getDriverBaseInfoAndUserRights(driverId: string) {
    // gets base driver info first, then user rights
    // based on the client for the driver
    return this.getDriverProfileBaseInfo(driverId).pipe(
      switchMap(res => {
        // get user rights after driver base data, then
        // combine into on observable

        return zip(
          of(res),
          res && res.clientCode ? this.userRightsService.getUserRights(this.lineOfBusinessId, [res.clientCode]) : of([])
        );
      }

      )
    )
    /*
    return this.getDriverProfileBaseInfo(driverId)
      .switchMap(res => {
        // get user rights after driver base data, then
        // combine into on observable

        return zip(
         of(res),
          res && res.clientCode ? this.userRightsService.getUserRights(this.lineOfBusinessId, [res.clientCode]) : of([])
        );
      })
      */

  }

  private loadData(obs: Observable<Object>, driverProfile: DcDriver, callback: (data: any, driverProfile: DcDriver) => void) {
    obs.subscribe({
      next: (data) => {
        if (callback) {
          callback(data, driverProfile);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private setCollectionLoadedFlags(driverProfile: DcDriver, loadedFlag: boolean) {
    // sets all the collection ..Loaded properties in driverProfile to loadedFlag
    driverProfile.driverDocumentsLoaded = loadedFlag;
    driverProfile.driverNotesLoaded = loadedFlag;
  }

  private getDriverProfileBaseInfo(driverId: string) {
    return this.dcProfileService.getDriverHistoryProfileBaseData(driverId);
  }

  private getActionItemMenuData(driverId: string) {
    return this.dcProfileService.getDriverHistoryProfileActionMenuItems(driverId);
  }

  actionMenuItemClick(itemId: number) {
    console.log("actionMenuClick", itemId)
    switch (itemId) {
      case 2:
        this.openEmployeeDriverForm(null);
        break;
      case 3:
        this.notifyModal('Reset Password', 'dcResetPassword', '180px;', 'sm', { driverId: this.driverId });
        break;
      case 6:
        this.openEmployeeDriverForm('Training');
        break;
      case 10:
        this.exportPdf();
        break;
      case 12:
        // schedule LOA
        this.notifyModal('Leave of Absence', 'dcLeaveOfAbsence', '420px;', 'md', { driverId: this.driverId });
        break;
      case 16:
        // cancel LOA
        this.notifyModal('Cancel Leave of Absence', 'dcCancelLeaveOfAbsence', '200px;', 'md', { driverId: this.driverId })
        break;
      case 13:
        this.notifyModal('Terminate Driver', 'dcTerminateDriver', '180px;', 'sm', { driverId: this.driverId })
        break;
      case 29:
        // Change Product Assignment
        //this.notifyModal('Unenroll In MVR Monitoring', 'mvrMonitoringUnenroll', '200px;', 'md', { driverId: this.driverId });
        break;
      case 30:
        //Mobile Time Clock Edit
        this.notifyModal('Edit Mobile Time Clock', 'dcTimeClockEdit', '420px;', 'lg', { driverId: this.driverId })
        break;
    }

  }


  private setDriverBaseInfo(data: DcDriver) {
    // copy all properties that are retrieved from other services
    let driverNotesInfo = this.driverProfile ? this.driverProfile.driverNotes : null;
    let driverDocumentsInfo = this.driverProfile ? this.driverProfile.driverDocuments : null;

    // set base data
    this.driverProfile = data as DcDriver;

    // calculated properties:
    if (this.driverProfile.driverLicense) {
      this.driverProfile.driverLicenseNumber = this.driverProfile.driverLicense.driverLicenseNumber || "";
      this.driverProfile.driverLicenseState = this.driverProfile.driverLicense.stateProvince || "";
    } else {
      this.driverProfile.driverLicenseNumber = "";
      this.driverProfile.driverLicenseState = "";
    }

    // add back copied properties
    this.setDriverNotesInfo(driverNotesInfo, this.driverProfile);
    this.setDriverDocumentsInfo(driverDocumentsInfo, this.driverProfile);

  }

  private setDriverNotesInfo = (function (data: Array<DriverNotes>, driverProfile: DcDriver) {
    driverProfile.driverNotes = data;
    driverProfile.driverNotesLoaded = true;
  }).bind(this);

  private setDriverDocumentsInfo = (function (data: Array<DriverDocuments>, driverProfile: DcDriver) {
    driverProfile.driverDocuments = data;
    driverProfile.driverDocumentsLoaded = true;
  }).bind(this);

  private setDriverLiveRouteTrainingInfo = (function (data: Array<DcLiveRouteTrainingType>, driverProfile: DcDriver) {
    driverProfile.liveRouteTraining = data;
    driverProfile.liveRouteTrainingLoaded = true;
  }).bind(this);

  /*
  private setDriverFollowUpDate = (function (data: DriverFollowUpDate, driverProfile: DcDriver) {
    driverProfile.followUpDate = data?data.followUpDate:null;
  }).bind(this); */

  private setDriverCompletedTrainingInfo = (function (data: Array<DriverTraining>, driverProfile: DcDriver) {
    driverProfile.completedTraining = data || [];
    driverProfile.completedTrainingLoaded = true;
  }).bind(this);

  private setDriverIncompleteTrainingInfo = (function (data: Array<DriverTraining>, driverProfile: DcDriver) {
    driverProfile.incompleteTraining = data || [];
    // add icon to tab heading if incomplete training
    let trainingTab = this.tabs.find(t => t.name == 'Training');
    if (trainingTab) {
      if (driverProfile.incompleteTraining.length > 0) {
        trainingTab.icon = ' <i class="fa fa-exclamation-triangle"></i>'
      } else {
        trainingTab.icon = '';
      }

    }

    driverProfile.incompleteTrainingLoaded = true;
  }).bind(this);
  /*
  private setDriverHpeAssignments = (function (data: Array<DriverHPEAssignment>, driverProfile: DriverProfile) {
    driverProfile.hpeAssignments = data;
    driverProfile.hpeAssignmentsLoaded = true;
    this.getHPECoreCompetencies(this.driverProfile.hpeAssignments, driverProfile);
  }).bind(this);

  private getHPECoreCompetencies(data: Array<DriverHPEAssignment>, driverProfile: DriverProfile) {
    //this.driverProfile.hpeAssignments = data || [];
    if (data && (data.length > 0)) {
      this.dcProfileService.getDriverHistoryProfileHPECoreCompetenciesData(driverProfile.driverId.toString(), data[0].assignmentId.toString()).subscribe(data => {
        driverProfile.hpeCoreCompetencies = data;
        driverProfile.hpeCoreCompetenciesLoaded = true;
      });

      this.dcProfileService.getDriverHistoryProfileHPESubLessonsData(driverProfile.driverId.toString(), data[0].assignmentId.toString()).subscribe(data => {
        driverProfile.hpeSubLessons = data;
        driverProfile.hpeSubLessonsLoaded = true;
      });

    }
  }

*/
  private getTabs() {
    let arrTab = [
      { name: 'Driver Info', icon: '', show: true, display: true },
      { name: 'Assigned Products', icon: '', show: true, display: true },
      { name: 'Training', icon: '', show: true, display: true },
      { name: 'Notes and Docs', icon: '', show: true, display: true }
    ];
    this.tabs = arrTab;
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.obsSubscription.unsubscribe();
    this.refreshAllInfoSub.unsubscribe();
    this.refreshBaseInfoSub.unsubscribe();
    this.refreshNotesInfoSub.unsubscribe();
    this.refreshDocumentsInfoSub.unsubscribe();
    this.refreshCompletedTrainingInfoSub.unsubscribe();
    this.refreshIncompleteTrainingInfoSub.unsubscribe();
    this.refreshLeaveOfAbsenceInfoSub.unsubscribe();
  }

  // notifies the DHP modal of changes
  notifyModal(title: string, message: string, modalHeight: string, modalWidth: string, modalInput?: any) {
    this.dcProfileService.notifyModal(title, message, modalHeight, modalWidth, modalInput);
  }

  // opens the employee driver form
  openEmployeeDriverForm(openToSection: string) {

    let activeTabIndex: number = 0;

    // find and catalog the last accessed tab
    this.dhpTabset._tabs.forEach(a => {
      if (a.isActive) {
        this.dcProfileService.updateLastDCTab(activeTabIndex);
        let queryParams: any = { returnToProfileId: this.driverProfile.driverId };
        if (openToSection) {
          queryParams.openToSection = openToSection;
        }

        this.router.navigate(['dc/driveredit/' + this.driverProfile.driverId], { queryParams: queryParams });
      }
      activeTabIndex = activeTabIndex + 1;
    });
  }

  // determines if access to the component is allowed
  isAccessAllowed(userRightsId: number) {
    let retVal: boolean = false;

    if (this._userRights) {
      let thisRight = this._userRights.filter(r => r.userRightId == userRightsId);
      if ((thisRight.length > 0) && (thisRight[0].permission == 'allow')) {
        retVal = true;
      }
    }

    return retVal;
  }


  private selectTab(tabName: string) {
    //sets active tanb and opens to that tab
    let index = this.tabs.findIndex(t => t.name == tabName);
    if (index >= 0) {
      this.dhpTabset._tabs[index].active = true;
    }
  }

  private hideTab(tabName: string) {
    //sets the display attribute in tab to false,
    // this will result in a customClass being added to hide the tab
    this.tabs.filter(t => t.name == tabName).forEach(t => { t.display = false });
  }

  private setTabHeading(tabName: string, txt: string) {
    this.tabs.filter(t => t.name == tabName).forEach(t => { t.icon = txt });
  }

  exportPdf() {
    this.fileDownloadService.saveFile(this.baseUrl + 'api/Safety/GetDriverPdf?DriverId=' + this.driverId + '&DCVersion=true');
  }

  onPrint() {
    this.fileDownloadService.openTab(this.baseUrl + "api/Safety/PrintDriverProfile?DriverId=" + this.driverId);
  }
}
