<div class="row" >
  <div class="col-sm-12">
    <div class="row" style="padding-bottom:10px">
      <div class="col-md-8">
        <h3>Date Range For All Charts</h3>
      </div>
      <div class="col-md-4">
        <span style="float:right">
          <button type="button" (click)="saveDates()" class="btn btn-default">
            Set Global Date Range
          </button>
          <button type="button" (click)="closeDateSelectPanel()" class="btn btn-default">
            Exit
          </button>
        </span>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-sm-4">

        <div class="form-check">
          <input type="radio" class="form-check-input" id="manualEnter" name="groupManual"
            (change)="disableDates(false)" [ngModel]="manualRadioChecked" [value]="true">
          <label class="form-check-label" for="manualEnter">Use Manually Entered Dates</label>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row mt-2">
        <div class="col-sm-4">
          <mat-form-field appearance="fill">
            <mat-label class="lbl-start-date">From Date</mat-label>
            <input class="input-date" matInput disabled [matDatepicker]="startDatePicker"
              [(ngModel)]="dateRange.startDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker [disabled]="manuallyEnteredDisabled">
              <mat-datepicker-actions>
                <button mat-button (click)="startDatePicker.select(actualDate); startDatePicker.close()">
                  <mat-icon>history</mat-icon>
                  Today
                </button>
                <button mat-button (click)="startDatePicker.select(null); startDatePicker.close()">
                  <mat-icon>highlight_off</mat-icon>
                  Clear
                </button>
              </mat-datepicker-actions>
            </mat-datepicker>
          </mat-form-field>

        </div>
        <div class="col-sm-4">
          <mat-form-field appearance="fill">
            <mat-label class="lbl-start-date">Through Date</mat-label>
            <input class="input-date" matInput disabled [matDatepicker]="endDatePicker" [(ngModel)]="dateRange.endDate">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker [disabled]="manuallyEnteredDisabled">
              <mat-datepicker-actions>
                <button mat-button (click)="endDatePicker.select(actualDate); endDatePicker.close()">
                  <mat-icon>history</mat-icon>
                  Today
                </button>
                <button mat-button (click)="endDatePicker.select(null); endDatePicker.close()">
                  <mat-icon>highlight_off</mat-icon>
                  Clear
                </button>
              </mat-datepicker-actions>
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-sm-4">

        <div class="form-check">
          <input type="radio" class="form-check-input" id="chooseRolling" name="groupRolling"
            (change)="disableDates(true)" [ngModel]="rollingRadioChecked" [value]="true">
          <label class="form-check-label" for="chooseRolling">Use Dynamic Dates</label>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-2">
        <div class="col-sm">
          <mat-form-field appearance="fill" class="select-container">
            <mat-label>Select Time Period</mat-label>
            <mat-select [(value)]="selectedRollingOption" [disabled]="useRollingDatesDisabled">
              <mat-option *ngFor="let option of rollingOptions" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm"> </div>
        <div class="col-sm md-form"> </div>
      </div>
    </div>
  </div>
</div>