<div class="card card-body mt-3">
    <div class="row">
        <div class="col-md-6">
            <h3>Add/Edit a Visibility User</h3>
        </div>
    </div> 
    <div class="row">   
        <div class="col-md-6 md-form"> 
            <mat-form-field class="example-full-width" appearance="fill">
                <input 
                    type="text" 
                    placeholder="Email Address" 
                    matInput 
                    [matAutocomplete]="auto"
                    [ngModel]="searchText | async"
                    (ngModelChange)="getData($event)"
                />
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let user of results | async" [value]="user.userEmail">
                        {{ user.userEmail | lowercase }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="col-sm-3">  
            <button class="btn btn-primary btn-sm">Go</button>
        </div> 
    </div> 
</div>         


