import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DriverRiskLevelsService } from '../services/driver-risk-levels.service';
import { DriverRiskLevel } from './driver-risk-level';
import { minimumCheckboxesSelected } from '../../../components/classes-and-interfaces/classes-and-interfaces.component';

@Component({
  selector: 'app-driver-risk',
  templateUrl: './driver-risk.component.html',
  styleUrls: ['./driver-risk.component.css']
})
export class DriverRiskComponent implements OnInit, OnDestroy {
  @Input() parentForm: UntypedFormGroup;
  @Input() selectorPrefix: string;

  private _riskLevelsSubscription: Subscription;
  public riskLevels = new Array<DriverRiskLevel>();
  public riskLevelsForm = new UntypedFormGroup({});
  constructor(private driverRiskLevelsService: DriverRiskLevelsService) { }

  ngOnInit(): void {
    this.driverRiskLevelsService.riskLevels$.subscribe((data) => this.receiveRiskLevels(data));
  }

  private receiveRiskLevels(data: Array<DriverRiskLevel>) {
    this.riskLevels = data;

    this.riskLevels.forEach(level => {
      this.riskLevelsForm.addControl(level.description, new UntypedFormControl(true));
    });

    this.riskLevelsForm.setValidators([minimumCheckboxesSelected(1)]);
    this.parentForm.addControl('riskLevels', this.riskLevelsForm);
  }

  ngOnDestroy(): void {
      this?._riskLevelsSubscription?.unsubscribe();
  }

}
