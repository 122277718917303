<div class="row">
    <div class="col-md-12">
      <div class="card card-body mt-3">
        <h4 class="card-title text-muted">License Information</h4>
        <div *ngIf="licenseInformationTableConfig">
          <app-data-table
            [columns]="licenseInformationTableConfig.fields"
            [data]="licenseInformationTableConfig.data"
            [expandable]="licenseInformationTableConfig?.expandable"
            [expand]="licenseInformationTableConfig?.expand"
            [noRecordsLabel]="licenseInformationTableConfig?.noRecordsLabel"
          ></app-data-table>
        </div>
      </div>
    </div>
  </div>
