import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';

@Injectable()
export class CustomReportsMenuService {
  baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  getByLineOfBusiness(LineOfBusinessId: number) {
    let params = new HttpParams();
    params = params.append('LineOfBusinessId', LineOfBusinessId.toString());

    return this.http.get(this.baseUrl + 'api/Datagrid/GetByLineOfBusiness', {
      params: params
    });
  }
}
