import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AuthService } from '../components/auth/auth.service';
import { SafetyDQFilesComponent } from './safety-dq-files/safety-dq-files.component';
import { SafetyDriverInfoComponent } from './safety-driver-info/safety-driver-info.component';
import { SafetyDriverRankingComponent } from './safety-driver-ranking/safety-driver-ranking.component';
import { SafetyDriverRiskComponent } from './safety-driver-risk-chart/safety-driver-risk.component';
import { SafetyEmployeeDriverFormComponent } from './safety-employee-driver-form/safety-employee-driver-form.component';
import { SafetyPrimaryDriverLinkComponent } from './safety-primary-driver-link/safety-primary-driver-link.component';
import { SafetyFilterComponent } from './safety-filter/safety-filter.component';
import { SafetyMVRComponent } from './safety-mvr/safety-mvr.component';
import { SafetyMvrReleaseRequirementsComponent } from './safety-mvr-release-req/safety-mvr-release-req.component';
import { SafetyMvrStateCodesComponent } from './safety-mvr-state-codes/safety-mvr-state-codes.component';
import { SafetyNewEmployeeDriverSelectorComponent } from './safety-new-employee-driver-selector/safety-new-employee-driver-selector.component';
import { SafetyNewEmployeeDriverSelectorFolderComponent } from './safety-new-employee-driver-selector-folder/safety-new-employee-driver-selector-folder.component';
import { SafetyNotesDocsComponent } from './safety-notes-docs/safety-notes-docs.component';
import { SafetyDriverPendingTasksComponent } from './safety-driver-pending-tasks/safety-driver-pending-tasks.component';
import { SafetyRiskProfileComponent } from './safety-risk-profile/safety-risk-profile.component';
import { SafetyTelematicsComponent } from './safety-telematics/safety-telematics.component';
import { SafetyTrainingComponent } from './safety-training/safety-training.component';
import { SafetyNonEmployeesComponent } from './safety-non-employees/safety-non-employees.component';
import { SafetyNonEmployeeInfoComponent } from './safety-non-employee-info/safety-non-employee-info.component';
import { SafetyAdditionalDriversFullComponent } from './safety-additional-drivers-full/safety-additional-drivers-full.component';
import { SafetyAdditionalDriversNotFullComponent } from './safety-additional-drivers-not-full/safety-additional-drivers-not-full.component';
import { SafetyMVRService } from './safety-mvr/safety-mvr-service.component';
import { SafetyDQFilesService } from './safety-dq-files/safety-dq-files-service.component';
import { SafetyDriverInfoService } from './safety-driver-info/safety-driver-info-service.component';
import { SafetyNotesDocsService } from './safety-notes-docs/safety-notes-docs-service.component';
import { SafetyRiskProfileService } from './safety-risk-profile/safety-risk-profile-service.component';
import { SafetyTelematicsService } from './safety-telematics/safety-telematics-service.component';
import { SafetyTrainingService } from './safety-training/safety-training-service.component';
import { SafetyModalWindowService } from './safety-modal-window/safety-modal-window-service.component';
import { SafetyDriverRankingService } from './safety-driver-ranking/safety-driver-ranking-service.component';
import { SafetyDriverRiskService } from './safety-driver-risk-chart/safety-driver-risk-service.component';
import { AuthGuard } from '../components/auth/auth.guard';
import { DriverProfileComponent } from './dhp-landing-page/dhp-landing-page.component';
import { DHPEditEmpoloyeeDriverComponent } from './dhp-edit-employee-driver/dhp-edit-employee-driver.component';
import { DHPEmployeeMVRReportsComponent } from './dhp-employee-mvr-reports/dhp-employee-mvr-reports.component';
import { DHPMVRReportComponent } from './dhp-mvr-report/dhp-mvr-report.component';
import { DHPUploadDocumentsComponent } from './dhp-upload-documents/dhp-upload-documents.component';
import { MvrExportComponent } from './mvr-export/mvr-export.component';
import { MvrRetrieveComponent, PdfType } from './mvr-retrieve/mvr-retrieve.component';
import { CovExportComponent } from './cov-export/cov-export.component';
import { ExportDriverSelectComponent } from './export-driver-select/export-driver-select.component';
import { TrainingSummaryExportComponent } from './training-summary-export/training-summary-export.component';
import { CorporateStructureComponent } from './corporate-structure/corporate-structure.component';
import { DQDriversLicenseComponent } from './dq-drivers-license/dq-drivers-license.component';
import { DQMedicalCardComponent } from './dq-medical-card/dq-medical-card.component';
import { DQDrivingHistoryComponent } from './dq-driving-history/dq-driving-history.component';
import { DQApplicationReviewComponent } from './dq-application-review/dq-application-review.component';
import { DQFilePanelComponent } from './dq-file-panel/dq-file-panel.component';
import { DQApproveModalComponent } from './dq-approve-modal/dq-approve-modal.component';
import { DQRejectModalComponent } from './dq-reject-modal/dq-reject-modal.component';
import { DQGridComponent } from './dq-grid/dq-grid.component';
import { COIValidationGridComponent } from './coi-validation-grid/coi-validation-grid.component';
import { COIValidationReviewComponent } from './coi-validation-review/coi-validation-review.component';
import { HondaOptOutGridComponent } from './honda-opt-out-grid/honda-opt-out-grid.component';
import { HondaOptOutInfoModalComponent } from './honda-opt-out-info-modal/honda-opt-out-info-modal.component';
import { ReactivateDriverInfoModalComponent } from './reactivate-driver-info-modal/reactivate-driver-info-modal.component';
import { ReactivateDriverGridComponent } from './reactivate-driver-grid/reactivate-driver-grid.component';
import { CorporateStructureAddGroupModalService } from './corporate-structure-add-group-modal/corporate-structure-add-group-modal.service';
import { DHPAssignPointsService } from './dhp-assign-points/dhp-assign-points-service.component';
import { DHPAddNotesService } from './dhp-add-notes/dhp-add-notes-service.component';
import { DHPEditEmployeeDriverService } from './dhp-edit-employee-driver/dhp-edit-employee-driver-service.component';
import { DHPEmployeeMVRReportsService } from './dhp-employee-mvr-reports/dhp-employee-mvr-reports-service.component';
import { DHPLeaveOfAbsenceService } from './dhp-leave-of-absence/dhp-leave-of-absence-service.component';
import { DHPMVRReportService } from './dhp-mvr-report/dhp-mvr-report-service.component';
import { DHPMyFollowupService } from './dhp-my-followup/dhp-my-followup-service.component';
import { DHPOrderMVRService } from './dhp-order-mvr/dhp-order-mvr-service.component';
import { DHPUploadDocumentsService } from './dhp-upload-documents/dhp-upload-documents-service.component';
import { DHPConfirmActionService } from './dhp-confirm-action/dhp-confirm-action-service.component';
import { DriverHistoryProfileService } from './dhp-landing-page/dhp-landing-page-service.component';
import { HondaOptOutInfoModalService } from './honda-opt-out-info-modal/honda-opt-out-info-modal-service.component';
import { ReactivateDriverInfoModalService } from './reactivate-driver-info-modal/reactivate-driver-info-modal-service.component';
import { OrdersAndAssignmentsReportComponent } from './orders-and-assignments-report/orders-and-assignments-report.component';
import { DriverMaintenanceReportComponent, DriverMaintenanceReportKeepSwitchComponent, DriverMaintenanceReportRemoveSwitchComponent } from './driver-maintenance-report/driver-maintenance-report.component';
import { DriverMaintenanceReportService } from './driver-maintenance-report/driver-maintenance-report.service';
import { RemovedDriversReportComponent, RemovedDriversReportActiveSwitchComponent } from './removed-drivers-report/removed-drivers-report.component';
import { RemovedDriversReportService } from './removed-drivers-report/removed-drivers-report.service';
import { DriverMaintenanceActionComponent } from './driver-maintenance-action/driver-maintenance-action.component';
import { DQRoadTestComponent } from './dq-road-test/dq-road-test.component';
import { SafetyDocumentLibraryComponent } from './safety-document-library/safety-document-library.component';
import { DhpMvrReportMedicalCertComponent } from './dhp-mvr-report/dhp-mvr-report-medical-cert/dhp-mvr-report-medical-cert.component';
import { SafetyMoveDriverComponent } from './safety-move-driver/safety-move-driver.component';
import { SafetyMoveDriverLocateComponent } from './safety-move-driver-locate/safety-move-driver-locate.component';
import { SafetyMoveDriverService } from './safety-move-driver/safety-move-driver.service';
import { SafetyMoveDriveGroupComponent } from './safety-move-drive-group/safety-move-drive-group.component';
import { SafetyMoveDriverConfirmComponent } from './safety-move-driver-confirm/safety-move-driver-confirm.component';
import { DqRecordOfRoadTestComponent } from './dq-record-of-road-test/dq-record-of-road-test.component';
import { SafetyAutoCoverageComponent } from './safety-auto-coverage/safety-auto-coverage.component';
import { SafetyAdvancedSearchComponent } from './safety-advanced-search/safety-advanced-search.component';
import { AdvancedSearchRequest } from './safety-advanced-search-results/search-advanced-search-service.service';
import { SafetyAdvancedSearchResultsComponent } from './safety-advanced-search-results/safety-advanced-search-results.component';
import { SafetyAdvancedSearchResolveService } from './safety-advanced-search-results/safety-advanced-search-resolve.service';
import { OrdersAndAssignmentsComponent } from './orders-and-assignments/orders-and-assignments.component';
import { DriverRiskComponent } from './orders-and-assignments/driver-risk/driver-risk.component';
import { TrainingLessonListComponent } from './orders-and-assignments/training-lesson-list/training-lesson-list.component';
import { MultipleServicesTabComponent } from './orders-and-assignments/multiple-services-tab/multiple-services-tab.component';
import { AssignTrainingTabComponent } from './orders-and-assignments/assign-training-tab/assign-training-tab.component';
import { OrderAssignChoicesComponent } from './orders-and-assignments/order-assign-choices/order-assign-choices.component';
import { DriverTypeComponent } from './orders-and-assignments/driver-type/driver-type.component';
import { SelectDriversTabComponent } from './orders-and-assignments/select-drivers-tab/select-drivers-tab.component';
import { MonitoringStatusComponent } from './orders-and-assignments/monitoring-status/monitoring-status.component';
import { TrainingLessonSelectorComponent } from './training-lesson-selector/training-lesson-selector.component';
import { DhpMvrReportEndorsementsComponent } from './dhp-mvr-report/dhp-mvr-report-endorsements/dhp-mvr-report-endorsements.component';
import { DhpMvrReportLicenseInformationComponent } from './dhp-mvr-report/dhp-mvr-report-license-information/dhp-mvr-report-license-information.component';
import { DhpMvrReportOrdersComponent } from './dhp-mvr-report/dhp-mvr-report-orders/dhp-mvr-report-orders.component';
import { DhpMvrAddressInformationComponent } from './dhp-mvr-report/dhp-mvr-address-information/dhp-mvr-address-information.component';
import { DhpMvrReportReturnInfoComponent } from './dhp-mvr-report/dhp-mvr-report-return-info/dhp-mvr-report-return-info.component';
import { DhpMvrReportPreviousLicenseInfoComponent } from './dhp-mvr-report/dhp-mvr-report-previous-license-info/dhp-mvr-report-previous-license-info.component';
import { DhpMvrReportAdditionalMessagesComponent } from './dhp-mvr-report/dhp-mvr-report-additional-messages/dhp-mvr-report-additional-messages.component';

import { AbstractReviewGridComponent } from './abstract-review-grid/abstract-review-grid.component';
import { AbstractReviewComponent } from './abstract-review/abstract-review.component';
import { ReviewSubmitModalComponent } from './orders-and-assignments/review-submit-modal/review-submit-modal.component';

import { DhpDocumentValidationGridComponent } from './dhp-document-validation-grid/dhp-document-validation-grid.component';
import { DhpDocumentValidationReviewComponent } from './dhp-document-validation-review/dhp-document-validation-review.component';
import { DhpDocumentValidationApproveModalComponent } from './dhp-document-validation-approve-modal/dhp-document-validation-approve-modal.component';
import { DhpDocumentValidationEditModalComponent } from './dhp-document-validation-edit-modal/dhp-document-validation-edit-modal.component';
import { DhpDocumentValidationRejectModalComponent } from './dhp-document-validation-reject-modal/dhp-document-validation-reject-modal.component';
import { DhpDocumentValidationRemoveModalComponent } from './dhp-document-validation-remove-modal/dhp-document-validation-remove-modal.component';



@NgModule({
  declarations: [
    CovExportComponent,
    DHPEditEmpoloyeeDriverComponent,
    DHPEmployeeMVRReportsComponent,
    DHPMVRReportComponent,
    DHPUploadDocumentsComponent,
    DQDriversLicenseComponent,
    DQMedicalCardComponent,
    DQDrivingHistoryComponent,
    DQApplicationReviewComponent,
    DQFilePanelComponent,
    DQApproveModalComponent,
    DQRejectModalComponent,
    DQGridComponent,
    COIValidationGridComponent,
    COIValidationReviewComponent,
    DriverMaintenanceActionComponent,
    DriverMaintenanceReportComponent,
    RemovedDriversReportComponent,
    RemovedDriversReportActiveSwitchComponent,
    DriverMaintenanceActionComponent,
    DriverMaintenanceReportKeepSwitchComponent,
    DriverMaintenanceReportRemoveSwitchComponent,

    DriverProfileComponent,
    HondaOptOutGridComponent,
    HondaOptOutInfoModalComponent,
    MvrExportComponent,
    MvrRetrieveComponent,
    OrdersAndAssignmentsReportComponent,
    ReactivateDriverInfoModalComponent,
    ReactivateDriverGridComponent,
    SafetyDQFilesComponent,
    SafetyDriverInfoComponent,
    SafetyDriverRankingComponent,
    SafetyDriverRiskComponent,
    SafetyEmployeeDriverFormComponent,
    SafetyPrimaryDriverLinkComponent,
    SafetyFilterComponent,
    SafetyMVRComponent,
    SafetyMvrReleaseRequirementsComponent,
    SafetyMvrStateCodesComponent,
    SafetyNewEmployeeDriverSelectorComponent,
    SafetyNewEmployeeDriverSelectorFolderComponent,
    SafetyNotesDocsComponent,
    SafetyDriverPendingTasksComponent,
    SafetyRiskProfileComponent,
    SafetyTelematicsComponent,
    SafetyTrainingComponent,
    SafetyNonEmployeesComponent,
    SafetyNonEmployeeInfoComponent,
    SafetyAdditionalDriversFullComponent,
    SafetyAdditionalDriversNotFullComponent,
    DQRoadTestComponent,
    SafetyDocumentLibraryComponent,
    DhpMvrReportMedicalCertComponent,
    SafetyMoveDriverComponent,
    SafetyMoveDriverLocateComponent,
    SafetyMoveDriveGroupComponent,
    SafetyMoveDriverConfirmComponent,
    DqRecordOfRoadTestComponent,
    SafetyAutoCoverageComponent,
    SafetyAdvancedSearchComponent,
    SafetyAdvancedSearchResultsComponent,
    OrdersAndAssignmentsComponent,
    DriverRiskComponent,
    TrainingLessonListComponent,
    MultipleServicesTabComponent,
    AssignTrainingTabComponent,
    OrderAssignChoicesComponent,
    DriverTypeComponent,
    SelectDriversTabComponent,
    MonitoringStatusComponent,
    TrainingLessonSelectorComponent,
    DhpMvrReportEndorsementsComponent,
    DhpMvrReportLicenseInformationComponent,
    DhpMvrReportOrdersComponent,
    DhpMvrAddressInformationComponent,
    DhpMvrReportReturnInfoComponent,
    DhpMvrReportPreviousLicenseInfoComponent,
    DhpMvrReportAdditionalMessagesComponent,
    AbstractReviewGridComponent,
    AbstractReviewComponent,
    ReviewSubmitModalComponent,
    DhpDocumentValidationGridComponent,
    DhpDocumentValidationApproveModalComponent,
    DhpDocumentValidationEditModalComponent,
    DhpDocumentValidationReviewComponent,
    DhpDocumentValidationRejectModalComponent,
    DhpDocumentValidationRemoveModalComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild([
      { path: 'dq/driverlicense/:driverId', component: DQDriversLicenseComponent, canActivate: [AuthGuard] }, // need to remove once DQ grid is working
      { path: 'dq/driverlicense/:driverId/:driverProcessAssignedId', component: DQDriversLicenseComponent, canActivate: [AuthGuard] },
      { path: 'dq/medicalcard/:driverId', component: DQMedicalCardComponent, canActivate: [AuthGuard] }, // need to remove once DQ grid is working
      { path: 'dq/medicalcard/:driverId/:driverProcessAssignedId', component: DQMedicalCardComponent, canActivate: [AuthGuard] },
      { path: 'dq/drivinghistory/:driverId', component: DQDrivingHistoryComponent, canActivate: [AuthGuard] }, // need to remove once DQ grid is working
      { path: 'dq/drivinghistory/:driverId/:driverProcessAssignedId', component: DQDrivingHistoryComponent, canActivate: [AuthGuard] },
      { path: 'dq/roadtest/:driverId/:driverProcessAssignedId', component: DQRoadTestComponent, canActivate: [AuthGuard] },
      { path: 'dq/recordofroadtest/:driverId/:driverProcessAssignedId', component: DqRecordOfRoadTestComponent, canActivate: [AuthGuard] },
      { path: 'dq/applicationreview/:driverId', component: DQApplicationReviewComponent, canActivate: [AuthGuard] }, // need to remove once DQ grid is working
      { path: 'dq/applicationreview/:driverId/:driverProcessAssignedId', component: DQApplicationReviewComponent, canActivate: [AuthGuard] },
      { path: 'dq/filemanagement', component: DQGridComponent, canActivate: [AuthGuard] },
      { path: 'driver/dqfiles/:id', component: SafetyDQFilesComponent, canActivate: [AuthGuard] },
      { path: 'driver/history-profile/:id', component: DriverProfileComponent, canActivate: [AuthGuard] },
      { path: 'driver/mvrreport/:id/:driverId', component: DHPMVRReportComponent, canActivate: [AuthGuard] },
      { path: 'optoutreport/:group/:driverId/:driverLastName', component: HondaOptOutGridComponent, canActivate: [AuthGuard] },
      { path: 'reactivatedrivergrid/:driverId/:driverLastName', component: ReactivateDriverGridComponent, canActivate: [AuthGuard] },
      { path: 'safety/corpstructure', component: CorporateStructureComponent, canActivate: [AuthGuard], data: { shouldReuse: true, Lob: 2 } },
      { path: 'safety/filter', component: SafetyFilterComponent, canActivate: [AuthGuard] },
      { path: 'safety/mvrreleaserequirements', component: SafetyMvrReleaseRequirementsComponent, canActivate: [AuthGuard] },
      { path: 'safety/staterequiredcodes', component: SafetyMvrStateCodesComponent, canActivate: [AuthGuard] },
      { path: 'safety/newemployeedriverselector', component: SafetyNewEmployeeDriverSelectorComponent, canActivate: [AuthGuard], data: { shouldReuse: true } },
      { path: 'safety/employeedriverform/:id', component: SafetyEmployeeDriverFormComponent, canActivate: [AuthGuard] },
      { path: 'safety/nonemployeedriverform/:id', component: SafetyEmployeeDriverFormComponent, canActivate: [AuthGuard] },
      { path: 'safety/newemployeedriverform/:groupguid', component: SafetyEmployeeDriverFormComponent, canActivate: [AuthGuard] },
      { path: 'safety/newnonemployeedriverform/:groupguid/:primarydriverid', component: SafetyEmployeeDriverFormComponent, canActivate: [AuthGuard] },
      { path: 'safety/mvrexport', component: MvrExportComponent, canActivate: [AuthGuard], data: { Lob: 2 } },
      { path: 'safety/ordersandassignments', component: OrdersAndAssignmentsComponent, canActivate: [AuthGuard] },
      { path: 'safety/mvrretrieve', component: MvrRetrieveComponent, canActivate: [AuthGuard], data: { pdfType: PdfType.MVR, Lob: 2 } },
      { path: 'safety/trainingsummaryexport', component: TrainingSummaryExportComponent, canActivate: [AuthGuard], data: { Lob: 2 }  },
      { path: 'safety/covexport', component: CovExportComponent, canActivate: [AuthGuard], data: { Lob: 2 } },
      { path: 'safety/covretrieve', component: MvrRetrieveComponent, canActivate: [AuthGuard], data: { pdfType: PdfType.COV, Lob: 2 } },
      { path: 'safety/trainingretrieve', component: MvrRetrieveComponent, canActivate: [AuthGuard], data: { pdfType: PdfType.Training, Lob: 2 } },
      { path: 'safety/ordersandassignmentsreport/:batchId', component: OrdersAndAssignmentsReportComponent, canActivate: [AuthGuard] },
      { path: 'safety/drivermaintenance/:id', component: DriverMaintenanceReportComponent, canActivate: [AuthGuard] },
      { path: 'safety/drivermaintenance-update/:id', component: DriverMaintenanceActionComponent, canActivate: [AuthGuard] },
      { path: 'safety/removeddrivers/:id', component: RemovedDriversReportComponent, canActivate: [AuthGuard] },
      { path: 'safety/coivalidationgrid', component: COIValidationGridComponent, canActivate: [AuthGuard] },
      { path: 'safety/coivalidationgrid/:id', component: COIValidationGridComponent, canActivate: [AuthGuard] },
      { path: 'safety/coivalidationreview/:driverId/:driverProcessAssignedId', component: COIValidationReviewComponent, canActivate: [AuthGuard] },
      { path: 'safety/coivalidationreview/:driverId/:driverProcessAssignedId/:gridId', component: COIValidationReviewComponent, canActivate: [AuthGuard] },
      { path: 'safety/documentlibrary', component: SafetyDocumentLibraryComponent, canActivate: [AuthGuard] },
      { path: 'safety/movedriver/list', component: SafetyMoveDriverLocateComponent, canActivate: [AuthGuard] },
      { path: 'safety/movedriver/edit/:id', component: SafetyMoveDriverComponent, canActivate: [AuthGuard] },
      { path: 'safety/advancedSearch', component: SafetyAdvancedSearchComponent, canActivate: [AuthGuard] },
      {
        path: 'safety/advancedSearch/:id', component: SafetyAdvancedSearchResultsComponent,
        runGuardsAndResolvers: 'always',
        resolve: { data: SafetyAdvancedSearchResolveService },
        canActivate: [AuthGuard]
      },
      { path: 'safety/abstractreviewgrid', component: AbstractReviewGridComponent, canActivate: [AuthGuard] },
      { path: 'safety/abstractreviewgrid/:id', component: AbstractReviewGridComponent, canActivate: [AuthGuard] },
      { path: 'safety/abstractreview/:driverId/:abstractReviewId', component: AbstractReviewComponent, canActivate: [AuthGuard] },
      { path: 'safety/abstractreview/:driverId/:abstractReviewId/:gridId', component: AbstractReviewComponent, canActivate: [AuthGuard] },
      { path: 'safety/dhpdocumentvalidationgrid', component: DhpDocumentValidationGridComponent, canActivate: [AuthGuard] },
      { path: 'safety/dhpdocumentvalidationreview/:documentId', component: DhpDocumentValidationReviewComponent, canActivate: [AuthGuard] }
    ])
  ],
  exports: [],
  providers: [
    CorporateStructureAddGroupModalService,
    DHPAssignPointsService,
    DHPAddNotesService,
    DHPEditEmployeeDriverService,
    DHPEmployeeMVRReportsService,
    DHPLeaveOfAbsenceService,
    DHPMVRReportService,
    DHPMyFollowupService,
    DHPOrderMVRService,
    DHPUploadDocumentsService,
    DHPConfirmActionService,
    DriverHistoryProfileService,
    DriverMaintenanceReportService,
    RemovedDriversReportService,
    HondaOptOutInfoModalService,
    ReactivateDriverInfoModalService,
    SafetyMVRService,
    SafetyDQFilesService,
    SafetyDriverInfoService,
    SafetyNotesDocsService,
    SafetyRiskProfileService,
    SafetyTelematicsService,
    SafetyTrainingService,
    SafetyModalWindowService,
    SafetyDriverRankingService,
    SafetyDriverRiskService,
    SafetyMoveDriverService,
    AdvancedSearchRequest,
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SafetyModule {
  constructor(private readonly authService: AuthService) {
    console.log('SAFETY MODULE STARTING');
  }
}
