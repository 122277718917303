import { Component, Inject, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { WidgetInfo, DashboardTile, DashboardTileCategory } from "../classes-and-interfaces/classes-and-interfaces.component";
import { DashboardService } from "../dashboard-service/dashboard-service.component";
import { Subscription } from 'rxjs';
import { ErrorModalService } from "../error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-widget-user-add',
  templateUrl: './widget-user-add.component.html',
  styleUrls: ['./widget-user-add.component.css']
})
export class WidgetUserAddComponent implements OnInit, OnDestroy {
  @Input() lineOfBusinessId: number;
  @Input() widgetList: Array<DashboardTile>;
  widgetListFiltered: Array<DashboardTile>;
  @Input() clientSelectedArray: Array<string>;
  @Output() onAddWidgetExit: EventEmitter<any> = new EventEmitter<any>();
  baseUrl: string;
  dashboardSubscription: Subscription;
  searchText: string;
  arrCategoryChoices: Array<DashboardTileCategory> = [];
  arrCategoryChoicesFiltered: Array<DashboardTileCategory> = [];

  constructor(private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    //private toastrService: ToastService,
    private dashboardService: DashboardService,
    private LoadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
    // subscribe to tile remove so available gets updated upon remove
    this.dashboardSubscription = dashboardService.tileIdRemoved$.subscribe(
      tileIdRemoved => {
        this.getWidgetList();
      }
    );

  }

  ngOnInit() {
    this.getCategoryChoices();
    this.widgetListFiltered = JSON.parse(JSON.stringify(this.widgetList));
  }

  private getWidgetList() {
    this.LoadingSpinnerService.show();
    this.dashboardService.getAvailableTileArray(this.lineOfBusinessId, this.clientSelectedArray).subscribe({
      next: (data) => {
        this.widgetList = data as Array<DashboardTile>;
        this.onTextSearch();
        this.LoadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.LoadingSpinnerService.hide();
      }
    })
  }

  private getCategoryChoices() {
    this.LoadingSpinnerService.show();
    this.dashboardService.getTileCategoriesByLOB(this.lineOfBusinessId).subscribe({
      next: (data) => {
        this.arrCategoryChoices = data as Array<DashboardTileCategory>;
        this.LoadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.LoadingSpinnerService.hide();
      }
    })
  }

  onTextSearch() {
    // filter options by both search criteria
    this.widgetListFiltered = this.widgetList.filter(t => this.combinedSearchFunction(t));
  }

  onCategorySearch() {
    // filter options by both search criteria
    this.widgetListFiltered = this.widgetList.filter(t => this.combinedSearchFunction(t));
  }

  private combinedSearchFunction(tile: DashboardTile): boolean {
    // function that checks whether a training option meets both search criteria
    let matchesText = true;
    let matchesCat = false;

    if (this.searchText && (this.searchText.length >= 3)) {
      let matchesText1 = (tile.description.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0);
      let matchesText2 = (tile.title.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0);
      matchesText = matchesText1 || matchesText2;
    }

    this.arrCategoryChoicesFiltered = this.arrCategoryChoices.filter(c => c.selected);
    let arrCatId: Array<number> = this.arrCategoryChoicesFiltered.map(c => c.categoryId);
    if (arrCatId.length <= 0) {
      // no cat selected, return all
      matchesCat = true;
    } else {
      if (tile.categoryIdList && (tile.categoryIdList.length > 0)) {
        let arrTileCat: Array<string> = tile.categoryIdList.split(",");
        arrTileCat.forEach(cat => {
          if (arrCatId.indexOf(+cat) >= 0) {
            matchesCat = true;
          }
        });
      }
    }

    return matchesText && matchesCat;
  }

  selectCategory(catId: string) {
    let cat = this.arrCategoryChoices.find(c => c.categoryId == +catId);
    if (cat) {
      cat.selected = true;
      this.onCategorySearch();
    }
  }

  addWidget(tileId: number) {
    let params = new HttpParams();
    params = params.append('TileId', tileId.toString());
    // set position params to -1 = newly added widgets will
    // be auto positioned in dashboard
    params = params.append('PositionX', '-1');
    params = params.append('PositionY', '-1');

    this.LoadingSpinnerService.show();
    this.http.post(this.baseUrl + 'api/Dashboard/AddOrUpdate', null, {
      params: params
    }).subscribe({
      next: (data) => {
        this.dashboardService.setTileIdAdded(tileId);
        this.getWidgetList();

        /* old code which refreshes tile array from http
        this.getWidgetList();
        // update Grid
        this.dashboardService.getTileArray(this.lineOfBusinessId).subscribe(data => {
          this.dashboardService.setTileArray(data as Array<DashboardTile>);
        },
          (err: HttpErrorResponse) => {
            this.toastrService.error(err.error.toString());
          });
        */
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
      }
    }
    )
  }

  getWidgetListByCategory(catId: number, widgetList: Array<DashboardTile>): Array<DashboardTile> {
    return widgetList.filter(w => w.categoryIdList.split(",").indexOf(catId.toString()) >= 0);
  }

  closeAddWidgetPanel() {
    this.onAddWidgetExit.emit('closePanels');
  }

  ngOnDestroy() {
    this.dashboardSubscription.unsubscribe();
  }
}
