<!--<link rel="stylesheet" type="text/css" href="/css/stat-center-print.css">-->
<div class="quickSearchContainerDashboard">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row"  *ngIf="!hasClientSelections">
  <div class="col-md-12">
    <p><em>No Client Selected</em></p>
  </div>
</div>
<ng-container *ngIf="hasClientSelections">
  <div class="row"  *ngIf="gridLoading">
    <div class="col-md-12">
      <p><em>Loading...</em></p>
    </div>
  </div>
  <ng-container *ngIf="!gridLoading">
    <app-dashboard-action-bar [dashboardNoResults]="gridNoResults"
                              (onDashboardActionSelect)="doDashboardAction($event)">
    </app-dashboard-action-bar>
    <app-dashboard-date-select *ngIf="dashboardDateSelectPanelShow"
                               [lineOfBusinessId]="lineOfBusiness"
                               (onDateSelectExit)="doDashboardAction($event)">
    </app-dashboard-date-select>
    <app-widget-user-add [widgetList]="dashboardAvailableTileArray"
                         [lineOfBusinessId]="lineOfBusiness"
                         [clientSelectedArray]="clientSelectedArray"
                         (onAddWidgetExit)="doDashboardAction('closePanels')"
                         *ngIf="dashboardAddWidgetPanelShow">
    </app-widget-user-add>
    <div *ngIf="!gridNoResults" class="dashboardMainContainer">
      <app-dashboard-filter-summary [lineOfBusinessId]="lineOfBusiness"
                                    [clientSelectedArray]="clientSelectedArray"
                                    [clientIdSelectedArray]="clientIdSelectedArray"
                                    [globalDateFilter]="globalDateFilter">
      </app-dashboard-filter-summary>
      <div [ngStyle]="{'height': gridsterContainerHeight.toString() + 'px'}" *ngIf="false">
        <gridster [options]="gridsterOptions">
          <gridster-item [item]="item" *ngFor="let item of gridsterDashboard | sortTiles; let recIndex = index; trackBy:item?.tileId">
            <app-card-stat-center [card]="item.dashboardTile" [frameId]="'chartFrame_' + item.dashboardTile.tileId.toString()"></app-card-stat-center>
          </gridster-item>
        </gridster>
      </div>
    </div>
    <div class="row" *ngIf="gridNoResults">
      <div class="col-md-12" >
        <p><em>No Results Found</em></p>
      </div>
    </div>
  </ng-container>
</ng-container>
