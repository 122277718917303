//Angular
import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute, ResolveEnd, NavigationEnd } from "@angular/router";
//Third Party
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as exceljs from 'exceljs';
import * as FileSaver from 'file-saver';
//APP
import { LineOfBusinessService } from "../line-of-business-service/line-of-business-service.component";
import { QuickSearchRequest } from "./quick-search.service";
import { ClientSelectionService } from "../client-selection-service/client-selection-service.component";
import { DataGridEmailService } from "../data-grid-export-email/data-grid-export-email.service";
import { FileDownloadService } from "../../shared/file-download/file-download.service";
import { DataGridService } from "../data-grid/data-grid.service";
import { DriverClientSettings } from "../classes-and-interfaces/classes-and-interfaces.component";
import { LoadingSpinnerService } from "../../services/loading-spinner-service/loading-spinner.service";

@Component({
  selector: 'quick-search-claims',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss']
})
export class QuickSearchComponent implements OnInit, OnDestroy {
  invalidGridData: boolean = false;
  public configObject: any;
  baseUrl: string;
  lineOfBusinessId: number;
  public page = 1;
  public itemsPerPage = 50;
  public maxSize = 5;
  public numPages = 1;
  public length = 0;
  public searchText: string;
  public selectedColumnFilter: number;
  public searchAcrossClients: boolean;
  lineOfBusinessSubscription: Subscription;
  clientSelectionSubscription: Subscription;
  clientSettings: DriverClientSettings;
  clientSelectedArray: Array<string>;

  public sortPageTime = 0;
  columns = [];
  rowsRaw: Array<any>;
  rows: Array<any>;
  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: {}
  };
  today: any;

  constructor(private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private route: ActivatedRoute,
    private dataGridService: DataGridService,
    private searchRequest: QuickSearchRequest,
    private clientSelectionService: ClientSelectionService,
    private lineOfBusinessService: LineOfBusinessService,
    private loadingSpinnerService: LoadingSpinnerService,
    private emailService: DataGridEmailService,
    private fileDownloadService: FileDownloadService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;

    this.searchText = this.searchRequest.SearchText;
    this.selectedColumnFilter = this.searchRequest.SearchColumnFilterId;
    this.searchAcrossClients = this.searchRequest.SearchAcrossClients;

    this.today = Date.now();
    this.lineOfBusinessSubscription = lineOfBusinessService.lineOfBusinessSelected$.subscribe(data => {
      this.lineOfBusinessId = data as number;
    });
  }

  ngOnInit(): void {
    this.loadingSpinnerService.hide();
    this.lineOfBusinessId = this.lineOfBusinessService.getLineOfBusinessValue();
    this.setClientSelectedArray();

    this.route.data
      .subscribe((data) => {
        if (data && data.data && data.data.columns) {
          this.columns = data.data.columns;
          this.rowsRaw = data.data.rows;

          let fields = new Array<any>();

          this.columns.forEach(s => {
            if (this.lineOfBusinessId === 1 && s.name === "Claim") {
              fields.push({
                name: s.title,
                objectKey: s.name,
                columnClass: 'clickable',
                click: row => {
                  this.router.navigate(["/accident/claim/" + row[s.name]]);
                }
              });
            }
            else if ((this.lineOfBusinessId === 2 || this.lineOfBusinessId === 7) && s.prop.toLowerCase() === "employeeid") {
              fields.push({
                name: s.title,
                objectKey: s.prop,
                columnClass: 'clickable',
                click: row => {
                  this.navigateToDriverProfile(row["driverId"]);
                }
              });
            }
            else
              fields.push({
                name: s.title,
                objectKey: s.prop,
                dataType: s?.dataType,
                hidden: ['driverid'].includes(s.prop?.toLowerCase()),
                render: row => {
                  if (s.dataType === "currency") {
                    if (row[s.prop] >= 0)
                      return "$" + row[s.prop];
                    else
                      return "($" + (row[s.prop] * -1) + ")";
                  }
                  else if (s.dataType === "date" && row[s.prop]) {
                    try {
                      return moment(new Date(row[s.prop])).format("MM/DD/YYYY");
                    }
                    catch (err) {
                      return row[s.prop];
                    }
                  }
                  else
                    return row[s.prop]
                }
              });
          });

          let settings = this.columns.map(function (s) {
            return {
              objectKey: s.name,
              sort: 'enable',
              visible: s.visible
            };
          }).filter(x => x.objectKey.toLowerCase() !== "driverid");
          this.configObject = {
            settings: settings,
            fields: fields,
            data: data.data.rows
          };
          if (!data.data.totals || data.data.totals.length === 0) {
            this.configObject.totals = [];
          }
        } else {
          this.invalidGridData = true;
        }
      });
  }

  ngOnDestroy() {
    this.lineOfBusinessSubscription.unsubscribe();
  }

  setClientSelectedArray() {
    this.clientSelectedArray = this.clientSelectionService.getClientSelectedArrayValue(this.lineOfBusinessId);
    this.clientSelectionSubscription = this.clientSelectionService.clientSelectedInfo$.subscribe(
      clientSelectedInfo => {
        const temp = clientSelectedInfo;
        if (temp[this.lineOfBusinessId]) {
          this.clientSelectedArray = temp[this.lineOfBusinessId].clientSelectedArray;
        }
      }
    );
  }

  navigateToDriverProfile(driverId) {
    try {
      if (this.lineOfBusinessId === 2) //Safety
      {
        this.dataGridService.getPrimaryDriverId(driverId).subscribe({
          next: (data) => {
            let primaryDriverId = data as number;
            if (primaryDriverId > 0 && !this.getClientSettings().nonEmployeeProfiling) {
              console.log("Navigating to primary driver profile...")
              this.router.navigate(["/driver/history-profile/" + primaryDriverId], { queryParams: { openToTab: 'Non-Employees' } });
            }
            else {
              this.router.navigate(["/driver/history-profile/" + driverId]);
            }
          },
          error: (err) => {
            this.router.navigate(["/driver/history-profile/" + driverId]);
          }
        });
      }
      else if (this.lineOfBusinessId === 7) //Delivery Contractors
      {
        this.router.navigate(["/dc/driverprofile/" + driverId])
      }
    }
    catch (err) {
      this.router.navigate(["/driver/history-profile/" + driverId]);
    }
  }

  private getClientSettings(): DriverClientSettings {
    let cSettings = this.clientSelectionService.getClientSettingsObjectValue();
    if (cSettings && cSettings[this.clientSelectedArray[0]]) {
      this.clientSettings = cSettings[this.clientSelectedArray[0]];
    } else {
      this.clientSettings = null;
    }
    return this.clientSettings;
  }

  onExcel() {
    const workbook = new exceljs.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    const searchString = " ";
    const replaceWith = "_";
    let dbColumns;
    this.columns.forEach((element) => {
      if (dbColumns === undefined) {
        dbColumns = [{ header: element['name'], key: element['name'].replace(new RegExp(searchString, 'g'), '') }]
      }
      else {
        dbColumns.push({ header: element['name'], key: element['name'].replace(new RegExp(searchString, 'g'), '') })
      }
    })
    worksheet.columns = dbColumns;
    this.configObject.data.forEach((element) => {
      const rowValue = {};
      dbColumns.forEach((columns) => {
        rowValue[columns['key']] = element[columns['header']]
      })
      worksheet.addRow(rowValue);
    })
    const fileName = `Visibility-QuickSearch-${moment().format("MM-DD-YYYY")}.xlsx`.replace(new RegExp(searchString, 'g'), replaceWith);
    workbook.xlsx.writeBuffer().then(function (data) {
      var blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      FileSaver.saveAs(blob, fileName)
    })
  }

  onPrint() {
    let requestParam = btoa(JSON.stringify(this.searchRequest));
    this.fileDownloadService.openTab(this.baseUrl + "api/QuickSearch/Print?request=" + encodeURIComponent(requestParam));
  }

  onEmail() {
    this.emailService.OpenSearchEmailPopup(this.searchRequest);
  }

  trigger($event) {
    switch ($event.name) {
      case 'gt-sorting-applied':
        if ($event.value && $event.value[0] !== '$$gtInitialRowIndex') {

          if (($event.value[0] as string).startsWith('-')) {
            this.searchRequest.SortField = ($event.value[0] as string).slice(1);
            this.searchRequest.SortDir = "DESC"
          }
          else {
            this.searchRequest.SortField = $event.value[0]
            this.searchRequest.SortDir = "ASC"
          }
          const fld = this.configObject.fields.find(x => x.objectKey === this.searchRequest.SortField)
          if (fld)
            this.searchRequest.SortDisplayField = fld.name;
        }
        else {
          this.searchRequest.SortDir = "";
          this.searchRequest.SortDisplayField = "";
          this.searchRequest.SortField = "";
        }
        break;
    }
  }

  sortChange($event): void {
    if (!$event?.active) {
      this.searchRequest.SortDir = "";
      this.searchRequest.SortDisplayField = "";
      this.searchRequest.SortField = "";
    } else {
      this.searchRequest.SortField = $event?.active || null;
      this.searchRequest.SortDir = $event?.direction?.toUpperCase() || null;
      const fld = this.configObject.fields.find(x => x.objectKey === this.searchRequest.SortField);
      if (fld) {
        this.searchRequest.SortDisplayField = fld.name;
      }
    }
  }
}
