import {
  Component, Input, ViewEncapsulation, Inject, OnInit, OnChanges,
  SimpleChange, ViewChild, AfterViewInit, ElementRef, Pipe, PipeTransform
} from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChartRequest } from '../chart-v2/chart-v2.component';
import { DashboardTile } from '../classes-and-interfaces/classes-and-interfaces.component';
import { NgTemplateOutlet } from '@angular/common';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';
import { DashboardService } from '../dashboard-service/dashboard-service.component';
import { Router } from '@angular/router';
import { ReportRequest } from '../data-grid/data-grid.service';
import { OnPremDriverService } from '../on-prem-service/on-prem-driver-service.component';
import { DataGridModalService } from '../data-grid-filter-modal/data-grid-filter-modal.service';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-card-static',
  templateUrl: './card-static.component.html',
  styleUrls: ['./card-static.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class CardStaticComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() card: DashboardTile;

  @Input() filterStartDate: string;
  @Input() filterEndDate: string;

  @Input() globalDateFilterStartDate: string;
  @Input() globalDateFilterEndDate: string;
  @Input() slicerOptionChosen: string;
  @Input() clientSelectionString: string;

  @ViewChild('vc') container: ElementRef;

  chartRequest: ChartRequest;
  chartData: [any];
  readonly template: string = '<span>No Data.</span>';
  chartLoaded: boolean = false;
  resultHtml: string = '<span>No Data.</span>';
  showLoading: boolean = false;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private gridRequest: ReportRequest,
    private loadingSpinnerService: LoadingSpinnerService,
    private dashboardService: DashboardService,
    private dataGridModalService: DataGridModalService,
    private router: Router,
    private onPremDriverService: OnPremDriverService
  ) {
    this.chartRequest = new ChartRequest();
  }

  ngAfterViewInit() {
  }



  setClickEvent() {
    var s = $("#tile" + this.card.tileId + " a.textOnlyLink");
    s.click(this.click.bind(this));
  }

  click(e) {

    var s = $(e.target);

    var params: [any] = new Array<any>() as [any];
    if (s.attr("CategoryName")) {
      params.push({ name: "CategoryName", value: s.attr("CategoryName") });
    }

    if (s.attr("PointName")) {
      params.push({ name: "PointName", value: s.attr("PointName") });
    }


    if (this.dashboardService.buildGridRequestHighCharts(this.gridRequest, params, this.card)) {
      this.loadingSpinnerService.show();
      let randomVal = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
      try {
        sessionStorage.setItem('grid_' + randomVal, JSON.stringify(this.gridRequest));
        if (!this.card.isOnPrem) {
          this.router.navigate(['/datagrid/' + randomVal]);
        } else {
          this.dataGridModalService.openModal(this.card.dataGridId);
        }
      } catch (e) {
        this.loadingSpinnerService.hide();
      }
    }

    //console.log(this.chartData);
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (this.chartLoaded) {
      this.ngOnInit();
    }
  }
  ngOnInit() {
    if (!this.card) {
      return;
    }

    this.chartRequest.LineOfBusiness = this.card.lineOfBusinessId;
    //initialize date filters
    this.chartRequest.StartDate = null;
    this.chartRequest.EndDate = null;

    //Set start and end date if filters have values
    if (this.filterStartDate || this.globalDateFilterStartDate) {
      this.chartRequest.StartDate = new Date(this.filterStartDate || this.globalDateFilterStartDate);
    }
    if (this.filterEndDate || this.globalDateFilterEndDate) {
      this.chartRequest.EndDate = new Date(this.filterEndDate || this.globalDateFilterEndDate);
    }

    this.chartRequest.TileId = this.card.tileId;

    this.chartRequest.Client = this.card.clientSelectedArray.join(",");
    this.chartRequest.SlicerValue = this.slicerOptionChosen || this.card.slicerDefaultValue;

    if (this.card.isOnPrem) {
      this.getOnPremChartData();
    } else {
      this.getChartData();
    }


  }

  private getChartData() {
    this.showLoading = true;
    this.http.post(this.baseUrl + 'api/Dashboard/GetTileData',
      JSON.stringify(this.chartRequest),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {

        let result: any = data;

        //console.log(result);
        this.chartData = result.rows;
        //this.compileTemplate();

        this.replacePlaceholders(this.chartData[0]);
        this.chartLoaded = true;
        this.showLoading = false;

      });
  }

  private getOnPremChartData() {
    if (!this.card.onPremApiUrl || this.card.onPremApiUrl == '') {
      return;
    }
    this.showLoading = true;
    this.onPremDriverService.get(this.card.onPremApiUrl.replace('{clientCode}', this.clientSelectionString))
      .subscribe(data => {
        this.showLoading = false;
        this.chartLoaded = true;
        this.replacePlaceholders(data);
      });
  }


  replacePlaceholders(obj) {
    this.resultHtml = "";
    (obj as Array<any>).forEach(x => {
      var original = this.card.jsChartTileConfig;
      var result = original.replace(/{{([^}}]+)}}/g, function (wholeMatch, key) {
        var substitution = x[$.trim(key)];

        return typeof substitution == 'undefined' ? wholeMatch : substitution;
      });
      this.resultHtml += result;
    });
    if (!this.resultHtml) {
      this.resultHtml = this.template;
    }
    setTimeout(this.setClickEvent.bind(this), 1000);

  }
}
