<div>
  <div>
    <div>
      <div class="modal-header">
        <h4 class="modal-title w-100" id="labelDashboardTileZoomModal">{{dashboardTileZoom?.title}}</h4>
        <button type="button" class="close pull-right mr-1" aria-label="Close" [mat-dialog-close]="true">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="zoomContainer" *ngIf="dashboardTileZoom">
          <app-chart-v2 [TileId]="tileZoomFrame" [card]="dashboardTileZoom"
                        [filterStartDate]="dashboardTileZoom.filterStartDate"
                        [filterEndDate]="dashboardTileZoom.filterEndDate"
                        [globalDateFilterStartDate]="dashboardTileZoom.globalDateFilter.startDate"
                        [globalDateFilterEndDate]="dashboardTileZoom.globalDateFilter.endDate"
                        [slicerOptionChosen]="dashboardTileZoom.slicerOptionChosen"
                        [isOpenedInDialog]="true"></app-chart-v2>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" aria-label="Close" [mat-dialog-close]="true">Close</button>
      </div>
    </div>
  </div>
</div>
