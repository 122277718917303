import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common'
import { Router, ActivatedRoute } from '@angular/router';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { ClaimArbitrationDetail, ArbitrationDocketDetail, UserRightsInfo, Respondent } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { Subscription } from "rxjs";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-accident-claim-arbitration',
  templateUrl: './accident-claim-arbitration.component.html',
  styleUrls: ['./accident-claim-arbitration.component.scss']
})

export class AccidentClaimArbitrationComponent implements OnInit {
  @Output() onFollowUpDateRefresh: EventEmitter<any> = new EventEmitter<any>();
  @Input() userRights: Array<UserRightsInfo>;
  _claimId: number;
  artbitrationDetails: ClaimArbitrationDetail;
  docketDetails: Array<ArbitrationDocketDetail>;
  docketsDetailsWithRespondent: Array<ArbitrationDocketDetail>;
  docketTotal: number;
  arrReq: Array<any> = [];
  _routeSub: Subscription;

  constructor(private readonly _route: ActivatedRoute,
    private readonly datepipe: DatePipe,
    private readonly accidentClaimService: AccidentClaimService,
    private readonly clientSelectionService: ClientSelectionService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];

      this.getArbitrationDetails();
      this.getDocketDetails();
    });
  }

  private getArbitrationDetails(): void {
    const dateFormat = 'MM/dd/yyyy';
    const timeZone = 'en-US';
    this.accidentClaimService.getArbitrationDetails(this._claimId).subscribe({
      next: (data) => {
        this.artbitrationDetails = data as ClaimArbitrationDetail;
        this.artbitrationDetails.decisionDate = (!this.artbitrationDetails.decisionDate) ? this.artbitrationDetails.decisionDate :
          formatDate(this.artbitrationDetails.decisionDate, dateFormat, timeZone);
        this.artbitrationDetails.filingDate = (!this.artbitrationDetails.filingDate) ? this.artbitrationDetails.filingDate :
          formatDate(this.artbitrationDetails.filingDate, dateFormat, timeZone);
        this.artbitrationDetails.updateDate = (!this.artbitrationDetails.updateDate) ? this.artbitrationDetails.updateDate :
          formatDate(this.artbitrationDetails.updateDate, dateFormat, timeZone);
        this.artbitrationDetails.closeDate = (!this.artbitrationDetails.closeDate) ? this.artbitrationDetails.closeDate :
          formatDate(this.artbitrationDetails.closeDate, dateFormat, timeZone);
        this.artbitrationDetails.postDecisionInquiryDate = (!this.artbitrationDetails.postDecisionInquiryDate) ? this.artbitrationDetails.postDecisionInquiryDate :
          formatDate(this.artbitrationDetails.postDecisionInquiryDate, dateFormat, timeZone);
      },
      error: (err: HttpErrorResponse) => {
        console.log(err.error.toString());
      }
    });
  }
  private getDocketDetails(): void {
    this.accidentClaimService.getDocketDetails(this._claimId).subscribe({
      next: (data) => {
        this.docketDetails = data as Array<ArbitrationDocketDetail>;
        this.docketsDetailsWithRespondent = this.docketDetails
          .map(e => e['docketNumber'])
          .map((e, x, final) => final.indexOf(e) === x && x)
          .filter(obj => this.docketDetails[obj])
          .map(e => this.docketDetails[e]);
        const respondents: Array<Respondent> = [];
        var repeatedDocketNumber = this.docketDetails
          .map(e => e['docketNumber'])
          .map((e, x, final) => final.indexOf(e) !== x && x)
          .filter(obj => this.docketDetails[obj])
          .map(e => this.docketDetails[e]["docketNumber"]);
        if (repeatedDocketNumber.length > 0) {
          for (const d of repeatedDocketNumber) {
            this.docketDetails.filter(obj => d.includes(obj.docketNumber)).forEach(docket => {
              const respondent: Respondent = {
                address: docket.addres,
                adjusterEmail: docket.adjusterEmail,
                name: docket.name,
                companyName: docket.companyName,
                previousPaymentReceived: docket.previousPaymentReceived,
                amountRequested: docket.amountRequested
              };
              respondents.push(respondent);
            });
            var indexObject = this.docketsDetailsWithRespondent.findIndex(r => r.docketNumber === d);
            this.docketsDetailsWithRespondent[indexObject].respondent = respondents;
          }
        } else {
          for (var y = 0; y < this.docketDetails.length; y++) {
            this.docketDetails.forEach(docket => {
              const respondent: Respondent = {
                address: docket.addres,
                adjusterEmail: docket.adjusterEmail,
                name: docket.name,
                companyName: docket.companyName,
                previousPaymentReceived: docket.previousPaymentReceived,
                amountRequested: docket.amountRequested
              };
              respondents.push(respondent);
            });
            this.docketsDetailsWithRespondent[y].respondent = respondents;
          }
        }
        this.docketTotal = 0;
        for (var j = 0; j <= this.docketDetails.length; j++) {
          this.docketTotal += this.docketDetails[j].amountAwarded;
        }
      },
      error: (err: HttpErrorResponse) => {
        console.log(err.error.toString());
      }
    });
  }
}
