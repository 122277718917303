import { Component, OnInit, Input } from '@angular/core';
import { DriverProfile, UserRightsInfo, DriverClientSettings } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

/**
 * this is the client-side controller for the safety - driver info tab panel
 */
@Component({
  selector: 'safety-driver-info',
  templateUrl: './safety-driver-info.component.html',
  styleUrls: ['./safety-driver-info.component.css']
})

export class SafetyDriverInfoComponent implements OnInit {
  @Input() driverProfile: DriverProfile;
  @Input() _userRights: Array<UserRightsInfo>;
  @Input() clientSettings: DriverClientSettings;
  @Input() extendedInfo: Array<any>;
  @Input() fullProfile: boolean;
  hireDateInReq = false;
  canViewDLDOB = false;
  canViewDOT = false;
  canViewAutoCoverage = false;

  // angular on intialization event
  ngOnInit() {
    if (this.extendedInfo) {
      this.extendedInfo.forEach(x => {
        if (x.label.toLowerCase() === "hire date") { 
          this.hireDateInReq = true;
        }
      });
    }
    this.canViewDLDOB = this.isAccessAllowed(42);
    if (this.clientSettings) {
      this.canViewDOT = this.clientSettings.isDriverQualificationOn;
      this.canViewAutoCoverage = this.clientSettings.isAutoCoverageOn;
    }
  }

  // determines if access to the component is allowed
  isAccessAllowed(userRightsId: number) {
    let retVal = false;

    if (this._userRights) {
      const thisRight = this._userRights.filter(r => r.userRightId === userRightsId);
      if ((thisRight.length > 0) && (thisRight[0].permission === 'allow')) {
        retVal = true;
      }
    }

    return retVal;
  }

}
