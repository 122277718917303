<div>
  <div class="title">
    <h4 id="labelConfirmModal">Add/Edit Document</h4>
    <div class="btn-close-container">
      <button title="close-btn" class="btn-close" [mat-dialog-close]="true">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <div class="body">
    <document-upload-azure #docUploadAzureRef uploadType="SafetyDocumentLibrary" [documents]="data.documents"
      [lineofBusinesssId]="lineofBusinesssId" [clientShortName]="data.clientShortName"
      (onDocUploadRefresh)="closeModal(true)" (onDocUploadExit)="closeModal(false)">
    </document-upload-azure>
  </div>
</div>