import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { trigger, state, style, transition, animate } from '@angular/animations';


@Injectable()
export class ScrollToTopService {
  baseUrl: string;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  private timerID: any = null;
  private animate: boolean = false;
  private speed: number = 80;
  private acceleration: number = 0;
  /**
     * Scrolls window to top
     * @param event
     */
  public scrollTop()//event: any)
  {
    if (!this.isBrowser()) {
      return;
    }

    //event.preventDefault();
    if (this.animate) {
      this.animateScrollTop();
    } else {
      window.scrollTo(0, 0);
    }
  };

  /**
   * Performs the animated scroll to top
   */
  private animateScrollTop() {
    if (this.timerID !== null) {
      return;
    }

    let initialSpeed = this.speed;
    const that = this;

    this.timerID = setInterval(function () {
      window.scrollBy(0, -initialSpeed);
      initialSpeed = initialSpeed + that.acceleration;
      if (that.getCurrentScrollTop() === 0) {
        clearInterval(that.timerID);
        that.timerID = null;
      }
    }, 15);
  };
  /**
     * Get current Y scroll position
     */
  private getCurrentScrollTop() {
    if (typeof window.scrollY !== 'undefined' && window.scrollY >= 0) {
      return window.scrollY;
    }

    if (typeof window.pageYOffset !== 'undefined' && window.pageYOffset >= 0) {
      return window.pageYOffset;
    }

    if (typeof document.body.scrollTop !== 'undefined' && document.body.scrollTop >= 0) {
      return document.body.scrollTop;
    }

    if (typeof document.documentElement.scrollTop !== 'undefined' && document.documentElement.scrollTop >= 0) {
      return document.documentElement.scrollTop;
    }

    return 0;
  };
  /**
     * This check will prevent 'window' logic to be executed
     * while executing the server rendering
     */
  private isBrowser(): boolean {
    return typeof (window) !== 'undefined';
  };
}
