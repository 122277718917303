import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';

@Injectable()
export class DHPOrderMVRService {
  baseUrl: string;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private onPremService: OnPremDriverService,
    //private clientService: ClientSelectionService,
  ) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  // order mvr report for a driver
  orderMVRReport(driverId: number, client: string) {
    //console.log('orders and MVR report');


    var params = {
      ClientCode: client,
      MvrDriverIdList: driverId.toString(),
      MvrDriverIdExcludeLis: null,
      MvrDataValidationDue: null,
      MvrDataValidationOnly: false,
      MvrMonitorDriverIdList: null,
      MvrMonitorDriverIdExcludeList: null,
      LicenseUploadDriverIdList: null,
      LicenseUploadDriverIdExcludeList: null,
      LicenseUploadDueDate: null,
      TrainingDriverIdList: null,
      TrainingDriverIdExcludeList: null,
      TrainingDueDate: null,
      TrainingAssignmentType: null,
      TrainingCourseGroupList: null,
      TrainingPassRateList: null,
      ProcessId: null
    };
    this.onPremService.post("task/batch", params).subscribe({
      next: (data) => {
        console.log(data)
      },
      error: (err: HttpErrorResponse) => {
        //this.errorService.setErrorObject(err.error);
        console.log(err);
      }
    });
  }
}
