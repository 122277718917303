//angular libraries
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, Output, ViewChild, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//third party libraries
import { MatSnackBar } from '@angular/material/snack-bar';
//projects imports
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { DcDriverProduct, DcRequestResponse } from '../dc-classes-and-interfaces/dc-classes-and-interfaces.component';
import { DcConfirmActionService } from '../dc-confirm-action/dc-confirm-action-service.component';
import { DcDriverProfileService } from '../dc-driver-profile/dc-driver-profile-service.component';


@Component({
  selector: 'dc-driver-remove-program',
  templateUrl: './dc-driver-remove-program.component.html',
  styleUrls: ['./dc-driver-remove-program.component.scss']
})
export class DcDriverRemoveProgramComponent implements OnInit {
  @Input() modalHeight: string;
  @Input() modalInput: any;
  title: string;
  driverName: string;
  programName: string;
  companyId: number;
  driverId: number;
  slotToRemove: number;
  lastProgram: boolean = false;
  programs: Array<DcDriverProduct>;
  @Output() onConfirmModalYes: EventEmitter<any> = new EventEmitter<any>();
  @Output() onConfirmModalNo: EventEmitter<any> = new EventEmitter<any>();

  constructor(private onPremService: OnPremDcService,
    private errorService: ErrorModalService,
    private dcService: DcDriverProfileService,
    private snackBar: MatSnackBar,
    private dcConfirmActionService: DcConfirmActionService,
    private router: Router
  ) { }

  ngOnInit() {
    this.companyId = this.modalInput.companyId;
    this.driverId = this.modalInput.driverId;
    this.title = this.modalInput.title;
    this.programName = this.modalInput.programName;
    this.programs = this.modalInput.programs;
    this.slotToRemove = this.modalInput.slotID;
    this.lastProgram = this.modalInput.lastProgram;
    this.driverName = this.modalInput.driverName;
  }


  confirmModalYes() {
    this.removeProgram();
    this.closeRemoveConfirmModal()
  }

  confirmModalNo() {
    this.closeRemoveConfirmModal();
  }

  public removeProgram() {
    this.onPremService.put('companies/' + this.companyId + '/slots/' + this.slotToRemove + '/unassign', null).subscribe({
      next: (data) => { // next
        let resp = data as DcRequestResponse;
        this.lastProgram = this.programs.length == 1 ? true : false
        if (!resp.isSuccess) {
          this.errorService.replaceErrorObject({ message: resp.errorMessage });
        } else {
          this.snackBar.open('Driver Removed', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: 'success-snackbar'
          });
          this.dcService.setRefreshDriverProfileBaseData(this.driverId);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
      },
      complete: () => { // complete
        if (this.lastProgram) {
          this.terminateDriver();
        }
      }
    })
  }

  private terminateDriver(): void {
    let action = 'Terminate Driver';
    this.dcConfirmActionService.terminateDriver(this.driverId.toString()).subscribe({
      next: (data) => {
        this.snackBar.open(action + "Driver Terminated", 'Ok', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5000,
          panelClass: 'success-snackbar'
        });
        this.router.navigate(['/statcenter', 7]);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
      }
    });
  }

  public closeRemoveConfirmModal(): void {
    this.dcService.notifyModalClose();
  }

}
