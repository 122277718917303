import { Component, Input  } from '@angular/core';
import { DriverProfile, UserRightsInfo, DriverExtendedInfo, DriverClientSettings } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

/**
 * this is the client-side controller for the safety - non-employees tab panel
 */
@Component({
  selector: 'safety-non-employees',
  templateUrl: './safety-non-employees.component.html',
  styleUrls: ['./safety-non-employees.component.css']
})

export class SafetyNonEmployeesComponent {
  @Input() driverProfile: DriverProfile;
  @Input() _userRights: Array<UserRightsInfo>;
  @Input() secondaryDrivers: Array<DriverProfile>;
  @Input() arrReqFields: Array<DriverExtendedInfo>;
  @Input() allowMultipleNonEmployees: boolean;
  @Input() nonEmployeeProfiling: boolean;
  @Input() clientSettings: DriverClientSettings;

  constructor() { }

}
