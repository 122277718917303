<div [formGroup]="trainingAssignmentsForm">
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-body mt-3">
            <app-order-assign-choices
              [availableChoices]="safetyProcesses"
              [parentForm]="trainingAssignmentsForm"
              [uiMap]="uiMap"
              [selectorPrefix]="formGroupNameInput"
            ></app-order-assign-choices>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-body mt-3">
            <app-driver-type
              [parentForm]="trainingAssignmentsForm"
              [showLicenseVerificationOption]="false"
              [selectorPrefix]="formGroupNameInput"
            ></app-driver-type>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-body mt-3">
            <app-driver-risk
              [parentForm]="trainingAssignmentsForm"
              [selectorPrefix]="formGroupNameInput"
            ></app-driver-risk>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-body mt-3">
        <app-training-lesson-selector
          [parentForm]="trainingAssignmentsForm"
          
        ></app-training-lesson-selector>
      </div>
    </div>
  </div>
</div>
