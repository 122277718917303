
import { throwError as observableThrowError, Subject, Observable, BehaviorSubject } from 'rxjs';
import { catchError } from "rxjs/operators";
import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CustomReport, CustomReportField, ClientReqFields, GetLOB, CustomReportHeader, CustomReportBody } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

@Injectable()
export class CustomReportService {
  baseUrl: string;
  errorMessage: string;

  public customReportsList = new BehaviorSubject<Array<GetLOB>>([]);
  
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  /* GET BY LOB ID */
  getByUserLOB(LineOfBusinessId: number): Observable<GetLOB[]> {
    let params = new HttpParams();
    params = params.append('LineOfBusinessId', LineOfBusinessId.toString());
    return this.http.get<GetLOB[]>(this.baseUrl + 'api/CustomReport/GetByUserLob', {
      params: params
    }).pipe(
        catchError(this.handleError)
    );
  }

  /* GET BY REPORT ID */
  getById(ReportId: number) : Observable<CustomReport[]> {
    let params = new HttpParams();
    params = params.append('ReportId', ReportId.toString());

    return this.http.get<CustomReport[]>(this.baseUrl + 'api/CustomReport/GetById', {
      params: params
    }).pipe(
        catchError(this.handleError)
    );
  }

  /* GET CHOSEN FIELDS BY LINE OF BUSINESS ID AND REPORT ID */
  getColumnsByUserLOB(lineOfBusinessId: number, reportId: number): Observable<CustomReportField[]> {
    let params = new HttpParams();
    params = params.append('LineOfBusinessId', lineOfBusinessId.toString());

    if (reportId > 0) {
      params = params.append('ReportId', reportId.toString());
    }

    return this.http.get<CustomReportField[]>(this.baseUrl + 'api/CustomReport/GetColumnsByUserLob', {
      params: params
    }).pipe(
        catchError(this.handleError)
    );
  }

  /* GET CHOSEN FIELDS BY LOB ID AND REPORT ID */
  getClientReqByUserLob(lineOfBusinessId: number, clients: string) : Observable<ClientReqFields[]> {
    let params = new HttpParams();
    params = params.append('LineOfBusinessId', lineOfBusinessId.toString());
    params = params.append('Clients', clients.toString());

      return this.http.get <ClientReqFields[]>(this.baseUrl + 'api/CustomReport/GetClientReqByUserLob', {
      params: params
      }).pipe(
          catchError(this.handleError)
      );
  }

  /* UPDATE OR INSERT A REPORT */
  upsertReportHeader(customReportHeader: CustomReportHeader) : Observable<number> {
    return this.http.post<number>(this.baseUrl + 'api/CustomReport/UpsertReport',
      JSON.stringify(customReportHeader),
      {
        headers: {
          'Content-Type': 'application/json'
        }
        }).pipe(
            catchError(this.handleError)
        );
  }

  /* UPDATE OR INSERT FIELDS FOR A REPORT ID */
  upsertReportColumns(customReportBody: CustomReportBody) : Observable<any> {
    return this.http.post<number>(this.baseUrl + 'api/CustomReport/UpsertColumns',
      JSON.stringify(customReportBody),
      {
        headers: {
          'Content-Type': 'application/json'
        }
        }).pipe(
            catchError(this.handleError)
        );
  }

  /* DELETE REPORT BY ID */
  deleteReports(reportId: number): Observable<any> {
    return this.http.post<number>(this.baseUrl + 'api/CustomReport/DeleteReport',
      JSON.stringify(reportId),
      {
        headers: {
          'Content-Type': 'application/json'
        }
        }).pipe(
            catchError(this.handleError)
        );
  }

  /* HANDLES ENCOUNTERED ERRORS */
  private handleError(err: HttpErrorResponse) {
    return observableThrowError(err.error.message);
  }
}
