import { environment } from './../../../environments/environment';
import { SharedModule } from './../shared.module';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { saveAs } from 'file-saver';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { DbDocumentAzureInfo, ErrorObject } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { BlobStorageService } from '../blob-storage/blob-storage.service';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Injectable(
)
export class FileDownloadService {
  constructor(
    private http: HttpClient,
    private blobStorageService: BlobStorageService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorModalService: ErrorModalService
  ) {
  }

  private spinnerInnerHTML: string;

  get(url: string): Observable<{}> {
    return new Observable((observer: Subscriber<{}>) => {
      let objectUrl: string = null;

      this.http
        .get(url, {
          responseType: 'blob'
        })
        .subscribe(m => {
          objectUrl = URL.createObjectURL(m);
          observer.next(objectUrl);
        });

      return () => {
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
          objectUrl = null;
        }
      };
    });
  }

  public getFile(url) {
    return this.http.get(url, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  public saveFile(url, filename = "") {
    this.loadingSpinnerService.showUploadMessage();
    this.getFile(url).subscribe({
      next: (response) => this.saveToFileSystem(response, filename),
      error: (error: HttpErrorResponse) => this.downloadError(error)
    });
  }

  public saveAzureFile(url: string, fileName: string = "") {
    this.loadingSpinnerService.showUploadMessage()
    this.getBlobInfo(url).subscribe({
      next: (response) => {
        const blobInfo = response as DbDocumentAzureInfo;
        var storageUrl = this.getStorageUrl(blobInfo.azureBlobUrl);
        this.blobStorageService.getBlob(blobInfo, storageUrl)
          .then(blobResponse =>
            this.saveAzureFileToFileSystem(blobResponse, fileName.length > 0 ? fileName : blobInfo.fileName)
          )
          .catch(error => console.error(error));
      },
      error: (error: HttpErrorResponse) => this.downloadError(error)
    });
  }

  private getStorageUrl(azureBlobUrl: string) {
    var url = new URL(azureBlobUrl);
    return url.protocol + "//" + url.hostname + "/"
  }

  private downloadError(error: HttpErrorResponse) {
    this.loadingSpinnerService.hideUploadMessage();
    let service = this.errorModalService;
    var reader = new FileReader();
    reader.onload = function (service) {
      return function (e) {
        let err = JSON.parse(e.target.result as string) as ErrorObject;
        service.setErrorObject(err);
        console.log(reader.result);
      };
    }(this.errorModalService);
    reader.readAsText(error.error);
  }

  private saveToFileSystem(response, requestedfilename = "") {
    this.loadingSpinnerService.hideUploadMessage();
    const contentDispositionHeader: string = response.headers.get('Content-Disposition');
    const parts: string[] = contentDispositionHeader.split(';');
    let filename = parts[1].split('=')[1];
    if (filename.search("/") > -1) {
      filename = parts[1].split('/')[1];
    }
    //Replacing all occurrences of the quotation
    filename = filename.replace(/\"/g, "");

    let extension = filename.substr(filename.lastIndexOf('.'));

    if (requestedfilename)
      filename = requestedfilename + extension;
    const blob = new Blob([response.body], { type: response.headers.get("content-type") });
    saveAs(blob, filename);
  }

  private saveAzureFileToFileSystem(response, filename) {
    response.blobBody.then(blob => {
      new Blob([blob], { type: response._response.headers.get("content-type") });
      this.loadingSpinnerService.hideUploadMessage();
      saveAs(blob, filename);
    })
  }

  public getBlobInfo(url: string) {
    return this.http.get(url);
  }

  public openTab(url) {
    this.loadingSpinnerService.show();
    let newTab = window.open("", "Print");
    newTab.document.write("Loading...");
    newTab.document.close();
    this.http.get(url, {
      responseType: 'text',
      observe: 'response'
    }).subscribe({
      next: (response) => this.handleOpenTab(response, newTab),
      error: (error: HttpErrorResponse) => this.downloadError(error)
    });
  }

  private handleOpenTab(response, printWindow: Window) {
    this.loadingSpinnerService.hide();
    printWindow.document.open();
    printWindow.document.clear();
    printWindow.document.write(response.body);
    printWindow.document.close();
  }
}
