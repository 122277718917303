



<div class="quickSearchContainerDataGrid">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" >
  <div class="col-md-12">
    <H3>
      BI Driver Maintenance
    </H3>
  </div>
</div>
<div class="row">
  <div class="col-md-12" style="text-align:right">
    <button #btnOptOutMultiple id="btnOptOutMultiple" class="btn btn-primary"  style="white-space: nowrap!important" (click)="openSubmitSelectedDrivers()">
      Submit All Selected Drivers
    </button>
  </div>
</div>
<div class="row" *ngIf="configObject">
  <div class="col-md-12">
    <app-data-table
      #myTable
      [columns]="configObject.fields"
      [data]="configObject.data"
      [showPagination]="true"
      noRecordsLabel="No results"
    ></app-data-table>
    <div id="reportFooter">
      <div class="left"> Fleet Response Confidential</div>
      <div class="right"> Report run {{today | date :'short' }}</div>
    </div>
  </div>
  <!--<div *ngIf="!rowsRaw || rowsRaw?.length == 0">
    No results
  </div>-->
</div>
