import { Component, OnInit, OnDestroy } from '@angular/core';
import { DcDriverProfileService } from '../dc-driver-profile/dc-driver-profile-service.component';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { Subscription, Observable, forkJoin } from 'rxjs';
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { DcModalWindowService } from '../dc-modal-window/dc-modal-window-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { DcSubscription, DcSubscriptionSlot, DcDriver } from '../dc-classes-and-interfaces/dc-classes-and-interfaces.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'dc-programs-driver-slots',
  templateUrl: './dc-programs-driver-slots.component.html'
})

export class DcProgramsDriverSlotsComponent implements OnInit, OnDestroy {
  arrProgram: Array<DcSubscription>;
  companyId: number;
  companyName: string;
  companyGuid: string;
  refreshDriverSlotsSub: Subscription;

  constructor(private loadingSpinnerService: LoadingSpinnerService,
    private onPremService: OnPremDcService,
    private lineOfBusinessService: LineOfBusinessService,
    private dcService: DcDriverProfileService,
    private dcModalWindowService: DcModalWindowService,
    private errorService: ErrorModalService) { }

  ngOnInit() {
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 7) {
      this.lineOfBusinessService.setLineOfBusiness(7);
    }

    this.refreshDriverSlotsSub = this.dcService.dcRefreshDriverSlotsData$.subscribe(data => {
      // refresh driver data for program if driver added
      if (data) {
        let productId = data as number;
        let p = this.arrProgram.find(p => p.product.id == productId);

        if (p) {
          let arrObs: Array<Observable<Object>> = [
            this.getSummaryDataByProduct(this.companyId, productId),
            this.getDetailData(this.companyId, productId)
          ]
          this.loadingSpinnerService.show();
          forkJoin(arrObs).subscribe({
            next: (subs) => {
              const summ = subs[0] as Array<DcSubscription>;
              p.slotsAvailable = summ[0].slotsAvailable;
              p.slotsPurchased = summ[0].slotsPurchased;
              p.slotsUsed = summ[0].slotsUsed;
              p.slotDetail = subs[1] as Array<DcSubscriptionSlot>;
              this.loadingSpinnerService.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.errorService.setErrorObject(err.error);
              this.loadingSpinnerService.hide();
            }
          });
        }

        this.dcService.setRefreshDriverSlotsData(null);
      }
    })

  }

  initPage(companyObject: any) {
    this.arrProgram = null;
    this.companyId = companyObject.companyId;
    this.companyName = companyObject.companyName;
    this.companyGuid = companyObject.groupGuid;

    if (this.companyId) {
      this.loadingSpinnerService.show();

      this.getSummaryData(this.companyId).subscribe({
        next: (data) => {
          this.arrProgram = data as Array<DcSubscription>;
          this.loadingSpinnerService.hide();
          this.arrProgram.forEach(p => {
            p.productName = p.product.description;
            this.getDetailData(this.companyId, p.product.id).subscribe({
              next: (subs) => {
                p.slotDetail = subs as Array<DcSubscriptionSlot>;
              },
              error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
              }
            });
          });
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    }
  }

  private getSummaryData(companyId: number) {
    if (companyId > 0) {
      return this.onPremService.get("companies/" + companyId.toString() + "/products/summary");
    }
  }

  private getSummaryDataByProduct(companyId: number, productId: number) {
    if ((companyId > 0) && (productId > 0)) {
      return this.onPremService.get("companies/" + companyId.toString() + "/products/" + productId.toString() + "/summary");
    }
  }

  private getDetailData(companyId: number, productId: number) {
    if ((companyId > 0) && (productId > 0)) {
      return this.onPremService.get("companies/" + companyId.toString() + "/products/" + productId.toString() + "/drivers");
    }
  }

  addNewDriver() {
    this.dcModalWindowService.notifyModal('Add New Driver', 'dcDriverSlotsAddDriver', 'auto', 'lg', { companyId: this.companyId, companyGuid: this.companyGuid, addType: 'new' });

  }

  addExistingDriver() {
    this.dcModalWindowService.notifyModal('Add Existing Driver', 'dcDriverSlotsAddDriver', 'auto', 'lg', { companyId: this.companyId, companyGuid: this.companyGuid, addType: 'existing' });
  }

  ngOnDestroy() {
    this.refreshDriverSlotsSub.unsubscribe();
  }
}

