
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  //private authStarted = false;

  authTokenInProgress = false;
  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();


  constructor(private injector: Injector,
    private authService: AuthService,
    private router: Router) {

  }

  addAuthHeader(request) {
    try {
      //console.log(this.authService.user.expires_in);
      if (this.authService.user.expires_in < 0 || !this.authService.isLoggedIn()) {
        console.log("Token expired. Redirecting to login page...");
        this.authService.startAuthentication();
      }
    }
    catch { }
    if (this.authService.isLoggedIn()) {
      if (this.authService.user.expires_in < 2700 && !this.refreshTokenInProgress
        && !(request.url as string).toLowerCase().endsWith("/api/usersettings/getusernotifications") //exception to prevent user notifications to extend session
      ) {
        this.refreshTokenInProgress = true;
        //console.log("Token refreshing...");
        this.authService.refreshToken().then((user) => {
          console.log("Token refreshed");
          this.authService.user = user;
          this.tokenRefreshedSource.next(true);
          this.refreshTokenInProgress = false;
        }).catch((err) => {
          console.log(err);
          this.refreshTokenInProgress = false;
        });
      }

      //console.log("Token expires at:");
      //console.log(new Date(this.authService.user.expires_at));
      //console.log(this.authService.user.expires_in);
      //console.log(this.authService.user.expires_in / 60);


      const authHeader = this.authService.getAuthorizationHeaderValue();
      if (authHeader) {
        return request.clone({
          setHeaders: {
            "Authorization": authHeader
          }
        });
      }
    }
    else {
      this.authService.startAuthentication();
    }
    //else {
    //  this.logout();
    //}
    return request;
  }

  refreshToken(): Promise<any> | Observable<any> {
    if (this.authTokenInProgress) {
      return new Observable(observer => {
        observer.complete();
      });
      //return new Observable(observer => {
      //  this.tokenRefreshed$.subscribe(() => {
      //    observer.next();
      //    observer.complete();
      //    //this.tokenRefreshedSource.complete();
      //  });
      //});
    } else {
      this.authTokenInProgress = true;
      return this.tokenRefreshed$;
      //return this.authService.startAuthentication()//this.authService.refreshToken()
      //  .then(() => {
      //    this.authTokenInProgress = false;
      //    this.tokenRefreshedSource.next();
      //  });
    }
  }
  logout() {
    this.authService.logOut();
    this.router.navigate(["login"]);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    //let requestToForward = req;

    // Handle request
    req = this.addAuthHeader(req);


    return next.handle(req)
      .pipe(
        catchError(error => {

          if (error.status === 401) {
            return (this.refreshToken() as Observable<any>)
              .pipe(() => {
                req = this.addAuthHeader(req);
                return next.handle(req);
              })
              .pipe(
                catchError(() => {
                  this.logout();
                  return EMPTY;
                })
              );
          }
          else if (error.status === 403) {
            console.log("Access Denied: 403");
            this.router.navigate(["/accessdenied"]);
            return throwError(() => error);
          }
          else
            return throwError(() => error);
        })
      );
    /*
  .catch(error => {

    if (error.status === 401) {
      return (this.refreshToken() as Observable<any>)
        .pipe(() => {
          req = this.addAuthHeader(req);
          return next.handle(req);
        })
        .catch(() => {
          this.logout();
          return empty();
        });
    }
    else if (error.status === 403) {
      console.log("Access Denied: 403");
      this.router.navigate(["/accessdenied"]);
      return observableThrowError(error)
    }
    else
      return observableThrowError(error);


  });
  */
  }
}
