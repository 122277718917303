<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <div class="row">
        <div class="col-md-4">
          <h4 class="h4-responsive font-weight-bold pb-2">Information</h4>

          <div class="mb-3">
            <label class="mb-0" for="estimateAmount">Estimate Amount</label>
            <input type="text" id="estimateAmount" [ngModel]="supplementalData.estimateAmount | number:'1.2-2'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="supplementalData.estimateAmount=roundNumber($event)" tabindex="10">
          </div>

          <div class="mb-3">
            <label class="mb-0" for="deductibleAmount">Deductible Amount</label>
            <input type="text" id="deductibleAmount" [ngModel]="supplementalData.deductibleAmount | number:'1.2-2'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="supplementalData.deductibleAmount=roundNumber($event)" tabindex="20">
          </div>

          <div class="mb-3">
            <label class="mb-0" for="nbvAmount">NBV</label>
            <input type="text" id="nbvAmount" [ngModel]="supplementalData.nbv | number:'1.2-2'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="supplementalData.nbv=roundNumber($event)" tabindex="30">
          </div>

          <div>
            <mat-slide-toggle 
              [checked]="supplementalData.isTotalLoss" 
              [(ngModel)]="supplementalData.isTotalLoss">Total Loss
            </mat-slide-toggle>
          </div>

          <div class="mt-3">
            <label for="repairDate">Date Repair Paid by Merchants</label>
          </div>
          <div>
            <mat-form-field appearance="fill" class="w-100">
              <mat-label *ngIf="!supplementalData.merchantsPaidDate" class="lbl-start-date">Date Repair Paid</mat-label>
              <input 
                class="input-date" 
                matInput disabled 
                [(ngModel)]="supplementalData.merchantsPaidDate" 
                [matDatepicker]="dataRepairDatePicker"
                [max]="maxDate" 
                (click)="dataRepairDatePicker.open()">
              <mat-datepicker-toggle matSuffix [for]="dataRepairDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #dataRepairDatePicker disabled="false">
                <mat-datepicker-actions>
                  <button mat-button (click)="dataRepairDatePicker.select(actualDate); dataRepairDatePicker.close()">
                    <mat-icon>history</mat-icon>
                    Today
                  </button>
                  <button mat-button (click)="dataRepairDatePicker.select(null); dataRepairDatePicker.close()">
                    <mat-icon>highlight_off</mat-icon>
                    Clear
                  </button>
                </mat-datepicker-actions> 
              </mat-datepicker>
            </mat-form-field>
          </div>

          <div class="row">
            <div class="col-md-12">
              <mat-form-field appearance="fill" class="select-container">
                <mat-label>Choose the Lease Type</mat-label>
                <mat-select #selectLeaseTypes="matSelect" [(value)]="supplementalData.merchantsLeaseTypeIdString">
                  <input matInput class="select-search-input" 
                    placeholder="Search Lease Types..." 
                    (keyup)="filterLeaseTypeOptions($event.target.value)">
                  <mat-option *ngFor="let option of filteredLeaseTypeOptions" [value]="option.value">
                    {{option.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12">
              <mat-form-field appearance="fill" class="select-container">
                <mat-label>Liability</mat-label>
                <mat-select #selectLiabilityTypes="matSelect" [(value)]="supplementalData.merchantsLiabilityIdString">
                  <input matInput class="select-search-input" 
                    placeholder="Search Liabilities..." 
                    (keyup)="filterLiabilityTypeOptions($event.target.value)">
                  <mat-option *ngFor="let option of filteredLiabilityTypeOptions" [value]="option.value">
                    {{option.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="mb-3 mt-3">
            <label class="mb-0" for="liabClaimNumber">Liability Claim Number</label>
            <input type="text" id="liabClaimNumber" [(ngModel)]="supplementalData.liabilityClaimNumber" placeholder="Liability Claim Number" tabindex="80">
          </div>

          <div class="mb-3 mt-4">
            <label class="mb-0" for="biClaimNumber">Bodily Injury Claim Number</label>
            <input type="text" id="biClaimNumber" [(ngModel)]="supplementalData.bodilyInjuryClaimNumber" placeholder="Bodily Injury Claim Number" tabindex="90">
          </div>

        </div>

        <div class="col-md-4">
          <h4 class="h4-responsive font-weight-bold pb-2">Expenses</h4>

          <div class="mb-3">
            <label class="mb-0" for="towingExpense">Towing Expense</label>
            <input type="text" id="towingExpense" [ngModel]="supplementalData.towingAmount | number:'1.2-2'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="supplementalData.towingAmount=roundNumber($event)" tabindex="100">
          </div>

          <div class="mb-3">
            <label class="mb-0" for="storageExpense">Storage Expense</label>
            <input type="text" id="storageExpense" [ngModel]="supplementalData.storageAmount | number:'1.2-2'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="supplementalData.storageAmount=roundNumber($event)" tabindex="110">
          </div>

          <div class="mb-3">
            <label class="mb-0" for="rentalExpense">Rental Expense</label>
            <input type="text" id="rentalExpense" [ngModel]="supplementalData.rentalExpenseAmount | number:'1.2-2'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="supplementalData.rentalExpenseAmount=roundNumber($event)" tabindex="120">
          </div>

          <div class="mb-3">
            <label class="mb-0" for="glassExpense">Glass Expense</label>
            <input type="text" id="glassExpense" [ngModel]="supplementalData.glassPaymentAmount | number:'1.2-2'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="supplementalData.glassPaymentAmount=roundNumber($event)" tabindex="130">
          </div>

          <div class="mb-3">
            <label class="mb-0" for="policeReport">Police Report / Other</label>
            <input type="text" id="policeReport" [ngModel]="supplementalData.policeReportExpenseAmount | number:'1.2-2'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="supplementalData.policeReportExpenseAmount=roundNumber($event)" tabindex="140">
          </div>

          <div class="mb-3">
            <label class="mb-0" for="repairAmountPaidByMerchants">Repair Amount Paid by Merchants</label>
            <input type="number" id="repairAmountPaidByMerchants" [ngModel]="supplementalData.merchantsPaidAmount | number:'1.2-2'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="supplementalData.merchantsPaidAmount=roundNumber($event)" tabindex="150">
          </div>

          <div class="mb-3">
            <label class="mb-0" for="miscellaneousExpense">Miscellaneous</label>
            <input type="text" id="miscellaneousExpense" [ngModel]="supplementalData.miscExpenseAmount | number:'1.2-2'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="supplementalData.miscExpenseAmount=roundNumber($event)" tabindex="160">
          </div>

        </div>

        <div class="col-md-4">
          <h4 class="h4-responsive font-weight-bold pb-2">Amounts Received</h4>

          <div class="mb-3">
            <label class="mb-0" for="insuranceProceedsReceived">Insurance Proceeds Received</label>
            <input type="text" id="insuranceProceedsReceived" [ngModel]="supplementalData.receivedAmount | number:'1.2-2'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="supplementalData.receivedAmount=roundNumber($event)" tabindex="200">
          </div>

          <div class="mb-3">
            <label class="mb-0" for="deductibleReceived">Deductible Received</label>
            <input type="text" id="deductibleReceived" [ngModel]="supplementalData.deductibleReceivedAmount | number:'1.2-2'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="supplementalData.deductibleReceivedAmount=roundNumber($event)" tabindex="210">
          </div>

          <div class="mb-3">
            <label class="mb-0" for="rentalReimbursement">Rental Reimbursement</label>
            <input type="text" id="rentalReimbursement" [ngModel]="supplementalData.rentalReimbursementAmount | number:'1.2-2'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="supplementalData.rentalReimbursementAmount=roundNumber($event)" tabindex="220">
          </div>

          <div class="mb-3">
            <label class="mb-0" for="salvageAmount">Salvage Amount</label>
            <input type="text" id="salvageAmount" [ngModel]="supplementalData.salvageAmount | number:'1.2-2'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="supplementalData.salvageAmount=roundNumber($event)" tabindex="230">
          </div>

          <div class="mb-3">
            <label class="mb-0" for="miscellaneousReceived">Miscellaneous</label>
            <input type="text" id="miscellaneousReceived" [ngModel]="supplementalData.miscReceivedAmount | number:'1.2-2'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="supplementalData.miscReceivedAmount=roundNumber($event)" tabindex="240">
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-md-4">

        </div>

        <div class="col-md-4">
          <p class="mb-0">Merchants Paid Expenses: <strong>{{expenseSum | currency:'USD':'$':'1.2-2' | negativeToParensPipe}}</strong></p>
        </div>

        <div class="col-md-4">
          <p class="mb-0">Merchants Received Amount: <strong>{{receivedSum | currency:'USD':'$':'1.2-2' | negativeToParensPipe}}</strong></p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 pull-right text-right">
          <button class="btn btn-default" (click)="update()">Update</button>
        </div>
      </div>
    </div>
  </div>
</div>
