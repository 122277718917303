import { Component, OnInit } from '@angular/core';
import { DHPMVRReportService } from '../dhp-mvr-report-service.component';
import { MVRReport, MedicalCertificate} from '../../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'dhp-mvr-report-medical-cert',
  templateUrl: './dhp-mvr-report-medical-cert.component.html',
  styleUrls: ['../../dhp-mvr-report/dhp-mvr-report.component.css']
})
export class DhpMvrReportMedicalCertComponent implements OnInit {
  mvrReport: MVRReport;
  public medicalCertificateTableConfig: any;

  constructor(private readonly datePipe: DatePipe,
    private readonly dhpMVRReportService: DHPMVRReportService) { }
    gtOptions: any = {};

  ngOnInit(): void {
    this.dhpMVRReportService.sharedMvrReport.subscribe(mvrData => { 
      if(mvrData != null ) {
        this.mvrReport = mvrData;
        const medCertArray = (this.mvrReport.medicalCertificate != null) ?  [this.mvrReport.medicalCertificate ] : [];
        this.setMedicalCertTableConfig(medCertArray);
      }
    });
  }

  private setMedicalCertTableConfig(medicalRecords: Array<MedicalCertificate>) {
    const columns = [
      { name: "Expiration Date", prop: "expirationDate", title: "Expiration Date", visible: true, dataType: "date", columnClass: "width15"  },
      { name: "Status", prop: "status", title: "Status", visible: true, dataType: null, columnClass: ""  },
      { name: "Self Cert. Type", prop: "selfCertificationType", title: "Self Cert. Type", visible: true, dataType: null, columnClass: ""  },      
      { name: "Issue Date", prop: "issueDate", title: "Issue Date", visible: true, dataType: "date", columnClass: ""  },
      { name: "Restrictions", prop: "restrictions", title: "Restrictions", visible: true, dataType: null, columnClass: ""  },
      { name: "Examiner First Name", prop: "examinerFirstName", title: "Examiner First Name", visible: true, dataType: null, columnClass: ""  },
      { name: "Examiner Last Name", prop: "examinerLastName", title: "Examiner Last Name", visible: true, dataType: null, columnClass: ""  },
      { name: "Examiner Number", prop: "examinerLicenseNumber", title: "Examiner Number", visible: true, dataType: null, columnClass: ""  },                  
      { name: "Comments", prop: "additionalComments", title: "Comments", visible: true, dataType: "number", columnClass: "" }
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    const fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s.dataType,
        hidden: !s.visible,
        columnClass: (row, col) => {
          return s.columnClass;
        },
        render: row => {
          if (s.dataType === "date") {
            return this.datePipe.transform(row[s.prop], 'MM/dd/yyyy');
          } else {
            return row[s.prop];
          }
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.medicalCertificateTableConfig = {
      settings: settings,
      fields: fields,
      data: medicalRecords,
      noRecordsLabel: 'No Medical Cert Records'
    }
    this.gtOptions = (medicalRecords.length > 1 ? { numberOfRows: medicalRecords.length } : {});
  }


}
