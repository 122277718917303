import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { AccidentClaimSubroApprovalDetail, ApprovalResponseLookup, AccidentClaimSubroNetRecoveryInfo, AccidentClaimSubroStatus, AccidentClaimSubroApprovalHistory, UserRightsInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";

@Component({
  selector: 'app-accident-claim-subro-approval',
  templateUrl: './accident-claim-subro-approval.component.html',
})
export class AccidentClaimSubroApprovalComponent implements OnInit, OnDestroy {
  @Input() userRights: Array<UserRightsInfo>;
  _claimId: number;
  _routeSub: Subscription;
  _subroStatusSubscription: Subscription;
  _subroNetRecoverySubscription: Subscription;

  approvalDetail: AccidentClaimSubroApprovalDetail;
  approvalHistory: Array<AccidentClaimSubroApprovalHistory>;
  approvalResponses: Array<ApprovalResponseLookup>;
  subroNetRecoveryInfo: Array<AccidentClaimSubroNetRecoveryInfo>;
  subroStatuses: Array<AccidentClaimSubroStatus>;

  clientResponseId: number;
  comments: string;


  constructor(private readonly _route: ActivatedRoute,
    private readonly router: Router,
    private readonly datepipe: DatePipe,
    private readonly accidentClaimService: AccidentClaimService,
    private readonly errorService: ErrorModalService
  ) {

  }

  printPage(): void {
    window.print();
  }

  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];

      this.accidentClaimService.getSubroApprovalDetail(this._claimId).subscribe({
        next: (data) => {
          this.approvalDetail = data as AccidentClaimSubroApprovalDetail;

          if (this.approvalDetail && this.approvalDetail.clientActionLookupId) {
            this.accidentClaimService.getSubroApprovalResponses(this.approvalDetail.clientActionLookupId).subscribe({
              next: (lookup) => {
                this.approvalResponses = lookup as Array<ApprovalResponseLookup>;
              },
              error: (err: HttpErrorResponse) => {
                console.log(err.error.toString());
              }
            });
          }
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });

      this.accidentClaimService.getSubroApprovalHistory(this._claimId).subscribe({
        next: (data) => {
          this.approvalHistory = data as Array<AccidentClaimSubroApprovalHistory>;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });
    });

    this._subroNetRecoverySubscription = this.accidentClaimService.subroNetRecovery$.subscribe(data => {
      this.subroNetRecoveryInfo = data as Array<AccidentClaimSubroNetRecoveryInfo>;
    });

    this._subroStatusSubscription = this.accidentClaimService.subroStatuses$.subscribe(data => {
      this.subroStatuses = data as Array<AccidentClaimSubroStatus>;
    });
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
    this._subroNetRecoverySubscription.unsubscribe();
    this._subroStatusSubscription.unsubscribe();
  }


  public submitClientApproval(): void {
    if (this.clientResponseId === undefined) {
      this.errorService.setErrorObject({ message: 'Client Response is required' });
      return;
    }

    if (this.approvalResponses.filter(x => x.responseLookupId === this.clientResponseId)[0].isCommentMandatory && (this.comments === undefined || this.comments === '')) {
      this.errorService.setErrorObject({ message: 'Comments are required' });
      return;
    }

    this.accidentClaimService.updateSubroClientApproval(this.approvalDetail.subroClientApprovalId, this._claimId,
      this.clientResponseId, this.approvalDetail.subroRepUserId, this.comments).subscribe({
        next: (data) => {
          this.router.navigate(['/accident/claim/' + this._claimId]);
        },
        error: (err: HttpErrorResponse) => {
          if (err.error) {
            this.errorService.setErrorObject(err.error);
          }
        }
      });
  }

  public resetClientApproval(): void {
    this.clientResponseId = undefined;
    this.comments = undefined;
  }

  public getMailToLink(data: AccidentClaimSubroApprovalDetail): string {
    let mt: string = '';
    if (data) {
      let dt = new Date(data.dateOfLoss);
      mt += ('mailto:' + data.subroRepEmailAddress);
      mt += ('?subject=Claim: ' + data.claimId + ', DOL: ' + this.datepipe.transform(dt, 'M/d/yyyy') + ', Client: ' + data.client);
    }
    return mt;
  }
}
