<ng-container *ngIf="dataGridFilter">
  <div *ngIf="filterInfoLoading" class="row">
    <div class="col-sm-12">Loading...</div>
  </div>
  <ng-container *ngIf="!filterInfoLoading">
    <div class="row">
      <div class="col-sm-12">
        <div class="md-form">
          <input id="dataGridFilterTitle" type="text" [(ngModel)]="dataGridFilter.displayName" class="form-control"
            placeholder="Display Name" />
          <label for="dataGridFilterTitle"
            [ngClass]="{ 'label-validation-error': objFilterValidationFail['displayName'], 'active':true }">Display
            Name</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="md-form">
          <input id="dataGridFilterSPInputParam" type="text" [(ngModel)]="dataGridFilter.dataGridSpInputParameter"
            class="form-control" placeholder="Stored Procedure Parameter" />
          <label for="dataGridFilterSPInputParam"
            [ngClass]="{ 'label-validation-error': objFilterValidationFail['dataGridSpInputParameter'], 'active':true }">Stored
            Procedure Parameter</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label [ngClass]="{ 'label-validation-error': objFilterValidationFail['filterOrderString'], 'active':true }">Order</label>
        <mat-form-field appearance="fill" class="select-container w-100">
          <mat-label>Specify the order index for this filter among the other filters</mat-label>
          <mat-select [(value)]="dataGridFilter.filterOrderString" placeholder="Specify the order index for this filter among the other filters">
            <mat-option *ngFor="let option of filterOrderChoices" [value]="option.value">
              {{option.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label [ngClass]="{ 'label-validation-error': objFilterValidationFail['dataGridFilterTypeIdString'], 'active':true }">Type</label>
        <mat-form-field appearance="fill" class="select-container w-100">
          <mat-label>Specify the filter type</mat-label>
          <mat-select [(value)]="dataGridFilter.dataGridFilterTypeIdString">
            <mat-option *ngFor="let option of filterTypeChoices" [value]="option.value">
              {{option.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <ng-container *ngIf="(dataGridFilter.dataGridFilterTypeIdString=='1')">
      <div class="row select-cell-margin">
        <div class="col-sm-12">
          <label [ngClass]="{ 'label-validation-error': objFilterValidationFail['dataGridFilterRangeTypeIdString'], 'active':true }">Range Type</label>
          <mat-form-field appearance="fill" class="select-container w-100">
            <mat-label>Specify the date range type</mat-label>
            <mat-select [(value)]="dataGridFilter.dataGridFilterRangeTypeIdString">
              <mat-option *ngFor="let option of filterDateRangeChoices" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="(dataGridFilter.dataGridFilterTypeIdString=='2')||(dataGridFilter.dataGridFilterTypeIdString=='5')||(dataGridFilter.dataGridFilterTypeIdString=='8')">
      <div *ngIf="dataGridFilter.dataGridFilterTypeIdString=='8'" class="row select-cell-margin">
        <div class="col-sm-12">
          <mat-form-field appearance="fill" class="select-container w-100">
            <mat-label>Specify dependent filter for the select list</mat-label>
            <mat-select [(value)]="dataGridFilter.dataGridFilterIdDependentOnIdString">
              <mat-option *ngFor="let option of reportFilters" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <label
            [ngClass]="{ 'label-validation-error': objFilterValidationFail['dataGridFilterIdDependentOnIdString'], 'active':true }">Dependent
            Filter</label>
        </div>
      </div>

      <div class="row select-cell-margin">
        <div class="col-sm-12">
          <div class="md-form">
            <input id="dataGridFilterDataSource" type="text" [(ngModel)]="dataGridFilter.dataSourceSql"
              class="form-control" placeholder="Data Source" />
            <label for="dataGridFilterDataSource"
              [ngClass]="{ 'label-validation-error': objFilterValidationFail['dataSourceSql'], 'active':true }">Data
              Source</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="md-form">
            <input id="dataGridFilterDisplayField" type="text" [(ngModel)]="dataGridFilter.textField"
              class="form-control" placeholder="Display Field" />
            <label for="dataGridFilterDisplayField"
              [ngClass]="{ 'label-validation-error': objFilterValidationFail['textField'], 'active':true }">Display
              Field</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="md-form">
            <input id="dataGridFilterValueField" type="text" [(ngModel)]="dataGridFilter.valueField"
              class="form-control" placeholder="Value Field" />
            <label for="dataGridFilterValueField"
              [ngClass]="{ 'label-validation-error': objFilterValidationFail['valueField'], 'active':true }">Value
              Field</label>
          </div>
        </div>
      </div>
      <div class="row select-cell-margin">
        <div class="col-sm-2">
          <div class="switch">
            <label>
              <input [checked]="dataGridFilter.isMultiSelect"
                (change)="dataGridFilter.isMultiSelect=!dataGridFilter.isMultiSelect" type="checkbox">
              <span class="lever enableWidgetSwitch"></span>
              <span class="enableWidgetLabel">{{dataGridFilter.isMultiSelect?"Allow":"Disallow"}}</span>
            </label>
          </div>
        </div>
        <div class="col-sm-10">
          <div class="row">
            <div class="col-sm-12">
              <span class="widgetLabelBold">Allow Multiple Selections</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <span class="widgetLabelSmaller"></span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" aria-label="Close" (click)="closeModal()" matRipple >Close</button>
    <button type="button" class="btn btn-default" style="margin-bottom:6px" aria-label="Save" matRipple  (click)="saveDataGridFilter()">Save changes</button>
  </div>
</ng-container>