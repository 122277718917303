//Angular
import { Component, OnInit, OnDestroy, Inject, Input, ViewChild, AfterViewInit } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
//Third Party
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
//APP
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'dhp-edit-training-due-date',
  templateUrl: './dhp-edit-training-due-date.component.html',
  styleUrls: ['./dhp-edit-training-due-date.component.scss'],
})
export class DHPEditTrainingDueDateComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dueDatePicker') dueDatePicker: MatDatepicker<Date>;
  @Input() modalHeight: string;
  @Input() modalInput: any;
  trainingAssignmentId: string;
  newDueDate: Date;
  baseUrl: string;
  reportSelection: null;
  dirty: boolean = false;
  followUpDate: string;
  chkEmailFollowup: boolean = false;
  formValidated: boolean = true;
  comments: string;
  dueDateType: string;
  actualDate = new Date();

  // set default due date control value to today, in order to avoid change detector error messages.
  // actual initial value is set in ngOnInit()
  dueDateControl = new UntypedFormControl(this.datepipe.transform(new Date(), 'MM/dd/yyyy'), [Validators.required]);

  allControls: Array<UntypedFormControl> = [
    this.dueDateControl
  ];

  // the overloaded constructor for the controller
  constructor(private router: Router,
    private dhpService: DriverHistoryProfileService,
    private onPremService: OnPremDriverService,
    private datepipe: DatePipe,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private snackBar: MatSnackBar,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService) { }

  // angular on intialization event
  ngOnInit() {
    this.newDueDate = new Date(this.modalInput.trainingData.dueDate);
    this.trainingAssignmentId = this.modalInput.trainingData.assignmentId;
    this.dueDateType = this.modalInput.dueDateType;
  }

  ngAfterViewInit(): void {
    this.dueDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.dueDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
          this.dueDatePicker.select(event.value);
          this.dueDatePicker.close();
        })
      }, 0)
    })
  }

  // provides a central point for handling errors
  handleError(message: string) {

  }

  // prints the page
  printPage(): void {
    window.print();
  }

  // validates whether the user supplied a note and then makes the submit button available
  // if they did
  onKey(event: any) {
    if (event.target.value != null && event.target.value.length > 0) {
      this.formValidated = true;
    }
    else {
      this.formValidated = false;
    }
  }

  private validateForm(): boolean {
    let isOK: boolean = true;

    if (this.allControls.filter(c => (c.invalid)).length > 0) {
      this.errorService.setErrorObject({ message: 'Missing or Invalid Fields.' });
      isOK = false;
    }

    return isOK;
  }

  submit() {
    //console.log(this.pointsAssignment);

    if (this.validateForm()) {
      let newDueDateISO = (new Date(this.newDueDate)).toISOString().slice(0, 10);
      let endpoint = 'training/dueDate/' + this.trainingAssignmentId + '/' + newDueDateISO;

      if (endpoint.length > 0) {
        this.loadingSpinnerService.show();

        this.onPremService.put(endpoint,
          null
        ).subscribe({
          next: (data) => {
            // signal that driver profile data needs to be refreshed
            switch (this.dueDateType) {
              case 'editTrainingDueDate':
                // refresh Incomplete Trianing data
                this.dhpService.setRefreshDriverProfileIncompleteTrainingData(this.modalInput.driverId);
                this.loadingSpinnerService.hide();
                this.showMessage("Edit Training Due Date Completed.");
                break;
              case 'editHPEAssignmentDueDate':
                // refresh HPE Assignment Data
                this.dhpService.setRefreshDriverProfileHPEAssignmentData(this.modalInput.driverId);
                this.loadingSpinnerService.hide();
                this.showMessage("Edit HPE Assignment Due Date Completed.");
                break;
            }
            // notify the modal window to close
            this.dhpService.notifyModalClose();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err.error);
            this.loadingSpinnerService.hide();
          }
        });
      }
    }
    //this.dhpAssignPointsService.setDriverPoints();
  }

  // removes the follow-up date
  cancel() {
    // notify the modal window to close
    this.dhpService.notifyModalClose();
  }

  // sleeps for a certain number of milliseconds
  sleep(milliseconds: number) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }

  // the default destructor
  ngOnDestroy() {
  }

  private showMessage(message: string): void {
    this.snackBar.open(message, 'Ok', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: 'success-snackbar'
    });
  }
}
