import { Injectable, OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { LineOfBusinessInfo } from '../classes-and-interfaces/classes-and-interfaces.component';
import { LineOfBusinessService } from '../line-of-business-service/line-of-business-service.component';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html'
})
export class AuthCallbackComponent implements OnInit {

  private lineOfBusinessChoices: LineOfBusinessInfo[];

  constructor(private authService: AuthService,
    private readonly router: Router,
    private readonly lineOfBusinessService: LineOfBusinessService) {
  }

  ngOnInit(): void {
    this.authService.completeAuthentication().then(() => {
      this.lineOfBusinessService.getLineOfBusinessChoices()
      .subscribe(data => {
        this.lineOfBusinessChoices = data;
        if(data.length > 0) {
          try {
            if (sessionStorage["authRedirectUrl"] && sessionStorage["authRedirectUrl"].length > 0 && !(sessionStorage["authRedirectUrl"] as string).startsWith("callback")) {
              this.nonCallBackRedirect();
            }
            else
            {
              this.defaultRedirect();
            }
          }
            catch 
          { 
            this.defaultRedirect();
          } 
        }
      });
    }).catch(err => {
      console.log(err);
      this.defaultRedirect();
    }); 
  }

  private defaultRedirect() {
    if(this.lineOfBusinessChoices?.length > 0 && this.lineOfBusinessChoices[0]?.lineOfBusinessId === 6) 
    {
      this.router.navigate(['/custom', 6]);
    } else {
      this.router.navigate(['/home']);
    }
  }
  
  private nonCallBackRedirect() {
    if(this.lineOfBusinessChoices?.length > 0 && this.lineOfBusinessChoices[0]?.lineOfBusinessId === 6) 
    {
      sessionStorage.removeItem("authRedirectUrl");
      this.router.navigate(['/custom', 6]);
    } 
    else 
    {
      let redirectUrl = sessionStorage["authRedirectUrl"];
      sessionStorage.removeItem("authRedirectUrl");
      this.router.navigate([redirectUrl])
    }
  }
}
