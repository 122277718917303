<div>
  <quick-search-menu></quick-search-menu>
</div>

<div class="row" *ngIf="driverProfile">
  <div class="col-md-8">
    <div class="row">
      <div class="col-md-12"
           style="font-size:20px;font-weight:500;color:#44474b;"
           valign="middle">
        <label>{{driverProfile?.fullName}}</label>
      </div>
    </div>
    <div class="row" style="padding-bottom:10px" *ngIf="driverProfile">
      <div class="col-md-12" style="font-size:14px;font-weight:500;color:#44474b;" valign="top">
        {{driverProfile?.fullHierarchy | stringReplaceCharsPipe:'|':' -> '}}
      </div>
    </div>
  </div>
  <div class="col-md-4" style="padding-left:0;padding-right:3rem;">
    <li *ngFor="let actionCat of actionMenuObjectArray"
        [class]="'nav-item dropdown btn-group actionDropdownButton'+actionCat.catId"
        dropdown>
      <button [matMenuTriggerFor]="menu" class="btn btn-secondary dropdown-toggle" type="button" aria-haspopup="true" aria-expanded="false">
        {{actionCat.label}}
      </button>
      <mat-menu #menu="matMenu">
        <button *ngFor="let actionItem of actionCat.items"
                class="dropdown-item"
                type="button"
                (click)="actionMenuItemClick(actionItem.id)">
          {{actionItem.description}}
        </button>
      </mat-menu>
    </li>
  </div>
</div>

<div class="row" *ngIf="driverProfile&&(!driverProfile.isEmployee)&&(driverProfile.primaryDriverDetails)">
  <div class="col-md-12">
    <app-safety-primary-driver-link [primaryDriverId]="driverProfile.primaryDriverId" [primaryDriverDetails]="driverProfile.primaryDriverDetails"></app-safety-primary-driver-link>
  </div>
</div>
<div class="row" *ngIf="driverProfile">
  <div class="col-md-12">
    <div class="row driverSummaryPanel">
      <div [class]="driverProfile.isMvrTaskPending||driverProfile.isLicenseVerificationPending?'col-md-4':'col-md-6'" style="padding:1rem">
        <div class="row">
          <div class="col-md-12" style="font-weight:bold">
            DRIVER INFO
          </div>
        </div>
        <div class="row" *ngIf="driverProfile.employeeId&&(driverProfile.employeeId.length > 0)">
          <div class="col-md-12">
            <span class="readOnlyFormLayoutLabel">Employee ID: </span>
            <span class="readOnlyFormLayoutBold">{{driverProfile?.employeeId != '' ? driverProfile?.employeeId : 'Not Available'}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span class="readOnlyFormLayoutLabel">License #: </span>
            <span class="readOnlyFormLayoutBold">{{((driverProfile?.driverLicenseNumber&&(driverProfile?.driverLicenseNumber.length > 0)) ? driverProfile?.driverLicenseNumber +(driverProfile?.driverLicenseState != '' ? ' (' + driverProfile?.driverLicenseState + ')': '') : 'Not Available')}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span class="readOnlyFormLayoutLabel">Date of Birth: </span>
            <span *ngIf="canViewDLDOB&&(driverProfile?.dateOfBirth != '')" class="readOnlyFormLayoutBold">
              {{driverProfile?.dateOfBirth |  date:'MM/dd/yyyy'}}
            </span>
            <span *ngIf="canViewDLDOB&&(driverProfile?.dateOfBirth == '')" class="readOnlyFormLayoutBold">
              Not Available
            </span>
          </div>
        </div>
      </div>
      <div [class]="driverProfile.isMvrTaskPending||driverProfile.isLicenseVerificationPending?'col-md-4':'col-md-6'" style="padding:1rem">
        <div class="row">
          <div class="col-md-12" style="font-weight:bold">
            PROFILE STATUS
          </div>
        </div>
        <div class="row" *ngIf="driverProfile.riskLevel">
          <div class="col-md-12">
            <span class="readOnlyFormLayoutLabel">Risk Level: </span>
            <span class="readOnlyFormLayoutBold" [ngStyle]="{'color': driverProfile.riskLevelColor}">{{driverProfile?.riskLevel}}</span>
          </div>
        </div>
        <div class="row" *ngIf="driverProfile.driverLicenseStatus">
          <div class="col-md-12">
            <span class="readOnlyFormLayoutLabel">MVR Status: </span>
            <span class="readOnlyFormLayoutBold" style="color:red">{{driverProfile?.driverLicenseStatus}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span class="readOnlyFormLayoutLabel">MVR Type: </span>
            <span class="readOnlyFormLayoutBold">{{ driverProfile?.isEnrolledInMonitoring ? driverProfile?.monitoringProgramStatus : 'NOT MONITORED' }}</span>
          </div>
        </div>
        <div class="row" *ngIf="driverProfile?.isEnrolledInMonitoring">
          <div class="col-md-12">
            <span class="readOnlyFormLayoutLabel">Enrolled Date: </span>
            <span class="readOnlyFormLayoutBold">{{ driverProfile?.monitoringStartDate!=null ? (driverProfile?.monitoringStartDate | date:'MM/dd/yyyy') : 'N/A'  }}</span>
          </div>
        </div>
        <div class="row" *ngIf="driverProfile?.isEnrolledInMonitoring">
          <div class="col-md-12">
            <span class="readOnlyFormLayoutLabel">Last Review Date: </span>
            <span class="readOnlyFormLayoutBold">{{ driverProfile?.monitoringLastUpdateDate!=null ? (driverProfile?.monitoringLastUpdateDate | date:'MM/dd/yyyy') : 'N/A' }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="driverProfile?.isMvrTaskPending||driverProfile?.isLicenseVerificationPending" style="padding:1rem">
        <div class="row">
          <div class="col-md-12" style="font-weight:bold">
            PENDING TASKS
          </div>
        </div>
        <div class="row" *ngIf="driverProfile?.mvrTaskStatus">
          <div class="col-md-12" [ngStyle]="{'color': driverProfile?.mvrTaskStatusColor}">
            {{driverProfile?.mvrTaskStatus}}
          </div>
        </div>
        <div class="row" *ngIf="driverProfile?.licenseVerificationStatus">
          <div class="col-md-12" [ngStyle]="{'color': driverProfile?.licenseVerificationStatusColor}">
            {{driverProfile?.licenseVerificationStatus}}
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="row">
  <div class="col-md-12">
    <ng-container>
      <mat-tab-group *ngIf="tabs && (tabs.length>0) && driverProfile" #dhpTabs class="classic-tabs">
        <mat-tab *ngFor="let tab of tabs | showdptabspipe" [customClass]="tab.display?'':'hideDriverProfileTab'">
          <ng-template mat-tab-label>
            <span>{{tab.name}}</span> <div *ngIf="tab.icon" class="ml-2" [innerHTML]="tab.icon"></div>
        </ng-template>
          <safety-risk-profile *ngIf="tab.name=='Risk Profile'"
          [driverProfile]="driverProfile"
          [riskHistory]="driverProfile.riskHistory"
          [riskClaimHistory]="driverProfile.riskClaimHistory"
          [currentMvr]="driverProfile.currentMvr"
          [auxiliaryPointHistory]="driverProfile.auxiliaryPointHistory"
          [clientSettings]="clientSettings"
          [_userRights]="_userRights">
          </safety-risk-profile>
          <safety-mvr *ngIf="tab.name=='MVR Records'"
           [driverProfile]="driverProfile"
           [currentMvr]="driverProfile.currentMvr"
           [mvrRecords]="driverProfile.mvrHistory"
           [clientSettings]="clientSettings"
           [_userRights]="_userRights"
           [fullProfile]="true">
          </safety-mvr>
          <safety-training *ngIf="tab.name=='Training'"
            [driverProfile]="driverProfile"
            [completedTraining]="driverProfile.completedTraining"
            [incompleteTraining]="driverProfile.incompleteTraining"
            [hasExternalTraining]="clientSettings.hasExternalTraining"
            [externalTraining]="driverProfile.externalTraining"
            [hpeAssignments]="driverProfile.hpeAssignments || []"
            [hpeCoreCompetencies]="driverProfile.hpeCoreCompetencies || []"
            [hpeSubLessons]="driverProfile.hpeSubLessons || []"
            [MotorMindsAssignments]="driverProfile.MotorMindsAssignments || []"
            [_userRights]="_userRights">
          </safety-training>
          <safety-driver-info *ngIf="tab.name=='Driver Info'"
            [driverProfile]="driverProfile"
            [extendedInfo]="extendedInfo"
            [_userRights]="_userRights"
            [clientSettings]="clientSettings"
            [fullProfile]="true">
          </safety-driver-info>
          <safety-dq-files *ngIf="tab.name=='Driver Qualification'"
            [driverProfile]="driverProfile"
            [driverQualifications]="driverProfile.driverQualifications||[]"
            [driverEmploymentHistory]="driverProfile.driverEmploymentHistory||[]"
            [_userRights]="_userRights">
          </safety-dq-files>
          <safety-telematics *ngIf="tab.name=='Telematics'"
            [driverProfile]="driverProfile"
            [driverTelematics]="driverProfile.driverTelematics||[]"
            [_userRights]="_userRights">
          </safety-telematics>
          <safety-notes-docs *ngIf="tab.name=='Notes and Docs'"
            [driverProfile]="driverProfile"
            [driverNotes]="driverProfile.driverNotes||[]"
            [driverDocuments]="driverProfile.driverDocuments||[]"
            [_userRights]="_userRights">
          </safety-notes-docs>
          <safety-non-employees *ngIf="tab.name=='Non-Employees'"
            [driverProfile]="driverProfile"
            [secondaryDrivers]="driverProfile.secondaryDrivers||[]"
            [arrReqFields]="arrReqFields||[]"
            [_userRights]="_userRights"
            [allowMultipleNonEmployees]="clientSettings.allowMultipleNonEmployees"
            [nonEmployeeProfiling]="clientSettings.nonEmployeeProfiling"
            [clientSettings]="clientSettings">
          </safety-non-employees>
          <safety-auto-coverage *ngIf="tab.name=='Auto Coverage'"
            [driverProfile]="driverProfile"
            [driverAutoCoverage]="driverProfile.driverAutoCoverage||[]"
            [_userRights]="_userRights">
          </safety-auto-coverage>           
          <safety-driver-pending-tasks *ngIf="tab.name=='Pending Tasks'"
            [driverProfile]="driverProfile"
            [pendingTasks]="driverProfile.pendingTasks||[]"
            [_userRights]="_userRights">
          </safety-driver-pending-tasks>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </div>
</div>

