<div class="row" *ngIf="driverCOV">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Certificate of Violations</h4>
          <app-loading-panel *ngIf="!driverProfile?.driverQualificationsLoaded"></app-loading-panel>
          <div class="row">
            <div class="col-sm-3 accidentClaimTabLabel">
              Assigned Date:
            </div>
            <div class="col-sm-2 accidentClaimTabBold">
              {{driverCOV?.assignedDate}}
            </div>            
          </div>
          <div class="row" *ngIf="driverCOV">
            <div class="col-sm-3 accidentClaimTabLabel">
              Manager Completed Date:
            </div>
            <div class="col-sm-2 accidentClaimTabBold">
              {{driverCOV?.processCompletedDate}}
            </div>
          </div>
          <div class="row" *ngIf="driverCOV">
            <div class="col-sm-12 accidentClaimTabBold" [ngStyle]="{
                  'color': driverCOV?.covStatusColor,
                  'text-align': 'center',
                  'padding-top': '1rem'}">
              <h4>{{driverCOV?.covStatus}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">DQ File Management</h4>
          <app-loading-panel *ngIf="!driverQualTableConfig"></app-loading-panel>
          <div *ngIf="driverQualTableConfig">
            <app-data-table 
              [columns]="driverQualTableConfig.fields"
              [data]="driverQualTableConfig.data"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Driving History Review</h4>
          <app-loading-panel *ngIf="!driverHistoryReviewTableConfig"></app-loading-panel>
          <div *ngIf="driverHistoryReviewTableConfig">
            <app-data-table 
              [columns]="driverHistoryReviewTableConfig.fields"
              [data]="driverHistoryReviewTableConfig.data"
              noRecordsLabel="No Driving History Review information"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
