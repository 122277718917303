<div class="backdrop" [ngStyle]="{'display':display}" *ngIf="showModal"></div>
<div class="modal" tabindex="-1" role="dialog" *ngIf="showModal" [ngStyle]="{'display':display}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">{{newsItem.dialogTitle}}</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <h4>{{newsItem.title}}​</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" [innerHTML]="newsItem.newsText | safe">
               
              </div>
            </div>
          </div>
          </div><div class="row">
          <div class="col-md-12">

            <button type="button" style="float:right"
                    (click)="dismissModal()"
                    class="btn btn-primary">
              <!--Dismiss-->
              Don't show this again
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div class="row" *ngIf="showModal">
  <div class="col-md-12 ieWarningPanel card card-body mt-3">
    <div class="row">
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-12">
            <h4>{{newsItem.dialogTitle}}​</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            {{newsItem.newsText}}
          </div>
        </div>
      </div>
      <div class="col-md-3">

        <button type="button"
                (click)="dismissModal()"
                class="btn btn-primary">
          Dismiss
        </button>
      </div>
    </div>

  </div>

</div>-->
