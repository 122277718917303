import { Component, Inject, Input, OnInit, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { MvrExportDriverInfo } from '../mvr-export/mvr-export.component';
import { DatePipe } from '@angular/common';
import { DataTableComponent } from '../../components/data-table/data-table.component';
import { BehaviorSubject, debounceTime, Observable, tap } from 'rxjs';

@Component({
  selector: 'app-export-driver-select',
  templateUrl: './export-driver-select.component.html',
  styleUrls: ['./export-driver-select.component.css']
})
export class ExportDriverSelectComponent implements OnInit, AfterViewInit {
  @Input() showMVRDate: boolean;
  @Input() showLastMVRDate: boolean;
  @Input() showLastDVDate: boolean;
  @Input() showRiskLevel: boolean;

  @Output() onUpdateDriversSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUpdateDriversExcluded: EventEmitter<any> = new EventEmitter<any>();

  // DHP Next Gen
  @Output() selectedMvrDriverInfo = new EventEmitter<MvrExportDriverInfo[]>();

  @ViewChild('driverTable') driverTable: DataTableComponent;
  @ViewChild('driverExcludedTable') driverExcludedTable: DataTableComponent;

  baseUrl: string;
  gtOptions: any = {};
  gtOptions2: any = {};
  public driverTableConfig: any;
  public driverExcludedTableConfig: any;
  arrDriverIdInclude: Array<number> = [];
  arrDriverIdExclude: Array<number> = [];

  private readonly _arrDriverSubject = new BehaviorSubject<MvrExportDriverInfo[]>([]);
  arrDriver$: Observable<MvrExportDriverInfo[]> = this._arrDriverSubject.asObservable();

  @Input()
  set arrDriver(arrDriver: Array<MvrExportDriverInfo>) {
    this._arrDriverSubject.next(arrDriver);
  };

  constructor(private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private errorService: ErrorModalService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit() {
    this.setTableConfig([]);
    this.setExcludedTableConfig([]);
  }

  ngAfterViewInit(): void {
    this.arrDriver$.pipe(
      debounceTime(500),
      tap((drivers) => {
        const arrDriverFiltered = this.updateExcludedDriverInfo(drivers);
        this.setTableConfig(arrDriverFiltered);
      }),
      debounceTime(500),
      tap((drivers) => {
        this.updateDriverIdArrays();
      })
    ).subscribe();
  }

  private setTableConfig(arrDriver: Array<MvrExportDriverInfo>) {
    let datePipe = new DatePipe("en-US");

    var columns = [
      { name: "include", prop: "include", visible: true, dataType: "button", title: "Exclude" },
      { name: "ID", prop: "driverId", title: "ID", sort: "", visible: false, dataType: null },
      { name: "Driver", prop: "driverName", title: "Driver", sort: "", visible: true, dataType: null },
      { name: "State", prop: "driverLicenseState", title: "State", sort: "", visible: true, dataType: null },
      { name: "Group", prop: "fullHierarchy", title: "Group", sort: "", visible: true, dataType: null },
      { name: "Emp", prop: "employee", title: "Emp", sort: "", visible: true, dataType: null },
      { name: "Last DV Date", prop: "lastLicenseVerificationDate", title: "Last DV Date", sort: "", visible: this.showLastDVDate, dataType: 'date' },
      { name: "Last MVR Date", prop: "lastMvrRequestDate", title: "Last MVR Date", sort: "", visible: this.showLastMVRDate, dataType: 'date' },
      { name: "MVR Date", prop: "mvrDate", title: "MVR Date", sort: "", visible: this.showMVRDate, dataType: 'date' },
      { name: "Risk Level", prop: "riskLevel", title: "Risk Level", sort: "", visible: this.showRiskLevel, dataType: null }
    ];

    var settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: 'enable',
        visible: s.visible
      };
    });

    var fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        hidden: !s.visible,
        sort: s.sort && s.sort === 'enable',
        render: row => {
          let r = row[s.prop];
          if (s.dataType === "date") {
            try {
              if (r) {
                r = datePipe.transform(new Date(r), "MM/dd/yyyy");
              } else {
                r = '';
              }
            } catch (e) { r = '';}
          }
          else if (s.dataType == "button") {
            r = '<a><i class="fa fa-minus-circle"></i></a>';
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }

          return val;
        },
        click: row => {
          this.excludeDriver(row);
        }
        //click: row => {
        //  return this.move(row, false);
        //}
      });
    });

    this.driverTableConfig = {
      settings: settings,
      fields: fields,
      data: arrDriver,
      showPagination: true,
      noRecordsLabel: 'No drivers to select',
      pageSize: 10
    };
  }

  private setExcludedTableConfig(arrDriver: Array<MvrExportDriverInfo>) {
    var columns2 = [
      { name: "ID", prop: "driverId", title: "ID", sort: "", visible: false, dataType: null },
      { name: "Driver", prop: "driverName", title: "Driver", sort: "", visible: true, dataType: null },
      { name: "include", prop: "include", visible: true, dataType: "button", title: "" }
    ];

    var settings2 = columns2.map(function (s) {
      return {
        objectKey: s.prop,
        sort: 'enable',
        visible: s.visible
      };
    });

    var fields2 = new Array<any>();
    columns2.forEach(s => {
      fields2.push({
        name: s.title,
        objectKey: s.prop,
        hidden: !s.visible,
        sort: s.sort && s.sort === 'enable',
        render: row => {
          //return '<button class="btn btn-sm btn-primary ' +
          //  '">Include</button>';
          let r = row[s.prop];
          if (s.dataType == "button") {
            r = '<a><i class="fa fa-plus-circle"></i></a>';
          }
          return r;
        },
        value: row => {
          //return '<button class="btn btn-sm btn-primary ' +
          //  '">Include</button>';
          let r = row[s.prop];
          if (s.dataType == "button") {
            r = '<a><i class="fa fa-plus-circle"></i></a>';
          }
          return r;
        },
        click: row => {
          this.includeDriver(row);
        }
        //value: row => {
        //  var val = row[s.prop];
        //  //if (s.dataType === "date") {
        //  //  // use ISO string as value for date sorting
        //  //  try {
        //  //    val = new Date(val).toISOString();
        //  //  } catch (e) { }
        //  //}
        //  if (s.dataType == "button" || s.name == "Remove" || true) {
        //    val = '<button class="btn btn-sm btn-primary ' +
        //      '"><i class="fa fa-plus-circle"></i></button>';
        //  }
        //  return val;
        //},
      });
    });

    this.driverExcludedTableConfig = {
      settings: settings2,
      fields: fields2,
      data: arrDriver,
      showPagination: true,
      noRecordsLabel: 'Click to select drivers from the table to the right',
      pageSize: 10
    }
  }

  public triggerDriver = function ($event) {
    const value = $event.row || $event.value;
    switch ($event.name) {
      case 'gt-info':
        //console.log($event.value);
        this.updateDriverIdArrays();
        break;
      case 'gt-row-clicked':
      case 'excludeDriver':
        this.excludeDriver(value);
        break;
    }
  };

  public triggerDriverExcluded = function ($event) {
    const value = $event.row || $event.value;
    switch ($event.name) {
      case 'gt-info':
        //console.log($event.value);
        this.updateDriverIdArrays();
        break;
      case 'gt-row-clicked':
      case 'includeDriver':
        //console.log($event.value);
        this.includeDriver(value);
        break;
    }
  };

  private excludeDriver(eventVal: any) {
    //let clickedRow = eventVal.row as MvrExportDriverInfo;
    const clickedRow = eventVal as MvrExportDriverInfo;
    //this.driverTable.gtDelete('driverId', clickedRow.driverId);
    //this.driverExcludedTable.gtAdd([<GtRow>clickedRow]);
    this.driverTable.deleteRow('driverId', clickedRow?.driverId);
    this.driverExcludedTable.addRows([clickedRow]);
    // need to manually clear loading property,
    // since excluded table will initially have no entries
    //this.driverExcludedTable.loading = false;
    this.updateDriverIdArrays();
  }

  private includeDriver(eventVal: any) {
    //let clickedRow = eventVal.row as MvrExportDriverInfo;
    const clickedRow = eventVal as MvrExportDriverInfo;

    //this.driverExcludedTable.gtDelete('driverId', clickedRow.driverId);
    //this.driverTable.gtAdd([<GtRow>clickedRow]);
    this.driverExcludedTable.deleteRow('driverId', clickedRow?.driverId);
    this.driverTable.addRows([clickedRow]);

    this.updateDriverIdArrays();
  }

  excludeAll() {
    const rowsToMove: Array<MvrExportDriverInfo> = this.driverTable?.dataSource?.data as Array<MvrExportDriverInfo>;

    this.driverExcludedTable.addRows(rowsToMove as Array<any>);

    let idsToMove = rowsToMove.map(r => r.driverId);
    for (let i = 0; i < idsToMove.length; i++) {
      this.driverTable.deleteRow('driverId', idsToMove[i]);
    }

    this.updateDriverIdArrays();

    // need to manually clear loading property,
    // since excluded table will initially have no entries
    //this.driverExcludedTable.loading = false;
  }

  includeAll() {
    const rowsToMove: Array<MvrExportDriverInfo> = this.driverExcludedTable?.dataSource?.data as Array<MvrExportDriverInfo>;
    this.driverTable.addRows(rowsToMove as Array<any>);

    let idsToMove = rowsToMove.map(r => r.driverId);
    for (let i = 0; i < idsToMove.length; i++) {
      let movedRow = rowsToMove[i];
      this.driverExcludedTable.deleteRow('driverId', idsToMove[i]);
    }

    this.updateDriverIdArrays();
  }

  private updateDriverIdArrays() {
    // gets arrays of driver ids that are in the selected and excluded driver tables
    if (this.driverTable?.dataSource?.data) {
      this.arrDriverIdInclude = this.driverTable?.dataSource?.data.map(d => d.driverId);
      this.onUpdateDriversSelected.emit(this.arrDriverIdInclude);
    }

    if (this.driverExcludedTable?.dataSource?.data) {
      this.arrDriverIdExclude = this.driverExcludedTable?.dataSource?.data.map(d => d.driverId);
      this.onUpdateDriversExcluded.emit(this.arrDriverIdExclude);
    }

    // DHP Next Gen
    this.selectedMvrDriverInfo.emit(this.driverTable?.dataSource?.data);
  }

  private updateExcludedDriverInfo(arrDriver: Array<MvrExportDriverInfo>): Array<MvrExportDriverInfo> {
    // when driver info gets updated via @Input() set arrDriver,
    // remove entries from excluded driver table that arent in arrDriver,
    // then remove remaining excluded entries from driver table

    let arrDriverFiltered: Array<MvrExportDriverInfo> = [];

    if (this.driverExcludedTable && this.driverExcludedTable?.dataSource?.data) {
      let arrDriverIdInclude = arrDriver.map(d => d.driverId);
      // remove from excluded any driver not in arrDriver
      let arrDriverIdRemoveFromExcluded = this.arrDriverIdExclude.filter(e => !arrDriverIdInclude.includes(e));
      arrDriverIdRemoveFromExcluded.forEach(d => {
        this.driverExcludedTable.deleteRow('driverId', +d);
      });
      // remove from arrDriver any driver still on excluded table
      const arrDriverIdExcluded = this.driverExcludedTable?.dataSource?.data.map(d => d.driverId);
      arrDriverFiltered = arrDriver.filter(e => arrDriverIdExcluded.indexOf(e.driverId) < 0);

    } else {
      arrDriverFiltered = arrDriver;
    }

    return arrDriverFiltered;
  }
}
