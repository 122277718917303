//Angular
import { Component, OnInit, Inject } from '@angular/core';
//Third Party
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
//App
import { DashboardTile } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

@Component({
  selector: 'app-dashboard-tile-zoom',
  templateUrl: './dashboard-tile-zoom.component.html',
  styleUrls: ['./dashboard-tile-zoom.component.scss']
})
export class DashboardTileZoomComponent implements OnInit {
  dashboardTileZoom: DashboardTile = null;
  tileId: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) data: {tileId: string, card: DashboardTile}
  ) { 
    this.dashboardTileZoom = data.card;
    this.tileId = data.tileId;
  }

  ngOnInit(): void {
  }

}
