<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
  <div class="card card-body mt-3">
    <h4 class="card-title text-muted">Current Driver Status</h4>
    <div class="row">
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        Employee:
      </div>
      <div class="col-sm-9 readOnlyFormLayoutBold">
        {{driverBaseInfo?.fullName}}
      </div>

    </div>
    <div class="row">
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        Employee ID:
      </div>
      <div class="col-sm-9 readOnlyFormLayoutBold">
        {{driverBaseInfo?.employeeId}}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        Group:
      </div>
      <div class="col-sm-9 readOnlyFormLayoutBold">
        {{driverBaseInfo?.fullHierarchy | stringReplaceCharsPipe:'|':' -> '}}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        License Status:
      </div>
      <div class="col-sm-9 readOnlyFormLayoutBold">
        {{driverBaseInfo?.driverLicenseStatus}}
      </div>
 
    </div>
    <div class="row">
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        Last MVR:
      </div>
      <div class="col-sm-9 readOnlyFormLayoutBold">
        {{driverBaseInfo?.lastMVR |  date:'MM/dd/yyyy'}}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        State:
      </div>
      <div class="col-sm-9 readOnlyFormLayoutBold">
        {{driverBaseInfo?.driverLicenseState}}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        Start Date:
      </div>
      <div class="col-sm-3 readOnlyFormLayoutBold">
        {{driverBaseInfo?.leaveOfAbsenceStartDate |  date:'MM/dd/yyyy'}}
      </div>
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        Return Date:
      </div>
      <div class="col-sm-3 readOnlyFormLayoutBold">
        {{driverBaseInfo?.leaveOfAbsenceEndDate |  date:'MM/dd/yyyy'}}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        Entered By:
      </div>
      <div class="col-sm-9 readOnlyFormLayoutBold">
        {{driverBaseInfo?.enteredBy}}
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <li class="nav-item dropdown btn-group" style="float:right;margin: 0 0 0 0;" dropdown>
    <button [matMenuTriggerFor]="menu"
            class="btn btn-secondary dropdown-toggle"
            style="margin-right:6px"
            type="button" id="ddDriverStatus"
            aria-haspopup="true"
            aria-expanded="false">
      Update Status
    </button>
    <mat-menu #menu="matMenu">
      <button *ngIf="isActiveLeave"
              class="dropdown-item"
              type="button"
              (click)="editLeave()">
        Edit Leave
      </button>
      <button *ngIf="isActiveLeave" class="dropdown-item" type="button" (click)="endLeave('Return from Leave')">Return from Leave</button>
      <button *ngIf="!isActiveLeave" class="dropdown-item" type="button" (click)="cancelLeave('Cancel Leave')">Cancel Leave</button>
      <button *ngIf="canTerminateDriver" class="dropdown-item" type="button" (click)="terminateDriver()">Terminate Driver</button>
    </mat-menu>
  </li>
  <button class="btn btn-default" style="margin-bottom:6px"
          (click)="cancel()">
    Exit
  </button>
</div>






