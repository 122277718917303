import { Component, Input, OnInit  } from '@angular/core';
import { UserRightsInfo, DriverProfile, DriverExtendedInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

@Component({
  selector: 'safety-non-employee-info',
  templateUrl: './safety-non-employee-info.component.html',
  styleUrls: ['./safety-non-employee-info.component.css']
})

export class SafetyNonEmployeeInfoComponent implements OnInit{
  @Input() _userRights: Array<UserRightsInfo>;
  @Input() driverProfile: DriverProfile;
  @Input() arrReqFields: Array<DriverExtendedInfo>;
  extendedInfo: Array<any>;
  canViewDLDOB: boolean = false;

  constructor() { }

  ngOnInit() {
    this.setDriverExtendedInfo(this.arrReqFields, this.driverProfile);
    this.canViewDLDOB = this.isAccessAllowed(42);
  }

  private setDriverExtendedInfo(arrReqFields: Array<DriverExtendedInfo>, driverProfile: DriverProfile) {
    let arrInfo = [];
    arrReqFields.forEach(e => {
      let fieldVal = driverProfile[e.reqField.toLowerCase()];
      arrInfo.push({ label: e.reqDescription, value: (fieldVal || '') });
    });
    this.extendedInfo = arrInfo;

  }

  // determines if access to the component is allowed
  private isAccessAllowed(userRightsId: number) {
    let retVal: boolean = false;

    if (this._userRights) {
      let thisRight = this._userRights.filter(r => r.userRightId == userRightsId);
      if ((thisRight.length > 0) && (thisRight[0].permission == 'allow')) {
        retVal = true;
      }
    }

    return retVal;
  }
}
