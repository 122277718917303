import { Component, OnInit, OnDestroy, Input, Output, OnChanges, SimpleChanges,Inject, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { SafetyNotesDocsService } from '../../safety/safety-notes-docs/safety-notes-docs-service.component';
import { Subscription } from 'rxjs';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { DriverProfile, UserRightsInfo, DriverNotes, DriverDocuments } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { DriverHistoryProfileService } from '../../safety/dhp-landing-page/dhp-landing-page-service.component';
import { DatePipe } from '@angular/common';
import { SafetyNotesDocsComponent } from '../../safety/safety-notes-docs/safety-notes-docs.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';

/**
 * this is the client-side controller for the safety - notes and documents tab panel
 */
@Component({
  selector: 'dc-driver-notes-docs',
  templateUrl: './dc-driver-notes-docs.component.html',
  styleUrls: ['./dc-driver-notes-docs.component.css']
})

export class DcDriverNotesDocsComponent extends SafetyNotesDocsComponent {

  // the overloaded constructor for the controller
  constructor(protected router: Router,
    protected driverHistoryProfileService: DriverHistoryProfileService,
    protected fileDownloadService: FileDownloadService,
    protected  safetyNotesDocumentService: SafetyNotesDocsService,
    protected  http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    protected  datePipe: DatePipe,
    protected  alertService: AlertService,
    protected  loadingSpinnerService: LoadingSpinnerService,
    protected  errorService: ErrorModalService)
  {
    super(router, driverHistoryProfileService, fileDownloadService, safetyNotesDocumentService, http, baseUrl, datePipe, alertService, loadingSpinnerService, errorService);
  }


}
