import { Component } from '@angular/core';
import { ReactivateDriverSearchModalService } from "../reactivate-driver-search-modal/reactivate-driver-search-modal-service.component";
import { HondaOptOutSearchModalService } from '../honda-opt-out-search-modal/honda-opt-out-search-modal-service.component';

@Component({
  selector: 'app-honda-opt-out-menu-item',
  templateUrl: './honda-opt-out-menu-item.component.html'
})
export class HondaOptOutMenuItemComponent {

  constructor(private reactivateService: HondaOptOutSearchModalService) { }

  showReactivateModal() {
    this.reactivateService.openReactivateDriverSearchModal();
  }
}
