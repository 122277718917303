//Angular
import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { Pipe, PipeTransform, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { DatePipe } from '@angular/common';
//Third Party
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
//APP
import { ClientSelectionService, ClientSelectionsObject, ClientChoicesObject } from '../client-selection-service/client-selection-service.component';
import { DateFilterService } from '../date-filter-service/date-filter-service.component';
import { LineOfBusinessService } from '../line-of-business-service/line-of-business-service.component';
import { DashboardService } from '../dashboard-service/dashboard-service.component';
import { BusinessGroupNewsService } from '../business-group-news-popup/business-group-news-service.component';
import { DashboardTile, DashboardFilterRecord, DashboardGridsterItem, DashboardDateFilterRange, DashboardTileFilterInfo, ClientSelectionObject } from "../classes-and-interfaces/classes-and-interfaces.component";
import { ReportRequest } from "../data-grid/data-grid.service";
import { DashboardTileDateFilterComponent } from "../dashboard-tile-date-filter/dashboard-tile-date-filter.component";
import { DashboardTileSlicerFilterComponent } from "../dashboard-tile-slicer-filter/dashboard-tile-slicer-filter.component";
import { ErrorModalService } from "../error-modal/error-modal-service.component";
import { DashboardFilterStandardComponent } from '../dashboard-filter-standard/dashboard-filter-standard.component';
import { DashboardTileZoomComponent } from '../../modals/dashboard-tile-zoom/dashboard-tile-zoom.component';
import { DashboardTileFilterComponent } from '../../modals/dashboard-tile-filter/dashboard-tile-filter.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-dashboard-stat-center',
  templateUrl: './dashboard-stat-center.component.html',
  styleUrls: ['./dashboard-stat-center.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardStatCenterComponent implements OnInit, OnDestroy {
  //isIE: boolean = false;
  baseUrl: string;
  //dashboardReportSubscription: Subscription;
  dashboardTileFilterSubscription: Subscription;
  dashboardTileZoomSubscription: Subscription;
  dashboardTileAddSubscription: Subscription;
  dashboardTileRemoveSubscription: Subscription;
  dashboardTileSubscription: Subscription;
  //ieDetectionSubscription: Subscription;
  @ViewChild(DashboardTileDateFilterComponent) tileDateFilterComponent: DashboardTileDateFilterComponent;
  @ViewChild(DashboardTileSlicerFilterComponent) tileSlicerFilterComponent: DashboardTileSlicerFilterComponent;
  @ViewChildren('divDashboardGridsterContainer') dashboardGridsterContainer: QueryList<ElementRef>;
  clientSelectionSubscription: Subscription;
  //clientChoicesSubscription: Subscription;
  //clientIdSelectionSubscription: Subscription;
  globalDateFilterSubscription: Subscription;
  clientSelectedArray: Array<string> = [];
  clientIdSelectedArray: Array<number> = [];
  //clientChoices: Array<ClientSelectionObject>;
  hasClientSelections: boolean = true;
  globalDateFilter: DashboardDateFilterRange;
  dashboardFilterInfo: Array<DashboardFilterRecord>;
  dashboardAvailableTileArray: Array<DashboardTile>;
  lineOfBusiness: number;
  private routesub: any;
  //private routeEventsSub: any;
  gridNoResults: boolean = false;
  gridLoading: boolean = false;
  //dashboardFilterPanelShow: boolean = false;
  dashboardDateSelectPanelShow: boolean = false;
  dashboardAddWidgetPanelShow: boolean = false;
  // for tile zoom
  dashboardTileZoom: DashboardTile = null;
  // for individual tile filter
  dashboardTileToFilter: DashboardTile = null;
  dashboardTileFilterType: string;
  dashboardTileToFilterStartDate: string;
  dashboardTileToFilterEndDate: string;
  isRouteResolved: boolean = false;
  isRedirected: boolean = false;

  // load dashboard-filter-standard only for select lines of business
  loadDashboardFilterPanel: boolean = false;

  // array of tile Ids in dashboard - used to compare after
  // call to get tiles, to see if Dashboard data needs to be refreshed
  arrDashboardTileId: Array<number> = [];

  // for gridster
  gridsterDashboard: Array<DashboardGridsterItem>;

  static itemResize(item, itemComponent) {
    //console.info('itemResized', item, itemComponent);
  }

  constructor(
    private router: Router,
    @Inject('BASE_URL') baseUrl: string,
    private route: ActivatedRoute,
    private gridRequest: ReportRequest,
    private clientSelectionService: ClientSelectionService,
    private dateFilterService: DateFilterService,
    private lineOfBusinessService: LineOfBusinessService,
    private dashboardService: DashboardService,
    private businessGroupService: BusinessGroupNewsService,
    //private browserInfoService: BrowserInfoService,
    private datePipe: DatePipe,
    //private toastrService: ToastService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    public dialog: MatDialog,
    private readonly routeReuse: RouteReuseStrategy
  ) {
    this.baseUrl = baseUrl;

    // subscribe to dashboard tile changes
    this.dashboardTileSubscription = dashboardService.tileArray$.subscribe(
      tileArray => {
        this.gridLoading = true;
        this.gridNoResults = false;
        let items = tileArray as Array<DashboardTile>;
        if (items) {
          this.processGridUpdate(items, true);
        }
      }
    );

    // global date filter - apply to powerBI filters
    this.globalDateFilterSubscription = dateFilterService.globalDateFilter$.subscribe(
      globalDateFilter => {
        this.closeActionPanels();
        this.globalDateFilter = globalDateFilter as DashboardDateFilterRange;
        if (this.gridsterDashboard) {
          this.gridsterDashboard.forEach(i => i.dashboardTile.globalDateFilter = this.globalDateFilter);
          this.gridsterDashboard.forEach(i => console.log(i.dashboardTile));
          //this.applyFilters(this.gridsterDashboard);
        }
      }
    );

    // subscribe to tile add - to add tile to UI
    this.dashboardTileAddSubscription = dashboardService.tileIdAdded$.subscribe(
      tileIdAdded => {
        this.dashboardService.getTileById(tileIdAdded as number, this.lineOfBusiness, this.clientSelectedArray).subscribe({
          next: (data) => {
            let tile = data as DashboardTile;
            let gridsterItem: DashboardGridsterItem = this.buildGridsterItem(tile);
            // setTilesLoading will be applied in applyFilters
            //if ((tile.embedUrl) && (tile.embedUrl.length > 0)) {
            //this.dashboardService.setTilesLoading(true);
            //}
            this.gridsterDashboard.push(gridsterItem);
            //this.applyFilters(this.gridsterDashboard);
            this.arrDashboardTileId = this.gridsterDashboard.map(t => t.tileId);
            this.loadingSpinnerService.hide();
          },
          error: (err: HttpErrorResponse) => {
            //this.toastrService.error(err.error.toString());
            this.errorService.setErrorObject(err.error);
            this.loadingSpinnerService.hide();
          }
        }
        );
      }
    );

    // subscribe to tile remove - to remove tile from UI
    this.dashboardTileRemoveSubscription = dashboardService.tileIdRemoved$.subscribe(
      tileIdRemoved => {
        // set tile autoArrange flag
        this.dashboardService.setGridsterAutoArrange(true);
        // remove from dashboard
        let remIndex = this.gridsterDashboard.findIndex(t => t.dashboardTile.tileId == tileIdRemoved);
        if (remIndex >= 0) {
          this.gridsterDashboard.splice(remIndex, 1);
          this.arrDashboardTileId = this.gridsterDashboard.map(t => t.tileId);
        }

      }
    );

    // subscribe to tile zoom - show tile in modal window
    this.dashboardTileZoomSubscription = dashboardService.tileZoom$.subscribe(
      tileZoom => {
        this.dashboardTileZoom = tileZoom as DashboardTile;
        this.showZoomModal();
      }
    );

    // subscribe to tile filter - show tile filter in modal window
    this.dashboardTileFilterSubscription = dashboardService.tileToFilter$.subscribe(
      filterInfo => {
        let f = filterInfo as { card: DashboardTile, filterType: string };
        this.dashboardTileToFilter = f.card;
        this.dashboardTileFilterType = f.filterType;
        this.showFilterModal();
      }
    );

  }

  ngOnInit() {
    //this.setTilesLoading(true);
    this.isRouteResolved = false;
    this.loadingSpinnerService.show();
    this.routesub = this.route.params.subscribe(params => {
      this.isRouteResolved = true;
      var lobParam = +params['LineOfBusiness']
      if (params['LineOfBusiness']) {
        this.lineOfBusiness = +params['LineOfBusiness']; // (+) converts string 'id' to a number
      } else {
        //default to lineOfBusiness1
        this.lineOfBusiness = 0;
      }
      let lobOld = this.lineOfBusinessService.getLineOfBusinessValue();
      // set line of business for other components that subscribe
      if (this.lineOfBusiness != 0) {
        this.lineOfBusinessService.setLineOfBusiness(this.lineOfBusiness);
        this.businessGroupService.getNewsItem(this.lineOfBusiness);
        if (this.lineOfBusiness == 1) {
          this.loadDashboardFilterPanel = true;
        }
      } else {
        //this.lineOfBusinessService.setLineOfBusiness(lobOld);
        this.lineOfBusinessService.lineOfBusinessSelected$.subscribe(data => {
          let isLobDifferentThanUrl = false;
          // console.log("Lob12:");
          // console.log(this.lineOfBusiness)
          // console.log(data)
          if (lobParam == 0 && data != 0 && this.isRouteResolved && !this.isRedirected) {
            this.isRedirected = true;
            this.router.navigate(['/statcenter/', data]);
            //this.lineOfBusiness = data;s
            //this.getStatCenterGrid(true);
          }
        });
      }
      // clear stored routes if navigating to a different line of business
      if (this.lineOfBusiness != lobOld) {
        let ss = this.route.snapshot;
        ss.data["clearStoredRoutes"] = true;
        this.routeReuse.store(ss, null);
        //this.router.routeReuseStrategy.store(null, null);
      }

      // client selection subscription
      this.clientSelectionSubscription = this.clientSelectionService.clientSelectedInfo$.subscribe(
        clientSelectedInfo => {
          this.closeActionPanels();
          let temp = clientSelectedInfo as ClientSelectionsObject;
          // 2019-02 - no longer using forceReload queryParam, so
          // forceReload should always be false
          let forceReload = !!this.route.queryParams['forceReload'];
          this.processClientSelection(temp, forceReload);
        }
      );

      // client choices subscription
      /*
      this.clientChoicesSubscription = this.clientSelectionService.clientChoicesInfo$.subscribe(data => {
        let temp = data as ClientChoicesObject;
        if ((this.lineOfBusiness > 0) && temp[this.lineOfBusiness]) {
          this.clientChoices = temp[this.lineOfBusiness];
        } else {
          this.clientChoices = [];
        }
      });
      */


      /* don't load client info on init - use clientSelectionSubscription
      this.clientIdSelectedArray = this.clientSelectionService.getClientIdSelectedArrayValue(this.lineOfBusiness);
      this.clientSelectedArray = this.clientSelectionService.getClientSelectedArrayValue(this.lineOfBusiness);
      if (this.clientSelectedArray.length <= 0) {
        // get localStorage values if this.clientSelectedArray is empty
        this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusiness);
      }
      this.hasClientSelections = (this.clientSelectedArray.length > 0);
      if (this.hasClientSelections) {
        // get global date filters then load grid
        this.loadGlobalDateFilterAndGrid();
      } else {
        this.dashboardService.setGridsterDashboard(null);
        this.dashboardService.setTilesLoading(false);
      }
      */
    });

    /*
    this.routeEventsSub = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        //alert(window.location.href);
        //alert(event.url);
        if (((window.location.href.indexOf('statcenter') >= 0)) && (event.url.indexOf('statcenter') >= 0)) {
          this.unsubscribeAll();
        }
      }
    });
    */

    // if navigating to another dashboard, cancel all subscriptions
    // this is necessary because the dashboard uses RouteResuseStrategy,
    // and onDestroy wont get called when a route is reused
    /* 2019-02 - removing route reuse logic for statcenter
    let initNavStartUrl = window.location.href.replace(this.baseUrl, '/');
    this.routeEventsSub = this.router.events
      .filter(event => event instanceof NavigationStart)
      .startWith(new NavigationStart(0, initNavStartUrl))
      .pairwise()
      .subscribe((value: [NavigationStart, NavigationStart]) => {

        let previousUrl = value[0].url;
        let nextUrl = value[1].url;

        if ((!this.isIE) && (previousUrl != nextUrl) && (previousUrl.indexOf('statcenter') >= 0)) {
          // check whether page has fully loaded - if not then dont reuse the route
          // do not change route reuse if in IE
          if (this.dashboardService.getTilesLoadingValue()) {
            if (window.location.href.indexOf('statcenter') >= 0) {
              this.unsubscribeAll();
            }
            this.router.routeReuseStrategy.shouldAttach = function (route: ActivatedRouteSnapshot): boolean {
              if (window.location.href.indexOf('statcenter') >= 0) {
                // temporarily set statcenter routes to not reuse
                return false;
              } else {
                // this will be true if the route has been stored before
                // and is not a force reload
                let canAttach: boolean = (!!route.routeConfig) && (!this.isForceReload(route)) && (!!this.getStoredRoute(route));

                return canAttach;
              }

            }
          } else {
            this.router.routeReuseStrategy.shouldAttach = function (route: ActivatedRouteSnapshot): boolean {
              if (route.data.shouldReuse) {
                // this will be true if the route has been stored before
                // and is not a force reload
                let canAttach: boolean = (!!route.routeConfig) && (!this.isForceReload(route)) && (!!this.getStoredRoute(route));

                return canAttach;
              }
            }
          }
        }

        if ((previousUrl != nextUrl) && (previousUrl.indexOf('statcenter') >= 0) && (nextUrl.indexOf('statcenter') >= 0)) {
          this.unsubscribeAll();
        }
      });
      */

    // subscribe to IE browser detection - 
    // used in routeEventsSub above
    /* 2019-02 - no longer needed since we are no longer using using powerbi
    this.ieDetectionSubscription = this.browserInfoService.isIE$.subscribe(isIE => {
      this.isIE = isIE;
      //if (this.isIE) {alert("IE!!!!")}
    });
    */
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  private showZoomModal(): void {
    const dialogRef = this.dialog.open(DashboardTileZoomComponent, {
      data: {
        tileId: 'tileZoomFrame',
        card: this.dashboardTileZoom
      },
      width: "53em",
      height: "54em",
      hasBackdrop: false,
      panelClass: "dashboard-tile-zoom",
      position: { top: "2em" },
    });
  }

  private showFilterModal(): void {
    const dialogRef = this.dialog.open(DashboardTileFilterComponent, {
      data: {
        filterType: this.dashboardTileFilterType,
        card: this.dashboardTileToFilter,
        globalDateFilter: this.globalDateFilter
      },
      width: "30em",
      height: "30em",
      hasBackdrop: false,
      panelClass: "dashboard-tile-zoom",
      position: { top: "2em" },
    });
  }

  private unsubscribeAll() {
    //console.log("unsub start");
    if (this.routesub)
      this.routesub.unsubscribe();
    //this.routeEventsSub.unsubscribe();
    //this.ieDetectionSubscription.unsubscribe();
    this.clientSelectionSubscription.unsubscribe();
    //this.clientChoicesSubscription.unsubscribe();
    //this.clientIdSelectionSubscription.unsubscribe();
    this.dashboardTileSubscription.unsubscribe();
    this.dashboardTileZoomSubscription.unsubscribe();
    this.dashboardTileAddSubscription.unsubscribe();
    this.dashboardTileRemoveSubscription.unsubscribe();
    this.dashboardTileFilterSubscription.unsubscribe();
    //this.dashboardReportSubscription.unsubscribe();
    this.globalDateFilterSubscription.unsubscribe();
    //this.dashboardAutoArrangeSubscription.unsubscribe();
    //console.log("unsub end");
  }

  private processClientSelection(clientSelectedInfo: ClientSelectionsObject, forceReload: boolean) {
    if (clientSelectedInfo[this.lineOfBusiness]) {
      this.clientIdSelectedArray = clientSelectedInfo[this.lineOfBusiness].clientIdSelectedArray;
      let cArr = clientSelectedInfo[this.lineOfBusiness].clientSelectedArray;
      if (!this.arrayCompare(cArr, this.clientSelectedArray)) {
        this.clientSelectedArray = cArr;
        if (this.clientSelectedArray.length <= 0) {
          // no clients selected
          this.hasClientSelections = false;
          this.arrDashboardTileId = [];
          delete this.gridsterDashboard;
          this.dashboardService.setGridsterDashboard(null);
          //this.dashboardService.setTilesLoading(false);
          //this.setTilesLoading(false);
        } else {
          if (!this.hasClientSelections) {
            // load grid info if no previous client selection
            this.hasClientSelections = true;
            this.loadGlobalDateFilterAndGrid(forceReload);
          } else {
            // don't load global date filter if it is already loaded.
            // Run getStatCenterGrid to see if dashboard needs
            // to be reloaded
            this.hasClientSelections = true;
            if (this.globalDateFilter) {
              this.getStatCenterGrid(forceReload);
            } else {
              this.loadGlobalDateFilterAndGrid(forceReload);
            }
          }
        }
      } else {
        this.loadingSpinnerService.hide();
      }
    } else {
      this.loadingSpinnerService.hide();
    }
  }

  private loadGlobalDateFilterAndGrid(forceReload: boolean) {
    // get global date filters then load grid
    this.loadingSpinnerService.show();
    this.dateFilterService.getGlobalDateFilter(this.lineOfBusiness).subscribe({
      next: (data) => {
        this.globalDateFilter = data as DashboardDateFilterRange;
        this.getStatCenterGrid(forceReload);
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    })
  }

  private getStatCenterGrid(forceReload: boolean) {
    if (this.hasClientSelections && this.lineOfBusiness) {
      //this.gridLoading = true;
      this.gridNoResults = false;

      this.dashboardService.getTileArray(this.lineOfBusiness, this.clientSelectedArray).subscribe({
        next: (data) => {
          //this.dashboardService.setTilesLoading(true);
          this.processGridUpdate(data as Array<DashboardTile>, forceReload);
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    } else {
      this.loadingSpinnerService.hide();
    }
  }

  private processGridUpdate(items: Array<DashboardTile>, forceGridRebuild: boolean) {
    if (items.length <= 0) {
      this.gridLoading = false;
      this.gridNoResults = true;
      this.arrDashboardTileId = [];
      delete this.gridsterDashboard;
      this.dashboardService.setGridsterDashboard(null);
    } else {
      let arrTileId = items.map(t => t.tileId);
      if ((!forceGridRebuild) && (this.arrayCompare(arrTileId, this.arrDashboardTileId))) {
        // no new tiles, just update filters
        this.gridsterDashboard.forEach(i => i.dashboardTile.clientSelectedArray = this.clientSelectedArray);
        //this.applyFilters(this.gridsterDashboard);
      } else {
        // rebuild gridsterDashboard
        this.gridLoading = true;
        this.arrDashboardTileId = arrTileId;
        let arrDash = [];
        items.forEach((item: DashboardTile, index: number) => {
          let gridsterItem: DashboardGridsterItem = this.buildGridsterItem(item);
          arrDash.push(gridsterItem);
        })
        arrDash.forEach(i => i.dashboardTile.clientSelectedArray = this.clientSelectedArray);

        this.gridsterDashboard = arrDash;
        //this.updateGridsterContainerHeight();
        this.gridLoading = false;
        this.dashboardService.setGridsterDashboard(this.gridsterDashboard);
        this.dashboardService.setDashboardLastUpdated(this.lineOfBusiness, new Date());
      }

    }
  }

  private buildGridsterItem(item: DashboardTile): DashboardGridsterItem {
    item.tokenType = "Embed";
    if (item.visualizationId && (item.visualizationId.length > 0) && item.pageName && (item.pageName.length > 0)) {
      item.type = "visual";
    } else {
      item.type = "report";
    }
    item.isDropTarget = false;
    //item.reportLoaded = false;

    // client and date filters
    item.clientSelectedArray = this.clientSelectedArray;
    item.globalDateFilter = this.globalDateFilter;

    // if hasSlicer, set default for slicerOptionChosen
    // to slicerDefaultValue, if slicerOptionChosen is empty
    if (item.hasSlicer && ((!item.slicerOptionChosen) || (item.slicerOptionChosen.length <= 0))) {
      if (item.slicerDefaultValue) {
        item.slicerOptionChosen = item.slicerDefaultValue;
      } else {
        item.slicerOptionChosen = "";
      }
    }

    let gridsterItem: DashboardGridsterItem = {
      x: null,
      y: null,
      dashboardTile: item,
      rows: item.tileHeight,
      cols: item.tileWidth
    }
    // get position on grid - if either x or y is negative
    // let gridster auto position the tile
    if ((item.positionX >= 0) && (item.positionY >= 0)) {
      gridsterItem.x = item.positionX;
      gridsterItem.y = item.positionY;
    }

    return gridsterItem;
  }

  closeActionPanels() {
    //this.dashboardFilterPanelShow = false;
    this.dashboardAddWidgetPanelShow = false;
    this.dashboardDateSelectPanelShow = false;
  }

  doDashboardAction(action: string) {
    switch (action) {
      case "showFilterPanel":
        // 2019-11 - dashboard filter now displays via a modal, not a slide panel
        //if (this.dashboardFilterPanelShow) {
        //this.closeActionPanels();
        //} else {
        this.closeActionPanels();
        this.showFilterPanel();
        //}
        break;
      case "showDateSelectPanel":
        if (this.dashboardDateSelectPanelShow) {
          this.closeActionPanels();
        } else {
          this.closeActionPanels();
          this.showDateSelectPanel();
        }
        break;
      case "showAddWidgetPanel":
        if (this.dashboardAddWidgetPanelShow) {
          this.closeActionPanels();
        } else {
          this.closeActionPanels();
          this.showAddWidgetPanel();
        }
        break;
      case "resetTileFilters":
        this.closeActionPanels();
        this.resetTileFilters();
        break;
      case "closePanels":
        this.closeActionPanels();
        break;
      case "closePanelsAndRefresh":
        this.closeActionPanels();
        this.getStatCenterGrid(false);
        break;
      default:
      //alert(action);
    }
  }

  private showFilterPanel() {
    switch (this.lineOfBusiness) {
      case 2:
        this.showFilterPageSafety();
        break;
      case 7:
        this.showFilterPageDc();
        break;
      default:
        // use accident function by default
        if (this.loadDashboardFilterPanel) this.showFilterPanelAccident();
    }
  }

  private showFilterPanelAccident() {
    const dialogRef = this.dialog.open(DashboardFilterStandardComponent, {
      data: {
        lineOfBusinessId: this.lineOfBusiness,
        clientSelectedArray: this.clientSelectedArray,
        clientIdSelectedArray: this.clientIdSelectedArray,
      },
      width: "60em",
      height: "60em",
      panelClass: "add-document-modal",
      position: { top: "0", right: "0" },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.doDashboardAction(result);
    })
    /* 2109-11 - just need to invoke showClaimsFilters to open filter modal
    this.dashboardService.getFilterDataMultiClient(this.lineOfBusiness, this.clientIdSelectedArray).subscribe(data => {
      this.dashboardFilterInfo = data as Array<DashboardFilterRecord>;
      this.dashboardService.setFilterData(this.dashboardFilterInfo);
        //this.dashboardFilterPanelShow = true;
        this.dashboardService.showClaimsFilters();
    },
      (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
      });
      */
  }

  private showFilterPageSafety() {
    //alert('safety filter page');
    this.router.navigate(['/safety/filter']);
  }

  private showFilterPageDc() {
    //alert('dc filter page');
    this.router.navigate(['/dc/filter']);
  }

  private showDateSelectPanel() {
    this.dashboardDateSelectPanelShow = true;
  }

  private showAddWidgetPanel() {
    this.loadingSpinnerService.show();
    this.dashboardService.getAvailableTileArray(this.lineOfBusiness, this.clientSelectedArray).subscribe({
      next: (data) => {
        this.dashboardAvailableTileArray = data as Array<DashboardTile>;
        this.dashboardService.setAvailableTileArray(this.dashboardAvailableTileArray);
        this.dashboardAddWidgetPanelShow = true;
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  showDataGrid(data: any, thisCard: DashboardTile) {
    this.loadingSpinnerService.show();
    //console.log(data);
    if (this.dashboardService.buildGridRequestHighCharts(this.gridRequest, data, thisCard)) {
      let randomVal = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
      try {
        this.dashboardTileZoom = null;
        sessionStorage.setItem('grid_' + randomVal, JSON.stringify(this.gridRequest));
        this.router.navigate(['/datagrid/' + randomVal]);
      } catch (e) {
        this.loadingSpinnerService.hide();
      }
    }

  }

  private resetTileFilters() {
    this.loadingSpinnerService.show();
    this.dashboardService.resetTileFilters(this.lineOfBusiness).subscribe({
      next: (data) => {
        // remove filter start and end dates from tiles that had them,
        // then recalc filters from global filters
        //this.dashboardService.setTilesLoading(true);
        //this.setTilesLoading(true);
        this.gridsterDashboard.filter(d => (
          (d.dashboardTile.filterStartDate && (d.dashboardTile.filterStartDate.length > 0)) || (d.dashboardTile.filterEndDate && (d.dashboardTile.filterEndDate.length > 0))
        )).forEach(d => {
          //d.dashboardTile.reportLoaded = false;
          d.dashboardTile.filterStartDate = null;
          d.dashboardTile.filterEndDate = null;
          //let newfilters: Array<DashboardTileFilterInfo> = this.calcTileFilters(d.dashboardTile);
          //d.dashboardTile.filters = (newfilters.length > 0 ? newfilters.map(f => f.filterObject) : null);
          //d.dashboardTile.filtersSummary = (newfilters.length > 0 ? newfilters.filter(f => f.filterText.length > 0).map(f => f.filterText) : null);
        });
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  closeDashboardTileZoomModal() {
    this.dashboardTileZoom = null;
  }

  closeDashboardTileFilterModal() {
    this.dashboardTileFilterType = null;
    this.dashboardTileToFilter = null;
    this.dashboardTileToFilterStartDate = null;
    this.dashboardTileToFilterEndDate = null;
  }

  private arrayCompare(a1, a2) {
    return (a1.length == a2.length && a1.every((v, i) => v === a2[i]));
  }
}
@Pipe({
  name: 'sortTiles'
})
export class SortTilesPipe implements PipeTransform {

  transform(gridItems: Array<DashboardGridsterItem>): Array<DashboardGridsterItem> {

    // Check if is not null
    if (!gridItems) return gridItems;

    return gridItems.sort((a: DashboardGridsterItem, b: DashboardGridsterItem) => {
      // sort ascending 
      return a.y > b.y ? 1 : -1;
    })
  }

}

