//Angular libraries
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

//thirdparty libraries
import { formatDate } from 'ngx-bootstrap/chronos';

//project imports
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { DataGridEmailService, DQGridRequest } from '../../components/data-grid-export-email/data-grid-export-email.service';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';

/**
 * this is the client-side controller for the safety - driver info tab panel
 */
@Component({
  selector: 'app-dq-grid',
  templateUrl: './dq-grid.component.html',
  styleUrls: ['./dq-grid.component.scss']
})

export class DQGridComponent implements OnInit, OnDestroy {
  public configObject: any;
  columns = [];
  rowsRaw: Array<any>;
  rows: Array<any>;
  today: any;
  baseUrl: string;
  lineOfBusinessId: number;
  SortDisplayField: string;
  SortField: string;
  SortDir: string;

  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: {}
  };

  ngOnDestroy(): void {
  }

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private router: Router,
    private route: ActivatedRoute,
    private onPremService: OnPremDriverService,
    private clientSelectionService: ClientSelectionService,
    private datePipe: DatePipe,

    private lineOfBusinessService: LineOfBusinessService,
    private errorService: ErrorModalService,
    private fileDownloadService: FileDownloadService,
    private emailService: DataGridEmailService
  ) {
    this.baseUrl = baseUrl;
    this.today = Date.now();
  }

  ngOnInit() {
    // set line of business to safety if not already initialized to Safety
    this.lineOfBusinessId = 2;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob !== 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    this.getData(this.clientSelectionService.getSavedClientShortNames(2)[0]).subscribe(data => {
      this.setupGrid(data);
    });

  }

  setupGrid(data) {

    this.rows = data;
    this.rowsRaw = data;

    this.columns = [
      { name: "Action", prop: "action", title: "Action", visible: true },
      { name: "Employee Id", prop: "employeeId", title: "Employee Id", visible: true },
      { name: "First Name", prop: "firstName", title: "First Name", visible: true },
      { name: "Last Name", prop: "lastName", title: "Last Name", visible: true },
      { name: "Email", prop: "emailAddress", title: "Email", visible: true },
      { name: "Review Type", prop: "documentType", title: "Review Type", visible: true },
      { name: "Date Requested", prop: "assignedDate", title: "Date Requested", dataType: "date", visible: true },
      { name: "Date Uploaded", prop: "driverCompletedDate", title: "Date Uploaded", dataType: "date", visible: true },
      { name: "Group", prop: "group", title: "Group", visible: true }
    ];


    var fields = new Array<any>();

    this.columns.forEach(s => {
      if (s.prop.toLowerCase() == "employeeid") {
        fields.push({
          name: s.title,
          objectKey: s.prop,
          hidden: !s?.visible,
          columnClass: 'clickable',
          click: row => {
            this.router.navigate(["/driver/history-profile/" + row["driverId"]]);
          }
        });
      }
      else if (s.prop.toLowerCase() == "action") {
        fields.push({
          name: s.title,
          objectKey: s.prop,
          hidden: !s?.visible,
          columnClass: 'clickable',
          click: row => {
            if (row["documentType"] === "Medical Certificate") {
              this.router.navigate(["/dq/medicalcard/" + row["driverId"] + "/" + row["driverProcessAssignedId"]]);
            }
            else if (row["documentType"] === "Drivers License") {
              this.router.navigate(["/dq/driverlicense/" + row["driverId"] + "/" + row["driverProcessAssignedId"]]);
            }
            else if (row["documentType"] === "Driver's Application") {
              this.router.navigate(["dq/applicationreview/" + row["driverId"] + "/" + row["driverProcessAssignedId"]]);
            }
            else if (row["documentType"] === "Driving History Review") {
              this.router.navigate(["/dq/drivinghistory/" + row["driverId"] + "/" + row["driverProcessAssignedId"]]);
            }
            else if (row["documentType"] === "Road Test") {
              this.router.navigate(["/dq/roadtest/" + row["driverId"] + "/" + row["driverProcessAssignedId"]])
            }
            else if (row["documentType"] === "Record Of Road Test") {
              this.router.navigate(["/dq/recordofroadtest/" + row["driverId"] + "/" + row["driverProcessAssignedId"]])
            }
          }
        });
      }
      else {
        fields.push({
          name: s.title,
          objectKey: s.prop,
          hidden: !s?.visible,
          render: row => {
            if (s.dataType === "currency") {
              if (row[s.prop] >= 0)
                return "$" + row[s.prop];
              else
                return "($" + (row[s.prop] * -1) + ")";
            }
            else if (s.dataType === "date" && row[s.prop]) {
              try {
                return formatDate(new Date(row[s.prop]), "MM/DD/YYYY");
              }
              catch (err) {
                return row[s.prop];
              }
            }
            else
              return row[s.prop]
          }
        });
      }
    });

    var settings = this.columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: 'enable'
      };
    }).filter(x => x.objectKey.toLowerCase() !== "driverid");
    this.configObject = {
      settings: settings,
      fields: fields,
      data: data
    };
  }

  getData(client: string) {
    this.resetSort();

    if (client && client !== "")
      return this.onPremService.get("driverQualification/" + client + "/review");
  }

  onPrint() {
    let sortParams = "";

    if (this.SortDisplayField && this.SortDisplayField.length > 0)
      sortParams = "&sort=" + this.SortField + "&sortDir=" + this.SortDir;


    this.fileDownloadService.openTab(this.baseUrl + "api/Safety/PrintDQGrid?client=" + this.clientSelectionService.getClientSelectedArrayValue(2).join(",") + sortParams);
  }


  onExcel() {
    let sortParams = "";

    if (this.SortDisplayField && this.SortDisplayField.length > 0)
      sortParams = "&sort=" + this.SortDisplayField + "&sortDir=" + this.SortDir;

    this.fileDownloadService
      .saveFile(this.baseUrl + "api/Safety/DQExcel?client=" + this.clientSelectionService.getClientSelectedArrayValue(2).join(",") + sortParams);
  }
  onEmail() {

    let request = new DQGridRequest();
    request.SortDisplayField = this.SortDisplayField;
    request.SortDir = this.SortDir;
    request.SortField = this.SortField;

    request.SelectedClient = this.clientSelectionService.getClientSelectedArrayValue(2).join(",");
    this.emailService.OpenEmailPopup(request);
  }

  resetSort() {
    this.SortDir = "";
    this.SortField = "";
    this.SortDisplayField = "";
  }
  trigger($event) {
    switch ($event.name) {
      case 'gt-sorting-applied':
        if ($event.value && $event.value[0] !== '$$gtInitialRowIndex') {

          if (($event.value[0] as string).startsWith('-')) {
            this.SortField = ($event.value[0] as string).slice(1);
            this.SortDir = "DESC"
          }
          else {
            this.SortField = $event.value[0]
            this.SortDir = "ASC"
          }
          let fld = this.configObject.fields.find(x => x.objectKey == this.SortField)
          if (fld)
            this.SortDisplayField = fld.name;
        }
        else {
          this.resetSort();
        }
        break;
    }
  }

  sortChange($event): void {
    if (!$event?.active) {
      this.resetSort();
    } else {
      this.SortField = $event?.active || null;
      this.SortDir = $event?.direction?.toUpperCase() || null;
      const fld = this.configObject.fields.find(x => x.objectKey === this.SortField);
      console.log(fld);
      if (fld) {
        this.SortDisplayField = fld.name;
      }
    }
  }
}

