<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h4 class="card-title text-muted">
        {{noteType}} Notes
        <span>
          <button class="btn btn-default"
                  *ngIf="canAddNote()"
                  (click)="toggleAddNotePanel()">
            Add Note
          </button>
        </span>
        <span>
          <button class="btn btn-default"
                  (click)="exportToPdf()">
            Export to PDF
          </button>
        </span>
      </h4>
      <app-accident-claim-note-add *ngIf="showAddNotePanel"
                                   [claimId]="_claimId"
                                   (onInsertNoteRefresh)="refreshNotes()"
                                   (onCancelInsertNote)="toggleAddNotePanel()"
                                   [noteTypeId]="noteTypeId"></app-accident-claim-note-add>
      <div class="row" *ngIf="notes?.length > 0">
        <div class="col-md-12">
          <table class="table table-striped table-bordered">
            <thead>
              <tr class="table-header">
                <th *ngFor="let column of columns" scope="col" [ngTableSorting]="config" [column]="column"
                    (sortChanged)="onChangeTable($event)" ngClass="{{column.className || ''}}">
                  {{column.title}}
                  <i *ngIf="config && column.sort" class="pull-right fa"
                     [ngClass]="{'fa-chevron-down': column.sort === 'desc', 'fa-chevron-up': column.sort === 'asc'}"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let note of notes">
                <td [style.color]="note.noteTypeHexColor" class="claimNotesBold">{{note.formattedCreateDate}}</td>
                <td [style.color]="note.noteTypeHexColor" class="claimNotesBold">{{note.createUserName}}</td>
                <td class="accidentClaimTabLabel" style="word-break:break-word">{{note.noteText}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
