//Angular
import { AfterViewChecked, AfterViewInit, Component, Inject, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl, Validators, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
//Third Party
import { MatDatepicker } from '@angular/material/datepicker';
//APP
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { DcDriverProfileService } from '../dc-driver-profile/dc-driver-profile-service.component';
import { SafetyModalWindowService } from '../../safety/safety-modal-window/safety-modal-window-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';

@Component({
  selector: 'dc-leave-of-absence',
  templateUrl: './dc-leave-of-absence.component.html',
  styleUrls: ['./dc-leave-of-absence.component.scss']
})
export class DcLeaveOfAbsenceComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('startDatePicker') startDatePicker: MatDatepicker<Date>;
  @ViewChildren('endDatePicker') endDatePickerList: QueryList<MatDatepicker<Date>>;
  @Input() modalHeight: string;
  @Input() modalInput: any;

  endDatePicker: MatDatepicker<Date>;
  baseUrl: string;
  formValidated: boolean = false;
  startDateReadOnly: boolean = false;
  beginDate: string;
  returnDate: string;
  beginDateControl = new UntypedFormControl('', [Validators.required]);
  returnDateControl = new UntypedFormControl('', [Validators.required]);

  // date vars for yesterday and today, with time stripped out
  // correct values are set in constructor
  private dtYesterday: Date = new Date((new Date()).toDateString());
  private dtTomorrow: Date = new Date((new Date()).toDateString());
  actualDate = new Date();

  allControls: Array<UntypedFormControl> = [
    this.beginDateControl,
    this.returnDateControl
  ];

  // the overloaded constructor for the controller
  constructor(private router: Router,
    private dcService: DcDriverProfileService,
    private safetyModalWindowService: SafetyModalWindowService,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private onPremService: OnPremDcService,
    private alertService: AlertService,
    private loadingSpinnerService: LoadingSpinnerService,
    private clientSelectionService: ClientSelectionService,
    private errorService: ErrorModalService,
    private datepipe: DatePipe)
  {
    this.dtYesterday.setDate(this.dtYesterday.getDate() - 1);
    this.dtTomorrow.setDate(this.dtTomorrow.getDate() + 1);
    // do not allow date picker start before todays date
  }

  ngOnInit() {
    if (this.modalInput) {
      this.startDateReadOnly = this.modalInput.startDateReadOnly;
      this.beginDate = this.datepipe.transform(this.modalInput.leaveOfAbsenceBeginDate, 'MM/dd/yyyy');
      this.returnDate = this.datepipe.transform(this.modalInput.leaveOfAbsenceReturnDate, 'MM/dd/yyyy');
    }
  }

  ngAfterViewInit(): void {
    this.startDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.startDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.startDatePicker.select(event.value);
            this.startDatePicker.close();
        })
    },0)
    })
  }

  ngAfterViewChecked(): void {
    if (!this.endDatePicker && this.endDatePickerList?.first) {
      this.endDatePicker = this.endDatePickerList.first;
      this.endDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.endDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.endDatePicker.select(event.value);
            this.endDatePicker.close();
          })
        }, 0)
      })
    }
  }

  private validateDates(): boolean {
    let isOK = true;

    // return date should be after begin date
    let beginDate = new Date(this.beginDateControl.value);
    let returnDate = new Date(this.returnDateControl.value);
    if (returnDate <= beginDate) {
      isOK = false;
      // set errors on date fields
      this.errorService.setErrorObject({ message: 'Return Date must be after Begin Date' });
      this.addValidationError(this.returnDateControl, 'datevalidation');
    } else {
      // clear errors on date fields
      this.removeValidationError(this.returnDateControl, 'datevalidation');
    }


    return isOK;
  }

  private validateForm(): boolean {
    let isOK: boolean = true;

    // validate dates
    isOK = this.validateDates();

    // filter out errors related to other validations
    // and add message for missing/invalid
    if (this.allControls.map(c1 => {
      let errs = c1.errors;
      if (errs) {
        delete errs['datevalidation'];
        if (Object.keys(errs).length <= 0) {
          errs = null;
        }
      }
      return errs;
    }).filter(c2 => c2 != null).length > 0) {
      this.errorService.setErrorObject({ message: 'Missing or Invalid Fields.' });
      isOK = false;
    }

    return isOK;
  }

  private addValidationError(ctrl: UntypedFormControl, key: string) {
    let errs: ValidationErrors = ctrl.errors;

    if (errs) {
      errs[key] = true;
    } else {
      errs = {};
      errs[key] = true;
    }
    ctrl.setErrors(errs);
  }

  private removeValidationError(ctrl: UntypedFormControl, key: string) {
    let errs: ValidationErrors = ctrl.errors;

    if (errs) {
      delete errs[key];
    }

    if (errs && (Object.keys(errs).length <= 0)) {
      errs = null;
    }

    ctrl.setErrors(errs);
  }

  submit() {
    if (this.validateForm()) {
      if (this.modalInput.fromLOAReport) {
        // save new end date
        this.setLeaveOfAbsenceEndDate();
      } else {
        // save both dates
        this.setLeaveOfAbsenceDateRange();
      }
      
    } 
  }

  cancel() {

    // notify the modal window to close
    this.dcService.notifyModalClose();
  }

  // updates a driver's leave of absence using a provided date range
  private setLeaveOfAbsenceDateRange() {
    let beginDateISO = (new Date(this.beginDate)).toISOString().slice(0, 10);
    let returnDateISO = (new Date(this.returnDate)).toISOString().slice(0, 10);

    this.loadingSpinnerService.show();
    this.onPremService.put('leaveofabsence/' + this.modalInput.driverId + '/schedule/' + beginDateISO + '/' + returnDateISO,
      null
    ).subscribe({
      next: (data) => {
        if (this.modalInput.fromLOAReport) {
          // if calling from LOA report, refresh report on submit
          this.loadingSpinnerService.hide();
          this.router.navigate(['dc/driversonleave']);
        } else {
          if(!this.modalInput.fromAdvancedSearch) {
            // refresh driver profile data or navigate to LOA report
            let beginDate = new Date(this.beginDate);
            if (beginDate < this.dtTomorrow) {
              // if beginDate <= today, go to Leave of Absence report page
              this.loadingSpinnerService.hide();
              this.router.navigate(['dc/driversonleave']);
            } else {
              // stay on form, update profile data
              this.dcService.setRefreshDriverProfileLeaveOfAbsenceData(this.modalInput.driverId);
            }
          }
        }
        // notify the modal windows to close
        this.dcService.notifyModalClose();
        this.alertService.showSuccessAlert('Leave of Absence Set','end', 'top', 5000);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  // updates a driver's leave of absence end date
  private setLeaveOfAbsenceEndDate() {
    let returnDateISO = (new Date(this.returnDate)).toISOString().slice(0, 10);

    this.loadingSpinnerService.show();
    this.onPremService.put('leaveofabsence/' + this.modalInput.driverId + '/update/' + returnDateISO,
      null
    ).subscribe({
      next: (data) => {
        if (this.modalInput.fromLOAReport) {
          // if calling from LOA report, refresh report on submit
          this.loadingSpinnerService.hide();
          this.router.navigate(['dc/driversonleave']);
        }
        // notify the modal windows to close
        this.dcService.notifyModalClose();
        this.alertService.showSuccessAlert('Leave Of Absence Updated','end', 'top', 5000);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  // provides a central point for handling errors
  handleError(message: string) {

  }

  // sleeps for a certain number of milliseconds
  sleep(milliseconds: number) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }
}
