import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Inject, Input } from '@angular/core';
import { DriverHistoryProfileService } from '../../safety/dhp-landing-page/dhp-landing-page-service.component';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import {Router } from '@angular/router';
import { DriverProfile, UserRightsInfo, DriverTraining, DriverHPEAssignment, DriverHPECoreCompetency, DriverHPESubLesson } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { DatePipe } from '@angular/common';
import { SafetyTrainingComponent } from '../../safety/safety-training/safety-training.component';

@Component({
  selector: 'dc-driver-training',
  templateUrl: './dc-driver-training.component.html',
  styleUrls: ['./dc-driver-training.component.css']
})

export class DcDriverTrainingComponent extends SafetyTrainingComponent {

  // the overloaded constructor for the controller
  constructor(protected driverHistoryProfileService: DriverHistoryProfileService,
    protected router: Router,
    protected http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    protected fileDownloadService: FileDownloadService
  )
  {
    super(driverHistoryProfileService, router, http, baseUrl, fileDownloadService);
  }


}
