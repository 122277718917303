<div #divDashboardGridsterContainer
     [hidden]="hideThisPanel||hideThisPanelTemp"
     [ngStyle]="{'height': gridsterContainerHeight.toString() + 'px'}"
     class="dashboardGridsterContainer"
     (window:resize)="onDashboardWindowResize(divDashboardGridsterContainer.getBoundingClientRect())">
  <gridster [options]="gridsterOptions">
    <gridster-item [item]="item" *ngFor="let item of gridsterDashboard | sortTiles; let recIndex = index">
      <app-card-stat-center [card]="item.dashboardTile" [frameId]="'chartFrame_' + item.dashboardTile.tileId.toString()"></app-card-stat-center>
    </gridster-item>
  </gridster>
</div>
