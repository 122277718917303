<ul>
  <li *ngFor="let menuItem of menuItems">
    <a class="collapsible-header waves-effect" id='link-menu-item-report-{{menuItem.dataGridId}}' [title]="menuItem.description" (click)="showModal(menuItem.dataGridId)">
      {{menuItem.title}}
    </a>
  </li>
  <li *ngIf="showTrainingSummaryToPDF" class="menuSeparator">

  </li>
  <li *ngIf="showTrainingSummaryToPDF && lineOfBusinessId == 2">
    <a class="collapsible-header waves-effect" id='link-menu-item-report-reqTStoPDF' [title]="'Request Training Summary to PDF'" [routerLink]="['/safety/trainingsummaryexport']">
      Request Training to PDF
    </a>
  </li>
  <li *ngIf="showTrainingSummaryToPDF && lineOfBusinessId == 2">
    <a class="collapsible-header waves-effect" id='link-menu-item-report-retTStoPDF' [title]="'Retrieve Training Summary to PDF'" [routerLink]="['/safety/trainingretrieve']">
      Retrieve Training to PDF
    </a>
  </li>
  <li *ngIf="lineOfBusinessId == 7" class="menuSeparator">

  </li>
  <li *ngIf="true && lineOfBusinessId == 7">
    <a class="collapsible-header waves-effect" id='link-menu-item-report-reqTStoPDF' [title]="'Request Training Summary to PDF'" [routerLink]="['/dc/trainingsummaryexport']">
      Request Training to PDF
    </a>
  </li>
  <li *ngIf="true && lineOfBusinessId == 7">
    <a class="collapsible-header waves-effect" id='link-menu-item-report-retTStoPDF' [title]="'Retrieve Training Summary to PDF'" [routerLink]="['/dc/trainingretrieve']">
      Retrieve Training to PDF
    </a>
  </li>
</ul>
