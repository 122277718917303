import { Component } from '@angular/core';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html'
})

export class SettingsPageComponent {
    private settingsUserInfo: SettingsPageUserInfo = {
        title: "User",
        userEmail: "",
        userName: "",
        userId: "",
        collapsed: false
    }
    private settingsSubscriptionInfo: SettingsPageSubscriptionInfo = {
        title: "Subscriptions",
        collapsed: false
    }
    private settingsAssignmentInfo: SettingsPageAssignmentInfo = {
        title: "Assignments",
        collapsed: false
    }
    private settingsRoleInfo: SettingsPageRoleInfo = {
        title: "Roles",
        collapsed: false
    }
    settingsData: SettingsPageData = {
        settingsLookupSuccess: false,
        userInfo: this.settingsUserInfo,
        subscriptionInfo: this.settingsSubscriptionInfo,
        assignmentInfo: this.settingsAssignmentInfo,
        roleInfo: this.settingsRoleInfo
    }

    getUserSettings() {
        if (this.settingsData.userInfo.userEmail.length > 0) {
            this.settingsData.settingsLookupSuccess = true;
            alert(this.settingsData.userInfo.userEmail);
        }
    }

    saveUserSettings() {
        if (this.settingsData.userInfo.userEmail.length > 0) {
            alert("Settings for " + this.settingsData.userInfo.userEmail + " saved");
        }
    }

    clearUserSettings() {
        this.settingsData.userInfo.userEmail = "";
        this.settingsData.settingsLookupSuccess = false;
    }

    emailPassword() {
        if (this.settingsData.userInfo.userId.length > 0) {
            alert("Email password for " + this.settingsData.userInfo.userId);
        }
    }
}

export interface SettingsPageData {
    settingsLookupSuccess: boolean;
    userInfo: SettingsPageUserInfo;
    subscriptionInfo: SettingsPageSubscriptionInfo;
    assignmentInfo: SettingsPageAssignmentInfo;
    roleInfo: SettingsPageRoleInfo;
}
export interface SettingsPageUserInfo {
    title: string;
    userName: string;
    userId: string;
    userEmail: string;
    collapsed: boolean;
}
export interface SettingsPageSubscriptionInfo {
    title: string;
    collapsed: boolean;
}
export interface SettingsPageRoleInfo {
    title: string;
    collapsed: boolean;
}
export interface SettingsPageAssignmentInfo {
    title: string;
    collapsed: boolean;
}

