
import {throwError as observableThrowError,  Subject ,  Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";

@Injectable()
export class DHPConfirmActionService {
  baseUrl: string;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private dhpService: DriverHistoryProfileService,
    private onPremService: OnPremDriverService,
    private errorService: ErrorModalService) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  /* RESETS THE DRIVER'S PASSWORD */
  resetPassword(driverId: string) {
    //return this.onPremService.put('entity/resetpassword/' + driverId, null);
    return this.onPremService.put('entity/' + driverId + '/resetpassword', null);
  }

  resendLogin(driverId: string) {
    return this.onPremService.post('entity/' + driverId + '/resendlogin', null);
  }

  /* RE-SENDS THE DRIVER'S E-SIGN EMAIL */
  resendESignEmail(driverId: string) {
    return this.onPremService.post('email/esignature/' + driverId, null);
  }

  /* RESETS THE DRIVER'S ACCOUNT (Kevin Spelling only) */
  resetAccount() {
    return this.onPremService.put('demo/reset', null);
  }

  /* TERMINATES A DRIVER */
  terminateDriver(driverId: string) {
    return this.onPremService.put('status/terminate/' + driverId, null);
  }

  /* CANCEL LEAVE OF ABSENCE - FUTURE LOA*/
  cancelLeaveOfAbsence(driverId: string) {
    return this.onPremService.put('status/leaveOfAbsence/cancel/' + driverId, null);
  }

  /* END LEAVE OF ABSENCE - LOA IN PROGRESS*/
  endLeaveOfAbsence(driverId: string) {
    return this.onPremService.put('status/leaveOfAbsence/end/' + driverId, null);
  }

    /* REMOVE FOLLOW UP DATE */
  removeFollowUpDate(driverId: string) {
    //return this.onPremService.delete('entity/followUp/' + driverId);
    return this.onPremService.delete('entity/' + driverId + '/followUp');
  }

  /* CANCEL LICENSE VERIFICATION */
  cancelLicenseVerification(driverId: string) {
    return this.onPremService.delete('licenseVerification/' + driverId, null);
  }

  /* REMOVE DOCUMENT */
  removeDocument(docId: string) {
    return this.onPremService.delete('file/' + docId, null);
  }

  /* VERIFY DOCUMENT */
  verifyDocument(docId: string) {
    return this.onPremService.post('file/' + docId + '/verify', null);
  }

  /* ENROLL IN MONITORING */
  mvrMonitoringEnroll(docId: string) {
    return this.onPremService.put('mvr/' + docId + '/enroll', null);
  }

  /* UNENROLL IN MONITORING */
  mvrMonitoringUnenroll(docId: string) {
    return this.onPremService.put('mvr/' + docId + '/unenroll', null);
  }

  /* HANDLES ENCOUNTERED ERRORS */
  private handleError(err: HttpErrorResponse) {
    console.log(err.message);
    return observableThrowError(err.message);
  }
}
