import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { MVRReport, MVRReportViolation } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DatePipe } from '@angular/common';
import { DHPMVRReportService } from './dhp-mvr-report-service.component';
import { DhpMvrCustomRowComponent } from './dhp-mvr-custom-row/dhp-mvr-custom-row.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'dhp-mvr-report',
  templateUrl: './dhp-mvr-report.component.html',
  styleUrls: ['./dhp-mvr-report.component.css'],
})
export class DHPMVRReportComponent implements OnInit, OnDestroy {
  mvrReport: MVRReport;
  mvrId: string;
  driverId: string;
  returnTo: string;
  returnId: string;
  lineOfBusinessId: number;
  routeSub: Subscription;
  public driverRecordTableConfig: any;
  gtOptions: any = {};
  baseUrl: string;

  // the overloaded constructor for the controller
  constructor(private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private datePipe: DatePipe,
    private onPremService: OnPremDriverService,
    private loadingSpinnerService: LoadingSpinnerService,
    private lineOfBusinessService: LineOfBusinessService,
    private fileDownloadService: FileDownloadService,
    private errorService: ErrorModalService,
    private readonly dhpMVRReportService: DHPMVRReportService) {
    this.baseUrl = baseUrl;
  }

  // angular on intialization event
  ngOnInit() {
    // set line of business to safety if not already initialized to Safety
    this.lineOfBusinessId = 2;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }
    this.subscribeToMvrData();

    // subscribes to user id changes and queryParams
    let obsComb = combineLatest(this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams }));

    this.routeSub = obsComb.subscribe(allParams => {
      this.mvrId = allParams.params['id'];
      this.driverId = allParams.params['driverId'];
      this.returnTo = allParams.qparams['returnTo'];
      this.returnId = allParams.qparams['returnId'];
      this.dhpMVRReportService.getMvrInfo(this.mvrId);

    });
  }

  subscribeToMvrData() {
    this.dhpMVRReportService.sharedMvrReport.subscribe({
      next: (mvrData) => {
        if (mvrData != null) {
          this.loadMVRData(mvrData);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }


  loadMVRData(mvrData: MVRReport) {
    this.mvrReport = mvrData as MVRReport;

    // properties below do not get set by service, are hard coded
    this.mvrReport.licenseLegend = "REST = Restrictions; ENDR = Endorsements;";
    this.mvrReport.driverRecordLegend = "CONV = Conviction; SUSP = Suspension; VIOL = Violation; ABS = Abstract (ticket/violation); DISQ = Disqualification (an action taken against someone's driving privileges)";
    this.mvrReport.disclaimer = "Information contained herein should not be the sole determination in evaluation of this individual. All other factors, references and current situations should be considered. Human error in compiling this information is possible. The FCRA requires certain actions if you take adverse action based in whole or in part on this report. See the FCRA or call for procedures.";

    this.setDriverRecordTableConfig(this.mvrReport.violations);
    this.loadingSpinnerService.hide();
  }


  private setDriverRecordTableConfig(driverRecords: Array<MVRReportViolation>) {
    let columns = [
      { name: "Details", prop: "details", title: "Details", visible: true, dataType: null, columnClass: "width7" },
      { name: "Type", prop: "type", title: "Type", visible: true, dataType: null, columnClass: "width7" },
      { name: "Violation / Suspension Date", prop: "violationDate", title: "Violation / Suspension Date", visible: true, dataType: "date", columnClass: "width15" },
      { name: "Conviction / Reinstatement Date", prop: "convictionDate", title: "Conviction / Reinstatement Date", visible: true, dataType: "date", columnClass: "width15" },
      { name: "Description", prop: "description", title: "Description", visible: true, dataType: null, columnClass: "" },
      { name: "Org. Pts", prop: "totalPoints", title: "Org. Pts", visible: true, dataType: "number", columnClass: "width10" }
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s.dataType,
        hidden: !s.visible,
        columnClass: s?.columnClass,
        expand: {
          component: DhpMvrCustomRowComponent
        },
        render: row => {
          if (s.dataType == "date") {
            return this.datePipe.transform(row[s.prop], 'MM/dd/yyyy');
          } else if (s.prop === "details") {
            return '<span class="row-toggler"><i class="fa fa-bars"></i></span>'
          } else {
            return row[s.prop];
          }
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.driverRecordTableConfig = {
      settings: settings,
      fields: fields,
      data: driverRecords,
      expandable: true,
      expand: {
        type: 'dhpMvrCustomRowComponent'
      },
      noRecordsLabel: 'No Driver Records',
      withFooter: true,
      footerObject: {
        type: 'totals',
        columnName: 'totalPoints',
        label: 'Total Points'
      },
      totals: [{
        name: '',
        position: 'footer',
        update: false,
        fields: {
          description: 'Total Points:',
          totalPoints: (rows, objectKey) => {
            return rows
              .map(row => parseFloat(row[objectKey]))
              .reduce((sum, value) => {
                return (isNaN(sum) ? 0 : sum) + (isNaN(value) ? 0 : value);
              })
              .toFixed(0);
          }
        }
      }]
    }
    this.gtOptions = (driverRecords.length > 1 ?
      {
        numberOfRows: driverRecords.length, rowExpandInitialState: false, rowExpandInitialComponent:
        {
          component: DhpMvrCustomRowComponent, data: (row) => row.violationsExtended
        }
      }
      :
      {
        rowExpandInitialState: false, rowExpandInitialComponent:
        {
          component: DhpMvrCustomRowComponent, data: (row) => row.violationsExtended
        }
      });
  }

  exitMvr() {
    if (this.returnTo) {
      // return to primary driver profile if returnId specified,
      // otherwise return to driver's profile
      let returnId = (this.returnId && (this.returnId.length > 0) ? this.returnId : this.driverId);
      switch (this.returnTo) {
        case "driverProfileMVR":
          this.router.navigate(['/driver/history-profile/' + returnId], { queryParams: { openToTab: 'MVR Records' } });
          break;
        case "driverProfileRisk":
          this.router.navigate(['/driver/history-profile/' + returnId], { queryParams: { openToTab: 'Risk Profile' } });
          break;
        case "driverProfileNonEmployees":
          this.router.navigate(['/driver/history-profile/' + returnId], { queryParams: { openToTab: 'Non-Employees' } });
          break;
      }
    }
  }
  exportPdf() {
    this.fileDownloadService
      .saveFile(this.baseUrl + 'api/Safety/GetMvrPdf?DriverId=' + this.driverId + '&MvrId=' + this.mvrId);
  }
  print() {
    //window.print();
    this.fileDownloadService.openTab(this.baseUrl + "api/Safety/GetMvr?DriverId=" + this.driverId + '&MvrId=' + this.mvrId);
  }
  // the default destructor
  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }


}
