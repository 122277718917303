<div class="row" style="padding-top:2rem;padding-bottom:20px">
    <div class="col-12">
        <h3>Move Driver - Search</h3>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <form [formGroup]="driverForm" (ngSubmit)="driverLookup()">
            <div class="row ml-2">
                <div class="col-12 col-md-3 col-lg-2 ">
                    <mat-form-field appearance="outline" class="small w-100">
                        <mat-label>Client Code</mat-label>
                        <input matInput placeholder="Client Code" formControlName="clientCode"
                            [ngClass]="{ 'is-invalid': isSubmitted && form.clientCode.errors }">
                    </mat-form-field>
                    <div *ngIf="isSubmitted && form.clientCode.errors" class="invalid-feedback">
                        <div *ngIf="form.clientCode.errors.required">Client Code is required</div>
                    </div>
                </div>
                <div class="col-12 col-md-3 col-lg-2">
                    <mat-form-field appearance="outline" class="small w-100">
                        <mat-label>Search Text</mat-label>
                        <input matInput placeholder="Search Text" formControlName="searchText"
                            [ngClass]="{ 'is-invalid': isSubmitted && form.searchText.errors }">
                    </mat-form-field>
                    <div *ngIf="isSubmitted && form.searchText.errors" class="invalid-feedback">
                        <div *ngIf="form.searchText.errors.required">Search Text is required</div>
                    </div>
                </div>
                <div class="col-12 col-md-3 col-lg-2 ">
                    <div class="md-form">
                        <mat-form-field appearance="outline" class="small w-100">
                            <mat-label>Refine Search</mat-label>
                            <mat-select formControlName="type" id="type">
                                <mat-option value="5">Employee ID</mat-option>
                                <mat-option value="6">Employee Name</mat-option>
                                <mat-option value="30">Email</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>
                <div class="col-12 col-md-3 col-lg-2 ">
                    <div class="col-12" *ngIf="!isLoading">
                        <button type="submit" class="btn btn-primary w-100 m-0">
                            Locate Driver</button>
                    </div>
                    <div class="col-12 text-align-center" *ngIf="isLoading">
                        <app-loading-panel></app-loading-panel>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-12 summary" *ngIf="isDriverMissing && !driverForm.dirty">
                    <div class="alert alert-danger" role="alert">Can't find driver: {{form.clientCode.value}} -
                        {{form.employeeId.value}} </div>
                </div>
            </div>
        </form>
        <div class="row" *ngIf="locatedDrivers?.length > 0">
            <div class="col-12">
                <table striped="true" class="table table-striped w-100">
                    <thead>
                        <tr>
                            <th>Driver ID</th>
                            <th>Client Name</th>
                            <th>Employee ID</th>
                            <th>Driver Name</th>
                            <th>Corporate Hierarchy</th>
                            <th>Matching Value</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let driver of locatedDrivers">
                            <th scope="row">
                                <a class="red-text" [routerLink]="['../edit',driver.driverId]">
                                    {{driver.driverId}}
                                </a>

                            </th>
                            <td>{{driver.clientName}}</td>
                            <td>{{driver.employeeId}}</td>
                            <td>{{driver.firstName}} {{driver.lastName}}</td>
                            <td>{{driver.corporateHierarchy}}</td>
                            <td>{{driver.matchingValue}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</div>