<div class="row">
    <div class="col-md-12">
        <div class="card card-body mt-3">
            <h4 class="card-title text-muted">Previous License Information</h4>
            <div *ngIf="previousLicenseInfoTableConfig">
                <app-data-table
                    [columns]="previousLicenseInfoTableConfig.fields"
                    [data]="previousLicenseInfoTableConfig.data"
                    [noRecordsLabel]="previousLicenseInfoTableConfig?.noRecordsLabel"
                ></app-data-table>
            </div>
        </div>
    </div>
</div>