
import { throwError as observableThrowError, Subject } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class SafetyDriverInfoService {
  baseUrl: string;
  errorMessage: string;
  public isActiveUpdate = new Subject<any>();
  public isActiveUpdate$ = this.isActiveUpdate.asObservable();

  constructor(private readonly http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  setIsActiveUpdate(driverId: number, isActive: boolean) {
    this.isActiveUpdate.next({ driverId: driverId, isActive: isActive });
  }

  /* HANDLES ENCOUNTERED ERRORS (I'LL TAKE THIS OUT AFTER TESTING) */
  private handleError(err: HttpErrorResponse) {
    return observableThrowError(err.error.message);
  }
}
