<div>
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" *ngIf="driverProfile">
  <div class="col-8">
    <div class="row">
      <div class="col-12" 
           style="font-size:20px;font-weight:500;color:#44474b;"
           valign="middle">
        <mat-label>{{driverProfile?.fullName}}</mat-label>
      </div>
    </div>
    <div class="row" style="padding-bottom:10px" *ngIf="driverProfile">
      <div class="col-12" style="font-size:14px;font-weight:500;color:#44474b;" valign="top">
        {{companyFullHierarchy}}
      </div>
    </div>
  </div>
  <div class="col-4" style="padding-right:6rem;padding-bottom:1rem">
    <li class="nav-item dropdown btn-group actionDropdownButton" style="float:right" dropdown>
      <button [matMenuTriggerFor]="menu" class="btn btn-secondary dropdown-toggle" type="button" aria-haspopup="true" aria-expanded="false">
        Driver Actions
      </button>
      <mat-menu #menu="matMenu">
        <button *ngFor="let actionItem of actionMenuObjectArray"
                class="dropdown-item"
                type="button"
                (click)="actionMenuItemClick(actionItem.id)">
          {{actionItem.description}}
        </button>
      </mat-menu>
    </li>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="card card-body mt-3">
    <ng-container *ngIf="tabs && (tabs.length>0) && driverProfile">
      <mat-tab-group class="classic-tabs" #dhpTabs>
      <mat-tab *ngFor="let tab of tabs" [customClass]="tab.display?'':'hideDriverProfileTab'">
        <ng-template mat-tab-label>
          <span>{{tab.name}}</span> <div *ngIf="tab.icon" class="ml-2" [innerHTML]="tab.icon"></div>
        </ng-template>
        <div class="card card-body mt-3">
          <div class="col-12">
            <dc-driver-info *ngIf="tab.name=='Driver Info'" 
                              [driverProfile]="driverProfile"
                              [_userRights]="_userRights">
            </dc-driver-info>
            <dc-driver-assigned-programs *ngIf="tab.name=='Assigned Products'"
                                        [driverId]="driverId"
                                        [companyId]="companyId"
                                        [driverName]="driverProfile?.fullName"
                                        [programs]="driverProfile.assignedProducts">
            </dc-driver-assigned-programs>
            <dc-driver-training *ngIf="tab.name=='Training'"
                                [driverProfile]="driverProfile"
                                [completedTraining]="driverProfile.completedTraining"
                                [incompleteTraining]="driverProfile.incompleteTraining"
                                [_userRights]="_userRights">
            </dc-driver-training>
            <dc-driver-notes-docs *ngIf="tab.name=='Notes and Docs'"
                                  [driverProfile]="driverProfile"
                                  [driverNotes]="driverProfile.driverNotes||[]"
                                  [driverDocuments]="driverProfile.driverDocuments||[]"
                                  [_userRights]="_userRights">
            </dc-driver-notes-docs>
          </div>
        </div>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </div>
  </div>
</div>
