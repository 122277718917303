<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Invoice Detail</h4>
          <div class="row" *ngFor="let i of invoices">
            <div class="col-md-12">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th class="accidentClaimInvoicesHeader" scope="col">Invoice: {{i.invoiceNumber}}</th>
                    <th class="accidentClaimInvoicesHeader" scope="col" style="text-align:right;width:33%;">Date: {{i.invoiceDate |  date:'M/d/yyyy'}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let cc of i.chargeCodes">
                    <td>{{cc.description}}</td>
                    <td style="text-align:right;width:33%;">{{cc.amount | currency:'USD':'$':'1.2-2' | negativeToParensPipe}}</td>
                  </tr>
                  <tr>
                    <td class="accidentClaimInvoicesFooter" style="text-align:right">INVOICE TOTAL</td>
                    <td class="accidentClaimInvoicesFooter" style="text-align:right;width:33%;">{{getSumByInvoice(i.chargeCodes) | currency:'USD':'$':'1.2-2' | negativeToParensPipe}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="accidentClaimInvoicesTotal">TOTAL INVOICED FOR CLAIM: {{getInvoicesTotal() | currency:'USD':'$':'1.2-2' | negativeToParensPipe}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
