//angular imports
import { Component, Input, OnChanges, Output, EventEmitter, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';
//thirdparty dependencies
import { Validators, UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
//project imports
import { DcTimeEntry } from '../dc-classes-and-interfaces/dc-classes-and-interfaces.component';
import { OnPremTimeclockService } from '../../components/on-prem-service/on-prem-timeclock-service.component';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'dc-driver-time-clock-detail',
  templateUrl: './dc-driver-time-clock-detail.component.html',
  styleUrls: ['./dc-driver-time-clock-detail.component.scss']
})

export class DcDriverTimeClockDetailComponent implements OnChanges {
  startDate: string;
  endDate: string;
  actualDate = new Date();
  startDatePicker: MatDatepicker<Date>;
  endDatePicker: MatDatepicker<Date>;
  @ViewChildren('startDatePicker') startDatePickerList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('endDatePicker') endDatePickerList: QueryList<MatDatepicker<Date>>;
  @Input() arrEntryDetail: Array<DcTimeEntry>
  @Input() driverId: number;
  @Input() totalHours: number;
  public configObject: any;
  gtOptions: any = {};
  newEntry: DcTimeEntry;
  clockInDate: string;
  clockOutDate: string;
  clockInTime: string;
  clockOutTime: string;
  isNew: boolean = true;
  showEntryForm: boolean = false;
  isFormValid: boolean = true;
  totalMap: { [key: string]: string | number | Function } = {};

  beginDateControl = new UntypedFormControl('', [Validators.required]);
  endDateControl = new UntypedFormControl('', [Validators.required]);
  @ViewChild('input#clockInTimePicker') input: ElementRef;
  //@ViewChild('clockInTimePicker') clockInTimePicker: ClockPickerComponent;

  onDateChange = (event: { actualDateFormatted: string; }) => {
    //this.input.nativeElement.value = event.actualDateFormatted; // set value to input
    //this.clockInDatePicker. .closeBtnClicked(); // close date picker
    //this.clockInTimePicker.openBtnClicked(); // open time picker
  };

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log(`${type}: ${event.value}`);
  }

  constructor(private router: Router,
    private onPremService: OnPremTimeclockService,
    private loadingSpinnerService: LoadingSpinnerService,
    private snackBar: MatSnackBar,
    private errorService: ErrorModalService,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe) {



    this.totalMap["Total Hours"] = this.totalHours;

    this.newEntry = new DcTimeEntry();
    this.configObject = {
      settings: null,
      fields: null,

      totals: [
        {
          name: 'Total *',
          position: 'footer',
          update: false,
          fields: this.totalMap
        }]
    };
  }

  @Output() EditingStarted: EventEmitter<any> = new EventEmitter<any>();
  @Output() EditingStopped: EventEmitter<any> = new EventEmitter<any>();
  @Output() EntryUpdated: EventEmitter<any> = new EventEmitter<any>();

  ngOnChanges() {
    if (this.arrEntryDetail) {
      this.setupGrid(this.arrEntryDetail);
    }
  }

  ngAfterViewChecked(): void {
    if (!this.startDatePicker && this.startDatePickerList?.first) {
      this.startDatePicker = this.startDatePickerList.first;
      this.startDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.startDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.startDatePicker.select(event.value);
            this.startDatePicker.close();
          })
        }, 0)
      })
    }

    if (!this.endDatePicker && this.endDatePickerList?.first) {
      this.endDatePicker = this.endDatePickerList.first;
      this.endDatePicker.openedStream.subscribe(() => {
        setTimeout(() => {
          this.endDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.endDatePicker.select(event.value);
            this.endDatePicker.close();
          })
        }, 0)
      })
    }
  }

  private setupGrid(dataInitial: Array<DcTimeEntry>) {
    let data: Array<DcTimeEntry> = dataInitial;
    this.totalMap["TotalHours"] = this.decimalPipe.transform(this.totalHours, "1.2-2");;

    let columns = [
      { name: "Edit", prop: "edit", title: "Edit", visible: true, dataType: null },
      { name: "Delete", prop: "delete", title: "Delete", visible: true, dataType: null },
      { name: "Time Entry ID", prop: "TimeEntryId", title: "Time Entry ID", visible: false, dataType: null },
      { name: "Vehicle Number", prop: "VehicleNumber", title: "Vehicle Number", visible: true, dataType: null },
      { name: "Clock In Time", prop: "ClockInTime", title: "Clock In Time", dataType: "date", visible: true },
      { name: "Clock Out Time", prop: "ClockOutTime", title: "Clock Out Time", dataType: "date", visible: true },
      { name: "Total Hours", prop: "TotalHours", title: "Total Hours", dataType: null, visible: true }

    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: 'enable'
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      if (s.prop == "edit") {
        fields.push({
          name: s.title,
          objectKey: s.prop,
          columnClass: 'clickable',
          hidden: !s.visible,
          render: row => {
            let r = row[s.prop];
            r = 'edit';
            return r;
          },
          value: row => {
            let val = row[s.prop];
            return val;
          },
          click: row => {
            this.onRowEdit(row);
          }
        });
      }
      else if (s.prop == "delete") {
        fields.push({
          name: s.title,
          objectKey: s.prop,
          columnClass: 'clickable',
          hidden: !s.visible,
          render: row => {
            let r = row[s.prop];
            r = 'delete';
            return r;

          },
          value: row => {
            let val = row[s.prop];
            return val;
          },
          click: row => {
            //delete ACTION
            this.deleteEntry({ entryId: row.TimeEntryId });
          }
        });
      }
      else {
        fields.push({
          name: s.title,
          objectKey: s.prop,
          dataType: s.dataType,
          hidden: !s.visible,
          render: row => {
            let r = row[s.prop];
            switch (s.dataType) {
              case "date":
                try {
                  r = this.datePipe.transform(new Date(row[s.prop]).toISOString(), 'MM/dd/yyyy hh:mm a');
                } catch (e) { }
                break;
            }
            switch (s.prop) {
              case "employeeId":
                r = '<a style="color:#d23138;"><u>' + r + '</u></a>';
                break;
            }

            return r;
          },
          value: row => {
            let val = row[s.prop];
            if (s.dataType === "number") {
              // convert to number
              try {
                val = +val;
              } catch (e) { }
            }
            return val;
          }
        });
      }

    });

    this.configObject = {
      settings: settings,
      fields: fields,
      data: data,
      totals: [
        {
          name: 'Total *',
          position: 'footer',
          update: false,
          fields: this.totalMap
        }]
    }

    this.gtOptions = (data.length > 1 ? { numberOfRows: 50 } : {});
  }
  validateForm() {
    if (!this.clockInTime) {
      return false;
    }
    if (!this.clockOutTime)
      return false;
    if (!this.clockInDate) {
      return false;
    }
    if (!this.clockOutDate) {
      return false;
    }
    if (!this.newEntry.VehicleNumber || this.newEntry.VehicleNumber.length == 0)
      return false;

    return true;
  }
  showNewEntryForm() {
    this.isNew = true;
    this.showEntryForm = true;
  }
  resetForm() {
    this.newEntry = new DcTimeEntry();
    this.clockInTime = null;
    this.clockOutTime = null;
    this.clockInDate = "";
    this.clockOutDate = "";
    this.isFormValid = true;
  }
  updateEntry(entry: DcTimeEntry) { }
  addEntry() {
    this.isFormValid = this.validateForm()
    if (!this.isFormValid)
      return;

    this.loadingSpinnerService.show();
    let reqEntry = new DcTimeEntry();
    //reqEntry.ClockInTime = new Date(this.clockInDate + ' ' + this.clockInTime);
    //reqEntry.ClockOutTime = new Date(this.clockOutDate + ' ' + this.clockOutTime);

    reqEntry.ClockInTime = new Date(this.datePipe.transform(this.clockInDate, "yyyy-MM-dd") + 'T' + this.clockInTime + 'Z');
    reqEntry.ClockOutTime = new Date(this.datePipe.transform(this.clockOutDate, "yyyy-MM-dd") + 'T' + this.clockOutTime + 'Z');
    reqEntry.VehicleNumber = this.newEntry.VehicleNumber;

    if (this.isNew) {
      this.onPremService.post(`entries/${this.driverId}`, JSON.stringify(reqEntry)).subscribe({
        next: (response) => {
          this.snackBar.open('Entry Saved.', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: 'success-snackbar'
          });
          this.loadingSpinnerService.hide();
          this.resetForm();
          this.EntryUpdated.emit();
          this.cancel();
        },
        error: (err) => {
          this.loadingSpinnerService.hide();
          this.errorService.setErrorObject(err);
        }
      });
    }
    else {
      //reqEntry.TimeEntryId = this.newEntry.TimeEntryId;
      this.onPremService.put(`entries/${this.driverId}/${this.newEntry.TimeEntryId}`, JSON.stringify(reqEntry)).subscribe({
        next: (response) => {
          this.snackBar.open('Entry Saved.', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: 'success-snackbar'
          });
          this.loadingSpinnerService.hide();
          this.resetForm();
          this.EntryUpdated.emit();
          this.cancel();
        },
        error: (err) => {
          this.loadingSpinnerService.hide();
          this.errorService.setErrorObject(err);
        },
      });
    }
  }

  deleteEntry(data: any) {
    const entryId = data?.entryId;
    this.loadingSpinnerService.show();
    this.onPremService.delete(`entries/${this.driverId}/${entryId}`).subscribe({
      next: (response) => {
        this.snackBar.open('Entry Deleted.', 'Ok', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5000,
          panelClass: 'success-snackbar'
        });
        this.loadingSpinnerService.hide();
        this.resetForm();
        this.EntryUpdated.emit();
        this.cancel();
      },
      error: (err) => {
        this.loadingSpinnerService.hide();
        this.errorService.setErrorObject(err);
      }
    });
  }

  onRowEdit(row: any): void {
    //EDIT ACTION HERE
    try {
      this.newEntry = row;
      this.clockInTime = this.datePipe.transform(this.newEntry.ClockInTime, 'HH:mm');//formatDate(new Date(this.newEntry.ClockInTime), "hh:mm");
      this.clockOutTime = this.datePipe.transform(this.newEntry.ClockOutTime, 'HH:mm');//formatDate(this.newEntry.ClockOutTime, "hh:mm");
      this.clockInDate = this.datePipe.transform(this.newEntry.ClockInTime, 'MM/dd/yyyy');//formatDate(this.newEntry.ClockInTime, "MM/dd/YYYY");
      this.clockOutDate = this.datePipe.transform(this.newEntry.ClockOutTime, 'MM/dd/yyyy'); //formatDate(this.newEntry.ClockOutTime, "MM/dd/YYYY");
      this.isNew = false;
      this.showEntryForm = true;
      this.EditingStarted.emit(row);
    }
    catch (err) {
      console.log("Error");
      console.log(err);
    }
  }

  onTriggerAction(data: any): void {
    const columnName = data?.columnName;
    if (columnName === 'edit') {
      this.onRowEdit(data);
    } else if (columnName === 'delete') {
      this.deleteEntry(data);
    }
  }

  private cancel() {
    this.showEntryForm = false;
    this.isNew = true;
    this.resetForm();
  }
  private exit() {
    this.EditingStopped.emit();
  }
}

