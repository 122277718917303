import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WidgetInfo, DashboardTile, DashboardTileCategory } from "../classes-and-interfaces/classes-and-interfaces.component";

@Component({
  selector: 'app-widget-user-add-list',
  templateUrl: './widget-user-add-list.component.html',
  styleUrls: ['./widget-user-add-list.component.css']
})
export class WidgetUserAddListComponent {
  @Input() widgetListCategory: string;
  @Input() widgetListFiltered: Array<DashboardTile>;
  @Input() arrCategoryChoices: Array<DashboardTileCategory>;
  @Output() onSelectCategory: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAddWidget: EventEmitter<any> = new EventEmitter<any>();

  constructor(){}
  
  selectCategory(catId: string) {
    this.onSelectCategory.emit(catId);
  }

  addWidget(tileId:number) {
    this.onAddWidget.emit(tileId);
  }

}
