<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <div class="row accidentClaimTabPaddingTop">
        <div class="col-sm-4 accidentClaimTabLabel">
          Original Demand Amount:
        </div>
        <div class="col-sm-8 accidentClaimTabBold">
          {{subroInfo?.originalDemandAmount | currency:'USD':'$':'1.2-2' | negativeToParensPipe}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 accidentClaimTabLabel">
          Liability Percent:
        </div>
        <div class="col-sm-8 accidentClaimTabBold">
          {{subroInfo?.liabilityPercentage | percent:'1.2-2'}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 accidentClaimTabLabel">
          Total Demand Amount:
        </div>
        <div class="col-sm-8 accidentClaimTabBold">
          {{subroInfo?.totalDemandAmount| currency:'USD':'$':'1.2-2'}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 accidentClaimTabLabel">
          Deductible Amount:
        </div>
        <div class="col-sm-8 accidentClaimTabBold">
          {{subroInfo?.deductibleAmount | currency:'USD':'$':'1.2-2' | negativeToParensPipe}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 accidentClaimTabLabel">
          Deductible Prepaid?
        </div>
        <div class="col-sm-8 accidentClaimTabBold">
          {{subroInfo?.deductiblePrepaid}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 accidentClaimTabLabel">
          Total Demand Less Deductible:
        </div>
        <div class="col-sm-8 accidentClaimTabBold">
          {{subroInfo?.totalDemandLessDeductibleAmount | currency:'USD':'$':'1.2-2' | negativeToParensPipe}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 accidentClaimTabLabel">
          Settlement Amount:
        </div>
        <div class="col-sm-8 accidentClaimTabBold">
          {{subroInfo?.settlementAmount | currency:'USD':'$':'1.2-2'}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 accidentClaimTabLabel">
          Savings Amount:
        </div>
        <div class="col-sm-8 accidentClaimTabBold">
          {{(subroInfo?.totalDemandLessDeductibleAmount-subroInfo?.settlementAmount) | currency:'USD':'$':'1.2-2' | negativeToParensPipe}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 accidentClaimTabLabel">
          Fee:
        </div>
        <div class="col-sm-8 accidentClaimTabBold">
          {{subroInfo?.totalFeeAmount | currency:'USD':'$':'1.2-2'}}
        </div>
      </div>
    </div>
  </div>
</div>
<app-accident-claim-subro-missing-docs *ngIf="_claimId" [_claimId]="_claimId"></app-accident-claim-subro-missing-docs>
<app-accident-claim-note [noteType]="'Subrogation'" [noteTypeId]="5" [userRights]="userRights"></app-accident-claim-note>
