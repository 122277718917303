<div class="sidemenu-backdrop" *ngIf="isSideMenuOpen" (click)="toggleSideMenu()"></div>
<div id="mySidebar" class="sidebar" [class.display-side-menu] = "isSideMenuOpen">
  <div class="top-logo-container">
    <a class="pl-0" [routerLink]="['/home']" *ngIf="logoSrc">
      <img id="Fleet-logo" [src]="logoSrc" alt="Fleet Response Visibility">
    </a>
  </div>
  <div *ngIf="showForAccidentClaims">
    <mat-accordion multi="true">
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="far fa-chart-bar"></i> Main
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="collapsible-header waves-effect" id="link-menu-item-44700" [routerLink]="['/statcenter/1']">
              StatCenter
            </a>
            <a *ngIf="false" class="collapsible-header waves-effect" id="link-menu-item-44700" [routerLink]="['/highchartdemo/1']">
              HighCharts Demo
            </a>
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="fa fa-table"></i>Reports
          </mat-panel-title>
        </mat-expansion-panel-header>
          <app-data-grid-menu [lineOfBusinessId]="lineOfBusiness">
          </app-data-grid-menu>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="fa fa-cogs"></i>Report Builder
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="report-builder-list navbar-nav">
          <li style="padding: 1em;">
            <a class="report-builder-text" id="link-menu-item-44700" [routerLink]="['/customReports']">
              Report Builder Tool
            </a>
          </li>
        </ul>
        <app-custom-reports-menu></app-custom-reports-menu>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="fa fa-check-square"></i> Client Selector
          </mat-panel-title>
        </mat-expansion-panel-header>
        <client-selector [lineOfBusinessSelected]="lineOfBusiness"></client-selector>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <ng-container *ngIf="showForSafety">
    <mat-accordion multi="true">
      <mat-expansion-panel expanded="true" class="main-container">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="far fa-chart-bar"></i> Main
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="navbar-nav">
          <li>
            <a class="collapsible-header waves-effect" id="link-menu-item-44700" [routerLink]="['/statcenter/2']">
              StatCenter
            </a>
          </li>
          <li>
            <a class="collapsible-header waves-effect" id="link-menu-item-44710" [routerLink]="['/safety/corpstructure']">
              Corporate Structure
            </a>
          </li>
          <li *ngIf="hasAddDriverPermission">
            <a class="collapsible-header waves-effect" id="link-menu-item-44720" [routerLink]="['/safety/newemployeedriverselector']">
              Add New Employee Driver
            </a>
          </li>
          <li *ngIf="hasBatchToolPermission">
            <a class="collapsible-header waves-effect" id="link-menu-item-44730" [routerLink]="['/safety/ordersandassignments']">
              Orders & Assignments
            </a>
          </li>
          <li *ngIf="hasClientDocumentLibrary">
            <a class="collapsible-header waves-effect" id="link-menu-item-44710" [routerLink]="['/safety/documentlibrary']">
              Document Library
            </a>
          </li>
          <li *ngIf="isDriverQualificationOn">
            <a class="collapsible-header waves-effect" id="link-menu-item-44730" [routerLink]="['/dq/filemanagement']">
              DQ File Management
            </a>
          </li>
          <li *ngIf="isCOIValidationOn">
            <a class="collapsible-header waves-effect" id="link-menu-item-44730" [routerLink]="['/safety/coivalidationgrid']">
              Certificate of Insurance Validation
            </a>
          </li>
          <li *ngIf="hasMoveDriverPermission">
            <a class="collapsible-header waves-effect" id="link-menu-item-44730" [routerLink]="['/safety/movedriver/list']">
              Move Driver
            </a>
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="fa fa-table"></i>Reports
          </mat-panel-title>
        </mat-expansion-panel-header>
          <app-reactivate-driver-menu-item *ngIf="hasReactivateEmployeePermission"></app-reactivate-driver-menu-item>

          <app-honda-opt-out-menu-item *ngIf="hasOptOutDriverPermission"></app-honda-opt-out-menu-item>

          <app-orders-and-assignments-report-menu-item *ngIf="false"></app-orders-and-assignments-report-menu-item>

          <app-data-grid-menu [lineOfBusinessId]="lineOfBusiness"
                              [showTrainingSummaryToPDF]="isTrainingAccount">
          </app-data-grid-menu>

      </mat-expansion-panel>

      <mat-expansion-panel class="mvr-tools-container">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="fa fa-wrench"></i>MVR Tools
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="navbar-nav">
          <li>
            <a class="collapsible-header waves-effect" id="link-menu-item-44740" [routerLink]="['/safety/mvrreleaserequirements']">
              MVR Release Requirements
            </a>
          </li>
          <li>
            <a class="collapsible-header waves-effect" id="link-menu-item-44741" [routerLink]="['/safety/staterequiredcodes']">
              State Required Codes
            </a>
          </li>
          <li *ngIf="isMvrAccount">
            <a class="collapsible-header waves-effect" id="link-menu-item-44742" [routerLink]="['/safety/mvrexport']">
              Request MVRs to PDF
            </a>
          </li>
          <li *ngIf="isMvrAccount">
            <a class="collapsible-header waves-effect" id="link-menu-item-44743" [routerLink]="['/safety/mvrretrieve']">
              Retrieve MVRs to PDF
            </a>
          </li>
          <li *ngIf="isCertificateOfViolationOn">
            <a class="collapsible-header waves-effect" id="link-menu-item-44742" [routerLink]="['/safety/covexport']">
              Request COVs to PDF
            </a>
          </li>
          <li *ngIf="isCertificateOfViolationOn">
            <a class="collapsible-header waves-effect" id="link-menu-item-44743" [routerLink]="['/safety/covretrieve']">
              Retrieve COVs to PDF
            </a>
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="fa fa-check-square"></i> Client Selector
          </mat-panel-title>
        </mat-expansion-panel-header>
        <client-selector [lineOfBusinessSelected]="lineOfBusiness"></client-selector>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>

  <ng-container *ngIf="showForHeavyEquipment">
    <mat-accordion multi="true">
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="far fa-chart-bar"></i>Main
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="navbar-nav">
          <li>
            <a class="collapsible-header waves-effect" id="link-menu-item-44700" [routerLink]="['/statcenter/4']">
              StatCenter
            </a>
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="fa fa-table"></i>Reports
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-data-grid-menu [lineOfBusinessId]="lineOfBusiness"></app-data-grid-menu>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="fa fa-check-square"></i> Client Selector
          </mat-panel-title>
        </mat-expansion-panel-header>
        <client-selector [lineOfBusinessSelected]="lineOfBusiness"></client-selector>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>

  <ng-container *ngIf="showForDeliveryContractor">
    <mat-accordion multi="true">
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="far fa-chart-bar"></i>Main
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="navbar-nav">
          <li>
            <a class="collapsible-header waves-effect" id="link-menu-item-44700" [routerLink]="['/statcenter/7']">
              StatCenter
            </a>
          </li>
          <li>
            <a class="collapsible-header waves-effect" id="link-menu-item-44700" [routerLink]="['/dc/corpstructure']">
              Corporate Structure
            </a>
          </li>
          <li>
            <a class="collapsible-header waves-effect" id="link-menu-item-44700" [routerLink]="['/dc/programssummary']">
              My Account
            </a>
          </li>
          <li>
            <a class="collapsible-header waves-effect" id="link-menu-item-44700" [routerLink]="['/dc/documentlibrary']">
              Document Library
            </a>
          </li>
          <li>
            <a class="collapsible-header waves-effect" id="link-menu-item-44700" [routerLink]="['/dc/driverentry']">
              Driver Entry
            </a>
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="fa fa-table"></i>Reports
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-data-grid-menu [lineOfBusinessId]="lineOfBusiness"
                              [showTrainingSummaryToPDF]="isTrainingAccount">
          </app-data-grid-menu>
      </mat-expansion-panel>

      <mat-expansion-panel style="display: none;">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="fa fa-check-square"></i> Client Selector
          </mat-panel-title>
        </mat-expansion-panel-header>
        <client-selector [lineOfBusinessSelected]="lineOfBusiness"></client-selector>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>

  <ng-container *ngIf="showForCustom">
    <mat-accordion multi="true">
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="fa fa-check-square"></i>Main
          </mat-panel-title>
        </mat-expansion-panel-header>
        <custom-module-selector></custom-module-selector>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>

  <ng-container *ngIf="!showForCustom && !showForHeavyEquipment && !showForSafety &&!showForAccidentClaims">
    <mat-accordion multi="true">
      <mat-expansion-panel style="display: none;">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="fa fa-check-square"></i> Client Selector
          </mat-panel-title>
        </mat-expansion-panel-header>
        <client-selector [lineOfBusinessSelected]="lineOfBusiness"></client-selector>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>

  <li class="bottomLogo">
    <img id="Fleet-logo-bottom" src="images/Fleet_Response_4C_white_red_noShadow.png" alt="Fleet Response">
  </li>

</div>

<nav class="navbar fixed-top navbar-expand-md double-nav" style="height: 3em;">
  <button class="burger-menu-button ml-1" (click)="toggleSideMenu()">
    <i class="fa fa-bars" aria-hidden="true"></i>
  </button>
      <div class="navbar-links-full">
        <ul class="navbar-nav nav-flex-icons ml-0 align-items-center">
          <li *ngFor="let c of pageChoicesCanView;let i = index" [ngClass]="{'nav-item': true,'link-active': c.selected}+i">
            <a [ngClass]="{'mg-left' : i ==0}" class="topmenuonly" href="javascript: void(0)" (click)="selectPage(c.id, c.label, c.routerLink)">
              <span [class]="c.iconClass"></span> {{c.label}}
            </a>
          </li>
        </ul>
        <div class="ml-auto d-flex justify-content-end">
          <ul class="navbar-nav nav-flex-icons">
            <li class="nav-item" *ngIf="showClaimsNotifications && pendingApprovals > 0">
              <a class="nav-link waves-light" (click)="openPendingRequests(pendingApprovalsReportId)">
                <i class="fa fa-bell fa-inverse"></i>
                <span class="clearfix small-menu-text">
                  All Pending Approvals <span class="badge" style="background-color:#d23138">{{pendingApprovals}}</span>
                </span>
              </a>
            </li>
            <li class="nav-item" *ngIf="showSafetyNotifications && pendingCOIApprovals > 0">
              <a class="nav-link waves-light" (click)="openPendingCOIRequests(pendingCOIApprovalsReportId)">
                <i class="fa fa-bell fa-inverse"></i>
                <span class="clearfix small-menu-text">
                  Pending COI Approvals <span class="badge" style="background-color:#d23138">{{pendingCOIApprovals}}</span>
                </span>
              </a>
            </li>
            <li class="nav-item" *ngIf="newsButtonShow">
              <a class="nav-link waves-light" (click)="showBusinessGroupNews()">
                <i class="fal fa-newspaper"></i>
                <span class="clearfix">
                  {{newsButtonLabel}}
                </span>
              </a>
            </li>
          </ul>

          <ul class="navbar-nav nav-flex-icons" *ngIf="canViewAdminOptions() && adminMenuItems.length > 0">
            <li class="nav-item dropdown btn-group">
              <a [matMenuTriggerFor]="menu" type="button" class="nav-link dropdown-toggle waves-light" (click)="updateAdminMenu()">
                <i class="fa fa-key"></i>
                <span class="clearfix small-menu-text">
                  Admin <i class="fa fa-exclamation-triangle alert-icon-red" *ngIf="canViewAdminOptions() && pendingDocumentValidationCount > 0"></i>
                </span>
              </a>
                <mat-menu #menu="matMenu">
                  <span *ngFor="let item of adminMenuItems">
                    <a class="dropdown-item" [routerLink]="[item.route]">{{item.label}}</a>
                  </span>
                </mat-menu >
            </li>
          </ul>
          <ul class="navbar-nav nav-flex-icons">
            <li class="nav-item">
              <a class="nav-link waves-light" (click)="logout()"><i class="fa fa-sign-out"></i> <span class="clearfix small-menu-text">Logout</span></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="navbar-links-mobile">
        <!-- SideNav slide-out button -->
        <ul class="nav navbar-nav nav-flex-icons ml-auto">
          <li class="nav-item dropdown btn-group" dropdown>
            <a [matMenuTriggerFor]="menu" type="button" class="nav-link dropdown-toggle waves-light">
              <i class="fa fa-th"></i> <span>{{pageSelectedName || 'Select Page'}}</span>
            </a>
            <mat-menu #menu="matMenu">
              <a *ngFor="let c of pageChoicesCanView"
                 class="dropdown-item"
                 href="javascript: void(0)"
                 (click)="selectPage(c.id, c.label, c.routerLink)">
                {{c.label}}
              </a>
            </mat-menu>
          </li>
        </ul>

        <ul class="nav navbar-nav nav-flex-icons ml-auto" *ngIf="showClaimsNotifications||showSafetyNotifications||newsButtonShow">
          <li class="nav-item dropdown btn-group" dropdown>
            <a [matMenuTriggerFor]="menu" type="button" class="nav-link dropdown-toggle waves-light">
              <i class="fa fa-bell"></i>
            </a>
            <mat-menu #menu="matMenu">
              <a class="dropdown-item" *ngIf="showClaimsNotifications && pendingApprovals > 0" (click)="openPendingRequests(pendingApprovalsReportId)">All Pending Approvals ({{pendingApprovals}})</a>
              <a class="dropdown-item" *ngIf="showSafetyNotifications && pendingCOIApprovals > 0" (click)="openPendingCOIRequests(pendingCOIApprovalsReportId)">Pending COI Approvals ({{pendingCOIApprovals}})</a>
              <a class="dropdown-item" *ngIf="newsButtonShow" (click)="showBusinessGroupNews()">{{newsButtonLabel}}</a>
            </mat-menu>
          </li>
        </ul>

        <ul [ngClass]="{'ml-auto':!(showSafetyNotifications||showClaimsNotifications||newsButtonShow), 'ml-2':(showSafetyNotifications||showClaimsNotifications||newsButtonShow),'nav navbar-nav nav-flex-icons':true}" *ngIf="canViewAdminOptions()">
          <li class="nav-item dropdown btn-group" dropdown>
            <a [matMenuTriggerFor]="menu" type="button" class="nav-link dropdown-toggle waves-light">
              <i class="fa fa-key"></i>
            </a>
            <mat-menu #menu="matMenu">
              <span *ngFor="let item of adminMenuItems">
                <a class="dropdown-item" [routerLink]="[item.route]">{{item.label}}</a>
              </span>
            </mat-menu>
          </li>
        </ul>
        <ul class="nav navbar-nav nav-flex-icons ml-2">
          <li class="nav-item dropdown btn-group" dropdown>
            <a [matMenuTriggerFor]="menu" type="button" class="nav-link dropdown-toggle waves-light">
              <i class="fa fa-user"></i> <span>{{userFunctionLinkSelected}}</span>
            </a>
            <mat-menu #menu="matMenu">
              <a class="dropdown-item" (click)="logout()">Logout</a>
              <a class="dropdown-item" *ngIf="false" href="javascript: void(0)" (click)="selectUserFunctionLink('Support')">Support</a>
              <a class="dropdown-item" *ngIf="false" href="javascript: void(0)" (click)="selectUserFunctionLink('Account')">Account</a>
            </mat-menu>
          </li>
        </ul>
        <!--/. SideNav slide-out button -->
      </div>
</nav>

