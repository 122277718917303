import { Component, Inject, Injectable } from '@angular/core';
import { lastValueFrom, Observable ,  Subject } from "rxjs";
import { HttpClient } from '@angular/common/http';


@Injectable()
export class AdvancedSearchService {
  baseUrl: string;
  _http: HttpClient;

  private dataGridRequest = new Subject();
  dataGridRequest$ = this.dataGridRequest.asObservable();


  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._http = http;
    this.baseUrl = baseUrl;
  }


  updateGridRequest(request: AdvancedSearchRequest) {
    this.dataGridRequest.next(request);
  }

  getData(request: AdvancedSearchRequest): Promise<any> {
    this.dataGridRequest.next(request);

    return lastValueFrom(this._http.post(this.baseUrl + 'api/ClaimsSearch/Search',
      JSON.stringify(request),
      {
        headers: { 'Content-Type': 'application/json' },
      }
    ))
      .then(result => {
        return result;
      }).catch();
  }
}


export interface Column {
  name: string;
  prop: string;
}

export interface GridData {
  Columns: Column[];
  Data: any;
}

@Injectable()
export class AdvancedSearchRequest {
  SelectedClients: Array<any>;
  DateField: string;
  StartDate: Date;
  EndDate: Date;
  DriverFirstName: string;
  DriverLastName: string;
  VehicleUnitNumber: string;
  Vin: string;
  Req1: string;
  Req2: string;
  Req3: string;
  Req4: string;
  Req5: string;
  Req6: string;
  Req7: string;
  Req8: string;
  OvOwnerFirstName: string;
  OvOwnerLastName: string;
  OvOwnerCity: string;
  OvOwnerStateCode: string;
  OvOwnerInsuranceCompany: string;

  dummy: string;
  SortDisplayField: string;
  SortField: string;
  SortDir: string;
  public reset() {
    this.SortDir = "";
    this.SortField = "";
    this.SortDisplayField = "";
  }
}
