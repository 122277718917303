  <div>
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="confirmModalNo()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="labelConfirmModal">{{title}}</h4>
      </div>
      <div class="modal-body">
        {{prompt}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" aria-label="Yes" (click)="confirmModalYes()">Yes</button>
        <button type="button" class="btn btn-default" style="margin-bottom:6px" aria-label="No" (click)="confirmModalNo()">No</button>
      </div>
    </div>
  </div>
