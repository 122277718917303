import { Component, Input, OnInit, Inject } from '@angular/core';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { SafePipe } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-dq-file-panel',
  templateUrl: './dq-file-panel.component.html',
  styleUrls: ['./dq-file-panel.component.css'],
  providers: [SafePipe]
})

export class DQFilePanelComponent implements OnInit {
  @Input() documentId: number;
  @Input() fileName: string;
  @Input() uploadDate: string;
  imageBlobUrl: SafeResourceUrl | null = null;
  imageBlobType: string | null = null;
  baseUrl: string;

  constructor(@Inject('BASE_URL') baseUrl: string,
    private fileDownloadService: FileDownloadService,
    private safePipe: SafePipe,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService) {
    this.baseUrl = baseUrl;
  }

  ngOnInit() {
    this.fileDownloadService.getFile(`${this.baseUrl}api/Safety/GetSafetyFile?DocumentId=${this.documentId.toString()}`).subscribe({
      next: (data) => {
        this.processPreviewFile(data as HttpResponse<any>);
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  downloadFile(docId: number, fileName: string) {
    this.fileDownloadService
      .saveFile(this.baseUrl + 'api/Safety/GetSafetyFile?DocumentId=' + docId.toString(), fileName);
  }

  previewFile(docId: number) {
    if (docId) {
      this.loadingSpinnerService.show();
      let url = 'api/Safety/GetSafetyFile?DocumentId=' + docId.toString();
      this.fileDownloadService
        .getFile(this.baseUrl + url).subscribe({
          next: (response) => {
            this.processPreviewFile(response);
            this.loadingSpinnerService.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err.error);
            this.loadingSpinnerService.hide();
          }
        })
    }

  }

  private processPreviewFile(response: HttpResponse<any>) {
    if (response) {
      const blob = new Blob([response.body], { type: response.headers.get("content-type") });
      this.createImageFromBlob(blob);
    }
  }

  private createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      if (this.imageBlobType == "pdf") {
        this.imageBlobUrl = reader.result;
      } else {
        this.imageBlobUrl = this.safePipe.getSafeResourceUrl(reader.result);
      }
    }, false);
    if (image) {
      let blobType = "unavailable";
      if (image.type.indexOf("image") >= 0) {
        blobType = "image";
        reader.readAsDataURL(image);
      }
      if (image.type.indexOf("pdf") >= 0) {
        blobType = "pdf";
        reader.readAsArrayBuffer(image);
      }
      this.imageBlobType = blobType;

    }
  }

  closePreview() {
    this.imageBlobUrl = null;
    this.imageBlobType = null;
    this.imageBlobType = "closed";
  }


}
