<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
  <div *ngIf="dueDateType=='editTrainingDueDate'" class="card card-body mt-3">
    <div class="row">
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        Lesson:
      </div>
      <div class="col-sm-9 readOnlyFormLayoutBold">
        {{modalInput.trainingData.lesson}}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        Date Assigned:
      </div>
      <div class="col-sm-3 readOnlyFormLayoutBold">
        {{modalInput.trainingData.dateAssigned |  date:'MM/dd/yyyy'}}
      </div>
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        Assigned By:
      </div>
      <div class="col-sm-3 readOnlyFormLayoutBold">
        {{modalInput.trainingData.assignedBy}}
      </div>
    </div>
  </div>
  <div *ngIf="dueDateType=='editHPEAssignmentDueDate'" class="card card-body mt-3">
    <div class="row">
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        HPE Assignment:
      </div>
      <div class="col-sm-9 readOnlyFormLayoutBold">
        {{modalInput.trainingData.courseTitle}}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3 readOnlyFormLayoutLabel">
        Date Assigned:
      </div>
      <div class="col-sm-9 readOnlyFormLayoutBold">
        {{modalInput.trainingData.assignedDate |  date:'MM/dd/yyyy'}}
      </div>
    </div>
  </div>
  <div class="card card-body mt-3">
    <div class="row">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label class="lbl-start-date">Due Date</mat-label>
        <input class="input-date" matInput disabled 
          [matDatepicker]="dueDatePicker" 
          [formControl]="dueDateControl" 
          [(ngModel)]="newDueDate"
          (click)="dueDatePicker.open()">
        <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #dueDatePicker disabled="false">
          <mat-datepicker-actions>
            <button mat-button (click)="dueDatePicker.select(actualDate); dueDatePicker.close()">
              <mat-icon>history</mat-icon>
              Today
            </button>
            <button mat-button (click)="dueDatePicker.select(null); dueDatePicker.close()">
              <mat-icon>highlight_off</mat-icon>
              Clear
            </button>
          </mat-datepicker-actions> 
        </mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button *ngIf="formValidated" class="btn btn-default"
          (click)="submit()">
    Submit
  </button>
  <button *ngIf="!formValidated" class="btn btn-default"
          (click)="submit()" disabled>
    Submit
  </button>
  <button class="btn btn-default" style="margin-bottom:6px"
          (click)="cancel()">
    Cancel
  </button>
</div>
