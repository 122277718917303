//project imports
import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { DataGridFilterInfo } from "../classes-and-interfaces/classes-and-interfaces.component";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-data-grid-admin-filter-modal',
  templateUrl: './data-grid-admin-filter-modal.component.html',
  styleUrls: ['./data-grid-admin-filter-modal.component.scss'],

})
export class DataGridAdminFilterModalComponent {
  dataGridFilter: DataGridFilterInfo;
  filterTypeChoices: Array<any>;
  filterDateRangeChoices: Array<any>;
  filterOrderChoices: Array<any>;
  objFilterValidationFail: any;
  reportFilters: Array<any>;

  constructor(public dialogRef: MatDialogRef<DataGridAdminFilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      dataGridFilter: DataGridFilterInfo,
      filterTypeChoices: Array<any>,
      filterOrderChoices: Array<any>,
      reportFilters: Array<any>,
      objFilterValidationFail: any,
      filterDateRangeChoices: Array<any>
    }) {
    this.dataGridFilter = data.dataGridFilter;
    this.filterTypeChoices = data.filterTypeChoices;
    this.filterDateRangeChoices = data.filterDateRangeChoices;
    this.filterOrderChoices = data.filterOrderChoices;
    this.objFilterValidationFail = data.objFilterValidationFail;
    this.reportFilters = data.reportFilters;

  }

  closeModal(): void {
    this.dialogRef.close(false);
  }

  saveDataGridFilter(): void {
    this.dialogRef.close(this.data);
  }
}
