import { Component, OnInit, OnDestroy, NgModule } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessGroupNewsItem } from "../classes-and-interfaces/classes-and-interfaces.component";
import { BusinessGroupNewsService } from './business-group-news-service.component';


//#AngularUpgrade
//@NgModule({
//  imports: [NgxEditorModule]
//})

@Component({
  selector: 'app-business-group-news-popup',
  templateUrl: './business-group-news-popup.component.html',
  styleUrls: ['./business-group-news-popup.component.css']
})
export class BusinessGroupNewsPopupComponent implements OnInit, OnDestroy {
 
  display: string = "none";
  showModal: boolean = false;
  businessGroupNewsModalSubscription: Subscription;
  newsItem: BusinessGroupNewsItem;

  constructor(
    private businessGroupService: BusinessGroupNewsService
  ) { }

  ngOnInit(): void {

    this.businessGroupNewsModalSubscription = this.businessGroupService.openBusinessNewsObs$.subscribe(
      e => {
        if (e) {
          this.newsItem = e as BusinessGroupNewsItem;
          this.openModal();
        }
      }
    );

  }

  openModal() {
    this.showModal = true;
    this.display = "block";
  }
  dismissModal() {
    this.businessGroupService.dismissNewsItem(this.newsItem);
    this.closeModal();
  }
  closeModal() {
    this.showModal = false;
    this.display = "none";
  }

  ngOnDestroy() {
    this.businessGroupNewsModalSubscription.unsubscribe();
  }
}
