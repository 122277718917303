import { Component, OnInit, Input } from '@angular/core';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { UserRightsService } from '../../components/user-rights-service/user-rights-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { SafetyModalWindowService } from '../safety-modal-window/safety-modal-window-service.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { UserRightsInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'leave-of-absence',
  templateUrl: './leave-of-absence.component.html',
  styleUrls: ['./leave-of-absence.component.css']
})
export class LeaveOfAbsenceComponent implements OnInit {
  @Input() modalHeight: string;
  @Input() modalInput: any;
  clientSelectedArray: Array<string>;
  clientIdSelectedArray: Array<number>;
  lineOfBusinessId: number;
  baseUrl: string;
  private driverId: string;
  driverBaseInfo: any;
  _userRights: Array<UserRightsInfo>;
  loaNotFound: boolean = false;
  canTerminateDriver: boolean = false;
  isActiveLeave: boolean = false;
  // date vars for yesterday and today, with time stripped out
  // correct values are set in constructor
  private dtYesterday: Date = new Date((new Date()).toDateString());
  private dtTomorrow: Date = new Date((new Date()).toDateString());

  constructor(private loadingSpinnerService: LoadingSpinnerService,
    private clientSelectionService: ClientSelectionService,
    private lineOfBusinessService: LineOfBusinessService,
    private userRightsService: UserRightsService,
    private errorService: ErrorModalService,
    private driverHistoryProfileService: DriverHistoryProfileService,
    private safetyModalWindowService: SafetyModalWindowService,
    private datepipe: DatePipe) {
    this.dtYesterday.setDate(this.dtYesterday.getDate() - 1);
    this.dtTomorrow.setDate(this.dtTomorrow.getDate() + 1);
  }

  ngOnInit(): void {
    // set line of business to safety if not already initialized to Safety
    this.lineOfBusinessId = 2;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    // get the selected client(s)
    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusinessId);

    this.initAll();
  }

  private initAll() {
    this.loadingSpinnerService.show();
    this.userRightsService.getUserRights(this.lineOfBusinessId, this.clientSelectedArray).subscribe({
      next: (data) => {
        this._userRights = data as Array<UserRightsInfo>;
        this.driverBaseInfo = this.modalInput.driverBaseInfo;

        let loaStartDate = this.driverBaseInfo.leaveOfAbsenceStartDate;
        if (loaStartDate && (loaStartDate.length > 0)) {
          let startDate = new Date(loaStartDate);
          this.isActiveLeave = (startDate) < this.dtTomorrow;
          // format start and end dates to remove time component
          this.driverBaseInfo.leaveOfAbsenceStartDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
        }
        let loaEndDate = this.driverBaseInfo.leaveOfAbsenceEndDate;
        if (loaEndDate && (loaEndDate.length > 0)) {
          let endDate = new Date(loaEndDate);
          // format start and end dates to remove time component
          this.driverBaseInfo.leaveOfAbsenceEndDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
        }

        this.setViewRights();
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

  }

  // notifies the modal of changes
  notifyModal(title: string, message: string, modalHeight: string, modalWidth: string) {
    this.driverHistoryProfileService.notifyModal(title, message, modalHeight, modalWidth);
  }

  editLeave() {
    let modalInput = {
      fromLOAReport: this.driverBaseInfo.fromLOAReport != null ? this.driverBaseInfo.fromLOAReport : true,
      startDateReadOnly: false,//to be able to edit both Begin and End dates: ADO#71018
      driverId: this.driverBaseInfo.driverId,
      leaveOfAbsenceBeginDate: this.driverBaseInfo.leaveOfAbsenceStartDate,
      leaveOfAbsenceReturnDate: this.driverBaseInfo.leaveOfAbsenceEndDate,
      client: this.driverBaseInfo.clientCode
    };
    this.driverHistoryProfileService.notifyModal('Leave of Absence', 'dhpLeaveOfAbsence', '420px;', 'md', modalInput);
  }

  cancelLeave(dialogLabel: string) {
    // cancel future leave
    let modalInput = {
      fromLOAReport: this.driverBaseInfo.fromLOAReport != null ? this.driverBaseInfo.fromLOAReport : true,
      driverId: this.driverBaseInfo.driverId,
      client: this.driverBaseInfo.clientCode
    };
    this.driverHistoryProfileService.notifyModal(dialogLabel, 'cancelLeaveOfAbsence', '180px;', 'sm', modalInput);
  }

  endLeave(dialogLabel: string) {
    // end active leave
    let modalInput = {
      fromLOAReport: this.driverBaseInfo.fromLOAReport != null ? this.driverBaseInfo.fromLOAReport : true,
      driverId: this.driverBaseInfo.driverId,
      client: this.driverBaseInfo.clientCode
    };
    this.driverHistoryProfileService.notifyModal(dialogLabel, 'endLeaveOfAbsence', '180px;', 'sm', modalInput);
  }

  terminateDriver() {
    // end active leave
    let modalInput = {
      fromLOAReport: this.driverBaseInfo.fromLOAReport != null ? this.driverBaseInfo.fromLOAReport : true,
      driverId: this.driverBaseInfo.driverId,
      client: this.driverBaseInfo.clientCode
    };
    this.driverHistoryProfileService.notifyModal('Terminate Driver', 'terminateDriver', '180px;', 'sm', modalInput);
  }

  private setViewRights() {
    // set the view rights booleans according to whats in user rights
    this.canTerminateDriver = this.isAccessAllowed(54);
  }

  private isAccessAllowed(userRightsId: number) {
    let retVal: boolean = false;

    if (this._userRights) {
      let thisRight = this._userRights.filter(r => r.userRightId == userRightsId);
      if ((thisRight.length > 0) && (thisRight[0].permission == 'allow')) {
        retVal = true;
      }
    }

    return retVal;
  }

  cancel() {
    // notify the modal window to close
    this.safetyModalWindowService.notifyModalClose();
  }

}
