<div class="quickSearchContainerDataGrid">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" >
  <div class="col-md-8">
    <H3>
      BI Removed Drivers
    </H3>
  </div>
  <div class="col-md-4" style="text-align:right">
    <button class="btn btn-primary" (click)="submit()">
      Submit All Selected Drivers
    </button>
  </div>
</div>
<div class="row">

  <div class="col-md-12">
    <app-loading-panel *ngIf="!configObject"></app-loading-panel>
    <div *ngIf="configObject">
      <app-data-table
        [columns]="configObject.fields"
        [data]="configObject.data"
      ></app-data-table>

    </div>
    <div id="reportFooter">
      <div class="left"> Fleet Response Confidential</div>
      <div class="right"> Report run {{today | date :'short' }}</div>
    </div>
  </div>

</div>
