<div class="row my-5">
  <div class="col-md-12">
    <div class="card card-body">
      <div class="row">
        <div class="col-md-3 py-3">
          My Follow-up Date:
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="fill">
            <mat-label *ngIf="!followUpDate" class="lbl-start-date">Follow-up Date</mat-label>
            <input class="input-date" matInput disabled [matDatepicker]="startDatePicker" [(ngModel)]="followUpDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker disabled="false">
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p class="mb-0">Please type in your note and click the submit button:</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <textarea class="form-control px-2" rows="5" [(ngModel)]="note"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div>
            <mat-slide-toggle 
              [checked]="emailRequested" 
              [(ngModel)]="emailRequested" >Email a follow-up reminder on this date
            </mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="row">
        <span class="col-md-12">
          <button class="btn btn-default" (click)="upsertFollowUpDate()">Submit</button>
          <button class="btn btn-default" (click)="cancelUpsertFollowUpDate()">Exit</button>
        </span>
      </div>
    </div>
  </div>
</div>
