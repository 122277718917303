<div class="row">
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <h4 class="text-muted" style="font-weight:bold">WA and AB Abstract Review</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a class="driverProfileLink" (click)="openDriverProfile()">
          {{driverInfo?.fullName}}{{driverInfo?.employeeId ? ' (' + driverInfo?.employeeId.trim() + ')' : ''}}
        </a>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div style="float:right">
      <button class="btn btn-primary" (click)="submit()">
        Submit
      </button>
      <button type="button" (click)="cancel()" class="btn btn-primary">
        Exit
      </button>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h5 class="text-muted" style="font-weight:bold">Additional Information</h5>
      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">
          Client:
        </div>
        <div class="col-md-10 readOnlyFormLayoutBold">
          {{driverInfo?.clientName}}
        </div>
      </div>
      <div *ngIf="abstractReviewInstructions" class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">
          Instructions:
        </div>
        <div class="col-md-10 readOnlyFormLayoutBold">
          {{abstractReviewInstructions}}
        </div>
      </div>
    </div>


    <div class="card card-body mt-3">
      <h5 class="text-muted" style="font-weight:bold">MVR Date</h5>
      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutBold">
          <label>
            Date Obtained from State/Province:
          </label>
        </div>
        <div class="col-md-10">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label class="lbl-start-date">Date obtained from state/province.</mat-label>
            <input class="input-date" [formControl]="dateObtainedFromStateControl" [max]="disableSince" matInput
              disabled [matDatepicker]="dateObtainedFromStatePicker" [(ngModel)]="dateObtainedFromState">
            <mat-datepicker-toggle matSuffix [for]="dateObtainedFromStatePicker"></mat-datepicker-toggle>
            <mat-datepicker #dateObtainedFromStatePicker disabled="false">
              <mat-datepicker-actions>
                <button mat-button
                  (click)="dateObtainedFromStatePicker.select(actualDate); dateObtainedFromStatePicker.close()">
                  <mat-icon>history</mat-icon>
                  Today
                </button>
                <button mat-button
                  (click)="dateObtainedFromStatePicker.select(null); dateObtainedFromStatePicker.close()">
                  <mat-icon>highlight_off</mat-icon>
                  Clear
                </button>
              </mat-datepicker-actions>
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="card card-body mt-3">
      <h5 class="text-muted" style="font-weight:bold">License Status</h5>
      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">
          <!-- [ngClass]="{ 'label-validation-error': formSettingsDictionary['licenseStatus'].formControl.invalid, 'date-picker-label':true }" -->
          <label id="lblLicenseStatus" for="selLicenseStatus">
            License Status:
          </label>
        </div>
        <div class="col-md-10 readOnlyFormLayoutBold">
          <mat-form-field appearance="fill" class="select-container w-100">
            <mat-label>Select Status</mat-label>
            <mat-select [(value)]="licenseStatusString">
              <mat-option *ngFor="let option of licenseStatuses" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel">
          &nbsp;
        </div>
        <div class="col-md-10">
          <div class="switch" style="padding-top:1rem">
            <label>
              <input type="checkbox" [checked]="notifyDriver" [(ngModel)]="notifyDriver" />
              <span class="lever"></span>
            </label>
            <span [ngClass]="{'align-middle':true, 'labelSwitchSelected':notifyDriver}">Notify driver of invalid
              status</span>
          </div>
          <div class="switch" style="padding-top:1rem">
            <label>
              <input type="checkbox" [checked]="requiresReview" [(ngModel)]="requiresReview" />
              <span class="lever"></span>
            </label>
            <span [ngClass]="{'align-middle':true, 'labelSwitchSelected':requiresReview}">MVR needs further review by
              client</span>
          </div>
          <div>
            <p class="mb-0">Please enter your review notes for the client:</p>
            <textarea class="form-control px-2" rows="5" [(ngModel)]="note" [disabled]="!requiresReview"
              maxlength="1000"></textarea>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h5 class="text-muted" style="font-weight:bold">Attachments</h5>
      <app-dq-file-panel *ngIf="abstractReview&&abstractReview.fileName&&abstractReview.documentId"
        [documentId]="abstractReview.documentId" [fileName]="abstractReview.fileName"
        [uploadDate]="abstractReview.uploadDate">
      </app-dq-file-panel>

    </div>
  </div>
</div>