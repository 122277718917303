<div>
  <div>
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeIEModal()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="labelIELearnMoreModal">Learn More - Browser Compatibility</h4>
      </div>
      <div class="modal-body">
        <div class="row" style="padding:10px">
          <div class="col-md-12">
            In order to provide you with the best experience, we recommend you open Fleet Response Visibility in Microsoft Edge or another modern browser like Chrome or Firefox.
          </div>
        </div>
        <div class="row" style="padding:10px">
          <div class="col-md-12">
            Continuing with Internet Explorer may cause unexpected results, slow performance, or reduced functionality.
          </div>
        </div>
        <div class="row" style="padding:10px">
          <div class="col-md-12">
            Additionally, Fleet Response Visibility in Internet Explorer may need to reload more frequently than in other browsers.
          </div>
        </div>
        <div class="row" style="padding-top:10px;padding-left:10px;padding-right:10px;">
          <div class="col-md-12">
            <h4 style="font-weight:bold">Download:</h4>
          </div>
        </div>
        <div class="row" style="padding:10px">
          <div class="col-md-3">
            <a class="pl-0" href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
              <img id="linkChrome" src="images/ChromeIcon.jpg" alt="Google Chrome">
            </a>
          </div>
          <div class="col-md-3">
            <a class="pl-0" href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank" rel="noopener noreferrer">
              <img id="linkEdge" src="images/EdgeIcon.png" alt="Microsoft Edge">
            </a>
          </div>
          <div class="col-md-3">
            <a class="pl-0" href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener noreferrer">
              <img id="linkFireFox" src="images/FireFoxIcon.jpg" alt="FireFox">
            </a>
          </div>
          <div class="col-md-3">
            <a class="pl-0" href="https://www.apple.com/safari/" target="_blank" rel="noopener noreferrer">
              <img id="linkSafari" src="images/SafariIcon.jpg" alt="Safari">
            </a>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button"
                 aria-label="Open In Edge"
                (click)="openInEdge()"
                class="btn btn-primary">
          Open In Edge
        </button>
        <button type="button" class="btn btn-primary" style="margin-bottom:6px" aria-label="Close" (click)="closeIEModal()">Close</button> 
      </div>
    </div>
  </div>
</div>
