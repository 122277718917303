  <div>
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeErrorModal()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="labelErrorModal">Error</h4>
      </div>
      <div class="modal-body">
        <div class="row" style="padding:10px" *ngIf="(!errorArray)||(errorArray.length <= 0)||(!errorArray[0])">
          <div class="col-md-12">
            An error has occurred
          </div>
        </div>
        <ng-container *ngFor="let err of errorArray">
          <div class="row" style="padding:10px" *ngIf="err && err.message && (err.message.length >0)">
            <div class="col-md-12">
              {{err.message}}
            </div>
          </div>
          <div class="row" style="padding:10px" *ngIf="err && err.title && (err.title.length >0)">
            <div class="col-md-12">
              {{err.title}}
            </div>
          </div>
          <div class="row" style="padding:10px" *ngIf="err && err.referenceId && (err.referenceId.length >0)">
            <div class="col-md-4">
              Reference Id:
            </div>
            <div class="col-md-8">
              {{err.referenceId}}
            </div>
          </div>
          <div class="row" style="padding:10px" *ngIf="err && (err.detail || err.modelState)">
            <div class="col-md-12">
              Detail:
            </div>
          </div>
          <ng-container *ngFor="let errDetail of calcErrorDetail(err)">
            <div class="row" style="padding-left:10px;padding-right:10px;">
              <div class="col-md-12">
                {{errDetail}}
              </div>
            </div>
          </ng-container>

        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" style="margin-bottom:6px" aria-label="Close" (click)="closeErrorModal()">Close</button>
      </div>
    </div>
  </div>
