//Angular
import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
//Third Party
import { Subscription } from "rxjs";
//App
import { ClientSelectionService } from "../../components/client-selection-service/client-selection-service.component";
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { CorporateStructure } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { OnPremDriverService } from "../../components/on-prem-service/on-prem-driver-service.component";
import { LoadingSpinnerService } from "../../services/loading-spinner-service/loading-spinner.service";
import { AlertService } from "../../services/alert-service/alert.service";

@Component({
  selector: "app-safety-filter",
  templateUrl: "./safety-filter.component.html",
})
export class SafetyFilterComponent implements OnInit, OnDestroy {
  clientSelectedArray: Array<string> = [];
  arrCH: Array<CorporateStructure>;
  clientSelectionSubscription: Subscription;
  baseUrl: string;
  userSelections: SafetyFilterUserSelections;
  parentElementGuid: string;
  parentElementIndeterminate: boolean;

  constructor(
    private readonly router: Router,
    private readonly clientSelectionService: ClientSelectionService,
    private readonly lineOfBusinessService: LineOfBusinessService,
    private readonly http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private readonly onPremService: OnPremDriverService,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly errorService: ErrorModalService,
    private readonly alertService: AlertService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;

    this.clientSelectionSubscription =
      clientSelectionService.clientSelectedInfo$.subscribe(
        (clientSelectedArray) => {
          const temp = clientSelectedArray;
          if (temp[2]) {
            const cArr = temp[2].clientSelectedArray;
            if (cArr && cArr.length > 0) {
              if (!this.arrayCompare(cArr, this.clientSelectedArray)) {
                this.clientSelectedArray = cArr;
                this.getCHInfo(null, null);
              }
            } else {
              this.arrCH = null;
            }
          } else {
            this.arrCH = null;
          }
        }
      );
  }
  updateCheckboxState() {
    const cbEl = document.getElementById(
      "cbFilter_" + this.parentElementGuid
    ) as HTMLInputElement;
    if (cbEl) {
      cbEl.indeterminate = this.parentElementIndeterminate;
    }
  }
  ngOnInit() {
    // set line of business to safety and client choice to
    // safety choice, if not already initialized to Safety
    const lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob !== 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
      const cArr = this.clientSelectionService.getClientSelectedArrayValue(2);
      if (cArr && cArr.length > 0) {
        this.clientSelectedArray = cArr;
        this.getCHInfo(null, null);
      } else {
        this.arrCH = null;
      }
    }
  }

  private getCHInfo(
    parentElementId: string,
    callingElement: CorporateStructure
  ) {
    this.loadingSpinnerService.show();

    let parentElementParam = "";
    if (parentElementId) {
      parentElementParam = "/" + parentElementId.toString();
    }

    const endpoint = "hierarchy/structureForFilter/";
    this.onPremService
      .get(endpoint + this.clientSelectedArray.join(",") + parentElementParam)
      .subscribe({
        next: (data) => {
          const arrCH = data as Array<CorporateStructure>;
          this.processIsSelected(arrCH);

          this.parentElementGuid = arrCH[0].elementGuid;
          this.parentElementIndeterminate = arrCH[0].indeterminate;

          if (!parentElementId) {
            // init arrCH if parentElement=null
            this.arrCH = arrCH;
            this.arrCH.forEach((c) => {
              if (c.childElements && c.childElements.length > 0) {
                this.processIsSelected(c.childElements);
              }
            });
          } else {
            // assign to childElements of callingElement
            callingElement.childElements = arrCH;
          }
          setTimeout(this.updateCheckboxState.bind(this), 500);
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      }
      );
  }

  private processIsSelected(arrCH: Array<CorporateStructure>) {
    // updates boxChecked and indeterminate properties based on isSelected value
    arrCH.forEach((c) => {
      switch (c.isSelected) {
        case 0:
          // not checked
          c.boxChecked = false;
          c.indeterminate = false;
          break;
        case 1:
          // checked
          c.boxChecked = true;
          c.indeterminate = false;
          break;
        case 2:
          // indeterminate
          c.boxChecked = false;
          c.indeterminate = true;
          break;
      }
    });
  }

  saveFilter() {
    this.loadingSpinnerService.show();
    this.getUserSelections(this.arrCH, true);

    var param = {
      clientCode: this.userSelections.ClientCode,
      elementsToRemove: this.userSelections.ElementsToRemove
        ? this.userSelections.ElementsToRemove.join(",")
        : null,
      elementsToAdd: this.userSelections.ElementsToAdd
        ? this.userSelections.ElementsToAdd.join(",")
        : null,
    };
    this.onPremService
      .put("filters/statcenter", JSON.stringify(param))
      .subscribe({
        next: () => {
          //let num = data as number;
          //this.alertService.setAlertObject({ title: 'Filter Applied', message: 'Your filter has been set to: ' + num.toString() + ' groups.' })
          //this.alertService.setAlertObject({ title: 'Filter Applied', message: 'Your filter has been updated.' })
          this.alertService.showSuccessAlert(
            "Your filter has been updated.",
            "end",
            "top",
            5000
          );
          this.router.navigate(["/statcenter/", 2]);
        },
        error: (err: HttpErrorResponse) => {
          this.loadingSpinnerService.show();
          this.errorService.setErrorObject(err.error);
        }
      }
      );
  }

  private getUserSelections(
    arrThisLevel: Array<CorporateStructure>,
    clearPrevious: boolean
  ) {
    if (!this.userSelections) {
      this.userSelections = new SafetyFilterUserSelections(
        this.clientSelectedArray[0]
      );
    } else if (clearPrevious) {
      this.userSelections.ElementsToAdd = [];
      this.userSelections.ElementsToRemove = [];
    }
    const arrUpdated = arrThisLevel.filter((c) => c.updatedByUser);
    const arrIdAdd = arrUpdated.filter((c) => c.boxChecked).map((c) => c.elementGuid);
    const arrIdRm = arrUpdated.filter((c) => !c.boxChecked && !c.indeterminate).map((c) => c.elementGuid);

    this.userSelections.ElementsToAdd = this.userSelections.ElementsToAdd.concat(arrIdAdd);
    this.userSelections.ElementsToRemove = this.userSelections.ElementsToRemove.concat(arrIdRm);

    arrUpdated.forEach((c) => {
      if (c.childElements) {
        this.getUserSelections(c.childElements, false);
      }
    });
  }

  private arrayCompare(a1, a2) {
    return a1.length === a2.length && a1.every((v, i) => v === a2[i]);
  }

  ngOnDestroy() {
    this.clientSelectionSubscription.unsubscribe();
  }
}

export class SafetyFilterUserSelections {
  ClientCode: string;
  ElementsToAdd: Array<string>;
  ElementsToRemove: Array<string>;

  constructor(client: string) {
    this.ClientCode = client;
    this.ElementsToAdd = [];
    this.ElementsToRemove = [];
  }
}
