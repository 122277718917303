import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnPremDriverService } from '../../../components/on-prem-service/on-prem-driver-service.component';
import { MvrExportDriverInfo } from '../../../safety/mvr-export/mvr-export.component';
import { DriverFilterCriteria } from '../driver-filter-criteria';
import { Subject } from 'rxjs';
// Do not provide this in root. Components should add this service to their list of providers so they can maintain their own instance.
@Injectable()
export class FilteredDriversService {

  private _DriverInfo: Array<MvrExportDriverInfo>;
  private _DriverInfo$ = new Subject<Array<MvrExportDriverInfo>>();
  public DriverInfo$ = this._DriverInfo$.asObservable();

  constructor(
    private _onPremService: OnPremDriverService
  ) { }

  public changeSelectionCriteria(newCriteria: DriverFilterCriteria) {
    this._onPremService.post('tasks/batch/toPdf/drivers', JSON.stringify(newCriteria))
      .subscribe({
        next: (data: Array<MvrExportDriverInfo>) => {
          this.processDriverInfo(data);
        },
        error: (err: HttpErrorResponse) => {
          this._DriverInfo$.error(err);
        }
      }
      );
  }
  private processDriverInfo(data: Array<MvrExportDriverInfo>) {
    this._DriverInfo = data;
    this._DriverInfo$.next(this._DriverInfo);
  }
}
