<div class="row" [formGroup]="multipleServicesForm">
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <app-order-assign-choices
            [availableChoices]="safetyProcesses"
            [parentForm]="multipleServicesForm"
            [uiMap]="uiMap"
            [selectorPrefix]="formGroupNameInput"
          ></app-order-assign-choices>
        </div>
      </div>
    </div>
    <div *ngIf="hideMonitoringControl === false" class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <app-monitoring-status
            [parentForm]="multipleServicesForm"
          ></app-monitoring-status>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3 mvrRequestFilterContainer">
          <h4 class="card-title text-muted wrap-this">Without an MVR Request</h4>
          <div>Exclude drivers who had a MVR in this time period:</div>
          <div class="validation-error"
            *ngIf="this.mvrRequestFilter.valid === false"
          >A valid date is required.</div>
          <div class="row mt-2">
            <label class="col-sm-2" ng >From:</label>
            <mat-form-field appearance="fill" class="col-sm-4 date-picker-container">
              <input class="input-date" matInput disabled [matDatepicker]="startDatePicker" [min]="minDate" [max]="actualDate" formControlName="mvrRequestFilter">
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker disabled="false">
                <mat-datepicker-actions>
                  <button mat-button (click)="startDatePicker.select(actualDate); startDatePicker.close()">
                    <mat-icon>history</mat-icon>
                    Today
                  </button>
                  <button mat-button (click)="startDatePicker.select(null); startDatePicker.close()">
                    <mat-icon>highlight_off</mat-icon>
                    Clear
                  </button>
                </mat-datepicker-actions> 
              </mat-datepicker>
            </mat-form-field>
            <label class="col-sm-6">Through today.</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <app-driver-type
            [parentForm]="multipleServicesForm"
            [showLicenseVerificationOption]="true"
            [selectorPrefix]="formGroupNameInput"
          ></app-driver-type>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <app-driver-risk
          [parentForm]="multipleServicesForm"
          [selectorPrefix]="formGroupNameInput"
          ></app-driver-risk>
        </div>
      </div>
    </div>
  </div>
</div>
