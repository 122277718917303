<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Telematics</h4>
          <app-loading-panel *ngIf="!driverTelTableConfig"></app-loading-panel>
          <div *ngIf="driverTelTableConfig">
            <app-data-table 
              [columns]="driverTelTableConfig.fields"
              [data]="driverTelTableConfig.data"
              noRecordsLabel="No Telematics information"
              (triggerOpenModal)="openModal($event)"
            >
        </app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
