<div class="quickSearchContainerDataGrid">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" >
  <div class="col-md-12">
    <h4>Re-Activate Employee</h4>
  </div>
</div>
<div class="row">
  <div class="col-md-12">   
    <div *ngIf="tableConfig">
      <app-data-table
        [columns]="tableConfig.fields"
        [data]="tableConfig.data"
        [showPagination]="true"
        [showPageSize]="true"
        (triggerOpenModal)="openModal($event)"
      ></app-data-table>
    </div>
  </div>
</div>
