<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" *ngIf="!_tabs" >
  <div class="col-md-12">
    <p><em>Loading...</em></p>
  </div>
</div>
<ng-container *ngIf="_tabs">
  <div class="row" >
    <div class="col-sm-12">
      <h4 class="h4-responsive pl-4">Claim: {{_claimId}}</h4>
    </div>
  </div>
  <!-- Claim Tabs -->
  <mat-card appearance="outlined" class="claim-card-container">
    <mat-tab-group class="claim-tabs-container">
      <mat-tab *ngFor="let tab of _tabs" [label]="tab.displayName">
          <mat-card-content>
          <app-accident-claim-tab-loss-notice *ngIf="showThisTab(tab,1)"></app-accident-claim-tab-loss-notice>
          <app-accident-claim-info *ngIf="showThisTab(tab,2)" [userRights]="_userRights"
            #approvalTab></app-accident-claim-info>
          <app-accident-claim-unit *ngIf="showThisTab(tab,3)"></app-accident-claim-unit>
          <app-accident-claim-repair-first-party *ngIf="showThisTab(tab,11)"></app-accident-claim-repair-first-party>
          <app-accident-claim-repair-third-party *ngIf="showThisTab(tab,12)"></app-accident-claim-repair-third-party>
          <app-accident-claim-total-loss *ngIf="showThisTab(tab,14)"></app-accident-claim-total-loss>
          <app-accident-claim-subro-outbound *ngIf="showThisTab(tab,5)"
            [userRights]="_userRights"></app-accident-claim-subro-outbound>
          <app-accident-claim-subro-inbound *ngIf="showThisTab(tab,13)"
            [userRights]="_userRights"></app-accident-claim-subro-inbound>
          <app-accident-claim-at-fault *ngIf="showThisTab(tab,6)"></app-accident-claim-at-fault>
          <app-accident-claim-rental *ngIf="showThisTab(tab,7)"></app-accident-claim-rental>
          <app-accident-claim-invoices *ngIf="showThisTab(tab,8)"></app-accident-claim-invoices>
          <app-accident-claim-supports *ngIf="showThisTab(tab,9)" [userRights]="_userRights"></app-accident-claim-supports>
          <app-accident-claim-repair-approval *ngIf="showThisTab(tab,15)"></app-accident-claim-repair-approval>
          <app-accident-claim-subro-approval *ngIf="showThisTab(tab,16)"
            [userRights]="_userRights"></app-accident-claim-subro-approval>
          <app-accident-claim-supplemental-data *ngIf="showThisTab(tab,17)"></app-accident-claim-supplemental-data>
          <app-accident-claim-edit-claim *ngIf="showThisTab(tab,26)"
            (onClaimUpdated)="onClaimUpdated()"></app-accident-claim-edit-claim>
          <app-accident-claim-arbitration *ngIf="showThisTab(tab,27)"></app-accident-claim-arbitration>
          <p *ngIf="showEmptyTab(tab)">This is the {{tab.displayName}}</p>
        </mat-card-content>
        </mat-tab>
    </mat-tab-group>
  </mat-card>
  <!-- /.Claim Tabs -->
</ng-container>
