//Angular
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
//Third Party
import { Subscription, Observable, forkJoin } from 'rxjs';
//APP
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { DriverProfile, DQFileManagementBaseInfo, PhoneUsPipe, DQFileManagementEmployerInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';

@Component({
  selector: 'app-dq-driving-history',
  templateUrl: './dq-driving-history.component.html',
  styleUrls: ['./dq-driving-history.component.scss'],
  providers: [PhoneUsPipe]
})

export class DQDrivingHistoryComponent implements OnInit, OnDestroy {
  private routeSub: Subscription;
  private driverId: string;
  private driverProcessAssignedId: string;
  private returnUrl: string;
  drivingHistory: DQDrivingHistoryInfo;
  driverInfo: DriverProfile;
  verificationUnsuccessfulOptions: Array<any>;
  tableConfig: any;
  gtOptions: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private onPremService: OnPremDriverService,
    private lineOfBusinessService: LineOfBusinessService,
    private driverHistoryProfileService: DriverHistoryProfileService,
    private phoneNumberPipe: PhoneUsPipe,
    private datePipe: DatePipe,
    private loadingSpinnerService: LoadingSpinnerService,
    private alertService: AlertService,
    private errorService: ErrorModalService
  ) {
    //get the returnUrl if set
    var nav = this.router.getCurrentNavigation();
    this.returnUrl = nav?.extras?.state?.returnUrl || "";
  }

  ngOnInit() {
    //make sure LOB=2
    //this.lineOfBusinessService.setLineOfBusiness(2);
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    this.routeSub = this.route.params.subscribe(params => {
      this.driverId = params['driverId'];
      this.driverProcessAssignedId = params['driverProcessAssignedId'];
      this.initAll(true);
    });
  }

  private initAll(updateBaseInfo: boolean) {
    let endpoint = "";
    if (this.driverProcessAssignedId) {
      endpoint = "driverQualification/" + this.driverId + "/drivinghistory/" + this.driverProcessAssignedId;
    } else {
      endpoint = "driverQualification/" + this.driverId + "/drivinghistory/latest";
    }
    if (endpoint.length > 0) {
      let arrObs: Array<Observable<Object>> = [
        this.onPremService.get(endpoint)
      ]
      if (updateBaseInfo) {
        arrObs.push(this.onPremService.get("lookup/verificationunsuccessfulreasons"));
      }
      this.loadingSpinnerService.show();
      forkJoin(arrObs).subscribe({
        next: (data) => {
          this.drivingHistory = data[0] as DQDrivingHistoryInfo;
          if (updateBaseInfo) {
            let verOptions = data[1] as Array<DrivingHistoryVerificationUnsuccessfulReason>;
            this.getVerificationUnsuccessfulOptions(verOptions);
          }

          if (!this.drivingHistory.documentId) {
            //alert('no document, go to DQ File Management view');
            this.returnToListing();
          } else {
            this.drivingHistory.employmentHistory.forEach(h => {
              if (h.verificationUnsuccessfulReasonId) {
                h.verificationUnsuccessfulReasonIdString = h.verificationUnsuccessfulReasonId.toString();
              } else {
                h.verificationUnsuccessfulReasonIdString = null;
              }
              h.reasonOptions = this.verificationUnsuccessfulOptions;
            });
            if (updateBaseInfo) {
              let arrObs: Array<Observable<Object>> = [
                this.driverHistoryProfileService.getDriverHistoryProfileBaseData(this.driverId)
              ];

              forkJoin(arrObs).subscribe({
                next: (profile) => {
                  this.driverInfo = profile[0] as DriverProfile;
                  this.loadingSpinnerService.hide();
                },
                error: (err: HttpErrorResponse) => {
                  //this.toastrService.error(err.error.toString());
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
            }

            this.setEmployerHistoryTableConfig(this.drivingHistory.employmentHistory);
          }
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    } else {
      this.errorService.setErrorObject({ message: 'Invalid Parameters' });
    }
  }

  private getDriverInfo(driverId: string) {
    this.driverHistoryProfileService.getDriverHistoryProfileBaseData(driverId).subscribe({
      next: (data) => {
        this.driverInfo = data as DriverProfile;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private getVerificationUnsuccessfulOptions(tempin: Array<DrivingHistoryVerificationUnsuccessfulReason>) {
    const tempout: Array<any> = [];
    tempin.forEach((item: DrivingHistoryVerificationUnsuccessfulReason, index: number) => {
      const tempobj = {
        value: item.id.toString(),
        label: item.description
      };
      tempout.push(tempobj);
    })
    this.verificationUnsuccessfulOptions = tempout;
  }

  private setEmployerHistoryTableConfig(employerHistory: Array<DQFileManagementEmployerInfo>) {
    let columns = [
      { name: "Employer Name", prop: "employerName", title: "Employer Name", visible: true, dataType: null, columnClass: null },
      { name: "Phone", prop: "employerPhone", title: "Phone", visible: true, dataType: "phone", columnClass: null },
      { name: "From", prop: "startDate", title: "From", visible: true, dataType: "date", columnClass: null },
      { name: "To", prop: "endDate", title: "To", visible: true, dataType: "date", columnClass: null },
      { name: "Position Name", prop: "positionHeld", title: "Position Name", visible: true, dataType: null, columnClass: null },
      { name: "Contact Attempts", prop: "contactAttemptCount", title: "Contact Attempts", visible: true, dataType: "number", columnClass: null },
      { name: "Verified", prop: "isVerified", title: "Verified", visible: true, dataType: "boolean", columnClass: null },
      { name: "Verification Complete", prop: "isVerificationAttemptComplete", title: "Verification Complete", visible: true, dataType: "boolean", columnClass: null },
      { name: "Reason", prop: "verificationUnsuccessfulReasonIdString", title: "Reason", visible: true, dataType: null, columnClass: "reasonColumnClass" },
      { name: "ReasonReadOnly", prop: "verificationUnsuccessfulReason", title: "ReasonReadOnly", visible: false, dataType: null, columnClass: null },
      { name: "ReasonOptions", prop: "reasonOptions", title: "ReasonOptions", visible: false, dataType: null, columnClass: null },
      { name: "Attempt Notes", prop: "notes", title: "Attempt Notes", visible: true, dataType: null, columnClass: null },
      { name: "Attempted", prop: "attemptedButton", title: "Attempted", visible: true, dataType: "button", columnClass: null },
      { name: "Verified", prop: "verifiedButton", title: "Verified", visible: true, dataType: "button", columnClass: null },
      { name: "driverId", prop: "driverId", title: "driverId", visible: false, dataType: null, columnClass: null },
      { name: "driverProcessAssignedId", prop: "driverProcessAssignedId", title: "driverProcessAssignedId", visible: false, dataType: null, columnClass: null },
      { name: "employmentHistoryId", prop: "employmentHistoryId", title: "employmentHistoryId", visible: false, dataType: null, columnClass: null }
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        columnClass: (row, col) => {
          return s.columnClass;
        },
        component: this.formFieldComponent(s.prop),
        componentObject: s.prop !== "verificationUnsuccessfulReasonIdString" ? null : {
          reasonOptions: this.verificationUnsuccessfulOptions
        },
        columnComponent: (s.prop === "notes" ? { type: DQDrivingHistoryVerificationUnsuccessfulReasonOtherComponent } : (s.prop === "verificationUnsuccessfulReasonIdString" ? { type: DQDrivingHistoryVerificationUnsuccessfulReasonComponent } : null)),
        hidden: !s.visible,
        render: row => {
          let r = row[s.prop];

          switch (s.dataType) {
            case "date":
              try {
                r = this.datePipe.transform(r, 'MM/dd/yyyy');
              } catch (e) { }
              break;
            case "boolean":
              if (r != null) {
                // convert to Yes/No text
                try {
                  r = (r) ? "Yes" : "No";
                } catch (e) { }
              }
              break;
            case "phone":
              try {
                r = this.phoneNumberPipe.transform(r);
              } catch (e) { }
              break;
            case "button":
              let strDisabled = (row['isVerificationAttemptComplete'] ? ' disabled=true' : '');
              r = '<button class="btn btn-xs btn-default my-0 p-1"' + strDisabled + '>' + s.title + '</button>';
              break;
            default:
              r = row[s.prop];
          }

          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          if (s.prop === "reasonOptions") {
            val = this.verificationUnsuccessfulOptions;
          }

          return val;
        },
        click: row => {
          if (s.prop === "verifiedButton") {
            const input = {
              employmentHistoryId: row['employmentHistoryId'],
              contactAttempPrev: row['contactAttemptCount'],
              verified: true,
              verificationUnsuccessfulReasonId: null,
              notes: null
            };
            return this.submitAttempt(input);
          }
          if (s.prop === "attemptedButton") {
            const input = {
              employmentHistoryId: row['employmentHistoryId'],
              contactAttempPrev: row['contactAttemptCount'],
              verified: false,
              verificationUnsuccessfulReasonId: row['verificationUnsuccessfulReasonIdString'],
              notes: row['notes']
            };
            return this.submitAttempt(input);
          }
        }
      });
    });

    this.tableConfig = {
      settings: settings,
      fields: fields,
      data: employerHistory
    }

    this.gtOptions = (employerHistory.length > 1 ? { numberOfRows: employerHistory.length } : {});
  }

  openDriverProfile() {
    this.router.navigate(["/driver/history-profile/" + this.driverId]);
  }

  submitAttempt(data: any) {
    let errorObject = null;
    let postData: DQDrivingHistorySubmitInfo = {
      DriverProcessAssignedId: this.drivingHistory.driverProcessAssignedId,
      DriverId: this.drivingHistory.driverId,
      EmploymentHistoryId: data?.employmentHistoryId,
      ContactAttemptCount: (data?.contactAttempPrev ? data?.contactAttempPrev + 1 : 1),
      IsVerified: data?.verified
    }

    if (!data?.verified) {
      if (data?.verificationUnsuccessfulReasonId) {
        postData.VerificationUnsuccessfulReasonId = data?.verificationUnsuccessfulReasonId;
      }

      if (data?.notes) {
        postData.Notes = data?.notes;
      }
    }

    /* 2020-01 - do not require reason for attempt
    if (!verified) {
      
      if (verificationUnsuccessfulReasonId) {
        postData.VerificationUnsuccessfulReasonId = verificationUnsuccessfulReasonId;
        postData.Notes = notes;
      } else {
        errorObject = { message: "Please select a Reason" };
      }

    }
    */

    if (errorObject) {
      this.errorService.setErrorObject(errorObject);
    } else {
      this.loadingSpinnerService.show();
      const endpoint = `driverQualification/${this.drivingHistory.driverId.toString()}/employers/
      ${this.drivingHistory.driverProcessAssignedId.toString()}/${data?.employmentHistoryId.toString()}/verificationattempt`;
      
      this.onPremService.put(endpoint, JSON.stringify(postData)).subscribe({
        next: () => {
          this.alertService.showSuccessAlert('Driving History', 'end', 'top', 5000);
          this.initAll(false);
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      })
    }

  }

  cancel() {
    //alert('go to DQ File Management view');
    this.returnToListing();
  }

  returnToListing() {
    if (this.returnUrl.length > 0) {
      this.router.navigate([this.returnUrl]);
      return;
    }

    //fallback to the default location
    this.router.navigate(['/dq/filemanagement']);
  }

  formFieldComponent(prop: string) {
    let component = "";

    if (prop === "notes") {
      component = 'dqDrivingHistoryVerificationAttemptNotes';
    } else {
      if (prop === "verificationUnsuccessfulReasonIdString") {
        component = 'dqDrivingHistoryVerificationReasonOptions';
      } else {
        return null;
      }
    }

    return component;
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

}

// custom component for reason dropdown
@Component({
  selector: 'app-dq-driving-history-verification-reason-options',
  template: `
    <mat-form-field appearance="fill" class="select-container w-100" *ngIf="!row?.isVerificationAttemptComplete">
      <mat-label>Reason</mat-label>
        <mat-select #selectTitleHolder="matSelect" [(value)]="row.verificationUnsuccessfulReasonIdString">
          <mat-option *ngFor="let option of row?.reasonOptions" [value]="option.value">
            {{option.label}}
          </mat-option>
        </mat-select>        
      </mat-form-field>
    <span *ngIf="row?.isVerificationAttemptComplete">{{row?.verificationUnsuccessfulReason}}</span>
  `
})
export class DQDrivingHistoryVerificationUnsuccessfulReasonComponent {
  @Input() row: any;
}

// custom component for reason Other
@Component({
  selector: 'app-dq-driving-history-verification-attempt-notes',
  template: `
    <input type="text" class="dqDrivingHistoryInput" *ngIf="!row?.isVerificationAttemptComplete" 
            [(ngModel)]="row.notes"
            placeholder="Other Description" />
  `
})
export class DQDrivingHistoryVerificationUnsuccessfulReasonOtherComponent {
  @Input() row: any;
}
export interface DQDrivingHistoryInfo extends DQFileManagementBaseInfo {
  employmentHistory: Array<DQFileManagementEmployerInfo>;
}



export interface DrivingHistoryVerificationUnsuccessfulReason {
  id: number;
  secondaryId: number;
  description: string;
  secondaryCode: string;
}

export interface DQDrivingHistorySubmitInfo {
  DriverProcessAssignedId: number;
  DriverId: number;

  EmploymentHistoryId: number;
  ContactAttemptCount: number;
  IsVerified: boolean;
  VerificationUnsuccessfulReasonId?: number;
  Notes?: string;
}
