//Angular
import { Component, OnInit, Input, QueryList, ViewChildren, AfterViewChecked } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { UntypedFormControl, Validators } from '@angular/forms';
//Third Party
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
//APP
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { DcDriverProfileService } from '../dc-driver-profile/dc-driver-profile-service.component';
import { AlertModalService } from "../../components/alert-modal/alert-modal-service.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { ConfirmModalComponent } from "../../components/confirm-modal/confirm-modal.component";
import { ConfirmModalGenericComponent } from "../../components/confirm-modal-generic/confirm-modal-generic.component";
import {
  DcDriver, DcDriverEntry, DcSubscription, DcDriverMedicalCard, DcDriverLicense, DcDriverCreateResponse,
  DcRequestResponse, DcSubscriptionIdentifier, DcDriverValidateResponse, DcDriverAssignedOnlineTraining
} from '../dc-classes-and-interfaces/dc-classes-and-interfaces.component';
import { AssignProductToDriverConfirmObject } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { MatSelectOption } from '../../shared/models/mat-select-option.model';
import { MatDatepicker } from '@angular/material/datepicker';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';

@Component({
  selector: 'dc-programs-driver-slots-add',
  templateUrl: './dc-programs-driver-slots-add.component.html',
  styleUrls: ['./dc-programs-driver-slots-add.component.scss'],
})
export class DcProgramsDriverSlotsAddComponent implements OnInit, AfterViewChecked {
  @ViewChildren("selectDrivers") selectDriversHolderList: QueryList<MatSelect>;
  @ViewChildren('medicalCertificateDatePicker') medicalCertificateDatePickerList: QueryList<MatDatepicker<Date>>;
  @ViewChildren('driversLicenceExpirationDatePicker') driversLicenceExpirationDatePickerList: QueryList<MatDatepicker<Date>>;
  @Input() modalHeight: string;
  @Input() modalInput: any;
  selectDriversRef: MatSelect;
  medicalCertificateDatePickerRef: MatDatepicker<Date>;
  driversLicenceExpirationDatePickerRef: MatDatepicker<Date>;
  clientSelectedArray: Array<string>;
  companyGuid: string;
  companyId: number;
  programOptions: Array<any>;
  programChosen: string = "";
  programChosenSlots: number = 0;
  driverOptions: Array<MatSelectOption>;
  filteredDriverOptions: Array<MatSelectOption>;
  driverChosen: string = "";
  showSelectDriver: boolean = false;
  showDriverEntry: boolean = false;
  newDriverEntry: DcDriverEntry;
  dtToday: Date = new Date();
  confirmTitle: string;
  confirmMessage: string;
  confirmPrompt: string;
  toastrMessage: string;
  postNewDriverData: DcDriverEntry;
  assignConfirmed: boolean = false;
  companiesUrl = "";

  employeeIdControl = new UntypedFormControl('', [Validators.required]);
  firstNameControl = new UntypedFormControl('', [Validators.required]);
  lastNameControl = new UntypedFormControl('', [Validators.required]);
  driverEmailControl = new UntypedFormControl('', [Validators.email, Validators.required]);

  allControls: Array<UntypedFormControl> = [
    this.employeeIdControl,
    this.firstNameControl,
    this.lastNameControl,
    this.driverEmailControl
  ];

  // the overloaded constructor for the controller
  constructor(private dcService: DcDriverProfileService,
    private onPremService: OnPremDcService,
    private clientSelectionService: ClientSelectionService,
    //private companySelectionService: CompanySelectionService,
    private datePipe: DatePipe,
    private loadingSpinnerService: LoadingSpinnerService,
    private alertService: AlertService,
    private alertModalService: AlertModalService,
    private errorService: ErrorModalService,
    private dialog: MatDialog) { }

  // angular on intialization event
  ngOnInit() {
    this.companyId = this.modalInput.companyId;
    this.companyGuid = this.modalInput.companyGuid;
    //this.programOptions = this.modalInput.programOptions;
    this.showSelectDriver = (this.modalInput.addType == "existing" ? true : false);

    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(7);
    //this.companyGuidSelectedArray = this.companySelectionService.getSavedCompanyGuids(7);
    this.companiesUrl = `companies/${this.companyId?.toString()}/products`;

    this.loadingSpinnerService.show();
    this.onPremService.get(this.companiesUrl).subscribe({
      next: (data) => {
        let arrProgram = data as Array<DcSubscription>;
        if (arrProgram.length > 0) {
          this.programOptions = arrProgram.map(i => {
            // value should include expiration date if available
            let dt = (i.expirationDate ? "~" + i.expirationDate : '');
            let expDateString = (i.expirationDate ? " - Exp: " + this.datePipe.transform(i.expirationDate, "MM/dd/yyyy") : "");
            let slotsString = " (" + (i.slotsAvailable > 0 ? i.slotsAvailable.toString() : "No") + " slots available)";
            return {
              value: i.product.id.toString() + dt,
              label: i.product.description + expDateString + slotsString,
              slots: i.slotsAvailable
            }
          });
          this.loadingSpinnerService.hide();
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  ngAfterViewChecked(): void {
    if (!this.selectDriversRef && this.selectDriversHolderList?.first) {
      this.selectDriversRef = this.selectDriversHolderList.first;
      this.selectDriversRef._elementRef.nativeElement.addEventListener(
        "keydown",
        (event) => {
          if (event.code === "Space") {
            event.preventDefault();
          }
        }
      );
    }

    if (!this.medicalCertificateDatePickerRef && this.medicalCertificateDatePickerList?.first) {
      this.medicalCertificateDatePickerRef = this.medicalCertificateDatePickerList.first;
      this.medicalCertificateDatePickerRef.openedStream.subscribe(() => {
        setTimeout(() => {
          this.medicalCertificateDatePickerRef['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.medicalCertificateDatePickerRef.select(event.value);
            this.medicalCertificateDatePickerRef.close();
          })
        }, 0)
      })
    }

    if (!this.driversLicenceExpirationDatePickerRef && this.driversLicenceExpirationDatePickerList?.first) {
      this.driversLicenceExpirationDatePickerRef = this.driversLicenceExpirationDatePickerList.first;
      this.driversLicenceExpirationDatePickerRef.openedStream.subscribe(() => {
        setTimeout(() => {
          this.driversLicenceExpirationDatePickerRef['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.driversLicenceExpirationDatePickerRef.select(event.value);
            this.driversLicenceExpirationDatePickerRef.close();
          })
        }, 0)
      })
    }
  }

  showConfirmModal(): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: { confirmTitle: this.confirmTitle, confirmPrompt: this.confirmPrompt },
      width: '34em',
      minHeight: '13em',
      panelClass: 'alert-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.submitNewDriver(this.postNewDriverData);
    })
  }

  showConfirmGenericModal(): void {
    const dialogRef = this.dialog.open(ConfirmModalGenericComponent, {
      data: { confirmGenericTitle: 'Confirm Assignment', message: this.confirmMessage, yesLabel: 'Yes', noLabel: 'No' },
      width: '34em',
      minHeight: '10em',
      panelClass: 'alert-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.submitExisting();
    })
  }

  onChangeProgram(event: any) {
    this.showDriverEntry = false;
    this.programChosenSlots = this.programOptions.find(p => p.value == this.programChosen).slots;
    if (this.programChosenSlots <= 0) {
      this.showSelectDriver = false;
      this.driverChosen = "";
      let htmlMessage = 'There are no available slots for the program you selected.';
      htmlMessage += '<br/><br/>Please click OK and select a different program or,';
      htmlMessage += '<br/><br/>To purchase additional programs, training lessons, or driver slots, please <a href="http://safety.fleetresponse.com" target="_blank" rel="noopener noreferrer">click here</a>';
      this.alertModalService.setAlertObject({ title: 'No Slots Available', htmlMessage: htmlMessage })
    } else {
      if (this.modalInput.addType == "existing") {
        let programId = this.programChosen.split("~")[0];
        this.loadingSpinnerService.show();
        this.onPremService.get('companies/' + this.companyId + '/products/' + programId + '/drivers/available')
          .subscribe({
            next: (data) => {
              this.showSelectDriver = true;
              this.driverChosen = "";
              let options = data as Array<DcDriver>;
              this.driverOptions = options.map(i => {
                return {
                  value: i.driverId.toString(),
                  label: `${i.fullName} (${i.emailAddress} , ${i.employeeId})`
                }
              });
              this.filteredDriverOptions = this.driverOptions;
              this.loadingSpinnerService.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.errorService.setErrorObject(err.error);
              this.loadingSpinnerService.hide();
            }
          });
      }
    }


  }

  addNew() {
    this.showDriverEntry = true;
    this.newDriverEntry = new DcDriverEntry(this.clientSelectedArray[0], this.companyGuid);
    this.newDriverEntry.managerTrainerEmailAddresses = Array(3).fill("");
    this.newDriverEntry.ownerEmailAddress = "";
  }

  submitExisting() {
    this.loadingSpinnerService.show();
    this.addDriverToProgram(this.driverChosen);
  }

  private addDriverToProgram(driverId: string) {
    let programChosenParts = this.programChosen.split("~");
    let endpoint = `${this.companiesUrl}/${programChosenParts[0]}/drivers/${driverId}`;
    // add expirationDate if available
    if (programChosenParts.length > 1) {
      endpoint += ("?expirationDate=" + programChosenParts[1]);
    }
    this.onPremService.put(endpoint, null).subscribe({
      next: (data) => {
        let resp = data as DcRequestResponse;
        if (!resp.isSuccess) {
          this.errorService.replaceErrorObject({ message: resp.errorMessage });
          this.loadingSpinnerService.hide();
        } else {
          // refresh table for all programs
          this.programOptions.forEach(x => {
            let optionParts = x.value.split("~")
            this.dcService.setRefreshDriverSlotsData(+optionParts[0]);
          })

          this.dcService.notifyModalClose();
          this.loadingSpinnerService.hide();
        }

      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private validateDriverForm(): boolean {
    let isOK: boolean = true;
    if (this.allControls.filter(c => (c.invalid)).length > 0) {
      this.errorService.setErrorObject({ message: 'Missing or Invalid Fields.' });
      isOK = false;
    }

    return isOK;
  }

  validateNewDriver() {
    if (this.validateDriverForm()) {
      // get copy of driverInfo object
      this.postNewDriverData = JSON.parse(JSON.stringify(this.newDriverEntry));

      // create objects from input
      // medical card
      let medicalCard = null;

      if (this.newDriverEntry.medicalCardExpirationDate && (this.newDriverEntry.medicalCardExpirationDate.toString.length > 0)) {
        medicalCard = new DcDriverMedicalCard();
        medicalCard.expirationDate = new Date(this.newDriverEntry.medicalCardExpirationDate).toISOString().slice(0, 10);
        this.postNewDriverData.medicalCard = medicalCard;
      }

      // license
      let driverLicense = null;

      if (this.newDriverEntry.driverLicenseExpirationDate && (this.newDriverEntry.driverLicenseExpirationDate.toString.length > 0)) {
        driverLicense = new DcDriverLicense();
        driverLicense.expirationDate = new Date(this.newDriverEntry.driverLicenseExpirationDate).toISOString().slice(0, 10);
        this.postNewDriverData.driverLicense = driverLicense;
      }


      // assign online training
      let assignOnlineTraining = null;

      if (this.newDriverEntry.assignOnlineTrainingDateDue && (this.newDriverEntry.assignOnlineTrainingDateDue.toString.length > 0)) {
        assignOnlineTraining = new DcDriverAssignedOnlineTraining();
        assignOnlineTraining.assignedOnlineTrainingDueDate = new Date(this.newDriverEntry.assignOnlineTrainingDateDue).toISOString().slice(0, 10);
        this.postNewDriverData.assignOnlineTraining = assignOnlineTraining;
      }

      // create Subscription Identifier to send program info
      let programChosenParts = this.programChosen.split("~");
      let subId = new DcSubscriptionIdentifier(+programChosenParts[0], (programChosenParts[1]) || null);
      this.postNewDriverData.assignedProduct = subId;

      // delete unneeded properties
      delete this.postNewDriverData.label;
      delete this.postNewDriverData.driverCompanyLabel;
      delete this.postNewDriverData.languageId;
      delete this.postNewDriverData.locationId;
      delete this.postNewDriverData.emailAddressConfirm;
      delete this.postNewDriverData.medicalCardExpirationDate;
      delete this.postNewDriverData.driverLicenseExpirationDate;
      delete this.postNewDriverData.resetPassword;
      delete this.postNewDriverData.assignOnlineTraining;
      delete this.postNewDriverData.assignOnlineTrainingDateDue;

      console.log(this.postNewDriverData);

      // need to first validate driver, then post if validation passes

      let validateObs: Observable<Object> = this.onPremService.post('drivers/validate', JSON.stringify(this.postNewDriverData));

      this.loadingSpinnerService.show();
      validateObs.subscribe({
        next: (data) => {
          let resp = data as DcDriverValidateResponse;
          // process validation response, create driver action
          // will depend on validationStateId
          this.processValidationInfo(resp, this.postNewDriverData);
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });

    }
  }

  private processValidationInfo(resp: DcDriverValidateResponse, postData: any) {
    switch (resp.validationStateId) {
      case 0:
      case 1:
        // proceed to add driver
        this.toastrMessage = "Driver Created"
        this.submitNewDriver(postData);
        break;
      case 2:
        // inactive, prompt to reactivate
        this.loadingSpinnerService.hide();
        this.toastrMessage = "Driver Reactivated"
        this.confirmTitle = "Reactivate Driver?";
        this.confirmPrompt = resp.validationMessage + " Do you want to reactivate this driver?";
        this.showConfirmModal();
        break;
      case 3:
        // 'Driver exists in a different company.
        this.loadingSpinnerService.hide();
        this.alertModalService.setAlertObject({ title: 'Add Driver Error', message: resp.validationMessage + " Please contact support." });
        break;
      case 4:
        // Driver already exists in this program.'
        this.loadingSpinnerService.hide();
        this.alertModalService.setAlertObject({ title: 'Add Driver Error', message: resp.validationMessage });
        break;
      case 5:
        // driver in another program, ask to add to this program
        this.loadingSpinnerService.hide();
        this.toastrMessage = "Driver Added To Program"
        this.confirmTitle = "Driver Enrolled in Another Program";
        this.confirmPrompt = resp.validationMessage;
        this.showConfirmModal();
        break;
      case 6:
        // 'Driver exists in a different company, employee id and email do not match.
        this.loadingSpinnerService.hide();
        this.alertModalService.setAlertObject({ title: 'Add Driver Error', message: resp.validationMessage });
        break;
      default:
        this.loadingSpinnerService.hide();
        this.alertModalService.setAlertObject({ title: 'Add Driver Error', message: resp.validationMessage });
        break;
    }

  }

  submitNewDriver(postData: any) {
    let updateObs: Observable<Object> = this.onPremService.post('drivers', JSON.stringify(postData));
    this.loadingSpinnerService.show();
    updateObs.subscribe({
      next: (data) => {
        let resp = data as DcDriverCreateResponse;
        // refresh table for chosen program
        this.alertService.showSuccessAlert(this.toastrMessage, 'end', 'top', 5000);
        this.dcService.setRefreshDriverSlotsData(postData.assignedProduct.productId);
        this.dcService.notifyModalClose();
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  public confirmAssignment(): void { // only need to show a confirm if they are in TEMP and adding CSP, API will return a flag to say if the confirm should show
    let parts = this.programChosen.split("~");
    const endpoint = `${this.companiesUrl}/${parts[0]}/drivers/${this.driverChosen}/confirm/`;
    let expDate = "";
    if (parts.length > 1) {
      expDate = parts[1];
    }
    this.onPremService.post(endpoint, `{ expirationDate: ${expDate}}`).subscribe({
      next: (data) => {
        let resp = data as AssignProductToDriverConfirmObject;
        if (resp.userFacingMessageDefinitionId > 0) {
          this.confirmMessage = resp.messageBody;
          this.assignConfirmed = false;
        } else {
          this.assignConfirmed = true;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
      },
      complete: () => { // complete
        if (this.assignConfirmed) {
          this.submitExisting();
        } else {
          this.showConfirmGenericModal();
        }
      }
    })
  }

  filterOptions(value: string): void {
    const filterValue = value.toLowerCase();
    this.filteredDriverOptions = this.driverOptions.filter(option => option.label.toLowerCase().startsWith(value));
  }


  cancel() {
    this.postNewDriverData = null;
    // notify the modal window to close
    this.dcService.notifyModalClose();
  }
}
