<div class="row">
    <div class="col-md-12">
        <div class="card card-body mt-3">
            <h4 class="card-title text-muted">Address Information</h4>
            <div *ngIf="addressInformationTableConfig">
                <app-data-table
                    [columns]="addressInformationTableConfig.fields"
                    [data]="addressInformationTableConfig.data"
                    [noRecordsLabel]="addressInformationTableConfig?.noRecordsLabel"
                ></app-data-table>
            </div>
        </div>
    </div>
</div>