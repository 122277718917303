import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Inject, Input, ElementRef, ViewChild } from '@angular/core';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DriverProfile, UserRightsInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import * as Highcharts from 'highcharts';
import { Options } from 'highcharts';
import * as Highstock from "highcharts/highstock";
import { ChartOptions } from 'highcharts';
import Bullet from 'highcharts/modules/bullet';
import More from 'highcharts/highcharts-more';
import Tree from 'highcharts/modules/treemap';
import Heatmap from 'highcharts/modules/heatmap';
import Tilemap from 'highcharts/modules/tilemap';
import Exporting from 'highcharts/modules/exporting'
import bulletChart from 'highcharts/modules/bullet.src'
import { SafetyDriverRankingService } from './safety-driver-ranking-service.component';
More(Highcharts);
Bullet(Highcharts);
bulletChart(Highcharts);
More(Highcharts);
Tree(Highcharts);
Heatmap(Highcharts);
Tilemap(Highcharts);
Exporting(Highcharts);

/**
 * this is the client-side controller for the safety - training tab panel
 */
@Component({
  selector: 'safety-driver-ranking',
  templateUrl: './safety-driver-ranking.component.html',
  styleUrls: ['./safety-driver-ranking.component.css']
})

export class SafetyDriverRankingComponent implements OnInit, OnDestroy, OnChanges {
  @Input() driverProfile: DriverProfile;
  @Input() _userRights: Array<UserRightsInfo>;
  @ViewChild('container') container: ElementRef;
  Highcharts = Highcharts;
  chart: Highcharts.Chart;

  @Input() chartOptions: Highcharts.Options = ({
    chart: {
      inverted: true,
      marginLeft: 30,
      type: 'bullet',
      marginTop: 60,
      style: {
        fontFamily: "Arial, Helvetica, Gadget, sans-serif",
        textOutline: ''
      }

    },
    title: {
      text: 'Driver Ranking',
      style: {
        fontSize: "24px",
        color: "#868e96",
        align: "left"
      }
    },
    subtitle: {
      //text: 'Total Drivers With Risk Levels'
    },
    legend: {
      enabled: false,
      itemStyle: {
        fontWeight: "normal",
        fontSize: "18px",
        color: "#212529"
      }
    },
    //yAxis: {
    //  gridLineWidth: 0
    //},
    plotOptions: {
      series: {
        pointPadding: 0.0,
        borderWidth: 0,
        color: '#36496D',
        targetOptions: {
          width: '5000%'
        }
      }
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    xAxis: {
      gridLineWidth: 0,
      categories: ['']
    },
    yAxis: {
      gridLineWidth: 0,
      plotBands: [{
        from: 0,
        to: 254,
        color: '#666'
      }, {
        from: 254,
        to: 2828,
        color: '#bbb'
      }],
      labels: {
        format: '{value}%'
      },
      title: null
    },
    //series: [{
    //  data: [{
    //    y: 22,
    //    target: 27
    //  } ]
    //} as any) as Highcharts.SeriesBulletOptions],
    tooltip: {
      //pointFormat: '<b>{point.target:.2f}%</b>'
      pointFormat: 'You Are Here.'
    },
    series: [(
      {
        pointWidth: 20,
        data: [{
          y: 0,
          target: 254
        }]
      } as any) as Highcharts.SeriesBulletOptions]
  } as any) as Highcharts.Options;

  constructor(private safetyDriverRankingService: SafetyDriverRankingService,
    private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
  ) { }

  // angular on intialization event
  ngOnInit() {

    //this.chart = Highcharts.chart(this.container.nativeElement.id, this.chartOptions);
    //this.chart.showLoading();


    this.safetyDriverRankingService.getRankingByDriverId(this.driverProfile.driverId).subscribe(x => {
      ((this.chartOptions.series[0] as Highcharts.SeriesBulletOptions).data[0] as any).target = x.driverRankingValue * 100;
      ((this.chartOptions.series[0] as Highcharts.SeriesBulletOptions).data[0] as any).y = x.driverRankingValue * 100;
      ((this.chartOptions.series[0] as Highcharts.SeriesBulletOptions).data[0] as any).max = x.maxValue * 100;
      ((this.chartOptions.yAxis as any).plotBands as any[])[0].to = x.driverRankingValue * 100;
      ((this.chartOptions.yAxis as any).plotBands as any[])[1].from = x.driverRankingValue * 100;
      ((this.chartOptions.yAxis as any).plotBands as any[])[1].to = x.maxValue * 100;
      (this.chartOptions.yAxis as any).max = x.maxValue * 100;
      //this.chart.update(this.chartOptions);


      if (x.driverRankingValue > 0.5)
        this.chartOptions.subtitle.text = "Driver's risk level is better than " + (x.driverRankingValue * 100).toFixed(2) + "% of all fleet drivers at this company.";
      else
        this.chartOptions.subtitle.text = "Driver's risk level is worse than " + ((1 - x.driverRankingValue) * 100).toFixed(2) + "% of all fleet drivers at this company.";


      this.chart = Highcharts.chart(this.container.nativeElement.id, this.chartOptions);
      this.chart.reflow();
      //console.log(this.chartOptions);
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
  }






  // the default destructor
  ngOnDestroy() {

  }
}
