import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { DhpDocumentService } from '../../services/dhp-document-service/dhp-document.service';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { AuthService } from '../../components/auth/auth.service';
import {
  DhpDocumentValidation,
  DriverNote,
} from '../../safety/models';

@Component({
  selector: 'app-dhp-document-validation-remove-modal',
  templateUrl: './dhp-document-validation-remove-modal.component.html',
  styleUrls: ['./dhp-document-validation-remove-modal.component.scss']
})
export class DhpDocumentValidationRemoveModalComponent {
  documentData: DhpDocumentValidation;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: {
      documentData: DhpDocumentValidation
    },
    private readonly dialogRef: MatDialogRef<DhpDocumentValidationRemoveModalComponent>,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly errorService: ErrorModalService,
    private readonly dhpDocumentService: DhpDocumentService,
    private readonly onPremService: OnPremDriverService,
    private readonly authService: AuthService,
  ) {
    this.documentData = this.dialogData.documentData;
  }

  cancel(): void {
    this.closeModal();
  }

  openConfirmationModal(): void {
    this.submitRemoval()
    .pipe(mergeMap(this.addNoteForDocumentRemoved()))
    .subscribe({
      next: (data) => {
        this.snackBar.open('DHP Document Removed', 'Ok', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5000,
          panelClass: 'success-snackbar',
        });
        this.loadingSpinnerService.hide();
        this.router.navigate(['safety/dhpdocumentvalidationgrid']);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

    this.closeModal(true);
  }

  private addNoteForDocumentRemoved(): () => Observable<any> {
    const documentDescription = this.documentData.documentDescription;
    const userName = this.authService.user.profile.name;

    const note = new DriverNote(this.documentData.driverId);
    note.isNotification = false;
    note.note = `'${documentDescription}' was removed by ${userName}`;

    return () => this.onPremService.post('note', JSON.stringify(note));
  }

  private closeModal(result = false): void {
    this.dialogRef.close(result);
  }

  private submitRemoval(): Observable<any> {
    try {
      return this.dhpDocumentService.SaveDocumentRemove(this.documentData.documentId);
    }
    catch (err) {
      console.log(err);
      return new Observable<null>();
    }
  }

}
