//Angular
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
//Third Party
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//App
import { DashboardDateFilterRange, DashboardTile } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DashboardTileDateFilterComponent } from '../../components/dashboard-tile-date-filter/dashboard-tile-date-filter.component';
import { DashboardTileSlicerFilterComponent } from '../../components/dashboard-tile-slicer-filter/dashboard-tile-slicer-filter.component';

@Component({
  selector: 'app-dashboard-tile-filter',
  templateUrl: './dashboard-tile-filter.component.html',
  styleUrls: ['./dashboard-tile-filter.component.scss']
})
export class DashboardTileFilterComponent implements OnInit {
  dashboardTileToFilter: DashboardTile = null;
  dashboardTileFilterType: string;
  dashboardTileToFilterStartDate: string;
  dashboardTileToFilterEndDate: string;
  globalDateFilter: DashboardDateFilterRange;
  @ViewChild(DashboardTileDateFilterComponent) tileDateFilterComponent: DashboardTileDateFilterComponent;
  @ViewChild(DashboardTileSlicerFilterComponent) tileSlicerFilterComponent: DashboardTileSlicerFilterComponent;
  constructor(
    private dialogRef: MatDialogRef<DashboardTileFilterComponent>,
    @Inject(MAT_DIALOG_DATA) data: {card: DashboardTile, filterType: string, globalDateFilter: DashboardDateFilterRange}
  ) {
    this.dashboardTileToFilter = data.card;
    this.dashboardTileFilterType = data.filterType;
    this.globalDateFilter = data.globalDateFilter;
   }

  ngOnInit(): void {
    if (this.dashboardTileFilterType == 'date') {
      // set default filter dates to global dates if there are no tile-specific dates
      if (this.dashboardTileToFilter.tileDateFilterTypeId == 1) {
        // filter type = Range, use tile filterStartDate if available
        if (this.dashboardTileToFilter.filterStartDate && (this.dashboardTileToFilter.filterStartDate.length > 0)) {
          this.dashboardTileToFilterStartDate = this.dashboardTileToFilter.filterStartDate;
        } else {
          this.dashboardTileToFilterStartDate = this.globalDateFilter.startDate;
        }
      } else {
        this.dashboardTileToFilterStartDate = this.globalDateFilter.startDate;
      }
      if ((this.dashboardTileToFilter.tileDateFilterTypeId == 1) || (this.dashboardTileToFilter.tileDateFilterTypeId == 2)) {
        // filter type = Range or Single, use tile filterEndDate if available
        if (this.dashboardTileToFilter.filterEndDate && (this.dashboardTileToFilter.filterEndDate.length > 0)) {
          this.dashboardTileToFilterEndDate = this.dashboardTileToFilter.filterEndDate;
        } else {
          this.dashboardTileToFilterEndDate = this.globalDateFilter.endDate;
        }
      } else {
        this.dashboardTileToFilterEndDate = this.globalDateFilter.endDate;
      }
    }
  }

  closeTileFilterModal(): void {
    this.dialogRef.close();
  }

  updateTileFilterInfo(): void {
    if (this.dashboardTileFilterType == 'date') {
      this.tileDateFilterComponent.updateTileFilterInfo();
    }
    if (this.dashboardTileFilterType == 'slicer') {
      this.tileSlicerFilterComponent.updateTileFilterInfo();
    }
  }

}
