




<div class="backdrop" [ngStyle]="{'display':display}"></div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="onCloseHandled()"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Export to Email</h4>
      </div>
      <div class="modal-body">

        <p>
          <label>From:</label> <input type="text" value="{{_userDetails?.settings?.firstName}} {{_userDetails?.settings?.lastName}}" disabled="disabled" />
        </p>
        <p>
          <label>To:</label> <input type="email" #toEmailTbx [(ngModel)]="toEmail"/>

        </p>
        <p>
          <label for="subjectLine">Subject:</label>
          <input id="subjectLine" type="text" 
                 #toSubjectTbx [(ngModel)]="emailSubject"
                 /><br />
        </p>
        <p>
          <input type="checkbox"
                 title="Check to carbon-copy yourself"
                 [(ngModel)]="cc"
                 id="checkb-cc"
                 name="checkb"/>
  <label class="label-base" for="checkb-cc">Check to carbon-copy yourself</label>

          <!--<md-checkbox>Check to carbon-copy yourself</md-checkbox>-->
          <!--<md-checkbox ng-model="cc" aria-label="Check to carbon-copy yourself">
    Check to carbon-copy yourself
  </md-checkbox>-->
        </p>



      </div>
      <div class="modal-footer">
        <!--<button type="button" class="btn btn-default" (click)="onCloseHandled()">Close</button>-->

        <button type="button" class="btn btn-default" (click)="onSend()">Send</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal !-->
