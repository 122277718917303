<div class="row">
  <div class="col-md-12 ieWarningPanel card card-body mt-3" [style.display]="isDismissed?'none':''">
    <div class="row">
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-12">
            <h4>Fleet Response Visibility® runs faster in a modern browser.​</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            For the best experience, we recommend you open Fleet Response Visibility in Microsoft Edge or another modern browser like Chrome or Firefox.
            <a href="javascript: void(0)" (click)="openIELearnMoreModal()">Learn More</a>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <button type="button"
                (click)="openInEdge()"
                class="btn btn-primary">
          Open In Edge
        </button>
        <button type="button"
                (click)="dismissIEWarning()"
                class="btn btn-primary">
          Dismiss
        </button>
      </div>
    </div>

  </div>
</div>
