<div *ngIf="card?.hasSlicer" class="slicerContainer">{{card?.slicerDisplayName}} {{card?.slicerOperator}} {{chartRequest?.SlicerValue}}</div>
<div #vc [innerHTML]="resultHtml | safe" class="textOnlyChartWrapper" id="tile{{card?.tileId}}"></div>
<div class="highcharts-loading"
     style="position: absolute;
background-color: rgb(255, 255, 255);
opacity: 0.5; text-align: center;
z-index: 10;
top: 35px;
width: 100%; height: 100%;" *ngIf="showLoading">
  <span class="highcharts-loading-inner"
        style="font-weight: bold; position: relative; top: 40%;">Loading...</span>
</div>
