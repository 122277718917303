<div class="row">
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <h4 class="text-muted" style="font-weight:bold">Application Review</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a class="driverProfileLink" (click)="openDriverProfile()">
          {{driverInfo?.fullName}} ({{driverInfo?.employeeId.trim()}})
        </a>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div style="float:right">
      <button type="button" (click)="save()" class="btn btn-primary">
        Save
      </button>
      <button type="button" (click)="openApproveModal()" class="btn btn-primary">
        Approve
      </button>
      <button type="button" (click)="openRejectModal()" class="btn btn-primary">
        Reject
      </button>
      <button type="button" (click)="cancel()" class="btn btn-primary">
        Exit
      </button>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h5 class="text-muted" style="font-weight:bold">Additional Information</h5>
      <div class="row" *ngIf="driverAppReviewSubmitInfo">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <div class="switch" style="padding-top:1rem">
                <label>
                  <input type="checkbox" [checked]="driverAppReviewSubmitInfo.hasAddressesFor3Years"
                    [(ngModel)]="driverAppReviewSubmitInfo.hasAddressesFor3Years" />
                  <span class="lever"></span>

                  <span
                    [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverAppReviewSubmitInfo.hasAddressesFor3Years}">Addresses
                    for 3 years</span>
                </label>
              </div>
            </div>
            <div class="col-md-8">
              <div class="switch" style="padding-top:1rem">
                <label>
                  <input type="checkbox" [checked]="driverAppReviewSubmitInfo.hasOneLicenseOnly"
                    [(ngModel)]="driverAppReviewSubmitInfo.hasOneLicenseOnly" />
                  <span class="lever"></span>
                  <span
                    [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverAppReviewSubmitInfo.hasOneLicenseOnly}">One
                    License Only</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="switch" style="padding-top:1rem">
                <label>
                  <input type="checkbox" [checked]="driverAppReviewSubmitInfo.hasAccidentRecordsForPastYears"
                    [(ngModel)]="driverAppReviewSubmitInfo.hasAccidentRecordsForPastYears" />
                  <span class="lever"></span>

                  <span
                    [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverAppReviewSubmitInfo.hasAccidentRecordsForPastYears}">Accident
                    Record and Traffic Convictions/Forfeitures for past 3 years</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="switch" style="padding-top:1rem">
                <label>
                  <input type="checkbox" [checked]="driverAppReviewSubmitInfo.hasEmploymentHistory"
                    [(ngModel)]="driverAppReviewSubmitInfo.hasEmploymentHistory" />
                  <span class="lever"></span>

                  <span
                    [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverAppReviewSubmitInfo.hasEmploymentHistory}">Last
                    3 years of employment history</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="switch" style="padding-top:1rem">
                <label>
                  <input type="checkbox" [checked]="driverAppReviewSubmitInfo.hasNoEmploymentGaps"
                    [(ngModel)]="driverAppReviewSubmitInfo.hasNoEmploymentGaps" />
                  <span class="lever"></span>
                  <span
                    [ngClass]="{'align-middle':true, 'labelSwitchSelected':driverAppReviewSubmitInfo.hasNoEmploymentGaps}">No
                    gaps more than 30 days (without explanation)</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h5 class="text-muted" style="font-weight:bold">Attachments</h5>
      <app-dq-file-panel *ngIf="driverAppReview&&driverAppReview.fileName&&driverAppReview.documentId"
        [documentId]="driverAppReview.documentId" [fileName]="driverAppReview.fileName"
        [uploadDate]="driverAppReview.uploadDate">
      </app-dq-file-panel>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h5 class="text-muted" style="font-weight:bold">Commercial Motor Carrier previous employment</h5>
      <div class="row addEmployerRow" *ngIf="employerSubmitInfo">
        <div class="col-md-3">
          <div class="md-form">
            <input id="employerName" type="text" [(ngModel)]="employerSubmitInfo.employerName" class="form-control"
              placeholder="Employer Name" />
            <label for="employerName" class="active">Employer Name</label>
          </div>
        </div>
        <div class="col-md-2">
          <div class="md-form">
            <input id="employerPhone" type="text" [(ngModel)]="employerSubmitInfo.employerPhone" class="form-control"
              placeholder="Phone Number" />
            <label for="employerPhone" class="active">Phone Number</label>
          </div>
        </div>
        <div class="col-md-3">
          <div class="md-form">
            <input id="positionHeld" type="text" [(ngModel)]="employerSubmitInfo.positionHeld" class="form-control"
              placeholder="Position Held" />
            <label for="positionHeld" class="active">Position Held</label>
          </div>
        </div>
        <div class="col-md-2">
          <label id="lblEmployerStartDate" for="employerStartDate"
            [ngClass]="{ 'label-validation-error': employerStartDateControl.invalid, 'date-picker-label':true }">
            Start Date:
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Start Date</mat-label>
            <input class="input-date" matInput disabled [matDatepicker]="employerStartDate"
              [(ngModel)]="employerSubmitInfo.startDate" [formControl]="employerStartDateControl">
            <mat-datepicker-toggle matSuffix [for]="employerStartDate"></mat-datepicker-toggle>
            <mat-datepicker #employerStartDate disabled="false">
              <mat-datepicker-actions>
                <button mat-button (click)="employerStartDate.select(actualDate); employerStartDate.close()">
                  <mat-icon>history</mat-icon>
                  Today
                </button>
                <button mat-button (click)="employerStartDate.select(null); employerStartDate.close()">
                  <mat-icon>highlight_off</mat-icon>
                  Clear
                </button>
              </mat-datepicker-actions>
            </mat-datepicker>
          </mat-form-field>


        </div>
        <div class="col-md-2">
          <label id="lblEmployerEndDate" for="employerEndDate"
            [ngClass]="{ 'label-validation-error': employerEndDateControl.invalid, 'date-picker-label':true }">
            End Date:
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>End Date</mat-label>
            <input class="input-date" matInput disabled [matDatepicker]="employerEndDate"
              [(ngModel)]="employerSubmitInfo.endDate" [formControl]="employerEndDateControl">
            <mat-datepicker-toggle matSuffix [for]="employerEndDate"></mat-datepicker-toggle>
            <mat-datepicker #employerEndDate disabled="false">
              <mat-datepicker-actions>
                <button mat-button (click)="employerEndDate.select(actualDate); employerEndDate.close()">
                  <mat-icon>history</mat-icon>
                  Today
                </button>
                <button mat-button (click)="employerEndDate.select(null); employerEndDate.close()">
                  <mat-icon>highlight_off</mat-icon>
                  Clear
                </button>
              </mat-datepicker-actions>
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button type="button" (click)="addEmployer()" class="btn btn-primary">
            Add Previous Employer
          </button>
        </div>
      </div>
      <div class="row" style="padding-top:1rem">
        <div class="col-md-12">
          <app-loading-panel *ngIf="!tableConfig"></app-loading-panel>
          <div *ngIf="tableConfig">
            <app-data-table [columns]="tableConfig.fields" [data]="tableConfig.data"></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>