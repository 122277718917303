import { UserAdminServiceService } from './user-admin-service.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { UserLookupRequest, UserLookupModel } from './user-admin-interfaces';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.css']
})
export class UserAdminComponent implements OnInit {

  public emailAutoComplete$: Observable<any> = null;
  searchText = new Subject();
  results: Observable<Array<UserLookupModel>>;
  emailData: Array<UserLookupModel> = [];

  constructor(private readonly fb: UntypedFormBuilder,
    private userAdminServiceService: UserAdminServiceService,
    private errorService: ErrorModalService) { }

  ngOnInit(): void {
  }

  getData(value: string): void {
    let userLookupRequest: UserLookupRequest = {
      searchTerm: value,
      maxResults: 100
    }
    if (value != "") {
      this.userAdminServiceService.search(userLookupRequest).subscribe({
        next: (data: any) => {
          this.emailData = data as UserLookupModel[];
          this.results = this.searchText.pipe(
            startWith(''),
            debounceTime(300),
            map(() => this.emailData)
          )
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
        }
      });
    } else {
      this.results = of([]);
    }
  }
}
