
<div>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeReactivateSearchModal()">
        <span aria-hidden="true">×</span>
      </button>
      <h4 class="modal-title w-100" id="labelReactivateModal">Re-Activate Employee</h4>
    </div>
    <div class="modal-body">
      <div class="row" style="margin-top:1rem">
        <div class="col-sm-12">
          <div class="md-form">
            <input id="searchId" type="text" [(ngModel)]="searchId" class="form-control" placeholder="Employee ID" />
            <label for="searchId" class="active">Employee ID</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="md-form">
            <input id="searchName" type="text" [(ngModel)]="searchName" class="form-control" placeholder="Last Name" />
            <label for="searchName" class="active">Last Name</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" aria-label="Search" (click)="search()">Search</button>
      <button type="button" class="btn btn-default" style="margin-bottom:6px" aria-label="Close" (click)="closeReactivateSearchModal()">Close</button>
    </div>
  </div>
</div>
