import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { Subject ,  Observable } from 'rxjs';
import { DriverFollowUpDate } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

@Injectable()
export class DHPMyFollowupService {
  baseUrl: string;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private onPremService: OnPremDriverService
  )
  {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  // set a follow-up date
  setFollowUpDate(driverId:string, followUpDateObject: DriverFollowUpDate) {
    //console.log('follow-up date set');
    let postData = followUpDateObject;
    return this.onPremService.put('entity/' + driverId + '/followUp',
      JSON.stringify(postData)
    )
  }

  setFollowUpDateOLD(followUpDateObject: DriverFollowUpDate) {
    //console.log('follow-up date set');
    let postData = followUpDateObject;
    return this.onPremService.put('entity/followUp',
      JSON.stringify(postData)
    )
  }

  // set a follow-up date
  removeFollowUpDate() {
    console.log('follow-up date removed');
  }
}
