import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DcDriverProfileService } from '../dc-driver-profile/dc-driver-profile-service.component';
import { DataGridModalService } from '../../components/data-grid-filter-modal/data-grid-filter-modal.service'
import { DcConfirmActionService } from './dc-confirm-action-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';

@Component({
  selector: 'dc-confirm-action',
  templateUrl: './dc-confirm-action.component.html'
})
export class DcConfirmActionComponent implements OnInit, OnDestroy {
  @Input() modalHeight: string;
  @Input() modalType: string;
  @Input() modalInput: any;
  baseUrl: string;
  driverId: string;

  // the overloaded constructor for the controller
  constructor(private router: Router,
    private dcService: DcDriverProfileService,
    private dcConfirmActionService: DcConfirmActionService,
    private dataGridModalService: DataGridModalService,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private alertService: AlertService,
    private loadingSpinnerService: LoadingSpinnerService,
    private route: ActivatedRoute,
    private errorService: ErrorModalService) { }

  // angular on intialization event
  ngOnInit() {
    this.driverId = this.route.snapshot.paramMap.get('id');
  }

  // provides a central point for handling errors
  handleError(message: string) {

  }

  // retrieves and performs the selected action
  submit() {

    let action: string = '';

    try {
      if (this.modalType) {
        switch (this.modalType) {
          case 'dcResetPassword':
            {
              let action = 'Reset Password';
              let driverId = this.modalInput.driverId;
              this.loadingSpinnerService.show();
              this.dcConfirmActionService.resetPassword(driverId).subscribe({
                next: (data) => {
                  this.loadingSpinnerService.hide();
                  // notify the modal window to close
                  this.dcService.notifyModalClose();
                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }

          case 'dcTerminateDriver':
            {
              let action = 'Terminate Driver';
              let driverId = this.modalInput.driverId;
              let primaryDriverId = this.modalInput.primaryDriverId;
              let isSecondaryDriver = this.modalInput.isSecondaryDriver;
              this.loadingSpinnerService.show();
              this.dcConfirmActionService.terminateDriver(driverId).subscribe({
                next: (data) => {
                  // notify the modal window to close
                  this.dcService.notifyModalClose();
                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                  if (this.modalInput.fromLOAReport) {
                    // notify the modal windows to close
                    this.dcService.notifyModalClose();
                    // if calling from LOA report, refresh report on submit
                    this.router.navigate(['dc/driversonleave']);
                  } else {
                    if (!this.modalInput.fromAdvancedSearch) {
                      this.router.navigate(['statcenter', '7']);
                    }
                  }

                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }
          case 'dcCancelLeaveOfAbsence':
            // cancel future LOA
            {
              let action = 'Cancel Leave Of Absence';
              let driverId = this.modalInput.driverId;
              this.loadingSpinnerService.show();
              this.dcConfirmActionService.cancelLeaveOfAbsence(driverId).subscribe({
                next: (data) => {
                  if (this.modalInput.fromLOAReport) {
                    // if calling from LOA report, refresh report on submit

                    this.router.navigate(['dc/driversonleave']);
                  } else {
                    if (!this.modalInput.fromAdvancedSearch) {
                      // signal that driver profile data needs to be refreshed
                      this.dcService.setRefreshDriverProfileBaseData(driverId);
                    }
                  }

                  // notify the modal windows to close
                  this.dcService.notifyModalClose();
                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }
          case 'dcEndLeaveOfAbsence':
            // current LOA
            {
              let action = 'End Leave Of Absence';
              let driverId = this.modalInput.driverId;
              this.loadingSpinnerService.show();
              this.dcConfirmActionService.endLeaveOfAbsence(driverId).subscribe({
                next: (data) => {
                  if (this.modalInput.fromLOAReport) {
                    // if calling from LOA report, refresh report on submit
                    this.router.navigate(['dc/driversonleave']);
                  } else {
                    // no action - End LOA not available in driver profile form
                  }

                  // notify the modal windows to close
                  this.dcService.notifyModalClose();
                  this.alertService.showSuccessAlert(`${action} Successfully Completed.`, 'end', 'top', 5000);
                },
                error: (err: HttpErrorResponse) => {
                  this.errorService.setErrorObject(err.error);
                  this.loadingSpinnerService.hide();
                }
              });
              break;
            }

          default: {
            throw new Error('An error occurred. A corresponding action was not found.');
          }
        }
      }
    } catch (e) {
      // Present the error details on the modal
      console.log(e.messge);
    }
  }

  cancel() {
    // notify the modal window to close
    this.dcService.notifyModalClose();
  }


  // sleeps for a certain number of milliseconds
  sleep(milliseconds: number) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }

  // clears the source and target field arrays
  initializeValues() {
  }

  // the default destructor
  ngOnDestroy() {
  }
}
