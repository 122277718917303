export class DhpDocumentValidation {
  clientCode: string;
  documentDescription: string;
  documentFileName: string;
  documentId: number;
  documentType: string;
  documentUploadedDate: Date;
  driverDOB: Date;
  driverId: number;
  driverFirstName: string;
  driverLastName: string;
  driverLicenseNumber: string;
  driverLicenseState: string;
  driverType: string;
  mvrRequestDate: Date;
  mvrRequestId: number;
}
