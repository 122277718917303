import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {
  isLoading = false;
  isUploadingDocument = false;
  constructor(private loadingSpinnerService: LoadingSpinnerService) { }

  ngOnInit(): void {
    this.loadingSpinnerService.isLoading$.subscribe((value: boolean) =>{
      this.isLoading = value;
    })

    this.loadingSpinnerService.isUploadingDocument$.subscribe((value: boolean) =>{
      this.isUploadingDocument = value;
      this.isLoading = value;
    })
  }

}
