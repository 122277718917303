import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { DatePipe } from '@angular/common'
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { AccidentThirdPartyClaimMap, AccidentClaimInfo, AccidentClaimActionHistory, UserRightsInfo } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { Subscription } from "rxjs";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-accident-claim-info',
  templateUrl: './accident-claim-info.component.html'
})
export class AccidentClaimInfoComponent implements OnInit, OnDestroy {
  @Output() onFollowUpDateRefresh: EventEmitter<any> = new EventEmitter<any>();
  @Input() userRights: Array<UserRightsInfo>;
  _claimId: number;
  claimInfo: AccidentClaimInfo;
  showUpsertFollowUpDate: boolean = false;
  actionHistory: Array<AccidentClaimActionHistory>;
  numReq: number = 8;
  arrReq: Array<any> = [];

  firstThirdPartyClaimInfo: Array<AccidentClaimFirstThirdPartyClaimInfo> = [];
  _routeSub: Subscription;

  constructor(private readonly _route: ActivatedRoute,
    private readonly datepipe: DatePipe,
    private readonly accidentClaimService: AccidentClaimService,
    private readonly clientSelectionService: ClientSelectionService,
    private router: Router
  ) { }


  printPage(): void {
    window.print();
  }

  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];

      this.getClaimInfo();

      this.accidentClaimService.getClaimActionHistory(this._claimId).subscribe({
        next: (data) => {
          this.actionHistory = data as Array<AccidentClaimActionHistory>;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });
    });
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
  }

  private getClaimInfo(): void {
    this.accidentClaimService.getClaimInfo(this._claimId).subscribe({
      next: (data) => {
        this.claimInfo = data as AccidentClaimInfo;
        this.getReqArray(this.claimInfo);
        this.getFirstThirdPartyClaimInfo(this.claimInfo);
        this.accidentClaimService.setDriverName(this.claimInfo.driverName);
        this.checkClientSelections(this.claimInfo.client);
      },
      error: (err: HttpErrorResponse) => {
        console.log(err.error.toString());
      }
    });
  }

  private getReqArray(data: AccidentClaimInfo) {
    this.arrReq = [];
    for (var i = 1; i <= this.numReq; i++) {
      const reqLabel = data['req' + i + 'Title'];
      if (reqLabel && (reqLabel.length > 0)) {
        this.arrReq.push({
          req: data['req' + i],
          label: data['req' + i + 'Title']
        })
      }
    }
  }

  private getFirstThirdPartyClaimInfo(data: AccidentClaimInfo) {
    // return info for opposite of First/Third designation in clientClaimPartyType
    if (data && data.thirdPartyClaimMap && data.thirdPartyClaimMap.length > 0) {
      data.thirdPartyClaimMap.forEach(t => {
        const info = new AccidentClaimFirstThirdPartyClaimInfo();

        if (t.linkClaimType === "Third Party Claim") {
          info.claimId = t.thirdPartyClaimId;
        } else {
          info.claimId = t.firstPartyClaimId;
        }

        info.label = t.linkClaimType + ":";

        info.showLink = t.isLinkActive;

        if (data.clientClaimPartyType === "Third Party") {
          info.systemSourceLabel = " - " + t.firstPartySystemSource;

        }

        this.firstThirdPartyClaimInfo.push(info);
      });

    }
  }

  getMailToLink(data: AccidentClaimInfo): string {
    let mt: string = "";
    if (data) {
      const dt = new Date(data.dateOfLoss);
      mt += ("mailto:" + data.assignedClaimRepEmail);
      mt += ("?subject=Claim: " + data.claimId + ", DOL: " + this.datepipe.transform(dt, "M/d/yyyy") + ", Client: " + data.client);
    }
    return mt;

  }

  goToClaim(claimId: number) {
    this.accidentClaimService.setGoToClaim(claimId);
  }

  refreshClaimInfo(): void {
    this.getClaimInfo();
    this.toggleUpsertFollowUpDate();
  }

  toggleUpsertFollowUpDate(): void {
    this.showUpsertFollowUpDate = !this.showUpsertFollowUpDate;
  }

  deleteFollowUpDate(): void {
    this.accidentClaimService.deleteFollowUpDate(this._claimId).subscribe({
      next: (data) => {
        this.getClaimInfo();
      },
      error: (err: HttpErrorResponse) => {
        console.log(err.error.toString());
      }
    });
  }

  private checkClientSelections(claimClientShortName: string) {
    // if user opens a claim for a client that is not
    // selected, then add it to the client selections
    const arrClient = this.clientSelectionService.getClientSelectedArrayValue(1);
    if (arrClient.indexOf(claimClientShortName) < 0) {
      this.clientSelectionService.setAddClientsByShortName([claimClientShortName], 1);
    }
  }
}

export class AccidentClaimFirstThirdPartyClaimInfo {
  claimId: number;
  label: string;
  systemSourceLabel: string;
  showLink: boolean;

  constructor() {
    this.claimId = 0;
    this.label = "";
    this.systemSourceLabel = "";
    this.showLink = true;
  }
}
