<div class="row mt-3">
  <div class="col-md-12">
    <div class="card card-body">
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Vehicle</p>
        </div>
        <div class="col-md-9">
          <p class="font-weight-bold mb-0">{{approvalDetail?.vehicle}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Status</p>
        </div>
        <div class="col-md-9">
          <p class="font-weight-bold mb-0">{{approvalDetail?.subroStatus}}</p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-3">
          <p class="mb-0">Start Date</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.startDate | date:'M/d/yyyy'}}</p>
        </div>
        <div class="col-md-3">
          <p class="mb-0">Date Closed</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.closeDate | date:'M/d/yyyy'}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Liability Status</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.subroLiabilityStatus}}</p>
        </div>
        <div class="col-md-3">
          <p class="mb-0">Insurance Status</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.insuranceStatus}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Percent Accepted</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{(approvalDetail?.liabilityPercentage == 0) ? '' : (approvalDetail?.liabilityPercentage | percent:'1.2-2')}}</p>
        </div>
        <div class="col-md-3">
          <p class="mb-0">Reason</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.liabilityStatusReason}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Follow-Up Date</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{(approvalDetail?.liabilityPercentage == 0) ? '' : (approvalDetail?.liabilityPercentage | percent:'1.2-2')}}</p>
        </div>
        <div class="col-md-3">
          <p class="mb-0">Subro Rep</p>
        </div>
        <div class="col-md-3">
          <a class="font-weight-bold" href={{getMailToLink(approvalDetail)}}>{{approvalDetail?.subroRepName}}</a>
        </div>
      </div>
      <div class="row mt-3" *ngIf="subroNetRecoveryInfo?.length > 0">
        <div class="col-md-push-3 col-md-6 col-sm-12">
          <table class="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Requested</th>
                <th scope="col">Received</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let recovery of subroNetRecoveryInfo">
                <td>{{recovery.category}}</td>
                <td>{{recovery.requestedAmount | currency:'USD':'$':'1.2-2'}}</td>
                <td>{{recovery.receivedAmount | currency:'USD':'$':'1.2-2'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-3">
          <p class="mb-0">Note</p>
        </div>
        <div class="col-md-9 d-flex flex-wrap">
          <p class="font-weight-bold mb-0 wrap-text">{{approvalDetail?.noteToClient}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">State Negligence Law</p>
        </div>
        <div class="col-md-9">
          <p class="font-weight-bold mb-0">{{approvalDetail?.stateNegligenceLaw}}</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-3">
          <p class="mb-0">Accident Details</p>
        </div>
        <div class="col-md-9 d-flex flex-wrap">
          <p class="font-weight-bold mb-0 wrap-text">{{approvalDetail?.damageDescription}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3" *ngIf="subroStatuses?.length > 0">
  <div class="col-md-12">
    <div class="card card-body">
      <h4 class="card-title text-muted">Subrogation History</h4>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-striped table-bordered">
            <thead class="red darken-2 text-white">
              <tr>
                <th scope="col">Status History</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let stat of subroStatuses">
                <td>{{stat.status}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<app-accident-claim-note [noteType]="'Subrogation'" [noteTypeId]="5" [userRights]="userRights"></app-accident-claim-note>
<div class="row mt-3">
  <div class="col-md-12">
    <div class="card card-body">
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Action Pending</p>
        </div>
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">{{approvalDetail?.requestDate | date:'M/d/yyyy'}}</p>
        </div>
        <div class="col-md-6">
          <p class="font-weight-bold red-text mb-0">{{approvalDetail?.actionRequested}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Note to Client</p>
        </div>
        <div class="col-md-9 d-flex flex-wrap">
          <p class="font-weight-bold mb-0 wrap-text">{{approvalDetail?.noteToClient}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="mb-0">Recommendation</p>
        </div>
        <div class="col-md-9 d-flex flex-wrap">
          <p class="font-weight-bold mb-0 wrap-text">{{approvalDetail?.recommendation}}</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <h4 class="card-title text-muted">{{approvalDetail?.actionRequested}}</h4>

          <div class="form-check" *ngFor="let response of approvalResponses">
            <input class="form-check-input" type="radio" [id]="'response-' + response.responseLookupId" [name]="'clientResponseLookupId'" [value]="response.responseLookupId" [(ngModel)]="clientResponseId" />
            <label [for]="'response-' + response.responseLookupId" class="form-check-label font-weight-normal">{{response.responseDescription}}</label>
          </div>
        </div>
        <div class="col-md-6 md-form">
          <span>Comments</span>
          <textarea class="form-control" rows="5" cols="5" [(ngModel)]="comments" [name]="comments"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-md-offset-6 col-sm-12">
          <input class="btn btn-default" value="Submit" (click)="submitClientApproval()" />
          <input class="btn btn-default" value="Cancel" (click)="resetClientApproval()" />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-12">
    <div class="card card-body">
      <h4 class="card-title text-muted">Previous Review History</h4>

      <div class="row">
        <div class="col-md-12">
          <table class="table table-striped table-bordered" *ngIf="approvalHistory?.length > 0">
            <thead class="red darken-2 text-white">
              <tr>
                <th scope="col">Requested</th>
                <th scope="col">Requested Action</th>
                <th scope="col">Note to Client</th>
                <th scope="col">Reviewed By</th>
                <th scope="col">Replied</th>
                <th scope="col">Action Taken</th>
                <th scope="col">Client Comments</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let history of approvalHistory">
                <td>{{history.requestDate | date:'M/d/yyyy'}}</td>
                <td>{{history.approvalDescription}}</td>
                <td>{{history.noteToClient}}</td>
                <td>{{history.contactName}}</td>
                <td>{{history.responseDate | date:'M/d/yyyy'}}</td>
                <td>{{history.responseDescription}}</td>
                <td>{{history.clientComments}}</td>
              </tr>
            </tbody>
          </table>
          <p *ngIf="approvalHistory?.length == 0">(No Approval History Found)</p>
        </div>
      </div>
    </div>
  </div>
</div>
