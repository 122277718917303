import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DriverInactive } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { Subscription } from 'rxjs';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { HondaOptOutInfoModalService } from '../honda-opt-out-info-modal/honda-opt-out-info-modal-service.component';
import { HondaOptOutInfoModalComponent } from '../honda-opt-out-info-modal/honda-opt-out-info-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-honda-opt-out-grid',
  templateUrl: './honda-opt-out-grid.component.html',
  styleUrls: ['./honda-opt-out-grid.component.css']
})

export class HondaOptOutGridComponent implements OnInit, OnDestroy {
  @ViewChild('#btnOptOutMultiple') btnOptOutMultiple: HTMLButtonElement;
  public itemsPerPage: number = 50;
  group: string;
  driverLastName: string;
  employeeId: string;
  arrDriverInactive: Array<DriverInactive> = [];
  lineOfBusinessId: number;
  clientSelectedArray: Array<string>;
  routeSub: Subscription;
  gtOptions: any = { numberOfRows: 50 };
  public tableConfig: any;
  selectedDrivers: Array<any> = [];

  // the overloaded constructor for the controller
  constructor(private route: ActivatedRoute,
    private lineOfBusinessService: LineOfBusinessService,
    private clientService: ClientSelectionService,
    private reactivateService: HondaOptOutInfoModalService,
    private http: HttpClient,
    private onPremService: OnPremDriverService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private dialog: MatDialog) { }

  ngOnInit() {
    // set line of business to safety if not already initialized to Safety
    this.lineOfBusinessId = 2;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    // get the selected client(s)
    this.clientSelectedArray = this.clientService.getClientSelectedArrayValue(this.lineOfBusinessId);

    // get data for table
    this.routeSub = this.route.params.subscribe(params => {
      this.group = params['group'] || '';
      this.driverLastName = params['driverLastName'] || '';
      this.employeeId = params['driverId'] || '';
      //this.driverLastName = params['driverLastName'] || '';

      let clientCode = this.clientSelectedArray.join(',');
      this.selectedDrivers = [];
      this.doSearch(clientCode, this.group, this.employeeId, this.driverLastName);
    });

    // subscribe to refresh from info modal
    this.reactivateService.inactiveDriverGridRefreshObs$.subscribe(r => {
      if (r as boolean) {
        let clientCode = this.clientService.getClientSelectedArrayValue(this.lineOfBusinessId).join(',');
        $('#btnOptOutMultiple').prop("disabled", (this.selectedDrivers.length == 0));
        this.doSearch(clientCode, this.group, this.employeeId, this.driverLastName);
        this.reactivateService.setInactiveDriverGridRefresh(false);
      }


    });
    $('#btnOptOutMultiple').prop("disabled", (this.selectedDrivers.length == 0));
  }

  private doSearch(clientCode: string, group: string, employeeId: string, driverLastName: string) {
    if (clientCode && ((group && group != 'all') || employeeId || driverLastName)) {
      let params = new HttpParams();
      params = params.append('clientCode', clientCode);

      this.loadingSpinnerService.show();

      let searchParams = { EmployeeId: employeeId, LastName: driverLastName, ElementGuids: group };

      this.onPremService.post('entity/' + clientCode + '/active', JSON.stringify(searchParams), { params: params })
        .subscribe({
          next: (data) => {
            this.arrDriverInactive = data as Array<DriverInactive>;
            this.setTelTableConfig(this.arrDriverInactive);
            this.loadingSpinnerService.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err.error);
            this.loadingSpinnerService.hide();
          }
        });
    }
    else {
      let params = new HttpParams();
      params = params.append('clientCode', clientCode);
      this.onPremService.get('entity/active', { params: params })
        .subscribe({
          next: (data) => {
            this.arrDriverInactive = data as Array<DriverInactive>;
            this.setTelTableConfig(this.arrDriverInactive);
            this.loadingSpinnerService.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err.error);
            this.loadingSpinnerService.hide();
          }
        });
    }
  }

  private setTelTableConfig(arrDriver: Array<DriverInactive>) {
    let datePipe = new DatePipe("en-US");
    this.selectedDrivers = [];

    var columns = [
      { name: "select", prop: "select", title: '', sort: null, visible: true, dataType: null },
      { name: "Employee ID", prop: "employeeId", title: "Employee ID", sort: "enable", visible: true, dataType: null },
      { name: "Employee", prop: "lastName", title: "Employee", sort: "enable", visible: true, dataType: null },
      { name: "DOB", prop: "dateOfBirth", title: "DOB", sort: "enable", visible: true, dataType: 'date' },
      { name: "Email", prop: "email", title: "Email", sort: "enable", visible: true, dataType: null },

      { name: "Driver License Number", prop: "driverLicenseNumber", title: "Driver License Number", sort: "enable", visible: true, dataType: 'date' },
      { name: "Driver License State", prop: "driverLicenseState", title: "Driver License State", sort: "enable", visible: true, dataType: 'date' },
      { name: "Driver License Expiration Date", prop: "driverLicenseExpirationDate", title: "Driver License Expiration Date", sort: "enable", visible: true, dataType: 'date' },
      { name: "Commercial Drivers License", prop: "isCommercialDriversLicense", title: "Commercial Drivers License", sort: "enable", visible: true, dataType: 'boolean' },
      //{ name: "Active", prop: "active", title: "Active", sort: "enable", visible: true, dataType: "boolean" },
      { name: "Group", prop: "group", title: "Group", sort: "enable", visible: true, dataType: null },
      { name: "Group Active", prop: "isGroupActive ", title: "Group Active", sort: "enable", visible: true, dataType: "boolean" },
      { name: "Terminate", prop: "reactivateButton", title: "", sort: null, visible: true, dataType: null }
    ];

    var settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: s.sort,
        visible: s.visible
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s.dataType,
        hidden: !s.visible,
        sort: s.sort && s.sort === 'enable',
        render: row => {
          let r = row[s.prop];
          if (s.prop === "lastName") {
            r = row['lastName'] + ', ' + row['firstName'] + ' ' + row['middleName'];
          }
          if (s.prop === "reactivateButton") {
            // create Re-Activate button
            r = '<button class="btn btn-xs btn-default my-0 p-1" style="white-space: nowrap!important">Opt Out</button>';
          }
          if (s.prop === "select") {
            // create Re-Activate button
            r = '<input type="checkbox" class="cbxOptOut" style="opacity:1;pointer-events:all;" id="cbx_' + row['driverId'] + '">';
          }
          if (s.dataType === "currency") {
            if (row[s.prop] >= 0)
              r = "$" + row[s.prop];
            else
              r = "($" + (row[s.prop] * -1) + ")";
          }
          if (s.dataType === "date") {
            try {
              r = datePipe.transform(new Date(r), "MM/dd/yyyy");
            } catch (e) { }
          }
          if (s.dataType === "boolean" && r != null) {
            // convert to number
            try {
              r = (r) ? "Yes" : "No";
            } catch (e) { }
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          if (s.dataType === "boolean") {
            // convert to number
            try {
              val = (val) ? "Yes" : "No";
            } catch (e) { }
          }
          if (s.prop === "select") {
            val = '<input type="checkbox">';
          }
          return val;
        },
        click: row => {
          if (s.prop === "reactivateButton") {
            // open reactivate modal
            this.optOutSingle(row['driverId']);
          }
          if (s.prop === "select") {
            // open reactivate modal

            //console.log('cbx clicked:' + $('#cbx_' + row['employeeId']).is(':checked'));
            if ($('#cbx_' + row['driverId']).is(':checked')) {
              //add row to selected array
              this.selectedDrivers.push(row);
            }
            else {
              this.selectedDrivers = this.selectedDrivers.filter(x => x['driverId'] != row['driverId']);
            }
            $('#btnOptOutMultiple').prop("disabled", (this.selectedDrivers.length == 0));
          }
        }
      });
    });

    this.tableConfig = {
      settings: settings,
      fields: fields,
      data: arrDriver
    }
  }
  
  onSelectChange(data: any): void {
    if (data?.columnName === 'select') {
      if ($('#cbx_' + data?.row['driverId']).is(':checked')) {
        //add row to selected array
        this.selectedDrivers.push(data?.row);
      }
      else {
        this.selectedDrivers = this.selectedDrivers.filter(x => x['driverId'] !== data?.row['driverId']);
      }
      $('#btnOptOutMultiple').prop("disabled", (this.selectedDrivers.length === 0));
    }
  }

  openModal(data: any): void {
    if (data?.type === 'reactivate') {
      this.optOutSingle(data?.row?.driverId);
    }
  }

  optOutSingle(driverId: string) {
    let driverInfo = this.arrDriverInactive.find(d => driverId == d.driverId.toString());
    if (driverInfo) {
      this.openOptOutModal([driverInfo]);
    }
  }

  openMultipleOptOutModal() {
    this.openOptOutModal(this.selectedDrivers);
  }

  openOptOutModal(selectedDrivers: Array<any>) {
    const dialog = this.dialog.open(HondaOptOutInfoModalComponent,
      {
        data: { selectedDrivers: selectedDrivers, clientSelectedArray: this.clientSelectedArray },
        minHeight: "12em",
        width: "30em",
        panelClass: "add-group-modal",
        hasBackdrop: false,
        position: {top: "2em", right: "42em"}
      })
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}
