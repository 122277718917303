import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DriverTelematicsDetail } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'dhp-telematics-detail',
  templateUrl: './dhp-telematics-detail.component.html',
  styleUrls: ['./dhp-telematics-detail.component.css']
})
export class DHPTelematicsDetailComponent implements OnInit {
  @Input() modalHeight: string;
  @Input() modalInput: any;
  // private variables that are only shared with subscribers that import the type
  detailInfo: Array<DriverTelematicsDetail>;
  gtOptions: any = {};
  public driverTelTableConfig: any;

  constructor(private dhpService: DriverHistoryProfileService,
    private datePipe: DatePipe,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private readonly onPremService: OnPremDriverService) { }


  ngOnInit(): void {
    let driverId = this.modalInput.driverId.toString();
    let driverTelematicsId = this.modalInput.driverTelematicsId.toString();
    this.loadingSpinnerService.show();

    this.onPremService.get(`training/telematics/${driverId}/${driverTelematicsId}/details`).subscribe({
      next: (data) => {
        this.detailInfo = data as Array<DriverTelematicsDetail>;
        this.setTelTableConfig(this.detailInfo);

        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.loadingSpinnerService.hide();
        this.errorService.setErrorObject(err.error);
      }
    })
  }

  private setTelTableConfig(arrDriverTel: Array<DriverTelematicsDetail>) {
    var columns = [
      { name: "Start Date", prop: "startDate", title: "Start Date", visible: true, sort: "", dataType: "date" },
      { name: "End Date", prop: "endDate", title: "End Date", visible: true, sort: "", dataType: "date" },
      { name: "Points", prop: "score", title: "Points", sort: "", visible: true, dataType: "number" }
    ];

    var settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: 'enable'
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        render: row => {
          let r = row[s.prop];
          switch (s.dataType) {
            case "date":
              try {
                r = this.datePipe.transform(row[s.prop], 'MM/dd/yyyy');
              } catch (e) { }
              break;
          }
          switch (s.prop) {
            case "alertCategory":
              // link if hasDetail = true
              if (row["hasDetail"]) {
                r = '<a class="textalign center" style="color:#d23138;"><u>' + r + '</u></a>';
              }
              break;
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.driverTelTableConfig = {
      settings: settings,
      fields: fields,
      data: arrDriverTel
    }

    this.gtOptions = (arrDriverTel.length > 1 ? { numberOfRows: 10 } : {});

  }

  private openTelematicsDetailModal(alertCategory: string, driverId: number, driverTelematicsId: number) {
    this.dhpService.notifyModal(alertCategory, 'telematicsDetail', '', 'lg', { driverId: driverId, driverTelematicsId: driverTelematicsId });
  }

  openModal(data: any): void {
    this.openTelematicsDetailModal(data?.row?.alertCategory, data?.row?.driverId, data?.row?.driverTelematicsId);
  }

  cancel() {
    this.loadingSpinnerService.hide();
    // notify the modal window to close
    this.dhpService.notifyModalClose();
  }
}
