<app-card-gridster [card]="card"
                   (onRemoveClicked)="removeItem($event)"
                   (onExportClicked)="exportChart($event)"
                   (onShowCardModalClicked)="showCardModal($event)"
                   (onShowCardFilterModalClicked)="showCardFilterModal($event, 'date')"
                   (onShowCardSlicerModalClicked)="showCardFilterModal($event, 'slicer')">
  <div style="margin:2px">
    

     <ng-container *ngIf="card?.jsChartTypeId==1">
       <app-chart-v2 [TileId]="frameId" [card]="card"
                  [clientSelectionString]="card.clientSelectedArray.join(',')"
                  [filterStartDate]="card.filterStartDate"
                  [filterEndDate]="card.filterEndDate"
                  [globalDateFilterStartDate]="card.globalDateFilter.startDate"
                  [globalDateFilterEndDate]="card.globalDateFilter.endDate"
                  [slicerOptionChosen]="card.slicerOptionChosen">
        </app-chart-v2>
    </ng-container>
    <ng-container *ngIf="card?.jsChartTypeId==2">
      <app-card-static [card]="card"
                    [clientSelectionString]="card.clientSelectedArray.join(',')"
                    [filterStartDate]="card.filterStartDate"
                    [filterEndDate]="card.filterEndDate"
                    [globalDateFilterStartDate]="card.globalDateFilter.startDate"
                    [globalDateFilterEndDate]="card.globalDateFilter.endDate"
                    [slicerOptionChosen]="card.slicerOptionChosen">
      </app-card-static>
    </ng-container>

  </div>
</app-card-gridster>


