import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DriverInactive } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { Subscription } from 'rxjs';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { ReactivateDriverInfoModalService } from "../reactivate-driver-info-modal/reactivate-driver-info-modal-service.component";
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { ReactivateDriverInfoModalComponent } from '../reactivate-driver-info-modal/reactivate-driver-info-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-reactivate-driver-grid',
  templateUrl: './reactivate-driver-grid.component.html',
  styleUrls: ['./reactivate-driver-grid.component.css']
})

export class ReactivateDriverGridComponent implements OnInit, OnDestroy {
  public itemsPerPage: number = 50;
  driverId: string;
  driverLastName: string;
  arrDriverInactive: Array<DriverInactive> = [];
  lineOfBusinessId: number;
  clientSelectedArray: Array<string>;
  routeSub: Subscription;
  gtOptions: any = { numberOfRows: 50 };
  public tableConfig: any;

  // the overloaded constructor for the controller
  constructor(private route: ActivatedRoute,
    private lineOfBusinessService: LineOfBusinessService,
    private clientService: ClientSelectionService,
    private reactivateService: ReactivateDriverInfoModalService,
    private http: HttpClient,
    private onPremService: OnPremDriverService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private dialog: MatDialog) { }

  ngOnInit() {
    // set line of business to safety if not already initialized to Safety
    this.lineOfBusinessId = 2;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    // get the selected client(s)
    this.clientSelectedArray = this.clientService.getSavedClientShortNames(this.lineOfBusinessId);

    // get data for table
    this.routeSub = this.route.params.subscribe(params => {
      this.driverId = params['driverId'] || '';
      this.driverLastName = params['driverLastName'] || '';
      let clientCode = this.clientSelectedArray[0];

      this.doSearch(clientCode, this.driverId, this.driverLastName);
    });

    // subscribe to refresh from info modal
    this.reactivateService.inactiveDriverGridRefreshObs$.subscribe(r => {
      if (r as boolean) {
        let clientCode = this.clientSelectedArray[0];
        this.doSearch(clientCode, this.driverId, this.driverLastName);
        this.reactivateService.setInactiveDriverGridRefresh(false);
      }


    });
  }

  private doSearch(clientCode: string, driverId: string, driverLastName: string) {
    if (clientCode && (driverId || driverLastName)) {
      let params = new HttpParams();
      params = params.append('clientCode', clientCode);
      if (driverId && (driverId.length > 0)) {
        params = params.append('employeeId', driverId);
      }
      if (driverLastName && (driverLastName.length > 0)) {
        params = params.append('lastName', driverLastName);
      }
      this.loadingSpinnerService.show();
      this.onPremService.get('entity/inactive', { params: params })
        .subscribe({
          next: (data) => {
            this.arrDriverInactive = data as Array<DriverInactive>;
            this.setTelTableConfig(this.arrDriverInactive);
            this.loadingSpinnerService.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err.error);
            this.loadingSpinnerService.hide();
          }
        });
    }
  }

  private setTelTableConfig(arrDriver: Array<DriverInactive>) {
    let datePipe = new DatePipe("en-US");

    var columns = [
      { name: "Employee ID", prop: "employeeId", title: "Employee ID", sort: "enable", visible: true, dataType: null },
      { name: "Employee", prop: "lastName", title: "Employee", sort: "enable", visible: true, dataType: null },
      { name: "DOB", prop: "dateOfBirth", title: "DOB", sort: "enable", visible: true, dataType: 'date' },
      { name: "Email", prop: "email", title: "Email", sort: "enable", visible: true, dataType: null },
      { name: "Group", prop: "group", title: "Group", sort: "enable", visible: true, dataType: null },
      { name: "De-activated Date", prop: "deactivatedDate", title: "De-activated Date", sort: "enable", visible: true, dataType: 'date' },
      { name: "Re-Activate", prop: "reactivateButton", title: "Re-Activate", sort: null, visible: true, dataType: null }
    ];

    var settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: s.sort,
        visible: s.visible
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        render: row => {
          let r = row[s.prop];
          if (s.prop === "lastName") {
            r = row['lastName'] + ', ' + row['firstName'] + ' ' + row['middleName'];
          }
          if (s.prop === "reactivateButton") {
            // create Re-Activate button
            r = '<button class="btn btn-xs btn-default my-0 p-1">Re-Activate</button>';
          }
          if (s.dataType === "currency") {
            if (row[s.prop] >= 0)
              r = "$" + row[s.prop];
            else
              r = "($" + (row[s.prop] * -1) + ")";
          }
          if (s.dataType === "date") {
            try {
              r = datePipe.transform(new Date(r), "MM/dd/yyyy");
            } catch (e) { }
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        },
        click: row => {
          if (s.prop === "reactivateButton") {
            // open reactivate modal
            this.openReactivateModal(row);
          }
        }
      });
    });

    this.tableConfig = {
      settings: settings,
      fields: fields,
      data: arrDriver
    }
  }

  openModal(data: any): void {
    if (data?.type === 'reactivate') {
      this.openReactivateModal(data?.row);
    }
  }

  openReactivateModal(data: any) {
    const employeeId = data?.employeeId;
    let driverInfo = this.arrDriverInactive.find(
      (d) => employeeId == d.employeeId
    );
    const dialog = this.dialog.open(ReactivateDriverInfoModalComponent, {
      data: { driverInfo: driverInfo },
      minHeight: "12em",
      width: "30em",
      panelClass: "add-group-modal",
      hasBackdrop: false,
      position: { top: "2em", right: "42em" },
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}
