<div class="quickSearchContainer">
    <quick-search-menu></quick-search-menu>
  </div>
  <div  *ngIf="rowsRaw?.length > 0">
    <h4>Search Date: {{ today | date :'short' }}</h4>
    <app-data-table
      [columns]="configObject.fields"
      [data]="configObject.data"
      [showPagination]="true"
      [showPageSize]="true"
      (triggerSortChange)="sortChange($event)"
    ></app-data-table>
    <div id="reportFooter">
      <div class="left"> Fleet Response Confidential</div>
      <div class="right"> Report run {{today | date :'short' }}</div>
    </div>
  </div>
  
  <div  *ngIf="!rowsRaw || rowsRaw?.length == 0">
    No results
  </div>
  