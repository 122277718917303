import { Injectable } from '@angular/core';
import { CorporateStructure } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ClientSelectionService, ClientSelectionsObject } from '../../components/client-selection-service/client-selection-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { ReplaySubject, combineLatest } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CorporateStructureService {
  private _corporateStructures: CorporateStructures;
  private _corporateStructures$ = new ReplaySubject<CorporateStructures>(1);
  public corporateStructures$ = this._corporateStructures$.asObservable();
  constructor(
      private _clientSelectionService: ClientSelectionService,
      private _onPremService: OnPremDriverService
      ) {
      this._clientSelectionService.clientSelectedInfo$.subscribe((clientSelections) => this.handleClientSelectionChange(clientSelections));
   }
   private handleClientSelectionChange(clientSelections: ClientSelectionsObject) {
     if (clientSelections[2] != null) {
       // Can only select a single client at a time in the safety LOB.
       const safetyClient = clientSelections[2].clientSelectedArray[0];
      combineLatest([
        this._onPremService.get('hierarchy/structure/' + safetyClient + '/true'),
        this._onPremService.get('hierarchy/stateStructure/' + safetyClient)
      ]).subscribe(([chStructure, spStructure]) => {
        if (chStructure[0] != null && spStructure[0] != null) {
          this._corporateStructures = {
            StateProvinceStructure: spStructure[0],
            CorporateHierarchyStructure: chStructure[0]
          };
          this._corporateStructures$.next(this._corporateStructures);
        }
      });
     }
   }
}
export interface CorporateStructures {
  StateProvinceStructure: CorporateStructure;
  CorporateHierarchyStructure: CorporateStructure;
}
