import { Component, Inject, Injectable, Input } from '@angular/core';
import { lastValueFrom, Observable ,  Subject } from "rxjs";
import { HttpClient, HttpParams } from '@angular/common/http';
import { OnPremClaimService } from '../on-prem-service/on-prem-claim-service.component';
import { OnPremDriverService } from '../on-prem-service/on-prem-driver-service.component';


@Injectable()
export class DataGridService {
  baseUrl: string;
  _http: HttpClient;

  private dataGridRequest = new Subject();
  dataGridRequest$ = this.dataGridRequest.asObservable();
  private refreshGrid = new Subject();
  refreshGrid$ = this.refreshGrid.asObservable();

  constructor(http: HttpClient,
    private onPremClaimService: OnPremClaimService,
    private onPremDriverService: OnPremDriverService,
    @Inject('BASE_URL') baseUrl: string) {
    this._http = http;
    this.baseUrl = baseUrl;
  }


  updateGridRequest(request: ReportRequest) {
    this.dataGridRequest.next(request);
  }

  getData(request: ReportRequest): Promise<GridData> {
    this.dataGridRequest.next(request);

    return lastValueFrom(this._http.post(this.baseUrl + 'api/Datagrid/GridData',
      JSON.stringify(request),
      {
        headers: { 'Content-Type': 'application/json' },
      }
    ))
      .then(result => {
        return result as GridData;
      }).catch();
  }

  runVehicleInventoryReport(client) {
    let param = new HttpParams();
    param.append("clientCodeList", client);
    console.log("Client: " + client)
    //return this.onPremClaimService.post("reports/vehicleinventoryreport/send", JSON.stringify({ clientCodeList: client }));//, { params: param })
    return this.onPremClaimService.post("reports/vehicleinventoryreport/send", JSON.stringify(client));
  }
  getPrimaryDriverId(driverId) {
    return this.onPremDriverService.get("entity/" + driverId + "/primary/id");
  }

  /* set flag to indicate refresh of grid */
  setRefreshGrid(doRefresh: boolean) {
    this.refreshGrid.next(doRefresh);
  }
}


export interface Column {
  name: string;
  prop: string;
}

export interface GridData {
  Columns: Column[];
  Data: any;
}

@Injectable()
export class ReportRequest {
  //@Input('DataGridId')
  //get DataGridId(): number {
  //  return this._DataGridId;
  //}

  //set DataGridId(val) {
  //  if (this._DataGridId != val) {
  //    this.SortDir = "";
  //    this.SortField = "";
  //    this.SortDisplayField = "";
  //    console.log("Reset");
  //  }
  //  console.log("Set DataGridId: " + val);
  //  this._DataGridId = val;
  //}
  public reset() {
    this.ParameterValues = [];
    this.StartDate = null;
    this.EndDate = null;
    this.clickTrough = false;
    this.SortDir = "";
    this.SortField = "";
    this.SortDisplayField = "";
  }
  DataGridId: number;
  ReportFilterValues: Array<any>;
  SelectedClientIds: Array<any>;
  SortDisplayField: string;
  SortField: string;
  SortDir: string;
  ParameterValues: Array<any>;
  StartDate: Date;
  EndDate: Date;
  DateFilterTypeId: number;
  clickTrough: boolean;
  dummy: string;
  GroupGuid: string;
}
