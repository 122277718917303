
import {throwError as observableThrowError,  Subject ,  Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { DcDriverProfileService } from '../dc-driver-profile/dc-driver-profile-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";

@Injectable()
export class DcConfirmActionService {
  baseUrl: string;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private dcService: DcDriverProfileService,
    private onPremService: OnPremDcService,
    private errorService: ErrorModalService) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  /* RESETS THE DRIVER'S PASSWORD */
  resetPassword(driverId: string) {
    //return this.onPremService.put('entity/resetpassword/' + driverId, null);
    return this.onPremService.put('drivers/' + driverId + '/resetpassword', null);
  }

  /* TERMINATES A DRIVER */
  terminateDriver(driverId: string) {
    return this.onPremService.put('drivers/' + driverId + '/terminate', null);
  }

  /* CANCEL LEAVE OF ABSENCE - FUTURE LOA*/
  cancelLeaveOfAbsence(driverId: string) {
    return this.onPremService.put('leaveOfAbsence/' + driverId + '/cancel', null);
  }

  /* END LEAVE OF ABSENCE - LOA IN PROGRESS*/
  endLeaveOfAbsence(driverId: string) {
    return this.onPremService.put('leaveOfAbsence/' + driverId + '/end', null);
  }

  /* HANDLES ENCOUNTERED ERRORS */
  private handleError(err: HttpErrorResponse) {
    console.log(err.message);
    return observableThrowError(err.message);
  }
}
