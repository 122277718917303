//Angular
import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UntypedFormControl, Validators, ValidationErrors } from '@angular/forms';
//Third Party
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
//APP
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { SafetyModalWindowService } from '../safety-modal-window/safety-modal-window-service.component';
import { DataGridModalService } from '../../components/data-grid-filter-modal/data-grid-filter-modal.service'
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { DataGridMenuService } from '../../components/data-grid-menu-component/data-grid-menu.service';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'dhp-leave-of-absence',
  templateUrl: './dhp-leave-of-absence.component.html',
  styleUrls: ['./dhp-leave-of-absence.component.scss'],
})
export class DHPLeaveOfAbsenceComponent implements OnInit, AfterViewInit {
  @ViewChild('beginDatePicker') beginDatePicker: MatDatepicker<Date>;
  @ViewChild('returnDatePicker') returnDatePicker: MatDatepicker<Date>;
  @Input() modalHeight: string;
  @Input() modalInput: any;

  baseUrl: string;
  formValidated: boolean = false;
  startDateReadOnly: boolean = false;
  beginDate: string;
  returnDate: string;
  beginDateControl = new UntypedFormControl('', [Validators.required]);
  returnDateControl = new UntypedFormControl('', [Validators.required]);
  actualDate = new Date();
  // date vars for yesterday and today, with time stripped out
  // correct values are set in constructor
  private dtTomorrow: Date = new Date((new Date()).toDateString());

  allControls: Array<UntypedFormControl> = [
    this.beginDateControl,
    this.returnDateControl
  ];

  // the overloaded constructor for the controller
  constructor(private dhpService: DriverHistoryProfileService,
    private safetyModalWindowService: SafetyModalWindowService,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private dataGridModalService: DataGridModalService,
    private onPremService: OnPremDriverService,
    private snackBar: MatSnackBar,
    private loadingSpinnerService: LoadingSpinnerService,
    private dataGridMenuService: DataGridMenuService,
    private clientSelectionService: ClientSelectionService,
    private readonly errorService: ErrorModalService) {
    this.dtTomorrow.setDate(this.dtTomorrow.getDate() + 1);
  }

  ngOnInit() {
    if (this.modalInput) {
      this.beginDate = this.modalInput.leaveOfAbsenceBeginDate;
      this.returnDate = this.modalInput.leaveOfAbsenceReturnDate;
    }
  }

  ngAfterViewInit(): void {
    this.beginDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.beginDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
          this.beginDatePicker.select(event.value);
          this.beginDatePicker.close();
        })
      }, 0)
    })

    this.returnDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.returnDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
          this.returnDatePicker.select(event.value);
          this.returnDatePicker.close();
        })
      }, 0)
    })
  }

  private validateDates(): boolean {
    let isOK = true;

    // return date should be after begin date
    let beginDate = new Date(this.beginDateControl.value);
    let returnDate = new Date(this.returnDateControl.value);
    if (returnDate <= beginDate) {
      isOK = false;
      // set errors on date fields
      this.errorService.setErrorObject({ message: 'Return Date must be after Begin Date' });
      this.addValidationError(this.returnDateControl, 'datevalidation');
    } else {
      // clear errors on date fields
      this.removeValidationError(this.returnDateControl, 'datevalidation');
    }

    return isOK;
  }

  private validateForm(): boolean {
    let isOK: boolean = true;

    // validate dates
    isOK = this.validateDates();

    // filter out errors related to other validations
    // and add message for missing/invalid
    if (this.allControls.map(c1 => {
      let errs = c1.errors;
      if (errs) {
        delete errs['datevalidation'];
        if (Object.keys(errs).length <= 0) {
          errs = null;
        }
      }
      return errs;
    }).filter(c2 => c2 != null).length > 0) {
      this.errorService.setErrorObject({ message: 'Missing or Invalid Fields.' });
      isOK = false;
    }

    return isOK;
  }

  private addValidationError(ctrl: UntypedFormControl, key: string) {
    let errs: ValidationErrors = ctrl.errors;

    if (errs) {
      errs[key] = true;
    } else {
      errs = {};
      errs[key] = true;
    }
    ctrl.setErrors(errs);
  }

  private removeValidationError(ctrl: UntypedFormControl, key: string) {
    let errs: ValidationErrors = ctrl.errors;

    if (errs) {
      delete errs[key];
    }

    if (errs && (Object.keys(errs).length <= 0)) {
      errs = null;
    }

    ctrl.setErrors(errs);
  }

  submit() {
    if (this.validateForm()) {
      //Commented this code to be able to edit both Begin and End dates: ADO#71018
      //if (this.modalInput.fromLOAReport) {
        // save new end date
      //  this.setLeaveOfAbsenceEndDate();
     // } else {
        // save both dates
        this.setLeaveOfAbsenceDateRange();
      //}
      
    } 
  }


  cancel() {
    // notify the modal window to close
    this.dhpService.notifyModalClose();
  }

  // updates a driver's leave of absence using a provided date range
  private setLeaveOfAbsenceDateRange() {
    let beginDateISO = (new Date(this.beginDate)).toISOString().slice(0, 10);
    let returnDateISO = (new Date(this.returnDate)).toISOString().slice(0, 10);

    this.loadingSpinnerService.show();
    this.onPremService.put('status/leaveOfAbsence/schedule/' + this.modalInput.driverId + '/' + beginDateISO + '/' + returnDateISO,
      null
    ).subscribe({
      next: (data) => {
        if (this.modalInput.fromLOAReport) {
          // if calling from LOA report, refresh report on submit
          this.loadingSpinnerService.hide();
          this.dataGridModalService.openModal(this.dataGridMenuService.getLeaveOfAbsenceReportId());
        } else {
          if (!this.modalInput.fromAdvancedSearch) {
            // refresh driver profile data or navigate to LOA report
            let beginDate = new Date(this.beginDate);
            if (beginDate < this.dtTomorrow) {
              // if beginDate <= today, go to Leave of Absence report page
              this.loadingSpinnerService.hide();
              this.dataGridModalService.openModal(this.dataGridMenuService.getLeaveOfAbsenceReportId());
            } else {
              // stay on form, update profile data
              this.dhpService.setRefreshDriverProfileLeaveOfAbsenceData(this.modalInput.driverId);
            }
          }
        }

        // notify the modal windows to close
        this.dhpService.notifyModalClose();
        this.safetyModalWindowService.notifyModalClose();

        this.showMessage("Leave Of Absence Set");

      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  // updates a driver's leave of absence end date
  private setLeaveOfAbsenceEndDate() {
    const returnDateISO = (new Date(this.returnDate)).toISOString().slice(0, 10);

    this.loadingSpinnerService.show();
    this.onPremService.put('/status/leaveOfAbsence/end/' + this.modalInput.driverId + '/' + returnDateISO,
      null
    ).subscribe({
      next: (data) => {
        if (this.modalInput.fromLOAReport) {
          // if calling from LOA report, refresh report on submit
          this.loadingSpinnerService.hide();
          this.dataGridModalService.openModal(this.dataGridMenuService.getLeaveOfAbsenceReportId());
        } else {
          // no action - edit end date not available in driver profile
        }

        // notify the modal windows to close
        this.dhpService.notifyModalClose();
        this.safetyModalWindowService.notifyModalClose();

        this.showMessage("Leave Of Absence Updated");
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

  }

  // provides a central point for handling errors
  handleError(message: string) {
    //Do nothing
  }

  // sleeps for a certain number of milliseconds
  sleep(milliseconds: number) {
    const start = new Date().getTime();
    for (let i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }

  private showMessage(message: string): void {
    this.snackBar.open(message, 'Ok', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: 'success-snackbar'
    });
  }
}
