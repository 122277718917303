import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { CustomReportGridService, CustomReportGridRequest } from './custom-report-grid.service';

@Injectable()
export class CustomReportGridResolve  {
  constructor(private customReportService: CustomReportGridService,
    private router: Router,
    private reportRequest: CustomReportGridRequest) {
    
  }

  resolve(route: ActivatedRouteSnapshot): Promise<any> | boolean {

    
     // let id = route.params["id"] as string;
     //// let req1: ReportRequest = new ReportRequest();
     // //req1.DataGridId = this.gridRequest.DataGridId;
     // //console.log(id);

     //// this.searchRequest.SelectedClients = [ "BI1"];
     // //this.searchRequest.LineOfBusinessId = 1;
     // //this.searchRequest.SearchColumnFilterId = 1;
     // var req1 = new CustomReportGridRequest();
     // req1.ReportId = 1;
     //   req1.ReportName = "Test";
     //   req1.StartDate = new Date("2018-02-06T17:25:53.079Z");
     //   req1.EndDate = new Date("2018-07-06T17:25:53.079Z");
     //   req1.Clients = "BI1";
     //   req1.DateField = "DateOfLoss";
    try {
      let reportReq = JSON.parse(atob(route.params["id"])) as CustomReportGridRequest
      this.reportRequest.Clients = reportReq.Clients;
      this.reportRequest.DateField = reportReq.DateField;
      this.reportRequest.DateFieldDisplayValue = reportReq.DateFieldDisplayValue;
      this.reportRequest.EndDate = reportReq.EndDate;
      this.reportRequest.ReportId = reportReq.ReportId;
      this.reportRequest.ReportName = reportReq.ReportName;
      this.reportRequest.StartDate = reportReq.StartDate;

    }
    catch (e) {

    }
    return this.customReportService.getData(this.reportRequest).then(x => {
            if (x) {
                return x;
            } else { // id not found
                this.router.navigate(['/']);
                return false;
            }
    }).catch(e => {
      console.log(e)

      return e;
    });
    }
}
