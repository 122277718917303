import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { DateFilterService } from '../date-filter-service/date-filter-service.component';
import { LineOfBusinessService } from '../line-of-business-service/line-of-business-service.component';
import { DashboardService, DashboardLastUpdatedObject } from '../dashboard-service/dashboard-service.component';
import { ClientSelectionService, ClientSelectionsObject } from '../client-selection-service/client-selection-service.component';
import { BrowserInfoService } from '../browser-info-service/browser-info-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { DashboardDateFilterRange, DashboardFilterRecord, CorporateStructure } from '../classes-and-interfaces/classes-and-interfaces.component';
import { Subscription } from 'rxjs';
import { ErrorModalService } from '../error-modal/error-modal-service.component';
import { Router } from '@angular/router';
import { OnPremDriverService } from '../on-prem-service/on-prem-driver-service.component';

@Component({
  selector: 'app-dashboard-filter-summary',
  templateUrl: './dashboard-filter-summary.component.html',
  styleUrls: ['./dashboard-filter-summary.component.css']
})

export class DashboardFilterSummaryComponent implements OnInit, OnDestroy, OnChanges {

  dashboardFilterInfo: Array<DashboardFilterRecord> = [];
  private lobSummaryText = '';
  private dateFilterSummaryText = '';
  private filterSummaryText = '';
  fullSummaryText = '';
  dateLastUpdatedText = '';
  @Input() lineOfBusinessId: number;
  @Input() clientSelectedArray: Array<string>;
  @Input() clientIdSelectedArray: Array<number>;
  @Input() globalDateFilter: DashboardDateFilterRange;
  private lineOfBusinessChoices: Array<any> = [];
  lineOfBusinessChoicesSubscription: Subscription;
  lineOfBusinessChangeSubscription: Subscription;
  filterInfoArraySubscription: Subscription;
  dashboardLastUpdatedSubscription: Subscription;

  constructor(private router: Router,
    private dateFilterService: DateFilterService,
    private lineOfBusinessService: LineOfBusinessService,
    private dashboardService: DashboardService,
    private clientSelectionService: ClientSelectionService,
    private browserInfoService: BrowserInfoService,
    private datePipe: DatePipe,
    private onPremService: OnPremDriverService,
    private errorService: ErrorModalService
  ) { }

  ngOnInit() {
    this.calcLOBSummaryText();
    this.calcDateLastUpdatedText(this.lineOfBusinessId, this.dashboardService.getDashboardLastUpdatedValue(this.lineOfBusinessId));

    /*
    this.dateFilterService.getGlobalDateFilter(this.lineOfBusinessId).subscribe(data => {
      this.globalDateFilter = data as DashboardDateFilterRange;
      this.calcDateFilterSummaryText();
    },
      (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
      }
    );
    */

    /*
    // global date filter subscription
    this.globalDateFilterSubscription = this.dateFilterService.globalDateFilter$.subscribe(
      globalDateFilter => {
        this.globalDateFilter = globalDateFilter as DashboardDateFilterRange;
        this.calcDateFilterSummaryText();
      }
    );
    */

    // client id subscription
    // get client id array then look up filter info
    /*
    this.clientIdSelectionSubscription = this.clientSelectionService.clientSelectedInfo$.subscribe(
      clientSelectedInfo => {
        let temp = clientSelectedInfo as ClientSelectionsObject;
        if (temp[this.lineOfBusinessId]) {
          let cArr = temp[this.lineOfBusinessId].clientIdSelectedArray;
          if (!this.arrayCompare(cArr, this.clientIdSelectedArray)) {
            this.clientIdSelectedArray = cArr;
            this.clientSelectedArray = temp[this.lineOfBusinessId].clientSelectedArray;
            this.dashboardService.getFilterDataMultiClient(this.lineOfBusinessId, this.clientIdSelectedArray).subscribe(data => {
              this.dashboardFilterInfo = data as Array<DashboardFilterRecord>;
              this.calcFilterSummaryText();
            },
              (err: HttpErrorResponse) => {
                //this.toastrService.error(err.error.toString());
                this.errorService.setErrorObject(err.error);
              });
          }
        }
      }
    );
    */

    // subscribe to changes in Line of Business choices, so we can
    // calculate line of business label
    this.lineOfBusinessChoicesSubscription = this.lineOfBusinessService.getLineOfBusinessChoices().subscribe(
      lineOfBusinessChoices => {
        this.lineOfBusinessChoices = lineOfBusinessChoices as Array<any>;
        this.calcLOBSummaryText();
      }
    );


    // filterInfoArray subscription
    this.filterInfoArraySubscription = this.dashboardService.filterInfoArray$.subscribe(
      filterInfoArray => {
        this.dashboardFilterInfo = filterInfoArray as Array<DashboardFilterRecord>;
        this.calcFilterSummaryText();
      }
    );

    // update last updated date when grid is fully refreshed
    this.dashboardLastUpdatedSubscription = this.dashboardService.dashboardLastUpdated$.subscribe(
      dtInfo => {
        const dt = dtInfo as DashboardLastUpdatedObject;
        this.calcDateLastUpdatedText(this.lineOfBusinessId, dt[this.lineOfBusinessId]);
      }
    );

    this.lineOfBusinessChangeSubscription = this.lineOfBusinessService.lineOfBusinessSelected$.subscribe(lob => {
      if (lob !== this.lineOfBusinessId) {
        this.lineOfBusinessId = lob;
        this.calcLOBSummaryText();
      }
    }

    );

    // subscribe to IE browser detection - reload action is
    // handled differently for IE
    /* 2019-02 - no longer needed since we are no longer using using powerbi
    this.ieDetectionSubscription = this.browserInfoService.isIE$.subscribe(isIE => {
      this.isIE = isIE;
      //if (this.isIE) {alert("IE!!!!")}
    });
    */
  }

  ngOnChanges(changes: SimpleChanges) {
    // recalc global date filter text if globalDateFilter is updated
    if (changes.hasOwnProperty('globalDateFilter') && changes['globalDateFilter'].currentValue) {
      this.calcDateFilterSummaryText();
    }

    // recalc filter summary text if client selections change
    let clientArrayOld = [];
    let clientArrayNew = [];
    let clientIdArrayOld = [];
    let clientIdArrayNew = [];

    if (
      changes.hasOwnProperty('clientSelectedArray') &&
      changes['clientSelectedArray'].previousValue &&
      changes['clientSelectedArray'].previousValue.length > 0
    ) {
      clientArrayOld = changes['clientSelectedArray'].previousValue;
    }

    if (
      changes.hasOwnProperty('clientSelectedArray') &&
      changes['clientSelectedArray'].currentValue &&
      changes['clientSelectedArray'].currentValue.length > 0
    ) {
      clientArrayNew = changes['clientSelectedArray'].currentValue;
    }

    if (
      changes.hasOwnProperty('clientIdSelectedArray') &&
      changes['clientIdSelectedArray'].previousValue &&
      changes['clientIdSelectedArray'].previousValue.length > 0
    ) {
      clientIdArrayOld = changes['clientIdSelectedArray'].previousValue;
    }

    if (
      changes.hasOwnProperty('clientIdSelectedArray') &&
      changes['clientIdSelectedArray'].currentValue &&
      changes['clientIdSelectedArray'].currentValue.length > 0
    ) {
      clientIdArrayNew = changes['clientIdSelectedArray'].currentValue;
    }

    if ((!this.arrayCompare(clientArrayOld, clientArrayNew)) || (!this.arrayCompare(clientIdArrayOld, clientIdArrayNew))) {
      this.clientIdSelectedArray = clientIdArrayNew;
      this.clientSelectedArray = clientArrayNew;
      switch (this.lineOfBusinessId) {
        case 2:
          this.GetSafetyFilterStatus();
          break;
        default:
          this.dashboardService.getFilterDataMultiClient(this.lineOfBusinessId, this.clientIdSelectedArray).subscribe({
            next: (data) => {
              this.dashboardFilterInfo = data as Array<DashboardFilterRecord>;
              this.calcFilterSummaryText();
            },
            error: (err: HttpErrorResponse) => {
              this.errorService.setErrorObject(err.error);
            }
          });
      }

    }
  }

  ngOnDestroy() {
    this.lineOfBusinessChoicesSubscription.unsubscribe();
    this.filterInfoArraySubscription.unsubscribe();
    this.dashboardLastUpdatedSubscription.unsubscribe();
    this.lineOfBusinessChangeSubscription.unsubscribe();
  }

  private calcLOBSummaryText() {
    const label = this.lineOfBusinessService.getLineOfBusinessLabel(this.lineOfBusinessId);
    if (label.length > 0) {
      this.lobSummaryText = label + ' StatCenter';
    }
    this.calcFullSummaryText();
  }

  private calcDateFilterSummaryText() {
    // get start & end dates adjusted to common time zone
    const startDate = new Date(new Date(this.globalDateFilter.startDate).toISOString().substr(0, 10) + 'T00:00:00.000Z');
    const endDate = new Date(new Date(this.globalDateFilter.endDate).toISOString().substr(0, 10) + 'T00:00:00.000Z');

    const diff = Math.abs(startDate.getTime() - endDate.getTime());
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24)) + 1;

    const startDateString = this.datePipe.transform(this.globalDateFilter.startDate, 'MM/dd/yyyy');
    const endDateString = this.datePipe.transform(this.globalDateFilter.endDate, 'MM/dd/yyyy');

    this.dateFilterSummaryText = startDateString + ' thru ' + endDateString + ' (' + diffDays.toString() + ' days)';

    this.calcFullSummaryText();
  }

  private calcFilterSummaryText() {
    switch (this.lineOfBusinessId) {
      case 1:
        if (this.dashboardFilterInfo.length > 0) {
          const els = this.dashboardFilterInfo.map(e => e.element);
          const f = Array.from(new Set(els));
          this.filterSummaryText = 'Filter: ' + f.join(', ');
        } else {
          this.filterSummaryText = '';
        }
        this.calcFullSummaryText();
        break;
      case 2:
      case 7:
        this.GetSafetyFilterStatus();
        break;
    }

  }

  private calcFullSummaryText() {
    const arrText = [];
    if (this.lobSummaryText.length > 0) {
      arrText.push(this.lobSummaryText);
    }
    if (this.dateFilterSummaryText.length > 0) {
      arrText.push(this.dateFilterSummaryText);
    }
    if (this.filterSummaryText.length > 0) {
      arrText.push(this.filterSummaryText);
    }
    this.fullSummaryText = arrText.join(' - ');
  }

  private calcDateLastUpdatedText(lineOfBusiness: number, dt: Date) {
    if (dt) {
      this.dateLastUpdatedText = this.datePipe.transform(dt, 'short');
    } else {
      this.dateLastUpdatedText = '';
    }
  }

  reloadDashboard() {
    this.router.navigate(['statcenter', this.lineOfBusinessId.toString()]);
  }

  reloadDashboardOLD() {
    // 2019-02 - no longer using this reload logic since no longer using powerbi
    /*
    if (this.isIE) {
      // do full reload in IE to release powerbi/iframe memory
      window.location.reload(true);
    } else {
      // use angular navigation
      this.router.navigate(['statcenter', this.lineOfBusinessId.toString()], { queryParams: { forceReload: 'true' } });
    }
    */
  }

  private arrayCompare(a1, a2) {
    return (a1.length === a2.length && a1.every((v, i) => v === a2[i]));
  }

  private GetSafetyFilterStatus() {

    if (!this.clientSelectedArray || this.clientSelectedArray.length === 0) {
      return;
    }

    this.onPremService.get('hierarchy/structureForFilter/' + this.clientSelectedArray.join(','))
      .subscribe({
        next: (data) => {
          const arrCH = data as Array<CorporateStructure>;

          if (arrCH && (arrCH.length > 0) && (arrCH[0].isSelected !== 1)) {
            this.filterSummaryText = 'Filter: Results are filtered.';
          } else {
            this.filterSummaryText = '';
          }

          this.calcFullSummaryText();

        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
        }
      });
  }

}
