<div class="row">
    <div class="col-md-12">
        <div class="card card-body mt-3">
        <h4 class="card-title text-muted">Additional Messages</h4>
        <div *ngIf="additionalMessagesTableConfig">
            <app-data-table 
                [columns]="additionalMessagesTableConfig.fields"
                [data]="additionalMessagesTableConfig.data"
                [noRecordsLabel]="additionalMessagesTableConfig?.noRecordsLabel"
            ></app-data-table>
        </div>
        </div>
    </div>
</div>