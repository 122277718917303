//Angular
import { Component, Input, Output, OnChanges, ViewChild, EventEmitter, Inject } from '@angular/core';
//Third Party
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//APP
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";

@Component({
  selector: 'dq-reject-modal',
  templateUrl: './dq-reject-modal.component.html',
  styleUrls: ['./dq-reject-modal.component.scss']
})
export class DQRejectModalComponent {
  dqType: string;
  rejectOptions: Array<DqRejectModalReason>;
  rejectionReasonIdString: string;
  otherRejectionReason: string;
  isReasonRequired: boolean = false;
  selectedReasonLabel: string = "";

  constructor(private errorService: ErrorModalService,
    @Inject(MAT_DIALOG_DATA) public data: {dqType: string, rejectOptions: Array<DqRejectModalReason> },
    private dialogRef: MatDialogRef<DQRejectModalComponent>) {
      this.dqType = data.dqType;
      this.rejectOptions = data.rejectOptions;
     }

  onReasonSelect(rejectionReasonIdString: string): void {
    let reasonNeededArray = this.rejectOptions.filter(opt => opt.isReasonRequired && (opt.value == rejectionReasonIdString));

    if (reasonNeededArray.length > 0) {
      this.isReasonRequired = true;
      this.selectedReasonLabel = reasonNeededArray[0].label;
    } else {
      this.isReasonRequired = false;
      this.selectedReasonLabel = "";
    }
    
  }

  submit(): void {
    if (this.isReasonRequired && ((!this.otherRejectionReason) || (this.otherRejectionReason.length <= 0))) {
      this.errorService.setErrorObject({ message: "Description required if Reject Reason is " + this.selectedReasonLabel });
    } else {
      this.dialogRef.close({ rejectionReasonIdString: this.rejectionReasonIdString, otherRejectionReason: this.otherRejectionReason })
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}

export interface DqRejectModalReason {
  value: string;
  label: string;
  isReasonRequired: boolean;
}
