<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div >
  <nav class="navbar-dark dashboardFilterPanelActionBar div_top_hypers" style="padding: 0.5em;">
    <ul class="nav navbar-nav nav-flex-icons ml-auto inlineStyle p-2">
      <li class="nav-item inlineStyle">
        <a (click)="onPrint()">
          <span class='fa fa-print'></span> Print
        </a>
      </li>
      <li class="nav-item inlineStyle">
        <a (click)="onExcel()">
          <span class='fa fa-file-excel-o'></span> Export to Excel
        </a>
      </li>
      <li class="nav-item inlineStyle">
        <a (click)="onEmail()">
          <span class='fa fa-envelope'></span>
          Export to Email
        </a>
      </li>
    </ul>
  </nav>
  <app-data-grid-export-email [lineOfBusiness]="lineOfBusinessId"></app-data-grid-export-email>

  <H3>Certificate of Insurance Validation</H3>
  <div>
    <app-loading-panel *ngIf="!configObject"></app-loading-panel>
    <div *ngIf="configObject">
      <app-data-table 
        [columns]="configObject.fields"
        [data]="configObject.data"
        noRecordsLabel="No Review Information"
        (triggerSortChange)="sortChange($event)"
        [showPagination]="true"
      ></app-data-table>
    </div>

    <div id="reportFooter">
      <div class="left"> Fleet Response Confidential</div>
      <div class="right"> Report run {{today | date :'short' }}</div>
    </div>
  </div>

</div>