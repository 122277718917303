import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, NavigationEnd, ResolveEnd } from '@angular/router';
import { QuickSearchService, GridData, QuickSearchRequest } from './quick-search.service';

@Injectable()
export class QuickSearchResolve  {
  constructor(private quickSearchService: QuickSearchService, private router: Router, private searchRequest: QuickSearchRequest) {
    this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        //this.reloadGridData();
      }
      if (event instanceof ResolveEnd) {
        //let id = route.params["id"] as string;
        //// let req1: ReportRequest = new ReportRequest();
        ////req1.DataGridId = this.gridRequest.DataGridId;
        ////console.log(id);

        //// this.searchRequest.SelectedClients = [ "BI1"];
        //this.searchRequest.LineOfBusinessId = 1;
        //this.searchRequest.SearchColumnFilterId = 1;

        //return this.quickSearchService.getData(this.searchRequest).then(x => {
        //  if (x) {
        //    return x;
        //  } else { // id not found
        //    this.router.navigate(['/']);
        //    return false;
        //  }
        //});
      }
    });
  }

  resolve(route: ActivatedRouteSnapshot): Promise<any> | boolean {
    let id = route.params["id"] as string;
    // let req1: ReportRequest = new ReportRequest();
    //req1.DataGridId = this.gridRequest.DataGridId;
    //console.log(id);

    // this.searchRequest.SelectedClients = [ "BI1"];
    //this.searchRequest.LineOfBusinessId = 1;
    //this.searchRequest.SearchColumnFilterId = 1;

    if (!this.searchRequest.SearchText) {
      try {
        let req1 = JSON.parse(sessionStorage.getItem('quicksearch_' + id)) as QuickSearchRequest;
        this.searchRequest.SearchText = req1.SearchText;
        this.searchRequest.LineOfBusinessId = req1.LineOfBusinessId;


        this.searchRequest.SelectedClients = req1.SelectedClients;
        this.searchRequest.SearchAcrossClients = req1.SearchAcrossClients;
        this.searchRequest.SearchColumnFilterId = req1.SearchColumnFilterId;
        this.searchRequest.dummy = req1.dummy;
      }
      catch { }

    }



    return this.quickSearchService.getData(this.searchRequest).then(x => {
      if (x) {
       
        if (x.rows != null && x.rows.length == 1) {
          switch (this.searchRequest.LineOfBusinessId) {
            case 1: {
              console.log("quicksearch redirect to claim:")
              console.log(x);
              this.router.navigateByUrl("/accident/claim/" + x.rows[0].Claim);
              return false;
            }
            case 2: {
              console.log("quicksearch redirect to safety driver profile:")
              console.log(x);
              this.router.navigateByUrl("/driver/history-profile/" + x.rows[0].driverId);
              return false;
            }
            case 7: {
              console.log("quicksearch redirect to dc driver profile:")
              console.log(x);
              this.router.navigateByUrl("/dc/driverprofile/" + x.rows[0].driverId);
              return false;
            }
          }
        }
        return x;
      } else { // id not found
        this.router.navigate(['/']);
        return false;
      }
    }).catch(e => {
      console.log(e)
      return e;
    });
  }
}
