//Angular
import { Component, Inject } from '@angular/core';
//Third Party
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//APP

@Component({
  selector: 'confirm-modal-generic',
  templateUrl: './confirm-modal-generic.component.html',
  styleUrls: ['./confirm-modal-generic.component.scss']
})
export class ConfirmModalGenericComponent {
  confirmGenericTitle: string;
  message: string;
  yesLabel: string;
  noLabel: string;

  constructor(
    private dialogRef: MatDialogRef<ConfirmModalGenericComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {confirmGenericTitle: string, message: string, yesLabel: string, noLabel: string}
  ) {
    this.confirmGenericTitle = data.confirmGenericTitle;
    this.message = data.message;
    this.yesLabel = data.yesLabel;
    this.noLabel = data.noLabel;
  }

  confirmModalGenericYes() {
    this.dialogRef.close(true);
  }

  confirmModalGenericNo() {
    this.dialogRef.close(false);
  }

}

