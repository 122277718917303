import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LegacyBatch as Legacy_Batch } from './batch';
import { OnPremDriverService } from '../../../components/on-prem-service/on-prem-driver-service.component';

// DHP Next Gen
import {
  BatchCommandService
} from '../../../services/fleet-command-services';
import { Batch } from '../../models/batch.model';

@Injectable({
  providedIn: 'root'
})

export class SubmitBatchService {

  constructor(
    private readonly legacySvc: OnPremDriverService,
    private readonly batchCommandService: BatchCommandService
  ) { }

  legacy_SubmitBatch(data: Legacy_Batch): Observable<Object> {
    return this.legacySvc.post('tasks/batch', JSON.stringify(data));
  }

  submitBatch(data: Batch[]): Observable<Object> {
    return this.batchCommandService.post(
      'batch',
      JSON.stringify(data)
    );
  }
}
