
<div class="row" *ngIf="rentalDetails?.length > 0">
  <div class="col-md-12">
    <h4>Rental Information</h4>
  </div>
  <div class="col-md-12">
    <table class="table table-striped table-bordered accidentClaimRentalTable">
      <thead>
        <tr class="table-header">
          <th scope="col">Reservation ID</th>
          <th scope="col">Rate Type</th>
          <th scope="col">Daily Rate</th>
          <th scope="col">Days</th>
          <th scope="col">Date In</th>
          <th scope="col">Date Out</th>
          <th scope="col">Invoiced</th>
          <th scope="col">Agency</th>
          <th scope="col">Note</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let detail of rentalDetails">
          <td>{{detail.rentalId}}</td>
          <td>{{detail.rateType}}</td>
          <td>{{detail.dailyRate | currency:'USD':'$':'1.2-2'}}</td>
          <td>{{detail.invoiceDays}}</td>
          <td>{{detail.dateIn |  date:'M/d/yyyy'}}</td>
          <td>{{detail.dateOut |  date:'M/d/yyyy'}}</td>
          <td>{{detail.rentalAmount | currency:'USD':'$':'1.2-2'}}</td>
          <td>{{detail.agency}}</td>
          <td>{{detail.invoiceStatus}}</td>
        </tr>
        <tr class="table-footer">
          <td>INVOICES:</td>
          <td></td>
          <td></td>
          <td>{{ getRentalDaysTotal() }}</td>
          <td></td>
          <td></td>
          <td>{{ getInvoicedTotal() | currency:'USD':'$':'1.2-2' }}</td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row" *ngIf="rentalDetails?.length == 0">
  <div class="col-md-3">Rental Information</div>
  <div class="col-md-9 font-weight-bold">(No rental information available)</div>
</div>
