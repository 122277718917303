//Angular
import { Component, Inject, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy, ViewChildren, QueryList, AfterViewChecked } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
//Third Party
import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
//App
import { DashboardService } from '../dashboard-service/dashboard-service.component';
import { ClientSelectionService } from '../client-selection-service/client-selection-service.component';
import { GridFilterClient } from "../../app.component";
import { DashboardFilterRecord, DashboardTile, ClientSelectionObject } from "../classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../error-modal/error-modal-service.component";
import { MatSelectOption } from '../../shared/models/mat-select-option.model';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
    selector: 'app-dashboard-filter-standard',
    templateUrl: './dashboard-filter-standard.component.html',
    styleUrls: ['./dashboard-filter-standard.component.scss']
})

export class DashboardFilterStandardComponent implements OnInit, AfterViewChecked {
    @ViewChildren("selectClients") selectClients: QueryList<MatSelect>;
    selectClientRef: MatSelect = null;
    //@Input()

    dashboardFilterInfo: Array<DashboardFilterRecord>;
    showClaimsFilterPanelSub: Subscription;
    @Output() onGridFilterUpdate: EventEmitter<any> = new EventEmitter<any>();

    gridFilterClientChoices: Array<any>;
    selectFilteredClientChoices: Array<MatSelectOption>;
    gridFilterCriteriaChoices: Array<GridFilterOperator>;
    gridFilterReqChoices: Array<any>;
    gridFilterPanelEdit: boolean;
    gridFilterClientChosen: string = "";
    gridFilterReqNew: string = "";
    gridFilterCriteriaNew: string = "";
    gridFilterValNew: string = "";
    baseUrl: string;
    constructor(private http: HttpClient,
        @Inject(MAT_DIALOG_DATA) private data: { lineOfBusinessId: number, clientSelectedArray: Array<string>, clientIdSelectedArray: Array<number> },
        @Inject('BASE_URL') baseUrl: string,
        private clientSelectionService: ClientSelectionService,
        private dashboardService: DashboardService,
        //private toastrService: ToastService,
        private loadingSpinnerService: LoadingSpinnerService,
        private errorService: ErrorModalService,
        private dialogRef: MatDialogRef<DashboardFilterStandardComponent>,
    ) {
        this.http = http;
        this.baseUrl = baseUrl;
        this.gridFilterPanelEdit = true;
        this.getGridFilterCriteriaChoices();

    }

    ngOnInit() {
        this.getGridFilterClientChoices(this.data.lineOfBusinessId);
    }

    ngAfterViewChecked(): void {
        if (!this.selectClientRef && this.selectClients?.first) {
            this.selectClientRef = this.selectClients.first;
            this.selectClientRef._elementRef.nativeElement.addEventListener(
                "keydown",
                (event) => {
                    if (event.code === "Space") {
                        event.preventDefault();
                    }
                }
            );
        }
    }

    private getGridFilterData(LineOfBusinessId: number) {
        if (this.gridFilterClientChoices && (this.gridFilterClientChoices.length > 0)) {
            this.dashboardService.getFilterDataMultiClient(LineOfBusinessId,
                this.gridFilterClientChoices.map(x => { return x.value })).subscribe({
                    next: (data) => {
                        this.dashboardFilterInfo = (data as Array<DashboardFilterRecord>).sort((a, b) => a.clientName.localeCompare(b.clientName));
                        // update summary text for selected clients
                        let filterSummaryInfo = this.dashboardFilterInfo.filter(f => this.data.clientIdSelectedArray.indexOf(+f.clientId) >= 0);
                        this.dashboardService.setFilterData(filterSummaryInfo);
                    },
                    error: (err: HttpErrorResponse) => {
                        //this.toastrService.error(err.error.toString());
                        this.errorService.setErrorObject(err.error);
                    }
                })
        }

    }

    private getGridFilterClientChoices(LineOfBusinessId: number) {
        this.clientSelectionService.getClientChoices(LineOfBusinessId).subscribe({
            next: (data) => {
                const tempin: Array<GridFilterClient> = data as Array<GridFilterClient>;
                const tempout: Array<any> = [];
                tempin.forEach((element) => {
                    const tempobj = {
                        value: element.clientId,
                        label: element.clientName
                    };
                    tempout.push(tempobj);
                });
                this.gridFilterClientChoices = tempout;
                this.selectFilteredClientChoices = this.gridFilterClientChoices;
                this.getGridFilterData(1);
            },
            error: (err: HttpErrorResponse) => {
                //this.toastrService.error(err.error.toString());
                this.errorService.setErrorObject(err.error);
            }
        })
    }

    private getGridFilterReqChoices(clientId: number) {
        if (!clientId)
            return;
        let params = new HttpParams();
        params = params.append('LineOfBusinessId', this.data.lineOfBusinessId.toString());
        params = params.append('ClientId', clientId.toString());

        this.http.get(this.baseUrl + 'api/UserFilter/FilterElements', {
            params: params
        }).subscribe({
            next: (data) => {
                const tempin: Array<GridFilterElement> = data as Array<GridFilterElement>;
                const tempout: Array<any> = [];
                tempin.forEach((element) => {
                    const tempobj = {
                        value: element.filterElementId,
                        label: element.filterElementName
                    };
                    tempout.push(tempobj);
                });
                this.gridFilterReqChoices = tempout;
            },
            error: (err: HttpErrorResponse) => {
                //this.toastrService.error(err.error.toString());
                this.errorService.setErrorObject(err.error);
            }
        })
    }

    private getGridFilterCriteriaChoices() {
        this.http.get(this.baseUrl + 'api/UserFilter/Operators')
            .subscribe({
                next: (data) => {
                    const tempin: Array<GridFilterOperator> = data as Array<GridFilterOperator>;
                    const tempout: Array<any> = [];
                    tempin.forEach((element) => {
                        const tempobj = {
                            value: element.filterOperatorId,
                            label: element.filterOperatorName
                        };
                        tempout.push(tempobj);
                    });
                    this.gridFilterCriteriaChoices = tempout;
                },
                error: (err: HttpErrorResponse) => {
                    //this.toastrService.error(err.error.toString());
                    this.errorService.setErrorObject(err.error);
                }
            })
    }

    selectClientGridFilter(clientId: number) {
        this.getGridFilterReqChoices(clientId);
        this.getGridFilterData(this.data.lineOfBusinessId);
    }


    editGridFilterInfo() {
        this.gridFilterPanelEdit = true;
    }

    closeFilterPanel() {
        this.loadingSpinnerService.hide();
        this.resetAllInputs();
        this.gridFilterPanelEdit = true;
        this.dialogRef.close('closePanels');
    }

    gridFilterClearNew() {
        this.gridFilterReqNew = "";
        this.gridFilterCriteriaNew = "";
        this.gridFilterValNew = "";
    }

    gridFilterAddNew() {
        let params = new HttpParams();
        params = params.append('ReqDataElementId', this.gridFilterReqNew.toString());
        params = params.append('FilterOperatorId', this.gridFilterCriteriaNew.toString());
        params = params.append('FilterValue', this.gridFilterValNew.toString());
        params = params.append('IsUserEditable', 'true');
        params = params.append('ClientId', this.gridFilterClientChosen.toString());
        params = params.append('LineOfBusinessId', this.data.lineOfBusinessId.toString());

        this.loadingSpinnerService.show();
        this.http.put(this.baseUrl + 'api/UserFilter/Add', null, {
            params: params
        }).subscribe({
            next: () => {
                // update filter table
                this.getGridFilterData(this.data.lineOfBusinessId);
                // update Grid
                this.dashboardService.getTileArray(this.data.lineOfBusinessId, this.data.clientSelectedArray).subscribe({
                    next: (data) => {
                        this.dashboardService.setTileArray(data as Array<DashboardTile>);
                        this.loadingSpinnerService.hide();
                    },
                    error: (err: HttpErrorResponse) => {
                        //this.toastrService.error(err.error.toString());
                        this.errorService.setErrorObject(err.error);
                        this.loadingSpinnerService.hide();
                    }
                });
            },
            error: (err: HttpErrorResponse) => {
                //this.toastrService.error(err.error.toString());
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        }
        )
    }

    filterOptions(value: string): void {
        const filterValue = value.toLowerCase();
        this.selectFilteredClientChoices = this.gridFilterClientChoices.filter(option => option.label.toLowerCase().startsWith(value));
    }

    private gridFilterUpdateIsUserEditable(record: DashboardFilterRecord) {
        //record.isUserEditable = !record.isUserEditable;
        let params = new HttpParams();
        params = params.append('filterid', record.userClientGroupFilterId.toString());
        params = params.append('IsUserEditable', record.isUserEditable.toString());

        this.loadingSpinnerService.show();
        this.http.post(this.baseUrl + 'api/UserFilter/Update', null, {
            params: params
        }).subscribe({
            next: () => {
                // update filter table
                this.getGridFilterData(this.data.lineOfBusinessId);
                // update Grid
                this.dashboardService.getTileArray(this.data.lineOfBusinessId, this.data.clientSelectedArray).subscribe({
                    next: (data) => {
                        this.dashboardService.setTileArray(data as Array<DashboardTile>);
                        this.loadingSpinnerService.hide();
                    },
                    error: (err: HttpErrorResponse) => {
                        //this.toastrService.error(err.error.toString());
                        this.errorService.setErrorObject(err.error);
                        this.loadingSpinnerService.hide();
                    }
                });
            },
            error: (err: HttpErrorResponse) => {
                //this.toastrService.error(err.error.toString());
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        }
        )
    }

    gridFilterRemove(FilterId: string) {
        let params = new HttpParams();
        params = params.append('FilterId', FilterId);

        this.loadingSpinnerService.show();
        this.http.delete(this.baseUrl + 'api/UserFilter', {
            params: params
        }).subscribe({
            next: () => {
                // update filter table
                this.getGridFilterData(this.data.lineOfBusinessId);
                // update Grid
                this.dashboardService.getTileArray(this.data.lineOfBusinessId, this.data.clientSelectedArray).subscribe({
                    next: (data) => {
                        this.dashboardService.setTileArray(data as Array<DashboardTile>);
                        this.loadingSpinnerService.hide();
                    },
                    error: (err: HttpErrorResponse) => {
                        this.errorService.setErrorObject(err.error);
                        this.loadingSpinnerService.hide();
                    }
                });
            },
            error: (err: HttpErrorResponse) => {
                this.errorService.setErrorObject(err.error);
                this.loadingSpinnerService.hide();
            }
        }
        )
    }

    private resetAllInputs() {
        this.gridFilterClientChoices = [];
        this.gridFilterReqChoices = [];
        this.gridFilterClientChosen = "";
        this.gridFilterReqNew = "";
        this.gridFilterCriteriaNew = "";
        this.gridFilterValNew = "";
    }
}

export interface GridFilterInfo {
    LineOfBusinessId: number;
    clientId: number;
    clientName: string;
    records: Array<DashboardFilterRecord>;
}

export interface GridFilterOperator {
    filterOperatorId: number,
    filterOperatorName: string
}
export interface GridFilterElement {
    filterElementId: number,
    filterElementName: string
}

