import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DcSubscriptionSlot, DcDriver } from '../dc-classes-and-interfaces/dc-classes-and-interfaces.component';

@Component({
  selector: 'dc-programs-driver-slots-detail',
  templateUrl: './dc-programs-driver-slots-detail.component.html'
})

export class DcProgramsDriverSlotsDetailComponent implements OnChanges {
  @Input() arrSlotDetail: Array<DcSubscriptionSlot>
  public configObject: any;
  gtOptions: any = {};

  constructor(private router: Router,
    private datePipe: DatePipe){}

  ngOnChanges() {
    if (this.arrSlotDetail) {
      this.setupGrid(this.arrSlotDetail);
    }
  }

  private setupGrid(dataInitial: Array<DcSubscriptionSlot>) {
    let data: Array<DcDriverSlot> = [];
    dataInitial.forEach(s => {
      if (s.assignedDriver) {
        let dr = new DcDriverSlot(s.assignedDriver);
        try {
          dr.managerTrainerEmail1 = s.assignedDriver.managerTrainerEmailAddresses[0];
        } catch (e) { };
        try {
          dr.managerTrainerEmail2 = s.assignedDriver.managerTrainerEmailAddresses[1];
        } catch (e) { };
        try {
          dr.managerTrainerEmail3 = s.assignedDriver.managerTrainerEmailAddresses[2];
        } catch (e) { };
        try {
          dr.medCardExpirationDate = s.assignedDriver.medicalCard.expirationDate;
        } catch (e) { };
        try {
          dr.licenseExpirationDate = s.assignedDriver.driverLicense.expirationDate;
        } catch (e) { };
        dr.programExpirationDate = s.expirationDate;
        try {
          dr.isExpiringSoon = s.isExpiringSoon;
        } catch (e) { };

        data.push(dr);
      }

    });

    let columns = [
      { name: "Employee ID", prop: "employeeId", title: "Employee ID", visible: true, dataType: "link" },
      { name: "First Name", prop: "firstName", title: "First Name", visible: true , dataType: null },
      { name: "Last Name", prop: "lastName", title: "Last Name", visible: true, dataType: null },
      { name: "Email Address", prop: "emailAddress", title: "Email Address", dataType: null, visible: true },
      { name: "Owner Email", prop: "ownerEmailAddress", title: "Owner Email", dataType: null, visible: true },
      { name: "Manager/Trainer Email", prop: "managerTrainerEmail1", title: "Manager/Trainer Email", dataType: null, visible: true },
      { name: "2nd Manager/Trainer", prop: "managerTrainerEmail2", title: "2nd Manager/Trainer", dataType: null, visible: true },
      { name: "3rd Manager/Trainer", prop: "managerTrainerEmail3", title: "3rd Manager/Trainer", dataType: "date", visible: true },
      { name: "Medical Certificate Exp Date", prop: "medCardExpirationDate", title: "Medical Certificate Exp Date", dataType: "date", visible: true },
      { name: "License Exp Date", prop: "licenseExpirationDate", title: "License Exp Date", dataType: "date", visible: true },
      { name: "Program Exp Date", prop: "programExpirationDate", title: "Program Exp Date", dataType: "date", visible: true },
      { name: "Driver ID", prop: "driverId", title: "Driver ID", dataType: "number", visible: false },
      { name: "isExpiringSoon", prop: "isExpiringSoon", title: "Is Expiring", dataType: "boolean", visible: false }      
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: 'enable'
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s.dataType,
        hidden: !s.visible,
        rowClass: row => {
          let rc = "";
          switch(s.prop) {
            case "isExpiringSoon":
              try {
                  rc = row.isExpiringSoon ? "table-warning" : "";
              } catch (e) { }
              break;
          }
          return rc;
        },
        render: row => {
          let r = row[s.prop];
          switch (s.dataType) {
            case "date":
              try {
                r = this.datePipe.transform(row[s.prop], 'MM/dd/yyyy');
              } catch (e) { }
              break;
          }
          switch (s.prop) {
            case "employeeId":
              r = '<a style="color:#d23138;"><u>' + r + '</u></a>';
              break;
          }

          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        },
        click: row => {
          if (s.prop === "employeeId") {
            return this.openDriver(row["driverId"]);
          }
        }
      });
    });

    this.configObject = {
      settings: settings,
      fields: fields,
      data: data
    }

    this.gtOptions = (data.length > 1 ? { numberOfRows: 150 } : {});
  }

  private openDriver(driverId: string) {
    this.router.navigate(['dc/driverprofile/' + driverId], { queryParams: { returnToPage: 'driverentry' } });
  }

  openModal(data: any): void {
    this.openDriver(data?.row['driverId']);
  }
}

export class DcDriverSlot extends DcDriver {
  managerTrainerEmail1: string;
  managerTrainerEmail2: string;
  managerTrainerEmail3: string;
  medCardExpirationDate?: string;
  licenseExpirationDate?: string;
  programExpirationDate?: string;
  isExpiringSoon: boolean;

  constructor(obj: DcDriver) {
    super(obj.clientCode, obj.groupGuid);
    Object.assign(this, obj)
  }

}
