import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class OnPremService {
  private applicationId: string = '1';


  constructor(private http: HttpClient,
    private authService: AuthService) {
  }


  delete(serviceSubDirectory: string, endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object> {
    const onPremBaseUrl = this.authService.getOnPremApiBaseUrl();
    return this.http.delete(onPremBaseUrl + serviceSubDirectory + endpoint,
      {
        params: options ? options.params : null,
        headers: {
          'x-fr-applicationid': this.applicationId
        }
      });
  }

  get<T>(serviceSubDirectory: string, endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<T> {
    const onPremBaseUrl = this.authService.getOnPremApiBaseUrl();
    return this.http.get<T>(onPremBaseUrl + serviceSubDirectory + endpoint,
      {
        params: options ? options.params : null,
        headers: {
          'x-fr-applicationid': this.applicationId
        }
      });
  }

  getFile(serviceSubDirectory: string, endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<HttpResponse<Blob>> {
    const onPremBaseUrl = this.authService.getOnPremApiBaseUrl();
    return this.http.get(onPremBaseUrl + serviceSubDirectory + endpoint,
      {
        params: options ? options.params : null,
        headers: {
          'x-fr-applicationid': this.applicationId
        },
        responseType: 'blob',
        observe: 'response'
      });
  }

  post(serviceSubDirectory: string, endpoint: string, body: any | null, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object> {
    const onPremBaseUrl = this.authService.getOnPremApiBaseUrl();
    return this.http.post(onPremBaseUrl + serviceSubDirectory + endpoint, body,
      {
        params: options ? options.params : null,
        headers: {
          'Content-Type': 'application/json',
          'x-fr-applicationid': this.applicationId
        }
      });
  }

  put(serviceSubDirectory: string, endpoint: string, body: any | null, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object> {
    const onPremBaseUrl = this.authService.getOnPremApiBaseUrl();
    return this.http.put(onPremBaseUrl + serviceSubDirectory + endpoint, body,
      {
        params: options ? options.params : null,
        headers: {
          'Content-Type': 'application/json',
          'x-fr-applicationid': this.applicationId
        }
      });
  }
}
