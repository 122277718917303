<div class="row">
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <h6 class="text-muted">Attachment Details</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button type="button"
                (click)="downloadFile(documentId, fileName)"
                class="btn btn-primary">
          Download
        </button>
      </div>
    </div>
    <div class="row" style="padding-top:1rem">
      <div class="col-md-4 readOnlyFormLayoutLabel">
        File Name:
      </div>
      <div class="col-md-8 readOnlyFormLayoutBold">
        {{fileName}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 readOnlyFormLayoutLabel">
        Upload Date:
      </div>
      <div class="col-md-8 readOnlyFormLayoutBold">
        {{uploadDate |  date:'M/d/yyyy'}}
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12" *ngIf="!imageBlobType">
        <app-loading-panel></app-loading-panel>
      </div>
      <div class="col-md-12" *ngIf="imageBlobType=='unavailable'">
        Attachment Preview Not Available
      </div>

    </div>
    <ng-container *ngIf="(imageBlobType=='image')||(imageBlobType=='pdf')||(imageBlobType=='closed')">
      <div class="row">
        <div class="col-md-12">
          <h6 class="text-muted">Attachment Preview</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button type="button"
                  *ngIf="imageBlobType == 'closed'"
                  (click)="previewFile(documentId)"
                  class="btn btn-primary">
            Preview
          </button>
          <button type="button"
                  *ngIf="(imageBlobType=='image')||(imageBlobType=='pdf')"
                  (click)="closePreview()"
                  class="btn btn-primary">
            Close Preview
          </button>
        </div>
      </div>
      <div class="row" style="padding-top:1rem">
        <div class="col-md-12">
          <app-image-preview [src]="imageBlobUrl" [type]="imageBlobType"></app-image-preview>
        </div>
      </div>
    </ng-container>
  </div>
</div>
