<div class="checkboxListContainer col-md-12" [formGroup]="servicesSelections">
  <h4 class="card-title text-muted wrap-this">Order / Assign</h4>
  <div
    *ngIf="
      servicesSelections.errors?.requireCheckboxToBeChecked &&
      !servicesSelections.pristine
    "
    class="validation-error"
  >
    At least one process must be selected
  </div>
  <ul>
    <ng-container *ngFor="let choice of choiceList | keyvalue: choiceItemSort">
      <li *ngIf="choice.value.userCanAssignTo !== targetDriverTypes.NONE">
        <div class="row validation-error"
        *ngIf="
          choice.value.dueDateControl?.pristine === false && 
          choice.value.dueDateControl?.invalid === true">
            {{choice.value.controlLabel}} requires a valid due date.
        </div>
        <div class="row">
          <div
            class="col-md-4"
            [ngClass]="{ readOnly: choice.value.isReadOnly }"
          >
            <input
              type="checkbox"
              [formControlName]="choice.key"
              [id]="selectorPrefix + choice.key"
              [value]="false"
              [readOnly]="choice.value.isReadOnly"
            />
            <label [attr.for]="selectorPrefix + choice.key">{{
              choice.value.controlLabel
            }}</label>
          </div>
          <ng-container
            *ngIf="choice.value.requiresDueDate && choice.value.control.value"
          >
            <div class="col-md-6">
              <mat-form-field appearance="fill">
                <mat-label class="lbl-start-date">Date Due</mat-label>
                <input class="input-date" matInput disabled [matDatepicker]="dueDatePicker" [min]="minDate" [formControlName]="choice.value.dueDateControlKey">
                <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #dueDatePicker disabled="false">
                  <mat-datepicker-actions>
                    <button mat-button (click)="dueDatePicker.select(actualDate); dueDatePicker.close()">
                      <mat-icon>history</mat-icon>
                      Today
                    </button>
                    <button mat-button (click)="dueDatePicker.select(null); dueDatePicker.close()">
                      <mat-icon>highlight_off</mat-icon>
                      Clear
                    </button>
                  </mat-datepicker-actions> 
                </mat-datepicker>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
