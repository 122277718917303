import { Inject, Injectable } from '@angular/core';
import { ReportRequest } from '../data-grid/data-grid.service';
import { Subject, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { QuickSearchRequest } from '../quick-search/quick-search.service';
import { AdvancedSearchRequest } from '../../claims/advanced-search-claims/advanced-search-claims.service';
import { CustomReportGridRequest } from '../../claims/custom-report-grid/custom-report-grid.service';


@Injectable()
export class DataGridEmailService {
  gridRequest: ReportRequest;
  private modalPopup = new Subject();
  modalPopup$ = this.modalPopup.asObservable();

  baseUrl: string;
  _http: HttpClient;


  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, gridRequest: ReportRequest) {
    this._http = http;
    this.baseUrl = baseUrl;
  }


  SendEmail(request: ExportToEmailRequest, lineOfBusiness: number): any {
    if (request.GridType == 1) {
      return lastValueFrom(this._http.post(`${this.baseUrl}api/Datagrid/Email/${lineOfBusiness}`,
        JSON.stringify(request),
        {
          headers: { 'Content-Type': 'application/json' }
        }
      ))
        .then(result => {
          return false;
        }).catch();
    }
    else if (request.GridType == 5)
    {
      return lastValueFrom(this._http.post(this.baseUrl + 'api/Safety/DqGridEmail',
        JSON.stringify(request),
        {
          headers: { 'Content-Type': 'application/json' }
        }
      ))
        .then(result => {
          return false;
        }).catch();
    }
    else if (request.GridType == 6) {
      return lastValueFrom(this._http.post(this.baseUrl + 'api/Safety/COIGridEmail',
        JSON.stringify(request),
        {
          headers: { 'Content-Type': 'application/json' }
        }
      ))
        .then(result => {
          return false;
        }).catch();
    }
    else if (request.GridType == 7) {
      return lastValueFrom(this._http.post(this.baseUrl + 'api/DeliveryContractors/DriversOnLeaveEmail',
        JSON.stringify(request),
        {
          headers: { 'Content-Type': 'application/json' }
        }
      ))
        .then(result => {
          return false;
        }).catch();
    }
    else if (request.GridType == 8) {
      return lastValueFrom(this._http.post(this.baseUrl + 'api/DeliveryContractors/TimeClockReportEmail',
        JSON.stringify(request),
        {
          headers: { 'Content-Type': 'application/json' }
        }
      ))
        .then(result => {
          return false;
        }).catch();
    }
    else if (request.GridType == 9) {
      return lastValueFrom(this._http.post(this.baseUrl + 'api/DeliveryContractors/TimeClockReportDrilldownEmail',
        JSON.stringify(request),
        {
          headers: { 'Content-Type': 'application/json' }
        }
      ))
        .then(result => {
          return false;
        }).catch();
    }
    else if (request.GridType == 10) {
      return lastValueFrom(this._http.post(this.baseUrl + 'api/Safety/AbstractReviewGridEmail',
        JSON.stringify(request),
        {
          headers: { 'Content-Type': 'application/json' }
        }
      ))
        .then(result => {
          return false;
        }).catch();
    }
    else {
      return lastValueFrom(this._http.post(`${this.baseUrl}api/CustomReport/Email/${lineOfBusiness}`,
        JSON.stringify(request),
        {
          headers: { 'Content-Type': 'application/json' }
        }
      ))
        .then(result => {
          return false;
        }).catch();
    }
  }
  SendSearchEmail(request: ExportToEmailRequest, lineOfBusiness: number): any {
    if (request.GridType == 2) {
      return lastValueFrom(this._http.post(`${this.baseUrl}api/QuickSearch/Email/${lineOfBusiness}`,
        JSON.stringify(request),
        {
          headers: { 'Content-Type': 'application/json' }
        }
      ))
        .then(result => {
          return false;
        }).catch();
    }
    else {
      return lastValueFrom(this._http.post(this.baseUrl + 'api/ClaimsSearch/Email',
        JSON.stringify(request),
        {
          headers: { 'Content-Type': 'application/json' }
        }
      ))
        .then(result => {
          return false;
        }).catch();
    }
  }

  GetUserDetails() {
    return this._http.get(this.baseUrl + 'api/UserSettings/Get');
  }

  OpenEmailPopup(grid: any) {
    this.modalPopup.next(grid);
  }

  OpenSearchEmailPopup(searchRequest: any) {
    this.modalPopup.next(searchRequest);
  }
}


export class ExportToEmailRequest {
  DataGridRequest: ReportRequest;
  CustomReportRequest: CustomReportGridRequest;
  SearchRequest: QuickSearchRequest;
  AdvancedSearchRequest: AdvancedSearchRequest;
  DqGridRequest: DQGridRequest;
  emailSubject: string;
  Recipient: string;
  cc: boolean;
  GridType: number;
}
export class DQGridRequest {
  SelectedClient: string;
  SortDisplayField: string;
  SortField: string;
  SortDir: string;
}
export class COIValidationGridRequest {
  SelectedClient: string;
  SortDisplayField: string;
  SortField: string;
  SortDir: string;
}
export class DcDriversOnLeaveGridRequest {
  SelectedClient: string;
  SortDisplayField: string;
  SortField: string;
  SortDir: string;
  SelectedCompany: string;
}
export class DcDriverTimeClockGridRequest {
  DataGridRequest: ReportRequest;
  SelectedClient: string;
  SortDisplayField: string;
  SortField: string;
  SortDir: string;
}
export class DcDriverTimeClockDrilldownGridRequest {
    DataGridRequest: ReportRequest;
    SelectedClient: string;
    SortDisplayField: string;
    SortField: string;
    SortDir: string;
}
export class AbstractReviewGridRequest {
  SortDisplayField: string;
  SortField: string;
  SortDir: string;
}
