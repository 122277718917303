import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { UserLookupRequest, UserLookupModel } from './user-admin-interfaces';


@Injectable({
  providedIn: 'root'
})
export class UserAdminServiceService {

  constructor(private http: HttpClient) { }

  getOnAdminApiBaseUrl(): string {
    return environment.AuthConfiguration.userAdminApi; 
  }

  search(lookupRequest: UserLookupRequest) : Observable<UserLookupModel[]> {
    const url = this.getOnAdminApiBaseUrl() + 'user/lookup';
    const jsonString = JSON.stringify(lookupRequest);
    return this.http.post<UserLookupModel[]>(url,
      jsonString,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  }
 



}


