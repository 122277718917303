//Angular
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Router } from "@angular/router";
//Third Party
import { Subscription } from "rxjs";
//App
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { QuickSearchService, GridData, QuickSearchRequest } from '../../components/quick-search/quick-search.service';
import { ClientSelectionService, ClientSelectionsObject, ClientChoicesObject } from '../../components/client-selection-service/client-selection-service.component'
import { LoadingSpinnerService } from "../../services/loading-spinner-service/loading-spinner.service";

@Component({
  selector: 'quick-search-menu',
  templateUrl: './quick-search-menu.component.html',
  styleUrls: ['./quick-search-menu.component.scss']
  //encapsulation: ViewEncapsulation.None
})
export class QuickSearchMenuComponent implements OnInit, OnDestroy {
  lineOfBusinessId: number;
  quickSearchChoices: Array<any>;
  clientSelectedArray: Array<string>;
  clientSelectionSubscription: Subscription;
  clientChoicesSubscription: Subscription;
  lineOfBusinessSubscription: Subscription;
  hasMultipleClients: boolean = false;

  @Input() searchText: string;
  @Input() selectedColumnFilter: string = "0";
  @Input() searchAcrossClients: boolean = true;

  constructor(
    private lineOfBusinessService: LineOfBusinessService,
    private searchRequest: QuickSearchRequest,
    private clientSelectionService: ClientSelectionService,
    private quickSearchService: QuickSearchService,
    private router: Router,
    private loadingSpinnerService: LoadingSpinnerService
  ) { }

  ngOnInit(): void {
    this.lineOfBusinessId = this.lineOfBusinessService.getLineOfBusinessValue();
    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusinessId);
    this.getFilterColumns();

    this.lineOfBusinessSubscription = this.lineOfBusinessService.lineOfBusinessSelected$.subscribe(data => {
      this.lineOfBusinessId = data as number;
      if (this.clientSelectedArray) {
        this.getFilterColumns();
      }
    });

    this.clientSelectionSubscription = this.clientSelectionService.clientSelectedInfo$.subscribe(data => {
      let temp = data as ClientSelectionsObject;
      if ((this.lineOfBusinessId > 0) && temp[this.lineOfBusinessId]) {
        let arrClientOld = this.clientSelectedArray;
        let arrClientNew = temp[this.lineOfBusinessId].clientSelectedArray;
        if (!this.arrayCompare(arrClientNew, arrClientOld)) {
          this.clientSelectedArray = arrClientNew;
          this.getFilterColumns();
        }

      }
    });
    if (this.lineOfBusinessId == 7) {
      this.hasMultipleClients = false;
    }
    else {
      this.clientChoicesSubscription = this.clientSelectionService.clientChoicesInfo$.subscribe(data => {
        let temp = data as ClientChoicesObject;
        if ((this.lineOfBusinessId > 0) && temp[this.lineOfBusinessId]) {
          this.hasMultipleClients = (temp[this.lineOfBusinessId].length > 1);
        } else {
          this.hasMultipleClients = false;
        }
      });
    }
  }

  ngOnDestroy() {
    this.clientSelectionSubscription.unsubscribe();
    this.lineOfBusinessSubscription.unsubscribe();
    if (this.clientChoicesSubscription)
      this.clientChoicesSubscription.unsubscribe();
  }

  onChange(e: any) {
    //console.log(e);
    if (e == "") {
      this.selectedColumnFilter = "0";
    }
  }

  search(): void {
    try {
      this.searchRequest.reset();
      this.searchRequest.SearchText = this.searchText;
      this.searchRequest.SelectedClients = this.clientSelectedArray;
      this.searchRequest.LineOfBusinessId = this.lineOfBusinessId;
      if (this.selectedColumnFilter)
        this.searchRequest.SearchColumnFilterId = parseInt(this.selectedColumnFilter);
      else
        this.searchRequest.SearchColumnFilterId = 0

      this.searchRequest.SearchAcrossClients = this.searchAcrossClients as boolean;
    }
    catch (ex) {
      console.log(ex);
    }

    try {
      this.loadingSpinnerService.show();
    }
    catch (ex) {
      console.log(ex);
    }
    let randomVal = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);

    sessionStorage.setItem('quicksearch_' + randomVal, JSON.stringify(this.searchRequest));
    this.router.navigateByUrl("/quickSearch/" + randomVal);
  }

  private getFilterColumns(): void {
    if (!this.lineOfBusinessId)
      this.lineOfBusinessId = this.lineOfBusinessService.getLineOfBusinessValue();

    if (!this.clientSelectedArray) {
      this.clientSelectionService.getClientSelectedArrayValue(this.lineOfBusinessId);
    }

    if (this.clientSelectedArray && this.clientSelectedArray.length > 0) {
      this.quickSearchService.getFilterColumns(this.clientSelectedArray.join(","), this.lineOfBusinessId).then(x => {
        if (x) {
          this.quickSearchChoices = x;
        }
      });
    }
  }

  private arrayCompare(a1, a2) {
    return (a1.length == a2.length && a1.every((v, i) => v === a2[i]));
  }
}
