<div >
  <ng-container *ngIf="claim">

    <div class="row" style="padding-top:3rem;padding-bottom:30px">
      <div class="col-md-9">
        <div *ngIf="showValidationErrorMessage" class="label-validation-error">All fields are required!</div>
      </div>
      <div class="col-md-3" style="text-align:right">
        <button type="button"
                (click)="saveClaim()"
                class="btn btn-primary">
          Submit
        </button>

      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="md-form">
          <input id="insured" type="text" [(ngModel)]="claim.insured" class="form-control" placeholder="Customer/Policy Holder Name"  />
          <label for="insured" [ngClass]="{ 'label-validation-error': objValidationFail['insured'], 'active':true }"
                 >Customer/Policy Holder Name</label>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="select-container">
          <mat-form-field appearance="fill" class="select-container">
            <mat-label [ngClass]="{ 'label-validation-error': objValidationFail['lineOfCoverageId'], 'active':true }">Line of Coverage</mat-label>
            <mat-select #selectLinesOfCoverage="matSelect" [(value)]="selectedLineOfCoverage">
              <input matInput class="select-search-input" placeholder="Search Lines of Coverage..." (keyup)="filterLinesOfCoverageOptions($event.target.value)">
              <mat-option *ngFor="let option of filteredLinesOfCoverage" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="md-form">
          <input id="subAccountName" type="text" [(ngModel)]="claim.subAccountName" class="form-control" placeholder="Customer Sub-Account Name" />
          <label for="subAccountName" [ngClass]="{ 'label-validation-error': objValidationFail['subAccountName'], 'active':true }"
                 >Customer Sub-Account Name</label>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="select-container">
          <mat-form-field  appearance="fill" class="select-container">
            <mat-label [ngClass]="{ 'label-validation-error': objValidationFail['underwritingCompanyId'], 'active':true }">Underwriting Company</mat-label>
            <mat-select #selectUnderwriting="matSelect" [(value)]="claim.underwritingCompanyId">
              <input matInput class="select-search-input" placeholder="Search Underwriting Companies..." (keyup)="filterUnderwritingCompanyOptions($event.target.value)">
              <mat-option *ngFor="let option of filteredunderwritingCompanies" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="md-form">
          <input id="policyNumber" type="text" [(ngModel)]="claim.policyNumber" class="form-control" placeholder="Policy Number"  />
          <label for="policyNumber" [ngClass]="{ 'label-validation-error': objValidationFail['policyNumber'], 'active':true }">Policy Number</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="md-form">
          <input id="clientClaimId" type="text" [(ngModel)]="claim.clientClaimId" class="form-control" placeholder="MSIG Claim #"  />
          <label for="clientClaimId" [ngClass]="{ 'label-validation-error': objValidationFail['clientClaimId'], 'active':true }">MSIG Claim #</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="md-form">
          <input id="rentalAllowance" type="text" [(ngModel)]="claim.rentalAllowance" class="form-control" placeholder="Rental Allowance (per day/limit)" />
          <label for="rentalAllowance" [ngClass]="{ 'label-validation-error': objValidationFail['rentalAllowance'], 'active':true }">Rental Allowance (per day/limit)</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="md-form">
          <input id="deductible" type="text" [(ngModel)]="claim.deductible" class="form-control" placeholder="Deductible"  />
          <label for="deductible" [ngClass]="{ 'label-validation-error': objValidationFail['deductible'], 'active':true }">Deductible</label>
        </div>
      </div>
    </div>
    <div class="row">
      
    </div>
    <div class="row">
      
    </div>

  </ng-container>
</div>
