
  <div>
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="alertModalClose()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="labelAlertModal">{{title}}</h4>
      </div>
      <div class="modal-body" *ngIf="message&&(message.length>0)">
        {{message}}
      </div>
      <div class="modal-body" [innerHTML]="htmlMessage" *ngIf="htmlMessage&&(htmlMessage.length>0)">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" aria-label="OK" (click)="alertModalClose()">OK</button>
      </div>
    </div>
  </div>
