import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';

@Injectable()
export class ClaimTabService {
  baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  getTabs(ClaimId: number) {
    let params = new HttpParams();
    params = params.append('ClaimId', ClaimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetTabs', {
      params: params
    });
  }
}
