<div class="backdrop" [ngStyle]="{'display':display}"></div>

<div class="modal bringToFront" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="onCloseHandled()"><span
            aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">{{grid?.title}}</h4>
      </div>
      <div class="modal-body">
        <span>Today's date: {{ today }}</span>

        <div *ngFor="let filter of gridFilters">


          <div *ngIf="filter.filterType=='Date'">
            <div *ngIf="filter.rangeType=='Range'">
              <p>
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label class="lbl-start-date">From Date</mat-label>
                  <input class="input-date" disabled matInput [matDatepicker]="startDatePicker" [(ngModel)]="startDate">
                  <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #startDatePicker disabled="false">
                    <mat-datepicker-actions>
                      <button mat-button (click)="startDatePicker.select(actualDate); startDatePicker.close()">
                        <mat-icon>history</mat-icon>
                        Today
                      </button>
                      <button mat-button (click)="startDatePicker.select(null); startDatePicker.close()">
                        <mat-icon>highlight_off</mat-icon>
                        Clear
                      </button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                </mat-form-field>

              </p>
              <p>
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Through Date</mat-label>
                  <input class="input-date" matInput disabled [matDatepicker]="endDatePicker" [(ngModel)]="endDate">
                  <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #endDatePicker disabled="false">
                    <mat-datepicker-actions>
                      <button mat-button (click)="endDatePicker.select(actualDate); endDatePicker.close()">
                        <mat-icon>history</mat-icon>
                        Today
                      </button>
                      <button mat-button (click)="endDatePicker.select(null); endDatePicker.close()">
                        <mat-icon>highlight_off</mat-icon>
                        Clear
                      </button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                </mat-form-field>

              </p>
            </div>
            <div *ngIf="filter.rangeType=='To'">

              <p>
                <mat-form-field appearance="fill">
                  <mat-label>Through Date</mat-label>
                  <input class="input-date" matInput disabled [matDatepicker]="endDatePicker" [(ngModel)]="endDate">
                  <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #endDatePicker disabled="false">
                    <mat-datepicker-actions>
                      <button mat-button (click)="endDatePicker.select(actualDate); endDatePicker.close()">
                        <mat-icon>history</mat-icon>
                        Today
                      </button>
                      <button mat-button (click)="endDatePicker.select(null); endDatePicker.close()">
                        <mat-icon>highlight_off</mat-icon>
                        Clear
                      </button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                </mat-form-field>

              </p>
            </div>

            <div *ngIf="filter.rangeType=='From'">
              <p>
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label class="lbl-start-date">From Date</mat-label>
                  <input class="input-date" disabled matInput [matDatepicker]="startDatePicker" [(ngModel)]="startDate">
                  <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #startDatePicker disabled="false">
                    <mat-datepicker-actions>
                      <button mat-button (click)="startDatePicker.select(actualDate); startDatePicker.close()">
                        <mat-icon>history</mat-icon>
                        Today
                      </button>
                      <button mat-button (click)="startDatePicker.select(null); startDatePicker.close()">
                        <mat-icon>highlight_off</mat-icon>
                        Clear
                      </button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                </mat-form-field>
              </p>

            </div>
          </div>

          <p *ngIf="filter.filterType=='Single-Select List'">
            <label class="font-weight-bold">{{filter.displayName}}</label>

            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Specify the filter type</mat-label>
              <mat-select [(value)]="filter.value" (selectionChange)="OnChangeSelected($event, filter)" required>
                <mat-option *ngFor="let option of filter.filterOptions" [value]="option.value">
                  {{option.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </p>
          <p *ngIf="filter.filterType=='Multi-Select List'">
            <label class="font-weight-bold">{{filter.displayName}}</label><br />
            <span>Use CTRL (or Command) + click, to select more than one item</span>
            <select style="display:inline!important" multiple="multiple" name="{{ filter.dataGridFilterId }}"
              [(ngModel)]="filter.value" required>
              <option *ngFor="let option of filter.filterOptions" value="{{option.value}}">{{option.label}}</option>
            </select>

          </p>

          <p *ngIf="filter.filterType=='Dependent Select List' && !filter.disabled">
            <label>{{filter.displayName}}</label>

            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Specify {{filter.displayName}}</mat-label>
              <mat-select [(value)]="filter.value" [disabled]="filter.disabled" required>
                <mat-option *ngFor="let option of filter.filterOptions" [value]="option.value">
                  {{option.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>


          </p>
        </div>
      </div>

      <div class="modal-footer">

        <div class="label-validation-error" *ngIf="errorMsg?.length > 0">
          {{errorMsg}}
        </div>
        <button type="button" class="btn btn-default" (click)="onGenerateReport()">Generate Report</button>
      </div>
      <!--</form>-->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal !-->