import { MVRReport } from './../../components/classes-and-interfaces/classes-and-interfaces.component';
import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';

@Injectable()
export class DHPMVRReportService {
  baseUrl: string;
  //private mvrReport: MVRReport;
  private mvrReport = new BehaviorSubject<MVRReport>(null);
  sharedMvrReport = this.mvrReport.asObservable();
   
  
  constructor(private http: HttpClient, 
    private onPremService: OnPremDriverService,
    private errorService: ErrorModalService,
    @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  // get a specific driver mvr report
  getMvrInfo(mvrId: string): void{
    this.onPremService.get('mvr/' + mvrId)
      .subscribe(data => {
        this.mvrReport.next(data as MVRReport);
      },
        (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
        });
  }
}
