
import { Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DhpHierarchy, MoveDriver, SearchLineOfBusiness, DriverProfile, ClientSelectionObject } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

@Injectable()
export class SafetyMoveDriverService {
  baseUrl: string;
  errorMessage: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.http = http;
  }
  getDriverProfile(driverId:number | string) : Observable<DriverProfile>{
    return this.http.get<DriverProfile>(`${this.baseUrl}api/Safety/GetDriverProfile?DriverId=${driverId}`);
  }
  getDhpHierarchy(dhpClient: string): Observable<DhpHierarchy[]> {
    return this.http.get<DhpHierarchy[]>(`${this.baseUrl}api/Safety/GetDhpHierarchy?dhpClient=${dhpClient}`);
  }

  moveDriver(moveDriver: MoveDriver):Observable<MoveDriver>{
    return this.http.post<MoveDriver>(`${this.baseUrl}api/Safety/MoveDriver`, moveDriver);
  }

  getRelatedClients(clientCode: string):Observable<ClientSelectionObject[]>{
    return this.http.get<ClientSelectionObject[]>(`${this.baseUrl}api/Safety/GetRelatedClients?clientCode=${clientCode}`);
  }

  quickSearchDriver(search: SearchLineOfBusiness):Observable<any>{
    const requestBody = {
      clientCode : search.clientCode,
      lineOfBusinessId: search.lineOfBusinessId,
      searchText : search.searchText,
      searchAcrossClients: false,
      searchColumnFilterId: search.searchColumnId,
      selectedClients : [search.clientCode]
    };
    return this.http.post<any>(`${this.baseUrl}api/QuickSearch/search`, requestBody);
  }


  


}

