<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" style="padding-bottom:20px">
  <div class="col-md-9">
    <h3>Add New Employee Driver</h3>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <mat-tab-group class="tabs-container">
      <mat-tab label="Driver Placement"> 
        <div class="row">
          <div class="col-md-12">
            <app-safety-new-employee-driver-selector-folder [arrCHInfo]="arrCH"
                                      [clientSelectedArray]="clientSelectedArray">
            </app-safety-new-employee-driver-selector-folder>
          </div>
         </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
