<div class="row">
  <div class="col-md-12">
    <h3 class="text-muted">State Required Codes</h3>

    <div class="card card-body mb-3">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="md-form">
            <input id="caRequestorCode" type="text" class="form-control" [(ngModel)]="stateCodeForm.CaCode" />
            <label for="caRequestorCode" [class.active]="stateCodeForm.CaCode">California State Requestor Code</label>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="md-form">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label class="lbl-start-date">California Code Expiration</mat-label>
              <input class="input-date" matInput disabled [matDatepicker]="caExpDatePicker" [(ngModel)]="stateCodeForm.CaExpirationDate">
              <mat-datepicker-toggle matSuffix [for]="caExpDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #caExpDatePicker disabled="false">
                <mat-datepicker-actions>
                  <button mat-button (click)="caExpDatePicker.select(actualDate); caExpDatePicker.close()">
                    <mat-icon>history</mat-icon>
                    Today
                  </button>
                  <button mat-button (click)="caExpDatePicker.select(null); caExpDatePicker.close()">
                    <mat-icon>highlight_off</mat-icon>
                    Clear
                  </button>
                </mat-datepicker-actions> 
              </mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <p>California Code Expiration is required to order California MVRs.</p>
          <p>California State Requestor Code is for your reference and can be left blank.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          &nbsp;
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="md-form">
            <input id="utOrganizationId" type="text" class="form-control" [(ngModel)]="stateCodeForm.UtCode" />
            <label for="utOrganizationId" [class.active]="stateCodeForm.UtCode">Utah Organization ID</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <p>Utah Organization ID is required to order Utah MVRs.</p>
        </div>
      </div>


    <div class="row">
      <div class="col-md-6 col-sm-12">
        &nbsp;
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="md-form">
          <input id="paCode" type="text" class="form-control" [(ngModel)]="stateCodeForm.PaCode" />
          <label for="paCode" [class.active]="stateCodeForm.PaCode">Pennsylvania Code</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <p>Pennsylvania Code is required to order Pennsylvania MVRs.</p>
      </div>
    </div>
  </div>
    <div class="row">
      <div class="col-md-12">
        <button class="btn btn-default" (click)="save()">Save</button>
      </div>
    </div>
  </div>
</div>
