<div *ngIf="!showSingleSelect">
  <ul class="clientSelectorContainer">
    <li class="waves-effect clientSelectorRow" *ngFor="let e of clientChoicesForLOB;let i = index">
      <div>
        <input type="checkbox"
               class="input-dark"
               name="checkb"
               id="checkb-{{e.clientId}}"
               value="{{e.clientShortName}}"
               [disabled]="(i == 0)&&firstClientSelectedForLOB"
               [(ngModel)]="e.checked"
               (change)="processClientSelection(lineOfBusinessSelected, true, true)" />
        <label class="label-base" for="checkb-{{e.clientId}}">{{e.clientName}}</label>
      </div>
    </li>
  </ul>
</div>
<div *ngIf="showSingleSelect">
  <ul class="clientSelectorContainer" *ngIf="clientChoicesForLOB">
    <li class="waves-effect clientSelectorRow" *ngFor="let e of clientChoicesForLOB">
      <div>
        <input type="radio"
               class="input-dark"
               name="checkb"
               id="checkb-{{e.clientId}}"
               value="{{e.clientShortName}}"
               [ngModel]="clientShortNamesChosenForLOB[0]"
               (change)="processClientSelectionSingleSelect(e.clientId, lineOfBusinessSelected, true, true)" />
        <label class="label-base" for="checkb-{{e.clientId}}">{{e.clientName}}</label>
      </div>
    </li>
  </ul>
</div>
