<div class="card card-body mt-3">
  <h4 class="card-title text-muted">Document Upload</h4>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-body mt-3">
            <h4 class="card-title text-muted">Claim Info</h4>
            <div class="row">
              <div class="col-md-2">
                Claim:
              </div>
              <div class="col-md-4">
                {{claimId}}
              </div>
              <div class="col-md-2">
                Driver:
              </div>
              <div class="col-md-4">
                {{accidentClaimService.getDriverName()}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-body mt-3">
            <h4 class="card-title text-muted">Document Upload</h4>
            <div class="row">
              <div class="col-md-12">
                <div class="md-form">
                  <input id="accidentClaimSupportsUploadDescription" type="text" [(ngModel)]="fileDescription" class="form-control" />
                  <label for="accidentClaimSupportsUploadDescription" class="active">Description</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9">
                <div class="file-field">
                  <div class="btn btn-default btn-sm waves-light">
                    <span>Choose file</span>
                    <input type="file" (change)="onFileSelected($event)" #accidentClaimSupportsFileInput>
                  </div>
                  <div class="file-path-wrapper">
                    <input class="file-path" type="text" placeholder="Upload your file" [value]="showFiles()">
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                File cannot be larger than 1TB.
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" style="text-align:center">
                <span>
                  <button type="button"
                          (click)="uploadDocs()"
                          class="btn btn-default">
                    Finish Uploading Documentation
                  </button>
                  <button type="button"
                          (click)="closeUploadPanel()"
                          class="btn btn-default">
                    Exit
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
