import { Component, OnInit,OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from "rxjs";
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";

@Component({
  selector: 'app-accident-claim-transactions',
  templateUrl: './accident-claim-transactions.component.html',
  styleUrls: ['./accident-claim-transactions.component.css']
})
export class AccidentClaimTransactionsComponent implements OnInit,OnDestroy {
  _claimId: number;
 _routeSub: Subscription;
  gtOptions: any = {};
  public tableConfig: any;
  baseUrl: string;

  displayTransactionsModal: string;

  constructor(@Inject('BASE_URL') baseUrl: string,
    private _route: ActivatedRoute,
    private accidentClaimService: AccidentClaimService,
    private fileDownloadService: FileDownloadService,
    private errorService: ErrorModalService)
  {
    this.baseUrl = baseUrl;
  }

  private setTableConfig(arrTrans: Array<Transaction>) {
    var columns = [
      { name: "Recipient", prop: "emailRecpient", title: "Recipient", dataType: null },
      { name: "Sent By", prop: "sentBy", title: "Sent By", dataType: null },
      { name: "Sent Time", prop: "sentTime", title: "Sent Time", dataType: null },
      { name: "Viewed", prop: "viewed", title: "Viewed", dataType: null }
    ];

    var settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: ''
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop
      });
    });

    this.tableConfig = {
      settings: settings,
      fields: fields,
      data: arrTrans,
      noRecordsLabel: 'No Transactions information'
    }

    this.gtOptions = (arrTrans.length > 1 ? { numberOfRows: arrTrans.length } : {});
  }

  exportToPdf(): void {
    //window.open('/api/Document/GetLossNoticePdf?ClaimId=' + this._claimId);

    this.fileDownloadService
      .saveFile(this.baseUrl + 'api/Claim/GetLossNoticePdf?ClaimId=' + this._claimId);
    //this.fileDownloadService
    //  .saveFile('/api/Document/GetLossNoticePdf?ClaimId=' + this._claimId);
  }
  viewTransactions(): void {
    this.displayTransactionsModal = "block";
    //this.dataGridId = DataGridId;

  }
  closeTransactions(): void {
    this.displayTransactionsModal = "none"
  }
  printPage(): void {
    window.print();
  }

  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];
      this.accidentClaimService.getLossNoticeTransactions(this._claimId).subscribe({
        next: (data) => {
          this.setTableConfig(data as Array<Transaction>);
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
        }
      })
    });
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
  }
}

export class Transaction {
  detailViewId: number;
  displayName: string;
  detailViewTab: string;
}
