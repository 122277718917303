//Angular
import { Component, Input, Output, ViewChild, EventEmitter, Inject } from '@angular/core';
//Third Party
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//App
import { DcDriverProfileService } from '../dc-driver-profile/dc-driver-profile-service.component';
import { DcModalWindowService } from '../dc-modal-window/dc-modal-window-service.component';
import { DriverHistoryProfileModal } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

@Component({
  selector: 'dc-modal-window',
  templateUrl: './dc-modal-window.component.html',
  styleUrls: ['./dc-modal-window.component.scss'],
})
export class DcModalWindowComponent {
  driverProfileSubscription: Subscription;
  driverProfileSubscriptionClose: Subscription;
  dcModalSubscription: Subscription;
  dcModalSubscriptionClose: Subscription;
  title: string;
  htmlBody: string;
  modalWidth: string;
  modalHeight: string;
  modalInput: any;

  // constructor
  constructor(private dcProfileService: DcDriverProfileService, 
    private dcModalService: DcModalWindowService,
    @Inject(MAT_DIALOG_DATA) private data: {modalInfo: DriverHistoryProfileModal},
    private dialogRef: MatDialogRef<DcModalWindowComponent>) 
    {
        this.title = data.modalInfo.title;
        this.htmlBody = data.modalInfo.message;
        this.modalWidth = data.modalInfo.modalWidth;
        this.modalHeight = data.modalInfo.modalHeight;
        this.modalInput = data.modalInfo.modalInput;
    
    // closes the modal using a signal passed to it from the DC Driver Profile
    this.driverProfileSubscriptionClose = dcProfileService.alertDCModalClose$.subscribe(
      a => {
        this.closeModal();
      }
    );

    // closes the modal using a signal passed to it from the DC Modal Window
    this.dcModalSubscriptionClose = dcModalService.alertDcModalClose$.subscribe(
      a => {
        this.closeModal();
      }
    );
  }

  // shows the modal
  openModal() {
    // set the modal height dynamically
    //document.getElementById('modalBody').style.cssText = 'height:' + this.modalHeight + ';';

    // set the modal width dynamically
    if (this.modalWidth) {
      document.getElementById('modalDc').className = 'modal-dialog modal-' + this.modalWidth;
    } else {
      document.getElementById('modalDc').className = 'modal-dialog';
    }
  }

  // hides the modal
  closeModal(): void {
    this.dialogRef.close();
  }
}
