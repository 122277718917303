<div style="z-index:1049">
  <div>
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title" id="labelConfirmModal">{{title}}</h4>
      </div>

      <leave-of-absence *ngIf="htmlBody=='leaveOfAbsence'" [modalHeight]="modalHeight" [modalInput]="modalInput"></leave-of-absence>
      <cov-signoff-modal *ngIf="htmlBody=='covSignoff'" [modalHeight]="modalHeight" [modalInput]="modalInput"></cov-signoff-modal>
      <cdl-verification-modal *ngIf="htmlBody=='cdlVerification'" [modalHeight]="modalHeight" [modalInput]="modalInput"></cdl-verification-modal>
    </div>
</div>
