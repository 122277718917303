
<div>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">×</span>
      </button>
      <h4 class="modal-title w-100" id="labelDqRejectModal">Reject {{dqType}}</h4>
    </div>
    <div id="modalBody" class="modal-body">
      <div class="row"style="padding-top:2rem">
        <div class="col-md-12">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Reject Reason</mat-label>
            <mat-select [(value)]="rejectionReasonIdString" (selectionChange)="onReasonSelect(rejectionReasonIdString)">
              <mat-option *ngFor="let option of rejectOptions"  [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="isReasonRequired" style="padding-top:2rem">
        <div class="col-md-12">
          <div class="md-form">
            <input id="rejectReasonOther"
                   type="text"
                   [(ngModel)]="otherRejectionReason"
                   class="form-control"
                   [placeholder]="'Reason if '+selectedReasonLabel" />
            <label for="rejectReasonOther" class="active">Reason if {{selectedReasonLabel}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary"
              [disabled]="(!rejectionReasonIdString)||(rejectionReasonIdString.length <= 0)"
              (click)="submit()">
        Submit Rejection
      </button>
      <button class="btn btn-primary"
              (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
