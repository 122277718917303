//Angular
import { Component, Input, Output, Inject, ViewChildren, QueryList, Renderer2, ElementRef, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
//Third Party
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
//App
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { CorporateStructure } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-safety-new-employee-driver-selector-folder',
  templateUrl: './safety-new-employee-driver-selector-folder.component.html',
  styleUrls: ['./safety-new-employee-driver-selector-folder.component.scss']
})
export class SafetyNewEmployeeDriverSelectorFolderComponent implements OnInit, OnChanges {
  @Input() arrCHInfo: Array<CorporateStructure>;
  @Input() clientSelectedArray: Array<string>;
  //@ViewChildren('safetyFilterCheckbox') safetyFilterCheckboxes: QueryList<ElementRef>;
  baseUrl: string;
  collapsed: boolean = true;
  placement: string = "";
  dataSource = new MatTreeNestedDataSource<CorporateStructure>();
  treeControl = new NestedTreeControl<CorporateStructure>(node => node.childElements);
  constructor(private router: Router,
    private clientSelectionService: ClientSelectionService,
    private renderer: Renderer2,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private onPremService: OnPremDriverService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void {
    this.dataSource.data = this.arrCHInfo;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reloadTree();
  }

  hasChild(index: number, node: CorporateStructure): boolean {
    return node.childElements?.length > 0 || node.hasChildren && !node.isLowestTier;
  }

  elementClick(callingElement: CorporateStructure, e: any) {
    if (callingElement.hasChildren) {
      // expand next level
      this.getCHSubInfo(callingElement, e);
    } else {
      this.openNewDriverForm(callingElement);
    }
  }

  private getCHSubInfo(callingElement: CorporateStructure, e: any) {
    let parentElementId: string = callingElement.elementGuid;
    let hasChildElements =
      callingElement.hasChildren && !callingElement.childElements;

    if (hasChildElements) {
      this.loadingSpinnerService.show();
      if (callingElement.isLowestTier) {
        // do not expand, open new employee driver form
        this.openNewDriverForm(callingElement);
      } else {
        this.onPremService.get('hierarchy/structure/' + this.clientSelectedArray.join(',') + '/true/' + parentElementId.toString())
          .subscribe({
            next: (data) => {
              let arrInfo = data as Array<CorporateStructure>;
              // remove driver elements
              let arrInfoFiltered = arrInfo.filter(c => !c.isDriver);
              // assign to childElements of callingElement
              callingElement.childElements = arrInfoFiltered;
              this.reloadTree();
              this.loadingSpinnerService.hide();
              /*
              // pass reference to parent to each child
              callingElement.childElements.forEach(c => c.parentElement = callingElement);

              if (arrInfoFiltered.length <= 0) {
                callingElement.hasChildren = false;
                this.openNewDriverForm(callingElement);
              } else {
                // recalc body height when expanding/collpasing an item
              }
              */
            },
            error: (err: HttpErrorResponse) => {
              //this.toastrService.error(err.error.toString());
              this.errorService.setErrorObject(err.error);
              this.loadingSpinnerService.hide();
            }
          });
      }
    }
  }

  private reloadTree(): void {
    this.dataSource.data = null;
    this.dataSource.data = this.arrCHInfo;
  }


  private calcPlacementText(el: CorporateStructure): string {
    let txt = ""
    if (el) {
      if (el.parentElement) {
        txt = this.calcPlacementText(el.parentElement) + " -> " + el.element;
      } else {
        txt = el.element;
      }
    }
    return txt;
  }

  private openNewDriverForm(item: CorporateStructure) {
    this.router.navigate(['safety/newemployeedriverform/', item.elementGuid], { queryParams: { returnToPage: 'newemployeedriverselector' } });
  }
}
