import { Inject, Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SafetyAdvancedSearchService {
  baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) { 
    this.baseUrl = baseUrl;
    this.http = http;
  }

  getStateProvinces() {
    return this.http.get(this.baseUrl + 'api/ClaimsSearch/GetStateProvinces');
  }
}
