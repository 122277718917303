import { Component } from '@angular/core';
import { IELearnMoreModalService } from '../ie-learnmore-modal/ie-learnmore-modal-service.component';

@Component({
  selector: 'ie-warning-panel',
  templateUrl: './ie-warning-panel.component.html',
  styleUrls: ['./ie-warning-panel.component.css']
})
export class IEWarningPanelComponent{
  isDismissed: boolean = false;

  constructor(private ieModalService: IELearnMoreModalService){}

  openIELearnMoreModal() {
    this.ieModalService.openModal();
  }

  openInEdge() {
    window.location.href = "microsoft-edge:" + window.location.href;
  }

  dismissIEWarning() {
    this.isDismissed = true;
    sessionStorage.setItem('FleetVisibility_IEWarningDismissed', 'Dismiss');
  }
}
