import { ClientSelectionService } from './../../components/client-selection-service/client-selection-service.component';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { CompanySelectionObject, ClientSelectionObject } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'dc-company-selector',
  templateUrl: './dc-company-selector.component.html',
  styleUrls: ['./dc-company-selector.component.css'],
})
export class DcCompanySelectorComponent implements OnInit {
  @Input() lineOfBusiness: string;
  @Output() onCompanySelected: EventEmitter<any> = new EventEmitter<any>();
  companyId: string;
  companyOptions: Array<any>;
  clientChoices: Array<ClientSelectionObject> = new Array<ClientSelectionObject>();

  // the overloaded constructor for the controller
  constructor(private onPremService: OnPremDcService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private clientSelectionService: ClientSelectionService) { }

  // angular on intialization event
  ngOnInit() {
    var lineOfBusiness: number = +this.lineOfBusiness;
    this.loadingSpinnerService.show();
    switch (lineOfBusiness) {
      case 7:
        this.onPremService.get('companies').subscribe({
          next: (data) => {
            this.companyOptions = [];
            let arrOpt = data as Array<CompanySelectionObject>;
            arrOpt.forEach((item: CompanySelectionObject, index: number) => {
              const tempobj = {
                //value: item.id,
                value: item.id.toString(),
                label: item.description,
                groupGuid: item.groupGuid,
                clientShortName: "",
                billingEmailAddress: item.billingEmailAddress,
                billingContactId: item.billingContactId,
                fullHierarchy: item.fullHierarchy
              };
              this.companyOptions.push(tempobj);
            });
            this.loadingSpinnerService.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err.error);
            this.loadingSpinnerService.hide();
          }
        });
        break;
      case 2:
        this.getClientChoices(lineOfBusiness);
        break;
      default:
        break;
    }

  }

  onCompanySelect(event: { source: { value: string; }; }) {
    let emitObj = {};
    this.companyId = event.source.value
    emitObj["companyId"] = +this.companyId;
    emitObj["companyName"] = this.companyOptions.find(c => c.value == this.companyId).label;
    emitObj["groupGuid"] = this.companyOptions.find(c => c.value == this.companyId).groupGuid;
    emitObj["clientShortName"] = this.companyOptions.find(c => c.value == this.companyId).clientShortName;
    emitObj["billingEmailAddress"] = this.companyOptions.find(c => c.value == this.companyId).billingEmailAddress;
    emitObj["billingContactId"] = this.companyOptions.find(c => c.value == this.companyId).billingContactId;
    emitObj["fullHierarchy"] = this.companyOptions.find(c => c.value == this.companyId).fullHierarchy;
    this.onCompanySelected.emit(emitObj);
  }


  private getClientChoices(LineOfBusinessId: number) {
    this.clientChoices = [];
    this.clientSelectionService.getClientChoices(LineOfBusinessId).subscribe({
      next: (data) => {
        this.companyOptions = [];
        this.clientChoices = data as Array<ClientSelectionObject>;
        this.clientChoices.forEach((item: ClientSelectionObject, index: number) => {
          const tempobj = {
            value: item.clientId.toString(),
            label: item.clientName,
            groupGuid: "",
            clientShortName: item.clientShortName
          };
          this.companyOptions.push(tempobj);
        });
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }



}
