<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" style="padding-bottom:20px">
  <div class="col-md-6">
    <h3>Per Client Chart Configuration | {{widgetTitle}}</h3>
  </div>
  <div class="col-md-6">
    <button type="button"
            (click)="enableAll(true)"
            class="btn btn-primary">
      Enable All
    </button>
    <button type="button"
            (click)="enableAll(false)"
            class="btn btn-primary">
      Disable All
    </button>
    <button type="button"
            (click)="exitPerClientInfo()"
            class="btn btn-primary">
      Exit
    </button>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <mat-accordion multi="true">
      <mat-expansion-panel *ngFor="let prodGroup of perClientProductGroups; let i = index">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="title">{{prodGroup.productName}}</div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-md-12">
            <button type="button"
                    (click)="enableProduct(prodGroup.productId, true)"
                    class="btn btn-primary">
              Enable All
            </button>
            <button type="button"
                    (click)="enableProduct(prodGroup.productId, false)"
                    class="btn btn-primary">
              Disable All
            </button>
          </div>
        </div>
        <div class="row" *ngFor="let client of prodGroup.clients">
          <div class="col-md-2">
            <div class="switch">
              <label>
                <input [checked]="client.isActive" (change)="client.isActive=!client.isActive;enableClient(client.clientShortName,client.isActive)" type="checkbox">
                <span class="lever enableWidgetSwitch"></span>
                <span class="enableWidgetLabel">{{client.isActive?"Enabled":"Disabled"}}</span>
              </label>
            </div>
          </div>
          <div class="col-md-10">
            {{client.clientName}}
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
