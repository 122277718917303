<div class="modal-content">
  <div class="modal-header">

    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="cancel()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="labelDqRejectModal">Approve Document</h4>
  </div>

  <div id="modalBody" class="modal-body">
    <div class="row">
      <p class="description">
        Reviewing document: <span>{{ documentData.documentFileName }}</span>
      </p>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="openConfirmationModal()">Approve</button>
    <button class="btn btn-primary" (click)="cancel()">Cancel</button>
  </div>

</div>
