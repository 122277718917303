//Angular
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
//Third Party
import { combineLatest } from 'rxjs';
//APP
import { DriverTrainingCategory, DriverTrainingCourse } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { TrainingLessonService } from './training-lesson.service';
import { MatSelectOption } from '../../shared/models/mat-select-option.model';

@Component({
  selector: 'app-training-lesson-selector',
  templateUrl: './training-lesson-selector.component.html',
  styleUrls: ['./training-lesson-selector.component.scss']
})
export class TrainingLessonSelectorComponent implements OnInit {

  @Input() parentForm: UntypedFormGroup;
  private _trainingLessons: Array<DriverTrainingCourseUi>;
  private _trainingCategories: Array<DriverTrainingCategory>;
  public trainingCategoryOptions = new Array<MatSelectOption>();
  public filteredTrainingCategoryOptions = new Array<MatSelectOption>();
  public trainingSelectionForm = new UntypedFormGroup({});


  private _lessonNameFilter = new UntypedFormControl('');
  private _categoryFilter = new UntypedFormControl(-1);
  private _lessonControlMap = new Map<number, DriverTrainingCourse>();

  static validateOneLessonSelected(control: UntypedFormArray): ValidationErrors {

    if (
      control.controls.filter(
        (lessonCont: UntypedFormGroup) => lessonCont.controls['selected'].value
      ).length <= 0) {
      return { noTrainingLesson: 'At least one training lesson must be selected.' };

    }
    return null;
  }

  constructor(
    private _trainingLessonService: TrainingLessonService,
    private readonly _fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    combineLatest([
      this._trainingLessonService.TrainingCourses$,
      this._trainingLessonService.TrainingCategories$
    ]).subscribe(([trainingLessons, trainingCategories]) => {
      trainingCategories.sort((a, b) => a.description.localeCompare(b.description));
      this._trainingLessons = trainingLessons.map((lesson: DriverTrainingCourse) => {
        return { ...lesson, label: lesson.title + ' PASS RATE: ' + lesson.passRate.toString() + '%' };
      });
      this._trainingCategories = trainingCategories;

      this._trainingLessons.sort((a, b) => a.label.localeCompare(b.label));
      const categoryOptions = new Array<MatSelectOption>();
      categoryOptions.push({ value: -1, label: '-- No Category --' });
      this._trainingCategories.forEach(category => {
        //
        categoryOptions.push({ value: category.id, label: category.description });
      });
      this.trainingCategoryOptions = categoryOptions;
      this.filteredTrainingCategoryOptions = this.trainingCategoryOptions;
      this.filterLessonList(null);
      // this.trainingLessonOptions.controls.push(
      //   ...this._trainingLessons.map(
      //     (lesson) => {
      //       return new FormControl(lesson) as AbstractControl;
      //     })
      // );
    });

    this.trainingSelectionForm.addControl('lessonNameFilter', this._lessonNameFilter);
    this.trainingSelectionForm.addControl('categoryFilter', this._categoryFilter);
    this.trainingSelectionForm.addControl('selectedLessons', new UntypedFormArray([]));

    this.parentForm.addControl('trainingSelections', this.trainingSelectionForm);

  }

  public getLessonOptions() {
    return (this.trainingSelectionForm.controls['selectedLessons'] as UntypedFormArray).controls as UntypedFormGroup[];
  }

  filterLessonList(value: string) {
    const nameFilter = value?.toLowerCase() ?? '';
    const selectedCategoryId = this._categoryFilter.value ?? -1;

    const formArrayStaging = new UntypedFormArray([], [Validators.required, TrainingLessonSelectorComponent.validateOneLessonSelected]);


    this._trainingLessons.forEach((lesson) => {
      const showLesson =
        (lesson.title.toLowerCase().startsWith(nameFilter) || nameFilter.length === 0) &&
        (lesson.courseCategoryId === selectedCategoryId || selectedCategoryId === -1);

      if (showLesson) {
        formArrayStaging.push(this._fb.group({
          selected: new UntypedFormControl(),
          lesson: new UntypedFormControl(lesson)
        }));
      }
    });

    this.trainingSelectionForm.setControl('selectedLessons', formArrayStaging);
  }

  filterOptions(value: string): void{
    const filterValue = value.toLowerCase();
    this.filteredTrainingCategoryOptions = this.trainingCategoryOptions.filter(option => option.label.toLowerCase().startsWith(value));
  }

  selectionChange(value: string | number){
    this.trainingSelectionForm.patchValue({
      'categoryFilter': value
    });
    this._categoryFilter.setValue(value);
    this.filterLessonList("");
  }


}

interface DriverTrainingCourseUi extends DriverTrainingCourse {
  label: string;
}
