import { Endorsements, MVRReport } from './../../../components/classes-and-interfaces/classes-and-interfaces.component';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DHPMVRReportService } from '../dhp-mvr-report-service.component';

@Component({
  selector: 'dhp-mvr-report-endorsements',
  templateUrl: './dhp-mvr-report-endorsements.component.html',
  styleUrls: ['../../dhp-mvr-report/dhp-mvr-report.component.css']
})
export class DhpMvrReportEndorsementsComponent implements OnInit {
  mvrReport: MVRReport;
  public endorsementsTableConfig: any;

  constructor(private readonly datePipe: DatePipe,
    private readonly dhpMVRReportService: DHPMVRReportService) { }
    gtOptions: any = {};

  ngOnInit(): void {
    this.dhpMVRReportService.sharedMvrReport.subscribe(mvrData => { 
      if(mvrData != null ) {
        this.mvrReport = mvrData;
        const endorsementsArray = (this.mvrReport.endorsements != null) ?  this.mvrReport.endorsements : [];
        this.setEndorsementTableConfig(endorsementsArray);
      }
    });
  }

  private setEndorsementTableConfig(endorsementRecords: Array<Endorsements>) {
    const columns = [
      { name: "Endorsement Id", prop: "endorsementId", title: "Endorsement Id", visible: true, dataType: null, columnClass: "width15"  },
      { name: "License Id", prop: "licenseId", title: "License Id", visible: true, dataType: null, columnClass: ""  },
      { name: "Description", prop: "name", title: "Description", visible: true, dataType: null, columnClass: ""  },      
      { name: "Start Date", prop: "startDate", title: "Start Date", visible: true, dataType: "date", columnClass: ""  },
      { name: "End Date", prop: "expirationDate", title: "End Date", visible: true, dataType: "date", columnClass: ""  }
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    const fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s.dataType,
        hidden: !s.visible,
        columnClass: (row, col) => {
          return s.columnClass;
        },
        render: row => {
          if (s.dataType === "date") {
            return this.datePipe.transform(row[s.prop], 'MM/dd/yyyy');
          } else {
            return row[s.prop];
          }
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.endorsementsTableConfig = {
      settings: settings,
      fields: fields,
      data: endorsementRecords,
      noRecordsLabel: 'No Endorsement Records'
    }
    this.gtOptions = (endorsementRecords.length > 1 ? { numberOfRows: endorsementRecords.length } : {});
  }

}
