//Angular
import { Component, ViewChild, QueryList, Input, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
//Third Party
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
//APP
import { DriverInactive } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { HondaOptOutInfoModalService } from './honda-opt-out-info-modal-service.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-honda-opt-out-info-modal',
  templateUrl: './honda-opt-out-info-modal.component.html',
  styleUrls: ['./honda-opt-out-info-modal.component.scss']
})
export class HondaOptOutInfoModalComponent {
  driverInfo: DriverInactive;
  dtToday: Date = new Date();
  reactivateSubscription: Subscription;
  searchId: string;
  searchName: string;
  //@Input()
  selectedDrivers: Array<any> = [];
  @Input() clientSelectedArray: Array<string>;

  gtOptions: any = { numberOfRows: 500 };
  public tableConfig: any;

  constructor(private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { selectedDrivers, clientSelectedArray: Array<string> },
    private optOutService: HondaOptOutInfoModalService,
    private http: HttpClient,
    private onPremService: OnPremDriverService,
    private errorService: ErrorModalService,
    private dialogRef: MatDialogRef<HondaOptOutInfoModalComponent>,
    private loadingSpinnerService: LoadingSpinnerService,
    private readonly snackBar: MatSnackBar) {
    this.selectedDrivers = data.selectedDrivers;
    this.clientSelectedArray = data.clientSelectedArray;
    this.setTableConfig();
  }

  permanentlyTerminate() {

    // this.closeReactivateInfoModal();

    this.loadingSpinnerService.show();
    //this.selectedDrivers.forEach(x => {

    let selectedEmployeeIds = this.selectedDrivers.map(x => x.driverId).join(',');
    //});
    this.onPremService.post('status/' + this.clientSelectedArray.join(',') + '/permanentlyterminate/',
      JSON.stringify(selectedEmployeeIds)
    ).subscribe({
      next: (data) => {
        this.loadingSpinnerService.hide();
        this.snackBar.open('Driver Permanently Terminated', 'Ok', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5000,
          panelClass: 'success-snackbar'
        });
        this.closeReactivateInfoModal();
        // refresh inactive driver grid results
        this.optOutService.setInactiveDriverGridRefresh(true);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  closeReactivateInfoModal() {
    this.driverInfo = null;
    this.dialogRef.close(false);
  }



  private setTableConfig() {
    let datePipe = new DatePipe("en-US");

    var columns = [
      { name: "Employee ID", prop: "employeeId", title: "Employee ID", sort: "enable", visible: true, dataType: null },
      { name: "Employee", prop: "lastName", title: "Employee", sort: "enable", visible: true, dataType: null },
      { name: "DOB", prop: "dateOfBirth", title: "DOB", sort: "enable", visible: true, dataType: 'date' }

    ];

    var settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: s.sort,
        visible: s.visible
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s.dataType,
        type: 'text',
        renderObject: s.prop === 'lastName' ? {
          type: 'hondaOptOutInfoModal'
        } : null,
        render: row => {
          let r = row[s.prop];
          if (s.prop === "lastName") {
            r = row['lastName'] + ', ' + row['firstName'] + ' ' + row['middleName'];
          }
          if (s.dataType === "date") {
            try {
              r = datePipe.transform(new Date(r), "MM/dd/yyyy");
            } catch (e) { }
          }

          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          return val;
        }

      });
    });

    this.tableConfig = {
      settings: settings,
      fields: fields,
      data: this.selectedDrivers
    }
    //console.log(this.tableConfig);
  }

}
