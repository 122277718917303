import { DriverTraining } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DcConfirmActionService } from './../dc-confirm-action/dc-confirm-action-service.component';
import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { DcDriverProduct, DcRequestResponse } from '../dc-classes-and-interfaces/dc-classes-and-interfaces.component';
import { DcModalWindowService } from '../dc-modal-window/dc-modal-window-service.component';
import { DatePipe } from '@angular/common';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { DcDriverProfileService } from '../dc-driver-profile/dc-driver-profile-service.component';
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { AlertService } from '../../services/alert-service/alert.service';

/**
 * this is the client-side controller for the safety - telematics tab panel
 */
@Component({
  selector: 'dc-driver-assigned-programs',
  templateUrl: './dc-driver-assigned-programs.component.html',
  styleUrls: ['./dc-driver-assigned-programs.component.css']
})

export class DcDriverAssignedProgramsComponent implements OnInit, OnChanges {
  @Input() programs: Array<DcDriverProduct>;
  @Input() companyId: number;
  @Input() driverId: number;
  @Input() driverName: string;
  lastProgram: boolean = false;
  confirmMessage: string = ""
  gtOptions: any = {};
  slotToRemove: number;
  incompleteTraining: Array<DriverTraining>;
  public driverProgramTableConfig: any;

  // the overloaded constructor for the controller
  constructor(private dcModalWindowService: DcModalWindowService,
    private onPremService: OnPremDcService,
    private errorService: ErrorModalService,
    private dcService: DcDriverProfileService,
    private AlertService: AlertService,
    private dcConfirmActionService: DcConfirmActionService) { }

  ngOnInit() {
    // set data for tables
    this.setTableData();
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //update table data when inputs change
    this.setTableData();
  }

  private setTableData() {
    if (this.programs) {
      this.setProgTableConfig(this.programs);
    }
  }

  public removeProgram() {
    this.onPremService.put(`companies/${this.companyId}/slots/${this.slotToRemove}/unassign`, null).subscribe({
      next: (data) => { // next
        let resp = data as DcRequestResponse;
        this.lastProgram = this.programs.length == 1 ? true : false
        if (!resp.isSuccess) {
          this.errorService.replaceErrorObject({ message: resp.errorMessage });
        } else {
          this.AlertService.showSuccessAlert('Driver Removed', 'end', 'top', 5000);
          this.dcService.setRefreshDriverProfileBaseData(this.driverId);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
      },
      complete: () => { // complete
        if (this.lastProgram) {
          this.terminateDriver();
        }
      }
    })
  }

  private terminateDriver(): void {
    let action = 'Terminate Driver';
    this.dcConfirmActionService.terminateDriver(this.driverId.toString()).subscribe({
      next: (data) => {
        this.AlertService.showSuccessAlert(`${action} Driver Terminated`, 'end', 'top', 5000);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
      }
    });
  }

  public onTriggerAction(data: any): void {
    const columnName = data?.columnName;
    if (columnName === 'remove') {
      return this.confirmRemove(data);
    }
  }

  public confirmRemove(data: any): void {
    const slotID = data?.slotId;
    const programName = data?.productName;
    this.dcModalWindowService.notifyModal('Confirm Removal', 'dcRemoveProgram', 'auto', 'md', { companyId: this.companyId, driverId: this.driverId, programName: programName, driverName: this.driverName, slotID: slotID, programs: this.programs, lastProgram: this.lastProgram });
  }

  public cancel(): void {
    // notify the modal window to close
    this.dcService.notifyModalClose();
  }

  private setProgTableConfig(arrInitial: Array<DcDriverProduct>) {
    let arrProgram = JSON.parse(JSON.stringify(arrInitial)) as Array<DcDriverProduct>;
    arrProgram.forEach(p => p.productName = p.product.description);
    let datePipe = new DatePipe("en-US");

    var columns = [
      { name: "Program", prop: "productName", title: "Program", visible: true, sort: "", dataType: null },
      { name: "Expiration Date", prop: "expirationDate", title: "Expiration Date", sort: "", visible: true, dataType: "date" },
      { name: "Assigned Date", prop: "assignedDate", title: "Assigned Date", visible: true, dataType: "date" },
      { name: "slotId", prop: "slotId", title: "slotId", visible: false, dataType: null },
      { name: "Remove", prop: "remove", title: "", visible: true, dataType: null },
    ];

    var settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: 'enable'
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s.dataType,
        hidden: !s.visible,
        render: row => {
          let r = row[s.prop];
          if (s.dataType === "date") {
            try {
              r = (r ? datePipe.transform(new Date(r), "MM/dd/yyyy") : '');
            } catch (e) { }
          }
          if (s.prop == "remove") {
            r = '<button class="btn btn-xs mvrContestButton rounded my-0 p-1">Remove</button>';
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        },
        click: row => {
          if (s.prop === "remove") {
            const data = {
              slotId: row["slotId"],
              productName: row["productName"]
            }
            return this.confirmRemove(data);
          }
        }

      });
    });

    this.driverProgramTableConfig = {
      settings: settings,
      fields: fields,
      data: arrProgram
    }

    this.gtOptions = (arrProgram.length > 1 ? { numberOfRows: arrProgram.length } : {});

  }

  assignProgramDialog() {
    this.dcModalWindowService.notifyModal('Assign Program', 'dcDriverAssignProgram', 'auto', 'md', { companyId: this.companyId, driverId: this.driverId });
  }

}
