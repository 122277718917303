import { NgModule, NO_ERRORS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { RouterModule, RunGuardsAndResolvers, RouteReuseStrategy } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AuthService } from '../components/auth/auth.service';
import { AuthGuard } from '../components/auth/auth.guard';

import { AccidentAdvancedSearchComponent } from './accident-advanced-search/accident-advanced-search.component';
import { AccidentClaimComponent } from './accident-claim/accident-claim.component';
import { AccidentClaimTabLossNoticeComponent } from './accident-claim-tab-loss-notice/accident-claim-tab-loss-notice.component';
import { AccidentClaimFollowUpDateComponent } from './accident-claim-follow-up-date/accident-claim-follow-up-date.component';
import { AccidentClaimInfoComponent } from './accident-claim-info/accident-claim-info.component';
import { AccidentClaimAddNote } from './accident-claim-note-add/accident-claim-note-add.component';
import { AccidentClaimNotes } from './accident-claim-notes/accident-claim-notes.component';
import { AccidentClaimService } from './accident-claim/accident-claim-service.component';
import { AccidentClaimTransactionsComponent } from './accident-claim-transactions/accident-claim-transactions.component';
import { AccidentClaimSubroOutboundComponent } from './accident-claim-subro-outbound/accident-claim-subro-outbound.component';
import { AccidentClaimSubroInboundComponent } from './accident-claim-subro-inbound/accident-claim-subro-inbound.component';
import { AccidentClaimSubroMissingDocsComponent } from './accident-claim-subro-missing-docs/accident-claim-subro-missing-docs.component';
import { AccidentClaimInvoicesComponent } from './accident-claim-invoices/accident-claim-invoices.component';
import { AccidentClaimAtFaultPartyComponent } from './accident-claim-at-fault/accident-claim-at-fault.component';
import { AccidentClaimRentalComponent } from './accident-claim-rental/accident-claim-rental.component';
import { AccidentClaimUnitComponent } from './accident-claim-unit/accident-claim-unit.component';
import { AccidentClaimRepairApprovalComponent } from './accident-claim-repair-approval/accident-claim-repair-approval.component';
import { AccidentClaimRepairFirstPartyComponent } from './accident-claim-repair-first-party/accident-claim-repair-first-party.component';
import { AccidentClaimRepairThirdPartyComponent } from './accident-claim-repair-third-party/accident-claim-repair-third-party.component';
import { AccidentClaimSupportsComponent } from './accident-claim-supports/accident-claim-supports.component';
import { AccidentClaimSupportsUploadComponent } from './accident-claim-supports-upload/accident-claim-supports-upload.component';
import { AccidentClaimSupportsSlideshowComponent } from './accident-claim-supports-slideshow/accident-claim-supports-slideshow.component';
import { AccidentClaimSubroApprovalComponent } from './accident-claim-subro-approval/accident-claim-subro-approval.component';
import { AccidentClaimTotalLossComponent } from './accident-claim-total-loss/accident-claim-total-loss.component';
import { AccidentClaimSupplementalDataComponent } from './accident-claim-supplemental-data/accident-claim-supplemental-data.component';
import { AccidentClaimEditClaimComponent } from './accident-claim-edit-claim/accident-claim-edit-claim.component';
import { AdvancedSearchClaimsComponent } from './advanced-search-claims/advanced-search-claims.component';
import { AdvancedSearchService, AdvancedSearchRequest } from './advanced-search-claims/advanced-search-claims.service';
import { ClaimsAdvancedSearchResolve } from './advanced-search-claims/advanced-search-claims-resolve.service';
import { AccidentAdvanceSearchService } from './accident-advanced-search/accident-advanced-search-service.component';
import { DashboardCustomReportAdminComponent } from './dashboard-custom-report-admin/dashboard-custom-report-admin.component';
import { CustomReportGridComponent } from './custom-report-grid/custom-report-grid.component';
import { CustomReportGridResolve } from './custom-report-grid/custom-report-grid-resolve.service';
import { CustomReportGridService, CustomReportGridRequest } from './custom-report-grid/custom-report-grid.service';
import { CustomReportService } from './dashboard-custom-report-admin/dashboard-custom-report-service.component';
import { CustomReportsMenuService } from './custom-reports-menu-component/custom-reports-menu.service';
import { CustomReportsModalService } from './custom-reports-filter-modal/custom-reports-filter-modal.service';
import { CustomReportsFilterModalComponent } from './custom-reports-filter-modal/custom-reports-filter-modal.component';
import { CustomReportsMenuComponent } from './custom-reports-menu-component/custom-reports-menu.component';
import { DateFilterService } from '../components/date-filter-service/date-filter-service.component';
import { AccidentClaimArbitrationComponent } from './accident-claim-arbitration/accident-claim-arbitration.component';

@NgModule({
    declarations: [
        AccidentAdvancedSearchComponent,
        AccidentClaimComponent,
        AccidentClaimTabLossNoticeComponent,
        AccidentClaimFollowUpDateComponent,
        AccidentClaimInfoComponent,
        AccidentClaimAddNote,
        AccidentClaimNotes,
        AccidentClaimTransactionsComponent,
        AccidentClaimSubroOutboundComponent,
        AccidentClaimSubroInboundComponent,
        AccidentClaimSubroMissingDocsComponent,
        AccidentClaimInvoicesComponent,
        AccidentClaimAtFaultPartyComponent,
        AccidentClaimRentalComponent,
        AccidentClaimUnitComponent,
        AccidentClaimRepairApprovalComponent,
        AccidentClaimRepairFirstPartyComponent,
        AccidentClaimRepairThirdPartyComponent,
        AccidentClaimSupportsComponent,
        AccidentClaimSupportsUploadComponent,
        AccidentClaimSupportsSlideshowComponent,
        AccidentClaimSubroApprovalComponent,
        AccidentClaimTotalLossComponent,
        AccidentClaimSupplementalDataComponent,
        AccidentClaimEditClaimComponent,
        AccidentClaimArbitrationComponent,
        AdvancedSearchClaimsComponent,

        CustomReportGridComponent,
        CustomReportGridComponent,
        CustomReportsFilterModalComponent,
        CustomReportsMenuComponent,

        DashboardCustomReportAdminComponent,
    ],
    imports: [
        SharedModule,
        RouterModule.forChild([

            { path: 'accident/claim/:claimId', component: AccidentClaimComponent, canActivate: [AuthGuard] },
            { path: 'accident/claim/:claimId/:tabId', component: AccidentClaimComponent, canActivate: [AuthGuard] },
            { path: 'accident/advancedSearch', component: AccidentAdvancedSearchComponent, canActivate: [AuthGuard] },
            {
                path: 'accident/advancedSearch/:id', component: AdvancedSearchClaimsComponent,
                runGuardsAndResolvers: 'always',
                resolve: { data: ClaimsAdvancedSearchResolve },
                canActivate: [AuthGuard]
            },
            { path: 'customReports', component: DashboardCustomReportAdminComponent, canActivate: [AuthGuard] },
            {
                path: 'customReports/:id', component: CustomReportGridComponent,
                runGuardsAndResolvers: 'always',
                resolve: { data: CustomReportGridResolve },
                canActivate: [AuthGuard]
            },
        ])
    ],
    exports: [
        CustomReportsFilterModalComponent,
        CustomReportsMenuComponent
    ],
    providers: [
        AccidentClaimService,
        AccidentAdvanceSearchService,
        AdvancedSearchRequest,
        AdvancedSearchService,
        ClaimsAdvancedSearchResolve,
        CustomReportGridService,
        CustomReportGridResolve,
        CustomReportGridRequest,
        CustomReportService,
        CustomReportsMenuService,
        CustomReportsModalService,
        DateFilterService
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class ClaimsModule {
    constructor(private authService: AuthService) {
        console.log('CLAIMS MODULE STARTING');
    }
}

