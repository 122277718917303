import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { BusinessGroupNewsItem } from '../classes-and-interfaces/classes-and-interfaces.component';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class BusinessGroupNewsService {
  private openBusinessNewsObs = new Subject();
  openBusinessNewsObs$ = this.openBusinessNewsObs.asObservable();

  private closeBusinessNewsObs = new Subject();
  closeBusinessNewsObs$ = this.closeBusinessNewsObs.asObservable();
  _baseUrl: string;

  private businessGroupNewsItem = new Subject<BusinessGroupNewsItem>();
  businessGroupNewsItem$ = this.businessGroupNewsItem.asObservable();

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private authService: AuthService) {
    this._baseUrl = baseUrl;
  }


  getNewsItem(LineOfBusiness: number, showIfDismissed: boolean = false) {
    if (!this.authService.isLoggedIn())
      return;

    this.http.get(this._baseUrl + "api/BusinessNews/GetByLob?LineOfBusinessId=" + LineOfBusiness).subscribe({
      next: (data) => {
        let item = data as BusinessGroupNewsItem;
        //let startDate = new Date(item.startDate);
        //let endDate = new Date(item.endDate);
        //let today = new Date()
        //if (startDate < today && today < endDate) {
        this.businessGroupNewsItem.next(item);
        if (item && item.isShowPopup || showIfDismissed)
          this.openBusinessNewsObs.next(item);
        //}
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        //this.errorService.setErrorObject(err.error);
        //this.mdbSpinningPreloader.stop();
      }
    });
    //if (LineOfBusiness != 1) { return; }
    //let item = new BusinessGroupNewsItem();
    //item.lineOfBusiness = 1;
    //item.dashboardButtonLabel = "news 1";
    //item.dialogTitle = "title1";
    //item.isPopupShow = true;
    //item.newsText = "body1";
    //item.visibilityNewsId = 22;

    //this.openBusinessNewsObs.next(item);
  }

  closeNewsModal(LineOfBusiness: number) {
    this.closeBusinessNewsObs.next(LineOfBusiness);
  }
  dismissNewsItem(item: BusinessGroupNewsItem) {

    let params = new HttpParams();
    params = params.append('VisibilityNewsId', item.visibilityNewsId.toString());
    params = params.append('Show', "false");
    //localStorage.setItem("VisibilityNewsDismissed_" + item.lineOfBusinessId, item.visibilityNewsId.toString());
    this.http.post(this._baseUrl + 'api/BusinessNews/SetViewStatus', null, {
      params: params
    }).subscribe({
      next: (data) => {

      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        //this.errorService.setErrorObject(err.error);
      }
    });
  }
  checkIfItemDismissed(item: BusinessGroupNewsItem): boolean {
    //return (localStorage["VisibilityNewsDismissed_" + item.lineOfBusinessId] == item.visibilityNewsId);
    return !item.isShowPopup;
  }
}


