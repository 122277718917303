//angular libraries
import { Component, Inject, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
//thirdparty libraries
import { Observable, forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
//project imports
import { DataGridInfo, LineOfBusinessInfo } from "../classes-and-interfaces/classes-and-interfaces.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { ConfirmModalComponent } from "../confirm-modal/confirm-modal.component";
import { ErrorModalService } from "../error-modal/error-modal-service.component";


@Component({
  selector: 'app-data-grid-admin-list',
  templateUrl: './data-grid-admin-list.component.html',
  styleUrls: ['./data-grid-admin-list.component.scss']
})
export class DataGridAdminListComponent implements OnInit {
  @ViewChild(ConfirmModalComponent) removeConfirmModalContainer: ConfirmModalComponent;
  dataGridToRemove: DataGridInfo = null;
  dataGridList: Array<DataGridInfo>;
  dataGridListFiltered: Array<DataGridInfo>;
  baseUrl: string;
  lineOfBusinessChoices: Array<any>;
  lineOfBusinessFilter: string;

  constructor(private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private dialog: MatDialog
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void {
    this.initAll();
  }

  private initAll() {
    let arrObs: Array<Observable<Object>> = [
      this.http.get(this.baseUrl + 'api/AdminDataGrid/Get'),
      this.http.get(this.baseUrl + 'api/UserFilter/LineOfBusinesses')
    ];
    this.loadingSpinnerService.show();
    let obsSub = forkJoin(arrObs).subscribe({
      next: (data) => {
        this.dataGridList = data[0] as Array<DataGridInfo>;
        this.dataGridListFiltered = this.dataGridList;
        let lobInfo = data[1] as Array<LineOfBusinessInfo>;
        let lobChoices: Array<any> = [{ value: "0", label: "-- No Filter --" }];
        this.lineOfBusinessChoices = lobChoices.concat(lobInfo.map(c => { return { value: c.lineOfBusinessId.toString(), label: c.lineOfBusinessName } }));
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        if (err.status != 403)
          this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private getDataGridList() {
    this.loadingSpinnerService.show();
    this.http.get(this.baseUrl + 'api/AdminDataGrid/Get').subscribe({
      next: (data) => {
        this.dataGridList = data as Array<DataGridInfo>;
        this.applyLineOfBusinessFilter(this.lineOfBusinessFilter);
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        if (err.status != 403)
          this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    })
  }

  applyLineOfBusinessFilter(strLOB: string) {
    try {
      let lob = parseInt(strLOB, 10);
      if (lob > 0) {
        this.dataGridListFiltered = this.dataGridList.filter(w => w.lineOfBusinessId == lob);
      } else {
        this.dataGridListFiltered = this.dataGridList;
      }

    } catch (e) {
      this.dataGridListFiltered = this.dataGridList;
    }
  }

  enableDataGrid(dataGridId: number, enabled: boolean) {
    let params = new HttpParams();
    params = params.append('DataGridId', dataGridId.toString());
    params = params.append('activate', enabled.toString());

    this.http.post(this.baseUrl + 'api/AdminDataGrid/ActivateGrid', null, {
      params: params
    }).subscribe({
      next: (data) => {
        this.getDataGridList();
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        if (err.status != 403)
          this.errorService.setErrorObject(err.error);
      }
    }
    )
  }

  addNewDataGrid() {
    this.router.navigateByUrl('/admin/datagridadminedit/');
  }

  showConfirmModal(): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: { confirmTitle: "Delete Report?", confirmPrompt: `Delete ${this.dataGridToRemove?.title}?` },
      width: '34em',
      minHeight: '13em',
      panelClass: 'alert-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.removeDataGrid(this.dataGridToRemove.dataGridId);
    })
  }

  confirmRemoveDataGrid(dataGrid: DataGridInfo) {
    this.dataGridToRemove = dataGrid;
    this.showConfirmModal();
  }

  removeDataGrid(dataGridId: number) {
    let params = new HttpParams();
    params = params.append('DataGridId', dataGridId.toString());

    this.loadingSpinnerService.show();
    this.http.delete(this.baseUrl + 'api/AdminDataGrid/DeleteGrid', {
      params: params
    }).subscribe({
      next: (data) => {
        this.loadingSpinnerService.hide();
        this.getDataGridList();
        this.dataGridToRemove = null;
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        if (err.status != 403)
          this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    }
    )
  }
}
