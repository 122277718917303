<!--<highcharts-chart [Highcharts]="Highcharts"
                  [options]="chartOptions"
                  [callbackFunction]="chartCallback"
                  style="width: 100%;" class="highchartsContainer"
                  id="chart{{TileId}}"
                  ></highcharts-chart>-->

<div id="container{{card?.tileId}}" style="width: 100%;" class="highchartsContainer" #container></div>


