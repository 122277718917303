import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserRightsService } from '../../components/user-rights-service/user-rights-service.component';
import { ClaimTabService } from '../../components/claim-tab-service/claim-tab-service.component';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from "rxjs";
import { UserRightsInfo } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
//#AngularUpgrade
//import { setTimeout } from 'timers';

@Component({
  selector: 'app-accident-claim',
  templateUrl: './accident-claim.component.html',
  styleUrls: ['./accident-claim.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccidentClaimComponent implements OnInit, OnDestroy {
  _claimId: number;
  _tabs: Array<ClaimTab>;
  _validTabs: Array<number> = [];
  _routeSub: Subscription;
  _goToClaimSub: Subscription;
  _userRights: Array<UserRightsInfo>;

  constructor(private router: Router,
    private _route: ActivatedRoute,
    private userRightsService: UserRightsService,
    private claimTabService: ClaimTabService,
    private accidentClaimService: AccidentClaimService,
    private lineOfBusinessService: LineOfBusinessService
  ) {
    // set shouldReuseRoute to return false to make sure
    // all tabs re-initialize when navigating to new claimId
    /* - 2018-11 - commenting out shouldReuseRoute code, route
     * reuse isnt applicable to accident claim components?
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    */
    this._goToClaimSub = accidentClaimService.goToClaim$.subscribe(
      c => {
        let claimId = c as number;
        if (claimId && (claimId > 0)) {
          this.router.navigate(['accident/claim', claimId.toString()]);
        }
      }
    );
  }

  ngOnInit(): void {
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 1) {
      this.lineOfBusinessService.setLineOfBusiness(1);

    }

    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];
      // get tabs
      this.claimTabService.getTabs(this._claimId).subscribe({
        next: (data) => {
          this._tabs = data as Array<ClaimTab>;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });
      // get user permissions for this claim
      this.userRightsService.getUserRightsAccidentClaim(this._claimId).subscribe({
        next: (data) => {
          this._userRights = data as Array<UserRightsInfo>;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });
    });
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
    this._goToClaimSub.unsubscribe();
  }

  showThisTab(tab: ClaimTab, tabId: number) {

    if (this._tabs && (tabId == tab.detailViewTabId)) {
      this._validTabs.push(tabId);
      return true;
    } else {
      return false;
    }
  }

  showEmptyTab(tab: ClaimTab) {
    return (this._validTabs.indexOf(tab.detailViewTabId) < 0);
  }
  onClaimUpdated() {
    this.router.navigate(["/accident/claim", this._claimId]);
  }
}

export class ClaimTab {
  detailViewTabId: number;
  displayName: string;
  detailViewTab: string;
}
