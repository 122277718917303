import { Component, OnInit, Input, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";

@Component({
  selector: 'app-accident-claim-subro-missing-docs',
  templateUrl: './accident-claim-subro-missing-docs.component.html',
  styleUrls: ['./accident-claim-subro-missing-docs.component.css']
})
export class AccidentClaimSubroMissingDocsComponent implements OnInit {
  @Input() _claimId: number;
  gtOptions: any = {};
  public tableConfig: any;
  public showMissingDocsPanel: boolean = true;

  displayTransactionsModal: string;

  constructor(
    private accidentClaimService: AccidentClaimService,
    private errorService:ErrorModalService
  ) { }

  ngOnInit(): void {

    this.accidentClaimService.getSubroMissingDocs(this._claimId).subscribe({
      next: (data) => {
      if (data) {
        let arrDocs = data as Array<SubroMissingDocInfo>;
        if (arrDocs.length > 0) {
          this.setTableConfig(arrDocs);
        } else {
          // don't show panel if no missing docs
          this.showMissingDocsPanel = false;
        }
      } else {
        // don't show panel if no missing docs
        this.showMissingDocsPanel = false;
      }
     
    },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
      }
      });
  }

  private setTableConfig(arrDoc: Array<SubroMissingDocInfo>) {
    let datePipe = new DatePipe("en-US");

    let columns = [
      { name: "Type", prop: "documentType", title: "Type", dataType: null },
      { name: "Description", prop: "documentDescription", title: "Description", dataType: null },
      { name: "Created By", prop: "createdBy", title: "Created By", dataType: null },
      { name: "Created Date", prop: "createDate", title: "Created Date", dataType: 'date' }
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: ''
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s?.dataType,
        render: row => {
          let r = row[s.prop];

          if (s.dataType === "date") {
            try {
              r = datePipe.transform(new Date(r), "MM/dd/yyyy");
            } catch (e) { }
          }

          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.tableConfig = {
      settings: settings,
      fields: fields,
      data: arrDoc,
      noRecordsLabel: 'No Missing Document information'
    }

    this.gtOptions = (arrDoc.length > 1 ? { numberOfRows: arrDoc.length } : {});
  }
  
}

export class SubroMissingDocInfo {
  missingDocId: number;
  claim: number;
  subroId: number;
  documentType: string;
  documentDescription: string;
  createUser: number;
  createdBy: string;
  createDate: string;
}
