import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard  {

  constructor(private authService: AuthService) {

  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    //if (this.authService.isLoggedIn()) {
    //  return true;
    //}
    return new Promise((resolve, reject) => {
      this.authService.isLoggedInAndGetUser().then(result => {
        if (result) {
          resolve(true);
          return;
        }

        //this.authService.onConfigurationLoaded.subscribe(() => {
          console.log('should only run on start up');
        //  if (!state.url.startsWith("callback"))
        //    sessionStorage.setItem("authRedirectUrl", state.url);
        //  this.authService.startAuthentication();
        //});
          if (!state.url.startsWith("callback"))
              sessionStorage.setItem("authRedirectUrl", state.url);
          this.authService.startAuthentication();
        
        resolve(false);
      })
        .catch(() => {
          resolve(false);
        });
    });
  }
}
