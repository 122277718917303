<div tabindex="-1" role="dialog">
  <div role="document">
    <div>
      <div class="header">
        <h4 class="modal-title">Add Group</h4>
        <button type="button" class="close" aria-label="Close" (click)="onCloseHandled()"><span style="color:white" aria-hidden="true">&times;</span></button>
      </div>
        <div class="body">
          <div class="col-sm-12">
            <div class="md-form">
              <input type="text" id="tbxGroup" [(ngModel)]="groupName" />
              <label for="tbxGroup" [class.active]="groupName">Group Name</label>
             </div>
            <div>
              <p>
                <button class="btn btn-default" (click)="onOkHandled()">OK</button>
                <button class="btn btn-default" (click)="onCloseHandled()">Cancel</button>
              </p>
            </div>
          </div>
        </div> 
    </div>
  </div>
