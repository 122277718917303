<div>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeReactivateInfoModal()">
        <span aria-hidden="true">×</span>
      </button>
      <h4 class="modal-title w-100" id="labelReactivateInfoModal">Re-Activate Employee</h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12" style="text-align:center">
          Today's Date: {{dtToday|date:'MM/dd/yyyy'}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card card-body mt-3">
            <div class="row">
              <div class="col-sm-4 readOnlyFormLayoutLabel">
                Employee ID:
              </div>
              <div class="col-sm-8 readOnlyFormLayoutBold">
                {{driverInfo?.employeeId}}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 readOnlyFormLayoutLabel">
                Employee:
              </div>
              <div class="col-sm-8 readOnlyFormLayoutBold">
                {{driverInfo?.lastName + ', ' + driverInfo?.firstName + ' ' + driverInfo?.middleName}}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 readOnlyFormLayoutLabel">
                DOB:
              </div>
              <div class="col-sm-8 readOnlyFormLayoutBold">
                {{driverInfo?.dateOfBirth|date:'MM/dd/yyyy'}}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 readOnlyFormLayoutLabel">
                Email:
              </div>
              <div class="col-sm-8 readOnlyFormLayoutBold">
                {{driverInfo?.email}}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 readOnlyFormLayoutLabel">
                Group:
              </div>
              <div class="col-sm-8 readOnlyFormLayoutBold">
                {{driverInfo?.group}}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 readOnlyFormLayoutLabel">
                Deactivated:
              </div>
              <div class="col-sm-8 readOnlyFormLayoutBold">
                {{driverInfo?.deactivatedDate|date:'MM/dd/yyyy'}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" aria-label="Re-Activate" (click)="reActivate()">Re-Activate</button>
      <button type="button" class="btn btn-default" style="margin-bottom:6px" aria-label="Close" (click)="closeReactivateInfoModal()">Close</button>
    </div>
  </div>
</div>
