import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { interval, BehaviorSubject } from 'rxjs';
import { startWith, flatMap } from 'rxjs/operators';
import { UserRightsInfo } from '../classes-and-interfaces/classes-and-interfaces.component';

@Injectable()
export class UserRightsService {
  baseUrl: string;
  private userRightsValue = new BehaviorSubject<Array<UserRightsInfo>>(Array.from<UserRightsInfo>([]));
  userRightsValue$ = this.userRightsValue.asObservable();
  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  getUserRights(lineOfBusinessId: number, clientShortNames: Array<string>) {
    let params = new HttpParams();
    params = params.append('LineOfBusinessId', lineOfBusinessId.toString());
    params = params.append('Clients', clientShortNames.join(','));
    this.getSetUserRights(params);
    return this.http.get(this.baseUrl + 'api/UserSettings/GetUserRights', {
      params: params
    });
  }

  getUserRightsValue() {
    return this.userRightsValue.getValue();
  }

  getSetUserRights(params: HttpParams) {
    this.http.get(this.baseUrl + 'api/UserSettings/GetUserRights', { params: params }).subscribe(
      (data: Array<UserRightsInfo>) => { 
        // The API returns the user rights for one line of business at a time
        const lineOfbusiness = data[0]?.lineOfBusinessId;
        // Merge new rights data into a single collection.
        const updatedRights = this.userRightsValue.value
          // Get a new array of rights consisting of all existing rights, EXCEPT the rights for the current line of business.
          .filter(r => r.lineOfBusinessId !== lineOfbusiness)
          // Append the rights for the current line of business to the array
          .concat(data);
      this.userRightsValue.next(updatedRights);
    });
  }

  getUserRightsAccidentClaim(claimId: number) {
    let params = new HttpParams();
    params = params.append('claimId', claimId.toString());

    return this.http.get(this.baseUrl + 'api/Claim/GetPermissionsByClaimId', {
      params: params
    });
  }

  getUserNotifications() {
    return interval(30000).pipe(
        startWith(3000),
        flatMap(() => {
            return this.http.get(this.baseUrl + 'api/UserSettings/GetUserNotifications');
        })
    );
  }
}
