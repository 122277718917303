//Angular
import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
//Third Party
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as exceljs from 'exceljs';
import * as FileSaver from 'file-saver';
//App
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { CustomReportGridRequest } from "./custom-report-grid.service";
import { DataGridEmailService } from "../../components/data-grid-export-email/data-grid-export-email.service";
import { FileDownloadService } from "../../shared/file-download/file-download.service";
import { ErrorObject } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from "../../services/loading-spinner-service/loading-spinner.service";

@Component({
  selector: 'custom-report-grid',
  templateUrl: './custom-report-grid.component.html',
  styleUrls: ['./custom-report-grid.component.scss']
})
export class CustomReportGridComponent implements OnInit, OnDestroy {
  invalidGridData: boolean = false;
  public configObject: any;
  baseUrl: string;
  lineOfBusinessId: number;
  public page = 1;
  public itemsPerPage = 50;
  public maxSize = 5;
  public numPages = 1;
  public length = 0;
  public reportTitle: string;
  lineOfBusinessSubscription: Subscription;


  public sortPageTime = 0;
  columns = [];
  rowsRaw: Array<any>;
  rows: Array<any>;
  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: {}
  };
  today: any;

  constructor(private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    readonly route: ActivatedRoute,
    private lineOfBusinessService: LineOfBusinessService,
    private emailService: DataGridEmailService,
    private fileDownloadService: FileDownloadService,
    public reportRequestService: CustomReportGridRequest,
    private errorModalService: ErrorModalService,
    private loadingSpinnerService: LoadingSpinnerService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;

    this.today = Date.now();
    this.lineOfBusinessSubscription = lineOfBusinessService.lineOfBusinessSelected$.subscribe(data => {
      this.lineOfBusinessId = data as number;
    });
  }

  ngOnInit(): void {
    this.loadingSpinnerService.hide();
    this.lineOfBusinessId = this.lineOfBusinessService.getLineOfBusinessValue();

    this.route.data
      .subscribe((data) => {
        if (data && data.data) {

          if (data.data instanceof HttpErrorResponse) {
            this.errorModalService.setErrorObject(data.data.error as ErrorObject);
            return;
          }

          this.columns = data.data.columns;
          this.rowsRaw = data.data.rows;

          var fields = new Array<any>();
          this.reportTitle = data.data.grid.title;

          this.columns.forEach(s => {
            if (this.lineOfBusinessId === 1 && s.name === "Claim") {
              fields.push({
                name: s.title,
                objectKey: s.prop,
                columnClass: 'clickable',
                click: row => {
                  this.router.navigate(["/accident/claim/" + row[s.name]]);
                }
              });
            }
            else if (this.lineOfBusinessId === 2 && s.name === "DriverId") {
              fields.push({
                name: s.title,
                objectKey: s.prop,
                columnClass: 'clickable',
                click: row => {
                  this.router.navigate(["/driver/history-profile/" + row[s.name]]);
                }
              });
            }
            else
              fields.push({
                name: s.title,
                objectKey: s.name,
                render: row => {
                  if (s.dataType === "currency") {
                    if (row[s.prop] >= 0) {
                      return "$" + row[s.name];
                    }
                    else {
                      return "($" + (row[s.name] * -1) + ")";
                    }
                  }
                  else if (s.dataType === "date" && row[s.name]) {
                    try {
                      return moment(new Date(row[s.name])).format("MM/DD/YYYY");
                    }
                    catch (err) {
                      return row[s.name];
                    }
                  }
                  else {
                    return row[s.name];
                  }
                }
              });
          });

          var settings = this.columns.map(function (s) {
            return {
              objectKey: s.name,
              sort: 'enable'
            };
          });
          this.configObject = {
            settings: settings,
            fields: fields,
            data: data.data.rows
          };
          if (!data.data.totals || data.data.totals.length === 0) {
            this.configObject.totals = [];
          }
        } else {
          this.invalidGridData = true;
        }
      });


  }

  ngOnDestroy() {
    this.lineOfBusinessSubscription.unsubscribe();
  }

  onExcel() {
    const workbook = new exceljs.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    const searchString = " ";
    const replaceWith = "_";
    let dbColumns;
    this.columns.forEach((element) => {
      if (dbColumns === undefined) {
        dbColumns = [{header: element['name'], key: element['name'].replace(new RegExp(searchString, 'g'), '')}]
      }
      else {
        dbColumns.push({header: element['name'], key: element['name'].replace(new RegExp(searchString, 'g'), '')}) 
      }
    })
    worksheet.columns = dbColumns;
    this.configObject.data.forEach((element) => {
      const rowValue = {};
      dbColumns.forEach((columns) => { 
        rowValue[columns['key']] = element[columns['header']]
      })
      worksheet.addRow(rowValue);
    })
    const fileName = `Visibility-CustomReport-${moment().format("MM-DD-YYYY")}.xlsx`.replace(new RegExp(searchString, 'g'), replaceWith);
    workbook.xlsx.writeBuffer().then(function (data) {
      var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      FileSaver.saveAs(blob, fileName)
    })
  }

  onPrint() {
    let requestParam = btoa(JSON.stringify(this.reportRequestService));
    this.fileDownloadService.openTab(this.baseUrl + "api/CustomReport/Print?request=" + encodeURIComponent(requestParam));
  }

  onEmail() {
    this.reportRequestService.ReportName = this.reportTitle;
    this.emailService.OpenEmailPopup(this.reportRequestService);
  }

  trigger($event) {
    switch ($event.name) {
      case 'gt-sorting-applied':
        console.log($event.value);
        if ($event.value && $event.value[0] !== '$$gtInitialRowIndex') {

          if (($event.value[0] as string).startsWith('-')) {
            this.reportRequestService.SortField = ($event.value[0] as string).slice(1);
            this.reportRequestService.SortDir = "DESC"
          }
          else {
            this.reportRequestService.SortField = $event.value[0]
            this.reportRequestService.SortDir = "ASC"
          }
          const fld = this.configObject.fields.find(x => x.objectKey === this.reportRequestService.SortField)
          if (fld)
            this.reportRequestService.SortDisplayField = fld.name;
        }
        else {
          this.reportRequestService.SortDir = "";
          this.reportRequestService.SortDisplayField = "";
          this.reportRequestService.SortField = "";
        }
        break;
    }
  }

  sortChange($event): void {
    if (!$event?.active) {
      this.reportRequestService.SortDir = "";
      this.reportRequestService.SortDisplayField = "";
      this.reportRequestService.SortField = "";
    } else {
      this.reportRequestService.SortField = $event?.active || null;
      this.reportRequestService.SortDir = $event?.direction?.toUpperCase() || null;
      const fld = this.configObject.fields.find(x => x.objectKey === this.reportRequestService.SortField);
      if (fld) {
        this.reportRequestService.SortDisplayField = fld.name;
      }
    }
  }
}
