<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" style="padding-bottom:20px">
  <div class="col-sm-9">
    <h3>{{isNewDataGrid?"Add":"Edit"}} News Item</h3>
  </div>
  <div class="col-sm-3">
    <button type="button"
            (click)="preview()"
            class="btn btn-primary">
      Preview
    </button>
    <button type="button"
            (click)="exitDataGridEdit()"
            class="btn btn-primary">
      Exit
    </button>
  </div>
</div>
<ng-container>
  <div class="row select-cell-margin" *ngIf="isNewDataGrid">
    <div class="col-md-12">
      <div class="md-form">
        <mat-form-field appearance="fill" class="select-container w-100">
          <mat-label>Choose the Line of Business for this news item</mat-label>
          <mat-select #selectLinesOfBusiness="matSelect" [(value)]="newsItemObject.lineOfBusinessId">
            <mat-option *ngFor="let option of dataGridLineOfBusinessChoices" [value]="option.value">
              {{option.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!--  -->
        <label for="lineOfBusinessId" [ngClass]="{ 'label-validation-error': objValidationFail['lineOfBusinessId'], 'active':true }">Line Of Business</label>
      </div>
    </div>
  </div>
  <div class="row select-cell-margin" *ngIf="!isNewDataGrid">
    <div class="col-md-12">
      <div class="md-form">
        <input id="lineOfBusiness" type="text" [(ngModel)]="lineOfBusinessName" class="form-control" disabled />
        <label for="lineOfBusiness" class="active">Line Of Business</label>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="md-form">
        <input id="dataGridTitle" type="text" [(ngModel)]="newsItemObject.dialogTitle" class="form-control" placeholder="Dialog Title" />
        <label for="dataGridTitle" [ngClass]="{ 'label-validation-error': objValidationFail['dialogTitle'], 'active':true }">Dialog Title</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="md-form">
        <input id="dataButtonLabel" type="text" [(ngModel)]="newsItemObject.dashboardButtonLabel" class="form-control" placeholder="Dashboard Button" />
        <label for="dataButtonLabel" [ngClass]="{ 'label-validation-error': objValidationFail['dashboardButtonLabel'], 'active':true }">Dashboard Button</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="md-form">
        <input id="dataHeadline" type="text" [(ngModel)]="newsItemObject.title" class="form-control active" placeholder="Headline" />
        <label for="dataHeadline" [ngClass]="{ 'label-validation-error': objValidationFail['title'], 'active':true }">Headline</label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="md-form">
        <mat-form-field appearance="fill" class="w-100" name="startDate">
          <mat-label class="lbl-start-date"></mat-label>
          <input class="input-date" matInput disabled [matDatepicker]="startDatePicker"
            [(ngModel)]="startDateString">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker [disabled]="false">
            <mat-datepicker-actions>
              <button mat-button (click)="startDatePicker.select(actualDate); startDatePicker.close()">
                <mat-icon>history</mat-icon>
                Today
              </button>
              <button mat-button (click)="startDatePicker.select(null); startDatePicker.close()">
                <mat-icon>highlight_off</mat-icon>
                Clear
              </button>
            </mat-datepicker-actions>
          </mat-datepicker>
        </mat-form-field>
        <label for="startDate" [ngClass]="{ 'label-validation-error': objValidationFail['startDate'], 'active':true }">Start Date</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="md-form">
        <mat-form-field appearance="fill" class="w-100" name="expireDate">
          <mat-label class="lbl-start-date"></mat-label>
          <input class="input-date" matInput disabled [matDatepicker]="endDatePicker"
            [(ngModel)]="endDateString">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker [disabled]="false">
            <mat-datepicker-actions>
              <button mat-button (click)="endDatePicker.select(actualDate); endDatePicker.close()">
                <mat-icon>history</mat-icon>
                Today
              </button>
              <button mat-button (click)="endDatePicker.select(null); endDatePicker.close()">
                <mat-icon>highlight_off</mat-icon>
                Clear
              </button>
            </mat-datepicker-actions>
          </mat-datepicker>
        </mat-form-field>
        <label for="expireDate" [ngClass]="{ 'label-validation-error': objValidationFail['endDate'], 'active':true }">Expires</label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <label for="newsText" [ngClass]="{ 'label-validation-error': objValidationFail['newsText'], 'active':true }">News Text</label>
      <angular-editor id="newsText" [config]="editorConfig" [(ngModel)]="newsItemObject.newsText"></angular-editor>
    </div>
  </div>
</ng-container>

<div class="backdrop" [ngStyle]="{'display':display}" *ngIf="showModal"></div>
<div class="modal" tabindex="-1" *ngIf="showModal" [ngStyle]="{'display':display}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">{{newsItemObject.dialogTitle}} (Preview)</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <h4>{{newsItemObject.title}}​</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" [innerHTML]="newsItemObject.newsText | safe">

              </div>
            </div>
          </div>
        </div><div class="row">
          <div class="col-md-12">

            <button type="button" style="float:right"
                    (click)="saveDataGrid()"
                    class="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
