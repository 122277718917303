import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { SafetyModalWindowService } from '../safety-modal-window/safety-modal-window-service.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { BrowserInfoService } from '../../components/browser-info-service/browser-info-service.component';
import { DataGridService } from '../../components/data-grid/data-grid.service';
import { DatePipe } from '@angular/common';
import { Observable, zip, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';

@Component({
  selector: 'cov-signoff-modal',
  templateUrl: './cov-signoff-modal.component.html',
  styleUrls: ['./cov-signoff-modal.component.css']
})
export class CovSignoffModalComponent implements OnInit {
  @Input() modalHeight: string;
  @Input() modalInput: any;
  gtOptions: any = {};
  public tableConfig: any;
  private driverId: string;
  managerReviewInfo: CovSignoffManagerReview;
  managerReviewDetail: Array<CovSignoffManagerReviewDetail>;
  driverQualified: boolean = false;
  driverDisqualified: boolean = false;
  supervisorFirstName: string;
  supervisorLastName: string;
  supervisorFirstNameControl = new UntypedFormControl('', [Validators.required]);
  supervisorLastNameControl = new UntypedFormControl('', [Validators.required]);
  ipAddress: string;

  constructor(private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private onPremService: OnPremDriverService,
    private browserInfoService: BrowserInfoService,
    private driverHistoryProfileService: DriverHistoryProfileService,
    private dataGridService: DataGridService,
    private alertService: AlertService,
    private safetyModalWindowService: SafetyModalWindowService) { }

  ngOnInit(): void {
    this.initAll();
  }

  private initAll() {
    this.driverId = this.modalInput.driverId;
    this.loadingSpinnerService.show();
    this.getManagerReviewAndDetails(this.driverId).subscribe({
      next: (data) => {
        this.managerReviewInfo = data[0] as CovSignoffManagerReview;
        this.managerReviewDetail = data[1] as Array<CovSignoffManagerReviewDetail>;
        this.setTableConfig(this.managerReviewDetail);
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private getManagerReview(driverId: string) {
    return this.onPremService.get<CovSignoffManagerReview>('cov/' + driverId + '/review/latest');
  }

  private getManagerReviewAndDetails(driverId: string) {
    // gets base manager review info first, then details for the COV id
    return this.getManagerReview(driverId).pipe(
      switchMap(res => {
        // combine base and details into one observable

        return zip(
          of(res),
          res && res.certificateOfViolationId ? this.onPremService.get<Array<CovSignoffManagerReviewDetail>>('cov/' + driverId + '/review/' + res.certificateOfViolationId + '/details') : of([])
        );
      })
    );
    /*
  return this.getManagerReview(driverId)
    .switchMap(res => {
      // combine base and details into one observable

      return zip(
        of(res),
        res && res.certificateOfViolationId ? this.onPremService.get<Array<CovSignoffManagerReviewDetail>>('cov/' + driverId + '/review/' + res.certificateOfViolationId + '/details') : of([])
      );
    })
    */
  }

  private setTableConfig(arrDoc: Array<CovSignoffManagerReviewDetail>) {
    let datePipe = new DatePipe("en-US");

    let columns = [
      { name: "Date", prop: "violationDate", title: "Date", dataType: 'date' },
      { name: "Offense", prop: "offense", title: "Offense", dataType: null },
      { name: "Location", prop: "location", title: "Location", dataType: null },
      { name: "Type of Vehicle Operated", prop: "typeOfVehicleOperated", title: "Type of Vehicle Operated", dataType: null },
      { name: "Source", prop: "source", title: "Source", dataType: null },
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: 'enable'
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s?.dataType,
        sort: false,
        render: row => {
          let r = row[s.prop];

          if (s.dataType === "date") {
            try {
              r = datePipe.transform(new Date(r), "MM/dd/yyyy");
            } catch (e) { }
          }

          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.tableConfig = {
      settings: settings,
      fields: fields,
      data: arrDoc
    }

    this.gtOptions = (arrDoc.length > 1 ? { numberOfRows: arrDoc.length } : {});
  }

  toggleDriverQualified() {
    if (this.driverQualified) {
      this.driverDisqualified = false;
    }
  }

  toggleDriverDisqualified() {
    if (this.driverDisqualified) {
      this.driverQualified = false;
    }
  }

  submit() {
    this.loadingSpinnerService.show();

    let postData: CovSignoffPostData = new CovSignoffPostData();
    postData.CertificateOfViolationId = this.managerReviewInfo.certificateOfViolationId;
    postData.DriverProcessAssignedId = this.managerReviewInfo.driverProcessAssignedId;
    postData.SupervisorFirstName = this.supervisorFirstName;
    postData.SupervisorLastName = this.supervisorLastName;
    postData.IsApproved = this.driverQualified;

    this.onPremService.post(`cov/${this.driverId}/review/${this.managerReviewInfo.certificateOfViolationId}/signoff`, JSON.stringify(postData)).subscribe({
      next: (data) => {
        if (this.modalInput.fromDriverProfile) {
          // signal that driver profile data needs to be refreshed
          this.driverHistoryProfileService.setRefreshDriverProfileDriverQualificationsData(this.modalInput.driverId);
          // notify the modal window to close
          this.driverHistoryProfileService.notifyModalClose();
          this.alertService.showSuccessAlert('Certificate of Violation Signoff Completed.', 'end', 'top', 5000);
        } else {
          // refresh grid
          this.dataGridService.setRefreshGrid(true);
          // notify the modal window to close
          this.safetyModalWindowService.notifyModalClose();
          this.alertService.showSuccessAlert('Certificate of Violation Completed.', 'end', 'top', 5000);
        }
      },
      error: (err: HttpErrorResponse) => {
        //this.toastrService.error(err.error.toString());
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  cancel() {
    // notify the modal window to close
    // this modal may show in either a data grid or the driver profile
    this.safetyModalWindowService.notifyModalClose();
    this.driverHistoryProfileService.notifyModalClose();
  }

  testRefresh() {
    // for testing only
    this.dataGridService.setRefreshGrid(true);
    // notify the modal window to close
    this.safetyModalWindowService.notifyModalClose();
    this.alertService.showSuccessAlert('Certificate of Violation Completed.', 'end', 'top', 5000);
  }

}

export interface CovSignoffManagerReview {
  certificateOfViolationId: number;
  driverProcessAssignedId: number;
  driverId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  employeeId: string;
  hireDate: string;
  signoffFirstName: string;
  signoffLastName: string;
  signoffDate: string;
  iPAddress: string;
}

export interface CovSignoffManagerReviewDetail {
  certificateOfViolationDetailId: number;
  certificateOfViolationId: number;
  violationDate: string;
  offense: string;
  location: string;
  typeOfVehicleOperated: string;
  source: string;
}

export class CovSignoffPostData {
  CertificateOfViolationId: number;
  DriverProcessAssignedId: number;
  SupervisorFirstName: string;
  SupervisorLastName: string;
  IPAddress: string;
  IsApproved: boolean;

  constructor() {

  }
}
