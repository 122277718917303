
<div>
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title" id="labelConfirmModal">{{title}}</h4>
      </div>

      <dc-programs-driver-slots-add *ngIf="htmlBody=='dcDriverSlotsAddDriver'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dc-programs-driver-slots-add>
      <dc-driver-assign-program *ngIf="htmlBody=='dcDriverAssignProgram'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dc-driver-assign-program>
      <dc-drivers-on-leave-dialog *ngIf="htmlBody=='dcDriversOnLeaveDialog'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dc-drivers-on-leave-dialog>
      <dc-leave-of-absence *ngIf="htmlBody=='dcLeaveOfAbsence'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dc-leave-of-absence>
      <dc-driver-remove-program *ngIf="htmlBody=='dcRemoveProgram'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dc-driver-remove-program>
      <dc-confirm-action id="dcCancelLeaveOfAbsence" *ngIf="htmlBody=='dcCancelLeaveOfAbsence'" modalType="dcCancelLeaveOfAbsence" [modalHeight]="modalHeight" [modalInput]="modalInput"></dc-confirm-action>
      <dc-confirm-action id="dcEndLeaveOfAbsence" *ngIf="htmlBody=='dcEndLeaveOfAbsence'" modalType="dcEndLeaveOfAbsence" [modalHeight]="modalHeight" [modalInput]="modalInput"></dc-confirm-action>
      <dc-confirm-action id="dcTerminateDriver" *ngIf="htmlBody=='dcTerminateDriver'" modalType="dcTerminateDriver" [modalHeight]="modalHeight" [modalInput]="modalInput"></dc-confirm-action>
      <dc-confirm-action id="dcResetPassword" *ngIf="htmlBody=='dcResetPassword'" modalType="dcResetPassword" [modalHeight]="modalHeight" [modalInput]="modalInput"></dc-confirm-action>
      <dc-driver-time-clock-dialog *ngIf="htmlBody=='dcTimeClockEdit'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dc-driver-time-clock-dialog>

  </div>
</div>
