import { Injectable } from '@angular/core';
import { MvrExportDriverInfo } from '../../safety/mvr-export/mvr-export.component';
import { EmployeeDriverInfo, TrainingCourseData } from '../../safety/safety-employee-driver-form/safety-employee-driver-form';
import { DriverTrainingCourse } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';
import {
  ServiceCertificateOfInsurance,
  ServiceCertificateOfViolation,
  ServiceDriverLicenseUpload,
  ServiceDriverLicenseVerification,
  ServiceDriverQualification,
  ServiceMvrMonitoring,
  ServiceMvrOrder,
  ServiceMvrOrderNonEmployee,
  ServicePolicySignoff,
  ServiceTraining
} from '../../safety/models';

@Injectable()
export class BatchUtilities {
    /**
     * For DHP Next Gen
     * */

  readonly batchSource = 'visibility';
  readonly dateFormat = 'MM/dd/yyyy';

  constructor(private readonly datePipe: DatePipe)
  { }

  createCertificateOfInsuranceService(): ServiceCertificateOfInsurance {
    return new ServiceCertificateOfInsurance();
  }

  createCertificateOfViolationService(): ServiceCertificateOfViolation {
    const service = new ServiceCertificateOfViolation();
    service.processId = 2;
    return service;
  }

  createDriverLicenseUploadService(driverInfo: EmployeeDriverInfo): ServiceDriverLicenseUpload {
    const service = new ServiceDriverLicenseUpload();
    service.dueDate = new Date(this.datePipe.transform(driverInfo.driverLicenseUploadDateDue, this.dateFormat));
    return service
  }

  createDriverLicenseVerificationService(driverInfo: EmployeeDriverInfo): ServiceDriverLicenseVerification {
    const service = new ServiceDriverLicenseVerification();
    service.dueDate = new Date(this.datePipe.transform(driverInfo.confirmLicenseInfoDateDue, this.dateFormat));
    return service
  }

  createDriverQualificationService(driverInfo: EmployeeDriverInfo): ServiceDriverQualification {
    const service = new ServiceDriverQualification();
    service.isCertificateOfRoadTest = driverInfo.dqRequestCertOfRoadTest;
    service.isCertificateOfViolation = driverInfo.dqRequestCOV;
    service.isClearingHouseLimitedSignoff = driverInfo.dqRequestClearingHouseSignoff;
    service.isDriverApplication = driverInfo.dqRequestApplication;
    service.isDriversLicenseUpload = driverInfo.dqRequestLicenseUpload;
    service.isDriversLog = driverInfo.dqRequestDriverLog;
    service.isMedicalCard = driverInfo.dqRequestMedCard;
    service.isRecordOfRoadTest = driverInfo.dqRequestRecordOfRoadTest;
    return service;
  }

  createMvrMonitoringService(): ServiceMvrMonitoring {
    return new ServiceMvrMonitoring();
  }

  createMvrOrderService(): ServiceMvrOrder {
    return new ServiceMvrOrder();
  }

  createMvrOrderNonEmployeeService(): ServiceMvrOrderNonEmployee {
    return new ServiceMvrOrderNonEmployee();
  }

  createPolicySignoffService(driverInfo: EmployeeDriverInfo): ServicePolicySignoff {
    const service = new ServicePolicySignoff();
    service.dueDate = new Date(this.datePipe.transform(driverInfo.policyTaskDueDate, this.dateFormat));
    return service;
  }

  createTrainingService(trainingCourseData: TrainingCourseData, driverInfo: EmployeeDriverInfo): ServiceTraining {
    const service = new ServiceTraining();
    service.trainingAssignmentTypeId = null;
    service.courseIds = trainingCourseData.courseId;
    service.passRates = trainingCourseData.passRate;
    service.dueDate = new Date(this.datePipe.transform(driverInfo.assignOnlineTrainingDateDue, this.dateFormat));
    return service;
  }

  getSelectedTrainingCourseData(selectedTraining: DriverTrainingCourse[]): TrainingCourseData {
    const trainingCourseData = new TrainingCourseData();
    trainingCourseData.courseId = selectedTraining.map(t => t.courseGroupId);
    trainingCourseData.passRate = selectedTraining.map(t => t.passRate);
    return trainingCourseData;
  }

  groupSelectedDriversByEmployeeStatus(driverData: Array<MvrExportDriverInfo>): object {
    return {
      employee: driverData.filter(x => x.employee === 'Yes').map(x => x.driverId),
      nonEmployee: driverData.filter(x => x.employee === 'No').map(x => x.driverId)
    };
  }

}
