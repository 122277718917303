//Angular
import { Component, Input, Output, ViewChild, EventEmitter, Inject } from '@angular/core';
//Third Party
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//APP
import { DriverHistoryProfileService } from "../dhp-landing-page/dhp-landing-page-service.component";
import { DriverHistoryProfileModal } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

@Component({
  selector: 'dhp-modal-window',
  templateUrl: './dhp-modal-window.component.html',
  styleUrls: ['./dhp-modal-window.component.scss'],
})
export class DHPModalWindowComponent {
  driverProfileSubscription: Subscription;
  driverProfileSubscriptionClose: Subscription;
  title: string;
  htmlBody: string;
  modalWidth: string;
  modalHeight: string;
  modalInput: any;

  // constructor
  constructor(private driverHistoryProfileService: DriverHistoryProfileService,
    @Inject(MAT_DIALOG_DATA) public data: {modalInfo: DriverHistoryProfileModal},
    private dialogRef: MatDialogRef<DHPModalWindowComponent>) {

      this.title = data.modalInfo.title;
      this.htmlBody = data.modalInfo.message;
      this.modalWidth = data.modalInfo.modalWidth;
      this.modalHeight = data.modalInfo.modalHeight;
      this.modalInput = data.modalInfo.modalInput; 
    
    // closes the modal using a signal passed to it from the dhp-landing-page service layer
    this.driverProfileSubscriptionClose = driverHistoryProfileService.alertDHPModalClose$.subscribe(
      a => {
        this.closeModal();
      }
    );
  }

  // hides the modal
  closeModal() {
    this.dialogRef.close();
  }
}
