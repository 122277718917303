<div class="row" *ngIf="showMissingDocsPanel">
  <div class="col-sm-12">
    <div class="card card-body mt-3">
      <h4 class="card-title text-muted">Missing Documents</h4>
      <app-loading-panel *ngIf="!tableConfig"></app-loading-panel>
      <div *ngIf="tableConfig">
        <app-data-table 
          [columns]="tableConfig.fields"
          [data]="tableConfig.data"
          [noRecordsLabel]="tableConfig.noRecordsLabel"
        ></app-data-table>
      </div>
    </div>
  </div>
</div>


