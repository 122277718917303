//Angular
import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
//Third Party
import { Subject ,  Observable ,  BehaviorSubject } from 'rxjs';
//App
import { DriverNotes, DriverDocuments, DriverTraining, DriverHPEAssignment, DriverHPECoreCompetency, DriverHPESubLesson, DriverHistoryProfileModal } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DataGridModalService } from '../../components/data-grid-filter-modal/data-grid-filter-modal.service'
import { DcDriver, DcLookupValue, DcLiveRouteTrainingType } from '../dc-classes-and-interfaces/dc-classes-and-interfaces.component';
import { DcModalWindowComponent } from '../dc-modal-window/dc-modal-window.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class DcDriverProfileService {
  baseUrl: string;
  public defaultTabIndex: number;

  // alert for the modal open with data supplied from the ui layer
  public dc = new Subject<DriverHistoryProfileModal>();
  public dcDefaultTabIndex = new BehaviorSubject<number>(0);
  alertDCModalInfo$ = this.dc.asObservable();

  // alert for the modal close
  public dcClose = new Subject<boolean>();
  alertDCModalClose$ = this.dcClose.asObservable();

  // alert for any driver history profile deep copy data changes
  public dcDriverProfileData = new Subject<DcDriver>();
  alertDCDriverProfileData$ = this.dcDriverProfileData.asObservable();

  // alert to landing page that ALL driver profile info should be refreshed
  public dcRefreshDriverProfileAllData = new BehaviorSubject<number>(null);
  dcRefreshDriverProfileAllData$ = this.dcRefreshDriverProfileAllData.asObservable();

  // alert to landing page that BASE driver profile info should be refreshed
  public dcRefreshDriverProfileBaseData = new BehaviorSubject<number>(null);
  dcRefreshDriverProfileBaseData$ = this.dcRefreshDriverProfileBaseData.asObservable();

  // alert to landing page that driver profile NOTES info should be refreshed
  public dcRefreshDriverProfileNotesData = new BehaviorSubject<number>(null);
  dcRefreshDriverProfileNotesData$ = this.dcRefreshDriverProfileNotesData.asObservable();

  // alert to landing page that driver profile DOCUMENTS info should be refreshed
  public dcRefreshDriverProfileDocumentsData = new BehaviorSubject<number>(null);
  dcRefreshDriverProfileDocumentsData$ = this.dcRefreshDriverProfileDocumentsData.asObservable();

  // alert to landing page that driver profile COMPLETED TRAINING info should be refreshed
  public dcRefreshDriverProfileCompletedTrainingData = new BehaviorSubject<number>(null);
  dcRefreshDriverProfileCompletedTrainingData$ = this.dcRefreshDriverProfileCompletedTrainingData.asObservable();

  // alert to landing page that driver profile INCOMPLETE TRAINING info should be refreshed
  public dcRefreshDriverProfileIncompleteTrainingData = new BehaviorSubject<number>(null);
  dcRefreshDriverProfileIncompleteTrainingData$ = this.dcRefreshDriverProfileIncompleteTrainingData.asObservable();

  // alert to landing page that LEAVE OF ABSENCE was set
  public dcRefreshDriverProfileLeaveOfAbsenceData = new BehaviorSubject<number>(null);
  dcRefreshDriverProfileLeaveOfAbsenceData$ = this.dcRefreshDriverProfileLeaveOfAbsenceData.asObservable();

  // alert to driver slots page page that driver data for a program needs to be refreshed
  public dcRefreshDriverSlotsData = new BehaviorSubject<number>(null);
  dcRefreshDriverSlotsData$ = this.dcRefreshDriverSlotsData.asObservable();

  constructor(private router: Router,
    @Inject('BASE_URL') baseUrl: string,
    private http: HttpClient,
    private onPremDcService: OnPremDcService,
    private onPremDriverService: OnPremDriverService,
    private dataGridModalService: DataGridModalService,
    private dialog: MatDialog) {
    this.baseUrl = baseUrl;
    this.http = http;
  }

  /* GETS LIST OF ACTION ITEMS FOR DRIVER*/
  getDriverHistoryProfileActionMenuItems(driverId: string) {
    return this.onPremDcService.get<Array<DcLookupValue>>('lookup/visibility/drivers/' + driverId + '/actions');
  }

  /* RETRIEVES A DEEP COPY OF THE DRIVER HISTORY PROFILE DATA by driver id*/
  getDriverHistoryProfileBaseData(driverId: string) {
    return this.onPremDcService.get<DcDriver>('drivers/' + driverId);
  }

  /* GETS NOTES DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileNotesData(driverId: string) {
    return this.onPremDriverService.get<Array<DriverNotes>>('note/list/' + driverId);
  }

  /* GETS DOCUMENTS DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileDocumentsData(driverId: string) {
    return this.onPremDriverService.get<Array<DriverDocuments>>('file/list/' + driverId);
  }

  /* GETS COMPLETED TRAINING DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileCompletedTrainingData(driverId: string) {
    return this.onPremDriverService.get<Array<DriverTraining>>('training/list/completed/' + driverId);
  }

  /* GETS INCOMPLETE TRAINING DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileIncompleteTrainingData(driverId: string) {
    return this.onPremDriverService.get<Array<DriverTraining>>('training/list/incomplete/' + driverId);
  }

  /* GETS HPE TRAINING DATA FOR DRIVER PROFILE*/
  getDriverHistoryProfileHPETrainingAssignmentsData(driverId: string) {
    return this.onPremDcService.get<Array<DriverHPEAssignment>>('training/hpe/assignments/' + driverId);
  }
  getDriverHistoryProfileHPECoreCompetenciesData(driverId: string, assignmentId: string) {
    return this.onPremDcService.get<Array<DriverHPECoreCompetency>>('training/hpe/coreCompetencies/' + driverId + '/' + assignmentId);
  }
  getDriverHistoryProfileHPESubLessonsData(driverId: string, assignmentId: string) {
    return this.onPremDcService.get<Array<DriverHPESubLesson>>('training/hpe/subLessons/' + driverId + '/' + assignmentId);
  }
  getDriverLiveRouteTraining(driverId: string) {
    return this.onPremDcService.get<Array<DcLiveRouteTrainingType>>('training/liveroute/' + driverId.toString())
  }

  /* NOTIFIES THE OBSERVABLE OF THE DROPDOWN SELECTION, WHICH IN TURN
     NOTIFIES THE MODAL DIALOG TO RESPOND */
  notifyModal(title: string, message: string, modalHeight: string, modalWidth: string, modalInput?:any) {
    let _modalInfo = new DriverHistoryProfileModal();
    _modalInfo.message = message;
    _modalInfo.title = title;
    _modalInfo.modalHeight = modalHeight;
    _modalInfo.modalWidth = modalWidth;
    _modalInfo.modalInput = modalInput;
    this.showDcModalWindow(_modalInfo);
  }

  showDcModalWindow(modalInfo: DriverHistoryProfileModal): void {
    const dialogRef = this.dialog.open(DcModalWindowComponent,{
      data: {modalInfo: modalInfo},
      width: '54em',
      minHeight: '13em',
      panelClass: 'add-document-modal',
      hasBackdrop: false,
      position: {top: '2em'}
    });
  }

  /* UPDATES THE INDEX FOR THE LAST DRIVER HISTORY PROFILE TAB THAT WAS USED
     THIS WAY IT WE CAN RETAIN IT IF WE WANT TO NAVIGATE BACK TO IT AFTER LEAVING */
  updateLastDCTab(lastTabIndex: number) {
    this.defaultTabIndex = lastTabIndex;
  }

  getLastDCTab() {
    return this.defaultTabIndex;
  }

  /* set flag to indicate refresh of ALL Driver Profile data */
  setRefreshDriverProfileAllData(driverId: number) {
    this.dcRefreshDriverProfileAllData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile BASE data */
  setRefreshDriverProfileBaseData(driverId: number) {
    this.dcRefreshDriverProfileBaseData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile NOTES data */
  setRefreshDriverProfileNotesData(driverId: number) {
    this.dcRefreshDriverProfileNotesData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile DOCUMENTS data */
  setRefreshDriverProfileDocumentsData(driverId: number) {
    this.dcRefreshDriverProfileDocumentsData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile COMPLETED TRAINING data */
  setRefreshDriverProfileCompletedTrainingData(driverId: number) {
    this.dcRefreshDriverProfileCompletedTrainingData.next(driverId);
  }

  /* set flag to indicate refresh of Driver Profile INCOMPLETE TRAINING data */
  setRefreshDriverProfileIncompleteTrainingData(driverId: number) {
    this.dcRefreshDriverProfileIncompleteTrainingData.next(driverId);
  }

  /* set flag to indicate LEAVE OF ABSENCE was set */
  setRefreshDriverProfileLeaveOfAbsenceData(driverId: number) {
    this.dcRefreshDriverProfileLeaveOfAbsenceData.next(driverId);
  }

  /* set program id for slots data to be refreshed */
  setRefreshDriverSlotsData(programId: number) {
    this.dcRefreshDriverSlotsData.next(programId);
  }

  /* NOTIFIES THE MODAL TO CLOSE */
  notifyModalClose() {
    this.dcClose.next(true);
  }

  /* NOTIFIES THE DRIVER HISTORY PROFILE COMPONENTS OF A DATA UPDATE */
  notifyDriverHistoryProfileDataUpdate(data: DcDriver) {
    this.dcDriverProfileData.next(data);
  }

  inActiveDriverRedirect(dp: DcDriver, client: string): boolean {
    // handles redirect for terminated or LOA drivers
    // returns true if redirect happens
    let ret: boolean = false;

    let isCurrentLOA = (!dp.isActive) && (dp.leaveOfAbsence.isOnLeaveOfAbsence);
    let isTerminated = (!dp.isActive) && ((!dp.leaveOfAbsence)||(dp.leaveOfAbsence.status.id == 0));
    if (isTerminated) {
      if (dp.employeeId) {
        // redirect to Re-Activate Employee report if terminated employee driver
        //this.router.navigate(['reactivatedrivergrid', dp.employeeId.trim(), '']);
        //alert("go to DC Reactivate Driver");
      } 

      ret = true;
    }

    if (isCurrentLOA) {
      this.router.navigate(['dc/driversonleave']);
      ret = true;
    }

    return ret;
  }


}
