<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" >
  <div class="col-md-12">
    <H3>Document Library</H3>
  </div>
</div>

<div class="row">
  <div class="row col-md-12 left-side-align mb-3">
    <div class="col-md-2">
      <a href="https://get.adobe.com/reader/" target="_blank" rel="noopener noreferrer">
        <img alt="Adobe Reader" src="images/get-adobe-reader.png" />
      </a>
    </div>
    <div class="col-md-10">
      <p>Adobe Acrobat Reader must be installed to be able to view and print the PDF version of these files.</p>
      <p>Please click on the Adobe icon on the left to download Acrobat Reader.</p>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <mat-accordion multi="true">
      <!-- (opened)="panelOpenState = true"
        (closed)="panelOpenState = false" -->
      <mat-expansion-panel *ngFor="let docLevel of documents; let i = index">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{docLevel.name}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- begins -->
        <app-dc-document-library-folder 
          [parentDocCategory]="docLevel"
          [childDocCategory]="docLevel.documents ?? docLevel.childCategories"
          [parentIndex]="i"
          (onToggle)="onChildToggle(i)">
        </app-dc-document-library-folder>
        <!-- ends -->
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
