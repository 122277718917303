//Angular
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
//Third Party
//APP
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-accident-claim-follow-up-date',
  templateUrl: './accident-claim-follow-up-date.component.html',
  styleUrls: ['./accident-claim-follow-up-date.component.scss'],
})
export class AccidentClaimFollowUpDateComponent implements OnInit {
  @Input() claimId: number;
  @Output() onUpsertFollowUpDate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancelUpsertFollowUpDate: EventEmitter<any> = new EventEmitter<any>();
  note: string;
  followUpDate: string;
  emailRequested: boolean = true;
  currentDate: Date = new Date();

  constructor(private readonly _route: ActivatedRoute,
    private readonly accidentClaimService: AccidentClaimService,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly errorService: ErrorModalService
  ) { }


  ngOnInit(): void {

  }

  upsertFollowUpDate(): void {
    if (!this.followUpDate || this.followUpDate === '') {
      this.errorService.setErrorObject({ message: 'Follow-up date is required' });
      return;
    }

    var followUp = new Date(this.followUpDate);
    if (followUp.getTime() > this.currentDate.getTime()) {
      this.loadingSpinnerService.show();

      this.accidentClaimService.upsertFollowUpDate(this.claimId, followUp.toLocaleDateString(), this.note,
        this.emailRequested).subscribe({
          next: (data) => {
            this.loadingSpinnerService.hide();
            this.onUpsertFollowUpDate.emit();
            this.accidentClaimService.setUpdatingFollowUpDate();
          },
          error: (err: HttpErrorResponse) => {
            if (err.error) {
              this.errorService.setErrorObject(err.error);
            }

            this.loadingSpinnerService.hide();
          }
        });
    } else {
      this.errorService.setErrorObject({ message: 'Follow-up date needs to be in the future' });
    }
  }

  cancelUpsertFollowUpDate() {
    this.onCancelUpsertFollowUpDate.emit();
  }
}
