<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
  <div class="row">
    <div class="col-md-12">
      <label>Please type in your note and click the submit button:</label>
      <textarea class="form-control" rows="5" id="comments" [(ngModel)]="note.note" [name]="note.note" (keyup)="onKey($event)"></textarea>
      <div class="switch">
        <label>
          <input [checked]="note.isNotification" (change)="note.isNotification=!note.isNotification" type="checkbox">
          <span class="lever enableWidgetSwitch"></span>
          <span class="enableWidgetLabel">E-Mail this note to Client Representative</span>
        </label>
      </div>

    </div>
  </div>
</div>
<div class="modal-footer">
  <button *ngIf="formValidated" class="btn btn-default"
          (click)="submit()">
    Submit
  </button>
  <button *ngIf="!formValidated" class="btn btn-default"
          (click)="submit()" disabled>
    Submit
  </button>
  <button class="btn btn-default" style="margin-bottom:6px"
          (click)="cancel()">
    Exit
  </button>
</div>
