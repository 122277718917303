//Angular
import { Injectable } from '@angular/core';
//Third Party
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
//App
import { DriverInactive } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { HondaOptOutSearchModalComponent } from './honda-opt-out-search-modal.component';

@Injectable()
export class HondaOptOutSearchModalService {
  private reactivateDriverSearchObs = new Subject();
  reactivateDriverSearchObs$ = this.reactivateDriverSearchObs.asObservable();
  private reactivateDriverSearchResultsObs = new Subject();
  reactivateDriverSearchResultsObs$ = this.reactivateDriverSearchResultsObs.asObservable();

  constructor(
    private onPremDriverService: OnPremDriverService,
    private dialog: MatDialog,
  ) { }

  openReactivateDriverSearchModal() {
    this.reactivateDriverSearchObs.next(true);
    this.openModal();
  }

  closeReactivateDriverSearchModal() {
    this.reactivateDriverSearchObs.next(false);
  }

  setReactivateDriverSearchResults(res: Array<DriverInactive>) {
    this.reactivateDriverSearchResultsObs.next(res);
  }
  getGroups(client: string) {
    return this.onPremDriverService.get("hierarchy/" + client + "/groups");
  }

  private openModal(): void {
    const dialogRef = this.dialog.open(HondaOptOutSearchModalComponent, {
      width: '29em',
      height: '28.7em',
      panelClass: 'add-document-modal',
      hasBackdrop: false,
      position: {top: '2em'}
    })
  }
}
