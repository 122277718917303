<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" style="padding-top: 4rem">
  <div class="col-md-12">
    <mat-card appearance="outlined">
      <mat-tab-group class="tabs-container">
        <mat-tab label="Corporate Structure" *ngIf="arrCH">
          <app-corporate-structure-folder
            [chType]="'CH'"
            [arrCHInfo]="arrCH"
            [clientSelectedArray]="clientSelectedArray"
            [showAddDriverButton]="true"
            (showLoadMoreBtnEvent)="showLoadMoreBtn($event)"
          >
          </app-corporate-structure-folder>
          <div
            class="row"
            *ngIf="arrChild.length > 0 && isLoadMoreShow == true"
          >
            <button class="btn btn-default" (click)="LoadMore('arrCH')">
              Load More ({{ arrChild.length }})
            </button>
          </div>
        </mat-tab>
        <mat-tab label="State/Province" *ngIf="arrCHByState">
          <app-corporate-structure-folder
            [chType]="'CHByState'"
            [arrCHInfo]="arrCHByState"
            [clientSelectedArray]="clientSelectedArray"
            [showAddDriverButton]="false"
          >
          </app-corporate-structure-folder>
        </mat-tab>
        <mat-tab label="Add Group" *ngIf="hasAddGroupPermission && arrCHShowAll">
          <app-corporate-structure-folder
            [chType]="'CHAddGroup'"
            [arrCHInfo]="arrCHShowAll"
            [clientSelectedArray]="clientSelectedArray"
            [showEmptyContainers]="true"
            [showAddDriverButton]="false"
            [showAddGroupButton]="true"
            (showLoadMoreAddGroupBtnEvent)="showLoadMoreaddGroupBtn($event)"
          >
          </app-corporate-structure-folder>
          <div
            class="row"
            *ngIf="
              arrChildAddGroup.length > 0 && isLoadMoreShowAddGroup == true
            "
          >
            <button class="btn btn-default" (click)="LoadMore('arrCHAddGroup')">
              Load More ({{ arrChildAddGroup.length }})
            </button>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>
</div>
