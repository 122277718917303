import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, NavigationEnd, ResolveEnd } from '@angular/router';
import { SearchAdvancedSearchServiceService, GridData, AdvancedSearchRequest } from './search-advanced-search-service.service';

@Injectable({
  providedIn: 'root'
})
export class SafetyAdvancedSearchResolveService  {

  constructor(
    private advancedSearchService: SearchAdvancedSearchServiceService, 
    private router: Router, 
    private searchRequest: AdvancedSearchRequest) {
      this.router.events.subscribe((e: any) => {
        // If it is a NavigationEnd event re-initalise the component
        if (e instanceof NavigationEnd) {
          //this.reloadGridData();
        }
        if (event instanceof ResolveEnd) {
  
        }
      });

     }

  resolve(route: ActivatedRouteSnapshot): Promise<any> | boolean {
    let id = route.params["id"] as string;

    return this.advancedSearchService.getData(this.searchRequest).then(x => {
      if (x) {
        return x;
      } else { // not found
        this.router.navigate(['/']);
        return false;
      }
    });
  }

}
