import { SharedModule } from './../shared.module';
import { Injectable, Inject } from '@angular/core';
import { AzureDocumentDataUpload, AzureDocument, ClientHasDocumentLibrary } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class DocumentLibraryService {
  baseUrl: string;

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private http: HttpClient,
  ) {
    this.baseUrl = baseUrl;
    this.http = http;
   }

  public postDocData(azureDocData: AzureDocumentDataUpload, clientShortName: string, lineOfBusinessId: string): Observable<AzureDocument> {
    return this.http.post<AzureDocument>(this.baseUrl + 'api/documentlibrary/' + clientShortName + '/' + lineOfBusinessId, azureDocData);
  }  

  public showDocument(clientDocumentId: number): Observable<Object> {
    return this.http.put(this.baseUrl + 'api/documentlibrary/' + clientDocumentId + '/show', null);
  }    

  public rollbackDocument(clientDocumentId: number): Observable<Object> {
    return this.http.post(this.baseUrl + 'api/documentlibrary/' + clientDocumentId + '/rollback', null);
  } 

  public updateDocInformation(documentData: AzureDocument): Observable<Object> {
    return this.http.put(this.baseUrl + 'api/documentlibrary/' + documentData.clientDocumentId, documentData);   
  }

  public deleteDocument(documentId: number): Observable<Object> {
    return this.http.delete(this.baseUrl + 'api/documentlibrary/' + documentId);   
  }

  public hideDocument(documentId: number): Observable<Object> {
    return this.http.put(this.baseUrl + 'api/documentlibrary/' + documentId + '/hide', null);   
  }  

  public getDocs(clientShortName: string, lineOfBusinessID: string): Observable<AzureDocument[]> {
    return this.http.get<AzureDocument[]>(this.baseUrl + 'api/documentlibrary/' + clientShortName + '/' + lineOfBusinessID);
  } 

  public clientHasDocumentsCheck(clientShortName: string, lineOfBusinessID: string): Observable<ClientHasDocumentLibrary> {
    return this.http.get<ClientHasDocumentLibrary>(this.baseUrl + 'api/documentlibrary/' + clientShortName + '/' + lineOfBusinessID + '/getDocumentLibraryExists');
  }   

}
