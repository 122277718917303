import { Component, OnInit, Input } from '@angular/core';
import { DhpHierarchy, DriverProfile } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

@Component({
  selector: 'app-safety-move-driver-confirm',
  templateUrl: './safety-move-driver-confirm.component.html',
  styleUrls: ['./safety-move-driver-confirm.component.css']
})
export class SafetyMoveDriverConfirmComponent implements OnInit {
  @Input() selectedDriver: DriverProfile
  @Input() selectedDhp: DhpHierarchy;
  constructor() { }

  ngOnInit(): void {
  }


}
