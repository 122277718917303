<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h4 class="card-title text-muted">Driver Information</h4>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-sm-4 accidentClaimTabLabel">
              Employee:
            </div>
            <div class="col-sm-8 accidentClaimTabBold">
              {{driverProfile?.employeeId ? driverProfile?.employeeId  + ' - ': ''}}{{driverProfile.fullName}}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 accidentClaimTabLabel">
              Email:
            </div>
            <div class="col-sm-8 accidentClaimTabBold">
              <a *ngIf="driverProfile?.emailAddress != ''" href="{{'mailTo:'+driverProfile?.emailAddress}}">
                {{driverProfile?.emailAddress}}
              </a>
              {{driverProfile?.emailAddress == ''? 'Not Available':''}}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 accidentClaimTabLabel">
              Language:
            </div>
            <div class="col-sm-8 accidentClaimTabBold">
              {{driverProfile?.preferredLanguage && driverProfile?.preferredLanguage.description ? driverProfile?.preferredLanguage.description : 'Not Available'}}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 accidentClaimTabLabel">
              Country:
            </div>
            <div class="col-sm-8 accidentClaimTabBold">
              {{driverProfile?.driverLicense && driverProfile?.driverLicense.country ? driverProfile?.driverLicense.country : 'Not Available'}}
            </div>
          </div>
          <div class="row" *ngIf="driverProfile&&driverProfile.leaveOfAbsence&&driverProfile.leaveOfAbsence.isOnLeaveOfAbsence">
            <div class="col-sm-4 accidentClaimTabLabel">
              Driver Status:
            </div>
            <div class="col-sm-8 accidentClaimTabBold" style="color:red">
              On Leave: {{driverProfile.leaveOfAbsence.startDate | date:'MM/dd/yyyy'}} - {{driverProfile.leaveOfAbsence.endDate | date:'MM/dd/yyyy'}}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 accidentClaimTabLabel">
              Medical Certificate Exp Date:
            </div>
            <div class="col-sm-8 accidentClaimTabBold" *ngIf="driverProfile?.medicalCard && driverProfile?.medicalCard.expirationDate">
              {{driverProfile?.medicalCard.expirationDate |  date:'M/d/yyyy'}}
            </div>
            <div class="col-sm-8 accidentClaimTabBold" *ngIf="!(driverProfile?.medicalCard && driverProfile?.medicalCard.expirationDate)">
              Not Available
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 accidentClaimTabLabel">
              Drivers License Exp Date:
            </div>
            <div class="col-sm-8 accidentClaimTabBold" *ngIf="driverProfile?.driverLicense && driverProfile?.driverLicense.expirationDate">
              {{driverProfile?.driverLicense.expirationDate |  date:'M/d/yyyy'}}
            </div>
            <div class="col-sm-8 accidentClaimTabBold" *ngIf="!(driverProfile?.driverLicense && driverProfile?.driverLicense.expirationDate)">
              Not Available
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 accidentClaimTabLabel">
              Created By:
            </div>
            <div class="col-sm-8 accidentClaimTabBold">
              {{driverProfile?.createdBy ? driverProfile?.createdBy : 'Not Available'}}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 accidentClaimTabLabel">
              Created Date:
            </div>
            <div class="col-sm-8 accidentClaimTabBold" *ngIf="driverProfile?.createDate">
              {{driverProfile?.createDate |  date:'M/d/yyyy'}}
            </div>
            <div class="col-sm-8 accidentClaimTabBold" *ngIf="!driverProfile?.createDate">
              Not Available
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-sm-4 accidentClaimTabLabel">
              Owner Email:
            </div>
            <div class="col-sm-8 accidentClaimTabBold">
              {{driverProfile?.ownerEmailAddress ? driverProfile?.ownerEmailAddress:'Not Available'}}
            </div>
          </div>
          <div class="row" *ngFor="let mgr of driverProfile?.managerTrainerEmailAddresses;let i = index">
            <div class="col-sm-4 accidentClaimTabLabel">
              Manager/Trainer Email ({{i + 1}}):
            </div>
            <div class="col-sm-8 accidentClaimTabBold">
              {{mgr}}
            </div>
          </div>
          <div class="row" *ngFor="let training of driverProfile?.liveRouteTraining;let i = index">
              <div class="col-sm-4 accidentClaimTabLabel">
                {{training.description}}:
              </div>
              <div class="col-sm-8 accidentClaimTabBold">
                {{ getLiveRouteTrainingString(training.liveRouteTraining) }}
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
