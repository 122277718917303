//Angular
import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//Third Party
//APP


@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  @Input() title: string;
  @Input() prompt: string;

  constructor(private dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {confirmTitle: string, confirmPrompt: string}) {
      this.title = data.confirmTitle;
      this.prompt = data.confirmPrompt;
    }

  confirmModalYes() {
    this.dialogRef.close(true);
  }

  confirmModalNo() {
    this.dialogRef.close(false);
  }
}
