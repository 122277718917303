import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { Observable } from 'rxjs';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { DatePipe } from '@angular/common';
import { formatDate } from 'ngx-bootstrap/chronos';
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { DataGridEmailService, AbstractReviewGridRequest } from '../../components/data-grid-export-email/data-grid-export-email.service';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { ReportRequest } from '../../components/data-grid/data-grid.service';


@Component({
  selector: 'app-abstract-review-grid',
  templateUrl: './abstract-review-grid.component.html',
  styleUrls: ['./abstract-review-grid.component.scss']
})

export class AbstractReviewGridComponent implements OnInit {
  public configObject: any;
  gtOptions: any = {};
  columns = [];
  rowsRaw: Array<any>;
  rows: Array<any>;
  today: any;
  baseUrl: string;
  lineOfBusinessId: number;
  SortDisplayField: string;
  SortField: string;
  SortDir: string;

  gridId: string;

  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: {}
  };

  constructor(@Inject('BASE_URL') baseUrl: string,
    private readonly router: Router,
    private readonly onPremService: OnPremDriverService,
    private readonly datePipe: DatePipe,
    private readonly loadingSpinnerServie: LoadingSpinnerService,
    private readonly lineOfBusinessService: LineOfBusinessService,
    private readonly errorService: ErrorModalService,
    private readonly fileDownloadService: FileDownloadService,
    private readonly route: ActivatedRoute,
    private readonly _http: HttpClient,
    private readonly emailService: DataGridEmailService) {
    this.baseUrl = baseUrl;
    this.today = Date.now();
  }

  ngOnInit() {
    // set line of business to safety if not already initialized to Safety
    this.lineOfBusinessId = 2;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob !== 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    this.route.paramMap.subscribe(params => {
      this.gridId = params.get("id");

      this.loadingSpinnerServie.show();
      if (this.gridId && this.gridId !== '') {
        this.getGridData(this.gridId).subscribe({
          next: (data) => {

            let rows = (data as any).rows.map(x => {
              return {
                action: (x.Action || "Review"),
                reviewType: (x.ReviewType || "Abstract"),
                abstractReviewId: x.AbstractReviewId,

                requestedDate: x.RequestedDate,
                uploadedDate: x.UploadedDate,

                driverId: x.DriverId,
                client: x.Client,
                employeeId: x.EmployeeId,
                firstName: x.FirstName,
                lastName: x.LastName
              }
            })
            this.setupGrid(rows);
            this.loadingSpinnerServie.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err.error);
            this.loadingSpinnerServie.hide();
          }
        }
        );
      }
      else {
        this.getData().subscribe({
          next: (data) => {
            this.setupGrid(data);
            this.loadingSpinnerServie.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err.error);
            this.loadingSpinnerServie.hide();
          }
        }
        );
      }
    });
  }

  setupGrid(data) {
    this.rows = data;
    this.rowsRaw = data;

    this.columns = [
      { name: "Action", prop: "action", title: "Action", visible: true, sort: '' },
      { name: "Employee Id", prop: "employeeId", title: "Employee Id", visible: true, sort: 'enable' },
      { name: "Client", prop: "client", title: "Client", visible: true, sort: 'enable' },
      { name: "First Name", prop: "firstName", title: "First Name", visible: true, sort: 'enable' },
      { name: "Last Name", prop: "lastName", title: "Last Name", visible: true, sort: 'enable' },
      { name: "Review Type", prop: "reviewType", title: "Review Type", visible: true, sort: 'enable' },
      { name: "Date Requested", prop: "requestedDate", title: "Date Requested", dataType: "date", visible: true, sort: 'enable' },
      { name: "Date Uploaded", prop: "uploadedDate", title: "Date Uploaded", dataType: "date", visible: true, sort: 'enable' }
    ];


    const fields = new Array<any>();
    this.columns.forEach(s => {
      if (s.prop.toLowerCase() === "employeeid") {
        fields.push({
          name: s.title,
          objectKey: s.prop,
          columnClass: 'clickable',
          sort: s?.sort === 'enable',
          click: row => {
            this.router.navigate(["/driver/history-profile/" + row["driverId"]]);
          }
        });
      }
      else if (s.prop.toLowerCase() === "action") {
        fields.push({
          name: s.title,
          objectKey: s.prop,
          columnClass: 'clickable',
          click: row => {
            if (this.gridId) {
              this.router.navigate([`safety/abstractreview/${row["driverId"]}/${row["abstractReviewId"]}/this.gridId`]);
            }
            else {
              this.router.navigate([`safety/abstractreview/${row["driverId"]}/${row["abstractReviewId"]}`]);
            }
          }
        });
      }
      else {
        fields.push({
          name: s.title,
          objectKey: s.prop,
          dataType: s?.dataType,
          sort: s?.sort === 'enable',
          render: row => {
            if (s.dataType === "currency") {
              if (row[s.prop] >= 0)
                return "$" + row[s.prop];
              else
                return "($" + (row[s.prop] * -1) + ")";
            }
            else if (s.dataType === "date" && row[s.prop]) {
              try {
                return formatDate(new Date(row[s.prop]), "MM/DD/YYYY");
              }
              catch (err) {
                return row[s.prop];
              }
            }
            else
              return row[s.prop]
          }
        });
      }
    });

    const settings = this.columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: s.sort
      };
    }).filter(x => x.objectKey.toLowerCase() !== "driverid");

    this.configObject = {
      settings: settings,
      fields: fields,
      data: data
    };
  }

  getData() {
    this.resetSort();

    return this.onPremService.get("abstractreview");
  }

  getGridData(id) {
    try {
      let request = JSON.parse(sessionStorage.getItem('grid_' + id)) as ReportRequest;

      return this._http.post(this.baseUrl + 'api/Datagrid/GridData',
        JSON.stringify(request),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
    }
    catch (err) {
      console.log(err);
      return new Observable<null>();
    }
  }

  onPrint() {
    let sortParams = "";

    if (this.SortDisplayField && this.SortDisplayField.length > 0) {
      sortParams = "&sort=" + this.SortField + "&sortDir=" + this.SortDir;
    }

    this.fileDownloadService.openTab(this.baseUrl + "api/Safety/AbstractReviewGridPrint?" + sortParams);
  }


  onExcel() {
    let sortParams = "";

    if (this.SortDisplayField && this.SortDisplayField.length > 0) {
      sortParams = "&sort=" + this.SortDisplayField + "&sortDir=" + this.SortDir;
    }

    this.fileDownloadService
      .saveFile(this.baseUrl + "api/Safety/AbstractReviewGridExcel?" + sortParams);
  }

  onEmail() {
    let request = new AbstractReviewGridRequest();
    request.SortDisplayField = this.SortDisplayField;
    request.SortDir = this.SortDir;
    request.SortField = this.SortField;

    this.emailService.OpenEmailPopup(request);
  }

  resetSort() {
    this.SortDir = "";
    this.SortField = "";
    this.SortDisplayField = "";
  }

  sortChange($event): void {
    if (!$event?.active) {
      this.resetSort();
    } else {
      this.SortField = $event?.active || null;
      this.SortDir = $event?.direction?.toUpperCase() || null;
      const fld = this.configObject.fields.find(x => x.objectKey === this.SortField);
      if (fld) {
        this.SortDisplayField = fld.name;
      }
    }
  }

  trigger($event) {
    if ($event.name === 'gt-sorting-applied') {
      if ($event.value && $event.value[0] !== '$$gtInitialRowIndex') {
        if (($event.value[0] as string).startsWith('-')) {
          this.SortField = ($event.value[0] as string).slice(1);
          this.SortDir = "DESC"
        }
        else {
          this.SortField = $event.value[0]
          this.SortDir = "ASC"
        }

        const fld = this.configObject.fields.find(x => x.objectKey = this.SortField)
        if (fld) {
          this.SortDisplayField = fld.name;
        }
      }
      else {
        this.resetSort();
      }
    }
  }
}

