//Angular
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
//Third Party
import { Subscription } from "rxjs";
//APP
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { AccidentClaimAtFaultPartyInfo, AccidentClaimAtFaultOutsideSubroInfo } from "../../components/classes-and-interfaces/classes-and-interfaces.component";

@Component({
  selector: 'app-accident-claim-at-fault',
  templateUrl: './accident-claim-at-fault.component.html',
  styleUrls: ['./accident-claim-at-fault.component.scss']
})
export class AccidentClaimAtFaultPartyComponent implements OnInit, OnDestroy {
  _claimId: number;
  //afInfo: AccidentClaimAtFaultPartyInfo;
  arrAfInfo: Array<AccidentClaimAtFaultPartyInfo>;
  _routeSub: Subscription;

  constructor(private _route: ActivatedRoute,
    private accidentClaimService: AccidentClaimService
  ) { }

  printPage(): void {
    window.print();
  }


  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];
      this.accidentClaimService.getAtFaultPartyInfo(this._claimId).subscribe({
        next: (data) => {
          this.arrAfInfo = data as Array<AccidentClaimAtFaultPartyInfo>;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });
    });
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
  }

}
