
<div>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">×</span>
      </button>
      <h4 class="modal-title" id="labelConfirmModal">{{title}}</h4>
    </div>
    
    <dhp-order-mvr *ngIf="htmlBody=='requestMVRReport'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-order-mvr>
    <dhp-assign-points *ngIf="htmlBody=='assignPoints'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-assign-points>
    <dhp-add-notes *ngIf="htmlBody=='addNotes'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-add-notes>
    <dhp-leave-of-absence *ngIf="htmlBody=='dhpLeaveOfAbsence'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-leave-of-absence>
    <dhp-view-email *ngIf="htmlBody=='viewEmail'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-view-email>
    <dhp-my-followup *ngIf="htmlBody=='assignFollowUpDate'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-my-followup>
    <dhp-edit-training-due-date *ngIf="htmlBody=='editTrainingDueDate'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-edit-training-due-date>
    <dhp-edit-training-due-date *ngIf="htmlBody=='editHPEAssignmentDueDate'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-edit-training-due-date>
    <dhp-telematics-detail *ngIf="htmlBody=='telematicsDetail'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-telematics-detail>
    <cov-signoff-modal *ngIf="htmlBody=='covSignoff'" [modalHeight]="modalHeight" [modalInput]="modalInput"></cov-signoff-modal>
    <dhp-contest-mvr *ngIf="htmlBody=='contestMVR'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-contest-mvr>
    <dhp-verify-mvr *ngIf="htmlBody=='verifyMVR'" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-verify-mvr>
    <dhp-confirm-action id="resetPassword" *ngIf="htmlBody=='resetPassword'" modalType="resetPassword" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-confirm-action>
    <dhp-confirm-action id="terminateDriver" *ngIf="htmlBody=='terminateDriver'" modalType="terminateDriver" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-confirm-action>
    <dhp-confirm-action id="resetAccount" *ngIf="htmlBody=='resetAccount'" modalType="resetAccount" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-confirm-action>
    <dhp-confirm-action id="resendESignEmail" *ngIf="htmlBody=='resendESignEmail'" modalType="resendESignEmail" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-confirm-action>
    <dhp-confirm-action id="cancelLeaveOfAbsence" *ngIf="htmlBody=='cancelLeaveOfAbsence'" modalType="cancelLeaveOfAbsence" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-confirm-action>
    <dhp-confirm-action id="endLeaveOfAbsence" *ngIf="htmlBody=='endLeaveOfAbsence'" modalType="endLeaveOfAbsence" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-confirm-action>
    <dhp-confirm-action id="removeFollowUpDate" *ngIf="htmlBody=='removeFollowUpDate'" modalType="removeFollowUpDate" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-confirm-action>
    <dhp-confirm-action id="resendLogin" *ngIf="htmlBody=='resendLogin'" modalType="resendLogin" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-confirm-action>
    <dhp-confirm-action id="removeDocument" *ngIf="htmlBody=='removeDocument'" modalType="removeDocument" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-confirm-action>
    <dhp-confirm-action id="verifyDocument" *ngIf="htmlBody=='verifyDocument'" modalType="verifyDocument" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-confirm-action>
    <dhp-confirm-action id="mvrMonitoringEnroll" *ngIf="htmlBody=='mvrMonitoringEnroll'" modalType="mvrMonitoringEnroll" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-confirm-action>
    <dhp-confirm-action id="mvrMonitoringUnenroll" *ngIf="htmlBody=='mvrMonitoringUnenroll'" modalType="mvrMonitoringUnenroll" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-confirm-action>
    <dhp-confirm-action id="cancelLicenseVerification" *ngIf="htmlBody=='cancelLicenseVerification'" modalType="cancelLicenseVerification" [modalHeight]="modalHeight" [modalInput]="modalInput"></dhp-confirm-action>
  </div>
</div>

