<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" style="padding-bottom:20px">
  <div class="col-md-9">
    <h3>{{isNewWidget?"Add":"Edit"}} Chart</h3>
  </div>
  <div class="col-md-3">
    <button type="button" (click)="saveWidget()" [disabled]="!canSaveWidget" class="btn btn-primary">
      Submit
    </button>
    <button type="button" (click)="exitWidgetEdit()" class="btn btn-primary">
      Exit
    </button>
  </div>
</div>
<div *ngIf="widgetNotFound" class="row">
  <div class="col-md-12">Chart with id {{widgetId}} not found</div>
</div>
<div *ngIf="widgetLoading" class="row">
  <div class="col-md-12">Loading...</div>
</div>
<ng-container *ngIf="!(widgetNotFound||widgetLoading)">
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <div class="md-form">
            <input id="widgetTitle" type="text" [(ngModel)]="widgetObject.title" class="form-control"
              [formControl]="titleControl" placeholder="Display Name" />
            <label for="widgetTitle"
              [ngClass]="{ 'label-validation-error': titleControl.invalid, 'active':true }">Display Name</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="md-form">
            <input id="widgetDesc" type="text" [(ngModel)]="widgetObject.description" class="form-control"
              [formControl]="descriptionControl" placeholder="Description (Displayed to user)" />
            <label for="widgetDesc"
              [ngClass]="{ 'label-validation-error': descriptionControl.invalid, 'active':true }">Description (Displayed
              to user)</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <span class="widgetLabelBold">Globally Enable/Disable</span>
        </div>
        <div class="col-md-8">
          <span class="widgetLabelSmaller">Make this chart available for use by all clients.</span>
        </div>
      </div>
      <div class="row select-cell-margin">
        <div class="col-md-2">
          <div class="switch">
            <label>
              <input [checked]="widgetObject.isActive" (change)="widgetObject.isActive=!widgetObject.isActive"
                type="checkbox">
              <span class="lever enableWidgetSwitch"></span>
              <span class="enableWidgetLabel">{{widgetObject.isActive?"Enabled":"Disabled"}}</span>
            </label>
          </div>
        </div>
        <div class="col-md-10">
          <a href="javascript:void(0);" (click)="openWidgetPerClientSettings()">Manage per-client settings</a>
        </div>
      </div>


      <!--Default Chart Enablement-->
      <div class="row">
        <div class="col-sm-2">
          <div class="switch">
            <label>
              <input [checked]="widgetObject.isDefault" (change)="widgetObject.isDefault=!widgetObject.isDefault"
                type="checkbox">
              <span class="lever enableWidgetSwitch"></span>
              <span class="enableWidgetLabel">{{widgetObject.isDefault?"Enabled":"Disabled"}}</span>
            </label>
          </div>
        </div>
        <div class="col-sm-10">
          <div class="row">
            <div class="col-sm-12">
              <span class="widgetLabelBold">Show for new client</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <span class="widgetLabelSmaller">Show for new client.</span>
            </div>
          </div>
        </div>
      </div>


      <div class="row select-cell-margin" *ngIf="widgetLineOfBusinessChoicesLoading">
        <div class="col-md-12">
          <h3>Loading Line Of Business Info...</h3>
        </div>
      </div>
      <div class="row select-cell-margin" *ngIf="!widgetLineOfBusinessChoicesLoading">
        <div class="col-md-12">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Choose the Line of Business for this chart</mat-label>
            <mat-select [(ngModel)]="widgetObject.lineOfBusinessIdString" [formControl]="lineOfBusinessIdControl">
              <mat-option *ngFor="let option of widgetLineOfBusinessChoices" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <label for="lineOfBusinessIdString" [ngClass]="{ 'label-validation-error': lineOfBusinessIdControl.invalid, 'active':true }">Line Of
            Business</label>
        </div>
      </div>
      <div class="row select-cell-margin" *ngIf="false">
        <div class="col-md-12">
          <h3>Loading Report Info...</h3>
        </div>
      </div>
      <div class="row select-cell-margin" *ngIf="false">
        <div class="col-md-12">

          <mat-form-field appearance="fill" class="select-container w-100">
            <mat-label>Choose the PowerBI Report linked to this chart</mat-label>
            <mat-select [(ngModel)]="widgetObject.reportGuid"
              (selectionChange)="previewReport(widgetObject.reportGuid, true)">
              <mat-option *ngFor="let option of widgetReportChoices" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <label for="reportGuid">Related PowerBI Report</label>
        </div>
      </div>
      <div class="row select-cell-margin" *ngIf="widgetDataGridChoicesLoading">
        <div class="col-md-12">
          <h3>Loading Drilldown Report Info...</h3>
        </div>
      </div>
      <div class="row select-cell-margin" *ngIf="!widgetDataGridChoicesLoading">
        <div
          [ngClass]="{'col-md-10': showEditDataGrid(widgetObject.dataGridIdString), 'col-md-12': !showEditDataGrid(widgetObject.dataGridIdString)}">

          <mat-form-field appearance="fill" class="select-container w-100">
            <mat-label>Choose the drilldown report linked to this chart</mat-label>
            <mat-select #selectReports="matSelect" [(ngModel)]="widgetObject.dataGridIdString"
              [formControl]="dataGridIdControl">
              <input matInput class="select-search-input" placeholder="Search Reports..."
                (keyup)="filterOptions($event.target.value)">
              <mat-option *ngFor="let option of widgetDataGridChoices" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <label for="dataGridIdString" [ngClass]="{ 'label-validation-error': dataGridIdControl.invalid, 'active':true }">Related Drilldown
            Report</label>
        </div>
        <div class="col-md-2" *ngIf="showEditDataGrid(widgetObject.dataGridIdString)">
          <a href="javascript:void(0);" (click)="editDataGrid(widgetObject.dataGridIdString)">
            Edit Report
          </a>
        </div>
      </div>
      <div class="row select-cell-margin">
        <div class="col-md-12">
          <div class="md-form">
            <input id="widgetStoredProc" type="text" [(ngModel)]="widgetObject.jsChartDataStoredProc"
              class="form-control" [formControl]="storedProcControl" placeholder="Stored Procedure for Chart" />
            <label for="widgetStoredProc"
              [ngClass]="{ 'label-validation-error': storedProcControl.invalid, 'active':true }">Stored Procedure for
              Chart</label>
          </div>
        </div>
      </div>
      <div class="row select-cell-margin">
        <div class="col-md-12">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Choose the Chart Type</mat-label>
            <mat-select [(ngModel)]="widgetObject.jsChartTypeIdString" [formControl]="chartTypeControl">
              <mat-option *ngFor="let option of widgetChartTypeChoices" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <label for="jsChartTypeIdString" [ngClass]="{ 'label-validation-error': chartTypeControl.invalid, 'active':true }">Chart Type</label>
        </div>
      </div>
      <div class="row select-cell-margin">
        <div class="col-md-12">
          <label for="jsChartTileConfig" [ngClass]="{ 'label-validation-error': chartConfigControl.invalid, 'active':true }"
            style="font-size:0.8rem">Chart Tile Configuration:</label>
          <textarea class="form-control" rows="5" id="widgetChartConfig" [(ngModel)]="widgetObject.jsChartTileConfig"
            [name]="widgetObject.jsChartTileConfig" [formControl]="chartConfigControl">
          </textarea>
        </div>
      </div>
      <div class="row select-cell-margin" *ngIf="false">
        <div class="col-md-12">
          <h3>Loading Visualization Info...</h3>
        </div>
      </div>
      <div class="row select-cell-margin" *ngIf="false">
        <div class="col-md-12">
          <mat-form-field appearance="fill" class="select-container w-100">
            <mat-label>Choose the visualization to use on the Dashboard tile</mat-label>
            <mat-select (value)]="widgetObject.visualizationId"
              (selectionChange)="previewVisualization(widgetObject.reportGuid, widgetObject.visualizationId, true)">
              <mat-option *ngFor="let option of widgetVisualizationChoices" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <label for="visualizationId">Visualization</label>
        </div>
      </div>

      <div class="row select-cell-margin" *ngIf="widgetDateFilterTypeChoicesLoading">
        <div class="col-md-12">
          <h3>Loading Date Filter Info...</h3>
        </div>
      </div>
      <div class="row select-cell-margin" *ngIf="!widgetDateFilterTypeChoicesLoading">
        <div class="col-md-12">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Choose the type of tile date filter</mat-label>
            <mat-select [(ngModel)]="widgetObject.tileDateFilterTypeIdString" [formControl]="dateFilterTypeControl">
              <mat-option *ngFor="let option of widgetTileDateFilterTypeChoices" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <label for="tileDateFilterTypeIdString" [ngClass]="{ 'label-validation-error': dateFilterTypeControl.invalid, 'active':true }">Tile Date Filter
            Type</label>
        </div>
      </div>
      <div class="row" *ngIf="arrCategoryChoices&&(arrCategoryChoices.length > 0)">
        <div class="col-md-12 select-cell-margin">
          <label for="checkb" [ngClass]="{ 'active':true }">Tile Categories</label>
          <ul class="searchByCat" *ngIf="arrCategoryChoices">
            <li *ngFor="let cat of arrCategoryChoices">
              <input type="checkbox" class="input-dark" name="checkb" id="checkb-{{cat.categoryId}}"
                value="{{cat.categoryId}}" [(ngModel)]="cat.selected" />
              <label class="label-base" for="checkb-{{cat.categoryId}}">{{cat.categoryName}}</label>
            </li>
          </ul>

        </div>
      </div>
      <div class="row">
        <div class="col-md-6 select-cell-margin">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Choose the tile height</mat-label>
            <mat-select [(ngModel)]="widgetObject.tileHeightString" [formControl]="tileHeightControl">
              <mat-option *ngFor="let option of widgetTileHeightChoices" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <label for="tileHeightString" [ngClass]="{ 'label-validation-error': tileHeightControl.invalid, 'active':true }">Tile Height</label>
        </div>
        <div class="col-md-6 select-cell-margin">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Choose the tile width</mat-label>
            <mat-select [(ngModel)]="widgetObject.tileWidthString" [formControl]="tileWidthControl">
              <mat-option *ngFor="let option of widgetTileWidthChoices" [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <label for="tileWidthString" [ngClass]="{ 'label-validation-error': tileWidthControl.invalid, 'active':true }">Tile Width</label>
        </div>
      </div>
      <div class="row select-cell-margin">
        <div class="col-md-4">
          <span class="widgetLabelBold">Is On Prem</span>
        </div>
        <div class="col-md-8">
          <div class="switch">
            <label>
              <input [checked]="widgetObject.isOnPrem" (change)="isOnPremChange(!widgetObject.isOnPrem)"
                type="checkbox">
              <span class="lever enableWidgetSwitch"></span>
              <span class="enableWidgetLabel">{{widgetObject.isOnPrem?"Yes":"No"}}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row select-cell-margin">
        <div class="col-md-12">
          <div class="md-form">

            <input id="widgetOnPremApiUrl" type="text" [(ngModel)]="widgetObject.onPremApiUrl" class="form-control"
              [formControl]="onPremApiUrlControl" placeholder="On Prem API Url" />
            <label for="widgetOnPremApiUrl" [ngClass]="{ 'active':true }">On Prem API Url</label>
          </div>
        </div>
      </div>

      <div class="row select-cell-margin">
        <div class="col-md-4">
          <span class="widgetLabelBold">Show Additional Filters</span>
        </div>
        <div class="col-md-8">
          <div class="switch">
            <label>
              <input [checked]="widgetObject.hasSlicer" (change)="hasSlicerChange(!widgetObject.hasSlicer)"
                type="checkbox">
              <span class="lever enableWidgetSwitch"></span>
              <span class="enableWidgetLabel">{{widgetObject.hasSlicer?"Yes":"No"}}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="widgetObject.hasSlicer">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <div class="md-form">
                <input id="widgetAdditionalFilterLabel" type="text" [(ngModel)]="widgetObject.slicerDisplayName"
                  class="form-control" [formControl]="slicerDisplayNameControl" placeholder="Filter Label" />
                <label for="widgetAdditionalFilterLabel"
                  [ngClass]="{ 'label-validation-error': slicerDisplayNameControl.invalid, 'active':true }">Filter
                  Label</label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="md-form">
                <input id="widgetAdditionalFilterFieldName" type="text" [(ngModel)]="widgetObject.slicerPbixFieldName"
                  class="form-control" slicerDisplayName [formControl]="slicerPbixFieldNameControl"
                  placeholder="Filter Column" />
                <label for="widgetAdditionalFilterFieldName"
                  [ngClass]="{ 'label-validation-error': slicerPbixFieldNameControl.invalid, 'active':true }">Filter
                  Column</label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="md-form">
                <input id="widgetAdditionalFilterTableName" type="text" [(ngModel)]="widgetObject.slicerPbixTableName"
                  class="form-control" [formControl]="slicerPbixTableNameControl" placeholder="Filter Table" />
                <label for="widgetAdditionalFilterTableName"
                  [ngClass]="{ 'label-validation-error': slicerPbixTableNameControl.invalid, 'active':true }">Filter
                  Table</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="md-form">
                <input id="widgetAdditionalFilterOptions" type="text" [(ngModel)]="widgetObject.slicerOptions"
                  class="form-control" [formControl]="slicerOptionsControl" placeholder="Options (comma separated)" />
                <label for="widgetAdditionalFilterFieldOptions"
                  [ngClass]="{ 'label-validation-error': slicerOptionsControl.invalid, 'active':true }">Options (comma
                  separated)</label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="md-form">
                <input id="widgetAdditionalFilterDefault" type="text" [(ngModel)]="widgetObject.slicerDefaultValue"
                  class="form-control" [formControl]="slicerDefaultValueControl" placeholder="Default Option" />
                <label for="widgetAdditionalFilterDefault"
                  [ngClass]="{ 'label-validation-error': slicerDefaultValueControl.invalid, 'active':true }">Default
                  Option</label>
              </div>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Choose the operator</mat-label>
                <mat-select [(value)]="widgetObject.slicerOperator" [formControl]="slicerOperatorControl">
                  <mat-option *ngFor="let option of widgetSlicerOperatorChoices" [value]="option.value">
                    {{option.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <label for="slicerOperator"
                [ngClass]="{ 'label-validation-error': slicerOperatorControl.invalid, 'active':true }">Operator</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 widgetPreviewPanel" *ngIf="true">
      <div class="row" style="padding-top:10px" *ngIf="widgetObject.jsChartTypeIdString == 1">
        <div class="col-md-12">
          <h4>Chart Preview</h4>
        </div>
      </div>
      <div class="row" *ngIf="widgetObject.jsChartTypeIdString == 1">
        <div class="col-md-12 widgetPowerBIPreviewContainer">
          <div class="row">
            <div class="col-sm-12" style="padding-top:1rem">
              Date Range:
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label class="lbl-start-date">From Date</mat-label>
                  <input class="input-date" matInput disabled [matDatepicker]="startDatePicker"
                    [(ngModel)]="filterStartDate">
                  <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #startDatePicker [disabled]="manuallyEnteredDisabled">
                    <mat-datepicker-actions>
                      <button mat-button (click)="startDatePicker.select(actualDate); startDatePicker.close()">
                        <mat-icon>history</mat-icon>
                        Today
                      </button>
                      <button mat-button (click)="startDatePicker.select(null); startDatePicker.close()">
                        <mat-icon>highlight_off</mat-icon>
                        Clear
                      </button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label *ngIf="!endDate">Through Date</mat-label>
                  <input class="input-date" [(ngModel)]="endDate" matInput disabled [matDatepicker]="endDatePicker" [(ngModel)]="endDate">
                  <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #endDatePicker disabled="false">
                    <mat-datepicker-actions>
                      <button mat-button (click)="endDatePicker.select(actualDate); endDatePicker.close()">
                        <mat-icon>history</mat-icon>
                        Today
                      </button>
                      <button mat-button (click)="endDatePicker.select(null); endDatePicker.close()">
                        <mat-icon>highlight_off</mat-icon>
                        Clear
                      </button>
                    </mat-datepicker-actions> 
                  </mat-datepicker>
                </mat-form-field>

            </div>
          </div>
          <app-chart-v2 *ngIf="widgetObject.jsChartTypeIdString == 1" [IsAdminPreview]="true"
            [ChartOptionsString]="widgetObject.jsChartTileConfig"
            [LineOfBusinessIdString]="widgetObject.lineOfBusinessIdString" [filterStartDate]="filterStartDate"
            [filterEndDate]="filterEndDate" [ChartDS]="widgetObject.jsChartDataStoredProc"></app-chart-v2>
        </div>
      </div>
    </div>
  </div>
</ng-container>