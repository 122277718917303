//Angular
import { Component, Input, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
//Third Party
import { Subscription, Observable, forkJoin } from 'rxjs';
//App
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { CorporateStructure } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { UserRightsService } from '../../components/user-rights-service/user-rights-service.component';
import { CorporateStructureAddGroupModalService } from '../corporate-structure-add-group-modal/corporate-structure-add-group-modal.service';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { CustomReuseStrategy } from '../../components/routing/custom-reuse-strategy.component';
import { CorporateStructureAddGroupModalComponent } from '../corporate-structure-add-group-modal/corporate-structure-add-group-modal.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-corporate-structure',
  templateUrl: './corporate-structure.component.html',
  styleUrls: ['./corporate-structure.component.scss']
})
export class CorporateStructureComponent implements OnInit, OnDestroy {
  @ViewChild(CorporateStructureAddGroupModalComponent) csAddModalComp: CorporateStructureAddGroupModalComponent;
  clientSelectedArray: Array<string> = [];
  arrCH: Array<CorporateStructure>;
  arrCHShowAll: Array<CorporateStructure>;
  arrCHByState: Array<CorporateStructure>;
  groupAddedSubscription: Subscription;
  //routeEventsSub: Subscription;
  baseUrl: string;
  hasAddGroupPermission: boolean = false;
  @Input() Lob: number;

  isLoadMoreShow: boolean = false;
  isLoadMoreShowAddGroup: boolean = false;
  //default page size
  pageSize: number = 100;
  arrChild: Array<CorporateStructure> = [];
  arrChildAddGroup: Array<CorporateStructure> = [];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private clientSelectionService: ClientSelectionService,
    private lineOfBusinessService: LineOfBusinessService,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private onPremService: OnPremDriverService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private userRightsService: UserRightsService,
    private corporateStructureAddGroupService: CorporateStructureAddGroupModalService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;

    this.groupAddedSubscription = this.corporateStructureAddGroupService._corporateStructureFolderAdded$.subscribe(() => {
      this.initCHInfo();
    });
  }
  getPermissions() {
    this.userRightsService.getUserRights(this.Lob, this.clientSelectedArray).subscribe(data => {
      (data as [any]).forEach(x => {
        if (x.userRightId == 2062 && x.permission === "allow") {
          this.hasAddGroupPermission = true;
        }
      });
    });
  }
  ngOnInit() {
    this.route.data.subscribe((data: any) => {
      this.Lob = data.Lob;
      // set line of business to safety and client choice to
      // safety choice, if not already initialized to Safety or DC
      let lob = this.lineOfBusinessService.getLineOfBusinessValue();
      if (lob != 2 && lob != 7 && this.Lob) {
        this.lineOfBusinessService.setLineOfBusiness(this.Lob);
        lob = this.Lob;
      }
      let cArr = this.clientSelectionService.getSavedClientShortNames(lob);
      if (cArr && (cArr.length > 0)) {
        this.clientSelectedArray = cArr;
        this.getPermissions();
        this.initCHInfo();
      } else {
        this.arrCH = null;
        this.arrCHByState = null;
      }
    });
  }

  private initCHInfo() {

    let arrObs: Array<Observable<Object>> = [
      this.onPremService.get('hierarchy/structure/' + this.clientSelectedArray.join(',') + '/true'),
      this.onPremService.get('hierarchy/stateStructure/' + this.clientSelectedArray.join(','))

    ];
    this.loadingSpinnerService.show();

    forkJoin(arrObs).subscribe({
      next: (data) => {

        //arrCHShowAll
        this.arrCHShowAll = Object.assign([], JSON.parse(JSON.stringify(data[0] as Array<CorporateStructure>)));
        this.setArrChildAddGroup(this.arrCHShowAll[0].childElements);

        //arrCH
        this.arrCH = Object.assign([], JSON.parse(JSON.stringify(data[0] as Array<CorporateStructure>)));
        this.setArrChild(this.arrCH[0].childElements);

        this.arrCHByState = data[1] as Array<CorporateStructure>;
        this.loadingSpinnerService.hide();
        // tree collapses after data reload so we need to hide the btns as well
        this.hideLoadMoreBtns();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private hideLoadMoreBtns() {
    this.isLoadMoreShow = false;
    this.isLoadMoreShowAddGroup = false;
  }

  private arrayCompare(a1, a2) {
    return (a1.length == a2.length && a1.every((v, i) => v === a2[i]));
  }

  private unsubscribeAll() {
    //this.routeEventsSub.unsubscribe();
    this.groupAddedSubscription.unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }


  public LoadMore(onTab: string) {
    if (onTab === 'arrCH') {
      this.setPagingArrchild(this.arrChild);
    }
    else {
      this.setPagingArrChildAddGroup(this.arrChildAddGroup)
    }
  }

  private setArrChild(arrChild: Array<CorporateStructure>) {
    this.arrCH[0].childElements = [];
    this.setPagingArrchild(arrChild);
  }

  private setPagingArrchild(arrChild: Array<CorporateStructure>) {
    this.arrChild = Object.assign(arrChild);
    let spliceArrChild = arrChild;
    spliceArrChild = spliceArrChild.splice(0, this.pageSize);

    spliceArrChild.forEach(child => {
      this.arrCH[0].childElements.push(child);
    });
    this.arrCH = this.arrCH.slice();
  }

  private setArrChildAddGroup(arrChildAddGroup: Array<CorporateStructure>) {
    this.arrCHShowAll[0].childElements = [];
    this.setPagingArrChildAddGroup(arrChildAddGroup);
  }

  private setPagingArrChildAddGroup(arrChildAddGroup: Array<CorporateStructure>) {

    this.arrChildAddGroup = Object.assign(arrChildAddGroup);
    let spliceArrChildAddGroup = arrChildAddGroup;
    spliceArrChildAddGroup = spliceArrChildAddGroup.splice(0, this.pageSize);

    spliceArrChildAddGroup.forEach(child => {
      this.arrCHShowAll[0].childElements.push(child);
    });
    this.arrCHShowAll = this.arrCHShowAll.slice();
  }

  public showLoadMoreBtn(showButton: boolean) {
    this.isLoadMoreShow = showButton;

  }
  public showLoadMoreaddGroupBtn(showButton: boolean) {
    this.isLoadMoreShowAddGroup = showButton;
  }

}
