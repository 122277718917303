<a class="collapsible-header waves-effect" [routerLink]="['../../list']">
  Back to List
</a>
<div class="row" style="padding-top:2rem;padding-bottom:20px">
    <div class="col-12">
      <h3>Move Driver</h3>
    </div>
</div>
<div class="row" *ngIf="isLocatingDriver === false && !selectedDriver">
  <div class="col-12">
    <div class="alert alert-danger"  role="alert">Cant access driver!</div>
  </div>
</div>

<div class="row" *ngIf="isLocatingDriver ">
  <div class="col-12">
    <app-loading-panel ></app-loading-panel>
  </div>
</div>


<div *ngIf="selectedDriver">
  <div class="row" style="margin-bottom:20px" >
    <div class="col-12">
        <div class="card ">
            <div class="card-header card-panel-header">
                Driver Details
            </div>
            <div class="card-body min-height-300">
                <div class="row mb-2">
                  <div class="col-12 col-md-2">
                    Full Name
                  </div>
                  <div class="col-12 col-md-4 located-driver-text">
                    {{selectedDriver.fullName}}
                  </div>
                  <div class="col-12 col-md-2">
                    Client Code
                  </div>
                  <div class="col-12 col-md-4 located-driver-text">
                      {{selectedDriver.clientCode}}
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12 col-md-2">
                        Employee ID
                  </div>
                  <div class="col-12 col-md-4 located-driver-text">
                        {{selectedDriver.employeeId}}
                  </div>
                  <div class="col-12 col-md-2">
                    Client Name
                  </div>
                  <div class="col-12 col-md-4 located-driver-text">
                        {{selectedDriver.clientName}}
                  </div>
                  
                </div>
                <div class="row mb-2">
                  <div class="col-12 col-md-2">
                    Email
                  </div>
                  <div class="col-12 col-md-4 located-driver-text">
                        {{selectedDriver.Email}}
                  </div>
                  <div class="col-12 col-md-2">
                    Corporate Hierarchy
                  </div>
                  <div class="col-12 col-md-4 located-driver-text">
                        {{selectedDriver.fullHierarchy}}
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12 col-md-2">
                    Date of Birth
                  </div>
                  <div class="col-12 col-md-4 located-driver-text">
                        {{selectedDriver.dateOfBirth}}
                  </div>
                  
                </div>
            </div>
        </div>
    </div>
  </div>


  <div class="row" style="margin-bottom:20px">
    <div class="col-12">
      <div class="card card-body">
        <app-safety-move-drive-group [clientSelections]= "clientSelections"  (selectedDhpChange)="selectedDhpChange($event)"  [isLoading]="isSearchingDriverGroup" (searchDhpHierarchy)="searchDhpGroup($event)" [searchedDhpHierarchy]="searchedDhpHierarchy"></app-safety-move-drive-group>
      </div>
    </div>
  </div>

  <div class="row" >
    <div class="col-12">
      <div class="card card-body">
        <app-safety-move-driver-confirm 
          [selectedDriver]= "selectedDriver" 
          [selectedDhp]="selectedDhp" >
        </app-safety-move-driver-confirm>
        <div class="row" *ngIf="selectedDriver && searchedDhpHierarchy">
          <div class="col-12">
            <div class="row">
              <div class="col-12 summary" *ngIf="showMoveDriverErrorMessage">
                <div class="alert alert-danger"  role="alert">Move Driver Failed!</div>
              </div>
            </div>
            <div class="row">
                <div class="col-12 summary" *ngIf="showMoveDriverSuccessfulMessage">
                  <div class="alert alert-success"  role="alert">Move Driver Successful! <a routerLink="" (click)="refresh()"> - Refresh</a></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 " *ngIf="!isMovingDriver && selectedDriver && selectedDhp">
                    <button type="submit" (click)="moveDriver()"
                    class="btn btn-primary w-100">
                    Move Driver</button>
                </div>
            </div>
            <div class="row">   
                <div class="col-12 text-align-center" *ngIf="isMovingDriver">
                  <app-loading-panel ></app-loading-panel>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>