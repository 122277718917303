import { Component, OnInit, OnChanges, SimpleChanges, Inject, Input } from '@angular/core';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  DriverProfile,
  UserRightsInfo,
  DriverTraining,
  DriverHPEAssignment,
  DriverHPECoreCompetency,
  DriverHPESubLesson,
  DriverMMTraining
} from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { DatePipe } from '@angular/common';

/**
 * this is the client-side controller for the safety - training tab panel
 */
@Component({
  selector: 'safety-training',
  templateUrl: './safety-training.component.html',
  styleUrls: ['./safety-training.component.css']
})

export class SafetyTrainingComponent implements OnInit, OnChanges {
  @Input() driverProfile: DriverProfile;
  @Input() _userRights: Array<UserRightsInfo>;
  gtOptionsCompletedTrainingTable: any = {};
  gtOptionsIncompleteTrainingTable: any = {};
  gtOptionsExternalTrainingTable: any = {};
  gtOptionsHPEAssignmentsTable: any = {};
  gtOptionsHPECoreCompetenciesTable: any = {};
  gtOptionsHPESubLessonsTable: any = {};
  gtOptionsMotorMindsAssignmentsTable: any = {};
  @Input() completedTraining: Array<DriverTraining>;
  @Input() incompleteTraining: Array<DriverTraining>;
  @Input() externalTraining: Array<DriverTraining>;
  @Input() hasExternalTraining: boolean;
  @Input() hpeAssignments: Array<DriverHPEAssignment>;
  @Input() hpeCoreCompetencies: Array<DriverHPECoreCompetency>;
  @Input() hpeSubLessons: Array<DriverHPESubLesson>;
  @Input() MotorMindsAssignments: Array<DriverMMTraining>;
  driverCompletedTrainingTableConfig: any;
  driverIncompleteTrainingTableConfig: any;
  driverExternalTrainingTableConfig: any;
  driverHpeTrainingTableConfig: any;
  driverHpeCoreCompetenciesTableConfig: any;
  driverHpeSubLessonsTableConfig: any;
  driverMMTrainingTableConfig: any;
  canEditDueDate: boolean = false;
  baseUrl: string;
  editDueDate = "Edit Due Date";

  // the overloaded constructor for the controller
  constructor(protected driverHistoryProfileService: DriverHistoryProfileService,
    protected router: Router,
    protected http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    protected fileDownloadService: FileDownloadService
  ) {
    this.baseUrl = baseUrl;
  }

  // angular on intialization event
  ngOnInit() {
    this.canEditDueDate = this.isAccessAllowed(13);
    // for testing set to true
    //this.hasExternalTraining = true;
    // set data for tables
    this.setTableData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //update table data when inputs change
    this.setTableData();
  }

  private setTableData() {
    if (this.completedTraining) {
      this.setCompletedTrainingTableConfig(this.completedTraining);
    }
    if (this.incompleteTraining) {
      this.setIncompleteTrainingTableConfig(this.incompleteTraining);
    }
    if (this.hasExternalTraining && this.externalTraining) {
      this.setExternalTrainingTableConfig(this.externalTraining);
    }
    if (this.hpeAssignments) {
      this.setHPETableConfig(this.hpeAssignments);
    }
    if (this.hpeCoreCompetencies) {
      this.setHPECoreCompetenciesTableConfig(this.hpeCoreCompetencies);
    }
    if (this.hpeSubLessons) {
      this.setHPESubLessonsTableConfig(this.hpeSubLessons);
    }
    if (this.MotorMindsAssignments) {
      this.setMMTableConfig(this.MotorMindsAssignments);
    }
  }

  private setCompletedTrainingTableConfig(arrCompletedTraining: Array<DriverTraining>) {
    const columns = [
      { name: "Lesson", prop: "lesson", title: "Lesson", visible: true, dataType: null, sort: "enable" },
      { name: "Date Completed", prop: "dateCompleted", title: "Date Completed", visible: true, dataType: "date", sort: "enable" },
      { name: "Date Assigned", prop: "dateAssigned", title: "Date Assigned", visible: true, dataType: "date", sort: "enable" },
      { name: "Due Date", prop: "dueDate", title: "Due Date", visible: true, dataType: "date", sort: "enable" },
      { name: "Required Score", prop: "passRate", title: "Required Score", visible: true, dataType: "number", sort: "enable" },
      { name: "Highest Score", prop: "highestScore", title: "Highest Score", visible: true, dataType: "number", sort: "enable" },
      { name: "Attempts", prop: "attempts", title: "Attempts", visible: true, dataType: "number", sort: "enable" },
      { name: "Assigned By", prop: "assignedBy", title: "Assigned By", visible: true, dataType: null, sort: "enable" },
      { name: "TargetedType", prop: "targetedType", title: "Targeted Type", visible: true, dataType: null, sort: "enable" },
      { name: "Certificate", prop: "assignmentId", title: "Certificate", visible: true, dataType: "link", sort: null },
      { name: "courseId", prop: "courseId", title: "courseId", visible: false, dataType: null, sort: null },
      { name: "driverId", prop: "driverId", title: "driverId", visible: false, dataType: null, sort: null }
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: s.sort
      };
    });

    const fields = [];
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        hidden: !s?.visible,
        sort: s?.sort === 'enable',
        rowClass: "colorCompleted",
        render: row => {
          let r = "";
          if (s.prop === "assignmentId") {
            // create Training Certificate link
            r = '<a class="textalign center" style="color:#d23138;"><u>Training Certificate</u></a>';
          } else {
            r = row[s.prop];
          }

          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        },
        click: row => {
          if (s.prop === "assignmentId") {
            // Training Certificate link
            return this.viewTrainingCertificate(this.driverProfile.driverId, row[s.prop]);
          }
        }
      });
    });

    this.driverCompletedTrainingTableConfig = {
      settings: settings,
      fields: fields,
      data: arrCompletedTraining
    }

    this.gtOptionsCompletedTrainingTable = (arrCompletedTraining.length > 1 ? { numberOfRows: arrCompletedTraining.length } : {});
  }

  private setIncompleteTrainingTableConfig(arrIncompleteTraining: Array<DriverTraining>) {
    const columns = [
      { name: "Lesson", prop: "lesson", title: "Lesson", visible: true, dataType: null, sort: "enable" },
      { name: "Date Completed", prop: "dateCompleted", title: "Date Completed", visible: false, dataType: "date", sort: null },
      { name: "Date Assigned", prop: "dateAssigned", title: "Date Assigned", visible: true, dataType: "date", sort: "enable" },
      { name: "Due Date", prop: "dueDate", title: "Due Date", visible: true, dataType: "date", sort: "enable" },
      { name: "Required Score", prop: "passRate", title: "Required Score", visible: true, dataType: "number", sort: "enable" },
      { name: "Highest Score", prop: "highestScore", title: "Highest Score", visible: true, dataType: "number", sort: "enable" },
      { name: "Attempts", prop: "attempts", title: "Attempts", visible: true, dataType: "number", sort: "enable" },
      { name: "Assigned By", prop: "assignedBy", title: "Assigned By", visible: true, dataType: null, sort: "enable" },
      { name: "TargetedType", prop: "targetedType", title: "Targeted Type", visible: true, dataType: null, sort: "enable" },
      { name: this.editDueDate, prop: "assignmentId", title: this.editDueDate, visible: this.canEditDueDate, dataType: "button", sort: null },
      { name: "courseId", prop: "courseId", title: "courseId", visible: false, dataType: null, sort: null },
      { name: "driverId", prop: "driverId", title: "driverId", visible: false, dataType: null, sort: null }
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: s.sort
      };
    });

    const fields = [];
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        hidden: !s?.visible,
        sort: s?.sort === 'enable',
        rowClass: (row, col) => {
          let dateNow = new Date();
          let dueDate = new Date(row["dueDate"]);
          return dueDate >= dateNow ? "colorDue" : "colorPastDue";
        },
        render: row => {
          let r = "";
          if (s.prop === "assignmentId") {
            // create Assign button
            r = '<button class="btn btn-xs btn-default my-0 p-1">Edit</button>';
          } else {
            r = row[s.prop];
          }

          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        },
        click: row => {
          if (s.prop === "assignmentId") {
            // open dialog to edit assignment date
            this.notifyModal('Edit Training Due Date', 'editTrainingDueDate', null, 'lg', { dueDateType: 'editTrainingDueDate', trainingData: row, driverId: this.driverProfile.driverId });
            // open employee driver form to Training section
            //this.openEmployeeDriverForm(row["driverId"], "Training", "Training");
          }
        }
      });
    });

    this.driverIncompleteTrainingTableConfig = {
      settings: settings,
      fields: fields,
      data: arrIncompleteTraining
    }

    this.gtOptionsIncompleteTrainingTable = (arrIncompleteTraining.length > 1 ? { numberOfRows: arrIncompleteTraining.length } : {});
  }

  private setExternalTrainingTableConfig(arrExternalTraining: Array<DriverTraining>) {
    const columns = [
      { name: "Lesson", prop: "lesson", title: "Lesson", visible: true, dataType: null, sort: "enable" },
      { name: "Date Completed", prop: "dateCompleted", title: "Date Completed", visible: true, dataType: "date", sort: "enable" },
      { name: "Date Assigned", prop: "dateAssigned", title: "Date Assigned", visible: true, dataType: "date", sort: "enable" },
      { name: "Due Date", prop: "dueDate", title: "Due Date", visible: true, dataType: "date", sort: "enable" },
      { name: "Highest Score", prop: "highestScore", title: "Highest Score", visible: true, dataType: "number", sort: "enable" },
      { name: "Attempts", prop: "attempts", title: "Attempts", visible: true, dataType: "number", sort: "enable" }
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: s.sort
      };
    });

    const fields = [];
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        type: 'text',
        dataType: s?.dataType,
        hidden: !s?.visible,
        sort: s?.sort === 'enable',
        rowClass: (row, col) => {
          let dateNow = new Date();
          let dueDate = new Date(row["dueDate"]);
          return dueDate >= dateNow ? "colorDue" : "colorPastDue";
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.driverExternalTrainingTableConfig = {
      settings: settings,
      fields: fields,
      data: arrExternalTraining,
      rowClassObject: {
        name: 'dueDate'
      }
    }

    this.gtOptionsExternalTrainingTable = (arrExternalTraining.length > 1 ? { numberOfRows: arrExternalTraining.length } : {});
  }

  private setHPETableConfig(arrHpe: Array<DriverHPEAssignment>) {
    const columns = [
      { name: "HPE Assignment", prop: "courseTitle", title: "HPE Assignment", visible: true, dataType: null, sort: "enable" },
      { name: "Date Completed", prop: "completeDate", title: "Date Completed", visible: true, dataType: "date", sort: null },
      { name: "Status", prop: "assignmentStatus", title: "Status", visible: true, dataType: null, sort: null },
      { name: "Date Assigned", prop: "assignedDate", title: "Date Assigned", visible: true, dataType: "date", sort: "enable" },
      { name: "Due Date", prop: "dueDate", title: "Due Date", visible: true, dataType: "date", sort: "enable" },
      { name: "assignmentId", prop: "assignmentId", title: "assignmentId", visible: false, dataType: null, sort: null },
      { name: "HPE Training Certificate", prop: "assignmentIdCert", title: "HPE Training Certificate", visible: true, dataType: null, sort: null },
      { name: this.editDueDate, prop: "assignmentIdDueDate", title: this.editDueDate, visible: this.canEditDueDate, dataType: null, sort: null }
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: s.sort
      };
    });

    const fields = [];
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        hidden: !s?.visible,
        sort: s?.sort === 'enable',
        rowClass: (row, col) => {
          let dateNow = new Date();
          let dueDate = new Date(row["dueDate"]);
          return dueDate >= dateNow ? "colorDue" : "colorPastDue";
        },
        render: row => {
          let r = "";
          switch (s.prop) {
            case "assignmentIdCert":
              // create Training Certificate link
              if (row["completeDate"] && row["completeDate"] != '') {
                r = '<a class="textalign center" style="color:#d23138;"><u>Training Certificate</u></a>';
              }
              break;
            case "assignmentIdDueDate":
              // create Edit button
              if (row['assignmentStatus'] == "Incomplete") {
                r = '<button class="btn btn-xs btn-default my-0 p-1">Edit</button>';
              }
              break;
            default:
              r = this.formatDefaultRowItem(s.dataType, row[s.prop]);
          }
          return r;
        },
        value: row => {
          let val = null;
          try {
            val = row[s.prop];
          } catch (e) { }
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
        ,
        click: row => {
          if (s.prop === "assignmentIdCert") {
            // Training Certificate link
            return this.viewHPETrainingCertificate(this.driverProfile.driverId, row["assignmentId"]);
          }
          if (s.prop === "assignmentIdDueDate") {
            // open dialog to edit assignment date
            if (row['assignmentStatus'] == "Incomplete") {
              this.notifyModal('Edit HPE Assignment Due Date', 'editHPEAssignmentDueDate', null, 'lg', { dueDateType: 'editHPEAssignmentDueDate', trainingData: row, driverId: this.driverProfile.driverId });
            }
          }
        }
      });
    });

    this.driverHpeTrainingTableConfig = {
      settings: settings,
      fields: fields,
      data: arrHpe
    }

    this.gtOptionsHPEAssignmentsTable = (arrHpe.length > 1 ? { numberOfRows: arrHpe.length } : {});
  }

  private setHPECoreCompetenciesTableConfig(arrHpe: Array<DriverHPECoreCompetency>) {
    const columns = [
      { name: "HPE Core Competency", prop: "coreCompetency", title: "HPE Core Competency", visible: true, dataType: null, sort: "enable" },
      { name: "Score", prop: "score", title: "Score", visible: true, dataType: null, sort: null },
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        //visible: s.visible,
        hidden: !s.visible,
        sort: s?.sort === 'enable',
        render: row => {
          let r = "";
          if (s.prop === "assignmentId") {
            r = '<a class="textalign center" style="color:#d23138;"><u>Training Certificate</u></a>';
          } else {
            r = this.formatDefaultRowItem(s.dataType, row[s.prop]);
          }

          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
      };
    });

    const fields = [];
    columns.forEach(s => {
      //console.log(s);
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s?.dataType,
        hidden: !s?.visible,
        sort: s?.sort === 'enable'
      });
    });

    this.driverHpeCoreCompetenciesTableConfig = {
      settings: settings,
      fields: fields,
      data: arrHpe
    }

    this.gtOptionsHPECoreCompetenciesTable = (arrHpe.length > 1 ? { numberOfRows: arrHpe.length } : {});
  }

  private setHPESubLessonsTableConfig(arrHpe: Array<DriverHPESubLesson>) {
    const columns = [
      { name: "HPE Lesson", prop: "courseTitle", title: "HPE Lesson", visible: true, dataType: null, sort: "enable" },
      { name: "Date Completed", prop: "completeDate", title: "Date Completed", visible: true, dataType: "date", sort: null },
      { name: "Status", prop: "status", title: "Status", visible: true, dataType: null, sort: null },
      { name: "Date Assigned", prop: "assignedDate", title: "Date Assigned", visible: true, dataType: "date", sort: "enable" },
      { name: "Due Date", prop: "dueDate", title: "Due Date", visible: true, dataType: "date", sort: "enable" },
      { name: "Score", prop: "score", title: "Score", visible: true, dataType: null, sort: null },
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: s.sort
      };
    });

    const fields = [];
    columns.forEach(s => {
      //console.log(s);
      fields.push({
        name: s.title,
        objectKey: s.prop,
        hidden: !s?.visible,
        sort: s?.sort === 'enable',
        render: row => {
          let r = "";
          if (s.prop === "assignmentId") {
            // create Training Certificate link
            r = '<a class="textalign center" style="color:#d23138;"><u>Training Certificate</u></a>';
          } else {
            r = this.formatDefaultRowItem(s.dataType, row[s.prop]);
          }

          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.driverHpeSubLessonsTableConfig = {
      settings: settings,
      fields: fields,
      data: arrHpe
    }

    this.gtOptionsHPESubLessonsTable = (arrHpe.length > 1 ? { numberOfRows: arrHpe.length } : {});
  }

  // notifies the modal of changes
  notifyModal(title: string, message: string, modalHeight: string, modalWidth: string, modalInput?: any) {
    this.driverHistoryProfileService.notifyModal(title, message, modalHeight, modalWidth, modalInput);
  }

  // determines if access to the component is allowed
  private isAccessAllowed(userRightsId: number) {
    let retVal: boolean = false;

    if (this._userRights) {
      let thisRight = this._userRights.filter(r => r.userRightId == userRightsId);
      if ((thisRight.length > 0) && (thisRight[0].permission == 'allow')) {
        retVal = true;
      }
    }

    return retVal;
  }

  private viewTrainingCertificate(driverId: number, assignmentId: number) {
    //alert('View Training Certificate for id ' + assignmentId.toString());
    this.fileDownloadService.saveFile(this.baseUrl + 'api/Safety/GetTrainingCertificate?driverId=' + driverId.toString() + '&assignmentId=' + assignmentId.toString());
  }
  private viewHPETrainingCertificate(driverId: number, assignmentId: number) {
    //alert('View Training Certificate for id ' + assignmentId.toString());
    this.fileDownloadService.saveFile(this.baseUrl + 'api/Safety/GetHPETrainingCertificate?driverId=' + driverId.toString() + '&assignmentId=' + assignmentId.toString());
  }

  private setMMTableConfig(arrMM: Array<DriverMMTraining>) {
    const columns = [
      { name: "MotorMinds Assignment", prop: "title", title: "MotorMinds Assignment", visible: true, dataType: null, sort: "enable" },
      { name: "Date Assigned", prop: "assignedDate", title: "Date Assigned", visible: true, dataType: "date", sort: "enable" },
      { name: "Due Date", prop: "dueDate", title: "Due Date", visible: true, dataType: "date", sort: "enable" },
      { name: "Date Completed", prop: "completedDate", title: "Date Completed", visible: true, dataType: "date", sort: null },
      { name: "Status", prop: "status", title: "Status", visible: true, dataType: null, sort: null },
      { name: "assignedBy", prop: "assignedBy", title: "Assigned By", visible: true, dataType: null, sort: null },
      { name: "MotorMinds Training Certificate", prop: "assignmentIdCert", title: "MotorMinds Training Certificate", visible: true, dataType: "link", sort: null },
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: s.sort
      };
    });

    const fields = [];
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s?.dataType,
        hidden: !s?.visible,
        sort: s?.sort === 'enable',
        rowClass: (row, col) => {
          const dateNow = new Date();
          const dueDate = new Date(row["dueDate"]);
          if (row["status"] && (row["status"].toLowerCase() === 'completed' || row["status"].toLowerCase() === 'passed')) {
            return "colorCompleted";
          }
          return dueDate >= dateNow ? "colorDue" : "colorPastDue";
        },
        render: row => {
          let r = "";
          switch (s.prop) {
            case "assignmentIdCert":
              // create Training Certificate link
              if (row["completedDate"] && row["completedDate"] !== '') {
                r = '<a class="textalign center" style="color:#d23138;"><u>Training Certificate</u></a>';
              }
              break;
            case "status":
              // handling nulls in Status Field
              if (row["status"] && row["status"] !== '') {
                r = row['status'];
              }
              else {
                r = 'INCOMPLETE';
              }
              break;
            case "assignedBy":
              // handling nulls in Assigned By Field
              if (row["assignedBy"] && row["assignedBy"] !== '') {
                r = row['assignedBy'];
              }
              else {
                r = '';
              }
              break;
            default:
              r = this.formatDefaultRowItem(s.dataType, row[s.prop]);
          }
          return r;
        },
        value: (row, col) => {
          let val = null;
          try {
            val = row[col.prop];
          } catch (e) { }
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
        ,
        click: row => {
          if (s.prop === "assignmentId") {
            // Training Certificate link
            this.viewTrainingCertificate(this.driverProfile.driverId, row["assignmentId"]);
          }
        }
      });
    });

    this.driverMMTrainingTableConfig = {
      settings: settings,
      fields: fields,
      data: arrMM,
      rowClassObject: {
        name: 'dueDate',
        withCompleted: true
      }
    }

    this.gtOptionsMotorMindsAssignmentsTable = (arrMM.length > 1 ? { numberOfRows: arrMM.length } : {});
  }
  
  private formatDefaultRowItem(dataType: string, property: any) {
    const datePipe = new DatePipe("en-US");
    let data = "";

    if (dataType === "date") {
      try {
        if (property) {
          data = datePipe.transform(new Date(property), "MM/dd/yyyy");
        } else {
          data = '';
        }
      } catch (e) { data = ''; }
    }
    else {
      data = property;
    }

    return data;
  }

  onDownload(data: any): void {
    switch (data?.columnName) {
      case 'completedTraining':
      case 'motormindsAssignment':
        this.viewTrainingCertificate(this.driverProfile.driverId, data?.assignmentId);
        break;
      case 'hpeTrainingCert':
        this.viewHPETrainingCertificate(this.driverProfile.driverId, data?.assignmentId);
        break;
      default:
        break;
    }
  }

  openModal(data: any): void {
    switch (data?.type) {
      case 'incompleteTraining':
        this.notifyModal(
          'Edit Training Due Date',
          'editTrainingDueDate',
          null,
          'lg',
          { dueDateType: 'editTrainingDueDate', trainingData: data?.row, driverId: this.driverProfile.driverId });
        break;
      case 'editDueDate':
        if (data.row['assignmentStatus'] === "Incomplete") {
          this.notifyModal(
            'Edit HPE Assignment Due Date',
            'editHPEAssignmentDueDate',
            null,
            'lg',
            { dueDateType: 'editHPEAssignmentDueDate', trainingData: data?.row, driverId: this.driverProfile.driverId }
          );
        }
        break;
      default:
        break;
    }
  }

  // sleeps for a certain number of milliseconds
  sleep(milliseconds: number) {
    const start = new Date().getTime();
    for (let i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }
}
