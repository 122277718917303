<div class="row my-5">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <p class="mb-0">Please type in your note and click the submit button:</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 md-form">
        <textarea class="form-control px-2" rows="5" [(ngModel)]="note" required></textarea>
      </div>
    </div>
    <div class="row">
      <span class="col-md-12">
        <button class="btn btn-default" (click)="insertNote()">Submit</button>
        <button class="btn btn-default" (click)="cancelInsertNote()">Exit</button>
      </span>
    </div>
  </div>
</div>
