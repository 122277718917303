<h4 class="card-title text-muted wrap-this">Lessons</h4>
<div class="row extra-margin" [formGroup]="trainingSelectionForm">
  <div class="col-md-6">
    <div class="md-form">
      <input
        type="text"
        id="searchText"
        name="searchText"
        class="form-control"
        placeholder="Search by Description"
        formControlName="lessonNameFilter"
        (keyup)="filterLessonList($event.target.value)"
      />
      <label for="searchText" class="active">Search</label>
    </div>
  </div>
  <div class="col-md-6">
    <mat-form-field appearance="fill" class="select-container w-100">
      <mat-label>Search By Category</mat-label>
      <mat-select (selectionChange)="selectionChange($event.value)">
        <input matInput class="select-search-input" placeholder="Search Categories..." (keyup)="filterOptions($event.target.value)">
        <mat-option *ngFor="let option of filteredTrainingCategoryOptions" [value]="option.value">
          {{option.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="row rem1-margin">
  <div class="col-md-12">
    
      <div
        *ngIf="
        trainingSelectionForm.controls['selectedLessons'].pristine !== true &&
        trainingSelectionForm.controls['selectedLessons'].errors?.noTrainingLesson
        "
        class="validation-error"
      >
        At least one training lesson must be selected.
      </div>
    
    <div class="trainingListContainer">
      <ul>
        <li *ngFor="let lcont of this.getLessonOptions(); let i = index">
          <input
            type="checkbox"
            class="input-dark"
            name="checkbTraining"
            id="checkbTraining-{{ i }}"
            [formControl]="lcont.controls.selected"
          />
          <label for="checkbTraining-{{ i }}">{{
            lcont.controls.lesson.value.label
          }}</label>
        </li>
      </ul>
    </div>
  </div>
</div>
