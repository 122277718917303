<!-- Angular Materia -->
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree-container">
  <mat-tree-node class="link" *matTreeNodeDef="let node" (click)="elementClick(node, treeControl.isExpanded(node))">
      {{node.element}}
      <div class="form-check">
        <input #safetyFilterCheckbox
        class="form-check-input filled-in"
        type="checkbox"
        [id]="'cbFilter_' + (selectorId? selectorId + '_':'') + node.elementGuid"
        [checked]="node.boxChecked"
        [indeterminate]="node.indeterminate && !node.boxChecked" 
        (change)="itemToggle(node, !node.boxChecked)">
        <label class="form-check-label" [for]="'cbFilter_' + (selectorId? selectorId + '_':'') + node.elementGuid"></label>
      </div> 
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div class="mat-tree-node">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name" (click)="elementClick(node, treeControl.isExpanded(node))">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <div class="link">
          {{node.element}} 
          <div class="form-check d-flex align-items-center">
            <input #safetyFilterCheckbox
                   class="form-check-input filled-in"
                   type="checkbox"
                   [id]="'cbFilter_' + (selectorId? selectorId + '_':'') + node.elementGuid"
                   [checked]="node.boxChecked"
                   [indeterminate]="node.indeterminate && !node.boxChecked" 
                   (change)="itemToggle(node, !node.boxChecked)">
            <label class="form-check-label" [for]="'cbFilter_' + (selectorId? selectorId + '_':'') + node.elementGuid"></label>
          </div> 
        </div>
      </div>
      <div 
        class="group"
        role="group"  
        [class.tree-invisible]="!treeControl.isExpanded(node)">
       <ng-template matTreeNodeOutlet></ng-template>
    </div>
  </mat-nested-tree-node>
</mat-tree>


