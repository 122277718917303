<div class="quickSearchContainerDataGrid">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" >
  <div class="col-md-12">
    <H3>
      BI Driver Maintenance
    </H3>
  </div>
</div>
<div class="row">
  <div class="col-md-12" style="text-align:right">
    <button #btnKeep id="btnKeep" class="btn btn-primary" style="white-space: nowrap!important" (click)="keep()">
      Keep This Driver
    </button>
    <button #btnRemove id="btnRemove" class="btn btn-primary" style="white-space: nowrap!important" (click)="remove()">
      Remove This Driver
    </button>
    <button #btnMove id="btnMove" class="btn btn-primary" style="white-space: nowrap!important" (click)="moveToGroup()">
      Move To Group
    </button>
    <button #btnExit id="btnExit" class="btn btn-primary" style="white-space: nowrap!important" (click)="exit()">
      Exit
    </button>
  </div>
</div>
<div class="row">

  <div *ngIf="row" class="col-md-12">
    <app-data-table [columns]="configObject.fields" [data]="configObject.data"></app-data-table>
    <div id="reportFooter">
      <div class="left"> Fleet Response Confidential</div>
      <div class="right"> Report run {{today | date :'short' }}</div>
    </div>
  </div>
  <!--<div *ngIf="!rowsRaw || rowsRaw?.length == 0">
    No results
  </div>-->
</div>