//angular libraries
import { Component, Inject, Input, Output, OnInit, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
//thirdparty libraries
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDatepicker } from '@angular/material/datepicker';
//projects imports
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { DriverProfile } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';


@Component({
    selector: 'document-upload',
    templateUrl: './document-upload.component.html',
    styleUrls: ['./document-upload.component.scss']
})

export class DocumentUploadComponent implements OnInit {
    @Input() uploadType: string;
    @Input() driverProfile: DriverProfile;
    @Input() driverId: number;
    @Output() onDocUploadExit: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDocUploadRefresh: EventEmitter<any> = new EventEmitter<any>();
    expirationDateControl = new UntypedFormControl('', [Validators.required]);
    dqDriverLogStartDateControl = new UntypedFormControl('', [Validators.required]);
    dqDriverLogEndDateControl = new UntypedFormControl('', [Validators.required]);
    documentTypeId: number;
    fileDescription: string;
    expirationDate: Date;
    documentDateObtainedState: Date;
    dqDriverLogStartDate: Date;
    dqDriverLogEndDate: Date;
    baseUrl: string;
    actualDate = new Date();
    //formData: FormData;
    files: File[];

    dragOver: boolean;
    documentTypeOptions: Array<any>;
    filteredDocumentTypeOptions: Array<any>;
    private dtToday: Date = new Date((new Date()).toDateString());
    @ViewChildren('myDateStatePicker') myDateStatePickerList: QueryList<MatDatepicker<Date>>;
    @ViewChildren('autoCoverageExpirationDatePicker') autoCoverageExpirationDatePickerList: QueryList<MatDatepicker<Date>>;
    @ViewChildren('startDatePicker') startDatePickerList: QueryList<MatDatepicker<Date>>;
    @ViewChildren('endDatePicker') endDatePickerList: QueryList<MatDatepicker<Date>>;
    filterStartDate: string;
    filterEndDate: string;
    autoCoverageExpirationDatePicker: MatDatepicker<Date>;
    dateStatePicker: MatDatepicker<Date>;
    filterStartDatePicker: MatDatepicker<Date>;
    filterEndDatePicker: MatDatepicker<Date>;
    startDate: string;

    maxDateStartDate: Date = new Date();
    maxDateEndDate: Date;
    descriptionControl = new UntypedFormControl('', [Validators.required]);
    documentTypeControl = new UntypedFormControl('', []);
    dateObtainedTypeControl = new UntypedFormControl('', []);

    allControls: Array<UntypedFormControl> = [
        this.descriptionControl,
        this.documentTypeControl,
        this.dateObtainedTypeControl
    ];



    constructor(@Inject('BASE_URL') baseUrl: string,
        private http: HttpClient,
        private onPremService: OnPremDriverService,
        private loadingSpinnerService: LoadingSpinnerService,
        private snackBar: MatSnackBar,
        private errorService: ErrorModalService
    ) {
        this.baseUrl = baseUrl;
        this.files = [];

        const tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);
        this.maxDateStartDate = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), this.dtToday.getDate());
        this.maxDateEndDate = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), this.dtToday.getDate());
    }

    ngOnInit() {
        if (this.uploadType == 'DriverProfile') {
            this.documentTypeControl.setValidators([Validators.required]);
        }
        if (this.uploadType == 'EmployeeDriverForm') {
            this.documentTypeControl.setValidators([Validators.required]);
        }
        this.getOptions();

    }

    ngAfterViewChecked(): void {
        if (!this.filterStartDatePicker && this.startDatePickerList?.first) {
            this.filterStartDatePicker = this.startDatePickerList.first;
            this.filterStartDatePicker.openedStream.subscribe(() => {
                setTimeout(() => {
                    this.filterStartDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
                        this.filterStartDatePicker.select(event.value);
                        this.filterStartDatePicker.close();
                    })
                }, 0)
            })
        }

        if (!this.filterEndDatePicker && this.endDatePickerList?.first) {
            this.filterEndDatePicker = this.endDatePickerList.first;
            this.filterEndDatePicker.openedStream.subscribe(() => {
                setTimeout(() => {
                    this.filterEndDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
                        this.filterEndDatePicker.select(event.value);
                        this.filterEndDatePicker.close();
                    })
                }, 0)
            })
        }

        if (!this.dateStatePicker && this.myDateStatePickerList?.first) {
            this.dateStatePicker = this.myDateStatePickerList.first;
            this.dateStatePicker.openedStream.subscribe(() => {
                setTimeout(() => {
                    this.dateStatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
                        this.dateStatePicker.select(event.value);
                        this.dateStatePicker.close();
                    })
                }, 0)
            })
        }

        if (!this.autoCoverageExpirationDatePicker && this.autoCoverageExpirationDatePickerList?.first) {
            this.autoCoverageExpirationDatePicker = this.autoCoverageExpirationDatePickerList.first;
            this.autoCoverageExpirationDatePicker.openedStream.subscribe(() => {
                setTimeout(() => {
                    this.autoCoverageExpirationDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
                        this.autoCoverageExpirationDatePicker.select(event.value);
                        this.autoCoverageExpirationDatePicker.close();
                    })
                }, 0)
            })
        };
    }
    private getOptions() {
        this.onPremService.get('lookup/documentTypes/' + this.driverId)
            .subscribe({
                next: (data) => {
                    let documentTypeOptions = data as Array<DocUploadType>;
                    this.documentTypeOptions = documentTypeOptions.map(i => { return { value: i.id, label: i.description } });
                    this.filteredDocumentTypeOptions = this.documentTypeOptions;
                    this.loadingSpinnerService.hide();
                },
                error: (err: HttpErrorResponse) => {
                    this.errorService.setErrorObject(err.error);
                    this.loadingSpinnerService.hide();
                }
            });
    }

    showFiles() {
        let files = "";
        for (let i = 0; i < this.files.length; i++) {
            files += this.files[i].name
            if (!(this.files.length - 1 == i)) {
                files += ", "
            }
        }
        return files;
    }

    filterOptions(value: string): void {
        this.documentTypeOptions = this.filteredDocumentTypeOptions.filter(option => option.label.toLowerCase().
            startsWith(value.toLocaleLowerCase()));
    }

    onUploadOutput(outputGeneral: any): void {
        // allow only one file at a time
        let output: any = outputGeneral.target.files[0];
        if (this.files.length <= 0) {
            this.files.push(output);
        } else {
            this.files[0] = output;
        }
        this.showFiles();
    }

    private validateForm(): boolean {
        let isOK: boolean = true;

        let controls = this.allControls.slice();
        if (this.documentTypeId == 15) // check expiration date if document type is "Auto Coverage(Id=15)"
            controls.push(this.expirationDateControl);

        if (this.documentTypeId == 31) { // check start date and end dage if document type is "DQ Driver Log(Id=31)"
            controls.push(this.dqDriverLogStartDateControl);
            controls.push(this.dqDriverLogEndDateControl);
            if (this.dqDriverLogEndDate < this.dqDriverLogStartDate) {
                isOK = false;
                this.errorService.setErrorObject({ message: 'Start Date cannot be greateer than End Date' });
            }
        }

        if (controls.filter(c => (c.invalid)).length > 0) {
            this.errorService.setErrorObject({ message: 'Missing or Invalid Fields.' });
            isOK = false;
        }

        return isOK;
    }

    uploadDocs() {
        if (this.validateForm()) {
            if (this.fileDescription && (this.fileDescription.length > 0)) {
                let fileCount: number = this.files.length;
                if (fileCount > 0) {
                    switch (this.uploadType) {
                        case "DriverProfile":
                        case "EmployeeDriverForm":
                            this.driverProfileUpload(this.driverId, this.files[0]);
                            break;
                    }
                } else {
                    this.errorService.setErrorObject({ message: "No file chosen" });
                }
            } else {
                this.errorService.setErrorObject({ message: "Please enter a description" });
            }
        }

    }
    private getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    private driverProfileUpload(driverId: number, file: File) {

        this.getBase64(file).then(data => {
            let fName = this.files[0].name;
            let fExt = null;
            let lastIndex = fName.lastIndexOf('.');
            if (lastIndex >= 0) {
                fExt = fName.substr(lastIndex + 1);
                fName = fName.substr(0, lastIndex);
            }

            let param = {
                driverId: this.driverId.toString(),
                documentTypeId: this.documentTypeId.toString(),
                fileName: fName,
                extension: (fExt || null),
                description: this.fileDescription,
                fileData: (data as string).replace("data:", "").replace(/^.*;base64,/, ""),
                expirationDate: this.expirationDate,
                StartDate: this.dqDriverLogStartDate,
                EndDate: this.dqDriverLogEndDate,
                driverProcessId: null,
                additionalData: null
            };
            this.loadingSpinnerService.show();

            var url = "file";
            if (this.documentTypeId == 15) // if document type is "Auto Coverage(Id=15)" upload to a different endpopint
            {
                param.driverProcessId = 0;
                url = "insurances/" + this.driverId.toString() + "/upload/"
            }

            if (this.documentTypeId == 31) // if document type is "Driver Log(Id=31)" upload to a different endpopint
            {
                url = "driverQualification/" + this.driverId.toString() + "/driverlog/upload/";
            }
            if (this.documentTypeId == 5) {
                let additionalData = { "dateObtained": this.documentDateObtainedState };
                param.additionalData = additionalData;
            }

            this.onPremService.post(url,
                param
            ).subscribe({
                next: () => {
                    // notify the upload panel to refresh and close
                    this.onDocUploadRefresh.emit();

                    this.snackBar.open(`File Uploaded. \nDriver History Profile`, '', {
                        horizontalPosition: 'end',
                        verticalPosition: 'top',
                        duration: 5000,
                        panelClass: 'success-snackbar'
                    })
                },
                error: (err: HttpErrorResponse) => {
                    this.errorService.setErrorObject(err.error);
                }
            });
            this.loadingSpinnerService.hide();
        });

    }

    closeUploadPanel() {
        this.onDocUploadExit.emit();
    }

    onChange(event: any) {

        console.log(event.value);

        if (event.value == 5) {
            this.dateObtainedTypeControl.setValidators([Validators.required, this.DateStateValidator()]);
        }
        else {
            this.dateObtainedTypeControl.setValidators([]);
        }
    }

    isDateStateValid(dateState: string) {
        var selectedDate = new Date(dateState);
        var dateToday = new Date();
        var dateToCompare = new Date()

        var isMorethanaYearAgo = selectedDate < new Date(dateToCompare.setFullYear(dateToday.getFullYear() - 1))
        var isDateSelectedGreaterThanToday = selectedDate > dateToday;

        if (isDateSelectedGreaterThanToday) {

            this.errorService.setErrorObject({ message: 'Date cannot be in the future' });
            return { 'DateIsNotValid': true };

        }
        else if (isMorethanaYearAgo) {
            this.errorService.setErrorObject({ message: 'Abstract should be from the past year' });
            return { 'DateIsNotValid': true };
        }

        return null;
    }


    DateStateValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {

            if (control.value != null || control.value != '' || control.value != undefined) {
                return this.isDateStateValid(control.value);
            }
            return null;
        };
    }
}

export interface DocUploadType {
    id: number;
    description: string;
}

export class DriverProfileDocUpload {
    driverId: number;
    documentTypeId: number;
    fileName: string;
    extension: string;
    description: string;
    fileData: Uint8Array;

    constructor(driverId: number) {
        return {
            driverId: driverId,
            documentTypeId: null,
            fileName: null,
            extension: null,
            description: null,
            fileData: null
        }
    }
}
