//Angular
import { Component, Input, Inject, ViewChildren, QueryList, Renderer2, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
//Third Party
import { MatDialog } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
//APP
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { CorporateStructure } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { CorporateStructureAddGroupModalService } from '../corporate-structure-add-group-modal/corporate-structure-add-group-modal.service';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { CorporateStructureAddGroupModalComponent } from '../corporate-structure-add-group-modal/corporate-structure-add-group-modal.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';


@Component({
  selector: 'app-corporate-structure-folder',
  templateUrl: './corporate-structure-folder.component.html',
  styleUrls: ['./corporate-structure-folder.component.scss']
})
export class CorporateStructureFolderComponent implements OnInit, OnChanges {
  @Input() arrCHInfo: Array<CorporateStructure>;
  @Input() clientSelectedArray: Array<string>;
  @Input() chType: string;
  baseUrl: string;
  collapsed: boolean = true;
  @Input() showAddGroupButton: boolean = false;
  @Input() showAddDriverButton: boolean = false;
  @Input() showEmptyContainers: boolean = false;
  @Output() showLoadMoreBtnEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showLoadMoreAddGroupBtnEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  LineOfBusiness: number;

  treeControl = new NestedTreeControl<CorporateStructure>(node => node.childElements);
  dataSource = new MatTreeNestedDataSource<CorporateStructure>();

  constructor(private router: Router,
    private dialog: MatDialog,
    private clientSelectionService: ClientSelectionService,
    private renderer: Renderer2,
    @Inject('BASE_URL') baseUrl: string,
    private onPremService: OnPremDriverService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService,
    private corporateStructureAddGroupService: CorporateStructureAddGroupModalService,
    protected lineOfBusinessService: LineOfBusinessService,

  ) {
    this.baseUrl = baseUrl;
  }
  ngOnInit(): void {
    this.LineOfBusiness = this.lineOfBusinessService.getLineOfBusinessValue();
    this.lineOfBusinessService.lineOfBusinessSelected$.subscribe(data => {
      this.LineOfBusiness = data;
    });
    this.dataSource.data = this.arrCHInfo;
  }

  ngOnChanges(): void {
    this.reloadTree();
  }

  hasChild(index: number, node: CorporateStructure): boolean {
    return node.childElements?.length > 0 || node.hasChildren;
  }

  elementClick(callingElement: CorporateStructure, e: any) {
    if (callingElement.tier === 0) {
      if (this.chType === 'CH') {
        this.showLoadMoreBtnEvent.emit(this.getExpandedState(callingElement));
      }
      else if (this.chType === 'CHAddGroup') {
        this.showLoadMoreAddGroupBtnEvent.emit(this.getExpandedState(callingElement));
      }
    }

    if (callingElement.hasChildren) {
      // expand next level
      this.getCHSubInfo(callingElement, e);
    } else {


      if (callingElement.isDriver) {
        switch (this.chType) {
          case 'CH':
            this.showLoadMoreBtnEvent.emit(false);
            break;
          case 'CHByState':            // open driver profile

            break;
          case 'CHAddGroup':
            this.showLoadMoreAddGroupBtnEvent.emit(false);
            break;
        }

        this.openDriverProfile(callingElement.driverId);
      }
    }
  }

  private getExpandedState(callingElement: CorporateStructure) {
    return this.treeControl.isExpanded(callingElement);
  }

  private reloadTree(): void {
    this.dataSource.data = null;
    this.dataSource.data = this.arrCHInfo;
  }

  private getCHSubInfo(callingElement: CorporateStructure, e: any) {
    e.preventDefault();
    let parentElementId: string = callingElement.elementGuid;
    let hasChildElements =
      callingElement.hasChildren && !callingElement.childElements;

    if (hasChildElements) {
      this.loadingSpinnerService.show();

      let showAllTiers = true;
      /* showAllTiers should always be true
          let showAllTiers = false;
          if (this.showAddGroupButton) {
            showAllTiers = true;
          }
          */

      let svcUrl = "";
      if (this.chType === "CHByState") {
        svcUrl =
          "hierarchy/stateStructure/" +
          this.clientSelectedArray.join(",") +
          "/" +
          parentElementId.toString();
      } else {
        svcUrl =
          "hierarchy/structure/" +
          this.clientSelectedArray.join(",") +
          "/" +
          showAllTiers +
          "/" +
          parentElementId.toString();
      }

      this.onPremService.get(svcUrl).subscribe({
        next: (data) => {
          // assign to childElements of callingElement
          callingElement.childElements = data as Array<CorporateStructure>;
          this.reloadTree();
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          //this.toastrService.error(err.error.toString());
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      }
      );
    }
  }

  private openDriverProfile(id: number) {
    let Lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (Lob === 2)
      this.router.navigate(['driver/history-profile', id.toString()]);
    else if (Lob === 7)
      this.router.navigate(['dc/driverprofile', id.toString()]);
  }

  private openNewDriverForm(item: CorporateStructure) {
    this.router.navigate(['safety/newemployeedriverform/', item.elementGuid], { queryParams: { returnToPage: 'corpstructure' } });
  }

  private openAddGroupPopup(item: CorporateStructure) {
    //console.log(ch);
    const dialog = this.dialog.open(CorporateStructureAddGroupModalComponent,
      {
        data: { item, clientSelectedArray: this.clientSelectedArray },
        height: "12em",
        width: "30em",
        panelClass: "add-group-modal",
        hasBackdrop: false,
        position: { top: "15em", right: "42em" }
      })
    // this.corporateStructureAddGroupService.openModal(item);
  }
}
