
import { UntypedFormControl } from '@angular/forms';
import { DriverProfile } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { Batch } from '../shared/batch-assignment/batch';

export class DriverFormInfo {
    headerContent: string;
    footerContent: string;
    fields: Array<DriverFormFieldSettings>;
  }

  export class DriverFormFieldSettings {
    propertyName: string;
    isVisible: boolean;
    isReadOnly: boolean;
    isRequired: boolean;
    // formControl is set after the endpoint data gets retreived
    formControl: UntypedFormControl;
  }

  export class DriverState {
    description: string;
    id: number;
    secondaryCode: string;
    secondaryId: number;
  }

  export class DriverHierarchy {
    description: string;
    id: number;
    elementGuid: string;
  }

  export class DriverLanguage {
    displayText: string;
    id: number;
    language: string;
    languageCode: string;
  }

  export class DriverCounty {
    id: number;
    secondaryId: number;
    description: string;
    secondaryCode: string;
  }

  export class DriverRelationshipType {
    id: number;
    secondaryId: number;
    description: string;
    secondaryCode: string;
  }

  export interface EntityDriverResponseObject {
    entityId: number;
    inputField: string;
    isSuccess: boolean;
    validationMessage: string;
  }
  export class EmployeeDriverInfoBase {
    // data that will be sent to the entity/driver PUT for updates
    dateOfBirth: Date;
    dotDriver: boolean;
    driverId: number;
    driverLicenseExpirationDate: string;
    driverLicenseNumber: string;
    driverLicenseState: string;
    emailAddress: string;
    employeeId: string;
    firstName: string;
    fullName: string;
    groupGuid: string;
    hireDate: Date;
    isActive: boolean;
    isCommercialDriversLicense: boolean;
    lastName: string;
    middleName: string;
    primaryDriverId: number;
    languageId: string;
    req1: string;
    req10: string;
    req2: string;
    req3: string;
    req4: string;
    req5: string;
    req6: string;
    req7: string;
    req8: string;
    req9: string;
    requiresAutoCoverage: boolean;
    trainingOnlyPortalAccess: boolean;
    licenseState: string;
    driverCountry: string;

    // secondary driver additional fields
    isTemporaryDriver: boolean;
    driverDuration: string;
    livesWithPrimaryDriver: boolean;
    primaryDriverRelationshipId: string;
    temporaryDriverExpirationDate: string;

    // added programatically
    client: string;
    emailAddressConfirm: string;

    constructor() {

    }
  }

  export class EmployeeDriverInfo {
    driverBaseInfo: EmployeeDriverInfoBase;

    assignOnlineTraining: boolean;
    assignOnlineTrainingDateDue: string;
    training: string;

    confirmLicenseInfo: boolean;
    confirmLicenseInfoDateDue: string;

    orderPolicyTask: boolean;
    policyTaskDueDate: string;

    driverLicenseUpload: boolean;
    driverLicenseUploadDateDue: string;

    requestMVR: boolean;
    mvrOrderedDate: string;
    requestNonEmployeeMVR: boolean;

    requestCOV: boolean;
    requestCOI: boolean;
    resetPassword: boolean;
    resendLogon: boolean;

    dqRequired: boolean;
    dqRequestLicenseUpload: boolean;
    dqRequestMedCard: boolean;
    dqRequestCOV: boolean;
    dqRequestApplication: boolean;
    dqRequestRecordOfRoadTest: boolean;
    dqRequestCertOfRoadTest: boolean;
    dqRequestDriverLog: boolean;
    dqRequestClearingHouseSignoff: boolean;

    enrollInMonitoring: boolean;
    licenseUploadRequest: boolean;

    label: string;

    // for ordering MVR for non-employees
    secondaryDrivers: Array<DriverProfile>;

    constructor() {
      this.driverBaseInfo = new EmployeeDriverInfoBase();
    }
  }
  export class DriverBatchItem {

    hasBatchRequest: boolean;
    batchRequest?: Batch;
    // if these are true, separate endpoints get called
    resendLogin?: boolean;
    resetPassword?: boolean;
    constructor() {
        this.hasBatchRequest = false;
        //this.batchRequest = new Batch();
      //// defaults for new
    }
  }
