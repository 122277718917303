//Angular
import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
//Third Party
import { MatStepper } from '@angular/material/stepper';
import { MatDatepicker } from '@angular/material/datepicker';
//APP
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { CorporateStructure } from "../../components/classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { AlertModalService } from "../../components/alert-modal/alert-modal-service.component";
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { MvrExportComponent, MvrExportDriverSelectionCriteria, MvrExportMVRSelectionCriteria, MvrExportDriverInfo } from '../mvr-export/mvr-export.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-training-summary-export',
  templateUrl: './training-summary-export.component.html',
  styleUrls: ['./training-summary-export.component.scss']
})
export class TrainingSummaryExportComponent extends MvrExportComponent implements OnInit{
  // most public proprties are defined in MvrExportComponent
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('startDatePicker') startDatePicker: MatDatepicker<Date>;
  @ViewChild('endDatePicker') endDatePicker: MatDatepicker<Date>;
  @Input() Lob: number;
  selectionCriteria: MvrExportDriverSelectionCriteria = new MvrExportDriverSelectionCriteria();

  allControls: Array<UntypedFormControl> = [
    this.exportStartDateControl,
    this.exportEndDateControl,
    this.exportDriverTypeControl
  ];
 actualDate = new Date();
 criteriaForTrainingFormGroup: UntypedFormGroup;
  constructor(
    router: Router,
    clientSelectionService: ClientSelectionService,
    lineOfBusinessService: LineOfBusinessService,
    onPremService: OnPremDriverService,
    cdRef:ChangeDetectorRef,
    loadginSpinnerService: LoadingSpinnerService,
    errorService: ErrorModalService,
    alertService: AlertModalService,
    private readonly formBuilder: UntypedFormBuilder
  ) {
    super(router, clientSelectionService, lineOfBusinessService, onPremService, cdRef, loadginSpinnerService, errorService, alertService);
     this.criteriaForTrainingFormGroup = this.formBuilder.group({
      exportStartDateControl: ['', Validators.required],
      // exportEndDateControl: ['', Validators.required],
      // exportRetrieveTypeControl: ['', Validators.required]
    })
  }

  ngAfterViewInit(): void {
  this.startDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.startDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.startDatePicker.select(event.value);
            this.startDatePicker.close();
        })
    },0)
    });

    this.endDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.endDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.endDatePicker.select(event.value);
            this.endDatePicker.close();
        })
    },0)
    });
  }

  nextStep(): void {
    if(this.criteriaValid()) this.criteriaForTrainingFormGroup.patchValue({"exportStartDateControl":true})
    this.stepper.next();
  }

  backStep(): void {
    this.stepper.selectedIndex = this.stepper.selectedIndex-1;
  }

  getDrivers() {
    this.arrGroupGuid = this.getCheckedGroupGuidArray(this.arrCH);
    if(this.Lob == 2) {
      this.arrStateGuid = this.getCheckedGroupGuidArray(this.arrCHByState);
    }
    if ((this.arrGroupGuid.length > 0) || (this.arrStateGuid.length > 0)) {

      this.selectionCriteria.clientCode = this.clientSelectedArray[0];
      this.selectionCriteria.groupGuidList = this.arrGroupGuid.join(",");
      this.selectionCriteria.lineOfBusiness = this.Lob;          
      if(this.Lob == 2) {
        this.selectionCriteria.stateGuidList = this.arrStateGuid.join(","); 
      }

      // most recent not used
      delete this.selectionCriteria.mostRecent;

      this.loadginSpinnerService.show();
      this.onPremService.post('training/toPdf/drivers', JSON.stringify(this.selectionCriteria)).subscribe({
        next: (data) => {
          this.arrDriver = data as Array<MvrExportDriverInfo>;
          this.loadginSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadginSpinnerService.hide();
        }
      });
    } else {
      this.arrDriver = [];
    }

  }


  submitTSExport() {
    if (this.arrDriverIdSelected.length <= 0) {
      this.alertService.setAlertObject({
        title: 'Training Summary to PDF', message: 'Please select one or more drivers to include.'
      });
    } else {
      let postData = new MvrExportMVRSelectionCriteria();
      postData.ClientCode = this.clientSelectedArray[0];
      postData.BatchType = 1;
      postData.StartDate = this.selectionCriteria.startDate;
      postData.EndDate = this.selectionCriteria.endDate;
      postData.DriverIdList = this.arrDriverIdSelected.map(d => d.toString()).join(",");
      postData.DriverIdExcludeList = this.arrDriverIdExcluded.map(d => d.toString()).join(",");

      this.loadginSpinnerService.show();
      this.onPremService.post('training/toPdf/batch',
        JSON.stringify(postData)
      ).subscribe({
        next: (data) => {
          this.loadginSpinnerService.hide();
          this.isSubmitted = true;
          this.alertService.setAlertObject({ 
            title: 'Training Summary to PDF', 
            message: 'Your Training Summary to PDF file is being processed. You will receive an email when it is available.' + 
            '\n\nNote: This process may take up to an hour depending on the size of the file.' 
          });
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadginSpinnerService.hide();
        }
      });
    }

  }

}
