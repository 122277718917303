<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>
<div class="row" *ngIf="driverInfo" style="padding-bottom:20px">
  <div class="col-9">
    <h3>{{driverInfo.label}}</h3>
    <h4 *ngIf="isNewDriver" style="margin-left:2rem">{{driverInfo.driverCompanyLabel}}</h4>
  </div>
  <div class="col-3">
    <button type="button"
            (click)="validateBeforeSubmit()"
            class="btn btn-primary">
      Submit
    </button>
    <button type="button"
            (click)="exitDriverForm(false)"
            class="btn btn-primary">
      Exit
    </button>
  </div>
</div>
<ng-container *ngIf="allSectionsLoaded&&driverInfo">
  <div class="row">
    <div class="col-12">
      <mat-accordion [multiple]="true" aria-multiselectable="true" multi="true">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              General Information
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" style="margin-left:1rem;margin-right:1rem;">
            <div class="col-12">
              <div class="row extra-margin" *ngIf="!isNewDriver">
                <div class="col-12">
                  <mat-label>Company</mat-label>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-select 
                      [options]="companyOptions"
                      placeholder="Choose Company"
                      visibleOptions=10
                      [formControl]="companyIdControl"
                      [(ngModel)]="driverInfo.groupGuid"
                      [disabled]="true">
                    </mat-select>
                  </mat-form-field>

                </div>
              </div>
              <div [ngClass]="{'row':true, 'extra-margin':isNewDriver, 'rem2-margin':!isNewDriver}">
                <div class="col-12">
                  <div class="md-form">
                    <mat-label for="driverId" [ngClass]="{ 'label-validation-error': employeeIdControl.invalid, 'active':true }">Employee ID</mat-label>
                    <input id="driverId"
                           type="text"
                           [(ngModel)]="driverInfo.employeeId"
                           class="form-control"
                           [formControl]="employeeIdControl"
                           placeholder="Employee ID" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="md-form">
                    <mat-label for="driverFirstName" [ngClass]="{ 'label-validation-error': firstNameControl.invalid, 'active':true }">First Name</mat-label>
                    <input id="driverFirstName"
                           type="text"
                           [(ngModel)]="driverInfo.firstName"
                           [formControl]="firstNameControl"
                           class="form-control"
                           placeholder="First Name" />                   
                  </div>
                </div>
                <div class="col-4">
                  <div class="md-form">
                    <mat-label for="driverMiddleName" class="active">Middle Name</mat-label>
                    <input id="driverMiddleName"
                           type="text"
                           [(ngModel)]="driverInfo.middleName"
                           class="form-control"
                           placeholder="Middle Name" />                    
                  </div>
                </div>
                <div class="col-4">
                  <div class="md-form">
                    <mat-label for="driverLastName" [ngClass]="{ 'label-validation-error': lastNameControl.invalid, 'active':true }">Last Name</mat-label>
                    <input id="driverLastName"
                           type="text"
                           [(ngModel)]="driverInfo.lastName"
                           [formControl]="lastNameControl"
                           class="form-control"
                           placeholder="Last Name" />                    
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="md-form">
                    <mat-label for="driverEmail" [ngClass]="{ 'label-validation-error': driverEmailControl.invalid, 'active':true }">Email Address</mat-label>
                    <input id="driverEmail"
                           type="text"
                           [(ngModel)]="driverInfo.emailAddress"
                           [formControl]="driverEmailControl"
                           class="form-control"
                           placeholder="Email Address" />                    
                  </div>
                </div>
                <div class="col-6">
                  <div class="md-form">
                    <mat-label for="driverEmailConfirm" [ngClass]="{ 'label-validation-error': driverEmailConfirmControl.invalid, 'active':true }">Re-Type Email to Confirm</mat-label>
                    <input id="driverEmailConfirm"
                           type="text"
                           [(ngModel)]="driverInfo.emailAddressConfirm"
                           [formControl]="driverEmailConfirmControl"
                           class="form-control"
                           placeholder="Re-Type Email to Confirm" />                    
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-label>Language</mat-label>
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Language</mat-label>
        <mat-select>
        <mat-option *ngFor="let option of languageOptions" [value]="option.value">
            {{option.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-divider></mat-divider> 
        <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Extended Information
          </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row" style="margin-left:1rem;margin-right:1rem;">
        <div class="col-12">
          <div class="row extra-margin">
            <div class="col-12">
              <div class="md-form">
                <input id="ownerEmail"
                       type="text"
                       [(ngModel)]="driverInfo.ownerEmailAddress"
                       class="form-control"
                       placeholder="Owner Email" />
                <mat-label for="ownerEmail" class="active">Owner Email</mat-label>
              </div>
            </div>
          </div>
          <div class="row" *ngFor="let mgr of driverInfo.managerTrainerEmailAddresses;let i = index; trackBy:indexTracker;">
            <div class="col-12">
              <div class="md-form">
                <input [id]="'ownerEmail' + (i+1)"
                       name="ownerEmail{{i+1}}"
                       type="text"
                       [(ngModel)]="driverInfo.managerTrainerEmailAddresses[i]"
                       class="form-control"
                       [placeholder]="'Manager Email (' + (i+1) + ')'" />
                <mat-label [for]="'ownerEmail' + (i+1)" class="active">{{'Manager Email (' + (i+1) + ')'}}</mat-label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Medical Certificate Expiration Date:</mat-label>
                  <input class="input-date" matInput [matDatepicker]="medCardExpirationDatePicker" [(ngModel)]="driverInfo.medicalCardExpirationDate" [max]="maxDate">
                  <mat-datepicker-toggle matIconSuffix [for]="medCardExpirationDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #medCardExpirationDatePicker 
                      disabled="false">
                      <mat-datepicker-actions>
                        <button mat-button (click)="medCardExpirationDatePicker.select(actualDate); medCardExpirationDatePicker.close()">
                          <mat-icon>history</mat-icon>
                          Today
                        </button>
                        <button mat-button (click)="medCardExpirationDatePicker.select(null); medCardExpirationDatePicker.close()">
                          <mat-icon>highlight_off</mat-icon>
                          Clear
                        </button>
                      </mat-datepicker-actions>
                  </mat-datepicker>
                </mat-form-field>         
            </div>
          </div>
          <div class="row">
            <div class="col-12">              
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Drivers License Expiration Date:</mat-label>
                <input class="input-date" matInput [matDatepicker]="driversLicenseExpirationDatePicker" [(ngModel)]="driverInfo.driverLicenseExpirationDate" [max]="maxDate">
                <mat-datepicker-toggle matIconSuffix [for]="driversLicenseExpirationDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #driversLicenseExpirationDatePicker 
                    disabled="false">
                    <mat-datepicker-actions>
                      <button mat-button (click)="driversLicenseExpirationDatePicker.select(actualDate); driversLicenseExpirationDatePicker.close()">
                        <mat-icon>history</mat-icon>
                        Today
                      </button>
                      <button mat-button (click)="driversLicenseExpirationDatePicker.select(null); driversLicenseExpirationDatePicker.close()">
                        <mat-icon>highlight_off</mat-icon>
                        Clear
                      </button>
                    </mat-datepicker-actions>
                </mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
        </mat-expansion-panel>
        <mat-divider></mat-divider>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Delivery Contractor
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" style="margin-left:1rem;margin-right:1rem;">
            <div class="col-12">
              <div class="row extra-margin" *ngFor="let group of arrLiveRouteTrainingType; let ttIndex = index;">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <mat-label>{{group.description}}</mat-label>
                    </div>
                  </div>
                  <div class="row" *ngFor="let tr of group.liveRouteTraining; let trIndex = index" style="margin-left:1rem">
                    <div [ngClass]="(group.isRoadTest && group.isLiveRoute) ? 'col-md-4' : 'col-md-6'" *ngIf="(group.isPracticeHours)"> 
                      <label [id]="'lblTestHours_' + (tr.liveRouteTrainingId)"
                             [for]="'testHours_' + (tr.liveRouteTrainingId)"
                             [ngClass]="{ 'label-validation-error': practiceHoursControlDictionary[tr.liveRouteTrainingId].invalid, 'hours-label':true }">
                        Hours:
                      </label>
                      <input [id]="'trainingHours_' + (tr.liveRouteTrainingId)"
                             type="number"
                             step="0.1"
                             [(ngModel)]="tr.trainingHours"
                             [formControl]="practiceHoursControlDictionary[tr.liveRouteTrainingId]"
                             placeholder="Hours"
                             class="form-control"/>
                    </div>
                    <div [ngClass]="(group.isRoadTest && group.isLiveRoute) ? 'col-md-4' : 'col-md-6'" *ngIf="(group.isRoadTest || group.isLiveRoute)">
                      <mat-label [id]="'lblTestDate_' + (tr.liveRouteTrainingId)"
                             [for]="'testDate_' + (tr.liveRouteTrainingId)"
                             [ngClass]="{ 'label-validation-error': liveRouteDateControlDictionary[tr.liveRouteTrainingId].invalid, 'date-picker-label':true }">
                        Date:
                      </mat-label>
                      <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Date:</mat-label>
                        <input class="input-date" matInput [matDatepicker]="roadTestDatePicker" [max]="maxDate" 
                        [(ngModel)]="tr.trainingDate" [formControl]="liveRouteDateControlDictionary[tr.liveRouteTrainingId]">
                        <mat-datepicker-toggle matIconSuffix [for]="roadTestDatePicker"></mat-datepicker-toggle>
                          <mat-datepicker #roadTestDatePicker
                            disabled="false">
                            <mat-datepicker-actions>
                              <button mat-button (click)="roadTestDatePicker.select(actualDate); roadTestDatePicker.close()">
                                <mat-icon>history</mat-icon>
                                Today
                              </button>
                              <button mat-button (click)="roadTestDatePicker.select(null); roadTestDatePicker.close()">
                                <mat-icon>highlight_off</mat-icon>
                                Clear
                              </button>
                            </mat-datepicker-actions>
                        </mat-datepicker>
                      </mat-form-field>                      
                    </div>
                    <div [ngClass]="(group.isRoadTest && group.isLiveRoute) ? 'col-4' : 'col-6'">
                      <div class="md-form" style="margin-top:24px">
                        <input [id]="'testEmployeeId' + (ttIndex+1) + '_' +(trIndex+1)"
                               type="text"
                               [(ngModel)]="tr.qualifiedObserverEmployeeId"
                               class="form-control"
                               [formControl]="liveRouteIdControlDictionary[tr.liveRouteTrainingId]"
                               placeholder="Qualified Observer Employee ID" />
                        <mat-label [for]="'testEmployeeId' + (ttIndex+1) + '_' +(trIndex+1)" [ngClass]="{ 'label-validation-error': liveRouteIdControlDictionary[tr.liveRouteTrainingId].invalid, 'active':true }">Qualified Observer Employee ID</mat-label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-divider></mat-divider>        
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Location
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" style="margin-left:1rem;margin-right:1rem;">
            <div class="col-12">
              <div class="row extra-margin">
                <div class="col-12">
                  <mat-label>Driver's Location</mat-label>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-select placeholder="Choose Driver's Location" class="show-toggle-in-modal" [(value)]="driverInfo.locationId">
                      <input matInput class="select-search-input" placeholder="Search Locations..." (keyup)="filterOptions($event.target.value)">
                      <mat-option (onSelectionChange)="onStateChange($event)" *ngFor="let option of stateProvinceOptions" [value]="option.value">
                        {{option.label}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="{ 'label-validation-error': companyIdControl.invalid, 'active':true  }">Company</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-divider></mat-divider>
        <mat-expansion-panel>
        <mat-expansion-panel-header>
        <mat-panel-title>
          Training
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngIf="(!trainingOptions) || (trainingOptions.length <= 0)" class="row">
        <div class="col-12">
          <h4 style="text-align:center">***  All Training Lessons Have Been Assigned  ***</h4>
        </div>
      </div>
      <div *ngIf="trainingSlotsAvailable <= 0" class="row">
        <div class="col-12">
          <h4 style="text-align:center">***  No Slots Available  ***</h4>
        </div>
      </div>
      <div *ngIf="trainingSlotsAvailable > 0" class="row">
        <div class="col-12" style="text-align:right">
          <mat-label style="color:blue">
            You have used {{trainingSlotsUsed}} of {{trainingSlotsPurchased}} slots
          </mat-label>
        </div>
      </div>
      <div *ngIf="trainingOptions && (trainingOptions.length > 0) && (trainingSlotsAvailable > 0)" class="row" style="margin-left:1rem;margin-right:1rem;">
        <div class="col-12">
          <div class="row">
            <div class="col-4">
              <div>
                <mat-slide-toggle 
                [checked]="driverInfo.assignOnlineTraining" 
                [(ngModel)]="driverInfo.assignOnlineTraining">Assign Online Training
              </mat-slide-toggle>
              </div>
            </div>
            <div class="col-8">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Date Due:</mat-label>
                <input class="input-date" matInput disabled [matDatepicker]="assignOnlineTrainingDatePicker" 
                [(ngModel)]="driverInfo.assignOnlineTrainingDateDue"
                [max]="maxDate">
                <mat-datepicker-toggle matIconSuffix [for]="assignOnlineTrainingDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #assignOnlineTrainingDatePicker 
                  [disabled]="!driverInfo.assignOnlineTraining">
                    <mat-datepicker-actions>
                      <button mat-button (click)="assignOnlineTrainingDatePicker.select(actualDate); assignOnlineTrainingDatePicker.close()">
                        <mat-icon>history</mat-icon>
                        Today
                      </button>
                      <button mat-button (click)="assignOnlineTrainingDatePicker.select(null); assignOnlineTrainingDatePicker.close()">
                        <mat-icon>highlight_off</mat-icon>
                        Clear
                      </button>
                    </mat-datepicker-actions>
                </mat-datepicker>
              </mat-form-field>         
            </div>
          </div>
          <div class="row extra-margin">
            <div class="col-6">
              <div class="md-form">
                <input type="text"
                       id="searchText"
                       name="searchText"
                       class="form-control"
                       placeholder="Enter 3 or more characters to Search by Description"
                       [(ngModel)]="searchText" (ngModelChange)="onTextSearch()" />
                <mat-label for="searchText" class="active">Search</mat-label>
              </div>
            </div>
            <div class="col-6">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Search By Category</mat-label>
                <mat-select #trainingCategorySelect [(value)]="categorySearchValue" (selectionChange)="onCategorySearch($event)">
                  <input matInput class="select-search-input" placeholder="Search Categories..." (keyup)="filterCategoryOptions($event.target.value)">
                  <mat-option *ngFor="let option of filteredTrainingCategoryOptions" [value]="option.value">
                    {{option.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row rem1-margin">
            <div class="col-12">
              <div class="trainingListContainer">
                <ul>
                  <li *ngFor="let t of trainingOptionsFiltered">
                    <input type="checkbox" class="input-dark" name="checkb" id="checkb-{{t.value}}"
                      value="{{t.value}}" [(ngModel)]="t.selected"
                      [disabled]="!driverInfo.assignOnlineTraining" />
                    <label class="label-base" for="checkb-{{t.value}}">{{t.label}}</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="false">
            <div class="col-12">
            </div>
          </div>
        </div>
      </div>
        </mat-expansion-panel>
        <mat-divider></mat-divider>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Services
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" style="margin-left:1rem;margin-right:1rem;">
              <div class="col-12">
                <div class="row">
                  <div class="col-4">
                    <div>
                      <mat-slide-toggle 
                      [checked]="driverInfo.resetPassword" 
                      [(ngModel)]="driverInfo.resetPassword">Reset Password for the driver
                    </mat-slide-toggle>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</ng-container>
<div class="row">
  <div class="col-9">
  </div>
  <div class="col-3">
    <button type="button"
            (click)="validateBeforeSubmit()"
            class="btn btn-primary">
      Submit
    </button>
    <button type="button"
            (click)="exitDriverForm(false)"
            class="btn btn-primary">
      Exit
    </button>
  </div>
</div>