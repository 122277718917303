import { Inject, Injectable } from '@angular/core';
import { lastValueFrom, Subject } from "rxjs";
import { DataGrid } from '../data-grid-menu-component/data-grid-menu.component'
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class DataGridModalService {
  _http: HttpClient
  _baseUrl: string

  private grid = new Subject();
  grid$ = this.grid.asObservable();

  private gridFilters = new Subject();
  gridFilters$ = this.gridFilters.asObservable();


  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._http = http;
    this._baseUrl = baseUrl;
  }


  openModal(dataGridId: number) {
    let params = new HttpParams();
    params = params.append('DataGridId', dataGridId.toString());

    lastValueFrom(this._http.get(this._baseUrl + 'api/AdminDataGrid/GetById', {
      params: params
    }))
      .then(result => {
        this.grid.next(result as DataGrid);
      }).catch()

    this.getFilters(dataGridId);

  }

  openLeaveOfAbsenceReport(client: string) {
    let params = new HttpParams();
    params = params.append('LineOfBusiness', "2");
    params = params.append('Clients', client);
      lastValueFrom(this._http.get(this._baseUrl + 'api/Datagrid/GetByLineOfBusiness', {
      params: params
    }))
      .then(result => {

        let menuItems = result as Array<DataGrid>;
        let report = menuItems.find(x => x.title.toLowerCase() == "leave of absence report");
        if (report) {
          this.openModal(report.dataGridId);
        }

      }).catch()
  }

  getFilters(dataGridId: number) {
    let params = new HttpParams();
    params = params.append('DataGridId', dataGridId.toString());

      lastValueFrom(this._http.get(this._baseUrl + 'api/Datagrid/GridFilters', {
      params: params
    }))
      .then(result => {
        this.gridFilters.next(result as DataGridFilter[]);
      }).catch()
  }

  refreshOptions(request: DataGridFilter): Promise<any[]> {
    return lastValueFrom(this._http.post(this._baseUrl + 'api/Datagrid/RefreshFilterOptions',
      JSON.stringify(request),
      {
        headers: { 'Content-Type': 'application/json' },
      }
    ))
      .then(result => {
        return result as any[];
      }).catch();
  }

}


export class DataGridFilter {
  dataGridFilterId: number;
  dataGridId: number;
  dataGridFilterTypeId: number;
  dataGridSpInputParameter: string;
  filterType: string;
  displayName: string;
  dbName: string;
  textField: string;
  valueField: string;
  isMultiSelect: boolean;
  dataGridFilterRangeTypeId: number;
  rangeType: string;
  filterOptions: any[];
  value: any;
  dataGridFilterIDDependentOn: number;
  hasDependentFilter: boolean;
  filterOptionsFilter: string;
  disabled: boolean;
}
