//Angular
import { Component, Inject } from '@angular/core';
//Third Party
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//APP
import { ErrorObject } from "../classes-and-interfaces/classes-and-interfaces.component";
import { ErrorModalService } from "./error-modal-service.component";

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent {
  errorArray: Array<ErrorObject> = [];
  errorSubscription: Subscription;

  constructor(
    private errorService: ErrorModalService,
    private dialogRef: MatDialogRef<ErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {error: Array<ErrorObject>}) {
    this.errorArray = data.error;
  }

  closeErrorModal() {
    this.errorService.clearErrorObject();
    this.dialogRef.close();
  }

  calcErrorDetail(err: ErrorObject) {
    let isModelState: boolean = (err && (err.modelState != null));
    let isErrorsArray: boolean = (err && (err.errors != null));
    let isDetail: boolean = (err && err.detail && (err.detail.length > 0));
    let arrErr = [];
    if (isModelState) {
      // modelState will have a property for each field error.
      // the type of that property will be a string array. Add each
      // string in that array to the error detail array
      Object.keys(err.modelState).forEach(function (key, index) {
        if (key != "$type") {
          let arrErrFld = err.modelState[key];
          arrErrFld.forEach(function (errMsg) {
            arrErr.push(key + " : " + errMsg);
          });

        }
      });
    }

    if (isErrorsArray) {
      // errors will have a property for each field error.
      // the type of that property will be a string array. Add each
      // string in that array to the error detail array
      Object.keys(err.errors).forEach(function (key, index) {
        if (key != "$type") {
          let arrErrFld = err.errors[key];
          arrErrFld.forEach(function (errMsg) {
            arrErr.push(key + " : " + errMsg);
          });

        }
      });
    }

    if (isDetail) {
      // return a one element array with the detail property 
      arrErr.push(err.detail);
    } else {
      if (typeof err === 'string' || err instanceof String) arrErr.push(err);
    }

    if(arrErr.length == 0 ) arrErr.push("An error has occurred");
    return arrErr;
  }
}
