<div class="row mt-3"  *ngFor="let u of units">
  <div class="col-md-12">
    <div class="card card-body">
      <div class="row">
        <div class="col-md-1">Vehicle:</div>
        <div class="col-md-11 font-weight-bold">{{u.unitNumber}}</div>
      </div>

      <div class="row">
        <div class="col-md-1">Year:</div>
        <div class="col-md-3 font-weight-bold">{{u.vehicleYear}}</div>

        <div class="col-md-1">Make:</div>
        <div class="col-md-3 font-weight-bold">{{u.vehicleMake}}</div>

        <div class="col-md-1">Model:</div>
        <div class="col-md-3 font-weight-bold">{{u.vehicleModel}}</div>
      </div>
      <div class="row">
        <div class="col-md-1">Mileage:</div>
        <div class="col-md-3 font-weight-bold">{{u.vehicleOdometer}}</div>

        <div class="col-md-1">Lic. Plate:</div>
        <div class="col-md-3 font-weight-bold">{{u.licensePlate}} <span *ngIf="u.licensePlate!='UNKNOWN'">({{u.licensePlateState}})</span></div>

        <div class="col-md-1">VIN:</div>
        <div class="col-md-3 font-weight-bold">{{u.vin}}</div>
      </div>
      <div class="row">
        <div class="col-md-2">Current Vehicle Location:</div>
        <div class="col-md-10 font-weight-bold">{{u.currentLocation}}</div>
      </div>
      <div class="row d-flex flex-wrap">
        <div class="col-md-2">Damage:</div>
        <div class="col-md-10 wrap-text font-weight-bold">{{u.damageDescription}}</div>
      </div>
    </div>
  </div>
</div>
