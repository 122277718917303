<div class="row">
  <div class="col-md-12">
    <app-loading-panel *ngIf="!configObject"></app-loading-panel>
    <div *ngIf="configObject">
      <app-data-table 
        [columns]="configObject.fields"
        [data]="configObject.data"
        noRecordsLabel="No Programs/Services information"
        (triggerOpenModal)="openModal($event)"
      ></app-data-table>
    </div>
  </div>
</div>
