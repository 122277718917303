<div class="box">
  <div class="card-header">
    <div class="card-default drag-handler" style="float:left;width:90%">
      <h5 class="panel-title">
        {{card.title}}
      </h5>
    </div>
    <div class="card-default"
         style="float:right;width:10%"
         (mousedown)="$event.stopPropagation()"
         (touchstart)="$event.stopPropagation()">
      <h5 class="panel-title">
        <div class="dropdown" dropdown placement="left">
          <a style="float:right">
            <span [matMenuTriggerFor]="menu" class="fa fa-ellipsis-h"></span>
          </a>
          <mat-menu #menu="matMenu">
            <a class="dropdown-item"
               (click)="showCardFilterModal(card)"
               *ngIf="(card.tileDateFilterTypeId==1)||(card.tileDateFilterTypeId==2)">
              Change Dates
            </a>
            <a class="dropdown-item"
               (click)="showCardSlicerModal(card)"
               *ngIf="card.hasSlicer">
              Filter
            </a>
            <a class="dropdown-item" (click)="showCardModal(card)" *ngIf="card?.jsChartTypeId == 1">Zoom</a>
            <a class="dropdown-item" (click)="removeItem(card)">Remove</a>
            <a class="dropdown-item" (click)="export(card)" *ngIf="card?.jsChartTypeId == 1">Export Image</a>
          </mat-menu>
        </div>
      </h5>
    </div>
  </div>
  <div [ngClass]="isTileFilterApplied(card)?'card-block cardBodyBorderHasTileFilter':'card-block cardBodyBorderDefault'">
    <ng-content></ng-content>
  </div>
</div>
