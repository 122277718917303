<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree-container">
  <mat-tree-node class="link" *matTreeNodeDef="let node" (click)="elementClick(node, $event)">
      {{node.element}}
      <a *ngIf="showAddGroupButton && (!node.isDriver)" class="ml-2" role="button" (click)="openAddGroupPopup(node)">+</a>
      <a *ngIf="showAddDriverButton && node.isLowestTier && (!showAddGroupButton) && (!node.isDriver) && this.LineOfBusiness != 7" role="button" class="addDriverIcon" title="Add New Driver" (click)="openNewDriverForm(node)">
        <i class="fa fa-user-plus"></i>
      </a>
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div class="mat-tree-node">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name" (click)="elementClick(node, $event)">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        {{node.element}}  
        <a *ngIf="showAddGroupButton && (!node.isDriver)" class="ml-2" role="button" (click)="openAddGroupPopup(node)">+</a>
        <a *ngIf="showAddDriverButton && node.isLowestTier && (!showAddGroupButton) && (!node.isDriver) && this.LineOfBusiness != 7" role="button" class="addDriverIcon" title="Add New Driver" (click)="openNewDriverForm(node)">
          <i class="fa fa-user-plus"></i>
        </a>
      </div>
      <div 
        class="group"
        role="group"  
        [class.tree-invisible]="!treeControl.isExpanded(node)">
       <ng-template matTreeNodeOutlet></ng-template>
    </div>
  </mat-nested-tree-node>
</mat-tree>

