import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BlockBlobClient, BlobClient, BlobUploadCommonResponse, BlobServiceClient } from "@azure/storage-blob";
import { BlobDownloadResponse } from '@azure/storage-blob/typings/latest/src/generated/src/models';
import { Observable } from "rxjs";
import { environment } from '../../../environments/environment';
import { AzureDocument, DbDocumentAzureInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';

@Injectable()
export class BlobStorageService {
  constructor(private http: HttpClient
  ) {}

  
  getBlob(blob: DbDocumentAzureInfo, storageUrl: string) : Promise<BlobDownloadResponse> {
    const client: BlobClient = new BlobClient('BlobEndpoint=' + storageUrl + ';SharedAccessSignature=' + blob.azureSharedAccessSignature, blob.blobContainer, blob.fileName);
    return client.download();
  }


  uploadBlob(blob: AzureDocument, file: File, storageUrl: string) : Promise<BlobUploadCommonResponse> {
    const blockBlobclient: BlockBlobClient = new BlockBlobClient('BlobEndpoint=' + storageUrl+ ';SharedAccessSignature=' + blob.azureSharedAccessSignature, blob.azureBlobContainerName, blob.azureFileName);    
    return blockBlobclient.uploadBrowserData(file, {
      blockSize: 32 * 1024 * 1024, // 32MB block size --- 
      // From Microsoft: We have increased the maximum allowable block size from 4 MB to 100 MB, while maintaining support for up to 50,000 blocks committed to a single Blob. 
      // Figure 32MB blocks @ 50,000 blocks per blob will give us blobs up to 1.2 terabytes - https://azure.microsoft.com/en-us/blog/general-availability-larger-block-blobs-in-azure-storage/
      // may want to experiment with different block sizes if this becomes an issue in the future or we add a progress bar
      concurrency: 20, // 20 concurrency
      onProgress: (ev) => console.log(ev),  // use this in future story to show progress... maybe emit to bound screen field... small blocks can show progress more often, but we are only allound 50,000 per file and 1 terrabyte is requested    
      blobHTTPHeaders: {
        blobContentType: file.type,
      }
    });
  }
}
