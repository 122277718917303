import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { UserRightsService } from '../../components/user-rights-service/user-rights-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { SafetyModalWindowService } from '../safety-modal-window/safety-modal-window-service.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { UserRightsInfo, ErrorObject } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';
import { DriverMaintenanceReportService } from '../driver-maintenance-report/driver-maintenance-report.service';
import { formatDate } from 'ngx-bootstrap/chronos';
import { Router } from '@angular/router';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'driver-maintenance-action',
  templateUrl: './driver-maintenance-action.component.html',
  styleUrls: ['./driver-maintenance-action.component.css']
})
export class DriverMaintenanceActionComponent implements OnInit {
  //@Input() modalHeight: string;
  //@Input() modalInput: any;

  //@ViewChild('driverMaintenaceModal') rejectModalComp: ModalDirective;

  // @Input() columnsArray: any[];
  @Input() row: any;
  selectedClient: string;
  //@Output() onReject: EventEmitter<any> = new EventEmitter<any>();
  //@Output() onCancel: EventEmitter<any> = new EventEmitter<any>();


  baseUrl: string;
  private driverId: string;

  // date vars for yesterday and today, with time stripped out
  // correct values are set in constructor
  private dtYesterday: Date = new Date((new Date()).toDateString());
  private dtTomorrow: Date = new Date((new Date()).toDateString());

  public page: number = 1;
  public itemsPerPage: number = 50;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;
  public today: Date = new Date(Date.now());
  public sortPageTime: number = 0;
  public dateFilterRangeType: number = 1;
  columns = [];

  //rowsRaw: Array<any>;
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  gtOptions: any = { numberOfRows: 500 };
  public configObject: any;

  constructor(private loadingSpinnerService: LoadingSpinnerService,
    private clientSelectionService: ClientSelectionService,
    private lineOfBusinessService: LineOfBusinessService,
    private userRightsService: UserRightsService,
    private errorService: ErrorModalService,
    private driverHistoryProfileService: DriverHistoryProfileService,
    private safetyModalWindowService: SafetyModalWindowService,
    private driverMaintenanceService: DriverMaintenanceReportService,
    private router: Router,
    private datepipe: DatePipe) {
    this.dtYesterday.setDate(this.dtYesterday.getDate() - 1);
    this.dtTomorrow.setDate(this.dtTomorrow.getDate() + 1);
  }

  ngOnInit(): void {

    this.initAll();


  }

  private initAll() {

    this.columns = [
      { name: "Driver Id", prop: "driverId", title: "Driver Id", visible: false, dataType: "number" },
      { name: "Employee Id", prop: "employeeID", title: "Employee Id", visible: true, dataType: "date" },
      { name: "Employee Name", prop: "employeeName", title: "Employee Name", visible: true, dataType: null },
      { name: "Active", prop: "active", title: "Active", visible: true, dataType: null },
      { name: "Corporate Structure", prop: "corporateStructure", title: "Corporate Structure", visible: true, dataType: "number" }
    ];

    this.selectedClient = this.clientSelectionService.getClientSelectedArrayValue(2)[0];

    this.row = this.driverMaintenanceService.row;



    var fields = new Array<any>();
    this.columns.forEach(s => {

      if (s.prop.toLowerCase() == "driverid") {
        //do nothing
      }
      else if (s.prop.toLowerCase() == "employeeid") {
        fields.push({
          name: s.title,
          objectKey: s.prop,
          //columnClass: 'clickable',
          //click: row => {
          //  this.navigateToDriverProfile((row["DriverID"] ? row["DriverID"] : row["DriverId"]));
          //}
        });
      }
      else
        fields.push({
          name: s.title,
          objectKey: s.prop,
          render: row => {

            if (s.dataType === "currency") {
              var formattedCurrencyValue = this.formatter.format(Math.abs(row[s.prop]))
              if (row[s.prop] >= 0)
                return formattedCurrencyValue;
              else {
                return "($" + formattedCurrencyValue + ")";
              }
            }
            else if (s.dataType === "date" && row[s.prop]) {
              try {
                return formatDate(new Date(row[s.prop]), "MM/DD/YYYY");
              }
              catch (err) {
                return row[s.prop];
              }
            }
            else if (row[s.prop] != null && Boolean(row[s.prop])) {
              return (row[s.prop] as boolean) ? "Yes" : "No";
            }
            else
              return row[s.prop]
          }
        });
    });


    var settings = this.columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: 'enable'
      };
    }).filter(x => x.objectKey.toLowerCase() != "driverid");


    this.configObject = {
      settings: settings,
      fields: fields,
      data: [this.row]

    };


    this.loadingSpinnerService.hide();


  }

  exit() {
    let randomVal = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
    this.router.navigate(['/safety/drivermaintenance/' + randomVal]);
  }

  keep() {
    this.driverMaintenanceService.submitKeepDrivers(this.selectedClient, [{ driverId: this.row.driverId }]).subscribe(result => {
      this.exit();
    });
  }
  remove() {
    this.driverMaintenanceService.submitRemoveDrivers(this.selectedClient, [{ driverId: this.row.driverId }]).subscribe(result => {
      this.exit();
    });
  }
  moveToGroup() {
    this.driverMaintenanceService.moveDriverToGroup(this.selectedClient, this.row.driverId).subscribe(result => {
      this.exit();
    });
  }

}
