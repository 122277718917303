import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { DHPAddNotesService } from './dhp-add-notes-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';
import { DriverNote } from '../../safety/models';

@Component({
  selector: 'dhp-add-notes',
  templateUrl: './dhp-add-notes.component.html',
  styleUrls: ['./dhp-add-notes.component.css']
})
export class DHPAddNotesComponent implements OnInit, OnDestroy {
  @Input() modalHeight: string;
  @Input() modalInput: any;
  note: DriverNote;
  // private variables that are only shared with subscribers that import the type
  dirty: boolean = false;
  formValidated: boolean = false;

  // the overloaded constructor for the controller
  constructor(private router: Router,
    private dhpService: DriverHistoryProfileService,
    private dhpAddNotesService: DHPAddNotesService,
    private http: HttpClient,
    private onPremService: OnPremDriverService,
    private alertService: AlertService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorService: ErrorModalService) {

  }

  // angular on intialization event
  ngOnInit() {
    this.note = new DriverNote(this.modalInput.driverId);
  }

  // provides a central point for handling errors
  handleError(message: string) {

  }

  // validates whether the user supplied a note and then makes the submit button available
  // if they did
  onKey(event: any) { // without type info
    if (event.target.value != null && event.target.value.length > 0) {
      this.formValidated = true;
    }
    else {
      this.formValidated = false;
    }
  }

  // submits the form values
  submit() {
    //console.log(this.note);

    let postData = this.note;

    this.loadingSpinnerService.show();
    this.onPremService.post('note',
      JSON.stringify(postData)
    ).subscribe({
      next: (data) => {
        // signal that driver profile data needs to be refreshed
        this.dhpService.setRefreshDriverProfileNotesData(this.modalInput.driverId);
        // notify the modal window to close
        this.dhpService.notifyModalClose();
        this.alertService.showSuccessAlert('Add Note Completed.', 'end', 'top', 5000);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });

  }

  // cancels the action
  cancel() {
    this.dhpService.notifyModalClose();
  }

  // sleeps for a certain number of milliseconds
  sleep(milliseconds: number) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }

  // the default destructor
  ngOnDestroy() {
  }
}
