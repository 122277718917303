<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
  <div class="card card-body mt-3">
    <div class="row extra-margin">
      <div class="col-sm-8">
        <mat-form-field appearance="fill" class="select-container w-100">
          <mat-label>Choose the Infraction Type</mat-label>
          <mat-select class="dhpAssignPointsSelect" [(value)]="pointsAssignment.auxiliaryPointInfractionTypeId" [formControl]="infractionControl">
            <mat-option *ngFor="let option of infractionOptions" [value]="option.value">
              {{option.label}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="infractionControl.invalid">Assign</mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-4">
        <div class="md-form">
          <input id="points" type="text" [(ngModel)]="pointsAssignment.points" class="form-control" [formControl]="pointsControl" placeholder="Points" />
          <label for="points" [ngClass]="{ 'label-validation-error': pointsControl.invalid, 'active':true }">Points</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label class="lbl-start-date">Date of Incident</mat-label>
          <input class="input-date" matInput disabled 
            [matDatepicker]="dateOfIncidentPicker" 
            [formControl]="dateOfIncidentControl" 
            [max]="actualDate"
            [(ngModel)]="pointsAssignment.infractionDate"
            (click)="dateOfIncidentPicker.open()">
          <mat-datepicker-toggle matSuffix [for]="dateOfIncidentPicker"></mat-datepicker-toggle>
          <mat-datepicker #dateOfIncidentPicker disabled="false">
            <mat-datepicker-actions>
              <button mat-button (click)="dateOfIncidentPicker.select(actualDate); dateOfIncidentPicker.close()">
                <mat-icon>history</mat-icon>
                Today
              </button>
              <button mat-button (click)="dateOfIncidentPicker.select(null); dateOfIncidentPicker.close()">
                <mat-icon>highlight_off</mat-icon>
                Clear
              </button>
            </mat-datepicker-actions> 
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="row extra-margin">
      <div class="col-sm-6">
        <mat-form-field appearance="fill" class="select-container w-100">
          <mat-label>Choose the Expiration Type</mat-label>
          <mat-select class="dhpAssignPointsSelect" 
            [(value)]="pointsAssignment.expireWithMvr" 
            [formControl]="expiresTypeControl"
            (selectionChange)="onChangeExpireType($event)">
            <mat-option *ngFor="let option of expiresTypeOptions" [value]="option.value">
              {{option.label}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="expireLabelInvalid">Expiration Date</mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6" *ngIf="(pointsAssignment.expireWithMvr != null)&&(!pointsAssignment.expireWithMvr)">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label class="lbl-start-date">Expiration Date</mat-label>
          <input class="input-date" matInput disabled 
            [matDatepicker]="expirationDatePicker" 
            [formControl]="expiresControl" 
            [min]="minDate"
            [(ngModel)]="pointsAssignment.expirationDate"
            (click)="expirationDatePicker.open()">
          <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #expirationDatePicker disabled="false">
            <mat-datepicker-actions>
              <button mat-button (click)="expirationDatePicker.select(actualDate); expirationDatePicker.close()">
                <mat-icon>history</mat-icon>
                Today
              </button>
              <button mat-button (click)="expirationDatePicker.select(null); expirationDatePicker.close()">
                <mat-icon>highlight_off</mat-icon>
                Clear
              </button>
            </mat-datepicker-actions> 
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="card card-body mt-3">
    <div class="row">
      <div class="col-sm-12">
        <h4 class="card-title text-muted">Notes</h4>
        <textarea class="form-control"
                  rows="3"
                  id="comment"
                  [(ngModel)]="pointsAssignment.noteText">
        </textarea>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-default"
          (click)="submit()">
    Submit
  </button>
  <button class="btn btn-default" style="margin-bottom:6px"
          (click)="cancel()">
    Exit
  </button>
</div>
