import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { ErrorObject } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';
import { RemovedDriversReportService } from './removed-drivers-report.service';
import { formatDate } from 'ngx-bootstrap/chronos';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'removed-drivers-report',
  templateUrl: './removed-drivers-report.component.html',
  styleUrls: ['./removed-drivers-report.component.css']
})
export class RemovedDriversReportComponent implements OnInit, OnDestroy {
  clientSelectedArray: Array<string>;
  clientIdSelectedArray: Array<number>;
  lineOfBusinessId: number;
  routeParamsSub: Subscription;
  gridRequestId: string;

  public page: number = 1;
  public itemsPerPage: number = 50;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;
  public today: Date = new Date(Date.now());
  public sortPageTime: number = 0;
  public dateFilterRangeType: number = 1;
  columns = [];

  rowsRaw: Array<any>;
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  gtOptions: any = { numberOfRows: 500 };
  public configObject: any;

  constructor(
    private route: ActivatedRoute,
    private loadingSpinnerService: LoadingSpinnerService,
    private clientSelectionService: ClientSelectionService,
    private lineOfBusinessService: LineOfBusinessService,
    private errorService: ErrorModalService,
    private removedDriversService: RemovedDriversReportService,
    private datepipe: DatePipe) { }

  ngOnInit(): void {
    // set line of business to safety if not already initialized to Safety
    this.lineOfBusinessId = 2;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }

    // get the selected client(s)
    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusinessId);

    this.routeParamsSub = this.route.params.subscribe(params => {
      this.gridRequestId = params['id'];
      if (this.gridRequestId && (this.gridRequestId.length > 0)) {
        let gridRequest = JSON.parse(sessionStorage.getItem('grid_' + this.gridRequestId));
        //console.log(gridRequest);
        this.initAll(gridRequest["StartDate"], gridRequest["EndDate"]);
      }
    }

    )



  }

  private initAll(startDate: string, endDate: string) {

    //this.columns = [
    //  { name: "Incidents", prop: "incidents", title: "Incidents", visible: true, dataType: "number" },
    //  { name: "Violation Date", prop: "violationDate", title: "Violation Date", visible: true, dataType: "date" },
    //  { name: "Convicted Date", prop: "convictionDate", title: "Convicted Date", visible: true, dataType: "date" },
    //  { name: "Description", prop: "masterDescription", title: "Description", visible: true, dataType: null },
    //  { name: "Points", prop: "totalPoints", title: "Points", visible: true, dataType: "number" }
    //];

    this.removedDriversService.getRemovedDriversReport(this.clientSelectedArray[0], startDate, endDate).subscribe((data: any) => {
      console.log(data);
      if (data && data.datasets) {
        if (data.data instanceof HttpErrorResponse) {
          this.errorService.setErrorObject(data.data.error as ErrorObject);
          return;
        }


        this.columns = data.datasets[0].columns.map(x => {
          return {
            prop: x.propertyName.replace(/^\w/, c => c.toLowerCase()),
            name: x.propertyName,
            title: x.displayName,
            dataType: x.dbTypeName
          };
        });

        //let extraColumns = [{ name: "Active", prop: "active", title: 'Active', sort: null, visible: true, dataType: null }]
        //this.columns = this.columns.concat(extraColumns);
        this.rowsRaw = (data.datasets[0].rows as any[]).slice(0, 50);
        //this.config.sorting.columns = data.data.columns

        // this.grid = data.data.grid;


        var fields = new Array<any>();
        this.columns.forEach(s => {
          if (s.prop.toLowerCase() == "driverid") {
            //fields.push({
            //  name: s.title,
            //  objectKey: s.prop,
            //  columnClass: 'clickable',
            //  click: row => {
            //    this.router.navigate(["/driver/history-profile/" + row[s.prop]]);
            //  }
            //});
          }
          /*
          else if (s.prop.toLowerCase() == "employeeid") {
            // console.log(row);
            fields.push({
              name: s.title,
              objectKey: s.prop,
              columnClass: 'clickable',
              click: row => {
                //this.navigateToDriverProfile((row["DriverID"] ? row["DriverID"] : row["DriverId"]));
              }
            });
          }
          */
          else
            fields.push({
              name: s.title,
              objectKey: s.prop,
              columnComponent: (s.prop === "active" ? { type: RemovedDriversReportActiveSwitchComponent } : null),
              type: this.formFieldType(s.prop),
              dataType: s.dataType,
              renderObject: ['keep', 'remove'].includes(s.prop) ? { component: 'removedDriversReport' } : null,
              component: s.prop === "active" ? 'removedDriversReportActiveSwitch' : null,
              componentObject: s.prop !== "active" ? null : {},
              render: row => {

                if (s.dataType === "currency") {
                  var formattedCurrencyValue = this.formatter.format(Math.abs(row[s.prop]))
                  if (row[s.prop] >= 0)
                    return formattedCurrencyValue;//"$" + row[s.prop];
                  else {
                    //formattedCurrencyValue = this.formatter.format(row[s.prop] -1)
                    return "($" + formattedCurrencyValue + ")";
                    //return "($" + (row[s.prop] * -1) + ")";
                  }
                }
                else if (s.dataType === "DateTime" && row[s.prop]) {
                  try {
                    return formatDate(new Date(row[s.prop]), "MM/DD/YYYY");
                  }
                  catch (err) {
                    return row[s.prop];
                  }
                }
                else if (row[s.prop] && Boolean(row[s.prop])) {
                  return (row[s.prop] as boolean) ? "Yes" : "No";
                }
                else if (s.prop === "keep") {
                  // create Re-Activate button
                  return '<input type="checkbox" class="cbxOptOut"  style="opacity:1;pointer-events:all;" id="cbx_' + row['driverId'] + '">';
                }
                else if (s.prop === "remove") {
                  // create Re-Activate button
                  return '<input type="checkbox" class="cbxOptOut"  style="opacity:1;pointer-events:all;" id="cbx_' + row['driverId'] + '">';
                }
                else
                  return row[s.prop]
              },
              value: row => {
                let val = row[s.prop];

                if (s.prop === "keep") {
                  val = '<input type="checkbox">';
                  console.log("keep:" + val);
                }
                else if (s.prop === "remove") {
                  val = '<input type="checkbox">';
                }
                return val;
              },
            });
        });

        //console.log("ok2");
        //console.log(this.columns);

        var settings = this.columns.map(function (s) {
          return {
            objectKey: s.prop,
            sort: 'enable'
          };
        }).filter(x => x.objectKey.toLowerCase() != "driverid");

        this.configObject = {
          settings: settings,
          fields: fields,
          data: data.datasets[0].rows,

        };

      } else {
        //this.invalidGridData = true;
      }
      this.loadingSpinnerService.hide();
    });

  }

  submit() {
    this.loadingSpinnerService.show();
    let driverIds = this.configObject.data.filter(d => d.active).map(d => d.driverId.toString()).join(",");
    if (driverIds.length > 0) {
      this.removedDriversService.reactivateDrivers(this.clientSelectedArray[0], `"${driverIds}"`).subscribe({
        next: (data) => {
          let gridRequest = JSON.parse(sessionStorage.getItem('grid_' + this.gridRequestId));
          this.initAll(gridRequest["StartDate"], gridRequest["EndDate"]);
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    } else {
      this.errorService.setErrorObject({ message: "Please set one or more Drivers to be Active" });
      this.loadingSpinnerService.hide();
    }

  }

  formFieldType(prop: string) {
    let type = "";

    if (prop === 'active') {
      type = 'component';
    } else if (['keep', 'remove'].includes(prop)) {
      type = 'html'
    } else {
      type = 'text';
    }

    return type;
  }

  ngOnDestroy() {
    this.routeParamsSub.unsubscribe();
  }


}

// custom component for active/inactive switch
@Component({
  selector: 'app-removed-drivers-report-active-switch',
  template: `
  <div class="chbx">
    <input [checked]="row.active" (change)="toggleIsActive(row.driverId, !row.active)" type="checkbox" class="cbxOptOut"  style="opacity:1;pointer-events:all;">
  </div>
  `
})
export class RemovedDriversReportActiveSwitchComponent {
  //@Input() clientSelectedArray: Array<string>;
  //@Input() gridRequestId: string;
  @Input() row: any;

  constructor(/*private router: Router,
    private removedDriversService: RemovedDriversReportService,
    private errorService: ErrorModalService*/) {
  }

  toggleIsActive(driverId: number, isActive: boolean) {
    this.row.active = isActive;
    /* 
    this.removedDriversService.reactivateDrivers(this.clientSelectedArray[0], this.row.driverId).subscribe(data => {
      this.router.navigate(["safety/removeddrivers/" + this.gridRequestId]);
    },
      (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
      });
      */
  }
}
