//Angular
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
//Third Party
import { Subscription } from "rxjs";
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
//APP
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { AccidentClaimSupplementalData } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { MatSelectOption } from '../../shared/models/mat-select-option.model';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-accident-claim-supplemental-data',
  templateUrl: './accident-claim-supplemental-data.component.html',
  styleUrls: ['./accident-claim-supplemental-data.component.scss']
})
export class AccidentClaimSupplementalDataComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dataRepairDatePicker') dataRepairDatePicker: MatDatepicker<Date>;
  @ViewChild("selectLeaseTypes", { static: false }) selectLeaseTypes: MatSelect;
  @ViewChild("selectLiabilityTypes", { static: false }) selectLiabilityTypes: MatSelect;
  _claimId: number;
  _routeSub: Subscription;
  leaseTypeOptions: Array<MatSelectOption>;
  filteredLeaseTypeOptions: Array<MatSelectOption>
  liabilityTypeOptions: Array<MatSelectOption>;
  filteredLiabilityTypeOptions: Array<MatSelectOption>;
  supplementalData: AccidentClaimSupplementalData = new AccidentClaimSupplementalData();

  expenseSum: number = 0;
  receivedSum: number = 0;
  maxDate = new Date();

  actualDate = new Date();


  constructor(private _route: ActivatedRoute,
    private accidentClaimService: AccidentClaimService,
    private datepipe: DatePipe,
    private errorService: ErrorModalService,
    private snackBar: MatSnackBar,
    private loadingSpinnerService: LoadingSpinnerService) {
  }


  printPage(): void {
    window.print();
  }

  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];
      this.initAll();
    });

    this.maxDate.setDate(this.maxDate.getDate() + 1);
    this.maxDate.setMonth(this.maxDate.getMonth() + 1);
  }

  ngAfterViewInit(): void {
    this.dataRepairDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.dataRepairDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
          this.dataRepairDatePicker.select(event.value);
          this.dataRepairDatePicker.close();
        })
      }, 0)
    })

    this.selectLeaseTypes._elementRef.nativeElement.addEventListener(
      "keydown",
      (event) => {
        if (event.code === "Space") {
          event.preventDefault();
        }
      }
    );

    this.selectLiabilityTypes._elementRef.nativeElement.addEventListener(
      "keydown",
      (event) => {
        if (event.code === "Space") {
          event.preventDefault();
        }
      }
    );
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
  }

  roundNumber(value: string): number {
    return +(parseFloat(value).toFixed(2));
  }

  update(): void {
    this.loadingSpinnerService.show();

    this.supplementalData.merchantsLeaseTypeId = +this.supplementalData.merchantsLeaseTypeIdString;
    this.supplementalData.merchantsLiabilityId = +this.supplementalData.merchantsLiabilityIdString;

    this.accidentClaimService.updateSupplementalData(this.supplementalData).subscribe({
      next: () => {
        this.snackBar.open('Supplemental data saved successfully', 'Ok', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5000,
          panelClass: 'success-snackbar'
        })
        this.getSupplementalData();
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.loadingSpinnerService.hide();
        this.errorService.setErrorObject(err.error);
      }
    });
  }


  private initAll(): void {
    this.accidentClaimService.getMerchantsLeaseTypes().subscribe({
      next: (leaseData) => {
        this.leaseTypeOptions = this.transformLeaseChoices(leaseData as Array<any>);
        this.filteredLeaseTypeOptions = this.leaseTypeOptions;
        this.accidentClaimService.getMerchantsLiabilityTypes().subscribe({
          next: (liabilityData) => {
            this.liabilityTypeOptions = this.transformLiabilityChoices(liabilityData as Array<any>);
            this.filteredLiabilityTypeOptions = this.liabilityTypeOptions;
            this.getSupplementalData();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.setErrorObject(err.error);
          }
        });
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
      }
    });
  }

  private getSupplementalData() {
    this.accidentClaimService.getSupplementalData(this._claimId).subscribe({
      next: (data) => {
        if (data) {
          this.supplementalData = data as AccidentClaimSupplementalData;

          if (this.supplementalData.merchantsPaidDate) {
            this.supplementalData.merchantsPaidDate = this.datepipe.transform(this.supplementalData.merchantsPaidDate, "MM/dd/yyyy");
          }

          if (this.supplementalData.merchantsLeaseTypeId) {
            this.supplementalData.merchantsLeaseTypeIdString = this.supplementalData.merchantsLeaseTypeId.toString();
          }

          if (this.supplementalData.merchantsLiabilityId) {
            this.supplementalData.merchantsLiabilityIdString = this.supplementalData.merchantsLiabilityId.toString();
          }

          this.expenseSum = this.supplementalData.towingAmount + this.supplementalData.storageAmount + this.supplementalData.rentalExpenseAmount +
            this.supplementalData.glassPaymentAmount + this.supplementalData.policeReportExpenseAmount + this.supplementalData.merchantsPaidAmount + this.supplementalData.miscExpenseAmount;
          this.receivedSum = this.supplementalData.receivedAmount + this.supplementalData.deductibleReceivedAmount + this.supplementalData.rentalReimbursementAmount +
            this.supplementalData.salvageAmount + this.supplementalData.miscReceivedAmount;
        }

        this.supplementalData.claimId = this._claimId;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
      }
    });
  }

  private transformLeaseChoices(data: Array<any>): Array<any> {
    const tempin: Array<LeaseType> = data as Array<LeaseType>;
    const tempout: Array<any> = [];
    tempin.forEach((item: LeaseType, index: number) => {
      const tempobj = {
        value: item.merchantsLeaseTypeId.toString(),
        label: item.merchantsLeaseType
      };
      tempout.push(tempobj);
    })
    return tempout;
  }

  private transformLiabilityChoices(data: Array<any>): Array<any> {
    const tempin: Array<LiabilityType> = data as Array<LiabilityType>;
    const tempout: Array<any> = [];
    tempin.forEach((item: LiabilityType, index: number) => {
      const tempobj = {
        value: item.merchantsLiabilityId.toString(),
        label: item.merchantsLiability
      };
      tempout.push(tempobj);
    })
    return tempout;
  }

  filterLeaseTypeOptions(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredLeaseTypeOptions = this.leaseTypeOptions.filter((option) =>
      option.label.toLowerCase().startsWith(value)
    );
  }

  filterLiabilityTypeOptions(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredLiabilityTypeOptions = this.liabilityTypeOptions.filter((option) =>
      option.label.toLowerCase().startsWith(value)
    );
  }

}

export class LeaseType {
  merchantsLeaseTypeId: number;
  merchantsLeaseType: string;
}

export class LiabilityType {
  merchantsLiabilityId: number;
  merchantsLiability: string;
}
