<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <div class="form-inline">
            <h4 class="card-title text-muted">Assigned Products</h4>
            <button class="btn btn-xs btn-default p-1" style="margin:-10px 0 0 10px; width:150px;" (click)="assignProgramDialog()">
              Assign Program
            </button>
          </div>

          <app-loading-panel *ngIf="!driverProgramTableConfig"></app-loading-panel>
          <div *ngIf="driverProgramTableConfig">
            <app-data-table 
              [columns]="driverProgramTableConfig.fields"
              [data]="driverProgramTableConfig.data"
              (triggerAction)="onTriggerAction($event)"
              noRecordsLabel="No Program information"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

