import { Component, OnInit, Inject } from '@angular/core';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import { LineOfBusinessService } from "../../components/line-of-business-service/line-of-business-service.component";
import { HttpErrorResponse } from '@angular/common/http';
import { DcModalWindowService } from '../dc-modal-window/dc-modal-window-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { UserRightsInfo, ErrorObject } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { DataGridEmailService, DcDriverTimeClockGridRequest } from '../../components/data-grid-export-email/data-grid-export-email.service';
import { Subscription } from 'rxjs';
import { ReportRequest } from '../../components/data-grid/data-grid.service';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';


@Component({
  selector: 'dc-mobile-time-clock-report',
  templateUrl: './dc-mobile-time-clock-report.component.html',
  styleUrls: ['./dc-mobile-time-clock-report.component.scss']
})
export class DcMobileTimeClockReportComponent implements OnInit {

  clientSelectedArray: Array<string>;
  clientIdSelectedArray: Array<number>;
  companySelectedGuid: string;
  lineOfBusinessId: number;
  baseUrl: string;
  driverBaseInfo: any;
  _userRights: Array<UserRightsInfo>;
  loaNotFound: boolean = false;
  canTerminateDriver: boolean = false;
  isActiveLeave: boolean = false;
  routeParamsSub: Subscription;
  gridRequestId: string;
  gridRequest: ReportRequest;

  public page = 1;
  public maxSize = 5;
  public itemsPerPage = 50;
  public numPages = 1;
  public length = 0;
  public today = new Date(Date.now());
  public sortPageTime = 0;
  public dateFilterRangeType = 1;
  columns = [];
  keepDrivers = [];
  removeDrivers = [];

  rowsRaw: Array<any>;
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  public configObject: any;

  constructor(@Inject('BASE_URL') baseUrl: string,
    private route: ActivatedRoute,
    private loadingSpinnerService: LoadingSpinnerService,
    private clientSelectionService: ClientSelectionService,
    private lineOfBusinessService: LineOfBusinessService,
    readonly dcModalWindowService: DcModalWindowService,
    readonly errorService: ErrorModalService,
    private onPremTimeclockService: OnPremDcService,
    private fileDownloadService: FileDownloadService,
    private emailService: DataGridEmailService,
    readonly router: Router,
    public datePipe: DatePipe) {
    this.baseUrl = baseUrl;
    this.configObject = { settings: [], fields: [], data: [] }
  }

  ngOnInit(): void {
    // set line of business to safety if not already initialized to Safety
    this.lineOfBusinessId = 7;
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 7) {
      this.lineOfBusinessService.setLineOfBusiness(7);
    }

    // get the selected client(s)
    this.clientSelectedArray = this.clientSelectionService.getSavedClientShortNames(this.lineOfBusinessId);
    this.routeParamsSub = this.route.params.subscribe(params => {
      this.gridRequestId = params['id'];
      if (this.gridRequestId && (this.gridRequestId.length > 0)) {
        this.gridRequest = JSON.parse(sessionStorage.getItem('grid_' + this.gridRequestId));
        this.initAll();
      }
    });


  }

  onPrint() {
    let sortParams = "";

    let request: ReportRequest = JSON.parse(JSON.stringify(this.gridRequest));
    if (!request.clickTrough) {
      request.ParameterValues.forEach(x => {
        if (x.filterType != 'Date') {
          x.DisplayValue = "";
          x.ParameterName = "";
        }
      });
    }
    request.GroupGuid = this.companySelectedGuid;

    let requestParam = btoa(JSON.stringify(request));
    this.fileDownloadService.openTab(this.baseUrl + "api/DeliveryContractors/PrintTimeClockReport?request=" + encodeURIComponent(requestParam));
  }
  onExcel() {
    let request: ReportRequest = JSON.parse(JSON.stringify(this.gridRequest));
    if (!request.clickTrough) {
      request.ParameterValues.forEach(x => {
        if (x.filterType !== 'Date') {
          x.DisplayValue = "";
          x.ParameterName = "";
        }
      });
    }
    request.GroupGuid = this.companySelectedGuid;

    let requestParam = btoa(JSON.stringify(request));
    this.fileDownloadService.saveFile(this.baseUrl + "api/DeliveryContractors/TimeClockReportExcel?request=" + encodeURIComponent(requestParam));
  }

  onEmail() {

    let gridRequest: ReportRequest = JSON.parse(JSON.stringify(this.gridRequest));
    if (!gridRequest.clickTrough) {
      gridRequest.ParameterValues.forEach(x => {
        if (x.filterType !== 'Date') {
          x.DisplayValue = "";
          x.ParameterName = "";
        }
      });
    }
    gridRequest.GroupGuid = this.companySelectedGuid;

    let request = new DcDriverTimeClockGridRequest();
    request.DataGridRequest = gridRequest;

    request.SelectedClient = this.clientSelectedArray[0];
    this.emailService.OpenEmailPopup(request);
  }

  private initAll() {
    this.loadingSpinnerService.show();
    const companyParameter = this.gridRequest.ParameterValues.find(x => x.ParameterName === "Company")
    if (companyParameter)
      this.companySelectedGuid = companyParameter.Value

    var params = {
      parameters: {
        Client: "FEDEX1",
        GroupGuid: this.companySelectedGuid,//"AEC069EF-0759-44CC-AC47-44D33D711D1E",
        StartDate: this.gridRequest.StartDate, //"2017-03-08",
        EndDate: null
      }
    }
    this.onPremTimeclockService.post('reports/4', JSON.stringify(params)).subscribe({
      next: (data: any) => {
        if (data) {
          if (data instanceof HttpErrorResponse) {
            this.errorService.setErrorObject(data.error as ErrorObject);
            return;
          }

          this.columns = data[0].columns.map(x => {

            let col = {
              prop: x.propertyName,
              name: x.propertyName,
              title: x.displayName,
              dataType: undefined
            }
            if (x.dbTypeName === "DateTime")
              col.dataType = "date"
            return col;
          });

          this.rowsRaw = (data[0].rows as any[]).slice(0, 50);

          var fields = new Array<any>();
          this.columns.forEach(s => {
            if (s.prop.toLowerCase() === "driverid") {
              //do nothing
            }
            else if (s.prop.toLowerCase() === "employeeid") {
              fields.push({
                name: s.title,
                objectKey: s.prop,
                columnClass: 'clickable',
                click: row => {
                  this.router.navigate(["/dc/driverprofile/" + row['DriverID']])
                }
              });
            }
            else
              fields.push({
                name: s.title,
                objectKey: s.prop,
                dataType: s.dataType,
                hidden: !s.visible,
                render: row => {

                  if (s.dataType === "currency") {
                    var formattedCurrencyValue = this.formatter.format(Math.abs(row[s.prop]))
                    if (row[s.prop] >= 0)
                      return formattedCurrencyValue;
                    else {
                      return "($" + formattedCurrencyValue + ")";
                    }
                  }
                  else if (s.dataType === "date" && row[s.prop]) {
                    try {

                      try {
                        return this.datePipe.transform(row[s.prop], 'MM/dd/yyyy');
                      } catch (e) { }
                    }
                    catch (err) {
                      return row[s.prop];
                    }
                  }
                  else if (row[s.prop] != null && Boolean(row[s.prop])) {
                    return (row[s.prop] as boolean) ? "Yes" : "No";
                  }
                  else
                    return row[s.prop]
                },
                value: row => {
                  let val = row[s.prop];
                  if (s.dataType === "date") {
                    // use ISO string as value for date sorting
                    try {
                      val = new Date(val).toISOString();
                    } catch (e) { }
                  }
                  if (s.dataType === "number") {
                    // convert to number
                    try {
                      val = +val;
                    } catch (e) { }
                  }
                  return val;
                },

              });
          });

          var settings = this.columns.map(function (s) {
            return {
              objectKey: s.prop,
              sort: 'disable'
            };
          }).filter(x => x.objectKey.toLowerCase() !== "driverid");


          this.configObject = {
            settings: settings,
            fields: fields,
            data: data[0].rows,

          };
        }

        this.loadingSpinnerService.hide();
      },
      error: (err) => {
        this.loadingSpinnerService.hide();
        this.errorService.setErrorObject(err);
      }
    });

  }
}




