import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityTimeService {

  constructor() { }

  static getDaysDifference(d1: Date, d2: Date): number {
    const t1 = d1.getTime();
    const t2 = d2.getTime();
    const diff = t2 - t1;
    const minutes = 24 * 60 * 60 * 1000;
    return Math.round(diff / minutes);
  }
  static getYearDifference(d1: Date, d2: Date): number {
    var years = d2.getFullYear() - d1.getFullYear();
    var m = d2.getMonth() - d1.getMonth();
    if (m < 0 || (m === 0 && d2.getDate() < d1.getDate())) {
        years--;
    }
    return years;
  }
  static isDaysDifferenceGtOrEq90(d1: Date) : boolean {
    return this.getDaysDifference(new Date(d1), new Date()) >= 90;
  }
  static isAgeLessThan18(dob: Date): boolean {
    return this.getYearDifference(dob, new Date()) < 18;
  }
}
