import { Component, OnInit } from '@angular/core';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { OnPremDcService } from '../../components/on-prem-service/on-prem-dc-service.component';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { DcSubscription } from '../dc-classes-and-interfaces/dc-classes-and-interfaces.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'dc-programs-summary',
  templateUrl: './dc-programs-summary.component.html'
})

export class DcProgramsSummaryComponent implements OnInit {
  companyId: number;
  companyName:string;
  billingEmailAddress: string;
  billingContactId: number;
  fullHierarchy: string;
  public configObject: any;
  gtOptions: any = {};
  arrProgram:Array<DcSubscription>;

  constructor(private datePipe: DatePipe,
    private loadingSpinnerService: LoadingSpinnerService,
    private onPremService: OnPremDcService,
    private lineOfBusinessService: LineOfBusinessService,
    private errorService: ErrorModalService){}

  ngOnInit() {
    let lob = this.lineOfBusinessService.getLineOfBusinessValue();
    if (lob != 7) {
      this.lineOfBusinessService.setLineOfBusiness(7);
    }

  }

  initPage(companyObject:any) {
    // called after user selects a company
    this.configObject = null;
    this.companyId = companyObject.companyId;
    this.companyName = companyObject.companyName;
    this.billingEmailAddress = companyObject.billingEmailAddress;
    this.billingContactId = companyObject.billingContactId;
    var hierarchyString = companyObject.fullHierarchy.replace(' -> ' + this.companyName, "");
    this.fullHierarchy = hierarchyString; 

    if (this.companyId) {
      this.loadingSpinnerService.show();

      this.getData(this.companyId).subscribe({ 
        next: (data) => {
          this.arrProgram = data as Array<DcSubscription>;
          this.arrProgram.forEach(p => p.productName = p.product.description);
          this.setupGrid(this.arrProgram);
          this.loadingSpinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
    } 
  }

  private setupGrid(data: Array<DcSubscription>) {
    let columns = [
      { name: "Name", prop: "productName", title: "Name", visible: true, dataType: null },
      { name: "Slots Purchased", prop: "slotsPurchased", title: "Slots Purchased", visible: true , dataType: "number" },
      { name: "Slots Used", prop: "slotsUsed", title: "Slots Used", visible: true, dataType: "number" },
      { name: "Expiration Date", prop: "expirationDate", title: "Expiration Date", dataType: "date", visible: true }
    ];

    let settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: 'enable'
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s.dataType,
        hidden: !s.visible,
        render: row => {
          let r = row[s.prop];
          switch (s.dataType) {
            case "date":
              try {
                r = this.datePipe.transform(row[s.prop], 'MM/dd/yyyy');
              } catch (e) { }
              break;
          }

          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.configObject = {
      settings: settings,
      fields: fields,
      data: data
    }

    this.gtOptions = (data.length > 1 ? { numberOfRows: 50 } : {});
  }

  private getData(companyId: number) {
    if (companyId > 0) {
      //return this.onPremService.get("companies/" + companyId.toString() + "/products");
      return this.onPremService.get("companies/" + companyId.toString() + "/account");
    }
  }

}

