import { Component, Input } from '@angular/core';
import { SettingsPageRoleInfo } from '../settings-page/settings-page.component'

@Component({
  selector: 'app-settings-role-panel',
  templateUrl: './settings-role-panel.component.html'
})
export class SettingsRolePanelComponent {
    @Input() card: SettingsPageRoleInfo;
}
