<div id="modalBody" class="modal-body" [ngStyle]="{'height':modalHeight}">
  <div class="row">
    <div class="col-md-12">
      <div class="card card-body mt-3">
        <div [ngClass]="{ 'label-validation-error': followUpDateInvalid, 'active':true }">
          My Follow-up Date:
        </div>
        <div class="">
          <mat-form-field appearance="fill">
            <mat-label class="lbl-start-date">Follow-up Date</mat-label>
            <input class="input-date" matInput disabled [matDatepicker]="datePicker" [min]="minDate"
            [formControl]="followUpDateControl" [(ngModel)]="followUpDateObject.followUpDate">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker disabled="false">
            </mat-datepicker>
          </mat-form-field>
        </div>
        <label>Please type in your note and click the Assign Follow-up Date Button:</label>
        <textarea class="form-control" rows="5" id="comments" [(ngModel)]="followUpDateObject.noteText" [name]="followUpDateObject.noteText"></textarea>
        <input type="checkbox"
               title="E-Mail this note to Client Representative"
               id="chkEmailClientRep"
               name="chkEmailClientRep" />
        <div class="switch">
          <label>
            <input [checked]="followUpDateObject.sendEmail" (change)="followUpDateObject.sendEmail=!followUpDateObject.sendEmail" type="checkbox">
            <span class="lever enableWidgetSwitch"></span>
            <span class="enableWidgetLabel">E-Mail a follow-up reminder on this date</span>
          </label>
        </div>
        <!--<label class="label-base" for="chkEmailClientRep">E-Mail a follow-up reminder on this date</label>-->
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-default"
          (click)="submitDate()">
    Assign Follow-Up Date
  </button>
  <button class="btn btn-default" style="margin-bottom:6px"
          (click)="cancel()">
    Exit
  </button>
</div>
