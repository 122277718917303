import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { AccidentClaimSubroOutboundInfo, AccidentClaimSubroNetRecoveryInfo, AccidentClaimSubroStatus, UserRightsInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-accident-claim-subro-outbound',
  templateUrl: './accident-claim-subro-outbound.component.html',
  styleUrls: ['./accident-claim-subro-outbound.component.css']
})
export class AccidentClaimSubroOutboundComponent implements OnInit, OnDestroy {
  @Input() userRights: Array<UserRightsInfo>;
  _claimId: number;
  subroInfo: AccidentClaimSubroOutboundInfo;
  subroNetRecoveryInfo: Array<AccidentClaimSubroNetRecoveryInfo>;
  subroStatuses: Array<AccidentClaimSubroStatus>;
  _routeSub: Subscription;

  constructor(private _route: ActivatedRoute,
    private accidentClaimService: AccidentClaimService,
    private datepipe: DatePipe
  ) { }


  printPage(): void {
    window.print();
  }


  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe(params => {
      this._claimId = +params['claimId'];
      this.accidentClaimService.getSubroOutboundInfo(this._claimId).subscribe({
        next: (data) => {
          this.subroInfo = data as AccidentClaimSubroOutboundInfo;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });

      this.accidentClaimService.getSubroNetRecoveryInfo(this._claimId).subscribe({
        next: (data) => {
          this.subroNetRecoveryInfo = data as Array<AccidentClaimSubroNetRecoveryInfo>;
          this.accidentClaimService.setSubroNetRecovery(this.subroNetRecoveryInfo);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });

      this.accidentClaimService.getSubroStatuses(this._claimId).subscribe({
        next: (data) => {
          this.subroStatuses = data as Array<AccidentClaimSubroStatus>;
          this.accidentClaimService.setSubroStatuses(this.subroStatuses);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.error.toString());
        }
      });
    });
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
  }

  getMailToLink(data: AccidentClaimSubroOutboundInfo): string {
    let mt: string = '';
    if (data) {
      let dt = new Date(data.dateOfLoss);
      mt += ('mailto:' + data.subroRepEmail);
      mt += ('?subject=Claim: ' + data.claimId + ', DOL: ' + this.datepipe.transform(dt, 'M/d/yyyy') + ', Client: ' + data.client);
    }
    return mt;
  }
}
