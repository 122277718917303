<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-2 font-weight-bold text-right">Body Shop:</div>
      <div class="col-md-7">
        <div class="card card-body my-3">
          <div *ngIf="detail?.vendorName">
            <p class="font-weight-bold my-0">{{detail?.vendorName}}</p>
            <p class="font-weight-bold my-0">{{detail?.addressLine1}}</p>
            <p class="font-weight-bold my-0">{{detail?.city + ", " + detail?.state + " " + detail?.zipCode}}</p>
            <p class="font-weight-bold my-0">Phone: {{detail?.phoneNumber | phoneUs}}</p>
            <p class="font-weight-bold my-0">Fax: {{detail?.faxNumber | phoneUs}}</p>
            <p class="font-weight-bold my-0">Contact: {{detail?.ownerManagerName}}</p>
            <p class="font-weight-bold my-0">Email: {{detail?.emailAddress}}</p>
          </div>
          <div *ngIf="!detail?.vendorName">
            <p class="font-weight-bold my-0">(Not Available)</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-md-center" *ngIf="estimateDetails?.length > 0">
      <div class="col-md-9 col-sm-12">
        <table class="table">
          <thead style="display:none;">
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let estimate of estimateDetails; let i = index">
              <td>{{i + 1}}. Estimate Type</td>
              <td class="font-weight-bold">{{estimate.estimateDate | date:'M/d/yyyy'}}</td>
              <td class="font-weight-bold red-text">{{estimate.estimateType}}</td>
              <td class="font-weight-bold text-right red-text">{{ estimate.approveDate == '' ? '' : 'Approved ' + (estimate.approveDate |  date:'M/d/yyyy')}}</td>
              <td class="font-weight-bold">{{estimate.estimateAmount | currency:'USD':'$':'1.2-2'}}</td>
            </tr>
          </tbody>
          <tfoot *ngIf="getEstimatedTotal() > 0">
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-weight-bold text-right red-text">Current Approved Total:</td>
              <td class="font-weight-bold">{{ getEstimatedTotal() | currency:'USD':'$':'1.2-2' }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="row" *ngIf="estimateDetails?.length > 0">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            <p class="mb-0">Start Date:</p>
          </div>
          <div class="col-md-3">
            <p class="font-weight-bold mb-0">{{detail?.repairStartDate | date:'M/d/yyyy'}}</p>
          </div>
          <div class="col-md-3">
            <p class="mb-0">Expected Return Date:</p>
          </div>
          <div class="col-md-3">
            <p class="font-weight-bold mb-0">{{detail?.expectedReturnDate | date:'M/d/yyyy'}}</p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
            <p class="mb-0">Complete Date:</p>
          </div>
          <div class="col-md-3">
            <p class="font-weight-bold mb-0">{{detail?.repairCompleteDate | date:'M/d/yyyy'}}</p>
          </div>
          <div class="col-md-3">
            <p class="mb-0">Days in Shop:</p>
          </div>
          <div class="col-md-3">
            <p class="font-weight-bold mb-0">{{detail?.actualRepairDays}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <p class="mb-0">Tax:</p>
          </div>
          <div class="col-md-3">
            <p class="font-weight-bold mb-0">{{detail?.taxAmount | currency:'USD':'$':'1.2-2'}}</p>
          </div>
          <div class="col-md-3">
            <p class="mb-0">Tow:</p>
          </div>
          <div class="col-md-3">
            <p class="font-weight-bold mb-0">{{detail?.towAmount | currency:'USD':'$':'1.2-2'}}</p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
            <p class="mb-0">Final Repair Amount:</p>
          </div>
          <div class="col-md-3">
            <p class="font-weight-bold mb-0">{{detail?.totalAmount | currency:'USD':'$':'1.2-2'}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="estimateDetails?.length == 0">
      <div class="col-md-3">
        <p class="mb-0">Remarks:</p>
      </div>
      <div class="col-md-9">
        <p class="font-weight-bold mb-0">(No estimates for this claim)</p>
      </div>
    </div>
    <div class="row mb-3" *ngIf="glassDetail">
      <div class="col-md-3">
        <p class="mb-0">Glass Amount:</p>
      </div>
      <div class="col-md-3">
        <p class="font-weight-bold mb-0">{{glassDetail?.glassAmount | currency:'USD':'$':'1.2-2'}}</p>
      </div>
      <div class="col-md-3">
        <p class="mb-0">Glass Date:</p>
      </div>
      <div class="col-md-3">
        <p class="font-weight-bold mb-0">{{glassDetail?.glassDate | date:'M/d/yyyy'}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <p class="mb-0">Note:</p>
      </div>
      <div class="col-md-9">
        <p class="font-weight-bold mb-0">{{detail?.claimStatus}}</p>
      </div>
    </div>
  </div>
</div>
