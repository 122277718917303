<div class="row" style="padding-top:2rem" *ngIf="widgetListCategory&&(widgetListFiltered.length>0)">
  <div class="col-sm-12">
    <h4 style="font-style:italic">{{widgetListCategory}}</h4>
  </div>
  
</div>
<ng-container *ngFor="let w of widgetListFiltered">
  <div class="row widgetInfoRow">
    <div [class]="w.categoryIdList&&(w.categoryIdList.length>0)?'col-sm-6':'col-sm-10'">
      <div class="row">
        <div class="col-sm-12"><h5>{{w.title}}</h5></div>
      </div>
      <div class="row">
        <div class="col-sm-12">{{w.description}}</div>
      </div>
    </div>
    <div class="col-sm-4" *ngIf="w.categoryIdList&&(w.categoryIdList.length>0)">
      <div style="float:right" *ngIf="arrCategoryChoices">
        <button *ngFor="let cat of arrCategoryChoices"
                type="button"
                (click)="selectCategory(cat.categoryId)"
                class="btn categoryButton"
                [ngStyle]="{'display':w.categoryIdList.split(',').indexOf(cat.categoryId.toString()) < 0?'none':'' }">
          {{cat.categoryName}}
        </button>
      </div>
    </div>
    <div class="col-sm-2">
      <button type="button"
              (click)="addWidget(w.tileId)"
              class="btn btn-primary">
        Add
      </button>
    </div>
  </div>
</ng-container>
