//Angular
import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
//Third party
import { MatSnackBar } from '@angular/material/snack-bar';
//App
import { environment } from './../../../environments/environment';
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { BlobStorageService } from '../../shared/blob-storage/blob-storage.service';
import { AzureDocument, DbDocumentAzureInfo } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-accident-claim-supports-upload',
  templateUrl: './accident-claim-supports-upload.component.html'
})

export class AccidentClaimSupportsUploadComponent {
  @Input() claimId: number;
  @Output() onDocUploadExit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDocUploadRefresh: EventEmitter<any> = new EventEmitter<any>();
  fileDescription: string;
  baseUrl: string;
  // for mdb upload control
  formData: FormData;
  files: File[];
  dragOver: boolean;
  returnAzureDocInfo: DbDocumentAzureInfo;

  constructor(@Inject('BASE_URL') baseUrl: string,
    public readonly accidentClaimService: AccidentClaimService,
    private loadingSpinnerService: LoadingSpinnerService,
    private readonly errorService: ErrorModalService,
    private snackBar: MatSnackBar,
    private blobStorageService: BlobStorageService,
  ) {
    this.baseUrl = baseUrl;
    // for mdb upload control
    this.files = [];
  }

  showFiles() {
    let files = "";
    for (let i = 0; i < this.files.length; i++) {
      files += this.files[i].name
      if (!((this.files.length - 1) === i)) {
        files += ", "
      }
    }
    return files;
  }

  onFileSelected(event): void {
    const fileSelected: File = event.target.files[0];
    this.files[0] = fileSelected;
    console.log("fileSelected", fileSelected)
  }

  uploadDocs() {
    if (this.fileDescription && (this.fileDescription.length > 0)) {
      let fileCount: number = this.files.length;
      if (fileCount > 0) {
        this.loadingSpinnerService.show();
        this.loadingSpinnerService.showUploadMessage();
        this.formData = new FormData();
        var fileSize = this.files[0].size;
        if (fileSize > 2147483647) fileSize = 2147483647; // SQL can only hold an int in old column for file size
        this.accidentClaimService.insertDocumentData(this.claimId, this.fileDescription, fileSize.toString(),
          this.files[0].name, this.files[0].size.toString()).subscribe({
            next: (data: DbDocumentAzureInfo) => {
              this.returnAzureDocInfo = data;
            },
            error: (err: HttpErrorResponse) => {
              this.errorService.setErrorObject(err.error);
              this.loadingSpinnerService.hideUploadMessage();
              this.loadingSpinnerService.hide();
            },
            complete: () => {
              this.postDocToAzure(this.convertFileToAzureDoc(this.returnAzureDocInfo));
            }
          })
      } else {
        this.errorService.setErrorObject({ message: "No file chosen" });
      }
    } else {
      this.errorService.setErrorObject({ message: "Please enter a description" });
    }
  }

  private convertFileToAzureDoc(file: DbDocumentAzureInfo): AzureDocument {
    let azureDocument: AzureDocument = {
      clientDocumentId: 0,
      fileExtension: file.fileExtension,
      title: file.fileName,
      description: file.fileName,
      uiSortOrder: 0,
      isVisible: true,
      fileName: file.documentGuid,
      azureStorageAccountName: file.azureStorageAccountName, // the storage account the document is stored in
      azureBlobContainerName: file.azureBlobContainerName,  // the blob container
      azureFileName: file.fileName, // the path to the file in the blob container
      azureSharedAccessSignature: file.azureSharedAccessSignature, // the SAS token
      azureBlobUrl: file.fileName, // the url to the file
      azureBlobFullUrl: file.azureBlobFullUrl // the full url to the file with the SAS token
    }
    return azureDocument;
  }

  private postDocToAzure(blobData: AzureDocument) {
    this.blobStorageService.uploadBlob(blobData, this.files[0], environment.storageUriClaimsDocuments)
      .then(blobResponse => {
        this.accidentClaimService.documentUploadedUpdate(blobData.fileName).subscribe({
          next: () => {
            this.loadingSpinnerService.hideUploadMessage();
            this.loadingSpinnerService.hide();
            this.snackBar.open('Document Uploaded Sucessfully', 'Ok', {
              horizontalPosition: 'end',
              verticalPosition: 'top',
              duration: 5000,
              panelClass: 'success-snackbar'
            })
            this.onDocUploadRefresh.emit();
          },
          error: (err: HttpErrorResponse) => {
            this.loadingSpinnerService.hideUploadMessage();
            this.loadingSpinnerService.hide();
            this.errorService.setErrorObject({ message: "There was an error uploading the document. Please try again later." });
          }
        })
      })
      .catch(error => {
        this.loadingSpinnerService.hideUploadMessage();
        this.loadingSpinnerService.hide();
        this.errorService.setErrorObject({ message: "There was an error uploading the document. Please try again later." });
        console.error(error);
      });
  }

  closeUploadPanel() {
    this.onDocUploadExit.emit();
  }
}
