//Angular
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
//third Party
import { MatDatepicker } from '@angular/material/datepicker';
//App
import { SafetyProcessUiProperties } from '../safety-process-ui-properties';
import { SafetyProcess, SafetyProcessList, TargetDriverTypes } from '../services/safety-processes.service';

@Component({
  selector: 'app-multiple-services-tab',
  templateUrl: './multiple-services-tab.component.html',
  styleUrls: ['./multiple-services-tab.component.scss']
})
export class MultipleServicesTabComponent implements OnInit, AfterViewInit {
  @ViewChild('startDatePicker') startDatePicker: MatDatepicker<Date>;
  @Input() safetyProcesses: Map<SafetyProcessList, SafetyProcess>;
  @Input() parentForm: UntypedFormGroup;
  @Input() formGroupNameInput: string;
  actualDate = new Date();
  minDate = new Date('01/01/2011');
  public multipleServicesForm: UntypedFormGroup = new UntypedFormGroup({});
  public uiMap = new Map<SafetyProcessList, SafetyProcessUiProperties>();
  public mvrRequestFilter = new UntypedFormControl(new Date() , Validators.required);
  public hideMonitoringControl = true;

  constructor() {

    this.uiMap.set(SafetyProcessList.Mvr,
      { displayOrder: 1, controlLabel: 'Order MVRs', getsSelectedWith: [SafetyProcessList.DriverQualification] });

    this.uiMap.set(SafetyProcessList.LicenseVerifcation, { displayOrder: 2, controlLabel: 'License Verification', requiresDueDate: true });
    this.uiMap.set(SafetyProcessList.CertificateOfViolation, { displayOrder: 3, controlLabel: 'Certificate of Violation' });
    this.uiMap.set(SafetyProcessList.AutoCoverage, { displayOrder: 4, controlLabel: 'Auto Coverage' });
    this.uiMap.set(SafetyProcessList.LicenseUpload, { displayOrder: 5, controlLabel: 'License Upload', requiresDueDate: true });
    this.uiMap.set(SafetyProcessList.Monitoring, { displayOrder: 5, controlLabel: 'Enroll In Monitoring' });
    this.uiMap.set(SafetyProcessList.PolicySignOff, { displayOrder: 6, controlLabel: 'Policy Sign Off', requiresDueDate: true });
    this.uiMap.set(SafetyProcessList.DriverQualification, { displayOrder: 10, controlLabel: 'DQ Required' });
    this.uiMap.set(SafetyProcessList.DqLicenseUpload,
      {
        displayOrder: 11,
        controlLabel: 'DQ License Upload',
        isReadOnly: true,
        getsSelectedWith: [SafetyProcessList.DriverQualification]
      });
    this.uiMap.set(SafetyProcessList.DqDriverApplication,
      {
        displayOrder: 12,
        controlLabel: 'DQ Driver Application',
        isReadOnly: true,
        getsSelectedWith: [SafetyProcessList.DriverQualification]
      });
    this.uiMap.set(SafetyProcessList.DqMedCard,
      {
        displayOrder: 13,
        controlLabel: 'DQ Medical Certificate',
        isReadOnly: true,
        getsSelectedWith: [SafetyProcessList.DriverQualification]
      });
    this.uiMap.set(SafetyProcessList.DqCertificateOfRoadTest,
      {
        displayOrder: 14,
        controlLabel: 'DQ Certificate of Road Test',
        isReadOnly: true,
        getsSelectedWith: [SafetyProcessList.DriverQualification]
      });
    this.uiMap.set(SafetyProcessList.DqCertificateOfViolation,
      {
        displayOrder: 15,
        controlLabel: 'DQ COV',
        isReadOnly: true,
        getsSelectedWith: [SafetyProcessList.DriverQualification]
      });
    this.uiMap.set(SafetyProcessList.DqRecordOfRoadTest,
      {
        displayOrder: 16,
        controlLabel: 'DQ Record of Road Test',
        isReadOnly: true,
        getsSelectedWith: [SafetyProcessList.DriverQualification]
      });
    this.uiMap.set(SafetyProcessList.DqDriverLog,
      {
        displayOrder: 17,
        controlLabel: 'DQ Driver Log',
        isReadOnly: true,
        getsSelectedWith: [SafetyProcessList.DriverQualification]
      });
    this.uiMap.set(SafetyProcessList.DqClearingHouse,
      {
        displayOrder: 18,
        controlLabel: 'Clearing House Limited Signoff',
        isReadOnly: true
      });

  }
  ngOnInit(): void {
    this.parentForm.addControl(this.formGroupNameInput, this.multipleServicesForm);
    this.multipleServicesForm.addControl('mvrRequestFilter', this.mvrRequestFilter);

    if (
      this.safetyProcesses.has(SafetyProcessList.Monitoring) &&
      this.safetyProcesses.get(SafetyProcessList.Monitoring).userCanAssignTo !== TargetDriverTypes.NONE
    ) {
      // this.multipleServicesForm.addControl('monitoringStatusFilter',)
      this.hideMonitoringControl = false;
    }
  }

  ngAfterViewInit(): void {
    this.startDatePicker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.startDatePicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
            this.startDatePicker.select(event.value);
            this.startDatePicker.close();
        })
    },0)
    })
  }
}
