//Angular imports
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
//thirdparty libraries
import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
//project dependencies
import { OrdersAndAssignmentsReportModalService, OrdersAndAssignmentsBatchDateTime } from "./orders-and-assignments-report-modal-service.component";
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

@Component({
  selector: 'app-orders-and-assignments-report-modal',
  templateUrl: './orders-and-assignments-report-modal.component.html',
  styleUrls: ['./orders-and-assignments-report-modal.component.scss']
})
export class OrdersAndAssignmentsReportModalComponent implements OnInit, OnDestroy {
  batchDateTimeOptions: Array<any>;
  dtToday: Date = new Date();
  ordAssignReportSubscription: Subscription;
  batchId: string;

  constructor(private router: Router,
    private ordAssignReportService: OrdersAndAssignmentsReportModalService,
    private errorService: ErrorModalService,
    private loadingSpinnerService: LoadingSpinnerService,
    private dialogRef: MatDialogRef<OrdersAndAssignmentsReportModalComponent>){}

  ngOnInit(): void {
    this.getBatchDateTimeOptions();
  }

  private getBatchDateTimeOptions() {
    //#AngularUpgrade

    //this.ordAssignReportService.getBatchDateTimeOptions().subscribe(
    //  data => {
    //    let tempin = data as Array<OrdersAndAssignmentsBatchDateTime>;
    //    this.batchDateTimeOptions = tempin.map(o => {
    //      let opt = { value: o.batchId.toString(), label: o.batchDateTime };
    //      return opt;
    //    });
    //  },
    //  (err: HttpErrorResponse) => {
    //    //this.toastrService.error(err.error.toString());
    //    this.errorService.setErrorObject(err.error);
    //  }
    //)
  }

  search() {
    this.closeOrdAssignReportSearchModal();
    this.router.navigate(['safety/ordersandassignmentsreport', this.batchId]);

  }

  closeOrdAssignReportSearchModal() {
    this.ordAssignReportService.closeOrdAssignReportDriverSearchModal();
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.ordAssignReportSubscription.unsubscribe();
  }
}
