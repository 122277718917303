<div class="row">
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <h4 class="text-muted" style="font-weight:bold">Certificate of Insurance Validation Review</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a class="driverProfileLink" (click)="openDriverProfile()">
          {{driverInfo?.fullName}} ({{driverInfo?.employeeId.trim()}})
        </a>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div style="float:right">
      <button type="button"
              (click)="openApproveModal()"
              class="btn btn-primary">
        Approve
      </button>
      <button type="button"
              (click)="openRejectModal()"
              class="btn btn-primary">
        Reject
      </button>
      <button type="button"
              (click)="cancel()"
              class="btn btn-primary">
        Exit
      </button>
    </div>

  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h5 class="text-muted" style="font-weight:bold">Additional Information</h5>
      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel" >
          Client:
        </div>
        <div class="col-md-10 readOnlyFormLayoutBold">
          {{driverInfo?.clientName}}
        </div>
      </div>      
      <div class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel" >
          Insurance Expiration Date:
        </div>
        <div class="col-md-10 readOnlyFormLayoutBold">
          {{coi?.insuranceExpirationDate |  date:'MM/dd/yyyy'}}
        </div>
      </div>
      <div *ngIf="coiInstructions" class="row">
        <div class="col-md-2 readOnlyFormLayoutLabel" >
          Instructions:
        </div>
        <div class="col-md-10 readOnlyFormLayoutBold">
          {{coiInstructions}}
        </div>
      </div>      
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h5 class="text-muted" style="font-weight:bold">Attachments</h5>
      <app-dq-file-panel *ngIf="coi&&coi.fileName&&coi.documentId"
                         [documentId]="coi.documentId"
                         [fileName]="coi.fileName"
                         [uploadDate]="coi.uploadDate">
      </app-dq-file-panel>

    </div>
  </div>
</div>
