import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { DriverQualifications, UserRightsInfo, DriverEmploymentHistory, DriverProfile } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DatePipe } from '@angular/common';

/**
 * this is the client-side controller for the safety - dq files tab panel
 */
@Component({
  selector: 'safety-dq-files',
  templateUrl: './safety-dq-files.component.html',
  styleUrls: ['./safety-dq-files.component.css']
})

export class SafetyDQFilesComponent implements OnInit, OnChanges{

  // private variables that are only shared with subscribers that import the type
  @Input() driverProfile: DriverProfile;
  @Input() driverQualifications: Array<DriverQualifications>;
  @Input() driverEmploymentHistory: Array<DriverEmploymentHistory>;
  @Input() _userRights: Array<UserRightsInfo>;
  driverCOV: DriverQualifications;
  gtOptionsQual: any = {};
  gtOptionsHistoryReview: any = {};
  public driverQualTableConfig: any;
  public driverHistoryReviewTableConfig: any;
 
  // the overloaded constructor for the controller
  constructor(private datePipe: DatePipe) {}

  // angular on intialization event
  ngOnInit() {
    // set data for tables
    this.setTableData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //update table data when inputs change
    this.setTableData();
  }

  private setTableData() {
    if (this.driverQualifications) {
      let covIndex = this.driverQualifications.findIndex(q => q.processId == 2);
      if (covIndex >= 0) {
        //this.driverCOV = this.driverQualifications.splice(covIndex, 1)[0];
        this.driverCOV = this.driverQualifications[covIndex];
      }
      this.setQualTableConfig(this.driverQualifications);
    }
    if (this.driverEmploymentHistory) {
      this.setHistoryReviewTableConfig(this.driverEmploymentHistory);
    }
  }

  private setQualTableConfig(arrDriverQual: Array<DriverQualifications>) {
    var columns = [
      { name: "Document Type", prop: "documentType", title: "Document Type", sort: "", dataType: null },
      { name: "Assigned", prop: "assignedDate", title: "Assigned", sort: "", dataType: null },
      { name: "Due Date", prop: "dueDate", title: "Due Date", sort: "", dataType: 'date' },
      { name: "Completed Date", prop: "processCompletedDate", title: "Completed Date", sort: "", dataType: 'date' },
      { name: "Status", prop: "status", title: "Status", sort: "", dataType: null }
    ];

    var settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: 'enable'
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        type: 'text',
        dataType: s.dataType,
        sort: false,
        render: row => {
          if (s.dataType === "currency") {
            if (row[s.prop] >= 0)
              return "$" + row[s.prop];
            else
              return "($" + (row[s.prop] * -1) + ")";
          }
          else
            return row[s.prop];
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.driverQualTableConfig = {
      settings: settings,
      fields: fields,
      data: arrDriverQual
    }

    this.gtOptionsQual = (arrDriverQual.length > 1 ? { numberOfRows: arrDriverQual.length } : {});
  }

  private setHistoryReviewTableConfig(arrDriverHist: Array<DriverEmploymentHistory>) {
    var columns = [
      { name: "Employer Name", prop: "employerName", title: "Employer Name", sort: "", dataType: null },
      { name: "Phone", prop: "employerPhone", title: "Phone", sort: "", dataType: null },
      { name: "From", prop: "startDate", title: "From", sort: "", dataType: 'date' },
      { name: "To", prop: "endDate", title: "To", sort: "", dataType: 'date' },
      { name: "Position Name", prop: "positionHeld", title: "Position Name", sort: "", dataType: null },
      { name: "Driving Position", prop: "isDrivingPosition", title: "Driving Position", sort: "", dataType: 'boolean' },
      { name: "Contact Attempts", prop: "contactAttemptCount", title: "Contact Attempts", sort: "", dataType: 'number' },
      { name: "Verified", prop: "isVerified", title: "Verified", sort: "", dataType: 'yesorblank' }
    ];

    var settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: 'enable'
      };
    });

    let fields = new Array<any>();
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        type: 'text',
        dataType: s.dataType,
        sort: false,
        render: row => {
          let r = row[s.prop];
          if (s.dataType === "date") {
            // format in mm/dd/yyyy format
            try {
              r = this.datePipe.transform(r, 'MM/dd/yyyy')
            } catch (e) { }
          }
          if (s.dataType === "boolean") {
            // convert to string if not null
            try {
              r = r.toString();
            } catch (e) {
              r = "";
            }            
          }
          if (s.dataType === "yesorblank") {
            // show Yes if true, blank otherwise
            if (r) {
              r = "Yes"
            } else {
              r = "";
            }

          }

          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        }
      });
    });

    this.driverHistoryReviewTableConfig = {
      settings: settings,
      fields: fields,
      data: arrDriverHist
    }

    this.gtOptionsHistoryReview = (arrDriverHist.length > 1 ? { numberOfRows: arrDriverHist.length } : {});
  }
}
