import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, Inject, EventEmitter } from '@angular/core';
import { SafetyNotesDocsService } from '../safety-notes-docs/safety-notes-docs-service.component';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { DriverProfile, UserRightsInfo, DriverNotes, DriverDocuments } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { DatePipe } from '@angular/common';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';

/**
 * this is the client-side controller for the safety - notes and documents tab panel
 */
@Component({
  selector: 'safety-notes-docs',
  templateUrl: './safety-notes-docs.component.html',
  styleUrls: ['./safety-notes-docs.component.css']
})

export class SafetyNotesDocsComponent implements OnInit, OnChanges {
  // private variables that are only shared with subscribers that import the type
  @Input() driverProfile: DriverProfile;
  driverId: number;
  @Input() _userRights: Array<UserRightsInfo>;
  @Input() driverNotes: Array<DriverNotes>;
  @Input() driverDocuments: Array<DriverDocuments>;
  @Output() onDocUploadExit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDocUploadRefresh: EventEmitter<any> = new EventEmitter<any>();
  //@ViewChild('dhpUploadDocuments') inputEl: ElementRef;
  fileDescription: string;
  clientSelectionSubscription: Subscription;
  lineOfBusinessSubscription: Subscription;
  driverHistoryProfileSubscription: Subscription;
  clientSelectedArray: Array<string>;
  clientIdSelectedArray: Array<number>;
  lineOfBusinessId: number;
  baseUrl: string;
  reportSelection: null;
  dirty = false;
  // for mdb upload control
  formData: FormData;
  //files: UploadFile[];
  //uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  showDocuments = true;
  showUploadPanel = false;
  canUpload = false;
  canAddNote = false;
  canRemoveDoc = false;
  canSeeDOB = false;
  canSeeDriverLic = false;
  gtOptionsNotesTable: any = {};
  gtOptionsDocsTable: any = {};
  public driverNotesTableConfig: any;
  public driverDocsTableConfig: any;
  remDoc = "Remove Document";

  // the overloaded constructor for the controller
  constructor(protected router: Router,
    protected driverHistoryProfileService: DriverHistoryProfileService,
    protected fileDownloadService: FileDownloadService,
    protected safetyNotesDocumentService: SafetyNotesDocsService,
    protected http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    protected datePipe: DatePipe,
    protected alertService: AlertService,
    protected loadingSpinnerService: LoadingSpinnerService,
    protected errorService: ErrorModalService) {
    this.baseUrl = baseUrl;
  }

  // angular on intialization event
  ngOnInit() {
    this.canAddNote = this.isAccessAllowed(56);
    this.canUpload = this.isAccessAllowed(55);
    this.canRemoveDoc = this.isAccessAllowed(20);
    this.canSeeDOB = this.isAccessAllowed(42);
    this.canSeeDriverLic = this.isAccessAllowed(42);
    // for testing
    //this.canRemoveDoc = true;
    // set data for tables
    this.setTableData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //update table data when inputs change
    this.driverId = this.driverProfile.driverId;
    this.setTableData();
  }

  private setTableData() {
    if (this.driverNotes) {
      this.setNotesTableConfig(this.driverNotes);
    }
    if (this.driverDocuments) {
      this.setDocsTableConfig(this.driverDocuments);
    }
  }

  private setNotesTableConfig(arrDriverNotes: Array<DriverNotes>) {
    const columns = [
      { name: "Created", prop: "createDate", title: "Created", visible: true, dataType: "datetime", columnClass: null },
      { name: "Created Color", prop: "createDateColor", title: "Created Color", visible: false, dataType: null, columnClass: null },
      { name: "Entered By", prop: "enteredBy", title: "Entered By", visible: true, dataType: "datetime", columnClass: null },
      { name: "Entered By Color", prop: "enteredByColor", title: "Entered By Color", visible: false, dataType: null, columnClass: null },
      { name: "Notes", prop: "note", title: "Notes", visible: true, dataType: null, columnClass: "notesColumnClass" },
      { name: "Email", prop: "driverEmailOutboxId", title: "Email", visible: true, dataType: "link", columnClass: null }
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: (s.visible ? 'enable' : '')
      };
    });

    const fields = [];
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s?.dataType,
        hidden: !s.visible,
        columnClass: (row, col) => {
          return s.columnClass;
        },
        render: row => {
          let r = "";
          switch (s.prop) {
            case "createDate":
              if (row[s.prop] && (row[s.prop].length > 0)) {
                // formatting for createDate
                if (s.dataType === "datetime") {
                  r = this.datePipe.transform(row[s.prop], 'MM/dd/yyyy hh:mm:ss a');
                } else {
                  r = row[s.prop];
                }
                // render with color
                /* 2018-12 - remove color
                  * if (row[s.prop + 'Color'] && (row[s.prop + 'Color'].length > 0)) {
                  r = '<span style="font-weight:bold;color:' + row[s.prop + 'Color'] + '">' + r + '</span>';
                }
                */
              } else {
                r = '';
              }
              break;
            case "driverEmailOutboxId":
              // create email view link
              if (row[s.prop] > 0) {
                r = '<a class="textalign center" style="color:#d23138;"><u>View</u></a>';
              } else {
                r = '';
              }
              break;
            default:
              r = row[s.prop];
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        },
        click: row => {
          if (s.prop === "driverEmailOutboxId") {
            return this.viewNoteEmail(row["driverEmailOutboxId"]);
          }
        }
      });
    });

    this.driverNotesTableConfig = {
      settings: settings,
      fields: fields,
      data: arrDriverNotes
    }

    if (!this.canSeeDOB)
    {
      this.driverNotesTableConfig.data.forEach((element, index) => {
        if (element.note.includes("DOB")) {
          this.driverNotesTableConfig.data.splice(index, 1);
        }
      });
    }
    if (!this.canSeeDriverLic)
    {
      this.driverNotesTableConfig.data.forEach((element, index) => {
        if (element.note.includes("DriverLicense")) {
          this.driverNotesTableConfig.data.splice(index, 1);
        }
      });
    }
    this.gtOptionsNotesTable = (arrDriverNotes.length > 1 ? { numberOfRows: arrDriverNotes.length } : {});
  }

  private setDocsTableConfig(arrDriverDoc: Array<DriverDocuments>) {
    const columns = [
      { name: "Date Uploaded", prop: "dateFormUploaded", title: "Date Uploaded", visible: true, dataType: "date", sort: "enable", columnClass: null },
      { name: "Uploaded By", prop: "uploadedBy", title: "Uploaded By", visible: true, dataType: null, sort: "enable", columnClass: null },
      { name: "Document Description", prop: "documentDescription", title: "Document Description", visible: true, dataType: "link", sort: "enable", columnClass: null },
      { name: "Date Rejected/Approved/Verified", prop: "dateFormRejectedApproved", title: "Date Rejected / Approved / Verified", 
        visible: true, dataType: "date", sort: "enable", columnClass: null },
      { name: "Form Review", prop: "formReview", title: "Form Review", visible: true, dataType: "button", sort: "enable", columnClass: 'textalign center' },
      { name: this.remDoc, prop: "removeDocument", title: this.remDoc, visible: this.canRemoveDoc, dataType: "button", sort: null, columnClass: 'textalign center' },
      { name: "Driver Document ID", prop: "driverDocumentId", title: "Driver Document ID", visible: false, dataType: null, sort: null, columnClass: null }
    ];

    const settings = columns.map(function (s) {
      return {
        objectKey: s.prop,
        visible: s.visible,
        sort: s.sort
      };
    });

    const fields = [];
    columns.forEach(s => {
      fields.push({
        name: s.title,
        objectKey: s.prop,
        dataType: s?.dataType,
        hidden: !s?.visible,
        sort: s?.sort === 'enable',
        columnClass: (row, col) => {
          return s.columnClass;
        },
        render: row => {
          let r = "";
          switch (s.prop) {
            case "documentDescription":
              // create download link
              if (row[s.prop].length > 0) {
                r = `<a style="color:#d23138;""><u>${row[s.prop]}</u></a>`;
              } else {
                r = '';
              }
              break;
            case "removeDocument":
              // create Remove button
              r = '<button class="btn btn-xs btn-default my-0 p-1">Remove</button>';
              break;
            case "formReview":
              if (row["isFormReviewRequired"]) {
                r = `<button class="btn btn-xs btn-default my-0 p-1 textalign center">${row[s.prop]}</button>`;
              } else {
                r = row[s.prop];
              }
              break;
            default:
              r = row[s.prop];
          }
          return r;
        },
        value: row => {
          let val = row[s.prop];
          if (s.dataType === "date") {
            // use ISO string as value for date sorting
            try {
              val = new Date(val).toISOString();
            } catch (e) { }
          }
          if (s.dataType === "number") {
            // convert to number
            try {
              val = +val;
            } catch (e) { }
          }
          return val;
        },
        click: row => {
          if (s.prop === "documentDescription") {
            return this.downloadDoc(row["driverDocumentId"]);
          }
          if (s.prop === "removeDocument") {
            return this.removeDoc(row["driverDocumentId"]);
          }
          if (s.prop === "formReview" && row["isFormReviewRequired"]) {
            return this.reviewDoc(row["driverDocumentId"], row[s.prop]);
          }
        }
      });
    });

    this.driverDocsTableConfig = {
      settings: settings,
      fields: fields,
      data: arrDriverDoc
    }

    this.gtOptionsDocsTable = (arrDriverDoc.length > 1 ? { numberOfRows: arrDriverDoc.length } : {});
  }

  // determines if access to the component is allowed
  isAccessAllowed(userRightsId: number) {
    let retVal = false;

    if (this._userRights) {
      const thisRight = this._userRights.filter(r => r.userRightId === userRightsId);
      if ((thisRight.length > 0) && (thisRight[0].permission === 'allow')) {
        retVal = true;
      }
    }

    return retVal;
  }

  // provides a central point for handling errors
  handleError(message: string) {
    this.alertService.showErrorAlert(message, 'end', 'top', 5000);
  }

  notifyModal(title: string, message: string, modalHeight: string, modalWidth: string, modalInput: any) {
    this.driverHistoryProfileService.notifyModal(title, message, modalHeight, modalWidth, modalInput);
  }


  // sleeps for a certain number of milliseconds
  sleep(milliseconds: number) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }

  toggleUploadPanel(showPanel: boolean) {
    this.showUploadPanel = showPanel;
  }

  refreshDocInfo() {
    // signal that driver profile document data needs to be refreshed
    this.driverHistoryProfileService.setRefreshDriverProfileDocumentsData(this.driverId);
    this.driverHistoryProfileService.setRefreshDriverProfileDriverQualificationsData(this.driverId);
    // close upload panel
    this.toggleUploadPanel(false);
  }

  // view the note
  viewNoteEmail(driverEmailOutboxId: number) {
    const modalInput = {
      driverId: this.driverId,
      driverEmailOutboxId: driverEmailOutboxId
    };
    this.driverHistoryProfileService.notifyModal('View Email', 'viewEmail', null, 'lg', modalInput);
    //alert("View Note with id" + f);
  }

  removeDoc(docId: number) {
    this.notifyModal(this.remDoc, 'removeDocument', '180px;', 'sm', { docId: docId, driverId: this.driverId })
  }

  reviewDoc(docId: number, action: string) {
    this.notifyModal(action + ' Document', action.toLowerCase() + 'Document', '180px;', 'sm', { docId: docId, driverId: this.driverId })
  }

  downloadDoc(docId: number) {
    this.fileDownloadService
      .saveFile(`${this.baseUrl}api/Safety/GetSafetyFile?DocumentId=${docId.toString()}`);
  }
  downloadZipFile() {
    this.fileDownloadService
      .saveFile(`${this.baseUrl}api/Safety/GetDriverDocuments?DriverId=${this.driverId.toString()}`);
  }

  openModal(data: any): void {
    const row = data?.row || null;
    switch (data?.type) {
      case 'viewEmail':
        this.viewNoteEmail(row?.driverEmailOutboxId);
        break;
      case 'downloadDocument':
        this.downloadDoc(row?.driverDocumentId);
        break;
      case 'removeDocument':
        this.removeDoc(row?.driverDocumentId);
        break;
      case 'reviewDocument':
        if (row?.isFormReviewRequired === true) {
          this.reviewDoc(data.row["driverDocumentId"], "formReview");
        }
        break;
      default:
        break;
    }
  }
}
