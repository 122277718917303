<div class="row">
  <div class="col-sm-12 buttonsContainer">


    <button type="button"
            (click)="exportToPdf()"
            class="btn btn-default">
      Export to PDF
    </button>
    <button type="button"
            (click)="viewTransactions()"
            class="btn btn-default">
      View Transactions
    </button>
    <button type="button"
            (click)="printPage()"
            class="btn btn-default">
      Print
    </button>
  </div>
</div>

<div class="modal modal-wide " tabindex="-1" role="dialog" [ngStyle]="{'display':displayTransactionsModal}">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="closeTransactions()"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Transaction History</h4>
      </div>
      <div class="modal-body">
        <h5>Claim Loss Notice Transaction History:</h5>
        <app-loading-panel *ngIf="!tableConfig"></app-loading-panel>
        <div *ngIf="tableConfig">
          <app-data-table 
            [columns]="tableConfig.fields"
            [data]="tableConfig.data"
            [noRecordsLabel]="tableConfig.noRecordsLabel"
          ></app-data-table>
        </div>

      </div>
    </div>
  </div>
</div>

